import React, { useEffect, useState } from 'react';
import styles from './ActionBottom.module.css';
import { TempReplyAction } from '../../../../../../../@Types/Action';
import LoaderBottom from './LoaderBottom/LoaderBottom';
import { FailedBottom } from './FailedBottom/FailedBottom';
import {
    getTicketReply,
    ReplyCache,
} from '../../../../../../../utils/ReplyCache';
import { Ticket } from '../../../../../../../@Types/TicketTypes/Ticket';
import { PendingBottom } from './PendingBottom/PendingBottom';
import { SelfPendingBottom } from './SelfPendingBottom/SelfPendingBottom';

interface ActionBottomProps {
    element: TempReplyAction;
    ticket: Ticket;
}

function TempReplyBottom({ element, ticket }: ActionBottomProps): JSX.Element {
    const [loading, setLoading] = useState(false);
    const [cache, setCache] = useState<ReplyCache | undefined>(
        getTicketReply(element.idProject, element.idTicket)
    );

    const refreshCache = (): void =>
        setCache(getTicketReply(element.idProject, element.idTicket));

    useEffect(() => {
        setLoading(false);
        return (): void => {
            setLoading(false);
        };
    }, [loading]);

    if (!cache) {
        return <div></div>;
    }
    return (
        <div className={styles.container}>
            {cache.state === 'sending' && (
                <LoaderBottom
                    element={element}
                    refreshCache={refreshCache}
                    timeout={cache.timeout}
                    ticket={ticket}
                />
            )}
            {cache.state === 'failed' && (
                <FailedBottom
                    element={element}
                    refreshCache={refreshCache}
                    ticket={ticket}
                />
            )}
            {cache.state === 'pending' && !cache.sameUser && <PendingBottom />}
            {cache.state === 'pending' && cache.sameUser && (
                <SelfPendingBottom
                    element={element}
                    refreshCache={refreshCache}
                />
            )}
        </div>
    );
}

export default TempReplyBottom;
