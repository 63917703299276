import { InmuebleInfo360 } from '../../../../types';
import { OpenComponent } from '../../../Utils/Openable/OpenComponent';
import styles from './CierreVenta.module.css';
import { calcDate } from '../../../../../../utils/DateFunctions';
import {
    empStrOrUndToNA,
    getPrettyCurrencyValue,
} from '../../../Utils/otherFunctions';

interface CierreVentaProps {
    cierreVenta: InmuebleInfo360['infoAgrupacion']['cierreVenta'];
    conceptosMap: InmuebleInfo360['cartera']['conceptosMap'];
}

export function CierreVenta({
    cierreVenta,
    conceptosMap,
}: CierreVentaProps): JSX.Element {
    const cuotaInicial = conceptosMap.subs[1];
    const credito = conceptosMap.subs[2];
    const reforma = conceptosMap.subs[3];
    const subsidio = conceptosMap.subs[4];
    const total = {
        valorPactado:
            cuotaInicial.valorPactado +
            credito.valorPactado +
            subsidio.valorPactado +
            reforma.valorPactado,
    };
    return (
        <OpenComponent title={'Cierre Venta'} showOpen={true}>
            <div className={styles.baseInfo}>
                <div className={styles.row}>
                    <div className={styles.label}>Tipo de Venta:</div>
                    <div className={styles.value}>{cierreVenta.tipoVenta}</div>
                </div>
                <div className={styles.row}>
                    <div className={styles.label}>Valor de la Venta:</div>
                    <div className={styles.value}>
                        {getPrettyCurrencyValue(total.valorPactado)}
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.label}># de Encargo:</div>
                    <div className={styles.value}>
                        {empStrOrUndToNA(cierreVenta.numeroEncargo)}
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.label}>Escritura No:</div>
                    <div className={styles.value}>
                        {empStrOrUndToNA(cierreVenta.numeroEscritura)}
                    </div>
                </div>
            </div>
            <div className={styles.baseInfo}>
                <div className={styles.row}>
                    <div className={styles.label}>Ultimo Tramite Cumplido:</div>
                </div>
                {!cierreVenta.ultimoTramiteCumplido ? (
                    <div className={styles.row}>
                        <h1 className={styles.label}>No hay trámites</h1>
                    </div>
                ) : (
                    <>
                        <div className={styles.row}>
                            <div className={styles.label}>
                                {empStrOrUndToNA(
                                    cierreVenta.ultimoTramiteCumplido.codigo
                                )}
                            </div>
                            <div className={styles.value}>
                                {empStrOrUndToNA(
                                    cierreVenta.ultimoTramiteCumplido.nombre
                                )}
                            </div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.label}>
                                Fecha Compromiso:
                            </div>
                            <div className={styles.value}>
                                {cierreVenta.ultimoTramiteCumplido
                                    .fechaCompromiso
                                    ? calcDate(
                                          new Date(
                                              cierreVenta.ultimoTramiteCumplido.fechaCompromiso
                                          ),
                                          true
                                      )
                                    : 'N/A'}
                            </div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.label}>
                                Fecha Cumplimiento:
                            </div>
                            <div className={styles.value}>
                                {cierreVenta.ultimoTramiteCumplido
                                    .fechaCumplimiento
                                    ? calcDate(
                                          new Date(
                                              cierreVenta.ultimoTramiteCumplido.fechaCumplimiento
                                          ),
                                          true
                                      )
                                    : 'N/A'}
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className={styles.baseInfo}>
                <div className={styles.row}>
                    <div className={styles.label}>Reformas:</div>
                    <div className={styles.value}>
                        {reforma.valorPactado > 0 ? 'Aplica' : 'No Aplica'}
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.label}>Valor Reforma:</div>
                    <div className={styles.value}>
                        {getPrettyCurrencyValue(reforma.valorPactado ?? 0)}
                    </div>
                </div>
            </div>
        </OpenComponent>
    );
}
