import { AYFConversationStep } from '../../../../@Types/ConversationTypes/AYFConversationStep';
import StepTypes from '../../../../constants/Construction/AYFFormStepTypes';
import ListApiStep from './Collection/ListApiStep/ListApiStep';
import TextStep from './Collection/TextStep/TextStep';

interface AYFStepMapperProps {
    step: AYFConversationStep;
}
function AYFStepMapper(props: AYFStepMapperProps): JSX.Element {
    switch (props.step.type) {
        case StepTypes.AYF_BILL_NUM:
        case StepTypes.AYF_YEAR:
        case StepTypes.AYF_CUT_OFF_DATE:
            return (
                <TextStep
                    {...props}
                    step={props.step as any}
                    showSubject={false}
                    showClientInfoType={false}
                    showCompanyMatcher={false}
                    showValidation={false}
                />
            );
        case StepTypes.AYF_START_MONTH:
        case StepTypes.AYF_END_MONTH:
        case StepTypes.AYF_ICA_CITY:
            return (
                <ListApiStep
                    {...props}
                    step={props.step as any}
                    showClientInfoType={false}
                />
            );
        default:
            return <div></div>;
    }
}

export default AYFStepMapper;
