import { Trigger } from '../../../../../../../@Types/ProcessTypes/Activity';

import React, { useState } from 'react';
import styles from './FormActivityAction.module.css';
import SubmitBtns from '../../../../Activity/SubmitBtns/SubmitBtns';
import RoundedButton from '../../../../../../../shared/RoundedButton/RoundedButton';
import FormRenderer from '../../../../../../../shared/FormRenderer/FormRenderer';
import FormDialog from '../../../../../../../shared/FormRenderer/FormDialog/FormDialog';
import { EurekaDraft } from '../../../../../../../@Types/Draft/Draft';
import { convertFromRaw, EditorState } from 'draft-js';
import DraftRenderer from '../../../../../../../shared/DraftRenderer/DraftRenderer';
export interface FormActivityProps {
    activity: {
        id: string;
        name: string;
        helperText?: string;
        triggers: Trigger[];
        defaultTrigger?: Trigger;
    };
    idTrigger: string | null;
    values: Record<string, any>;
    message?: EurekaDraft;
}

function FormActivityComponent({
    values,
    message,
    activity,
    idTrigger,
}: FormActivityProps): JSX.Element {
    const [showDialog, setShowDialog] = useState(false);

    const trigger = idTrigger
        ? activity.triggers.find((trigger) => trigger.id === idTrigger)
        : activity.defaultTrigger;
    if (!trigger) return <></>;

    const renderForm = (): JSX.Element | void => {
        if (trigger.form?.rootSteps) {
            return (
                <React.Fragment>
                    {trigger.form?.postview?.steps && (
                        <div
                            className={styles.formContainer}
                            style={{
                                marginTop:
                                    activity.helperText || message ? -10 : 0,
                            }}
                        >
                            <FormRenderer
                                rootSteps={trigger.form?.postview?.steps}
                                postview={true}
                                form={{
                                    ...trigger.form,
                                    size: {
                                        blockNum: 2,
                                        blockSize: 210,
                                        spacingSize: 20,
                                    },
                                }}
                                values={values}
                            />
                        </div>
                    )}
                    {trigger.form.rootSteps.length > 0 &&
                        (!trigger.form?.postview?.steps ||
                            trigger.form.postview?.steps.length !==
                                trigger.form.rootSteps.length ||
                            trigger?.form?.postview?.steps.find(
                                (idStep) =>
                                    !trigger.form?.rootSteps.includes(idStep)
                            )) && (
                            <div
                                style={{
                                    marginBottom:
                                        trigger.form?.postview?.showTriggers ||
                                        !trigger.form
                                            ? 10
                                            : 0,
                                }}
                            >
                                <RoundedButton
                                    onClick={(): void => {
                                        setShowDialog(true);
                                    }}
                                    backgroundColor={'var(--secondary)'}
                                    text={
                                        !trigger.form.postview?.steps?.length
                                            ? 'Ver Formulario'
                                            : 'Ver más'
                                    }
                                    fontSize={'15px'}
                                    padding="8px 16px"
                                />
                            </div>
                        )}
                </React.Fragment>
            );
        }
    };

    return (
        <React.Fragment>
            {showDialog && trigger.form && (
                <FormDialog
                    label={activity.name}
                    onClose={(): void => setShowDialog(false)}
                    postview={true}
                    idForm={activity.id + '-' + trigger.id}
                    rootSteps={trigger.form.rootSteps}
                    form={trigger.form}
                    sendLabel={trigger.label}
                    values={values}
                />
            )}
            <div className={styles.container}>
                {!message && activity.helperText && (
                    <label className={styles.helperMessageLbl}>
                        {activity.helperText}
                    </label>
                )}
                {message && (
                    <div className={styles.postviewMessagecontainer}>
                        <DraftRenderer
                            editorState={EditorState.createWithContent(
                                convertFromRaw(message)
                            )}
                        />
                    </div>
                )}
                {renderForm()}
                {(trigger.form?.postview?.showTriggers || !trigger.form) && (
                    <SubmitBtns
                        postview={true}
                        idTrigger={trigger.id}
                        triggers={activity.triggers}
                        defaultTrigger={activity.defaultTrigger}
                    />
                )}
            </div>
        </React.Fragment>
    );
}

export default FormActivityComponent;
