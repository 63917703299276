import React from 'react';
import { getBezierPath, Position } from 'react-flow-renderer';

interface ErrorEdgeProps {
    /** Edge Id */
    id: string;
    sourceX: number;
    sourceY: number;
    targetX: number;
    targetY: number;
    /** Source Handle Position */
    sourcePosition: Position | undefined;
    /** Target Handle Position */
    targetPosition: Position | undefined;
}
function ErrorEdge({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
}: ErrorEdgeProps): JSX.Element {
    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    return (
        <g>
            <path
                id={id + 'background'}
                className="flowPathBack"
                d={edgePath}
            />
            <path id={id} className="flowPath-error" d={edgePath} />
        </g>
    );
}
export default ErrorEdge;
