import styles from './MapperStepConfig.module.css';
import Toggle from '../../../../shared/Toggle/Toggle';
import React from 'react';
import { FormStep, MapperStep } from '../../../../@Types/FormTypes/FormStep';
import RoundedSelect from '../../../../shared/RoundedSelect/RoundedSelect';
import { MenuItem } from '@material-ui/core';
import RoundedFormStepPicker from '../../../../shared/@Pickers/RoundedFormStepPicker/RoundedFormStepPicker';
import FormStepTypes from '../../../../constants/FormStepTypes';
import { useCurrentProject } from '../../../../hooks';
import { useGenericEditorSelector } from '../../../../shared/GenericFormEditor/GenericFormEditorHooks';

interface MapperStepConfigProps {
    step?: MapperStep;
    updateStep?: (step: FormStep) => void;
}
function MapperStepConfig({
    step,
    updateStep,
}: MapperStepConfigProps): JSX.Element {
    if (!step || !updateStep) {
        return <div></div>;
    }
    const project = useCurrentProject();
    const editorInfo = useGenericEditorSelector((state) => state);
    return (
        <React.Fragment>
            {project.concepts?.length > 0 && (
                <div className={styles.conceptsContainer}>
                    <div className={styles.toggleCont}>
                        <div className={styles.conceptLabel}>Concepto:</div>
                        <div className={styles.toggleContainer}>
                            <Toggle
                                size="small"
                                checked={step.concept !== undefined}
                                onChange={(checked: boolean): void => {
                                    updateStep({
                                        ...step,
                                        concept: checked
                                            ? {
                                                  idConcept:
                                                      project.concepts[0].id,
                                                  mappings: {},
                                              }
                                            : undefined,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    {step.concept && (
                        <React.Fragment>
                            <div className={styles.conceptPickerContainer}>
                                <RoundedSelect
                                    minWidth={210}
                                    height="31px"
                                    value={step.concept.idConcept}
                                    label="Concepto"
                                    containerMargin="0px"
                                    handleUpdate={(event): void => {
                                        if (step.concept) {
                                            updateStep({
                                                ...step,
                                                concept: {
                                                    ...step.concept,
                                                    idConcept:
                                                        event.target.value,
                                                },
                                            });
                                        }
                                    }}
                                >
                                    {project.concepts.map((concept) => (
                                        <MenuItem
                                            value={concept.id}
                                            key={concept.id}
                                        >
                                            {concept.name}
                                        </MenuItem>
                                    ))}
                                </RoundedSelect>
                            </div>
                            {((): JSX.Element => {
                                const concept = project.concepts.find(
                                    (concept) =>
                                        concept.id === step.concept?.idConcept
                                );
                                const mappings = step.concept!.mappings;
                                const allSteps = {
                                    ...step.steps,
                                    ...editorInfo.steps,
                                };
                                if (!concept) return <div></div>;
                                return (
                                    <div
                                        className={styles.conceptPropContainer}
                                    >
                                        {concept.baseSteps.map((idStep) => {
                                            const subStep =
                                                concept.steps[idStep];
                                            if (!subStep) return <div></div>;
                                            return (
                                                <RoundedFormStepPicker
                                                    key={idStep}
                                                    steps={Object.values(
                                                        allSteps
                                                    ).filter(
                                                        (step) =>
                                                            step.type !==
                                                            FormStepTypes.MAPPER
                                                    )}
                                                    allowCustom
                                                    label={
                                                        (subStep as any).label
                                                    }
                                                    value={mappings[idStep]}
                                                    handleUpdate={(
                                                        idStep
                                                    ): void => {
                                                        updateStep({
                                                            ...step,
                                                            concept: {
                                                                ...step.concept!,
                                                                mappings: {
                                                                    ...mappings,
                                                                    [subStep.id]:
                                                                        idStep,
                                                                },
                                                            },
                                                        });
                                                    }}
                                                />
                                            );
                                        })}
                                    </div>
                                );
                            })()}
                        </React.Fragment>
                    )}
                </div>
            )}
        </React.Fragment>
    );
}
export default MapperStepConfig;
