import { Agent } from '../../@Types/Agent';
import { AgentPermissions } from '../../@Types/Agent';
import axiosInstance from '../../AxiosAPI';

export const loadAgentsByQuery = async (
    queryString: string,
    idAgent?: string
): Promise<{
    elements: Agent[];
    page: number;
    selectedElement?: number;
    filters: {};
    orderBy: string;
    search: string;
}> => {
    const query = new URLSearchParams(queryString);
    const result = await axiosInstance.get(
        `/agents/load${idAgent ? '/' + idAgent : ''}` + '?' + query
    );
    return { ...result.data };
};

/**
 * Function that loads the agents' list
 * @param page currently viewing
 * @param pageSize to request
 * @param filters currently active
 * @param orderBy method ordered by, undefined if search is active
 * @param search search query, undefined if no search is active
 * @param idProject the id of the current project
 * @returns array of Agents that match the inputs
 */
export const loadAgents = async (
    page: number,
    pageSize: number,
    filters: any[], //TODO definir filtros
    orderBy: string | undefined,
    search: string | undefined,
    idProject?: string
): Promise<Agent[]> => {
    let url = `/agents?page=${page}&pageSize=${pageSize}`;
    if (filters.length !== 0) {
        url += `&filters=${filters}`;
    }
    if (search !== undefined) {
        url += `&search=${encodeURIComponent(search)}`;
    } else {
        url += `&orderBy=${orderBy}`;
    }
    if (idProject !== undefined) {
        url += `&project=${idProject}`;
    }
    const response = await axiosInstance.get(url);
    return response.data;
};

/**
 * Function called to handle the conexion with the server to create an agent
 * @param agent Record of values to create the agent with
 */
export const createAgent = async (
    agent: Record<string, unknown>
): Promise<void> => {
    await axiosInstance.post('/agents', agent);
};

/**
 * Function that handles the async update of an agents value
 * @param id of the agent to update
 * @param agent Record of values to update
 */
export const updateAgent = async (
    id: string,
    agent: Record<keyof Agent, any>
): Promise<void> => {
    if (agent.replacement) agent.replacement = agent.replacement._id;
    await axiosInstance.patch('agents/' + id, agent);
};

export const updateAgentOnVacation = async (
    id: string,
    onVacations: boolean
): Promise<void> => {
    await axiosInstance.patch('agents/' + id, { onVacations });
};

export const transferTicketsToReplacement = async (
    id: string
): Promise<void> => {
    await axiosInstance.patch('agents/' + id, { transferAgentTickets: true });
};

/**
 * Function that handles the async update of an agents permissions
 * @param id of the agent to update
 * @param permissions of the agent in Record of values to update
 */
export const updateAgentPermissions = async (
    id: string,
    permissions: AgentPermissions
): Promise<void> => {
    if (permissions.entities === undefined) {
        permissions.entities = {};
    }
    await axiosInstance.patch('agents/' + id + '/permissions', permissions);
};

const AgentsCache: Record<string, Promise<Agent> | Agent> = {};

/**
 * Function that loads an agent
 * @param id the agent to load
 * @returns Agent that matches the input
 */
export const loadAgent = async (id: string): Promise<Agent> => {
    if (AgentsCache[id]) return AgentsCache[id];
    AgentsCache[id] = new Promise(async (resolve, reject) => {
        try {
            const { data } = await axiosInstance.get(`/agents/${id}`);
            resolve(data);
        } catch (error) {
            reject(error);
        }
    });
    return AgentsCache[id];
};

/**
 * Function that loads the agents by a given list of ids
 * @param agents the list of ids
 */
export const loadAgentsById = async (agents: string[]): Promise<Agent[]> => {
    const loaded = await Promise.all(
        agents.map(async (idAgent): Promise<Agent | null> => {
            try {
                return await loadAgent(idAgent);
            } catch (error) {
                return null;
            }
        })
    );
    return loaded.filter((agent): agent is Agent => agent !== null);
};

export default {
    loadAgents,
    loadAgent,
    createAgent,
    updateAgent,
    updateAgentPermissions,
    updateOnVacation: updateAgentOnVacation,
};
