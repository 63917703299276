import styles from './AgentsMenu.module.css';
import React, { useRef } from 'react';
import Search from '../../Search/Search';
import Popper, { PopperPlacementType } from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import { Agent, Area } from '../../../@Types/@Types';
import {
    filter,
    toggleFilters,
    search,
    setLoading,
} from '../../../controllers/AgentsMenuController/AgentsActions';
import AreasFilter from '../../AreasFilter/AreasFilter';

import RoundedButton from '../../RoundedButton/RoundedButton';
import AgentsList from './AgentsList';
import Dialog from '../../Dialog/Dialog';

interface AgentsMenuProps {
    /** The ref of the anchor, the Agents button on the ticket detail */
    anchorRef: any;
    /** Function to call to close the menu */
    handleClose: Function;
    /** The ids or objects of the initially selected Agents */
    initialAgents?: Agent[] | string[];
    /** The agents to not include in the list */
    filterAgents?: Agent[];
    /** The title of the menu */
    title?: string;
    /** If the menu allows selecting multiple agents */
    multiple?: boolean;
    /** if the menu should only display the current project's agents */
    projectFilter?: boolean;
    /** if the area filters should be shown */
    showAreaFilters?: boolean;
    /** Function called when the selectedAgents change */
    handleChange?: Function;
    /** confirmation button text */
    confirm?: string;
    /** Function called on confirm */
    handleConfirm?: Function;
    children?: any;
    zIndex?: number;
    placement?: PopperPlacementType;
    marginTop?: string;
    /** material ui style seed */
    seed?: string;
    /** If the app is mobile */
    mobile?: boolean;
}
function AgentsMenu({
    anchorRef,
    handleClose,
    multiple = true,
    showAreaFilters = true,
    initialAgents = [],
    filterAgents = [],
    seed = 'Erk-',
    handleChange,
    projectFilter = false,
    children,
    handleConfirm,
    confirm = 'Confirmar',
    placement = 'bottom-start',
    title = 'Seleccionar Agente',
    zIndex = 2,
    marginTop = '0px',
    mobile = false,
}: AgentsMenuProps): JSX.Element {
    const dispatch = useDispatch();
    const emptyRef = useRef<HTMLDivElement>(null);
    const menuInfo = useSelector((state: RootState) => state.agentsMenu);
    const listRef = useRef<HTMLDivElement>(null);

    const renderContent = (): JSX.Element => {
        return (
            <div
                style={{ marginTop }}
                className={
                    mobile ? styles.mobileContainer : styles.emptyContainer
                }
                onClick={(e): void => {
                    if (emptyRef.current === e.target) {
                        handleClose();
                    }
                }}
                ref={emptyRef}
                data-testid={'AgentsMenu_container_S'}
            >
                <div className={styles.container}>
                    <div className={styles.title}>
                        {title + (multiple ? 's' : '')}
                    </div>
                    <div className={styles.searchContainer}>
                        <Search
                            wait={500}
                            seed={seed}
                            placeholder={'Buscar Agentes'}
                            search={menuInfo.search}
                            handleLoadingSearch={(loading: boolean): void => {
                                dispatch(setLoading(loading));
                            }}
                            handleSearch={(value: any): void => {
                                if (listRef.current)
                                    listRef.current.scrollTop = 0;
                                dispatch(search(value));
                            }}
                        />
                        {projectFilter && showAreaFilters && (
                            <div
                                className={styles.filterBtn}
                                onClick={(): void => {
                                    dispatch(toggleFilters());
                                }}
                                style={{
                                    border: menuInfo.showFilters
                                        ? '1px solid var(--dark-grey)'
                                        : '1px solid rgba(0, 0, 0, 0.23)',
                                }}
                            >
                                <img
                                    className={styles.filterIcon}
                                    src={`/media/icons/greyFilterIcon${
                                        menuInfo.showFilters ? '-Dark' : ''
                                    }.svg`}
                                />
                            </div>
                        )}
                    </div>
                    {menuInfo.showFilters && (
                        <div className={styles.filtersContainer}>
                            <div className={styles.areasFilters}>
                                Area:
                                <div className={styles.areasDropDownContainer}>
                                    <AreasFilter
                                        seed={seed}
                                        options={menuInfo.areas}
                                        value={menuInfo.areafilter}
                                        handleUpdate={(area: Area): void => {
                                            dispatch(filter(area));
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={styles.listContainer}>
                        <AgentsList
                            listRef={listRef}
                            handleChange={handleChange}
                            multiple={multiple}
                            filterAgents={filterAgents}
                            initialAgents={initialAgents}
                            projectFilter={projectFilter}
                            showAreaFilters={showAreaFilters}
                        />
                        {menuInfo.elements.length !== 0 &&
                            children?.length > 0 &&
                            children}
                        {handleConfirm !== undefined && (
                            <div className={styles.confirmBtnContainer}>
                                <RoundedButton
                                    text={confirm}
                                    onClick={(): void => {
                                        if (menuInfo.selected.length > 0) {
                                            handleConfirm(
                                                multiple
                                                    ? menuInfo.selected
                                                    : menuInfo.selected[0]
                                            );
                                        }
                                    }}
                                    borderRadius={3}
                                    disabled={menuInfo.selected.length === 0}
                                ></RoundedButton>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    if (mobile) {
        return (
            <Dialog
                onClose={(): void => {
                    handleClose();
                }}
                maxWidth="100vw"
                border={10}
                transparent
            >
                {renderContent()}
            </Dialog>
        );
    } else {
        return (
            <ClickAwayListener
                mouseEvent="onMouseDown"
                onClickAway={(): void => {
                    handleClose();
                }}
            >
                <Popper
                    open={true}
                    anchorEl={anchorRef.current}
                    placement={placement}
                    modifiers={{
                        preventOverflow: {
                            enabled: true,
                            priority: ['top', 'bottom', 'left', 'right'],
                            boundariesElement: 'window',
                            padding: 20,
                        },
                    }}
                    style={{ zIndex }}
                >
                    {renderContent()}
                </Popper>
            </ClickAwayListener>
        );
    }
}
export default AgentsMenu;
