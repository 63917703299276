import React from 'react';
import { TicketPropertyTypes } from '../../../constants/TicketPropertyTypes';
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import styles from './RoundedTicketPropertyPicker.module.css';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import TitleIcon from '@material-ui/icons/Title';
import SubjectIcon from '@material-ui/icons/Subject';
import { MenuItem } from '@material-ui/core';
import RoundedSelect, {
    RoundedSelectProps,
} from '../../RoundedSelect/RoundedSelect';
import EntryIcon from '../../../Icons/EntryIcon';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import EntityIcon from '../../SmartIcons/EntityIcon';
import { ProjectEntity } from '../../../@Types/Project';
import MegaphoneIcon from '../../../Icons/MegaphoneIcon';
import { useCurrentProject } from '../../../hooks';

interface RoundedTicketPropertyPickerProps
    extends Omit<RoundedSelectProps, 'handleUpdate' | 'value'> {
    /** Currently selected value */
    value: TicketPropertyTypes | string | undefined;
    /** The posible properties to select */
    propertyTypes?: TicketPropertyTypes[];
    /** function called when value changes */
    handleUpdate: (property: TicketPropertyTypes | string) => void;
}
function RoundedTicketPropertyPicker({
    value,
    propertyTypes = [],
    handleUpdate,
    ...others
}: RoundedTicketPropertyPickerProps): JSX.Element {
    const project = useCurrentProject();

    const organization = useSelector(
        (state: RootState) => state.site.organization
    );
    const entities = useSelector((state: RootState) => state.site.entities);
    return (
        <RoundedSelect
            value={value}
            paddingLeft={0}
            handleUpdate={(event): void => {
                handleUpdate(event.target.value as TicketPropertyTypes);
            }}
            {...others}
        >
            {propertyTypes
                .filter((type) => type !== TicketPropertyTypes.ENTITYVALUE)
                .map((property) => (
                    <MenuItem
                        key={property}
                        value={property}
                        style={{ paddingLeft: 0 }}
                    >
                        <PropertyComponent property={property} />
                    </MenuItem>
                ))}
            {propertyTypes.includes(TicketPropertyTypes.ENTITYVALUE) &&
                project.entities
                    .filter(
                        (entity) =>
                            entity.idEntity !== organization?.idCompanyEntity &&
                            entities[entity.idEntity].active
                    )
                    .map((entity) => (
                        <MenuItem
                            key={entity.idEntity}
                            value={entity.idEntity}
                            style={{ paddingLeft: 0 }}
                        >
                            <EntityPropertyComponent entity={entity} />
                        </MenuItem>
                    ))}
        </RoundedSelect>
    );
}
export default RoundedTicketPropertyPicker;

function PropertyComponent({
    property,
}: {
    property: TicketPropertyTypes | undefined;
}): JSX.Element {
    switch (property) {
        case TicketPropertyTypes.SUBJECT: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.subjectLbl}>
                            <TitleIcon fontSize="inherit" />
                        </div>
                    </div>
                    Asunto
                </div>
            );
        }
        case TicketPropertyTypes.TEXT: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.textAreaContainer}>
                            <SubjectIcon fontSize="inherit" />
                        </div>
                    </div>
                    Mensaje
                </div>
            );
        }
        case TicketPropertyTypes.CLASSIFIER: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <img
                            className={styles.shapesIcon}
                            alt={'shapes'}
                            src={'/media/icons/shapes.svg'}
                        />
                    </div>
                    Clasificador
                </div>
            );
        }
        case TicketPropertyTypes.RESOLUTION_DATE: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.dateInputContainer}>
                            <CalendarTodayRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                    Fecha de Vencimiento
                </div>
            );
        }
        case TicketPropertyTypes.CLIENT: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.clientContainer}>
                            <PersonRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                    Cliente
                </div>
            );
        }
        case TicketPropertyTypes.SENTIMENT: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.sentimentContainer}>
                            <SentimentVerySatisfiedIcon fontSize="inherit" />
                        </div>
                    </div>
                    Tono
                </div>
            );
        }
        case TicketPropertyTypes.COMPANY: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <img
                            className={styles.companyIcon}
                            src="/media/icons/bluehandshake.svg"
                        />
                    </div>
                    Empresa
                </div>
            );
        }
        case TicketPropertyTypes.STATE: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <img
                            className={styles.stateIcon}
                            src="/media/icons/bluecycle.svg"
                        />
                    </div>
                    Estado
                </div>
            );
        }
        case TicketPropertyTypes.CHANNEL: {
            return (
                <div className={styles.property}>
                    <div className={styles.megaphoneIcon}>
                        <MegaphoneIcon
                            style={{ width: 30, height: 30 }}
                            fill="var(--secondary)"
                        />
                    </div>
                    Canal
                </div>
            );
        }
        case TicketPropertyTypes.SURVEY: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <img
                            className={styles.stateIcon}
                            src="/media/icons/bluesurvey.svg"
                        />
                    </div>
                    Encuesta
                </div>
            );
        }
        case TicketPropertyTypes.SUBTICKET: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <img
                            className={styles.stateIcon}
                            src="/media/icons/subticket.svg"
                        />
                    </div>
                    SubCaso
                </div>
            );
        }
        case TicketPropertyTypes.ENTRY: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <EntryIcon fill="var(--secondary)" />
                    </div>
                    Entrada
                </div>
            );
        }
        case TicketPropertyTypes.PROCESS: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <img
                            className={styles.stateIcon}
                            src="/media/icons/bluecycle.svg"
                        />
                    </div>
                    Proceso
                </div>
            );
        }
        default:
            return <div></div>;
    }
}
function EntityPropertyComponent({
    entity: { idEntity, multiple },
}: {
    entity: ProjectEntity;
}): JSX.Element {
    const entity = useSelector(
        (state: RootState) => state.site.entities[idEntity]
    );
    return (
        <div className={styles.property}>
            <div className={styles.icon}>
                <EntityIcon idEntity={idEntity} fill={'var(--secondary)'} />
            </div>
            {multiple ? entity.pluralName : entity.name}
        </div>
    );
}
