import styles from './StepMenu.module.css';
import TitleIcon from '@material-ui/icons/Title';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SubjectIcon from '@material-ui/icons/Subject';
import { FormStepTypes } from '../../../constants/FormStepTypes';
import { FormStep } from '../../../@Types/FormTypes/FormStep';
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded';
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import DescriptionIcon from '@material-ui/icons/Description';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import { nanoid } from 'nanoid';
import { FormStepLocation } from '../../../@Types/FormTypes/Form';
import LocationTypes from '../../../constants/LocationTypes';
import React from 'react';
import IconTypes from '../../../constants/IconTypes';
import { Entity } from '../../../@Types/@Types';
import { createCBRFormStep } from './Construction/CBRStepMenu/CBRStepMenu';
import CBRFormStepTypes from '../../../constants/Construction/CBRFormStepTypes';
import { CBRFormStep } from '../../../@Types/FormTypes/CBRFormStep';
import ScheduleRounded from '@material-ui/icons/ScheduleRounded';

interface StepMenuMapperProps {
    type: FormStepTypes;
    first?: boolean;
    handleAdd: (step: FormStep) => void;
    location: FormStepLocation; //Null if main create btn
}

export default function StepMenuMapper({
    type,
    first = false,
    handleAdd,
    location,
}: StepMenuMapperProps): JSX.Element {
    const calcInfo = (): JSX.Element => {
        switch (type) {
            case FormStepTypes.TITLE:
                return (
                    <React.Fragment>
                        <div className={styles.titleIcon}>
                            <TitleIcon fontSize="inherit" />
                        </div>
                        <div className={styles.titleOptionLabel}>Título</div>
                    </React.Fragment>
                );
            case FormStepTypes.TEXTINPUT:
                return (
                    <React.Fragment>
                        <div className={styles.textInputContainer}>A..</div>
                        <div className={styles.optionLabel}>Campo de Texto</div>
                    </React.Fragment>
                );
            case FormStepTypes.SELECTOR:
                return (
                    <React.Fragment>
                        <div className={styles.selectInputContainer}>
                            <ArrowDropDownIcon fontSize="inherit" />
                        </div>
                        <div className={styles.optionLabel}>Opciones</div>
                    </React.Fragment>
                );
            case FormStepTypes.CLASSIFIER_SELECTOR:
                return (
                    <React.Fragment>
                        <div className={styles.selectInputContainer}>
                            <img
                                className={styles.shapesIconContainer}
                                alt={'shapes'}
                                src={'/media/icons/shapes.svg'}
                            />
                            <ArrowDropDownIcon fontSize="inherit" />
                        </div>
                        <div className={styles.optionLabel}>Clasificador</div>
                    </React.Fragment>
                );
            case FormStepTypes.DATEPICKER:
                return (
                    <React.Fragment>
                        <div className={styles.dateInputContainer}>
                            <CalendarTodayRoundedIcon fontSize="inherit" />
                        </div>
                        <div className={styles.optionLabel}>Campo de Fecha</div>
                    </React.Fragment>
                );
            case FormStepTypes.TIMEPICKER:
                return (
                    <React.Fragment>
                        <div className={styles.dateInputContainer}>
                            <ScheduleRounded fontSize="inherit" />
                        </div>
                        <div className={styles.optionLabel}>
                            Campo de Tiempo
                        </div>
                    </React.Fragment>
                );
            case FormStepTypes.AGENTPICKER:
                return (
                    <React.Fragment>
                        <div className={styles.agentInputContainer}>
                            <PersonRoundedIcon fontSize="inherit" />
                        </div>
                        <div className={styles.optionLabel}>
                            Agente (Interno)
                        </div>
                    </React.Fragment>
                );
            case FormStepTypes.TEXTAREA:
                return (
                    <React.Fragment>
                        <div className={styles.textAreaContainer}>
                            <SubjectIcon fontSize="inherit" />
                        </div>
                        <div className={styles.optionLabel}>Mensaje</div>
                    </React.Fragment>
                );
            case FormStepTypes.FILEUPLOAD:
                return (
                    <React.Fragment>
                        <div className={styles.fileInputContainer}>
                            <DescriptionIcon fontSize="inherit" />
                        </div>
                        <div className={styles.optionLabel}>Subir Archivos</div>
                    </React.Fragment>
                );
            case FormStepTypes.CHECKBOX:
                return (
                    <React.Fragment>
                        <div className={styles.checkBoxIcon}>
                            <CheckBoxRoundedIcon fontSize="inherit" />
                        </div>
                        <div className={styles.optionLabel}>CheckBox</div>
                    </React.Fragment>
                );
            case FormStepTypes.SEPARATOR:
                return (
                    <React.Fragment>
                        <div className={styles.separatorContainer}>
                            <NavigateBeforeRoundedIcon fontSize="inherit" />
                            <div className={styles.separatorSeparator}></div>
                            <NavigateNextRoundedIcon fontSize="inherit" />
                        </div>

                        <div className={styles.optionLabel}>Separador</div>
                    </React.Fragment>
                );
            default:
                return <div></div>;
        }
    };
    return (
        <div
            className={styles.option}
            style={first ? { borderTop: 'none' } : {}}
            onClick={(): void => {
                handleAdd(createFormStep(type, location) as FormStep);
            }}
        >
            {calcInfo()}
        </div>
    );
}

export function createFormStep(
    type: FormStepTypes | CBRFormStepTypes,
    location: FormStepLocation | null,
    entity?: Entity,
    id = type + '-' + nanoid(10)
): FormStep | CBRFormStep {
    const baseStep = {
        id,
    };
    if (location?.type === LocationTypes.SECTION) {
        (baseStep as any).maxSize = 1;
    }

    switch (type) {
        case FormStepTypes.TITLE:
            return {
                ...baseStep,
                type,
                title: 'Título',
                description: '',
            };
        case FormStepTypes.TEXTINPUT:
            return {
                ...baseStep,
                type,
                label: 'Campo',
                description: '',
                required: false,
                isSubject: false,
                size: 2,
            };
        case FormStepTypes.SELECTOR:
            return {
                ...baseStep,
                type,
                label: 'Opciones',
                description: '',
                required: false,
                isSubject: false,
                searchable: false,
                options: [],
                size: 1,
            };
        case FormStepTypes.CLASSIFIER_SELECTOR:
            return {
                ...baseStep,
                type,
                idClassifier: null,
                label: '',
                description: '',
                required: false,
                isSubject: false,
                searchable: false,
                size: 1,
                options: {},
            };

        case FormStepTypes.DATEPICKER:
            return {
                ...baseStep,
                type,
                label: 'Fecha',
                description: '',
                pickTime: false,
                required: false,
                size: 1,
            };
        case FormStepTypes.TIMEPICKER:
            return {
                ...baseStep,
                type,
                label: 'Tiempo',
                description: '',
                pickDays: true,
                pickHours: true,
                pickMinutes: true,
                required: false,
                size: 1,
            };

        case FormStepTypes.AGENTPICKER:
            return {
                ...baseStep,
                type,
                label: 'Agente',
                description: '',
                required: false,
                multiple: false,
                size: 1,
            };

        case FormStepTypes.TEXTAREA:
            return {
                ...baseStep,
                type,
                label: 'Mensaje',
                description: '',
                required: false,
                isPrimary: false,
                hasTextEditor: false,
            };

        case FormStepTypes.FILEUPLOAD:
            return {
                ...baseStep,
                type,
                label: 'Adjuntar Archivos',
                description: '',
                required: false,
            };

        case FormStepTypes.CHECKBOX:
            return {
                ...baseStep,
                type,
                label: 'Campo',
                description: '',
                required: false,
                size: 1,
            };

        case FormStepTypes.SEPARATOR:
            return {
                ...baseStep,
                type,
                transparent: false,
            };
        case FormStepTypes.ENTITYVALUEPICKER:
            if (!entity) throw Error();
            return {
                ...baseStep,
                type,
                label: entity.name,
                icon: entity.icon ?? IconTypes.GENERIC,
                searchable: true,
                showIcon: true,
                description: entity.description,
                idEntity: entity._id,
                required: false,
                filters: [],
                path: [],
                options: {},
                size: 1,
            };
        default:
            if (type.startsWith('CBR_')) {
                return createCBRFormStep(type as CBRFormStepTypes, id);
            }
            console.error('Couldnt create step of type ' + type);
            throw Error();
    }
}
