import { cloneElement, useState, useEffect, CSSProperties } from 'react';
import styles from './ListDetailLayout.module.css';
import ArrowBack from '@material-ui/icons/ArrowBack';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Detail from './Detail/Detail';
import RoundedIconButton from '../RoundedIconButton/RoundedIconButton';
import React from 'react';

interface ListDetailLayout {
    /** determines the desired width of the left component */
    listWidth: number;
    /** determines the screen width at which the component turns into mobile mode */
    responsiveBreakpoint: number;
    /** Function called when element is clicked on */
    handleSelectedElement: Function;
    /** If on first load the detail should be open. */
    detailOnFirstLoad?: boolean;
    /** Label to display when no element is selected */
    selectElementLabel: string;
    /** Object of the currently selected element, will be handed down to the children */
    selectedElement: any;
    /** The first child is rendered on the List Section
     *  The second chid is rendered on the Detail Section*/
    children: any;
    /** If loader should be shown */
    loading: boolean;
    /** If the detail has padding and scroll */
    detailPaddingAndScroll?: boolean;
    /** Header element to display ontop of the list */
    listHeaderInfo?: () => JSX.Element;
    /** If the detail component wants to controll the return button in mobile*/
    controlledReturn?: boolean;
}

/**
 * Component that has 2 sections:
 * The left one (List Section) maintains a constant width or gets teh full component width on the mobile version
 * The right one (Detail Section) maintains takes as much width as posible
 *
 * @children The first child is rendered on the List Section
 * @children The second chid is rendered on the Detail Section
 */
function ListDetailLayout({
    loading,
    children,
    listWidth,
    listHeaderInfo,
    selectedElement,
    controlledReturn,
    selectElementLabel,
    detailOnFirstLoad = false,
    responsiveBreakpoint,
    handleSelectedElement,
    detailPaddingAndScroll = true,
}: ListDetailLayout): JSX.Element {
    // Defines if the detail card is shown
    // This only applies on the mobile version ( smaller than the responsive breackpoint prop )
    const [showDetail, setShowDetail] = useState(
        detailOnFirstLoad && window.innerWidth < responsiveBreakpoint
    );
    const [firstLoad, setFirstLoad] = useState(true);

    // True if the current screen size is smaller than the responsiveBreackpoint prop
    const responsive = useMediaQuery(`(max-width:${responsiveBreakpoint}px)`, {
        noSsr: true,
    });
    const xsm = useMediaQuery('(max-width:576px)', {
        noSsr: true,
    });
    const mobileApp = useMediaQuery('(max-width:768px)', {
        noSsr: true,
    });

    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
        } else {
            if (!selectedElement) {
                setShowDetail(false);
            } else {
                setShowDetail(true);
            }
        }
    }, [selectedElement]);

    useEffect(() => {
        if (responsive && !loading && showDetail && !selectedElement) {
            setShowDetail(false);
        }
    }, [loading]);
    // Calculates the width of the List Section
    const calculateListWidth = (): string => {
        if (responsive) {
            if (showDetail) {
                return '0px';
            } else {
                return '100%';
            }
        } else {
            return `${listWidth}px`;
        }
    };

    // Calculates the minWidth of the List Section content
    // this is done to maintain the list section content on a constant position during the animation
    const calculateListContentWidth = (): string => {
        if (responsive) {
            if (xsm) {
                return '100vw';
            } else if (mobileApp) {
                return 'calc(100vw - 25px)';
            } else {
                return 'calc(100vw - 85px)';
            }
        } else {
            return listWidth - 25 + 'px';
        }
    };

    // Calculates the minWidth of the Detail Section content
    // this is done to maintain the detail section content on a constant position during the animation
    const calculateDetailContentMinWidth = (): string => {
        if (responsive) {
            if (mobileApp) {
                return '100vw';
            } else {
                return 'calc(100vw - 85px)';
            }
        } else {
            return '100%';
        }
    };

    const calcRightPadding = (): number => {
        if (responsive) {
            if (xsm) {
                return 6;
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    };

    const calcLeftPadding = (): number => {
        if (responsive) {
            if (xsm) {
                return 10;
            } else if (mobileApp) {
                return 25;
            } else {
                return 25;
            }
        } else {
            return 25;
        }
    };

    // This handler is called when an element of the list (in the first child) is selected
    const handleItemClick = (
        pSelectedElement: any,
        showdetail = true
    ): void => {
        setShowDetail(showdetail);
        if (
            pSelectedElement?._id !== undefined &&
            pSelectedElement?._id === selectedElement?._id
        ) {
            handleSelectedElement(undefined);
        } else {
            handleSelectedElement(pSelectedElement);
        }
    };

    // This handler is called when the return to list button is clicked on the detail section
    const handleReturnToList = (): void => {
        setShowDetail(false);
        handleSelectedElement(undefined);
    };

    // This returns the style of the detail card depending on the size of the screen and the need for padding
    const calcDetailStyle = (): CSSProperties => {
        const style: CSSProperties = {
            width: calculateDetailContentMinWidth(),
            height: 'calc(100% - 10px)',
        };
        if (xsm && listHeaderInfo !== undefined) {
            style.margin = 0;
        } else if (!responsive || mobileApp) {
            style.margin = '10px 0px 0px 0px';
        }
        if (xsm && !detailPaddingAndScroll) {
            style.height = '100%';
        }
        return style;
    };

    return (
        <React.Fragment>
            {!xsm && listHeaderInfo !== undefined && listHeaderInfo()}
            <div className={styles.layoutContainer + ' flexbox'}>
                <div
                    style={{
                        width: calculateListWidth(),
                        transition: mobileApp ? 'none' : 'width 0.35s ease-out',
                    }}
                    className={styles.listContainer}
                >
                    {xsm && listHeaderInfo !== undefined && (
                        <div className={styles.xsmHeadersContainer}>
                            {listHeaderInfo()}
                        </div>
                    )}
                    <div
                        style={
                            xsm && listHeaderInfo
                                ? {
                                      width: calculateListContentWidth(),
                                      flexGrow: 1,
                                      minHeight: 0,
                                  }
                                : {
                                      width: calculateListContentWidth(),
                                  }
                        }
                        className={styles.listContent}
                    >
                        {cloneElement(children[0], {
                            loading,
                            handleItemClick,
                            paddingLeft: calcLeftPadding(),
                            paddingRight: calcRightPadding(),
                            selectedElement,
                            layoutIsMobile: responsive,
                        })}
                    </div>
                </div>
                <div
                    style={{
                        width: `calc(100% - ${calculateListWidth()})`,
                        transition: mobileApp ? 'none' : 'width 0.35s ease-out',
                    }}
                    className={styles.detailContainer}
                >
                    <div
                        style={calcDetailStyle()}
                        className={styles.detailCard}
                    >
                        {((responsive && showDetail && !mobileApp) ||
                            (mobileApp && showDetail && !controlledReturn)) && (
                            <div className={styles.returnButton}>
                                <RoundedIconButton
                                    backgroundColor="var(--secondary)"
                                    color="white"
                                    onClick={handleReturnToList}
                                >
                                    <ArrowBack />
                                </RoundedIconButton>
                            </div>
                        )}
                        <Detail
                            loading={loading}
                            label={selectElementLabel}
                            selectedElement={selectedElement}
                            paddingAndScroll={detailPaddingAndScroll}
                            xsm={xsm}
                        >
                            {cloneElement(children[1], {
                                selectedElement: selectedElement,
                                layoutIsMobile: responsive,
                                handleReturnToList: controlledReturn
                                    ? handleReturnToList
                                    : undefined,
                            })}
                        </Detail>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export default ListDetailLayout;
