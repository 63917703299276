export enum IntegrationTypes {
    CONDITION = 'CONDITION',
    DOCUMENT = 'DOCUMENT',
    DRAFT = 'DRAFT',
    ENTITY_VALUES_FILTER = 'ENTITY_VALUES_FILTER',
}
export enum IntegrationConfigurationTypes {
    API = 'API',
    LAMBDA = 'LAMBDA',
}

export default IntegrationTypes;
