import { Entity, EntityValue } from '../../@Types/@Types';
import { EntityValueClient, FullEntityValue } from '../../@Types/EntityValue';
import axiosInstance from '../../AxiosAPI';
import {
    EntityValueFilters,
    entityValuesToQueryString,
} from './EntityValuesReducer';

export const loadEntityValuesByQuery = async (
    entity: Entity,
    queryString: string,
    idEntityValue?: string
): Promise<{
    elements: EntityValue[];
    page: number;
    selectedElement?: number;
    filters: {};
    orderBy: string;
}> => {
    const query = new URLSearchParams(queryString);
    let url = `/entities/${entity._id}/entityValues/load${
        idEntityValue ? '/' + idEntityValue : ''
    }?${query}`;

    const resp = await axiosInstance.get(url);
    return resp.data;
};

/**
 * Function that loads the entityValues' list
 * @param idEntity the id of the entity
 * @param page currently viewing
 * @param pageSize to request
 * @param filters currently active
 * @param orderBy method ordered by, undefined if search is active
 * @param search search query, undefined if no search is active
 * @returns array of EntityValues that match the inputs
 */
export const loadEntityValues = async (
    entity: Entity,
    page: number,
    pageSize: number,
    filters: EntityValueFilters | undefined,
    orderBy?: string | undefined,
    search?: string | undefined
): Promise<EntityValue[]> => {
    let url = `/entities/${entity._id}/entityValues?page=${page}&pageSize=${pageSize}`;
    url += '&' + entityValuesToQueryString(entity, filters, orderBy, search);
    const resp = await axiosInstance.get(url);
    return resp.data;
};

/**
 * Function called to handle the conexion with the server to create an entityValue
 * @param idEntity the id of the entity
 * @param entityValue Record of values to create the entityValue with
 */
export const createEntityValue = async (
    idEntity: string,
    entityValue: Partial<EntityValue>
): Promise<void> => {
    await axiosInstance.post(`/entities/${idEntity}/entityValues`, entityValue);
};

/**
 * Function that handles the async update of an entityValue's value
 * @param idEntity the id of the entity
 * @param idEntityValue of the entityValue to update
 * @param entityValue Record of values to update
 */
export const updateEntityValue = async (
    idEntity: string,
    idEntityValue: string,
    entityValue: Partial<EntityValue>
): Promise<void> => {
    await axiosInstance.patch(
        `/entities/${idEntity}/entityValues/${idEntityValue}`,
        entityValue
    );
};

/**
 * Function that fetches a single entityValue given its id
 * @param idEntity the id of the entity
 * @param idEntityValue the id of the entityValue to fetch
 * @returns ticket
 */
export const loadEntityValueById = async (
    idEntity: string,
    idEntityValue: string
): Promise<EntityValue> => {
    const response = await axiosInstance.get(
        `/entities/${idEntity}/entityValues/${idEntityValue}`
    );
    return response.data;
};

/**
 * Function that loads the entityValues by a given list of ids
 * @param idEntity the id of the entity
 * @param entityValues the list of ids
 */
export const loadEntityValuesById = async (
    idEntity: string,
    entityValues: string[]
): Promise<FullEntityValue[]> => {
    if (entityValues.length === 0) return [];
    const response = await axiosInstance.get(
        `/entities/${idEntity}/entityValues?id=${entityValues.join(',')}`
    );
    return response.data;
};

/**
 * Function called to handle the conexion with the server to delete a entityValue
 * @param idEntity the id of the entity
 * @param idEntityValue the id of the entityValue to delete
 */
export const deleteEntityValue = async (
    idEntity: string,
    idEntityValue: string
): Promise<void> => {
    await axiosInstance.delete(
        `/entities/${idEntity}/entityValues/${idEntityValue}`
    );
};

/**
 * Function called to handle the conexion with the server to fetch the clients of a entityValue
 * @param idEntity the id of the entity
 * @param idEntityValue the id of the entityValue to fetch
 */
export const loadEntityValueClients = async (
    idEntity: string,
    idEntityValue: string
): Promise<EntityValueClient[]> => {
    const response = await axiosInstance.get(
        `/entities/${idEntity}/entityValues/${idEntityValue}/clients`
    );
    return response.data;
};

/**
 * Function called to handle the conexion with the server to update a clients entityValue permission
 * @param idEntity the id of the entity
 * @param idEntityValue the id of the entityValue to update
 * @param idClient the id of the client to update
 * @param permissions the new permissions of the client
 */
export const updateEntityValueClientPermissions = async (
    idEntity: string,
    idEntityValue: string,
    idClient: string,
    permissions: Record<string, boolean>
): Promise<void> => {
    await axiosInstance.patch(
        `/entities/${idEntity}/entityValues/${idEntityValue}/clients/${idClient}`,
        permissions
    );
};

/**
 * Function called to handle the conexion with the server to remove a client from a entityValue
 * @param idEntity the id of the entity
 * @param idEntityValue the id of the entityValue to update
 * @param idClient the id of the client to remove
 */
export const removeEntityValueClient = async (
    idEntity: string,
    idEntityValue: string,
    idClient: string
): Promise<void> => {
    await axiosInstance.delete(
        `/entities/${idEntity}/entityValues/${idEntityValue}/clients/${idClient}`
    );
};

/**
 * Function called to handle the conexion with the server to add a client to a entityValue
 * @param idEntity the id of the entity
 * @param idEntityValue the id of the entityValue to update
 * @param idClient the id of the client to add
 */
export const addEntityValueClient = async (
    idEntity: string,
    idEntityValue: string,
    idClient: string
): Promise<void> => {
    await axiosInstance.post(
        `/entities/${idEntity}/entityValues/${idEntityValue}/clients/${idClient}`
    );
};

export default {
    loadEntityValues,
    createEntityValue,
    updateEntityValue,
    loadEntityValueById,
    loadEntityValuesById,
    deleteEntityValue,
    loadEntityValueClients,
    addEntityValueClient,
    removeEntityValueClient,
    updateEntityValueClientPermissions,
};
