import axiosInstance from '../../../AxiosAPI';
import { Entity } from '../../../@Types/@Types';

/**
 *  Function that loads the entities of the organization
 * @returns entities of the org
 */
export const loadEntities = async (): Promise<Record<string, Entity>> => {
    let response = await axiosInstance.get('/organization/entities');
    let entities: Record<string, Entity> = {};
    if (response.data && Array.isArray(response.data)) {
        for (const entity of response.data) {
            if (entity.size === undefined) {
                entity.size = {
                    spacingSize: entity.spacingSize ?? 20,
                    blockNum: entity.blockNum ?? 3,
                    blockSize: entity.blockSize ?? 210,
                };
            }
            entities[entity._id] = entity;
        }
    }
    return entities;
};

export default {
    loadEntities,
};
