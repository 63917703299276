import React from 'react';
import styles from './WhatsappTemplateMessageButton.module.css';
import { WhatsappTemplateButton } from '../../../../@Types/WhatsappTemplate';
import { WhatsappTemplateButtonTypes } from '../../../../constants/WhatsappTemplates/WhatsappTemplateButtonTypes';
import LaunchIcon from '../../../../Icons/LaunchIcon';
import ReplyIcon from '../../../../Icons/ReplyIcon';

interface WhatsappTemplateMessageButtonProps {
    button: WhatsappTemplateButton;
}

function WhatsappTemplateMessageButton({
    button,
}: WhatsappTemplateMessageButtonProps): JSX.Element {
    return (
        <div className={styles.messageBtn}>
            {button.type === WhatsappTemplateButtonTypes.QUICK_REPLY ? (
                <ReplyIcon
                    style={{ width: 20, height: 20, marginTop: -2 }}
                    fill="var(--accent)"
                />
            ) : (
                <LaunchIcon
                    style={{ width: 20, height: 20, marginTop: 1 }}
                    fill="var(--accent)"
                />
            )}
            {button.text ? button.text : '(Por Definir)'}
        </div>
    );
}

export default WhatsappTemplateMessageButton;
