import { useCallback, useRef, useState } from 'react';
import { cloneElement } from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import Loader from '../Loader/Loader';
import styles from './Dialog.module.css';
import { makeStyles } from '@material-ui/core';
interface CustomDialogProps
    extends Omit<DialogProps, 'maxWidth' | 'open' | 'onClose'> {
    /** Function to handle close action */
    onClose: (event?: MouseEvent) => void;
    /** If dialog is open */
    open?: boolean;
    /** children to render */
    children?: React.ReactElement<any, string>;
    // If the dialog has embedded elements
    disableEnforceFocus?: boolean;
    /** The border of the dialog */
    border?: number;
    /** The maxwidth of the dialog */
    maxWidth?: number | string;
    /** The max height of the dialog */
    maxHeight?: number | string;
    /** If the dialog should show the loader */
    showLoader?: boolean;
    /** If the container is transparent */
    transparent?: boolean;
    zIndex?: number;
}

const useStyles = (zIndex: number): Function =>
    makeStyles(() => ({
        root: {
            zIndex: (zIndex + ' !important') as any,
        },
    }));
/**
 * Generic Dialog Component with embedded loader and multiple options
 */
function CustomDialog({
    onClose,
    open = true,
    children,
    border = 2,
    maxWidth = 600,
    maxHeight = '90vh',
    disableEnforceFocus = false,
    transparent = false,
    showLoader = true,
    zIndex = 1300,
}: CustomDialogProps): JSX.Element {
    const [loading, setLoading] = useState(false);

    const ref = useRef<HTMLDivElement>(null);
    const dialogClasses = useStyles(zIndex)();

    const scrollToTop = useCallback((): void => {
        if (ref.current) {
            ref.current.scrollTop = 0;
        }
    }, []);

    return (
        <Dialog
            disableEnforceFocus={disableEnforceFocus}
            classes={dialogClasses}
            PaperProps={{
                style: {
                    backgroundColor: transparent ? 'transparent' : 'white',
                    boxShadow: transparent ? 'none' : 'inherit',
                    borderRadius: border,
                    maxWidth,
                    margin: 10,
                    maxHeight,
                },
            }}
            onClose={!loading ? (onClose as any) : undefined}
            open={open}
        >
            <div className={styles.container} ref={ref}>
                <div
                    data-id="close-dialog"
                    className={styles.close}
                    onClick={!loading ? (onClose as any) : undefined}
                ></div>
                {loading && showLoader && (
                    <Loader curtain={true} color={'var(--secondary)'} />
                )}
                {children &&
                    cloneElement(
                        children,
                        children.type !== 'div'
                            ? {
                                  loading,
                                  setLoading,
                                  scrollToTop,
                                  onClose,
                              }
                            : {}
                    )}
            </div>
        </Dialog>
    );
}

export default CustomDialog;
