import styles from './ClientEntityValue.module.css';
import Avatar from '../../../Avatar/Avatar';
import { useSelector } from 'react-redux';
import Toggle from '../../../Toggle/Toggle';
import { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import React from 'react';
import { Client, EntityValue } from '../../../../@Types/@Types';
import { RootState } from '../../../../utils/_store';
import { updateEntityValueClientPermissions } from '../../../../controllers/EntityValuesController/EntityValuesService';
import produce from 'immer';
import EntityIcon from '../../../SmartIcons/EntityIcon';
import { Link } from 'react-router-dom';
import RoundedButton from '../../../RoundedButton/RoundedButton';
interface ClientEntityValeuProps {
    /** The ClientInfo to display */
    client: Client;
    /** The entityValue to display */
    entityValue: EntityValue;
    handleUpdate: Function;
    handleDelete?: Function;
}
function ClientEntityValueComponent({
    client,
    entityValue,
    handleUpdate,
    handleDelete,
}: ClientEntityValeuProps): JSX.Element {
    const entity = useSelector(
        (state: RootState) => state.site.entities[entityValue.idEntity]
    );
    const [showPermissions, setShowPermissions] = useState(false);

    const clientEntityValue = client.entities[entityValue.idEntity]?.find(
        (e) => e.idEntityValue === entityValue._id
    );

    const nPermissions = Object.values(
        clientEntityValue?.permissions ?? {}
    ).filter((p) => p === true).length;
    return (
        <React.Fragment>
            <div className={styles.container}>
                <div className={styles.cardContainer}>
                    <div className={styles.sideBar}></div>
                    <div className={styles.info}>
                        <h4 className={styles.title}>
                            {entityValue.values[entity.idLabelStep] ??
                                entity.name}
                        </h4>
                        <div className={styles.viewMoreBtn}>
                            <Link
                                to={`/entities/${entityValue.idEntity}/${entityValue._id}`}
                                className="app-link"
                            >
                                <RoundedButton
                                    text={'Ver más'}
                                    backgroundColor={'var(--secondary)'}
                                    color={'white'}
                                    onClick={(): void => {}}
                                    padding="0px 10px"
                                />
                            </Link>
                        </div>
                    </div>
                    <div className="flexbox">
                        <div className={styles.photo}>
                            <Avatar
                                lbl={
                                    entityValue.values[entity.idLabelStep] ??
                                    '?'
                                }
                                size={50}
                                fontSize={30}
                            >
                                <EntityIcon
                                    idEntity={entity._id}
                                    fill="var(--primary1)"
                                    style={{
                                        height: 35,
                                        width: 35,
                                    }}
                                />
                            </Avatar>
                        </div>
                    </div>
                    {handleDelete && (
                        <div
                            className={styles.removeBtn}
                            onClick={(): void => {
                                handleDelete();
                            }}
                        >
                            <ClearRoundedIcon fontSize="inherit" />
                        </div>
                    )}
                </div>
                {showPermissions && entity.permissions && (
                    <div className={styles.permissionsContainer}>
                        {entity.permissions.map((permission, index) => (
                            <div
                                className={styles.permission + ' flexbox'}
                                key={index}
                            >
                                <div
                                    className={
                                        styles.permissionLbl + ' noselect'
                                    }
                                >
                                    {permission.name}
                                </div>
                                <div className={styles.toggleContainer}>
                                    <Toggle
                                        size="small"
                                        checked={
                                            clientEntityValue?.permissions[
                                                permission.id
                                            ] === true
                                        }
                                        onChange={(checked: boolean): void => {
                                            handleUpdate(
                                                produce(client, (client) => {
                                                    const clientEntityValue =
                                                        client.entities[
                                                            entityValue.idEntity
                                                        ]?.find(
                                                            (e) =>
                                                                e.idEntityValue ===
                                                                entityValue._id
                                                        );
                                                    if (clientEntityValue) {
                                                        clientEntityValue.permissions[
                                                            permission.id
                                                        ] = checked;
                                                    }
                                                })
                                            );
                                            updateEntityValueClientPermissions(
                                                entity._id,
                                                entityValue._id,
                                                client._id,
                                                {
                                                    ...clientEntityValue?.permissions,
                                                    [permission.id]: checked,
                                                }
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {entity.permissions && entity.permissions.length > 0 && (
                    <div className={styles.permissionsTabContainer}>
                        <div
                            className={styles.permissionsTab}
                            onClick={(): void => {
                                setShowPermissions(!showPermissions);
                            }}
                        >
                            Permisos
                            <div className={styles.countLbl}>
                                {nPermissions}
                            </div>
                            <div className={styles.permissionsIcon}>
                                {showPermissions ? (
                                    <ExpandLessIcon fontSize="inherit" />
                                ) : (
                                    <ExpandMoreIcon fontSize="inherit" />
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

export default ClientEntityValueComponent;
