import styles from './Element.module.css';
import Avatar from '../../../Avatar/Avatar';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import { useRef } from 'react';

interface ElementComponentProps<Type> {
    /** Function to call element label */
    calcLbl: (element: Type) => string;
    /** Function to calc element Icon  */
    calcIcon?: (element: Type) => JSX.Element;
    /** Function to calc if element was deleted */
    calcIsDeleted?: (element: Type) => boolean;
    /** Element to display */
    element: Type;
    /** Function to handle when element is clicked on */
    handleClick: Function;
    /** If the current element is the first one, to display the border-top */
    first: boolean;
    /** If the current element is selected */
    selected: boolean;
    /** If multiple agents can be selected */
    multiple: boolean;
    /** Function called to view the selected element */
    handleView?: () => void;
    /** Function called to config the selected element */
    handleConfig?: () => void;
    /** Function used to get the element's unique key */
    calcId: (element: Type) => string;
}
/**
 * Component used to display non-selected companies in the assign tab.
 */
function ElementComponent<Type>({
    calcLbl,
    calcIcon,
    calcIsDeleted,
    element,
    multiple,
    handleClick,
    first,
    selected,
    handleView,
    handleConfig,
}: ElementComponentProps<Type>): JSX.Element {
    const configRef = useRef<HTMLDivElement>(null);
    const viewRef = useRef<HTMLDivElement>(null);
    if (element) {
        return (
            <div
                className={styles.container}
                style={{
                    borderTop: first ? 'none' : '1px solid var(--light-grey)',
                    backgroundColor: selected ? 'var(--greyHover)' : '',
                }}
                onClick={(event): void => {
                    if (
                        !configRef.current?.contains(event.target as any) &&
                        !viewRef.current?.contains(event.target as any)
                    ) {
                        handleClick();
                    }
                }}
            >
                <div
                    className={styles.avatarContainer}
                    style={{ marginLeft: multiple ? 30 : 10 }}
                >
                    {calcIcon ? (
                        calcIcon(element)
                    ) : (
                        <Avatar lbl={calcLbl(element)} toolTip={false} />
                    )}
                </div>
                <div
                    className={styles.lblContainer}
                    style={{
                        fontWeight: selected ? 700 : 500,
                        textDecoration: calcIsDeleted?.(element)
                            ? 'line-through'
                            : 'none',
                    }}
                >
                    <label className={styles.nameLbl} title={calcLbl(element)}>
                        {calcLbl(element)}
                    </label>
                </div>
                <div className={styles.iconsContainer}>
                    {handleConfig && selected && (
                        <div
                            className={styles.settingsIcon}
                            onClick={handleConfig}
                            ref={configRef}
                        >
                            <SettingsRoundedIcon fontSize="inherit" />
                        </div>
                    )}
                    {handleView && (
                        <div
                            className={styles.settingsIcon}
                            onClick={handleView}
                            ref={viewRef}
                        >
                            <VisibilityRoundedIcon fontSize="inherit" />
                        </div>
                    )}
                </div>
            </div>
        );
    }
    return <div></div>;
}

export default ElementComponent;
