import styles from './StateChangeDialog.module.css';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../utils/_store';
import RoundedButton from '../../../../../../shared/RoundedButton/RoundedButton';
import ActionsService from '../../../../../../controllers/TicketsController/Actions/ActionsService';
import { State } from '../../../../../../@Types/State';
import { Ticket } from '../../../../../../@Types/TicketTypes/Ticket';
import VanillaToasts from '../../../../../../shared/Toast/Toast';
import {
    refreshCurrent,
    refreshTicket,
} from '../../../../../../controllers/TicketsController/TicketsActions';
import StateTypes from '../../../../../../constants/StateTypes';

interface StateChangeDialogProps {
    selectedElement: Ticket;
    state: State;
    setLoading?: Function;
    onClose?: Function;
    loading?: boolean;
    onConfirm: Function;
}
function StateChangeDialog({
    selectedElement,
    onConfirm,
    state,
    onClose,
    loading,
    setLoading,
}: StateChangeDialogProps): JSX.Element {
    const dispatch = useDispatch();
    const siteInfo = useSelector((state: RootState) => state.site);
    const calcMessage = (): string | JSX.Element => {
        if (state?.type === StateTypes.CLOSED) {
            return '¿Estás seguro que quieres cerrar este caso?';
        } else if (selectedElement.state?.type === StateTypes.CLOSED) {
            return '¿Estás seguro que quieres reabrir este caso?';
        } else {
            return (
                <label>
                    ¿Estás seguro que quieres cambiar el estado de este caso a{' '}
                    <span className={styles.stateSpan}>{state?.label}</span>?
                </label>
            );
        }
    };
    return (
        <React.Fragment>
            <h3 className="center-anything">
                Confirmar{' '}
                {state?.type === StateTypes.CLOSED ? 'Cierre' : 'Cambio'}
            </h3>
            <div className={styles.message}>{calcMessage()}</div>
            <div className={styles.btnContainer}>
                <RoundedButton
                    fontSize={15}
                    padding={'0 10px 0 10px'}
                    text="Cancelar"
                    backgroundColor="var(--greyBtn)"
                    onClick={(): void => {
                        onClose?.();
                    }}
                />
                <div
                    className={styles.confirmBtn}
                    data-testid="StateChangeDialog_confirmBtn"
                >
                    <RoundedButton
                        fontSize={15}
                        padding={'0 10px 0 10px'}
                        text={
                            state?.type === StateTypes.CLOSED
                                ? 'Cerrar'
                                : 'Cambiar'
                        }
                        backgroundColor="var(--secondary)"
                        color="white"
                        onClick={async (): Promise<void> => {
                            if (setLoading && siteInfo.idProject) {
                                try {
                                    setLoading(true);
                                    await ActionsService.changeState(
                                        siteInfo.idProject,
                                        selectedElement._id,
                                        state._id
                                    );
                                    dispatch(refreshCurrent(true));
                                    if (selectedElement.father?._id) {
                                        dispatch(
                                            refreshTicket(
                                                selectedElement.father?._id
                                            )
                                        );
                                    }
                                    onConfirm();
                                } catch (error) {
                                    VanillaToasts.create({
                                        title:
                                            (error as any).response.data ??
                                            'Error al cambiar el estado',
                                        text: error,
                                        type: 'error',
                                        timeout: 3000,
                                    });

                                    setLoading(false);
                                }
                            }
                        }}
                        disabled={loading}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}
export default StateChangeDialog;
