import DeepEqual from 'deep-equal';
export type KeysOfUnion<T> = T extends T ? keyof T : never;

export function compareArrays<T>(
    actual: T[] | undefined,
    expected: T[] | undefined,
    comparator: (o1: T, o2: T) => boolean
): boolean {
    if (!actual || !expected) return true;
    if (!!actual !== !!expected) return false;
    for (let i = 0; i < actual.length; i += 1) {
        if (!comparator(actual[i], expected[i])) return false;
    }
    return true;
}

export function compareRecord<T>(
    actual: Record<string, T> | undefined,
    expected: Record<string, T> | undefined,
    comparator: (o1: T, o2: T) => boolean
): boolean {
    if (!actual || !expected) return true;
    if (!!actual !== !!expected) return false;
    for (const key in expected) {
        if (!comparator(actual[key], expected[key])) return false;
    }
    return true;
}

export function deepCompare<T>(
    actual: any,
    expected: T,
    omits?: KeysOfUnion<T>[]
): boolean {
    if (omits && omits.length > 0) {
        const actualCopy = { ...actual };
        const expectedCopy = { ...expected };
        omits.forEach((omit) => {
            delete actualCopy[omit];
            delete expectedCopy[omit];
        });
        return DeepEqual(actualCopy, expectedCopy);
    } else {
        return DeepEqual(actual, expected);
    }
}
