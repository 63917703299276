import { Survey } from '../../../@Types/@Types';
import styles from './SurveyDetail.module.css';
import React from 'react';
import EditContainer from '../../../shared/EditContainer/EditContainer';
import RoundedButton from '../../../shared/RoundedButton/RoundedButton';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
interface SurveyDetailProps {
    /** The selected Survey */
    selectedElement?: Survey;
    /** Function called when an agent's value needs updating */
    handleUpdate: Function;
}
function SurveyDetail({
    selectedElement,
    handleUpdate,
    history,
}: SurveyDetailProps & RouteComponentProps): JSX.Element {
    if (!selectedElement) {
        return <div></div>;
    }

    const siteInfo = useSelector((state: RootState) => state.site);
    return (
        <div className={styles.containerDetail}>
            <div className={styles.titleContainer}>
                <div className={styles.title}>
                    <EditContainer
                        object={selectedElement}
                        field={'name'}
                        handleConfirm={handleUpdate}
                    >
                        <div className={styles.titlelbl}>
                            {selectedElement.name}
                        </div>
                        <input
                            className={styles.nameInput + ' edit-input'}
                            defaultValue={selectedElement.name}
                            type="text"
                            size={10}
                        />
                    </EditContainer>
                    <div></div>
                </div>
            </div>
            <div className={styles.descriptionLabel}>Descripción</div>
            <div className={styles.descriptionContainer}>
                <EditContainer
                    object={selectedElement}
                    field={'description'}
                    handleConfirm={handleUpdate}
                >
                    <div className={styles.description}>
                        {selectedElement.description}
                    </div>
                    <textarea
                        className={styles.descriptionInput + ' edit-input'}
                        defaultValue={selectedElement.description}
                    />
                </EditContainer>
            </div>
            <div className={styles.surveyBtn}>
                <RoundedButton
                    text="Editar Encuesta"
                    backgroundColor="var(--secondary)"
                    color="white"
                    onClick={(): void => {
                        history.push(
                            `/${siteInfo.idProject}/surveys/${selectedElement._id}/edit`
                        );
                    }}
                />
            </div>
        </div>
    );
}
export default withRouter(SurveyDetail);
