import styles from './CompanyDetail.module.css';

import React, { useEffect, useRef, useState } from 'react';
import { Company } from '../../../@Types/Company';
import EntityRenderer from '../../../shared/EntityRenderer/EntityRenderer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import { refreshCurrent } from '../../../controllers/CompaniesController/CompaniesActions';
import CompaniesService, {
    loadCompanyClients,
    updateCompany,
} from '../../../controllers/CompaniesController/CompaniesService';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import SincoQueries from '../../../custom/Sinco/Queries/Queries';
import { OrganizationPermissions } from '../../../constants/Permissions';
import { checkOrgPerm } from '../../../utils/PermissionsFunctions';
import { CompanyClient } from '../../../@Types/Company';
import CompanyClientComponent from './CompanyClientComponent/CompanyClientComponent';
import Loader from '../../../shared/Loader/Loader';
import RoundedButton from '../../../shared/RoundedButton/RoundedButton';
import ConfirmDialog from '../../../shared/ConfirmDialog/ConfirmDialog';
import ClientsMenu from '../../../shared/@Menus/ClientsMenu/ClientsMenu';
import { Client } from '../../../@Types/Client';
interface CompanyDetailProps {
    /** The selected Entry */
    selectedElement?: Company;
    /** Function that handles deleting the current classifier */
    handleClickDelete?: Function;
}
function CompanyDetail({
    selectedElement,
    handleClickDelete,
}: CompanyDetailProps): JSX.Element {
    const user = useSelector((state: RootState) => state.site.user);
    const organization = useSelector(
        (state: RootState) => state.site.organization
    );
    const entities = useSelector((state: RootState) => state.site.entities);
    const [clients, setClients] = useState<CompanyClient[] | null>(null);
    const [showAdd, setShowAdd] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState<string | undefined>(
        undefined
    );

    const addBtnRef = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();

    async function loadClients(): Promise<void> {
        if (selectedElement?._id) {
            setClients(null);
            const clients = await loadCompanyClients(selectedElement?._id);
            setClients(clients);
        }
    }

    useEffect(() => {
        loadClients();
    }, [selectedElement?._id]);

    if (!selectedElement) {
        return <div></div>;
    }
    const entity = entities[selectedElement.idEntity];
    if (!entity) {
        return <div></div>;
    }
    return (
        <React.Fragment>
            {deleteDialog !== undefined && (
                <ConfirmDialog
                    onConfirm={async (
                        element: string,
                        setLoading: Function
                    ): Promise<void> => {
                        try {
                            setLoading(true);
                            await CompaniesService.removeCompanyClient(
                                selectedElement?._id,
                                deleteDialog
                            );
                            loadClients();
                            setDeleteDialog(undefined);
                        } catch (error) {
                            //TODO: handle errors
                            console.error(error);
                            setLoading(false);
                        }
                    }}
                    element={deleteDialog}
                    title="Desvincular Cliente"
                    btnMsg="Desvincular"
                    deleting
                    msg="¿Está seguro de que desea desvincular el cliente de esta empresa?"
                    onClose={(): void => {
                        setDeleteDialog(undefined);
                    }}
                />
            )}
            {showAdd && (
                <ClientsMenu
                    anchorRef={addBtnRef}
                    handleClose={(): void => {
                        setShowAdd(false);
                    }}
                    placement="bottom-end"
                    zIndex={1301}
                    marginTop={'-5px'}
                    multiple={false}
                    handleConfirm={async (client: Client): Promise<void> => {
                        try {
                            setShowAdd(false);
                            setClients(null);
                            await CompaniesService.addCompanyClient(
                                selectedElement?._id,
                                client._id
                            );
                            loadClients();
                        } catch (error) {
                            //TODO: handle errors
                            console.error(error);
                        }
                    }}
                />
            )}
            <div className={styles.container} key={selectedElement._id}>
                <div
                    className={styles.entityContainer}
                    style={{
                        width:
                            (entity.size.blockSize + entity.size.spacingSize) *
                            entity.size.blockNum,
                    }}
                >
                    <div className={styles.btnsContainer}>
                        {handleClickDelete !== undefined && (
                            <div
                                className={styles.delBtn + ' grey-btn'}
                                onClick={(): void => {
                                    handleClickDelete(selectedElement);
                                }}
                            >
                                <DeleteRoundedIcon
                                    style={{ fill: 'black' }}
                                    fontSize="inherit"
                                />
                            </div>
                        )}
                        <SincoQueries company={selectedElement} />
                    </div>
                    <EntityRenderer
                        entity={entity}
                        internal
                        customSend={async (company): Promise<void> => {
                            await updateCompany(selectedElement._id, {
                                values: company.values,
                            });
                            dispatch(refreshCurrent());
                        }}
                        editable={checkOrgPerm(
                            user,
                            organization,
                            OrganizationPermissions.EDIT_COMPANIES
                        )}
                        entityValue={{ ...selectedElement, relationships: {} }}
                    />
                </div>
                <div className={styles.clientsContainer}>
                    <div className={styles.clientsLbl}>
                        Clientes:
                        <div className={styles.addBtnContainer} ref={addBtnRef}>
                            <RoundedButton
                                text="Agregar Cliente"
                                backgroundColor={'var(--secondary)'}
                                color={'white'}
                                onClick={(): void => {
                                    setShowAdd(true);
                                }}
                                padding="2px 13px"
                            />
                        </div>
                    </div>
                    <div className={styles.clientsListContainer + ' flexbox'}>
                        {!clients && (
                            <div className={styles.loaderContainer}>
                                <Loader size={50} />
                            </div>
                        )}
                        {clients && clients.length === 0 && (
                            <div className={styles.emptyLbl}>
                                Empresa sin clientes
                            </div>
                        )}
                        {clients &&
                            clients.map((client, index) => (
                                <CompanyClientComponent
                                    client={client}
                                    idCompany={selectedElement._id}
                                    key={index}
                                    handleUpdate={(client: any): void => {
                                        const tempClients = [...clients];
                                        tempClients[index] = client;
                                        setClients(tempClients);
                                    }}
                                    handleDelete={(): void => {
                                        setDeleteDialog(client._id);
                                    }}
                                />
                            ))}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export default CompanyDetail;
