import React, { JSX, useEffect, useRef, useState } from 'react';
import styles from './NotificationsMenu.module.css';
import { Button, IconButton } from '@material-ui/core';
import List from '../../../List/List';
import {
    NotificationFilterOption,
    renderFilterIcon,
} from '../NotificationFilterOptions/NotificationFilterOption';
import NotificationListItem from './NotificationListItem/NotificationListItem';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../utils/_store';
import {
    pageScroll,
    reset,
} from '../../../../controllers/NotificationsMenuController/NotificationsMenuActions';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';

export interface NotificationsMenuProps {
    isPage?: boolean;
}

export function NotificationsMenu({
    isPage,
}: NotificationsMenuProps): JSX.Element {
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const listRef = useRef<HTMLDivElement>(null);
    const filterRef = useRef(null);
    const stateInfo = useSelector(
        (state: RootState) => state.NotificationsMenu
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(reset({ filterState: stateInfo.filterState }));
    }, []);

    return (
        <div className={styles.container}>
            <div
                className={styles.menu}
                style={{
                    width: isPage ? 'calc(100% - 30px)' : '340px',
                    height: isPage ? '100%' : '400px',
                    backgroundColor: isPage ? 'transparent' : 'var(--primary1)',
                    border: isPage ? 'none' : '1px solid var(--primary2)',
                    borderRadius: isPage ? '0' : '5px',
                    boxShadow: isPage ? 'none' : '0 0 5px 0 rgba(0,0,0,0.2)',
                }}
            >
                <div className={styles.header}>
                    <div className={styles.headerTitle}>Notificaciones</div>
                    <div className={styles.headerButtons}>
                        <div className={styles.refreshIconContainer}>
                            {stateInfo.needReset && !stateInfo.loading && (
                                <div className={styles.notificationIcon}>!</div>
                            )}
                            <IconButton
                                disabled={stateInfo.loading}
                                aria-label="Notifications"
                                style={{
                                    height: 26,
                                    padding: 0,
                                    minWidth: 26,
                                    marginTop: -3,
                                }}
                                className={styles.refreshIconBorder}
                                onClick={(): void => {
                                    dispatch(
                                        reset({
                                            filterState: stateInfo.filterState,
                                        })
                                    );
                                }}
                            >
                                <RefreshRoundedIcon
                                    className={styles.refreshIcon}
                                    style={{
                                        width: 24,
                                        height: 24,
                                        transform: 'rotate(-60deg)',
                                    }}
                                />
                            </IconButton>
                        </div>
                        <div className={styles.filterIcon} ref={filterRef}>
                            <Button
                                aria-label="Notifications"
                                style={{
                                    height: 24,
                                    padding: 0,
                                    minWidth: 24,
                                    marginTop: -3,
                                }}
                                onClick={(): void => {
                                    setOpenFilter(!openFilter);
                                }}
                            >
                                {renderFilterIcon(
                                    stateInfo.filterState,
                                    false,
                                    24
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={styles.list}>
                    <List
                        elements={stateInfo.elements}
                        lastPage={stateInfo.noMoreElements}
                        listRef={listRef}
                        onPageScroll={(): void => {
                            dispatch(pageScroll());
                        }}
                        loading={stateInfo.loading}
                        label="No tienes notificaciones"
                        paddingLeft={0}
                        paddingRight={0}
                        paddingBottom={10}
                        width={'100%'}
                    >
                        <NotificationListItem />
                    </List>
                </div>
                {openFilter && (
                    <NotificationFilterOption
                        filterState={stateInfo.filterState}
                        setFilterState={(filterState): void => {
                            if (
                                filterState !== stateInfo.filterState ||
                                stateInfo.needReset
                            ) {
                                dispatch(reset({ filterState }));
                            }
                        }}
                        handleClose={(): void => {
                            setOpenFilter(false);
                        }}
                        filterRef={filterRef}
                    />
                )}
            </div>
        </div>
    );
}
