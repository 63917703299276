import { Area } from '../../../../../@Types/@Types';
import styles from './SubAreaGridElement.module.css';
import ApartmentRoundedIcon from '@material-ui/icons/ApartmentRounded';
function SubAreaGridElement({ element }: { element: Area }): JSX.Element {
    return (
        <div className={styles.container}>
            <div className={styles.iconContainer}>
                <ApartmentRoundedIcon fontSize="inherit" />
            </div>
            <div className={styles.label}>{element.name}</div>
        </div>
    );
}

export default SubAreaGridElement;
