import React, { useEffect, useRef, useState } from 'react';
import ListDetailLayout from '../../shared/ListDetailLayout/ListDetailLayout';
import PageTitle from '../../shared/PageTitle/PageTitle';
import List from '../../shared/List/List';
import EntityDetail from './EntityDetail/EntityDetail';
import EntityListItem from './EntityListItem/EntityListItem';
import Search from '../../shared/Search/Search';
import { useSelector, useDispatch } from 'react-redux';
import {
    reset,
    selectElement,
    updateElement,
    search,
    setLoading,
} from '../../controllers/EntitiesController/EntitiesActions';
import { RootState } from '../../utils/_store';
import { Entity } from '../../@Types/@Types';
import Dialog from '../../shared/Dialog/Dialog';
import CreateEntity from './CreateEntity/CreateEntity';
import RoundedButton from '../../shared/RoundedButton/RoundedButton';

function EntitiesPage(): JSX.Element {
    const dispatch = useDispatch();
    const pageInfo = useSelector((state: RootState) => state.entitiesPage);
    const organization = useSelector(
        (state: RootState) => state.site.organization
    );
    const [createDialog, setCreateDialog] = useState(false);
    const listRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        dispatch(reset());
        if (listRef.current) listRef.current.scrollTop = 0;
    }, []);
    return (
        <React.Fragment>
            {createDialog && (
                <Dialog
                    open={createDialog}
                    onClose={(): void => setCreateDialog(false)}
                >
                    <CreateEntity
                        handleCreated={(): void => {
                            dispatch(reset());
                            if (listRef.current) listRef.current.scrollTop = 0;
                        }}
                        close={(): void => setCreateDialog(false)}
                    />
                </Dialog>
            )}
            <div className="standard-layout">
                <PageTitle
                    title={'Entidades'}
                    route={organization?.name + ' / Entidades'}
                />
                <div className="filters-container flexbox">
                    <RoundedButton
                        text="Nueva"
                        backgroundColor={'var(--secondary)'}
                        color={'white'}
                        onClick={(): void => {
                            setCreateDialog(true);
                        }}
                    />

                    <div className="search-container">
                        <Search
                            search={pageInfo.search}
                            handleLoadingSearch={(loading: boolean): void => {
                                dispatch(setLoading(loading));
                            }}
                            handleSearch={(value: string): void => {
                                /** Refresh the list and send it back to the top */
                                if (listRef.current)
                                    listRef.current.scrollTop = 0;
                                dispatch(search(value));
                            }}
                        />
                    </div>
                </div>

                <ListDetailLayout
                    listWidth={450}
                    responsiveBreakpoint={1000}
                    selectElementLabel="Selecciona una Entidad"
                    loading={pageInfo.loading}
                    selectedElement={
                        pageInfo.selectedElement !== undefined
                            ? pageInfo.elements[pageInfo.selectedElement]
                            : undefined
                    }
                    handleSelectedElement={(element?: Entity): void => {
                        const index = pageInfo.elements.findIndex(
                            (listElement) => listElement._id === element?._id
                        );
                        dispatch(selectElement(index));
                    }}
                >
                    <List
                        elements={pageInfo.elements}
                        lastPage={true}
                        listRef={listRef}
                        label="No hay Entidades"
                    >
                        <EntityListItem />
                    </List>
                    <EntityDetail
                        handleUpdate={(
                            object: Entity,
                            field: keyof Entity,
                            value: string
                        ): void => {
                            dispatch(updateElement({ object, field, value }));
                        }}
                    />
                </ListDetailLayout>
            </div>
        </React.Fragment>
    );
}

export default EntitiesPage;
