import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select, { SelectProps } from '@material-ui/core/Select';
import { MenuItem, OutlinedInput } from '@material-ui/core';
import { State } from '../../../../@Types/State';
import styles from './StatesFilters.module.css';
import StatesMenu from '../../../../shared/@Menus/StatesMenu/StatesMenu';
const useStyles = (props: { hasData: boolean }): Function =>
    makeStyles(() => ({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        formControl: {
            minWidth: 85,
            '& .Erk-MuiFormLabel-root': {
                transform: 'none',
                fontSize: '12px',
                marginTop: '5px',
                marginLeft: '12px',
                color: 'var(--dark-grey)',
                display: props.hasData ? 'none' : 'default',
            },
        },
        selectEmpty: {
            // marginTop: theme.spacing(2),
        },
    }));

const useOutlinedInputStyles = (props: { hasData: boolean }): Function =>
    makeStyles({
        root: {
            height: '22px',
            fontSize: 12,
            '& $notchedOutline': {
                borderColor: 'var(--secondary2)',
                borderWidth: 2,
            },
            '&:hover $notchedOutline': {
                borderColor: 'var(--secondary2)',
            },
            '& .Erk-MuiOutlinedInput-inputMarginDense': {
                paddingBottom: 4,
                paddingTop: 4,
                borderRadius: 4,
                backgroundColor: props.hasData
                    ? 'var(--secondary2)'
                    : 'transparent',
            },
            '&$focused $notchedOutline': {
                borderColor: 'var(--secondary2)',
            },
            color: 'var(--accent)',
        },
        focused: {},
        notchedOutline: {},
    });

const useLabelInputStyles = makeStyles(() => ({
    root: {
        // marginTop: props.height != '40px' ? '-4px' : '0px',
        // '&.Erk-MuiInputLabel-shrink': {
        //     marginTop: '0px',
        // },
        // '& .Mui-error': {
        //     color: props.errorColor,
        // },
        // '&.Mui-error': {
        //     color: props.errorColor,
        // },
    },
}));

const useMenuStyles = makeStyles(() => ({
    root: {
        '& .Erk-MuiPaper-root': {
            display: 'none',
        },
    },
}));

interface RoundedSelectProps extends SelectProps {
    /** Currently selected value */
    values: State[];
    /** function called when value changes */
    handleUpdate: Function;
    /** The label to display */
    label: string;
    /** The possible States to select from*/
    states: Record<string, State>;
}

function CustomSelect({
    label,
    values,
    handleUpdate,
    states,
    ...others
}: RoundedSelectProps): JSX.Element {
    const classes = useStyles({ hasData: values.length > 0 })();
    const outlinedInputClasses = useOutlinedInputStyles({
        hasData: values.length > 0,
    })();
    const labelClasses = useLabelInputStyles();
    const menuClasses = useMenuStyles();
    const [showMenu, setShowMenu] = React.useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);

    return (
        <React.Fragment>
            {showMenu && (
                <StatesMenu
                    multiple
                    anchorRef={anchorRef}
                    states={Object.values(states)
                        .filter((state) => state.active !== false)
                        .sort((a, b) => a.order - b.order)
                        .map((state) => state)}
                    onClose={(): void => {
                        setShowMenu(false);
                    }}
                    handleUpdate={handleUpdate}
                    selectedStates={values}
                />
            )}
            <FormControl
                variant="outlined"
                className={classes.formControl}
                size="small"
            >
                <InputLabel classes={labelClasses} shrink={false}>
                    {label}
                </InputLabel>
                <Select
                    ref={anchorRef}
                    {...others}
                    renderValue={(): React.ReactNode => {
                        if (values.length > 0) {
                            return (
                                <div className={styles.valuesContainer}>
                                    Estado: {values[0].label}
                                    {values.length > 1 ? (
                                        <span className={styles.boldSpan}>
                                            {' +' +
                                                (values.length - 1).toString()}
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            );
                        } else {
                            return <div></div>;
                        }
                    }}
                    value={values.length > 0 ? 0 : ''}
                    autoWidth
                    MenuProps={{
                        getContentAnchorEl: (): any => null,
                        PopoverClasses: menuClasses,
                    }}
                    onClose={(): void => {
                        setShowMenu(false);
                    }}
                    onOpen={(): void => {
                        setShowMenu(true);
                    }}
                    input={<OutlinedInput classes={outlinedInputClasses} />}
                >
                    <MenuItem value={0}></MenuItem>
                </Select>
            </FormControl>
        </React.Fragment>
    );
}
export default CustomSelect;
