import styles from './PageTitle.module.css';
interface PageProps {
    /** If the title can be edited by the user */
    editable?: boolean;
    /** Route to the title */
    route: string;
    /** Title Label */
    title: string;
    responsiveWidthBreakpoint?: number;
    /** If the title should always be displayed */
    fixed?: boolean;
    /** Function called to handle name change */
    handleUpdate?: (value: string) => void;
    /** If loading */
    loading?: boolean;
}
/**
 * Page title component used to display the user's location on the site.
 */
function PageTitle({
    route,
    title,
    fixed = false,
    loading = false,
}: PageProps): JSX.Element {
    return (
        <div
            className={fixed ? styles.fixedContainer : styles.container}
            style={loading ? { visibility: 'hidden' } : {}}
        >
            <label className={fixed ? styles.fixedRoute : styles.route}>
                {route}
            </label>
            <h3 className={fixed ? styles.fixedTitle : styles.title}>
                {title}
            </h3>
        </div>
    );
}

export default PageTitle;
