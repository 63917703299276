import styles from './AgentsFilters.module.css';
import React, { useRef } from 'react';
import Search from '../../../../shared/Search/Search';
import { Agent } from '../../../../@Types/@Types';
import {
    search,
    setLoading,
} from '../../../../controllers/AgentsMenuController/AgentsActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../utils/_store';
import AgentsList from '../../../../shared/@Menus/AgentsMenu/AgentsList';

interface AgentsFiltersProps {
    /** The ids of the initially selected Agents */
    initialAgents: Agent[];
    /** Function called when a agent is clicked on  */
    handleChange: Function;
    /** Tabs component to display after the search */
    tabsComponent: JSX.Element;
}
function AgentsFilters({
    handleChange,
    initialAgents,
    tabsComponent,
}: AgentsFiltersProps): JSX.Element {
    const dispatch = useDispatch();
    const menuInfo = useSelector((state: RootState) => state.agentsMenu);
    const listRef = useRef<HTMLDivElement>(null);

    return (
        <React.Fragment>
            <div className={styles.searchContainer}>
                <Search
                    wait={500}
                    placeholder={'Buscar Agentes'}
                    search={menuInfo.search}
                    handleLoadingSearch={(loading: boolean): void => {
                        dispatch(setLoading(loading));
                    }}
                    handleSearch={(value: any): void => {
                        if (listRef.current) listRef.current.scrollTop = 0;
                        dispatch(search(value));
                    }}
                />
            </div>
            {tabsComponent}
            <div className={styles.listContainer}>
                <AgentsList
                    listRef={listRef}
                    handleChange={handleChange}
                    initialAgents={initialAgents}
                    filterAgents={[]}
                    multiple={true}
                    projectFilter={false}
                    showAreaFilters={false}
                />
            </div>
        </React.Fragment>
    );
}

export default AgentsFilters;
