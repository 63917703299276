import { MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Ticket } from '../../../../../../@Types/@Types';
import FileIcon from '../../../../../../Icons/FileIcon';
import { ForwardedRef, forwardRef, useState } from 'react';
import TicketsService from '../../../../../../controllers/TicketsController/TicketsService';
import { fetchDownloadUrl } from '../../../../../../controllers/FilesController/FilesService';
import Loader from '../../../../../../shared/Loader/Loader';
import styles from './MoreActionsMenu.module.css';

interface TicketSummaryProps {
    ticket: Ticket;
    handleClose: () => void;
    handleDisableClose: () => void;
    menuRef?: ForwardedRef<HTMLLIElement>;
}

function TicketSummary({
    ticket,
    menuRef,
    handleClose,
    handleDisableClose,
}: TicketSummaryProps): JSX.Element {
    const [loading, setLoading] = useState(false);

    const handleSummary = async (): Promise<void> => {
        setLoading(true);
        handleDisableClose();

        try {
            let fileUrl;
            const file = await TicketsService.getSummary(
                ticket.idProject,
                ticket._id
            );
            fileUrl = await fetchDownloadUrl(file.S3Key, file.fileName);
            var a = document.createElement('a');
            a.href = fileUrl;
            a.setAttribute('download', file.fileName);
            a.click();
        } catch (error) {
            console.error(error);
        }

        setLoading(false);
        handleClose();
    };

    return (
        <MenuItem
            onClick={(): void => {
                handleSummary();
            }}
            key="TRACEBTN"
            ref={menuRef}
        >
            <ListItemIcon style={{ minWidth: 35 }}>
                {loading && (
                    <div className={styles.loaderContainer}>
                        <Loader size={22} color={'var(--secondary)'} />
                    </div>
                )}
                {!loading && (
                    <FileIcon
                        fill={'var(--secondary)'}
                        style={{
                            width: '22px',
                            height: '22px',
                        }}
                    />
                )}
            </ListItemIcon>
            <ListItemText primary="Generar Resumen" />
        </MenuItem>
    );
}

export default forwardRef<HTMLLIElement, TicketSummaryProps>((props, ref) => (
    <TicketSummary {...props} menuRef={ref} />
));
