import { useState } from 'react';
import RoundedTextField from '../../shared/RoundedTextField/RoundedTextField';
import PayloadEditor, {
    BasePayloadEditorConditionTypes,
} from '../../shared/TextEditor/PayloadEditor';
import { Payload } from '../../@Types/Payload';
import RoundedButton from '../../shared/RoundedButton/RoundedButton';
import { nanoid } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '../../utils/_store';
import styles from './DraftEditor.module.css';
import { PayloadEditorActions } from '../../controllers/PayloadEditorController/PayloadEditorSlice';
import { useDispatch } from 'react-redux';

const Conditions = {
    types: [...BasePayloadEditorConditionTypes],
};

function DraftEditor(): JSX.Element {
    const user = useSelector((state: RootState) => state.site.user);
    const [config, setConfig] = useState<{
        idEditor: string;
        payload?: Payload;
    }>({ idEditor: 'DraftEditor' });
    const [text, setText] = useState('');

    if (user?.email !== 'andres@capta.co') return <></>;
    const dispatch = useDispatch();
    return (
        <div className={styles.container}>
            <RoundedTextField
                value={text}
                onChange={(e): void => {
                    setText(e.target.value);
                }}
                multiline
                minRows={5}
                maxRows={10}
            />
            <RoundedButton
                text="Cargar datos"
                onClick={(): void => {
                    let draft = undefined;
                    try {
                        draft = JSON.parse(text);
                        // eslint-disable-next-line no-empty
                    } catch (error) {}

                    setConfig({
                        idEditor: nanoid(),
                        payload: draft
                            ? {
                                  draft,
                              }
                            : undefined,
                    });
                }}
            />
            <PayloadEditor
                idEditor={config.idEditor}
                placeholder={''}
                payload={config.payload}
                autoFocus
                context={{
                    ticket: true,
                    agent: true,
                    client: true,
                }}
                conditions={Conditions}
            />
            <RoundedButton
                text="Console Log"
                onClick={async (): Promise<void> => {
                    const payload: Payload | null = await dispatch(
                        PayloadEditorActions.calcPayload({
                            idEditor: config.idEditor,
                        }) as any
                    ).unwrap();
                    // eslint-disable-next-line no-console
                    console.log(payload);
                }}
            />
        </div>
    );
}

export default DraftEditor;
