import React, { JSX } from 'react';
import { MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styles from './NotificationsMobileOption.module.css';

export interface NotificationsMobileOptionProps {
    numNotifications: number;
    handleClose: () => void;
}

export default function NotificationsMobileOption({
    handleClose,
    numNotifications,
}: NotificationsMobileOptionProps): JSX.Element {
    return (
        <MenuItem onClick={handleClose}>
            <Link to="/notifications" className={styles.link + ' app-link'}>
                <div className={styles.text}>Notificaciones</div>
            </Link>
            {numNotifications > 0 && (
                <div className={styles.notificationIcon}>
                    {numNotifications > 99 ? '99+' : numNotifications}
                </div>
            )}
        </MenuItem>
    );
}
