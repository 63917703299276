import { EurekaFormProps } from '@arquimedes.co/eureka-forms';
import { useMemo } from 'react';
import { Client, Entity, EntityValue } from '../../@Types/@Types';
import Types from '../../constants/EntityPropertyTypes';
import FormRenderer from '../FormRenderer/FormRenderer';
import NameProperty from './NameProperty/NameProperty';
import EntityRelationshipPicker from './EntityRelationshipPickerStep/EntityRelationshipPickerStep';
import Relationship, { FullRelationship } from '../../@Types/Relationship';
import { EntityProperty } from '../../@Types/EntityTypes/EntityProperty';
import { EntityRelationship } from '../../@Types/EntityTypes/Entity';
import EntityPropertyTypes from '../../constants/EntityPropertyTypes';
import { MapperStyleTypes } from '@arquimedes.co/eureka-forms/dist/constants/FormStepTypes';
import { AppProps } from '@arquimedes.co/eureka-forms/dist/App/App';
import { calcValue } from '@arquimedes.co/eureka-forms/dist/Form/FormFunctions';
import { mapOriginalValue } from '@arquimedes.co/eureka-forms/dist/App/AppFunctions';
import { MapperElement } from '@arquimedes.co/eureka-forms/dist/@Types/MapperElement';
import { useSelector } from 'react-redux';
import { RootState } from '../../utils/_store';
import { CountryCode } from 'libphonenumber-js';

const defaultSize: Entity['size'] = {
    blockNum: 3,
    blockSize: 210,
    spacingSize: 20,
};

interface EntityRendererProps extends Omit<EurekaFormProps, 'valuesData'> {
    entity: Entity | EntityRelationship;
    entityValue?: EntityValue | Relationship;
    /** The id company to filter clients from */ //TODO: Hacerlo bien con los entityvaluePickers?
    idCompany?: string;
    customSend?: (
        entity: Pick<Relationship | EntityValue, 'values' | 'relationships'>,
        reload: Function
    ) => Promise<void>;
    creating?: boolean;
    customNameBtns?: JSX.Element;
    size?: Entity['size'];
}

function EntityRendererComponent({
    size = defaultSize,
    creating = false,
    idCompany,
    entityValue,
    customNameBtns,
    ...props
}: EntityRendererProps): JSX.Element {
    const idOrganization = useSelector(
        (state: RootState) => state.site.organization?.idOrganization!
    );
    const countryCode = useSelector(
        (state: RootState) => state.site.organization?.countryCode
    );

    const { originalValues, relationshipsValues, entity } = useMemo(() => {
        const entity: Entity | (EntityRelationship & { size: Entity['size'] }) =
            { size, ...props.entity };
        if (creating) {
            entity.steps = Object.values(entity.steps).reduce(
                (a, step) => ({
                    ...a,
                    [step.id]: { ...step, editable: true },
                }),
                {}
            );
        }
        const relationshipsValues = {};
        mapRelationships(
            { idOrganization, countryCode: countryCode as CountryCode },
            entity.steps,
            entityValue,
            relationshipsValues,
            entity.relationships
        );
        return {
            entity,
            relationshipsValues,
            originalValues: entityValue?.values ?? {},
        };
    }, [props.entity, entityValue]);
    const customSteps: AppProps['customSteps'] = {
        [Types.NAME]: {
            component: NameProperty as any,
            componentProps: { creating, children: customNameBtns },
        },
        [Types.CLIENTPICKER]: {
            component: (props: any) => <EntityRelationshipPicker {...props} />,
            calcValue: (step: EntityProperty, value: any): any => {
                if (step.type === Types.CLIENTPICKER) {
                    return value.map((val: Client) => val._id);
                }
            },
            componentProps: { idCompany },
        },
        [Types.ENTITY_RELATIONSHIP]: {
            component: (props: any) => <EntityRelationshipPicker {...props} />,
            componentProps: {
                entityValue,
            },
        },
    };

    return (
        <FormRenderer
            {...props}
            form={entity}
            rootSteps={entity.rootSteps}
            sendLabel="Guardar"
            values={originalValues}
            customSubmit={
                props.customSend
                    ? async (values, reload): Promise<void> => {
                          const value: Pick<
                              Relationship,
                              'values' | 'relationships'
                          > = { values };
                          const deleteIds: string[] = [];
                          if (entity.relationships) {
                              value.relationships = calcRelationships(
                                  entity.steps,
                                  entityValue,
                                  values,
                                  customSteps,
                                  entity.relationships,
                                  deleteIds
                              );
                          }
                          for (const id of deleteIds) {
                              delete values[id];
                          }
                          await props.customSend?.(value, reload);
                      }
                    : undefined
            }
            customStepProps={{
                path: [],
                entity,
                relationshipsValues,
            }}
            customSteps={customSteps}
        />
    );
}

export default EntityRendererComponent;

function mapRelationships(
    orgInfo: { idOrganization: string; countryCode: CountryCode },
    steps: Record<string, EntityProperty>,
    value: EntityValue | Relationship | undefined,
    relationshipsValues: Record<string, any>,
    relationships?: EntityRelationship[],
    path: string[] = []
): void {
    for (const relationship of relationships ?? []) {
        for (const idRelationshipStep of relationship.idRelationshipSteps) {
            const step = steps[idRelationshipStep];
            if (!step) continue;
            const idStep = [...path, step.id].join('-');
            const elements = value?.relationships?.[relationship.idEntity];
            if (
                !elements?.length ||
                step?.type !== EntityPropertyTypes.ENTITY_RELATIONSHIP
            )
                continue;
            if (
                Object.values(MapperStyleTypes).includes(
                    step.style?.type as MapperStyleTypes
                )
            ) {
                const mappedElements: MapperElement<Relationship>[] = [];
                for (const element of elements) {
                    const idElement =
                        element.idEntity + '-' + element.idEntityValue;
                    const mappedElement: MapperElement<Relationship> = {
                        id: idElement,
                        ids: {},
                        value: element,
                        originalValues: {},
                        isOriginal: false,
                    };

                    for (const subStep of Object.values(relationship.steps)) {
                        const idSub = subStep.id;
                        const newIdStep = [idStep, idElement, idSub].join('-');
                        mappedElement.ids[idSub] = newIdStep;
                        if (element.values[idSub] !== undefined) {
                            //TODO: Mejorar esto!
                            const tempStore = {
                                global: {},
                                sections: {
                                    temp: {},
                                },
                            };
                            const value = mapOriginalValue(
                                orgInfo,
                                {
                                    ...relationship.steps[idSub],
                                    idSection: 'temp',
                                } as any,
                                element.values[idSub],
                                tempStore
                            );
                            relationshipsValues = {
                                ...relationshipsValues,
                                ...tempStore.sections['temp'],
                            };
                            if (value !== undefined) {
                                relationshipsValues[newIdStep] = value;
                            }
                        }
                    }
                    mappedElements.push(mappedElement);
                    if (relationship.relationships) {
                        mapRelationships(
                            orgInfo,
                            relationship.steps,
                            element,
                            relationshipsValues,
                            relationship.relationships,
                            [...path, step.id, idElement]
                        );
                    }
                }
                relationshipsValues[idStep] = mappedElements;
            } else {
                relationshipsValues[idStep] = elements;
            }
        }
    }
}

function calcRelationships(
    steps: Record<string, EntityProperty>,
    value: EntityValue | Relationship | undefined,
    values: Record<string, any>,
    customSteps: Record<string, any>,
    relationships: EntityRelationship[],
    deleteIds: string[] = [],
    path: string[] = []
): Record<string, Relationship[]> {
    const relationshipsDict: Record<string, Relationship[]> = {};
    for (const relationship of relationships) {
        for (const idRelationshipStep of relationship.idRelationshipSteps) {
            const step = steps[idRelationshipStep];
            if (!step) continue;
            const idStep = [...path, step.id].join('-');
            if (step?.type !== EntityPropertyTypes.ENTITY_RELATIONSHIP)
                continue;
            deleteIds.push(idStep);
            if (
                Object.values(MapperStyleTypes).includes(
                    step.style?.type as MapperStyleTypes
                )
            ) {
                const elements: MapperElement<Relationship>[] = values[
                    idStep
                ]?.filter((element: any) => element.deleted !== true);
                const mappedValues: Relationship[] = [];
                for (const element of elements) {
                    const rel = element.value;
                    if (!rel) continue;
                    const newValues: Record<string, any> = {};
                    for (const [idStep, subStep] of Object.entries(
                        relationship.steps
                    )) {
                        if (
                            subStep.type ===
                            EntityPropertyTypes.ENTITY_RELATIONSHIP
                        )
                            continue;
                        const mappedId = element.ids[idStep];
                        if (values[mappedId] !== undefined) {
                            newValues[idStep] = calcValue(
                                idStep,
                                relationship.steps as any,
                                values,
                                customSteps,
                                deleteIds,
                                values[mappedId]
                            );
                            deleteIds.push(mappedId);
                        }
                    }
                    rel.values = newValues;
                    if (relationship.relationships) {
                        rel.relationships = calcRelationships(
                            relationship.steps,
                            rel,
                            values,
                            customSteps,
                            relationship.relationships,
                            deleteIds,
                            [...path, step.id, element.id]
                        );
                    }
                    mappedValues.push(rel);
                }
                relationshipsDict[relationship.idEntity] = mappedValues;
            } else if (values[idStep] !== undefined) {
                relationshipsDict[relationship.idEntity] = values[idStep].map(
                    (element: FullRelationship): Relationship => {
                        const relationship: Relationship = {
                            idEntity: element.idEntity,
                            values: element.values,
                            idEntityValue: element.idEntityValue,
                        };
                        if (element.relationships)
                            relationship.relationships = element.relationships;
                        return relationship;
                    }
                );
            }
        }
        if (
            relationship.idRelationshipSteps.length === 0 &&
            value?.relationships?.[relationship.idEntity] !== undefined
        ) {
            relationshipsDict[relationship.idEntity] =
                value.relationships[relationship.idEntity];
        }
    }
    return relationshipsDict;
}
