import styles from './CompanyListItem.module.css';
import Avatar from '../../../shared/Avatar/Avatar';
import { Company } from '../../../@Types/@Types';
interface CompanyListItemProps {
    /** The selected Company */
    selectedElement?: Company;
    /** The Company to display */
    element?: Company;
    /** Function called when the component is clicked */
    handleItemClick?: Function;
}
function CompanyListItem({
    selectedElement,
    element,
    handleItemClick,
}: CompanyListItemProps): JSX.Element {
    if (!element || !handleItemClick) {
        return <div></div>;
    }

    return (
        <div
            className={
                styles.container +
                (selectedElement?._id === element._id
                    ? ' selected-item-shadow'
                    : '')
            }
            onClick={(): void => handleItemClick(element, true)}
        >
            <div className={styles.sideBar}></div>
            <div className={styles.info}>
                <h4 className={styles.title}>
                    {element.values['name'] ?? 'Empresa'}
                </h4>
            </div>
            <div className="flexbox">
                <div className={styles.photo}>
                    <Avatar
                        lbl={element.values['name'] ?? 'Empresa'}
                        size={50}
                        fontSize={30}
                    ></Avatar>
                </div>
            </div>
        </div>
    );
}

export default CompanyListItem;
