import styles from './TextAreaStep.module.css';
import RoundedTextField from '../../RoundedTextField/RoundedTextField';
import Toggle from '../../Toggle/Toggle';
import React, { cloneElement } from 'react';
import TextEditor from '../../TextEditor/TextEditor';
import { GTextArea } from '../../../@Types/GenericFormSteps';
import { GenericStepProps } from '../../GenericFormEditor/Step/Step';
import { calcStepWidth } from '../../GenericFormEditor/StepFunctions';
import { GLocation } from '../../../@Types/FormTypes/LocationTypes';

interface TextAreaProps {
    /** children to render */
    children?: React.ReactElement<any, string>;
}

function TextAreaStep<
    StepType extends GTextArea,
    UniqueType,
    LocationType extends GLocation
>({
    size,
    step,
    editing,
    children,
    updateStep,
    uniqueSteps,
    updateUniqueSteps,
}: GenericStepProps<StepType, UniqueType, LocationType> &
    TextAreaProps): JSX.Element {
    if (editing) {
        return (
            <div className={styles.editingContainer}>
                <div className={styles.inputContainer}>
                    <RoundedTextField
                        label="Etiqueta/Título"
                        value={step.label}
                        onChange={(e): void => {
                            updateStep({ ...step, label: e.target.value });
                        }}
                    ></RoundedTextField>
                </div>
                <RoundedTextField
                    label="Descripción"
                    value={step.description ?? ''}
                    onChange={(e): void => {
                        updateStep({
                            ...step,
                            description: e.target.value,
                        });
                    }}
                ></RoundedTextField>
                <div className={styles.textEditorContainer}>
                    <div className={styles.textEditorLabel}>
                        Editor de Texto:
                    </div>
                    <div className={styles.toggleContainer}>
                        <Toggle
                            checked={step.hasTextEditor}
                            onChange={(checked: boolean): void => {
                                updateStep({
                                    ...step,
                                    hasTextEditor: checked,
                                });
                            }}
                        />
                    </div>
                </div>
                {children &&
                    cloneElement(children, {
                        step,
                        updateStep,
                        uniqueSteps,
                        updateUniqueSteps,
                    })}
            </div>
        );
    } else {
        if (step.hasTextEditor) {
            return (
                <div
                    style={{
                        maxWidth: '100%',
                        width: calcStepWidth(4, size),
                    }}
                >
                    {step.label && (
                        <div className={styles.textEditorPreviewLabel}>
                            {step.label} {step.required ? ' *' : ''}
                        </div>
                    )}
                    <div className={styles.textEditorPreviewContainer}>
                        <TextEditor placeholder={step.description ?? ''} />
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    style={{
                        width: calcStepWidth(4, size),
                        maxWidth: '100%',
                        marginBottom: step.description ? '0px' : '5px',
                    }}
                >
                    <RoundedTextField
                        label={step.label}
                        required={step.required}
                        multiline
                        minRows={4}
                        maxRows={6}
                        helperText={step.description}
                        fontWeight={400}
                        backgroundColor={'var(--primary1)'}
                    ></RoundedTextField>
                </div>
            );
        }
    }
}

export default React.memo(TextAreaStep, (prev, next) => {
    const { step, stepEqualityChecker } = prev;
    const { step: newStep } = next;
    if (
        step.label !== newStep.label ||
        step.required !== newStep.required ||
        step.description !== newStep.description ||
        step.hasTextEditor !== newStep.hasTextEditor
    ) {
        return false;
    }

    return stepEqualityChecker?.(prev, next) ?? true;
}) as typeof TextAreaStep;
