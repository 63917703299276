import React, { useEffect, useState, useRef } from 'react';
import ListDetailLayout from '../../shared/ListDetailLayout/ListDetailLayout';
import PageTitle from '../../shared/PageTitle/PageTitle';
import List from '../../shared/List/List';
import AgentDetail from './AgentDetail/AgentDetail';
import AgentListItem from './AgentListItem/AgentListItem';
import CreateAgent from './CreateAgent/CreateAgent';
import RoundedButton from '../../shared/RoundedButton/RoundedButton';
import Search from '../../shared/Search/Search';
import Dialog from '../../shared/Dialog/Dialog';
import { useSelector, useDispatch } from 'react-redux';
import {
    reset,
    pageScroll,
    selectElement,
    updateElement,
    search,
    setLoading,
    refresh,
    goTo,
} from '../../controllers/AgentsController/AgentsActions';
import { RootState } from '../../utils/_store';
import { Agent } from '../../@Types/Agent';
import { RouteComponentProps, withRouter, match } from 'react-router-dom';
import { agentsToQueryString } from '../../controllers/AgentsController/AgentsReducer';

interface IdAgentRouteParam {
    /** Current Ticket */
    idAgent?: string;
    /** The id of the currentProject */
}
interface AgentsPageProps extends RouteComponentProps {
    match: match<IdAgentRouteParam>;
}
function AgentsPage({
    history,
    location,
    match: {
        params: { idAgent },
    },
}: AgentsPageProps): JSX.Element {
    const dispatch = useDispatch();
    const pageInfo = useSelector((state: RootState) => state.agentsPage);
    const organization = useSelector(
        (state: RootState) => state.site.organization
    );
    const [createDialog, setCreateDialog] = useState(false);
    const listRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        dispatch(reset({ history, queryString: location.search, idAgent }));
        window.addEventListener('popstate', handleGoBack);
        return (): void => {
            window.removeEventListener('popstate', handleGoBack);
        };
    }, []);

    function handleGoBack(): void {
        const paths = window.location.pathname.split('/');
        if (paths[1] !== 'agents') return;
        const idAgent = paths[2];
        dispatch(
            goTo({ history, idAgent, queryString: window.location.search })
        );
        if (listRef.current) scrollToSelected(idAgent, listRef);
    }

    function scrollToSelected(selected: any, listRef: any): void {
        const selectedNode = listRef.current.querySelector(
            '.selected-item-shadow'
        );
        if (selected && selectedNode) {
            listRef.current.scrollTo(
                0,
                selectedNode.offsetTop - listRef.current.offsetTop - 10
            );
        } else {
            listRef.current.scrollTop = 0;
        }
    }

    const updateUrl = (
        filters: {},
        orderBy: string | undefined,
        search: string | undefined,
        type: 'FILTER' | 'SEARCH' | 'ORDER'
    ): void => {
        let url = '/agents/';
        const filtersUrl = agentsToQueryString(filters, orderBy, search, true);
        const urlPayload = {
            pathname: url,
            search: filtersUrl,
            state: { changeType: type },
        };
        if ((history.location?.state as any)?.changeType) {
            history.replace(urlPayload);
        } else {
            history.push(urlPayload);
        }
    };
    return (
        <React.Fragment>
            {createDialog && (
                <Dialog
                    open={createDialog}
                    onClose={(): void => setCreateDialog(false)}
                >
                    <CreateAgent
                        handleAgentCreated={(): void => {
                            // TODO: Cuando hayan filtros toca activar el de ordenar por fecha de creación para que el nuevo quede de primeras
                            //Es llamar un action que le ponga loading cambie filtros y vuelva a pedir todo.
                            dispatch(refresh());
                            if (listRef.current) listRef.current.scrollTop = 0;
                        }}
                    />
                </Dialog>
            )}
            <div className="standard-layout">
                <PageTitle
                    title={'Agentes'}
                    route={organization?.name + ' / Agentes'}
                />
                <div className="filters-container flexbox">
                    <div data-testid={'AgentsPage_NewButton'}>
                        <RoundedButton
                            text="Nuevo"
                            backgroundColor={'var(--secondary)'}
                            color={'white'}
                            onClick={(): void => {
                                setCreateDialog(true);
                            }}
                        />
                    </div>
                    <div className="search-container">
                        <Search
                            search={pageInfo.search}
                            handleLoadingSearch={(loading: boolean): void => {
                                dispatch(setLoading(loading));
                            }}
                            handleSearch={(value: string): void => {
                                /** Refresh the list and send it back to the top */
                                if (listRef.current)
                                    listRef.current.scrollTop = 0;
                                dispatch(search(value));
                                updateUrl(
                                    pageInfo.filters,
                                    pageInfo.orderBy,
                                    value,
                                    'SEARCH'
                                );
                            }}
                        />
                    </div>
                </div>
                <ListDetailLayout
                    listWidth={450}
                    responsiveBreakpoint={1000}
                    selectElementLabel="Selecciona un agente"
                    loading={pageInfo.loading}
                    selectedElement={
                        pageInfo.selectedElement !== undefined
                            ? pageInfo.elements[pageInfo.selectedElement]
                            : undefined
                    }
                    handleSelectedElement={(element?: Agent): void => {
                        const index = pageInfo.elements.findIndex(
                            (listElement) => listElement._id === element?._id
                        );
                        history.push({
                            pathname: `/agents${
                                element ? '/' + element._id : ''
                            }`,
                            search: history.location.search,
                            state: {
                                prevPath: history.location.pathname,
                            },
                        });
                        dispatch(selectElement(index));
                    }}
                >
                    <List
                        elements={pageInfo.elements}
                        firstPage={pageInfo.pageUp === 1}
                        lastPage={pageInfo.lastPage}
                        listRef={listRef}
                        listId={'agents-infiniteList'}
                        onPageScroll={(direction): void => {
                            dispatch(pageScroll(direction));
                        }}
                        firstFocusScroll={scrollToSelected}
                        label="No hay agentes"
                    >
                        <AgentListItem />
                    </List>
                    <AgentDetail
                        showPermissions={true}
                        handleUpdate={(
                            object: Agent,
                            field: keyof Agent,
                            value: string
                        ): void => {
                            dispatch(updateElement({ object, field, value }));
                        }}
                    />
                </ListDetailLayout>
            </div>
        </React.Fragment>
    );
}

export default withRouter((props: any) => <AgentsPage {...props} />);
