import styles from './FiltersMenu.module.css';
import React, { useState, useRef } from 'react';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Classifier } from '../../../@Types/@Types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import { TicketFilters } from '../../../controllers/TicketsController/TicketsReducer';
import ClassifiersFilters from './ClassifiersFilters/ClassifiersFilters';
import { TicketClassifier } from '../../../@Types/TicketTypes/Ticket';
import CompaniesFilters from './CompaniesFilters/CompaniesFilters';
import OtherFilters from './OtherFilters/OtherFilters';
import AgentsFilters from './AgentsFilters/AgentsFilters';
import StatesFilters from './StatesFilters/StatesFilters';
import { State } from '../../../@Types/State';
import Dialog from '../../../shared/Dialog/Dialog';
import { countOtherFilters } from './TicketFilters';
import EntityFilters from './EntityFilters/EntityFilters';
import { useCurrentProject } from '../../../hooks';

enum TABS {
    CLASSIFIERS = 'CLASSIFIERS',
    AGENTS = 'AGENTS',
    COMPANIES = 'COMPANIES',
    OTHERS = 'OTHERS',
}

/** Helper interface to optimize the render when a user is searching something */
export interface SearchClassifier extends Classifier {
    /** If the classifier has a child that has the term being searched */
    hasSearchInChild?: boolean;
    /** If the classifier has the term being serched */
    hasSearch?: boolean;
}

/**
 * Object that stores the values currently being classified
 */
export interface ClassifierObj {
    /** root of the classifier currently bieng classified */
    idRoot: string;
    /** Currently classified value */
    idValue: string | null;
    /** parent of the currently displayed classifiers */
    current: string;
}
interface FiltersMenuProps {
    /** Element the menu is anchored to (classifiers button in the sidebar) */
    anchorRef: any;
    /** Function called when the menu closes */
    handleClose: Function;
    /** Function called when classifiers change */
    handleChange: (filters: TicketFilters) => void;
    /** The currently selected classifiers */
    filters: TicketFilters;
    /** If the layout is mobile */
    layoutIsMobile?: boolean;
}
function FiltersMenu({
    anchorRef,
    handleClose,
    layoutIsMobile,
    filters,
    handleChange,
}: FiltersMenuProps): JSX.Element {
    const [selectedTab, setSelectedTab] = useState<TABS>(TABS.CLASSIFIERS);
    const entities = useSelector((state: RootState) => state.site.entities);
    const project = useCurrentProject();
    const emptyRef = useRef<HTMLDivElement>(null);

    const renderSelectedTab = (): JSX.Element => {
        switch (selectedTab) {
            case TABS.CLASSIFIERS:
                return (
                    <ClassifiersFilters
                        filters={filters.classifiers}
                        handleChange={(
                            classifiers: TicketClassifier[]
                        ): void => {
                            handleChange({ ...filters, classifiers });
                        }}
                        tabsComponent={
                            <TabsComponent
                                selectedTab={selectedTab}
                                filters={filters}
                                setSelectedTab={setSelectedTab}
                            />
                        }
                    />
                );
            case TABS.COMPANIES:
                return (
                    <CompaniesFilters
                        initialCompanies={filters.companies}
                        handleChange={(companies: any): void => {
                            handleChange({ ...filters, companies });
                        }}
                        tabsComponent={
                            <TabsComponent
                                selectedTab={selectedTab}
                                setSelectedTab={setSelectedTab}
                                filters={filters}
                            />
                        }
                    />
                );
            case TABS.AGENTS:
                return (
                    <AgentsFilters
                        initialAgents={filters.agents}
                        handleChange={(agents: any): void => {
                            handleChange({ ...filters, agents });
                        }}
                        tabsComponent={
                            <TabsComponent
                                selectedTab={selectedTab}
                                setSelectedTab={setSelectedTab}
                                filters={filters}
                            />
                        }
                    />
                );
            case TABS.OTHERS:
                return (
                    <OtherFilters
                        filters={filters}
                        handleChange={handleChange}
                        tabsComponent={
                            <TabsComponent
                                selectedTab={selectedTab}
                                setSelectedTab={setSelectedTab}
                                filters={filters}
                            />
                        }
                    />
                );
            default:
                if (entities[selectedTab] !== undefined) {
                    return (
                        <EntityFilters
                            idEntity={selectedTab}
                            initialEntityValues={filters.entities[selectedTab]}
                            handleChange={(entityValues: any): void => {
                                handleChange({
                                    ...filters,
                                    entities: {
                                        ...filters.entities,
                                        [selectedTab]: entityValues,
                                    },
                                });
                            }}
                            tabsComponent={
                                <TabsComponent
                                    selectedTab={selectedTab}
                                    setSelectedTab={setSelectedTab}
                                    filters={filters}
                                />
                            }
                        />
                    );
                }
                return <div></div>;
        }
    };

    const renderContent = (): JSX.Element => {
        return (
            <div
                className={
                    layoutIsMobile
                        ? styles.mobileContainer
                        : styles.emptyContainer
                }
                onClick={(e): void => {
                    if (emptyRef.current === e.target) {
                        handleClose();
                    }
                }}
                ref={emptyRef}
            >
                <div className={styles.container}>
                    <div className={styles.filtersHeaderContainer}>
                        <span className={styles.title}>Filtrar Casos</span>
                        <div className={styles.statePickerContainer}>
                            <StatesFilters
                                label={'Estado'}
                                values={filters.states}
                                handleUpdate={(states: State[]): void => {
                                    handleChange({
                                        ...filters,
                                        states,
                                    });
                                }}
                                states={project.states}
                            />
                        </div>
                    </div>
                    {renderSelectedTab()}
                </div>
            </div>
        );
    };

    if (layoutIsMobile) {
        return (
            <Dialog
                onClose={(): void => {
                    handleClose();
                }}
                maxWidth="100vw"
                border={10}
                transparent
            >
                {renderContent()}
            </Dialog>
        );
    } else {
        return (
            <ClickAwayListener
                mouseEvent="onMouseDown"
                onClickAway={(): void => {
                    handleClose();
                }}
            >
                <Popper
                    open={true}
                    anchorEl={anchorRef.current}
                    placement={'bottom-start'}
                    modifiers={{
                        preventOverflow: {
                            enabled: true,
                            priority: ['top', 'bottom', 'left', 'right'],
                            boundariesElement: 'viewport',
                            padding: 20,
                        },
                    }}
                    style={{ zIndex: 3 }}
                >
                    {renderContent()}
                </Popper>
            </ClickAwayListener>
        );
    }
}
export default FiltersMenu;

interface TabsComponentProps {
    selectedTab: TABS;
    setSelectedTab: Function;
    filters: TicketFilters;
}
function TabsComponent({
    filters,
    selectedTab,
    setSelectedTab,
}: TabsComponentProps): JSX.Element {
    const project = useCurrentProject();

    const entities = useSelector((state: RootState) => state.site.entities);
    const organization = useSelector(
        (state: RootState) => state.site.organization
    );

    return (
        <div className={styles.tabsContainer}>
            <TabComponent
                tab={TABS.CLASSIFIERS}
                label="Clasificadores"
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                count={filters.classifiers.length}
            />
            {organization?.idCompanyEntity && (
                <TabComponent
                    tab={TABS.COMPANIES}
                    label="Empresas"
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    count={filters.companies.length}
                />
            )}
            {project.entities
                .filter(
                    (entity) =>
                        entity.isFilter &&
                        entities[entity.idEntity] &&
                        entity.idEntity !== organization?.idCompanyEntity &&
                        entity.idEntity !== organization?.idClientEntity
                )
                .map((projectEntity) => {
                    const entity = entities[projectEntity.idEntity];
                    return (
                        <TabComponent
                            key={entity._id}
                            tab={entity._id}
                            label={entity.pluralName}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                            count={filters.entities[entity._id]?.length ?? 0}
                        />
                    );
                })}
            <TabComponent
                tab={TABS.AGENTS}
                label="Agentes"
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                count={filters.agents.length}
            />
            <TabComponent
                tab={TABS.OTHERS}
                label="Otros"
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                count={countOtherFilters(filters)}
            />
        </div>
    );
}

interface TabComponentProps {
    tab: string;
    label: string;
    count: number;
    selectedTab: string;
    setSelectedTab: Function;
}

function TabComponent({
    tab,
    label,
    count,
    selectedTab,
    setSelectedTab,
}: TabComponentProps): JSX.Element {
    return (
        <div
            className={
                (tab === selectedTab ? styles.selectedOption : styles.option) +
                ' noselect'
            }
            onClick={(): void => {
                setSelectedTab(tab);
            }}
        >
            {label}
            <div className={styles.countContainer}>
                {count > 0 && <div className={styles.countLbl}>{count}</div>}
            </div>
        </div>
    );
}
