import styles from './TransformationDialog.module.css';
import EditContainer from '../../../../shared/EditContainer/EditContainer';
import {
    AssignProcessRoles,
    AutomaticTransformation,
    Transformation,
} from '../../../../@Types/FlowTypes/NodeTypes/TransformationNode';
import RoundedAutoPropPicker from '../../../../shared/@Pickers/RoundedTicketPropertyPicker/RoundedTicketPropertyPicker';
import { TicketPropertyTypes } from '../../../../constants/TicketPropertyTypes';
import TransformationTypes from '../../../../constants/Flows/TransformationTypes';
import RoundedTextField from '../../../../shared/RoundedTextField/RoundedTextField';
import RoundedClassifierPicker from '../../../../shared/@Pickers/RoundedClassifierPicker/RoundedClassifierPicker';
import RoundedStatePicker from '../../../../shared/@Pickers/RoundedStatePicker/RoundedStatePicker';
import { RootState } from '../../../../utils/_store';
import { useSelector } from 'react-redux';
import RoundedSurveyPicker from '../../../../shared/@Pickers/RoundedSurveyPicker/RoundedSurveyPicker';
import SubTicketTransformation from './SubTicketTransformation/SubTicketTransformation';

import AssignProcess from '../../../../shared/AssignProcess/AssignProcess';
import { Process } from '../../../../@Types/ProcessTypes/Process';
import FlowTypes from '../../../../constants/Flows/FlowTypes';
import { useMemo } from 'react';
import { ChannelTypes } from '../../../../constants/ChannelTypes';
import React from 'react';
import RoundedEntryPicker from '../../../../shared/@Pickers/RoundedEntryPicker/RoundedEntryPicker';
import RoundedSelect from '../../../../shared/RoundedSelect/RoundedSelect';
import { MenuItem } from '@material-ui/core';
import EntryTypes from '../../../../constants/EntryTypes';

interface TransformationDialogProps {
    /** The data of the transformation to display */
    transformation: Transformation & {
        /** Function used to open the dialog  */
        openDialog: Function;
    };
    /** Function called when a value needs to update */
    handleUpdate: Function;
    /** The type of the current flow */
    flowType: FlowTypes;
    setSubmit: (submit: () => Promise<void | Record<string, any>>) => void;
}

function TransformationDialog({
    flowType,
    setSubmit,
    transformation,
    handleUpdate,
}: TransformationDialogProps): JSX.Element {
    const states = useSelector(
        (state: RootState) =>
            state.site.projects[state.site.idProject ?? '']?.states
    );

    const propertyTypes = useMemo(() => {
        const properties = [
            TicketPropertyTypes.CLASSIFIER,
            TicketPropertyTypes.RESOLUTION_DATE,
            TicketPropertyTypes.STATE,
            TicketPropertyTypes.SURVEY,
            TicketPropertyTypes.SUBTICKET,
            TicketPropertyTypes.SUBJECT,
            TicketPropertyTypes.PROCESS,
        ];
        if (flowType === FlowTypes.ENTRY) {
            properties.push(TicketPropertyTypes.CHANNEL);
        }
        return properties;
    }, [flowType]);

    const idProject = useSelector((state: RootState) => state.site.idProject);
    const renderTransformationContent = (): JSX.Element | void => {
        if (transformation.type === TransformationTypes.AUTOMATIC) {
            return (
                <div className={styles.automaticContainer}>
                    <div className={styles.propertyPickerContainer}>
                        <RoundedAutoPropPicker
                            value={transformation.property}
                            label="Propiedad a transformar"
                            propertyTypes={propertyTypes}
                            handleUpdate={(property): void => {
                                const copy = {
                                    name: transformation.name,
                                    type: transformation.type,
                                    property: property,
                                    openDialog: transformation.openDialog,
                                };
                                handleUpdate(copy);
                            }}
                        />
                    </div>
                    {renderAutomaticContent()}
                </div>
            );
        }
    };

    const renderAutomaticContent = (): JSX.Element => {
        if (
            transformation.type === TransformationTypes.AUTOMATIC &&
            idProject
        ) {
            switch (transformation.property) {
                case TicketPropertyTypes.CLASSIFIER: {
                    return (
                        <div className={styles.contentContainer}>
                            <div className={styles.classifierPicker}>
                                <RoundedClassifierPicker
                                    height="40px"
                                    fullWidth={false}
                                    minWidth={240}
                                    label="Clasificador"
                                    value={
                                        transformation.idRoot
                                            ? [
                                                  {
                                                      idRoot: transformation.idRoot,
                                                      idValue:
                                                          transformation.idValue,
                                                  },
                                              ]
                                            : []
                                    }
                                    handleUpdate={(classifiers): void => {
                                        const copy = { ...transformation };
                                        copy.idRoot = classifiers?.[0]?.idRoot;
                                        copy.idValue =
                                            classifiers?.[0]?.idValue;
                                        handleUpdate(copy);
                                    }}
                                />
                            </div>
                        </div>
                    );
                }
                case TicketPropertyTypes.RESOLUTION_DATE: {
                    return (
                        <div className={styles.contentContainer}>
                            <div className={styles.numPickerContainer}>
                                <RoundedTextField
                                    value={transformation.numDays ?? 0}
                                    label="Días"
                                    inputProps={{ min: 0 }}
                                    onChange={(event): void => {
                                        const copy = { ...transformation };
                                        copy.numDays = parseInt(
                                            event.target.value
                                        );
                                        handleUpdate(copy);
                                    }}
                                    type="number"
                                ></RoundedTextField>
                            </div>
                            <div className={styles.numPickerContainer}>
                                <RoundedTextField
                                    value={transformation.numHours ?? 0}
                                    label="Horas"
                                    inputProps={{ min: 0 }}
                                    onChange={(event): void => {
                                        const copy = { ...transformation };
                                        copy.numHours = parseInt(
                                            event.target.value
                                        );
                                        handleUpdate(copy);
                                    }}
                                    type="number"
                                ></RoundedTextField>
                            </div>
                        </div>
                    );
                }
                case TicketPropertyTypes.STATE: {
                    const state = states[transformation.idState ?? ''];
                    return (
                        <div className={styles.contentContainer}>
                            <div className={styles.classifierPicker}>
                                <RoundedStatePicker
                                    height="40px"
                                    fullWidth={false}
                                    minWidth={240}
                                    label="Estado"
                                    handleUpdate={(state): void => {
                                        if (state) {
                                            const copy = { ...transformation };
                                            copy.idState = (state as any)._id;
                                            handleUpdate(copy);
                                        }
                                    }}
                                    value={state ? [state] : []}
                                    options={Object.values(states)}
                                />
                            </div>
                        </div>
                    );
                }
                case TicketPropertyTypes.CHANNEL: {
                    return (
                        <React.Fragment>
                            <div className={styles.channelContainer}>
                                <RoundedSelect
                                    value={
                                        transformation.channel === null
                                            ? 'NULL'
                                            : transformation.channel?.type
                                    }
                                    label="Canal"
                                    handleUpdate={(event): void => {
                                        const type = event.target.value;
                                        switch (type) {
                                            case ChannelTypes.EMAIL:
                                            case ChannelTypes.WHATSAPP:
                                                handleUpdate({
                                                    ...transformation,
                                                    channel: { type },
                                                });
                                                break;

                                            default:
                                                handleUpdate({
                                                    ...transformation,
                                                    channel: null,
                                                });
                                                break;
                                        }
                                    }}
                                >
                                    <MenuItem value={ChannelTypes.EMAIL}>
                                        Correo
                                    </MenuItem>
                                    <MenuItem value={ChannelTypes.WHATSAPP}>
                                        Whatsapp
                                    </MenuItem>
                                    <MenuItem value={'NULL'}>
                                        Sin Comunicaciones
                                    </MenuItem>
                                </RoundedSelect>
                            </div>
                            {transformation.channel?.type ===
                                ChannelTypes.WHATSAPP && (
                                <div className={styles.whatsappEntryContainer}>
                                    <RoundedEntryPicker
                                        label={'Entrada'}
                                        entryTypes={[EntryTypes.WHATSAPP]}
                                        height="40px"
                                        value={
                                            transformation.channel.apiKey
                                                ? [
                                                      transformation.channel
                                                          .apiKey,
                                                  ]
                                                : []
                                        }
                                        handleUpdate={(elements): void => {
                                            handleUpdate({
                                                ...transformation,
                                                channel: {
                                                    type: EntryTypes.WHATSAPP,
                                                    apiKey:
                                                        elements[0] ??
                                                        undefined,
                                                },
                                            });
                                        }}
                                    />
                                </div>
                            )}
                        </React.Fragment>
                    );
                }
                case TicketPropertyTypes.SUBJECT: {
                    return (
                        <div className={styles.contentContainer}>
                            <div className={styles.classifierPicker}>
                                <RoundedTextField
                                    height="40px"
                                    label="Asunto"
                                    onChange={(event): void => {
                                        handleUpdate({
                                            ...transformation,
                                            subject: event.target.value,
                                        });
                                    }}
                                    value={transformation.subject ?? ''}
                                />
                            </div>
                        </div>
                    );
                }
                case TicketPropertyTypes.SURVEY: {
                    const survey = transformation.survey;
                    return (
                        <div className={styles.contentContainer}>
                            <div className={styles.surveyPicker}>
                                <RoundedSurveyPicker
                                    height="40px"
                                    label="Encuesta"
                                    emptyLabel="Sin Encuesta"
                                    handleUpdate={([survey]): void => {
                                        const copy = { ...transformation };
                                        copy.idSurvey = survey?._id ?? null;
                                        copy.survey = survey;
                                        handleUpdate(copy);
                                    }}
                                    value={survey ? [survey] : []}
                                    idProject={idProject}
                                />
                            </div>
                        </div>
                    );
                }
                case TicketPropertyTypes.SUBTICKET: {
                    return (
                        <SubTicketTransformation
                            handleUpdate={handleUpdate}
                            transformation={transformation}
                        />
                    );
                }
                case TicketPropertyTypes.PROCESS: {
                    const process = transformation.process;
                    return (
                        <div className={styles.processContainer}>
                            <AssignProcess
                                process={process}
                                roles={transformation.roles}
                                setSubmit={setSubmit}
                                values={transformation.values}
                                handleUpdate={function (
                                    process: Process | undefined,
                                    roles: AssignProcessRoles
                                ): void {
                                    handleUpdate({
                                        ...transformation,
                                        idProcess: process?._id ?? null,
                                        process,
                                        roles,
                                    });
                                }}
                            />
                        </div>
                    );
                }
            }
        }
        return <div></div>;
    };

    if (transformation !== undefined) {
        return (
            <div className={styles.container}>
                <div className={styles.scrollContainer}>
                    <div className={styles.top}>
                        <div className={styles.valueContainer}>
                            <EditContainer
                                object={transformation}
                                field={'name'}
                                handleConfirm={(
                                    object: AutomaticTransformation,
                                    field: keyof AutomaticTransformation,
                                    value: string
                                ): void => {
                                    handleUpdate({
                                        ...transformation,
                                        name: value,
                                    });
                                }}
                            >
                                <label className={styles.nameLbl}>
                                    {transformation.name}
                                </label>
                                <input
                                    className={styles.nameInput + ' edit-input'}
                                    defaultValue={transformation.name}
                                    type="text"
                                    size={15}
                                />
                            </EditContainer>
                        </div>
                    </div>
                    {/* <div className={styles.typeContainer}>
                        <RoundedSelect
                            value={transformation.type}
                            label="Tipo"
                            handleUpdate={(event): void => {
                                const type = event.target.value;
                                const copy = { ...transformation, type };
                                if (type === types.AUTOMATIC) {
                                    copy.idRoot = '';
                                    copy.idValue = '';
                                }
                                handleUpdate(copy);
                            }}
                        >
                            <MenuItem value={types.AUTOMATIC}>
                                Automatica
                            </MenuItem>
                        </RoundedSelect>
                    </div> */}
                    {renderTransformationContent()}
                </div>
            </div>
        );
    } else {
        return <div></div>;
    }
}

export default TransformationDialog;
