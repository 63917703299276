import styles from './ClientListItem.module.css';
import Avatar from '../../../shared/Avatar/Avatar';
import { Client } from '../../../@Types/@Types';
interface ClientListItemProps {
    /** The selected ClientInfo */
    selectedElement?: Client;
    /** The ClientInfo to display */
    element?: Client;
    /** Function called when the component is clicked */
    handleItemClick?: Function;
}
function ClientListItem({
    selectedElement,
    element,
    handleItemClick,
}: ClientListItemProps): JSX.Element {
    if (!element || !handleItemClick) {
        return <div></div>;
    }

    const calcName = (): string | undefined => {
        const name = element.lastName
            ? element.name + ' ' + element.lastName
            : element.name;
        return name?.trim() ? name : undefined;
    };

    return (
        <div
            className={
                styles.container +
                (selectedElement?._id === element._id
                    ? ' selected-item-shadow'
                    : '')
            }
            data-testid={'ClientListItem'}
            onClick={(): void => handleItemClick(element, true)}
        >
            <div className={styles.sideBar}></div>
            <div className={styles.info}>
                <h4 data-testid={'CLI_name'} className={styles.title}>
                    {calcName() ?? '(Sin nombre)'}
                </h4>
                {element.email && (
                    <label data-testid={'CLI_email'} className={styles.infoLbl}>
                        {element.email}
                    </label>
                )}
                {element.cel && (
                    <label className={styles.infoLbl}>{element.cel}</label>
                )}
            </div>
            <div className="flexbox">
                <div className={styles.photo}>
                    <Avatar
                        lbl={calcName() ?? '?'}
                        size={50}
                        fontSize={30}
                    ></Avatar>
                </div>
            </div>
        </div>
    );
}

export default ClientListItem;
