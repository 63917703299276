import { createAction } from '@reduxjs/toolkit';
import { Survey } from '../../@Types/@Types';

export const Types = {
    RESET: 'SURVEYS/RESET',
    GET_SUCCESS: 'SURVEYS/GET_SUCCESS',
    PAGE_SCROLL: 'SURVEYS/NEW_PAGE_SCROLL',
    SELECT: 'SURVEYS/SELECT_ELEMENT',
    UPDATE: 'SURVEYS/UPDATE_ELEMENT',
    SEARCH: 'SURVEYS/SEARCH',
    LOADING: 'SURVEYS/LOADING',
    ORDER: 'SURVEYS/ORDER',
    FILTER: 'SURVEYS/FILTER',
};

/**
 * Called when the surveys page is first loaded
 */
export const reset = createAction(Types.RESET);

/**
 * Called to toggle the loader
 */
export const setLoading = createAction(
    Types.LOADING,
    function prepare(loading: boolean = true) {
        return {
            payload: loading,
        };
    }
);

/**
 * Called when the list needs to load more elements
 */
export const pageScroll = createAction(Types.PAGE_SCROLL);

/**
 * Called when the selectedElement (index) changes value
 */
export const selectElement = createAction<number>(Types.SELECT);

/**
 * Called when a value of the selected Survey is updated
 */
export const updateElement = createAction<{
    object: Survey;
    field: keyof Survey;
    value: string;
}>(Types.UPDATE);

/**
 * Called when the search value changes
 */
export const search = createAction<string>(Types.SEARCH);

/**
 *  Called when the saga has sucessfully retrieved the lists elements
 */
export const getSuccess = createAction<{
    elements: Survey[];
    noMoreElements: boolean;
}>(Types.GET_SUCCESS);
