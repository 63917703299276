import styles from './TicketTitle.module.css';
import AttachmentRoundedIcon from '@material-ui/icons/AttachmentRounded';
import Dialog from '../../../../../shared/Dialog/Dialog';
import ClientDetail from '../../ClientDetail/ClienteDetail';
import React, { useEffect, useRef, useState } from 'react';
import Interactions from './Interactions/Interactions';
import { Ticket } from '../../../../../@Types/TicketTypes/Ticket';
import AttachmentsMenu from './AttachmentsMenu/AttachmentsMenu';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../utils/_store';
import SincoQueries from '../../../../../custom/Sinco/Queries/Queries';
import TicketCompany from './TicketCompany/TicketCompany';
import { Suspense } from 'react';
import lazy from 'react-lazy-with-preload';
import MoreActionsMenu from './MoreActionsMenu/MoreActionsMenu';
import { refreshCurrent } from '../../../../../controllers/TicketsController/TicketsActions';
import TicketEntity from './TicketEntity/TicketEntity';
import { Client } from '../../../../../@Types/Client';
import { loadClientById } from '../../../../../controllers/ClientsController/ClientsService';
import TicketEntry from '../../TicketEntry/TicketEntry';
import EntryTypes from '../../../../../constants/EntryTypes';
import Loader from '../../../../../shared/Loader/Loader';
const CBRMenu = lazy(
    () => import('../../../../../custom/Construction/CBRMenu/CBRMenu')
);
interface TicketTitleProps {
    /** The currently selected Ticket */
    selectedElement: Ticket;
    /** if interactions are being filtered */
    interactions: boolean;
    /** Function to toggle interactions */
    setInteractions: Function;
    /** If the layout is mobile */
    layoutIsMobile?: boolean;
}
function TicketTitle({
    selectedElement,
    interactions,
    setInteractions,
    layoutIsMobile,
}: TicketTitleProps): JSX.Element {
    const dispatch = useDispatch();
    const [showClient, setShowClient] = useState(false);
    const [showEntry, setShowEntry] = useState(false);
    const [showAttachments, setShowAttachments] = useState(false);
    const siteInfo = useSelector((state: RootState) => state.site);
    const attachmentsRef = useRef<HTMLDivElement>(null);
    const [client, setClient] = useState<Client | null>(null);

    async function loadClient(): Promise<void> {
        try {
            const client = await loadClientById(
                selectedElement.client.idClient
            );
            setClient({
                ...selectedElement.client,
                _id: client._id,
                entities: client.entities,
                companies: client.companies,
            });
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        setClient(null);
        loadClient();
    }, [selectedElement.client]);

    /** Render the subtitle of the ticket, including name and entry */
    const calcSubTitle = (): (string | JSX.Element)[] => {
        const elements = [];
        let name: string | undefined = selectedElement?.client?.name ?? '';
        if (selectedElement?.client?.lastName) {
            if (name) {
                name += ' ';
            }
            name += selectedElement?.client?.lastName;
        }
        if (!name) {
            name = selectedElement?.client?.email;
        }
        if (!name) {
            name = selectedElement?.client?.cel;
        }
        if (!name) {
            name = selectedElement?.client?.identifier;
        }
        if (name) {
            elements.push(
                <span
                    onClick={(): void => {
                        setShowClient(true);
                    }}
                    className={styles.user}
                    key={'Name'}
                >
                    {name}
                </span>
            );
        } else {
            elements.push(
                <span
                    key={'Name'}
                    onClick={(): void => {
                        setShowClient(true);
                    }}
                    className={styles.user + ' noselect'}
                >
                    Anónimo
                </span>
            );
        }
        const project = siteInfo.projects[siteInfo.idProject!];
        if (
            project.idPrimaryEntity &&
            project.entities.find(
                (pEntity) => pEntity.idEntity === project.idPrimaryEntity
            ) &&
            project.idPrimaryEntity !== siteInfo.organization?.idCompanyEntity
        ) {
            const entity = siteInfo.entities[project.idPrimaryEntity];
            elements.push(
                <span className={'noselect'} key={'-' + entity.name}>
                    {' '}
                    -{' '}
                </span>
            );
            elements.push(
                <TicketEntity
                    key={entity.name + 'C'}
                    ticket={selectedElement}
                    client={client}
                    entity={entity}
                />
            );
            //TODO: Remove this after company migration.
        } else if (
            siteInfo.organization?.idCompanyEntity &&
            selectedElement.idProject !== '63209f1107e1a5001a486b48'
        ) {
            elements.push(
                <span className={'noselect'} key={'-Company'}>
                    {' '}
                    -{' '}
                </span>
            );
            elements.push(
                <TicketCompany
                    key="CompanyC"
                    ticket={selectedElement}
                    client={client}
                />
            );
        }

        const entry = selectedElement?.entry;
        if (entry) {
            elements.push(
                <span className={'noselect'} key="-Entry">
                    {' '}
                    - Por:{' '}
                </span>
            );
            if (entry.type !== EntryTypes.MIGRATION || entry.idForm) {
                elements.push(
                    <span
                        className={styles.entry}
                        onClick={(): void => {
                            setShowEntry(true);
                        }}
                        key={'Entry'}
                    >
                        {entry.name ?? entry.type.toLocaleLowerCase()}
                    </span>
                );
            } else {
                //If migration without a form, just show the name
                elements.push(
                    <span key={'Entry'}>
                        {entry.name ?? entry.type.toLocaleLowerCase()}
                    </span>
                );
            }
        }
        return elements;
    };
    /**
     * Function that renderes the buttons beside the subtitle, attachments, etc
     */
    const renderSubInfo = (): JSX.Element => {
        const attachments = selectedElement?.content?.files ?? [];
        if (attachments && attachments?.length > 0) {
            return (
                <div className={styles.subTitleBtnsContainer}>
                    <div
                        className={styles.attachmentsBtn}
                        ref={attachmentsRef}
                        onClick={(): void => {
                            setShowAttachments(true);
                        }}
                    >
                        {showAttachments && (
                            <AttachmentsMenu
                                handleClose={(): void => {
                                    setShowAttachments(false);
                                }}
                                anchorRef={attachmentsRef}
                                attachments={attachments}
                                idTicket={selectedElement._id}
                                idProject={selectedElement.idProject}
                            />
                        )}
                        <label className={styles.attachmentsBtnLbl}>
                            {attachments?.length}
                        </label>
                        <div className={styles.attachmentsIcon}>
                            <AttachmentRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                </div>
            );
        }
        return <div></div>;
    };
    return (
        <React.Fragment>
            {showEntry && (
                <Dialog
                    maxWidth="100%"
                    disableEnforceFocus
                    onClose={(): void => setShowEntry(false)}
                >
                    <TicketEntry ticket={selectedElement} />
                </Dialog>
            )}
            {showClient && (
                <Dialog
                    open={showClient}
                    maxWidth={'90vw'}
                    disableEnforceFocus
                    onClose={(): void => {
                        setShowClient(false);
                        dispatch(refreshCurrent());
                    }}
                >
                    <>
                        {!client && (
                            <div style={{ width: '980px', height: '70vh' }}>
                                <Loader />
                            </div>
                        )}
                        {client && (
                            <ClientDetail
                                client={client}
                                ticket={selectedElement}
                                refreshClient={loadClient}
                            />
                        )}
                    </>
                </Dialog>
            )}
            <div className={styles.container}>
                <div
                    className={
                        layoutIsMobile
                            ? styles.mobileInfoContainer
                            : styles.infoContainer
                    }
                >
                    <div
                        className={
                            layoutIsMobile
                                ? ''
                                : styles.infoContainerContainer +
                                  ' OverFlowContainer'
                        }
                    >
                        {!layoutIsMobile && (
                            <div className={styles.titleContainer}>
                                <div className="OverFlowContainer">
                                    <label
                                        className={styles.lblSubject}
                                        title={selectedElement.subject}
                                    >
                                        {selectedElement.subject}
                                    </label>
                                </div>
                            </div>
                        )}
                        {layoutIsMobile && (
                            <div className={styles.mobileTitleContainer}>
                                <p
                                    className={styles.mobileLblSubject}
                                    title={selectedElement.subject}
                                >
                                    {selectedElement.subject}
                                </p>
                            </div>
                        )}
                        <div
                            className={
                                layoutIsMobile
                                    ? styles.mobileSubTitleContainer
                                    : styles.subTitleContainer
                            }
                        >
                            <div
                                className={
                                    layoutIsMobile
                                        ? styles.mobileSubTitleInfoCont
                                        : styles.subTitleInfoContainer
                                }
                            >
                                <label
                                    className={
                                        layoutIsMobile
                                            ? styles.mobileSubTitleInfo
                                            : styles.subTitleInfo
                                    }
                                >
                                    {calcSubTitle()}
                                </label>
                            </div>

                            {renderSubInfo()}
                        </div>
                    </div>
                </div>
                {!layoutIsMobile && (
                    <div className={styles.btnsContainer}>
                        {siteInfo.organization?.idOrganization === 'sinco' && (
                            <SincoQueries company={selectedElement.company} />
                        )}
                        {siteInfo.organization?.idOrganization === 'melendez' &&
                            selectedElement.CBR == undefined && (
                                <Suspense fallback={<div></div>}>
                                    <CBRMenu ticket={selectedElement} />
                                </Suspense>
                            )}
                        <Interactions
                            active={interactions}
                            setActive={setInteractions}
                        />
                        <MoreActionsMenu ticket={selectedElement} />
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}
export default TicketTitle;
