enum EntryTypes {
    FORM = 'FORM',
    EMAIL = 'EMAIL',
    WHATSAPP = 'WHATSAPP',
    ACTIVITY = 'ACTIVITY',
    MIGRATION = 'MIGRATION',
}

export enum EntryPropertyTypes {
    TYPE = 'TYPE',
    INTERNAL = 'INTERNAL',
}

export default EntryTypes;
