import { ConversationStep } from '../../../../../@Types/ConversationTypes/ConversationStep';
import styles from './StepConfigBtn.module.css';
import { useState } from 'react';
import ConversationStepTypes from '../../../../../constants/Conversations/ConversationStepTypes';
import FormStepTypes from '../../../../../constants/FormStepTypes';
import React from 'react';
import Dialog from '../../../../../shared/Dialog/Dialog';
import Toggle from '../../../../../shared/Toggle/Toggle';
import RoundedFormStepPicker from '../../../../../shared/@Pickers/RoundedFormStepPicker/RoundedFormStepPicker';
import GearsIcon from '../../../../../Icons/GearsIcon';
import { FormStep } from '../../../../../@Types/FormTypes/FormStep';
import { useCurrentProject } from '../../../../../hooks';
import StringValidationTypes from '../../../../../constants/StringValidationTypes';
interface StepConfigBtnProps {
    step: ConversationStep;
    handleUpdate: (step: ConversationStep) => void;
}

function StepConfigBtn({
    step,
    handleUpdate,
}: StepConfigBtnProps): JSX.Element {
    const [open, setOpen] = useState(false);
    const project = useCurrentProject();
    if (!step || !handleUpdate || !project.values?.values) return <div></div>;

    let possibleValues = Object.values(project.values.values);
    if (step.type === ConversationStepTypes.ENTITY_VALUE_STEP) {
        possibleValues = possibleValues.filter(
            (value) =>
                value.type === FormStepTypes.ENTITYVALUEPICKER &&
                (!value.idEntity || value.idEntity === step.idEntity)
        );
    } else if (step.type === ConversationStepTypes.LIST_API_STEP) {
        possibleValues = possibleValues.filter(
            (value) => value.type === FormStepTypes.API_SELECTOR
        );
    } else if (step.type === ConversationStepTypes.TEXT_STEP) {
        if (step.validation?.type === StringValidationTypes.DATE) {
            possibleValues = possibleValues.filter(
                (value) => value.type === FormStepTypes.DATEPICKER
            );
        } else {
            possibleValues = possibleValues.filter(
                (value) => value.type === FormStepTypes.TEXTINPUT
            );
        }
    } else {
        possibleValues = possibleValues.filter(
            (value) => value.type === (step.type as any)
        );
    }

    const showConfig = possibleValues.length > 0;

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={(): void => setOpen(false)}
                border={20}
                maxWidth="60vw"
            >
                <div className={styles.container}>
                    <div className={styles.valueContainer}>
                        <div className={styles.valueLabel}>
                            Vincular al caso:
                        </div>
                        <div className={styles.toggleContainer}>
                            <Toggle
                                checked={!!step.idValue}
                                onChange={(checked: boolean): void => {
                                    handleUpdate({
                                        ...step,
                                        idValue: checked
                                            ? possibleValues[0].id
                                            : undefined,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    {step.idValue && (
                        <div className={styles.pickerContainer}>
                            <RoundedFormStepPicker
                                steps={possibleValues as FormStep[]}
                                value={step.idValue}
                                allowCustom
                                containerMargin="0px"
                                handleUpdate={(idValue): void => {
                                    handleUpdate({
                                        ...step,
                                        idValue,
                                    });
                                }}
                            />
                        </div>
                    )}
                </div>
            </Dialog>
            {showConfig && (
                <div
                    className={styles.btn + ' grey-btn'}
                    onClick={(): void => {
                        setOpen(true);
                    }}
                    title="Configuraciones"
                >
                    <GearsIcon className={styles.configIcon} />
                </div>
            )}
        </React.Fragment>
    );
}

export default StepConfigBtn;
