import EditContainer from '../../../shared/EditContainer/EditContainer';
import React from 'react';
import { Organization } from '../../../@Types/Organization';
import styles from './NameEditor.module.css';

interface NameEditorProps {
    organization: Organization;
    handleUpdate: (name: string) => void;
}

export default function NameEditor({
    organization,
    handleUpdate,
}: NameEditorProps): JSX.Element {
    return (
        <div className={styles.container}>
            Nombre:
            <div className={styles.nameContainer}>
                <EditContainer
                    object={organization}
                    field={'name'}
                    handleConfirm={async (
                        object: Organization,
                        field: 'name',
                        value: string
                    ): Promise<void> => {
                        await handleUpdate(value);
                    }}
                    validatorManager={{
                        validator: (value: string): boolean => {
                            return /^[a-zA-Z\d ]+$/g.test(value);
                        },
                        errorMessage:
                            'El nombre de la organización solo puede contener letras y números',
                    }}
                >
                    <div>{organization.name}</div>
                    <input
                        className={styles.nameInput + ' edit-input'}
                        defaultValue={organization.name}
                        type="text"
                    />
                </EditContainer>
            </div>
        </div>
    );
}
