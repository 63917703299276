import { IconProps } from './@IconTypes';

function TemplateIcon(props: IconProps): JSX.Element {
    return (
        <svg
            fill={props.fill}
            className={props.className}
            style={props.style}
            version="1.1"
            viewBox="94 67 28 32"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M 119.357 92.788 L 99.357 92.788 C 97.149 92.788 95.357 90.996 95.357 88.788 L 95.357 73.094 C 95.67 72.023 96.642 70.788 98.357 70.788 C 100.212 70.788 101.126 72.13 101.352 73.1 L 101.357 74.788 L 119.357 74.788 L 119.357 92.788 Z M 118.357 75.788 L 101.357 75.788 L 101.357 88.788 C 101.357 88.788 100.692 87.788 99.357 87.788 C 98.253 87.788 97.357 88.684 97.357 89.788 C 97.357 90.892 98.253 91.788 99.357 91.788 L 118.357 91.788 L 118.357 75.788 Z M 100.357 73.108 C 99.93 72.137 99.03 71.783 98.357 71.788 C 97.696 71.793 96.789 72.088 96.357 73.108 L 96.357 89.286 C 96.751 87.293 98.602 86.405 100.357 86.885 L 100.357 73.108 Z M 115.357 88.788 L 103.357 88.788 L 103.357 78.788 L 115.357 78.788 L 115.357 88.788 Z M 107.357 79.788 L 104.357 79.788 L 104.357 87.788 L 114.357 87.788 L 114.357 83.788 L 112.357 83.788 L 112.357 86.788 L 111.357 86.788 L 111.357 83.788 L 108.357 83.788 L 108.357 86.788 L 107.357 86.788 L 107.357 79.788 Z M 114.357 79.788 L 108.357 79.788 L 108.357 82.788 L 114.357 82.788 L 114.357 79.788 Z" />
        </svg>
    );
}
export default TemplateIcon;
