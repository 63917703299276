import { createAction } from '@reduxjs/toolkit';
import { Agent, Area } from '../../@Types/@Types';
import { SelectableAgent } from './AgentsReducer';

export const Types = {
    RESET: 'AGENTSMENU/RESET',
    GET_SUCCESS: 'AGENTSMENU/GET_SUCCESS',
    PAGE_SCROLL: 'AGENTSMENU/NEW_PAGE_SCROLL',
    SELECT: 'AGENTSMENU/SELECT_ELEMENT',
    SEARCH: 'AGENTSMENU/SEARCH',
    LOADING: 'AGENTSMENU/LOADING',
    FILTER: 'AGENTSMENU/FILTER',
    TOGGLE_FILTERS: 'AGENTSMENU/TOGGLE_FILTERS',
};

/**
 * Called when the agents menu is first loaded
 */
export const reset = createAction<{
    selected: Agent[] | string[];
    filterAgents: Agent[];
    projectFilter: boolean;
    fetchAreas?: boolean;
}>(Types.RESET);

/**
 * Called to toggle the loader
 */
export const setLoading = createAction(
    Types.LOADING,
    function prepare(loading: boolean = true) {
        return {
            payload: loading,
        };
    }
);

/**
 * Called when the list needs to load more elements
 */
export const pageScroll = createAction(Types.PAGE_SCROLL);

/**
 * Called when the selectedElements change value
 */
export const selectElements = createAction<{
    selected: Agent[];
    elements: SelectableAgent[];
}>(Types.SELECT);

/**
 * Called when the search value changes
 */
export const search = createAction<string>(Types.SEARCH);

/**
 * Called when the filter values change
 */
export const filter = createAction<Area | null>(Types.FILTER);

/**
 *  Called when the saga has sucessfully retrieved the lists elements
 */
export const getSuccess = createAction<{
    elements: SelectableAgent[];
    noMoreElements: boolean;
    areas?: Area[];
    selected?: Agent[];
}>(Types.GET_SUCCESS);

/**
 * Toggles if showing or not the filters
 */
export const toggleFilters = createAction(Types.TOGGLE_FILTERS);
