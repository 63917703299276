import React, { useEffect, useState, useRef } from 'react';
import ListDetailLayout from '../../shared/ListDetailLayout/ListDetailLayout';
import PageTitle from '../../shared/PageTitle/PageTitle';
import List from '../../shared/List/List';
import CompanyDetail from './CompanyDetail/CompanyDetail';
import CompanyListItem from './CompanyListItem/CompanyListItem';
import CreateCompany from './CreateCompany/CreateCompany';
import RoundedButton from '../../shared/RoundedButton/RoundedButton';
import Search from '../../shared/Search/Search';
import Dialog from '../../shared/Dialog/Dialog';
import { useSelector, useDispatch } from 'react-redux';
import {
    reset,
    selectElement,
    search,
    setLoading,
    pageScroll,
    refresh,
    goTo,
} from '../../controllers/CompaniesController/CompaniesActions';
import { RootState } from '../../utils/_store';
import { Company } from '../../@Types/@Types';
import * as CompaniesService from '../../controllers/CompaniesController/CompaniesService';
import ConfirmDialog from '../../shared/ConfirmDialog/ConfirmDialog';
import { OrganizationPermissions } from '../../constants/Permissions';
import { checkOrgPerm } from '../../utils/PermissionsFunctions';
import { RouteComponentProps, match, withRouter } from 'react-router-dom';
import { companyToQueryString } from '../../controllers/CompaniesController/CompaniesReducer';

interface IdCompanyRouteParam {
    /** Current Ticket */
    idCompany?: string;
    /** The id of the currentProject */
}
interface CompanyPageProps extends RouteComponentProps {
    match: match<IdCompanyRouteParam>;
}
function CompaniesPage({
    history,
    location,
    match: {
        params: { idCompany },
    },
}: CompanyPageProps): JSX.Element {
    const dispatch = useDispatch();
    const pageInfo = useSelector((state: RootState) => state.companiesPage);
    const user = useSelector((state: RootState) => state.site.user);
    const organization = useSelector(
        (state: RootState) => state.site.organization
    );
    const [createDialog, setCreateDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState<Company | undefined>(
        undefined
    );
    const listRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        dispatch(reset({ history, queryString: location.search, idCompany }));
        window.addEventListener('popstate', handleGoBack);
        return (): void => {
            window.removeEventListener('popstate', handleGoBack);
        };
    }, []);

    function handleGoBack(): void {
        const paths = window.location.pathname.split('/');
        if (paths[1] !== 'companies') return;
        const idCompany = paths[2];
        dispatch(
            goTo({ history, idCompany, queryString: window.location.search })
        );
        if (listRef.current) scrollToSelected(idCompany, listRef);
    }

    function scrollToSelected(selected: any, listRef: any): void {
        const selectedNode = listRef.current.querySelector(
            '.selected-item-shadow'
        );
        if (selected && selectedNode) {
            listRef.current.scrollTo(
                0,
                selectedNode.offsetTop - listRef.current.offsetTop - 10
            );
        } else {
            listRef.current.scrollTop = 0;
        }
    }

    const updateUrl = (
        filters: {},
        orderBy: string | undefined,
        search: string | undefined,
        type: 'FILTER' | 'SEARCH' | 'ORDER'
    ): void => {
        let url = '/companies/';
        const filtersUrl = companyToQueryString(filters, orderBy, search, true);
        const urlPayload = {
            pathname: url,
            search: filtersUrl,
            state: { changeType: type },
        };
        if ((history.location?.state as any)?.changeType) {
            history.replace(urlPayload);
        } else {
            history.push(urlPayload);
        }
    };

    return (
        <React.Fragment>
            {createDialog && (
                <Dialog
                    open={createDialog}
                    onClose={(): void => setCreateDialog(false)}
                    maxWidth="100%"
                >
                    <CreateCompany
                        handleCompanyCreated={(): void => {
                            // TODO: Cuando hayan filtros toca activar el de ordenar por fecha de creación para que el nuevo quede de primeras
                            //Es llamar un action que le ponga loading cambie filtros y vuelva a pedir todo.
                            //TODO corregir bugs, mismo problema en clientes
                            dispatch(refresh());
                            setCreateDialog(false);
                            if (listRef.current) listRef.current.scrollTop = 0;
                        }}
                    />
                </Dialog>
            )}
            {deleteDialog !== undefined && (
                <ConfirmDialog
                    onConfirm={async (
                        element: Company,
                        setLoading: Function
                    ): Promise<void> => {
                        try {
                            setLoading(true);
                            await CompaniesService.deleteCompany(element._id);
                            dispatch(refresh());
                            setDeleteDialog(undefined);
                        } catch (error) {
                            //TODO: handle errors
                            console.error(error);
                            setLoading(false);
                        }
                    }}
                    element={deleteDialog}
                    title="Borrar Empresa"
                    btnMsg="Borrar"
                    deleting
                    msg="¿Está seguro de que desea borrar esta empresa?"
                    onClose={(): void => {
                        setDeleteDialog(undefined);
                    }}
                />
            )}
            <div className="standard-layout">
                <PageTitle
                    title={'Empresas'}
                    route={organization?.name + ' / Empresas'}
                />
                <div className="filters-container flexbox">
                    {checkOrgPerm(
                        user,
                        organization,
                        OrganizationPermissions.CREATE_COMPANIES
                    ) && (
                        <RoundedButton
                            text="Nueva"
                            backgroundColor={'var(--secondary)'}
                            color={'white'}
                            onClick={(): void => {
                                setCreateDialog(true);
                            }}
                        />
                    )}

                    <div className="search-container">
                        <Search
                            search={pageInfo.search}
                            handleLoadingSearch={(loading: boolean): void => {
                                dispatch(setLoading(loading));
                            }}
                            handleSearch={(value: string): void => {
                                /** Refresh the list and send it back to the top */
                                if (listRef.current)
                                    listRef.current.scrollTop = 0;
                                dispatch(search(value));
                                updateUrl(
                                    pageInfo.filters,
                                    pageInfo.orderBy,
                                    value,
                                    'SEARCH'
                                );
                            }}
                        />
                    </div>
                </div>

                <ListDetailLayout
                    listWidth={450}
                    responsiveBreakpoint={1000}
                    selectElementLabel="Selecciona una Empresa"
                    loading={pageInfo.loading}
                    selectedElement={
                        pageInfo.selectedElement !== undefined
                            ? pageInfo.elements[pageInfo.selectedElement]
                            : undefined
                    }
                    handleSelectedElement={(element?: Company): void => {
                        const index = pageInfo.elements.findIndex(
                            (listElement) => listElement._id === element?._id
                        );
                        history.push({
                            pathname: `/companies${
                                element ? '/' + element._id : ''
                            }`,
                            search: history.location.search,
                            state: {
                                prevPath: history.location.pathname,
                            },
                        });
                        dispatch(selectElement(index));
                    }}
                >
                    <List
                        elements={pageInfo.elements}
                        firstPage={pageInfo.pageUp === 1}
                        lastPage={pageInfo.lastPage}
                        listId={'companies-infiniteList'}
                        listRef={listRef}
                        firstFocusScroll={scrollToSelected}
                        onPageScroll={(direction): void => {
                            dispatch(pageScroll(direction));
                        }}
                        label="No hay Empresas"
                    >
                        <CompanyListItem />
                    </List>
                    <CompanyDetail handleClickDelete={setDeleteDialog} />
                </ListDetailLayout>
            </div>
        </React.Fragment>
    );
}

export default withRouter((props: any) => <CompaniesPage {...props} />);
