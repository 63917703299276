import React from 'react';
import { DraftContext } from '../../../../@Types/Draft/DraftContext';
import FormIcon from '../../../../Icons/FormIcon';
import { GenericContextProps } from '../TextEditorContext';
import Context from '../Context';
import { DraftEntityDataTypes } from '../../../../constants/Draft/DraftEntityDataTypes';
import { useFormEditorSelector } from '../../../../pages/Forms/FormReducerFunctions';
import EurekaElementMenuGroup from '../../../@Menus/ElementMenu/EurekaElementMenuGroup';

interface FormStepContext extends GenericContextProps {
    context: DraftContext | undefined;
}

function FormStepContext({
    context,
    zIndex,
    ...props
}: FormStepContext): JSX.Element {
    const previousSteps = useFormEditorSelector(
        (state) =>
            state.stepDependencies[context?.form?.idStep ?? '']?.previousSteps
    );

    if (!previousSteps || previousSteps.length === 0) return <></>;
    return (
        <EurekaElementMenuGroup
            zIndex={zIndex + 1}
            icon={FormIcon}
            label="Pasos Anteriores"
            renderElements={(menuProps): JSX.Element => (
                <React.Fragment>
                    {previousSteps.map((idStep) => (
                        <Context
                            {...props}
                            {...menuProps}
                            key={idStep}
                            data={{
                                type: DraftEntityDataTypes.FORM_STEP,
                                idStep,
                            }}
                        />
                    ))}
                </React.Fragment>
            )}
        />
    );
}

export default FormStepContext;
