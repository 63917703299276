import axiosInstance from '../../AxiosAPI';
import { Conversation } from '../../@Types/ConversationTypes/Conversation';
import StepTypes from '../../constants/Conversations/ConversationStepTypes';
import CBRStepTypes from '../../constants/Construction/CBRFormStepTypes';
import {
    ConversationStep,
    ListApiStep,
    ListStep,
} from '../../@Types/ConversationTypes/ConversationStep';
import { mapCondition } from '../ConditionEditorController/ConditionFunctions';
import { EurekaDraft } from '../../@Types/Draft/Draft';
import produce from 'immer';
import { AYFConversationStep } from '../../@Types/ConversationTypes/AYFConversationStep';
import { CBRConversationStep } from '../../@Types/ConversationTypes/CBRConversationStep';
import { mapEurekaDraft } from '../PayloadEditorController/PayloadFunctions';
import { WhatsappTemplate } from '../../@Types/WhatsappTemplate';
import { ApiOptionTypes } from '../../constants/ApiOptionTypes';
import { EntityValuePathTypes } from '../../constants/FormStepTypes';

/**
 * Function that load the conversation of a given entry
 * @param apiKey the apiKey of the entry to fetch the conversation
 * @returns the conversation of the entry given
 */
export async function loadConversationData(
    idProject: string,
    apiKey: string
): Promise<{ conversation: Conversation; entry: any }> {
    const [conversationResp, entryResp] = await Promise.all([
        axiosInstance.get(`/projects/${idProject}/conversations/${apiKey}`),
        axiosInstance.get(`/projects/${idProject}/entries/${apiKey}`),
    ]);

    const conversation: Conversation = conversationResp.data; // conversationTemp as any;
    for (const step of Object.values(conversation.steps)) {
        conversation.steps[step.id] = importStepData(step);
    }

    return {
        conversation,
        entry: entryResp.data,
    };
}

/**
 * Function that load the conversation of a given entry
 * @param apiKey the apiKey of the entry to fetch the conversation
 * @returns the conversation of the entry given
 */
export async function loadConversationTemplates(
    idProject: string,
    apiKey: string
): Promise<{ templates: Record<string, WhatsappTemplate>; entry: any }> {
    const [templatesResp, entryResp] = await Promise.all([
        axiosInstance.get(
            `/projects/${idProject}/conversations/${apiKey}/templates/base`
        ),
        axiosInstance.get(`/projects/${idProject}/entries/${apiKey}`),
    ]);

    return {
        templates: templatesResp.data,
        entry: entryResp.data,
    };
}

/**
 * Function that load the conversation of a given entry
 * @param apiKey the apiKey of the entry to fetch the conversation
 * @returns the conversation of the entry given
 */
export async function saveConversationTemplates(
    idProject: string,
    apiKey: string,
    templates: Record<string, WhatsappTemplate>
): Promise<Record<string, WhatsappTemplate>> {
    const response = await axiosInstance.patch(
        `/projects/${idProject}/conversations/${apiKey}/templates/base`,
        templates
    );
    return response.data;
}

/**
 * Function that saves the conversation, sends an error if the conversation is not valid
 * @param idProject the id of the conversation's project
 * @param apiKey the conversation's entry apiKey
 * @param conversation the conversation to save
 */
export const saveConversation = async (
    idProject: string,
    apiKey: string,
    conversation: Conversation
): Promise<void> => {
    const conv = produce(conversation, (conversation) => {
        const newSteps: Record<string, ConversationStep> = {};
        for (const idStep of conversation.rootSteps) {
            exportStep(idStep, conversation.steps, newSteps);
        }
        exportStep(conversation.idCreate, conversation.steps, newSteps);
        conversation.steps = newSteps;
        return conversation;
    });
    await axiosInstance.post(
        `/projects/${idProject}/conversations/${apiKey}`,
        conv
    );
};

const exportStep = (
    idStep: string,
    steps: Record<string, ConversationStep>,
    newSteps: Record<string, ConversationStep>
): void => {
    const step = { ...steps[idStep] };
    if (step) {
        newSteps[idStep] = exportStepData(step);
        if (step.type === StepTypes.TEXT_STEP) {
            if (step.authentication) {
                for (const idSubStep of step.authentication.errorSteps) {
                    exportStep(idSubStep, steps, newSteps);
                }
            }
        } else if (step.type === StepTypes.BUTTONS_STEP) {
            for (const idOption of step.optionList) {
                const option = step.options[idOption];
                if (option) {
                    for (const idSubStep of option.steps) {
                        exportStep(idSubStep, steps, newSteps);
                    }
                }
            }
        } else if (
            step.type === StepTypes.LIST_STEP ||
            step.type === StepTypes.LIST_PAGE_STEP
        ) {
            const options = Object.values(step.options);
            for (const option of options) {
                for (const idSubStep of option.steps) {
                    exportStep(idSubStep, steps, newSteps);
                }
            }
        } else if (step.type === StepTypes.LIST_API_STEP) {
            const options = Object.values(step.options);
            for (const option of options) {
                if (option.type === ApiOptionTypes.HIDE) continue;
                for (const idSubStep of option.steps) {
                    exportStep(idSubStep, steps, newSteps);
                }
            }
        } else if (step.type === StepTypes.ENTITY_VALUE_STEP) {
            for (const path of step.path) {
                if (path.type === EntityValuePathTypes.VALUE) {
                    if (
                        path.idEntityValue &&
                        typeof path.idEntityValue !== 'string'
                    ) {
                        path.idEntityValue = path.idEntityValue._id;
                    }
                }
            }
        } else if (
            step.type === StepTypes.GROUP_STEP ||
            step.type === StepTypes.CREATABLE_STEP
        ) {
            for (const idSubStep of step.steps) {
                exportStep(idSubStep, steps, newSteps);
            }
        } else if ((step as any).type === CBRStepTypes.CBR_LOCATIVAS) {
            if ((step as any).subStep) {
                exportStep((step as any).subStep, steps, newSteps);
            }
        }
    }
};

export async function loadConversationHistory(
    idProject: string,
    idConversation: string
): Promise<Conversation> {
    const resp = await axiosInstance.get(
        `/projects/${idProject}/conversations/history/${idConversation}`
    );
    return resp.data;
}

/**
 * Function that update the profile of the actual agent
 */
export const uploadSticker = async (file: File): Promise<string> => {
    const fd = new FormData();
    if (file.type.includes('image')) {
        fd.append('file', file, file.name);
    } else {
        throw { response: { data: 'Formato de imagen incorrecto' } };
    }
    return (
        await axiosInstance.post('files/sticker', fd, {
            timeout: 30000,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    ).data;
};

export default {
    loadConversationData,
    saveConversation,
    loadConversationHistory,
    loadConversationTemplates,
    saveConversationTemplates,
};

function importStepData(step: ConversationStep): ConversationStep {
    return produce(step, (step) => {
        switch (step.type) {
            case StepTypes.BUTTONS_STEP:
                const optionList: any = [];
                const options: any = {};
                for (const option of step.options as any) {
                    optionList.push(option.id);
                    options[option.id] = option;
                }
                step.optionList = optionList;
                step.options = options;
                step.selectedOption = null;
                break;
            case StepTypes.LIST_STEP:
            case StepTypes.LIST_API_STEP:
            case StepTypes.LIST_PAGE_STEP:
                step.selectedOption = null;
                break;
            default:
                break;
        }
    });
}

function exportStepData(pStep: ConversationStep): ConversationStep {
    return produce(pStep, (step) => {
        step.condition = mapCondition(step.condition);
        switch (step.type) {
            case StepTypes.OPEN_STEP:
                if (step.continuousConfirmationMessage)
                    step.continuousConfirmationMessage = mapEurekaDraft(
                        step.continuousConfirmationMessage
                    );
                step.message = mapEurekaDraft(step.message);
                break;
            case StepTypes.CREATE_PASSTHROUGH:
                if (step.message) step.message = mapEurekaDraft(step.message);
                break;
            case StepTypes.INFO_TEXT_STEP:
            case StepTypes.TEXT_STEP:
            case StepTypes.FILE_STEP:
            case StepTypes.LINK_STEP:
            case StepTypes.CREATE_DEFAULT:
                step.message = mapEurekaDraft(step.message);
                break;
            case StepTypes.INFO_IMAGE_STEP:
            case StepTypes.INFO_VIDEO_STEP:
                if (step.caption) step.caption = mapEurekaDraft(step.caption);
                break;
            case StepTypes.BUTTONS_STEP:
                delete (step as any).selectedOption;
                const options: any[] = [];
                for (const idOption of step.optionList) {
                    const option = step.options[idOption];
                    options.push({
                        ...option,
                        condition: mapCondition(option.condition),
                        conditions: undefined as any,
                    });
                }
                (step as any).options = options;
                delete (step as any).optionList;
                step.message = mapEurekaDraft(step.message);
                break;
            case StepTypes.LIST_STEP:
            case StepTypes.LIST_PAGE_STEP: {
                const options: ListStep['options'] = {};
                for (const [idOption, option] of Object.entries(step.options)) {
                    options[idOption] = {
                        ...option,
                        condition: mapCondition(option.condition),
                    };
                    (options[idOption] as any).conditions = undefined;
                }
                delete (step as any).optionList;
                step.message = mapEurekaDraft(step.message);
                break;
            }
            case StepTypes.LIST_API_STEP: {
                const options: ListApiStep['options'] = {};
                for (const [idOption, option] of Object.entries(step.options)) {
                    if (option.type === ApiOptionTypes.HIDE) continue;
                    options[idOption] = {
                        ...option,
                        condition: mapCondition(option.condition),
                    };
                    (options[idOption] as any).conditions = undefined;
                }
                delete (step as any).optionList;
                step.message = mapEurekaDraft(step.message);
                break;
            }
            case StepTypes.ENTITY_VALUE_STEP:
            case StepTypes.CREATABLE_STEP:
                step.message = mapEurekaDraft(step.message);
                break;
            default:
                if (hasMessage(step)) {
                    (step as any).message = mapEurekaDraft(
                        (step as any).message
                    );
                }
                break;
        }
    });
}

function hasMessage(
    step: ConversationStep | AYFConversationStep | CBRConversationStep
): step is Extract<
    ConversationStep | AYFConversationStep | CBRConversationStep,
    { message: EurekaDraft }
> {
    return !!(step as any).message;
}
