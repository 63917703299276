import TitleIcon from '@material-ui/icons/Title';
import SubjectIcon from '@material-ui/icons/Subject';
import { MenuItem } from '@material-ui/core';
import RoundedSelect, {
    RoundedSelectProps,
} from '../../RoundedSelect/RoundedSelect';
import React, { useContext } from 'react';
import StepTypes, {
    ConversationStepTypes,
} from '../../../constants/Conversations/ConversationStepTypes';
import styles from './RoundedConversationStepPicker.module.css';
import FormatListBulletedRoundedIcon from '@material-ui/icons/FormatListBulletedRounded';
import PlaylistAddRoundedIcon from '@material-ui/icons/PlaylistAddRounded';
import {
    ConversationStep,
    EntityValueStep,
} from '../../../@Types/ConversationTypes/ConversationStep';
import { calcBaseConvStepName } from '../../../pages/Conversations/ConversationEditor/StepFunctions';
import { EditorState } from 'draft-js';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { CBRConversationStep } from '../../../@Types/ConversationTypes/CBRConversationStep';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import { Entity } from '../../../@Types/@Types';
import EntityIcon from '../../SmartIcons/EntityIcon';
import { ApiKeyContext } from '../../../pages/Conversations/ConversationEditor/ConversationEditor';
import { useAppSelector } from '../../../hooks';
import LinkIcon from '../../../Icons/LinkIcon';
import FileIcon from '../../../Icons/FileIcon';

export interface RoundedConversationStepPickerProps
    extends Omit<RoundedSelectProps, 'handleUpdate' | 'value'> {
    stepOrder: string[];
    /** Currently selected conversationStep id */
    value: string | undefined;
    /** function called when value changes */
    handleUpdate: (step: ConversationStep) => void;
}
function RoundedConversationStepPicker({
    value,
    stepOrder,
    handleUpdate,
    ...others
}: RoundedConversationStepPickerProps): JSX.Element {
    const steps = useAppSelector((state) => state.conversationEditor.steps);

    return (
        <RoundedSelect
            value={value}
            paddingLeft={0}
            containerMargin="5px"
            handleUpdate={(event): void => {
                const step = steps[event.target.value ?? ''];
                if (step) {
                    handleUpdate(step);
                }
            }}
            {...others}
        >
            {stepOrder.map((idStep) => {
                const step = steps[idStep];
                if (step) {
                    return (
                        <MenuItem
                            key={idStep}
                            value={step.id}
                            style={{
                                paddingLeft: 0,
                            }}
                        >
                            <StepComponent step={step} />
                        </MenuItem>
                    );
                } else {
                    return <div key={idStep}></div>;
                }
            })}
        </RoundedSelect>
    );
}
export default RoundedConversationStepPicker;

function StepComponent({ step }: { step: ConversationStep }): JSX.Element {
    const entity = useSelector(
        (state: RootState) =>
            step.type === ConversationStepTypes.ENTITY_VALUE_STEP &&
            state.site.entities[step.idEntity]
    );
    const apiKey = useContext(ApiKeyContext);
    const editorState: EditorState | undefined = useSelector(
        (state: RootState) =>
            state.payloadEditors['Conversation-' + apiKey + '-' + step.id]
                ?.editorState
    );
    switch (step.type) {
        case StepTypes.INFO_AUDIO_STEP:
        case StepTypes.INFO_STICKER_STEP:
        case StepTypes.INFO_DOCUMENT_STEP: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <InfoRoundedIcon fontSize="inherit" />
                    </div>
                    {step.name}
                </div>
            );
        }
        case StepTypes.INFO_TEXT_STEP: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <InfoRoundedIcon fontSize="inherit" />
                    </div>
                    {calcName(step, editorState)}
                </div>
            );
        }
        case StepTypes.INFO_VIDEO_STEP:
        case StepTypes.INFO_IMAGE_STEP: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <InfoRoundedIcon fontSize="inherit" />
                    </div>
                    {calcName(step, editorState)}
                </div>
            );
        }
        case StepTypes.LINK_STEP:
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <LinkIcon
                            fill="var(--secondary)"
                            style={{ width: 32, height: 32 }}
                        />
                    </div>
                    {calcName(step, editorState)}
                </div>
            );
        case StepTypes.TEXT_STEP: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <TitleIcon fontSize="inherit" />
                    </div>
                    {calcName(step, editorState)}
                </div>
            );
        }
        case StepTypes.FILE_STEP: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <FileIcon
                            fill="var(--secondary)"
                            style={{ width: 24, height: 24 }}
                        />
                    </div>
                    {calcName(step, editorState)}
                </div>
            );
        }
        case StepTypes.BUTTONS_STEP: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.btnIcon}></div>
                    </div>
                    {calcName(step, editorState)}
                </div>
            );
        }
        case StepTypes.LIST_STEP:
        case StepTypes.LIST_PAGE_STEP: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.listIconContainer}>
                            <FormatListBulletedRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                    {calcName(step, editorState)}
                </div>
            );
        }
        case StepTypes.OPEN_STEP: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.textAreaContainer}>
                            <SubjectIcon fontSize="inherit" />
                        </div>
                    </div>
                    {calcName(step, editorState)}
                </div>
            );
        }
        case StepTypes.ENTITY_VALUE_STEP: {
            if (!entity) return <div></div>;
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.entityIcon}>
                            <EntityIcon
                                fill={'var(--secondary)'}
                                idEntity={entity?._id}
                            />
                        </div>
                    </div>
                    {calcEntityValueStepName(entity, step, editorState)}
                </div>
            );
        }
        case StepTypes.CREATABLE_STEP: {
            return (
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.creatableIcon}>
                            <PlaylistAddRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                    {step.name}
                </div>
            );
        }
        default:
            if (step.type.startsWith('CBR_')) {
                const cbrStep = step as any as CBRConversationStep;
                return (
                    <div className={styles.property}>
                        <div className={styles.icon}>
                            <img
                                className={styles.sincoIcon}
                                src="/media/icons/sinco-cbr.svg"
                            />
                        </div>
                        {calcName(cbrStep as any, editorState)}
                    </div>
                );
            }
            return <div></div>;
    }
}

function calcName(
    step: ConversationStep,
    text: EditorState | undefined
): string {
    const baseText = calcBaseConvStepName(step.type);
    if (step.name !== baseText) return step.name;
    const txt = text?.getCurrentContent().getPlainText() ?? step.name;
    if (txt.length > 50) {
        return txt.substring(0, 40) + '…';
    }
    return txt;
}

function calcEntityValueStepName(
    entity: Entity,
    step: EntityValueStep,
    text: EditorState
): string {
    const baseText = 'Solicitud de ' + entity.pluralName;
    if (step.name !== baseText) return step.name;
    const txt = text.getCurrentContent().getPlainText();
    if (txt.length > 50) {
        return txt.substring(0, 40) + '…';
    }
    return txt;
}
