import { StepComponentProps } from '../../Step';
import styles from './OpenStep.module.css';
import React from 'react';
import StepContainer from '../../StepContainer/StepContainer';
import DraftRenderer from '../../../../../../shared/DraftRenderer/DraftRenderer';
import { OpenStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import { EditorState, convertFromRaw } from 'draft-js';
import ClientReply from '../../ClientReply/ClientReply';
interface OpenStepProps extends StepComponentProps {
    step: OpenStep;
}

function OpenStepComponent({ step, path }: OpenStepProps): JSX.Element {
    return (
        <React.Fragment>
            <StepContainer
                step={step}
                path={path}
                header={step.header}
                footer={step.footer}
                bottomComponent={
                    <div className={styles.messageBtn}>{step.label}</div>
                }
            >
                <div className={styles.messageContainer}>
                    <DraftRenderer
                        editorState={EditorState.createWithContent(
                            convertFromRaw(step.message)
                        )}
                    />
                </div>
            </StepContainer>
            <ClientReply padding={10}>
                <div className={styles.replyContent}>
                    <div className={styles.w2}></div>
                    <div className={styles.w3}></div>
                    <div className={styles.w1}></div>
                    <div className={styles.w2}></div>
                </div>
            </ClientReply>
            <ClientReply padding={10}>
                <div className={styles.replyContent}>
                    <div className={styles.w3}></div>
                    <div className={styles.w1}></div>
                    <div className={styles.w2}></div>
                    <div className={styles.w3}></div>
                </div>
            </ClientReply>
            <ClientReply padding={10}>
                <div className={styles.replyContent}>
                    <div className={styles.replyBtn + ' noselect'}>
                        {step.label}
                    </div>
                </div>
            </ClientReply>
        </React.Fragment>
    );
}
export default OpenStepComponent;
