import styles from './OpenStep.module.css';
import React, { useContext } from 'react';
import { OpenStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import { useDispatch } from 'react-redux';
import { updateStep } from '../../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import Toggle from '../../../../../../shared/Toggle/Toggle';
import RoundedTextField from '../../../../../../shared/RoundedTextField/RoundedTextField';
import PayloadEditor from '../../../../../../shared/TextEditor/PayloadEditor';
import {
    ApiKeyContext,
    BaseConversationPayloadConditionTypes,
} from '../../../ConversationEditor';
import { TextEditorTypes } from '../../../../../../constants/TextEditorTypes';

interface OpenStepProps {
    step: OpenStep;
}

function OpenStepComponent({ step }: OpenStepProps): JSX.Element {
    const dispatch = useDispatch();
    const apiKey = useContext(ApiKeyContext);
    return (
        <React.Fragment>
            <div className={styles.headerContainer}>
                <RoundedTextField
                    label="Encabezado"
                    multiline
                    maxLength={60}
                    value={step.header}
                    onChange={(e): void => {
                        dispatch(
                            updateStep({ ...step, header: e.target.value })
                        );
                    }}
                ></RoundedTextField>
            </div>
            <div className={styles.container}>
                <div className={styles.editorContainer}>
                    <PayloadEditor
                        placeholder={'Mensaje'}
                        type={TextEditorTypes.WHATSAPP}
                        idEditor={`Conversation-${apiKey}-${step.id}`}
                        payload={{ draft: step.message }}
                        onChange={({ draft: message }): void => {
                            if (!message) return;
                            dispatch(updateStep({ ...step, message }));
                        }}
                        media={false}
                        context={{ client: true }}
                        conditions={{
                            types: BaseConversationPayloadConditionTypes,
                            context: {
                                conversation: {
                                    idStep: step.id,
                                },
                            },
                        }}
                        minHeight={144}
                        maxHeight={400}
                        required
                        maxLength={4096}
                    />
                </div>
            </div>
            <div className={styles.footerContainer}>
                <RoundedTextField
                    label="Pie"
                    multiline
                    maxLength={60}
                    value={step.footer}
                    onChange={(e): void => {
                        dispatch(
                            updateStep({ ...step, footer: e.target.value })
                        );
                    }}
                ></RoundedTextField>
            </div>
            <div className={styles.btnLblContainer}>
                <RoundedTextField
                    label="Botón"
                    value={step.label}
                    maxLength={20}
                    onChange={(e): void => {
                        dispatch(
                            updateStep({
                                ...step,
                                label: e.target.value,
                            })
                        );
                    }}
                ></RoundedTextField>
            </div>
            <div className={styles.toggleLabelContainer}>
                Cuerpo del Caso:
                <div className={styles.toggleContainer}>
                    <Toggle
                        checked={step.isPrimary}
                        onChange={(checked: boolean): void => {
                            dispatch(
                                updateStep({ ...step, isPrimary: checked })
                            );
                        }}
                    />
                </div>
            </div>
            <div className={styles.toggleLabelContainer}>
                Permitir Adjuntos:
                <div className={styles.toggleContainer}>
                    <Toggle
                        checked={step.allowFiles}
                        onChange={(checked: boolean): void => {
                            dispatch(
                                updateStep({ ...step, allowFiles: checked })
                            );
                        }}
                    />
                </div>
            </div>
            {/* <div className={styles.toggleLabelContainer}>
                Mostrar en la Traza:
                <div className={styles.toggleContainer}>
                    <Toggle
                        checked={step.showInTrace}
                        onChange={(checked: boolean): void => {
                            dispatch(
                                updateStep({ ...step, showInTrace: checked })
                            );
                        }}
                    />
                </div>
            </div> */}
            <div className={styles.toggleLabelContainer}>
                Reenviar Mensaje de Confirmación :
                <div className={styles.toggleContainer}>
                    <Toggle
                        checked={
                            step.continuousConfirmationMessage !== undefined
                        }
                        onChange={(checked: boolean): void => {
                            dispatch(
                                updateStep({
                                    ...step,
                                    continuousConfirmationMessage: checked
                                        ? step.message
                                        : undefined,
                                })
                            );
                        }}
                    />
                </div>
            </div>
            {step.continuousConfirmationMessage !== undefined && (
                <div className={styles.container}>
                    <div className={styles.editorContainer}>
                        <PayloadEditor
                            placeholder={'Mensaje'}
                            type={TextEditorTypes.WHATSAPP}
                            idEditor={`Conversation-${apiKey}-${step.id}-continuous`}
                            payload={{
                                draft: step.continuousConfirmationMessage,
                            }}
                            onChange={({
                                draft: continuousConfirmationMessage,
                            }): void => {
                                if (!continuousConfirmationMessage) return;
                                dispatch(
                                    updateStep({
                                        ...step,
                                        continuousConfirmationMessage,
                                    })
                                );
                            }}
                            media={false}
                            context={{ client: true }}
                            conditions={{
                                types: BaseConversationPayloadConditionTypes,
                                context: {
                                    conversation: {
                                        idStep: step.id,
                                    },
                                },
                            }}
                            minHeight={144}
                            maxHeight={400}
                            required
                            maxLength={4096}
                        />
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

export default OpenStepComponent;
