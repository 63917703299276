import { AYFFormStep as AYFFormStepType } from '../../../@Types/FormTypes/AYFFormStep';
import { FormStepLocation, UniqueSteps } from '../../../@Types/FormTypes/Form';
import AYFFormStepTypes from '../../../constants/Construction/AYFFormStepTypes';
import SmartSelectStep from '../../../shared/@Steps/SmartSelectStep/SmartSelectStep';
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import TextInputStep from '../../../shared/@Steps/TextInputStep/TextInputStep';
import { GenericStepProps } from '../../../shared/GenericFormEditor/Step/Step';
import DescriptionIcon from '@material-ui/icons/Description';
import DatePickerStep from '../../../shared/@Steps/DatePickerStep/DatePickerStep';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import AYFCityConfig from './@Construction/AYFCityConfig/AYFCityConfig';
function AYFStepMapper(
    props: GenericStepProps<AYFFormStepType, UniqueSteps, FormStepLocation>
): JSX.Element {
    switch (props.step.type) {
        case AYFFormStepTypes.AYF_START_MONTH:
        case AYFFormStepTypes.AYF_END_MONTH:
            return (
                <SmartSelectStep
                    {...props}
                    step={{ ...props.step, searchable: false }}
                    icon={
                        <CalendarTodayRoundedIcon
                            fontSize="inherit"
                            style={{
                                color: '#757575',
                                fontSize: 20,
                                marginRight: 4,
                                marginTop: 2,
                            }}
                        />
                    }
                />
            );
        case AYFFormStepTypes.AYF_YEAR:
            return (
                <TextInputStep
                    {...props}
                    step={{ ...props.step }}
                    icon={
                        <CalendarTodayRoundedIcon
                            fontSize="inherit"
                            style={{
                                color: '#757575',
                                fontSize: 20,
                                marginRight: 4,
                                marginTop: 2,
                            }}
                        />
                    }
                />
            );
        case AYFFormStepTypes.AYF_CUT_OFF_DATE:
            return <DatePickerStep {...props} step={{ ...props.step }} />;
        case AYFFormStepTypes.AYF_BILL_NUM:
            return (
                <TextInputStep
                    {...props}
                    step={props.step}
                    icon={
                        <DescriptionIcon
                            style={{ color: '#757575', fontSize: 23 }}
                        />
                    }
                />
            );
        case AYFFormStepTypes.AYF_ICA_CITY:
            return (
                <SmartSelectStep
                    {...props}
                    step={props.step}
                    icon={
                        <LocationOnRoundedIcon
                            fill="#757575"
                            style={{
                                display: 'flex',
                                height: 23,
                                width: 23,
                                marginRight: 3,
                                marginTop: 1,
                            }}
                        />
                    }
                >
                    <AYFCityConfig />
                </SmartSelectStep>
            );
        default:
            return <div></div>;
    }
}

export default AYFStepMapper;
