import { Project } from '../../../@Types/Project';
import styles from './ProjectCard.module.css';

interface ProjectCardProps {
    project: Project;
    nTickets: number;
    handleClick: Function;
}

function ProjectCard({
    project,
    nTickets,
    handleClick,
}: ProjectCardProps): JSX.Element {
    return (
        <div
            className={styles.container}
            onClick={(): void => {
                handleClick();
            }}
        >
            <div className={styles.top}>
                <div className={styles.topLbl}>{project.name}</div>
            </div>
            <div className={styles.contentContainer}>
                <span className={styles.spanNTickets}>{nTickets ?? 0}</span>{' '}
                caso
                {nTickets === 1 ? ' ' : 's '}
                pendiente{nTickets === 1 ? '' : 's'}
            </div>
        </div>
    );
}
export default ProjectCard;
