import styles from './SelectorStep.module.css';
import { OptionTypes } from '../../../constants/OptionTypes';
import RoundedTextField from '../../RoundedTextField/RoundedTextField';
import RoundedSelect from '../../RoundedSelect/RoundedSelect';
import Option from './Option/Option';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { GenericStepProps } from '../../GenericFormEditor/Step/Step';
import { calcStepWidth } from '../../GenericFormEditor/StepFunctions';
import { cloneElement } from 'react';
import { GLocation } from '../../../@Types/FormTypes/LocationTypes';
import { GSelector } from '../../../@Types/GenericFormSteps';
import LocationTypes from '../../../constants/LocationTypes';
import React from 'react';

interface SelectorStepProps {
    /** children to render */
    children?: React.ReactElement<any, string>;
}

type ArrayElement<ArrayType extends readonly unknown[]> =
    ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

function SelectorStep<StepType extends GSelector, U, L extends GLocation>({
    size,
    step,
    editing,
    children,
    location,
    updateStep,
    ...others
}: GenericStepProps<StepType, U, L> & SelectorStepProps): JSX.Element {
    const mapOptions = (): JSX.Element[] => {
        return step.options.map((option, index) => (
            <Option<StepType, U, L>
                {...others}
                key={index}
                location={{
                    type: LocationTypes.SELECTOR,
                    idRootStep: location.idRootStep,
                    idStep: step.id,
                    indexOption: index,
                    indexStep: null,
                }}
                option={option as any}
                handleDeleteOption={(): void => {
                    const tempStep = { ...step, options: [...step.options] };
                    tempStep.options.splice(index, 1);
                    updateStep(tempStep);
                }}
                size={size}
                updateOption={(
                    option: ArrayElement<StepType['options']>
                ): void => {
                    const tempStep = { ...step, options: [...step.options] };
                    tempStep.options[index] = { ...option };
                    updateStep(tempStep);
                }}
                step={step}
            />
        ));
    };

    if (editing) {
        return (
            <div className={styles.editingContainer}>
                <div className={styles.inputContainer}>
                    <RoundedTextField
                        label="Etiqueta"
                        value={step.label ?? ''}
                        onChange={(e): void => {
                            updateStep({ ...step, label: e.target.value });
                        }}
                    ></RoundedTextField>
                </div>
                <RoundedTextField
                    label="Descripción"
                    value={step.description ?? ''}
                    onChange={(e): void => {
                        updateStep({
                            ...step,
                            description: e.target.value,
                        });
                    }}
                ></RoundedTextField>
                {children &&
                    cloneElement(children, {
                        step,
                        updateStep,
                        uniqueSteps: others.uniqueSteps,
                        updateUniqueSteps: others.updateUniqueSteps,
                    })}
                <div className={styles.optionsLabel}>Opciones: </div>
                <div className={styles.optionsList}>
                    {mapOptions()}
                    <div
                        className={styles.addOption}
                        onClick={(): void => {
                            const tempStep = {
                                ...step,
                                options: [...step.options],
                            };
                            tempStep.options.push({
                                value: 'Opción',
                                label: 'Opción',
                                type: OptionTypes.DEFAULT,
                            });
                            updateStep(tempStep);
                        }}
                    >
                        <div className={styles.addIconContainer}>
                            <AddRoundedIcon fontSize="inherit" />
                        </div>
                        <div className={styles.addOptionLbL + ' noselect'}>
                            Agregar Opción
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div
                style={{
                    width: calcStepWidth(step.size, size),
                    maxWidth: '100%',
                    minHeight:
                        step.description || step.required ? '50px' : '38px',
                }}
            >
                <RoundedSelect
                    fullWidth
                    value=""
                    backgroundColor="var(--primary1)"
                    label={step.label}
                    required={step.required}
                    helperText={step.description}
                    containerMargin="0px"
                    height={'31px'}
                ></RoundedSelect>
            </div>
        );
    }
}

export default React.memo(SelectorStep, (prev, next) => {
    const { step, stepEqualityChecker } = prev;
    const { step: newStep } = next;
    if (
        step.size !== newStep.size ||
        step.label !== newStep.label ||
        step.maxSize !== newStep.maxSize ||
        step.required !== newStep.required ||
        step.searchable !== newStep.searchable ||
        step.description !== newStep.description ||
        step.options !== newStep.options
    ) {
        return false;
    }

    return stepEqualityChecker?.(prev, next) ?? true;
}) as typeof SelectorStep;
