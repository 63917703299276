import { CustomStepProps } from '@arquimedes.co/eureka-forms/dist/FormSteps/CustomStep';
import StepMapper from '@arquimedes.co/eureka-forms/dist/FormSteps/Step';

import styles from '@arquimedes.co/eureka-forms/dist/FormSteps/TextInputStep/MaterialTextInputStep/MaterialTextInputStep.module.css';
import FormStepTypes from '@arquimedes.co/eureka-forms/dist/constants/FormStepTypes';
import { Client } from '../../../@Types/Client';
import { useFormStep } from '@arquimedes.co/eureka-forms/dist/FormSteps/StepHooks';
import { TextInput } from '@arquimedes.co/eureka-forms/dist/@Types/FormStep';
import { calcStepWidth } from '@arquimedes.co/eureka-forms/dist/FormSteps/StepFunctions';
import RoundedTextField from '@arquimedes.co/eureka-forms/dist/Shared/RoundedTextField/RoundedTextField';
import RoundedPhoneInput from '@arquimedes.co/eureka-forms/dist/Shared/RoundedPhoneInput/RoundedPhoneInput';
import { useEffect } from 'react';
import ClientInfoTypes from '../../../constants/ClientInfoTypes';
import StringValidationTypes from '../../../constants/StringValidationTypes';
import {
    isEmptyPhoneNumber,
    isValidPhoneNumber,
} from '../../../utils/PhoneFunctions';

function ClientInfoTypeStep(
    props: CustomStepProps & { client: Client | undefined }
): JSX.Element {
    const { step } = props;
    switch (step.type) {
        case FormStepTypes.TEXTINPUT:
            if (
                step.clientInfoType === ClientInfoTypes.CEL ||
                step.validation?.type === (StringValidationTypes.PHONE as any)
            ) {
                return <ClientInfoTypePhoneInput {...props} step={step} />;
            }
            return <ClientInfoTypeTextInput {...props} step={step} />;
        default:
            return <StepMapper {...props} />;
    }
}
export default ClientInfoTypeStep;

function ClientInfoTypeTextInput({
    step,
    client,
    form,
    widthStats,
    postview,
    formStyle,
    editable,
}: Omit<CustomStepProps, 'step'> & {
    client: Client | undefined;
    step: TextInput;
}): JSX.Element {
    const validation = step.validation;
    const { currentBreakPoint } = widthStats;

    const { ref, value, onChange, error, field } = useFormStep<string>(step, {
        defaultValue: '',
        debounce: true,
        rules: {
            required: step.required ? 'Este campo es obligatorio' : undefined,
            pattern: validation
                ? {
                      value: new RegExp(
                          validation.type === 'EMAIL'
                              ? // eslint-disable-next-line no-control-regex
                                /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
                              : validation.value
                      ),
                      message: validation.message,
                  }
                : undefined,
        },
    });

    useEffect(() => {
        if (step.clientInfoType) {
            onChange(client?.[step.clientInfoType as ClientInfoTypes] ?? '');
        }
    }, [client]);

    return (
        <div
            className={styles.container}
            style={{
                width:
                    currentBreakPoint <= step.size
                        ? '100%'
                        : calcStepWidth(step.size, form.size),
                minHeight:
                    step.description ||
                    (!postview && editable && (step.required || validation))
                        ? '55px'
                        : '43px',
            }}
        >
            <RoundedTextField
                {...field}
                inputRef={ref}
                value={value}
                onChange={(e): void => onChange(e.target.value)}
                data-testid={step.id}
                label={step.label}
                cantEdit={!editable || postview}
                required={step.required}
                fontWeight={400}
                focusColor={formStyle.primaryColor}
                outlineColor={formStyle.outlineColor}
                textColor={formStyle.textColor}
                errorColor={formStyle.errorColor}
                backgroundColor={formStyle.stepBackgroundColor}
                helperText={error?.message ?? step.description}
                helperTextColor={formStyle.descriptionTextColor}
                error={!!error}
            ></RoundedTextField>
        </div>
    );
}

function ClientInfoTypePhoneInput({
    step,
    client,
    form,
    widthStats,
    postview,
    formStyle,
    editable,
}: Omit<CustomStepProps, 'step'> & {
    client: Client | undefined;
    step: TextInput;
}): JSX.Element {
    const { currentBreakPoint } = widthStats;

    const { ref, value, onChange, error, field } = useFormStep<string>(step, {
        defaultValue: '',
        debounce: true,
        rules: {
            required: step.required ? 'Este campo es obligatorio' : undefined,
            validate: (phone) => {
                if (isValidPhoneNumber(phone)) return true;
                if (!step.required && isEmptyPhoneNumber(phone)) return true;
                return (
                    step.validation?.message ?? 'Número de teléfono inválido'
                );
            },
        },
    });

    useEffect(() => {
        if (step.clientInfoType) {
            onChange(client?.[step.clientInfoType as ClientInfoTypes] ?? '');
        }
    }, [client]);

    return (
        <div
            className={styles.container}
            style={{
                width:
                    currentBreakPoint <= step.size
                        ? '100%'
                        : calcStepWidth(step.size, form.size),
                minHeight:
                    step.description || (!postview && editable)
                        ? '55px'
                        : '43px',
            }}
        >
            <RoundedPhoneInput
                {...field}
                inputRef={ref}
                value={value}
                onChange={onChange}
                data-testid={step.id}
                label={step.label}
                cantEdit={!editable || postview}
                required={step.required}
                fontWeight={400}
                focusColor={formStyle.primaryColor}
                outlineColor={formStyle.outlineColor}
                textColor={formStyle.textColor}
                errorColor={formStyle.errorColor}
                backgroundColor={formStyle.stepBackgroundColor}
                helperText={error?.message ?? step.description}
                helperTextColor={formStyle.descriptionTextColor}
                error={!!error}
            />
        </div>
    );
}
