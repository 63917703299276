import styles from './Autoscale.module.css';
import FlowIcon from '../../../../../../Icons/FlowIcon';
import { useCurrentProject } from '../../../../../../hooks';
interface AreaProps {}

/**
 * Component that displays the content of the assign to Area action
 */
function AutoScaleComponent({}: AreaProps): JSX.Element {
    const project = useCurrentProject();

    return (
        <div className={styles.container}>
            <div className={styles.flowContainer}>
                <div className={styles.iconContainer}>
                    <FlowIcon
                        style={{ width: 28, height: 28 }}
                        fill={'var(--secondary)'}
                    />
                </div>
                <label className={styles.nameLbl}>{project.name}</label>
            </div>
        </div>
    );
}
export default AutoScaleComponent;
