import GearsIcon from '../../../../Icons/GearsIcon';
import { DraftEntityDataTypes } from '../../../../constants/Draft/DraftEntityDataTypes';
import Context from '../Context';
import { UserTypes } from '../../../../constants/UserTypes';
import { useContext } from 'react';
import { GenericContextProps, UserTypeContext } from '../TextEditorContext';
import { DraftContext } from '../../../../@Types/Draft/DraftContext';
import EurekaElementMenuGroup from '../../../@Menus/ElementMenu/EurekaElementMenuGroup';

interface OtherContextsProps extends GenericContextProps {
    context: DraftContext | undefined;
}

function OtherContexts({ context, ...props }: OtherContextsProps): JSX.Element {
    const userTypeContext = useContext(UserTypeContext);
    const userType = userTypeContext ?? UserTypes.AGENT;
    return (
        <EurekaElementMenuGroup
            {...props}
            icon={(props): JSX.Element => (
                <GearsIcon {...props} fill={'var(--dark-grey)'} />
            )}
            label={'Otros'}
            renderElements={(): JSX.Element => {
                return (
                    <>
                        {context?.ticket && (
                            <Context
                                {...props}
                                entityStyles={[]}
                                autoEdit={userTypeContext === null}
                                data={{
                                    type: DraftEntityDataTypes.TICKET_LINK,
                                    linkType: userType,
                                }}
                            />
                        )}
                        <Context
                            {...props}
                            autoEdit
                            data={{
                                type: DraftEntityDataTypes.CONDITION_MET,
                                condition: {
                                    type: 'DEFAULT',
                                } as any,
                            }}
                        />
                        {!context?.isPublic && (
                            <Context
                                {...props}
                                autoEdit
                                data={{
                                    type: DraftEntityDataTypes.DATE,
                                    time: {},
                                }}
                            />
                        )}
                    </>
                );
            }}
        />
    );
}

export default OtherContexts;
