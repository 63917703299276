import styles from './DateActivityAction.module.css';
import EventRoundedIcon from '@material-ui/icons/EventRounded';
import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { getLocale } from '../../../../../../..';
import { EurekaDraft } from '../../../../../../../@Types/Draft/Draft';
import { EditorState, convertFromRaw } from 'draft-js';
import DraftRenderer from '../../../../../../../shared/DraftRenderer/DraftRenderer';
import LocationIcon from '../../../../../../../Icons/LocationIcon';
import { stringToDraft } from '../../../../../../../utils/draftFunctions';
interface DateActivityActionProps {
    date: Date;
    subject?: string;
    body?: EurekaDraft;
    location?: string;
    cancelled: boolean;
}

function DateActivityAction({
    date,
    subject,
    body,
    location,
    cancelled = false,
}: DateActivityActionProps): JSX.Element {
    if (!date) return <></>;
    let dateFormat = 'PPPp';
    const formattedDate = format(date, dateFormat, {
        locale: getLocale(),
    });
    const content = useMemo(
        () =>
            EditorState.createWithContent(
                convertFromRaw(body ?? stringToDraft(''))
            ),
        [body]
    );
    const hasContent = content.getCurrentContent().hasText();
    return (
        <div className={styles.container}>
            <div className={styles.dateContainer} title={formattedDate}>
                <div className={styles.iconContainer}>
                    <EventRoundedIcon fontSize="inherit" />
                </div>
                <label
                    className={styles.dateLbl}
                    style={
                        cancelled
                            ? {
                                  textDecoration: 'line-through',
                              }
                            : {}
                    }
                >
                    {formattedDate}
                </label>
            </div>

            {(hasContent || location) && (
                <div>
                    {subject && subject.length > 0 && hasContent && (
                        <label className={styles.subjectLbl}>{subject}</label>
                    )}
                    {hasContent && (
                        <div className={styles.messageContainer}>
                            <DraftRenderer editorState={content} />
                        </div>
                    )}
                    {location && (
                        <div className={styles.locationLbl}>
                            <LocationIcon
                                style={{
                                    height: 20,
                                    width: 20,
                                }}
                                fill="var(--secondary)"
                            />
                            {location}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
export default DateActivityAction;
