import { makeStyles, Icon } from '@material-ui/core';
import { Client } from '../../../@Types/Client';
import {
    loadClientsById,
    loadClients,
} from '../../../controllers/ClientsController/ClientsService';
import RoundedGenericPicker, {
    RoundedGenericPickerProps,
} from '../RoundedGenericPicker/RoundedGenericPicker';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';

function RoundedClientPicker({
    label = 'Cliente',
    companyFilter,
    ...props
}: Omit<
    RoundedGenericPickerProps<Client>,
    'calcLbl' | 'loadInitialType' | 'loadElements' | 'elementLabel'
> & { companyFilter?: string }): JSX.Element {
    return (
        <RoundedGenericPicker<Client>
            {...props}
            IconComponent={ClientIcon}
            label={label}
            elementLabel="Cliente"
            calcLbl={(client): string => {
                const name = client.lastName
                    ? client.name + ' ' + client.lastName
                    : client.name;
                return name?.trim()
                    ? name
                    : client.email ??
                          client.identifier ??
                          client.cel ??
                          'Sin Nombre';
            }}
            loadInitialType={loadClientsById}
            loadElements={async (
                page: number,
                pageSize: number,
                search: string | undefined
            ): Promise<Client[]> => {
                return await loadClients(
                    page,
                    pageSize,
                    {
                        idCompany: companyFilter,
                    },
                    undefined,
                    search
                );
            }}
        />
    );
}
export default RoundedClientPicker;

function ClientIcon(props: any): JSX.Element {
    const useStyles = makeStyles({
        iconRoot: {
            position: 'absolute',
            right: '7px',
        },
        imageIcon: {
            height: 23,
            width: 23,
        },
    });
    const classes = useStyles();
    return (
        <Icon classes={{ root: classes.iconRoot }} className={props.className}>
            <PersonRoundedIcon fontSize="inherit" />
        </Icon>
    );
}
