import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import SubjectIcon from '@material-ui/icons/Subject';
import { Icon, MenuItem } from '@material-ui/core';
import RoundedSelect, {
    RoundedSelectProps,
} from '../../RoundedSelect/RoundedSelect';
import React from 'react';
import styles from './RoundedFormStepPicker.module.css';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import GradeRoundedIcon from '@material-ui/icons/GradeRounded';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import PlaylistAddRoundedIcon from '@material-ui/icons/PlaylistAddRounded';
import {
    EntityValuePicker,
    FormStep,
} from '../../../@Types/FormTypes/FormStep';
import FormStepTypes from '../../../constants/FormStepTypes';
import TitleIcon from '@material-ui/icons/Title';
import CBRFormStepTypes from '../../../constants/Construction/CBRFormStepTypes';
import AYFFormStepTypes from '../../../constants/Construction/AYFFormStepTypes';
import { CBRFormStep } from '../../../@Types/FormTypes/CBRFormStep';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import EntityIcon from '../../SmartIcons/EntityIcon';
import { EntityValueProjectValue } from '../../../@Types/Project';
import { AYFFormStep } from '../../../@Types/FormTypes/AYFFormStep';
import ScheduleRounded from '@material-ui/icons/ScheduleRounded';
import ApiIcon from '../../../Icons/ApiIcon';

type AllStepTypes = FormStepTypes | CBRFormStepTypes | AYFFormStepTypes;

type StepType =
    | Exclude<FormStep, EntityValuePicker>
    | EntityValueProjectValue
    | CBRFormStep
    | AYFFormStep;

export interface RoundedFormStepPickerProps
    extends Omit<RoundedSelectProps, 'handleUpdate' | 'value'> {
    steps: StepType[];
    /** Currently selected FormStep id */
    value: string | undefined;
    /** The posible steps to select */
    stepTypes?: AllStepTypes[];
    /** function called when value changes */
    handleUpdate: (idStep: string, stepType: AllStepTypes) => void;
    allowCustom?: boolean;
}
function RoundedFormStepPicker({
    value,
    steps,
    allowCustom = false,
    stepTypes = allowCustom
        ? [
              ...Object.values(FormStepTypes),
              ...Object.values(CBRFormStepTypes),
              ...Object.values(AYFFormStepTypes),
          ]
        : Object.values(FormStepTypes),
    handleUpdate,
    ...others
}: RoundedFormStepPickerProps): JSX.Element {
    return (
        <RoundedSelect
            value={value}
            paddingLeft={0}
            handleUpdate={(event): void => {
                const step = steps.find(
                    (step) => step.id === event.target.value
                );
                if (step) {
                    handleUpdate(step.id, step.type);
                }
            }}
            {...others}
        >
            {steps
                .filter((step) => stepTypes.includes(step.type))
                .map((step) => {
                    return (
                        <MenuItem
                            key={step.id}
                            value={step.id}
                            style={{
                                paddingLeft: 0,
                            }}
                        >
                            <StepComponent step={step} />
                        </MenuItem>
                    );
                })}
        </RoundedSelect>
    );
}
export default RoundedFormStepPicker;

export function StepComponent({ step }: { step: StepType }): JSX.Element {
    const classifiers = useSelector(
        (state: RootState) => state.site.classifiers
    );
    switch (step.type) {
        case FormStepTypes.TITLE: {
            return (
                <div className={styles.step}>
                    <div className={styles.icon}>
                        <div className={styles.nameLbl}>
                            <TitleIcon fontSize="inherit" />
                        </div>
                    </div>
                    {step.title ? step.title : step.description}
                </div>
            );
        }
        case FormStepTypes.TEXTINPUT: {
            return (
                <div className={styles.step}>
                    <div className={styles.icon}>
                        <div className={styles.textInputContainer}>A..</div>
                    </div>
                    {step.label}
                </div>
            );
        }
        case FormStepTypes.CLASSIFIER_SELECTOR:
            const classifier = classifiers[step.idClassifier ?? ''];
            return (
                <div className={styles.step}>
                    <div className={styles.icon}>
                        <div className={styles.selectInputContainer}>
                            <ArrowDropDownIcon fontSize="inherit" />
                        </div>
                    </div>
                    {step.label
                        ? step.label
                        : classifier
                        ? classifier.name
                        : 'Por Definir'}
                </div>
            );
        case FormStepTypes.SELECTOR: {
            return (
                <div className={styles.step}>
                    <div className={styles.icon}>
                        <div className={styles.selectInputContainer}>
                            <ArrowDropDownIcon fontSize="inherit" />
                        </div>
                    </div>
                    {step.label}
                </div>
            );
        }
        case FormStepTypes.DATEPICKER: {
            return (
                <div className={styles.step}>
                    <div className={styles.icon}>
                        <div className={styles.dateInputContainer}>
                            <CalendarTodayRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                    {step.label}
                </div>
            );
        }
        case FormStepTypes.TIMEPICKER: {
            return (
                <div className={styles.step}>
                    <div className={styles.icon}>
                        <div className={styles.dateInputContainer}>
                            <ScheduleRounded fontSize="inherit" />
                        </div>
                    </div>
                    {step.label}
                </div>
            );
        }
        case FormStepTypes.AGENTPICKER: {
            return (
                <div className={styles.step}>
                    <div className={styles.icon}>
                        <div className={styles.agentInputContainer}>
                            <PersonRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                    {step.label}
                </div>
            );
        }
        case FormStepTypes.TEXTAREA: {
            return (
                <div className={styles.step}>
                    <div className={styles.icon}>
                        <div className={styles.textAreaContainer}>
                            <SubjectIcon fontSize="inherit" />
                        </div>
                    </div>
                    {step.label}
                </div>
            );
        }
        case FormStepTypes.FILEUPLOAD: {
            return (
                <div className={styles.step}>
                    <div className={styles.icon}>
                        <div className={styles.selectInputContainer}>
                            <DescriptionRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                    {step.label}
                </div>
            );
        }
        case FormStepTypes.CHECKBOX: {
            return (
                <div className={styles.step}>
                    <div className={styles.icon}>
                        <div className={styles.checkBoxIcon}>
                            <CheckBoxRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                    {step.label}
                </div>
            );
        }
        case FormStepTypes.COMPANYPICKER: {
            return (
                <div className={styles.step}>
                    <div className={styles.icon}>
                        <div className={styles.agentInputContainer}>
                            <div className={styles.companyIconContainer}>
                                <Icon fontSize="inherit">
                                    <img
                                        className={styles.companyImg}
                                        src="/media/icons/bluehandshake.svg"
                                    />
                                </Icon>
                            </div>
                        </div>
                    </div>
                    {step.label}
                </div>
            );
        }
        case FormStepTypes.ENTITYVALUEPICKER: {
            return <EntityValuePickerComponent step={step} />;
        }
        case FormStepTypes.RATING: {
            return (
                <div className={styles.step}>
                    <div className={styles.icon}>
                        <div className={styles.titleIcon}>
                            <GradeRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                    {step.label}
                </div>
            );
        }
        case FormStepTypes.MAPPER: {
            return (
                <div className={styles.step}>
                    <div className={styles.icon}>
                        <div className={styles.mapperIcon}>
                            <PlaylistAddRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                    {step.label}
                </div>
            );
        }
        case FormStepTypes.API_SELECTOR: {
            return (
                <div className={styles.step}>
                    <div className={styles.icon}>
                        <ApiIcon />
                    </div>
                    {step.label}
                </div>
            );
        }
        default:
            /** TODO: Hacer esto bien. icono generico de CBR/Proyecto. */
            if (step.type.startsWith('CBR_')) {
                const cbrStep = step as any as CBRFormStep;
                return (
                    <div className={styles.step}>
                        <div className={styles.icon}>
                            <img
                                className={styles.sincoIcon}
                                src="/media/icons/sinco-cbr.svg"
                            />
                        </div>
                        {cbrStep.label}
                    </div>
                );
            } else if (step.type.startsWith('AYF_')) {
                const cbrStep = step as any as CBRFormStep;
                return (
                    <div className={styles.step}>
                        <div className={styles.sincoIcon}>
                            <img
                                className={styles.sincoImg}
                                src="/media/icons/sinco-ayf.svg"
                            />
                        </div>
                        {cbrStep.label}
                    </div>
                );
            }
            return <div></div>;
    }
}
function EntityValuePickerComponent({
    step,
}: {
    step: EntityValueProjectValue;
}): JSX.Element {
    const entity = useSelector(
        (state: RootState) => state.site.entities[step.idEntity ?? '']
    );
    return (
        <div className={styles.step}>
            <div className={styles.icon}>
                <div className={styles.companyIconContainer}>
                    <EntityIcon
                        fill={'var(--secondary)'}
                        idEntity={entity?._id}
                    />
                </div>
            </div>
            {step.label}
        </div>
    );
}
