import actionStyles from '../Action/Action.module.css';
import styles from './Activity.module.css';
interface InternalActivityProps {
    children?: any;
    title: string;
}

function InternalActivity({
    children,
    title,
}: InternalActivityProps): JSX.Element {
    return (
        <div
            className={actionStyles.container}
            style={{
                marginLeft: 60,
                marginRight: 60,
                marginTop: 20,
                justifyContent: 'center',
            }}
        >
            <div className={styles.actionContainer}>
                <div className={styles.titleContainer}>
                    <div className={actionStyles.actionTitle}>{title}</div>
                </div>
                <div className={styles.contentContainer}>{children}</div>
            </div>
        </div>
    );
}

export default InternalActivity;
