import React, { useState } from 'react';
import { EmailEntry } from '../../../@Types/Entry';
import EntityPropertyTypes from '../../../constants/EntityPropertyTypes';
import RoundedEntityPropertyPicker from '../../../shared/@Pickers/RoundedEntityPropertyPicker/RoundedEntityPropertyPicker';
import Toggle from '../../../shared/Toggle/Toggle';
import styles from './EntryDetail.module.css';
import { useAppSelector } from '../../../hooks';
interface EntryEmailProps {
    entry: EmailEntry;
    /** Function called when an agent's value needs updating */
    handleUpdate: Function;
}
function EntryEmailComponent({
    entry,
    handleUpdate,
}: EntryEmailProps): JSX.Element {
    const [matchCompany, setMatchCompany] = useState(
        entry.idDomainCompanyMatcher !== undefined
    );
    const idCompanyEntity = useAppSelector(
        (state) => state.site.organization?.idCompanyEntity
    );

    return (
        <div className={styles.emailContainer}>
            <div className={styles.descriptionLabel}>Dirección de correo</div>{' '}
            <div className={styles.description}>{entry.email}</div>
            {idCompanyEntity && (
                <React.Fragment>
                    <div className={styles.toggleLabelContainer}>
                        Vincular Empresa con Dominio:
                        <div className={styles.toggleContainer}>
                            <Toggle
                                checked={matchCompany}
                                onChange={(checked: boolean): void => {
                                    if (!checked) {
                                        handleUpdate(
                                            entry,
                                            'idDomainCompanyMatcher',
                                            null
                                        );
                                    }
                                    setMatchCompany(checked);
                                }}
                            />
                        </div>
                    </div>
                    {matchCompany && (
                        <div className={styles.propertySelectorContainer}>
                            <RoundedEntityPropertyPicker
                                idEntity={idCompanyEntity}
                                value={entry.idDomainCompanyMatcher}
                                label="Propiedad"
                                autoWidth
                                propertyTypes={[EntityPropertyTypes.TEXTINPUT]}
                                handleUpdate={(idProperty): void => {
                                    handleUpdate(
                                        entry,
                                        'idDomainCompanyMatcher',
                                        idProperty
                                    );
                                }}
                            />
                        </div>
                    )}
                </React.Fragment>
            )}
        </div>
    );
}

export default EntryEmailComponent;
