import { useRef, useState } from 'react';
import styles from './EurekaElementMenu.module.css';
import { IconProps } from '../../../Icons/@IconTypes';
import { Popper, PopperProps } from '@material-ui/core';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import { EurekaElementMenuElementStyle } from './EurekaElementMenuElement';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';

interface EurekaElementMenuGroupProps {
    label: string;
    icon: (props: IconProps) => JSX.Element;
    renderElements: (props: {
        zIndex: number;
        style: EurekaElementMenuElementStyle;
    }) => JSX.Element | JSX.Element[];
    zIndex: number;
    style?: EurekaElementMenuElementStyle;
    onClick?: () => void;
    placement?: PopperProps['placement'];
}

function EurekaElementMenuGroup({
    label,
    icon,
    zIndex,
    onClick,
    renderElements,
    style = { fontSize: 16 },
    placement = 'right-start',
}: EurekaElementMenuGroupProps): JSX.Element {
    const [hovering, setHovering] = useState(false);
    const [hoveringMenu, setHoveringMenu] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const { minWidth, iconWidth } = style;

    return (
        <>
            {(hovering || hoveringMenu) && (
                <Popper
                    open={true}
                    anchorEl={ref.current}
                    placement={placement}
                    style={{ zIndex: zIndex + 1 }}
                    modifiers={{
                        preventOverflow: {
                            enabled: true,
                            priority: ['top', 'bottom', 'left', 'right'],
                            boundariesElement: 'viewport',
                            padding: 20,
                        },
                    }}
                >
                    <div
                        className={styles.menuContainer}
                        style={{
                            marginLeft: -2,
                            marginTop: -1,
                        }}
                        onMouseEnter={(): void => setHoveringMenu(true)}
                        onMouseLeave={(): void => setHoveringMenu(false)}
                    >
                        {renderElements({ zIndex: zIndex + 1, style })}
                    </div>
                </Popper>
            )}
            <div
                className={styles.container}
                ref={ref}
                style={{
                    fontSize: style.fontSize,
                    backgroundColor: hoveringMenu
                        ? 'var(--light-grey)'
                        : undefined,
                    minWidth,
                    cursor: onClick ? 'pointer' : 'default',
                }}
                onClick={onClick}
                onMouseEnter={(): void => setHovering(true)}
                onMouseLeave={(): void => setHovering(false)}
            >
                <div
                    className={styles.icon}
                    style={{
                        width: iconWidth,
                    }}
                >
                    {icon({
                        fill: 'var(--secondary)',
                        contrast: 'var(--primary1)',
                        style: {
                            width: 26,
                            height: 26,
                            color: 'var(--secondary)',
                        },
                    })}
                </div>
                <div
                    className={styles.label}
                    style={{
                        paddingRight: 10,
                    }}
                >
                    {label}
                </div>
                <div className={styles.arrowContainer}>
                    {placement.startsWith('bottom') ? (
                        <ArrowDownwardRoundedIcon fontSize="inherit" />
                    ) : (
                        <ArrowForwardRoundedIcon fontSize="inherit" />
                    )}
                </div>
            </div>
        </>
    );
}

export default EurekaElementMenuGroup;
