import React, { Component, useState } from 'react';
import classNames from 'classnames';
import { CircularProgress, ClickAwayListener } from '@material-ui/core';
import VerticalAlignBottomRoundedIcon from '@material-ui/icons/VerticalAlignBottomRounded';
import VerticalAlignCenterRoundedIcon from '@material-ui/icons/VerticalAlignCenterRounded';
import VerticalAlignTopRoundedIcon from '@material-ui/icons/VerticalAlignTopRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import styles from './ImageComponent.module.css';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import {
    PayloadEditorActions,
    usePayloadEditorDispatch,
    usePayloadEditorSelector,
} from '../../../controllers/PayloadEditorController/PayloadEditorSlice';
import { PayloadEditorStateTypes } from '../../../controllers/PayloadEditorController/PayloadEditorTypes';

function ImageComponent({ block, preview }: any): JSX.Element {
    const [active, setActive] = useState(false);
    const [hovering, setHovering] = useState(false);
    const dispatch = usePayloadEditorDispatch();
    const entityKey = block.getEntityAt(0);
    const entityData = usePayloadEditorSelector((state) =>
        state.editorState.getCurrentContent().getEntity(entityKey).getData()
    );
    const { alignment, width, alt, imageKey } = entityData;
    const image = usePayloadEditorSelector((state) => state.images[imageKey]);

    if (!image) return <div></div>;

    const setEntityAlignment: Function = (alignment: any): void => {
        dispatch(
            PayloadEditorActions.updateAtomicEntityData({
                entityKey,
                entityData: { ...entityData, alignment },
            })
        );
    };

    const renderAlignmentOptions = (alignment: any): Object => {
        return (
            <div className={'rdw-image-alignment-options-popup'}>
                <div
                    onClick={(): void => setEntityAlignment('left')}
                    className={
                        (alignment === 'left'
                            ? styles.selectedBtn
                            : styles.btn) + ' rdw-image-alignment-option'
                    }
                >
                    <VerticalAlignBottomRoundedIcon fontSize="inherit" />
                </div>
                <div
                    onClick={(): void => setEntityAlignment(undefined)}
                    className={
                        (alignment === 'none' || alignment === undefined
                            ? styles.selectedBtn
                            : styles.btn) + ' rdw-image-alignment-option'
                    }
                >
                    <VerticalAlignCenterRoundedIcon fontSize="inherit" />
                </div>
                <div
                    onClick={(): void => setEntityAlignment('right')}
                    className={
                        (alignment === 'right'
                            ? styles.selectedBtn
                            : styles.btn) + ' rdw-image-alignment-option'
                    }
                >
                    <VerticalAlignTopRoundedIcon fontSize="inherit" />
                </div>
                <div
                    onClick={(): void => {
                        dispatch(
                            PayloadEditorActions.deleteBlock(block.getKey())
                        );
                    }}
                    className="rdw-image-alignment-option"
                    style={{ color: 'var(--secondary)' }}
                >
                    <DeleteRoundedIcon fontSize="inherit" />
                </div>
            </div>
        );
    };

    return (
        <span
            className={
                classNames('rdw-image-alignment-editor', {
                    'rdw-image-left': alignment === 'left',
                    'rdw-image-right': alignment === 'right',
                    'rdw-image-center': !alignment || alignment === 'center',
                }) + ' noselect'
            }
        >
            <ClickAwayListener
                mouseEvent="onMouseDown"
                onClickAway={(): void => {
                    setActive(false);
                }}
            >
                <span
                    className="rdw-image-imagewrapper noselect"
                    onClick={(): void => setActive(true)}
                    style={{ cursor: 'pointer' }}
                    onMouseEnter={(): void => {
                        setHovering(true);
                    }}
                    onMouseLeave={(): void => {
                        setHovering(false);
                    }}
                >
                    {image.state === PayloadEditorStateTypes.UPLOADING && (
                        <div className={styles.loaderContainer}>
                            <div className={styles.loader}>
                                <CircularProgress
                                    size={40}
                                    style={{
                                        color: 'var(--secondary)',
                                    }}
                                />
                            </div>
                        </div>
                    )}

                    {image.state === PayloadEditorStateTypes.ERROR && (
                        <div className={styles.errorContainer}>
                            <div
                                className={styles.warningIcon}
                                style={{ fontSize: 30 }}
                            >
                                <WarningRoundedIcon fontSize="inherit" />
                            </div>
                            <label
                                className={styles.warningLbl}
                                style={{ fontSize: 15 }}
                            >
                                Error al subir esta imagen
                            </label>
                        </div>
                    )}

                    <img
                        src={image.url}
                        alt={alt}
                        style={{
                            height: 'auto',
                            width,
                            maxWidth: '100%',
                            opacity:
                                preview ||
                                image.state ===
                                    PayloadEditorStateTypes.UPLOADING ||
                                image.state === PayloadEditorStateTypes.ERROR
                                    ? 0.4
                                    : 1,
                        }}
                    />
                    <div
                        className={
                            active
                                ? styles.imgWrapperActive
                                : hovering
                                ? styles.imgWrapperHovering
                                : styles.imgWrapper
                        }
                        style={
                            image.state === PayloadEditorStateTypes.ERROR
                                ? { borderColor: 'var(--error)' }
                                : {}
                        }
                    ></div>
                    {active && renderAlignmentOptions(alignment)}
                </span>
            </ClickAwayListener>
        </span>
    );
}

const getImageComponent = (config: any, preview: boolean): any =>
    class Image extends Component {
        render(): Object {
            return (
                <ImageComponent
                    config={config}
                    {...this.props}
                    preview={preview}
                />
            );
        }
    };

export default getImageComponent;
