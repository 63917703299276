import { useFormPreviousSteps } from '../../../pages/Forms/FormReducerFunctions';
import RoundedFormStepPicker, {
    RoundedFormStepPickerProps,
} from '../RoundedFormStepPicker/RoundedFormStepPicker';

interface PreviousFormStepsPickerProps
    extends Omit<RoundedFormStepPickerProps, 'steps'> {
    idStep: string;
}

function PreviousFormStepsPicker({
    idStep,
    ...props
}: PreviousFormStepsPickerProps): JSX.Element {
    const previousSteps = useFormPreviousSteps(idStep);

    return <RoundedFormStepPicker steps={previousSteps} {...props} />;
}

export default PreviousFormStepsPicker;
