import React from 'react';
import styles from './Openable.module.css';

interface OpenComponentProps {
    title: string;
    children: any;
    showOpen?: boolean;
}
export function OpenComponent({
    title,
    children,
    showOpen = false,
}: OpenComponentProps): JSX.Element {
    const [open, setOpen] = React.useState(showOpen);
    return (
        <div className={styles.container}>
            <div
                className={styles.title}
                style={{
                    cursor: 'pointer',
                }}
            >
                <div
                    className={styles.label}
                    onClick={(): void => {
                        if (!open) {
                            setOpen(true);
                        } else {
                            setOpen(false);
                        }
                    }}
                >
                    {title}
                </div>
                <img
                    alt={'>'}
                    className={styles.dropdownIcon}
                    src={
                        open
                            ? '/media/icons/treedownarrow.svg'
                            : '/media/icons/treerightarrow.svg'
                    }
                    onClick={(): void => {
                        setOpen(!open);
                    }}
                />
            </div>
            {open && children}
        </div>
    );
}
