import { makeStyles, Icon } from '@material-ui/core';
import RoundedGenericPicker, {
    RoundedGenericPickerProps,
} from '../../../@Pickers/RoundedGenericPicker/RoundedGenericPicker';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../utils/_store';
import EntityIcon from '../../../SmartIcons/EntityIcon';
import {
    Relationship,
    FullRelationship,
} from '../../../../@Types/Relationship';
import { EntityValue } from '../../../../@Types/EntityValue';
import React, { useEffect, useState } from 'react';
import Dialog from '../../../Dialog/Dialog';
import RelationshipDialog from '../../../RelationshipDialog/RelationshipDialog';
import produce, { current } from 'immer';
import { update } from '../../../../controllers/GenericMenuController/GenericMenuActions';
import { useDispatch } from 'react-redux';
import EntityRenderer from '../../EntityRenderer';
import { EntityValueFilters } from '../../../../controllers/EntityValuesController/EntityValuesReducer';
import { EntityRelationship } from '../../../../@Types/EntityTypes/Entity';
import {
    loadEntityValues,
    loadEntityValuesById,
} from '../../../../controllers/EntityValuesController/EntityValuesService';

function RoundedEntityRelationshipPicker({
    idStep,
    filters,
    entityValue,
    relationship,
    label,
    ...props
}: Omit<
    RoundedGenericPickerProps<FullRelationship, Relationship>,
    'calcLbl' | 'loadInitialType' | 'loadElements' | 'elementLabel'
> & {
    idStep: string;
    filters?: EntityValueFilters;
    entityValue?: EntityValue | Relationship;
    relationship: EntityRelationship;
}): JSX.Element {
    const { idEntity } = relationship;
    const [showConfig, setShowConfig] = useState<FullRelationship | null>(null);
    const [showView, setShowView] = useState<FullRelationship | null>(null);
    const dispatch = useDispatch();
    const entity = useSelector(
        (state: RootState) => state.site.entities[relationship.idEntity]
    );
    if (!relationship || !entity) return <div></div>;
    return (
        <React.Fragment>
            {showView && (
                <Dialog
                    open={showView !== null}
                    maxWidth={'90vw'}
                    onClose={(): void => {
                        setShowView(null);
                    }}
                    zIndex={1301}
                >
                    <ViewEntityValueDialog
                        idEntity={showView.idEntity}
                        entityValue={showView.entityValue}
                    />
                </Dialog>
            )}
            {showConfig && (
                <Dialog
                    open={showConfig !== null}
                    maxWidth={'90vw'}
                    onClose={(): void => {
                        setShowConfig(null);
                    }}
                    zIndex={1301}
                >
                    <RelationshipDialog
                        idEntity={idEntity}
                        relationship={showConfig}
                        entityValue={entityValue}
                        customSubmit={async (values): Promise<void> => {
                            const value = produce(props.value, (value) => {
                                for (const relationship of value) {
                                    if (
                                        relationship.idEntity ===
                                            showConfig.idEntity &&
                                        relationship.idEntityValue ===
                                            showConfig.idEntityValue
                                    ) {
                                        relationship.values = values;
                                        dispatch(
                                            update(
                                                idStep,
                                                current(relationship)
                                            )
                                        );
                                    }
                                }
                            });
                            props.handleUpdate?.(value as FullRelationship[]);
                            setShowConfig(null);
                        }}
                        relatedEntityValue={showConfig.entityValue}
                    />
                </Dialog>
            )}
            {showView && (
                <Dialog
                    open={showView !== null}
                    maxWidth={'90vw'}
                    onClose={(): void => {
                        setShowView(null);
                    }}
                    zIndex={1301}
                >
                    <ViewEntityValueDialog
                        idEntity={showView.idEntity}
                        entityValue={showView.entityValue}
                    />
                </Dialog>
            )}
            <RoundedGenericPicker<FullRelationship, Relationship>
                {...props}
                idMenu={idStep}
                IconComponent={EntityRelationshipIcon(entity._id)}
                handleView={(element: FullRelationship): void => {
                    setShowView(element);
                }}
                handleConfig={
                    relationship.rootSteps.length > 0
                        ? (element: FullRelationship): void => {
                              setShowConfig(element);
                          }
                        : undefined
                }
                label={label}
                keepOpen={showConfig !== null || showView !== null}
                elementLabel={entity.name}
                calcLbl={(relationship): string =>
                    relationship.entityValue?.values[entity.idLabelStep] ??
                    entity.name
                }
                calcIsDeleted={(relationship): boolean =>
                    relationship.entityValue && !relationship.entityValue.active
                }
                canChangeOrder
                isInitialType={isBaseRelationship}
                loadInitialType={async (
                    relationships: Relationship[]
                ): Promise<FullRelationship[]> => {
                    const ids = relationships.map(
                        (relationship) => relationship.idEntityValue
                    );
                    const entityValues = await loadEntityValuesById(
                        relationship.idEntity,
                        ids
                    );

                    const newRelationships: FullRelationship[] = [];
                    for (const relationship of relationships) {
                        const entityValue = entityValues.find(
                            (entityValue) =>
                                entityValue._id === relationship.idEntityValue
                        );
                        if (entityValue) {
                            newRelationships.push({
                                entityValue: entityValue,
                                idEntity: relationship.idEntity,
                                idEntityValue: relationship.idEntityValue,
                                values: relationship.values,
                            });
                        }
                    }
                    return newRelationships;
                }}
                calcId={(element): string => element.idEntityValue}
                loadElements={async (
                    page: number,
                    pageSize: number,
                    search: string | undefined
                ): Promise<FullRelationship[]> => {
                    const entityValues = await loadEntityValues(
                        entity,
                        page,
                        pageSize,
                        filters,
                        undefined,
                        search
                    );
                    return entityValues.map((entityValue) => ({
                        entityValue: { ...entityValue, active: true },
                        idEntity,
                        idEntityValue: entityValue._id,
                        values: {},
                    }));
                }}
            />
        </React.Fragment>
    );
}
export default RoundedEntityRelationshipPicker;

const isBaseRelationship = (
    elements: FullRelationship[] | Relationship[]
): elements is Relationship[] =>
    (elements[0] as FullRelationship)?.entityValue === undefined;

interface ViewEntityValueDialogProps {
    /** The id of the current entity */
    idEntity: string;
    entityValue: EntityValue;
    /** Function called to close the Dialog */
    onClose?: Function;
}

function ViewEntityValueDialog({
    idEntity,
    entityValue,
    onClose,
}: ViewEntityValueDialogProps): JSX.Element {
    const siteInfo = useSelector((state: RootState) => state.site);

    const entity = siteInfo.entities[idEntity];

    useEffect(() => {
        if (entity === undefined) {
            onClose?.();
        }
    }, []);

    if (!entity) return <div></div>;

    return (
        <div
            className={'standard-dialog'}
            style={{
                maxWidth: '80vw',
                minHeight: '50vh',
                maxHeight: '60vh',
                overflowY: 'auto',
            }}
        >
            <div
                style={{
                    paddingBottom: 20,
                    maxWidth: '100%',
                    width:
                        (entity.size.blockSize + entity.size.spacingSize) *
                        entity.size.blockNum,
                }}
            >
                <EntityRenderer
                    editable={false}
                    entity={entity}
                    entityValue={entityValue}
                />
            </div>
        </div>
    );
}

const EntityRelationshipIcon = (idEntity: string) =>
    function RelationshipIcon(props: any): JSX.Element {
        const useStyles = makeStyles({
            iconRoot: {
                position: 'absolute',
                right: '7px',
            },
            imageIcon: {
                height: 23,
                width: 23,
            },
        });
        const classes = useStyles();
        return (
            <Icon
                classes={{ root: classes.iconRoot }}
                className={props.className}
            >
                <EntityIcon
                    {...props}
                    className={classes.imageIcon}
                    idEntity={idEntity}
                />
            </Icon>
        );
    };
