import axiosInstance from '../../AxiosAPI';
import { Ticket } from '../../@Types/TicketTypes/Ticket';
import { ControlInfo360 } from './types';

export async function getClient360(identificacion: string): Promise<any> {
    try {
        const client360 = await axiosInstance.get(
            `/cbr/360/client/${identificacion}`
        );
        return client360.data;
    } catch (error) {
        // Mensaje de error
        return undefined;
    }
}

export async function getInmueble360(idAgrupacion: number): Promise<any> {
    try {
        const agrupacion = await axiosInstance.get(
            `/cbr/360/inmueble/${idAgrupacion}`
        );
        return agrupacion.data;
    } catch (error) {
        // Mensaje de error
        return undefined;
    }
}

export async function getTickets360(
    identificacion: string,
    idProject: string,
    idAgrupacion: number | undefined
): Promise<ControlInfo360> {
    try {
        const tickets = await axiosInstance.get(
            `/cbr/360/${idProject}/tickets/${identificacion}/${
                idAgrupacion ?? ''
            }`
        );
        const ticketsData = tickets.data as Ticket[];
        const posventaTickets = [];
        const normalTickets = [];
        let ratingTotal = 0;
        let ratingCount = 0;
        for (const ticket of ticketsData) {
            ticket.creation_date = new Date(ticket.creation_date);
            ticket.resolution_date = new Date(ticket.resolution_date);
            if (
                ticket.classifiers.some(
                    (c) => c.idValue === '65ce31a0aeceff8288fb49b2'
                )
            ) {
                posventaTickets.push(ticket);
            } else {
                normalTickets.push(ticket);
            }
            if (ticket.rating?.rating) {
                ratingTotal += ticket.rating.rating;
                ratingCount++;
            }
        }
        if (ratingCount > 0) {
            const rating = Math.round((ratingTotal / ratingCount) * 4);
            return { normalTickets, posventaTickets, rating };
        }
        return { normalTickets, posventaTickets };
    } catch (error) {
        // Mensaje de error
        return { normalTickets: [], posventaTickets: [] };
    }
}
