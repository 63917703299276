import styles from './CompaniesFilters.module.css';
import React, { useRef } from 'react';
import Search from '../../../../shared/Search/Search';
import { Company } from '../../../../@Types/@Types';
import {
    search,
    setLoading,
} from '../../../../controllers/GenericMenuController/GenericMenuActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../utils/_store';
import GenericList from '../../../../shared/@Menus/GenericMenu/GenericList';
import {
    loadCompanies,
    loadCompaniesById,
} from '../../../../controllers/CompaniesController/CompaniesService';
import { defaultState } from '../../../../controllers/GenericMenuController/GenericMenuReducer';
const idMenu = 'FILTERS';

interface CompaniesFiltersProps {
    /** The ids of the initially selected Companies */
    initialCompanies: Company[];
    /** Function called when a company is clicked on  */
    handleChange: Function;
    /** Tabs component to display after the search */
    tabsComponent: JSX.Element;
}
function CompaniesFilters({
    handleChange,
    initialCompanies,
    tabsComponent,
}: CompaniesFiltersProps): JSX.Element {
    const dispatch = useDispatch();
    const menuInfo = useSelector(
        (state: RootState) => state.genericMenu[idMenu] ?? defaultState
    );
    const listRef = useRef<HTMLDivElement>(null);

    return (
        <React.Fragment>
            <div className={styles.searchContainer}>
                <Search
                    wait={500}
                    placeholder={'Buscar Empresas'}
                    search={menuInfo.search}
                    handleLoadingSearch={(loading: boolean): void => {
                        dispatch(setLoading(idMenu, loading));
                    }}
                    handleSearch={(value: any): void => {
                        if (listRef.current) listRef.current.scrollTop = 0;
                        dispatch(search(idMenu, value));
                    }}
                />
            </div>
            {tabsComponent}
            <div className={styles.listContainer}>
                <GenericList
                    idMenu={idMenu}
                    listRef={listRef}
                    handleUpdate={handleChange as any}
                    initialElements={initialCompanies}
                    elementsToOmit={[]}
                    multiple={true}
                    label="Empresa"
                    calcLbl={(company): string =>
                        company.values.name ?? 'Empresa'
                    }
                    loadInitialType={loadCompaniesById}
                    loadElements={async (
                        page: number,
                        pageSize: number,
                        search: string | undefined
                    ): Promise<Company[]> => {
                        return await loadCompanies(
                            page,
                            pageSize,
                            [],
                            undefined,
                            search
                        );
                    }}
                />
            </div>
        </React.Fragment>
    );
}

export default CompaniesFilters;
