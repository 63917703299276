import rootStyles from './AreaTreeItem.module.css';
import nodeStyles from './AreaTreeNode.module.css';
import { useEffect, useState } from 'react';
import AgentTreeNode from '../AgentTreeNode/AgentTreeNode';
import { Agent, Area } from '../../../@Types/@Types';

/** All these props are inherited from the parent*/
interface AreaTreeItemProps {
    /** Id of the area to display */
    idElement?: string;
    /** Map of all the projects areas */
    elements?: Record<string, Area>;
    /** Function that handles clicking on this component */
    handleItemClick?: Function;
    /** The currently selected element */
    selectedElement?: string | undefined;
    /** If user is editing the trees order */
    editing?: boolean;
    /** If the layout is mobile */
    layoutIsMobile?: boolean;
    /** If the item is root */
    isRoot?: boolean;
}

/**
 * Recursive area component
 */
function AreaTreeItem({
    idElement,
    elements,
    handleItemClick,
    selectedElement,
    editing = false,
    layoutIsMobile,
    isRoot = false,
}: AreaTreeItemProps): JSX.Element {
    if (
        !idElement ||
        !elements ||
        !handleItemClick ||
        layoutIsMobile === undefined
    ) {
        return <div></div>;
    }
    /** Choose between root and node styles */
    const styles = isRoot ? rootStyles : nodeStyles;
    const element = elements[idElement];
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (editing) {
            setOpen(false);
        }
    }, [editing]);
    /**
     * Renders the area's subareas
     * @returns subareas
     */
    const renderSubAreas = (): JSX.Element[] => {
        return element.subareas.map((id: string, index: number) => (
            <AreaTreeItem
                idElement={id}
                elements={elements}
                key={index}
                handleItemClick={handleItemClick}
                selectedElement={selectedElement}
                layoutIsMobile={layoutIsMobile}
            />
        ));
    };
    /**
     * Renders the area's agents
     * @returns subareas
     */
    const renderAgents = (): JSX.Element[] => {
        return element.agents.map((agent: Agent, i: number) => (
            <AgentTreeNode
                element={agent}
                key={i}
                handleItemClick={handleItemClick}
                selectedElement={selectedElement}
            />
        ));
    };
    /** Computes if the element being displayed has children to display the carret or not */
    const hasChildren = element.subareas.length + element.agents.length > 0;
    return (
        <div className={styles.treeItemContainer + ' noselect'}>
            <div
                className={styles.treeItemTitle}
                style={{
                    backgroundColor:
                        selectedElement === element._id
                            ? 'var(--defaultGrey)'
                            : 'var(--primary1)',
                    cursor: editing ? 'auto' : 'pointer',
                }}
            >
                <img
                    style={{
                        visibility: hasChildren ? 'visible' : 'hidden',
                    }}
                    alt={'>'}
                    className={styles.dropdownIcon}
                    src={
                        open
                            ? '/media/icons/treedownarrow.svg'
                            : '/media/icons/treerightarrow.svg'
                    }
                    onClick={(): void => {
                        if (hasChildren && !editing) {
                            setOpen(!open);
                        }
                    }}
                />

                <div
                    className={styles.label}
                    onClick={(): void => {
                        if (!editing) {
                            if (hasChildren) {
                                if (!open) {
                                    if (!layoutIsMobile) {
                                        handleItemClick(element._id);
                                    }
                                    setOpen(true);
                                } else {
                                    if (selectedElement === element._id) {
                                        handleItemClick(undefined);
                                        setOpen(false);
                                    } else {
                                        handleItemClick(element._id);
                                    }
                                }
                            } else {
                                if (selectedElement === element._id) {
                                    handleItemClick(undefined);
                                } else {
                                    handleItemClick(element._id);
                                }
                            }
                        }
                    }}
                >
                    {element.name}
                </div>
            </div>
            {open && (
                <div className={styles.areaItemSubContainer}>
                    {renderSubAreas()}
                    {renderAgents()}
                </div>
            )}
        </div>
    );
}

export default AreaTreeItem;
