import { IconButton } from '@material-ui/core';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import styles from './Support.module.css';
import React, { useMemo, useState } from 'react';
import Dialog from '../../Dialog/Dialog';
import { RootState } from '../../../utils/_store';
import { useSelector } from 'react-redux';
import EurekaForm from '@arquimedes.co/eureka-forms';

function Support(): JSX.Element {
    const [showMenu, setShowMenu] = useState(false);

    const renderButton = (): JSX.Element => {
        return (
            <IconButton
                title="Servicio al Cliente"
                aria-label="Notifications"
                style={{
                    width: 50,
                    height: 50,
                    padding: 0,
                    fontSize: 35,
                    color: 'var(--accent)',
                }}
                onClick={(): void => {
                    setShowMenu(true);
                }}
            >
                <HelpOutlineRoundedIcon fontSize="inherit" />
            </IconButton>
        );
    };

    return (
        <React.Fragment>
            {renderButton()}
            {showMenu && (
                <SupportForm
                    handleClose={(): void => {
                        setShowMenu(false);
                    }}
                />
            )}
        </React.Fragment>
    );
}
export default Support;

export function SupportForm({
    handleClose,
}: {
    handleClose: () => void;
}): JSX.Element {
    const site = useSelector((state: RootState) => state.site);
    const values = useMemo(
        () => ({
            name: site.user?.name,
            idAgent: site.user?._id,
            email: site.user?.email,
            idOrganization: site.organization?.idOrganization,
        }),
        [site]
    );

    return (
        <Dialog onClose={(): void => handleClose()} maxWidth={'90vw'}>
            <div className={styles.container}>
                <div className={styles.formContainer}>
                    <EurekaForm
                        isWidget
                        internal
                        idOrganization="capta"
                        apiKey={'support'}
                        valuesData={values}
                        handleConfirmed={handleClose}
                    />
                </div>
            </div>
        </Dialog>
    );
}
