import axios from 'axios';
import { ApiRequest, ApiRequestOption } from '../@Types/ApiRequest';
import { convertFromRaw } from 'draft-js';

export const EurekaIntegrationsUrl = `https://integrations.${process.env.REACT_APP_DOMAIN}/`;
export const EurekaApiUrl = `https://api.${process.env.REACT_APP_DOMAIN}/`;

export type CachedPromise<T> = Promise<T> | T;

const apiCache: Record<string, CachedPromise<ApiRequestOption[]>> = {};

export async function fetchApiRequest(
    request: ApiRequest,
    idOrganization: string,
    idAttribute?: string,
    labelAttribute?: string
): Promise<ApiRequestOption[]> {
    const url = request.url.blocks[0].text;
    if (!url) return [];
    const params = new URLSearchParams({});
    if (url.startsWith(EurekaApiUrl) || url.startsWith(EurekaIntegrationsUrl)) {
        params.set('idOrganization', idOrganization);
    }
    if (Object.keys(request.url.entityMap).length > 0) return [];
    for (const queryParam of request.queryParams) {
        params.set(
            queryParam.key,
            convertFromRaw(queryParam.value).getPlainText()
        );
    }
    if (url === EurekaApiUrl + 'Months') {
        return await getMonths();
    }

    const axiosRequest = async (): Promise<ApiRequestOption[]> => {
        const response = await axios.get(url + '?' + params.toString());
        const data = response.data;
        if (!Array.isArray(data)) return [];
        return data.map((item) => ({
            id: item[idAttribute ?? 'id'],
            label: item[labelAttribute ?? 'label'],
        }));
    };
    apiCache[url] = axiosRequest();
    return await apiCache[url];
}

const getMonths = async (): Promise<{ id: string; label: string }[]> => {
    return [
        { id: '1', label: 'Enero' },
        { id: '2', label: 'Febrero' },
        { id: '3', label: 'Marzo' },
        { id: '4', label: 'Abril' },
        { id: '5', label: 'Mayo' },
        { id: '6', label: 'Junio' },
        { id: '7', label: 'Julio' },
        { id: '8', label: 'Agosto' },
        { id: '9', label: 'Septiembre' },
        { id: '10', label: 'Octubre' },
        { id: '11', label: 'Noviembre ' },
        { id: '12', label: 'Diciembre' },
    ];
};
