import Avatar from '../../../../../../shared/Avatar/Avatar';
import styles from './TransferArea.module.css';
import TrendingFlatRoundedIcon from '@material-ui/icons/TrendingFlatRounded';
import { Agent, Area } from '../../../../../../@Types/@Types';
import ApartmentRoundedIcon from '@material-ui/icons/ApartmentRounded';
interface TransferAreaProps {
    /** Agents removed by the transfer */
    agentsRemoved: Agent[];
    /** The Area the ticket was transfered to  */
    area: Area;
}

/**
 * Component that displays the content of the transfer to Area action
 */
function TransferArea({ agentsRemoved, area }: TransferAreaProps): JSX.Element {
    /* TODO implementar un avatar group propio para que no muestre mas de 4 fotos. */

    return (
        <div className={styles.container}>
            {/*  TODO This could be unified with the removed agents from the transfer to agent action */}
            <div className={styles.agentsRemovedContainer}>
                {agentsRemoved.map((agent, index) => (
                    <Avatar key={index} lbl={agent.name} img={agent.img} />
                ))}
            </div>

            <div className={styles.arrowContainer}>
                <TrendingFlatRoundedIcon fontSize="inherit" />
            </div>
            <div className={styles.areaContainer}>
                <div className={styles.iconContainer}>
                    <ApartmentRoundedIcon fontSize="inherit" />
                </div>

                <label className={styles.nameLbl}>{area.name}</label>
            </div>
        </div>
    );
}
export default TransferArea;
