import { ClientInfo } from './ClientInfo/ClientInfo';
import styles from './Info360.module.css';
import React, { CSSProperties, useEffect, useState } from 'react';
import { Ticket } from '../../../@Types/TicketTypes/Ticket';
import { InmuebleInfo } from './InmuebleInfo/InmuebleInfo';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import Control from './Control/Control';
import RoundedIconButton from '../../../shared/RoundedIconButton/RoundedIconButton';
import TicketIcon from '../../../Icons/TicketIcon';
import PersonIcon from '@material-ui/icons/Person';
import { getClient360, getInmueble360, getTickets360 } from '../ProdesaService';
import { ClienteInfo360, ControlInfo360, InmuebleInfo360 } from '../types';

interface Prodesa360InfoProps {
    ticket?: Ticket;
}

const getWidowWidth = (): 1 | 2 | 3 => {
    const width = window.innerWidth;
    if (width < 1000) {
        return 1;
    } else if (width < 1400) {
        return 2;
    } else {
        return 3;
    }
};
export function Info360({ ticket }: Prodesa360InfoProps): JSX.Element {
    const [identificacion, setIdentificacion] = useState('');
    const [idAgrupacion, setIdAgrupacion] = useState<number | undefined>(
        undefined
    );
    // const [idCBRInmEntity, setIdCBRInmEntity] = useState('');
    const entities = useSelector((state: RootState) => state.site.entities);
    const [windowWidth, setWindowWidth] = useState<1 | 2 | 3>(getWidowWidth());
    const [selectedColumn, setSelectedColumn] = useState<1 | 2>(1);

    const [loadingClient, setLoadingClient] = useState(true);
    const [loadingTickets, setLoadingTickets] = useState(true);
    const [loadingInmueble, setLoadingInmueble] = useState(true);

    const [clientInfo, setClientInfo] = useState<ClienteInfo360 | undefined>(
        undefined
    );
    const [controlInfo, setControlInfo] = useState<ControlInfo360 | undefined>(
        undefined
    );
    const [inmuebleInfo, setInmuebleInfo] = useState<
        InmuebleInfo360 | undefined
    >();
    const idProject =
        useSelector((state: RootState) => state.site.idProject) ?? '';

    function getInmuebleId(ticket: Ticket): number | undefined {
        for (const entity of Object.values(entities)) {
            if (entity.externalIntegrations.CBR_ID_INMUEBLE?.[0]) {
                const inmueble = ticket.entities?.[entity._id]?.[0];
                if (!inmueble?.values.idCBR) return undefined;
                return parseInt(inmueble.values.idCBR);
            }
        }
        return undefined;
    }

    useEffect(() => {
        setLoadingInmueble(true);
        setLoadingTickets(true);
        setControlInfo(undefined);
        setInmuebleInfo(undefined);
        if (identificacion) {
            handleLoadingTickets(identificacion, idProject, idAgrupacion);
            if (idAgrupacion) {
                handleLoadingInmuebleInfo(idAgrupacion);
            } else {
                setLoadingInmueble(false);
            }
        } else {
            setLoadingInmueble(false);
            setLoadingTickets(false);
        }
    }, [identificacion, idAgrupacion]);

    useEffect(() => {
        setLoadingClient(true);
        setClientInfo(undefined);
        if (identificacion) {
            setIdAgrupacion(undefined);
            setInmuebleInfo(undefined);
            handleLoadingClientInfo(identificacion);
        } else {
            setLoadingClient(false);
        }
    }, [identificacion]);

    async function handleLoadingClientInfo(
        identificacion: string
    ): Promise<void> {
        const response = await getClient360(identificacion);
        setClientInfo(response);
        setLoadingClient(false);
    }

    async function handleLoadingInmuebleInfo(
        idAgrupacion: number
    ): Promise<void> {
        const response = await getInmueble360(idAgrupacion);
        setInmuebleInfo(response);
        setLoadingInmueble(false);
    }

    async function handleLoadingTickets(
        identificacion: string,
        idProject: string,
        idAgrupacion: number | undefined
    ): Promise<void> {
        const response = await getTickets360(
            identificacion,
            idProject,
            idAgrupacion
        );
        setControlInfo(response);
        setLoadingTickets(false);
    }

    useEffect(() => {
        const handleResize = (): void => {
            setWindowWidth(getWidowWidth());
        };

        window.addEventListener('resize', handleResize);
        return (): void => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (ticket) {
            if (ticket.client.identifier) {
                setIdAgrupacion(getInmuebleId(ticket));
                setIdentificacion(ticket.client.identifier);
            }
        }
    }, [ticket]);

    const leftColumStyle = (windowWidth: 1 | 2 | 3): CSSProperties => {
        if (windowWidth === 1) {
            return { width: '100vw', marginBottom: '10px' };
        } else if (windowWidth === 2) {
            return { width: '28vw', margin: 0 };
        } else {
            return { width: 'calc(27vw - 60px)' };
        }
    };

    const rightColumStyle = (windowWidth: 1 | 2 | 3): CSSProperties => {
        if (windowWidth === 1) {
            return { width: '100vw', marginBottom: '10px' };
        } else if (windowWidth === 2) {
            return { width: '28vw' };
        } else {
            return { width: 'calc(22vw - 60px)' };
        }
    };

    const centerColumStyle = (windowWidth: 1 | 2 | 3): CSSProperties => {
        if (windowWidth === 1) {
            return { width: '100vw', marginBottom: '10px' };
        } else if (windowWidth === 2) {
            return { width: '50vw' };
        } else {
            return { width: 'calc(43vw - 60px)', margin: '0 10px' };
        }
    };

    return (
        <div className={styles.container}>
            {(windowWidth !== 2 || selectedColumn === 1) && (
                <div
                    className={styles.column}
                    style={leftColumStyle(windowWidth)}
                >
                    <ClientInfo
                        identificacion={identificacion}
                        setIdentificacion={setIdentificacion}
                        idAgrupacion={idAgrupacion}
                        setIdAgrupacion={setIdAgrupacion}
                        loading={loadingClient}
                        setLoading={setLoadingClient}
                        setLoadingInmueble={setLoadingInmueble}
                        setLoadingTickets={setLoadingTickets}
                        clientInfo={clientInfo}
                        controlInfo={controlInfo}
                        loadingTickets={loadingTickets}
                    />
                </div>
            )}
            <div
                className={styles.column}
                style={centerColumStyle(windowWidth)}
            >
                <InmuebleInfo
                    idAgrupacion={idAgrupacion}
                    inmuebleInfo={inmuebleInfo}
                    clientInfo={clientInfo}
                    loading={loadingInmueble}
                />
            </div>

            {(windowWidth !== 2 || selectedColumn === 2) && (
                <div
                    className={styles.column}
                    style={{
                        ...rightColumStyle(windowWidth),
                        backgroundColor: 'var(--primary2)',
                    }}
                >
                    <Control
                        controlInfo={controlInfo}
                        loading={loadingTickets}
                    />
                </div>
            )}
            {windowWidth === 2 && (
                <div className={styles.returnButton}>
                    <RoundedIconButton
                        backgroundColor="var(--contrast)"
                        color="var(--primary1)"
                        onClick={(): void => {
                            setSelectedColumn(selectedColumn === 1 ? 2 : 1);
                        }}
                    >
                        <>
                            {selectedColumn === 2 ? (
                                <PersonIcon />
                            ) : (
                                <TicketIcon
                                    style={{
                                        width: 25,
                                        height: 25,
                                        fill: 'var(--primary1)',
                                    }}
                                />
                            )}
                        </>
                    </RoundedIconButton>
                </div>
            )}
        </div>
    );
}
