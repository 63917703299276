// import { Handle, Position } from 'react-flow-renderer';
import { Link, withRouter, RouteComponentProps, match } from 'react-router-dom';
import { Section } from '../../../../../@Types/GenericForm';
import styles from './SectionNode.module.css';

interface SectionNodeProps {
    /** Section Node data */
    data: Section;
    match: match<{ idEntry: string; idProject: string }>;
}

function SectionNode({
    data,
    match,
}: SectionNodeProps & RouteComponentProps): JSX.Element {
    return (
        <>
            {/*<Handle*/}
            {/*    className={styles.leftHandle}*/}
            {/*    type="target"*/}
            {/*    position={Position.Left}*/}
            {/*    style={{*/}
            {/*        top: '50%',*/}
            {/*        visibility: 'hidden',*/}
            {/*    }}*/}
            {/*/>*/}
            <Link to={`${match.url}/${data.id}`} className={'app-link'}>
                <div className={styles.container}>
                    <label className={styles.title}>{data.name}</label>
                    <div className={styles.fakeTxtInputLarge}></div>
                    <div className={styles.fakeTxtInputSmall}></div>
                    <div className={styles.doubleInputContainer}>
                        <div className={styles.fakeTxtInputXSmall}></div>
                        <div className={styles.fakeTxtInputXSmall}></div>
                    </div>
                    <div className={styles.fakeTxtInputSmall}></div>
                    <div className={styles.fakeTxtInputLarge}></div>
                    <div className={styles.fakeTextArea}></div>
                </div>
            </Link>
            {/*<Handle*/}
            {/*    className={styles.rightHandle}*/}
            {/*    type="source"*/}
            {/*    position={Position.Right}*/}
            {/*    style={{*/}
            {/*        top: '50%',*/}
            {/*        visibility: 'hidden',*/}
            {/*    }}*/}
            {/*/>*/}
        </>
    );
}

export default withRouter(SectionNode);
