import { useAppSelector } from '../../../hooks';
import RoundedConversationStepPicker, {
    RoundedConversationStepPickerProps,
} from '../RoundedConversationStepPicker/RoundedConversationStepPicker';

interface PreviousConversationStepsPickerProps
    extends Omit<RoundedConversationStepPickerProps, 'stepOrder'> {
    idStep: string;
}

function PreviousConversationStepsPicker({
    idStep,
    ...props
}: PreviousConversationStepsPickerProps): JSX.Element {
    const previousSteps: string[] = useAppSelector(
        (state) =>
            state.conversationEditor.stepDependencies[idStep]?.previousSteps
    );
    return (
        <RoundedConversationStepPicker
            stepOrder={previousSteps ?? []}
            {...props}
        />
    );
}

export default PreviousConversationStepsPicker;
