import { IconProps } from './@IconTypes';

function NotificationIcon({
    className,
    style,
    fill,
    contrast = '#293241',
}: IconProps): JSX.Element {
    return (
        <svg
            fill={fill}
            style={style}
            className={className}
            version="1.1"
            width="500"
            height="460"
            viewBox="0 0 500 460"
        >
            <path d="M404.758 310.788C407.632 304.727 410.131 298.551 412.269 292.274H411.72C449.168 182.357 370.415 66.8325 254.153 61.2075C254.151 61.2015 254.15 61.1955 254.148 61.1895C154.112 56.5795 71 136.676 71 235.994C71 332.408 149.361 410.85 245.743 410.991C271.886 410.956 296.944 405.328 320.311 394.243C411.518 412.276 404.405 410.993 406.5 410.993C415.979 410.993 423.06 402.307 421.209 393.052L404.758 310.788Z" />
            <rect
                x="224"
                y="136"
                width="45"
                height="115"
                rx="22.5"
                fill={contrast}
            />
            <circle cx="246.5" cy="297.5" r="22.5" fill={contrast} />
        </svg>
    );
}
export default NotificationIcon;
