import styles from './ApiParam.module.css';
import React from 'react';
import { ApiSelectorParam } from '../../../../@Types/GenericFormSteps';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import RoundedSelect from '../../../RoundedSelect/RoundedSelect';
import { ApiSelectorParamTypes } from '../../../../constants/FormStepTypes';
import { MenuItem } from '@material-ui/core';
import { ApiSelector } from '../../../../@Types/FormTypes/FormStep';
import RoundedTextField from '../../../RoundedTextField/RoundedTextField';
import RoundedFormStepPicker from '../../../@Pickers/RoundedFormStepPicker/RoundedFormStepPicker';
import Toggle from '../../../Toggle/Toggle';
import { useFormPreviousSteps } from '../../../../pages/Forms/FormReducerFunctions';

interface ApiParamProps {
    step: ApiSelector;
    param: ApiSelectorParam;
    handleUpdate: (param: ApiSelectorParam) => void;
    handleDelete: () => void;
}

function ApiParam({
    step,
    param,
    handleDelete,
    handleUpdate,
}: ApiParamProps): JSX.Element {
    const previousSteps = useFormPreviousSteps(step.id);

    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <div className={styles.bullet}></div>
                <div className={styles.keyInput}>
                    <RoundedTextField
                        label="llave"
                        value={param.key}
                        onChange={(e): void => {
                            handleUpdate({
                                ...param,
                                key: e.target.value,
                            });
                        }}
                    ></RoundedTextField>
                </div>
                <div className={styles.deleteBtn} onClick={handleDelete}>
                    <DeleteRoundedIcon fontSize="inherit" />
                </div>
            </div>
            <div className={styles.bottom}>
                <div className={styles.paramTypeSelector}>
                    <RoundedSelect
                        autoWidth
                        containerMargin="0px"
                        label="Tipo"
                        value={param.type}
                        handleUpdate={(e): void => {
                            const type = e.target
                                .value as ApiSelectorParamTypes;
                            switch (type) {
                                case ApiSelectorParamTypes.STEP:
                                    if (previousSteps.length == 0) return;
                                    handleUpdate({
                                        type,
                                        key: param.key,
                                        idStep: previousSteps[0].id,
                                        required: false,
                                    });
                                    break;
                                case ApiSelectorParamTypes.VALUE:
                                default:
                                    handleUpdate({
                                        type,
                                        key: param.key,
                                        value: '',
                                    });
                                    break;
                            }
                        }}
                    >
                        <MenuItem
                            value={ApiSelectorParamTypes.STEP}
                            disabled={previousSteps.length == 0}
                        >
                            Paso anterior
                        </MenuItem>
                        <MenuItem value={ApiSelectorParamTypes.VALUE}>
                            Valor
                        </MenuItem>
                    </RoundedSelect>
                </div>
                {param.type === ApiSelectorParamTypes.STEP && (
                    <div className={styles.stepInfoContainer}>
                        <div className={styles.valueInput}>
                            <RoundedFormStepPicker
                                steps={previousSteps}
                                value={param.idStep}
                                allowCustom
                                containerMargin="0px"
                                handleUpdate={(idStep): void => {
                                    handleUpdate({
                                        ...param,
                                        idStep,
                                    });
                                }}
                            />
                        </div>
                        <div className={styles.requiredContainer}>
                            <div className={styles.requiredLabel + ' noselect'}>
                                Obligatorio:
                            </div>
                            <div className={styles.toggleContainer}>
                                <Toggle
                                    checked={param.required === true}
                                    onChange={(checked: boolean): void => {
                                        handleUpdate({
                                            ...param,
                                            required: checked,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {param.type === ApiSelectorParamTypes.VALUE && (
                    <div className={styles.valueInput}>
                        <RoundedTextField
                            label="Valor"
                            height="40px"
                            value={param.value}
                            onChange={(e): void => {
                                handleUpdate({
                                    ...param,
                                    value: e.target.value,
                                });
                            }}
                        ></RoundedTextField>
                    </div>
                )}
            </div>
        </div>
    );
}
export default ApiParam;
