import Dialog from '../Dialog/Dialog';
import styles from './SaveDialog.module.css';
import RoundedButton from '../RoundedButton/RoundedButton';
import { useEffect, useState } from 'react';
import WarningIcon from '@material-ui/icons/Warning';

interface SaveProps {
    /** Function that handles the closing event */
    onClose: () => void;
    /** Function to call to save */
    handleSave: Function;
}

/**
 * Component that calls the services to save.
 */
function Save({ onClose, handleSave }: SaveProps): JSX.Element {
    return (
        <Dialog onClose={onClose} showLoader={false}>
            <InnerSaveDialog
                onClose={onClose}
                handleSave={handleSave}
            ></InnerSaveDialog>
        </Dialog>
    );
}
export default Save;

interface InnerDialogProps extends SaveProps {
    /** Function that toggles the loader in the modal, inherited from the modal. */
    setLoading?: Function;
    /** if loader should be active */
    loading?: boolean;
}

function InnerSaveDialog({
    onClose,
    handleSave,
    setLoading,
}: InnerDialogProps): JSX.Element {
    const [saving, setSaving] = useState(true);
    const [error, setError] = useState<undefined | string>(undefined);

    const toggleSave = (toggle: boolean): void => {
        if (setLoading) {
            setLoading(toggle);
            setSaving(toggle);
        }
    };
    const doSave = async (): Promise<void> => {
        if (setLoading) {
            toggleSave(true);
            try {
                await handleSave();
                toggleSave(false);
            } catch (error) {
                //TODO handle errors
                setError(error + '');
                toggleSave(false);
                console.error(error);
            }
        } else {
            onClose();
        }
    };

    useEffect(() => {
        doSave();
    }, []);

    if (error) {
        return (
            <div className={styles.containerErrors + ' standard-dialog'}>
                <div className="center-anything">
                    <label className={styles.titleError}>
                        {'Error Encontrado'}
                        <div className={styles.errorsIcon}>
                            <WarningIcon fontSize="inherit" />
                        </div>
                    </label>
                </div>
                <div className="center-anything">
                    <p className={styles.errorMsg}>{error}</p>
                </div>
            </div>
        );
    }
    if (saving) {
        return (
            <div className={styles.container + ' standard-dialog'}>
                <div className={styles.savingMsg}>
                    Guardando
                    <span className="loader__dot">.</span>
                    <span className="loader__dot">.</span>
                    <span className="loader__dot">.</span>
                </div>
            </div>
        );
    } else {
        return (
            <div className={styles.container + ' standard-dialog'}>
                <div className="center-anything">
                    <label className={styles.title + ' center-anything'}>
                        Se ha guardado correctamente!
                    </label>
                </div>
                <div className={styles.cerrarBtn}>
                    <RoundedButton
                        text={'Cerrar'}
                        color="white"
                        fontSize={18}
                        onClick={(): void => onClose()}
                    />
                </div>
            </div>
        );
    }
}
