import styles from './FormConfig.module.css';
import EditContainer from '../../../shared/EditContainer/EditContainer';
import Toggle from '../../../shared/Toggle/Toggle';
import RoundedTextField from '../../../shared/RoundedTextField/RoundedTextField';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import React, { useRef, useState } from 'react';
import { RootState } from '../../../utils/_store';
import { useSelector } from 'react-redux';
import { Entry, Form } from '../../../@Types/@Types';
import {
    updateName,
    updateSteps,
} from '../../../controllers/GenericEditorController/GenericEditorActions';
import RoundedButton from '../../../shared/RoundedButton/RoundedButton';
import HiddenStepsEditor from './HiddenStepsEditor/HiddenStepsEditor';
import LocationTypes from '../../../constants/LocationTypes';
import { checkAdmin } from '../../../utils/PermissionsFunctions';
import TermsEditor from './TermsEditor/TermsEditor';
import PayloadEditor from '../../../shared/TextEditor/PayloadEditor';
import { EurekaDraft } from '../../../@Types/Draft/Draft';
import { useGenericEditorDispatch } from '../../../shared/GenericFormEditor/GenericFormEditorHooks';
import { useFormEditorSelector } from '../FormReducerFunctions';
interface FormConfigProps {
    data?: any;
    handleUpdate?: Function;
}
function FormConfig({ data, handleUpdate }: FormConfigProps): JSX.Element {
    if (data?.form === undefined) {
        return <div></div>;
    }
    const { form, entry }: { form: Form; entry: Entry } = data;
    const { organization, user } = useSelector(
        (state: RootState) => state.site
    );
    const dispatch = useGenericEditorDispatch();
    const calcUrl = (): string => {
        return `${process.env.REACT_APP_PREFIX}${organization?.idOrganization}.forms.${process.env.REACT_APP_DOMAIN}/${entry.apiKey}`;
    };
    const [showEdit, setShowEdit] = useState(false);
    const urlRef = useRef<HTMLInputElement>(null);
    const copyRef = useRef<HTMLButtonElement>(null);
    const steps = useFormEditorSelector((editorInfo) => editorInfo.steps);

    const handleUpdateForm = (form: Form): void => {
        if (handleUpdate) {
            handleUpdate({ entry, form });
        }
    };
    if (!form) return <></>;
    return (
        <React.Fragment>
            {showEdit && (
                <HiddenStepsEditor
                    steps={steps}
                    hiddenSteps={form.hiddenSteps ?? []}
                    onClose={(steps, hiddenSteps): void => {
                        if (form) {
                            const tempForm = { ...form };
                            tempForm.hiddenSteps = hiddenSteps;
                            dispatch(
                                updateSteps({
                                    steps,
                                    location: {
                                        type: LocationTypes.SECTION,
                                        idRootStep: 'NULL',
                                        indexStep: 0,
                                    },
                                })
                            );
                            handleUpdateForm(tempForm);
                        }
                        setShowEdit(false);
                    }}
                />
            )}
            <div className={styles.descriptionLabel + ' noselect'}>Nombre</div>
            <div className={styles.descriptionInputContainer}>
                <EditContainer
                    object={form}
                    field={'name'}
                    handleConfirm={(
                        _object: Omit<
                            Form,
                            'steps' | 'sections' | 'uniqueSteps'
                        >,
                        field: keyof Omit<
                            Form,
                            'steps' | 'sections' | 'uniqueSteps'
                        >,
                        value: string
                    ): void => {
                        if (form) {
                            const tempForm = { ...form };
                            tempForm.name = value;
                            handleUpdateForm(tempForm);
                            dispatch(updateName(value));
                        }
                    }}
                >
                    <div className={styles.description}>{form.name}</div>
                    <input
                        className={styles.descriptionInput + ' edit-input'}
                        defaultValue={form.name}
                        type="text"
                    />
                </EditContainer>
            </div>
            <div className={styles.descriptionLabel + ' noselect'}>
                Descripción
            </div>
            <div className={styles.descriptionInputContainer}>
                <EditContainer
                    object={form}
                    field={'description'}
                    handleConfirm={(
                        _object: Omit<
                            Form,
                            'steps' | 'sections' | 'uniqueSteps'
                        >,
                        field: keyof Omit<
                            Form,
                            'steps' | 'sections' | 'uniqueSteps'
                        >,
                        value: string
                    ): void => {
                        if (form) {
                            const tempForm = { ...form };
                            tempForm.description = value;
                            handleUpdateForm(tempForm);
                        }
                    }}
                >
                    <div className={styles.description}>{form.description}</div>
                    <input
                        className={styles.descriptionInput + ' edit-input'}
                        defaultValue={form.description}
                        type="text"
                    />
                </EditContainer>
            </div>
            <div className={styles.internalContainer}>
                <div className={styles.internalLabel + ' noselect'}>
                    Creable por Agentes:
                </div>
                <div className={styles.togglecontainer}>
                    <Toggle
                        checked={form.isInternal}
                        onChange={(value: boolean): void => {
                            if (form) {
                                const tempForm = { ...form };
                                tempForm.isInternal = value;
                                handleUpdateForm(tempForm);
                            }
                        }}
                    />
                </div>
            </div>
            <div className={styles.disableChannelContainer}>
                <div className={styles.standAloneLabel + ' noselect'}>
                    Deshabilitar Comunicaciones con Cliente:
                </div>
                <div className={styles.togglecontainer}>
                    <Toggle
                        checked={form.disableChannel === true}
                        onChange={(value: boolean): void => {
                            if (form) {
                                const tempForm = { ...form };
                                tempForm.disableChannel = value;
                                handleUpdateForm(tempForm);
                            }
                        }}
                    />
                </div>
            </div>
            <div className={styles.disableChannelContainer}>
                <div className={styles.standAloneLabel + ' noselect'}>
                    Deshabilitar mensaje de recepción del caso
                </div>
                <div className={styles.togglecontainer}>
                    <Toggle
                        checked={form.skipClientReceivedNotification === true}
                        onChange={(value: boolean): void => {
                            if (form) {
                                const tempForm = { ...form };
                                tempForm.skipClientReceivedNotification = value;
                                handleUpdateForm(tempForm);
                            }
                        }}
                    />
                </div>
            </div>
            <div className={styles.publicContainer}>
                <div className={styles.publicLabel + ' noselect'}>
                    Acceso Público:
                </div>
                <div className={styles.togglecontainer}>
                    <Toggle
                        checked={form.isPublic}
                        onChange={(value: boolean): void => {
                            if (form) {
                                const tempForm = { ...form };
                                tempForm.isPublic = value;
                                handleUpdateForm(tempForm);
                            }
                        }}
                    />
                </div>
            </div>
            {form.isPublic && (
                <div className={styles.standAloneContainer}>
                    <div className={styles.standAloneLabel + ' noselect'}>
                        Página Independiente:
                    </div>
                    <div className={styles.togglecontainer}>
                        <Toggle
                            checked={form.isStandAlone}
                            onChange={(value: boolean): void => {
                                if (form) {
                                    const tempForm = { ...form };
                                    tempForm.isStandAlone = value;
                                    handleUpdateForm(tempForm);
                                }
                            }}
                        />
                    </div>
                    <div className={styles.captchaContainer}>
                        <div className={styles.captchaLabel + ' noselect'}>
                            Captcha:
                        </div>
                        <div className={styles.togglecontainer}>
                            <Toggle
                                checked={form.hasCaptcha}
                                onChange={(value: boolean): void => {
                                    if (form) {
                                        const tempForm = { ...form };
                                        tempForm.hasCaptcha = value;
                                        handleUpdateForm(tempForm);
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
            {form.isPublic && (
                <div className={styles.urlContainer}>
                    <div className={styles.urlLabel + ' noselect'}>Url:</div>
                    <div className={styles.urlInputContainer}>
                        <RoundedTextField
                            value={calcUrl()}
                            readOnly={true}
                            outlineColor="var(--light-grey)"
                            focusColor="var(--light-grey)"
                            backgroundColor="var(--primary2)"
                            borderRadius={5}
                            padding="6px 6px"
                            inputRef={urlRef}
                        />
                    </div>
                    <button
                        ref={copyRef}
                        className={styles.copyLink}
                        onClick={(): void => {
                            urlRef.current?.select();
                            /* For mobile devices */
                            urlRef.current?.setSelectionRange(0, 99999);
                            document.execCommand('copy');
                            copyRef.current?.focus();
                        }}
                    >
                        <FileCopyRoundedIcon fontSize="inherit" />
                    </button>
                </div>
            )}
            {checkAdmin(user) && (
                <React.Fragment>
                    <div className={styles.descriptionLabel + ' noselect'}>
                        Pasos Escondidos
                    </div>
                    <div className={styles.btnContainer}>
                        <RoundedButton
                            text="Editar"
                            onClick={(): void => {
                                setShowEdit(true);
                            }}
                        />
                    </div>
                </React.Fragment>
            )}
            <TermsEditor />
            <div className={styles.confirmationContainer}>
                <div className={styles.confirmationLabel + ' noselect'}>
                    Mensaje de Confirmación:
                </div>
                <div className={styles.confirmationEditorContainer}>
                    <PayloadEditor
                        payload={{
                            draft: form.confirmationMessage as EurekaDraft,
                        }}
                        idEditor={'FORM-CONFIRM'}
                        onChange={({ draft }): void => {
                            if (!draft) return;
                            handleUpdateForm({
                                ...form,
                                confirmationMessage: draft as any,
                            });
                        }}
                        textAlignment="center"
                        autoFocus={false}
                        context={{
                            isPublic: true,
                            caseNumber: true,
                        }}
                        conditions={{
                            types: [],
                        }}
                        minHeight={100}
                        maxHeight={400}
                        media={false}
                        style={{
                            fontSize: '19.5px',
                        }}
                    />
                </div>
            </div>

            <div className={styles.publicContainer}>
                <div className={styles.publicLabel + ' noselect'}>
                    Mostrar enlace al caso:
                </div>
                <div className={styles.togglecontainer}>
                    <Toggle
                        checked={form.showLink}
                        onChange={(showLink: boolean): void => {
                            handleUpdateForm({ ...form, showLink });
                        }}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

export default FormConfig;
