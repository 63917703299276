import { MenuItem } from '@material-ui/core';
import React from 'react';
import ConditionOperators, {
    ConditionEqualsOperators,
} from '../../../../constants/Conditions/ConditionOperators';
import RoundedSelect from '../../../RoundedSelect/RoundedSelect';
import styles from '../Condition.module.css';
import entryStyles from './EntryCondition.module.css';
import { ConditionProps } from '../../Condition';
import produce from 'immer';
import { EntryCondition } from '../../../../@Types/ConditionTypes/TicketPropertyConditions';
import RoundedEntryPicker from '../../../@Pickers/RoundedEntryPicker/RoundedEntryPicker';
import { EntryPropertyTypes } from '../../../../constants/EntryTypes';
import EntryPropertyConditionComponent from './EntryPropertyCondition/EntryPropertyCondition';

interface EntryConditionProps extends ConditionProps<EntryCondition> {}
function EntryConditionComponent({
    context,
    condition,
    hoverDelete,
    handleUpdate,
}: EntryConditionProps): JSX.Element {
    const renderValue = (): JSX.Element => {
        switch (condition.operator) {
            case ConditionOperators.EQUAL:
            case ConditionOperators.NOTEQUAL:
                return (
                    <div className={styles.valueContainer}>
                        <div className={styles.multiSelectContainer}>
                            <RoundedEntryPicker
                                value={condition.values ?? []}
                                label={'Entradas'}
                                multiple={true}
                                height="40px"
                                error={hoverDelete}
                                handleUpdate={(entries): void => {
                                    handleUpdate({
                                        ...condition,
                                        values: entries,
                                    });
                                }}
                            />
                        </div>
                    </div>
                );
            case ConditionOperators.INCLUDES:
            case ConditionOperators.NOTINCLUDES:
                return (
                    <React.Fragment>
                        <div className={entryStyles.propertySelectorContainer}>
                            <RoundedSelect
                                value={condition.property ?? ''}
                                label="Propiedad"
                                containerMargin="0px"
                                error={hoverDelete}
                                handleUpdate={(event): void => {
                                    handleUpdate(
                                        produce(condition, (condition) => {
                                            condition.property =
                                                event.target.value;
                                            delete (condition as any).value;
                                        })
                                    );
                                }}
                            >
                                <MenuItem value={EntryPropertyTypes.TYPE}>
                                    Tipo
                                </MenuItem>
                                <MenuItem value={EntryPropertyTypes.INTERNAL}>
                                    Creado Internamente
                                </MenuItem>
                            </RoundedSelect>
                        </div>
                        <EntryPropertyConditionComponent
                            context={context}
                            condition={condition}
                            hoverDelete={hoverDelete}
                            handleUpdate={handleUpdate as any}
                        />
                    </React.Fragment>
                );
            default:
                return <div></div>;
        }
    };

    return (
        <React.Fragment>
            <div className={styles.operatorContainer}>
                <div className={styles.selectorContainer}>
                    <RoundedSelect
                        value={condition.operator ?? ''}
                        label="Operador"
                        containerMargin="0px"
                        error={hoverDelete}
                        handleUpdate={(event): void => {
                            handleUpdate(
                                produce(condition, (condition) => {
                                    const operator = event.target.value;
                                    if (
                                        ConditionEqualsOperators.includes(
                                            condition.operator
                                        ) &&
                                        !ConditionEqualsOperators.includes(
                                            operator
                                        )
                                    ) {
                                        delete (condition as any).values;
                                    }
                                    condition.operator = operator;
                                })
                            );
                        }}
                    >
                        <MenuItem value={ConditionOperators.EQUAL}>
                            Es igual a
                        </MenuItem>
                        <MenuItem value={ConditionOperators.NOTEQUAL}>
                            No es igual a
                        </MenuItem>
                        <MenuItem value={ConditionOperators.INCLUDES}>
                            Contiene
                        </MenuItem>
                        <MenuItem value={ConditionOperators.NOTINCLUDES}>
                            No contiene
                        </MenuItem>
                    </RoundedSelect>
                </div>
            </div>
            {renderValue()}
        </React.Fragment>
    );
}
export default EntryConditionComponent;
