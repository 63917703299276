import { MenuItem } from '@material-ui/core';
import React from 'react';
import RoundedSelect from '../../../../RoundedSelect/RoundedSelect';
import styles from '../../Condition.module.css';
import entryStyles from '../EntryCondition.module.css';
import { ConditionProps } from '../../../Condition';
import EntryTypes, {
    EntryPropertyTypes,
} from '../../../../../constants/EntryTypes';
import { EntryPropertyCondition } from '../../../../../@Types/ConditionTypes/TicketPropertyConditions';
import { OperatorTypes } from '@arquimedes.co/eureka-forms/dist/constants/ConditionTypes';
import produce from 'immer';
interface EntryPropertyConditionProps
    extends ConditionProps<EntryPropertyCondition> {}
function EntryPropertyConditionComponent({
    condition,
    hoverDelete,
    handleUpdate,
}: EntryPropertyConditionProps): JSX.Element {
    const renderPropertyOperator = (): JSX.Element => {
        switch (condition.property) {
            case EntryPropertyTypes.TYPE:
            case EntryPropertyTypes.INTERNAL: {
                return (
                    <div className={styles.selectorContainer}>
                        <RoundedSelect
                            value={condition.propertyOperator ?? ''}
                            label="Operador"
                            containerMargin="0px"
                            error={hoverDelete}
                            handleUpdate={(event): void => {
                                handleUpdate({
                                    ...condition,
                                    propertyOperator: event.target.value,
                                });
                            }}
                        >
                            <MenuItem value={OperatorTypes.EQUAL}>
                                Igual a
                            </MenuItem>
                            <MenuItem value={OperatorTypes.NOTEQUAL}>
                                No igual a
                            </MenuItem>
                        </RoundedSelect>
                    </div>
                );
            }
            default:
                return <div></div>;
        }
    };

    const renderPropertyValue = (): JSX.Element => {
        switch (condition.property) {
            case EntryPropertyTypes.TYPE: {
                return (
                    <RoundedSelect
                        value={condition.value ?? ''}
                        label="Tipo"
                        containerMargin="0px"
                        error={hoverDelete}
                        handleUpdate={(event): void => {
                            handleUpdate(
                                produce(condition, (condition) => {
                                    condition.value = event.target.value;
                                })
                            );
                        }}
                    >
                        <MenuItem value={EntryTypes.FORM}>Formulario</MenuItem>
                        <MenuItem value={EntryTypes.EMAIL}>Correo</MenuItem>
                        <MenuItem value={EntryTypes.WHATSAPP}>
                            Whatsapp
                        </MenuItem>
                    </RoundedSelect>
                );
            }
            case EntryPropertyTypes.INTERNAL: {
                return (
                    <RoundedSelect
                        value={
                            condition.value !== undefined
                                ? condition.value
                                    ? 'SI'
                                    : 'NO'
                                : ''
                        }
                        minWidth={160}
                        label="Creado Internamente"
                        containerMargin="0px"
                        error={hoverDelete}
                        handleUpdate={(event): void => {
                            handleUpdate(
                                produce(condition, (condition) => {
                                    condition.value =
                                        event.target.value === 'SI';
                                })
                            );
                        }}
                    >
                        <MenuItem value={'SI'}>Sí</MenuItem>
                        <MenuItem value={'NO'}>No</MenuItem>
                    </RoundedSelect>
                );
            }
            default:
                return <div></div>;
        }
    };

    return (
        <React.Fragment>
            <div className={styles.operatorContainer}>
                {renderPropertyOperator()}
            </div>
            <div className={entryStyles.valueContainer}>
                {renderPropertyValue()}
            </div>
        </React.Fragment>
    );
}
export default EntryPropertyConditionComponent;
