export enum EmailPreferences {
    CLIENT_REPLY = 'CLIENT_REPLY',
    COMMENT = 'COMMENT',
    TRANSFER = 'TRANSFER',
    ASSIGN = 'ASSIGN',
    ACTIVITY = 'ACTIVITY',
}

export const EmailPreferencesList = [
    { key: EmailPreferences.CLIENT_REPLY, label: 'Mensaje de un Cliente' },
    { key: EmailPreferences.COMMENT, label: 'Comentario de un Agente' },
    { key: EmailPreferences.TRANSFER, label: 'Caso Transferido' },
    { key: EmailPreferences.ASSIGN, label: 'Caso Asignado' },
    { key: EmailPreferences.ACTIVITY, label: 'Actividad Pendiente' },
];
