import * as libphonenumber from 'libphonenumber-js/max';
import {
    guessCountryByPartialPhoneNumber,
    removeDialCode,
} from 'react-international-phone';
import { FormStep } from '../@Types/FormTypes/FormStep';
import { EntityProperty } from '../@Types/EntityTypes/EntityProperty';
import FormStepTypes from '../constants/FormStepTypes';
import ClientInfoTypes from '../constants/ClientInfoTypes';
import StringValidationTypes from '../constants/StringValidationTypes';
import EntityPropertyTypes from '../constants/EntityPropertyTypes';

export function isValidPhoneNumber(phone: string): boolean {
    if (!phone.startsWith('+')) phone = '+' + phone;
    return libphonenumber.isValidPhoneNumber(phone);
}

export function isEmptyPhoneNumber(phone: string): boolean {
    if (!phone.startsWith('+')) phone = '+' + phone;
    const { country } = guessCountryByPartialPhoneNumber({
        phone,
    });
    if (
        country &&
        !removeDialCode({
            phone,
            dialCode: country?.dialCode,
        })
    ) {
        return true;
    }
    if (!country) {
        console.error('Country not found for phone number:', phone);
        return true;
    }
    return false;
}

export function isPhoneStep(step: FormStep | EntityProperty): boolean {
    if (
        step.type === FormStepTypes.TEXTINPUT &&
        (step.clientInfoType === ClientInfoTypes.CEL ||
            step.validation?.type === StringValidationTypes.PHONE)
    )
        return true;
    if (
        step.type === EntityPropertyTypes.TEXTINPUT &&
        step.validation?.type === StringValidationTypes.PHONE
    )
        return true;
    return false;
}
