export function getPrettyCurrencyValue(value = 0, code = 'COP'): string {
    const formatter = new Intl.NumberFormat('es-CO', {
        maximumFractionDigits: 0,
        style: 'currency',
        currency: code,
    });
    return formatter.format(value);
}

export function joinEntityStrings(names: string[]): string {
    let joinS = '';
    for (const name of names) {
        if (joinS) {
            joinS += ' + ';
        }
        joinS += name;
    }
    return joinS;
}

export function empStrOrUndToNA(value: string | undefined | null): string {
    if (value === undefined || value === null || value === '') {
        return 'N/A';
    }
    return value;
}
