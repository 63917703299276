import { IconProps } from './@IconTypes';

function BrainIcon({ className, style, fill }: IconProps): JSX.Element {
    return (
        <svg
            fill={fill}
            style={style}
            className={className}
            version="1.1"
            viewBox="0 0 700 580"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="m186.48 308c-11.199 0-21.281-6.7188-25.762-16.801h-79.52c4.4805 32.48 28 59.359 59.359 67.199-10.078 40.32 11.199 77.281 43.68 94.078 1.1211 44.801 36.961 79.52 81.762 79.52 30.238 0 57.121-16.801 71.68-42.559v-133.28h-58.238v34.719c10.078 4.4805 16.801 14.559 16.801 25.762 0 15.68-12.32 28-28 28-15.68 0-28-12.32-28-28 0-11.199 6.7188-21.281 16.801-25.762v-57.121l81.758 0.003907v-99.68h-64.961v-45.922h-26.879c-4.4805 10.078-14.559 16.801-25.762 16.801-15.68 0-28-12.32-28-28 0-15.68 12.32-28 28-28 11.199 0 21.281 6.7188 25.762 16.801h49.281v45.922l42.559-0.003906v-142.24c-14.559-25.762-40.32-42.559-71.68-42.559-43.68 0-80.641 34.719-81.762 79.52-33.602 15.68-53.762 54.879-43.68 94.078-31.359 8.9609-54.879 34.719-59.359 67.199h79.52c4.4805-10.078 14.559-16.801 25.762-16.801 15.68 0 28 12.32 28 28 0 15.68-13.441 29.121-29.121 29.121z" />
            <path d="m558.32 200.48c10.078-38.078-10.078-77.281-43.68-94.078-1.1211-44.801-38.078-79.52-81.762-79.52-30.238 0-57.121 16.801-71.68 42.559v61.602h56c4.4805-10.078 14.559-16.801 25.762-16.801 15.68 0 28 12.32 28 28 0 15.68-12.32 28-28 28-11.199 0-21.281-6.7188-25.762-16.801h-56v268.8h42.559l0.003907-52.641h51.52c4.4805-10.078 14.559-16.801 25.762-16.801 15.68 0 28 12.32 28 28 0 15.68-12.32 28-28 28-11.199 0-21.281-6.7188-25.762-16.801h-29.121v52.641h-64.961v44.801c14.559 25.762 40.32 42.559 71.68 42.559 44.801 0 80.641-35.84 81.762-79.52 33.602-15.68 53.762-53.762 43.68-94.078 3.3594-1.1211 5.6016-2.2383 8.9609-3.3594v-44.801l-94.082-0.003907v-56c-10.078-4.4805-16.801-14.559-16.801-25.762 0-15.68 12.32-28 28-28 15.68 0 28 12.32 28 28 0 11.199-6.7188 21.281-16.801 25.762v33.602h92.961v53.762c19.043-13.441 30.242-36.961 30.242-61.602 0-36.961-25.762-69.441-60.48-79.52z" />
        </svg>
    );
}
export default BrainIcon;
