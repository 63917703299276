import React from 'react';
import { AgentEmailPreferences } from '../../../@Types/Agent';
import { EmailPreferencesList } from '../../../constants/EmailPreferences';
import Toggle from '../../../shared/Toggle/Toggle';
import styles from './EmailPreferences.module.css';
interface EmailPreferencesProps {
    preferences: AgentEmailPreferences;
    handleChange: Function;
}
function EmailPreferences({
    preferences,
    handleChange,
}: EmailPreferencesProps): JSX.Element {
    return (
        <div className={styles.container}>
            <label className={styles.titleLbl}>Correos:</label>
            <div
                className={styles.emailPreferenceList}
                data-testid={'emailPreferences'}
            >
                {EmailPreferencesList.map((preference, index) => (
                    <div className={styles.emailPreference} key={index}>
                        <div
                            className={styles.emailPreferenceLbl + ' noselect'}
                        >
                            {preference.label}
                        </div>
                        <div
                            className={styles.toggleContainer}
                            data-testid={'preferenceToggle'}
                        >
                            <Toggle
                                size="small"
                                checked={
                                    (preferences ?? {})[
                                        preference.key ?? ''
                                    ] !== false
                                }
                                onChange={(checked: boolean): void => {
                                    handleChange({
                                        ...preferences,
                                        [preference.key]: checked,
                                    });
                                }}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
export default EmailPreferences;
