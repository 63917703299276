import styles from './TextInputConfig.module.css';
import { ClientInfoTypes } from '../../../../constants/ClientInfoTypes';
import Toggle from '../../../../shared/Toggle/Toggle';
import React, { useState } from 'react';
import { UniqueSteps } from '../../../../@Types/FormTypes/Form';
import { FormStep, TextInput } from '../../../../@Types/FormTypes/FormStep';
import EntityPropertyTypes from '../../../../constants/EntityPropertyTypes';
import RoundedEntityPropertyPicker from '../../../../shared/@Pickers/RoundedEntityPropertyPicker/RoundedEntityPropertyPicker';
import RoundedClientPropertyPicker from '../../../../shared/@Pickers/RoundedClientPropertyPicker/RoundedClientPropertyPicker';
import ClientPropertyTypes from '../../../../constants/ClientPropertyTypes';
import { useAppSelector } from '../../../../hooks';
import { isIntegration } from '../../../../@Types/Integration';

interface TextInputConfigProps {
    step?: TextInput;
    uniqueSteps?: UniqueSteps;
    updateUniqueSteps?: (unique: UniqueSteps) => void;
    updateStep?: (step: FormStep) => void;
}
function TextInputConfig({
    step,
    updateStep,
    uniqueSteps,
    updateUniqueSteps,
}: TextInputConfigProps): JSX.Element {
    if (!step || !uniqueSteps || !updateUniqueSteps || !updateStep) {
        return <div></div>;
    }
    const [matchCompany, setMatchCompany] = useState(
        step.idCompanyMatcher !== undefined
    );
    const idCompanyEntity = useAppSelector(
        (state) => state.site.organization?.idCompanyEntity
    );

    return (
        <React.Fragment>
            <div className={styles.subjectContainer}>
                <div className={styles.subjectLabel}>Es el asunto:</div>
                <div className={styles.toggleContainer}>
                    <Toggle
                        checked={step.isSubject === true}
                        onChange={(checked: boolean): void => {
                            updateStep({
                                ...step,
                                isSubject: checked,
                            });
                        }}
                    />
                </div>
            </div>
            <div className={styles.clientContainer}>
                <div className={styles.clientLabel}>
                    Información del cliente:
                </div>
                <div className={styles.toggleContainer}>
                    <Toggle
                        checked={step.clientInfoType !== undefined}
                        onChange={(checked: boolean): void => {
                            updateStep({
                                ...step,
                                clientInfoType: checked
                                    ? ClientInfoTypes.NAME
                                    : undefined,
                            });
                        }}
                    />
                </div>
                {step.clientInfoType && (
                    <div className={styles.clientInfoContainer}>
                        <div className={styles.clientInfoLabel}>Tipo: </div>
                        <div className={styles.clientInfoSelectorContainer}>
                            <RoundedClientPropertyPicker
                                fullWidth
                                label={''}
                                height={'31px'}
                                MenuProps={{ disablePortal: true }}
                                value={
                                    step.clientInfoType ?? step.idClientProperty
                                }
                                propertyTypes={[
                                    ClientPropertyTypes.CLIENT_INFO_TYPE,
                                    EntityPropertyTypes.TEXTINPUT,
                                ]}
                                handleUpdate={(idProperty): void => {
                                    if (
                                        Object.values(ClientInfoTypes).includes(
                                            idProperty as ClientInfoTypes
                                        )
                                    ) {
                                        updateStep({
                                            ...step,
                                            clientInfoType:
                                                idProperty as ClientInfoTypes,
                                            idClientProperty: undefined,
                                        });
                                    } else {
                                        updateStep({
                                            ...step,
                                            clientInfoType: undefined,
                                            idClientProperty: idProperty,
                                        });
                                    }
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
            {idCompanyEntity && (
                <div className={styles.clientContainer}>
                    <div className={styles.clientInfoLabel}>
                        Vincular Empresa:
                    </div>
                    <div className={styles.toggleContainer}>
                        <Toggle
                            checked={matchCompany}
                            onChange={(checked: boolean): void => {
                                if (!checked) {
                                    updateStep({
                                        ...step,
                                        idCompanyMatcher: undefined,
                                    });
                                }
                                setMatchCompany(checked);
                            }}
                        />
                    </div>
                    {matchCompany && (
                        <div className={styles.propertySelectorContainer}>
                            <RoundedEntityPropertyPicker
                                idEntity={idCompanyEntity}
                                height="31px"
                                value={step.idCompanyMatcher}
                                label="Propiedad"
                                autoWidth
                                propertyTypes={[EntityPropertyTypes.TEXTINPUT]}
                                handleUpdate={(property): void => {
                                    if (isIntegration(property)) return;
                                    updateStep({
                                        ...step,
                                        idCompanyMatcher: property?.id,
                                    });
                                }}
                            />
                        </div>
                    )}
                </div>
            )}
        </React.Fragment>
    );
}
export default TextInputConfig;
