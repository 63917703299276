import { IconProps } from './@IconTypes';

function Apicon({ className, style, fill }: IconProps): JSX.Element {
    return (
        <svg
            fill={fill}
            style={style}
            className={className}
            version="1.1"
            viewBox="0 0 96 100"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="14.374" cy="25.394" r="3.083" />
            <circle cx="22.738" cy="25.394" r="3.083" />
            <circle cx="31.102" cy="25.394" r="3.083" />
            <rect x="69.42" y="23.894" width="13.79" height="3" />
            <path d="M85.979,16.208H10.022C6.702,16.208,4,18.909,4,22.229v19.174v8.247v10.943v8.246v4.933c0,3.32,2.701,6.021,6.021,6.021  h75.957c3.32,0,6.021-2.701,6.021-6.021v-4.933v-8.247V49.65v-8.247V22.229C92,18.909,89.299,16.208,85.979,16.208z M89,41.403  v8.247v10.942v8.247v4.933c0,1.666-1.355,3.021-3.021,3.021H10.022C8.356,76.793,7,75.438,7,73.772v-4.933v-8.246V49.65v-8.247  V33.45h82V41.403z M89,30.45H7v-8.221c0-1.666,1.355-3.021,3.021-3.021h75.957c1.666,0,3.021,1.355,3.021,3.021V30.45z" />
            <polygon points="41.605,64.562 31.721,54.676 41.605,44.792 39.484,42.671 27.479,54.676 39.484,66.683 " />
            <polygon points="56.517,66.683 68.522,54.676 56.517,42.671 54.396,44.792 64.28,54.676 54.396,64.562 " />
            <rect
                x="46.501"
                y="43.223"
                transform="matrix(0.9555 0.295 -0.295 0.9555 18.2627 -11.7255)"
                width="3"
                height="22.908"
            />
        </svg>
    );
}
export default Apicon;
