import { PreReplyActivity } from '../../../../../@Types/ProcessTypes/Activity';
import React, { useMemo, useState } from 'react';
import InternalActivity from '../InternalActivity';
import { Ticket } from '../../../../../@Types/@Types';
import { PreReplyTicketActivity } from '../../../../../@Types/TicketTypes/TicketActivity';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../utils/_store';
import ActionTypes from '../../../../../constants/ActionTypes';
import { ActivityTypes } from '../../../../../constants/ActivityTypes/ActivityTypes';
import styles from './PreReplyActivity.module.css';
import RoundedPayload from '../../../../../shared/RoundedPayload/RoundedPayload';
import { PreReplyActivityAction } from '../../../../../@Types/ProcessTypes/ActivityAction';
import SmartAgent from '../../../../../shared/Avatar/SmartAgent';
import RoundedButton from '../../../../../shared/RoundedButton/RoundedButton';
import RevisionDialog from './RevisionDialog/RevisionDialog';
import { Payload } from '../../../../../@Types/Payload';
import PreReplyDialog from './PreReplyDialog/PreReplyDialog';
import ActionsService from '../../../../../controllers/TicketsController/Actions/ActionsService';
export interface PreReplyActivityProps {
    ticket: Ticket;
    activity: PreReplyActivity;
    ticketActivity: PreReplyTicketActivity;
}

enum PreReplyStates {
    REPLY = 'REPLY',
    MODIFY = 'MODIFY',
    REVISION = 'REVISION',
}

function PreReplyActivityComponent({
    ticket,
    activity,
    ticketActivity,
}: PreReplyActivityProps): JSX.Element {
    const actions = useSelector(
        (state: RootState) => state.ticketActions.elements
    );
    const [showDialog, setShowDialog] = useState<
        { state: PreReplyStates; payload?: Payload } | undefined
    >(undefined);

    const action: PreReplyActivityAction | undefined = useMemo(
        () =>
            actions.find(
                (action) => action._id === ticketActivity.idAction
            ) as PreReplyActivityAction,
        [actions, ticket]
    );

    const handleClose = (): void => {
        setShowDialog(undefined);
    };

    const state = useMemo(() => {
        if (
            !action ||
            action.type !== ActionTypes.ACTIVITY ||
            action.activityType !== ActivityTypes.PRE_REPLY
        )
            return undefined;
        if (ticketActivity.idAgent && !ticketActivity.idConfirmationAgent)
            return PreReplyStates.REVISION;
        else if (action.revisions.length > 0) {
            return PreReplyStates.MODIFY;
        } else return PreReplyStates.REPLY;
    }, [ticketActivity.idAgent, ticketActivity.idConfirmationAgent]);

    switch (state) {
        case PreReplyStates.REPLY:
            return (
                <React.Fragment>
                    {showDialog?.state === PreReplyStates.REPLY && (
                        <PreReplyDialog
                            onClose={(): void => handleClose()}
                            payload={showDialog.payload}
                            label={'Enviar Borrador'}
                            handlePayload={async (payload): Promise<void> => {
                                await ActionsService.preReply(
                                    ticket.idProject,
                                    ticket._id,
                                    payload,
                                    {}
                                );
                            }}
                        />
                    )}
                    <InternalActivity title={activity.name}>
                        <div
                            className={styles.btnsContainer}
                            style={{ marginTop: 15 }}
                        >
                            <RoundedButton
                                onClick={(): void =>
                                    setShowDialog({
                                        state: PreReplyStates.REPLY,
                                    })
                                }
                                backgroundColor={'var(--secondary)'}
                                text={'Redactar Borrador'}
                                fontSize={'15px'}
                                padding="8px 16px"
                            />
                        </div>
                    </InternalActivity>
                </React.Fragment>
            );
        case PreReplyStates.MODIFY:
            const revision = action.revisions[action.revisions.length - 1];
            return (
                <React.Fragment>
                    {showDialog?.state === PreReplyStates.MODIFY && (
                        <PreReplyDialog
                            label={'Enviar Cambios'}
                            payload={showDialog.payload}
                            handlePayload={async (payload): Promise<void> => {
                                await ActionsService.preReply(
                                    ticket.idProject,
                                    ticket._id,
                                    payload,
                                    {}
                                );
                            }}
                            onClose={(): void => handleClose()}
                        />
                    )}
                    <InternalActivity
                        title={'Realizar Cambios ' + activity.name}
                    >
                        <div className={styles.revisionTop}>
                            <div className={styles.agentContainer}>
                                <SmartAgent
                                    idAgent={revision.idAgentRevision}
                                    suffix=" solicitó los siguientes cambios:"
                                />
                            </div>
                        </div>
                        {revision.comment && (
                            <p className={styles.revisionComment}>
                                {revision.comment}
                            </p>
                        )}
                        <div className={styles.payloadContainer}>
                            <RoundedPayload
                                name={activity.name}
                                payload={
                                    revision.revisionPayload ?? revision.payload
                                }
                                date={
                                    revision.revisionPayload
                                        ? new Date(revision.revision_date)
                                        : new Date(revision.date)
                                }
                                showLbl={false}
                                marginTop={0}
                            />
                        </div>
                        <div className={styles.btnsContainer}>
                            <RoundedButton
                                onClick={(): void =>
                                    setShowDialog({
                                        payload:
                                            revision.revisionPayload ??
                                            revision.payload,
                                        state: PreReplyStates.MODIFY,
                                    })
                                }
                                backgroundColor={'var(--secondary)'}
                                text={'Realizar Cambios'}
                                fontSize={'15px'}
                                padding="8px 16px"
                            />
                        </div>
                    </InternalActivity>
                </React.Fragment>
            );
        case PreReplyStates.REVISION:
            if (!action.payload) return <></>;
            return (
                <React.Fragment>
                    {showDialog?.state === PreReplyStates.MODIFY && (
                        <RevisionDialog
                            payload={showDialog.payload}
                            onClose={(): void => handleClose()}
                            ticket={ticket}
                        />
                    )}
                    {showDialog?.state === PreReplyStates.REPLY && (
                        <PreReplyDialog
                            onClose={(): void => handleClose()}
                            label={'Enviar'}
                            payload={showDialog.payload}
                            title="Respuesta al Cliente"
                            handlePayload={async (payload): Promise<void> => {
                                await ActionsService.confirmPreReply(
                                    ticket.idProject,
                                    ticket._id,
                                    payload,
                                    {}
                                );
                            }}
                        />
                    )}
                    <InternalActivity title={'Revisión ' + activity.name}>
                        {ticketActivity.idAgent && (
                            <div className={styles.revisionTop}>
                                <div className={styles.agentContainer}>
                                    <SmartAgent
                                        idAgent={ticketActivity.idAgent}
                                        suffix=":"
                                    />
                                </div>
                            </div>
                        )}
                        <div className={styles.payloadContainer}>
                            <RoundedPayload
                                name={activity.name}
                                payload={action.payload}
                                date={new Date()}
                                showLbl={false}
                                marginTop={5}
                            />
                        </div>
                        <div className={styles.btnsContainer}>
                            <RoundedButton
                                onClick={(): void =>
                                    setShowDialog({
                                        payload: action.payload ?? undefined,
                                        state: PreReplyStates.MODIFY,
                                    })
                                }
                                backgroundColor={'var(--error)'}
                                text={'Solicitar Cambios'}
                                fontSize={'15px'}
                                padding="8px 16px"
                            />
                            <RoundedButton
                                onClick={(): void =>
                                    setShowDialog({
                                        payload: action.payload ?? undefined,
                                        state: PreReplyStates.REPLY,
                                    })
                                }
                                backgroundColor={'var(--secondary)'}
                                text={'Utilizar Borrador'}
                                fontSize={'15px'}
                                padding="8px 16px"
                            />
                        </div>
                    </InternalActivity>
                </React.Fragment>
            );

        default:
            return <></>;
    }
}

export default PreReplyActivityComponent;
