import React, { useEffect, useState } from 'react';
import { Area, Ticket } from '../../../../../../../@Types/@Types';
import { RootState } from '../../../../../../../utils/_store';
import styles from './TransferArea.module.css';
import AreaComponent from './Area/Area';
import { useDispatch, useSelector } from 'react-redux';
import RoundedButton from '../../../../../../../shared/RoundedButton/RoundedButton';
import TransferDialog from '../TransferDialog/TransferDialog';
import ActionsService from '../../../../../../../controllers/TicketsController/Actions/ActionsService';
import VanillaToasts from '../../../../../../../shared/Toast/Toast';
import { appendAction } from '../../../../../../../controllers/TicketsController/Actions/ActionsActions';
interface TransferAreaProps {
    /** Function to call to close the menu */
    handleClose: Function;
    /** The currently selected Ticket */
    selectedTicket: Ticket;
    /** The current search value */
    search: string | undefined;
    /** The areas to display */
    areas: Area[];
}
function TransferArea({
    handleClose,
    selectedTicket,
    search,
    areas,
}: TransferAreaProps): JSX.Element {
    const siteInfo = useSelector((state: RootState) => state.site);
    const dispatch = useDispatch();
    const [showConfirm, setShowConfirm] = useState<Area | undefined>(undefined);
    const [selectedElement, setSelectedElement] = useState<Area | undefined>(
        undefined
    );
    const [elements, setElements] = useState(areas);

    /** When the search changes refilter the area list */
    useEffect(() => {
        if (search) {
            const regex = new RegExp(search, 'gi');
            setElements(
                areas.filter(
                    (area) =>
                        regex.test(area.name) || regex.test(area.description)
                )
            );
        } else {
            setElements(areas);
        }
    }, [search]);

    return (
        <div className={styles.container}>
            {showConfirm !== undefined && (
                <TransferDialog
                    onTransfer={async (
                        {
                            element,
                            removeAll,
                        }: { element: Area; removeAll: boolean },
                        setLoading: Function
                    ): Promise<void> => {
                        if (siteInfo.idProject) {
                            try {
                                setLoading(true);
                                const action =
                                    await ActionsService.transferArea(
                                        siteInfo.idProject,
                                        selectedTicket._id,
                                        element._id,
                                        removeAll
                                    );
                                dispatch(appendAction(action));
                                handleClose();
                            } catch (error) {
                                VanillaToasts.create({
                                    title: 'Error al transferir a el área',
                                    text: error,
                                    type: 'error',
                                    timeout: 3000,
                                });
                                console.error(error);
                                setLoading(false);
                            }
                        }
                    }}
                    element={showConfirm}
                    title="Transferir Área"
                    elementName={showConfirm.name}
                    onClose={(): void => {
                        setShowConfirm(undefined);
                    }}
                />
            )}
            <div className={styles.listContainer}>
                {elements.map((area, index) => (
                    <AreaComponent
                        last={index === elements.length - 1}
                        area={area}
                        key={index}
                        handleClick={(): void => {
                            setSelectedElement(area);
                        }}
                        selected={selectedElement?._id === area._id}
                    />
                ))}
                {elements.length === 0 && (
                    <div className={styles.emptyContainer}>No hay areas</div>
                )}
            </div>
            {elements.length !== 0 && (
                <div className={styles.transferBtnContainer}>
                    <RoundedButton
                        text="Transferir"
                        onClick={(): void => {
                            if (selectedElement !== undefined) {
                                setShowConfirm(selectedElement);
                            }
                        }}
                        borderRadius={3}
                        disabled={selectedElement === undefined}
                    ></RoundedButton>
                </div>
            )}
        </div>
    );
}
export default TransferArea;
