import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './Attachments.module.css';
import AttachFileRoundedIcon from '@material-ui/icons/AttachFileRounded';
import React from 'react';
import {
    PayloadEditorActions,
    PayloadEditorFilesSelectors,
    usePayloadEditorDispatch,
    usePayloadEditorSelector,
} from '../../../controllers/PayloadEditorController/PayloadEditorSlice';
import { getAcceptedExtensions } from '../../../constants/Files/extensions';
import { PayloadEditorStateTypes } from '../../../controllers/PayloadEditorController/PayloadEditorTypes';
import { CircularProgress } from '@material-ui/core';
import AttachmentsMenu from './AttachmentsMenu/AttachmentsMenu';

interface AttachmentsProps {}
/**
 * Generic attach file button for text editor
 */
function Attachments({}: AttachmentsProps): JSX.Element {
    const [showMenu, setShowMenu] = useState(false);
    const dispatch = usePayloadEditorDispatch();

    const isDirty = usePayloadEditorSelector((state) => state.isDirty);

    const files = usePayloadEditorSelector((state) =>
        PayloadEditorFilesSelectors.selectAll(state)
    );

    const inputRef = useRef<HTMLInputElement>(null);
    const btnRef = useRef<HTMLButtonElement>(null);

    const loading = useMemo(
        () =>
            files.find(
                (file) => file.state === PayloadEditorStateTypes.UPLOADING
            ) !== undefined,
        [files]
    );

    const error = useMemo(() => {
        if (
            isDirty &&
            files.find(
                (file) => file.state === PayloadEditorStateTypes.UPLOADING
            )
        ) {
            return 'Algunos archivos no han terminado de subirse!';
        } else if (
            files.find((file) => file.state === PayloadEditorStateTypes.ERROR)
        ) {
            return 'Hay archivos con errores!';
        }
        return undefined;
    }, [files, isDirty]);

    useEffect(() => {
        if (showMenu && files.length === 0) {
            setShowMenu(false);
        }
    }, [files]);

    const onButtonClick = (): void => {
        if (inputRef.current) {
            if (files.length > 0) {
                setShowMenu(true);
            } else {
                inputRef.current.click();
            }
        }
    };

    const onFilesSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const newFiles = e.target.files;
        if (newFiles) {
            dispatch(PayloadEditorActions.uploadFiles(Array.from(newFiles)));
            setShowMenu(true);
            if (inputRef.current) {
                inputRef.current.value = '';
            }
        }
    };

    return (
        <div
            onClick={onButtonClick}
            className={styles.btnContainer}
            data-testid={'Attachments'}
        >
            <button className={styles.attachButton} ref={btnRef}>
                <AttachFileRoundedIcon fontSize="inherit" />
                <div className={styles.countContainer}>
                    {files && files.length > 0 && (
                        <div className={styles.countLbl}>{files.length}</div>
                    )}
                    {!showMenu && loading && (
                        <CircularProgress
                            size={18}
                            style={{
                                color: 'var(--secondary)',
                                position: 'absolute',
                            }}
                        />
                    )}
                </div>
            </button>
            {showMenu && files && files.length > 0 && (
                <AttachmentsMenu
                    files={files}
                    anchorRef={btnRef}
                    handleAddFiles={(): void => inputRef.current?.click()}
                    handleClose={(): void => {
                        setShowMenu(false);
                    }}
                />
            )}
            {error && !showMenu && (
                <React.Fragment>
                    <div className={styles.errorArrow}></div>
                    <div className={styles.errorPopper}>
                        <label className={styles.errorPopperLbl}>{error}</label>
                    </div>
                </React.Fragment>
            )}
            <input
                type="file"
                ref={inputRef}
                style={{ display: 'none' }}
                onChange={onFilesSelected}
                multiple
                accept={getAcceptedExtensions()}
            />
        </div>
    );
}
export default Attachments;
