import styles from './AgentTreeNode.module.css';
import PersonIcon from '@material-ui/icons/Person';
import { Agent } from '../../../@Types/@Types';
interface AgentTreeNodeProps {
    /** The Agent to display */
    element: Agent;
    /** Funtion that handles when node is clicked*/
    handleItemClick: Function;
    /** the id of the selected element */
    selectedElement: String | undefined;
}
/**
 * Tree Agent Component
 */
function AgentTreeNodeProps({
    element,
    handleItemClick,
    selectedElement,
}: AgentTreeNodeProps): JSX.Element {
    return (
        <div className={styles.treeNodeContainer}>
            <div
                style={{
                    backgroundColor:
                        selectedElement === element._id
                            ? 'var(--defaultGrey)'
                            : 'var(--primary1)',
                }}
                className={styles.treeNodeTitle}
                onClick={(): void => {
                    if (selectedElement === element._id) {
                        handleItemClick(undefined);
                    } else {
                        handleItemClick(element._id);
                    }
                }}
            >
                <div className={styles.userIconContainer}>
                    <div className={styles.userIcon}>
                        <PersonIcon fontSize="inherit" />
                    </div>
                </div>
                <div className={styles.label}>{element.name}</div>
            </div>
        </div>
    );
}

export default AgentTreeNodeProps;
