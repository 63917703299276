import axiosInstance from '../../AxiosAPI';
import { Ticket, TicketClient } from '../../@Types/TicketTypes/Ticket';
import {
    defaultOrderby,
    TicketFilters,
    ticketsToQueryString,
} from './TicketsReducer';
import TicketFilterTypes from '../../constants/TicketFilterTypes';
import { PayloadFile } from '../../@Types/Payload';

/**
 * Function that loads the tickets' list
 * @param idProject the id of the current project
 * @param idTicket the id of the ticket to fetch
 * @param queryString the users filters in query string from
 * @returns state of the list to display
 */
export const loadTickets = async (
    idProject: string,
    queryString?: string,
    idTicket?: string,
    forced?: boolean
): Promise<{
    elements: Ticket[];
    selectedElement?: number;
    filters: TicketFilters;
    orderBy: string | undefined;
    search: string | undefined;
}> => {
    const query = new URLSearchParams(queryString);
    if (!query.has('all')) {
        query.set('all', '' + false);
    }

    if (!query.has('companyResponsible')) {
        query.set('companyResponsible', '' + false);
    }

    if (!query.has('type')) {
        query.set('type', '' + TicketFilterTypes.ALL);
    }

    if (!query.has('search')) {
        query.set('defaultOrderby', defaultOrderby);
    }
    if (query.has('forced')) query.delete('forced');
    if (forced) {
        query.set('forced', 'true');
    }
    if (!query.has('withNotifications')) {
        query.set('withNotifications', 'false');
    }
    const response = await axiosInstance.get(
        `/projects/${idProject}/tickets/load${
            idTicket ? '/' + idTicket : ''
        }?${query}`
    );

    //TODO talvez validar los filtros que llegan del back?

    const filters = response?.data?.filters;
    if (filters?.crtStartDate) {
        filters.crtStartDate = new Date(parseInt(filters.crtStartDate));
    }

    if (filters?.crtEndDate) {
        filters.crtEndDate = new Date(parseInt(filters.crtEndDate));
    }

    if (filters?.clsStartDate) {
        filters.clsStartDate = new Date(parseInt(filters.clsStartDate));
    }

    if (filters?.clsEndDate) {
        filters.clsEndDate = new Date(parseInt(filters.clsEndDate));
    }

    return {
        ...response.data,
        elements:
            response?.data?.elements?.map(
                (ticket: any): Ticket =>
                    ({
                        ...ticket,
                        resolution_date: new Date(ticket.resolution_date),
                        creation_date: new Date(ticket.creation_date),
                        pendingNotificationsToMarkAsRead: {},
                    } as Ticket)
            ) ?? [],
        filters,
    };
};

/**
 * Function that loads the tickets' list
 * @param idProject the id of the current project
 * @param page currently viewing
 * @param pageSize to request
 * @param filters currently active
 * @param orderBy method ordered by, undefined if search is active
 * @param search search query, undefined if no search is active
 * @returns array of Tickets that match the inputs
 */
export const fetchTickets = async (
    idProject: string,
    page: number,
    pageSize: number,
    filters: TicketFilters,
    orderBy: string,
    search: string | undefined
): Promise<Ticket[]> => {
    let url = `/projects/${idProject}/tickets?page=${page}&pageSize=${pageSize}`;
    url += '&' + ticketsToQueryString(filters, orderBy, search);
    const response = await axiosInstance.get(url);
    return response.data.map(
        (ticket: any): Ticket =>
            ({
                ...ticket,
                resolution_date: new Date(ticket.resolution_date),
                creation_date: new Date(ticket.creation_date),
                pendingNotificationsToMarkAsRead: {},
            } as Ticket)
    );
};

/**
 * Function that fetches a single ticket given its id
 * @param idProject the id of the current project
 * @param idTicket the id of the ticket to fetch
 * @returns ticket
 */
export const loadTicketById = async (
    idProject: string,
    idTicket: string
): Promise<Ticket> => {
    let url = `/projects/${idProject}/tickets/${idTicket}`;
    const response = await axiosInstance.get(url);
    const ticket = response.data;
    return {
        ...ticket,
        resolution_date: new Date(ticket.resolution_date),
        creation_date: new Date(ticket.creation_date),
    } as Ticket;
};

/**
 * Function called to get the files detail of the ticket content, specially the url for download file
 * @param idProject the id of the current project
 * @param idTicket the id of the current ticket
 */
export const loadFiles = async (
    idProject: string,
    idTicket: string
): Promise<Record<string, any>> => {
    let url = `/projects/${idProject}/tickets/${idTicket}/files`;
    let response = await axiosInstance.get(url);
    return response.data;
};

export const createSubTicket = async (
    idProject: string,
    idTicket: string,
    subTicket: Record<string, unknown>
): Promise<void> => {
    await axiosInstance.post(
        `/projects/${idProject}/tickets/${idTicket}`,
        subTicket
    );
};

export const deleteTicket = async (
    idProject: string,
    idTicket: string
): Promise<void> => {
    await axiosInstance.delete(`/projects/${idProject}/tickets/${idTicket}`);
};

/**
 * Function that handles the async update of an client's value
 * @param idProject of the project
 * @param idTicket of the ticket to update
 * @param client Record of values to update
 */
export const updateTicketClient = async (
    idProject: string,
    idTicket: string,
    client: Partial<TicketClient>
): Promise<void> => {
    await axiosInstance.patch(
        `/projects/${idProject}/tickets/${idTicket}/client`,
        client
    );
};

/**
 * Function called to get the file of the summary of the ticket
 * @param idProject the id of the current project
 * @param idTicket the id of the current ticket
 */
export const getSummary = async (
    idProject: string,
    idTicket: string
): Promise<PayloadFile> => {
    const response = await axiosInstance.get(
        `/projects/${idProject}/tickets/${idTicket}/summary`
    );
    return response.data;
};

/**
 * Function called to handle the conexion with the server to merge a ticketClient with a client
 * @param idClient the id of the client to delete
 */
export const mergeClients = async (
    idProject: string,
    idTicket: string,
    idClient: string
): Promise<void> => {
    await axiosInstance.patch(
        `/projects/${idProject}/tickets/${idTicket}/mergeClient`,
        {
            idClient,
        }
    );
};

export default {
    mergeClients,
    getSummary,
    loadTickets,
    fetchTickets,
    loadFiles,
    createSubTicket,
    deleteTicket,
};
