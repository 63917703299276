import { StepComponentProps } from '../Step';
import styles from './CreateStep.module.css';
import React from 'react';
import StepContainer from '../StepContainer/StepContainer';
import DraftRenderer from '../../../../../shared/DraftRenderer/DraftRenderer';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import {
    CreateStep,
    DefaultCreateStep,
} from '../../../../../@Types/ConversationTypes/ConversationStep';
import StepTypes from '../../../../../constants/Conversations/ConversationStepTypes';
import { EditorState, convertFromRaw } from 'draft-js';
interface CreateStepProps extends Partial<StepComponentProps> {
    step: CreateStep;
    isDefault?: boolean;
}

function CreateStepComponent({
    step,
    path,
    isDefault,
}: CreateStepProps): JSX.Element {
    if (step.type === StepTypes.CREATE_PASSTHROUGH) {
        if (step.message) {
            return (
                <StepContainer
                    step={step}
                    path={path ?? null}
                    draggable={isDefault !== true}
                >
                    <div className={styles.messageContainer}>
                        <DraftRenderer
                            editorState={EditorState.createWithContent(
                                convertFromRaw(step.message)
                            )}
                        />
                    </div>
                </StepContainer>
            );
        } else {
            return (
                <StepContainer
                    hidden
                    step={step}
                    path={path ?? null}
                    draggable={isDefault !== true}
                >
                    <div className={styles.passthroughContainer}>
                        <img
                            src="/media/icons/blackTicketIcon.svg"
                            className={styles.ticketIcon}
                        />
                        <div className={styles.passthoughLbl}>{step.name}</div>
                    </div>
                </StepContainer>
            );
        }
    } else {
        return (
            <StepContainer
                step={step}
                header={step.showUrl ? step.header : undefined}
                footer={step.showUrl ? step.footer : undefined}
                path={path ?? null}
                draggable={isDefault !== true}
                bottomComponent={
                    step.showUrl ? (
                        <div className={styles.messageBtn}>
                            <div className={styles.linkContainer}>
                                <OpenInNewRoundedIcon fontSize="inherit" />
                            </div>
                            {step.label}
                        </div>
                    ) : undefined
                }
            >
                <div className={styles.messageContainer}>
                    <DraftRenderer
                        editorState={EditorState.createWithContent(
                            convertFromRaw(step.message)
                        )}
                    />
                </div>
            </StepContainer>
        );
    }
}
export default React.memo(CreateStepComponent, (prevProps, nextProps) => {
    //Should only check what visually changes.
    const step = prevProps.step;
    if (prevProps.step.type !== nextProps.step.type) {
        return false;
    }
    if (step.type === StepTypes.CREATE_DEFAULT) {
        const newStep = nextProps.step as DefaultCreateStep;
        if (
            step.message !== newStep.message ||
            step.showUrl !== newStep.showUrl
        ) {
            return false;
        }
        if (step.showUrl && newStep.showUrl) {
            if (
                step.header !== newStep.header ||
                step.footer !== newStep.footer ||
                step.label !== newStep.label
            ) {
                return false;
            }
        }
    }
    if (
        step.type === StepTypes.CREATE_PASSTHROUGH &&
        (step.name !== nextProps.step.name ||
            step.message !== nextProps.step.message)
    ) {
        return false;
    }
    return true;
}) as typeof CreateStepComponent;
