import { Ticket } from '../../../@Types/@Types';
import axiosInstance from '../../../AxiosAPI';

export const completeForm = async (
    idProject: string,
    idTicket: string,
    idActivity: string,
    payload: {
        idTrigger: string | null;
        values: Record<string, any>;
    }
): Promise<Ticket> => {
    let url = `/projects/${idProject}/tickets/${idTicket}/activities/${idActivity}/form`;
    return (await axiosInstance.post(url, payload, { timeout: 60000 })).data;
};

export const completeDate = async (
    idProject: string,
    idTicket: string,
    idActivity: string,
    payload: {
        idTrigger: string;
        values: Record<string, any>;
    }
): Promise<Ticket> => {
    let url = `/projects/${idProject}/tickets/${idTicket}/activities/${idActivity}/date`;
    return (await axiosInstance.post(url, payload, { timeout: 60000 })).data;
};

export default {
    completeForm,
    completeDate,
};
