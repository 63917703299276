import { CBRConversationStep } from '../../../../@Types/ConversationTypes/CBRConversationStep';
import StepTypes from '../../../../constants/Construction/CBRFormStepTypes';
import ListApiStep from './Collection/ListApiStep/ListApiStep';
import TextStep from './Collection/TextStep/TextStep';
import CBRElementStep from './Construction/CBRElementStep/CBRElementStep';
import { StepComponentProps } from './Step';

interface CBRStepMapperProps extends StepComponentProps {
    step: CBRConversationStep;
}
function CBRStepMapper(props: CBRStepMapperProps): JSX.Element {
    switch (props.step.type) {
        case StepTypes.CBR_DOC:
        case StepTypes.CBR_CEL:
        case StepTypes.CBR_EMAIL:
        case StepTypes.CBR_PHONE:
        case StepTypes.CBR_COMENTARIO:
        case StepTypes.CBR_LAST_NAME:
        case StepTypes.CBR_FIRST_NAME:
        case StepTypes.CBR_MIDDLE_NAME:
        case StepTypes.CBR_SECOND_LAST_NAME:
            return <TextStep {...props} step={props.step as any} />;
        case StepTypes.CBR_TIPO_DOC:
        case StepTypes.CBR_PROYECTO:
        case StepTypes.CBR_INMUEBLE:
        case StepTypes.CBR_TIPO_ESPACIO:
        case StepTypes.CBR_MACRO_PROYECTO:
        case StepTypes.CBR_TIPO_SOLICITANTE:
            return <ListApiStep {...props} step={props.step as any} />;
        case StepTypes.CBR_LOCATIVAS:
            return <CBRElementStep {...props} step={props.step as any} />;
        default:
            return <div></div>;
    }
}

export default CBRStepMapper;
