import React from 'react';
import { TextInput } from '../../../../../@Types/EntityTypes/EntityProperty';
import NotificationTypes from '../../../../../constants/NotificationTypes';
import StringValidationTypes, {
    StringValidationValues,
} from '../../../../../constants/StringValidationTypes';
import NotificationTypePicker from '../../../../../shared/@Pickers/RoundedNotificationTypePicker/RoundedNotificationTypePicker';
import Toggle from '../../../../../shared/Toggle/Toggle';
import styles from './TextInputConfig.module.css';
import { StringValidation } from '../../../../../@Types/StringValidation';

interface TextInputConfigProps {
    step?: TextInput;
    updateStep?: (step: TextInput) => void;
}
function TextInputConfig({
    step,
    updateStep,
}: TextInputConfigProps): JSX.Element {
    if (!step || !updateStep) {
        return <div></div>;
    }

    const calcValidation = (
        type: NotificationTypes
    ): StringValidation | undefined => {
        switch (type) {
            case NotificationTypes.EMAIL:
                return StringValidationValues[StringValidationTypes.EMAIL];
            case NotificationTypes.WHATSAPP:
                return StringValidationValues[StringValidationTypes.PHONE];

            default:
                return undefined;
        }
    };

    return (
        <React.Fragment>
            <div className={styles.notificationContainer}>
                <div className={styles.notificationLabel}>
                    Medio de Notificación:
                </div>
                <div className={styles.toggleContainer}>
                    <Toggle
                        checked={step.notificationType !== undefined}
                        onChange={(checked: boolean): void => {
                            updateStep({
                                ...step,
                                notificationType: checked
                                    ? NotificationTypes.EMAIL
                                    : undefined,
                                validation:
                                    StringValidationValues[
                                        StringValidationTypes.EMAIL
                                    ],
                            });
                        }}
                    />
                </div>
            </div>
            {step?.notificationType && (
                <div className={styles.notificationSelectorContainer}>
                    <NotificationTypePicker
                        value={step?.notificationType}
                        handleUpdate={(
                            notificationType: NotificationTypes
                        ): void => {
                            updateStep({
                                ...step,
                                notificationType,
                                validation: calcValidation(notificationType),
                            });
                        }}
                    />
                </div>
            )}
        </React.Fragment>
    );
}
export default TextInputConfig;
