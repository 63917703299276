import ActionsService from '../controllers/TicketsController/Actions/ActionsService';
import CustomTimeout from './CustomTimeout';

interface InProgressReply {
    id: string;
    timeout: CustomTimeout;
    state: 'sending';
}

interface FailedReply {
    id: string;
    state: 'failed';
}

interface PendingReply {
    id: string;
    state: 'pending';
    sameUser: boolean;
}

export type ReplyCache = InProgressReply | FailedReply | PendingReply;
export type RepliesCache = Record<
    string, // id of project
    Record<
        string, // id of ticket
        ReplyCache
    >
>;
const RepliesCache: RepliesCache = {};

export function addPendingReply(
    idProject: string,
    idTicket: string,
    idTempReply: string,
    sameUser = false
): ReplyCache {
    if (!RepliesCache[idProject]) {
        RepliesCache[idProject] = {};
    }
    RepliesCache[idProject][idTicket] = {
        id: idTempReply,
        state: 'pending',
        sameUser,
    };
    return RepliesCache[idProject][idTicket];
}

export function addTempReply(
    idProject: string,
    idTicket: string,
    idTempReply: string,
    timeout: CustomTimeout
): void {
    if (Object.keys(RepliesCache).length === 0)
        window.addEventListener('beforeunload', sendPendingReplies);
    if (!RepliesCache[idProject]) RepliesCache[idProject] = {};
    RepliesCache[idProject][idTicket] = {
        id: idTempReply,
        timeout: timeout,
        state: 'sending',
    };
}

export function removeReply(idProject: string, idTicket: string): void {
    if (RepliesCache[idProject]?.[idTicket]) {
        delete RepliesCache[idProject][idTicket];
        if (Object.keys(RepliesCache[idProject]).length === 0) {
            delete RepliesCache[idProject];
        }
        if (Object.keys(RepliesCache).length === 0) {
            window.removeEventListener('beforeunload', sendPendingReplies);
        }
    }
}

export function resetFailedReply(
    idProject: string,
    idTicket: string,
    idTempReply: string,
    timeout: CustomTimeout
): void {
    if (RepliesCache[idProject]) {
        const cache = RepliesCache[idProject][idTicket];
        if (cache && cache.state === 'failed') {
            RepliesCache[idProject][idTicket] = {
                id: idTempReply,
                state: 'sending',
                timeout: timeout,
            };
        }
    }
}

export function setReplyAsFailed(
    idProject: string,
    idTicket: string,
    idTempReply: string
): void {
    if (RepliesCache[idProject] && RepliesCache[idProject][idTicket]) {
        const cache = RepliesCache[idProject][idTicket];
        if (cache && cache.state === 'sending') {
            RepliesCache[idProject][idTicket] = {
                id: idTempReply,
                state: 'failed',
            };
        }
    }
}

export function getTicketReply(
    idProject: string,
    idTicket: string
): ReplyCache | undefined {
    if (RepliesCache[idProject]) {
        return RepliesCache[idProject][idTicket];
    }
    return undefined;
}

export const sendPendingReplies = async (e: any): Promise<void> => {
    e.preventDefault();
    if (Object.keys(RepliesCache).length > 0) {
        await ActionsService.sendPendingReplies(RepliesCache);
    }
};
