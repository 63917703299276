import { AnyAction } from '@reduxjs/toolkit';
import * as Actions from './GenericMenuActions';

export interface GenericMenuConfig<Type, InitialType> {
    loadElements: (
        page: number,
        pageSize: number,
        search: string | undefined
    ) => Promise<Type[]>;
    /** async function to call to load elements by their initial values */
    loadInitialType: (ids: InitialType[]) => Promise<Type[]>;
    /** Function to calc if should load by initial value */
    isInitialType: (
        elements: InitialType[] | Type[]
    ) => elements is InitialType[];
    /** Function used to get the element's unique key */
    calcId: (element: Type) => string;
}

export type GenericMenu<Type, InitialType> = Record<
    string,
    GenericMenuState<Type, InitialType>
>;

export interface GenericMenuState<Type, InitialType> {
    /** The current search, undefined if none */
    search: string | undefined;
    order: string[];
    /** the currently selected elements */
    selected: string[];
    /** all possible elements */
    elements: Record<string, Type>;
    /** The number of pages currently loaded in the list */
    page: number;
    /** Active if no more pages are available */
    noMoreElements: boolean;
    /** If loader is active */
    loading: boolean;
    /** Companies to not include in the menu */
    elementsToOmit: Type[];
    config: GenericMenuConfig<Type, InitialType>;
}

export const defaultState: GenericMenuState<any, any> = {
    selected: [],
    order: [],
    elements: {},
    elementsToOmit: [],
    search: undefined,
    page: 1,
    noMoreElements: false,
    loading: true,
    config: {} as any,
};

/**
 * Redux Reducer that handles CompaniesActions Tiggers
 * @param state The current state
 * @param action the action that was triggered
 * @returns the new state
 */
const GenericMenuReducer = (
    state: GenericMenu<any, any> = {} as any,
    action: AnyAction
): GenericMenu<any, any> => {
    if (!action.meta?.key || !action.type.startsWith('GENERICMENU/'))
        return state;
    if (Actions.clear.match(action)) {
        const newState = { ...state };
        delete newState[action.meta.key];
        return newState;
    }
    return {
        ...state,
        [action.meta.key]: MenuReducer(state[action.meta.key], action),
    };
};

/**
 * Redux Reducer that handles CompaniesActions Tiggers
 * @param state The current state
 * @param action the action that was triggered
 * @returns the new state
 */
const MenuReducer = (
    state: GenericMenuState<any, any> = defaultState,
    action: AnyAction
): GenericMenuState<any, any> => {
    if (Actions.reset.match(action)) {
        return {
            ...state,
            order: [],
            selected: [],
            elements: {},
            elementsToOmit: action.payload.elementsToOmit,
            search: undefined,
            page: 1,
            noMoreElements: false,
            loading: true,
            config: action.payload.config,
        };
    } else if (Actions.setLoading.match(action)) {
        return {
            ...state,
            loading: action.payload,
        };
    } else if (Actions.getSuccess.match(action)) {
        return {
            ...state,
            loading: false,
            order: action.payload.order,
            selected: action.payload.selected,
            elements: action.payload.elements,
            noMoreElements: action.payload.noMoreElements,
        };
    } else if (Actions.pageScroll.match(action)) {
        return { ...state, page: state.page + 1 };
    } else if (Actions.selectElements.match(action)) {
        return {
            ...state,
            selected: action.payload,
        };
    } else if (Actions.search.match(action)) {
        return {
            ...state,
            order: [],
            elements: state.selected.reduce(
                (a, id) => ({
                    ...a,
                    [id]: state.elements[id],
                }),
                {}
            ),
            page: 1,
            noMoreElements: false,
            loading: true,
            search: action.payload === '' ? undefined : action.payload,
        };
    } else if (Actions.update.match(action)) {
        return {
            ...state,
            elements: {
                ...state.elements,
                [state.config.calcId(action.payload)]: action.payload,
            },
        };
    }
    return state;
};
export default GenericMenuReducer;
