import RoundedSelect from '../../../shared/RoundedSelect/RoundedSelect';
import React from 'react';
import styles from './CreateEntry.module.css';
import RoundedTextField from '../../../shared/RoundedTextField/RoundedTextField';
import RoundedButton from '../../../shared/RoundedButton/RoundedButton';
import { Controller, useForm } from 'react-hook-form';
import { MenuItem } from '@material-ui/core';
import {
    createEntry,
    setupWhatsappSignup,
} from '../../../controllers/EntriesController/EntriesService';
import EntryTypes from '../../../constants/EntryTypes';

interface CreateEntryProps {
    idProject: string;
    /** Function called when the agent has been created */
    handleEntryCreated: Function;
    /** Function called to close the Dialog */
    onClose?: Function;
    /** Function called to update the Dialog's loader */
    setLoading?: Function;
}
function CreateEntry({
    idProject,
    handleEntryCreated,
    onClose,
    setLoading,
}: CreateEntryProps): JSX.Element {
    const {
        getValues,
        control,
        formState: { errors, isDirty, isValid },
    } = useForm({
        mode: 'onChange',
    });

    const handleSubmit = async (): Promise<void> => {
        if (setLoading && onClose) {
            try {
                setLoading(true);
                const values = getValues();
                if (values.type === EntryTypes.WHATSAPP) {
                    const token = await setupWhatsappSignup(idProject, {
                        name: values.entryName,
                        description: values.description,
                    });
                    location.href = `${process.env.REACT_APP_PREFIX}whatsapp.${process.env.REACT_APP_DOMAIN}/setup?token=${token}`;
                } else {
                    await createEntry(idProject, values);
                    handleEntryCreated();
                    onClose();
                }
            } catch (error) {
                //TODO handle errors
                console.error(error);
                setLoading(false);
            }
        }
    };

    return (
        <div
            className={styles.container + ' standard-dialog'}
            data-testid={'CreateEntryContainer'}
        >
            <h2 className="center-anything">Crear Entrada</h2>
            <div
                className="text-field-container"
                data-testid={'CreateEntry_name'}
            >
                <Controller
                    name="entryName"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: 'El nombre es obligatorio',
                    }}
                    render={({ field }): JSX.Element => (
                        <RoundedTextField
                            {...field}
                            label="Nombre"
                            helperText={
                                errors.entryName ? errors.entryName.message : ''
                            }
                            error={!!errors.entryName}
                        />
                    )}
                />
            </div>
            <div
                className="text-field-container"
                data-testid={'CreateEntry_description'}
            >
                <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: 'La descripción es obligatoria',
                    }}
                    render={({ field }): JSX.Element => (
                        <RoundedTextField
                            {...field}
                            label="Descripción"
                            helperText={
                                errors.description
                                    ? errors.description.message
                                    : ''
                            }
                            error={!!errors.description}
                        />
                    )}
                />
            </div>
            <div
                className="text-field-container"
                data-testid={'CreateEntry_type'}
            >
                <Controller
                    name="type"
                    control={control}
                    rules={{ required: 'el tipo es obligatorio' }}
                    defaultValue=""
                    render={({ field }): JSX.Element => (
                        <RoundedSelect
                            {...field}
                            label="Tipo de entrada"
                            value={field.value}
                            handleUpdate={field.onChange}
                            helperText={
                                errors.type
                                    ? errors.type.message?.toString()
                                    : ''
                            }
                            error={!!errors.type}
                            containerMargin="0px"
                            height={'31px'}
                        >
                            <MenuItem value={'FORM'}>Formulario</MenuItem>
                            <MenuItem value={'API'}>
                                Integración por API
                            </MenuItem>
                            <MenuItem value={'EMAIL'}>Correo</MenuItem>
                            <MenuItem value={'WHATSAPP'}>Whatsapp</MenuItem>
                        </RoundedSelect>
                    )}
                />
            </div>

            <div className="center-anything">
                <div className="button">
                    <RoundedButton
                        text="Cancelar"
                        onClick={(): void => {
                            onClose?.();
                        }}
                    />
                </div>
                <div className="button" data-testid={'CreateEntry_confirm'}>
                    <RoundedButton
                        text="Crear Entrada"
                        backgroundColor="var(--secondary)"
                        color="white"
                        disabled={
                            Object.keys(errors).length > 1 ||
                            !isDirty ||
                            !isValid
                        }
                        onClick={handleSubmit}
                    />
                </div>
            </div>
        </div>
    );
}

export default CreateEntry;
