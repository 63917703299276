import styles from './ValuePill.module.css';
import { TicketValue } from '../../@Types/TicketTypes/TicketValue';
import { ProjectValue } from '../../@Types/Project';
import CBRFormStepTypes from '../../constants/Construction/CBRFormStepTypes';
import FormStepTypes from '../../constants/FormStepTypes';
import { calcDate } from '../../utils/DateFunctions';
interface ValuePillProps {
    ticketValue: TicketValue;
    projectValue: ProjectValue;
    /** If the classifier should show the tooltip on hover */
    showToolTip?: boolean;
    /** Function to call when classifier is clicked on */
    handleClick?: Function;
    /** The type of cursor to show */
    cursor?: string;
}
function ValuePill({
    cursor,
    ticketValue,
    projectValue,
    showToolTip = true,
    handleClick,
}: ValuePillProps): JSX.Element {
    if (!projectValue.color || !ticketValue.value) return <div></div>;

    const value = calcValue(projectValue.type, ticketValue.value);

    const cursr = cursor
        ? cursor
        : handleClick !== undefined
        ? 'pointer'
        : 'default';
    return (
        <div
            className={styles.container}
            style={{
                cursor: cursr,
            }}
            onClick={(): void => {
                if (handleClick !== undefined) {
                    handleClick();
                }
            }}
        >
            <div
                className={styles.pill}
                style={{
                    backgroundColor: projectValue.color.background,
                    color: projectValue.color.font,
                    cursor: cursr,
                }}
                title={showToolTip ? projectValue.label + ': ' + value : ''}
            >
                <div className={styles.pillLbl}>
                    {projectValue.label}:{' '}
                    <div className={styles.valueSpan}>{value}</div>
                </div>
            </div>
        </div>
    );
}
export default ValuePill;

function calcValue(type: FormStepTypes | CBRFormStepTypes, value: any): string {
    switch (type) {
        case FormStepTypes.TEXTAREA:
            return value.value ?? '';
        case FormStepTypes.TEXTINPUT:
        case FormStepTypes.SELECTOR:
            return value ?? '';
        case FormStepTypes.ENTITYVALUEPICKER:
            return value.label ?? '';
        case FormStepTypes.DATEPICKER:
            return !isNaN(Date.parse(value)) ? calcDate(new Date(value)) : '';
        case FormStepTypes.TIMEPICKER:
            const parts = [];
            if (value.days) {
                parts.push(`${value.days} día${value.days > 1 ? 's' : ''}`);
            }
            if (value.hours) {
                parts.push(`${value.hours} hora${value.hours > 1 ? 's' : ''}`);
            }
            if (value.minutes) {
                parts.push(
                    `${value.minutes} minuto${value.minutes > 1 ? 's' : ''}`
                );
            }
            if (parts.length === 0)
                return value.working
                    ? 'Horario Hábil Más Cercano'
                    : 'Actualidad';
            if (value.working) {
                const isMultiple = parts.length > 1 || parts[0].includes('s');
                return parts.join(', ') + (isMultiple ? '  Hábiles' : ' Hábil');
            }
            return parts.join(', ');
        default:
            if (type.startsWith('CBR_')) {
                return value.label ?? value + '';
            }
            return value?.toString?.() + '';
    }
}
