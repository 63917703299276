import { createAction } from '@reduxjs/toolkit';
import { Template } from '../../@Types/Template';
import ScrollTypes from '../../constants/ScrollTypes';
import { Payload } from '../../@Types/Payload';

export const Types = {
    RESET: 'TEMPLATES/RESET',
    RESET_SUCCESS: 'TEMPLATES/RESET_SUCCESS',
    REFRESH: 'TEMPLATES/REFRESH',
    GET_SUCCESS: 'TEMPLATES/GET_SUCCESS',
    PAGE_SCROLL: 'TEMPLATES/NEW_PAGE_SCROLL',
    SELECT: 'TEMPLATES/SELECT_ELEMENT',
    UPDATE: 'TEMPLATES/UPDATE_ELEMENT',
    UPDATE_FAILED: 'TEMPLATES/UPDATE_FAILED',
    SEARCH: 'TEMPLATES/SEARCH',
    LOADING: 'TEMPLATES/LOADING',
    ORDER: 'TEMPLATES/ORDER',
    FILTER: 'TEMPLATES/FILTER',
    GO_TO: 'TEMPLATES/GO_TO',
};

/**
 * Called when the templates page is first loaded
 */
export const reset = createAction<{
    history: any;
    queryString: string;
    idTemplate?: string;
}>(Types.RESET);

export const resetSuccess = createAction<{
    elements: Template[];
    selectedElement?: number;
    lastPage: boolean;
    page: number;
    filters: any[];
    orderBy: string | undefined;
    search: string | undefined;
}>(Types.RESET_SUCCESS);

/**
 * Called when list has changed
 */
export const refresh = createAction(Types.REFRESH);

/**
 * Called to toggle the loader
 */
export const setLoading = createAction(
    Types.LOADING,
    function prepare(loading: boolean = true) {
        return {
            payload: loading,
        };
    }
);

/**
 * Called when the list needs to load more elements
 */
export const pageScroll = createAction<ScrollTypes>(Types.PAGE_SCROLL);

/**
 * Called when the selectedElement (index) changes value
 */
export const selectElement = createAction<number | undefined>(Types.SELECT);

/**
 * Called when a value of the selected Agent is updated
 */
export const updateElement = createAction<{
    object: Template;
    field: keyof Omit<Template, 'payload'>;
    value: string;
}>(Types.UPDATE);

export const updatePayload = createAction<{
    object: Template;
    field: 'payload';
    value: Payload;
}>(Types.UPDATE);
export const updateFailed = createAction<Template>(Types.UPDATE_FAILED);

/**
 * Called when the search value changes
 */
export const search = createAction<string>(Types.SEARCH);

/**
 *  Called when the orderby value changes
 */
export const orderBy = createAction<string>(Types.ORDER);

/**
 * Called when the filter values change
 */
export const filter = createAction<string[]>(Types.FILTER);

/**
 *  Called when the saga has sucessfully retrieved the lists elements
 */
export const getSuccess = createAction<{
    selectedElement: number | undefined;
    elements: Template[];
    lastPage: boolean;
}>(Types.GET_SUCCESS);

export const goTo = createAction<{
    history: any;
    idTemplate: string;
    queryString: string;
}>(Types.GO_TO);
