import { Entity } from '../../../@Types/@Types';
import styles from './EntityDetail.module.css';
import React from 'react';
import EditContainer from '../../../shared/EditContainer/EditContainer';
import RoundedButton from '../../../shared/RoundedButton/RoundedButton';
import { RouteComponentProps, withRouter } from 'react-router-dom';
interface EntityDetailProps {
    /** The selected Entity */
    selectedElement?: Entity;
    /** Function called when an agent's value needs updating */
    handleUpdate: Function;
}
function EntityDetail({
    selectedElement,
    handleUpdate,
    history,
}: EntityDetailProps & RouteComponentProps): JSX.Element {
    if (!selectedElement) {
        return <div></div>;
    }
    return (
        <div className={styles.containerDetail} key={selectedElement._id}>
            <div className={styles.titleContainer}>
                <div className={styles.title}>
                    <EditContainer
                        object={selectedElement}
                        field={'name'}
                        handleConfirm={handleUpdate}
                    >
                        <div className={styles.titlelbl}>
                            {selectedElement.name}
                        </div>
                        <input
                            className={styles.nameInput + ' edit-input'}
                            defaultValue={selectedElement.name}
                            type="text"
                            size={10}
                        />
                    </EditContainer>
                    <div></div>
                </div>
            </div>
            <div className={styles.descriptionLabel}>Nombre en plural</div>
            <div className={styles.pluralNameContainer}>
                <EditContainer
                    object={selectedElement}
                    field={'pluralName'}
                    handleConfirm={handleUpdate}
                >
                    <div className={styles.pluralNameLbl}>
                        {selectedElement.pluralName}
                    </div>
                    <input
                        className={styles.pluralNameInput + ' edit-input'}
                        defaultValue={selectedElement.pluralName ?? ''}
                        type="text"
                        size={10}
                    />
                </EditContainer>
            </div>
            <div className={styles.descriptionLabel}>Descripción</div>
            <div className={styles.descriptionContainer}>
                <EditContainer
                    object={selectedElement}
                    field={'description'}
                    handleConfirm={handleUpdate}
                >
                    <div className={styles.description}>
                        {selectedElement.description}
                    </div>
                    <textarea
                        className={styles.descriptionInput + ' edit-input'}
                        defaultValue={selectedElement.description}
                    />
                </EditContainer>
            </div>
            <div className={styles.entityBtn}>
                <RoundedButton
                    text="Editar Entidad"
                    backgroundColor="var(--secondary)"
                    color="white"
                    onClick={(): void => {
                        history.push(`/entities/edit/${selectedElement._id}`);
                    }}
                />
            </div>
        </div>
    );
}
export default withRouter(EntityDetail);
