import { Payload } from '../../../../../../@Types/Payload';
import Dialog from '../../../../../../shared/Dialog/Dialog';
import styles from './PreReplyDialog.module.css';
import RoundedButton from '../../../../../../shared/RoundedButton/RoundedButton';
import { useState } from 'react';
import { refreshCurrent } from '../../../../../../controllers/TicketsController/TicketsActions';
import PayloadEditor from '../../../../../../shared/TextEditor/PayloadEditor';
import {
    PayloadEditorActions,
    usePayloadEditorDispatch,
} from '../../../../../../controllers/PayloadEditorController/PayloadEditorSlice';

interface ChangesDialogProps {
    onClose: () => void;
    label: string;
    payload: Payload | undefined;
    handlePayload: (payload: Payload) => Promise<void>;
    title?: string;
}
function ChangesDialog({
    label,
    payload,
    onClose,
    handlePayload,
    title = 'Borrador de Respuesta al cliente',
}: ChangesDialogProps): JSX.Element {
    const [loading, setLoading] = useState(false);
    const dispatch = usePayloadEditorDispatch();
    const handleSend = async (): Promise<void> => {
        setLoading(true);
        try {
            const payload: Payload | null = await dispatch(
                PayloadEditorActions.calcPayload({
                    required: true,
                    idEditor: 'PreReply',
                }) as any
            ).unwrap();
            if (!payload) return;
            await handlePayload(payload);
            dispatch(refreshCurrent(true));
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    return (
        <Dialog onClose={onClose} maxWidth={'90vw'} open={true}>
            <div className={styles.container}>
                <div className={styles.header}>{title}</div>
                <div className={styles.editorContainer}>
                    <PayloadEditor
                        alwaysScroll
                        idEditor="PreReply"
                        payload={payload}
                        placeholder={'Respuesta al Cliente'}
                    />
                </div>

                <div className={styles.buttonsContainer}>
                    <RoundedButton
                        text={'Cancelar'}
                        onClick={onClose}
                        disabled={loading}
                        backgroundColor={'var(--defaultGrey)'}
                        color={'var(--accent)'}
                        fontSize="20px"
                        padding="4px 14px"
                        height={30}
                    />
                    <RoundedButton
                        text={label}
                        loading={loading}
                        onClick={handleSend}
                        fontSize="20px"
                        padding="4px 14px"
                        backgroundColor="var(--secondary)"
                        height={30}
                    />
                </div>
            </div>
        </Dialog>
    );
}

export default ChangesDialog;
