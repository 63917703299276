import styles from './Step.module.css';
import { ClickAwayListener } from '@material-ui/core';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
    copyStep,
    deleteStep,
    pasteStep,
    moveStepUp,
    updateStep,
    moveStepDown,
} from '../../../controllers/GenericEditorController/GenericEditorActions';
import BottomContainer from './BottomContainer';
import { FormSize, GBaseStep } from '../../../@Types/GenericForm';
import React from 'react';
import { WidthStats } from '../Section/Section';
import { calcFillerSize } from '../StepFunctions';
import {
    GLocation,
    SectionLocation,
} from '../../../@Types/FormTypes/LocationTypes';
import {
    useGenericEditorDispatch,
    useGenericEditorSelector,
} from '../GenericFormEditorHooks';
import { IdGenericEditorContext } from '../GenericFormEditor';

export interface GenericStepProps<S extends GBaseStep, U, L extends GLocation>
    extends StepComponentProps<S, U, L> {
    editing: boolean;
    updateStep: (step: S) => void;
    /** Function that checks if changes were made */
    stepEqualityChecker?: (
        prev: Readonly<{ step: S; uniqueSteps: U }>,
        next: Readonly<{ step: S; uniqueSteps: U }>
    ) => boolean;
}

export interface StepComponentProps<
    S extends GBaseStep,
    U,
    L extends GLocation
> {
    /** The currently open step */
    openId: string | undefined;
    step: S;
    location: L | SectionLocation;
    handleParentClose?: Function;
    level: number;
    size: FormSize;
    uniqueSteps: U;
    stepMenuComponent?: JSX.Element;
    updateUniqueSteps: (unique: U) => void;
    /** If the user can change the step's id */
    editId: boolean;
    /** Functions to pass to the stepMapper */
    stepMapperProps: Record<string, any>;
    /** The editor's current stats */
    widthStats: WidthStats;
    customStepBtns?: (props: GenericStepProps<any, U, any>) => JSX.Element;
    stepMapper: (props: GenericStepProps<any, U, any>) => JSX.Element;
}

interface StepComponentHelperProps<
    S extends GBaseStep,
    U,
    L extends GLocation
> {
    /** Called to set the new openId */
    onOpen: Function;
    /** If the step can move up */
    canMoveUp: boolean;
    /** If the step can move up */
    canMoveDown: boolean;
    /** If the step can be cloned */
    canClone?: boolean;
    /** If the step can be deleted */
    canDelete?: boolean;
    /** If the copied info can be pasted */
    canPaste?: boolean;
    /** If the user can change the step's id */
    editId: boolean;
    customStepBtns?: (props: GenericStepProps<S, U, L>) => JSX.Element;
    stepMapper: (props: GenericStepProps<S, U, L>) => JSX.Element;
    /** Function that overrides the updateStep hook */
    handleUpdate?: (step: S) => void;
    /** If editing is disabled */
    disabled?: boolean;
}

export type FullStepComponentProps<
    S extends GBaseStep,
    U,
    L extends GLocation
> = StepComponentProps<S, U, L> & StepComponentHelperProps<S, U, L>;

export function BaseStepComponent<S extends GBaseStep, U, L extends GLocation>({
    location,
    size,
    level,
    step,
    openId,
    onOpen,
    editId,
    disabled,
    customStepBtns,
    widthStats,
    stepMapper,
    canPaste = true,
    canClone = true,
    canMoveUp,
    canDelete = true,
    canMoveDown,
    handleUpdate,
    handleParentClose,
    ...others
}: FullStepComponentProps<S, U, L>): JSX.Element {
    const dispatch = useGenericEditorDispatch();
    const copiedInfo = useGenericEditorSelector((state) => state.copiedInfo);
    const error = useGenericEditorSelector((state) => state.error);
    const idEditor = useContext(IdGenericEditorContext);
    const [editing, setEditing] = useState(false);
    const editingRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setEditing(step.id === openId);
    }, [step.id, openId]);

    useEffect(() => {
        if (error?.steps[step.id]) setEditing(true);
        if (error?.idStep === step.id && !editing) {
            document.getElementById(idEditor + '-' + step.id)?.scrollIntoView();
        }
    }, [error]);

    const handleClose = useCallback(
        (e: any): void => {
            if (document.getElementById('GenericEditorSaveDialogError')) return;
            if (!editingRef.current?.contains(e.target)) {
                handleParentClose?.(e);
                setEditing(false);
            }
        },
        [editingRef.current]
    );

    const stepProps = {
        ...others,
        stepMapper,
        customStepBtns,
        location,
        openId,
        level,
        size,
        widthStats,
        step,
        editing,
        editId,
        handleParentClose: handleClose,
        updateStep: (step: S): void => {
            if (handleUpdate) {
                handleUpdate(step);
            } else {
                dispatch(
                    updateStep({
                        step,
                        location,
                    })
                );
            }
        },
    };

    if (editing) {
        return (
            <ClickAwayListener
                mouseEvent="onMouseDown"
                onClickAway={handleClose}
            >
                <div className={styles.editingContainer} ref={editingRef}>
                    <div className={styles.btnContainer}>
                        {customStepBtns && customStepBtns(stepProps)}
                        <div
                            className={
                                canClone
                                    ? styles.copyBtn
                                    : styles.disabledCopyBtn
                            }
                            onClick={(): void => {
                                if (canClone)
                                    dispatch(
                                        copyStep({
                                            idStep: step.id,
                                            location,
                                        })
                                    );
                            }}
                            title={'Copiar'}
                        >
                            <FileCopyRoundedIcon fontSize="inherit" />
                        </div>

                        <div
                            className={
                                copiedInfo && canPaste
                                    ? styles.pasteBtn
                                    : styles.disabledBtn
                            }
                            onClick={(): void => {
                                if (copiedInfo && canPaste) {
                                    dispatch(pasteStep(location));
                                }
                            }}
                            title={'Pegar'}
                        >
                            <GetAppRoundedIcon fontSize="inherit" />
                        </div>
                        <div
                            className={
                                canDelete
                                    ? styles.deleteBtn
                                    : styles.disabledBtn
                            }
                            onClick={(): void => {
                                if (canDelete)
                                    dispatch(
                                        deleteStep({
                                            idStep: step.id,
                                            location,
                                        })
                                    );
                            }}
                            title={'Borrar'}
                        >
                            <DeleteRoundedIcon fontSize="inherit" />
                        </div>
                        <div
                            className={
                                canMoveUp ? styles.upBtn : styles.disabledBtn
                            }
                            onClick={(): void => {
                                if (canMoveUp) dispatch(moveStepUp(location));
                            }}
                        >
                            <ArrowUpwardRoundedIcon fontSize="inherit" />
                        </div>
                        <div
                            className={
                                canMoveDown
                                    ? styles.downBtn
                                    : styles.disabledBtn
                            }
                            onClick={(): void => {
                                if (canMoveDown)
                                    dispatch(moveStepDown(location));
                            }}
                        >
                            <ArrowDownwardRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                    {stepMapper(stepProps)}
                    <BottomContainer
                        step={step}
                        updateStep={(step: S): void => {
                            if (handleUpdate) {
                                handleUpdate(step);
                            } else {
                                dispatch(
                                    updateStep({
                                        step,
                                        location,
                                    })
                                );
                            }
                        }}
                        location={location}
                        editId={editId}
                    />
                </div>
            </ClickAwayListener>
        );
    } else {
        const renderStep = (): JSX.Element => {
            return (
                <div
                    className={styles.container}
                    id={idEditor + '-' + step.id}
                    onClick={(e): void => {
                        if (!disabled) {
                            if (location !== null) e.stopPropagation();
                            setEditing(true);
                            onOpen();
                        }
                    }}
                >
                    <div
                        className={
                            disabled
                                ? styles.disabledStepCurtain
                                : styles.stepCurtain
                        }
                    ></div>
                    {stepMapper(stepProps)}
                </div>
            );
        };
        if (
            level === 0 &&
            (step as any).maxSize &&
            (step as any).maxSize <= size.blockNum &&
            (step as any).size &&
            (step as any).size > 0
        ) {
            return (
                <div
                    className={styles.firstLvlContainer}
                    style={{
                        width:
                            widthStats.currentBreakPoint <= (step as any).size
                                ? '100%'
                                : 'fit-content',
                    }}
                >
                    {renderStep()}
                    <div
                        className={styles.smallSeparator}
                        style={{
                            width: calcFillerSize(step, size) + 20,
                        }}
                    ></div>
                </div>
            );
        } else {
            return (
                <React.Fragment>
                    {renderStep()}
                    {level === 0 && (step as any).maxSize && (
                        <div className={styles.separator}></div>
                    )}
                </React.Fragment>
            );
        }
    }
}

function StepComponent<S extends GBaseStep, U, L extends GLocation>({
    idStep,
    ...props
}: Omit<FullStepComponentProps<S, U, L>, 'step'> & {
    stepEqualityChecker?: (
        prev: Readonly<{ step: S; uniqueSteps: U }>,
        next: Readonly<{ step: S; uniqueSteps: U }>
    ) => boolean;
    idStep: string;
}): JSX.Element {
    const step = useGenericEditorSelector((state) => state.steps[idStep]);

    if (!step) return <div></div>;
    return (
        <BaseStepComponent
            stepEqualityChecker={undefined}
            {...props}
            step={step as S}
        />
    );
}

export default StepComponent;
