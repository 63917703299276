import { StepComponentProps } from '../../Step';
import styles from './InfoAudioStep.module.css';
import React from 'react';
import StepContainer from '../../StepContainer/StepContainer';
import { InfoAudioStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';

interface InfoAudioStepProps extends StepComponentProps {
    step: InfoAudioStep;
}

function InfoAudioStepComponent({
    step,
    path,
}: InfoAudioStepProps): JSX.Element {
    return (
        <StepContainer step={step} path={path}>
            <div className={styles.audioContainer}>
                <audio controls>
                    <source src={step.file.url} type="audio/mp3" />
                    Your browser does not support the audio element.
                </audio>
            </div>
        </StepContainer>
    );
}

export default InfoAudioStepComponent;
