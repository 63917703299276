import { Agent } from '../../../../../@Types/@Types';
import Avatar from '../../../../../shared/Avatar/Avatar';

interface ActionAvatarProps {
    /** Agent to display */
    agent: Agent;
}

/**
 * Component used to display the agent that made an action
 */
function ActionAvatar({ agent }: ActionAvatarProps): JSX.Element {
    return <Avatar img={agent?.img} lbl={agent?.name} toolTip={true} />;
}

export default ActionAvatar;
