import styles from './Section.module.css';
import editorStyles from '../GenericFormEditor.module.css';
import { useEffect, useState, useRef } from 'react';
import { Link, match, useRouteMatch, useHistory } from 'react-router-dom';
import {
    updateUniqueSteps,
    setCurrentSection,
    updateSection,
    updateName,
    addSection,
} from '../../../controllers/GenericEditorController/GenericEditorActions';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded';
import NavigateNextRounded from '@material-ui/icons/NavigateNextRounded';
import { BaseEditorProps, RecursiveSectionProps } from '../GenericFormEditor';
import Loader from '../../Loader/Loader';
import PageTitle from '../../PageTitle/PageTitle';
import NextMenu from './NextMenu/NextMenu';
import { GBaseStep, GForm, Section } from '../../../@Types/GenericForm';
import StepComponent from '../Step/Step';
import AddStepBtn from '../AddStepBtn/AddStepBtn';
import { GenericEditorState } from '../../../controllers/GenericEditorController/GenericEditorReducer';
import { GLocation } from '../../../@Types/FormTypes/LocationTypes';
import LocationTypes from '../../../constants/LocationTypes';
import {
    useGenericEditorDispatch,
    useGenericEditorSelector,
} from '../GenericFormEditorHooks';
import { useCurrentProject } from '../../../hooks';
export interface WidthStats {
    currentBreakPoint: number;
    isMobile: boolean;
    isResponsive: boolean;
}

interface idSectionRouteParam {
    /** The id of the currentSection */
    idSection: string;
}

interface SectionComponentProps<S extends GBaseStep, U, L extends GLocation, D>
    extends BaseEditorProps<D>,
        RecursiveSectionProps<S, U, L> {}

function SectionComponent<
    F extends GForm<S, U>,
    S extends GBaseStep,
    U,
    L extends GLocation,
    D
>({
    containerRef,
    stepMenuComponent,
    stepMapper,
    multiSection,
    editId = false,
    stepMapperProps = {},
    calcCurrentRoute,
    setShowSettings,
    ...others
}: SectionComponentProps<S, U, L, D> & {
    containerRef: any;
    setShowSettings?: () => void;
}): JSX.Element {
    let history = useHistory();
    let match: match<idSectionRouteParam> = useRouteMatch();
    const project = useCurrentProject();
    const dispatch = useGenericEditorDispatch();
    const editorInfo = useGenericEditorSelector<
        GenericEditorState<F, S, U, L, D>
    >((state) => state);

    const [choosingNext, setChoosingNext] = useState(false);
    const [openId, setOpenId] = useState<string | undefined>(undefined);
    const [widthStats, setWidthStats] = useState<undefined | WidthStats>(
        undefined
    );
    const nextBtnRef = useRef<HTMLButtonElement>(null);

    /** Calcs the currentBreakPoint and if is mobile */
    const handleResize = (): void => {
        const currentWidth = containerRef?.current?.offsetWidth;
        if (currentWidth !== undefined) {
            let currentBreakPoint = editorInfo.size.blockNum;
            while (
                currentWidth <
                (editorInfo.size.blockSize + editorInfo.size.spacingSize) *
                    currentBreakPoint
            ) {
                currentBreakPoint--;
            }
            setWidthStats({
                isResponsive:
                    currentWidth <
                    (editorInfo.size.blockSize + editorInfo.size.spacingSize) *
                        editorInfo.size.blockNum,
                isMobile:
                    currentWidth <
                    (editorInfo.size.blockSize + editorInfo.size.spacingSize) *
                        editorInfo.size.blockNum +
                        40,
                currentBreakPoint,
            });
        }
    };

    useEffect(() => {
        /** On load add a resize listener to calc the currentBreakpoint */
        window.addEventListener('resize', handleResize);
        handleResize();
        return (): void => {
            window.removeEventListener('resize', handleResize);
        };
    }, [containerRef.current, containerRef.current?.offsetWidth]);

    useEffect(() => {
        if (match.params.idSection && multiSection) {
            dispatch(setCurrentSection(match.params.idSection));
        }
    }, []);

    if (editorInfo.loading) {
        return (
            <div className={editorStyles.whiteCurtain}>
                <Loader size={100} />
            </div>
        );
    }

    const section = editorInfo.sections[editorInfo.idSection ?? ''];
    if (!section) {
        return <div>Section not Found</div>;
    }

    const renderSettingsBtn = (): JSX.Element | void => {
        if (multiSection) {
            return (
                <Link to={match.url.substring(0, match.url.lastIndexOf('/'))}>
                    <div className={styles.overViewBtnContainer}>
                        <SettingsRoundedIcon fontSize="inherit" />
                    </div>
                </Link>
            );
        } else if (setShowSettings) {
            return (
                <div
                    className={styles.overViewBtnContainer}
                    onClick={(): void => {
                        setShowSettings?.();
                    }}
                >
                    <SettingsRoundedIcon fontSize="inherit" />
                </div>
            );
        }
    };

    const renderTitle = (): JSX.Element => {
        if (!calcCurrentRoute) {
            return <div></div>;
        }
        const route = calcCurrentRoute?.(
            project,
            editorInfo.data,
            section.name
        );

        return (
            <div className={styles.pageTitleCont}>
                <PageTitle
                    editable
                    title={multiSection ? section.name : editorInfo.name}
                    route={route}
                    handleUpdate={(value: string): void => {
                        if (multiSection) {
                            dispatch(
                                updateSection({
                                    ...section,
                                    name: value,
                                })
                            );
                        } else {
                            dispatch(updateName(value));
                        }
                    }}
                />
            </div>
        );
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const renderPrevBtn = (): JSX.Element | void => {
        if (multiSection) {
            const prevSection = Object.values(editorInfo.sections).find(
                (pSection) => pSection.nextSection === section.id
            );
            if (prevSection) {
                return (
                    <Link
                        to={
                            match.url.substring(0, match.url.lastIndexOf('/')) +
                            '/' +
                            prevSection.id
                        }
                        className={'app-link'}
                    >
                        <button className={styles.prevBtn}>
                            <NavigateBeforeRoundedIcon fontSize="inherit" />
                        </button>
                    </Link>
                );
            }
            return (
                <button className={styles.prevBtn} disabled>
                    <NavigateBeforeRoundedIcon fontSize="inherit" />
                </button>
            );
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const renderNextBtn = (): JSX.Element | void => {
        if (multiSection) {
            if (section.nextSection) {
                return (
                    <Link
                        to={
                            match.url.substring(0, match.url.lastIndexOf('/')) +
                            '/' +
                            section.nextSection
                        }
                        className={'app-link'}
                    >
                        <button className={styles.nextBtn}>
                            <NavigateNextRounded fontSize="inherit" />
                        </button>
                    </Link>
                );
            } else {
                return (
                    <button
                        className={styles.nextBtn}
                        onClick={(): void => {
                            setChoosingNext(true);
                        }}
                        ref={nextBtnRef}
                    >
                        <NavigateNextRounded fontSize="inherit" />
                    </button>
                );
            }
        }
    };

    const mapSteps = (): JSX.Element[] => {
        return section.steps.map((idStep: string, index) => {
            if (widthStats) {
                return (
                    <StepComponent<S, U, L>
                        {...others}
                        idStep={idStep}
                        openId={openId}
                        canMoveUp={index !== 0}
                        canMoveDown={index !== section.steps.length - 1}
                        onOpen={(): void => {
                            setOpenId(idStep);
                        }}
                        key={index}
                        location={{
                            type: LocationTypes.SECTION,
                            indexStep: index,
                            idRootStep: idStep,
                        }}
                        level={0}
                        size={editorInfo.size}
                        editId={editId}
                        stepMapper={stepMapper}
                        stepMenuComponent={stepMenuComponent}
                        uniqueSteps={editorInfo.uniqueSteps}
                        updateUniqueSteps={(uniqueSteps): void => {
                            dispatch(updateUniqueSteps(uniqueSteps));
                        }}
                        stepMapperProps={stepMapperProps ?? {}}
                        widthStats={widthStats}
                    />
                );
            } else {
                return <div key={index}></div>;
            }
        });
    };
    return (
        <div className={styles.container}>
            {renderSettingsBtn()}
            {/* {renderPrevBtn()}
            {renderNextBtn()} */}
            {choosingNext && (
                <NextMenu
                    anchorRef={nextBtnRef}
                    handleClose={(): void => {
                        setChoosingNext(false);
                    }}
                    handleCreateSection={(pSection: Section): void => {
                        dispatch(addSection(pSection));
                        history.push(
                            match.url.substring(0, match.url.lastIndexOf('/')) +
                                '/' +
                                pSection.id
                        );
                        setChoosingNext(false);
                    }}
                />
            )}
            {renderTitle()}
            <div className={styles.contentContainer}>
                <div className={styles.stepsCard}>
                    <div
                        className={styles.stepsLayout}
                        style={{
                            width:
                                (editorInfo.size.blockSize +
                                    editorInfo.size.spacingSize) *
                                editorInfo.size.blockNum,
                        }}
                    >
                        {mapSteps()}
                        <div className={styles.addBtnContainer}>
                            <AddStepBtn
                                location={{
                                    type: LocationTypes.SECTION,
                                    idRootStep: 'NULL',
                                    indexStep: null,
                                }}
                                size={'46px'}
                                stepMenuComponent={stepMenuComponent}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SectionComponent;
