import React from 'react';
import { InfoAudioStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import InformativeTypePicker from '../InformativeTypePicker/InformativeTypePicker';
import InformativeFileComponent from '../InformativeFilePicker/InformativeFilePicker';
interface InfoAudioStepProps {
    step: InfoAudioStep;
}

function InfoAudioStepComponent({ step }: InfoAudioStepProps): JSX.Element {
    return (
        <React.Fragment>
            <InformativeTypePicker step={step} />
            <InformativeFileComponent
                idStep={step.id}
                file={step.file}
                types={[
                    'audio/aac',
                    'audio/mp4',
                    'audio/mpeg',
                    'audio/amr',
                    'audio/ogg',
                ]}
                inputs={'audio/*'}
                maxSize={16}
                defaultFile={{
                    fileName: 'Capta.mp3',
                    url: '/Capta.mp3',
                }}
            />
        </React.Fragment>
    );
}

export default InfoAudioStepComponent;
