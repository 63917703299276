import { FormSize, GBaseStep } from '../../@Types/GenericForm';

export function calcFillerSize<StepType extends GBaseStep>(
    step: StepType,
    size: FormSize
): number {
    if (
        (step as any).maxSize &&
        (step as any).maxSize <= size.blockNum &&
        (step as any).size &&
        (step as any).size > 0
    ) {
        const stepSize = (step as any).maxSize - (step as any).size;
        return size.blockSize * stepSize + size.spacingSize * (stepSize - 1);
    } else {
        return 0;
    }
}

export function fromConstToValue(cons: 1 | 2 | 3 | 4): number {
    switch (cons) {
        case 4:
            return 100;
        case 2:
            return 50;
        case 1:
            return 0;
        default:
            return 0;
    }
}
export function fromValueToConst(value: number): 1 | 2 | 3 | 4 {
    switch (value) {
        case 100:
            return 4;
        case 50:
            return 2;
        case 0:
            return 1;
        default:
            return 1;
    }
}

export const calcStepWidth = (
    stepSize: 1 | 2 | 3 | 4,
    formSize: FormSize
): number => {
    return (
        formSize.blockSize * stepSize + formSize.spacingSize * (stepSize - 1)
    );
};
