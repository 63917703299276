import React, { useEffect, useState, useRef } from 'react';
import ListDetailLayout from '../../shared/ListDetailLayout/ListDetailLayout';
import PageTitle from '../../shared/PageTitle/PageTitle';
import List from '../../shared/List/List';
import EntryDetail from './EntryDetail/EntryDetail';
import EntryListItem from './EntryListItem/EntryListItem';
import CreateEntry from './CreateEntry/CreateEntry';
import Search from '../../shared/Search/Search';
import Dialog from '../../shared/Dialog/Dialog';
import { useSelector, useDispatch } from 'react-redux';
import {
    reset,
    selectElement,
    updateElement,
    search,
    setLoading,
} from '../../controllers/EntriesController/EntriesActions';
import { RootState } from '../../utils/_store';
import { Entry } from '../../@Types/Entry';
import * as EntriesService from '../../controllers/EntriesController/EntriesService';
import RoundedButton from '../../shared/RoundedButton/RoundedButton';
import ConfirmDialog from '../../shared/ConfirmDialog/ConfirmDialog';
import { RouteComponentProps, match, withRouter } from 'react-router-dom';

interface EntriesPageProps extends RouteComponentProps {
    match: match<{ apiKey?: string }>;
}

function EntriesPage({
    history,
    match: {
        params: { apiKey },
    },
}: EntriesPageProps): JSX.Element {
    const dispatch = useDispatch();
    const pageInfo = useSelector((state: RootState) => state.entriesPage);
    const siteInfo = useSelector((state: RootState) => state.site);
    const [createDialog, setCreateDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState<Entry | undefined>(
        undefined
    );
    const listRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        dispatch(
            reset({
                apiKey,
            })
        );
        if (listRef.current) listRef.current.scrollTop = 0;
    }, [siteInfo.idProject]);
    return (
        <React.Fragment>
            {createDialog && siteInfo?.idProject && (
                <Dialog
                    open={createDialog}
                    onClose={(): void => setCreateDialog(false)}
                >
                    <CreateEntry
                        idProject={siteInfo.idProject}
                        handleEntryCreated={(): void => {
                            // TODO: Cuando hayan filtros toca activar el de ordenar por fecha de creación para que el nuevo quede de primeras
                            //Es llamar un action que le ponga loading cambie filtros y vuelva a pedir todo.
                            dispatch(reset({}));
                            if (listRef.current) listRef.current.scrollTop = 0;
                        }}
                        onClose={(): void => {
                            setCreateDialog(false);
                        }}
                    />
                </Dialog>
            )}
            {deleteDialog !== undefined && (
                <ConfirmDialog
                    onConfirm={async (
                        element: Entry,
                        setLoading: Function
                    ): Promise<void> => {
                        try {
                            setLoading(true);
                            await EntriesService.deleteEntry(
                                siteInfo.idProject!,
                                element.apiKey
                            );
                            dispatch(reset({}));
                            setDeleteDialog(undefined);
                        } catch (error) {
                            //TODO: handle errors
                            console.error(error);
                            setLoading(false);
                        }
                    }}
                    element={deleteDialog}
                    title="Borrar Entrada"
                    btnMsg="Borrar"
                    deleting
                    msg="¿Está seguro de que desea borrar esta entrada?"
                    onClose={(): void => {
                        setDeleteDialog(undefined);
                    }}
                />
            )}
            <div className="standard-layout">
                <PageTitle
                    title={'Entradas'}
                    route={
                        siteInfo?.projects[siteInfo?.idProject ?? '']?.name +
                        ' / Entradas'
                    }
                />
                <div className="filters-container flexbox">
                    <div
                        className="new-container"
                        data-testid={'EntriesPage_newButton'}
                    >
                        <RoundedButton
                            text="Nueva"
                            backgroundColor={'var(--secondary)'}
                            color={'white'}
                            onClick={(): void => {
                                setCreateDialog(true);
                            }}
                        />
                    </div>
                    <div className="search-container">
                        <Search
                            search={pageInfo.search}
                            handleLoadingSearch={(loading: boolean): void => {
                                dispatch(setLoading(loading));
                            }}
                            handleSearch={(value: string): void => {
                                /** Refresh the list and send it back to the top */
                                if (listRef.current)
                                    listRef.current.scrollTop = 0;
                                dispatch(search(value));
                            }}
                        />
                    </div>
                </div>

                <ListDetailLayout
                    listWidth={450}
                    responsiveBreakpoint={1000}
                    selectElementLabel="Selecciona una Entrada"
                    loading={pageInfo.loading}
                    selectedElement={
                        pageInfo.selectedElement !== undefined
                            ? pageInfo.elements[pageInfo.selectedElement]
                            : undefined
                    }
                    handleSelectedElement={(element?: Entry): void => {
                        const index = pageInfo.elements.findIndex(
                            (listElement) =>
                                listElement.apiKey === element?.apiKey
                        );
                        history.push({
                            pathname: `/${siteInfo.idProject}/entries${
                                element ? '/' + element.apiKey : ''
                            }`,
                            state: {
                                prevPath: history.location.pathname,
                            },
                        });
                        dispatch(selectElement(index));
                    }}
                >
                    <List
                        elements={pageInfo.elements}
                        lastPage={true}
                        listRef={listRef}
                        onPageScroll={(): void => {}}
                        label="No hay entradas"
                    >
                        <EntryListItem />
                    </List>
                    <EntryDetail
                        handleClickDelete={setDeleteDialog}
                        handleUpdate={(
                            object: Entry,
                            field: keyof Entry,
                            value: string
                        ): void => {
                            dispatch(updateElement({ object, field, value }));
                        }}
                    />
                </ListDetailLayout>
            </div>
        </React.Fragment>
    );
}

export default withRouter(EntriesPage);
