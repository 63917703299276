import axiosInstance from '../../AxiosAPI';
import { Template } from '../../@Types/Template';
import { defaultOrderBy, templatesToQueryString } from './TemplatesReducer';
import { Payload } from '../../@Types/Payload';
import { RootApi } from '../../utils/_api';

const ApiWithTags = RootApi.enhanceEndpoints({ addTagTypes: ['Templates'] });

export const TemplatesApi = ApiWithTags.injectEndpoints({
    endpoints: (build) => ({
        loadTemplates: build.query<
            Template[],
            {
                idProject: string;
            }
        >({
            query: ({ idProject }) => {
                return {
                    url: `/projects/${idProject}/templates?pageSize=2000`,
                    method: 'get',
                };
            },
            providesTags: ['Templates'],
        }),
    }),
    overrideExisting: false,
});

export const { useLoadTemplatesQuery } = TemplatesApi;

export const loadTemplatesByQuery = async (
    idProject: string,
    queryString: string,
    idTemplate?: string
): Promise<{
    elements: Template[];
    selectedElement?: number;
    filters: {};
    orderBy: string | undefined;
    search: string | undefined;
}> => {
    const query = new URLSearchParams(queryString);
    if (!query.has('all')) {
        query.set('all', '' + false);
    }

    if (!query.has('search')) {
        query.set('defaultOrderBy', defaultOrderBy);
    }
    const response = await axiosInstance.get(
        `/projects/${idProject}/templates/load${
            idTemplate ? '/' + idTemplate : ''
        }` +
            '?' +
            query
    );

    //TODO talvez validar los filtros que llegan del back?

    const filters = response?.data?.filters;
    if (filters?.crtStartDate) {
        filters.crtStartDate = new Date(parseInt(filters.crtStartDate));
    }
    if (filters?.crtEndDate) {
        filters.crtEndDate = new Date(parseInt(filters.crtEndDate));
    }
    return {
        ...response.data,
        elements:
            response?.data?.elements?.map(
                (template: any): Template =>
                    ({
                        ...template,
                        creation_date: new Date(template.creation_date),
                    } as Template)
            ) ?? [],
        filters,
    };
};

/**
 * Function that loads the tickets' list
 * @param idProject the id of the current project
 * @param page currently viewing
 * @param pageSize to request
 * @param filters currently active
 * @param orderBy method ordered by, undefined if search is active
 * @param search search query, undefined if no search is active
 * @returns array of Tickets that match the inputs
 */
export const loadTemplates = async (
    idProject: string,
    page: number,
    pageSize: number,
    filters: Record<string, any>,
    orderBy: string,
    search: string | undefined
): Promise<Template[]> => {
    let url = `/projects/${idProject}/templates?page=${page}&pageSize=${pageSize}`;
    url += '&' + templatesToQueryString(filters, orderBy, search);
    const response = await axiosInstance.get(url);
    return response.data.map(
        (template: any): Template =>
            ({
                ...template,
                creation_date: new Date(template.creation_date),
            } as Template)
    );
};

export const loadTemplatesById = async (
    idProject: string,
    templatesId: string[]
): Promise<Template[]> => {
    if (templatesId.length === 0) return [];
    const response = await axiosInstance.get(
        `/projects/${idProject}/templates?id=${templatesId.join(',')}`
    );
    return response.data;
};

/**
 * Function called to handle the conexion with the server to create a template
 * @param idProject
 * @param templateInfo Record of values to create the template with
 * @param payload
 */
export const createTemplate = async (
    idProject: string,
    templateInfo: Record<string, unknown>,
    payload: Payload
): Promise<void> => {
    const template = { ...templateInfo, payload };
    await axiosInstance.post(`/projects/${idProject}/templates`, template);
};

/**
 * Function that handles the async update of an agents value
 * @param id of the template to update
 * @param idProject
 * @param template Record of values to update
 */
export const updateTemplate = async (
    id: string,
    idProject: string,
    template: Record<keyof Template, any>
): Promise<void> => {
    await axiosInstance.patch(
        `/projects/${idProject}/templates/` + id,
        template
    );
};

export const deleteTemplate = async (
    id: string,
    idProject: string
): Promise<void> => {
    await axiosInstance.delete(`/projects/${idProject}/templates/` + id);
};

export default {
    loadTemplates,
    loadTemplatesByQuery,
    loadTemplatesById,
    createTemplate,
    updateTemplate,
    deleteTemplate,
};
