import { useRef, useState } from 'react';
import { Ticket } from '../../../../../@Types/@Types';
import styles from './ResolutionDateSideBar.module.css';
import React from 'react';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import ResolutionDatePicker from './ResolutionDatePicker';
import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';
import Dialog from '../../../../../shared/Dialog/Dialog';
import DateChangeDialog from './DateChangeDialog/DateChangeDialog';
import { useTicketPermission } from '../../../../../utils/PermissionsFunctions';
import { ProjectPermissions } from '../../../../../constants/Permissions';
interface HeaderSideBarProps {
    /** The ticket currently being displayed */
    selectedElement: Ticket;
    /** If the layout is mobile */
    layoutIsMobile?: boolean;
}

function HeaderSideBar({ selectedElement }: HeaderSideBarProps): JSX.Element {
    const [confirmDate, setConfirmDate] = useState<Date | undefined>(undefined);
    const [hoveringDate, setHoveringDate] = useState(false);
    const dateRef = useRef<HTMLDivElement>(null);

    /** If the current user can change the date of the ticket */
    const canChangeDate = useTicketPermission(
        selectedElement,
        ProjectPermissions.CHANGE_RESOLUTION_DATE
    );
    return (
        <React.Fragment>
            <div
                className={styles.header + ' noselect'}
                data-testid="ResolutionDateSideBar_header"
                onMouseOver={(): void => {
                    if (canChangeDate) {
                        setHoveringDate(true);
                    }
                }}
                onMouseLeave={(): void => {
                    setHoveringDate(false);
                }}
                onClick={(): void => {
                    if (canChangeDate) {
                        dateRef.current?.click();
                    }
                }}
                style={canChangeDate ? { cursor: 'pointer' } : {}}
            >
                <label
                    className={styles.titleLbl}
                    style={{
                        color: hoveringDate
                            ? 'var(--contrast)'
                            : 'var(--accent)',
                        cursor: canChangeDate ? 'pointer' : 'default',
                    }}
                >
                    Vencimiento
                </label>
                {canChangeDate && (
                    <div
                        className={styles.clockContainer}
                        style={{
                            color: hoveringDate
                                ? 'var(--contrast)'
                                : 'var(--accent)',
                        }}
                    >
                        <AccessTimeRoundedIcon fontSize="inherit" />
                    </div>
                )}
            </div>
            <div>
                {confirmDate !== undefined && (
                    <Dialog
                        maxWidth="100%"
                        disableEnforceFocus
                        onClose={(): void => {
                            setConfirmDate(undefined);
                        }}
                    >
                        <DateChangeDialog
                            resolutionDate={confirmDate}
                            selectedElement={selectedElement}
                            onConfirm={(): void => {
                                setConfirmDate(undefined);
                            }}
                        />
                    </Dialog>
                )}
                <ResolutionDatePicker
                    label=""
                    inputRef={dateRef}
                    value={confirmDate ?? selectedElement.resolution_date}
                    onChange={(date: MaterialUiPickersDate): void => {
                        if (
                            date &&
                            date.getTime() !==
                                selectedElement.resolution_date.getTime()
                        ) {
                            setConfirmDate(date);
                        }
                    }}
                    cantEdit={!canChangeDate}
                    onClose={(): void => {}}
                    creation_date={selectedElement.creation_date}
                />
            </div>
        </React.Fragment>
    );
}
export default HeaderSideBar;
