import { EntityProperty } from '../../@Types/EntityTypes/EntityProperty';
import ClientInfoTypes, {
    ClientInfoTypeLabels,
} from '../../constants/ClientInfoTypes';
import EntityPropertyTypes from '../../constants/EntityPropertyTypes';
import StringValidationTypes, {
    StringValidationValues,
} from '../../constants/StringValidationTypes';
interface CustomProperty {
    id: string;
    type: ClientInfoTypes.NAME | ClientInfoTypes.IDENTIFIER_TYPE;
}
export const DefaultClientStepIds = [
    ClientInfoTypes.NAME,
    ClientInfoTypes.EMAIL,
    ClientInfoTypes.CEL,
    ClientInfoTypes.IDENTIFIER,
    ClientInfoTypes.IDENTIFIER_TYPE,
];
export const DefaultClientSteps: Record<
    string,
    EntityProperty | CustomProperty
> = {
    [ClientInfoTypes.NAME]: {
        type: ClientInfoTypes.NAME,
        id: ClientInfoTypes.NAME,
    },
    [ClientInfoTypes.EMAIL]: {
        id: ClientInfoTypes.EMAIL,
        type: EntityPropertyTypes.TEXTINPUT,
        description: '',
        label: ClientInfoTypeLabels[ClientInfoTypes.EMAIL],
        editable: true,
        required: false,
        size: 2,
        validation: StringValidationValues[StringValidationTypes.EMAIL],
    },
    [ClientInfoTypes.CEL]: {
        id: ClientInfoTypes.CEL,
        type: EntityPropertyTypes.TEXTINPUT,
        description: '',
        label: ClientInfoTypeLabels[ClientInfoTypes.CEL],
        editable: true,
        required: false,
        size: 2,
        validation: StringValidationValues[StringValidationTypes.PHONE],
    },
    [ClientInfoTypes.IDENTIFIER]: {
        id: ClientInfoTypes.IDENTIFIER,
        type: EntityPropertyTypes.TEXTINPUT,
        description: ' ',
        label: ClientInfoTypeLabels[ClientInfoTypes.IDENTIFIER],
        editable: true,
        required: false,
        size: 2,
    },
    [ClientInfoTypes.IDENTIFIER_TYPE]: {
        id: ClientInfoTypes.IDENTIFIER_TYPE,
        type: ClientInfoTypes.IDENTIFIER_TYPE,
    },
};
