import Dialog from '../../../../../../../shared/Dialog/Dialog';
import styles from './TransferDialog.module.css';
import RoundedButton from '../../../../../../../shared/RoundedButton/RoundedButton';
import { useState } from 'react';
import React from 'react';

interface TransferProps {
    /** Element being transferd */
    element: any;
    /** Function that handles the closing event */
    onClose: () => void;
    /** Function called when user decides to transfer */
    onTransfer: Function;
    /** Name of the element to Transfer */
    elementName: string;
    /** Title of the dialog */
    title: string;
}

/**
 * Component that displays the confirm deletion can also be used to cancel an action.
 */
function Transfer({
    element,
    onClose,
    onTransfer,
    elementName,
    title,
}: TransferProps): JSX.Element {
    return (
        <Dialog open={element !== null} onClose={onClose}>
            <InnerTransferDialog
                element={element}
                onClose={onClose}
                onTransfer={onTransfer}
                title={title}
                elementName={elementName}
            ></InnerTransferDialog>
        </Dialog>
    );
}
export default Transfer;

interface InnerDialogProps {
    /** Element being transfered */
    element: any;
    /** Function that handles the closing event */
    onClose: () => void;
    /** Function called when user decides to transfer */
    onTransfer: Function;
    /** Title of the dialog */
    title: string;
    /** Function that toggles the loader in the modal, inherited from the modal. */
    setLoading?: Function;
    /** if loader should be active */
    loading?: boolean;
    /** Name of the element to transfer */
    elementName: string;
}

function InnerTransferDialog({
    element,
    onClose,
    onTransfer,
    title,
    setLoading,
    loading,
    elementName,
}: InnerDialogProps): JSX.Element {
    const [removeAll, setRemoveAll] = useState<boolean | undefined>(undefined);

    const renderMsg = (): JSX.Element => {
        if (removeAll === undefined) {
            return (
                <React.Fragment>
                    <div className={styles.message}>
                        ¿Desea remover a todos los agentes asignados al caso?
                    </div>
                    <div className={styles.btnContainer}>
                        <div className={styles.btn}>
                            <RoundedButton
                                fontSize={13}
                                padding={'0 10px 0 10px'}
                                text="Cancelar"
                                backgroundColor="var(--greyBtn)"
                                onClick={onClose}
                            />
                        </div>
                        <div className={styles.btn}>
                            <RoundedButton
                                fontSize={13}
                                padding={'0 10px 0 10px'}
                                text={'Remover a todos'}
                                backgroundColor="var(--error)"
                                color="white"
                                onClick={(): void => {
                                    setRemoveAll(true);
                                }}
                                disabled={loading}
                            />
                        </div>
                        <div className={styles.btn}>
                            <RoundedButton
                                fontSize={13}
                                padding={'0 10px 0 10px'}
                                text={'Removerme solo a mi'}
                                backgroundColor="var(--secondary)"
                                color="white"
                                onClick={(): void => {
                                    setRemoveAll(false);
                                }}
                                disabled={loading}
                            />
                        </div>
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div className={styles.message}>
                        ¿Está seguro que desea transferirle este caso a
                        <span className={styles.nameSpan}>{elementName}</span>
                        {removeAll ? ' removiendo a todos los agentes' : ''}?
                    </div>
                    <div className={styles.btnContainer + ' center-anything'}>
                        <div className="button">
                            <RoundedButton
                                text="Cancelar"
                                backgroundColor="var(--greyBtn)"
                                onClick={(): void => {
                                    setRemoveAll(undefined);
                                }}
                            />
                        </div>
                        <div className="button">
                            <RoundedButton
                                text={'Transferir'}
                                backgroundColor="var(--error)"
                                color="white"
                                onClick={(): void => {
                                    onTransfer(
                                        { element, removeAll },
                                        setLoading
                                    );
                                }}
                                disabled={loading}
                            />
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    };

    return (
        <div className={styles.container + ' standard-dialog'}>
            <h3 className="center-anything">{title}</h3>
            {renderMsg()}
        </div>
    );
}
