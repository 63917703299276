import styles from './EntityValueComponent.module.css';
import Avatar from '../../../../../shared/Avatar/Avatar';
import { EntityValue } from '../../../../../@Types/EntityValue';
import React, { useState } from 'react';
import { Entity } from '../../../../../@Types/@Types';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import Dialog from '../../../../../shared/Dialog/Dialog';
import RelationshipDialog from '../../../../../shared/RelationshipDialog/RelationshipDialog';
import produce from 'immer';
import {
    goTo,
    refreshCurrent,
} from '../../../../../controllers/EntityValuesController/EntityValuesActions';
import { updateEntityValue } from '../../../../../controllers/EntityValuesController/EntityValuesService';
import { useDispatch } from 'react-redux';
import EntityIcon from '../../../../../shared/SmartIcons/EntityIcon';
import RoundedButton from '../../../../../shared/RoundedButton/RoundedButton';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
interface EntityValueProps extends RouteComponentProps {
    /** The entityValue to display */
    entityValue: EntityValue;
    /** the entity of the entityValue to display */
    entity: Entity;
    /** The entity of the current EntityValue */
    mainEntityValue: EntityValue;
    /** the idEntity of the current entityValue */
    idEntity: string;
    refresh: () => void;
    handleUpdate: Function;
    handleDelete?: Function;
    filters?: string;
}
function EntityValueComponent({
    entity,
    history,
    refresh,
    idEntity,
    entityValue,
    mainEntityValue,
    filters = '',
}: EntityValueProps): JSX.Element {
    const relationship = entityValue.relationships[idEntity]?.find(
        (relationShip) => relationShip.idEntityValue === mainEntityValue._id
    );
    const dispatch = useDispatch();
    const [showConfig, setShowConfig] = useState(false);

    if (!relationship) return <div></div>;

    return (
        <React.Fragment>
            <Dialog
                open={showConfig}
                maxWidth={'90vw'}
                onClose={(): void => {
                    setShowConfig(false);
                }}
            >
                <RelationshipDialog
                    relationship={relationship}
                    entityValue={entityValue}
                    idEntity={entityValue.idEntity}
                    customSubmit={async (values): Promise<void> => {
                        const relationships = produce(
                            entityValue.relationships,
                            (entityRelationships) => {
                                const relationships =
                                    entityRelationships[
                                        mainEntityValue.idEntity
                                    ] ?? [];
                                for (const relationship of relationships) {
                                    if (
                                        relationship.idEntity ===
                                            mainEntityValue.idEntity &&
                                        relationship.idEntityValue ===
                                            mainEntityValue._id
                                    ) {
                                        relationship.values = values;
                                    }
                                }
                            }
                        );
                        await updateEntityValue(entity._id, entityValue._id, {
                            relationships,
                        });
                        dispatch(refreshCurrent());
                        refresh();
                        setShowConfig(false);
                    }}
                    relatedEntityValue={mainEntityValue}
                />
            </Dialog>
            <div className={styles.container}>
                <div className={styles.cardContainer}>
                    {Object.keys(relationship.values).length > 0 && (
                        <div
                            className={styles.removeBtn}
                            onClick={(): void => {
                                setShowConfig(true);
                            }}
                        >
                            <SettingsRoundedIcon fontSize="inherit" />
                        </div>
                    )}
                    <div className={styles.sideBar}></div>
                    <div className={styles.info}>
                        <h4 className={styles.title}>
                            {entityValue.values[entity.idLabelStep] ??
                                entity.name}
                        </h4>
                        <div className={styles.viewMoreBtn}>
                            <Link
                                to={`/entities/${entityValue.idEntity}/${entityValue._id}${filters}`}
                                className="app-link"
                            >
                                <RoundedButton
                                    text={'Ver más'}
                                    backgroundColor={'var(--secondary)'}
                                    color={'white'}
                                    onClick={(): void => {
                                        history.push(
                                            `/entities/${entityValue.idEntity}/${entityValue._id}${filters}`
                                        );
                                        dispatch(
                                            goTo({
                                                idEntityValue: entityValue._id,
                                                idEntity: entityValue.idEntity,
                                                history,
                                                queryString: filters,
                                            })
                                        );
                                    }}
                                    padding="0px 10px"
                                />
                            </Link>
                        </div>
                    </div>
                    <div className="flexbox">
                        <div className={styles.photo}>
                            <Avatar
                                lbl={
                                    entityValue.values[entity.idLabelStep] ??
                                    '?'
                                }
                                size={50}
                                fontSize={30}
                            >
                                <EntityIcon
                                    idEntity={entity._id}
                                    fill="var(--primary1)"
                                    style={{
                                        height: 35,
                                        width: 35,
                                    }}
                                />
                            </Avatar>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter((props: any) => <EntityValueComponent {...props} />);
