import { FilterList } from '@material-ui/icons';
import CommentIcon from '../../../../Icons/CommentIcon';
import ChatIcon from '../../../../Icons/ChatIcon';
import TicketIcon from '../../../../Icons/TicketIcon';
import TransferIcon from '../../../../Icons/TransferIcon';
import AssignActivityIcon from '../../../../Icons/AssignActivityIcon';
import React, { JSX } from 'react';
import { Menu } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import styles from './NotificationFilterOption.module.css';
import { NotificationFilterTypes } from '../../../../controllers/NotificationsMenuController/NotificationsMenuReducer';
import GroupedEmailIcon from '../../../../Icons/GroupedEmailIcon';
import PendingEmailIcon from '../../../../Icons/PendingEmailIcon';
import ActionTypes from '../../../../constants/ActionTypes';

export interface NotificationFilterOptionProps {
    filterState: NotificationFilterTypes;
    setFilterState: (value: NotificationFilterTypes) => void;
    handleClose: () => void;
    filterRef: React.RefObject<HTMLDivElement>;
}

export function NotificationFilterOption({
    filterState,
    setFilterState,
    handleClose,
    filterRef,
}: NotificationFilterOptionProps): JSX.Element {
    return (
        <Menu
            id="simple-menu"
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            style={{
                marginTop: '10px',
            }}
            keepMounted
            open={true}
            onClose={handleClose}
            anchorEl={filterRef.current}
        >
            {[NotificationFilterTypes.ALL, NotificationFilterTypes.UNSEEN].map(
                (filter) => (
                    <MenuItem
                        onClick={(): void => {
                            setFilterState(filter);
                            handleClose();
                        }}
                        key={filter}
                    >
                        {renderFilterIcon(filter, true)}
                        <div
                            className={
                                filterState === filter
                                    ? styles.selectFilterOption
                                    : ''
                            }
                            style={{ marginLeft: '8px' }}
                        >
                            {filterOptions[filter]}
                        </div>
                    </MenuItem>
                )
            )}
            <div
                style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    justifyContent: 'center',
                    display: 'flex',
                    paddingRight: '16px',
                    paddingLeft: '16px',
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: '2px',
                        backgroundColor: 'var(--secondary)',
                    }}
                ></div>
            </div>
            {[
                NotificationFilterTypes.COMMENT,
                NotificationFilterTypes.CLIENT_REPLY,
                NotificationFilterTypes.ASSIGN,
                NotificationFilterTypes.TRANSFER,
                NotificationFilterTypes.ACTIVITY,
            ].map((filter) => (
                <MenuItem
                    onClick={(): void => {
                        setFilterState(filter);
                        handleClose();
                    }}
                    key={filter}
                >
                    {renderNotificationIcon(filter, 24)}
                    <div
                        className={
                            filterState === filter
                                ? styles.selectFilterOption
                                : ''
                        }
                        style={{ marginLeft: '8px' }}
                    >
                        {filterOptions[filter]}
                    </div>
                </MenuItem>
            ))}
        </Menu>
    );
}

const filterOptions: Record<NotificationFilterTypes, string> = {
    ALL: 'Todas',
    UNSEEN: 'Pendientes',
    COMMENT: 'Comentario de un agente',
    CLIENT_REPLY: 'Respuesta de un cliente',
    ASSIGN: 'Caso asignado',
    TRANSFER: 'Caso transferido',
    ACTIVITY: 'Actividad pendiente',
};

export const renderFilterIcon = (
    filterState: NotificationFilterTypes,
    menuOption: boolean,
    size?: number
): JSX.Element => {
    switch (filterState) {
        case NotificationFilterTypes.ALL:
            if (menuOption) {
                return (
                    <GroupedEmailIcon
                        style={{
                            width: size ?? 24,
                            height: size ?? 24,
                            fill: 'var(--secondary)',
                        }}
                    />
                ); //TODO
            }
            return (
                <FilterList
                    style={{
                        width: size ?? 24,
                        height: size ?? 24,
                        color: 'var(--secondary)',
                    }}
                />
            );
        case NotificationFilterTypes.UNSEEN:
            return (
                <PendingEmailIcon
                    fill={'var(--secondary)'}
                    style={{
                        width: size ?? 24,
                        height: size ?? 24,
                    }}
                />
            );
        default:
            return renderNotificationIcon(filterState, size);
    }
};

export function renderNotificationIcon(
    filterState: NotificationFilterTypes | ActionTypes,
    size?: number
): JSX.Element {
    switch (filterState) {
        case ActionTypes.COMMENT:
        case NotificationFilterTypes.COMMENT:
            return (
                <CommentIcon
                    fill={'var(--secondary)'}
                    style={{
                        width: size ?? 24,
                        height: size ?? 24,
                    }}
                />
            );
        case ActionTypes.CLIENT_REPLY:
        case NotificationFilterTypes.CLIENT_REPLY:
            return (
                <ChatIcon
                    fill={'var(--secondary)'}
                    style={{
                        width: size ?? 24,
                        height: size ?? 24,
                    }}
                />
            );
        case ActionTypes.ASSIGN_AGENTS:
        case ActionTypes.AUTO_ASSIGN_AGENTS:
        case NotificationFilterTypes.ASSIGN:
            return TicketIcon({
                fill: 'var(--secondary)',
                style: {
                    width: size ?? 24,
                    height: size ?? 24,
                },
            });
        case ActionTypes.TRANSFER_AGENT:
        case NotificationFilterTypes.TRANSFER:
            return TransferIcon({
                fill: 'var(--secondary)',
                style: {
                    width: size ?? 24,
                    height: size ?? 24,
                },
            });
        case ActionTypes.ACTIVITY:
        case NotificationFilterTypes.ACTIVITY:
            return AssignActivityIcon({
                fill: 'var(--secondary)',
                style: {
                    width: size ?? 24,
                    height: size ?? 24,
                },
            });
        default:
            return <div></div>;
    }
}
