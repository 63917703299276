import { NodeTypesType } from 'react-flow-renderer';
import EntryNode from './EntryNode/EntryNode';
import ExitNode from './ExitNode/ExitNode';
import TransformationNode from './TransformationNode/TransformationNode';
import DecisionNode from './DecisionNode/DecisionNode';
import AssignNode from './AssignNode/AssignNode';
import AreaNode from './AreaNode/AreaNode';
import AgentNode from './AgentNode/AgentNode';
import AINode from './AINode/AINode';
import CommunicationNode from './CommunicationNode/CommunicationNode';
import FlowNodeTypes from '../../../constants/Flows/FlowNodeTypes';

const nodeTypes: NodeTypesType = {
    [FlowNodeTypes.ENTRY]: EntryNode,
    [FlowNodeTypes.EXIT]: ExitNode,
    [FlowNodeTypes.TRANSFORMATION]: TransformationNode,
    [FlowNodeTypes.DECISION]: DecisionNode,
    [FlowNodeTypes.ASSIGN]: AssignNode,
    [FlowNodeTypes.AREA]: AreaNode,
    [FlowNodeTypes.AGENT]: AgentNode,
    [FlowNodeTypes.AI]: AINode,
    [FlowNodeTypes.COMMUNICATION]: CommunicationNode,
};
export default nodeTypes;
