import styles from './ClassifierPill.module.css';
import { useAppSelector } from '../../hooks';
interface ClassifierPillProps {
    /** The root of the classifier to display */
    idRoot: string;
    /** If the classifier should show the tooltip on hover */
    showToolTip?: boolean;
    /** Function to call when classifier is clicked on */
    handleClick?: Function;
    /** The type of cursor to show */
    cursor?: string;
}
function ClassifierPill({
    idRoot,
    showToolTip = true,
    handleClick,
    cursor,
}: ClassifierPillProps): JSX.Element {
    const root = useAppSelector((state) => state.site.classifiers[idRoot]);

    if (!root) return <></>;

    const cursr = cursor ? cursor : handleClick ? 'pointer' : 'default';
    return (
        <div
            className={styles.container}
            style={{
                cursor: cursr,
            }}
            onClick={(): void => handleClick?.()}
        >
            <div
                className={styles.pill}
                style={{
                    backgroundColor: root.color?.background,
                    color: root.color?.font,
                    cursor: cursr,
                }}
                title={showToolTip ? root.name : ''}
            >
                <div className={styles.pillLbl}>{root.name}</div>
            </div>
        </div>
    );
}
export default ClassifierPill;
