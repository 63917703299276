import axiosInstance from '../../AxiosAPI';
import { Process } from '../../@Types/ProcessTypes/Process';

/**
 * Function that loads the processes' list
 * @param page currently viewing
 * @param pageSize to request
 * @param filters currently active
 * @param orderBy method ordered by, undefined if search is active
 * @param search search query, undefined if no search is active
 * @returns array of Processes that match the inputs
 */
export const loadProcesses = async (
    idProject: string,
    page: number,
    pageSize: number,
    _filters: [],
    orderBy: string | undefined,
    search: string | undefined
): Promise<Process[]> => {
    let url = `/projects/${idProject}/processes?page=${page}&pageSize=${pageSize}`;
    if (search !== undefined) {
        url += `&search=${encodeURIComponent(search)}`;
    } else {
        url += `&orderBy=${orderBy}`;
    }
    const resp = await axiosInstance.get(url);
    return resp.data;
};

const ProcessCache: Record<string, Promise<Process> | Process> = {};

export const loadProcessById = async (
    idProject: string,
    idProcess: string
): Promise<Process> => {
    if (ProcessCache[idProcess]) return await ProcessCache[idProcess];
    ProcessCache[idProcess] = new Promise(async (resolve, reject) => {
        try {
            const response = await axiosInstance.get(
                `/projects/${idProject}/processes/${idProcess}`
            );
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
    return ProcessCache[idProcess];
};

/**
 * Function that loads the processes by a given list of ids
 * @param processes the list of ids
 */
export const loadProcessesByIds = async (
    idProject: string,
    processes: string[]
): Promise<Process[]> => {
    return await Promise.all(
        processes.map((idProcess) => loadProcessById(idProject, idProcess))
    );
};

export default {
    loadProcessById,
    loadProcessesByIds,
};
