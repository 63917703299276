import { ContentBlock, EditorState } from 'draft-js';
import {} from '../../controllers/PayloadEditorController/PayloadEditorSlice';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import getImageComponent from './Images/ImageComponent';
import styles from './TextEditor.module.css';
import { Editor } from 'react-draft-wysiwyg';
import FullscreenRoundedIcon from '@material-ui/icons/FullscreenRounded';
import Dialog from '../Dialog/Dialog';
import {
    EurekaDecorator,
    DecoratorComponentProps,
    EurekaDecoratorsStrategy,
} from './EurekaDecorators/EurekaDecorator';
import { CircularProgress, ClickAwayListener } from '@material-ui/core';
import RoundedButton from '../RoundedButton/RoundedButton';
import { TextEditorTypes } from '../../constants/TextEditorTypes';

export interface TextEditorProps {
    /** The type of text being edited */
    type?: TextEditorTypes;
    /** If the TextEditor is Nested (to hide deeply nested parameter lists) */
    nested?: boolean;
    /** Placeholder of the text editor*/
    placeholder?: string;
    /** Displays loader in center */
    loader?: boolean;
    disabled?: boolean;
    editable?: boolean;
    alwaysScroll?: boolean;
    /** If the editor allows multiple lines */
    multiline?: boolean;
    /** If editor should be automatically focused on, the number is the delay */
    autoFocus?: boolean | number;
    /** If the editor must have text */
    required?: boolean;
    /** The current editorState to display */
    editorState?: EditorState;
    /** Function to call on editorState change */
    onChange?: (editorState: EditorState) => void;
    /** The max char limit to display an error */
    maxLength?: number;
    /** Function called to calc a templates value */
    customButtons?: () => JSX.Element[];
    allowFullScreen?: boolean;
    /** Returns if should close the fullscreen */
    onSubmit?: (callback: () => void) => Promise<boolean | void>;
    shouldUnfocus?: (e: React.MouseEvent<Document>) => boolean;
    /** The min height the editor should have */
    minHeight?: string | number;
    /** The max height the editor should have */
    maxHeight?: string | number;
    zIndex?: number;
    customDecorator?: (props: DecoratorComponentProps) => JSX.Element;
    /** If the editor has an error */
    error?: boolean;
    /** The edito's text alignment */
    textAlignment?: 'left' | 'center' | 'right';
    /** Custom Text style to render */
    style?: React.CSSProperties;
}

export const TextEditorTypeContext = React.createContext<TextEditorTypes>(
    TextEditorTypes.EUREKA
);

function TextEditorComponent({
    style,
    loader,
    disabled,
    minHeight,
    maxHeight,
    onSubmit,
    onChange,
    placeholder,
    editorState,
    required,
    maxLength,
    alwaysScroll,
    shouldUnfocus,
    editable = true,
    autoFocus = false,
    customButtons,
    zIndex = 3,
    customDecorator,
    multiline = true,
    error = false,
    textAlignment,
}: Omit<TextEditorProps, 'type'>): JSX.Element {
    const type = useContext(TextEditorTypeContext);
    const [firstTime, setFirstTime] = useState(true);
    const [loading, setLoading] = useState(false);
    const [focus, setFocus] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const editorRef = useRef<any>(null);
    // TODO: Bug de que el primer medio segundo no puedo escribir porque no esta focuseado.
    useEffect(() => {
        if (autoFocus) {
            if (firstTime && autoFocus !== true) {
                setTimeout(() => {
                    editorRef?.current?.focusEditor?.();
                }, autoFocus);
            } else if (!firstTime) editorRef?.current?.focusEditor?.();
        } else if (focus) setFocus(false);
        if (firstTime) setFirstTime(false);
    }, [placeholder]);

    let editorClassName = styles.editor + ' Erk-TextEditor';
    if (!editorState?.getCurrentContent().hasText()) {
        if (
            editorState?.getCurrentContent().getBlockMap().first().getType() !==
            'unstyled'
        ) {
            editorClassName += ' RichEditor-hidePlaceholder';
        }
    }

    const length =
        editorState?.getCurrentContent()?.getPlainText('')?.length ?? 0;

    const hasError = error || (isDirty && required && length === 0);

    const customStyle = useMemo(
        () => ({
            ...style,
            overflowY: alwaysScroll ? 'scroll' : ('auto' as any),
            minHeight,
            maxHeight,
        }),
        [style, minHeight, maxHeight, alwaysScroll]
    );

    const toolbar = useMemo(() => {
        switch (type) {
            case TextEditorTypes.EUREKA:
                return {
                    options: !multiline
                        ? ['inline', 'history']
                        : ['inline', 'list', 'history'],
                    inline: {
                        options: [
                            'bold',
                            'italic',
                            'underline',
                            'strikethrough',
                        ],
                    },
                    list: {
                        options: ['unordered'],
                    },
                };
            case TextEditorTypes.WHATSAPP:
                return {
                    options: ['inline', 'history'],
                    inline: {
                        options: ['bold', 'italic', 'strikethrough'],
                    },
                };
            case TextEditorTypes.WHATSAPP:
                return {
                    options: ['inline', 'history'],
                    inline: {
                        options: ['bold', 'italic', 'strikethrough'],
                    },
                };
            case TextEditorTypes.INPUT:
            case TextEditorTypes.ENTITYPICKER:
                return {
                    options: [],
                };
        }
    }, [type]);

    const onFocus = useCallback(() => {
        setFocus(true);
    }, []);

    const customBtns = useMemo(() => {
        return customButtons?.();
    }, [customButtons]);

    const decorators = useMemo(
        () => [
            {
                strategy: EurekaDecoratorsStrategy,
                component: customDecorator ?? EurekaDecorator,
            },
        ],
        []
    );

    const handleChange = useCallback(
        (editorState: EditorState) => {
            if (multiline) onChange?.(editorState);
            else if (
                editorState.getCurrentContent().getBlocksAsArray().length <= 1
            ) {
                onChange?.(editorState);
            }
        },
        [onChange, multiline]
    );

    const classes = useMemo(() => {
        let classes = ' Erk-TextEditorContainer';
        if (type === TextEditorTypes.EUREKA && !multiline)
            classes += ' Erk-TextEditor-BottomPadding';
        else if (type === TextEditorTypes.INPUT)
            classes += ' Erk-TextEditor-Input';
        else if (type === TextEditorTypes.ENTITYPICKER)
            classes += ' Erk-TextEditor-Picker';
        return classes;
    }, [editorState]);

    return (
        <div
            className={styles.container}
            style={{
                height: required || maxLength ? 'calc(100% - 20px)' : '100%',
            }}
        >
            <ClickAwayListener
                mouseEvent="onMouseDown"
                onClickAway={(e: any): void => {
                    if (!shouldUnfocus || shouldUnfocus(e)) {
                        setFocus(false);
                        if (!isDirty && focus) setIsDirty(true);
                    }
                }}
            >
                <div
                    className={styles.textContainer + classes}
                    style={{
                        borderColor: focus
                            ? 'var(--secondary)'
                            : hasError
                            ? 'var(--error)'
                            : undefined,
                        borderRadius:
                            type === TextEditorTypes.INPUT ||
                            type === TextEditorTypes.ENTITYPICKER
                                ? 10
                                : 15,
                        boxShadow: focus
                            ? '0 0 0 1px var(--secondary)'
                            : hasError
                            ? '0 0 0 1px var(--error)'
                            : undefined,
                    }}
                >
                    {(disabled || loading) && (
                        <div
                            className={styles.disabledCurtain}
                            style={{
                                zIndex: zIndex + 1,
                            }}
                        ></div>
                    )}
                    {loader && (
                        <div
                            style={{
                                position: 'absolute',
                                left: 'calc(50% - 25px)',
                                top: 'calc(50% + 0px)',
                            }}
                        >
                            <CircularProgress
                                size={40}
                                style={{
                                    color: 'var(--secondary)',
                                }}
                            />
                        </div>
                    )}
                    <Editor
                        ref={editorRef}
                        readOnly={!editable || disabled}
                        editorState={editorState}
                        onFocus={onFocus}
                        onEditorStateChange={handleChange}
                        toolbarClassName={styles.toolbar}
                        customBlockRenderFunc={customBlockRender}
                        editorClassName={editorClassName}
                        wrapperClassName={styles.wrapper}
                        stripPastedStyles
                        customStyleMap={customStyleMap}
                        editorStyle={customStyle}
                        placeholder={placeholder}
                        toolbar={toolbar}
                        spellCheck
                        textAlignment={textAlignment}
                        toolbarCustomButtons={customBtns}
                        customDecorators={decorators}
                    />
                    {(type === TextEditorTypes.INPUT ||
                        type === TextEditorTypes.ENTITYPICKER) &&
                        customBtns}
                </div>
            </ClickAwayListener>
            {isDirty && required && length === 0 && (
                <div className={styles.errorMsg}>El mensaje es obligatorio</div>
            )}
            {maxLength && maxLength > 0 && (
                <div
                    className={styles.charLimitContainer}
                    style={{
                        right: focus ? '7px' : '8px',
                        color: length > maxLength ? 'var(--error)' : '#989898',
                    }}
                >
                    {length}/{maxLength}
                </div>
            )}
            {onSubmit && (
                <div
                    style={{
                        position: 'absolute',
                        overflow: 'hidden',
                        maxHeight: '100%',
                        right: '15px',
                        bottom: '10px',
                        zIndex: zIndex + 1,
                    }}
                    data-testid="text_editor_submit"
                >
                    <RoundedButton
                        text={'Enviar'}
                        backgroundColor={'var(--secondary)'}
                        color="white"
                        disabled={loading || hasError || disabled || !editable}
                        bold={true}
                        onClick={async (): Promise<void> => {
                            setLoading(true);
                            await onSubmit(() => {});
                            setLoading(false);
                        }}
                    />
                </div>
            )}
        </div>
    );
}

function TextEditor({
    onSubmit,
    zIndex = 1301,
    type = TextEditorTypes.EUREKA,
    ...props
}: TextEditorProps): JSX.Element {
    const [fullScreen, setShowFullScreen] = useState(false);
    const handleSubmit = useCallback(async () => {
        if (onSubmit)
            return await onSubmit((): void => {
                if (fullScreen) setShowFullScreen(false);
            });
    }, [fullScreen, onSubmit]);

    const customButtons = useMemo(
        () => (): JSX.Element[] => {
            const buttons = props.customButtons?.() ?? [];
            if (props.allowFullScreen)
                buttons.push(
                    <FullScreenButton
                        onClick={(): void => setShowFullScreen(true)}
                    />
                );
            return buttons;
        },
        [props.allowFullScreen, props.customButtons, fullScreen]
    );

    return (
        <TextEditorTypeContext.Provider value={type}>
            {fullScreen && (
                <Dialog
                    disablePortal
                    maxWidth="100%"
                    open={fullScreen}
                    disableEnforceFocus
                    border={20}
                    onClose={(e): void => {
                        e?.stopPropagation();
                        setShowFullScreen(false);
                    }}
                >
                    <div className={styles.fullScreenTextField}>
                        <TextEditorComponent
                            {...props}
                            zIndex={zIndex}
                            onSubmit={onSubmit ? handleSubmit : undefined}
                        />
                    </div>
                </Dialog>
            )}
            {!fullScreen && (
                <TextEditorComponent
                    {...props}
                    zIndex={zIndex}
                    onChange={props.onChange}
                    onSubmit={onSubmit ? handleSubmit : undefined}
                    editorState={props.editorState}
                    customButtons={customButtons}
                />
            )}
        </TextEditorTypeContext.Provider>
    );
}

function FullScreenButton({ onClick }: { onClick: () => void }): JSX.Element {
    return (
        <button className={styles.fullscreenBtn} onClick={onClick}>
            <FullscreenRoundedIcon fontSize="inherit" />
        </button>
    );
}

export default TextEditor;

const customBlockRender = (block: ContentBlock, config?: any): any => {
    const type = block.getType();
    if (type === 'atomic' || type === 'preview-atomic') {
        const contentState = config.getEditorState().getCurrentContent();
        const entityKey = block.getEntityAt(0);
        if (entityKey) {
            const entity = contentState.getEntity(entityKey);
            if (entity && entity.type === 'IMAGE') {
                return {
                    component: getImageComponent(
                        config,
                        type === 'preview-atomic'
                    ),
                    editable: false,
                };
            }
        }
    }
    return undefined;
};

const customStyleMap = {
    PREVIEW: {
        opacity: 0.6,
    },
    NESTED: {
        backgroundColor: 'var(--secondary)',
        color: 'var(--primary1)',
        padding: '0px 3px',
        borderRadius: 5,
    },
};
