const POSTVENTAFORM = {
    name: 'Formulario PosVenta',
    firstSection: 'NaOGtu_k5YqS0gtxOeBA0',
    steps: {
        CBR_INMUEBLE: {
            size: 2,
            description: null,
            id: 'CBR_INMUEBLE',
            label: 'Inmueble',
            type: 'CBR_INMUEBLE',
            showIcon: true,
            required: true,
            searchable: true,
            dependencies: ['CBR_PROYECTO'],
        },
        CBR_PROYECTO: {
            size: 2,
            description: null,
            maxSize: 2,
            id: 'CBR_PROYECTO',
            label: 'Proyecto',
            type: 'CBR_PROYECTO',
            steps: [],
            showIcon: true,
            required: true,
            searchable: true,
        },
        'TITLE-4h_scIgNQOZWzJMtobPqx': {
            description: 'Información de Contacto',
            id: 'TITLE-4h_scIgNQOZWzJMtobPqx',
            type: 'TITLE',
            title: null,
        },
        CBR_MIDDLE_NAME: {
            description: null,
            id: 'CBR_MIDDLE_NAME',
            label: 'Segundo Nombre',
            type: 'CBR_MIDDLE_NAME',
            size: 1,
            required: false,
        },
        CBR_INCIDENCIAS: {
            elementStep: {
                size: 1,
                description: null,
                label: 'Elemento',
                subStep: {
                    description: null,
                    label: 'Elemento Hijo',
                    size: 1,
                    showIcon: true,
                    required: true,
                    searchable: true,
                },
                showIcon: true,
                required: true,
                searchable: true,
            },
            unitLabel: 'Incidencia',
            spaceStep: {
                size: 1,
                description: null,
                label: 'Espacio',
                type: 'CBR_TIPO_ESPACIO',
                showIcon: true,
                required: true,
                searchable: true,
            },
            description:
                'Por favor indícanos las incidencias que desees reportar con su respectivo espacio.',
            commentStep: {
                description: null,
                label: 'Comentarios',
                type: 'CBR_COMENTARIO',
                required: false,
            },
            id: 'CBR_INCIDENCIAS',
            label: 'Incidencias',
            type: 'CBR_INCIDENCIAS',
            addBtnLabel: 'Agregar Incidencia',
            required: false,
            dependencies: ['CBR_PROYECTO'],
        },
        TITLE: {
            description: 'Información del Solicitante:',
            id: 'TITLE',
            type: 'TITLE',
            title: 'Generar Postventa',
        },
        CBR_FIRST_NAME: {
            description: null,
            id: 'CBR_FIRST_NAME',
            label: 'Primer Nombre',
            type: 'CBR_FIRST_NAME',
            size: 1,
            required: true,
        },
        CBR_TIPO_DOC: {
            size: 1,
            description: null,
            id: 'CBR_TIPO_DOC',
            label: 'Tipo de documento',
            type: 'CBR_TIPO_DOC',
            required: true,
            searchable: true,
        },
        CBR_CEL: {
            description: null,
            id: 'CBR_CEL',
            label: 'Celular',
            type: 'CBR_CEL',
            size: 1,
            required: true,
        },
        'TITLE-cf-3PdK_5n0FOJjSg0FD7': {
            description: 'Información del Inmueble:',
            id: 'TITLE-cf-3PdK_5n0FOJjSg0FD7',
            type: 'TITLE',
            title: null,
        },
        CBR_EMAIL: {
            description: null,
            id: 'CBR_EMAIL',
            label: 'Correo',
            type: 'CBR_EMAIL',
            size: 2,
            required: true,
        },
        CBR_TIPO_SOLICITANTE: {
            size: 1,
            description: null,
            id: 'CBR_TIPO_SOLICITANTE',
            label: 'Tipo de solicitante',
            type: 'CBR_TIPO_SOLICITANTE',
            showIcon: true,
            required: true,
            searchable: true,
        },
        CBR_PHONE: {
            description: null,
            id: 'CBR_PHONE',
            label: 'Teléfono Fijo',
            type: 'CBR_PHONE',
            size: 1,
            required: false,
        },
        CBR_LAST_NAME: {
            description: null,
            id: 'CBR_LAST_NAME',
            label: 'Primer Apellido',
            type: 'CBR_LAST_NAME',
            size: 1,
            required: true,
        },
        CBR_SECOND_LAST_NAME: {
            description: null,
            id: 'CBR_SECOND_LAST_NAME',
            label: 'Segundo Apellido',
            type: 'CBR_SECOND_LAST_NAME',
            size: 1,
            required: false,
        },
        CBR_DOC: {
            size: 2,
            description: null,
            id: 'CBR_DOC',
            label: 'Documento',
            type: 'CBR_DOC',
            required: true,
            showIcon: true,
        },
    },
    sections: {
        NaOGtu_k5YqS0gtxOeBA0: {
            id: 'NaOGtu_k5YqS0gtxOeBA0',
            name: null,
            steps: [
                'TITLE',
                'CBR_FIRST_NAME',
                'CBR_MIDDLE_NAME',
                'CBR_LAST_NAME',
                'CBR_SECOND_LAST_NAME',
                'CBR_TIPO_SOLICITANTE',
                'CBR_TIPO_DOC',
                'CBR_DOC',
                'TITLE-4h_scIgNQOZWzJMtobPqx',
                'CBR_CEL',
                'CBR_PHONE',
                'CBR_EMAIL',
                'TITLE-cf-3PdK_5n0FOJjSg0FD7',
                'CBR_PROYECTO',
                'CBR_INMUEBLE',
                'CBR_INCIDENCIAS',
            ],
            nextSection: null,
        },
    },
    style: {
        type: 'MATERIAL',
        standAloneBackgroundColor: '#ffffff',
        backgroundColor: '#ffffff',
        stepBackgroundColor: '#ffffff',
        primaryColor: '#3d5a7f',
        secondaryColor: '#98c1d9',
        primaryContrastColor: '#ffffff',
        secondaryContrastColor: '#ee6c4d',
        errorColor: '#cc2936',
        textColor: '#293241',
        outlineColor: '#b8b8b8',
        descriptionTextColor: '#989898',
    },
    uniqueSteps: {
        clientInfoSteps: null,
    },
    terms: [],
    size: {
        blockNum: 4,
        blockSize: 210,
        spacingSize: 20,
    },
    description: '',
    isInternal: false,
    isPublic: false,
    hasCaptcha: false,
    isStandAlone: false,
    idForm: '',
    idOrganization: '',
    apiKey: '',
    idProject: '',
    classifiers: {},
};

export default POSTVENTAFORM;
