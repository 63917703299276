import axiosInstance from '../../AxiosAPI';
import { Area } from '../../@Types/@Types';
import { searchFunction } from '../../utils/searchFunction';
/**
 * Function that loads the project's areas
 * @param idProject the id of the current project
 * @param orderBy method ordered by, undefined if search is active
 * @param search search query, undefined if no search is active
 * @returns {roots:(an array if the ids of the root elements),elements: a map of the organization's areas}
 */
export const loadAreas = async (
    idProject: string,
    orderBy: string | undefined,
    search: string | undefined
): Promise<{
    roots: string[];
    elements: Record<string, Area>;
}> => {
    let url = `/projects/${idProject}/areas`;

    const response = await axiosInstance.get(url);
    const roots: string[] = [];
    const elements: Record<string, Area> = {};
    if (response.data) {
        /** Iterate the response */
        for (const elem of response.data) {
            const element: Area = <Area>elem;
            /** Instanciate the date */
            element.creation_date = new Date(element.creation_date);
            /** If path is null add element to roots */
            if (element.path === null) {
                roots.push(element._id);
            }
            elements[element._id] = element;
        }
        if (search !== undefined) {
            return <
                {
                    roots: string[];
                    elements: Record<string, Area>;
                }
            >searchFunction(elements, search);
        }
    }

    return { roots, elements };
};
/**
 * Function called to handle the conexion with the server to create an area(root)
 * @param idProject the id of the current project
 * @param area Record of values to create the area with
 */
export const createArea = async (
    idProject: string,
    area: Record<string, unknown>
): Promise<void> => {
    await axiosInstance.post(`/projects/${idProject}/areas/root`, area);
};

/**
 * Function called to handle the conexion with the server to create an area(node)
 * @param idProject the id of the current project
 * @param subArea Record of values to create the area with
 */
export const createSubArea = async (
    idProject: string,
    subArea: Record<string, unknown>
): Promise<void> => {
    await axiosInstance.post(`/projects/${idProject}/areas/node`, subArea);
};

/**
 * Function called to handle the conexion with the server to add an agent to an area
 * @param idProject the id of the current project
 * @param idArea the id of the area to add the agent to.
 * @param idAgent the id if the agent to be added
 */
export const addAgent = async (
    idProject: string,
    idArea: string,
    idAgent: string
): Promise<void> => {
    await axiosInstance.post(
        `/projects/${idProject}/areas/${idArea}/agents/${idAgent}`
    );
};

/**
 * Function called to handle the conexion with the server to remove an agent from an area
 * @param idProject the id of the current project
 * @param idArea the id of the area to remove the agent from.
 * @param idAgent the id if the agent to be removed
 */
export const removeAgent = async (
    idProject: string,
    idArea: string,
    idAgent: string
): Promise<void> => {
    await axiosInstance.delete(
        `/projects/${idProject}/areas/${idArea}/agents/${idAgent}`
    );
};

/**
 * Function called to handle the conexion with the server to update a value of an area
 * @param idProject the id of the current project
 * @param idArea the id of the area to update
 * @param area Record of values to update
 */
export const updateArea = async (
    idProject: string,
    idArea: string,
    area: Record<keyof Area, unknown>
): Promise<void> => {
    await axiosInstance.patch(`/projects/${idProject}/areas/${idArea}`, area);
};

/**
 * Function called to handle the conexion with the server to delete an area
 * @param idProject the id of the current project
 * @param idArea the id of the area to delete
 */
export const deleteArea = async (
    idProject: string,
    idArea: string
): Promise<void> => {
    await axiosInstance.delete(`/projects/${idProject}/areas/${idArea}`);
};

/**
 * Function called to handle the conexion with the server to update the classifiers order
 * @param idProject the id of the current project
 * @param roots array of root ids in order
 */
export const updateOrderAreas = async (
    idProject: string,
    roots: string[]
): Promise<void> => {
    await axiosInstance.patch(`/projects/${idProject}/areas/updateorder`, {
        roots: roots,
    });
};

export default {
    loadAreas,
    createArea,
    createSubArea,
    removeAgent,
    addAgent,
    deleteArea,
    updateArea,
    updateOrderAreas,
};
