import {
    createAction,
    createAsyncThunk,
    createSlice,
    nanoid,
} from '@reduxjs/toolkit';
import type { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { Condition } from '../../@Types/ConditionTypes/Condition';
import ConditionTypes from '../../constants/Conditions/ConditionTypes';
import { AppDispatch, RootState } from '../../utils/_store';
import { ExpressionCondition } from '../../@Types/ConditionTypes/ExpressionCondition';
import ExpressionTypes from '../../constants/Conditions/ExpressionTypes';
import { createContext, useContext } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import produce from 'immer';

export const ConditionContext = createContext<string>(nanoid());

export const useConditionEditorSelector: TypedUseSelectorHook<
    ConditionEditorState
> = (selector: any, options: any) => {
    const idCondition = useContext(ConditionContext);
    return useSelector(
        (state: RootState) => selector(state.conditionEditor[idCondition]),
        options
    );
};

export const useConditionEditorDispatch: () => AppDispatch =
    (): AppDispatch => {
        const idCondition = useContext(ConditionContext);
        const dispatch = useDispatch();
        const appDispatch = (action: AnyAction): AnyAction => {
            action.meta = { idCondition };
            return dispatch(action);
        };
        return appDispatch as AppDispatch;
    };

interface NormalizedExpressionCondition
    extends Omit<ExpressionCondition, 'conditions'> {
    conditions: string[];
}

export type ConditionEditorCondition =
    | Exclude<Condition, ExpressionCondition>
    | NormalizedExpressionCondition
    | {
          type: 'DEFAULT';
      };

export interface ConditionEditorState {
    root: string | null;
    conditions: Record<string, ConditionEditorCondition>;
}

const initialState: ConditionEditorState = {
    root: null,
    conditions: {},
};

export const ConditionEditorSlice = createSlice({
    name: 'ConditionEditor',
    initialState,
    reducers: {
        updateCondition: (
            state,
            {
                payload: { idCondition, condition },
            }: PayloadAction<{
                idCondition: string;
                condition:
                    | Exclude<Condition, ExpressionCondition>
                    | {
                          type: 'DEFAULT';
                      };
            }>
        ) => {
            state.conditions[idCondition] = condition;
        },

        toggleExpression: (state, { payload }: PayloadAction<string>) => {
            const condition = state.conditions[payload];
            if (condition.type === ConditionTypes.EXPRESSION) {
                condition.expression =
                    condition.expression === ExpressionTypes.AND
                        ? ExpressionTypes.OR
                        : ExpressionTypes.AND;
            }
        },
        addExpression: (state, { payload }: PayloadAction<string>) => {
            let condition = state.conditions[payload];
            if (condition.type !== ConditionTypes.EXPRESSION) {
                const idCondition = nanoid();
                state.conditions[idCondition] = condition;
                condition = {
                    type: ConditionTypes.EXPRESSION,
                    expression: ExpressionTypes.AND,
                    conditions: [idCondition],
                };
                state.conditions[payload] = condition;
            }

            const idCondition = nanoid();
            condition.conditions.push(idCondition);
            state.conditions[idCondition] = {
                type: 'DEFAULT',
            };
        },
        deleteCondition: (
            state,
            {
                payload: { idCondition, index },
            }: PayloadAction<{
                idCondition: string;
                index: number;
            }>
        ) => {
            const condition = state.conditions[idCondition];
            if (condition.type === ConditionTypes.EXPRESSION) {
                const [idSubCondition] = condition.conditions.splice(index, 1);
                const handleDeleteCondition = (idCondition: string): void => {
                    const condition = state.conditions[idCondition];
                    if (condition.type === ConditionTypes.EXPRESSION) {
                        for (const idSubCondition of condition.conditions) {
                            handleDeleteCondition(idSubCondition);
                        }
                    }
                    delete state.conditions[idCondition];
                };
                handleDeleteCondition(idSubCondition);

                if (condition.conditions.length === 1) {
                    const subCondition =
                        state.conditions[condition.conditions[0]];
                    state.conditions[idCondition] = subCondition;
                    delete state.conditions[condition.conditions[0]];
                }
            }
        },
    },
});

export const setupConditionEditor = createAction<
    Record<string, Condition | { type: 'DEFAULT' }>
>('ConditionEditor/Setup');

export const clearConditionEditor = createAction<string[]>(
    'ConditionEditor/Clear'
);

export function ConditionEditorReducer(
    state: Record<string, ConditionEditorState> = {},
    action: AnyAction
): Record<string, ConditionEditorState> {
    const idCondition = action.meta?.idCondition;
    if (clearConditionEditor.match(action)) {
        return produce(state, (state) => {
            for (const idCondition of action.payload) delete state[idCondition];
        });
    } else if (setupConditionEditor.match(action)) {
        return produce(state, (state) => {
            for (const [idCondition, condition] of Object.entries(
                action.payload
            )) {
                const conditions = {};
                normalizeCondition(idCondition, condition, conditions);
                state[idCondition] = {
                    root: idCondition,
                    conditions,
                };
            }
        });
    } else if (!idCondition) return state;
    return {
        ...state,
        [idCondition]: ConditionEditorSlice.reducer(state[idCondition], action),
    };
}

export const {
    updateCondition,
    addExpression,
    deleteCondition,
    toggleExpression,
} = ConditionEditorSlice.actions;

export const calcCondition = createAsyncThunk(
    'ConditionEditor/CalcCondition',
    async (
        {
            idCondition,
            keepSame,
        }: {
            idCondition: string;
            keepSame?: boolean;
        },
        thunkAPI
    ) => {
        const state = (thunkAPI.getState() as RootState).conditionEditor[
            idCondition
        ];
        if (!state?.root) return null;
        return keepSame
            ? basicDenormalizeConditions(state.root, state.conditions)
            : denormalizeConditions(state.root, state.conditions);
    }
);

const normalizeCondition = (
    idCondition: string,
    condition: Condition | { type: 'DEFAULT' },
    conditions: Record<string, ConditionEditorCondition>
): void => {
    if (condition.type === ConditionTypes.EXPRESSION) {
        conditions[idCondition] = {
            ...condition,
            conditions: condition.conditions.map((child) => {
                const idCondition = nanoid();
                normalizeCondition(idCondition, child, conditions);
                return idCondition;
            }),
        };
    } else {
        conditions[idCondition] = condition;
    }
};

const denormalizeConditions = (
    idCondition: string,
    conditions: Record<string, ConditionEditorCondition>
): Condition | undefined => {
    const condition = conditions[idCondition];
    if (condition.type === 'DEFAULT') return undefined;
    if (condition.type === ConditionTypes.EXPRESSION) {
        const subConditions = [];
        for (const idCondition of condition.conditions) {
            const subCondition = denormalizeConditions(idCondition, conditions);
            if (subCondition) subConditions.push(subCondition);
        }
        if (subConditions.length === 0) return undefined;
        if (subConditions.length === 1) return subConditions[0];
        return {
            ...condition,
            conditions: subConditions,
        };
    } else {
        return condition;
    }
};

const basicDenormalizeConditions = (
    idCondition: string,
    conditions: Record<string, ConditionEditorCondition>
): Condition | { type: 'DEFAULT' } => {
    const condition = conditions[idCondition];
    if (condition.type === ConditionTypes.EXPRESSION) {
        const subConditions = [];
        for (const idCondition of condition.conditions) {
            const subCondition = basicDenormalizeConditions(
                idCondition,
                conditions
            );
            if (subCondition) subConditions.push(subCondition);
        }
        return {
            ...condition,
            conditions: subConditions as any,
        };
    } else {
        return condition;
    }
};
