import React, { useEffect, useRef, useState } from 'react';
import { Client, Entity } from '../../../../@Types/@Types';
import { EntityValue, EntityValueClient } from '../../../../@Types/EntityValue';
import EntityValuesService, {
    loadEntityValueClients,
} from '../../../../controllers/EntityValuesController/EntityValuesService';
import RoundedButton from '../../../../shared/RoundedButton/RoundedButton';
import styles from './EntityValueClients.module.css';
import ClientsMenu from '../../../../shared/@Menus/ClientsMenu/ClientsMenu';
import ConfirmDialog from '../../../../shared/ConfirmDialog/ConfirmDialog';
import Loader from '../../../../shared/Loader/Loader';
import EntityValueClientComponent from './EntityValueClientComponent/EntityValueClientComponent';

interface EntityValueClientsProps {
    entity: Entity;
    entityValue: EntityValue;
}

function EntityValueClients({
    entity,
    entityValue,
}: EntityValueClientsProps): JSX.Element {
    const [clients, setClients] = useState<EntityValueClient[] | null>(null);
    const [showAdd, setShowAdd] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState<string | undefined>(
        undefined
    );

    const addBtnRef = useRef<HTMLDivElement>(null);

    async function loadClients(): Promise<void> {
        if (entityValue._id) {
            setClients(null);
            const clients = await loadEntityValueClients(
                entity._id,
                entityValue?._id
            );
            setClients(clients);
        }
    }

    useEffect(() => {
        loadClients();
    }, [entityValue]);
    return (
        <React.Fragment>
            {deleteDialog !== undefined && (
                <ConfirmDialog
                    onConfirm={async (
                        element: string,
                        setLoading: Function
                    ): Promise<void> => {
                        try {
                            setLoading(true);
                            await EntityValuesService.removeEntityValueClient(
                                entity._id,
                                entityValue._id,
                                deleteDialog
                            );
                            loadClients();
                            setDeleteDialog(undefined);
                        } catch (error) {
                            //TODO: handle errors
                            console.error(error);
                            setLoading(false);
                        }
                    }}
                    element={deleteDialog}
                    title="Desvincular Cliente"
                    btnMsg="Desvincular"
                    deleting
                    msg={`¿Está seguro de que desea desvincular el cliente de ${
                        entity.feminine ? 'esta' : 'este'
                    } ${entity.name}?`}
                    onClose={(): void => {
                        setDeleteDialog(undefined);
                    }}
                />
            )}
            {showAdd && (
                <ClientsMenu
                    anchorRef={addBtnRef}
                    handleClose={(): void => {
                        setShowAdd(false);
                    }}
                    placement="bottom-end"
                    zIndex={1301}
                    marginTop={'-5px'}
                    multiple={false}
                    elementsToOmit={clients ?? undefined}
                    handleConfirm={async (client: Client): Promise<void> => {
                        try {
                            setShowAdd(false);
                            setClients(null);
                            await EntityValuesService.addEntityValueClient(
                                entity._id,
                                entityValue._id,
                                client._id
                            );
                            loadClients();
                        } catch (error) {
                            //TODO: handle errors
                            console.error(error);
                        }
                    }}
                />
            )}
            <div className={styles.clientsContainer}>
                <div className={styles.clientsLbl}>
                    Clientes:
                    <div className={styles.addBtnContainer} ref={addBtnRef}>
                        <RoundedButton
                            text="Agregar Cliente"
                            backgroundColor={'var(--secondary)'}
                            color={'white'}
                            onClick={(): void => {
                                setShowAdd(true);
                            }}
                            padding="2px 13px"
                        />
                    </div>
                </div>
                <div className={styles.clientsListContainer + ' flexbox'}>
                    {!clients && (
                        <div className={styles.loaderContainer}>
                            <Loader size={50} />
                        </div>
                    )}
                    {clients && clients.length === 0 && (
                        <div className={styles.emptyLbl}>
                            {entity.name} sin clientes
                        </div>
                    )}
                    {clients &&
                        clients.map((client, index) => (
                            <EntityValueClientComponent
                                client={client}
                                entity={entity}
                                idEntityValue={entityValue._id}
                                key={index}
                                editable={true}
                                handleUpdate={(client: any): void => {
                                    const tempClients = [...clients];
                                    tempClients[index] = client;
                                    setClients(tempClients);
                                }}
                                handleDelete={(): void => {
                                    setDeleteDialog(client._id);
                                }}
                            />
                        ))}
                </div>
            </div>
        </React.Fragment>
    );
}

export default EntityValueClients;
