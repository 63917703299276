import styles from './AgentPickerStep.module.css';
import { Controller } from 'react-hook-form';
import RoundedAgentPicker from '../../@Pickers/RoundedAgentPicker/RoundedAgentPicker';
import { calcStepWidth } from '@arquimedes.co/eureka-forms/dist/FormSteps/StepFunctions';
import { CustomStepProps } from '@arquimedes.co/eureka-forms/dist/FormSteps/CustomStep';

export interface AgentPickerStepProps extends CustomStepProps {
    step: any;
}

function AgentPickerStep({
    step,
    form,
    editable,
    postview,
    widthStats,
    originalValue,
}: AgentPickerStepProps): JSX.Element {
    return (
        <div
            className={styles.container}
            style={{
                width:
                    widthStats.currentBreakPoint <= step.size
                        ? '100%'
                        : calcStepWidth(step.size, form.size),
                minHeight: step.description || step.required ? '55px' : '43px',
            }}
        >
            <Controller
                name={step.id}
                defaultValue={originalValue ?? []}
                rules={{
                    required: step.required
                        ? 'Este campo es obligatorio'
                        : undefined,
                }}
                shouldUnregister
                render={({
                    field: { ref, ...field },
                    fieldState: { error },
                }): JSX.Element => (
                    <RoundedAgentPicker
                        {...field}
                        inputRef={ref}
                        projectFilter={true}
                        showAreaFilters={true}
                        handleUpdate={field.onChange}
                        multiple={step.multiple}
                        cantEdit={editable === false || postview}
                        seed="EF-"
                        label={step.label}
                        helperText={error?.message ?? step.description}
                        error={!!error}
                        required={step.required}
                    />
                )}
            />
        </div>
    );
}

export default AgentPickerStep;
