import React from 'react';
import styles from './CreateSection.module.css';
import { Controller, useForm } from 'react-hook-form';
import RoundedTextField from '../../../../RoundedTextField/RoundedTextField';
import RoundedButton from '../../../../RoundedButton/RoundedButton';
import { nanoid } from '@reduxjs/toolkit';
import { Section } from '../../../../../@Types/GenericForm';
interface CreateClassifierProps {
    /** Function that handles when canceled or created */
    close: Function;
    /** Function that handles when classifier has been created */
    handleCreateSection: Function;
    /** Function inherited from the dialog to activate the loader */
    setLoading?: Function;
}
/**
 * Component rendered inside the create classifier dialog.
 */
function CreateClassifier({
    close,
    handleCreateSection,
    setLoading,
}: CreateClassifierProps): JSX.Element {
    const {
        getValues,
        control,
        formState: { errors, isDirty, isValid },
        trigger,
    } = useForm({
        mode: 'onChange',
    });

    /**
     * Function triggered when user want to create an classifier, checks if values are valid and calls the correct service
     */
    const handleCreate = async (): Promise<void> => {
        trigger();
        const values = getValues();
        if (isValid && isDirty && setLoading) {
            try {
                handleCreateSection({
                    id: nanoid(),
                    name: values.name,
                    title: values.name,
                    steps: [],
                    nextSection: null,
                } as Section);
                close();
            } catch (error) {
                setLoading(false);
                //TODO handle error
                console.error(error);
            }
        }
    };

    return (
        <div className={styles.container + ' standard-dialog'}>
            <h2 className="center-anything">Crear Sección</h2>
            <div className="center-anything">
                <div className="text-field-container">
                    <Controller
                        name="name"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: 'El nombre es obligatorio',
                        }}
                        render={({ field }): JSX.Element => (
                            <RoundedTextField
                                {...field}
                                label="Nombre"
                                helperText={
                                    errors.name ? errors.name.message : ''
                                }
                                error={!!errors.name}
                            />
                        )}
                    />
                </div>
            </div>
            <div className="center-anything">
                <div className="button">
                    <RoundedButton
                        text="Cancelar"
                        onClick={(): void => {
                            close();
                        }}
                    />
                </div>
                <div className="button">
                    <RoundedButton
                        text="Crear Sección"
                        backgroundColor="var(--secondary)"
                        color="white"
                        disabled={!!errors.name}
                        onClick={handleCreate}
                    />
                </div>
            </div>
        </div>
    );
}
export default CreateClassifier;
