import styles from './Rating.module.css';
import { TicketRating } from '../../../../../../@Types/TicketTypes/Ticket';
import Rating from '../../../../../../shared/Rating/Rating';
import { useRef, useState } from 'react';
import RoundedButton from '../../../../../../shared/RoundedButton/RoundedButton';
import React from 'react';
import RoundedTextField from '../../../../../../shared/RoundedTextField/RoundedTextField';
import {
    Rating as RatingType,
    TextArea,
} from '../../../../../../@Types/SurveyTypes/SurveyStep';
import FormDialog from '../../../../../../shared/FormRenderer/FormDialog/FormDialog';
interface RatingProps {
    name: string;
    rating: TicketRating;
}
function RatingComponent({ name, rating }: RatingProps): JSX.Element {
    const [showRating, setShowRating] = useState(false);
    const ratingRef = useRef<HTMLDivElement>(null);

    const primaryStep: RatingType = rating.survey.steps[
        rating.uniqueSteps.primaryRatingStep ?? ''
    ] as RatingType;

    const primaryValue = rating.surveyValues[primaryStep?.id ?? ''];

    const commentStep: TextArea = rating.survey.steps[
        rating.survey.uniqueSteps.commentStep ?? ''
    ] as TextArea;

    const commentValue = rating.surveyValues[commentStep?.id ?? '']?.value;
    return (
        <React.Fragment>
            {showRating && (
                <FormDialog
                    postview={true}
                    form={rating.survey}
                    values={rating.surveyValues}
                    onClose={(): void => setShowRating(false)}
                />
            )}
            <div className={styles.container}>
                {primaryStep && primaryValue !== undefined && (
                    <div className={styles.ratingContainer}>
                        <Rating
                            name={name}
                            value={primaryValue}
                            onChange={(): void => {}}
                            inputRef={ratingRef}
                            isAction
                            disabled
                            focusColor={'var(--secondary)'}
                            unSelectedColor={'var(--outlineGrey)'}
                            type={primaryStep.ratingType}
                        />
                    </div>
                )}
                {commentStep && commentValue !== undefined && (
                    <div className={styles.textField}>
                        <RoundedTextField
                            type="text"
                            label={commentStep.label}
                            multiline={true}
                            cantEdit
                            minRows={3}
                            value={commentValue}
                        />
                    </div>
                )}
                <div className={styles.formBtnContainer}>
                    <RoundedButton
                        onClick={(): void => {
                            setShowRating(true);
                        }}
                        backgroundColor={'var(--secondary)'}
                        text="Ver Calificación"
                        fontSize={'14px'}
                        padding="6px 12px"
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

export default RatingComponent;
