import { AnyAction } from '@reduxjs/toolkit';
import * as Actions from './GenericEditorActions';
import InternalFormStyle from '../../constants/InternalFormStyle';
import {
    FormSize,
    FormStyle,
    GBaseStep,
    GForm,
    Term,
} from '../../@Types/GenericForm';
import { Section } from '../../@Types/GenericForm';
import produce from 'immer';
import {
    GLocation,
    SectionLocation,
} from '../../@Types/FormTypes/LocationTypes';
import { calcSubSteps } from '@arquimedes.co/eureka-forms/dist/FormSteps/StepFunctions';

export interface GenericEditorError {
    error: string;
    idStep: string;
    property?: string;
}

export interface StepDependencies {
    dependencies: Record<string, string[]>; //Steptype => arreglo de pasos anteriores.
    ancestors: string[]; //Pasos ancestros a los que le pertenece el paso
    previousSteps: string[]; //pila de los pasos anteriores para poder usar en condiciones
    idParent: string | null;
}

export type SuccessInfo<S extends GBaseStep, U> = Pick<
    GForm<S, U>,
    'firstSection' | 'steps' | 'sections' | 'uniqueSteps'
> & { idSection?: string };

export interface EditorConfig<
    F extends GForm<S, U>,
    S extends GBaseStep,
    U,
    L extends GLocation,
    D
> {
    loadParams?: any[];
    loadData: (...args: any) => Promise<D>;
    getInfoOnSuccess?: (data: D) => SuccessInfo<S, U>;
    calcDependencies: (
        state: GenericEditorState<F, S, U, L, D>
    ) => GenericEditorState<F, S, U, L, D>;
    recursivelyCalcSize: (steps: Record<string, S>, idStep: string) => number;
    handleAddStep: (
        state: GenericEditorState<F, S, U, L, D>,
        payload: { step: S; location: L }
    ) => GenericEditorState<F, S, U, L, D>;
    handleDeleteStep: (
        state: GenericEditorState<F, S, U, L, D>,
        payload: { idStep: string; location: L }
    ) => GenericEditorState<F, S, U, L, D>;
    handlePasteStep: (
        state: GenericEditorState<F, S, U, L, D>,
        payload: L
    ) => GenericEditorState<F, S, U, L, D>;
    handleMoveStepUp: (
        state: GenericEditorState<F, S, U, L, D>,
        payload: L
    ) => GenericEditorState<F, S, U, L, D>;
    handleMoveStepDown: (
        state: GenericEditorState<F, S, U, L, D>,
        payload: L
    ) => GenericEditorState<F, S, U, L, D>;
    handleUpdateStepId: (
        state: GenericEditorState<F, S, U, L, D>,
        payload: {
            idStep: string;
            newId: string;
            location: SectionLocation | GLocation;
        }
    ) => GenericEditorState<F, S, U, L, D>;
    handleUpdateStep: (
        state: GenericEditorState<F, S, U, L, D>,
        payload: { step: S; location: L }
    ) => GenericEditorState<F, S, U, L, D>;
    handleUpdateSteps: (
        state: GenericEditorState<F, S, U, L, D>,
        payload: { steps: Record<string, S>; location: L }
    ) => GenericEditorState<F, S, U, L, D>;
    cloneStep: (
        idStep: string,
        steps: Record<string, S>,
        newSteps: Record<string, S>
    ) => string;
    calcExtraSteps?: (data: D) => string[] | undefined;
}

export type GenericEditor<
    F extends GForm<S, U>,
    S extends GBaseStep,
    U,
    L extends GLocation,
    D
> = Record<string, GenericEditorState<F, S, U, L, D>>;

export interface GenericEditorState<
    F extends GForm<S, U>,
    S extends GBaseStep,
    U,
    L extends GLocation,
    D
> {
    /** If loader is active */
    loading: boolean;
    /** The current name of the form */
    name: string;
    /** The first section of the form */
    firstSection: string;
    /** If the preview is being displayed */
    showPreview: boolean;
    /** If the preview is being displayed */
    showSettings: boolean;
    /** If the conditions dialog is being displayed */
    showConditions:
        | {
              idStep: string;
              location: L;
          }
        | undefined;
    /** All the steps in the form */
    steps: Record<string, S>;
    /** Record of the StepDependencies of each step */
    stepDependencies: Record<string, StepDependencies>;
    /** The forms Sections */
    sections: Record<string, Section>;
    /** The Current form */
    data: D;
    /** The currently displayed section's id*/
    idSection: string | undefined;
    /** The current unique steps (primary text area, subject text input, client info...)*/
    uniqueSteps: U;
    /** The forms current terms */
    terms: Term[];
    /** The current style of the form */
    style: FormStyle;
    /** The current formSize */
    size: FormSize;
    /** The currently copied information */
    copiedInfo:
        | {
              newStepId: string;
              newSteps: Record<string, S>;
          }
        | undefined;
    /** Error steps to display as open*/
    error: {
        idStep: string;
        steps: Record<string, boolean>;
        property?: string;
    };
    config: EditorConfig<F, S, U, L, D>;
}

export const DefaultGenericEditorState = {
    name: '',
    loading: true,
    showPreview: false,
    showSettings: false,
    firstSection: '',
    steps: {},
    stepDependencies: {},
    sections: {},
    uniqueSteps: undefined,
    data: undefined as any,
    idSection: undefined,
    idPrevSection: undefined,
    copiedInfo: undefined,
    config: {
        getInfoOnSuccess: (data: any) => data,
        calcDependencies: (state: any) => state,
    } as any,
    terms: [],
    style: InternalFormStyle,
    size: {
        spacingSize: 20,
        blockNum: 4,
        blockSize: 210,
    } as FormSize,
};

/**
 * Redux Reducer that handles EditorActions Tiggers
 * @param state The current state
 * @param action the action that was triggered
 * @returns the new state
 */

const EditorReducer = (
    state: GenericEditorState<
        any,
        any,
        any,
        any,
        any
    > = DefaultGenericEditorState as any,
    action: AnyAction
): GenericEditorState<any, any, any, any, any> => {
    if (Actions.reset.match(action)) {
        return {
            ...state,
            ...(DefaultGenericEditorState as any),
            config: { ...DefaultGenericEditorState.config, ...action.payload },
        };
    } else if (Actions.getSuccess.match(action)) {
        const info = normalizeInfo(
            state.config.getInfoOnSuccess?.(action.payload) ?? action.payload
        );
        //Set random idSection if no section was specified
        if (
            info &&
            info.idSection === undefined &&
            state.idSection === undefined
        ) {
            const sections = Object.keys(info.sections);
            if (sections.length > 0) {
                info.idSection = sections[0];
            }
        }
        return state.config.calcDependencies({
            ...state,
            data: action.payload,
            loading: false,
            copiedInfo: undefined,
            ...info,
        });
    } else if (Actions.setCurrentSection.match(action)) {
        return {
            ...state,
            idSection: action.payload,
        };
    } else if (Actions.updateData.match(action)) {
        return {
            ...state,
            data: action.payload,
        };
    } else if (Actions.updateUniqueSteps.match(action)) {
        return {
            ...state,
            uniqueSteps: action.payload,
        };
    } else if (Actions.updateName.match(action)) {
        return {
            ...state,
            name: action.payload,
        };
    } else if (Actions.updateTerms.match(action)) {
        return {
            ...state,
            terms: action.payload,
        };
    } else if (Actions.updateStyle.match(action)) {
        return {
            ...state,
            style: action.payload,
        };
    } else if (Actions.updateSize.match(action)) {
        return {
            ...state,
            size: action.payload,
        };
    } else if (Actions.updateSection.match(action)) {
        return {
            ...state,
            sections: {
                ...state.sections,
                [action.payload.id]: action.payload,
            },
        };
    } else if (Actions.addSection.match(action)) {
        return {
            ...state,
            sections: {
                ...state.sections,
                [action.payload.id]: action.payload,
            },
            idSection: undefined,
        };
    } else if (Actions.setShowPreview.match(action)) {
        return {
            ...state,
            showPreview: action.payload,
        };
    } else if (Actions.setShowSettings.match(action)) {
        return {
            ...state,
            showSettings: action.payload,
        };
    } else if (Actions.updateStep.match(action)) {
        return state.config.calcDependencies(
            calcMaxSize(
                state.config.handleUpdateStep({ ...state }, action.payload),
                action.payload.location
            )
        );
    } else if (Actions.updateSteps.match(action)) {
        return state.config.calcDependencies(
            calcMaxSize(
                state.config.handleUpdateSteps({ ...state }, action.payload),
                action.payload.location
            )
        );
    } else if (Actions.addStep.match(action)) {
        return state.config.calcDependencies(
            calcMaxSize(
                state.config.handleAddStep({ ...state }, action.payload),
                action.payload.location
            )
        );
    } else if (Actions.copyStep.match(action)) {
        return state.config.calcDependencies(
            produce(state, (state) => {
                const newSteps = {};
                const newStepId = state.config.cloneStep(
                    action.payload.idStep,
                    state.steps,
                    newSteps
                );
                state.copiedInfo = { newStepId, newSteps };
            })
        );
    } else if (Actions.updateStepId.match(action)) {
        return state.config.calcDependencies({
            ...state.config.handleUpdateStepId(state, action.payload),
        });
    } else if (Actions.deleteStep.match(action)) {
        return state.config.calcDependencies(
            calcMaxSize(
                state.config.handleDeleteStep({ ...state }, action.payload),
                action.payload.location
            )
        );
    } else if (Actions.pasteStep.match(action)) {
        return state.config.calcDependencies(
            calcMaxSize(
                state.config.handlePasteStep({ ...state }, action.payload),
                action.payload
            )
        );
    } else if (Actions.moveStepUp.match(action)) {
        return state.config.calcDependencies(
            state.config.handleMoveStepUp(state, action.payload)
        );
    } else if (Actions.moveStepDown.match(action)) {
        return state.config.calcDependencies(
            state.config.handleMoveStepDown(state, action.payload)
        );
    } else if (Actions.setError.match(action)) {
        return produce(state, (state) => {
            const idStep = action.payload.idStep;
            if (state.stepDependencies[idStep]) {
                state.error = {
                    idStep,
                    property: action.payload.property,
                    steps: state.stepDependencies[idStep].ancestors.reduce(
                        (a, idStep) => ({
                            ...a,
                            [idStep]: true,
                        }),
                        {
                            [idStep]: true,
                        }
                    ),
                };
            }
        });
    }
    return state;
};

export default (
    state: GenericEditor<any, any, any, any, any> = {} as any,
    action: AnyAction
): GenericEditor<any, any, any, any, any> => {
    if (!action.meta?.idEditor || !action.type.startsWith('GENERIC-EDITOR/'))
        return state;
    return {
        ...state,
        [action.meta.idEditor]: EditorReducer(
            state[action.meta.idEditor],
            action
        ),
    };
};

const calcMaxSize = (
    state: GenericEditorState<any, any, any, any, any>,
    location: GLocation
): GenericEditorState<any, any, any, any, any> =>
    produce(state, (state) => {
        const rootStep = state.steps[location.idRootStep];
        if (rootStep && rootStep.maxSize !== undefined) {
            state.steps[rootStep.id].maxSize = state.config.recursivelyCalcSize(
                state.steps,
                rootStep.id
            );
        }
    });

const normalizeInfo = (form: SuccessInfo<any, any>): SuccessInfo<any, any> => {
    return {
        ...form,
        steps: normalizeSteps(form.steps),
    };
};

function normalizeSteps(
    steps: Record<string, GBaseStep>
): Record<string, GBaseStep> {
    let allSteps: Record<string, GBaseStep> = {};
    for (const idStep of Object.keys(steps)) {
        const step = steps[idStep];
        if (isMapper(step)) {
            const mapperSteps = normalizeSteps(step.steps);
            allSteps = { ...allSteps, ...mapperSteps };
        }
        allSteps[idStep] = step;
    }
    return allSteps;
}

interface Mapper {
    steps: Record<string, GBaseStep>;
    rootSteps: string[];
}

function isMapper(step: any): step is Mapper {
    return step.type === 'MAPPER';
}

export function denormalizeForm(
    pform: GForm<any, any>,
    extraSteps?: string[]
): GForm<any, any> {
    const form = JSON.parse(JSON.stringify(pform));
    const newSteps: GForm<any, any>['steps'] = {};
    let nextSection: string | null = form.firstSection;
    while (nextSection !== null) {
        const section: Section = form.sections[nextSection];
        for (const idStep of section.steps) {
            denormalizeStep(idStep, form.steps, newSteps);
        }
        nextSection = section.nextSection ?? null;
    }
    if (extraSteps) {
        for (const idStep of extraSteps) {
            denormalizeStep(idStep, form.steps, newSteps);
        }
    }
    form.steps = newSteps;
    return form;
}

export function denormalizeStep(
    idStep: string,
    steps: Record<string, GBaseStep>,
    newSteps: Record<string, GBaseStep>
): void {
    const step = steps[idStep];
    if (!step) {
        console.error('Missing Step:', idStep);
        return;
    }
    if (isMapper(step)) {
        const subSteps: string[] = [];
        for (const idStep of step.rootSteps) {
            subSteps.push(idStep, ...calcSubSteps(idStep, steps as any));
        }
        const newSteps = {};
        for (const idStep of subSteps) {
            denormalizeStep(idStep, steps, newSteps);
        }
        step.steps = newSteps;
    } else {
        for (const idSubStep of calcSubSteps(idStep, steps as any)) {
            denormalizeStep(idSubStep, steps, newSteps);
        }
    }
    newSteps[idStep] = step;
}
