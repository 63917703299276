import { createAction } from '@reduxjs/toolkit';
import { SocketMessage } from './SocketsReducer';
import { EventChannel } from 'redux-saga';

export const Types = {
    INITIATE_SOCKET: 'INITIATE_SOCKET',
    RECEIVE_MESSAGE: 'RECEIVE_MESSAGE',
    CLOSE_SOCKET: 'CLOSE_SOCKET',
};

/**
 * Called when the user info is loaded and a socket connection could be established
 */
export const initiateSocket = createAction<{
    idAgent: string;
    email: string;
    idOrganization: string;
}>(Types.INITIATE_SOCKET);
/**
 * Called when a socket receive an incoming message
 */
export const receiveMessage = createAction<SocketMessage>(
    Types.RECEIVE_MESSAGE
);
/**
 * Called when the user is logged out or when the socket connection closed up
 */
export const closeSocket = createAction<{
    socket: WebSocket;
    socketChannel: EventChannel<any>;
}>(Types.CLOSE_SOCKET);
