import React, { useMemo, useState } from 'react';
import { Payload } from '../../../../@Types/Payload';
import Dialog from '../../../../shared/Dialog/Dialog';
import styles from './EditTemplatePayload.module.css';
import RoundedButton from '../../../../shared/RoundedButton/RoundedButton';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { Template } from '../../../../@Types/Template';
import {
    PayloadEditorActions,
    usePayloadEditorDispatch,
} from '../../../../controllers/PayloadEditorController/PayloadEditorSlice';
import PayloadEditor, {
    BasePayloadEditorConditionTypes,
} from '../../../../shared/TextEditor/PayloadEditor';
import ConditionTypes from '../../../../constants/Conditions/ConditionTypes';

interface EditTemplatePayloadProps {
    template: Template;
    handleUpdate: (object: Template, value: Payload) => void;
}

function EditTemplatePayload({
    template,
    handleUpdate,
}: EditTemplatePayloadProps): JSX.Element {
    const [openEdit, setOpenEdit] = useState(false);
    const dispatch = usePayloadEditorDispatch();
    const handleSend = async (): Promise<void> => {
        const payload: Payload | null = await dispatch(
            PayloadEditorActions.calcPayload({
                idEditor: 'Templates',
            }) as any
        ).unwrap();
        if (!payload) return;
        await handleUpdate(template, payload);
        setOpenEdit(false);
    };
    const handleClose = (): void => {
        setOpenEdit(false);
    };
    const handleOpen = (): void => {
        setOpenEdit(true);
    };

    const conditions = useMemo(() => {
        const types = [...BasePayloadEditorConditionTypes];
        types.push(ConditionTypes.ENTRY);
        return { types };
    }, []);

    return (
        <React.Fragment>
            <Dialog
                onClose={handleClose}
                open={openEdit}
                border={10}
                maxWidth={900}
            >
                <div
                    className={styles.editContainer}
                    data-testid={'Editor_Container'}
                >
                    <div className={styles.editHeader}>Editar Plantilla</div>
                    <div className={styles.editorContainer}>
                        <PayloadEditor
                            idEditor={'Templates'}
                            placeholder={'Editar'}
                            payload={template.payload}
                            autoFocus
                            context={{
                                ticket: true,
                                agent: true,
                                client: true,
                            }}
                            conditions={conditions}
                        />
                    </div>
                    <div className={styles.buttonsContainer}>
                        <div className={styles.cancelButton}>
                            <RoundedButton
                                text={'Descartar'}
                                onClick={handleClose}
                                backgroundColor={'var(--defaultGrey)'}
                                color={'var(--accent)'}
                                fontSize="20px"
                                padding="4px 14px"
                                height={30}
                            />
                        </div>
                        <div
                            className={styles.saveButton}
                            data-testid={'ConfirmButton'}
                        >
                            <RoundedButton
                                text={'Guardar'}
                                onClick={handleSend}
                                fontSize="20px"
                                padding="4px 14px"
                                height={30}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>

            <div
                className={styles.btnContainer}
                data-testid={'EditTemplatePayload_btn'}
            >
                <EditRoundedIcon onClick={handleOpen} />
            </div>
        </React.Fragment>
    );
}
export default EditTemplatePayload;
