import React, { useEffect } from 'react';
import styles from './CreateCompany.module.css';
import companiesService from '../../../controllers/CompaniesController/CompaniesService';
import EntityRenderer from '../../../shared/EntityRenderer/EntityRenderer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';

interface CreateCompanyProps {
    /** Function called when the agent has been created */
    handleCompanyCreated: Function;
    /** Function called to close the Dialog */
    onClose?: Function;
    /** Function called to update the Dialog's loader */
    setLoading?: Function;
}
function CreateCompany({
    handleCompanyCreated,
    onClose,
    setLoading,
}: CreateCompanyProps): JSX.Element {
    const siteInfo = useSelector((state: RootState) => state.site);

    useEffect(() => {
        if (
            siteInfo.entities[siteInfo.organization?.idCompanyEntity ?? ''] ===
            undefined
        ) {
            onClose?.();
        }
    }, []);

    const entity =
        siteInfo.entities[siteInfo.organization?.idCompanyEntity ?? ''];

    if (entity) {
        return (
            <div className={styles.container + ' standard-dialog'}>
                <h2 className="center-anything">Crear empresa</h2>
                <div
                    className={styles.entityContainer}
                    style={{
                        width:
                            (entity.size.blockSize + entity.size.spacingSize) *
                            entity.size.blockNum,
                    }}
                >
                    <EntityRenderer
                        entity={entity}
                        creating
                        customSend={async (company): Promise<void> => {
                            try {
                                await new Promise((resolve) =>
                                    setTimeout(resolve, 1000)
                                );
                                await companiesService.createCompany({
                                    values: company.values,
                                    idEntity: entity._id,
                                });
                                handleCompanyCreated();
                            } catch (error) {
                                //TODO handle errors
                                console.error(error);
                                setLoading?.(false);
                            }
                        }}
                        // editable
                    />
                </div>
            </div>
        );
    } else {
        return <div></div>;
    }
}

export default CreateCompany;
