import { AnyAction } from '@reduxjs/toolkit';
import * as actions from '../TemplatesController/TemplatesActions';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
    TemplatesApi,
    loadTemplates,
    loadTemplatesByQuery,
    updateTemplate,
} from './TemplatesService';
import VanillaToast from '../../shared/Toast/Toast';
import { TemplatesPageState, templatesToQueryString } from './TemplatesReducer';
import ScrollTypes from '../../constants/ScrollTypes';
import { RootState } from '../../utils/_store';

const getIdProject = (state: RootState): string | undefined | null =>
    state.site.idProject;

const PAGE_SIZE = 20;

function* resetTemplates(action: AnyAction): any {
    if (actions.reset.match(action)) {
        const idProject = yield select(getIdProject);
        if (
            action.payload.idTemplate ||
            action.payload.queryString.length > 0
        ) {
            try {
                const result = yield call(
                    loadTemplatesByQuery,
                    idProject,
                    action.payload.queryString,
                    action.payload.idTemplate
                );
                const changes = { id: false, query: false };
                result.lastPage = result.elements.length < PAGE_SIZE;
                if (
                    action.payload.idTemplate &&
                    result.selectedElement === undefined
                ) {
                    changes.id = true;
                    VanillaToast.create({
                        title: 'No se encontró la plantilla',
                        text: `Template ${action.payload.idTemplate} no encontrado`,
                        type: 'error',
                        timeout: 5000,
                    });
                }
                if (
                    action.payload.queryString.length > 0 &&
                    Object.keys(result.filters).length == 0 &&
                    !result.search
                ) {
                    changes.query = true;
                }
                if (changes.id || changes.query) {
                    const newQuery = templatesToQueryString(
                        result.filters,
                        result.orderBy,
                        result.search,
                        true
                    );
                    let basePath = `/${idProject}/templates`;
                    if (result.selectedElement !== undefined) {
                        basePath +=
                            '/' + result.elements[result.selectedElement]._id;
                    }
                    action.payload.history.replace({
                        pathname: basePath,
                        search: newQuery,
                        state: {
                            prevPath: action.payload.history.location.pathname,
                        },
                    });
                }
                yield put(actions.resetSuccess(result));
            } catch (error) {
                console.error(error);
            }
        } else {
            yield getTemplates(action);
        }
    }
}

/**
 * Function called when new templates should be rendered (reset,search, or newpage)
 * @param action of type Search, reset or scroll
 */
function* getTemplates(action: AnyAction): any {
    try {
        const pageInfo = yield select((state) => state.templatesPage);
        const idProject = yield select(getIdProject);
        let nPage = 1;
        if (actions.pageScroll.match(action)) {
            nPage =
                action.payload === ScrollTypes.DOWN
                    ? pageInfo.pageDown
                    : pageInfo.pageUp;
        }
        let result = yield call(
            loadTemplates,
            idProject,
            nPage,
            PAGE_SIZE,
            pageInfo.filters,
            pageInfo.orderBy,
            pageInfo.search
        );
        const addedLength = result.length;
        /** Calcs if more elements are available */
        const lastPage =
            (pageInfo.pageDown !== 1 && result.length === 0) ||
            (pageInfo.pageDown === 1 && result.length < PAGE_SIZE);
        let selectedElement = pageInfo.selectedElement;
        if (actions.pageScroll.match(action)) {
            if (action.payload === ScrollTypes.DOWN) {
                result = [...pageInfo.elements].concat(result);
            } else {
                if (selectedElement !== undefined) {
                    selectedElement += result.length;
                }
                result = result.concat(...pageInfo.elements);
            }
        }
        let list;
        if (
            actions.pageScroll.match(action) &&
            action.payload === ScrollTypes.UP
        ) {
            list = document.getElementById('templates-infiniteList');
        }
        yield put(
            actions.getSuccess({ selectedElement, elements: result, lastPage })
        );
        if (list) list.scrollTop = addedLength * 115;
    } catch (error) {
        //TODO handle errors
    }
}

/**
 * Function called when an agent's value is updated optimistically to patch the updates to the server asyncly
 * @param action of type UPDATE
 */
function* putTemplate(action: AnyAction): any {
    if (
        actions.updateElement.match(action) ||
        actions.updatePayload.match(action)
    ) {
        try {
            const field = action.payload.field;
            const payload: Record<string, unknown> = {};
            payload[field] = action.payload.value;
            const idProject = yield select(getIdProject);
            yield call(
                updateTemplate,
                action.payload.object._id,
                idProject,
                payload
            );
            yield put(TemplatesApi.util.invalidateTags(['Templates']));
        } catch (error) {
            VanillaToast.create({
                title: 'Error al actualizar la plantilla',
                text: error,
                type: 'error',
                timeout: 5000,
            });
            yield put(actions.updateFailed(action.payload.object));
        }
    }
}

function* goTo(action: AnyAction): any {
    try {
        if (actions.goTo.match(action)) {
            const idTemplate = action.payload.idTemplate;
            const pageInfo: TemplatesPageState = yield select(
                (state: RootState) => state.templatesPage
            );
            const selectedElement =
                pageInfo.elements[pageInfo.selectedElement ?? -1];
            const queryChange =
                action.payload.queryString !==
                templatesToQueryString(
                    pageInfo.filters,
                    pageInfo.orderBy,
                    pageInfo.search,
                    true
                );
            if (queryChange) {
                yield put(
                    actions.reset({
                        history: action.payload.history,
                        queryString: action.payload.queryString,
                        idTemplate,
                    })
                );
            } else if (idTemplate !== selectedElement?._id) {
                /** If the currently selected Element doesn't have the id in the url  */
                /** If user wants to unselect (no id in url) */
                if (idTemplate === undefined) {
                    yield put(actions.selectElement(undefined));
                } else {
                    /** Check if element is in the current list. */
                    const index = pageInfo.elements.findIndex(
                        (listElement) => listElement._id === idTemplate
                    );
                    /** IF element is in current list select it. */
                    if (index >= 0) {
                        yield put(actions.selectElement(index));
                    } else {
                        /** Reload page as if new one. */
                        yield put(
                            actions.reset({
                                history: action.payload.history,
                                queryString: action.payload.queryString,
                                idTemplate,
                            })
                        );
                    }
                }
            }
        }
    } catch (error) {
        //TODO handle errors
        console.error(error);
    }
}

function* watchGoTo(): any {
    yield takeLatest([actions.Types.GO_TO], goTo);
}

function* watchResetTemplates(): any {
    yield takeLatest([actions.Types.RESET], resetTemplates);
}

function* watchGetTemplatesRequest(): any {
    yield takeLatest(
        [
            actions.Types.REFRESH,
            actions.Types.PAGE_SCROLL,
            actions.Types.SEARCH,
        ],
        getTemplates
    );
}

function* watchUpdateTemplate(): any {
    yield takeLatest([actions.Types.UPDATE], putTemplate);
}

export default [
    fork(watchResetTemplates),
    fork(watchGetTemplatesRequest),
    fork(watchUpdateTemplate),
    fork(watchGoTo),
];
