import React, { useContext } from 'react';
import styles from '../TextEditorContext.module.css';
import {
    PayloadEditorActions,
    PayloadEditorContext,
    usePayloadEditorDispatch,
} from '../../../../controllers/PayloadEditorController/PayloadEditorSlice';
import { DraftEntityDataTypes } from '../../../../constants/Draft/DraftEntityDataTypes';
import { TicketPropertyTypes } from '../../../../constants/TicketPropertyTypes';
import { PayloadEditorConceptsContext } from '../../PayloadEditor';
import TicketIcon from '../../../../Icons/TicketIcon';
import { DraftStyleTypes } from '../../../../constants/Draft/DraftStyleTypes';
import { DraftEntityData } from '../../../../@Types/Draft/DraftEntityData';
import { TextEditorTypeContext } from '../../TextEditor';

interface FormContextProps {
    disabled: boolean;
}

function FormContext({ disabled }: FormContextProps): JSX.Element {
    const idEditor = useContext(PayloadEditorContext);
    const concepts = useContext(PayloadEditorConceptsContext);
    const editorType = useContext(TextEditorTypeContext);
    const data: DraftEntityData = {
        type: DraftEntityDataTypes.TICKET,
        property: TicketPropertyTypes.CASENUMBER,
    };

    const dispatch = usePayloadEditorDispatch();
    return (
        <React.Fragment>
            <button
                data-testid={'FormContextButton'}
                title={'Número de caso'}
                className={styles.contextBtn}
                disabled={disabled}
                onClick={(): void => {
                    dispatch(
                        PayloadEditorActions.addEntity({
                            editorType,
                            autoEdit: false,
                            isMapping: false,
                            idEditor,
                            data,
                            concepts,
                            styles: [DraftStyleTypes.BOLD],
                        })
                    );
                }}
            >
                <TicketIcon
                    fill={'var(--secondary)'}
                    style={{
                        width: 24,
                        height: 24,
                    }}
                />
            </button>
        </React.Fragment>
    );
}

export default FormContext;
