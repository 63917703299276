import { AnyAction } from '@reduxjs/toolkit';
import { takeLatest, call, put, fork, select } from 'redux-saga/effects';
import { Agent } from '../../../@Types/@Types';
import { RootState } from '../../../utils/_store';
import { AgentsPageState } from './AgentsReducer';
import * as actions from './AgentsActions';
import { loadAgents } from './AgentsService';
import { SiteState } from '../../_SiteController/SiteReducer';

/** Number of elements per Page */
const PAGE_SIZE = 20;

/**
 * Function called when new agents should be rendered(reset,search, or newpage)
 * @param action of type Search, reset or scroll
 */
function* getAgents(action: AnyAction): any {
    try {
        const siteInfo: SiteState = yield select(
            (state: RootState) => state.site
        );
        const pageInfo: AgentsPageState = yield select(
            (state: RootState) => state.ticketAssignAgents
        );
        let result = yield call(
            loadAgents,
            pageInfo.page,
            PAGE_SIZE,
            siteInfo.idProject,
            pageInfo.areafilter,
            pageInfo.search
        );
        //Remove agents on vacation
        result = result.filter((agent: Agent) => !agent.onVacations);
        //Remove agents that are already selected
        result = result.map((agent: Agent) => {
            return {
                ...agent,
                assigned:
                    pageInfo.assignedAgents.find(
                        (ele: Agent) => ele._id === agent._id
                    ) !== undefined,
            };
        });
        const noMoreElements =
            (pageInfo.page !== 1 && result.length === 0) ||
            (pageInfo.page === 1 && result.length < PAGE_SIZE);
        if (actions.pageScroll.match(action)) {
            result = [...pageInfo.elements].concat(result);
        }
        yield put(actions.getSuccess({ elements: result, noMoreElements }));
    } catch (error) {
        //TODO handle errors
        console.error(error);
    }
}

function* watchGetAgentsRequest(): any {
    yield takeLatest(
        [
            actions.Types.RESET,
            actions.Types.PAGE_SCROLL,
            actions.Types.SEARCH,
            actions.Types.FILTER,
        ],
        getAgents
    );
}

export default [fork(watchGetAgentsRequest)];
