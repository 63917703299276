import EurekaForm, { EurekaFormProps } from '@arquimedes.co/eureka-forms';
import { useSelector } from 'react-redux';
import { Agent, Classifier, Form } from '../../@Types/@Types';
import { Company } from '../../@Types/Company';
import Types from '../../constants/FormStepTypes';
import { RootState } from '../../utils/_store';
import AgentPickerStep from './AgentPickerStep/AgentPickerStep';
import CompanyPickerStep from './CompanyPickerStep/CompanyPickerStep';
import { FormStep } from '../../@Types/FormTypes/FormStep';
import { fetchDownloadUrl } from '../../controllers/FilesController/FilesService';
import { useMemo } from 'react';
import styles from './FormRenderer.module.css';
import React from 'react';
import type { Form as ErkForm } from '@arquimedes.co/eureka-forms/dist/@Types';
import produce from 'immer';
import { nanoid } from '@reduxjs/toolkit';
import { FormTypes } from '@arquimedes.co/eureka-forms/dist/constants/FormStepTypes';

export interface FormRendererProps {
    form: Omit<
        ErkForm,
        'steps' | 'firstSection' | 'sections' | 'classifiers'
    > & {
        steps: Record<string, any>;
        sections?: Form['sections'];
        firstSection?: Form['firstSection'];
        classifiers?: Record<string, Classifier>;
    };
    rootSteps?: string[];
    values?: Record<string, unknown>;
    idForm?: string;
}

function FormRenderer({
    form,
    values,
    rootSteps = [],
    idForm,
    ...props
}: Omit<EurekaFormProps, 'formData' | 'valuesData'> &
    FormRendererProps): JSX.Element {
    const idOrganization = useSelector(
        (state: RootState) => state.site.organization?.idOrganization
    );
    const countryCode = useSelector(
        (state: RootState) => state.site.organization?.countryCode
    );
    const formRef = React.useRef<any>();
    const classifiers = useSelector(
        (state: RootState) => state.site.classifiers
    );

    const formData: {
        form: FormRendererProps['form'];
        values: FormRendererProps['values'];
        tempSteps?: Record<string, string>;
    } = useMemo(() => {
        if (
            idForm === 'FORM-DEFAULT' ||
            idForm === 'FORM-CONFIRM-DEFAULT' ||
            idForm === 'ENTREGA-FORM-DEFAULT'
        ) {
            const tempSteps: Record<string, string> = {};
            return produce(
                { form, values, tempSteps },
                ({ form, values, tempSteps }) => {
                    if (!values) values = {};
                    form.firstSection = 'FIRST';
                    form.type = FormTypes.STEPPER;

                    const newSteps = rootSteps.filter(
                        (idStep) => idStep !== 'espacios'
                    );

                    form.sections = {
                        FIRST: {
                            id: 'FIRST',
                            steps: newSteps,
                            name: '',
                            nextSection: null,
                        },
                    };
                    const newSections = ['FIRST'];
                    for (const espacio of values.espacios as any[]) {
                        const idTemp = `ESPACIOSTEMP-${nanoid()}`;
                        const tempStep = JSON.parse(
                            JSON.stringify(form.steps.espacios)
                        );
                        tempStep.id = idTemp;
                        form.steps[idTemp] = tempStep;
                        form.sections[idTemp] = {
                            id: idTemp,
                            steps: [idTemp],
                            name: '',
                            nextSection: null,
                        };
                        form.sections[
                            newSections[newSections.length - 1]
                        ].nextSection = idTemp;
                        newSections.push(idTemp);
                        values[idTemp] = [espacio];
                        tempSteps[idTemp] = 'espacios';
                    }
                    if (
                        newSteps.length === 0 &&
                        form.sections['FIRST'].nextSection
                    ) {
                        form.firstSection = form.sections['FIRST'].nextSection;
                        delete form.sections['FIRST'];
                    }
                    delete values['espacios'];
                }
            );
        }
        const isCompleteForm =
            form.sections?.[form.firstSection ?? '']?.steps !== undefined;
        return isCompleteForm
            ? { form, values }
            : {
                  form: {
                      ...form,
                      firstSection: 'FIRST',
                      sections: {
                          FIRST: {
                              id: 'FIRST',
                              steps: rootSteps,
                              name: '',
                              nextSection: null,
                          },
                      },
                  },
                  values,
              };
    }, [form, rootSteps, values]);

    return (
        <div className={styles.container}>
            <div
                ref={formRef}
                className={styles.formContainer}
                style={
                    form
                        ? {
                              width:
                                  (form.size.blockSize +
                                      form.size.spacingSize) *
                                  form.size.blockNum,
                          }
                        : {}
                }
            >
                <EurekaForm
                    idOrganization={idOrganization}
                    countryCode={countryCode}
                    internal={true}
                    isWidget={true}
                    {...props}
                    customSubmit={
                        formData.tempSteps
                            ? async (values, reload): Promise<void> => {
                                  const newValues = produce(
                                      values,
                                      (values) => {
                                          for (const [
                                              idTempStep,
                                              idStep,
                                          ] of Object.entries(
                                              formData.tempSteps!
                                          )) {
                                              values[idStep] = [
                                                  ...((values[idStep] as any) ??
                                                      []),
                                                  ...(values[
                                                      idTempStep
                                                  ] as any),
                                              ];
                                              delete values[idTempStep];
                                          }
                                      }
                                  );
                                  await props.customSubmit?.(newValues, reload);
                              }
                            : props.customSubmit
                    }
                    formData={formData.form as any}
                    valuesData={formData.values}
                    classifiers={classifiers}
                    customSteps={{
                        ...(props.customSteps ?? {}),
                        [Types.AGENTPICKER]: {
                            component: (props: any): JSX.Element => (
                                <AgentPickerStep {...props} />
                            ),
                            calcValue: (step: FormStep, value: any): any => {
                                if (step.type === Types.AGENTPICKER) {
                                    return value.map((val: Agent) => val._id);
                                }
                            },
                        },
                        [Types.COMPANYPICKER]: {
                            component: (props: any): JSX.Element => (
                                <CompanyPickerStep {...props} />
                            ),
                            calcValue: (step: FormStep, value: any): any => {
                                if (step.type === Types.COMPANYPICKER) {
                                    return value.map((val: Company) => val._id);
                                }
                            },
                        },
                    }}
                    fetchDownloadUrl={fetchDownloadUrl}
                />
            </div>
        </div>
    );
}

export default FormRenderer;
