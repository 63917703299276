import { useEffect, useState } from 'react';
import styles from './FormTicketEntry.module.css';
import { useSelector } from 'react-redux';
import {
    FormTicketEntry,
    MigrationTicketEntry,
} from '../../../../../@Types/TicketTypes/Ticket';
import { RootState } from '../../../../../utils/_store';
import FormRenderer from '../../../../../shared/FormRenderer/FormRenderer';
import { loadFormHistory } from '../../../../../controllers/FormsController/FormsService';
import { Form } from '../../../../../@Types/@Types';
import SmartAgent from '../../../../../shared/Avatar/SmartAgent';

interface FormPostViewProps {
    /** The values sent by the user to display */
    values: Record<string, any>;
    /** The ticket's the Entry */
    entry: FormTicketEntry | MigrationTicketEntry;
}
/**
 * Component rendered inside the postview dialog.
 */
function FormPostView({ entry, values }: FormPostViewProps): JSX.Element {
    const idProject = useSelector((state: RootState) => state.site.idProject);
    const [form, setForm] = useState<Form | undefined | null>(undefined);

    const fetchForm = async (): Promise<void> => {
        if (idProject) {
            try {
                const response = await loadFormHistory(idProject, entry.idForm);
                setForm(response);
            } catch (error) {
                setForm(null);
            }
        }
    };
    useEffect(() => {
        fetchForm();
    }, []);

    if (form === undefined) return <div></div>;
    return (
        <div className={styles.container}>
            <div className={styles.containerLbl}>{entry.name}</div>
            {!form && (
                <div className={styles.formNotFound}>
                    No fue posible cargar el formulario
                </div>
            )}
            {form && (
                <div
                    className={styles.widgetContainer}
                    style={{
                        width: form.size
                            ? (form.size.blockSize + form.size.spacingSize) *
                                  form.size.blockNum +
                              40
                            : 980,
                    }}
                >
                    <FormRenderer postview={true} form={form} values={values} />
                    {form.hiddenSteps && form.hiddenSteps.length > 0 && (
                        <FormRenderer
                            postview={true}
                            form={{ steps: form.steps, size: form.size }}
                            rootSteps={form.hiddenSteps}
                            values={values}
                        />
                    )}
                </div>
            )}
            {values.INTERNAL_CREATING_AGENT && (
                <div className={styles.internalAgentContainer}>
                    <div className={styles.internalLbl}>Creado Por:</div>
                    <SmartAgent idAgent={values.INTERNAL_CREATING_AGENT} />
                </div>
            )}
        </div>
    );
}

export default FormPostView;
