import rootStyles from './ClassifierTreeItem.module.css';
import nodeStyles from './ClassifierTreeNode.module.css';
import React, { useEffect, useState } from 'react';
import { Classifier } from '../../../@Types/@Types';
interface ClassifierTreeItemProps {
    /** Id of the classifier to display */
    idElement?: string;
    /** Map of all the projects classifier */
    elements?: Record<string, Classifier>;
    /** Function that handles clicking on this component */
    handleItemClick?: Function;
    /** The currently selected element */
    selectedElement?: string | undefined;
    /** If user is editing the trees order */
    editing?: boolean;
    /** If the layout is mobile */
    layoutIsMobile?: boolean;
    /** If the item is root */
    isRoot?: boolean;
}
/**
 * Recursive classifier component
 */
function ClassifierTreeItem({
    idElement,
    elements,
    handleItemClick,
    selectedElement,
    editing = false,
    layoutIsMobile,
    isRoot = false,
}: ClassifierTreeItemProps): JSX.Element {
    if (
        !idElement ||
        !elements ||
        !handleItemClick ||
        layoutIsMobile === undefined
    ) {
        return <div></div>;
    }
    /** Choose between root and node styles */
    const styles = isRoot ? rootStyles : nodeStyles;
    const element = elements[idElement];
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (editing) {
            setOpen(false);
        }
    }, [editing]);
    /**
     * Renders the classifier's subclassifiers
     * @returns subclassifiers
     */
    const renderSubClassifiers = (): JSX.Element[] => {
        return element.children.map((child, index: number) => (
            <ClassifierTreeItem
                idElement={child}
                elements={elements}
                key={index}
                handleItemClick={handleItemClick}
                selectedElement={selectedElement}
                layoutIsMobile={layoutIsMobile}
            />
        ));
    };

    /** Computes if the element being displayed has children to display the carret or not */
    const hasChildren = element.children.length > 0;

    return (
        <div className={styles.treeItemContainer + ' noselect'}>
            <div
                className={styles.treeItemTitle}
                style={{
                    backgroundColor:
                        selectedElement === element._id
                            ? 'var(--defaultGrey)'
                            : 'var(--primary1)',
                    cursor: editing ? 'move' : 'pointer',
                }}
            >
                <img
                    style={{
                        visibility: hasChildren ? 'visible' : 'hidden',
                    }}
                    alt={'>'}
                    className={styles.dropdownIcon}
                    src={
                        open
                            ? '/media/icons/treedownarrow.svg'
                            : '/media/icons/treerightarrow.svg'
                    }
                    onClick={(): void => {
                        if (hasChildren && !editing) {
                            setOpen(!open);
                        }
                    }}
                />
                {isRoot && (
                    <div
                        className={styles.colorIcon}
                        style={{ backgroundColor: element.color?.background }}
                    ></div>
                )}
                <div
                    className={styles.label}
                    onClick={(): void => {
                        if (!editing) {
                            if (hasChildren) {
                                if (!open) {
                                    if (!layoutIsMobile) {
                                        handleItemClick(element._id);
                                    }
                                    setOpen(true);
                                } else {
                                    if (selectedElement === element._id) {
                                        handleItemClick(undefined);
                                        setOpen(false);
                                    } else {
                                        handleItemClick(element._id);
                                    }
                                }
                            } else {
                                if (selectedElement === element._id) {
                                    handleItemClick(undefined);
                                } else {
                                    handleItemClick(element._id);
                                }
                            }
                        }
                    }}
                >
                    {element.name}
                </div>
            </div>
            {open && (
                <div className={styles.itemSubContainer}>
                    {renderSubClassifiers()}
                </div>
            )}
        </div>
    );
}

export default ClassifierTreeItem;
