import styles from './Area.module.css';
import { Area } from '../../../../../../@Types/@Types';
import ApartmentRoundedIcon from '@material-ui/icons/ApartmentRounded';
interface AreaProps {
    /** The Area the ticket was assign to  */
    area: Area;
}

/**
 * Component that displays the content of the assign to Area action
 */
function AreaComponent({ area }: AreaProps): JSX.Element {
    return (
        <div className={styles.container}>
            <div className={styles.areaContainer}>
                <div className={styles.iconContainer}>
                    <ApartmentRoundedIcon fontSize="inherit" />
                </div>
                <label className={styles.nameLbl}>{area.name}</label>
            </div>
        </div>
    );
}
export default AreaComponent;
