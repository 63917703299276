import { IconProps } from './@IconTypes';

function EntryIcon({ className, style, fill }: IconProps): JSX.Element {
    return (
        <svg
            fill={fill}
            style={style}
            className={className}
            width="512"
            height="512"
            version="1.1"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <path d="m14.5 21h-4c-1.378 0-2.5-1.122-2.5-2.5v-2c0-.276.224-.5.5-.5s.5.224.5.5v2c0 .827.673 1.5 1.5 1.5h4c.276 0 .5.224.5.5s-.224.5-.5.5z" />
            </g>
            <g>
                <path d="m10.5 11h-10c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h10c.276 0 .5.224.5.5s-.224.5-.5.5z" />
            </g>
            <g>
                <path d="m16 24c-1.103 0-2-.897-2-2v-18c0-.86.552-1.621 1.373-1.895l6-2c.19-.068.405-.105.627-.105 1.103 0 2 .897 2 2v18c0 .859-.551 1.621-1.372 1.894l-6 2c-.193.069-.408.106-.628.106zm6-23c-.104 0-.208.018-.3.05l-6.011 2.004c-.413.137-.689.517-.689.946v18c0 .551.449 1 1 1 .104 0 .208-.018.302-.051l6.01-2.003c.411-.138.688-.518.688-.946v-18c0-.551-.449-1-1-1z" />
            </g>
            <g>
                <path d="m8.5 5c-.276 0-.5-.224-.5-.5v-2c0-1.378 1.122-2.5 2.5-2.5h11.5c.276 0 .5.224.5.5s-.224.5-.5.5h-11.5c-.827 0-1.5.673-1.5 1.5v2c0 .276-.224.5-.5.5z" />
            </g>
            <g>
                <path d="m6.5 15c-.128 0-.256-.049-.354-.146-.195-.195-.195-.512 0-.707l3.647-3.647-3.647-3.646c-.195-.195-.195-.512 0-.707s.512-.195.707 0l4 4c.195.195.195.512 0 .707l-4 4c-.097.097-.225.146-.353.146z" />
            </g>
        </svg>
    );
}
export default EntryIcon;
