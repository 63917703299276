import styles from './StateChange.module.css';
import State from './State/State';
import { StateChange as StateChangeType } from '../../../../../../@Types/Action';
interface StateProps {
    states: StateChangeType[];
}
/**
 * Component that displays multiple statechange actions, they are grouped in the service if they
 * were made by the same agent given a timespan
 */
function StateComponent({ states }: StateProps): JSX.Element {
    return (
        <div className={styles.container}>
            {states.map((state, index) => (
                <State
                    key={index}
                    idValue={state.idValue}
                    idOriginal={state.idOriginal}
                />
            ))}
        </div>
    );
}

export default StateComponent;
