import Dialog from '../../../shared/Dialog/Dialog';
import styles from './SaveDialog.module.css';
import RoundedButton from '../../../shared/RoundedButton/RoundedButton';
import { useEffect, useState } from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import * as GenericEditorActions from '../../../controllers/GenericEditorController/GenericEditorActions';
import { GBaseStep, GForm } from '../../../@Types/GenericForm';
import { AxiosError } from 'axios';
import { useGenericEditorDispatch } from '../GenericFormEditorHooks';
import { GenericEditorError } from '../../../controllers/GenericEditorController/GenericEditorReducer';

interface SaveProps<S extends GBaseStep, U, D> {
    /** Function that handles the closing event */
    onClose: () => void;
    /** Current form to save */
    form: GForm<S, U>;
    handleSave: (form: GForm<S, U>, data: D) => Promise<void>;
    /** The formData in the editor */
    data: D;
}

/**
 * Component that calls the services to save.
 */
function Save<S extends GBaseStep, U, D>({
    data,
    onClose,
    form,
    handleSave,
}: SaveProps<S, U, D>): JSX.Element {
    return (
        <Dialog open={form !== undefined} onClose={onClose} showLoader={false}>
            <InnerSaveDialog
                data={data}
                form={form}
                onClose={onClose}
                handleSave={handleSave}
            ></InnerSaveDialog>
        </Dialog>
    );
}
export default Save;

interface InnerDialogProps<S extends GBaseStep, U, D>
    extends SaveProps<S, U, D> {
    /** Function that toggles the loader in the modal, inherited from the modal. */
    setLoading?: Function;
    /** if loader should be active */
    loading?: boolean;
}

function InnerSaveDialog<S extends GBaseStep, U, D>({
    data,
    form,
    onClose,
    handleSave,
    setLoading,
}: InnerDialogProps<S, U, D>): JSX.Element {
    const [saving, setSaving] = useState(true);
    const [error, setError] = useState<undefined | string>(undefined);
    const dispatch = useGenericEditorDispatch();
    const toggleSave = (toggle: boolean): void => {
        if (setLoading) {
            setLoading(toggle);
            setSaving(toggle);
        }
    };
    const doSave = async (): Promise<void> => {
        if (setLoading) {
            toggleSave(true);
            try {
                await handleSave(form, data);
                toggleSave(false);
            } catch (error) {
                if (isEditorError(error)) {
                    dispatch(
                        GenericEditorActions.setError(error.response?.data!)
                    );
                    setError(error.response?.data?.error);
                } else {
                    setError(error + '');
                    console.error(error);
                }
                toggleSave(false);
            }
        } else {
            onClose();
        }
    };

    useEffect(() => {
        doSave();
    }, []);

    if (error) {
        return (
            <div
                className={styles.containerErrors + ' standard-dialog'}
                id={'GenericEditorSaveDialogError'}
            >
                <div className="center-anything">
                    <label className={styles.titleError}>
                        {'Error Encontrado'}
                        <div className={styles.errorsIcon}>
                            <WarningIcon fontSize="inherit" />
                        </div>
                    </label>
                </div>
                <div className="center-anything">
                    <p className={styles.errorMsg}>{error}</p>
                </div>
            </div>
        );
    }
    if (saving) {
        return (
            <div className={styles.container + ' standard-dialog'}>
                <div className={styles.savingMsg}>
                    Guardando
                    <span className="loader__dot">.</span>
                    <span className="loader__dot">.</span>
                    <span className="loader__dot">.</span>
                </div>
            </div>
        );
    } else {
        return (
            <div
                className={styles.container + ' standard-dialog'}
                data-testid={'SaveDialog_Container'}
            >
                <div className="center-anything">
                    <label className={styles.title + ' center-anything'}>
                        Se ha guardado correctamente!
                    </label>
                </div>
                <div
                    className={styles.cerrarBtn}
                    data-testid={'SaveDialog_CloseBtn'}
                >
                    <RoundedButton
                        text={'Cerrar'}
                        color="white"
                        fontSize={18}
                        onClick={(): void => onClose()}
                    />
                </div>
            </div>
        );
    }
}

function isEditorError(error: any): error is AxiosError<GenericEditorError> {
    return (
        (error as AxiosError<GenericEditorError>).response?.data?.idStep !==
            undefined &&
        (error as AxiosError<GenericEditorError>).response?.data?.error !==
            undefined
    );
}
