import styles from './DatePickerStep.module.css';
import RoundedTextField from '../../RoundedTextField/RoundedTextField';
import Toggle from '../../Toggle/Toggle';
import RoundedDatePicker from '../../@Pickers/RoundedDatePicker/RoundedDatePicker';
import React from 'react';
import { GDatePicker } from '../../../@Types/GenericFormSteps';
import { GenericStepProps } from '../../GenericFormEditor/Step/Step';
import { calcStepWidth } from '../../GenericFormEditor/StepFunctions';
import { GLocation } from '../../../@Types/FormTypes/LocationTypes';

function DatePickerStep<StepType extends GDatePicker, U, L extends GLocation>({
    editing,
    step,
    updateStep,
    size,
}: GenericStepProps<StepType, U, L>): JSX.Element {
    if (editing) {
        return (
            <div className={styles.editingContainer}>
                <div className={styles.inputContainer}>
                    <RoundedTextField
                        label="Etiqueta"
                        value={step.label}
                        onChange={(e): void => {
                            updateStep({ ...step, label: e.target.value });
                        }}
                    ></RoundedTextField>
                </div>

                <RoundedTextField
                    label="Descripción"
                    value={step.description ?? ''}
                    onChange={(e): void => {
                        updateStep({
                            ...step,
                            description: e.target.value,
                        });
                    }}
                ></RoundedTextField>
                <div className={styles.timeContainer}>
                    <div className={styles.timeLabel}>Seleccionar Hora:</div>
                    <div className={styles.toggleContainer}>
                        <Toggle
                            checked={step.pickTime === true}
                            onChange={(checked: boolean): void => {
                                updateStep({
                                    ...step,
                                    pickTime: checked,
                                });
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div
                style={{
                    width: calcStepWidth(step.size, size),
                    maxWidth: '100%',
                    marginBottom: step.description ? '0px' : '5px',
                }}
            >
                <RoundedDatePicker
                    value={null}
                    height="31px"
                    backgroundColor="var(--primary1)"
                    label={step.label}
                    helperText={step.description}
                    required={step.required}
                    onChange={(): void => {}}
                />
            </div>
        );
    }
}

export default React.memo(DatePickerStep, (prev, next) => {
    const { step, stepEqualityChecker } = prev;
    const { step: newStep } = next;
    if (
        step.size !== newStep.size ||
        step.label !== newStep.label ||
        step.pickTime !== newStep.pickTime ||
        step.required !== newStep.required ||
        step.description !== newStep.description
    ) {
        return false;
    }

    return stepEqualityChecker?.(prev, next) ?? true;
}) as typeof DatePickerStep;
