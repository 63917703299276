import React, { useRef, useState } from 'react';
import styles from './EntityConfig.module.css';
import EntityRelationshipComponent from './EntityRelationship/EntityRelationship';
import {
    loadEntitiesById,
    loadEntities,
} from '../../../../controllers/EntitiesController/EntitiesService';
import GenericMenu from '../../../../shared/@Menus/GenericMenu/GenericMenu';
import EntityIcon from '../../../../shared/SmartIcons/EntityIcon';
import Avatar from '../../../../shared/Avatar/Avatar';
import { Entity } from '../../../../@Types/@Types';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { EntityRelationship } from '../../../../@Types/EntityTypes/Entity';

interface EntityRelationshipsComponentProps {
    relationships: EntityRelationship[];
    handleUpdate: (relationships: EntityRelationship[]) => void;
    path: string[];
}

function EntityRelationshipsComponent({
    path,
    handleUpdate,
    relationships,
}: EntityRelationshipsComponentProps): JSX.Element {
    const [showMenu, setShowMenu] = useState(false);

    const addRelationshipRef = useRef<any>();

    return (
        <React.Fragment>
            <div className={styles.descriptionLabel + ' noselect'}>
                Relaciones
            </div>
            {showMenu && (
                <GenericMenu<Entity, string>
                    handleClose={(): void => {
                        setShowMenu(false);
                    }}
                    zIndex={1305}
                    anchorRef={addRelationshipRef}
                    label={'Entidad'}
                    pluralLabel={'Entidades'}
                    calcLbl={(entity): string => entity.name}
                    loadInitialType={async (ids): Promise<Entity[]> =>
                        await loadEntitiesById(ids)
                    }
                    multiple={false}
                    handleUpdate={([relationshipEntity]): void => {
                        handleUpdate([
                            ...relationships,
                            {
                                idEntity: relationshipEntity._id,
                                name: relationshipEntity.name,
                                description: relationshipEntity.description,
                                bidirectional: true,
                                idRelationshipSteps: [],
                                multiple: true,
                                required: true,
                                rootSteps: [],
                                steps: {},
                            },
                        ]);
                        setShowMenu(false);
                    }}
                    calcIcon={(entity): JSX.Element => (
                        <Avatar lbl={entity.name} size={30} fontSize={30}>
                            <EntityIcon
                                idEntity={entity._id}
                                fill="var(--primary1)"
                                style={{
                                    height: 21,
                                    width: 21,
                                }}
                            />
                        </Avatar>
                    )}
                    loadElements={async (
                        page: number,
                        pageSize: number,
                        search: string | undefined
                    ): Promise<Entity[]> => {
                        return await loadEntities(page, pageSize, search);
                    }}
                />
            )}
            <div className={styles.relationshipsContainer}>
                {relationships.map((relationship, index) => (
                    <EntityRelationshipComponent
                        path={path}
                        key={relationship.idEntity + index}
                        relationship={relationship}
                        handleDelete={(): void => {
                            const copy = [...relationships];
                            copy.splice(index, 1);
                            handleUpdate(copy);
                        }}
                        handleUpdate={(relationship): void => {
                            const copy = [...relationships];
                            copy[index] = relationship;
                            handleUpdate(copy);
                        }}
                    />
                ))}
                <div
                    className={styles.addPermButton}
                    ref={addRelationshipRef}
                    onClick={(): void => {
                        setShowMenu(true);
                    }}
                >
                    <div className={styles.addIconContainer}>
                        <AddRoundedIcon fontSize="inherit" />
                    </div>
                    <div className={styles.addPermLbl + ' noselect'}>
                        Agregar
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export default EntityRelationshipsComponent;
