export enum OptionTypes {
    DEFAULT = 'DEFAULT',
    NESTED = 'NESTED',
    ADD_SECTION = 'ADD_SECTION',
}

export enum ClassifierOptionTypes {
    DEFAULT = 'CLASSIFIER_DEFAULT',
    HIDE = 'CLASSIFIER_HIDE',
    NESTED = 'CLASSIFIER_NESTED',
}

export enum EntityValueOptionTypes {
    DEFAULT = 'ENTITY_VALUE_DEFAULT',
    HIDE = 'ENTITY_VALUE_HIDE',
    NESTED = 'ENTITY_VALUE_NESTED',
}

export enum ApiSelectorOptionTypes {
    DEFAULT = 'API_SELECTOR_DEFAULT',
    HIDE = 'API_SELECTOR_HIDE',
    NESTED = 'API_SELECTOR_NESTED',
}
