import { FormStepLocation, UniqueSteps } from '../../@Types/FormTypes/Form';
import { FormStep } from '../../@Types/FormTypes/FormStep';
import FormStepTypes from '../../constants/FormStepTypes';
import { Suspense } from 'react';
import lazy from 'react-lazy-with-preload';
import AgentPickerStep from '../../shared/@Steps/AgentPickerStep/AgentPickerStep';
import ClassifierSelectorStep from './Steps/ClassifierSelectorStep/ClassifierSelectorStep';
import CompanyPickerStep from './Steps/CompanyPickerStep/CompanyPickerStep';
import DatePickerStep from '../../shared/@Steps/DatePickerStep/DatePickerStep';
import FileUploadStep from '../../shared/@Steps/FileUploadStep/FileUploadStep';
import SelectorStep from '../../shared/@Steps/SelectorStep/SelectorStep';
import SeparatorStep from '../../shared/@Steps/SeparatorStep/SeparatorStep';
import TextAreaStep from '../../shared/@Steps/TextAreaStep/TextAreaStep';
import TitleStep from '../../shared/@Steps/TitleStep/TitleStep';
import CheckBoxStep from '../../shared/@Steps/CheckBoxStep/CheckBoxStep';
import TextInputStep from '../../shared/@Steps/TextInputStep/TextInputStep';
import TextAreaConfig from './Steps/TextAreaConfig/TextAreaConfig';
import TextInputConfig from './Steps/TextInputConfig/TextInputConfig';
import { GenericStepProps } from '../../shared/GenericFormEditor/Step/Step';
import RatingStep from '../../shared/@Steps/RatingStep/RatingStep';
import AYFStepMapper from './Steps/AYFStepMapper';
import EntityValueStep from '../../shared/@Steps/EntityValueStep/EntityValueStep';
import { CheckBoxLocation } from '../../@Types/FormTypes/LocationTypes';
import LocationTypes from '../../constants/LocationTypes';
import MapperStep from '../../shared/@Steps/MapperStep/MapperStep';
import MapperStepConfig from './Steps/MapperStepConfig/MapperStepConfig';
import ApiSelectorStep from '../../shared/@Steps/ApiSelectorStep/ApiSelectorStep';
import EntityValueStepConfig from './Steps/EntityValueStepConfig/EntityValueStepConfig';
import TimePickerStep from '../../shared/@Steps/TimePickerStep/TimePickerStep';
const CBRStepMapper = lazy(() => import('./Steps/CBRStepMapper'));

export function FormStepMapper(
    props: GenericStepProps<FormStep, UniqueSteps, FormStepLocation>
): JSX.Element {
    const { step, location } = props;
    switch (step.type) {
        case FormStepTypes.TITLE: {
            return <TitleStep {...props} step={step} />;
        }
        case FormStepTypes.CHECKBOX: {
            return (
                <CheckBoxStep
                    {...props}
                    step={step}
                    calcSubLocation={(checked: boolean): CheckBoxLocation => ({
                        type: LocationTypes.CHECKBOX,
                        idRootStep: location.idRootStep,
                        idStep: step.id,
                        indexStep: null,
                        checked,
                    })}
                />
            );
        }
        case FormStepTypes.SELECTOR: {
            return (
                <SelectorStep
                    {...props}
                    step={step}
                    stepEqualityChecker={({ step }, { step: next }): boolean =>
                        step.isSubject === next.isSubject
                    }
                />
            );
        }
        case FormStepTypes.CLASSIFIER_SELECTOR: {
            return <ClassifierSelectorStep {...props} step={step} />;
        }
        case FormStepTypes.DATEPICKER: {
            return <DatePickerStep {...props} step={step} />;
        }
        case FormStepTypes.TIMEPICKER: {
            return <TimePickerStep {...props} step={step} />;
        }
        case FormStepTypes.AGENTPICKER: {
            return <AgentPickerStep {...props} step={step} />;
        }
        case FormStepTypes.COMPANYPICKER: {
            return <CompanyPickerStep {...props} step={step} />;
        }
        case FormStepTypes.TEXTAREA: {
            return (
                <TextAreaStep
                    {...props}
                    step={step}
                    stepEqualityChecker={({ step }, { step: next }): boolean =>
                        step.isPrimary === next.isPrimary
                    }
                >
                    <TextAreaConfig />
                </TextAreaStep>
            );
        }
        case FormStepTypes.TEXTINPUT: {
            return (
                <TextInputStep
                    {...props}
                    step={step}
                    allowValidation
                    stepEqualityChecker={({ step }, { step: next }): boolean =>
                        step.isSubject === next.isSubject &&
                        step.clientInfoType === next.clientInfoType &&
                        step.idCompanyMatcher === next.idCompanyMatcher &&
                        step.idClientProperty === next.idClientProperty
                    }
                >
                    <TextInputConfig />
                </TextInputStep>
            );
        }
        case FormStepTypes.FILEUPLOAD: {
            return <FileUploadStep {...props} step={step} />;
        }
        case FormStepTypes.RATING: {
            return <RatingStep {...props} step={step} />;
        }
        case FormStepTypes.SEPARATOR: {
            return <SeparatorStep {...props} step={step} />;
        }
        case FormStepTypes.ENTITYVALUEPICKER: {
            return (
                <EntityValueStep
                    {...props}
                    step={step}
                    stepEqualityChecker={({ step }, { step: next }): boolean =>
                        step.dialogs === next.dialogs
                    }
                >
                    <EntityValueStepConfig location={props.location} />
                </EntityValueStep>
            );
        }
        case FormStepTypes.API_SELECTOR: {
            return <ApiSelectorStep {...props} step={step} />;
        }
        case FormStepTypes.MAPPER: {
            return (
                <MapperStep
                    {...props}
                    step={step}
                    stepEqualityChecker={({ step }, { step: next }): boolean =>
                        step.concept === next.concept
                    }
                >
                    <MapperStepConfig />
                </MapperStep>
            );
        }
        default:
            if (
                props.stepMapperProps.integrations.CBR &&
                (props.step as any).type.startsWith('CBR')
            ) {
                return (
                    <Suspense fallback={<div></div>}>
                        <CBRStepMapper {...(props as any)} />
                    </Suspense>
                );
            } else if (
                props.stepMapperProps.integrations.AYF === true &&
                (props.step as any).type.startsWith('AYF')
            ) {
                return (
                    <Suspense fallback={<div></div>}>
                        <AYFStepMapper {...(props as any)} />
                    </Suspense>
                );
            }
            return <div></div>;
    }
}
