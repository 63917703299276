import { AnyAction } from '@reduxjs/toolkit';
import { Agent, Area } from '../../../@Types/@Types';
import * as Actions from './AgentsActions';

export interface AgentsPageState {
    /** The currently displayed Agents */
    elements: Agent[];
    /** The currently active filters */
    areafilter: Area | null;
    /** The current search, undefined if none */
    search: string | undefined;
    /** the index of the currently selectedElement */
    selectedElement: number | undefined;
    /** The number of pages currently loaded in the list */
    page: number;
    /** Active if no more pages are available */
    noMoreElements: boolean;
    /** If loader is active */
    loading: boolean;
    /** If filters are showing */
    showFilters: boolean;
}

const initialState = {
    elements: [],
    areafilter: null,
    search: undefined,
    selectedElement: undefined,
    page: 1,
    noMoreElements: false,
    loading: true,
    showFilters: false,
};

/**
 * Redux Reducer that handles AgentsActions Tiggers
 * @param state The current state
 * @param action the action that was triggered
 * @returns the new state
 */
const AgentsReducer = (
    state: AgentsPageState = initialState,
    action: AnyAction
): AgentsPageState => {
    if (Actions.resetTransfer.match(action)) {
        return {
            ...state,
            elements: [],
            areafilter: null,
            search: undefined,
            selectedElement: undefined,
            page: 1,
            noMoreElements: false,
            loading: true,
            showFilters: false,
        };
    } else if (Actions.setLoading.match(action)) {
        return {
            ...state,
            loading: action.payload,
        };
    } else if (Actions.getSuccess.match(action)) {
        return {
            ...state,
            loading: false,
            elements: action.payload.elements,
            noMoreElements: action.payload.noMoreElements,
        };
    } else if (Actions.pageScroll.match(action)) {
        return { ...state, page: state.page + 1 };
    } else if (Actions.selectElement.match(action)) {
        return { ...state, selectedElement: action.payload };
    } else if (Actions.search.match(action)) {
        return {
            ...state,
            elements: [],
            page: 1,
            noMoreElements: false,
            selectedElement: undefined,
            loading: true,
            search: action.payload === '' ? undefined : action.payload,
        };
    } else if (Actions.filter.match(action)) {
        return {
            ...state,
            areafilter: action.payload,
            loading: true,
        };
    } else if (Actions.toggleFilters.match(action)) {
        return {
            ...state,
            showFilters: !state.showFilters,
        };
    }
    return state;
};
export default AgentsReducer;
