import { useEffect, useRef } from 'react';
import Dialog from '../../../../shared/Dialog/Dialog';
import { EditorConfig } from '../../../../controllers/GenericEditorController/GenericEditorReducer';
import Section from '../../../../shared/GenericFormEditor/Section/Section';
import { reset } from '../../../../controllers/GenericEditorController/GenericEditorActions';
import styles from './HiddenStepsEditor.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../utils/_store';
import { FormStep } from '../../../../@Types/FormTypes/FormStep';
import ReducerFunctions, {
    useFormEditorSelector,
} from '../../FormReducerFunctions';
import StepMenu from '../../StepMenu/StepMenu';
import { FormStepMapper } from '../../FormStepMappers';
import { Form } from '../../../../@Types/@Types';
import { IdGenericEditorContext } from '../../../../shared/GenericFormEditor/GenericFormEditor';

const idEditor = 'HIDDEN-STEPS';

interface HiddenStepsEditorProps {
    steps: Record<string, FormStep>;
    hiddenSteps: string[];
    onClose: (steps: Record<string, FormStep>, hiddenSteps: string[]) => void;
}

function HiddenStepsEditor({
    steps,
    hiddenSteps,
    onClose,
}: HiddenStepsEditorProps): JSX.Element {
    const formInfo = useFormEditorSelector((editorState) => editorState);

    const editorInfo = useSelector(
        (state: RootState) => state.genericEditor[idEditor]
    );
    const containerRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(
            reset(idEditor, {
                loadData: () => {
                    return { steps, size: formInfo.size } as any;
                },
                //Function to edit the initial state
                getInfoOnSuccess: () => {
                    return {
                        firstSection: 'FIRST',
                        sections: {
                            FIRST: {
                                id: 'FIRST',
                                steps: hiddenSteps,
                                name: '',
                                nextSection: null,
                            },
                        },
                        steps: steps,
                        uniqueSteps: {},
                    };
                },
                ...ReducerFunctions,
            } as EditorConfig<Form, any, any, any, any>)
        );
    }, []);

    return (
        <Dialog
            maxWidth={'90vw'}
            onClose={(): void => {
                onClose(
                    editorInfo.steps,
                    editorInfo.sections[editorInfo.firstSection].steps ?? []
                );
            }}
        >
            <IdGenericEditorContext.Provider value={idEditor}>
                <div className={styles.container} ref={containerRef}>
                    <Section<any, any, any, any, any>
                        stepMenuComponent={<StepMenu zIndex={1303} />}
                        multiSection={false}
                        editId={true}
                        stepMapper={FormStepMapper}
                        containerRef={containerRef}
                        stepMapperProps={undefined}
                    />
                </div>
            </IdGenericEditorContext.Provider>
        </Dialog>
    );
}
export default HiddenStepsEditor;
