import axiosInstance from '../../AxiosAPI';
import { Process } from '../../@Types/ProcessTypes/Process';

/**
 * Function that loads the tickets' list
 * @param idProject the id of the current project
 * @param idTicket the id of the ticket to fetch
 * @param queryString the users filters in query string from
 * @returns state of the list to display
 */
export const loadProcessHistoriesByIds = async (
    idProject: string,
    ids: string[]
): Promise<Process[]> => {
    const query = new URLSearchParams();
    query.set('ids', ids.join(','));
    const response = await axiosInstance.get(
        `/projects/${idProject}/processes/history?${query}`
    );

    return response.data;
};

export default {
    loadProcessHistoriesByIds,
};
