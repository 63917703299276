import styles from './EntityFilters.module.css';
import React, { useRef } from 'react';
import Search from '../../../../shared/Search/Search';
import { EntityValue } from '../../../../@Types/@Types';
import {
    search,
    setLoading,
} from '../../../../controllers/GenericMenuController/GenericMenuActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../utils/_store';
import GenericList from '../../../../shared/@Menus/GenericMenu/GenericList';
import {
    loadEntityValues,
    loadEntityValuesById,
} from '../../../../controllers/EntityValuesController/EntityValuesService';
import Avatar from '../../../../shared/Avatar/Avatar';
import EntityIcon from '../../../../shared/SmartIcons/EntityIcon';
const idMenu = 'FILTERS';
interface EntityFiltersProps {
    idEntity: string;
    /** The ids of the initially selected entityValues */
    initialEntityValues: EntityValue[];
    /** Function called when a entityValue is clicked on  */
    handleChange: Function;
    /** Tabs component to display after the search */
    tabsComponent: JSX.Element;
}
function EntityFilters({
    idEntity,
    handleChange,
    tabsComponent,
    initialEntityValues,
}: EntityFiltersProps): JSX.Element {
    const dispatch = useDispatch();
    const menuSearch = useSelector(
        (state: RootState) => state.genericMenu[idMenu]?.search
    );
    const entity = useSelector(
        (state: RootState) => state.site.entities[idEntity]
    );
    const listRef = useRef<HTMLDivElement>(null);

    if (!entity) return <div></div>;
    return (
        <React.Fragment>
            <div className={styles.searchContainer}>
                <Search
                    wait={500}
                    placeholder={'Buscar ' + entity.pluralName}
                    search={menuSearch}
                    handleLoadingSearch={(loading: boolean): void => {
                        dispatch(setLoading(idMenu, loading));
                    }}
                    handleSearch={(value: any): void => {
                        if (listRef.current) listRef.current.scrollTop = 0;
                        dispatch(search(idMenu, value));
                    }}
                />
            </div>
            {tabsComponent}
            <div className={styles.listContainer}>
                <GenericList<EntityValue, string>
                    idMenu={idMenu}
                    listRef={listRef}
                    dependencies={[idEntity]}
                    handleUpdate={handleChange as any}
                    initialElements={initialEntityValues}
                    elementsToOmit={[]}
                    multiple={true}
                    label={entity.name}
                    calcLbl={(entityValue): string =>
                        entityValue.values[entity.idLabelStep] ?? entity.name
                    }
                    loadInitialType={async (ids): Promise<EntityValue[]> =>
                        await loadEntityValuesById(entity._id, ids)
                    }
                    calcIcon={(entityValue): JSX.Element => (
                        <Avatar
                            lbl={
                                entityValue.values?.[entity.idLabelStep] ??
                                entity.name
                            }
                            size={30}
                            fontSize={30}
                        >
                            <EntityIcon
                                idEntity={entity._id}
                                fill="var(--primary1)"
                                style={{
                                    height: 21,
                                    width: 21,
                                }}
                            />
                        </Avatar>
                    )}
                    loadElements={async (
                        page: number,
                        pageSize: number,
                        search: string | undefined
                    ): Promise<EntityValue[]> => {
                        return await loadEntityValues(
                            entity,
                            page,
                            pageSize,
                            undefined,
                            undefined,
                            search
                        );
                    }}
                />
            </div>
        </React.Fragment>
    );
}

export default EntityFilters;
