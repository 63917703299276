import { MenuGroupedNotification } from '../../@Types/GroupedMenuNotification';
import * as Actions from './NotificationsMenuActions';

export interface NotificationsMenuState {
    order: Record<string, number>;
    /** The notifications */
    elements: MenuGroupedNotification[];
    /** The number of pages currently loaded in the list */
    page: number;
    /** Active if no more pages are available */
    noMoreElements: boolean;
    /** If loader is active */
    loading: boolean;
    /** If need to refresh */
    needReset: boolean;
    /** The filter state */
    filterState: NotificationFilterTypes;
}

export enum NotificationFilterTypes {
    ALL = 'ALL',
    UNSEEN = 'UNSEEN',
    ASSIGN = 'ASSIGN',
    COMMENT = 'COMMENT',
    CLIENT_REPLY = 'CLIENT_REPLY',
    TRANSFER = 'TRANSFER',
    ACTIVITY = 'ACTIVITY',
}

const initialState: NotificationsMenuState = {
    order: {},
    elements: [],
    page: 1,
    noMoreElements: false,
    loading: true,
    needReset: true,
    filterState: NotificationFilterTypes.ALL,
};

export const NotificationsMenuReducer = (
    state: NotificationsMenuState = initialState,
    action: any
): NotificationsMenuState => {
    if (Actions.reset.match(action)) {
        return {
            ...state,
            order: {},
            elements: [],
            page: 1,
            noMoreElements: false,
            loading: true,
            filterState: action.payload.filterState,
            needReset: true,
        };
    } else if (Actions.resetSuccess.match(action)) {
        return {
            ...state,
            loading: false,
            page: action.payload.page,
            order: action.payload.order,
            elements: action.payload.elements,
            noMoreElements: action.payload.noMoreElements,
            needReset: false,
        };
    } else if (Actions.setLoading.match(action)) {
        return {
            ...state,
            loading: action.payload,
        };
    } else if (Actions.getSuccess.match(action)) {
        return {
            ...state,
            loading: false,
            order: action.payload.order,
            elements: action.payload.elements,
            noMoreElements: action.payload.noMoreElements,
        };
    } else if (Actions.pageScroll.match(action)) {
        return { ...state, page: state.page + 1 };
    } else if (Actions.needReset.match(action)) {
        return { ...state, needReset: true };
    } else if (Actions.markTicketGroupsAsRead.match(action)) {
        const elements = state.elements.map((element) => {
            if (element.ticket._id === action.payload) {
                element.unread = 0;
                element.subs = element.subs.map((sub) => {
                    sub.seen = true;
                    return sub;
                });
            }
            return element;
        });
        return { ...state, elements };
    }
    return state;
};

export default NotificationsMenuReducer;
