import { createAction } from '@reduxjs/toolkit';
import { Agent, Area } from '../../../@Types/@Types';
import { AssignableAgent } from '../../../@Types/Agent';
export const Types = {
    RESET: 'TICKET_AGENTS/RESET',
    GET_SUCCESS: 'TICKET_AGENTS/GET_SUCCESS',
    PAGE_SCROLL: 'TICKET_AGENTS/NEW_PAGE_SCROLL',
    SELECT: 'TICKET_AGENTS/SELECT_ELEMENT',
    SEARCH: 'TICKET_AGENTS/SEARCH',
    LOADING: 'TICKET_AGENTS/LOADING',
    FILTER: 'TICKET_AGENTS/FILTER',
    SET_CHANGES: 'TICKET_AGENTS/SET_CHANGES',
    TOGGLE_FILTERS: 'TICKET_AGENTS/TOGGLE_FILTERS',
};

/**
 * Called when the agents page is first loaded, recives list of current agents to filter them out.
 */
export const resetAssign = createAction<Agent[]>(Types.RESET);

/**
 * Called to toggle the loader
 */
export const setLoading = createAction(
    Types.LOADING,
    function prepare(loading: boolean = true) {
        return {
            payload: loading,
        };
    }
);

/**
 * Called when the list needs to load more elements
 */
export const pageScroll = createAction(Types.PAGE_SCROLL);

/**
 * Called when the selectedElements change value
 */
export const selectElements = createAction<{
    assigned: Agent[];
    elements: AssignableAgent[];
}>(Types.SELECT);

/**
 * Called when the search value changes
 */
export const search = createAction<string>(Types.SEARCH);

/**
 * Called when the filter values change
 */
export const filter = createAction<Area | null>(Types.FILTER);

/**
 *  Called when the saga has sucessfully retrieved the lists elements
 */
export const getSuccess = createAction<{
    elements: AssignableAgent[];
    noMoreElements: boolean;
}>(Types.GET_SUCCESS);

/**
 * Updates the changes object
 */
export const setChanges = createAction<Record<string, boolean>>(
    Types.SET_CHANGES
);

/**
 * Toggles if showing or not the filters
 */
export const toggleFilters = createAction(Types.TOGGLE_FILTERS);
