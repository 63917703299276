import { MenuItem } from '@material-ui/core';

import ChatIcon from '../../../../../Icons/ChatIcon';
import NotificationIcon from '../../../../../Icons/NotificationIcon';
import RoundedSelect, {
    RoundedSelectProps,
} from '../../../../../shared/RoundedSelect/RoundedSelect';
import styles from './CommunicationTypeSelector.module.css';
import ActionTypes from '../../../../../constants/ActionTypes';

interface CommunicationTypeSelectorProps
    extends Omit<RoundedSelectProps, 'handleUpdate' | 'value'> {
    /** Currently selected clientProperty id */
    value: ActionTypes;
    /** function called when value changes */
    handleUpdate: (type: ActionTypes) => void;
}
const menuStyle = {
    paddingLeft: 0,
};

function CommunicationTypeSelector({
    value,

    handleUpdate,
    ...others
}: CommunicationTypeSelectorProps): JSX.Element {
    return (
        <RoundedSelect
            value={value}
            paddingLeft={0}
            handleUpdate={(event): void => {
                const value = event.target.value ?? '';
                handleUpdate(value);
            }}
            {...others}
        >
            <MenuItem style={menuStyle} value={ActionTypes.AUTO_REPLY}>
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <ChatIcon fill={'var(--secondary)'} />
                    </div>
                    Respuesta Automatica
                </div>
            </MenuItem>
            <MenuItem style={menuStyle} value={ActionTypes.NOTIFICATION}>
                <div className={styles.property}>
                    <div className={styles.notificationIcon}>
                        <NotificationIcon
                            fill={'var(--secondary)'}
                            contrast="white"
                        />
                    </div>
                    Notificación
                </div>
            </MenuItem>
        </RoundedSelect>
    );
}
export default CommunicationTypeSelector;
