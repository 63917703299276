import { useUpdateNodeInternals } from 'react-flow-renderer';
import Toggle from '../../../../../shared/Toggle/Toggle';
import styles from './CommunicationIntegration.module.css';
import { CommunicationData } from '../../../../../@Types/FlowTypes/NodeTypes/CommunicationNode';
import RoundedTextField from '../../../../../shared/RoundedTextField/RoundedTextField';
import IntegrationTypes, {
    IntegrationConfigurationTypes,
} from '../../../../../constants/IntegrationTypes';
import { IntegrationsApi } from '../../../../../controllers/IntegratrionsController/IntegrationsService';
import { useEffect } from 'react';
import IntegrationErkValuesPicker from '../../../../../shared/Integrations/IntegrationErkValuesPicker/IntegrationErkValuesPicker';
import produce from 'immer';
import { DraftContext } from '../../../../../@Types/Draft/DraftContext';

const PayloadContext: DraftContext = {
    ticket: true,
    client: true,
};

interface CommunicationIntegrationProps {
    nodeId: string;
    data: CommunicationData & {
        /** Function used to open the dialog  */
        openDialog: Function;
    };
    /** Function called when a value needs to update */
    handleUpdate: Function;
}

function CommunicationIntegration({
    data,
    nodeId,
    handleUpdate,
}: CommunicationIntegrationProps): JSX.Element {
    const updateNodeInternals = useUpdateNodeInternals();
    const { data: integrations } = IntegrationsApi.useLoadIntegrationsQuery({
        type: IntegrationTypes.DOCUMENT,
    });

    useEffect(() => {
        if (integrations && data.integration) {
            const integration = integrations.find(
                (integration) =>
                    integration.configuration.type ===
                        IntegrationConfigurationTypes.LAMBDA &&
                    integration.configuration.integrationKey ===
                        data.integration
            );
            if (
                integration &&
                !data.payload.integrations?.find(
                    (integ) => integ.idIntegration === integration._id
                )
            ) {
                handleUpdate({
                    ...data,
                    payload: {
                        ...data.payload,
                        integrations: [
                            {
                                idIntegration: integration._id,
                                values: {},
                            },
                        ],
                    },
                });
            }
        }
    }, [integrations, data.integration]);
    return (
        <div className={styles.content}>
            <div className={styles.toggleLabelContainer}>
                Integración:
                <div className={styles.toggleContainer}>
                    <Toggle
                        checked={data.integration !== undefined}
                        onChange={(checked: boolean): void => {
                            handleUpdate({
                                ...data,
                                integration: checked ? '' : undefined,
                                payload: { ...data.payload, integrations: [] },
                            });
                            setTimeout(() => updateNodeInternals(nodeId), 100);
                        }}
                    />
                </div>
            </div>
            {data.integration !== undefined && (
                <div className={styles.integrationTxtField}>
                    <RoundedTextField
                        label={'Integración'}
                        height="31px"
                        fullWidth={true}
                        value={data.integration}
                        onChange={(event): void => {
                            handleUpdate({
                                ...data,
                                integration: event.target.value,
                            });
                        }}
                    />
                </div>
            )}
            <div className={styles.valuesContainer}>
                {data.payload.integrations?.map(
                    ({ idIntegration, values }, index) => (
                        <IntegrationErkValuesPicker
                            values={values}
                            context={PayloadContext}
                            key={idIntegration}
                            idIntegration={idIntegration}
                            handleUpdate={(values): void => {
                                handleUpdate(
                                    produce(data, (data) => {
                                        if (!data.payload.integrations) return;
                                        data.payload.integrations[
                                            index
                                        ].values = values;
                                    })
                                );
                            }}
                        />
                    )
                )}
            </div>
        </div>
    );
}

export default CommunicationIntegration;
