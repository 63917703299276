import React, { useState } from 'react';
import styles from './CheckBoxStep.module.css';
import RoundedCheckBox from '../../RoundedCheckBox/RoundedCheckBox';
import { GCheckBox } from '../../../@Types/GenericFormSteps';
import RoundedTextField from '../../RoundedTextField/RoundedTextField';
import StepComponent, {
    GenericStepProps,
} from '../../GenericFormEditor/Step/Step';
import { calcStepWidth } from '../../GenericFormEditor/StepFunctions';
import { GLocation } from '../../../@Types/FormTypes/LocationTypes';
import AddStepBtn from '../../GenericFormEditor/AddStepBtn/AddStepBtn';
import Toggle from '../../Toggle/Toggle';

function CheckBoxStep<S extends GCheckBox, U, L extends GLocation>({
    editing,
    step,
    updateStep,
    size,
    level,
    calcSubLocation,
    ...others
}: GenericStepProps<S, U, L> & {
    calcSubLocation: (checked: boolean) => L;
}): JSX.Element {
    const [openId, setOpenId] = useState<string | undefined>(undefined);
    const mapSteps = (checked: boolean, steps: string[]): JSX.Element[] => {
        const baseLocation = calcSubLocation(checked);
        return steps.map((idStep: string, index) => {
            return (
                <StepComponent
                    {...others}
                    size={size}
                    level={level + 1}
                    onOpen={(): void => {
                        setOpenId(idStep);
                    }}
                    canMoveUp={index !== 0}
                    canMoveDown={index !== steps.length - 1}
                    location={{
                        ...baseLocation,
                        indexStep: index,
                    }}
                    key={checked + '' + index}
                    openId={openId}
                    idStep={idStep}
                />
            );
        });
    };

    if (editing) {
        return (
            <div className={styles.editingContainer}>
                <div className={styles.inputContainer}>
                    <RoundedTextField
                        label="Etiqueta"
                        value={step.label}
                        onChange={(e): void => {
                            updateStep({ ...step, label: e.target.value });
                        }}
                    ></RoundedTextField>
                </div>
                <RoundedTextField
                    label="Descripción"
                    value={step.description ?? ''}
                    onChange={(e): void => {
                        updateStep({
                            ...step,
                            description: e.target.value,
                        });
                    }}
                ></RoundedTextField>
                <div className={styles.isListContainer}>
                    <div className={styles.isListLbl}>Formato Lista:</div>
                    <div className={styles.toggleContainer}>
                        <Toggle
                            checked={step.isList === true}
                            onChange={(checked: boolean): void => {
                                updateStep({
                                    ...step,
                                    isList: checked,
                                });
                            }}
                        />
                    </div>
                </div>
                <div className={styles.subStepsLbl}>SubPasos:</div>
                <div className={styles.optionContainer}>
                    <div className={styles.bulletContainer}>
                        <div className={styles.bullet} />
                    </div>
                    <div className={styles.checkboxContainer}>
                        <RoundedCheckBox checked={false} cantEdit size={30} />{' '}
                        (Sin Seleccionar):
                    </div>
                </div>
                <div className={styles.stepsContainer}>
                    {mapSteps(false, step.uncheckedSteps ?? [])}
                    <div className={styles.addBtnContainer}>
                        <AddStepBtn
                            size="35px"
                            location={calcSubLocation(false)}
                            stepMenuComponent={others.stepMenuComponent}
                        />
                    </div>
                </div>
                <div className={styles.optionContainer}>
                    <div className={styles.bulletContainer}>
                        <div className={styles.bullet} />
                    </div>
                    <div className={styles.checkboxContainer}>
                        <RoundedCheckBox checked={true} cantEdit size={30} />{' '}
                        (Seleccionado):
                    </div>
                </div>
                <div className={styles.stepsContainer}>
                    {mapSteps(true, step.steps ?? [])}
                    <div className={styles.addBtnContainer}>
                        <AddStepBtn
                            size="35px"
                            location={calcSubLocation(true)}
                            stepMenuComponent={others.stepMenuComponent}
                        />
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div
                style={{
                    width: calcStepWidth(step.size, size),
                    maxWidth: '100%',
                    marginBottom: step.description ? '0px' : '5px',
                }}
            >
                <div
                    className={styles.labelCheckBoxContainer}
                    style={{
                        alignItems: step.isList ? 'start' : 'center',
                        height: step.size < 4 ? 31 : undefined,
                    }}
                >
                    {!step.isList && (
                        <div
                            className={
                                step.size < 4
                                    ? styles.checkboxLblOverflow
                                    : styles.checkboxLbl
                            }
                            style={{
                                maxWidth: calcStepWidth(step.size, size) - 45,
                                marginRight: 10,
                            }}
                        >
                            {step.label}
                            {step.required ? ' *' : ''}:
                        </div>
                    )}
                    <RoundedCheckBox
                        padding="0px"
                        size="1.6rem"
                        checked={true}
                    ></RoundedCheckBox>
                    {step.isList && (
                        <div
                            className={
                                step.size < 4
                                    ? styles.checkboxLblOverflow
                                    : styles.checkboxLbl
                            }
                            style={{
                                maxWidth: calcStepWidth(step.size, size) - 45,
                                marginLeft: 10,
                            }}
                        >
                            {step.label}
                            {step.required ? ' *' : ''}
                        </div>
                    )}
                </div>
                {step.description && (
                    <p className={styles.descriptionPar}>{step.description}</p>
                )}
            </div>
        );
    }
}

export default React.memo(CheckBoxStep, (prev, next) => {
    const { step, stepEqualityChecker } = prev;
    const { step: newStep } = next;
    if (
        step.size !== newStep.size ||
        step.label !== newStep.label ||
        step.required !== newStep.required ||
        step.description !== newStep.description ||
        step.steps !== newStep.steps ||
        step.isList !== newStep.isList ||
        step.uncheckedSteps !== newStep.uncheckedSteps
    ) {
        return false;
    }
    return stepEqualityChecker?.(prev, next) ?? true;
}) as typeof CheckBoxStep;
