import { useSelector } from 'react-redux';
import { Process } from '../../@Types/ProcessTypes/Process';
import { RootState } from '../../utils/_store';
import RoundedProcessPicker from '../@Pickers/RoundedProcessPicker/RoundedProcessPicker';
import styles from './AssignProcess.module.css';
import { AssignProcessRoles } from '../../@Types/FlowTypes/NodeTypes/TransformationNode';
import ActivityPersonaTypes from '../../constants/ActivityTypes/ActivityPersonaTypes';
import RoundedAgentPicker from '../@Pickers/RoundedAgentPicker/RoundedAgentPicker';
import RoundedAreaPicker from '../@Pickers/RoundedAreaPicker/RoundedAreaPicker';
import React, { useMemo } from 'react';
import RoundedEntityPicker from '../@Pickers/RoundedEntityPicker/RoundedEntityPicker';
import EntityPropertyTypes from '../../constants/EntityPropertyTypes';
import RoundedEntityPropertyPicker from '../@Pickers/RoundedEntityPropertyPicker/RoundedEntityPropertyPicker';
import FormRenderer from '../FormRenderer/FormRenderer';
import { useCurrentProject } from '../../hooks';
import { isIntegration } from '../../@Types/Integration';

interface AssignProcessProps {
    /** Process to display, attached in backend on load */
    process: Process | undefined;
    roles: AssignProcessRoles | undefined;
    values?: Record<string, any>;
    handleUpdate(process: Process | undefined, roles: AssignProcessRoles): void;
    setSubmit: (submit: () => Promise<void | Record<string, any>>) => void;
}

function AssignProcess({
    process,
    roles = {},
    values = {},
    setSubmit,
    handleUpdate,
}: AssignProcessProps): JSX.Element {
    const entities = useSelector((state: RootState) => state.site.entities);
    const project = useCurrentProject();

    if (!project) return <div></div>;
    const allowedEntities = useMemo(() => {
        return project.entities
            .filter((entity) =>
                Object.values(
                    entities[entity.idEntity ?? '']?.steps ?? {}
                ).find((step) => step.type === EntityPropertyTypes.AGENTPICKER)
            )
            .map((entity) => entity.idEntity);
    }, [project, entities]);

    return (
        <div className={styles.processContainer}>
            <div className={styles.processPicker}>
                <RoundedProcessPicker
                    height="40px"
                    label="Proceso"
                    handleUpdate={([process]): void => {
                        handleUpdate(process, {});
                    }}
                    value={process ? [process] : []}
                    idProject={project._id}
                />
            </div>
            <div className={styles.processRolesLbl}>Roles:</div>
            {!process && (
                <div className={styles.processRolesInfo}>
                    Seleccione un proceso
                </div>
            )}
            {process && process.roles.length == 0 && (
                <div className={styles.processRolesInfo}>
                    El proceso no tiene roles
                </div>
            )}
            <div className={styles.processRolesContainer}>
                {process?.roles.map((role) => {
                    const transformationRole = roles?.[role.id];
                    switch (role.type) {
                        case ActivityPersonaTypes.AGENT: {
                            return (
                                <div
                                    className={styles.processRolePicker}
                                    key={role.id}
                                >
                                    <RoundedAgentPicker
                                        autoWidth
                                        minWidth={260}
                                        label={role.name}
                                        multiple
                                        value={
                                            transformationRole?.type ===
                                                ActivityPersonaTypes.AGENT &&
                                            transformationRole.agents
                                                ? transformationRole.agents
                                                : []
                                        }
                                        handleUpdate={(agents: any): void => {
                                            handleUpdate(process, {
                                                ...roles,
                                                [role.id]: {
                                                    type: ActivityPersonaTypes.AGENT,
                                                    agents,
                                                },
                                            });
                                        }}
                                    />
                                </div>
                            );
                        }
                        case ActivityPersonaTypes.AREA: {
                            return (
                                <div
                                    className={styles.processRolePicker}
                                    key={role.id}
                                >
                                    <RoundedAreaPicker
                                        minWidth={220}
                                        label={role.name}
                                        autoWidth
                                        idProject={project._id}
                                        multiple={false}
                                        value={
                                            transformationRole?.type ===
                                                ActivityPersonaTypes.AREA &&
                                            transformationRole.idArea
                                                ? ([
                                                      transformationRole.idArea,
                                                  ] as string[])
                                                : []
                                        }
                                        handleUpdate={([area]: any): void => {
                                            handleUpdate(process, {
                                                ...roles,
                                                [role.id]: {
                                                    type: ActivityPersonaTypes.AREA,
                                                    idArea: area,
                                                },
                                            });
                                        }}
                                    />
                                </div>
                            );
                        }
                        case ActivityPersonaTypes.ENTITYVALUE: {
                            return (
                                <React.Fragment>
                                    <div className={styles.entityValueRoleLbl}>
                                        {role.name}:
                                    </div>
                                    <div
                                        className={
                                            styles.entityValueRole + ' flexbox'
                                        }
                                        key={role.id}
                                    >
                                        <div
                                            className={styles.entityValuePicker}
                                        >
                                            <RoundedEntityPicker
                                                minWidth={220}
                                                autoWidth
                                                allowedEntities={
                                                    allowedEntities
                                                }
                                                multiple={false}
                                                value={
                                                    transformationRole?.type ===
                                                        ActivityPersonaTypes.ENTITYVALUE &&
                                                    transformationRole.idEntity
                                                        ? ([
                                                              transformationRole.idEntity,
                                                          ] as string[])
                                                        : []
                                                }
                                                handleUpdate={([
                                                    entity,
                                                ]: any): void => {
                                                    handleUpdate(process, {
                                                        ...roles,
                                                        [role.id]: {
                                                            type: ActivityPersonaTypes.ENTITYVALUE,
                                                            idEntity: entity,
                                                            idProperty: '',
                                                        },
                                                    });
                                                }}
                                            />
                                        </div>
                                        {transformationRole?.type ===
                                            ActivityPersonaTypes.ENTITYVALUE &&
                                            transformationRole.idEntity && (
                                                <React.Fragment>
                                                    <div
                                                        className={
                                                            styles.entityValueRolePicker
                                                        }
                                                    >
                                                        <RoundedEntityPropertyPicker
                                                            minWidth={220}
                                                            propertyTypes={[
                                                                EntityPropertyTypes.AGENTPICKER,
                                                            ]}
                                                            height="31px"
                                                            label="Propiedad"
                                                            containerMargin="0px"
                                                            autoWidth
                                                            idEntity={
                                                                typeof transformationRole.idEntity ===
                                                                'string'
                                                                    ? transformationRole.idEntity
                                                                    : transformationRole
                                                                          .idEntity
                                                                          ._id
                                                            }
                                                            value={
                                                                transformationRole.idProperty
                                                            }
                                                            handleUpdate={(
                                                                property
                                                            ): void => {
                                                                if (
                                                                    !property ||
                                                                    isIntegration(
                                                                        property
                                                                    )
                                                                )
                                                                    return;
                                                                handleUpdate(
                                                                    process,
                                                                    {
                                                                        ...roles,
                                                                        [role.id]:
                                                                            {
                                                                                ...transformationRole,
                                                                                idProperty:
                                                                                    property.id,
                                                                            },
                                                                    }
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    {transformationRole.idProperty && (
                                                        <div
                                                            className={
                                                                styles.entityValuePicker
                                                            }
                                                        >
                                                            <RoundedAgentPicker
                                                                autoWidth
                                                                minWidth={220}
                                                                label={
                                                                    'En caso de no existir'
                                                                }
                                                                multiple
                                                                value={
                                                                    transformationRole?.type ===
                                                                        ActivityPersonaTypes.ENTITYVALUE &&
                                                                    transformationRole.fallback
                                                                        ? transformationRole.fallback
                                                                        : []
                                                                }
                                                                handleUpdate={(
                                                                    fallback
                                                                ): void => {
                                                                    handleUpdate(
                                                                        process,
                                                                        {
                                                                            ...roles,
                                                                            [role.id]:
                                                                                {
                                                                                    ...transformationRole,
                                                                                    fallback,
                                                                                },
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )}
                                    </div>
                                </React.Fragment>
                            );
                        }
                        default:
                            return <div key={role.id}></div>;
                    }
                })}
            </div>
            {process?.values && process?.values.parameters && (
                <React.Fragment>
                    <div className={styles.processRolesLbl}>
                        Configuraciónes:
                    </div>
                    <div className={styles.formContainer}>
                        <FormRenderer
                            form={{
                                size: {
                                    blockNum: 1,
                                    blockSize: 320,
                                    spacingSize: 0,
                                },
                                steps: process.values.steps,
                            }}
                            values={values}
                            customSubmitBtns={(): JSX.Element => <></>}
                            rootSteps={process.values.parameters}
                            setSubmit={setSubmit}
                        />
                    </div>
                </React.Fragment>
            )}
        </div>
    );
}
export default AssignProcess;
