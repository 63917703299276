import { IconProps } from './@IconTypes';

function ParameterIcon({
    className,
    style,
    fill = '#3d5a7f',
}: IconProps): JSX.Element {
    return (
        <svg
            style={style}
            className={className}
            version="1.1"
            fill="none"
            viewBox="0 0 619 379"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M202.478 246.385C194.362 246.394 186.371 244.373 179.231 240.506C172.091 236.639 166.026 231.049 161.586 224.241C157.146 217.434 154.471 209.624 153.803 201.519C153.136 193.414 154.496 185.27 157.762 177.825C161.029 170.38 166.097 163.869 172.508 158.882C178.919 153.895 186.47 150.589 194.478 149.263C202.486 147.938 210.698 148.635 218.369 151.292C226.04 153.949 232.928 158.481 238.41 164.478H289.96C276.62 129.083 242.435 103.831 202.478 103.831C150.929 103.831 109 145.842 109 197.489C109 249.135 150.929 291.169 202.478 291.169C242.322 291.169 276.422 266.057 289.848 230.814H238.136C233.584 235.731 228.066 239.652 221.93 242.331C215.794 245.011 209.172 246.391 202.478 246.385Z"
                fill={fill}
            />
            <path
                d="M457.481 108.489V119.42C444.622 110.42 426.444 103.831 408.483 103.831C368.508 103.831 334.372 129.074 321.001 164.469H372.546C379.175 157.214 387.834 152.131 397.392 149.884C406.95 147.637 416.962 148.331 426.12 151.875C435.278 155.418 443.156 161.647 448.726 169.747C454.296 177.848 457.298 187.443 457.34 197.279C457.382 207.116 454.462 216.736 448.961 224.884C443.461 233.032 435.636 239.328 426.508 242.95C417.381 246.572 407.375 247.351 397.799 245.186C388.222 243.021 379.52 238.012 372.829 230.814H321.113C334.538 266.057 368.638 291.169 408.483 291.169C426.02 291.098 443.116 285.658 457.481 275.58V288.181H504V108.489H457.481Z"
                fill={fill}
            />
            <path
                d="M435.948 197.295C435.945 203.402 433.919 209.334 430.189 214.163C426.458 218.992 421.234 222.443 415.334 223.976C409.435 225.509 403.195 225.037 397.592 222.634C391.989 220.231 387.341 216.032 384.376 210.697L384.242 210.463H226.863L226.737 210.697C223.788 216.092 219.126 220.345 213.49 222.782C207.854 225.219 201.567 225.701 195.627 224.15C189.687 222.6 184.434 219.106 180.701 214.223C176.968 209.341 174.97 203.349 175.023 197.198C175.075 191.048 177.177 185.092 180.993 180.274C184.809 175.457 190.122 172.054 196.088 170.607C202.054 169.159 208.331 169.749 213.924 172.283C219.518 174.817 224.106 179.15 226.961 184.595L227.092 184.833H383.869L383.995 184.595C386.838 179.115 391.434 174.75 397.047 172.199C402.66 169.648 408.966 169.058 414.953 170.523C420.941 171.989 426.264 175.426 430.071 180.283C433.878 185.14 435.947 191.137 435.948 197.313V197.295Z"
                fill="#ED6C4D"
            />
            <path
                d="M613.92 199.381V186.17C602.06 186.17 593.786 183.685 589.098 178.713C584.339 173.741 581.96 165.432 581.96 153.784V119.693C581.96 109.892 581.037 101.405 579.19 94.2315C577.273 87.0582 574.183 81.1278 569.922 76.4403C565.661 71.7528 559.979 68.2727 552.876 66C545.774 63.7273 537.003 62.5909 526.562 62.5909V83.4716C534.801 83.4716 541.3 84.75 546.058 87.3068C550.746 89.8636 554.084 93.8409 556.072 99.2386C557.99 104.565 558.949 111.384 558.949 119.693V162.307C558.949 167.847 559.695 172.889 561.186 177.435C562.607 181.98 565.27 185.886 569.176 189.153C573.082 192.42 578.658 194.942 585.902 196.717C593.075 198.493 602.415 199.381 613.92 199.381ZM526.562 335.318C537.003 335.318 545.774 334.182 552.876 331.909C559.979 329.636 565.661 326.156 569.922 321.469C574.183 316.781 577.273 310.851 579.19 303.678C581.037 296.504 581.96 288.017 581.96 278.216V244.125C581.96 232.477 584.339 224.168 589.098 219.196C593.786 214.224 602.06 211.739 613.92 211.739V198.528C602.415 198.528 593.075 199.416 585.902 201.192C578.658 202.967 573.082 205.489 569.176 208.756C565.27 212.023 562.607 215.929 561.186 220.474C559.695 225.02 558.949 230.062 558.949 235.602V278.216C558.949 286.526 557.99 293.344 556.072 298.67C554.084 303.997 550.746 307.939 546.058 310.496C541.3 313.124 534.801 314.438 526.562 314.438V335.318ZM613.92 211.739V186.17H588.778V211.739H613.92Z"
                fill={fill}
            />
            <path
                d="M11.0795 199.381V186.17C22.9403 186.17 31.2145 183.685 35.902 178.713C40.6605 173.741 43.0398 165.432 43.0398 153.784V119.693C43.0398 109.892 43.9631 101.405 45.8097 94.2315C47.7273 87.0582 50.8168 81.1278 55.0781 76.4403C59.3395 71.7528 65.0213 68.2727 72.1236 66C79.2259 63.7273 87.9972 62.5909 98.4375 62.5909V83.4716C90.1989 83.4716 83.7003 84.75 78.9418 87.3068C74.2543 89.8636 70.9162 93.8409 68.9276 99.2386C67.0099 104.565 66.0511 111.384 66.0511 119.693V162.307C66.0511 167.847 65.3054 172.889 63.8139 177.435C62.3935 181.98 59.7301 185.886 55.8239 189.153C51.9176 192.42 46.3423 194.942 39.098 196.717C31.9247 198.493 22.5852 199.381 11.0795 199.381ZM98.4375 335.318C87.9972 335.318 79.2259 334.182 72.1236 331.909C65.0213 329.636 59.3395 326.156 55.0781 321.469C50.8168 316.781 47.7273 310.851 45.8097 303.678C43.9631 296.504 43.0398 288.017 43.0398 278.216V244.125C43.0398 232.477 40.6605 224.168 35.902 219.196C31.2145 214.224 22.9403 211.739 11.0795 211.739V198.528C22.5852 198.528 31.9247 199.416 39.098 201.192C46.3423 202.967 51.9176 205.489 55.8239 208.756C59.7301 212.023 62.3935 215.929 63.8139 220.474C65.3054 225.02 66.0511 230.062 66.0511 235.602V278.216C66.0511 286.526 67.0099 293.344 68.9276 298.67C70.9162 303.997 74.2543 307.939 78.9418 310.496C83.7003 313.124 90.1989 314.438 98.4375 314.438V335.318ZM11.0795 211.739V186.17H36.2216V211.739H11.0795Z"
                fill={fill}
            />
        </svg>
    );
}
export default ParameterIcon;
