import { Payload } from '../../../../../../@Types/Payload';
import Dialog from '../../../../../../shared/Dialog/Dialog';
import RoundedTextField from '../../../../../../shared/RoundedTextField/RoundedTextField';
import styles from './RevisionDialog.module.css';
import RoundedButton from '../../../../../../shared/RoundedButton/RoundedButton';
import { useState } from 'react';
import ActionsService from '../../../../../../controllers/TicketsController/Actions/ActionsService';
import { Ticket } from '../../../../../../@Types/@Types';
import { refreshCurrent } from '../../../../../../controllers/TicketsController/TicketsActions';
import {
    PayloadEditorActions,
    usePayloadEditorDispatch,
} from '../../../../../../controllers/PayloadEditorController/PayloadEditorSlice';
import PayloadEditor from '../../../../../../shared/TextEditor/PayloadEditor';

interface RevisionDialogProps {
    ticket: Ticket;
    payload: Payload | undefined;
    onClose: () => void;
}
function RevisionDialog({
    onClose,
    payload,
    ticket,
}: RevisionDialogProps): JSX.Element {
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState<any>('');
    const dispatch = usePayloadEditorDispatch();
    const handleSend = async (): Promise<void> => {
        setLoading(true);
        try {
            const payload: Payload | null = await dispatch(
                PayloadEditorActions.calcPayload({
                    required: true,
                    idEditor: 'PreReply',
                }) as any
            ).unwrap();
            if (!payload) return;
            await ActionsService.preReplyRevision(
                ticket.idProject,
                ticket._id,
                payload,
                comment,
                {}
            );
            dispatch(refreshCurrent(true));
            onClose();
        } catch (error) {
            console.error(error);
        }

        setLoading(false);
    };
    return (
        <Dialog onClose={onClose} maxWidth={'90vw'}>
            <div className={styles.container}>
                <div className={styles.header}>
                    Solicitud de Cambios al Borrador
                </div>
                <div className={styles.editorContainer}>
                    <PayloadEditor
                        idEditor="PreReply"
                        placeholder={'Editar'}
                        payload={payload}
                    />
                </div>
                <div className={styles.commentContainer}>
                    <RoundedTextField
                        label="Observaciones"
                        value={comment}
                        onChange={(e): void => {
                            setComment(e.target.value);
                        }}
                        multiline
                        minRows={4}
                    />
                </div>
                <div className={styles.buttonsContainer}>
                    <RoundedButton
                        text={'Cancelar'}
                        onClick={onClose}
                        disabled={loading}
                        backgroundColor={'var(--defaultGrey)'}
                        color={'var(--accent)'}
                        fontSize="20px"
                        padding="4px 14px"
                        height={30}
                    />
                    <RoundedButton
                        text={'Solicitar Cambios'}
                        loading={loading}
                        onClick={handleSend}
                        fontSize="20px"
                        padding="4px 14px"
                        backgroundColor="var(--error)"
                        height={30}
                    />
                </div>
            </div>
        </Dialog>
    );
}

export default RevisionDialog;
