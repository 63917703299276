import React, { useEffect, useState } from 'react';
import { Agent, Ticket } from '../../../../../../../@Types/@Types';
import { RootState } from '../../../../../../../utils/_store';
import styles from './TransferAgent.module.css';
import AgentComponent from './Agent/Agent';
import { useSelector, useDispatch } from 'react-redux';
import {
    pageScroll,
    selectElement,
} from '../../../../../../../controllers/TicketsController/TransferAgent/AgentsActions';
import List from '../../../../../../../shared/MenuList/MenuList';
import RoundedButton from '../../../../../../../shared/RoundedButton/RoundedButton';
import ActionsService from '../../../../../../../controllers/TicketsController/Actions/ActionsService';
import TransferDialog from '../TransferDialog/TransferDialog';
import { RouterProps, withRouter } from 'react-router-dom';
import { appendAction } from '../../../../../../../controllers/TicketsController/Actions/ActionsActions';
import VanillaToasts from '../../../../../../../shared/Toast/Toast';
interface TransferAgentProps {
    /** List ref to scroll to top from parent */
    listRef: any;
    /** Function to call to close the menu  */
    handleClose: Function;
    /** The currently selected Ticket */
    selectedElement: Ticket;
}
function TransferAgent({
    history,
    listRef,
    handleClose,
    selectedElement,
}: TransferAgentProps & RouterProps): JSX.Element {
    const dispatch = useDispatch();
    const pageInfo = useSelector(
        (state: RootState) => state.ticketTransferAgents
    );
    const siteInfo = useSelector((state: RootState) => state.site);
    const [showConfirm, setShowConfirm] = useState<Agent | undefined>(
        undefined
    );

    useEffect(() => {
        if (listRef?.current?.scrollTop) {
            listRef.current.scrollTop = 0;
        }
    }, []);

    return (
        <div className={styles.container}>
            {showConfirm !== undefined && (
                <TransferDialog
                    onTransfer={async (
                        {
                            element,
                            removeAll,
                        }: { element: Agent; removeAll: boolean },
                        setLoading: Function
                    ): Promise<void> => {
                        if (siteInfo.idProject) {
                            try {
                                setLoading(true);
                                const action =
                                    await ActionsService.transferAgent(
                                        siteInfo.idProject,
                                        selectedElement._id,
                                        element._id,
                                        removeAll
                                    );
                                dispatch(appendAction(action));
                                handleClose();
                                history.push({
                                    pathname: `/${siteInfo.idProject}/tickets`,
                                    search: history.location.search,
                                });
                            } catch (error) {
                                VanillaToasts.create({
                                    title: 'Error al transferir a el agente',
                                    text: error,
                                    type: 'error',
                                    timeout: 3000,
                                });
                                console.error(error);
                                setLoading(false);
                            }
                        }
                    }}
                    element={showConfirm}
                    title="Transferir a Agente"
                    elementName={showConfirm.name}
                    onClose={(): void => {
                        setShowConfirm(undefined);
                    }}
                />
            )}
            <List
                noMoreElements={pageInfo.noMoreElements}
                listRef={listRef}
                onPageScroll={(): void => {
                    dispatch(pageScroll());
                }}
                loading={pageInfo.loading}
                elements={pageInfo.elements}
            >
                {pageInfo.elements.map(
                    (agent: Agent, index: number): JSX.Element => (
                        <AgentComponent
                            first={index === 0}
                            agent={agent}
                            key={index}
                            handleClick={(): void => {
                                dispatch(selectElement(index));
                            }}
                            selected={pageInfo.selectedElement === index}
                        />
                    )
                )}
                {pageInfo.elements.length === 0 && (
                    <div className={styles.emptyContainer}>No hay agentes</div>
                )}
            </List>
            {pageInfo.elements.length !== 0 && (
                <div className={styles.transferBtnContainer}>
                    <RoundedButton
                        text="Transferir"
                        onClick={(): void => {
                            if (pageInfo.selectedElement !== undefined) {
                                setShowConfirm(
                                    pageInfo.elements[pageInfo.selectedElement]
                                );
                            }
                        }}
                        borderRadius={3}
                        disabled={pageInfo.selectedElement === undefined}
                    ></RoundedButton>
                </div>
            )}
        </div>
    );
}
export default withRouter((props: any) => <TransferAgent {...props} />);
