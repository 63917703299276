import { useSelector } from 'react-redux';
import { RootState } from '../../utils/_store';
import IconTypes from '../../constants/IconTypes';
import SmartIcon, { SmartIconProps } from './SmartIcon';

export interface EntityIconProps extends Omit<SmartIconProps, 'icon'> {
    idEntity: string | null;
}

function EntityIcon({ idEntity, ...others }: EntityIconProps): JSX.Element {
    const icon = useSelector(
        (state: RootState) => state.site.entities[idEntity ?? '']?.icon
    );

    return <SmartIcon icon={icon ?? IconTypes.GENERIC} {...others} />;
}
export default EntityIcon;
