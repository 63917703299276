import { useEffect, useRef, useState } from 'react';
import styles from './ContentList.module.css';
import ContentCard from './ContentCard/ContentCard';
import TicketTitle from './TicketTitle/TicketTitle';
import { useSelector, useDispatch } from 'react-redux';
import ActionTypes, { Interactions } from '../../../../constants/ActionTypes';
import { setShowCBRPosVenta } from '../../../../controllers/TicketsController/Actions/ActionsActions';
import Loader from '../../../../shared/Loader/Loader';
import Action from '../Action/Action';
import { RootState } from '../../../../utils/_store';
import { Ticket } from '../../../../@Types/TicketTypes/Ticket';
import { setImgMaxWidth } from '../../../../controllers/TicketsController/Actions/ActionsActions';
import { useMediaQuery } from '@material-ui/core';
import Types from '../../../../constants/ActionTypes';
import React from 'react';
import FormPostView from '../../../../custom/Construction/CBRMenu/PosVenta/PosVenta';
import Dialog from '../../../../shared/Dialog/Dialog';
import Activity from '../Activity/Activity';

interface ContentListProps {
    /** If the list should display the payload on the top. */
    showPayload?: boolean;
    /** The currently displayed ticket */
    selectedElement: Ticket;
    /** If the layout is mobile */
    layoutIsMobile?: boolean;
    /** If the user can comment the ticket */
    canComment: boolean;
}

function ContentList({
    canComment,
    showPayload = false,
    selectedElement,
    layoutIsMobile,
}: ContentListProps): JSX.Element {
    const dispatch = useDispatch();
    const actions = useSelector(
        (state: RootState) => state.ticketActions.elements
    );
    const loading = useSelector(
        (state: RootState) => state.ticketActions.loading
    );
    const showCBRPosVenta = useSelector(
        (state: RootState) => state.ticketActions.showCBRPosVenta
    );
    const user = useSelector((state: RootState) => state.site.user);
    const [interactions, setInteractions] = useState(false);
    /** TODO Possibly open and close the top of the ticket? */
    const [open] = useState(true);
    const listRef = useRef<HTMLDivElement>(null);
    const xsm = useMediaQuery('(max-width:576px)', {
        noSsr: true,
    });
    if (!user) {
        return <div></div>;
    }
    useEffect(() => {
        setInteractions(false);
    }, [selectedElement._id]);

    /** Calcs the maxwidth of an image */
    const handleResize = (): void => {
        if (listRef.current) {
            dispatch(
                setImgMaxWidth(
                    listRef?.current?.offsetWidth * (layoutIsMobile ? 0.8 : 0.7)
                )
            );
        }
    };

    useEffect(() => {
        /** On load add a resize listener to calc the currentBreakpoint */
        window.addEventListener('resize', handleResize);
        handleResize();
        return (): void => {
            window.removeEventListener('resize', handleResize);
        };
    }, [listRef.current]);

    const mapElements = (): JSX.Element => {
        const activities =
            selectedElement.process?.current
                .map((activity) => (
                    <Activity
                        key={activity.path + '-' + activity.idActivity}
                        current={activity}
                        ticket={selectedElement}
                    />
                ))
                .filter((activity) => activity !== null) ?? [];

        return (
            <React.Fragment>
                {actions
                    .filter((element) =>
                        interactions
                            ? Interactions.includes(element.type) ||
                              element.type === ActionTypes.CREATE
                            : true
                    )
                    .map((element) => (
                        <Action
                            key={element._id}
                            user={user}
                            element={element}
                            ticket={selectedElement}
                            xsm={xsm}
                        />
                    ))}
                {activities}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {showCBRPosVenta && (
                <Dialog
                    maxWidth={'90vw'}
                    onClose={(): void => {
                        dispatch(setShowCBRPosVenta(false));
                    }}
                >
                    <FormPostView
                        values={selectedElement.CBR.formValues}
                        posventaCaseNum={selectedElement.CBR.posventaCaseNum}
                    />
                </Dialog>
            )}
            <div className={styles.container}>
                {!layoutIsMobile && (
                    <div
                        className={styles.headerContainer}
                        style={{
                            transition: 'max-height 0.5s ease-out',
                            maxHeight: open ? 500 : 0,
                        }}
                    >
                        <TicketTitle
                            selectedElement={selectedElement}
                            setInteractions={setInteractions}
                            interactions={interactions}
                        />
                        <ContentCard content={selectedElement.content} />
                    </div>
                )}
                <div className={styles.listContainer} ref={listRef}>
                    {loading && listRef.current && <Loader zIndex={1} />}
                    {!loading && (
                        <div className={styles.listCont}>
                            <div
                                className={styles.listContCont}
                                id="actions-list"
                                data-testid="actions_list_container"
                            >
                                {showPayload && (
                                    <Action
                                        user={user}
                                        element={{
                                            type: Types.CLIENT_REPLY,
                                            _id: 'CONTENT',
                                            creation_date:
                                                selectedElement.creation_date,
                                            idTicket: selectedElement._id,
                                            idProject:
                                                selectedElement.idProject,
                                            payload: selectedElement.content,
                                        }}
                                        key={0}
                                        xsm={xsm}
                                        ticket={selectedElement}
                                    />
                                )}
                                {mapElements()}
                                <div
                                    style={{ height: canComment ? 55 : 25 }}
                                ></div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}
export default ContentList;
