import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles(() => ({
    root: {
        color: 'var(--secondary)',
        '& .Erk-MuiSlider-markLabel': {
            fontSize: '0.75rem',
            lineHeight: 0.6,
        },
    },
}));

interface SliderProps {}

export function SliderComponent({ ...others }: SliderProps | any): JSX.Element {
    const classes = useStyles();

    return (
        <Slider
            classes={classes}
            defaultValue={20}
            aria-labelledby="discrete-slider-custom"
            // valueLabelDisplay="auto"
            step={null}
            track={false}
            {...others}
        />
    );
}
export default SliderComponent;
