import styles from './Classification.module.css';
import Classifier from './Classifier/Classifier';
import { Classification as ClassificationType } from '../../../../../../@Types/Action';
interface ClassificationProps {
    /** Array of classifiers made by the same user*/
    classifiers: ClassificationType[];
}
/**
 * Component that displays multiple classification actions, they are grouped in the service if they
 * were made by the same agent given a timespan 1
 */
function Classification({ classifiers }: ClassificationProps): JSX.Element {
    return (
        <div className={styles.container}>
            {classifiers.map((classifier, index) => (
                <Classifier
                    key={index}
                    idRoot={classifier.idRoot}
                    idValue={classifier.idValue}
                    idOriginal={classifier.idOriginal}
                />
            ))}
        </div>
    );
}

export default Classification;
