import styles from './MoreActionsMenu.module.css';
import {
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Ticket } from '../../../../../../@Types/@Types';
import { RootState } from '../../../../../../utils/_store';
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import ConfirmDialog from '../../../../../../shared/ConfirmDialog/ConfirmDialog';
import TicketsService from '../../../../../../controllers/TicketsController/TicketsService';
import { refresh } from '../../../../../../controllers/TicketsController/TicketsActions';
import { checkAdmin } from '../../../../../../utils/PermissionsFunctions';
import TicketSummary from './TicketSummary';

interface MoreActionsMenuProps {
    /** The currently selected Ticket */
    ticket: Ticket;
}

function MoreActionsMenu({ ticket }: MoreActionsMenuProps): JSX.Element {
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [confirmDelete, setConfirmDelete] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        if (disabled) return;
        setAnchorEl(null);
    };
    const siteInfo = useSelector((state: RootState) => state.site);

    const actions = [];

    if (siteInfo.organization?.idOrganization === 'melendez') {
        actions.push(
            <MenuItem
                onClick={(): void => {
                    handleClose();
                    window.open(
                        `${process.env.REACT_APP_PREFIX}${siteInfo.organization?.idOrganization}.clients.${process.env.REACT_APP_DOMAIN}/trace/${ticket._id}`,
                        '_blank'
                    );
                }}
                key="TRACEBTN"
            >
                <ListItemIcon style={{ minWidth: 35 }}>
                    <img
                        alt={'Interaction'}
                        className={styles.interactionImg}
                        src={'/media/icons/chat.svg'}
                    ></img>
                </ListItemIcon>
                <ListItemText primary="Generar Resumen" />
            </MenuItem>
        );
    } else if (siteInfo.organization?.idOrganization !== 'melendez') {
        actions.push(
            <TicketSummary
                ticket={ticket}
                key="SUMMARY"
                handleClose={(): void => {
                    setDisabled(false);
                    handleClose();
                }}
                handleDisableClose={(): void => {
                    setDisabled(true);
                }}
            />
        );
    }

    if (
        checkAdmin(siteInfo.user) ||
        siteInfo.user?.email === 'german.garzon@sinco.com.co'
    ) {
        actions.push(
            <MenuItem
                onClick={(): void => {
                    handleClose();
                    setConfirmDelete(true);
                }}
                key="DELETEBTN"
            >
                <ListItemIcon style={{ minWidth: 35, fontSize: 24 }}>
                    <DeleteRoundedIcon
                        fontSize="inherit"
                        style={{ color: 'var(--greyBtn)' }}
                    />
                </ListItemIcon>
                <ListItemText primary="Borrar Caso" />
            </MenuItem>
        );
    }

    if (actions.length > 0) {
        return (
            <React.Fragment>
                {confirmDelete && (
                    <ConfirmDialog
                        onConfirm={async (
                            element: Ticket,
                            setLoading: Function
                        ): Promise<void> => {
                            if (siteInfo.idProject) {
                                try {
                                    setLoading(true);
                                    await TicketsService.deleteTicket(
                                        siteInfo.idProject,
                                        element._id
                                    );
                                    dispatch(refresh());
                                    setConfirmDelete(false);
                                } catch (error) {
                                    //TODO: handle errors
                                    console.error(error);
                                    setLoading(false);
                                }
                            }
                        }}
                        element={ticket}
                        title="Borrar Caso"
                        btnMsg="Borrar"
                        deleting
                        msg="¿Está seguro que desea borrar este caso y todos sus SubCasos? Esta acción es irreversible!"
                        onClose={(): void => {
                            setConfirmDelete(false);
                        }}
                    />
                )}
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertRoundedIcon />
                </IconButton>
                <Menu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    style={{
                        pointerEvents: disabled ? 'none' : 'auto',
                    }}
                    onClose={handleClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    {actions}
                </Menu>
            </React.Fragment>
        );
    } else {
        return <div></div>;
    }
}

export default MoreActionsMenu;
