import styles from './State.module.css';

import TrendingFlatRoundedIcon from '@material-ui/icons/TrendingFlatRounded';

import React from 'react';
import { StateChange } from '../../../../../../../@Types/Action';
import StatePill from '../../../../../../../shared/StatePill/StatePill';

function State({ idOriginal, idValue }: StateChange): JSX.Element {
    return (
        <div className={styles.container}>
            {idOriginal && (
                <div className={styles.pillContainer}>
                    <StatePill idState={idOriginal} />
                </div>
            )}
            {idOriginal && (
                <div className={styles.arrowContainer}>
                    <TrendingFlatRoundedIcon fontSize="inherit" />
                </div>
            )}
            <div className={styles.pillContainer}>
                <StatePill idState={idValue} />
            </div>
        </div>
    );
}
export default State;
