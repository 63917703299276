import styles from './ChangePassword.module.css';
import { Controller, useForm } from 'react-hook-form';
import VanillaToasts from '../../../shared/Toast/Toast.js';
import { updatePassword } from '../../../controllers/_SiteController/Services/UserService';
import RoundedTextField from '../../../shared/RoundedTextField/RoundedTextField';
import RoundedButton from '../../../shared/RoundedButton/RoundedButton';
interface ChangePasswordProps {
    setLoading?: Function;
    loading?: boolean;
    onClose?: Function;
}

function ChangePassword({
    setLoading,
    onClose,
}: ChangePasswordProps): JSX.Element {
    const {
        getValues,
        control,
        formState: { errors, isDirty, isValid },
    } = useForm({
        mode: 'onChange',
    });

    const handlePasswordUpdate = async (): Promise<void> => {
        const values = getValues();
        if (isValid) {
            try {
                setLoading?.(true);
                await updatePassword(values.actPass, values.newPass);
                onClose?.();
            } catch (error: any) {
                setLoading?.(false);
                //TODO: handle errors
                VanillaToasts.create({
                    title: 'Error:',
                    text: error.response.data,
                    type: 'error',
                    timeout: 5000,
                    callback: function () {},
                });
            }
        }
    };
    return (
        <div className={styles.container + ' standard-dialog'}>
            <label className={styles.title}>Actualizar Contraseña</label>
            <label className={styles.label}>Contraseña actual</label>
            <div className={styles.inputContainer}>
                <Controller
                    name="actPass"
                    control={control}
                    defaultValue=""
                    rules={{
                        validate: (value: any): boolean => value !== '',
                    }}
                    render={({ field }): JSX.Element => (
                        <RoundedTextField
                            {...field}
                            helperText={
                                !!errors.actPass
                                    ? 'La contraseña actual es obligatoria'
                                    : ''
                            }
                            error={!!errors.actPass}
                            type="password"
                        />
                    )}
                />
            </div>
            <label className={styles.label}>Nueva contraseña</label>
            <div className={styles.inputContainer}>
                <Controller
                    name="newPass"
                    control={control}
                    defaultValue=""
                    rules={{
                        validate: (value: any): boolean => value !== '',
                    }}
                    render={({ field }): JSX.Element => (
                        <RoundedTextField
                            {...field}
                            helperText={
                                !!errors.newPass
                                    ? 'La contraseña actual es obligatoria'
                                    : ''
                            }
                            error={!!errors.newPass}
                            type="password"
                        />
                    )}
                />
            </div>
            <label className={styles.label}>Confirma tu nueva contraseña</label>
            <div className={styles.inputContainer}>
                <Controller
                    name="newConPass"
                    control={control}
                    defaultValue=""
                    rules={{
                        validate: (value: any): boolean => value !== '',
                    }}
                    render={({ field }): JSX.Element => (
                        <RoundedTextField
                            {...field}
                            helperText={
                                !!errors.newConPass
                                    ? 'La confirmación de la contraseña es obligatoria'
                                    : ''
                            }
                            error={!!errors.newConPass}
                            type="password"
                        />
                    )}
                />
            </div>
            <div className={styles.containerSubmitBtn}>
                <RoundedButton
                    text="Actualizar"
                    backgroundColor={'var(--secondary)'}
                    color={'white'}
                    onClick={handlePasswordUpdate}
                    disabled={!isDirty}
                />
            </div>
        </div>
    );
}
export default ChangePassword;
