import { createAction } from '@reduxjs/toolkit';
import { Entry } from '../../@Types/@Types';

export const Types = {
    RESET: 'ENTRIES/RESET',
    GET_SUCCESS: 'ENTRIES/GET_SUCCESS',
    SELECT: 'ENTRIES/SELECT_ELEMENT',
    UPDATE: 'ENTRIES/UPDATE_ELEMENT',
    UPDATE_FAILED: 'ENTRIES/UPDATE_FAILED',
    SEARCH: 'ENTRIES/SEARCH',
    LOADING: 'ENTRIES/LOADING',
    ORDER: 'ENTRIES/ORDER',
    FILTER: 'ENTRIES/FILTER',
};

/**
 * Called when the entries page is first loaded
 */
export const reset = createAction<{
    apiKey?: string;
}>(Types.RESET);

/**
 * Called to toggle the loader
 */
export const setLoading = createAction(
    Types.LOADING,
    function prepare(loading: boolean = true) {
        return {
            payload: loading,
        };
    }
);

/**
 * Called when the selectedElement (index) changes value
 */
export const selectElement = createAction<number>(Types.SELECT);

/**
 * Called when a value of the selected Entry is updated
 */
export const updateElement = createAction<{
    object: Entry;
    field: keyof Entry;
    value: string;
}>(Types.UPDATE);

export const updateFailed = createAction<Entry>(Types.UPDATE_FAILED);
/**
 * Called when the search value changes
 */
export const search = createAction<string>(Types.SEARCH);

/**
 *  Called when the orderby value changes
 */
export const orderBy = createAction<string>(Types.ORDER);

/**
 * Called when the filter values change
 */
export const filter = createAction<string[]>(Types.FILTER);

/**
 *  Called when the saga has sucessfully retrieved the lists elements
 */
export const getSuccess = createAction<{
    elements: Entry[];
    selectedElement?: number;
}>(Types.GET_SUCCESS);
