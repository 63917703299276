import { IconProps } from '@material-ui/core';
import TitleIcon from './TitleIcon';
import ConversationStepTypes from '../constants/Conversations/ConversationStepTypes';
import ListIcon from './ListIcon';

interface ConversationStepIconProps extends IconProps {
    type: ConversationStepTypes;
}

function ConversationStepIcon({
    type,
    ...props
}: ConversationStepIconProps): JSX.Element {
    switch (type) {
        case ConversationStepTypes.TEXT_STEP:
            return <TitleIcon {...props} />;
        case ConversationStepTypes.LIST_STEP:
        case ConversationStepTypes.LIST_PAGE_STEP:
        case ConversationStepTypes.LIST_API_STEP:
            return <ListIcon {...props} />;
        case ConversationStepTypes.BUTTONS_STEP:
            return <ListIcon {...props} />;
        default:
            return <></>;
    }
}
export default ConversationStepIcon;
