import styles from './Control.module.css';
import { ListaCasos } from './ListaCasos/ListaCasos';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../utils/_store';
import { ControlInfo360 } from '../../types';
interface ControlProps {
    controlInfo: ControlInfo360 | undefined;
    loading: boolean;
}

export default function Control({
    controlInfo,
    loading,
}: ControlProps): JSX.Element {
    const organization = useSelector(
        (state: RootState) => state.site.organization
    );
    return (
        <div className={styles.container}>
            <div className={styles.divider}>
                <ListaCasos
                    title={'Historial Casos'}
                    loading={loading}
                    tickets={controlInfo?.normalTickets}
                    showOpen={true}
                    organization={organization}
                />
            </div>
            <div className={styles.divider}>
                <ListaCasos
                    title={'Casos Posventa'}
                    loading={loading}
                    tickets={controlInfo?.posventaTickets}
                    showOpen={true}
                    organization={organization}
                />
            </div>
        </div>
    );
}
