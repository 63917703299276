import { IconProps } from './@IconTypes';

function TicketIcon({ className, style, fill }: IconProps): JSX.Element {
    return (
        <svg
            fill={fill}
            style={style}
            className={className}
            height="531pt"
            viewBox="-16 -16 531.99886 531"
            width="531pt"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
        >
            <path d="m227.234375 16.113281c-20.972656-20.925781-54.929687-20.925781-75.910156 0l-28.203125 28.203125c-4.382813 4.390625-4.882813 11.332032-1.164063 16.296875 13.164063 17.5625 11.417969 42.132813-4.105469 57.65625-15.519531 15.519531-40.09375 17.269531-57.65625 4.109375-4.96875-3.722656-11.910156-3.226562-16.292968 1.15625l-28.203125 28.203125c-20.933594 20.980469-20.933594 54.9375 0 75.917969l255.808593 255.816406c20.980469 20.921875 54.9375 20.921875 75.910157 0l135.632812-135.632812c20.929688-20.972656 20.929688-54.933594 0-75.910156zm-202.320313 173.585938c-.023437-7.625 3.011719-14.949219 8.417969-20.324219l21.1875-21.195312c26.769531 14.082031 59.578125 9.105468 80.96875-12.28125 21.382813-21.382813 26.363281-54.199219 12.28125-80.964844l21.191407-21.1875c11.230468-11.203125 29.410156-11.203125 40.640624 0l215.960938 215.960937-176.742188 175.804688-215.488281-215.492188c-5.40625-5.378906-8.4375-12.695312-8.417969-20.320312zm440.503907 140.503906-135.632813 135.628906c-11.226562 11.210938-29.410156 11.210938-40.636718 0l-22.695313-22.691406 176.742187-175.800781 22.222657 22.222656c11.222656 11.222656 11.222656 29.417969 0 40.640625zm0 0" />
        </svg>
    );
}
export default TicketIcon;
