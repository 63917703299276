import React from 'react';
import { makeStyles, Switch, SwitchProps } from '@material-ui/core';

interface StyleProps {
    /** The color of the toggle */
    color?: string;
    /** The hover color of the toggle */
    hoverColor?: string;
}

const useStyles = (props: StyleProps): Function =>
    makeStyles({
        switchBase: {
            color: '#b8b8b8' + ' !important',
            '&$checked': {
                color: props.color + ' !important',
            },
            '&$checked + $track': {
                backgroundColor: props.color + ' !important',
            },
            '&:hover': {
                backgroundColor: props.hoverColor + ' !important',
            },
        },
        checked: {},
        track: {},
    });

interface RoundedToggleProps
    extends Omit<SwitchProps, 'color' | 'onChange'>,
        StyleProps {
    /** If the toggle is checked */
    checked?: boolean;
    /** Function to call when the toggle is clicked on */
    onChange: (checked: boolean) => void;
    /** If the Toggle is disabled */
    disabled?: boolean;
}
function RoundedToggle({
    color = 'var(--secondary)',
    hoverColor = 'var(--transparentsecondary)',
    checked = true,
    onChange,
    disabled = false,
    size = 'medium',
}: RoundedToggleProps): JSX.Element {
    const classes = useStyles({ color, hoverColor })();
    return (
        <Switch
            size={size}
            classes={classes}
            checked={checked}
            disabled={disabled}
            onChange={(event): void => {
                onChange(event.target.checked);
            }}
        />
    );
}
export default RoundedToggle;
