import {
    DateActivity,
    Trigger,
} from '../../../../../@Types/ProcessTypes/Activity';
import React, { useState } from 'react';
import Dialog from '../../../../../shared/Dialog/Dialog';
import styles from './DateActivity.module.css';
import InternalActivity from '../InternalActivity';
import ActivityTriggerTypes from '../../../../../constants/ActivityTypes/ActivityTriggerType';
import ConfirmDialog from '../../../../../shared/ConfirmDialog/ConfirmDialog';
import ActivitiesService from '../../../../../controllers/TicketsController/Activities/ActivitiesService';
import { Ticket } from '../../../../../@Types/@Types';
import { useDispatch } from 'react-redux';
import VanillaToasts from '../../../../../shared/Toast/Toast';
import { refreshCurrent } from '../../../../../controllers/TicketsController/TicketsActions';
import SubmitBtns from '../SubmitBtns/SubmitBtns';
import FormRenderer from '../../../../../shared/FormRenderer/FormRenderer';
export interface DateActivityProps {
    ticket: Ticket;
    activity: DateActivity;
}

function DateActivityComponent({
    ticket,
    activity,
}: DateActivityProps): JSX.Element {
    const [state, setState] = useState<
        | {
              trigger: Trigger;
              values: Record<string, unknown>;
              showDialog: boolean;
              showConfirmation: boolean;
          }
        | undefined
    >();

    const [loading, setLoading] = useState<string | undefined>();
    const dispatch = useDispatch();

    const handleSend = async (
        trigger?: Trigger,
        values?: Record<string, any>
    ): Promise<void> => {
        if (!trigger) return;
        setLoading(trigger.id);
        let next: typeof state;
        let send = false;
        /** Si está en preview */
        if (!state) {
            next = {
                trigger,
                values: values ?? {},
                showDialog: true,
                showConfirmation: false,
            };
        } else {
            next = {
                ...state,
            };
            if (values !== undefined) next.values = values;
            if (state.showConfirmation) send = true;
            else next.showConfirmation = true;
        }
        if (next.showDialog && !next.trigger.form) {
            next.showDialog = false;
            next.showConfirmation = true;
        }
        if (next.showConfirmation && !next.trigger.confirmation) {
            send = true;
        }

        if (send) {
            try {
                await ActivitiesService.completeDate(
                    ticket.idProject,
                    ticket._id,
                    activity.id,
                    {
                        idTrigger: next.trigger.id,
                        values: next.values,
                    }
                );
                dispatch(refreshCurrent(true));
                setState(undefined);
            } catch (error: any) {
                dispatch(refreshCurrent(true));
                VanillaToasts.create({
                    title: 'Error:',
                    text: error.response?.data,
                    type: 'error',
                    timeout: 5000,
                    callback: function () {},
                });
            }
        } else {
            setState(next);
        }
        setLoading(undefined);
    };

    return (
        <React.Fragment>
            {state?.showDialog && (
                <Dialog
                    maxWidth="100%"
                    disableEnforceFocus
                    onClose={(): void => {
                        setState(undefined);
                    }}
                >
                    <div className={styles.formDialogContainer}>
                        <FormRenderer
                            customSubmit={async (values): Promise<void> => {
                                await handleSend(state.trigger, values);
                            }}
                            form={state.trigger.form!}
                            rootSteps={state.trigger.form?.rootSteps}
                            sendLabel={
                                state.trigger.form?.label ?? state.trigger.label
                            }
                        />
                    </div>
                </Dialog>
            )}
            {state?.showConfirmation && state.trigger.confirmation && (
                <ConfirmDialog
                    onConfirm={async (
                        _values: any,
                        setLoading: Function
                    ): Promise<void> => {
                        setLoading(true);
                        await handleSend(state.trigger);
                        setLoading(false);
                    }}
                    deleting={
                        state.trigger.triggerType === ActivityTriggerTypes.ERROR
                    }
                    title={state.trigger.confirmation.title}
                    btnMsg={state.trigger.confirmation.label}
                    msg={state.trigger.confirmation.message}
                    onClose={(): void => {
                        if (state.showDialog) {
                            setState({ ...state, showConfirmation: false });
                        } else {
                            setState(undefined);
                        }
                    }}
                />
            )}
            <InternalActivity title={activity.name}>
                <div className={styles.container}>
                    {activity.helperText && (
                        <label className={styles.helperMessageLbl}>
                            {activity.helperText}
                        </label>
                    )}
                    <SubmitBtns
                        triggers={activity.triggers}
                        idTrigger={loading}
                        handleSubmit={handleSend}
                    />
                </div>
            </InternalActivity>
        </React.Fragment>
    );
}

export default DateActivityComponent;
