import { useSelector } from 'react-redux';
import stepMenuStyles from '../StepMenu.module.css';
import { nanoid } from 'nanoid';
import { EntityValuePicker } from '../../../../@Types/FormTypes/FormStep';
import styles from './OtherEntitiesMenu.module.css';
import React, { useMemo, useRef, useState } from 'react';
import { Popper } from '@material-ui/core';
import { RootState } from '../../../../utils/_store';
import IconTypes from '../../../../constants/IconTypes';
import StepTypes from '../../../../constants/FormStepTypes';
import { EntityPermissions } from '../../../../constants/Permissions';
import { checkEntityPerm } from '../../../../utils/PermissionsFunctions';
import EntityIcon from '../../../../shared/SmartIcons/EntityIcon';
import GenericEntityIcon from '../../../../Icons/Entities/GenericEntityIcon';
import { FormStepLocation } from '../../../../@Types/FormTypes/Form';
import LocationTypes from '../../../../constants/LocationTypes';
interface OtherEntitiesMenuProps {
    location: FormStepLocation;
    handleAdd: (step: EntityValuePicker) => void;
}
function OtherEntitiesMenu({
    location,
    handleAdd,
}: OtherEntitiesMenuProps): JSX.Element {
    const [hovering, setHovering] = useState(false);
    const [hoveringMenu, setHoveringMenu] = useState(false);
    const organization = useSelector(
        (state: RootState) => state.site.organization
    );
    const user = useSelector((state: RootState) => state.site.user);
    const siteEntities = useSelector((state: RootState) => state.site.entities);

    const entities = useMemo(
        () =>
            Object.values(siteEntities)
                .filter(
                    (entity) =>
                        entity.active !== false &&
                        entity._id !== organization?.idClientEntity &&
                        entity._id !== organization?.idCompanyEntity &&
                        checkEntityPerm(
                            user,
                            entity._id,
                            EntityPermissions.VIEW
                        )
                )
                .sort((e1, e2) => e1.name.localeCompare(e2.name)),
        [organization, siteEntities, user]
    );
    const btnRef = useRef<HTMLDivElement>(null);

    if (entities.length === 0) return <div></div>;

    return (
        <React.Fragment>
            <div
                className={stepMenuStyles.option}
                onMouseEnter={(): void => {
                    setHovering(true);
                }}
                onMouseLeave={(): void => {
                    setHovering(false);
                }}
                ref={btnRef}
                style={{
                    backgroundColor:
                        hovering || hoveringMenu
                            ? ' var(--light-grey)'
                            : 'white',
                }}
            >
                <div className={styles.entityInputContainer}>
                    <div className={styles.entityIconContainer}>
                        <GenericEntityIcon
                            fill={'var(--secondary)'}
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                        />
                    </div>
                </div>
                <div className={stepMenuStyles.optionLabel}>
                    Otras Entidades
                </div>
            </div>
            {(hovering || hoveringMenu) && (
                <Popper
                    open={true}
                    anchorEl={btnRef.current}
                    placement={'right-end'}
                    modifiers={{
                        preventOverflow: {
                            enabled: true,
                            priority: ['right', 'left'],
                            boundariesElement: 'viewport',
                        },
                    }}
                    style={{ zIndex: 3 }}
                >
                    <div
                        className={styles.container}
                        onMouseEnter={(): void => {
                            setHoveringMenu(true);
                        }}
                        onMouseLeave={(): void => {
                            setHoveringMenu(false);
                        }}
                    >
                        <div className={styles.bottomLeftPadding}></div>
                        <div>
                            {entities.map((entity) => (
                                <div
                                    className={stepMenuStyles.option}
                                    key={'ENTITY-' + entity._id}
                                    onClick={(): void => {
                                        const defaultValue: EntityValuePicker =
                                            {
                                                id:
                                                    StepTypes.ENTITYVALUEPICKER +
                                                    '-' +
                                                    entity._id +
                                                    '-' +
                                                    nanoid(),
                                                type: StepTypes.ENTITYVALUEPICKER,
                                                label: entity.name,
                                                icon:
                                                    entity.icon ??
                                                    IconTypes.GENERIC,
                                                searchable: true,
                                                showIcon: true,
                                                description: entity.description,
                                                idEntity: entity._id,
                                                required: false,
                                                filters: [],
                                                path: [],
                                                options: {},
                                                size: 1,
                                            };
                                        if (
                                            location.type ===
                                            LocationTypes.SECTION
                                        ) {
                                            defaultValue.maxSize = 1;
                                        }
                                        handleAdd(defaultValue);
                                    }}
                                >
                                    <div
                                        className={styles.entityInputContainer}
                                    >
                                        <div
                                            className={
                                                styles.entityIconContainer
                                            }
                                        >
                                            <EntityIcon
                                                fill={'var(--secondary)'}
                                                idEntity={entity._id}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.optionLabel}>
                                        {entity.name}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Popper>
            )}
        </React.Fragment>
    );
}

export default OtherEntitiesMenu;
