import { MenuGroupedNotification } from '../../@Types/GroupedMenuNotification';
import { FullNotification } from '../../@Types/Notification';
import axiosInstance from '../../AxiosAPI';

export const loadNotificationsMenu = async (
    page: number,
    pageSize: number,
    filterState: string
): Promise<MenuGroupedNotification[]> => {
    let response = await axiosInstance.get(
        `stockNotifications/menu?page=${page}&pageSize=${pageSize}&filterState=${filterState}`
    );
    const notifications = response.data;

    for (let i = 0; i < notifications.length; i++) {
        if (notifications[i].ticket) {
            const ticket = notifications[i].ticket;
            ticket.creation_date = new Date(ticket.creation_date);
            ticket.resolution_date = new Date(ticket.resolution_date);
        }
    }

    return notifications;
};

export const fetchFullStockNotification = async (
    id: string
): Promise<FullNotification> => {
    let response = await axiosInstance.get(`stockNotifications/${id}`);
    const notification = response.data;
    notification.ticket.creation_date = new Date(
        notification.ticket.creation_date
    );
    notification.ticket.resolution_date = new Date(
        notification.ticket.resolution_date
    );
    return notification;
};

export const markAsRead = async (id: string): Promise<void> => {
    await axiosInstance.put(`stockNotifications/makeSeen/${id}`);
};
