import { MenuItem } from '@material-ui/core';
import React from 'react';
import RoundedSelect from '../../RoundedSelect/RoundedSelect';
import styles from './Condition.module.css';
import { ConditionProps } from '../Condition';
import { FlowCondition } from '../../../@Types/ConditionTypes/FlowConditions';
import FlowConditionTypes from '../../../constants/Conditions/FlowConditionTypes';
import ConditionOperators from '../../../constants/Conditions/ConditionOperators';
import RoundedTextField from '../../RoundedTextField/RoundedTextField';

interface FlowConditionProps extends ConditionProps<FlowCondition> {}

function FlowConditionComponent({
    condition,
    hoverDelete,
    handleUpdate,
}: FlowConditionProps): JSX.Element {
    return (
        <React.Fragment>
            <div className={styles.operatorContainer}>
                <div
                    className={styles.classifierSelectorContainer}
                    style={{ minWidth: 240 }}
                >
                    <RoundedSelect
                        value={condition.flowConditionType}
                        containerMargin="0px"
                        error={hoverDelete}
                        handleUpdate={(event): void => {
                            const flowConditionType = event.target.value;
                            handleUpdate({
                                ...condition,
                                flowConditionType,
                            });
                        }}
                    >
                        <MenuItem value={FlowConditionTypes.FLOW_COUNT}>
                            Cantidad de ejecuciones por este flujo
                        </MenuItem>
                        <MenuItem value={FlowConditionTypes.STEP_COUNT}>
                            Cantidad de ejecuciones por este paso
                        </MenuItem>
                        <MenuItem value={FlowConditionTypes.PATH_COUNT}>
                            Cantidad de ejecuciones por esta decisión
                        </MenuItem>
                    </RoundedSelect>
                </div>
            </div>
            <div className={styles.operatorContainer}>
                <div className={styles.selectorContainer}>
                    <RoundedSelect
                        value={condition.operator ?? ''}
                        label="Operador"
                        containerMargin="0px"
                        error={hoverDelete}
                        handleUpdate={(event): void => {
                            handleUpdate({
                                ...condition,
                                operator: event.target.value,
                            });
                        }}
                    >
                        <MenuItem value={ConditionOperators.EQUAL}>
                            Es igual a
                        </MenuItem>
                        <MenuItem value={ConditionOperators.NOTEQUAL}>
                            No es igual a
                        </MenuItem>
                        <MenuItem value={ConditionOperators.LESS}>
                            Es menor que
                        </MenuItem>
                        <MenuItem value={ConditionOperators.MORE}>
                            Es mayor que
                        </MenuItem>
                    </RoundedSelect>
                </div>
            </div>
            <div className={styles.numberContainer}>
                <RoundedTextField
                    value={condition.value ?? 0}
                    label={'Candidad'}
                    height="40px"
                    fullWidth
                    type="number"
                    error={hoverDelete}
                    onChange={(event): void => {
                        const value = parseInt(event.target.value);
                        handleUpdate({
                            ...condition,
                            value,
                        });
                    }}
                />
            </div>
        </React.Fragment>
    );
}
export default FlowConditionComponent;
