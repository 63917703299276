import { AnyAction } from '@reduxjs/toolkit';
import { takeLatest, call, put, fork, select } from 'redux-saga/effects';
import { RootState } from '../../utils/_store';
import * as actions from './ConversationEditorActions';
import { loadConversationData } from './ConversationService';

/**
 * Function called when a conversation should be loaded
 * @param action of type reset
 */
function* getConversation(action: AnyAction): any {
    try {
        const idProject = yield select(
            (state: RootState) => state.site.idProject
        );
        const result = yield call(
            loadConversationData,
            idProject,
            action.payload
        );
        yield put(actions.getSuccess(result));
    } catch (error) {
        //TODO handle errors
        console.error(error);
    }
}

function* watchGetConversationsRequest(): any {
    yield takeLatest([actions.Types.RESET], getConversation);
}

export default [fork(watchGetConversationsRequest)];
