import styles from './SetEntities.module.css';
import TrendingFlatRoundedIcon from '@material-ui/icons/TrendingFlatRounded';
import React from 'react';
import { EntityValue } from '../../../../../../@Types/EntityValue';
import { Entity } from '../../../../../../@Types/@Types';
import Avatar from '../../../../../../shared/Avatar/Avatar';
import EntityIcon from '../../../../../../shared/SmartIcons/EntityIcon';
interface SetEntitiesProps {
    name: string;
    entity: Entity;
    original: EntityValue[] | null;
    value: EntityValue[] | null;
}

function SetEntities({
    name,
    entity,
    original,
    value,
}: SetEntitiesProps): JSX.Element {
    return (
        <div className={styles.container}>
            {original && original.length > 0 && (
                <div className={styles.entityValue}>
                    {original.map((entityValue, index) => (
                        <EntityValueComponent
                            key={index}
                            entity={entity}
                            entityValue={entityValue}
                        />
                    ))}
                </div>
            )}
            {original && original.length > 0 && (
                <div className={styles.arrowContainer}>
                    <TrendingFlatRoundedIcon fontSize="inherit" />
                </div>
            )}
            {value && (
                <div className={styles.entityValue}>
                    {value.map((entityValue, index) => (
                        <EntityValueComponent
                            key={index}
                            entity={entity}
                            entityValue={entityValue}
                        />
                    ))}
                    {value.length === 0 && (
                        <div className={styles.emptyContainer}>
                            (Sin {name})
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
export default SetEntities;

interface EntityValueComponentProps {
    entity: Entity;
    entityValue: EntityValue;
}

function EntityValueComponent({
    entityValue,
    entity,
}: EntityValueComponentProps): JSX.Element {
    return (
        <div className={styles.entityValueContainer}>
            <Avatar
                lbl={entityValue.values?.[entity.idLabelStep] ?? entity.name}
                size={30}
                fontSize={30}
            >
                <EntityIcon
                    idEntity={entity._id}
                    fill="var(--primary1)"
                    style={{
                        height: 21,
                        width: 21,
                    }}
                />
            </Avatar>
            <label className={styles.nameLbl}>
                {entityValue.values?.[entity.idLabelStep] ?? entity.name}
            </label>
        </div>
    );
}
