import React, { useMemo, useState } from 'react';
import styles from './FormCondition.module.css';
import Dialog from '../../../shared/Dialog/Dialog';
import ConditionEditor from '../../../shared/ConditionEditor/ConditionEditor';
import ConditionTypes from '../../../constants/Conditions/ConditionTypes';
import FilterIcon from '../../../Icons/FilterIcon';
import { FormCondition } from '../../../@Types/GenericForm';
import { useFormEditorSelector } from '../FormReducerFunctions';

const FormConditionTypes = [ConditionTypes.FORM_STEP];

interface FormConditionProps {
    idStep: string;
    title?: string;
    size?: number;
    iconSize?: number;
    borderRadius?: number;
    condition: FormCondition | undefined;
    handleUpdate: React.Dispatch<FormCondition | undefined>;
}

function FormConditionComponent({
    idStep,
    condition,
    handleUpdate,
    title = 'Condiciones',
    size = 30,
    iconSize = 18,
    borderRadius = 10,
}: FormConditionProps): JSX.Element {
    const [open, setOpen] = useState(false);
    const error = useFormEditorSelector((state) => state.error);
    const previousSteps = useFormEditorSelector(
        (state) => state.stepDependencies[idStep]?.previousSteps
    );
    if (!idStep || (!previousSteps && !condition)) return <div></div>;

    const showConditions = previousSteps.length > 0 || condition;
    const hasCondition =
        condition?.type && condition.type !== ('DEFAULT' as any);

    const hasError = useMemo(
        () => error?.idStep === idStep && error.property === 'condition',
        [error]
    );

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={(): void => setOpen(false)}
                border={20}
                maxWidth="90vw"
                disableEnforceFocus
            >
                <div className={styles.container}>
                    <div className={styles.nameLbl}>{title}</div>
                    <div className={styles.decisionsContainer}>
                        <ConditionEditor
                            condition={condition}
                            context={{ form: { idStep } }}
                            handleDelete={(): void => handleUpdate(undefined)}
                            handleUpdate={(condition): void =>
                                handleUpdate(condition)
                            }
                            conditionTypes={FormConditionTypes}
                        />
                    </div>
                </div>
            </Dialog>
            {showConditions && (
                <div
                    className={styles.conditionsBtn}
                    onClick={(): void => setOpen(true)}
                    style={{
                        width: size,
                        height: size,
                        borderRadius,
                    }}
                    title={'Condiciones'}
                >
                    <FilterIcon
                        fill={
                            hasError
                                ? 'var(--error)'
                                : hasCondition
                                ? 'var(--secondary)'
                                : 'var(--greyFont)'
                        }
                        style={{
                            width: iconSize,
                            height: iconSize,
                        }}
                    />
                </div>
            )}
        </React.Fragment>
    );
}

export default FormConditionComponent;
