import styles from './ProcessMenu.module.css';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useDispatch, useSelector } from 'react-redux';
import { Ticket } from '../../../../../../@Types/@Types';

import { RootState } from '../../../../../../utils/_store';
import Dialog from '../../../../../../shared/Dialog/Dialog';
import React, { useCallback, useState } from 'react';
import RoundedAgentPicker from '../../../../../../shared/@Pickers/RoundedAgentPicker/RoundedAgentPicker';
import RoundedButton from '../../../../../../shared/RoundedButton/RoundedButton';
import { Process } from '../../../../../../@Types/ProcessTypes/Process';
import AssignProcess from '../../../../../../shared/AssignProcess/AssignProcess';
import { AssignProcessRoles } from '../../../../../../@Types/FlowTypes/NodeTypes/TransformationNode';
import {
    removeProcess,
    setProcess,
    setProcessRoles,
} from '../../../../../../controllers/TicketsController/Actions/ActionsService';
import { refreshCurrent } from '../../../../../../controllers/TicketsController/TicketsActions';

interface ProcessMenuProps {
    /** The ref of the anchor, the Agents button on the ticket detail */
    anchorRef: any;
    /** Function to call to close the menu */
    handleClose: Function;
    /** The currently selected Ticket */
    ticket: Ticket;
    /** If the layout is mobile */
    layoutIsMobile?: boolean;
}
function ProcessMenu({
    ticket,
    anchorRef,
    handleClose,
    layoutIsMobile,
}: ProcessMenuProps): JSX.Element {
    const dispatch = useDispatch();
    const [currentRoles, setCurrentRoles] = useState<Record<string, string[]>>(
        ticket.process?.roles ?? {}
    );
    const [newProcess, setNewProcess] = useState<
        | {
              process: Process | undefined;
              roles: AssignProcessRoles | undefined;
              values: Record<string, any> | undefined;
          }
        | undefined
    >();

    const process = useSelector(
        (state: RootState) =>
            state.ticketsPage.processes[ticket.process?.idProcess ?? '']
    );

    const [showMenu, setShowMenu] = useState(process === undefined);
    const [loading, setLoading] = useState(false);
    const handleSave = async (): Promise<void> => {
        if (!ticket.process?.idProcess) return;
        setLoading(true);
        try {
            await setProcessRoles(ticket.idProject, ticket._id, currentRoles);
            dispatch(refreshCurrent(true));
            handleClose();
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const handleChangeProcess = async (
        setLoading: Function | undefined,
        values: Record<string, any>
    ): Promise<void> => {
        if (!newProcess || !newProcess.process || !newProcess.roles) return;
        setLoading?.(true);
        try {
            await setProcess(
                ticket.idProject,
                ticket._id,
                newProcess.process._id,
                newProcess.roles,
                values
            );
            dispatch(refreshCurrent(true));
            handleClose();
        } catch (err) {
            setLoading?.(false);
        }
    };

    const handleRemoveProcess = async (
        setLoading: Function | undefined
    ): Promise<void> => {
        setLoading?.(true);
        try {
            await removeProcess(ticket.idProject, ticket._id);
            dispatch(refreshCurrent(true));
            handleClose();
        } catch (err) {
            setLoading?.(false);
        }
    };

    const renderContent = (): JSX.Element => {
        return (
            <div className={styles.container}>
                <div className={styles.title}>{process.name} </div>
                <div className={styles.processRolesLbl}>Roles:</div>
                <div className={styles.processRolesContainer}>
                    {process?.roles.map((role) => {
                        return (
                            <RoundedAgentPicker
                                autoWidth
                                minWidth={220}
                                key={role.id}
                                label={role.name}
                                multiple
                                value={currentRoles[role.id] ?? []}
                                handleUpdate={(agents: any): void => {
                                    setCurrentRoles((roles) => ({
                                        ...roles,
                                        [role.id]: agents,
                                    }));
                                }}
                            />
                        );
                    })}
                    {!process?.roles?.length && (
                        <div className={styles.emptyRolesLbl}>
                            El proceso no tiene roles
                        </div>
                    )}
                </div>
                {process?.roles && process?.roles.length > 0 && (
                    <div className={styles.saveBtn}>
                        <RoundedButton
                            text={'Guardar Cambios'}
                            backgroundColor="var(--secondary)"
                            loading={loading}
                            onClick={(): void => {
                                handleSave();
                            }}
                        />
                    </div>
                )}
                <div className={styles.changeProcessBtn}>
                    <RoundedButton
                        text={'Cambiar Proceso'}
                        backgroundColor="var(--error)"
                        disabled={loading}
                        onClick={(): void => {
                            setShowMenu(true);
                        }}
                    />
                </div>
            </div>
        );
    };
    const renderMenu = (): JSX.Element => {
        if (layoutIsMobile) {
            return (
                <Dialog
                    onClose={(): void => {
                        handleClose();
                    }}
                    maxWidth="100vw"
                    border={10}
                    transparent
                >
                    {renderContent()}
                </Dialog>
            );
        } else {
            return (
                <ClickAwayListener
                    mouseEvent="onMouseDown"
                    onClickAway={(): void => {
                        if (!showMenu && !loading) handleClose();
                    }}
                >
                    <Popper
                        open={true}
                        anchorEl={anchorRef.current}
                        placement={'bottom-end'}
                        modifiers={{
                            preventOverflow: {
                                enabled: true,
                                priority: ['top', 'bottom', 'left', 'right'],
                                boundariesElement: 'viewport',
                                padding: 20,
                            },
                        }}
                        style={{ zIndex: 2 }}
                    >
                        {renderContent()}
                    </Popper>
                </ClickAwayListener>
            );
        }
    };
    return (
        <React.Fragment>
            {showMenu && (
                <Dialog
                    onClose={(): void => {
                        if (!loading) {
                            setShowMenu(false);
                            if (!process) handleClose();
                        }
                    }}
                >
                    <ChangeProcess
                        firstTime={process === undefined}
                        newProcess={newProcess}
                        setNewProcess={setNewProcess}
                        onClose={(): void => {
                            if (!loading) {
                                setShowMenu(false);
                                if (!process) handleClose();
                            }
                        }}
                        handleRemoveProcess={handleRemoveProcess}
                        handleChangeProcess={handleChangeProcess}
                    />
                </Dialog>
            )}
            {process !== undefined && renderMenu()}
        </React.Fragment>
    );
}
export default ProcessMenu;

interface ChangeProcessProps {
    firstTime: boolean;
    newProcess:
        | {
              process: Process | undefined;
              roles: AssignProcessRoles | undefined;
          }
        | undefined;
    setNewProcess: Function;
    onClose: Function;
    handleChangeProcess: (
        setLoading: Function | undefined,
        values: Record<string, any>
    ) => Promise<void>;
    handleRemoveProcess: Function;
    setLoading?: (loading: boolean) => void;
}

function ChangeProcess({
    onClose,
    firstTime,
    newProcess,
    setNewProcess,
    setLoading,
    handleRemoveProcess,
    handleChangeProcess,
}: ChangeProcessProps): JSX.Element {
    const [submit, setSubmit] =
        useState<() => Promise<Record<string, any> | void>>();

    const handleClick = useCallback(async (): Promise<void> => {
        let values;
        if (submit) {
            values = await submit();
            if (!values) return;
        }
        handleChangeProcess(setLoading, values ?? {});
    }, [submit, newProcess]);

    const handleSetSubmit = useCallback((submit): void => {
        setSubmit(() => submit);
    }, []);

    return (
        <div className={styles.changeProcessContainer}>
            <div className={styles.changeProcessTitle}>
                {firstTime ? 'Seleccionar' : 'Cambiar'} Proceso
            </div>
            <AssignProcess
                process={newProcess?.process}
                roles={newProcess?.roles}
                handleUpdate={(process, roles): void => {
                    setNewProcess({ process, roles });
                }}
                setSubmit={handleSetSubmit}
            />
            <div className={styles.changeBtnsContainer}>
                {firstTime && (
                    <RoundedButton
                        text={'Cancelar'}
                        backgroundColor="var(--greyBtn)"
                        onClick={(): void => {
                            onClose();
                        }}
                    />
                )}
                {!firstTime && (
                    <RoundedButton
                        text={'Quitar Proceso'}
                        backgroundColor="var(--error)"
                        onClick={(): void => {
                            handleRemoveProcess(setLoading);
                        }}
                    />
                )}
                <RoundedButton
                    text={(firstTime ? 'Seleccionar' : 'Cambiar') + ' Proceso'}
                    backgroundColor={'var(--secondary)'}
                    onClick={handleClick}
                />
            </div>
        </div>
    );
}
