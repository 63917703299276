import React from 'react';
import { Link, match, RouteComponentProps, withRouter } from 'react-router-dom';
import { WhatsappEntry } from '../../../@Types/Entry';
import RoundedButton from '../../../shared/RoundedButton/RoundedButton';
import styles from './EntryDetail.module.css';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
interface EntryConversationProps {
    entry: WhatsappEntry;
    match: match<{ idProject: string }>;
}
function EntryConversationcomponent({
    entry,
    history,
    match,
}: EntryConversationProps & RouteComponentProps): JSX.Element {
    return (
        <React.Fragment>
            <div className={styles.numContainer}>
                <div className={styles.descriptionLabel}>Número:</div>
                <div className={styles.numdescription}>
                    {entry.num}
                    <Link
                        to={{
                            pathname: 'https://wa.me/' + entry.num,
                        }}
                        target="_blank"
                    >
                        <div className={styles.open}>
                            <OpenInNewRoundedIcon fontSize="inherit" />
                        </div>
                    </Link>
                </div>
            </div>
            <div className={styles.flujoLabel}>Conversación</div>
            <img
                alt={'Conversation'}
                className={styles.conversationFoto}
                src={'/media/form.png'}
            ></img>
            <div className={styles.convButtons}>
                <RoundedButton
                    text="Ver Conversación"
                    backgroundColor="var(--secondary)"
                    color="white"
                    onClick={(): void => {
                        history.push(
                            `/${match.params.idProject}/entries/${entry.apiKey}/conversation`
                        );
                    }}
                />
                {entry.migrated && (
                    <RoundedButton
                        text="Mensajes Predefinidos"
                        backgroundColor="var(--secondary)"
                        color="white"
                        onClick={(): void => {
                            history.push(
                                `/${match.params.idProject}/entries/${entry.apiKey}/templates`
                            );
                        }}
                    />
                )}
            </div>
        </React.Fragment>
    );
}

export default withRouter(EntryConversationcomponent);
