import { Flow } from '../../../../../@Types/@Types';
import styles from './FlowIcon.module.css';

interface FlowIconProps {
    flow?: Flow;
}
/**
 * Component used to display the flow that made an automated action
 */
function FlowIcon({}: FlowIconProps): JSX.Element {
    //TODO el flujo deberia entrar por parametro
    return (
        <div className={styles.container} title={'Flujo'}>
            <img
                alt={'Bot'}
                className={styles.icon}
                src={'/media/icons/robot.svg'}
            ></img>
        </div>
    );
}
export default FlowIcon;
