import { ApiRequest } from '../../@Types/ApiRequest';
import PayloadEditor, {
    PayloadEditorConditions,
} from '../TextEditor/PayloadEditor';
import styles from './ApiRequestEditor.module.css';
import React from 'react';
import { DraftContext } from '../../@Types/Draft/DraftContext';
import produce from 'immer';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import ApiRequestQueryParam from './ApiRequestQueryParam/ApiRequestQueryParam';
import { stringToDraft } from '../../utils/draftFunctions';
import { TextEditorTypes } from '../../constants/TextEditorTypes';

export interface ApiRequestEditorProps {
    idEditor: string;
    request: ApiRequest;
    context: DraftContext;
    handleUpdate: (request: ApiRequest) => void;
    conditions: PayloadEditorConditions;
}

function ApiRequestEditor(props: ApiRequestEditorProps): JSX.Element {
    const { context, request, idEditor, conditions, handleUpdate } = props;
    // if (request.integration) {
    //     return <IntegrationApiRequestEditor {...props} />;
    // }

    return (
        <React.Fragment>
            <div className={styles.urlEditorContainer}>
                <div className={styles.urlLabel}>Enlace:</div>
                <PayloadEditor
                    idEditor={idEditor}
                    media={false}
                    multiline={false}
                    type={TextEditorTypes.INPUT}
                    placeholder="https://api.capta.co"
                    context={context}
                    conditions={conditions}
                    payload={{ draft: request.url }}
                    onChange={({ draft: url }): void => {
                        if (!url) return;
                        handleUpdate({ ...request, url });
                    }}
                />
            </div>
            <div className={styles.queryParamsContainer}>
                <div className={styles.paramsLabel}>Parámetros:</div>
                <div className={styles.paramsContainer}>
                    {request.queryParams?.map((queryParam, index) => (
                        <ApiRequestQueryParam
                            key={index}
                            idEditor={idEditor + '-' + index}
                            handleUpdate={(newParam): void => {
                                handleUpdate(
                                    produce(request, (request) => {
                                        request.queryParams[index] = newParam;
                                    })
                                );
                            }}
                            context={context}
                            conditions={conditions}
                            handleDelete={(): void => {
                                handleUpdate(
                                    produce(request, (request) => {
                                        request.queryParams.splice(index, 1);
                                    })
                                );
                            }}
                            queryParam={queryParam}
                        />
                    ))}
                    <div
                        className={styles.addParam}
                        onClick={(): void => {
                            handleUpdate(
                                produce(request, (request) => {
                                    request.queryParams.push({
                                        key: 'llave',
                                        value: stringToDraft(''),
                                        required: false,
                                    });
                                })
                            );
                        }}
                    >
                        <div className={styles.addIconContainer}>
                            <AddRoundedIcon fontSize="inherit" />
                        </div>
                        <div className={styles.addParamLbl + ' noselect'}>
                            Agregar Parámetro
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ApiRequestEditor;
