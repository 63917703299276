import {
    SurveyStepLocation,
    UniqueSteps,
} from '../../../@Types/SurveyTypes/Survey';
import { SurveyStep } from '../../../@Types/SurveyTypes/SurveyStep';
import Types from '../../../constants/SurveyStepTypes';
import * as SurveySteps from '../../../@Types/SurveyTypes/SurveyStep';
import React from 'react';
import DatePickerStep from '../../../shared/@Steps/DatePickerStep/DatePickerStep';
import SeparatorStep from '../../../shared/@Steps/SeparatorStep/SeparatorStep';
import TextAreaStep from '../../../shared/@Steps/TextAreaStep/TextAreaStep';
import TitleStep from '../../../shared/@Steps/TitleStep/TitleStep';
import CheckBoxStep from '../../../shared/@Steps/CheckBoxStep/CheckBoxStep';
import TextInputStep from '../../../shared/@Steps/TextInputStep/TextInputStep';
import TextAreaConfig from './Steps/TextAreaConfig/TextAreaConfig';
import { GenericStepProps } from '../../../shared/GenericFormEditor/Step/Step';
import { CheckBoxLocation } from '../../../@Types/FormTypes/LocationTypes';
import LocationTypes from '../../../constants/LocationTypes';
import RatingConfig from './Steps/RatingConfig/RatingConfig';
import RatingStep from '../../../shared/@Steps/RatingStep/RatingStep';
import FileUploadStep from '../../../shared/@Steps/FileUploadStep/FileUploadStep';
import SelectorStep from '../../../shared/@Steps/SelectorStep/SelectorStep';
import TimePickerStep from '../../../shared/@Steps/TimePickerStep/TimePickerStep';

export function SurveyStepMapper(
    props: GenericStepProps<SurveyStep, UniqueSteps, SurveyStepLocation>
): JSX.Element {
    const { step, location } = props;
    switch (props.step.type) {
        case Types.TITLE: {
            return <TitleStep {...props} step={props.step} />;
        }
        case Types.RATING: {
            return (
                <RatingStep
                    {...props}
                    step={props.step}
                    stepEqualityChecker={(prev, next): boolean =>
                        prev.uniqueSteps.primaryRatingStep ===
                        next.uniqueSteps.primaryRatingStep
                    }
                >
                    <RatingConfig />
                </RatingStep>
            );
        }
        case Types.CHECKBOX: {
            return (
                <CheckBoxStep
                    {...props}
                    step={props.step as SurveySteps.CheckBox}
                    calcSubLocation={(checked: boolean): CheckBoxLocation => ({
                        type: LocationTypes.CHECKBOX,
                        idRootStep: location.idRootStep,
                        idStep: step.id,
                        indexStep: null,
                        checked,
                    })}
                />
            );
        }
        case Types.SELECTOR: {
            return <SelectorStep {...props} step={props.step} />;
        }
        case Types.DATEPICKER: {
            return <DatePickerStep {...props} step={props.step} />;
        }
        case Types.TIMEPICKER: {
            return <TimePickerStep {...props} step={props.step} />;
        }
        case Types.TEXTAREA: {
            return (
                <TextAreaStep
                    {...props}
                    step={props.step}
                    stepEqualityChecker={(prev, next): boolean =>
                        prev.uniqueSteps.commentStep ===
                        next.uniqueSteps.commentStep
                    }
                >
                    <TextAreaConfig />
                </TextAreaStep>
            );
        }
        case Types.TEXTINPUT: {
            return (
                <TextInputStep {...props} step={props.step} allowValidation />
            );
        }
        case Types.FILEUPLOAD: {
            return <FileUploadStep {...props} step={props.step} />;
        }
        case Types.SEPARATOR: {
            return <SeparatorStep {...props} step={props.step} />;
        }
        default:
            return <div></div>;
    }
}
