import CalendarIcon from '../../../../Icons/CalendarIcon';
import { AgentPropertyTypes } from '../../../../constants/AgentPropertyTypes';
import {
    DraftEntityDataMappingTypes,
    DraftEntityDataTypes,
} from '../../../../constants/Draft/DraftEntityDataTypes';
import { DraftEntityTypes } from '../../../../constants/Draft/DraftEntityTypes';
import { DraftStyleTypes } from '../../../../constants/Draft/DraftStyleTypes';
import { TicketPropertyTypes } from '../../../../constants/TicketPropertyTypes';
import EurekaElementMenuGroup from '../../../@Menus/ElementMenu/EurekaElementMenuGroup';
import Context from '../Context';
import { GenericContextProps } from '../TextEditorContext';

function TicketContexts({
    nested,
    ...props
}: GenericContextProps & { nested: boolean }): JSX.Element {
    return (
        <>
            <Context
                {...props}
                data={{
                    type: DraftEntityDataTypes.TICKET,
                    property: TicketPropertyTypes.CASENUMBER,
                }}
            />
            <Context
                {...props}
                data={{
                    type: DraftEntityDataTypes.TICKET,
                    property: TicketPropertyTypes.SUBJECT,
                }}
            />
            <Context
                {...props}
                data={{
                    type: DraftEntityDataTypes.TICKET,
                    property: TicketPropertyTypes.STATE,
                }}
            />
            {/* <Context
                {...props}
                autoEdit
                data={{
                    type: DraftEntityDataTypes.CLASSIFIER,
                    idRoot: '',
                }}
            /> */}
            <EurekaElementMenuGroup
                {...props}
                icon={CalendarIcon}
                label={'Fechas'}
                renderElements={(): JSX.Element => (
                    <>
                        <Context
                            {...props}
                            data={{
                                type: DraftEntityDataTypes.TICKET,
                                property: TicketPropertyTypes.CREATION_DATE,
                            }}
                        />
                        <Context
                            {...props}
                            data={{
                                type: DraftEntityDataTypes.TICKET,
                                property: TicketPropertyTypes.RESOLUTION_DATE,
                            }}
                        />
                        <Context
                            {...props}
                            data={{
                                type: DraftEntityDataTypes.TICKET,
                                property: TicketPropertyTypes.CLOSED_DATE,
                            }}
                        />
                    </>
                )}
            />
            {!nested && (
                <Context
                    {...props}
                    data={{
                        type: DraftEntityDataTypes.AGENTS,
                        renderType: 'INLINE',
                        block: {
                            depth: 0,
                            text: 'Agente',
                            entityRanges: [
                                {
                                    offset: 0,
                                    length: 6,
                                    key: 0,
                                },
                            ],
                            inlineStyleRanges: [
                                {
                                    offset: 0,
                                    length: 6,
                                    style: DraftStyleTypes.BOLD,
                                },
                            ],
                            key: 'nested',
                            type: 'unstyled',
                        },
                        entityMap: {
                            '0': {
                                type: DraftEntityTypes.EUREKA,
                                mutability: 'IMMUTABLE',
                                data: {
                                    type: DraftEntityDataMappingTypes.AGENT_MAPPING,
                                    property: AgentPropertyTypes.NAME,
                                },
                            },
                        },
                    }}
                    entityStyles={[]}
                />
            )}
        </>
    );
}

export default TicketContexts;
