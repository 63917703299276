import React from 'react';
import styles from './LoginPage.module.css';
import { Switch, Route } from 'react-router-dom';
import LoginComponent from './LoginComponent/LoginComponent';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import SetPassword from './SetPassword/SetPassword';

/**
 * Displays the specific login component depending on the url
 * @returns the respective component
 */
function LoginPage(): JSX.Element {
    return (
        <div className={styles.loginLayout}>
            <div className={styles.semiCircle}></div>
            <div className={styles.triangle}></div>
            <div className={styles.cardLayout}>
                <div className={styles.loginCard}>
                    <div className={styles.cardContainer}>
                        <Switch>
                            <Route path="/login">
                                <LoginComponent />
                            </Route>
                            <Route path="/setPassword/:token">
                                <SetPassword />
                            </Route>
                            <Route path="/forgotPassword">
                                <ForgotPassword />
                            </Route>
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default LoginPage;
