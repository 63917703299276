import StepComponent, { StepComponentProps } from '../../Step';
import styles from './TextStep.module.css';
import React from 'react';
import StepContainer from '../../StepContainer/StepContainer';
import ClientReply from '../../ClientReply/ClientReply';
import { TextStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import DraftRenderer from '../../../../../../shared/DraftRenderer/DraftRenderer';
import { EditorState, convertFromRaw } from 'draft-js';
import LockedIcon from '../../../../../../Icons/LockedIcon';
import RoundedSelect from '../../../../../../shared/RoundedSelect/RoundedSelect';
import { MenuItem } from '@material-ui/core';
import UnlockedIcon from '../../../../../../Icons/UnlockedIcon';
import ClearIcon from '../../../../../../Icons/ClearIcon';
import AddStep from '../../../AddStep/AddStep';
import { LocationTypes } from '../../../../../../constants/Conversations/ConversationStepTypes';
interface TextStepProps extends StepComponentProps {
    step: TextStep;
}

function TextStepComponent({
    step,
    path,
    dragging,
}: TextStepProps): JSX.Element {
    const [showLocked, setShowLocked] = React.useState(false);

    return (
        <React.Fragment>
            <StepContainer step={step} path={path}>
                <div
                    className={styles.messageContainer}
                    style={{
                        paddingLeft: step.authentication ? 20 : 0,
                    }}
                >
                    {step.authentication && (
                        <LockedIcon
                            style={{
                                width: 20,
                                height: 20,
                                position: 'absolute',
                                top: 0,
                                left: -4,
                            }}
                            fill={'var(--accent)'}
                        />
                    )}
                    <DraftRenderer
                        editorState={EditorState.createWithContent(
                            convertFromRaw(step.message)
                        )}
                    />
                </div>
            </StepContainer>
            <ClientReply>
                <div className={styles.replyContent}>
                    <div className={styles.w2}></div>
                    <div className={styles.w3}></div>
                    <div className={styles.w1}></div>
                    {step.authentication ? (
                        <AuthPathPicker
                            showLocked={showLocked}
                            setShowLocked={setShowLocked}
                        />
                    ) : (
                        <div className={styles.w2}></div>
                    )}
                </div>
            </ClientReply>
            {showLocked && step.authentication && (
                <>
                    {step.authentication.errorSteps.map(
                        (idStep: string, index: number) => (
                            <StepComponent
                                key={index}
                                idStep={idStep}
                                path={[
                                    ...path,
                                    {
                                        idStep: step.id,
                                        type: LocationTypes.AUTH_ERROR,
                                        indexStep: index,
                                        label: 'Error ' + step.name,
                                    },
                                ]}
                            />
                        )
                    )}
                    <AddStep
                        disabled={dragging}
                        location={{
                            idStep: step.id,
                            type: LocationTypes.AUTH_ERROR,
                            indexStep: step.authentication.errorSteps.length,
                            label: null,
                        }}
                    />
                    <div className={styles.endingContainer}>
                        <div className={styles.separator}></div>
                        <div className={styles.endingLbl}>
                            Fin {step.name} - No Autorizado
                        </div>
                        <div className={styles.endSeparator}></div>
                    </div>
                </>
            )}
        </React.Fragment>
    );
}

export default TextStepComponent;

interface AuthPathPickerProps {
    showLocked: boolean;
    setShowLocked: (locked: boolean) => void;
}

function AuthPathPicker({
    showLocked,
    setShowLocked,
}: AuthPathPickerProps): JSX.Element {
    return (
        <div className={styles.authPickerContainer}>
            <RoundedSelect
                value={showLocked ? 'LOCKED' : 'UNLOCKED'}
                backgroundColor="var(--secondary2)"
                outlineColor="var(--secondary2)"
                containerMargin="0"
                handleUpdate={(): void => setShowLocked(!showLocked)}
                height="22px"
                paddingLeft={5}
                paddingRight={27}
                fullWidth
                minWidth={0}
            >
                <MenuItem
                    value={'UNLOCKED'}
                    style={{
                        padding: '0px 13px',
                        height: 24,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <UnlockedIcon
                            style={{
                                width: 18,
                                height: 18,
                            }}
                            fill={'var(--secondary)'}
                        />
                    </div>
                </MenuItem>
                <MenuItem
                    value="LOCKED"
                    style={{
                        padding: '0px 13px',
                        height: 24,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <UnlockedIcon
                            style={{
                                width: 18,
                                height: 18,
                            }}
                            fill={'var(--secondary)'}
                        />
                        <ClearIcon
                            style={{
                                width: 18,
                                height: 18,
                                marginTop: 1,
                                marginLeft: 1,
                                position: 'absolute',
                            }}
                            fill={'var(--error)'}
                        />
                    </div>
                </MenuItem>
            </RoundedSelect>
        </div>
    );
}
