import styles from './NextMenu.module.css';
import React, { useState } from 'react';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Section } from '../../../../@Types/GenericForm';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import Dialog from '../../../Dialog/Dialog';
import CreateSection from './CreateSection/CreateSection';
interface NextMenuProps {
    handleClose: Function;
    handleCreateSection: (section: Section) => void;
    anchorRef: any;
}

export default function NextMenu({
    handleClose,
    anchorRef,
    handleCreateSection,
}: NextMenuProps): JSX.Element {
    const [showCreateSection, setShowCreateSection] = useState(false);

    return (
        <ClickAwayListener
            mouseEvent="onMouseDown"
            onClickAway={(): void => {
                handleClose();
            }}
        >
            <Popper
                open={true}
                anchorEl={anchorRef.current}
                placement={'bottom-end'}
                modifiers={{
                    preventOverflow: {
                        enabled: true,
                        priority: ['top', 'bottom', 'left', 'right'],
                        boundariesElement: 'viewport',
                        padding: 20,
                    },
                }}
                style={{ zIndex: 3 }}
            >
                {showCreateSection && (
                    <Dialog
                        open={showCreateSection}
                        onClose={(): void => setShowCreateSection(false)}
                    >
                        <CreateSection
                            close={(): void => setShowCreateSection(false)}
                            handleCreateSection={handleCreateSection}
                        />
                    </Dialog>
                )}
                <div className={styles.container}>
                    <div
                        className={styles.option}
                        style={{ borderTop: 'none' }}
                        onClick={(): void => {
                            setShowCreateSection(true);
                        }}
                    >
                        <div className={styles.addIcon}>
                            <AddRoundedIcon fontSize="inherit" />
                        </div>
                        <div className={styles.titleOptionLabel}>
                            Agregar Sección
                        </div>
                    </div>
                </div>
            </Popper>
        </ClickAwayListener>
    );
}
