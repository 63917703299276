import styles from './IdentifierTypeComponent.module.css';
import { Controller } from 'react-hook-form';
import ClientInfoTypes, {
    ClientInfoTypeLabels,
} from '../../../constants/ClientInfoTypes';
import React, { useEffect, useState } from 'react';
import { calcStepWidth } from '@arquimedes.co/eureka-forms/dist/FormSteps/StepFunctions';
import RoundedSmartSelect from '../../RoundedSmartSelect/RoundedSmartSelect';
import { fetchIdentifierTypes } from '../../../controllers/ClientsController/ClientsService';
import { CustomStepProps } from '@arquimedes.co/eureka-forms/dist/FormSteps/CustomStep';

interface IdentifierTypeComponentProps extends Omit<CustomStepProps, 'step'> {
    /** children to render */
    children?: React.ReactElement<any, string>;
}
function IdentifierTypeComponent({
    form,
    widthStats,
    editable,
    originalValue,
}: IdentifierTypeComponentProps): JSX.Element {
    const [options, setOptions] = useState<string[] | null>(null);

    const fetchInitialData = async (): Promise<void> => {
        const elements = await fetchIdentifierTypes();
        setOptions(elements);
    };

    useEffect(() => {
        fetchInitialData();
    }, []);

    return (
        <div
            className={styles.container}
            style={{
                width:
                    widthStats.currentBreakPoint <= 2
                        ? '100%'
                        : calcStepWidth(2, form.size),
                minHeight: '55px',
            }}
        >
            <Controller
                name={ClientInfoTypes.IDENTIFIER_TYPE}
                defaultValue={originalValue}
                shouldUnregister
                render={({ field }): JSX.Element => (
                    <RoundedSmartSelect
                        fullWidth
                        label={
                            ClientInfoTypeLabels[
                                ClientInfoTypes.IDENTIFIER_TYPE
                            ]
                        }
                        disabled={!editable}
                        loading={options === null}
                        seed={'EF-'}
                        searchable
                        {...field}
                        handleUpdate={field.onChange}
                        ref={undefined}
                        innerRef={field.ref}
                        creatable
                        options={options ? options : []}
                    />
                )}
            />
        </div>
    );
}

export default IdentifierTypeComponent;
