import React, { useState } from 'react';
import styles from './ViewFormActivityAction.module.css';
import RoundedButton from '../../../../../../../shared/RoundedButton/RoundedButton';
import FormRenderer from '../../../../../../../shared/FormRenderer/FormRenderer';
import {
    ClientViewFormActivity,
    ViewFormActivity,
} from '../../../../../../../@Types/ProcessTypes/Activity';
import FormDialog from '../../../../../../../shared/FormRenderer/FormDialog/FormDialog';
export interface ViewFormActivityProps {
    activity: ViewFormActivity | ClientViewFormActivity;
    values: Record<string, any>;
}

function ViewFormActivityComponent({
    values,
    activity,
}: ViewFormActivityProps): JSX.Element {
    const { label, rootSteps, previewSteps, steps, size } = activity;
    const [showDialog, setShowDialog] = useState(false);

    const renderForm = (): JSX.Element | void => {
        if (rootSteps) {
            return (
                <React.Fragment>
                    {previewSteps && previewSteps.length > 0 && (
                        <FormRenderer
                            rootSteps={previewSteps}
                            postview={true}
                            form={{
                                steps,
                                size: {
                                    blockNum: 2,
                                    blockSize: 210,
                                    spacingSize: 20,
                                },
                            }}
                            values={values}
                        />
                    )}
                    {rootSteps.length > 0 &&
                        (previewSteps.length !== rootSteps.length ||
                            previewSteps.find(
                                (idStep) => !rootSteps.includes(idStep)
                            )) && (
                            <div>
                                <RoundedButton
                                    onClick={(): void => {
                                        setShowDialog(true);
                                    }}
                                    backgroundColor={'var(--secondary)'}
                                    text={
                                        label ??
                                        (rootSteps.length > 0
                                            ? 'Ver más'
                                            : 'Ver Formulario')
                                    }
                                    fontSize={'15px'}
                                    padding="8px 16px"
                                />
                            </div>
                        )}
                </React.Fragment>
            );
        }
    };

    return (
        <React.Fragment>
            {showDialog && (
                <FormDialog
                    label={activity.name}
                    postview={true}
                    rootSteps={rootSteps}
                    form={{
                        steps,
                        size,
                    }}
                    values={values}
                    onClose={(): void => setShowDialog(false)}
                />
            )}
            <div className={styles.container}>
                {activity.helperText && (
                    <label
                        className={styles.helperMessageLbl}
                        style={{
                            marginBottom:
                                previewSteps && previewSteps.length > 0
                                    ? 10
                                    : 20,
                        }}
                    >
                        {activity.helperText}
                    </label>
                )}
                {renderForm()}
            </div>
        </React.Fragment>
    );
}

export default ViewFormActivityComponent;
