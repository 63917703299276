import styles from './RoundedProjectPicker.module.css';
import RoundedSmartSelect, {
    RoundedSmartSelectProps,
} from '../../RoundedSmartSelect/RoundedSmartSelect';
import { useEffect, useState } from 'react';
import React from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';

interface ProjectPickerProps extends RoundedSmartSelectProps {
    macroProjects?: boolean;
    idMacroProject?: string;
}
function ProjectPicker({
    value,
    onChange,
    inputRef,
    macroProjects = false,
    idMacroProject,
    ...others
}: ProjectPickerProps): JSX.Element {
    const [options, setOptions] = useState<any[] | null | undefined>();
    const [loading, setLoading] = useState(false);
    const siteInfo = useSelector((state: RootState) => state.site);
    const calcOptions = async (idOrganization: string): Promise<void> => {
        const values = (
            await axios.get(
                `https://integrations.${
                    process.env.REACT_APP_DOMAIN
                }/sinco/cbr/${
                    macroProjects ? 'MacroProyectos' : 'Proyectos'
                }?idOrganization=${idOrganization}`
            )
        ).data;
        setOptions(values);
        setLoading(false);
    };
    useEffect(() => {
        if (siteInfo.organization)
            calcOptions(siteInfo.organization.idOrganization);
    }, []);

    const filterOptions = (options: any[]): any[] => {
        if (idMacroProject && !macroProjects) {
            return options.filter(
                (project) => project.idMacroProyecto === idMacroProject
            );
        }
        return options;
    };

    return (
        <div
            className={styles.container}
            onClick={(): void => {
                if (options === undefined) {
                    setLoading(true);
                }
            }}
        >
            <RoundedSmartSelect
                {...others}
                searchable
                value={value}
                disabled={options === null}
                loading={loading}
                options={filterOptions(options ?? [])}
                inputRef={inputRef}
                fullWidth
                backgroundColor={'white'}
                height={'31px'}
                icon={
                    <ProjectIcon
                        fill="#757575"
                        style={{
                            display: 'flex',
                            height: 23,
                            width: 23,
                        }}
                    />
                }
                containerMargin="0px"
                handleUpdate={(value: any): void => {
                    onChange?.(value);
                }}
                getOptionSelected={(option, value): boolean =>
                    option.id === value.id
                }
            />
        </div>
    );
}

export default ProjectPicker;

const ProjectIcon = (props: any): any => (
    <svg
        fill={props.fill}
        className={props.className}
        style={props.style}
        height="480pt"
        viewBox="0 -8 480 480"
        width="480pt"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="m440 448v-49.960938c10.546875-6.214843 16.726562-17.816406 16-30.039062 0-17.671875-10.742188-32-24-32s-24 14.328125-24 32c-.726562 12.222656 5.453125 23.824219 16 30.039062v49.960938h-56v-243.054688l-48-24v43.054688h24v16h-24v32h24v16h-24v32h24v16h-24v32h24v16h-24v32h24v16h-24v16h-16v-321.96875l-96-27.421875v29.390625h56v16h-56v32h56v16h-56v32h56v16h-56v32h56v16h-56v32h56v16h-56v32h56v16h-56v32h56v16h-56v16h-16v-448h-144v32h72v16h-72v32h72v16h-72v32h72v16h-72v32h72v16h-72v32h72v16h-72v32h72v16h-72v32h72v16h-72v32h72v16h-72v32h72v16h-72v16h-48v16h480v-16zm0 0" />
    </svg>
);
