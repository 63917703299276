import styles from './TermsEditor.module.css';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { nanoid } from 'nanoid';
import TermComponent from './Term/Term';
import { updateTerms } from '../../../../controllers/GenericEditorController/GenericEditorActions';
import { Term } from '../../../../@Types/GenericForm';
import { useFormEditorSelector } from '../../FormReducerFunctions';
import { useGenericEditorDispatch } from '../../../../shared/GenericFormEditor/GenericFormEditorHooks';

function TermsEditor(): JSX.Element {
    const dispatch = useGenericEditorDispatch();
    const editorInfo = useFormEditorSelector((editorInfo) => editorInfo);
    return (
        <div className={styles.termsContainer}>
            <div className={styles.termsLabel + ' noselect'}>
                Términos y Condiciones:
            </div>
            {editorInfo.terms.map((term, index) => (
                <TermComponent
                    key={index}
                    term={term}
                    handleDelete={(): void => {
                        const tempTerms = [...editorInfo.terms];
                        tempTerms.splice(index, 1);
                        dispatch(updateTerms(tempTerms));
                    }}
                    updateTerm={(pTerm: Term): void => {
                        const tempTerms = [...editorInfo.terms];
                        tempTerms[index] = pTerm;
                        dispatch(updateTerms(tempTerms));
                    }}
                />
            ))}
            <div
                className={styles.addTermButton}
                onClick={(): void => {
                    const tempTerms = [...editorInfo.terms];
                    tempTerms.push({
                        id: 'TERM&COND-' + nanoid(),
                        message: 'Acepto los {{Términos y Condiciones}}',
                        clickEffect: {
                            type: 'LINK',
                            url: 'https://capta.co',
                        },
                    });
                    dispatch(updateTerms(tempTerms));
                }}
            >
                <div className={styles.addIconContainer}>
                    <AddRoundedIcon fontSize="inherit" />
                </div>
                <div className={styles.addTermLbl + ' noselect'}>Agregar</div>
            </div>
        </div>
    );
}

export default TermsEditor;
