import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import { Controller, useForm } from 'react-hook-form';
import templateService, {
    TemplatesApi,
} from '../../../controllers/TemplatesController/TemplatesService';
import styles from './CreateTemplate.module.css';
import RoundedTextField from '../../../shared/RoundedTextField/RoundedTextField';
import RoundedButton from '../../../shared/RoundedButton/RoundedButton';
import {
    PayloadEditorActions,
    usePayloadEditorDispatch,
} from '../../../controllers/PayloadEditorController/PayloadEditorSlice';
import PayloadEditor, {
    BasePayloadEditorConditionTypes,
} from '../../../shared/TextEditor/PayloadEditor';
import { Payload } from '../../../@Types/Payload';
import { useMemo } from 'react';
import ConditionTypes from '../../../constants/Conditions/ConditionTypes';

const idEditor = 'Templates';

interface CreateTemplateProps {
    /** Function that handles when canceled or created */
    close: Function;
    /** Function that handles when template has been created */
    handleCreated: Function;
    /** Function inherited from the dialog to activate the loader */
    setLoading?: Function;
}
/**
 * Component rendered inside the create template dialog.
 */
function CreateTemplate({
    close,
    handleCreated,
    setLoading,
}: CreateTemplateProps): JSX.Element {
    const dispatch = usePayloadEditorDispatch();
    const idProject = useSelector((state: RootState) => state.site.idProject);

    const {
        getValues,
        control,
        formState: { errors, isDirty, isValid },
        trigger,
    } = useForm({
        mode: 'onChange',
    });

    /**
     * Function triggered when user want to create an template, checks if values are valid and calls the correct service
     */
    const handleCreate = async (): Promise<void> => {
        trigger();
        if (!(isValid && isDirty && idProject && setLoading)) {
            return;
        }

        const payload: Payload | null = await dispatch(
            PayloadEditorActions.calcPayload({ idEditor }) as any
        ).unwrap();
        if (!payload) return;

        const values = getValues();
        values.description = values.description ?? '';
        try {
            setLoading(true);
            await templateService.createTemplate(idProject, values, payload);
            dispatch(TemplatesApi.util.invalidateTags(['Templates']));
            setLoading(false);
            handleCreated();
            close();
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    const conditions = useMemo(() => {
        const types = [...BasePayloadEditorConditionTypes];
        types.push(ConditionTypes.ENTRY);
        return { types };
    }, []);

    return (
        <div
            className={styles.container + ' standard-dialog'}
            data-testid={'CreateTemplate'}
        >
            <h2 className="center-anything">Crear Plantilla</h2>
            <div
                className="text-field-container"
                data-testid={'CreateTemplate_name'}
            >
                <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: 'El nombre es obligatorio',
                    }}
                    render={({ field }): JSX.Element => (
                        <RoundedTextField
                            {...field}
                            label="Nombre"
                            helperText={errors.name ? errors.name.message : ''}
                            error={!!errors.name}
                        />
                    )}
                />
            </div>
            <div
                className="text-field-container"
                data-testid={'CreateTemplate_description'}
            >
                <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    render={({ field }): JSX.Element => (
                        <RoundedTextField
                            {...field}
                            label="Descripción"
                            helperText={
                                errors.description
                                    ? errors.description.message
                                    : ''
                            }
                            error={!!errors.description}
                        />
                    )}
                />
            </div>
            <div className={styles.payloadContainer} data-testid={'payload'}>
                <PayloadEditor
                    placeholder={'Plantilla'}
                    idEditor={idEditor}
                    required
                    autoFocus
                    context={{
                        ticket: true,
                        agent: true,
                        client: true,
                    }}
                    conditions={conditions}
                />
            </div>
            <div className="center-anything">
                <div className="button">
                    <RoundedButton
                        text="Cancelar"
                        onClick={(): void => {
                            close();
                        }}
                    />
                </div>
                <div className="button" data-testid={'CreateTemplate_Confirm'}>
                    <RoundedButton
                        text="Crear plantilla"
                        backgroundColor="var(--secondary)"
                        color="white"
                        disabled={!!errors.name}
                        onClick={handleCreate}
                    />
                </div>
            </div>
        </div>
    );
}
export default CreateTemplate;
