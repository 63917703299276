import { useContext } from 'react';
import { EurekaDraft } from '../../../@Types/Draft/Draft';
import {
    PayloadEditorActions,
    PayloadEditorContext,
    usePayloadEditorDispatch,
} from '../../../controllers/PayloadEditorController/PayloadEditorSlice';
import BrainIcon from '../../../Icons/BrainIcon';
import styles from './AIButton.module.css';

interface AIGeneratorButtonProps {
    generate: (draft: EurekaDraft) => Promise<EurekaDraft>;
}

function AIGeneratorButton({ generate }: AIGeneratorButtonProps): JSX.Element {
    const idEditor = useContext(PayloadEditorContext);
    const dispatch = usePayloadEditorDispatch();
    return (
        <>
            <button
                className={styles.AIButton}
                data-testid={'AIButton'}
                onClick={(): void =>
                    dispatch(
                        PayloadEditorActions.generateAI({
                            idEditor,
                            generate,
                        }) as any
                    )
                }
            >
                <BrainIcon
                    style={{
                        display: 'flex',
                        background: 'white',
                    }}
                    fill="inherit"
                />
            </button>
        </>
    );
}

export default AIGeneratorButton;
