import styles from './ValueFilters.module.css';
import React, { useEffect, useState } from 'react';
import Search from '../../../../../shared/Search/Search';
import { Entity } from '../../../../../@Types/@Types';
import { EntityProperty } from '../../../../../@Types/EntityTypes/EntityProperty';
import EntityPropertyTypes from '../../../../../constants/EntityPropertyTypes';
import RoundedSmartSelect from '../../../../../shared/RoundedSmartSelect/RoundedSmartSelect';
import { fetchUniqueValues } from '../../../../../controllers/EntitiesController/EntitiesService';

interface ValueFiltersProps {
    entity: Entity;
    /** Function called when classifiers change */
    handleChange: (filters: Record<string, any>) => void;
    /** The currently selected classifiers */
    values: Record<string, any>;
    /** Tabs component to display after the search */
    tabsComponent: JSX.Element;
}
function ValueFilters({
    values,
    entity,
    handleChange,
    tabsComponent,
}: ValueFiltersProps): JSX.Element {
    return (
        <React.Fragment>
            <div className={styles.searchContainer}>
                <Search
                    wait={500}
                    placeholder={'Buscar'}
                    search={''}
                    disabled
                    handleLoadingSearch={(): void => {}}
                    handleSearch={(): void => {}}
                />
            </div>
            {tabsComponent}
            <div className={styles.filtersContainer}>
                {entity.idFilterSteps.map((idProperty) => (
                    <ValueFilterComponent
                        key={idProperty}
                        idEntity={entity._id}
                        step={entity.steps[idProperty]}
                        value={values[idProperty]}
                        handleChange={(value): void => {
                            const newValues = { ...values };
                            if (!value) {
                                delete newValues[idProperty];
                            } else {
                                newValues[idProperty] = value;
                            }
                            handleChange(newValues);
                        }}
                    />
                ))}
            </div>
        </React.Fragment>
    );
}

export default ValueFilters;

interface ValueFilterComponentProps {
    idEntity: string;
    step: EntityProperty;
    value: any;
    handleChange: (value: any) => void;
    label?: string;
}

export function ValueFilterComponent({
    step,
    label,
    value,
    idEntity,
    handleChange,
}: ValueFilterComponentProps): JSX.Element {
    const [options, setOptions] = useState<string[]>();
    const [loading, setLoading] = useState(false);

    const loadOptions = async (): Promise<void> => {
        setLoading(true);
        const values = await fetchUniqueValues(idEntity, step.id);
        if (Array.isArray(values))
            setOptions(
                values.map((value) =>
                    value?.value && value?.label
                        ? value
                        : { value, label: value }
                )
            );
        setLoading(false);
    };

    useEffect(() => {
        loadOptions();
    }, [step.id]);

    if (!step) return <div></div>;

    switch (step.type) {
        case EntityPropertyTypes.TEXTINPUT:
        case EntityPropertyTypes.SELECTOR:
            return (
                <RoundedSmartSelect
                    searchable={!!options?.length && options?.length > 10}
                    value={options ? value : undefined}
                    loading={loading}
                    options={options ?? []}
                    fullWidth
                    label={label ?? step.label}
                    backgroundColor={'white'}
                    height={'31px'}
                    containerMargin="0px"
                    handleUpdate={(value: any): void => {
                        handleChange(value?.value);
                    }}
                    showDefault
                />
            );
        default:
            return <div></div>;
    }
}
