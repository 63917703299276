import { InmuebleInfo360 } from '../../../types';
import { OpenComponent } from '../../Utils/Openable/OpenComponent';
import { calcDate } from '../../../../../utils/DateFunctions';
import { getPrettyCurrencyValue } from '../../Utils/otherFunctions';
import MaterialTable from '../../Utils/MaterialTable';

interface SaldosProps {
    planDePagos: InmuebleInfo360['cartera']['planDePagos'];
}

export function Saldos({ planDePagos }: SaldosProps): JSX.Element {
    let totalValorPactado = 0;
    let totalSaldo = 0;
    let totalDiasMora = 0;
    return (
        <OpenComponent title={'Saldos'}>
            <MaterialTable
                style={{
                    primaryColor: '#3d5a7f',
                    outlineColor: 'rgba(196,184,184,0.52)',
                    fontSize: 13,
                }}
                part={{
                    title: '',
                    headers: [
                        {
                            label: 'Trámite',
                        },
                        {
                            label: 'Fecha pactada',
                        },
                        {
                            label: 'Valor pactado',
                        },
                        {
                            label: 'Saldo',
                        },
                        {
                            label: 'Días de mora',
                        },
                    ],
                    rows: planDePagos.map((pago: any) => {
                        totalValorPactado += pago.valorPactado;
                        totalSaldo += pago.saldo;
                        totalDiasMora += pago.diasMora;
                        return {
                            isHeader: false,
                            data: [
                                {
                                    value: pago.concepto,
                                },
                                {
                                    value: pago.fechaPactada
                                        ? calcDate(
                                              new Date(pago.fechaPactada),
                                              true
                                          )
                                        : 'N/A',
                                },
                                {
                                    value: getPrettyCurrencyValue(
                                        pago.valorPactado
                                    ),
                                },
                                {
                                    value: getPrettyCurrencyValue(pago.saldo),
                                },
                                {
                                    value: pago.diasMora.toString(),
                                },
                            ],
                        };
                    }),
                    totals: [
                        {
                            label: 'Total',
                            colSpan: 2,
                        },
                        {
                            label: getPrettyCurrencyValue(totalValorPactado),
                            colSpan: 1,
                        },
                        {
                            label: getPrettyCurrencyValue(totalSaldo),
                            colSpan: 1,
                        },
                        {
                            label: totalDiasMora.toString(),
                            colSpan: 1,
                        },
                    ],
                }}
            />
        </OpenComponent>
    );
}
