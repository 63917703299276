import { useSelector } from 'react-redux';
import {
    ClientRelationshipDraftEntityData,
    DraftEntityData,
    EntityValuesDraftEntityData,
} from '../../@Types/Draft/DraftEntityData';
import { IconProps } from '../../Icons/@IconTypes';
import IdentifierIcon from '../../Icons/IdentifierIcon';
import PhoneIcon from '../../Icons/PhoneIcon';
import SuitcaseIcon from '../../Icons/SuitcaseIcon';
import TextIcon from '../../Icons/TextIcon';
import { AgentPropertyTypes } from '../../constants/AgentPropertyTypes';
import ClientInfoTypes from '../../constants/ClientInfoTypes';
import TrendingFlatRoundedIcon from '@material-ui/icons/TrendingFlatRounded';
import {
    DraftEntityDataMappingTypes,
    DraftEntityDataTypes,
} from '../../constants/Draft/DraftEntityDataTypes';
import SmartIcon from '../SmartIcons/SmartIcon';
import { RootState } from '../../utils/_store';
import { calcFormStepLabel } from '../../pages/Forms/Steps/AuxStepfunctions';
import FormStepIcon from '../../Icons/FormStepIcon';
import EntityIcon from '../SmartIcons/EntityIcon';
import EntityPropertyTypes from '../../constants/EntityPropertyTypes';
import EntityPropertyIcon from '../../Icons/EntityPropertyIcon';
import ParameterIcon from '../../Icons/ParameterIcon';
import CaptaIcon from '../../Icons/CaptaIcon';
import LinkIcon from '../../Icons/LinkIcon';
import FilterIcon from '../../Icons/FilterIcon';
import CalendarIcon from '../../Icons/CalendarIcon';
import AgentsIcon from '../../Icons/AgentsIcon';
import { TicketPropertyTypes } from '../../constants/TicketPropertyTypes';
import TicketIcon from '../../Icons/TicketIcon';
import StateIcon from '../../Icons/StateIcon';
import { useContext } from 'react';
import { PayloadEditorConceptsContext } from './PayloadEditor';
import { Concept } from '../../@Types/Project';
import { UserTypes } from '../../constants/UserTypes';
import TitleIcon from '../../Icons/TitleIcon';
import EmailIcon from '../../Icons/EmailIcon';
import ShapesIcon from '../../Icons/ShapesIcon';
import { SiteState } from '../../controllers/_SiteController/SiteReducer';
import { getLocale } from '../..';
import { format } from 'date-fns';
import { DraftEntityTypes } from '../../constants/Draft/DraftEntityTypes';
import { Time } from '../../@Types/Time';
import NotificationIcon from '../../Icons/NotificationIcon';
import {
    useAppSelector,
    useClientEntity,
    useCurrentProject,
    useEntity,
} from '../../hooks';
import { useFormEditorSelector } from '../../pages/Forms/FormReducerFunctions';
import { FormStep } from '../../@Types/FormTypes/FormStep';
import { ConversationStep } from '../../@Types/ConversationTypes/ConversationStep';
import ConversationStepIcon from '../../Icons/ConversationStepIcon';
import { IntegrationsApi } from '../../controllers/IntegratrionsController/IntegrationsService';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { DraftIntegration } from '../../@Types/Integration';
import ApiIcon from '../../Icons/ApiIcon';
import { Entity } from '../../@Types/@Types';
import React from 'react';

type EditorTextFunction = (
    site: SiteState,
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    concepts: Concept[],
    steps: Record<string, FormStep>,
    conversationStep: Record<string, ConversationStep>
) => Promise<string> | string;

interface MappedEntityData {
    Label: string | (() => JSX.Element);
    MenuLabel?: string | (() => JSX.Element);
    calcEditorText: EditorTextFunction;
    MenuIcon?: (props: IconProps) => JSX.Element;
    Icon: (props: IconProps) => JSX.Element;
}

function MapEntityData(data: DraftEntityData): MappedEntityData {
    switch (data.type) {
        case DraftEntityDataTypes.AGENT:
        case DraftEntityDataMappingTypes.AGENT_MAPPING:
            switch (data.property) {
                case AgentPropertyTypes.FIRST_NAME:
                    return {
                        Label: 'Nombre (Agente)',
                        MenuLabel: 'Nombre',
                        Icon: TitleIcon,
                        calcEditorText: (): string => 'Agente',
                    };
                case AgentPropertyTypes.NAME:
                    return {
                        Label: 'Nombre Completo (Agente)',
                        MenuLabel: 'Nombre Completo',
                        Icon: TextIcon,
                        calcEditorText: (): string => 'Agente',
                    };
                case AgentPropertyTypes.EMAIL:
                    return {
                        Label: 'Correo Electrónico (Agente)',
                        MenuLabel: 'Correo Electrónico',
                        Icon: EmailIcon,
                        calcEditorText: (): string =>
                            'Correo' +
                            (data.type === DraftEntityDataTypes.AGENT
                                ? ' (Agente)'
                                : ''),
                    };
                case AgentPropertyTypes.JOB_TITLE:
                    return {
                        Label: 'Cargo (Agente)',
                        MenuLabel: 'Cargo',
                        Icon: SuitcaseIcon,
                        calcEditorText: (): string =>
                            'Cargo' +
                            (data.type === DraftEntityDataTypes.AGENT
                                ? ' (Agente)'
                                : ''),
                    };
                default:
                    return EmptyEntityData;
            }
        case DraftEntityDataTypes.CLIENT:
            switch (data.idProperty) {
                case ClientInfoTypes.NAME:
                    return {
                        Icon: ({ style }): JSX.Element => (
                            <div style={style}>T</div>
                        ),
                        Label: 'Nombre(s) (Cliente)',
                        MenuLabel: 'Nombre(s)',
                        calcEditorText: (): string => 'Cliente',
                    };
                case ClientInfoTypes.LASTNAME:
                    return {
                        Icon: ({ style }): JSX.Element => (
                            <div style={style}>T</div>
                        ),
                        Label: 'Apellido(s) (Cliente)',
                        MenuLabel: 'Apellido(s)',
                        calcEditorText: (): string => 'Apellido(s)',
                    };
                case 'FULLNAME':
                    return {
                        Icon: TextIcon,
                        Label: 'Nombre Completo (Cliente)',
                        MenuLabel: 'Nombre Completo',
                        calcEditorText: (): string => 'Cliente',
                    };
                case ClientInfoTypes.EMAIL:
                    return {
                        Label: 'Correo Electrónico (Cliente)',
                        MenuLabel: 'Correo Electrónico',
                        Icon: EmailIcon,
                        calcEditorText: (): string => 'Correo',
                    };
                case ClientInfoTypes.CEL:
                    return {
                        Label: 'Celular (Cliente)',
                        MenuLabel: 'Celular',
                        Icon: PhoneIcon,
                        calcEditorText: (): string => 'Celular',
                    };
                case ClientInfoTypes.IDENTIFIER:
                    return {
                        Label: 'Identificación (Cliente)',
                        MenuLabel: 'Identificación',
                        Icon: IdentifierIcon,
                        calcEditorText: (): string => 'Identificación',
                    };
                default:
                    return {
                        Label: (): JSX.Element => {
                            const entity = useSelector(
                                (state: RootState) =>
                                    state.site.entities[
                                        state.site.organization
                                            ?.idClientEntity ?? ''
                                    ]
                            );

                            const property = entity?.steps[data.idProperty];
                            if (
                                !property ||
                                property.type === EntityPropertyTypes.TITLE ||
                                property.type === EntityPropertyTypes.SEPARATOR
                            )
                                return <></>;
                            return <>{property.label + ' (Cliente)'}</>;
                        },
                        MenuLabel: (): JSX.Element => {
                            const entity = useSelector(
                                (state: RootState) =>
                                    state.site.entities[
                                        state.site.organization
                                            ?.idClientEntity ?? ''
                                    ]
                            );
                            const property = entity?.steps[data.idProperty];

                            if (
                                !property ||
                                property.type === EntityPropertyTypes.TITLE ||
                                property.type === EntityPropertyTypes.SEPARATOR
                            )
                                return <></>;
                            return <>{property.label}</>;
                        },
                        Icon: (props: IconProps): JSX.Element => {
                            const entity = useSelector(
                                (state: RootState) =>
                                    state.site.entities[
                                        state.site.organization
                                            ?.idClientEntity ?? ''
                                    ]
                            );
                            const property = entity?.steps[data.idProperty];
                            if (
                                !property ||
                                property.type === EntityPropertyTypes.TITLE ||
                                property.type === EntityPropertyTypes.SEPARATOR
                            )
                                return <></>;
                            return (
                                <EntityPropertyIcon
                                    {...props}
                                    property={property}
                                />
                            );
                        },
                        calcEditorText: async ({
                            organization,
                            entities,
                        }): Promise<string> => {
                            const entity =
                                entities[organization?.idClientEntity ?? ''];
                            const property = entity?.steps[data.idProperty];

                            if (
                                !property ||
                                property.type === EntityPropertyTypes.TITLE ||
                                property.type === EntityPropertyTypes.SEPARATOR
                            )
                                return '';
                            return property.label;
                        },
                    };
            }
        case DraftEntityDataTypes.INTEGRATION:
        case DraftEntityDataTypes.CLIENT_INTEGRATION: {
            return {
                Label: (): JSX.Element => {
                    return (
                        <IntegrationLabel idIntegration={data.idIntegration} />
                    );
                },
                Icon: (props: IconProps): JSX.Element => {
                    return (
                        <IntegrationIcon
                            {...props}
                            idIntegration={data.idIntegration}
                        />
                    );
                },
                calcEditorText: async (_store, dispatch): Promise<string> => {
                    const { data: integration } = await dispatch(
                        IntegrationsApi.endpoints.loadIntegration.initiate(
                            data.idIntegration
                        )
                    );
                    return (
                        (integration as DraftIntegration)?.editorText ??
                        integration?.name ??
                        ''
                    );
                },
            };
        }
        case DraftEntityDataTypes.PROJECT: {
            return {
                Label:
                    data.property == 'NAME'
                        ? 'Nombre del Ambiente'
                        : 'Descripción del Ambiente',
                MenuLabel: data.property == 'NAME' ? 'Nombre' : 'Descripción',
                Icon: TextIcon,
                calcEditorText: (): string =>
                    data.property == 'NAME'
                        ? 'Ambiente'
                        : 'Descripción del Ambiente',
            };
        }
        case DraftEntityDataTypes.NOTIFICATION: {
            return {
                Label: 'Asunto',
                Icon: NotificationIcon,
                calcEditorText: (): string => 'Asunto Notificación',
            };
        }
        case DraftEntityDataTypes.CONCEPT: {
            return {
                Label: (): JSX.Element => {
                    const project = useCurrentProject();
                    const concept = project?.concepts.find(
                        (concept) => concept.id === data.idConcept
                    );
                    if (!concept) return <></>;
                    return <>{concept.name}</>;
                },
                Icon: (props: IconProps): JSX.Element => {
                    const project = useCurrentProject();

                    const concept = project?.concepts.find(
                        (concept) => concept.id === data.idConcept
                    );
                    if (!concept) return <></>;
                    return <SmartIcon {...props} icon={concept.icon} />;
                },
                calcEditorText: (): string => data.block.text,
            };
        }
        case DraftEntityDataTypes.CLASSIFIER: {
            return {
                Label: (): JSX.Element => {
                    const classifier = useSelector(
                        (state: RootState) =>
                            state.site.classifiers[data.idRoot]
                    );
                    if (!classifier) return <>Clasificador</>;
                    return <>{classifier.name}</>;
                },
                MenuLabel: 'Clasificador',
                Icon: ShapesIcon,
                calcEditorText: ({ classifiers }): string =>
                    classifiers[data.idRoot]?.name ?? 'Clasificador',
            };
        }
        case DraftEntityDataMappingTypes.CONCEPT_MAPPING: {
            return {
                Label: (): JSX.Element => {
                    const concepts = useContext(PayloadEditorConceptsContext);
                    const concept = concepts.find(
                        (concept) => concept.id === data.idConcept
                    );
                    const step = concept?.steps[data.idStep];
                    if (!step) return <></>;

                    const label = calcFormStepLabel(step);

                    return <>{label}</>;
                },
                Icon: (props: IconProps): JSX.Element => {
                    const concepts = useContext(PayloadEditorConceptsContext);
                    const concept = concepts.find(
                        (concept) => concept.id === data.idConcept
                    );
                    const step = concept?.steps[data.idStep];
                    if (!step) return <></>;
                    return <FormStepIcon {...props} type={step.type} />;
                },
                calcEditorText: (_site, dispatch, concepts): string => {
                    const concept = concepts.find(
                        (concept) => concept.id === data.idConcept
                    );
                    const step = concept?.steps[data.idStep];
                    if (!step) return '';
                    return calcFormStepLabel(step);
                },
            };
        }
        case DraftEntityDataTypes.COMPANY: {
            return {
                Label: (): JSX.Element => {
                    const entity = useSelector(
                        (state: RootState) =>
                            state.site.entities[
                                state.site.organization?.idCompanyEntity ?? ''
                            ]
                    );
                    const property = entity?.steps[data.idProperty];
                    if (
                        !property ||
                        property.type === EntityPropertyTypes.TITLE ||
                        property.type === EntityPropertyTypes.SEPARATOR
                    )
                        return <></>;
                    return <>{property.label + ' (Empresa)'}</>;
                },
                MenuLabel: (): JSX.Element => {
                    const entity = useSelector(
                        (state: RootState) =>
                            state.site.entities[
                                state.site.organization?.idCompanyEntity ?? ''
                            ]
                    );
                    const property = entity?.steps[data.idProperty];
                    if (
                        !property ||
                        property.type === EntityPropertyTypes.TITLE ||
                        property.type === EntityPropertyTypes.SEPARATOR
                    )
                        return <></>;
                    return <>{property.label}</>;
                },
                Icon: (props: IconProps): JSX.Element => {
                    const entity = useSelector(
                        (state: RootState) =>
                            state.site.entities[
                                state.site.organization?.idCompanyEntity ?? ''
                            ]
                    );
                    const property = entity?.steps[data.idProperty];
                    if (!property) return <></>;
                    return (
                        <EntityPropertyIcon {...props} property={property} />
                    );
                },
                calcEditorText: ({ organization, entities }): string => {
                    const entity =
                        entities[organization?.idCompanyEntity ?? ''];
                    const property = entity?.steps[data.idProperty];
                    if (property.id === 'name') {
                        return entity.name;
                    }

                    if (
                        !property ||
                        property.type === EntityPropertyTypes.TITLE ||
                        property.type === EntityPropertyTypes.SEPARATOR
                    )
                        return '';
                    return property.label;
                },
            };
        }
        case DraftEntityDataTypes.ENTITYVALUES: {
            return {
                Label: (): JSX.Element => {
                    const { entity, multiple, label } =
                        useEntityValueData(data);

                    if (!entity) return <></>;

                    if (multiple || Object.keys(data.entityMap).length > 1)
                        return (
                            <>
                                {label ?? entity.pluralName}
                                <EntityValuesPath data={data} />
                            </>
                        );

                    const subEntity =
                        data.entityMap[Object.keys(data.entityMap)[0]];

                    if (subEntity.type !== DraftEntityTypes.EUREKA)
                        return <></>;

                    if (
                        subEntity.data.type ===
                        DraftEntityDataMappingTypes.ENTITYVALUE_INTEGRATION_MAPPING
                    ) {
                        return (
                            <>
                                <IntegrationLabel
                                    idIntegration={subEntity.data.idIntegration}
                                />
                                <EntityValuesPath
                                    data={data}
                                    suffix={entity.name}
                                />
                            </>
                        );
                    }
                    if (
                        subEntity.data.type !==
                        DraftEntityDataMappingTypes.ENTITYVALUE_MAPPING
                    )
                        return <></>;

                    const idProperty = subEntity.data.idProperty;
                    const property = entity?.steps[idProperty];
                    if (
                        !property ||
                        property.type === EntityPropertyTypes.TITLE ||
                        property.type === EntityPropertyTypes.SEPARATOR
                    )
                        return <></>;
                    return (
                        <>
                            {property.label}
                            <EntityValuesPath
                                data={data}
                                suffix={entity.name}
                            />
                        </>
                    );
                },
                MenuLabel: (): JSX.Element => {
                    const { entity, multiple, label } =
                        useEntityValueData(data);

                    if (!entity) return <></>;

                    const subEntity =
                        data.entityMap[Object.keys(data.entityMap)[0]];

                    if (subEntity.type !== DraftEntityTypes.EUREKA)
                        return <></>;

                    if (multiple && label) return <>{label}</>;

                    if (
                        subEntity.data.type ===
                        DraftEntityDataMappingTypes.ENTITYVALUE_INTEGRATION_MAPPING
                    ) {
                        return (
                            <IntegrationLabel
                                idIntegration={subEntity.data.idIntegration}
                            />
                        );
                    }

                    if (
                        subEntity.data.type !==
                        DraftEntityDataMappingTypes.ENTITYVALUE_MAPPING
                    )
                        return <></>;

                    const idProperty = subEntity.data.idProperty;
                    const property = entity?.steps[idProperty];
                    if (
                        !property ||
                        property.type === EntityPropertyTypes.TITLE ||
                        property.type === EntityPropertyTypes.SEPARATOR
                    )
                        return <></>;
                    return <>{property.label}</>;
                },
                Icon: (props: IconProps): JSX.Element => {
                    const { entity, multiple } = useEntityValueData(data);

                    if (!entity) return <></>;

                    if (multiple || Object.keys(data.entityMap).length > 1)
                        return (
                            <EntityIcon {...props} idEntity={data.idEntity} />
                        );

                    const subEntity =
                        data.entityMap[Object.keys(data.entityMap)[0]];
                    if (subEntity.type !== DraftEntityTypes.EUREKA)
                        return <></>;

                    if (
                        subEntity.data.type ===
                        DraftEntityDataMappingTypes.ENTITYVALUE_INTEGRATION_MAPPING
                    ) {
                        return (
                            <IntegrationIcon
                                {...props}
                                idIntegration={subEntity.data.idIntegration}
                            />
                        );
                    }

                    if (
                        subEntity.data.type !==
                        DraftEntityDataMappingTypes.ENTITYVALUE_MAPPING
                    )
                        return <></>;
                    const idProperty = subEntity.data.idProperty;
                    const property = entity?.steps[idProperty];
                    if (!property) return <></>;
                    return (
                        <EntityPropertyIcon {...props} property={property} />
                    );
                },
                calcEditorText: (): string => data.block.text,
            };
        }
        case DraftEntityDataTypes.CLIENT_RELATIONSHIP: {
            return {
                Label: (): JSX.Element => {
                    const { entity, multiple } =
                        useClientRelationshipData(data);

                    if (!entity) return <></>;
                    if (multiple || Object.keys(data.entityMap).length > 1)
                        return (
                            <>
                                {entity.pluralName}
                                <EntityValuesPath
                                    data={data}
                                    prefix={'Cliente'}
                                />
                            </>
                        );
                    const subEntity =
                        data.entityMap[Object.keys(data.entityMap)[0]];

                    if (subEntity.type !== DraftEntityTypes.EUREKA)
                        return <></>;

                    if (
                        subEntity.data.type ===
                        DraftEntityDataMappingTypes.ENTITYVALUE_INTEGRATION_MAPPING
                    ) {
                        return (
                            <>
                                <IntegrationLabel
                                    idIntegration={subEntity.data.idIntegration}
                                />
                                <EntityValuesPath
                                    data={data}
                                    prefix={'Cliente'}
                                    suffix={entity.name}
                                />
                            </>
                        );
                    }
                    if (
                        subEntity.data.type !==
                        DraftEntityDataMappingTypes.ENTITYVALUE_MAPPING
                    )
                        return <></>;

                    const idProperty = subEntity.data.idProperty;
                    const property = entity?.steps[idProperty];
                    if (
                        !property ||
                        property.type === EntityPropertyTypes.TITLE ||
                        property.type === EntityPropertyTypes.SEPARATOR
                    )
                        return <></>;
                    return (
                        <>
                            {property.label + ' '}
                            <EntityValuesPath
                                data={data}
                                prefix={'Cliente'}
                                suffix={entity.name}
                            />
                        </>
                    );
                },
                MenuLabel: (): JSX.Element => {
                    const { entity } = useClientRelationshipData(data);

                    if (!entity) return <></>;

                    const subEntity =
                        data.entityMap[Object.keys(data.entityMap)[0]];
                    if (subEntity.type !== DraftEntityTypes.EUREKA)
                        return <></>;

                    if (
                        subEntity.data.type ===
                        DraftEntityDataMappingTypes.ENTITYVALUE_INTEGRATION_MAPPING
                    ) {
                        return (
                            <IntegrationLabel
                                idIntegration={subEntity.data.idIntegration}
                            />
                        );
                    }

                    if (
                        subEntity.data.type !==
                        DraftEntityDataMappingTypes.ENTITYVALUE_MAPPING
                    )
                        return <></>;

                    const idProperty = subEntity.data.idProperty;
                    const property = entity?.steps[idProperty];
                    if (
                        !property ||
                        property.type === EntityPropertyTypes.TITLE ||
                        property.type === EntityPropertyTypes.SEPARATOR
                    )
                        return <></>;
                    return <>{property.label}</>;
                },
                Icon: (props: IconProps): JSX.Element => {
                    const { entity, multiple } =
                        useClientRelationshipData(data);

                    if (!entity) return <></>;
                    if (multiple || Object.keys(data.entityMap).length > 1)
                        return (
                            <EntityIcon {...props} idEntity={data.idEntity} />
                        );

                    const subEntity =
                        data.entityMap[Object.keys(data.entityMap)[0]];
                    if (subEntity.type !== DraftEntityTypes.EUREKA)
                        return <></>;

                    if (
                        subEntity.data.type ===
                        DraftEntityDataMappingTypes.ENTITYVALUE_INTEGRATION_MAPPING
                    ) {
                        return (
                            <IntegrationIcon
                                {...props}
                                idIntegration={subEntity.data.idIntegration}
                            />
                        );
                    }

                    if (
                        subEntity.data.type !==
                        DraftEntityDataMappingTypes.ENTITYVALUE_MAPPING
                    )
                        return <></>;
                    const idProperty = subEntity.data.idProperty;
                    const property = entity?.steps[idProperty];
                    if (!property) return <></>;
                    return (
                        <EntityPropertyIcon {...props} property={property} />
                    );
                },
                calcEditorText: (): string => data.block.text,
            };
        }
        case DraftEntityDataMappingTypes.ENTITYVALUE_MAPPING: {
            return {
                Label: (): JSX.Element => {
                    const entity = useEntity(data.idEntity);
                    if (!entity) return <></>;

                    const property = entity?.steps[data.idProperty];

                    if (
                        !property ||
                        property.type === EntityPropertyTypes.TITLE ||
                        property.type === EntityPropertyTypes.SEPARATOR
                    )
                        return <></>;
                    return <>{property.label}</>;
                },
                Icon: (props: IconProps): JSX.Element => {
                    const entity = useEntity(data.idEntity);

                    const property = entity?.steps[data.idProperty];
                    if (
                        !property ||
                        property.type === EntityPropertyTypes.TITLE ||
                        property.type === EntityPropertyTypes.SEPARATOR
                    )
                        return <></>;
                    return (
                        <EntityPropertyIcon {...props} property={property} />
                    );
                },
                calcEditorText: async ({ entities }): Promise<string> => {
                    const entity = entities[data.idEntity];
                    const property = entity?.steps[data.idProperty];
                    if (data.idProperty === entity.idLabelStep)
                        return entity.name;
                    if (
                        !property ||
                        property.type === EntityPropertyTypes.TITLE ||
                        property.type === EntityPropertyTypes.SEPARATOR
                    )
                        return '';
                    return property.label;
                },
            };
        }
        case DraftEntityDataMappingTypes.ENTITYVALUE_INTEGRATION_MAPPING: {
            return {
                Label: (): JSX.Element => {
                    return (
                        <IntegrationLabel idIntegration={data.idIntegration} />
                    );
                },
                Icon: (props: IconProps): JSX.Element => {
                    return (
                        <IntegrationIcon
                            {...props}
                            idIntegration={data.idIntegration}
                        />
                    );
                },
                calcEditorText: async (_store, dispatch): Promise<string> => {
                    const { data: integration } = await dispatch(
                        IntegrationsApi.endpoints.loadIntegration.initiate(
                            data.idIntegration
                        )
                    );
                    return (
                        (integration as DraftIntegration)?.editorText ??
                        integration?.name ??
                        ''
                    );
                },
            };
        }
        case DraftEntityDataTypes.NESTED: {
            return {
                Icon: (props: IconProps): JSX.Element => (
                    <ParameterIcon
                        {...props}
                        style={{
                            ...props.style,
                            width: 24,
                            height: 24,
                            marginTop: -2,
                            marginLeft: -2,
                        }}
                    />
                ),
                MenuIcon: (props: IconProps): JSX.Element => (
                    <ParameterIcon
                        {...props}
                        style={{
                            ...props.style,
                            width: 34,
                            height: 34,
                            marginLeft: -3,
                        }}
                    />
                ),
                Label: 'Grupo de Parámetros',
                calcEditorText: (): string => data.block.text,
            };
        }
        case DraftEntityDataTypes.ORGANIZATION: {
            return {
                Icon: (props: IconProps): JSX.Element => {
                    const organization = useSelector(
                        (state: RootState) => state.site.organization
                    );
                    if (!organization) return <CaptaIcon {...props} />;
                    return (
                        <img
                            {...props}
                            src={organization.branding.images.iconUrl}
                        />
                    );
                },
                Label: 'Nombre',
                calcEditorText: (): string => 'Organización',
            };
        }
        case DraftEntityDataTypes.TICKET_LINK: {
            return {
                Label: 'Enlace al Caso',
                Icon: LinkIcon,
                calcEditorText: ({ organization }): string =>
                    `https://${organization?.idOrganization}${
                        data.linkType === UserTypes.AGENT ? '' : '.clients'
                    }.capta.co/12345678/tickets/12345678`,
            };
        }
        case DraftEntityDataTypes.TICKET_VALUE: {
            return {
                Icon: (props: IconProps): JSX.Element => {
                    const project = useCurrentProject();
                    const step = project?.values.values[
                        data.idValue
                    ] as FormStep;
                    if (!step) return <></>;

                    return <FormStepIcon {...props} type={step.type} />;
                },
                Label: (): JSX.Element => {
                    const project = useCurrentProject();
                    const step = project?.values.values[
                        data.idValue
                    ] as FormStep;
                    if (!step) return <></>;
                    return <>{calcFormStepLabel(step)}</>;
                },
                calcEditorText: (site): string =>
                    calcFormStepLabel(
                        site.projects[site.idProject ?? ''].values.values[
                            data.idValue
                        ] as FormStep
                    ),
            };
        }
        case DraftEntityDataTypes.CONDITION_MET: {
            return {
                Label: 'Cumple Condición',
                Icon: FilterIcon,
                calcEditorText: (): string => 'Sí/No',
            };
        }
        case DraftEntityDataTypes.DATE: {
            return {
                Label: calcDateLabel(data.time),
                Icon: CalendarIcon,
                MenuLabel: 'Calcular Fecha',
                calcEditorText: (): string => {
                    return format(
                        new Date(2018, 9, 5, 14, 27),
                        data.format ?? 'Pp',
                        {
                            locale: getLocale(),
                        }
                    );
                },
            };
        }
        case DraftEntityDataTypes.TICKET: {
            switch (data.property) {
                case TicketPropertyTypes.CASENUMBER:
                    return {
                        Icon: TicketIcon,
                        Label: 'Número de Caso',
                        calcEditorText: (): string => 'ABC123',
                    };
                case TicketPropertyTypes.SUBJECT:
                    return {
                        Icon: TextIcon,
                        Label: 'Asunto',
                        calcEditorText: (): string => 'Asunto',
                    };
                case TicketPropertyTypes.STATE:
                    return {
                        Icon: StateIcon,
                        Label: 'Estado',
                        calcEditorText: (): string => 'Estado',
                    };
                case TicketPropertyTypes.CREATION_DATE:
                    return {
                        Icon: CalendarIcon,
                        Label: 'Fecha de Creación',
                        calcEditorText: (): string => 'Fecha de Creación',
                    };
                case TicketPropertyTypes.RESOLUTION_DATE:
                    return {
                        Icon: CalendarIcon,
                        Label: 'Fecha de Vencimiento',
                        calcEditorText: (): string => 'Fecha de Vencimiento',
                    };
                case TicketPropertyTypes.CLOSED_DATE:
                    return {
                        Icon: CalendarIcon,
                        Label: 'Fecha de Cierre',
                        calcEditorText: (): string => 'Fecha de Cierre',
                    };
                default:
                    return EmptyEntityData;
            }
        }
        case DraftEntityDataTypes.FORM_STEP: {
            return {
                Icon: (props: IconProps): JSX.Element => {
                    const step = useFormEditorSelector(
                        (state) => state.steps[data.idStep]
                    );
                    if (!step) return <></>;

                    return <FormStepIcon {...props} type={step.type} />;
                },
                Label: (): JSX.Element => {
                    const step = useFormEditorSelector(
                        (state) => state.steps[data.idStep]
                    );
                    if (!step) return <></>;
                    return <>{calcFormStepLabel(step)}</>;
                },
                calcEditorText: (_site, dispatch, concepts, steps): string =>
                    calcFormStepLabel(steps[data.idStep]),
            };
        }
        case DraftEntityDataTypes.CONVERSATION_STEP: {
            return {
                Icon: (props: IconProps): JSX.Element => {
                    const step = useSelector(
                        (state: RootState) =>
                            state.conversationEditor.steps[data.idStep ?? '']
                    );
                    if (!step) return <></>;

                    return <ConversationStepIcon {...props} type={step.type} />;
                },
                Label: (): JSX.Element => {
                    const step = useSelector(
                        (state: RootState) =>
                            state.conversationEditor.steps[data.idStep ?? '']
                    );
                    if (!step) return <></>;
                    return <>{step.name}</>;
                },
                calcEditorText: (
                    _site,
                    dispatch,
                    concepts,
                    steps,
                    convSteps
                ): string => convSteps[data.idStep]?.name,
            };
        }
        case DraftEntityDataTypes.AGENTS: {
            return {
                Icon: AgentsIcon,
                Label: 'Agentes Asignados',
                calcEditorText: (): string => data.block.text,
            };
        }
        default:
            return EmptyEntityData;
    }
}

export function EntityDataMapper(data?: DraftEntityData): MappedEntityData {
    if (!data) return EmptyEntityData;
    const mappings = MapEntityData(data);
    return {
        ...mappings,
        calcEditorText: async (...props): Promise<string> =>
            `${data.prefix ?? ''}${await mappings.calcEditorText(...props)}${
                data.suffix ?? ''
            }`,
    };
}

const useEntityValueData = ({
    path,
    idEntity,
}: EntityValuesDraftEntityData): {
    entity: Entity | undefined;
    multiple: boolean;
    label?: string;
} => {
    const entities = useAppSelector((state) => state.site.entities);
    const entity = entities[idEntity];
    const project = useCurrentProject();
    if (!path) {
        const projectEntity = project?.entities.find(
            (entity) => entity.idEntity == idEntity
        );
        if (!projectEntity) return { entity: undefined, multiple: false };
        return { entity, multiple: projectEntity.multiple };
    }
    const parent = entities[path[path.length - 1]];
    const relationship = parent?.relationships.find(
        (rel) => rel.idEntity === idEntity
    );
    if (!relationship) return { entity: undefined, multiple: false };
    return {
        entity,
        label: relationship.name,
        multiple: relationship.multiple,
    };
};

function EntityValuesPath({
    data,
    prefix,
    suffix,
}: {
    prefix?: string;
    suffix?: string;
    data: ClientRelationshipDraftEntityData | EntityValuesDraftEntityData;
}): JSX.Element {
    const entities = useAppSelector((state) => state.site.entities);
    const path = data.path;
    if (!path) return <></>;
    return (
        <>
            {` (${prefix ? prefix + ' ' : ''}`}
            {path?.map((idEntity, index) => (
                <React.Fragment key={idEntity}>
                    {entities[idEntity].name}
                    {(suffix || index < path.length - 1) && (
                        <TrendingFlatRoundedIcon
                            style={{
                                fontSize: 16,
                                margin: '0 1px',
                                marginTop: -2,
                                marginBottom: -2,
                            }}
                        />
                    )}
                </React.Fragment>
            ))}
            {`${suffix ?? ''})`}
        </>
    );
}

const useClientRelationshipData = ({
    path,
    idEntity,
}: ClientRelationshipDraftEntityData): {
    entity: Entity | undefined;
    multiple: boolean;
} => {
    const entities = useAppSelector((state) => state.site.entities);
    const clientEntity = useClientEntity();
    const entity = entities[idEntity];
    if (!path) {
        const relationship = clientEntity?.relationships.find(
            (rel) => rel.idEntity === idEntity
        );
        if (!relationship) return { entity: undefined, multiple: false };
        return { entity, multiple: relationship.multiple };
    }
    const parent = entities[path[path.length - 1]];
    const relationship = parent?.relationships.find(
        (rel) => rel.idEntity === idEntity
    );
    if (!relationship) return { entity: undefined, multiple: false };
    return { entity, multiple: relationship.multiple };
};

const EmptyEntityData = {
    Label: '',
    Icon: (): JSX.Element => <></>,
    calcEditorText: (): string => '',
};

function calcDateLabel(time: Time): string {
    if (time.days === 0 && time.hours === 0 && time.minutes === 0)
        return time.working ? 'Siguiente fecha hábil' : 'Fecha actual';

    let text = 'Fecha';

    if (time.working) text += ' hábil';
    const dates = [];

    if (time.days && time.days > 0)
        dates.push(`${time.days} día${time.days === 1 ? '' : 's'}`);

    if (time.hours && time.hours > 0)
        dates.push(`${time.hours} hora${time.hours === 1 ? '' : 's'}`);

    if (time.minutes && time.minutes > 0)
        dates.push(`${time.minutes} minuto${time.minutes === 1 ? '' : 's'}`);

    if (dates.length > 0) text += ` en ${dates.join(' y ')}`;

    return text;
}

function IntegrationLabel({
    idIntegration,
}: {
    idIntegration: string;
}): JSX.Element {
    const { data: integration } =
        IntegrationsApi.useLoadIntegrationQuery(idIntegration);
    return <>{integration?.name ?? 'Integración'}</>;
}

function IntegrationIcon({
    idIntegration,
    ...props
}: IconProps & { idIntegration: string }): JSX.Element {
    const { data: integration } =
        IntegrationsApi.useLoadIntegrationQuery(idIntegration);
    if (integration?.icon)
        return <SmartIcon {...props} icon={integration?.icon} />;
    return <ApiIcon {...props} />;
}
