import React, { useEffect, useRef, useState } from 'react';
import ListDetailLayout from '../../shared/ListDetailLayout/ListDetailLayout';
import PageTitle from '../../shared/PageTitle/PageTitle';
import List from '../../shared/List/List';
import SurveyDetail from './SurveyDetail/SurveyDetail';
import SurveyListItem from './SurveyListItem/SurveyListItem';
import Search from '../../shared/Search/Search';
import { useSelector, useDispatch } from 'react-redux';
import {
    reset,
    selectElement,
    updateElement,
    search,
    setLoading,
} from '../../controllers/SurveysController/SurveysActions';
import { RootState } from '../../utils/_store';
import { Survey } from '../../@Types/@Types';
import Dialog from '../../shared/Dialog/Dialog';
import CreateSurvey from './CreateSurvey/CreateSurvey';
import RoundedButton from '../../shared/RoundedButton/RoundedButton';
import { checkAdmin } from '../../utils/PermissionsFunctions';

function SurveysPage(): JSX.Element {
    const dispatch = useDispatch();
    const pageInfo = useSelector((state: RootState) => state.surveysPage);
    const siteInfo = useSelector((state: RootState) => state.site);
    const [createDialog, setCreateDialog] = useState(false);
    const listRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        dispatch(reset());
        if (listRef.current) listRef.current.scrollTop = 0;
    }, []);
    return (
        <React.Fragment>
            {createDialog && (
                <Dialog
                    open={createDialog}
                    onClose={(): void => setCreateDialog(false)}
                >
                    <CreateSurvey
                        handleCreated={(): void => {
                            dispatch(reset());
                            if (listRef.current) listRef.current.scrollTop = 0;
                        }}
                        close={(): void => setCreateDialog(false)}
                    />
                </Dialog>
            )}
            <div className="standard-layout">
                <PageTitle
                    title={'Encuestas'}
                    route={
                        siteInfo?.projects[siteInfo?.idProject ?? '']?.name +
                        ' / Encuestas'
                    }
                />
                <div className="filters-container flexbox">
                    {checkAdmin(siteInfo.user) && (
                        <RoundedButton
                            text="Nueva"
                            backgroundColor={'var(--secondary)'}
                            color={'white'}
                            onClick={(): void => {
                                setCreateDialog(true);
                            }}
                        />
                    )}

                    <div className="search-container">
                        <Search
                            search={pageInfo.search}
                            handleLoadingSearch={(loading: boolean): void => {
                                dispatch(setLoading(loading));
                            }}
                            handleSearch={(value: string): void => {
                                /** Refresh the list and send it back to the top */
                                if (listRef.current)
                                    listRef.current.scrollTop = 0;
                                dispatch(search(value));
                            }}
                        />
                    </div>
                </div>

                <ListDetailLayout
                    listWidth={450}
                    responsiveBreakpoint={1000}
                    selectElementLabel="Selecciona una Encuesta"
                    loading={pageInfo.loading}
                    selectedElement={
                        pageInfo.selectedElement !== undefined
                            ? pageInfo.elements[pageInfo.selectedElement]
                            : undefined
                    }
                    handleSelectedElement={(element?: Survey): void => {
                        const index = pageInfo.elements.findIndex(
                            (listElement) => listElement._id === element?._id
                        );
                        dispatch(selectElement(index));
                    }}
                >
                    <List
                        elements={pageInfo.elements}
                        lastPage={true}
                        listRef={listRef}
                        label="No hay Encuestas"
                    >
                        <SurveyListItem />
                    </List>
                    <SurveyDetail
                        handleUpdate={(
                            object: Survey,
                            field: keyof Survey,
                            value: string
                        ): void => {
                            dispatch(updateElement({ object, field, value }));
                        }}
                    />
                </ListDetailLayout>
            </div>
        </React.Fragment>
    );
}

export default SurveysPage;
