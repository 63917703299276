import { NavBackStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import { StepComponentProps } from '../../Step';
import StepContainer from '../../StepContainer/StepContainer';
import styles from './BackStep.module.css';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
interface BackStepProps extends StepComponentProps {
    step: NavBackStep;
}

function BackStep({ step, path }: BackStepProps): JSX.Element {
    return (
        <StepContainer hidden step={step} path={path}>
            <div className={styles.passthroughContainer}>
                <div
                    className={styles.iconContainer}
                    style={{
                        transform: 'scaleY(-1)',
                    }}
                >
                    <LowPriorityIcon fontSize="inherit" />
                </div>
                <div className={styles.passthoughLbl}>{step.name}</div>
            </div>
        </StepContainer>
    );
}
export default BackStep;
