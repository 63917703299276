import { MenuItem } from '@material-ui/core';
import React from 'react';
import { ClassifierCondition } from '../../../@Types/ConditionTypes/TicketPropertyConditions';
import ConditionOperators from '../../../constants/Conditions/ConditionOperators';
import RoundedClassifierPicker from '../../@Pickers/RoundedClassifierPicker/RoundedClassifierPicker';
import RoundedSelect from '../../RoundedSelect/RoundedSelect';
import styles from './Condition.module.css';
import { ConditionProps } from '../Condition';

interface ClassifierConditionProps
    extends ConditionProps<ClassifierCondition> {}

function ClassifierConditionComponent({
    condition,
    hoverDelete,
    handleUpdate,
}: ClassifierConditionProps): JSX.Element {
    return (
        <React.Fragment>
            <div className={styles.operatorContainer}>
                <div className={styles.selectorContainer}>
                    <RoundedSelect
                        value={condition.operator ?? ''}
                        label="Operador"
                        containerMargin="0px"
                        error={hoverDelete}
                        handleUpdate={(event): void => {
                            handleUpdate({
                                ...condition,
                                operator: event.target.value,
                            });
                        }}
                    >
                        <MenuItem value={ConditionOperators.EQUAL}>
                            Es igual a
                        </MenuItem>
                        <MenuItem value={ConditionOperators.NOTEQUAL}>
                            No es igual a
                        </MenuItem>
                    </RoundedSelect>
                </div>
            </div>
            <div className={styles.valueContainer}>
                {condition.idRoot !== '' && (
                    <div className={styles.classifierSelectorContainer}>
                        <RoundedClassifierPicker
                            height="40px"
                            fullWidth={true}
                            minWidth={300}
                            allowRoots
                            alwaysHot
                            label="Clasificador"
                            value={
                                condition.idRoot
                                    ? [
                                          {
                                              idRoot: condition.idRoot,
                                              idValue: condition.idValue,
                                          },
                                      ]
                                    : []
                            }
                            error={hoverDelete}
                            handleUpdate={(classifiers): void => {
                                handleUpdate({
                                    ...condition,
                                    idRoot: classifiers?.[0]?.idRoot,
                                    idValue: classifiers?.[0]?.idValue,
                                });
                            }}
                        />
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}
export default ClassifierConditionComponent;
