import AgentIcon from '../../Icons/AgentIcon';
import CalendarIcon from '../../Icons/CalendarIcon';
import LeakIcon from '../../Icons/Construction/LeakIcon';
import ProjectIcon from '../../Icons/Construction/ProjectIcon';
import PropertyIcon from '../../Icons/Construction/PropertyIcon';
import SpaceIcon from '../../Icons/Construction/SpaceIcon';
import IdentifierIcon from '../../Icons/IdentifierIcon';
import CheckListIcon from '../../Icons/Entities/CheckListIcon';
import GenericEntityIcon from '../../Icons/Entities/GenericEntityIcon';
import HandShakeIcon from '../../Icons/Entities/HandshakeIcon';
import SupplierIcon from '../../Icons/Entities/SupplierIcon';
import LocationIcon from '../../Icons/LocationIcon';
import IconTypes from '../../constants/IconTypes';

export interface InputIconProps {
    icon: string;
    fill?: string;
}

function InputIcon({
    icon,
    fill = '#757575',
    ...others
}: InputIconProps): JSX.Element {
    switch (icon) {
        case IconTypes.PROJECT:
            return (
                <ProjectIcon
                    style={{
                        display: 'flex',
                        height: 24,
                        width: 24,
                    }}
                    fill={fill}
                    {...others}
                />
            );
        case IconTypes.PROPERTY:
            return (
                <PropertyIcon
                    style={{
                        display: 'flex',
                        height: 23,
                        width: 23,
                    }}
                    fill={fill}
                    {...others}
                />
            );
        case IconTypes.SPACE:
            return (
                <SpaceIcon
                    style={{
                        display: 'flex',
                        height: 23,
                        width: 23,
                    }}
                    fill={fill}
                    {...others}
                />
            );
        case IconTypes.PERSON:
            return (
                <AgentIcon
                    style={{
                        display: 'flex',
                        height: 23,
                        width: 23,
                    }}
                    fill={fill}
                    {...others}
                />
            );
        case IconTypes.LEAK:
            return (
                <LeakIcon
                    style={{
                        display: 'flex',
                        height: 23,
                        width: 23,
                        marginTop: 2,
                    }}
                    fill={fill}
                    {...others}
                />
            );
        case IconTypes.DOCUMENT:
            return (
                <IdentifierIcon
                    style={{
                        display: 'flex',
                        height: 23,
                        width: 23,
                    }}
                    fill={fill}
                    {...others}
                />
            );
        case IconTypes.HANDSHAKE:
            return (
                <HandShakeIcon
                    style={{
                        display: 'flex',
                        height: 23,
                        width: 23,
                    }}
                    fill={fill}
                    {...others}
                />
            );
        case IconTypes.CHECKLIST:
            return (
                <CheckListIcon
                    style={{
                        display: 'flex',
                        height: 23,
                        width: 23,
                    }}
                    fill={fill}
                    {...others}
                />
            );
        case IconTypes.SUPPLIER:
            return (
                <SupplierIcon
                    style={{
                        display: 'flex',
                        height: 24,
                        width: 24,
                    }}
                    fill={fill}
                    {...others}
                />
            );
        case IconTypes.GENERIC:
        case IconTypes.CALENDAR:
            return (
                <CalendarIcon
                    style={{
                        display: 'flex',
                        height: 23,
                        width: 23,
                    }}
                    fill={fill}
                    {...others}
                />
            );
        case IconTypes.LOCATION:
            return (
                <LocationIcon
                    style={{
                        display: 'flex',
                        height: 23,
                        width: 23,
                    }}
                    fill={fill}
                    {...others}
                />
            );
        default:
            return (
                <GenericEntityIcon
                    style={{
                        display: 'flex',
                        height: 23,
                        width: 23,
                    }}
                    fill={fill}
                    {...others}
                />
            );
    }
}
export default InputIcon;
