import { PayloadFile } from '../../@Types/Payload';

export const enum PayloadEditorStateTypes {
    UPLOADING = 'UPLOADING',
    COMPLETED = 'COMPLETED',
    ERROR = 'ERROR',
}

export type PayloadEditorImage =
    | UploadingPayloadEditorImage
    | CompletedPayloadEditorImage
    | ErrorPayloadEditorImage;

export type PayloadEditorFile =
    | UploadingPayloadEditorFile
    | CompletedPayloadEditorFile
    | ErrorPayloadEditorFile;

interface UploadingPayloadEditorImage {
    state: PayloadEditorStateTypes.UPLOADING;
    imageKey: string;
    url: string;
}

interface CompletedPayloadEditorImage {
    state: PayloadEditorStateTypes.COMPLETED;
    imageKey: string;
    S3Key: string;
    url: string;
}

interface ErrorPayloadEditorImage {
    state: PayloadEditorStateTypes.ERROR;
    imageKey: string;
    url: string;
}

interface CompletedPayloadEditorFile extends PayloadFile {
    id: string;
    state: PayloadEditorStateTypes.COMPLETED;
}

interface UploadingPayloadEditorFile {
    id: string;
    fileName: string;
    state: PayloadEditorStateTypes.UPLOADING;
}

interface ErrorPayloadEditorFile {
    id: string;
    fileName: string;
    state: PayloadEditorStateTypes.ERROR;
    message: string;
}

export interface PayloadEditorTemplateContext {
    idTicket?: string;
}
