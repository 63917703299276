export enum AYFFormStepTypes {
    AYF_START_MONTH = 'AYF_START_MONTH',
    AYF_END_MONTH = 'AYF_END_MONTH',
    AYF_YEAR = 'AYF_YEAR',
    AYF_BILL_NUM = 'AYF_BILL_NUM',
    AYF_CUT_OFF_DATE = 'AYF_CUT_OFF_DATE',
    AYF_ICA_CITY = 'AYF_ICA_CITY',
}

export default AYFFormStepTypes;
