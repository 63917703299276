import styles from './TimePickerStep.module.css';
import RoundedTextField from '../../RoundedTextField/RoundedTextField';
import Toggle from '../../Toggle/Toggle';
import RoundedTimePicker from '../../@Pickers/RoundedTimePicker/RoundedTimePicker';
import React from 'react';
import { GTimePicker } from '../../../@Types/GenericFormSteps';
import { GenericStepProps } from '../../GenericFormEditor/Step/Step';
import { calcStepWidth } from '../../GenericFormEditor/StepFunctions';
import { GLocation } from '../../../@Types/FormTypes/LocationTypes';
import produce from 'immer';
import RoundedSelect from '../../RoundedSelect/RoundedSelect';
import { MenuItem } from '@material-ui/core';

function TimePickerStep<StepType extends GTimePicker, U, L extends GLocation>({
    editing,
    step,
    updateStep,
    size,
}: GenericStepProps<StepType, U, L>): JSX.Element {
    if (editing) {
        return (
            <div className={styles.editingContainer}>
                <div className={styles.inputContainer}>
                    <RoundedTextField
                        label="Etiqueta"
                        value={step.label}
                        onChange={(e): void => {
                            updateStep({ ...step, label: e.target.value });
                        }}
                    ></RoundedTextField>
                </div>

                <RoundedTextField
                    label="Descripción"
                    value={step.description ?? ''}
                    onChange={(e): void => {
                        updateStep({
                            ...step,
                            description: e.target.value,
                        });
                    }}
                ></RoundedTextField>
                <div className={styles.timeContainer}>
                    <div className={styles.timeLabel}>Seleccionar Días:</div>
                    <div className={styles.toggleContainer}>
                        <Toggle
                            checked={step.pickDays === true}
                            onChange={(checked: boolean): void => {
                                updateStep({
                                    ...step,
                                    pickDays: checked,
                                });
                            }}
                        />
                    </div>
                </div>
                <div className={styles.timeContainer}>
                    <div className={styles.timeLabel}>Seleccionar Horas:</div>
                    <div className={styles.toggleContainer}>
                        <Toggle
                            checked={step.pickHours === true}
                            onChange={(checked: boolean): void => {
                                updateStep({
                                    ...step,
                                    pickHours: checked,
                                });
                            }}
                        />
                    </div>
                </div>
                <div className={styles.timeContainer}>
                    <div className={styles.timeLabel}>Seleccionar Minutos:</div>
                    <div className={styles.toggleContainer}>
                        <Toggle
                            checked={step.pickMinutes === true}
                            onChange={(checked: boolean): void => {
                                updateStep({
                                    ...step,
                                    pickMinutes: checked,
                                });
                            }}
                        />
                    </div>
                </div>
                <div className={styles.workingContainer}>
                    <div className={styles.timeLabel}>Tipo de Tiempo:</div>
                    <div className={styles.workingSelectorContainer}>
                        <RoundedSelect
                            containerMargin="0px"
                            label=""
                            value={
                                step.working
                                    ? 'HABIL'
                                    : step.working === false
                                    ? 'CALENDAR'
                                    : 'DEFAULT'
                            }
                            handleUpdate={(event): void =>
                                updateStep(
                                    produce(step, (step) => {
                                        const type = event.target.value as
                                            | 'HABIL'
                                            | 'CALENDAR'
                                            | 'DEFAULT';
                                        switch (type) {
                                            case 'HABIL':
                                                step.working = true;
                                                break;
                                            case 'CALENDAR':
                                                step.working = false;
                                                break;
                                            case 'DEFAULT':
                                                delete step.working;
                                                break;
                                        }
                                    })
                                )
                            }
                        >
                            <MenuItem value={'HABIL'}>Hábil</MenuItem>
                            <MenuItem value={'CALENDAR'}>Calendario</MenuItem>
                            <MenuItem value={'DEFAULT'}>
                                Usuario Escoge
                            </MenuItem>
                        </RoundedSelect>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div
                style={{
                    width: calcStepWidth(step.size, size),
                    maxWidth: '100%',
                    marginBottom: step.description ? '0px' : '5px',
                }}
            >
                <RoundedTimePicker
                    value={undefined}
                    height="31px"
                    containerMargin="0px"
                    backgroundColor="var(--primary1)"
                    label={step.label}
                    helperText={step.description}
                    required={step.required}
                    onChange={(): void => {}}
                />
            </div>
        );
    }
}

export default React.memo(TimePickerStep, (prev, next) => {
    const { step, stepEqualityChecker } = prev;
    const { step: newStep } = next;
    if (
        step.size !== newStep.size ||
        step.label !== newStep.label ||
        step.pickDays !== newStep.pickDays ||
        step.pickHours !== newStep.pickHours ||
        step.pickMinutes !== newStep.pickMinutes ||
        step.working !== newStep.working ||
        step.required !== newStep.required ||
        step.description !== newStep.description
    ) {
        return false;
    }

    return stepEqualityChecker?.(prev, next) ?? true;
}) as typeof TimePickerStep;
