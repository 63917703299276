import styles from './OtherFilters.module.css';
import React from 'react';
import Search from '../../../../shared/Search/Search';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../utils/_store';
import RoundedSelect from '../../../../shared/RoundedSelect/RoundedSelect';
import { TicketFilters } from '../../../../controllers/TicketsController/TicketsReducer';
import { MenuItem } from '@material-ui/core';
import TicketFilterTypes from '../../../../constants/TicketFilterTypes';
import Toggle from '../../../../shared/Toggle/Toggle';
import { checkProjPerm } from '../../../../utils/PermissionsFunctions';
import { ProjectPermissions } from '../../../../constants/Permissions';
import RoundedDatePicker from '../../../../shared/@Pickers/RoundedDatePicker/RoundedDatePicker';
// import RoundedProjectPicker from '../../../../shared/Pickers/RoundedProjectPicker/RoundedProjectPicker';

interface OtherFiltersProps {
    /** Function called when classifiers change */
    handleChange: (filters: TicketFilters) => void;
    /** The currently selected classifiers */
    filters: TicketFilters;
    /** Tabs component to display after the search */
    tabsComponent: JSX.Element;
}
function OtherFilters({
    filters,
    handleChange,
    tabsComponent,
}: OtherFiltersProps): JSX.Element {
    const user = useSelector((state: RootState) => state.site.user);
    const idProject = useSelector((state: RootState) => state.site.idProject);
    const organization = useSelector(
        (state: RootState) => state.site.organization
    );
    return (
        <React.Fragment>
            <div className={styles.searchContainer}>
                <Search
                    wait={500}
                    placeholder={'Buscar'}
                    search={''}
                    disabled
                    handleLoadingSearch={(): void => {}}
                    handleSearch={(): void => {}}
                />
            </div>
            {tabsComponent}

            <div className={styles.roundSelect}>
                <RoundedSelect
                    minWidth={100}
                    height={'30px'}
                    value={filters.type}
                    label="Tipo de Casos"
                    containerMargin="0px"
                    handleUpdate={(event): void => {
                        const type = event.target.value;
                        handleChange({ ...filters, type });
                    }}
                >
                    <MenuItem value={TicketFilterTypes.ALL}>
                        Casos & SubCasos
                    </MenuItem>
                    <MenuItem value={TicketFilterTypes.TICKETS}>Casos</MenuItem>
                    <MenuItem value={TicketFilterTypes.SUBTICKETS}>
                        SubCasos
                    </MenuItem>
                </RoundedSelect>
            </div>

            {checkProjPerm(user, idProject, ProjectPermissions.ALL) && (
                <div className={styles.toggleContainer}>
                    <label className={styles.allCases}>Todos los Casos:</label>
                    <Toggle
                        checked={filters.all}
                        onChange={(checked: boolean): void => {
                            handleChange({
                                ...filters,
                                all: checked,
                            });
                        }}
                    />
                </div>
            )}
            {organization?.idCompanyEntity && (
                <div className={styles.companyToggleContainer}>
                    <label className={styles.allCases}>
                        Casos de mis empresas:
                    </label>
                    <Toggle
                        checked={filters.companyResponsible}
                        onChange={(checked: boolean): void => {
                            handleChange({
                                ...filters,
                                companyResponsible: checked,
                            });
                        }}
                    />
                </div>
            )}
            <div className={styles.creationDateLbl}>Fecha de Creación:</div>
            <div className={styles.datePickerContainer}>
                <RoundedDatePicker
                    clearable
                    clearLabel="Borrar"
                    value={filters.crtStartDate ?? null}
                    height="31px"
                    backgroundColor="white"
                    label={'Fecha Inicio'}
                    onChange={(date): void => {
                        if (
                            date?.getTime() !== filters.crtStartDate?.getTime()
                        ) {
                            handleChange({
                                ...filters,
                                crtStartDate: date,
                            });
                        }
                    }}
                />
            </div>
            <div className={styles.datePickerContainer}>
                <RoundedDatePicker
                    clearable
                    clearLabel="Borrar"
                    value={filters.crtEndDate ?? null}
                    height="31px"
                    backgroundColor="white"
                    label={'Fecha Fin'}
                    onChange={(date): void => {
                        if (date?.getTime() !== filters.crtEndDate?.getTime()) {
                            handleChange({
                                ...filters,
                                crtEndDate: date,
                            });
                        }
                    }}
                />
            </div>
            <div className={styles.creationDateLbl}>Fecha de Vencimiento:</div>
            <div className={styles.datePickerContainer}>
                <RoundedDatePicker
                    clearable
                    clearLabel="Borrar"
                    value={filters.clsStartDate ?? null}
                    height="31px"
                    backgroundColor="white"
                    label={'Fecha Inicio'}
                    onChange={(date): void => {
                        if (
                            date?.getTime() !== filters.clsStartDate?.getTime()
                        ) {
                            handleChange({
                                ...filters,
                                clsStartDate: date,
                            });
                        }
                    }}
                />
            </div>
            <div className={styles.datePickerContainer}>
                <RoundedDatePicker
                    clearable
                    clearLabel="Borrar"
                    value={filters.clsEndDate ?? null}
                    height="31px"
                    backgroundColor="white"
                    label={'Fecha Fin'}
                    onChange={(date): void => {
                        if (date?.getTime() !== filters.clsEndDate?.getTime()) {
                            handleChange({
                                ...filters,
                                clsEndDate: date,
                            });
                        }
                    }}
                />
            </div>
            {/* <div className={styles.datePickerContainer}>
                <RoundedProjectPicker
                    value={project}
                    height="31px"
                    backgroundColor="white"
                    label={'proyecto'}
                    onChange={(project: any): void => {
                        setProject(project);
                    }}
                />
            </div> */}
        </React.Fragment>
    );
}

export default OtherFilters;
