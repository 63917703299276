import styles from './CBRStepMenu.module.css';
import stepMenuStyles from '../StepMenu.module.css';
import CBRStepTypes from '../../../../../../constants/Construction/CBRFormStepTypes';
import { useRef, useState } from 'react';
import React from 'react';
import { Popper } from '@material-ui/core';
import ProjectIcon from '../../../../../../Icons/Construction/ProjectIcon';
import PropertyIcon from '../../../../../../Icons/Construction/PropertyIcon';
import SpaceIcon from '../../../../../../Icons/Construction/SpaceIcon';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import { RootState } from '../../../../../../utils/_store';
import { useDispatch, useSelector } from 'react-redux';
import { addStep } from '../../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import { StepLocation } from '../../../../../../controllers/ConversationEditorController/ConversationEditorReducer';
import { createCBRConversationStep } from './CBRStepFunctions';
import LeakIcon from '../../../../../../Icons/Construction/LeakIcon';
import IdentifierIcon from '../../../../../../Icons/IdentifierIcon';
interface CBRStepMenuProps {
    location: StepLocation;
    handleClose: Function;
}
function CBRStepMenu({ location, handleClose }: CBRStepMenuProps): JSX.Element {
    const dispatch = useDispatch();
    const [hovering, setHovering] = useState(false);
    const [hoveringMenu, setHoveringMenu] = useState(false);
    const editorSteps = useSelector(
        (state: RootState) => state.conversationEditor.steps
    );
    const btnRef = useRef<HTMLDivElement>(null);

    const findStep = (type: CBRStepTypes): boolean => {
        return (
            Object.values(editorSteps).find(
                (step) => step.type === (type as any)
            ) !== undefined
        );
    };
    const handleAdd = (type: CBRStepTypes): void => {
        dispatch(
            addStep({ step: createCBRConversationStep(type) as any, location })
        );
        handleClose();
    };
    return (
        <React.Fragment>
            <div
                className={stepMenuStyles.option}
                onMouseEnter={(): void => {
                    setHovering(true);
                }}
                onMouseLeave={(): void => {
                    setHovering(false);
                }}
                ref={btnRef}
                style={{
                    backgroundColor:
                        hovering || hoveringMenu
                            ? ' var(--light-grey)'
                            : 'white',
                }}
            >
                <div className={styles.cbrIcon}>
                    <img
                        className={styles.cbrImg}
                        src="/media/icons/sinco-cbr.svg"
                    />
                </div>
                <div className={stepMenuStyles.optionLabel}>
                    CBR - Posventas
                </div>
            </div>
            {(hovering || hoveringMenu) && (
                <Popper
                    open={true}
                    anchorEl={btnRef.current}
                    placement={'right-end'}
                    modifiers={{
                        preventOverflow: {
                            enabled: true,
                            priority: ['right', 'left'],
                            boundariesElement: 'viewport',
                        },
                    }}
                    style={{ zIndex: 3 }}
                >
                    <div
                        className={styles.container}
                        onMouseEnter={(): void => {
                            setHoveringMenu(true);
                        }}
                        onMouseLeave={(): void => {
                            setHoveringMenu(false);
                        }}
                    >
                        <div className={styles.bottomLeftPadding}></div>
                        <div>
                            <div
                                className={
                                    findStep(CBRStepTypes.CBR_FIRST_NAME)
                                        ? stepMenuStyles.existingOption
                                        : stepMenuStyles.option
                                }
                                onClick={(): void => {
                                    handleAdd(CBRStepTypes.CBR_FIRST_NAME);
                                }}
                            >
                                <div className={styles.textInputContainer}>
                                    A..
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Nombres*
                                </div>
                            </div>
                            <div
                                className={
                                    findStep(CBRStepTypes.CBR_LAST_NAME)
                                        ? stepMenuStyles.existingOption
                                        : stepMenuStyles.option
                                }
                                onClick={(): void => {
                                    handleAdd(CBRStepTypes.CBR_LAST_NAME);
                                }}
                            >
                                <div className={styles.textInputContainer}>
                                    A..
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Apellidos*
                                </div>
                            </div>
                            <div
                                className={
                                    findStep(CBRStepTypes.CBR_TIPO_DOC)
                                        ? stepMenuStyles.existingOption
                                        : stepMenuStyles.option
                                }
                                onClick={(): void => {
                                    handleAdd(CBRStepTypes.CBR_TIPO_DOC);
                                }}
                            >
                                <div className={styles.inputContainer}>
                                    <div className={styles.iconContainer}>
                                        <IdentifierIcon
                                            fill="var(--secondary)"
                                            style={{
                                                height: 25,
                                                width: 25,
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Tipo de Documento*
                                </div>
                            </div>
                            <div
                                className={
                                    findStep(CBRStepTypes.CBR_DOC)
                                        ? stepMenuStyles.existingOption
                                        : stepMenuStyles.option
                                }
                                onClick={(): void => {
                                    handleAdd(CBRStepTypes.CBR_DOC);
                                }}
                            >
                                <div className={styles.textInputContainer}>
                                    1..
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Documento*
                                </div>
                            </div>
                            <div
                                className={
                                    findStep(CBRStepTypes.CBR_CEL)
                                        ? stepMenuStyles.existingOption
                                        : stepMenuStyles.option
                                }
                                onClick={(): void => {
                                    handleAdd(CBRStepTypes.CBR_CEL);
                                }}
                            >
                                <div className={styles.textInputContainer}>
                                    1..
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Celular*
                                </div>
                            </div>
                            <div
                                className={
                                    findStep(CBRStepTypes.CBR_EMAIL)
                                        ? stepMenuStyles.existingOption
                                        : stepMenuStyles.option
                                }
                                onClick={(): void => {
                                    handleAdd(CBRStepTypes.CBR_EMAIL);
                                }}
                            >
                                <div className={styles.textInputContainer}>
                                    @..
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Correo*
                                </div>
                            </div>
                            <div
                                className={
                                    findStep(CBRStepTypes.CBR_TIPO_SOLICITANTE)
                                        ? stepMenuStyles.existingOption
                                        : stepMenuStyles.option
                                }
                                onClick={(): void => {
                                    handleAdd(
                                        CBRStepTypes.CBR_TIPO_SOLICITANTE
                                    );
                                }}
                            >
                                <div
                                    className={styles.inputContainer}
                                    style={{ fontSize: 25 }}
                                >
                                    <PersonRoundedIcon fontSize="inherit" />
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Tipo de solicitante*
                                </div>
                            </div>
                            <div
                                className={
                                    findStep(CBRStepTypes.CBR_MACRO_PROYECTO)
                                        ? stepMenuStyles.existingOption
                                        : stepMenuStyles.option
                                }
                                onClick={(): void => {
                                    handleAdd(CBRStepTypes.CBR_MACRO_PROYECTO);
                                }}
                            >
                                <div className={styles.inputContainer}>
                                    <div className={styles.iconContainer}>
                                        <ProjectIcon
                                            fill="var(--secondary)"
                                            style={{
                                                height: 'inherit',
                                                width: 'inherit',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Macro Proyecto
                                </div>
                            </div>
                            <div
                                className={
                                    findStep(CBRStepTypes.CBR_PROYECTO)
                                        ? stepMenuStyles.existingOption
                                        : stepMenuStyles.option
                                }
                                onClick={(): void => {
                                    handleAdd(CBRStepTypes.CBR_PROYECTO);
                                }}
                            >
                                <div className={styles.inputContainer}>
                                    <div className={styles.iconContainer}>
                                        <ProjectIcon
                                            fill="var(--secondary)"
                                            style={{
                                                height: 'inherit',
                                                width: 'inherit',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Proyecto*
                                </div>
                            </div>
                            <div
                                className={
                                    findStep(CBRStepTypes.CBR_INMUEBLE)
                                        ? stepMenuStyles.existingOption
                                        : stepMenuStyles.option
                                }
                                onClick={(): void => {
                                    handleAdd(CBRStepTypes.CBR_INMUEBLE);
                                }}
                            >
                                <div className={styles.inputContainer}>
                                    <div className={styles.iconContainer}>
                                        <PropertyIcon
                                            fill="var(--secondary)"
                                            style={{
                                                height: 'inherit',
                                                width: 'inherit',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Inmueble*
                                </div>
                            </div>
                            <div
                                className={
                                    findStep(CBRStepTypes.CBR_PROYECTO)
                                        ? stepMenuStyles.existingOption
                                        : stepMenuStyles.option
                                }
                                onClick={(): void => {
                                    handleAdd(CBRStepTypes.CBR_TIPO_ESPACIO);
                                }}
                            >
                                <div className={styles.inputContainer}>
                                    <div className={styles.iconContainer}>
                                        <SpaceIcon
                                            fill="var(--secondary)"
                                            style={{
                                                height: 'inherit',
                                                width: 'inherit',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Tipo de Espacio
                                </div>
                            </div>
                            <div
                                className={stepMenuStyles.option}
                                onClick={(): void => {
                                    handleAdd(CBRStepTypes.CBR_LOCATIVAS);
                                }}
                            >
                                <div className={styles.inputContainer}>
                                    <div className={styles.iconContainer}>
                                        <LeakIcon
                                            fill="var(--secondary)"
                                            style={{
                                                height: 'inherit',
                                                width: 'inherit',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Elemento*
                                </div>
                            </div>
                        </div>
                    </div>
                </Popper>
            )}
        </React.Fragment>
    );
}

export default CBRStepMenu;
