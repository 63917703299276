import React from 'react';
import { Radio, RadioProps, makeStyles } from '@material-ui/core';
interface StyleProps {
    error?: boolean;
    padding?: string;
    uncheckedColor?: string;
    checkedColor?: string;
    checkedHoverColor?: string;
    errorColor?: string;
    disabledColor?: string;
    cantEdit?: boolean;
}

const useRadioStyles = (props: StyleProps): Function =>
    makeStyles(() => ({
        root: {
            padding: props.padding,
            color: props.error ? props.errorColor : props.uncheckedColor,
            '&.Mui-checked': {
                color: props.checkedColor,
            },
            '&.Mui-checked:hover': {
                backgroundColor: props.checkedHoverColor,
            },
            '&.Mui-disabled': {
                cursor: 'default',
                pointerEvents: 'all !important',
                color: props.cantEdit
                    ? props.disabledColor
                    : props.checkedColor,
            },
            '&:hover': {
                backgroundColor: props.cantEdit
                    ? 'transparent'
                    : props.checkedHoverColor,
            },
        },
    }));

interface ComponentProps extends StyleProps {
    onChange?: Function;
    checked: boolean;
}

/**
 * Generic rounded Radio
 */
function CustomRadio({
    padding = '9px',
    onChange,
    error = false,
    checkedColor = 'var(--secondary)',
    checkedHoverColor = 'var(--transparentsecondary)',
    uncheckedColor = 'var(--greyFont)',
    disabledColor = 'var(--disabledGrey)',
    errorColor = 'var(--error)',
    cantEdit = false,
    checked,
    ...others
}: ComponentProps & RadioProps): JSX.Element {
    const classes = useRadioStyles({
        error,
        padding,
        cantEdit,
        errorColor,
        checkedColor,
        disabledColor,
        uncheckedColor,
        checkedHoverColor,
    })();

    return (
        <Radio
            {...others}
            disabled={cantEdit}
            classes={classes}
            checked={checked}
            onChange={onChange}
        />
    );
}

/**
 * Generic datepicker with apps designs. Is class do to the use in the react-hook-forms library
 */
class RoundedRadio extends React.Component<ComponentProps & RadioProps> {
    render(): JSX.Element {
        return <CustomRadio {...this.props} />;
    }
}
export default RoundedRadio;
