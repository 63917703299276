import { useSelector } from 'react-redux';
import styles from './OtherEntitiesMenu.module.css';
import { useMemo } from 'react';
import { RootState } from '../../../../../../utils/_store';
import { EntityPermissions } from '../../../../../../constants/Permissions';
import { checkEntityPerm } from '../../../../../../utils/PermissionsFunctions';
import EntityIcon from '../../../../../../shared/SmartIcons/EntityIcon';
import GenericEntityIcon from '../../../../../../Icons/Entities/GenericEntityIcon';
import { EntityValueStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import { createEntityStep } from '../../../StepFunctions';
import { useCurrentProject } from '../../../../../../hooks';
import EurekaElementMenuElement from '../../../../../../shared/@Menus/ElementMenu/EurekaElementMenuElement';
import EurekaElementMenuGroup from '../../../../../../shared/@Menus/ElementMenu/EurekaElementMenuGroup';
interface OtherEntitiesMenuProps {
    handleAdd: (step: EntityValueStep) => void;
}
function OtherEntitiesMenu({ handleAdd }: OtherEntitiesMenuProps): JSX.Element {
    const project = useCurrentProject();
    const organization = useSelector(
        (state: RootState) => state.site.organization
    );
    const user = useSelector((state: RootState) => state.site.user);
    const siteEntities = useSelector((state: RootState) => state.site.entities);

    const entities = useMemo(
        () =>
            Object.values(siteEntities)
                .filter(
                    (entity) =>
                        entity.active !== false &&
                        entity._id !== organization?.idClientEntity &&
                        entity._id !== organization?.idCompanyEntity &&
                        checkEntityPerm(
                            user,
                            entity._id,
                            EntityPermissions.VIEW
                        )
                )
                .sort((e1, e2) => e1.name.localeCompare(e2.name)),
        [siteEntities, organization, user]
    );

    if (entities.length === 0) return <div></div>;

    return (
        <EurekaElementMenuGroup
            label={'Otras Entidades'}
            icon={(props): JSX.Element => (
                <div className={styles.entityInputContainer}>
                    <GenericEntityIcon
                        {...props}
                        style={{
                            marginTop: 2,
                            marginLeft: 3,
                            height: 12,
                            width: 12,
                        }}
                    />
                </div>
            )}
            style={{ fontSize: 18 }}
            renderElements={(props): JSX.Element[] =>
                entities.map((entity) => (
                    <EurekaElementMenuElement
                        {...props}
                        key={entity._id}
                        Icon={(props): JSX.Element => (
                            <EntityIcon {...props} idEntity={entity._id} />
                        )}
                        onClick={(): void => {
                            handleAdd(createEntityStep(project, entity));
                        }}
                        Label={entity.name}
                    />
                ))
            }
            zIndex={1303}
        />
    );
}

export default OtherEntitiesMenu;
