import { MenuItem } from '@material-ui/core';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import RoundedSelect, {
    RoundedSelectProps,
} from '../../RoundedSelect/RoundedSelect';
import styles from './RoundedNotificationTypePicker.module.css';
import WhatsappIcon from '../../../Icons/WhatsappIcon';
import NotificationTypes from '../../../constants/NotificationTypes';

interface NotificationTypePickerProps
    extends Omit<RoundedSelectProps, 'handleUpdate' | 'value'> {
    /** Currently selected clientProperty id */
    value: NotificationTypes;
    /** function called when value changes */
    handleUpdate: (type: NotificationTypes) => void;
}
const menuStyle = {
    paddingLeft: 0,
};

function NotificationTypePicker({
    value,
    handleUpdate,
    ...others
}: NotificationTypePickerProps): JSX.Element {
    return (
        <RoundedSelect
            value={value}
            paddingLeft={0}
            handleUpdate={(event): void => {
                const value = event.target.value ?? '';
                handleUpdate(value);
            }}
            {...others}
        >
            <MenuItem style={menuStyle} value={NotificationTypes.EMAIL}>
                <div className={styles.property}>
                    <div className={styles.mailIcon}>
                        <MailOutlineRoundedIcon fontSize="inherit" />
                    </div>
                    Correo
                </div>
            </MenuItem>
            <MenuItem style={menuStyle} value={NotificationTypes.WHATSAPP}>
                <div className={styles.property}>
                    <div className={styles.whatsappIcon}>
                        <WhatsappIcon
                            fill="var(--secondary)"
                            style={{
                                height: 32,
                                width: 32,
                            }}
                        />
                    </div>
                    Whatsapp
                </div>
            </MenuItem>
        </RoundedSelect>
    );
}
export default NotificationTypePicker;
