import { AnyAction, nanoid } from '@reduxjs/toolkit';
import { call, put, fork, select, all, takeEvery } from 'redux-saga/effects';
import { postFile, getUploadUrls } from '../FilesController/FilesService';
import {
    InitialPayloadEditorState,
    PayloadEditorActions,
} from './PayloadEditorSlice';
import { AtomicBlockUtils } from 'draft-js';
import maxSize from '../../constants/Files/MaxSize';
import { calcImageEntityData } from '../../utils/draftFunctions';
import { PayloadEditorStateTypes } from './PayloadEditorTypes';
import { mapDraftEntities } from './PayloadEditorService';

function prepare(idEditor: string, action: AnyAction): AnyAction {
    action.meta = { idEditor };
    return action;
}

/**
 * Function called when files need to be uploaded
 * @param action
 */
function* uploadFiles(action: AnyAction): any {
    try {
        if (PayloadEditorActions.uploadFiles.match(action)) {
            const idEditor = (action as any).meta.idEditor;
            const files = action.payload;
            const ids = files.map(() => nanoid());
            yield put(
                prepare(
                    idEditor,
                    PayloadEditorActions.addFiles(
                        ids.map((id, index) => ({
                            id,
                            fileName: files[index].name,
                            state: PayloadEditorStateTypes.UPLOADING,
                        }))
                    )
                )
            );
            const fileLinks = yield call(getUploadUrls, files);
            for (let i = 0; i < files.length; i++) {
                if (fileLinks[i] === 'ERROR:INVALIDEXTENSION') {
                    yield put(
                        prepare(
                            idEditor,
                            PayloadEditorActions.updateFile({
                                id: ids[i],
                                fileName: files[i].name,
                                state: PayloadEditorStateTypes.ERROR,
                                message:
                                    'La extención .' +
                                    files[i].name.split('.').pop() +
                                    ' no es válida',
                            })
                        )
                    );
                } else if (files[i].size <= maxSize) {
                    yield fork(
                        uploadFile,
                        idEditor,
                        ids[i],
                        files[i],
                        fileLinks[i]
                    );
                }
            }
        }
    } catch (error) {
        console.error(error);
    }
}

async function wait(): Promise<any> {
    return await new Promise((resolve) => setTimeout(resolve, 1000));
}

function* uploadFile(
    idEditor: string,
    idFile: string,
    file: File,
    postInfo: any
): any {
    try {
        const [resp] = yield all([call(postFile, file, postInfo), call(wait)]);
        yield put(
            prepare(
                idEditor,
                PayloadEditorActions.updateFile({
                    id: idFile,
                    state: PayloadEditorStateTypes.COMPLETED,
                    S3Key: resp.S3Key,
                    fileName: resp.fileName,
                })
            )
        );
    } catch (error) {
        console.error(error);
    }
}

function* watchUploadFiles(): any {
    yield takeEvery([PayloadEditorActions.uploadFiles.type], uploadFiles);
}

/**
 * Function called when new image needs to be uploaded
 * @param action
 */
function* uploadImage(action: AnyAction): any {
    try {
        if (PayloadEditorActions.uploadImage.match(action)) {
            const url = URL.createObjectURL(action.payload);
            const entityData = yield call(calcImageEntityData, url);
            const idEditor = (action as any).meta.idEditor;
            const { editorState, images } = yield select(
                (state) =>
                    state.payloadEditors[idEditor] ?? InitialPayloadEditorState
            );

            const entityKey = editorState
                .getCurrentContent()
                .createEntity('IMAGE', 'MUTABLE', entityData)
                .getLastCreatedEntityKey();
            const newEditorState = AtomicBlockUtils.insertAtomicBlock(
                editorState,
                entityKey,
                ' '
            );
            const imageKey = entityData.imageKey;
            yield put(
                prepare(
                    idEditor,
                    PayloadEditorActions.updateState({
                        images: {
                            ...images,
                            [imageKey]: {
                                url,
                                imageKey,
                                state: PayloadEditorStateTypes.UPLOADING,
                            },
                        },
                        editorState: newEditorState,
                    })
                )
            );
            try {
                const [uploadInfo] = yield call(getUploadUrls, [
                    action.payload,
                ]);
                const result = yield call(postFile, action.payload, uploadInfo);

                yield put(
                    prepare(
                        idEditor,
                        PayloadEditorActions.updateImage({
                            url,
                            imageKey,
                            S3Key: result.S3Key,
                            state: PayloadEditorStateTypes.COMPLETED,
                        })
                    )
                );
            } catch (error) {
                console.error(error);
                yield put(
                    prepare(
                        idEditor,
                        PayloadEditorActions.updateImage({
                            url,
                            imageKey,
                            state: PayloadEditorStateTypes.ERROR,
                        })
                    )
                );
            }
        }
    } catch (error) {
        console.error(error);
    }
}

function* watchUploadImage(): any {
    yield takeEvery([PayloadEditorActions.uploadImage.type], uploadImage);
}
/**
 * Function called when a template is added to the editor
 * @param action
 */
function* addTemplate(action: AnyAction): any {
    try {
        if (PayloadEditorActions.calcTemplate.match(action)) {
            const idEditor = (action as any).meta.idEditor;
            const { templateContext } = yield select(
                (state) =>
                    state.payloadEditors[idEditor] ?? InitialPayloadEditorState
            );
            const payload = { ...action.payload.payload };
            if (payload.draft) {
                payload.draft = yield call(
                    mapDraftEntities,
                    templateContext,
                    payload.draft
                );
            }
            yield put(
                prepare(idEditor, PayloadEditorActions.addTemplate(payload))
            );
        }
    } catch (error) {
        console.error(error);
    }
}

function* watchAddTemplate(): any {
    yield takeEvery([PayloadEditorActions.calcTemplate.type], addTemplate);
}

export default [
    fork(watchUploadFiles),
    fork(watchUploadImage),
    fork(watchAddTemplate),
];
