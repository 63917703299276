import { useSelector } from 'react-redux';
import stepMenuStyles from '../StepMenu.module.css';
import { nanoid } from 'nanoid';
import {
    EntityValuePicker,
    FormStep,
    MapperStep,
} from '../../../../@Types/FormTypes/FormStep';
import styles from './ConceptMenu.module.css';
import React, { useRef, useState } from 'react';
import { Popper } from '@material-ui/core';
import { RootState } from '../../../../utils/_store';
import IconTypes from '../../../../constants/IconTypes';
import StepTypes, { FormStepTypes } from '../../../../constants/FormStepTypes';
import EntityIcon from '../../../../shared/SmartIcons/EntityIcon';
import { Concept } from '../../../../@Types/Project';
import LocationTypes from '../../../../constants/LocationTypes';
import { FormStepLocation } from '../../../../@Types/FormTypes/Form';
import { MapperStyleTypes } from '@arquimedes.co/eureka-forms/dist/constants/FormStepTypes';
import { addMapperStep } from '@arquimedes.co/eureka-forms/dist/App/AppFunctions';
import EurekaElementMenuElement from '../../../../shared/@Menus/ElementMenu/EurekaElementMenuElement';
import FormStepIcon from '../../../../Icons/FormStepIcon';
import { calcFormStepLabel } from '../../Steps/AuxStepfunctions';
interface ConceptMenuProps {
    concept: Concept;
    location: FormStepLocation;
    handleAdd: (step: FormStep) => void;
}
function ConceptMenu({ concept, ...props }: ConceptMenuProps): JSX.Element {
    const [hovering, setHovering] = useState(false);
    const [hoveringMenu, setHoveringMenu] = useState(false);
    const steps = Object.values(concept.steps);
    const btnRef = useRef<HTMLDivElement>(null);
    if (steps.length === 0) return <div></div>;

    return (
        <React.Fragment>
            <div
                className={stepMenuStyles.option}
                onMouseEnter={(): void => {
                    setHovering(true);
                }}
                onMouseLeave={(): void => {
                    setHovering(false);
                }}
                ref={btnRef}
                style={{
                    backgroundColor:
                        hovering || hoveringMenu
                            ? ' var(--light-grey)'
                            : 'white',
                }}
                onClick={(): void => {
                    const steps: Record<string, FormStep> = {};

                    for (const idStep of concept.baseSteps) {
                        const step = concept.steps[idStep];
                        if (!step) continue;
                        steps[idStep] = concept.steps[idStep];
                    }

                    const step: MapperStep = {
                        id: StepTypes.MAPPER + '-' + nanoid(),
                        type: StepTypes.MAPPER,
                        label: 'Elementos',
                        description: '',
                        addBtnLabel: 'Agregar',
                        required: false,
                        unitLabel: 'Elemento',
                        creatable: true,
                        style: MapperStyleTypes.PILL,
                        rootSteps: concept.baseSteps,
                        steps,
                    };

                    const Mapper = addMapperStep(step as any, {});
                    step.steps = Mapper.steps as any;
                    step.rootSteps = step.rootSteps.map(
                        (idStep) => Mapper.element.ids[idStep]
                    );
                    step.concept = {
                        idConcept: concept.id,
                        mappings: concept.baseSteps.reduce(
                            (a, idStep) => ({
                                ...a,
                                [idStep]: Mapper.element.ids[idStep],
                            }),
                            {}
                        ),
                    };

                    props.handleAdd(step);
                }}
            >
                <div className={styles.entityInputContainer}>
                    <div className={styles.entityIconContainer}>
                        <EntityIcon fill={'var(--secondary)'} idEntity={null} />
                    </div>
                </div>
                <div className={stepMenuStyles.optionLabel}>{concept.name}</div>
            </div>
            {(hovering || hoveringMenu) && (
                <Popper
                    open={true}
                    anchorEl={btnRef.current}
                    placement={'right-end'}
                    modifiers={{
                        preventOverflow: {
                            enabled: true,
                            priority: ['right', 'left'],
                            boundariesElement: 'viewport',
                        },
                    }}
                    style={{ zIndex: 3 }}
                >
                    <div
                        className={styles.container}
                        onMouseEnter={(): void => {
                            setHoveringMenu(true);
                        }}
                        onMouseLeave={(): void => {
                            setHoveringMenu(false);
                        }}
                    >
                        <div className={styles.bottomLeftPadding}></div>
                        <div>
                            {steps.map((step, index): JSX.Element => {
                                if (
                                    step.type ===
                                    FormStepTypes.ENTITYVALUEPICKER
                                ) {
                                    return (
                                        <EntityStep
                                            step={step}
                                            {...props}
                                            key={step.id}
                                            first={index === 0}
                                        />
                                    );
                                } else {
                                    return (
                                        <EurekaElementMenuElement
                                            key={step.id}
                                            Icon={(props): JSX.Element => {
                                                return (
                                                    <FormStepIcon
                                                        {...props}
                                                        type={step.type}
                                                    />
                                                );
                                            }}
                                            Label={calcFormStepLabel(step)}
                                            onClick={(): void =>
                                                props.handleAdd({
                                                    ...step,
                                                    id:
                                                        step.type +
                                                        '-' +
                                                        nanoid(),
                                                })
                                            }
                                        />
                                    );
                                }
                            })}
                        </div>
                    </div>
                </Popper>
            )}
        </React.Fragment>
    );
}

export default ConceptMenu;

function EntityStep({
    step,
    first,
    location,
    handleAdd,
}: {
    step: EntityValuePicker;
    first: boolean;
    location: FormStepLocation;
    handleAdd: (step: EntityValuePicker) => void;
}): JSX.Element {
    const entity = useSelector(
        (state: RootState) => state.site.entities[step.idEntity]
    );
    return (
        <div
            className={styles.option}
            style={first ? { borderTop: 'none' } : {}}
            onClick={(): void => {
                const defaultValue: EntityValuePicker = {
                    id:
                        StepTypes.ENTITYVALUEPICKER +
                        '-' +
                        entity._id +
                        '-' +
                        nanoid(),
                    type: StepTypes.ENTITYVALUEPICKER,
                    label: entity.name,
                    icon: entity.icon ?? IconTypes.GENERIC,
                    searchable: true,
                    showIcon: true,
                    description: entity.description,
                    idEntity: entity._id,
                    required: false,
                    filters: [],
                    path: [],
                    options: {},
                    size: 1,
                };
                if (location.type === LocationTypes.SECTION) {
                    defaultValue.maxSize = 1;
                }
                handleAdd(defaultValue);
            }}
        >
            <div className={styles.entityInputContainer}>
                <div className={styles.entityIconContainer}>
                    <EntityIcon
                        fill={'var(--secondary)'}
                        idEntity={entity._id}
                    />
                </div>
            </div>
            <div className={styles.optionLabel}>{entity.name}</div>
        </div>
    );
}
