import { useMemo, useRef, useState } from 'react';
import { Ticket } from '../../../../../@Types/@Types';
import styles from './HeaderSideBar.module.css';
import React from 'react';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Dialog from '../../../../../shared/Dialog/Dialog';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../utils/_store';
import StatesMenu from '../../../../../shared/@Menus/StatesMenu/StatesMenu';
import { State } from '../../../../../@Types/State';
import StateChangeDialog from './StateChangeDialog/StateChangeDialog';
import { useTicketPermission } from '../../../../../utils/PermissionsFunctions';
import { ProjectPermissions } from '../../../../../constants/Permissions';
import { useCurrentProject } from '../../../../../hooks';
import StateTypes from '../../../../../constants/StateTypes';
interface HeaderSideBarProps {
    /** The ticket currently being displayed */
    selectedElement: Ticket;
    /** If the layout is mobile */
    layoutIsMobile?: boolean;
}

function HeaderSideBar({
    layoutIsMobile,
    selectedElement,
}: HeaderSideBarProps): JSX.Element {
    const [hovering, setHovering] = useState(false);
    const project = useCurrentProject();
    const projects = useSelector((state: RootState) => state.site.projects);
    const [showConfirmState, setShowConfirmState] = useState<State | undefined>(
        undefined
    );
    const [open, setOpen] = useState(false);
    const headersRef = useRef<HTMLDivElement>(null);

    const hasClosePermission = useTicketPermission(
        selectedElement,
        ProjectPermissions.CLOSE
    );

    const canClose = useMemo(
        () =>
            hasClosePermission &&
            !selectedElement.state?.controlled &&
            selectedElement.subTickets?.find(
                (subTicket) =>
                    projects[subTicket.idProject].states[subTicket.state]
                        ?.type !== StateTypes.CLOSED
            ) === undefined,
        [hasClosePermission, selectedElement]
    );

    const hasStatePermission = useTicketPermission(
        selectedElement,
        ProjectPermissions.CHANGE_STATE
    );

    /** If the current user can change the state of the ticket */
    const canChangeState =
        hasStatePermission && !selectedElement.state?.controlled;
    return (
        <React.Fragment>
            <div
                className={
                    layoutIsMobile
                        ? styles.mobileHeaderContainer
                        : styles.headerContainer
                }
                style={{ minHeight: 70 }}
            >
                {showConfirmState !== undefined && (
                    <Dialog
                        maxWidth="100%"
                        disableEnforceFocus
                        onClose={(): void => {
                            setShowConfirmState(undefined);
                        }}
                    >
                        <StateChangeDialog
                            state={showConfirmState}
                            selectedElement={selectedElement}
                            onConfirm={(): void => {
                                setShowConfirmState(undefined);
                            }}
                        />
                    </Dialog>
                )}
                {open && (
                    <StatesMenu
                        mobile={layoutIsMobile}
                        anchorRef={headersRef}
                        states={Object.values(project.states)
                            .filter(
                                (state) =>
                                    state.controlled !== true &&
                                    state.active !== false
                            )
                            .sort((a, b) => a.order - b.order)
                            .map((state) => state)}
                        onClose={(): void => {
                            setOpen(false);
                        }}
                        zIndex={3}
                        canClose={canClose}
                        canChange={canChangeState}
                        placement={'bottom'}
                        handleUpdate={(state: State): void => {
                            if (state._id !== selectedElement.state?._id) {
                                setShowConfirmState(state);
                            }
                        }}
                        selectedStates={[selectedElement.state]}
                    />
                )}
                <div className={styles.contentContainer} style={{}}>
                    <label className={styles.caseNumberLbl}>
                        {selectedElement.caseNumber}
                    </label>

                    <div
                        className={styles.stateContainer}
                        data-testid="HeadSideBar_stateContainer"
                        ref={headersRef}
                        style={{
                            color: hovering
                                ? 'var(--contrast)'
                                : 'var(--accent)',
                            cursor:
                                !open && (canChangeState || canClose)
                                    ? 'pointer'
                                    : 'default',
                        }}
                        onMouseOver={(): void => {
                            //REMOVE FOR REOPEN
                            if (!open && (canChangeState || canClose)) {
                                setHovering(true);
                            }
                        }}
                        onMouseLeave={(): void => {
                            setHovering(false);
                        }}
                        onClick={(): void => {
                            if (canChangeState || canClose) {
                                setOpen(true);
                                setHovering(false);
                            }
                        }}
                    >
                        <label className={styles.stateLbl}>
                            {selectedElement.state?.label}
                        </label>
                        {(canChangeState || canClose) && (
                            //REMOVE FOR REOPEN
                            <div
                                className={styles.editContainer}
                                style={{
                                    color: hovering
                                        ? 'var(--contrast)'
                                        : 'var(--accent)',
                                }}
                            >
                                <EditRoundedIcon fontSize="inherit" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export default HeaderSideBar;
