import RoundedSmartSelect, {
    RoundedSmartSelectProps,
} from '../../RoundedSmartSelect/RoundedSmartSelect';
import { useMemo, useState } from 'react';
import { IntegrationsApi } from '../../../controllers/IntegratrionsController/IntegrationsService';
import IntegrationTypes from '../../../constants/IntegrationTypes';
import { useIdProject } from '../../../hooks';
import ApiIcon from '../../../Icons/ApiIcon';

interface RoundedIntegrationPickerProps
    extends Omit<RoundedSmartSelectProps, 'onChange'> {
    idEntity?: string;
    types: IntegrationTypes[];
    onChange?: (idIntegration: string) => void;
}
function RoundedIntegrationPicker({
    value,
    types,
    idEntity,
    onChange,
    inputRef,
    ...others
}: RoundedIntegrationPickerProps): JSX.Element {
    const idProject = useIdProject();
    const [loading, setLoading] = useState(false);

    const { data: integrationsData } = IntegrationsApi.useLoadIntegrationsQuery(
        {
            idEntity,
            idProject,
        }
    );
    const integrations = useMemo(
        () =>
            integrationsData?.filter(
                (integration) =>
                    types.includes(integration.type) &&
                    (idEntity || !integration.idEntity)
            ) ?? [],
        [integrationsData, types]
    );
    return (
        <RoundedSmartSelect
            {...others}
            onClick={(): void => {
                if (integrationsData === undefined) {
                    setLoading(true);
                }
            }}
            value={{
                id: integrations.find(
                    (integration) => integration._id === value
                )?._id,
                label: integrations.find(
                    (integration) => integration._id === value
                )?.name,
            }}
            loading={loading}
            options={integrations.map((integration) => ({
                id: integration._id,
                label: integration.name,
            }))}
            inputRef={inputRef}
            fullWidth
            backgroundColor={'white'}
            height={'40px'}
            icon={
                <ApiIcon
                    fill="#757575"
                    style={{
                        display: 'flex',
                        height: 23,
                        width: 23,
                    }}
                />
            }
            containerMargin="0px"
            handleUpdate={(value: any): void => onChange?.(value.id)}
            getValueString={(option): string => option.id}
        />
    );
}

export default RoundedIntegrationPicker;
