import styles from './PendingBottom.module.css';
import { LinearProgress } from '@material-ui/core';

export function PendingBottom(): JSX.Element {
    return (
        <div className={styles.container}>
            <div className={styles.loader}>
                <LinearProgress
                    variant="indeterminate"
                    classes={{
                        barColorPrimary: styles.loaderLine,
                        colorPrimary: styles.loaderFont,
                    }}
                />
            </div>
        </div>
    );
}
