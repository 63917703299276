export enum TicketPropertyTypes {
    SUBJECT = 'SUBJECT',
    TEXT = 'TEXT',
    CLIENT = 'CLIENT',
    CHANNEL = 'CHANNEL',
    COMPANY = 'COMPANY',
    CLASSIFIER = 'CLASSIFIER',
    CREATION_DATE = 'CREATION_DATE',
    RESOLUTION_DATE = 'RESOLUTION_DATE',
    CLOSED_DATE = 'CLOSED_DATE',
    SENTIMENT = 'SENTIMENT',
    CONVERSATION = 'CONVERSATION',
    FORM = 'FORM',
    VALUES = 'VALUES',
    STATE = 'STATE',
    SURVEY = 'SURVEY',
    SUBTICKET = 'SUBTICKET',
    ENTRY = 'ENTRY',
    PROCESS = 'PROCESS',
    CONCEPT = 'CONCEPT',
    CASENUMBER = 'CASENUMBER',
    ENTITYVALUE = 'ENTITYVALUE',
}
