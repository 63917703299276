function LeakIcon(props: any): JSX.Element {
    return (
        <svg
            fill={props.fill}
            className={props.class}
            style={props.style}
            height="512pt"
            viewBox="0 -86 512.00056 512"
            width="512pt"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="m203.484375 196.359375c2.402344 0 4.753906-.867187 6.601563-2.492187l26.621093-23.394532c3.03125-2.664062 4.164063-6.890625 2.871094-10.714844l-9.21875-27.257812 21.613281-18.996094c3.03125-2.664062 4.164063-6.890625 2.871094-10.714844l-11.359375-33.570312c-1.070313-3.167969-3.652344-5.589844-6.882813-6.457031l-57.925781-15.519531c-5.335937-1.433594-10.816406 1.734374-12.246093 7.070312-1.429688 5.335938 1.734374 10.820312 7.070312 12.25l52.753906 14.132812 7.507813 22.1875-21.613281 18.996094c-3.03125 2.664063-4.164063 6.890625-2.871094 10.714844l9.21875 27.257812-17.59375 15.460938-130.027344-34.839844 30.992188-115.667968c.6875-2.5625.328124-5.292969-.996094-7.589844-1.328125-2.296875-3.511719-3.972656-6.074219-4.660156l-45.574219-12.210938c-5.332031-1.429688-10.816406 1.734375-12.246094 7.070312l-36.636718 136.722657c-.6875 2.5625-.324219 5.292969 1 7.589843 1.324218 2.292969 3.511718 3.972657 6.070312 4.65625l45.574219 12.210938c.867187.234375 1.738281.34375 2.597656.34375 4.414063 0 8.453125-2.945312 9.652344-7.414062l.464844-1.734376 135.199219 36.226563c.851562.230469 1.722656.34375 2.585937.34375zm-181.238281-56.703125 31.460937-117.40625 26.253907 7.035156-31.457032 117.40625zm0 0" />
            <path d="m511.660156 144.136719-36.636718-136.722657c-1.429688-5.332031-6.910157-8.5-12.246094-7.070312l-45.574219 12.210938c-2.5625.6875-4.746094 2.363281-6.070313 4.660156-1.328124 2.296875-1.6875 5.023437-1 7.585937l.464844 1.734375-135.199218 36.226563c-3.28125.878906-5.894532 3.367187-6.933594 6.605469-1.039063 3.238281-.363282 6.777343 1.796875 9.40625l19.121093 23.265624-5.867187 25.070313c-.796875 3.40625.242187 6.980469 2.738281 9.425781l19.386719 18.988282-6.847656 28.496093c-.824219 3.429688.21875 7.039063 2.742187 9.5 1.894532 1.84375 4.40625 2.839844 6.980469 2.839844.863281 0 1.730469-.113281 2.585937-.34375l57.925782-15.519531c5.335937-1.429688 8.5-6.914063 7.070312-12.25-1.429687-5.332032-6.914062-8.5-12.246094-7.070313l-41.683593 11.171875 4.246093-17.664062c.820313-3.421875-.214843-7.023438-2.726562-9.484375l-19.417969-19.015625 5.714844-24.398438c.710937-3.035156-.035156-6.222656-2.011719-8.628906l-12.40625-15.09375 120.207032-32.207031 30.992187 115.667969c1.199219 4.46875 5.238281 7.414062 9.65625 7.414062.855469 0 1.726563-.109375 2.59375-.339844l45.574219-12.210937c2.5625-.6875 4.746094-2.363281 6.070312-4.660157 1.328125-2.296874 1.6875-5.027343 1-7.589843zm-48.160156 2.554687-30.996094-115.667968s0 0 0-.003907v-.003906l-.464844-1.730469 26.257813-7.035156 31.457031 117.40625zm0 0" />
            <path d="m263.386719 203.265625c-1.894531-2.078125-4.574219-3.261719-7.386719-3.261719-2.808594 0-5.492188 1.183594-7.386719 3.261719-.78125.855469-1.640625 1.789063-2.570312 2.792969-14.488281 15.730468-44.640625 48.457031-44.640625 79.34375 0 30.105468 24.492187 54.601562 54.597656 54.601562s54.597656-24.496094 54.597656-54.601562c0-30.886719-30.148437-63.613282-44.640625-79.339844-.925781-1.007812-1.789062-1.941406-2.570312-2.796875zm-7.386719 116.738281c-19.078125 0-34.597656-15.523437-34.597656-34.601562 0-20.492188 20.960937-45.539063 34.597656-60.601563 13.636719 15.0625 34.597656 40.109375 34.597656 60.601563 0 19.078125-15.519531 34.601562-34.597656 34.601562zm0 0" />
            <path d="m129.878906 54.871094c.917969.261718 1.839844.386718 2.75.386718 4.351563 0 8.355469-2.863281 9.609375-7.253906 1.519531-5.3125-1.554687-10.847656-6.867187-12.363281h-.007813c-5.308593-1.519531-10.839843 1.554687-12.359375 6.867187-1.515625 5.308594 1.5625 10.847657 6.875 12.363282zm0 0" />
            <path d="m407.335938 149.527344-.007813.003906c-5.335937 1.421875-8.507813 6.898438-7.082031 12.238281 1.191406 4.472657 5.238281 7.421875 9.65625 7.421875.855468 0 1.722656-.109375 2.585937-.339844 5.335938-1.421874 8.507813-6.902343 7.085938-12.238281-1.425781-5.335937-6.90625-8.511719-12.238281-7.085937zm0 0" />
        </svg>
    );
}
export default LeakIcon;
