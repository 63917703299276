class CustomTimeout {
    public readonly originalDelay: number;
    private delay: number;
    private idTimeout: NodeJS.Timeout;
    private readonly callback: Function;
    public endTime: number | undefined;
    private active = true;

    constructor(callback: Function, delay: number) {
        this.originalDelay = delay;
        this.delay = delay;
        this.callback = callback;
        this.endTime = new Date().getTime() + this.delay;
        this.idTimeout = setTimeout(() => {
            this.callback();
            this.active = false;
        }, this.delay);
    }

    public resume(): void {
        if (this.active) {
            this.endTime = new Date().getTime() + this.delay;
            this.idTimeout = setTimeout(async () => {
                await this.execute();
            }, this.delay);
        }
    }

    public pause(): void {
        if (this.active && this.endTime) {
            clearTimeout(this.idTimeout);
            this.delay = this.endTime - new Date().getTime();
            this.endTime = undefined;
        }
    }

    public timeLeft(): number {
        if (this.endTime) {
            return this.endTime - new Date().getTime();
        }
        return this.delay;
    }

    private async execute(): Promise<void> {
        clearTimeout(this.idTimeout);
        await this.callback();
        this.active = false;
    }

    public async executeNow(margin?: number): Promise<void> {
        if (this.active && (!margin || this.timeLeft() > margin)) {
            await this.execute();
        }
    }

    public isActive(): boolean {
        return this.active;
    }

    public restart(): void {
        this.delay = this.originalDelay;
        this.endTime = new Date().getTime() + this.delay;
        this.idTimeout = setTimeout(async () => {
            await this.execute();
        }, this.delay);
        this.active = true;
    }
}

export default CustomTimeout;
