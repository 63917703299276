import { EntityValuePicker } from '../../../../../@Types/FormTypes/FormStep';
import { EntityValuePickerDialog } from '../../../../../@Types/GenericFormSteps';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import styles from './EntityValuePickerDialog.module.css';
import FilterIcon from '../../../../../Icons/FilterIcon';
import React, { useState } from 'react';
import Dialog from '../../../../../shared/Dialog/Dialog';
import ConditionEditor from '../../../../../shared/ConditionEditor/ConditionEditor';
import produce from 'immer';
import ConditionTypes from '../../../../../constants/Conditions/ConditionTypes';
import { FormStepLocation } from '../../../../../@Types/FormTypes/Form';
import { useDispatch } from 'react-redux';
import RoundedSelect from '../../../../../shared/RoundedSelect/RoundedSelect';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { MenuItem } from '@material-ui/core';
import DraftRenderer from '../../../../../shared/DraftRenderer/DraftRenderer';
import { EditorState, convertFromRaw } from 'draft-js';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import PayloadEditor from '../../../../../shared/TextEditor/PayloadEditor';
import { Payload } from '../../../../../@Types/Payload';
import { PayloadEditorActions } from '../../../../../controllers/PayloadEditorController/PayloadEditorSlice';

interface EntityValuePickerDialogProps {
    step: EntityValuePicker;
    dialog: EntityValuePickerDialog;
    location: FormStepLocation;
    handleUpdate: (dialog: EntityValuePickerDialog) => void;
    handleDelete: () => void;
}

function EntityValuePickerDialogComponent({
    step,
    dialog,
    handleUpdate,
    handleDelete,
}: EntityValuePickerDialogProps): JSX.Element {
    const [showCondition, setShowCondition] = useState(false);
    const [showEditing, setShowEditing] = useState(false);
    const dispatch = useDispatch();

    return (
        <React.Fragment>
            {showCondition && (
                <Dialog
                    open
                    onClose={(): void => setShowCondition(false)}
                    border={20}
                    maxWidth="90vw"
                    disableEnforceFocus
                >
                    <div className={styles.conditionContainer}>
                        <div className={styles.nameLbl}>
                            Condición de la alerta
                        </div>
                        <div className={styles.decisionsContainer}>
                            <ConditionEditor
                                condition={dialog.condition}
                                context={{
                                    entityValue: {
                                        idEntity: step.idEntity,
                                        public: true,
                                    },
                                    form: {
                                        idStep: step.id,
                                    },
                                }}
                                handleDelete={(): void =>
                                    handleUpdate(
                                        produce(dialog, (dialog) => {
                                            dialog.condition = undefined;
                                        })
                                    )
                                }
                                handleUpdate={(condition): void => {
                                    handleUpdate(
                                        produce(dialog, (dialog) => {
                                            dialog.condition = condition;
                                        })
                                    );
                                }}
                                conditionTypes={[
                                    ConditionTypes.FORM_STEP,
                                    ConditionTypes.ENTITYVALUE,
                                ]}
                            />
                        </div>
                    </div>
                </Dialog>
            )}
            {showEditing && (
                <Dialog
                    open
                    onClose={async (): Promise<void> => {
                        const payload: Payload | null = await dispatch(
                            PayloadEditorActions.calcPayload({
                                idEditor: step.id,
                            }) as any
                        ).unwrap();
                        handleUpdate(
                            produce(dialog, (dialog) => {
                                if (!payload?.draft) return;
                                dialog.message = payload.draft;
                            })
                        );
                        setShowEditing(false);
                    }}
                    border={20}
                    maxWidth="90vw"
                    disableEnforceFocus
                >
                    <div className={styles.payloadContainer}>
                        <PayloadEditor
                            idEditor={step.id}
                            payload={{
                                draft: dialog.message,
                            }}
                            nested
                            media={false}
                            context={{
                                isPublic: true,
                            }}
                            mappings={{
                                entities: [step.idEntity],
                            }}
                            multiline={true}
                            conditions={{
                                types: [
                                    ConditionTypes.FORM_STEP,
                                    ConditionTypes.ENTITYVALUE,
                                ],
                                context: {
                                    entityValue: {
                                        idEntity: step.idEntity,
                                        public: true,
                                    },
                                    form: {
                                        idStep: step.id,
                                    },
                                },
                            }}
                        />
                    </div>
                </Dialog>
            )}
            <div className={styles.container}>
                <div className={styles.deleteBtn} onClick={handleDelete}>
                    <DeleteRoundedIcon fontSize="inherit" />
                </div>
                <div
                    className={styles.conditionBtn}
                    onClick={(): void => {
                        setShowCondition(true);
                    }}
                    title="Condición"
                >
                    <FilterIcon
                        style={{
                            display: 'flex',
                            height: 18,
                            width: 18,
                            marginTop: 2,
                        }}
                        fill={
                            dialog.condition
                                ? 'var(--secondary)'
                                : 'var(--greyFont)'
                        }
                    />
                </div>
                <div className={styles.iconContainer}>
                    <RoundedSelect
                        height="60px"
                        minWidth={90}
                        fontSize="40px"
                        value={dialog.type}
                        containerMargin="0px"
                        handleUpdate={(event): void => {
                            handleUpdate(
                                produce(dialog, (dialog) => {
                                    dialog.type = event.target.value;
                                })
                            );
                        }}
                    >
                        <MenuItem
                            value={'WARNING'}
                            style={{
                                fontSize: 40,
                                paddingBottom: 15,
                            }}
                        >
                            <WarningRoundedIcon
                                fontSize="inherit"
                                style={{
                                    color: 'var(--error)',
                                    marginTop: 5,
                                }}
                            />
                        </MenuItem>
                        <MenuItem
                            value={'INFO'}
                            style={{
                                fontSize: 40,
                                paddingBottom: 10,
                            }}
                        >
                            <InfoRoundedIcon
                                fontSize="inherit"
                                style={{
                                    color: 'var(--secondary)',
                                    marginTop: 5,
                                }}
                            />
                        </MenuItem>
                    </RoundedSelect>
                </div>
                <div
                    className={styles.textContainer}
                    onClick={(): void => {
                        setShowEditing(true);
                    }}
                >
                    <DraftRenderer
                        editorState={EditorState.createWithContent(
                            convertFromRaw(dialog.message)
                        )}
                    />
                    <div className={styles.editIcon}>
                        <EditRoundedIcon fontSize="inherit" />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default EntityValuePickerDialogComponent;
