import React, { useCallback, useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/SearchRounded';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

interface StyleProps {
    /** material ui style seed */
    seed?: string;
}

const useStyles = (props: StyleProps): Function =>
    makeStyles(() => ({
        root: {
            backgroundColor: 'var(--defaultGrey)',
            borderRadius: 10,
            height: 27,
            '& input': {
                padding: '6px 12px',
                fontWeight: 400,
                fontSize: 13,
                '&::placeholder': {
                    color: 'var(--dark-grey)',
                    opacity: 1,
                },
                paddingRight: '0px',
            },
            '& label': {
                marginTop: '-5px',
                fontSize: 13,
                fontWeight: 400,
            },
            [`& label.${props.seed}MuiInputLabel-shrink`]: {
                margin: -2,
            },
            '& input + fieldset': {
                borderRadius: 10,
            },
            [`& .${props.seed}MuiInput-underline:after`]: {
                borderBottomColor: '#0000003b',
            },
            [`& .${props.seed}MuiOutlinedInput-root`]: {
                '&.Mui-focused fieldset': {
                    borderColor: 'var(--greyFont)',
                },
                borderRadius: 10,
                height: 27,
            },
            [`& .${props.seed}MuiInputAdornment-root`]: {
                marginLeft: 0,
            },
            [`& .${props.seed}MuiOutlinedInput-root:hover .${props.seed}MuiOutlinedInput-notchedOutline`]:
                {
                    borderColor: 'var(--greyFont)',
                    borderRadius: 10,
                },

            '& label.Mui-focused': {
                color: 'var(--greyFont)',
                marginTop: 0,
                marginLeft: 1,
                fontSize: 15,
            },
        },
    }));

interface SearchProps extends StyleProps {
    /** Function to call on search, when user stops typing after waiting the specified wait time */
    handleSearch: (value: string, loading: boolean) => void;
    /** Search value */
    search: string | undefined;
    /** Function to call when user starts typing */
    handleLoadingSearch?: Function;
    /** The placeholder if it has no value */
    placeholder?: string;
    /** Time to wait between when the user starts typing and the handleSearch function should be called */
    wait?: number;
    /** if the search bar is disabled */
    disabled?: boolean;
    /** If should autoFocus the searchbar */
    autoFocus?: boolean;
}

function Search({
    handleSearch,
    disabled = false,
    autoFocus = false,
    search,
    handleLoadingSearch,
    placeholder = 'Buscar',
    wait = 1000,
    seed = 'Erk-',
}: SearchProps): JSX.Element {
    const classes = useStyles({ seed })();
    const [searchTimer, setSearchTimer] = useState<number | null>(null);
    const ref = React.useRef<HTMLInputElement>(null);
    const [value, setValue] = useState('');

    useEffect((): void => {
        if (search === undefined) {
            setValue('');
        } else if (value !== search) {
            setValue(search);
        }
    }, [search]);

    /**
     * Deletes timeout on component exit.
     */
    useEffect((): (() => void) => {
        return (): void => {
            if (searchTimer) clearTimeout(searchTimer);
        };
    }, [searchTimer]);

    useEffect((): void => {
        if (autoFocus) {
            setTimeout(() => {
                if (ref.current !== null) {
                    ref.current.focus();
                }
            }, 100);
        }
    }, []);

    const onChange = useCallback(
        (
            e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ): void => {
            const value = e.target.value;
            setValue(value);
            // //If its the first char start loading
            if (searchTimer === null) handleLoadingSearch?.(true);

            if (searchTimer) clearTimeout(searchTimer);

            if ((value === '' && search === undefined) || value === search) {
                handleLoadingSearch?.(false);
                setSearchTimer(null);
            } else {
                setSearchTimer(
                    window.setTimeout(() => {
                        handleSearch(value, false);
                        setSearchTimer(null);
                    }, wait)
                );
            }
        },
        [searchTimer, handleLoadingSearch, value, handleSearch, wait]
    );

    return (
        <TextField
            inputRef={ref}
            data-testid={'Search'}
            classes={classes}
            variant="outlined"
            placeholder={placeholder}
            size="small"
            fullWidth
            disabled={disabled}
            value={value}
            onChange={onChange}
            InputProps={{
                spellCheck: true,
                endAdornment: (
                    <InputAdornment position="end">
                        {value == '' ? (
                            <SearchIcon />
                        ) : (
                            <CloseRoundedIcon
                                style={{ cursor: 'pointer', width: '20px' }}
                                onClick={(): void => {
                                    setValue('');
                                    handleSearch('', false);
                                }}
                            />
                        )}
                    </InputAdornment>
                ),
            }}
        />
    );
}

export default Search;
