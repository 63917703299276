import styles from './ClassifiersFilters.module.css';
import React, { useState, useEffect } from 'react';
import Search from '../../../../shared/Search/Search';
import { TicketClassifier } from '../../../../@Types/TicketTypes/Ticket';
import Classifiers from '../../../../shared/@Menus/ClassifyMenu/Classifiers/Classifiers';
import Classifying from '../../../../shared/@Menus/ClassifyMenu/Classifying/Classifying';
import { Classifier } from '../../../../@Types/@Types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../utils/_store';
/** Helper interface to optimize the render when a user is searching something */
export interface SearchClassifier extends Classifier {
    /** If the classifier has a child that has the term being searched */
    hasSearchInChild?: boolean;
    /** If the classifier has the term being serched */
    hasSearch?: boolean;
}

/**
 * Object that stores the values currently being classified
 */
export interface ClassifierObj {
    /** root of the classifier currently bieng classified */
    idRoot: string;
    /** Currently classified value */
    idValue: string | null;
    /** parent of the currently displayed classifiers */
    current: string;
}
interface ClassifiersFiltersProps {
    /** Function called when classifiers change */
    handleChange: (filters: TicketClassifier[]) => void;
    /** The currently selected classifiers */
    filters: TicketClassifier[];
    /** Tabs component to display after the search */
    tabsComponent: JSX.Element;
}
function ClassifiersFilters({
    handleChange,
    filters,
    tabsComponent,
}: ClassifiersFiltersProps): JSX.Element {
    const siteInfo = useSelector((state: RootState) => state.site);
    /** The info to display (filtered by the current search) */
    const [info, setInfo] = useState<{
        roots: string[];
        classifiers: Record<string, SearchClassifier>;
    }>({ roots: siteInfo.projectRoots, classifiers: siteInfo.classifiers });

    const [search, setSearch] = useState<string | undefined>(undefined);

    const mobile = false;
    /** Classifier currently being classfied */
    const [classifyingObj, setClassifyingObj] = useState<
        ClassifierObj | undefined
    >(undefined);

    useEffect(() => {
        /** Filter the classifiers with the search value, add the <SEARCH tag when
         *  value is found and store if has the searched value or children that do */
        if (search !== undefined) {
            const roots: string[] = [];
            const classifiers: Record<string, SearchClassifier> = {};
            const regex = new RegExp(search, 'gi');
            const clasifs = Object.values({ ...siteInfo }.classifiers);
            for (let i = 0; i < clasifs.length; i++) {
                const element: SearchClassifier = { ...clasifs[i] };
                if (
                    element.idProject === siteInfo.idProject &&
                    element.active
                ) {
                    let found = false;
                    if (regex.test(element.name)) {
                        element.name = element.name.replace(
                            regex,
                            (match) => `<SEARCH:${match}>`
                        );
                        found = true;
                    }
                    if (regex.test(element.description)) {
                        element.description = element.description.replace(
                            regex,
                            (match) => `<SEARCH:${match}>`
                        );
                        found = true;
                    }
                    if (found) {
                        element.hasSearch = true;
                        let idRoot = element._id;
                        if (element.path) {
                            for (const p of element.path) {
                                if (classifiers[p]) {
                                    classifiers[p] = {
                                        ...classifiers[p],
                                        hasSearchInChild: true,
                                    };
                                } else {
                                    classifiers[p] = {
                                        ...siteInfo.classifiers[p],
                                        hasSearchInChild: true,
                                    };
                                }
                            }
                            idRoot = element.path[0];
                        }
                        if (!roots.includes(idRoot)) {
                            roots.push(idRoot);
                        }
                        classifiers[element._id] = element;
                    }
                }
            }
            for (const classif of Object.values(classifiers)) {
                classif.children = classif.children.filter(
                    (child) => classifiers[child] !== undefined
                );
            }
            setInfo({
                roots,
                classifiers,
            });
        } else {
            /** Filter valid classifers */
            const classifiers: Record<string, SearchClassifier> = {};
            const clasifs = Object.values({ ...siteInfo }.classifiers);
            for (const element of clasifs) {
                if (
                    element.idProject === siteInfo.idProject &&
                    element.active
                ) {
                    classifiers[element._id] = element;
                }
            }
            setInfo({
                roots: siteInfo.projectRoots,
                classifiers,
            });
        }
    }, [search]);

    /**
     * Function that handles changes made in the list
     */
    const change = (classifier: TicketClassifier): void => {
        const classifiers = [...filters];
        let found = false;
        for (let i = 0; i < classifiers.length; i++) {
            if (classifiers[i].idRoot === classifier.idRoot) {
                classifiers[i] = classifier;
                found = true;
                if (classifier.idValue === null) {
                    classifiers.splice(i, 1);
                }
            }
        }
        if (!found && classifier.idValue !== null) {
            classifiers.push(classifier);
        }
        handleChange(classifiers);
    };

    const renderOptions = (): JSX.Element | void => {
        if (classifyingObj === undefined) {
            return (
                <Classifiers
                    searching={search !== undefined}
                    setClassifyingObj={setClassifyingObj}
                    change={change}
                    currentClassifiers={filters}
                    info={info}
                    search={search}
                    noBorderTop
                    allowRoots={true}
                />
            );
        }
        return (
            <Classifying
                searching={search !== undefined}
                classifyingObj={classifyingObj}
                setClassifyingObj={setClassifyingObj}
                change={change}
                noBorderTop
                info={info}
                search={search}
            />
        );
    };

    if (mobile) {
        return <div></div>;
    } else {
        return (
            <React.Fragment>
                <div className={styles.searchContainer}>
                    <Search
                        wait={500}
                        placeholder={'Filtrar clasificadores'}
                        search={search}
                        handleSearch={(value: any): void => {
                            if (value.trim() === '') {
                                setSearch(undefined);
                            } else {
                                setSearch(value);
                            }
                        }}
                    />
                </div>
                {tabsComponent}
                {renderOptions()}
            </React.Fragment>
        );
    }
}
export default ClassifiersFilters;
