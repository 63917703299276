import styles from './StepMenu.module.css';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import TitleIcon from '@material-ui/icons/Title';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SubjectIcon from '@material-ui/icons/Subject';
import StepTypes from '../../../../constants/SurveyStepTypes';
import {
    SurveySelector,
    SurveyStep,
} from '../../../../@Types/SurveyTypes/SurveyStep';
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded';
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import DescriptionIcon from '@material-ui/icons/Description';
import { nanoid } from 'nanoid';
import GradeRoundedIcon from '@material-ui/icons/GradeRounded';
import { RatingTypes } from '../../../../constants/FormStepTypes';
import { SurveyStepLocation } from '../../../../@Types/SurveyTypes/Survey';
import LocationTypes from '../../../../constants/LocationTypes';
import ScheduleRounded from '@material-ui/icons/ScheduleRounded';
interface StepMenuProps {
    handleClose?: Function;
    handleAdd?: (step: SurveyStep) => void;
    anchorRef?: any;
    location?: SurveyStepLocation | null; //Null if main create btn
}

export default function StepMenu({
    handleClose,
    anchorRef,
    handleAdd,
    location,
}: StepMenuProps): JSX.Element {
    if (!handleClose || !handleAdd || !anchorRef || !location) {
        return <div></div>;
    }
    const calcSteps = (): JSX.Element[] => {
        const elements: JSX.Element[] = [];
        elements.push(
            <div
                className={styles.option}
                style={{ borderTop: 'none' }}
                key="TITLE"
                onClick={(): void => {
                    handleAdd({
                        id: StepTypes.TITLE + '-' + nanoid(),
                        type: StepTypes.TITLE,
                        title: 'Título',
                        description: '',
                    });
                }}
            >
                <div className={styles.titleIcon}>
                    <TitleIcon fontSize="inherit" />
                </div>
                <div className={styles.titleOptionLabel}>Título</div>
            </div>
        );
        elements.push(
            <div
                className={styles.option}
                key="RATING"
                onClick={(): void => {
                    handleAdd({
                        id: StepTypes.RATING + '-' + nanoid(),
                        type: StepTypes.RATING,
                        label: 'Calificación',
                        description: '',
                        required: false,
                        isPrimary: false,
                        ratingType: RatingTypes.SATISFACTION,
                        nestedSteps: null,
                    });
                }}
            >
                <div className={styles.titleIcon}>
                    <GradeRoundedIcon fontSize="inherit" />
                </div>
                <div className={styles.titleOptionLabel}>Calificación</div>
            </div>
        );
        elements.push(
            <div
                className={styles.option}
                key="TEXTINPUT"
                onClick={(): void => {
                    handleAdd({
                        id: StepTypes.TEXTINPUT + '-' + nanoid(),
                        type: StepTypes.TEXTINPUT,
                        label: 'Campo',
                        description: '',
                        required: false,
                        size: 2,
                    });
                }}
            >
                <div className={styles.textInputContainer}>A..</div>
                <div className={styles.optionLabel}>Campo de Texto</div>
            </div>
        );
        elements.push(
            <div
                className={styles.option}
                key="SELECTOR"
                onClick={(): void => {
                    const defaultValue: SurveySelector = {
                        id: StepTypes.SELECTOR + '-' + nanoid(),
                        type: StepTypes.SELECTOR,
                        label: 'Opciones',
                        description: '',
                        required: false,
                        searchable: false,
                        options: [],
                        size: 1,
                    };
                    if (location.type === LocationTypes.SECTION) {
                        defaultValue.maxSize = 1;
                    }
                    handleAdd(defaultValue);
                }}
            >
                <div className={styles.selectInputContainer}>
                    <ArrowDropDownIcon fontSize="inherit" />
                </div>
                <div className={styles.optionLabel}>Opciones</div>
            </div>
        );
        elements.push(
            <div
                className={styles.option}
                key="DATEPICKER"
                onClick={(): void => {
                    handleAdd({
                        id: StepTypes.DATEPICKER + '-' + nanoid(),
                        type: StepTypes.DATEPICKER,
                        label: 'Fecha',
                        description: '',
                        pickTime: false,
                        required: false,
                        size: 1,
                    });
                }}
            >
                <div className={styles.dateInputContainer}>
                    <CalendarTodayRoundedIcon fontSize="inherit" />
                </div>
                <div className={styles.optionLabel}>Campo de Fecha</div>
            </div>
        );
        elements.push(
            <div
                className={styles.option}
                key="TIMEPICKER"
                onClick={(): void => {
                    handleAdd({
                        id: StepTypes.TIMEPICKER + '-' + nanoid(),
                        type: StepTypes.TIMEPICKER,
                        label: 'Tiempo',
                        description: '',
                        pickDays: true,
                        pickHours: true,
                        pickMinutes: true,
                        required: false,
                        size: 1,
                    });
                }}
            >
                <div className={styles.dateInputContainer}>
                    <ScheduleRounded fontSize="inherit" />
                </div>
                <div className={styles.optionLabel}>Campo de Tiempo</div>
            </div>
        );

        elements.push(
            <div
                className={styles.option}
                key="TEXTAREA"
                onClick={(): void => {
                    handleAdd({
                        id: StepTypes.TEXTAREA + '-' + nanoid(),
                        type: StepTypes.TEXTAREA,
                        label: 'Comentario',
                        description: '',
                        isComment: false,
                        required: false,
                        hasTextEditor: false,
                    });
                }}
            >
                <div className={styles.textAreaContainer}>
                    <SubjectIcon fontSize="inherit" />
                </div>
                <div className={styles.optionLabel}>Comentario</div>
            </div>
        );
        elements.push(
            <div
                className={styles.option}
                key="FILEUPLOAD"
                onClick={(): void => {
                    handleAdd({
                        id: StepTypes.FILEUPLOAD + '-' + nanoid(),
                        type: StepTypes.FILEUPLOAD,
                        label: 'Adjuntar Archivos',
                        description: '',
                        required: false,
                    });
                }}
            >
                <div className={styles.fileInputContainer}>
                    <DescriptionIcon fontSize="inherit" />
                </div>
                <div className={styles.optionLabel}>Subir Archivos</div>
            </div>
        );
        elements.push(
            <div
                className={styles.option}
                key="CHECKBOX"
                onClick={(): void => {
                    handleAdd({
                        id: StepTypes.CHECKBOX + '-' + nanoid(),
                        type: StepTypes.CHECKBOX,
                        label: 'Campo',
                        description: '',
                        required: false,
                        size: 1,
                    });
                }}
            >
                <div className={styles.checkBoxIcon}>
                    <CheckBoxRoundedIcon fontSize="inherit" />
                </div>
                <div className={styles.optionLabel}>CheckBox</div>
            </div>
        );
        elements.push(
            <div
                className={styles.option}
                key="SEPARATOR"
                onClick={(): void => {
                    handleAdd({
                        id: StepTypes.SEPARATOR + '-' + nanoid(),
                        type: StepTypes.SEPARATOR,
                        transparent: false,
                    });
                }}
            >
                <div className={styles.separatorContainer}>
                    <NavigateBeforeRoundedIcon fontSize="inherit" />
                    <div className={styles.separatorSeparator}></div>
                    <NavigateNextRoundedIcon fontSize="inherit" />
                </div>

                <div className={styles.optionLabel}>Separador</div>
            </div>
        );
        return elements;
    };

    return (
        <ClickAwayListener
            mouseEvent="onMouseDown"
            onClickAway={(): void => {
                handleClose();
            }}
        >
            <Popper
                open={true}
                anchorEl={anchorRef.current}
                placement={'bottom'}
                modifiers={{
                    preventOverflow: {
                        enabled: true,
                        priority: ['top', 'bottom', 'left', 'right'],
                        boundariesElement: 'viewport',
                        padding: 20,
                    },
                }}
                style={{ zIndex: 3 }}
            >
                <div className={styles.container}>{calcSteps()}</div>
            </Popper>
        </ClickAwayListener>
    );
}
