/**
 * Assign node assign types
 */
enum TYPES {
    RANDOM = 'RANDOM',
    LOAD = 'LOAD',
    ALL = 'ALL',
    AUTO = 'AUTO',
}

export default TYPES;
