/* eslint-disable @typescript-eslint/no-unused-vars */
import { Entry, Form } from '../../@Types/@Types';
import axiosInstance from '../../AxiosAPI';
import { migrateFormData } from '../FormsController/FormsService';
/**
 * Function that loads the entries' list
 * @param page currently viewing
 * @param pageSize to request
 * @param filters currently active
 * @param orderBy method ordered by, undefined if search is active
 * @param search search query, undefined if no search is active
 * @returns array of Entries that match the inputs
 */
export const loadEntries = async (
    idProject: string,
    filters?: any[], //TODO definir filtros
    orderBy?: string | undefined,
    search?: string | undefined
): Promise<Entry[]> => {
    let url = `/projects/${idProject}/entries`;
    const resp = await axiosInstance.get(url);
    return resp.data;
};

/**
 * Function that loads the companies by a given list of ids
 * @param companies the list of ids
 */
export const loadEntriesByApiKeys = async (
    idProject: string,
    apiKeys: string[]
): Promise<Entry[]> => {
    const entries = await loadEntries(idProject);
    return entries.filter((entry) => apiKeys.includes(entry.apiKey));
};

/**
 * Function called to handle the conexion with the server to create an entry
 * @param entry Record of values to create the entry with
 */
export const createEntry = async (
    idProject: string,
    entry: Record<string, unknown>
): Promise<Entry> => {
    return await axiosInstance.post(`/projects/${idProject}/entries`, entry);
};

/**
 * Function called to handle the conexion with the server to create an entry
 * @param entry Record of values to create the entry with
 */
export const setupWhatsappSignup = async (
    idProject: string,
    body: {
        name: string;
        description: string;
    }
): Promise<string> => {
    const response = await axiosInstance.post(
        `/projects/${idProject}/entries/whatsapp`,
        body
    );
    return response.data;
};

/**
 * Function that handles the async update of an entries value
 * @param id of the entry to update
 * @param entry Record of values to update
 */
export const updateEntry = async (
    id: string,
    idProject: string,
    entry: Record<keyof Entry, any>
): Promise<void> => {
    await axiosInstance.patch(`/projects/${idProject}/entries/${id}`, entry);
};

/**
 * Function called to handle the conexion with the server to delete an entry
 * @param apiKey the apiKey of the entry to delete
 */
export const deleteEntry = async (
    idProject: string,
    apiKey: string
): Promise<void> => {
    await axiosInstance.delete(`/projects/${idProject}/entries/${apiKey}`);
};

export default {
    loadEntries,
    loadEntriesByApiKeys,
    createEntry,
    updateEntry,
    deleteEntry,
};
