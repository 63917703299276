import styles from './CompanyPickerStep.module.css';
import RoundedTextField from '../../../../shared/RoundedTextField/RoundedTextField';
import RoundedCompanyPicker from '../../../../shared/@Pickers/RoundedCompanyPicker/RoundedCompanyPicker';
import React from 'react';
import { GenericStepProps } from '../../../../shared/GenericFormEditor/Step/Step';
import { CompanyPicker } from '../../../../@Types/FormTypes/FormStep';
import { calcStepWidth } from '../../../../shared/GenericFormEditor/StepFunctions';
import { GLocation } from '../../../../@Types/FormTypes/LocationTypes';

function CompanyPickerStep<U, L extends GLocation>({
    editing,
    step,
    size,
    updateStep,
}: GenericStepProps<CompanyPicker, U, L>): JSX.Element {
    if (editing) {
        return (
            <div className={styles.editingContainer}>
                <div className={styles.inputContainer}>
                    <RoundedTextField
                        label="Etiqueta"
                        value={step.label}
                        onChange={(e): void => {
                            updateStep({ ...step, label: e.target.value });
                        }}
                    ></RoundedTextField>
                </div>

                <RoundedTextField
                    label="Descripción"
                    value={step.description ?? ''}
                    onChange={(e): void => {
                        updateStep({
                            ...step,
                            description: e.target.value,
                        });
                    }}
                ></RoundedTextField>
            </div>
        );
    } else {
        return (
            <div
                style={{
                    width: calcStepWidth(step.size, size),
                    maxWidth: '100%',
                    marginBottom: step.description ? '0px' : '5px',
                }}
            >
                <RoundedCompanyPicker
                    multiple={step.multiple}
                    value={[]}
                    backgroundColor="var(--primary1)"
                    label={step.label}
                    helperText={step.description ?? ''}
                    required={step.required}
                />
            </div>
        );
    }
}

export default React.memo(CompanyPickerStep, (prev, next) => {
    const { step, stepEqualityChecker } = prev;
    const { step: newStep } = next;
    if (
        step.size !== newStep.size ||
        step.label !== newStep.label ||
        step.multiple !== newStep.multiple ||
        step.required !== newStep.required ||
        step.description !== newStep.description
    ) {
        return false;
    }

    return stepEqualityChecker?.(prev, next) ?? true;
}) as typeof CompanyPickerStep;
