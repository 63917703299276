import styles from './ClassifierDetail.module.css';
import React, { useEffect, useState } from 'react';
import { TwitterPicker } from 'react-color';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Create from '../../../shared/Create/Create';
import SubstructureGridElement from './SubClassifierGridElement/SubClassifierGridElement';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import EditContainer from '../../../shared/EditContainer/EditContainer';
import TrendingFlatRoundedIcon from '@material-ui/icons/TrendingFlatRounded';
import { calcTextColor } from '../../../utils/colors';
import { Classifier } from '../../../@Types/@Types';

interface ClassifierDetailProps {
    /** id of the currently selected classifier */
    selectedElement?: string | undefined;
    /** The dictionary of classifiers */
    elements: Record<string, Classifier>;
    /** Function that handles adding a subclassifier*/
    createSubStructure: Function;
    /** Function that handles deleting the current classifier */
    handleClickDelete: Function;
    /** Function that handles updating a value */
    handleUpdate: Function;
    /** if layout is mobile, used to position the color picker */
    layoutIsMobile?: boolean;
}

/**
 * Component that renders the detail of a classifier
 */
function ClassifierDetail({
    selectedElement,
    elements,
    layoutIsMobile,
    createSubStructure,
    handleClickDelete,
    handleUpdate,
}: ClassifierDetailProps): JSX.Element {
    const [choosingColor, setChoosingColor] = useState(false);
    useEffect(() => {
        setChoosingColor(false);
    }, [selectedElement]);
    if (!selectedElement) {
        return <div></div>;
    }
    const element = elements[selectedElement];
    if (!element) {
        return <div></div>;
    }

    /**
     * Computes the visual element to show as the title
     * if path is longer than 3 it returns ... in the begining othewise returns the entire path
     * @returns the title to display
     */
    const calcTitle = (): any => {
        if (!element.path || element.path.length === 0) {
            return <span>&#x200E;{element.name}&#x200E;</span>;
        } else {
            const parents = element.path.map((id) => elements[id]);
            if (parents.length < 3) {
                const elements = [];
                parents.forEach((parent, index) => {
                    elements.push(
                        <span key={'T-' + index}>
                            &#x200E;{parent.name.toString()}&#x200E;
                        </span>
                    );
                    elements.push(
                        <div className={styles.arrowIconContainer} key={index}>
                            <TrendingFlatRoundedIcon fontSize="inherit" />
                        </div>
                    );
                });
                elements.push(
                    <span key={'element'}>
                        &#x200E;{element.name.toString()}&#x200E;
                    </span>
                );
                return elements;
            } else {
                return [
                    <span>&#x200E;{parents[0].name.toString()}&#x200E;</span>,
                    ' ... ',
                    <span>
                        &#x200E;{parents[parents.length - 1].name.toString()}
                        &#x200E;
                    </span>,
                    ,
                    <div className={styles.arrowIconContainer} key="arrow">
                        <TrendingFlatRoundedIcon fontSize="inherit" />
                    </div>,
                    <span key={'element'}>
                        &#x200E;{element.name.toString()}&#x200E;
                    </span>,
                ];
            }
        }
    };
    /**
     * Renders color picker if classifier is root
     * @returns colorpicker
     */
    const renderColor = (): JSX.Element => {
        return (
            <React.Fragment>
                <div className={styles.label}>Color</div>
                <div
                    className={styles.colorPick}
                    style={{
                        backgroundColor: element.color?.background,
                    }}
                    data-testid={'colorPick'}
                    onClick={(): void => {
                        setChoosingColor(true);
                    }}
                />
                {choosingColor && (
                    <div
                        className={styles.colorPickContainer + ' shadow'}
                        style={{ marginLeft: layoutIsMobile ? -20 : 40 }}
                    >
                        <ClickAwayListener
                            mouseEvent="onMouseDown"
                            onClickAway={(): void => {
                                setChoosingColor(false);
                            }}
                        >
                            <TwitterPicker
                                color={element.color?.background}
                                // colors={[
                                //     '#F26430',
                                //     '#E3B505',
                                //     '#6CC551',
                                //     '#2C6E49',
                                //     '#0081AF',
                                //     '#33658A',
                                //     '#ABB8C3',
                                //     '#cc2936',
                                //     '#C45AB3',
                                //     '#700353',
                                // ]}
                                triangle={layoutIsMobile ? 'hide' : 'top-left'}
                                onChangeComplete={(color: {
                                    hex: string;
                                }): void => {
                                    handleUpdate(element, 'color', {
                                        background: color.hex,
                                        font: calcTextColor(color.hex),
                                    });
                                }}
                            />
                        </ClickAwayListener>
                    </div>
                )}
            </React.Fragment>
        );
    };

    /**
     * Renders the classifier's SubClassifiers and the create classifier button in a grid
     * @returns SubClassifiers
     */
    const renderSubClassifiers = (): JSX.Element => {
        return (
            <React.Fragment>
                <div className={styles.label}>Sub Clasificadores</div>
                <div
                    className={styles.subClassifiersContainer + ' flexbox'}
                    data-testid={'subClassifiersContainer'}
                >
                    {element.children.map((child, index) => {
                        return (
                            <div
                                className={styles.subElementContainer}
                                key={index}
                                data-testid={'subElementContainer'}
                            >
                                <SubstructureGridElement
                                    element={elements[child]}
                                />
                            </div>
                        );
                    })}
                    <div
                        className={styles.subElementContainerCreate}
                        data-testid={'addSubClassifiers'}
                    >
                        <Create
                            handleCreate={(): void => {
                                createSubStructure(element);
                            }}
                            label={'Agregar Clasificador'}
                            fontSize={14}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };
    return (
        <div
            className={styles.containerDetail}
            data-testid={'ClassifierDetailContainer'}
        >
            <div
                className={styles.titleContainer}
                data-testid={'titleContainer'}
            >
                <div className={styles.title}>
                    <EditContainer
                        object={element}
                        field={'name'}
                        handleConfirm={handleUpdate}
                    >
                        <div className={styles.titlelbl}>{calcTitle()}</div>
                        <input
                            className={styles.nameInput + ' edit-input'}
                            defaultValue={element.name}
                            type="text"
                            size={10}
                        />
                    </EditContainer>
                    <div>
                        <div
                            className={styles.delBtn + ' grey-btn'}
                            onClick={(): void => {
                                handleClickDelete(element);
                            }}
                            data-testid={'ClassifierDetail_deleteButton'}
                        >
                            <DeleteRoundedIcon
                                style={{ fill: 'black' }}
                                fontSize="inherit"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.descriptionLabel}>Descripción</div>
            <div
                className={styles.descriptionContainer}
                data-testid={'descriptionContainer'}
            >
                <EditContainer
                    object={element}
                    field={'description'}
                    handleConfirm={handleUpdate}
                >
                    <div className={styles.description}>
                        {element.description}
                    </div>
                    <textarea
                        className={styles.descriptionInput + ' edit-input'}
                        defaultValue={element.description}
                    />
                </EditContainer>
            </div>
            <div className={styles.descriptionLabel}>
                Nombre de cara al cliente
            </div>
            <div className={styles.clientNameContainer}>
                <EditContainer
                    object={element}
                    field={'clientName'}
                    handleConfirm={handleUpdate}
                >
                    <div className={styles.clientNameLbl}>
                        {element.clientName}
                    </div>
                    <input
                        className={styles.clientNameInput + ' edit-input'}
                        defaultValue={element.clientName ?? ''}
                        type="text"
                        size={10}
                    />
                </EditContainer>
            </div>

            {element.color && renderColor()}
            {renderSubClassifiers()}
        </div>
    );
}

export default ClassifierDetail;
