import { DraftEntityDataMappingTypes } from '../../../../constants/Draft/DraftEntityDataTypes';
import Context from '../Context';
import { Concept } from '../../../../@Types/Project';
import FormStepTypes from '../../../../constants/FormStepTypes';
import { GenericContextProps } from '../TextEditorContext';

interface ConceptContextsProps extends GenericContextProps {
    concept: Concept;
}

function ConceptContexts({
    concept,
    ...props
}: ConceptContextsProps): JSX.Element {
    return (
        <>
            {Object.values(concept.steps).map((step) => {
                if (step.type === FormStepTypes.SEPARATOR) return <></>;
                return (
                    <Context
                        {...props}
                        key={step.id}
                        data={{
                            type: DraftEntityDataMappingTypes.CONCEPT_MAPPING,
                            idConcept: concept.id,
                            idStep: step.id,
                        }}
                        isMapping={true}
                    />
                );
            })}
        </>
    );
}

export default ConceptContexts;
