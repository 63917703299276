import { useEffect, useState } from 'react';
import { Agent } from '../../@Types/Agent';
import AgentsService from '../../controllers/AgentsController/AgentsService';
import Avatar from './Avatar';
interface SmartAvatarProps {
    /** Size of the avatar */
    size?: number;
    /** If shows text on hover */
    toolTip?: boolean;
    /** The size of the font to display */
    fontSize?: number;
    /** The id of the agent to display */
    idAgent: string;
}
/**
 * Component for custom avatar
 */
function SmartAvatar({
    idAgent,
    size = 20,
    fontSize = 12,
}: SmartAvatarProps): JSX.Element {
    const [agent, setAgent] = useState<Agent | null>(null);

    const loadAgent = async (): Promise<void> => {
        try {
            const agent = await AgentsService.loadAgent(idAgent);
            setAgent(agent);
        } catch (e) {
            //
        }
    };

    useEffect(() => {
        loadAgent();
    }, [idAgent]);

    return (
        <div title={agent?.name}>
            <Avatar
                size={size}
                fontSize={fontSize}
                lbl={agent?.name}
                img={agent?.img}
            ></Avatar>
        </div>
    );
}

export default SmartAvatar;
