import { nanoid } from 'nanoid';
import AYFStepTypes from '../../../../../../constants/Construction/AYFFormStepTypes';
import { AYFConversationStep } from '../../../../../../@Types/ConversationTypes/AYFConversationStep';
import StringValidationTypes, {
    StringValidationValues,
} from '../../../../../../constants/StringValidationTypes';
import { stringToDraft } from '../../../../../../utils/draftFunctions';

export function createAYFConversationStep(
    type: AYFStepTypes,
    id?: string
): AYFConversationStep {
    const baseStep = { id: id ?? type + '-' + nanoid(10), conditions: [] };
    switch (type) {
        case AYFStepTypes.AYF_START_MONTH:
            return {
                ...baseStep,
                type,
                name: 'Solicitud de Mes Inicial',
                label: 'Seleccionar',
                message: stringToDraft('Indícanos el mes inicial'),
                showInTrace: false,
                request: {
                    url: stringToDraft(''),
                    queryParams: [],
                },
                options: {},
            };
        case AYFStepTypes.AYF_END_MONTH:
            return {
                ...baseStep,
                type,
                name: 'Solicitud de Mes Final',
                label: 'Seleccionar',
                message: stringToDraft('Indícanos el mes final'),
                showInTrace: false,
                request: {
                    url: stringToDraft(''),
                    queryParams: [],
                },
                options: {},
            };
        case AYFStepTypes.AYF_YEAR:
            return {
                ...baseStep,
                type,
                name: 'Solicitud de Año',
                message: stringToDraft('Indícanos el año'),
                validation: StringValidationValues[StringValidationTypes.YEAR],
                isSubject: false,
                showInTrace: false,
            };
        case AYFStepTypes.AYF_CUT_OFF_DATE:
            return {
                ...baseStep,
                type,
                name: 'Solicitud de Fecha de Corte',
                message: stringToDraft('Indícanos la fecha de corte'),
                validation: StringValidationValues[StringValidationTypes.DATE],
                isSubject: false,
                showInTrace: false,
            };
        case AYFStepTypes.AYF_BILL_NUM:
            return {
                ...baseStep,
                type,
                name: 'Solicitud de Num Factura',
                message: stringToDraft('Indícanos el número de la factura'),
                isSubject: false,
                showInTrace: false,
            };
        case AYFStepTypes.AYF_ICA_CITY:
            return {
                ...baseStep,
                type,
                name: 'Solicitud de Ciudad',
                message: stringToDraft('Selecciona la ciudad'),
                label: 'Seleccionar',
                showInTrace: false,
                request: {
                    url: stringToDraft(''),
                    queryParams: [],
                },
                options: {},
            };
        default:
            throw Error();
    }
}
