import styles from './SubjectChange.module.css';
import TrendingFlatRoundedIcon from '@material-ui/icons/TrendingFlatRounded';

interface SubjectChangeProps {
    original: string | null;
    value: string | null;
}
/**
 * Component that displays multiple statechange actions, they are grouped in the service if they
 * were made by the same agent given a timespan
 */
function SubjectChange({ original, value }: SubjectChangeProps): JSX.Element {
    return (
        <div className={styles.container}>
            {original && (
                <div
                    className={styles.subjectContainer}
                    style={{ textDecoration: 'line-through' }}
                >
                    {original}
                </div>
            )}
            {original && (
                <div className={styles.arrowContainer}>
                    <TrendingFlatRoundedIcon fontSize="inherit" />
                </div>
            )}
            <div className={styles.subjectContainer}>{value}</div>
        </div>
    );
}

export default SubjectChange;
