import { useMemo } from 'react';
import { Template } from '../../../../@Types/Template';
import CaptaIcon from '../../../../Icons/CaptaIcon';
import AttachmentRoundedIcon from '@material-ui/icons/AttachmentRounded';
import CropOriginalRoundedIcon from '@material-ui/icons/CropOriginalRounded';
import styles from './TemplateComponent.module.css';

interface TemplateComponentProps {
    template: Template;
    onClick: () => void;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
}

function TemplateComponent({
    template,
    onClick,
    onMouseEnter,
    onMouseLeave,
}: TemplateComponentProps): JSX.Element {
    const { payload } = template;
    const { params, files, images } = useMemo(() => {
        let params = 0;
        if (payload.draft) {
            for (const entity of Object.values(payload.draft.entityMap)) {
                if (entity.type === 'EUREKA') params++;
            }
        }

        return {
            params,
            images: Object.values(payload.images ?? {}).length,
            files: payload.files?.length ?? 0,
        };
    }, [template.payload.draft]);

    return (
        <div
            data-testid="TemplateComponent"
            onMouseEnter={onMouseEnter}
            onClick={onClick}
            onMouseLeave={onMouseLeave}
            className={styles.container}
        >
            <div className={styles.topContainer}>
                <div className={styles.name} title={template.name}>
                    {template.name}
                </div>
            </div>
            <div className={styles.bottomContainer}>
                {/* Posiblemente dejar el description en el title y poner de donde viene! */}
                <div
                    className={styles.description}
                    title={template.description}
                >
                    {template.description}
                </div>
                {params > 0 && (
                    <div
                        className={styles.icon}
                        style={{ backgroundColor: 'var(--secondary2)' }}
                    >
                        <label className={styles.iconLbl}>{params}</label>
                        <div className={styles.captaIcon}>
                            <CaptaIcon
                                style={{
                                    width: 20,
                                    height: 20,
                                    marginTop: -4,
                                }}
                            />
                        </div>
                    </div>
                )}
                {files > 0 && (
                    <div
                        className={styles.icon}
                        style={{ backgroundColor: 'var(--contrast)' }}
                    >
                        <label className={styles.iconLbl}>{files}</label>
                        <div className={styles.filesIcon}>
                            <AttachmentRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                )}
                {images > 0 && (
                    <div
                        className={styles.icon}
                        style={{ backgroundColor: 'var(--secondary)' }}
                    >
                        <label className={styles.iconLbl}>{images}</label>
                        <div className={styles.imagesIcon}>
                            <CropOriginalRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
export default TemplateComponent;
