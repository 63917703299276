import { ClienteInfo360 } from '../../../types';
import styles from './Inmuebles.module.css';
import React from 'react';
import { empStrOrUndToNA } from '../../Utils/otherFunctions';

interface InmueblesProps {
    inmuebles: ClienteInfo360['inmuebles'];
    idAgrupacion: number | undefined;
    setIdAgrupacion: (idAgrupacion: number | undefined) => void;
}

export function Inmuebles({
    inmuebles,
    idAgrupacion,
    setIdAgrupacion,
}: InmueblesProps): JSX.Element {
    return (
        <div className={styles.negocios}>
            <div className={styles.title}>
                <div>Inmuebles</div>
            </div>
            <div className={styles.list}>
                {inmuebles.map((negocio: any, index: number) => {
                    return (
                        <div
                            className={
                                styles.element +
                                (idAgrupacion === negocio.id
                                    ? ' selected-item-shadow'
                                    : '')
                            }
                            onClick={(): void => {
                                if (idAgrupacion === negocio.id) {
                                    setIdAgrupacion(undefined);
                                } else {
                                    setIdAgrupacion(negocio.id);
                                }
                            }}
                            key={index}
                        >
                            <div className={styles.sideBar}></div>
                            <div className={styles.info}>
                                <div className={styles.row}>
                                    <div className={styles.label}>Nombre:</div>
                                    <div className={styles.value}>
                                        {negocio.nombre}
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.label}>
                                        Proyecto:
                                    </div>
                                    <div className={styles.value}>
                                        {empStrOrUndToNA(negocio.proyecto)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
