import { AnyAction } from '@reduxjs/toolkit';
import { takeLatest, call, put, fork, select } from 'redux-saga/effects';
import { RootState } from '../../utils/_store';
import * as actions from './SurveysActions';
import { loadSurveys, updateSurvey } from './SurveysService';
/** Selector that returns the current ipProject */
const getIdProject = (state: RootState): string | undefined | null =>
    state.site.idProject;

/** Number of elements per Page */
const PAGE_SIZE = 20;

/**
 * Function called when new surveys should be rendered (reset,search, or newpage)
 * @param action of type Search, reset or scroll
 */
function* getSurveys(action: AnyAction): any {
    try {
        const idProject = yield select(getIdProject);
        const pageInfo = yield select((state) => state.surveysPage);
        let result = yield call(
            loadSurveys,
            idProject,
            pageInfo.page,
            PAGE_SIZE,
            pageInfo.search
        );
        /** Calcs if more elements are available */
        const noMoreElements =
            (pageInfo.page !== 1 && result.length === 0) ||
            (pageInfo.page === 1 && result.length < PAGE_SIZE);

        if (actions.pageScroll.match(action)) {
            result = [...pageInfo.elements].concat(result);
        }
        yield put(actions.getSuccess({ elements: result, noMoreElements }));
    } catch (error) {
        //TODO handle errors
        console.error(error);
    }
}

/**
 * Function called when an survey's value is updated optimistically to patch the updates to the server asyncly
 * @param action of type UPDATE
 */
function* putSurvey(action: AnyAction): any {
    if (actions.updateElement.match(action)) {
        try {
            const payload: Record<string, unknown> = {};
            payload[action.payload.field] = action.payload.value;
            const idProject = yield select(getIdProject);
            yield call(
                updateSurvey,
                idProject,
                action.payload.object._id,
                payload
            );
        } catch (error) {
            //TODO handle errors
            //Revertir cambios con el object del action.
            console.error(error);
        }
    }
}

function* watchGetSurveysRequest(): any {
    yield takeLatest(
        [actions.Types.RESET, actions.Types.PAGE_SCROLL, actions.Types.SEARCH],
        getSurveys
    );
}

function* watchUpdateSurvey(): any {
    yield takeLatest([actions.Types.UPDATE], putSurvey);
}

export default [fork(watchGetSurveysRequest), fork(watchUpdateSurvey)];
