import React from 'react';
import { OrgIntegrations } from '../../../@Types/Organization';
import Toggle from '../../../shared/Toggle/Toggle';
import styles from './IntegrationsEditor.module.css';

interface IProps {
    integrations: OrgIntegrations;
    handleUpdate: (integrations: OrgIntegrations) => void;
}

export default function IntegrationsEditor({
    integrations,
    handleUpdate,
}: IProps): JSX.Element {
    return (
        <React.Fragment>
            <div className={styles.container}>
                <div className={styles.label}>AYF</div>
                <Toggle
                    size="medium"
                    checked={integrations.AYF === true}
                    onChange={(checked: boolean): void => {
                        handleUpdate({ ...integrations, AYF: checked });
                    }}
                />
            </div>
            <div className={styles.container}>
                <div className={styles.label}>CBR</div>
                <Toggle
                    size="medium"
                    checked={integrations.CBR === true}
                    onChange={(checked: boolean): void => {
                        handleUpdate({ ...integrations, CBR: checked });
                    }}
                />
            </div>
        </React.Fragment>
    );
}
