import Dialog from '../../../../../../../shared/Dialog/Dialog';
import styles from './CommentHistory.module.css';
import React, { useEffect, useState } from 'react';
import { fetchDownloadUrl } from '../../../../../../../controllers/FilesController/FilesService';
import {
    CommentAction,
    CommentHistoryVersion,
} from '../../../../../../../@Types/Action';
import RoundedPayload from '../../../../../../../shared/RoundedPayload/RoundedPayload';
import { PayloadImage } from '../../../../../../../@Types/Payload';
interface HistoryEditButtonProps {
    current: CommentAction;
    userName: string;
}

function CommentHistory({
    current,
    userName,
}: HistoryEditButtonProps): JSX.Element {
    const [openHistory, setOpenHistory] = useState(false);
    return (
        <React.Fragment>
            {openHistory && (
                <Dialog
                    onClose={(): void => setOpenHistory(false)}
                    open={openHistory}
                    border={10}
                    maxWidth={'fit-content'}
                >
                    <DialogContent current={current} userName={userName} />
                </Dialog>
            )}
            {current.history && (
                <div className={styles.historyButton}>
                    <div
                        className={styles.editText}
                        onClick={(): void => {
                            setOpenHistory(true);
                        }}
                    >
                        {'(Editado)'}
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}
export default CommentHistory;
function DialogContent({
    current,
    userName,
    setLoading,
}: HistoryEditButtonProps & {
    setLoading?: Function;
}): JSX.Element {
    const [versions, setVersions] = useState<CommentHistoryVersion[] | null>(
        null
    );
    const history = current.history;
    const loadImages = async (): Promise<void> => {
        if (!current.history) {
            return;
        }
        setLoading?.(true);

        const promises: Promise<void>[] = [];
        const mappedVersions = current.history.versions.map((version) => {
            const images: Record<string, PayloadImage> = {};
            if (images && version.payload.images) {
                for (const key of Object.keys(version.payload.images)) {
                    const image = {
                        ...version.payload.images[key],
                    };
                    if (!image.url) {
                        promises.push(
                            fetchDownloadUrl(image.S3Key, image.imageKey).then(
                                (url) => {
                                    image.url = url;
                                }
                            )
                        );
                    }
                    images[key] = image;
                }
            }
            return {
                payload: { ...version.payload, images },
                creation_date: version.creation_date,
            };
        });
        await Promise.all(promises);
        setVersions(mappedVersions);
        setLoading?.(false);
    };

    useEffect(() => {
        loadImages();
    }, [history]);
    if (!history || !versions) return <div></div>;
    return (
        <React.Fragment>
            <div className={styles.history}>
                <div className={styles.historyTitle}>Historial</div>
                <div className={styles.historyContent}>
                    <RoundedPayload
                        payload={current.payload}
                        date={new Date(history.lastEdit)}
                        name={userName}
                    />
                    {[
                        ...versions.map((version: any, index: number) => {
                            return (
                                <RoundedPayload
                                    key={index}
                                    payload={version.payload}
                                    date={new Date(version.creation_date)}
                                    name={userName}
                                />
                            );
                        }),
                    ].reverse()}
                </div>
            </div>
        </React.Fragment>
    );
}
