import styles from './MobileActionBar.module.css';
import React, { useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Ticket } from '../../../../../@Types/@Types';
import {
    setLoading,
    refresh,
    updateActionType,
    addAction,
} from '../../../../../controllers/TicketsController/Actions/ActionsActions';
import MobileTextField from './MobileTextField';
import { Fab, makeStyles } from '@material-ui/core';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import { RootState } from '../../../../../utils/_store';
import { Payload } from '../../../../../@Types/Payload';
import ActionsService from '../../../../../controllers/TicketsController/Actions/ActionsService';
import {
    calcImageEntityData,
    getRawText,
    stringToDraft,
} from '../../../../../utils/draftFunctions';
import Dialog from '../../../../../shared/Dialog/Dialog';
import PayloadConfirmation from '../../ActionBar/PayloadConfirmation/PayloadConfirmation';
import { AtomicBlockUtils, convertToRaw, EditorState } from 'draft-js';
import {
    getUploadUrls,
    postFile,
} from '../../../../../controllers/FilesController/FilesService';
import maxSize from '../../../../../constants/Files/MaxSize';
import * as RepliesCache from '../../../../../utils/ReplyCache';
import ActionTypes from '../../../../../constants/ActionTypes';
import CustomTimeout from '../../../../../utils/CustomTimeout';
import EntryTypes from '../../../../../constants/EntryTypes';
import { ActionTabs } from '../../ActionBar/ActionBar';
import { checkProjPerm } from '../../../../../utils/PermissionsFunctions';
import { ActivityTypes } from '../../../../../constants/ActivityTypes/ActivityTypes';
import { PreReplyTicketActivity } from '../../../../../@Types/TicketTypes/TicketActivity';
import { ProjectPermissions } from '../../../../../constants/Permissions';
const useStyles = makeStyles(() => ({
    root: {
        marginLeft: 4,
        paddingLeft: 2,
        backgroundColor: 'var(--secondary)',
        color: 'var(--primary1)',
        '&:hover': {
            backgroundColor: 'var(--secondary)',
        },
        '&.Mui-disabled': {
            backgroundColor: 'var(--secondary)',
            color: 'var(--primary1)',
        },
    },
}));

interface MobileActionBarProps {
    /** The currently selected Ticket */
    selectedElement: Ticket;
    /** If the current user can reply to the client */
    canReply: boolean;
    /** If the current user can comment the ticket */
    canComment: boolean;
}

function MobileActionBar({
    canReply,
    canComment,
    selectedElement,
}: MobileActionBarProps): JSX.Element {
    const btnClasses = useStyles();

    const user = useSelector((state: RootState) => state.site.user);
    const idProject = useSelector((state: RootState) => state.site.idProject);

    const preReplyActivity = useMemo(() => {
        if (!selectedElement.process) return null;
        const current = selectedElement.process.current.find(
            (act) => act.type === ActivityTypes.PRE_REPLY
        );
        if (!current) return null;
        const ticketActivity = selectedElement.process.activities[
            current.idActivity
        ].find((act) => act.path === current.path);
        return (ticketActivity as PreReplyTicketActivity) ?? null;
    }, [selectedElement.process]);

    const canPreReply = useMemo(() => {
        if (!preReplyActivity || preReplyActivity.idAgent) return false;
        return (
            preReplyActivity.agents.includes(user!._id) ||
            checkProjPerm(
                user,
                idProject,
                ProjectPermissions.MANAGE_NOT_ASSIGNED
            )
        );
    }, [preReplyActivity]);

    const [selectedTab, setSelectedTab] = useState<ActionTabs | undefined>(
        canReply
            ? ActionTabs.REPLY
            : canComment
            ? ActionTabs.COMMENT
            : canPreReply
            ? ActionTabs.PRE_REPLY
            : undefined
    );
    const [showConfirmation, setShowConfirmation] = useState<any>(undefined);
    const fieldRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const actionsPage = useSelector((state: RootState) => state.ticketActions);
    const siteInfo = useSelector((state: RootState) => state.site);
    const [text, setText] = useState('');

    if (!selectedTab) return <></>;

    const calcPlaceholder = (): string => {
        if (selectedTab === ActionTabs.REPLY) {
            return 'Respuesta al cliente';
        } else if (selectedTab === ActionTabs.COMMENT) {
            return 'Comentario interno';
        }
        if (selectedTab === ActionTabs.PRE_REPLY) {
            return 'Borrador de Respuesta';
        } else {
            return '';
        }
    };

    const onImageSelected = async (file: File): Promise<void> => {
        const localSrc = URL.createObjectURL(file);
        const entityData = await calcImageEntityData(localSrc);

        const editorState = EditorState.createEmpty();
        const entityKey = editorState
            .getCurrentContent()
            .createEntity('IMAGE', 'MUTABLE', entityData)
            .getLastCreatedEntityKey();
        const newEditorState = AtomicBlockUtils.insertAtomicBlock(
            editorState,
            entityKey,
            ' '
        );
        const currentContent = newEditorState.getCurrentContent();

        setShowConfirmation({
            text: '',
            draft: convertToRaw(currentContent),
            images: {
                [entityData.imageKey]: {
                    url: localSrc,
                    file,
                    imageKey: entityData.imageKey,
                    state: 'FETCHING',
                },
            },
        });
    };

    const handleSend = async (): Promise<void> => {
        if (siteInfo.idProject && !actionsPage.loading) {
            if (text.trim().length > 0) {
                try {
                    let draft = stringToDraft(text);
                    const payload: Payload = {
                        text,
                        draft,
                    };
                    setShowConfirmation(payload);
                } catch (error) {
                    console.error(error);
                }
            }
        }
    };

    const handleConfirm = async (
        tags: Record<string, boolean>
    ): Promise<void> => {
        const payload = { ...showConfirmation };
        if (siteInfo.idProject) {
            dispatch(setLoading());
            //Upload the files
            const file = payload?.files?.[0]?.file;
            const image: any = Object.values(payload?.images ?? {})?.[0];
            if (file) {
                try {
                    const fileLinks = await getUploadUrls([file]);
                    if (file.size <= maxSize) {
                        const uploadedFile = await postFile(
                            file,
                            fileLinks[0] as any
                        );
                        payload.draft = getRawText();
                        payload.text = '';
                        payload.files = [uploadedFile];
                    }
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.log('UPLOADFILEERROR:', error);
                }
            } else if (image) {
                try {
                    const fileLinks = await getUploadUrls([image.file]);
                    if (image.file.size <= maxSize) {
                        const uploadedImage = await postFile(
                            image.file,
                            fileLinks[0] as any
                        );
                        payload.text = '';
                        payload.images = {
                            [image.imageKey]: {
                                S3Key: uploadedImage.S3Key,
                                imageKey: image.imageKey,
                            },
                        };
                    }
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.log('UPLOADIMAGEERROR:', error);
                }
            } else {
                delete payload.files;
                delete payload.images;
            }

            if (selectedTab === ActionTabs.REPLY) {
                const delay =
                    selectedElement.entry.type == EntryTypes.WHATSAPP
                        ? 10000
                        : 40000;
                const idProject = siteInfo.idProject;
                const idTicket = selectedElement._id;
                const tempReply = await ActionsService.reply(
                    idProject,
                    idTicket,
                    showConfirmation,
                    tags,
                    delay
                );
                const oldTemp = RepliesCache.getTicketReply(
                    idProject,
                    idTicket
                );
                RepliesCache.removeReply(idProject, idTicket);
                if (oldTemp) {
                    dispatch(
                        updateActionType({
                            idAction: oldTemp?.id,
                            type: ActionTypes.REPLY,
                            date: new Date(),
                        })
                    );
                }
                const idTempReply = tempReply._id;
                tempReply.agent = siteInfo.user as any;
                const timeout = new CustomTimeout(async () => {
                    try {
                        const result = await ActionsService.persistTempReply(
                            idProject,
                            idTicket,
                            idTempReply
                        );
                        dispatch(
                            updateActionType({
                                idAction: idTempReply,
                                type: ActionTypes.REPLY,
                                date: new Date(result.creation_date),
                            })
                        );
                        RepliesCache.removeReply(idProject, idTicket);
                    } catch (error) {
                        RepliesCache.setReplyAsFailed(
                            idProject,
                            idTicket,
                            idTempReply
                        );
                    }
                }, delay);
                RepliesCache.addTempReply(
                    idProject,
                    idTicket,
                    idTempReply,
                    timeout
                );
                tempReply.creation_date = new Date(tempReply.creation_date);
                dispatch(addAction(tempReply));
            } else if (selectedTab === ActionTabs.COMMENT) {
                await ActionsService.comment(
                    siteInfo.idProject,
                    selectedElement._id,
                    payload
                );
            } else if (selectedTab === ActionTabs.PRE_REPLY) {
                await ActionsService.preReply(
                    siteInfo.idProject,
                    selectedElement._id,
                    payload,
                    tags
                );
            }
            dispatch(refresh());
            setShowConfirmation(undefined);
            setText('');
        }
    };

    return (
        <React.Fragment>
            {showConfirmation !== undefined && selectedTab && (
                <Dialog
                    maxWidth="100%"
                    disableEnforceFocus
                    onClose={(): void => setShowConfirmation(undefined)}
                >
                    <PayloadConfirmation
                        close={(): void => setShowConfirmation(undefined)}
                        payload={{
                            ...showConfirmation,
                            images: showConfirmation.images ?? {},
                        }}
                        type={selectedTab}
                        handleConfirm={handleConfirm}
                        actions={actionsPage.elements}
                        ticket={selectedElement}
                    />
                </Dialog>
            )}
            <div className={styles.container}>
                <div className={styles.titles + ' noselect'}>
                    {canReply && (
                        <div
                            className={
                                selectedTab === ActionTabs.REPLY
                                    ? styles.selectedTab
                                    : styles.tab
                            }
                            onClick={(): void => {
                                setSelectedTab(ActionTabs.REPLY);
                                fieldRef?.current?.focus?.();
                            }}
                        >
                            <label className={styles.lblTab}>
                                Responder al Cliente
                            </label>
                        </div>
                    )}
                    {canPreReply && (
                        <div
                            className={
                                selectedTab === ActionTabs.PRE_REPLY
                                    ? styles.selectedTab
                                    : styles.tab
                            }
                            onClick={(): void => {
                                setSelectedTab(ActionTabs.PRE_REPLY);
                                fieldRef?.current?.focus?.();
                            }}
                        >
                            <label className={styles.lblTab}>
                                Borrador de Respuesta
                            </label>
                        </div>
                    )}
                    {canComment && (
                        <div
                            className={
                                selectedTab === ActionTabs.COMMENT
                                    ? styles.selectedTab
                                    : styles.tab
                            }
                            onClick={(): void => {
                                setSelectedTab(ActionTabs.COMMENT);
                                fieldRef?.current?.focus?.();
                            }}
                        >
                            <label className={styles.lblTab}>Comentar</label>
                        </div>
                    )}
                </div>

                <div className={styles.actionForm}>
                    {selectedTab !== undefined && (
                        <div className={styles.textFieldContainer}>
                            <div className={styles.textField}>
                                <MobileTextField
                                    inputRef={fieldRef}
                                    value={text}
                                    onChange={(e): void => {
                                        setText(e.target.value);
                                    }}
                                    onImageSelected={onImageSelected}
                                    onFileSelected={(file: File): void => {
                                        setShowConfirmation({
                                            files: [{ file }],
                                        });
                                    }}
                                    placeholder={calcPlaceholder()}
                                    cantEdit={actionsPage.loading}
                                />
                            </div>
                            <Fab
                                size="small"
                                className={btnClasses.root}
                                onClick={(): void => {
                                    handleSend();
                                }}
                                disabled={actionsPage.loading}
                            >
                                <SendRoundedIcon />
                            </Fab>
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}

export default MobileActionBar;
