import styles from './InfoTextStep.module.css';
import React, { useContext } from 'react';
import { InfoTextStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import InformativeTypePicker from '../InformativeTypePicker/InformativeTypePicker';
import PayloadEditor from '../../../../../../shared/TextEditor/PayloadEditor';
import {
    ApiKeyContext,
    BaseConversationPayloadConditionTypes,
} from '../../../ConversationEditor';
import { useAppDispatch } from '../../../../../../hooks';
import { updateStep } from '../../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import { TextEditorTypes } from '../../../../../../constants/TextEditorTypes';
interface InfoTextStepProps {
    step: InfoTextStep;
}

function InfoTextStepComponent({ step }: InfoTextStepProps): JSX.Element {
    const apiKey = useContext(ApiKeyContext);
    const dispatch = useAppDispatch();
    return (
        <React.Fragment>
            <InformativeTypePicker step={step} />
            <div className={styles.container}>
                <div className={styles.editorContainer}>
                    <PayloadEditor
                        placeholder={'Mensaje'}
                        type={TextEditorTypes.WHATSAPP}
                        idEditor={`Conversation-${apiKey}-${step.id}`}
                        payload={{
                            draft: step.message,
                        }}
                        onChange={({ draft: message }): void => {
                            if (!message) return;
                            dispatch(updateStep({ ...step, message }));
                        }}
                        media={false}
                        context={{
                            client: true,
                        }}
                        conditions={{
                            types: BaseConversationPayloadConditionTypes,
                            context: {
                                conversation: {
                                    idStep: step.id,
                                },
                            },
                        }}
                        minHeight={144}
                        maxHeight={400}
                        required
                        maxLength={4096}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

export default InfoTextStepComponent;
