import Dialog from '../Dialog/Dialog';
import styles from './ConfirmDialog.module.css';
import RoundedButton from '../RoundedButton/RoundedButton';
import { cloneElement } from 'react';

interface ConfirmProps {
    /** Element being confirmed */
    element?: any;
    /** Function that handles the closing event */
    onClose: () => void;
    /** Function called when user decides to confirm */
    onConfirm: (Element: any, setLoading: (loading: boolean) => void) => void;
    /** Title of the dialog */
    title: string;
    /** Confirm btn msg, can be used to cancel */
    btnMsg: string;
    /** Dialog message */
    msg?: string;
    /** Children that can be rendered inside the dialog, if no child is found the message will be displayed */
    children?: any;
    /** If dialog is deleting */
    deleting?: boolean;
}

/**
 * Component that displays the confirm deletion can also be used to cancel an action.
 */
function ConfirmDialog({
    element,
    onClose,
    onConfirm,
    title,
    btnMsg,
    deleting = false,
    msg = '',
    children,
}: ConfirmProps): JSX.Element {
    const renderMsg = (): JSX.Element => {
        return (
            <>
                {msg && (
                    <div className={styles.message}>
                        <label className={styles.messageLbl}>{msg}</label>
                    </div>
                )}
                {children && cloneElement(children)}
            </>
        );
    };
    return (
        <Dialog open={element !== null} onClose={onClose}>
            <InnerConfirmDialog
                element={element}
                onClose={onClose}
                onConfirm={onConfirm}
                title={title}
                btnMsg={btnMsg}
                deleting={deleting}
            >
                {renderMsg()}
            </InnerConfirmDialog>
        </Dialog>
    );
}
export default ConfirmDialog;

interface InnerDialogProps extends ConfirmProps {
    /** Children that should be rendered inside the dialog */
    children?: any;
    /** Function that toggles the loader in the modal, inherited from the modal. */
    setLoading?: (loading: boolean) => void;
    /** if loader should be active */
    loading?: boolean;
}

function InnerConfirmDialog({
    element,
    onClose,
    onConfirm,
    children,
    title,
    btnMsg,
    setLoading,
    loading,
    deleting,
}: InnerDialogProps): JSX.Element {
    return (
        <div
            className={styles.container + ' standard-dialog'}
            data-testid={'ConfirmDialogContainer'}
        >
            <h3 className="center-anything">{title}</h3>
            {cloneElement(children)}
            <div className="center-anything">
                <div className="button" data-testid={'CancelButton'}>
                    <RoundedButton
                        text="Cancelar"
                        onClick={onClose}
                        backgroundColor="var(--light-grey)"
                        color="var(--accent)"
                    />
                </div>
                <div className="button" data-testid={'ConfirmButton'}>
                    <RoundedButton
                        text={btnMsg}
                        backgroundColor={
                            deleting ? 'var(--error)' : 'var(--secondary)'
                        }
                        color={'var(--primary1)'}
                        onClick={(): void => {
                            onConfirm(element, setLoading!);
                        }}
                        disabled={loading}
                    />
                </div>
            </div>
        </div>
    );
}
