import React, {
    createContext,
    useContext,
    useMemo,
    useRef,
    useState,
} from 'react';
import styles from './TextEditorContext.module.css';
import CaptaIcon from '../../../Icons/CaptaIcon';
import EntityIcon from '../../SmartIcons/EntityIcon';
import AgentIcon from '../../../Icons/AgentIcon';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import SmartIcon from '../../SmartIcons/SmartIcon';
import OtherContexts from './Contexts/OtherContexts';
import Context from './Context';
import { DraftEntityDataTypes } from '../../../constants/Draft/DraftEntityDataTypes';
import TicketContext from './Contexts/TicketContext';
import AgentContext from './Contexts/AgentContext';
import ClientContext from './Contexts/ClientContext';
import { EntityContextGroup } from './Contexts/EntityContext';
import ProjectContexts from './Contexts/ProjectContexts';
import OrganizationContext from './Contexts/OrganizationContext';
import HandShakeIcon from '../../../Icons/Entities/HandshakeIcon';
import ConceptContexts from './Contexts/ConceptContexts';
import { UserTypes } from '../../../constants/UserTypes';
import { DraftContext } from '../../../@Types/Draft/DraftContext';
import { DraftMappings } from '../../../@Types/Draft/DraftMappings';
import FormContext from './Contexts/FormContext';
import { usePayloadEditorSelector } from '../../../controllers/PayloadEditorController/PayloadEditorSlice';
import { convertToRaw } from 'draft-js';
import NotificationIcon from '../../../Icons/NotificationIcon';
import NotificationContext from './Contexts/NotificationContext';
import { TextEditorTypeContext } from '../TextEditor';
import FormStepContext from './Contexts/FormStepContext';
import { DraftEntityData } from '../../../@Types/Draft/DraftEntityData';
import EurekaElementMenuGroup from '../../@Menus/ElementMenu/EurekaElementMenuGroup';
import EurekaElementMenu from '../../@Menus/ElementMenu/EurekaElementMenu';
import { TextEditorTypes } from '../../../constants/TextEditorTypes';
import ConversationStepContext from './Contexts/ConversationContext';
import EntityValueMappingContext from './Contexts/EntityValueMappingContext';

export interface GenericContextProps {
    zIndex: number;
    handleClose: () => void;
    handleUpdate?: (data: DraftEntityData) => void;
}

interface TextEditorContextProps {
    nested?: boolean;
    context: DraftContext | undefined;
    mappings: DraftMappings | undefined;
    zIndex: number;
    maxEntities?: number;
}

export const UserTypeContext = createContext<UserTypes | null>(null);

function TextEditorContextComponent({
    mappings,
    zIndex,
    context,
    maxEntities,
    nested = false,
}: TextEditorContextProps): JSX.Element {
    const entities = useSelector((state: RootState) => state.site.entities);
    const editorState = usePayloadEditorSelector((state) => state.editorState);
    const disabled = useMemo(() => {
        return !!(
            maxEntities &&
            Object.keys(convertToRaw(editorState.getCurrentContent()).entityMap)
                .length >= maxEntities
        );
    }, [editorState, maxEntities]);
    const showContext = useMemo(() => {
        let show = context && Object.values(context).length > 0;
        if (!show && mappings) {
            show =
                mappings.agent ||
                (mappings.concepts && mappings.concepts.length > 0) ||
                (mappings.entities && mappings.entities.length > 0);
        }
        return show;
    }, [context, mappings]);

    return (
        <UserTypeContext.Provider value={context?.userType ?? null}>
            {showContext && (
                <ContextButton
                    zIndex={zIndex + 1}
                    data-testId="ContextButton"
                    title="Parámetros"
                    icon={
                        <CaptaIcon
                            style={{
                                width: 30,
                                height: 28,
                            }}
                        />
                    }
                    disabled={disabled}
                    renderContexts={(props): JSX.Element => (
                        <CaptaContext
                            {...props}
                            zIndex={zIndex}
                            nested={nested}
                            context={context}
                        />
                    )}
                />
            )}
            {context?.caseNumber && <FormContext disabled={disabled} />}
            {mappings?.agent && (
                <ContextButton
                    zIndex={zIndex + 1}
                    title={'Agente'}
                    icon={
                        <AgentIcon
                            fill={'var(--secondary)'}
                            style={{
                                width: 22,
                                height: 22,
                            }}
                        />
                    }
                    disabled={disabled}
                    renderContexts={(props): JSX.Element => (
                        <AgentContext {...props} isMapping />
                    )}
                />
            )}
            {context?.notification && (
                <ContextButton
                    zIndex={zIndex + 1}
                    title={'Notificación'}
                    icon={
                        <NotificationIcon
                            fill={'var(--secondary)'}
                            contrast={'var(--primary1)'}
                            style={{
                                width: 28,
                                height: 28,
                            }}
                        />
                    }
                    disabled={disabled}
                    renderContexts={(props): JSX.Element => (
                        <NotificationContext {...props} />
                    )}
                />
            )}
            {mappings?.entities &&
                mappings.entities.map((idEntity) => (
                    <ContextButton
                        key={idEntity}
                        zIndex={zIndex + 1}
                        title={entities[idEntity].name}
                        icon={
                            <EntityIcon
                                style={{
                                    width: 24,
                                    height: 24,
                                }}
                                fill={'var(--secondary)'}
                                idEntity={idEntity}
                            />
                        }
                        disabled={disabled}
                        renderContexts={(props): JSX.Element => (
                            <EntityValueMappingContext
                                {...props}
                                context={context}
                                idEntity={idEntity}
                            />
                        )}
                    />
                ))}
            {mappings?.concepts &&
                mappings.concepts.map((concept) => (
                    <ContextButton
                        key={concept.id}
                        zIndex={zIndex + 1}
                        title={concept.name}
                        icon={
                            <SmartIcon
                                style={{
                                    width: 24,
                                    height: 24,
                                }}
                                fill={'var(--secondary)'}
                                icon={concept.icon}
                            />
                        }
                        disabled={disabled}
                        renderContexts={(props): JSX.Element => (
                            <ConceptContexts concept={concept} {...props} />
                        )}
                    />
                ))}
        </UserTypeContext.Provider>
    );
}

export default TextEditorContextComponent;

export function CaptaContext({
    context,
    nested = false,
    ...props
}: GenericContextProps &
    Pick<TextEditorContextProps, 'context' | 'nested'>): JSX.Element {
    const idProject = useSelector((state: RootState) => state.site.idProject);
    const idCompanyEntity = useSelector(
        (state: RootState) => state.site.organization?.idCompanyEntity
    );
    return (
        <>
            <FormStepContext {...props} context={context} />
            <ConversationStepContext {...props} context={context} />
            {/* {context.action && <ActionContext type={context.action} />} */}
            {context?.agent && (
                <EurekaElementMenuGroup
                    zIndex={props.zIndex + 1}
                    icon={AgentIcon}
                    label="Agente"
                    renderElements={(menuProps): JSX.Element => (
                        <AgentContext {...props} {...menuProps} />
                    )}
                />
            )}
            {context?.ticket && <TicketContext {...props} nested={nested} />}
            {context?.client && <ClientContext {...props} context={context} />}
            {idCompanyEntity && (context?.ticket || context?.client) && (
                <EntityContextGroup
                    {...props}
                    context={context}
                    icon={HandShakeIcon}
                    type={DraftEntityDataTypes.COMPANY}
                    idEntity={idCompanyEntity}
                />
            )}
            {idProject && !context?.isPublic && (
                <ProjectContexts {...props} nested={nested} context={context} />
            )}
            {context?.organization && <OrganizationContext {...props} />}
            {!nested && (
                <Context
                    {...props}
                    autoEdit
                    data={{
                        type: DraftEntityDataTypes.NESTED,
                        block: {
                            depth: 0,
                            entityRanges: [],
                            inlineStyleRanges: [],
                            key: 'nested',
                            text: 'Grupo',
                            type: 'unstyled',
                        },
                        entityMap: {},
                    }}
                    entityStyles={[]}
                />
            )}
            <OtherContexts {...props} context={context} />
        </>
    );
}

interface ContextButtonProps {
    zIndex: number;
    testId?: string;
    title: string;
    icon: JSX.Element;
    disabled: boolean;
    renderContexts: (props: GenericContextProps) => JSX.Element;
}

function ContextButton({
    icon,
    title,
    testId,
    zIndex,
    disabled,
    renderContexts,
}: ContextButtonProps): JSX.Element {
    const [open, setOpen] = useState(false);
    const btnRef = useRef<HTMLButtonElement>(null);
    const type = useContext(TextEditorTypeContext);

    return (
        <React.Fragment>
            <button
                disabled={disabled}
                data-testid={testId}
                title={title}
                className={
                    type === TextEditorTypes.INPUT ||
                    type === TextEditorTypes.ENTITYPICKER
                        ? styles.contextIcon
                        : styles.contextBtn
                }
                ref={btnRef}
                onClick={(): void => setOpen(true)}
            >
                {icon}
            </button>
            {open && (
                <EurekaElementMenu
                    handleClose={(): void => setOpen(false)}
                    anchorEl={btnRef.current}
                    zIndex={zIndex}
                    renderElements={(props): JSX.Element =>
                        renderContexts({
                            ...props,
                            handleClose: () => setOpen(false),
                        })
                    }
                />
            )}
        </React.Fragment>
    );
}
