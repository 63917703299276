import { all } from 'redux-saga/effects';
import siteSaga from '../controllers/_SiteController/SiteSaga';
import genericEditorSaga from '../controllers/GenericEditorController/GenericEditorSaga';
import surveysSaga from '../controllers/SurveysController/SurveysSaga';
import areasSaga from '../controllers/AreasController/AreasSaga';
import PayloadEditorSaga from '../controllers/PayloadEditorController/PayloadEditorSaga';
import agentsSaga from '../controllers/AgentsController/AgentsSaga';
import entriesSaga from '../controllers/EntriesController/EntriesSaga';
import agentsMenuSaga from '../controllers/AgentsMenuController/AgentsSaga';
import genericMenuSaga from '../controllers/GenericMenuController/GenericMenuSaga';
import entitiesSaga from '../controllers/EntitiesController/EntitiesSaga';
import classifiersSaga from '../controllers/ClassifiersController/ClassifiersSaga';
import ticketsSaga from '../controllers/TicketsController/TicketsSaga';
import ticketActionsSaga from '../controllers/TicketsController/Actions/ActionsSaga';
import ticketAssignAgentsSaga from '../controllers/TicketsController/AssignAgents/AgentsSaga';
import ticketTransferAgentsSaga from '../controllers/TicketsController/TransferAgent/AgentsSaga';
import companiesSaga from '../controllers/CompaniesController/CompaniesSaga';
import entityValuesSaga from '../controllers/EntityValuesController/EntityValuesSaga';
import clientsSaga from '../controllers/ClientsController/ClientsSaga';
import socketsSaga from '../controllers/SocketsController/SocketsSaga';
import conversationEditorSaga from '../controllers/ConversationEditorController/ConversationEditorSaga';
import templatesSaga from '../controllers/TemplatesController/TemplatesSaga';
import NotificationsMenuSaga from '../controllers/NotificationsMenuController/NotificationsMenuSaga';

function* rootSaga(): any {
    yield all([
        ...siteSaga,
        ...genericMenuSaga,
        ...genericEditorSaga,
        ...areasSaga,
        ...agentsMenuSaga,
        ...agentsSaga,
        ...surveysSaga,
        ...classifiersSaga,
        ...companiesSaga,
        ...entityValuesSaga,
        ...clientsSaga,
        ...entriesSaga,
        ...ticketsSaga,
        ...entitiesSaga,
        ...PayloadEditorSaga,
        ...classifiersSaga,
        ...ticketActionsSaga,
        ...ticketAssignAgentsSaga,
        ...ticketTransferAgentsSaga,
        ...templatesSaga,
        ...socketsSaga,
        ...conversationEditorSaga,
        ...NotificationsMenuSaga,
    ]);
}

export default rootSaga;
