import React from 'react';
import {
    ButtonsConversationStepCondition,
    ConversationStepCondition,
    EntityValueConversationStepCondition,
    ListConversationStepCondition,
    OpenConversationStepCondition,
    TextConversationStepCondition,
} from '../../../@Types/ConditionTypes/ConversationStepConditions';
import RoundedConversationStepPicker from '../../@Pickers/RoundedConversationStepPicker/RoundedConversationStepPicker';
import styles from '../Condition.module.css';
import { ConditionProps } from '../Condition';
import RoundedSelect from '../../RoundedSelect/RoundedSelect';
import { MenuItem } from '@material-ui/core';
import ConditionOperators from '../../../constants/Conditions/ConditionOperators';
import ConversationStepTypes from '../../../constants/Conversations/ConversationStepTypes';
import RoundedTextField from '../../RoundedTextField/RoundedTextField';
import RoundedMultiSelect from '../../RoundedMultiSelect/RoundedMultiSelect';
import RoundedEntityValuePicker from '../../@Pickers/RoundedEntityValuePicker/RoundedEntityValuePicker';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';

interface ConversationConditionProps
    extends ConditionProps<ConversationStepCondition> {}
function ConversationConditionComponent({
    context,
    condition,
    hoverDelete,
    handleUpdate,
}: ConversationConditionProps): JSX.Element {
    if (!context.conversation) return <></>;
    const steps = useSelector(
        (state: RootState) => state.conversationEditor.steps
    );
    const step = steps[condition.idStep ?? ''];
    const previousSteps: string[] = useSelector(
        (state: RootState) =>
            state.conversationEditor.stepDependencies[
                context.conversation?.idStep ?? ''
            ]?.previousSteps
    );

    const renderStepOperators = (): JSX.Element[] => {
        const operators = [
            <MenuItem value={ConditionOperators.EXISTS} key={'EXISTS'}>
                Existe
            </MenuItem>,
            <MenuItem value={ConditionOperators.NOTEXISTS} key={'NOTEXISTS'}>
                No Existe
            </MenuItem>,
        ];

        if (
            [
                ConversationStepTypes.TEXT_STEP,
                ConversationStepTypes.BUTTONS_STEP,
                ConversationStepTypes.LIST_STEP,
                ConversationStepTypes.LIST_PAGE_STEP,
                ConversationStepTypes.ENTITY_VALUE_STEP,
            ].includes(step.type)
        ) {
            operators.push(
                <MenuItem value={ConditionOperators.EQUAL} key="EQUAL">
                    Igual a
                </MenuItem>,
                <MenuItem value={ConditionOperators.NOTEQUAL} key="NOTEQUAL">
                    No igual a
                </MenuItem>
            );
        }
        if (
            [
                ConversationStepTypes.TEXT_STEP,
                ConversationStepTypes.OPEN_STEP,
            ].includes(step.type)
        ) {
            operators.push(
                <MenuItem value={ConditionOperators.INCLUDES} key="INCLUDES">
                    Contiene
                </MenuItem>,
                <MenuItem
                    value={ConditionOperators.NOTINCLUDES}
                    key="NOTINCLUDES"
                >
                    No contiene
                </MenuItem>
            );
        }

        return operators;
    };

    const renderStepValue = (): JSX.Element => {
        if (
            condition.operator === undefined ||
            condition.operator === ConditionOperators.EXISTS ||
            condition.operator === ConditionOperators.NOTEXISTS
        ) {
            return <></>;
        }
        switch (step.type) {
            case ConversationStepTypes.TEXT_STEP: {
                const typedCondition =
                    condition as TextConversationStepCondition;
                return (
                    <div className={styles.selectorContainer}>
                        <RoundedTextField
                            label={step.name}
                            value={typedCondition.value ?? ''}
                            error={hoverDelete}
                            height="40px"
                            onChange={(event): void => {
                                handleUpdate({
                                    ...typedCondition,
                                    value: event.target.value,
                                });
                            }}
                        />
                    </div>
                );
            }
            case ConversationStepTypes.BUTTONS_STEP: {
                const typedCondition =
                    condition as ButtonsConversationStepCondition;
                return (
                    <div className={styles.selectorContainer}>
                        <RoundedSelect
                            value={typedCondition.value ?? ''}
                            label={step.name}
                            containerMargin="0px"
                            error={hoverDelete}
                            handleUpdate={(event): void => {
                                handleUpdate({
                                    ...typedCondition,
                                    value: event.target.value,
                                });
                            }}
                        >
                            {step.optionList.map((idOption) => {
                                const option = step.options[idOption];
                                return (
                                    <MenuItem value={option.id} key={option.id}>
                                        {option.label}
                                    </MenuItem>
                                );
                            })}
                        </RoundedSelect>
                    </div>
                );
            }
            case ConversationStepTypes.LIST_STEP:
            case ConversationStepTypes.LIST_PAGE_STEP: {
                const typedCondition =
                    condition as ListConversationStepCondition;
                return (
                    <div className={styles.selectorContainer}>
                        <RoundedSelect
                            value={typedCondition.value ?? ''}
                            label={step.name}
                            containerMargin="0px"
                            error={hoverDelete}
                            handleUpdate={(event): void => {
                                handleUpdate({
                                    ...typedCondition,
                                    value: event.target.value,
                                });
                            }}
                        >
                            {step.sections.map((section) =>
                                section.options.map((idOption) => {
                                    const option = step.options[idOption];
                                    return (
                                        <MenuItem
                                            value={option.id}
                                            key={option.id}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    );
                                })
                            )}
                        </RoundedSelect>
                    </div>
                );
            }
            case ConversationStepTypes.ENTITY_VALUE_STEP: {
                const typedCondition =
                    condition as EntityValueConversationStepCondition;
                return (
                    <div className={styles.selectorContainer}>
                        <RoundedEntityValuePicker
                            value={typedCondition.values ?? []}
                            multiple={true}
                            height="40px"
                            error={hoverDelete}
                            idEntity={step.idEntity}
                            handleUpdate={(entityValues): void => {
                                handleUpdate({
                                    ...typedCondition,
                                    values: entityValues as any,
                                });
                            }}
                        />
                    </div>
                );
            }
            case ConversationStepTypes.OPEN_STEP: {
                const typedCondition =
                    condition as OpenConversationStepCondition;
                return (
                    <div className={styles.multiSelectContainer}>
                        <RoundedMultiSelect
                            values={typedCondition.values ?? []}
                            options={[]} //TODO: generar sugestiones.
                            maxTags={5}
                            label="Valores"
                            fullWidth
                            error={hoverDelete}
                            handleUpdate={(values: string[]): void => {
                                handleUpdate({
                                    ...typedCondition,
                                    values: values,
                                });
                            }}
                        />
                    </div>
                );
            }
            default:
                return <div></div>;
        }
    };

    return (
        <React.Fragment>
            <div
                style={{
                    display: 'flex',
                }}
            >
                <RoundedConversationStepPicker
                    stepOrder={previousSteps ?? []}
                    value={condition.idStep}
                    label="Propiedad"
                    error={hoverDelete}
                    handleUpdate={(step): void => {
                        handleUpdate({
                            type: condition.type,
                            operator: condition.operator,
                            idStep: step.id,
                            stepType: step.type,
                        } as any);
                    }}
                />
            </div>
            {step && (
                <React.Fragment>
                    <RoundedSelect
                        value={condition.operator ?? ''}
                        label="Operador"
                        containerMargin="5px"
                        error={hoverDelete}
                        handleUpdate={(event): void => {
                            handleUpdate({
                                ...condition,
                                operator: event.target.value,
                            });
                        }}
                    >
                        {renderStepOperators()}
                    </RoundedSelect>
                    <div className={styles.valueContainer}>
                        {renderStepValue()}
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
export default ConversationConditionComponent;
