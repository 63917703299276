import React from 'react';
import styles from './AINode.module.css';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import { Handle, Position } from 'react-flow-renderer';
import BrainIcon from '../../../../Icons/BrainIcon';
import { AIData } from '../../../../@Types/FlowTypes/NodeTypes/AINode';
import ShapesIcon from '../../../../Icons/ShapesIcon';
import ChatIcon from '../../../../Icons/ChatIcon';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../utils/_store';
import { checkAdmin } from '../../../../utils/PermissionsFunctions';
interface AINodeProps {
    /** Node data needed to display the node */
    data: AIData & {
        /** Function used to open the dialog  */
        openDialog: Function;
        error: boolean;
    };
}

function AINode({ data }: AINodeProps): JSX.Element {
    const user = useSelector((state: RootState) => state.site.user);

    return (
        <>
            <Handle
                className={styles.leftHandle}
                type="target"
                position={Position.Left}
                style={{
                    top: '50%',
                    background: 'var(--primary1)',
                    border: '1px solid var(--accent)',
                }}
            />
            <div
                className={styles.container}
                style={
                    data.error
                        ? {
                              border: 'var(--error) 1px solid',
                              marginTop: '-1px',
                              marginLeft: '-1px',
                          }
                        : {}
                }
            >
                <BrainIcon className={styles.icon} />
                <div
                    className={styles.config}
                    onClick={(): void => {
                        data.openDialog();
                    }}
                >
                    <SettingsRoundedIcon fontSize="inherit" />
                </div>
                <div className={styles.iconsContainer}>
                    {data.classify && (
                        <ShapesIcon className={styles.shapesIcon} />
                    )}
                    {data.suggestReply && (
                        <ChatIcon
                            className={styles.chatIcon}
                            fill={
                                data.autoSend && checkAdmin(user)
                                    ? 'var(--contrast)'
                                    : 'var(--primary1)'
                            }
                        />
                    )}
                </div>
            </div>
            <Handle
                className={styles.rightHandle}
                type="source"
                position={Position.Right}
                style={{
                    top: '50%',
                    background: 'var(--primary1)',
                    border: '1px solid var(--accent)',
                }}
            />
        </>
    );
}

export default AINode;
