import { Entity } from '../../@Types/@Types';
import axiosInstance from '../../AxiosAPI';
/**
 * Function that loads the entities' list
 * @param page currently viewing
 * @param pageSize to request
 * @param search search query, undefined if no search is active
 * @returns array of Entities that match the inputs
 */
export const loadEntities = async (
    page: number,
    pageSize: number,
    search: string | undefined
): Promise<Entity[]> => {
    let url = `/entities?page=${page}&pageSize=${pageSize}`;
    if (search !== undefined) {
        url += `&search=${encodeURIComponent(search)}`;
    }
    const response = await axiosInstance.get(url);
    if (response.data.size === undefined) {
        //4-28-2022
        response.data.size = {
            spacingSize: response.data.spacingSize ?? 20,
            blockNum: response.data.blockNum ?? 4,
            blockSize: response.data.blockSize ?? 210,
        };
    }
    return response.data;
};

/**
 * Function called to handle the conexion with the server to create an entity
 * @param entity Record of values to create the entity with
 */
export const createEntity = async (
    entity: Record<string, unknown>
): Promise<void> => {
    await axiosInstance.post('/entities', entity);
};

/**
 * Function that handles the async update of an entities value
 * @param id of the entity to update
 * @param entity Record of values to update
 */
export const updateEntity = async (
    id: string,
    entity: Record<keyof Entity, any>
): Promise<void> => {
    await axiosInstance.patch('entities/' + id, entity);
};

/**
 * Function that loads the entities by a given list of ids
 * @param idEntity the id of the entity
 * @param entity the list of ids
 */
export const loadEntitiesById = async (
    entities: string[]
): Promise<Entity[]> => {
    if (entities.length === 0) return [];
    const response = await axiosInstance.get(
        `/entities?id=${entities.join(',')}`
    );
    return response.data;
};
/**
 * Function that loads an entity
 * @param id the entity to load
 * @returns Entity that matches the input
 */
export const loadEntity = async (id: string): Promise<Entity> => {
    const response = await axiosInstance.get(`/entities/${id}`);
    const entity: Entity = response.data;
    return entity;
};

/**
 * Function that saves the entity, sends an error if the entity is not valid
 * @param idEntry the form's entry id
 * @param pEntity the entity to save
 */
export const saveEntity = async (
    idEntity: string,
    pEntity: Entity
): Promise<void> => {
    const entity: any = { ...pEntity };
    delete entity['_id'];
    delete entity['creation_date'];
    await axiosInstance.patch(`/entities/${idEntity}`, entity);
};

/**
 * Function that saves the entity, sends an error if the entity is not valid
 * @param idEntry the form's entry id
 * @param pEntity the entity to save
 */
export const fetchUniqueValues = async (
    idEntity: string,
    idProperty: string
): Promise<any[]> => {
    const response = await axiosInstance.get(
        `/entities/${idEntity}/unique/${idProperty}`
    );
    return response.data;
};

export default {
    loadEntities,
    createEntity,
    updateEntity,
    loadEntity,
    saveEntity,
};
