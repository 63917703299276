enum ActionTypes {
    ACTIVITY = 'ACTIVITY',
    CREATE = 'CREATE',
    COMMENT = 'COMMENT',
    AUTO_COMMENT = 'AUTO_COMMENT',
    REPLY = 'REPLY',
    AUTO_REPLY = 'AUTO_REPLY',
    NOTIFICATION = 'NOTIFICATION',
    TEMP_REPLY = 'TEMP_REPLY',
    CLIENT_REPLY = 'CLIENT_REPLY',
    CLIENT_RATING = 'CLIENT_RATING',
    ASSIGN_AGENTS = 'ASSIGN_AGENTS',
    REMOVE_AGENT = 'REMOVE_AGENT',
    CHANGE_STATE = 'CHANGE_STATE',
    CHANGE_PROCESS = 'CHANGE_PROCESS',
    AUTO_CHANGE_STATE = 'AUTO_CHANGE_STATE',
    AUTO_CHANGE_PROCESS = 'AUTO_CHANGE_PROCESS',
    ASSIGN_AREA = 'ASSIGN_AREA',
    AUTO_ASSIGN_AREA = 'AUTO_ASSIGN_AREA',
    TRANSFER_AREA = 'TRANSFER_AREA',
    TRANSFER_AGENT = 'TRANSFER_AGENT',
    AUTO_ASSIGN_AGENTS = 'AUTO_ASSIGN_AGENTS',
    AUTOSCALE = 'AUTOSCALE',
    AUTO_AUTOSCALE = 'AUTO_AUTOSCALE',
    CLASSIFY = 'CLASSIFY',
    AUTO_CLASSIFY = 'AUTO_CLASSIFY',
    SET_COMPANY = 'SET_COMPANY',
    SET_ENTITY = 'SET_ENTITY',
    CHANGE_RESOLUTION_DATE = 'CHANGE_RESOLUTION_DATE',
    AUTO_CHANGE_RESOLUTION_DATE = 'AUTO_CHANGE_RESOLUTION_DATE',
    CBR_POSVENTA_CREATE = 'CBR_POSVENTA_CREATE',
    AUTO_CBR_POSVENTA_CREATE = 'AUTO_CBR_POSVENTA_CREATE',
    ERROR_AUTO_CBR_POSVENTA_CREATE = 'ERROR_AUTO_CBR_POSVENTA_CREATE',
    NOTIFICATION_ERROR = 'NOTIFICATION_ERROR',
    AUTO_REPLY_ERROR = 'AUTO_REPLY_ERROR',
    AUTO_CHANGE_SUBJECT = 'AUTO_CHANGE_SUBJECT',
    AUTO_CREATE_TICKET = 'AUTO_CREATE_TICKET',
    INTEGRATION_ERROR = 'INTEGRATION_ERROR',
    INTEGRATION_SUCCESS = 'INTEGRATION_SUCCESS',
}
export const Interactions = [ActionTypes.REPLY, ActionTypes.CLIENT_REPLY];

export default ActionTypes;
