import axiosInstance from '../../AxiosAPI';
import { Classifier } from '../../@Types/@Types';
import { searchFunction } from '../../utils/searchFunction';
/**
 * Function that loads the project's classifiers
 * @param idProject the id of the current project
 * @param orderBy method ordered by, undefined if search is active
 * @param search search query, undefined if no search is active
 * @returns {roots, elements}
 */
export const loadClassifiers = async (
    idProject: string,
    orderBy: string | undefined,
    search: string | undefined
): Promise<{
    /** an array if the ids of the root elements */
    roots: string[];
    /** a map of the organization's classifiers */
    elements: Record<string, Classifier>;
}> => {
    let url = `/projects/${idProject}/classifiers`;
    const response = await axiosInstance.get(url);
    const roots: string[] = [];
    const elements: Record<string, Classifier> = {};
    if (response?.data) {
        /** Iterate the response */
        for (const elem of response.data) {
            const element: Classifier = <Classifier>elem;
            /** Instanciate the date */
            element.creation_date = new Date(element.creation_date);
            /** If path is null add element to roots */
            if (element.path === null) {
                roots.push(element._id);
            }
            elements[element._id] = element;
        }
        if (search !== undefined) {
            return <
                {
                    roots: string[];
                    elements: Record<string, Classifier>;
                }
            >searchFunction(elements, search);
        }
    }
    return { roots, elements };
};
/**
 * Function called to handle the conexion with the server to create an classifier(root)
 * @param idProject the id of the current project
 * @param classifier Record of values to create the classifier with
 */
export const createRoot = async (
    idProject: string,
    classifier: Record<string, unknown>
): Promise<void> => {
    await axiosInstance.post(
        `/projects/${idProject}/classifiers/root`,
        classifier
    );
};

/**
 * Function called to handle the conexion with the server to create an classifier(node)
 * @param idProject the id of the current project
 * @param node Record of values to create the classifier with
 */
export const createNode = async (
    idProject: string,
    node: Record<string, unknown>
): Promise<void> => {
    await axiosInstance.post(`/projects/${idProject}/classifiers/node`, node);
};

/**
 * Function called to handle the conexion with the server to update a value of an classifier
 * @param idProject the id of the current project
 * @param idClassifier the id of the classifier to update
 * @param classifier Record of values to update
 */
export const updateClassifier = async (
    idProject: string,
    idClassifier: string,
    classifier: Record<keyof Classifier, unknown>
): Promise<void> => {
    await axiosInstance.patch(
        `/projects/${idProject}/classifiers/${idClassifier}`,
        classifier
    );
};
/**
 * Function called to handle the conexion with the server to delete an classifier
 * @param idProject the id of the current project
 * @param idClassifier the id of the classifier to delete
 * @param classifier Record of values to update
 */
export const deleteClassifier = async (
    idProject: string,
    idClassifier: string
): Promise<void> => {
    await axiosInstance.delete(
        `/projects/${idProject}/classifiers/${idClassifier}`
    );
};

/**
 * Function called to handle the conexion with the server to update the classifiers order
 * @param idProject the id of the current project
 * @param roots array of root ids in order
 */
export const updateOrderClassifiers = async (
    idProject: string,
    roots: string[]
): Promise<void> => {
    await axiosInstance.patch(
        `/projects/${idProject}/classifiers/updateOrder`,
        { roots: roots }
    );
};

export default {
    loadClassifiers,
    createRoot,
    createNode,
    updateClassifier,
    deleteClassifier,
    updateOrderClassifiers,
};
