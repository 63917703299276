import { makeStyles, Icon } from '@material-ui/core';
import { Survey } from '../../../@Types/@Types';
import {
    loadSurveysById,
    loadSurveys,
} from '../../../controllers/SurveysController/SurveysService';
import RoundedGenericPicker, {
    RoundedGenericPickerProps,
} from '../RoundedGenericPicker/RoundedGenericPicker';

function RoundedSurveyPicker({
    idProject,
    label = 'Encuestas',
    ...props
}: Omit<
    RoundedGenericPickerProps<Survey>,
    'calcLbl' | 'loadInitialType' | 'loadElements' | 'elementLabel'
> & { idProject: string }): JSX.Element {
    return (
        <RoundedGenericPicker<Survey>
            {...props}
            IconComponent={SurveyIcon}
            label={label}
            elementLabel="Encuesta"
            calcLbl={(survey): string => survey.name}
            loadInitialType={loadSurveysById}
            loadElements={async (
                page: number,
                pageSize: number,
                search: string | undefined
            ): Promise<Survey[]> => {
                return await loadSurveys(idProject, page, pageSize, search);
            }}
        />
    );
}
export default RoundedSurveyPicker;

function SurveyIcon(props: any): JSX.Element {
    const useStyles = makeStyles({
        iconRoot: {
            position: 'absolute',
            right: '7px',
        },
        imageIcon: {
            height: 23,
            width: 23,
        },
    });
    const classes = useStyles();
    return (
        <Icon classes={{ root: classes.iconRoot }} className={props.className}>
            <img
                className={classes.imageIcon}
                style={{ display: 'flex', height: 'inherit', width: 'inherit' }}
                src="/media/icons/bluesurvey.svg"
            />
        </Icon>
    );
}
