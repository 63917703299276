import styles from './TemplateListItem.module.css';
import { Template } from '../../../@Types/Template';

interface AgentListItemProps {
    /** The selected template */
    selectedElement?: Template;
    /** The template to display */
    element?: Template;
    /** Function called when the component is clicked */
    handleItemClick?: Function;
}
function TemplateListItem({
    selectedElement,
    element,
    handleItemClick,
}: AgentListItemProps): JSX.Element {
    if (!element || !handleItemClick) {
        return <div></div>;
    }
    return (
        <div
            className={
                styles.container +
                (selectedElement?._id === element._id
                    ? ' selected-item-shadow'
                    : '')
            }
            data-testid={'TemplateListItem'}
            onClick={(): void => handleItemClick(element, true)}
        >
            <div className={styles.sideBar}></div>
            <div className={styles.info}>
                <h4 data-testid={'TLI_name'} className={styles.title}>
                    {element.name}
                </h4>
            </div>
        </div>
    );
}

export default TemplateListItem;
