import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Client, ClientEntity } from '../../@Types/Client';
import ClientInfoTypes from '../../constants/ClientInfoTypes';
import { RootState } from '../../utils/_store';
import styles from './ClientRenderer.module.css';
import { DefaultClientStepIds, DefaultClientSteps } from './DefaultClientSteps';
import IdentifierTypeComponent from './IdentifierTypeComponent/IdentifierTypeComponent';
import NameComponent from './NameComponent/NameComponent';
import ClientCompanies from './ClientCompanies/ClientCompanies';
import ClientEntityRelationship from './ClientEntityRelationship/ClientEntityRelationship';
import FormRenderer from '../FormRenderer/FormRenderer';
import { EurekaFormProps } from '@arquimedes.co/eureka-forms';
interface ClientRendererProps {
    client?: Client;
    handleSave?: (client: Partial<Client>) => Promise<void>;
    editable: EurekaFormProps['editable'];
    showEntityValues?: boolean;
    showTickets?: boolean;
}
function ClientRenderer({
    entity,
    client,
    editable,
    handleSave,
}: ClientRendererProps & {
    entity: ClientEntity;
}): JSX.Element {
    const values = useMemo((): Record<string, unknown> => {
        const values = { ...(client?.values ?? {}) };
        for (const type of Object.values(ClientInfoTypes)) {
            values[type] = client?.[type] ?? '';
        }
        return values;
    }, [client?.values]);

    const customSend = async (values: any): Promise<void> => {
        const tempClient: Partial<Client> = { values };
        for (const type of Object.values(ClientInfoTypes)) {
            tempClient[type] = values[type];
            delete values[type];
        }
        await handleSave?.(tempClient);
    };
    return (
        <FormRenderer
            editable={editable}
            form={{
                ...entity,
                steps: { ...entity.steps, ...DefaultClientSteps },
            }}
            rootSteps={[...DefaultClientStepIds, ...entity.rootSteps]}
            customSteps={{
                [ClientInfoTypes.NAME]: {
                    component: NameComponent,
                    componentProps: {
                        originalName: values[ClientInfoTypes.NAME],
                        originalLastName: values[ClientInfoTypes.LASTNAME],
                    },
                },
                [ClientInfoTypes.IDENTIFIER_TYPE]: {
                    component: IdentifierTypeComponent,
                },
            }}
            sendLabel="Guardar"
            values={values}
            customSubmit={handleSave ? customSend : undefined}
        />
    );
}

function EntityRendererComponent({
    showTickets = false,
    showEntityValues = false,
    client,
    refresh,
    ...props
}: ClientRendererProps & { refresh?: Function }): JSX.Element {
    const entity = useSelector((state: RootState) => state.site.clientEntity);
    const idCompanyEntity = useSelector(
        (state: RootState) => state.site.organization?.idCompanyEntity
    );

    if (!entity) return <div></div>;

    return (
        <div className={styles.container}>
            <div
                className={styles.entityContainer}
                style={{
                    width:
                        (entity.size.blockSize + entity.size.spacingSize) *
                        entity.size.blockNum,
                }}
            >
                <ClientRenderer {...props} client={client} entity={entity} />
            </div>

            {showEntityValues && client && (
                <React.Fragment>
                    {idCompanyEntity && (
                        <ClientCompanies client={client} refresh={refresh} />
                    )}
                    {entity.relationships.map((relationship, index) => (
                        <ClientEntityRelationship
                            relationship={relationship}
                            client={client}
                            refresh={refresh}
                            key={index}
                        />
                    ))}
                </React.Fragment>
            )}
            {showTickets && <div></div>}
        </div>
    );
}
export default EntityRendererComponent;
