import styles from './TextInputStep.module.css';
import RoundedTextField from '../../RoundedTextField/RoundedTextField';
import RoundedSelect from '../../RoundedSelect/RoundedSelect';
import { MenuItem } from '@material-ui/core';
import React, { cloneElement } from 'react';
import { GTextInput } from '../../../@Types/GenericFormSteps';
import { GenericStepProps } from '../../GenericFormEditor/Step/Step';
import { calcStepWidth } from '../../GenericFormEditor/StepFunctions';
import {
    StringValidations,
    StringValidationValues,
} from '../../../constants/StringValidationTypes';
import { GLocation } from '../../../@Types/FormTypes/LocationTypes';

interface TextInputProps {
    /** children to render */
    children?: React.ReactElement<any, string>;
    /** If the user can select a validation */
    allowValidation?: boolean;
    /** The icon to display */
    icon?: React.ReactNode;
}

function TextInputStep<
    StepType extends GTextInput,
    UniqueType,
    LocationType extends GLocation
>({
    icon,
    size,
    step,
    editing,
    children,
    updateStep,
    uniqueSteps,
    updateUniqueSteps,
    allowValidation = false,
}: GenericStepProps<StepType, UniqueType, LocationType> &
    TextInputProps): JSX.Element {
    if (editing) {
        return (
            <div className={styles.editingContainer}>
                <div className={styles.inputContainer}>
                    <RoundedTextField
                        label="Etiqueta"
                        value={step.label}
                        onChange={(e): void => {
                            updateStep({ ...step, label: e.target.value });
                        }}
                    ></RoundedTextField>
                </div>

                <RoundedTextField
                    label="Descripción"
                    value={step.description ?? ''}
                    onChange={(e): void => {
                        updateStep({
                            ...step,
                            description: e.target.value,
                        });
                    }}
                ></RoundedTextField>
                {children &&
                    cloneElement(children, {
                        step,
                        updateStep,
                        uniqueSteps,
                        updateUniqueSteps,
                    })}
                {allowValidation && (
                    <div className={styles.validationContainer}>
                        <div className={styles.validationLabel}>
                            Validación:{' '}
                        </div>
                        <div className={styles.validationSelectorContainer}>
                            <RoundedSelect
                                fullWidth
                                handleUpdate={(e): void => {
                                    const tempStep = { ...step };
                                    const type = e.target.value;
                                    if (type) {
                                        tempStep.validation =
                                            StringValidationValues[type];
                                    } else {
                                        delete tempStep.validation;
                                    }
                                    updateStep(tempStep);
                                }}
                                value={step.validation?.type ?? ''}
                                label={''}
                                displayEmpty
                                MenuProps={{ disablePortal: true }}
                                height={'31px'}
                            >
                                <MenuItem value="" key="NONE">
                                    <em>Ninguna</em>
                                </MenuItem>
                                {StringValidations.map((val) => (
                                    <MenuItem value={val.type} key={val.type}>
                                        {val.label}
                                    </MenuItem>
                                ))}
                            </RoundedSelect>
                        </div>
                    </div>
                )}
                {step.validation?.type === 'OTHER' && (
                    <div className={styles.otherValidationContainer}>
                        <div className={styles.inputContainer}>
                            <RoundedTextField
                                label="Valor (Expressión Regular)"
                                value={step.validation.value}
                                onChange={(e): void => {
                                    if (step.validation) {
                                        updateStep({
                                            ...step,
                                            validation: {
                                                ...step.validation,
                                                value: e.target.value,
                                            },
                                        });
                                    }
                                }}
                            ></RoundedTextField>
                        </div>

                        <RoundedTextField
                            label="Mensaje de Error"
                            value={step.validation.message}
                            onChange={(e): void => {
                                if (step.validation) {
                                    updateStep({
                                        ...step,
                                        validation: {
                                            ...step.validation,
                                            message: e.target.value,
                                        },
                                    });
                                }
                            }}
                        ></RoundedTextField>
                    </div>
                )}
            </div>
        );
    } else {
        return (
            <div
                style={{
                    width: calcStepWidth(step.size, size),
                    maxWidth: '100%',
                    minHeight:
                        step.description || step.required || step.validation
                            ? '50px'
                            : '38px',
                }}
            >
                <RoundedTextField
                    label={step.label}
                    required={step.required}
                    helperText={step.description}
                    fontWeight={400}
                    backgroundColor={'var(--primary1)'}
                    icon={step.showIcon ? icon : undefined}
                ></RoundedTextField>
            </div>
        );
    }
}

export default React.memo(TextInputStep, (prev, next) => {
    const { step, stepEqualityChecker } = prev;
    const { step: newStep } = next;
    if (
        step.size !== newStep.size ||
        step.label !== newStep.label ||
        step.required !== newStep.required ||
        step.validation !== newStep.validation ||
        step.description !== newStep.description
    ) {
        return false;
    }

    return stepEqualityChecker?.(prev, next) ?? true;
}) as typeof TextInputStep;
