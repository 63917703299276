import styles from './MapperStep.module.css';
import RoundedTextField from '../../../shared/RoundedTextField/RoundedTextField';
import React, { cloneElement, useState } from 'react';
import StepComponent, {
    GenericStepProps,
} from '../../../shared/GenericFormEditor/Step/Step';
import RoundedButton from '../../../shared/RoundedButton/RoundedButton';
import AddStepBtn from '../../../shared/GenericFormEditor/AddStepBtn/AddStepBtn';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import LocationTypes from '../../../constants/LocationTypes';
import { GLocation } from '../../../@Types/FormTypes/LocationTypes';
import { GMapper } from '../../../@Types/GenericFormSteps';
import Toggle from '../../Toggle/Toggle';
function MapperStepComponent<
    StepType extends GMapper<any>,
    UniqueType,
    LocationType extends GLocation
>({
    step,
    size,
    level,
    editing,
    children,
    location,
    updateStep,
    widthStats,
    stepMenuComponent,
    ...others
}: GenericStepProps<StepType, UniqueType, LocationType> & {
    /** children to render */
    children?: React.ReactElement<any, string>;
}): JSX.Element {
    const [openId, setOpenId] = useState<string | undefined>(undefined);

    const renderSubSteps = (disabled = false): JSX.Element[] => {
        return step.rootSteps.map((idStep: string, index) => {
            return (
                <StepComponent
                    {...others}
                    level={level + 1}
                    onOpen={(): void => {
                        setOpenId(idStep);
                    }}
                    disabled={disabled}
                    canMoveUp={index !== 0}
                    canMoveDown={index !== step.rootSteps.length - 1}
                    location={{
                        type: LocationTypes.MAPPER,
                        idStep: step.id,
                        idRootStep: location.idRootStep,
                        indexStep: index,
                    }}
                    key={index}
                    openId={openId}
                    idStep={idStep}
                    stepMenuComponent={stepMenuComponent}
                    widthStats={widthStats}
                    size={size}
                />
            );
        });
    };

    if (editing) {
        return (
            <div className={styles.editingContainer}>
                <RoundedTextField
                    label="Título"
                    value={step.label ?? ''}
                    onChange={(e): void => {
                        updateStep({ ...step, label: e.target.value });
                    }}
                    fontSize="24px"
                    height="auto"
                    fontWeight={400}
                ></RoundedTextField>
                <div className={styles.inputContainer}>
                    <RoundedTextField
                        label="Descripción"
                        value={step.description ?? ''}
                        onChange={(e): void => {
                            updateStep({
                                ...step,
                                description: e.target.value,
                            });
                        }}
                        multiline
                        minRows={3}
                    ></RoundedTextField>
                </div>
                <div className={styles.inputContainer}>
                    <RoundedTextField
                        label="Etiqueta Unitaria"
                        value={step.unitLabel ?? ''}
                        onChange={(e): void => {
                            updateStep({ ...step, unitLabel: e.target.value });
                        }}
                    ></RoundedTextField>
                </div>
                <div className={styles.inputContainer}>
                    <RoundedTextField
                        label="Etiqueta para Agregar"
                        value={step.addBtnLabel ?? ''}
                        onChange={(e): void => {
                            updateStep({
                                ...step,
                                addBtnLabel: e.target.value,
                            });
                        }}
                    ></RoundedTextField>
                </div>
                <div className={styles.maxToggleContainer}>
                    <div className={styles.maxToggleLabel}>
                        Restringir Cantidad:
                    </div>
                    <div>
                        <Toggle
                            checked={step.max !== undefined}
                            onChange={(checked: boolean): void => {
                                updateStep({
                                    ...step,
                                    max: checked ? 1 : undefined,
                                });
                            }}
                        />
                    </div>
                </div>
                {step.max !== undefined && (
                    <div className={styles.maxInputContainer}>
                        <RoundedTextField
                            label="Cantidad Máxima"
                            value={step.max}
                            onChange={(e): void => {
                                const value = parseInt(e.target.value);
                                if (e.target.value === '') {
                                    updateStep({ ...step, max: '' });
                                } else {
                                    if (value < 1) return;
                                    updateStep({ ...step, max: value });
                                }
                            }}
                            focusColor={
                                (step.max as any) === '' || step.max === 0
                                    ? 'var(--error)'
                                    : 'var(--secondary)'
                            }
                            error={(step.max as any) === '' || step.max === 0}
                            type="number"
                        />
                    </div>
                )}
                {children &&
                    cloneElement(children, {
                        step,
                        updateStep,
                    })}
                <div className={styles.stepsLbl}>Pasos Anidados:</div>
                <div className={styles.stepsContainer}>
                    {renderSubSteps()}
                    <div className={styles.addBtnContainer}>
                        <AddStepBtn
                            size="35px"
                            location={{
                                type: LocationTypes.MAPPER,
                                idStep: step.id,
                                idRootStep: location.idRootStep,
                                indexStep: null,
                            }}
                            stepMenuComponent={stepMenuComponent}
                        />
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div
                className={styles.container}
                style={{
                    width: (size.blockSize + size.spacingSize) * size.blockNum,
                }}
            >
                <div className={styles.titleLbl}>{step.label}</div>
                {step.description && (
                    <p
                        className={styles.descriptionPar}
                        style={{
                            margin: step.description
                                ? '10px 0px'
                                : '0px 0px 5px 0px',
                        }}
                    >
                        {step.description}
                    </p>
                )}
                <div
                    className={
                        widthStats.isMobile
                            ? styles.mobileContainer
                            : styles.incidentContainer
                    }
                >
                    <div
                        className={styles.contentContainer}
                        style={{
                            width:
                                (size.blockSize + size.spacingSize) *
                                (size.blockNum - 1),
                        }}
                    >
                        <div className={styles.elementTitleLbl}>
                            {step.unitLabel + ' 1'}:
                        </div>
                        <div className={styles.deleteBtn}>
                            <DeleteRoundedIcon fontSize="inherit" />
                        </div>
                        {renderSubSteps(true)}
                    </div>
                </div>
                <div className={styles.btnContainer}>
                    <RoundedButton
                        text={step.addBtnLabel}
                        color={'white'}
                        fontSize={'1rem'}
                        padding={'5px 15px 5px 15px'}
                    />
                </div>
            </div>
        );
    }
}

export default React.memo(MapperStepComponent, (prev, next) => {
    const { step, stepEqualityChecker } = prev;
    const { step: newStep } = next;
    if (
        step.label !== newStep.label ||
        step.description !== newStep.description ||
        step.addBtnLabel !== newStep.addBtnLabel ||
        step.style !== newStep.style ||
        step.unitLabel !== newStep.unitLabel ||
        step.required !== newStep.required ||
        step.creatable !== newStep.creatable ||
        step.rootSteps !== newStep.rootSteps ||
        step.steps !== newStep.steps ||
        step.max !== newStep.max
    ) {
        return false;
    }

    return stepEqualityChecker?.(prev, next) ?? true;
}) as typeof MapperStepComponent;
