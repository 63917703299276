import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import RoundedEntityPropertyPicker from '../../../@Pickers/RoundedEntityPropertyPicker/RoundedEntityPropertyPicker';
import styles from './EntityValueFilter.module.css';
import EntityPropertyTypes from '../../../../constants/EntityPropertyTypes';
import React from 'react';
import { ValueFilterComponent } from '../../../../pages/EntityValues/EntityValueFilters/EntityValueFiltersMenu/ValueFilters/ValueFilters';
import RelationshipEntityValueFilter from './EntityEntityValueFilter/RelationshipEntityValueFilter';
import { EntityValuePickerFilter } from '../../../../@Types/GenericFormSteps';
import { EntityValueFilterTypes } from '../../../../constants/FormStepTypes';
import IntegrationTypes from '../../../../constants/IntegrationTypes';
import IntegrationErkValuesPicker from '../../../Integrations/IntegrationErkValuesPicker/IntegrationErkValuesPicker';
import { useAppSelector } from '../../../../hooks';
import { isIntegration } from '../../../../@Types/Integration';
import { EntityProperty } from '../../../../@Types/EntityTypes/EntityProperty';
import { DraftContext } from '../../../../@Types/Draft/DraftContext';

interface EntityValueFilterProps {
    idEntity: string;
    context: DraftContext;
    filter: EntityValuePickerFilter;
    handleUpdate: (filter: EntityValuePickerFilter) => void;
    handleDelete: () => void;
    elementsToOmit?: string[];
    showRequired?: boolean;
}

function EntityValueFilter({
    idEntity,
    filter,
    context,
    handleDelete,
    handleUpdate,
    elementsToOmit,
    showRequired = true,
}: EntityValueFilterProps): JSX.Element {
    const property = useAppSelector(
        (state) => state.site.entities[idEntity].steps[filter.idProperty]
    );

    const renderFilter = (): JSX.Element | null => {
        if (!property) return null;
        switch (property.type) {
            case EntityPropertyTypes.ENTITY_RELATIONSHIP:
                return (
                    <RelationshipEntityValueFilter
                        filter={filter}
                        property={property}
                        handleUpdate={(
                            filter: EntityValuePickerFilter
                        ): void => {
                            handleUpdate(filter);
                        }}
                        showRequired={showRequired}
                    />
                );
            case EntityPropertyTypes.SELECTOR:
                if (filter.type !== EntityValueFilterTypes.VALUE) return null;
                return (
                    <div className={styles.valueInput}>
                        <ValueFilterComponent
                            idEntity={idEntity}
                            step={property}
                            value={filter.value}
                            handleChange={(value): void => {
                                handleUpdate({ ...filter, value });
                            }}
                        />
                    </div>
                );
            default:
                if (filter.type !== EntityValueFilterTypes.INTEGRATION)
                    return null;

                return (
                    <div className={styles.valueInput}>
                        <IntegrationErkValuesPicker
                            context={context}
                            values={filter.values}
                            idIntegration={filter.idIntegration}
                            handleUpdate={(values): void => {
                                handleUpdate({ ...filter, values });
                            }}
                        />
                    </div>
                );
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <div className={styles.bullet}></div>
                <div>
                    <RoundedEntityPropertyPicker
                        idEntity={idEntity}
                        elementsToOmit={elementsToOmit}
                        propertyTypes={[
                            EntityPropertyTypes.TEXTINPUT,
                            EntityPropertyTypes.ENTITY_RELATIONSHIP,
                            EntityPropertyTypes.SELECTOR,
                        ]}
                        value={
                            filter?.type === EntityValueFilterTypes.INTEGRATION
                                ? filter.idIntegration
                                : filter.idProperty
                        }
                        integrationTypes={[
                            IntegrationTypes.ENTITY_VALUES_FILTER,
                        ]}
                        handleUpdate={(property): void => {
                            if (
                                property?.type ===
                                EntityPropertyTypes.ENTITY_RELATIONSHIP
                            ) {
                                if (property.idEntity === idEntity) {
                                    handleUpdate({
                                        idProperty: property.id,
                                        type: EntityValueFilterTypes.ENTITY_RELATIONSHIP,
                                    });
                                } else {
                                    handleUpdate({
                                        idProperty: property.id,
                                        type: EntityValueFilterTypes.STEP,
                                        any: true,
                                        required: false,
                                    });
                                }
                            } else if (
                                isIntegration(property) &&
                                property?.type ===
                                    IntegrationTypes.ENTITY_VALUES_FILTER
                            ) {
                                handleUpdate({
                                    idProperty: property.idProperty,
                                    type: EntityValueFilterTypes.INTEGRATION,
                                    idIntegration: property._id,
                                });
                            } else if (property) {
                                handleUpdate({
                                    idProperty: (property as EntityProperty).id,
                                    type: EntityValueFilterTypes.VALUE,
                                    value: [],
                                });
                            }
                        }}
                    />
                </div>
                <div className={styles.deleteBtn} onClick={handleDelete}>
                    <DeleteRoundedIcon fontSize="inherit" />
                </div>
            </div>
            {renderFilter()}
        </div>
    );
}
export default EntityValueFilter;
