import { useEffect } from 'react';
import { RouterProps } from '../../../Router';
import { match, withRouter } from 'react-router-dom';
import NoMobile from '../../../shared/NoMobile/NoMobile';
import { useSelector } from 'react-redux';
import { reset } from '../../../controllers/GenericEditorController/GenericEditorActions';
import { Survey } from '../../../@Types/@Types';
import GenericFormEditor from '../../../shared/GenericFormEditor/GenericFormEditor';
import SurveyConfig from './SurveyConfig/SurveyConfig';
import * as SurveyService from '../../../controllers/SurveysController/SurveysService';
import { SurveyStep } from '../../../@Types/SurveyTypes/SurveyStep';
import {
    SurveyStepLocation,
    UniqueSteps,
} from '../../../@Types/SurveyTypes/Survey';
import { RootState } from '../../../utils/_store';
import { EditorConfig } from '../../../controllers/GenericEditorController/GenericEditorReducer';
import ReducerFunctions from './SurveyReducerFunctions';
import { SurveyStepMapper } from './SurveyStepMapper';
import StepMenu from './StepMenu/StepMenu';
import { checkAdmin } from '../../../utils/PermissionsFunctions';
import { useGenericEditorDispatch } from '../../../shared/GenericFormEditor/GenericFormEditorHooks';
interface apiKeyRouteParam {
    /** Current survey */
    idSurvey: string;
    /** Current Project */
    idProject: string;
}

const idEditor = 'SURVEY';

function SurveyComponent({
    match,
    mobile,
}: {
    match: match<apiKeyRouteParam>;
} & RouterProps): JSX.Element {
    const dispatch = useGenericEditorDispatch();
    const siteInfo = useSelector((state: RootState) => state.site);

    useEffect(() => {
        if (match.params.idSurvey) {
            dispatch(
                reset(idEditor, {
                    loadParams: [match.params.idProject, match.params.idSurvey],
                    loadData: SurveyService.loadSurvey,
                    ...ReducerFunctions,
                } as EditorConfig<Survey, any, any, any, any>)
            );
        }
    }, [match.params.idProject, match.params.idSurvey]);

    if (mobile) {
        return <NoMobile />;
    }
    return (
        <GenericFormEditor<
            Survey,
            SurveyStep,
            UniqueSteps,
            SurveyStepLocation,
            Survey
        >
            mobile={mobile}
            idEditor={idEditor}
            stepMenuComponent={<StepMenu />}
            multiSection
            configComponent={<SurveyConfig />}
            editSize={checkAdmin(siteInfo.user)}
            editId={checkAdmin(siteInfo.user)}
            editStyle
            calcCurrentRoute={(project, data, sectionName): string => {
                let route = project?.name + ' / Encuestas / ' + data?.name;
                if (sectionName) {
                    route += ' / ' + sectionName;
                }
                return route;
            }}
            handleSave={async (survey, data): Promise<void> => {
                await SurveyService.saveSurvey(
                    match.params.idProject,
                    data._id,
                    {
                        ...data,
                        ...survey,
                    }
                );
            }}
            stepMapper={SurveyStepMapper}
            stepMapperProps={{}}
            getFormFromData={(data: Survey): Survey => {
                return data;
            }}
        />
    );
}
export default withRouter((props: any) => <SurveyComponent {...props} />);
