import styles from './Payload.module.css';
import { Payload } from '../../@Types/Payload';
import PayloadComponent from './PayloadComponent';
import { calcDate } from '../../utils/DateFunctions';

interface RoundedPayloadProps {
    /** Payload to Render */
    payload: Payload;
    /** The date sent */
    date: Date;
    /** If the action label should be shown */
    showLbl?: boolean;
    /** The name of the user to display */
    name?: string;
    /** if the payload is a reponse (otherwise is a comment) */
    isResponse?: boolean;
    /** if omit the title */
    blockTitle?: boolean;
    /** The margin */
    marginTop?: number;
    /** If the PayloadRenderer should show decorators */
    showDecorators?: boolean;
}

function RoundedPayload({
    payload,
    date,
    name,
    isResponse,
    blockTitle,
    showLbl = true,
    marginTop = 20,
    showDecorators = false,
}: RoundedPayloadProps): JSX.Element {
    return (
        <div className={styles.container}>
            <div
                className={styles.actionTitleContainerUser}
                style={{
                    marginTop,
                }}
            >
                {!blockTitle && (
                    <div className={styles.actionTitle}>
                        <span className={styles.boldName}>{name}</span>
                        {isResponse && showLbl
                            ? 'Respondió'
                            : showLbl
                            ? 'Comentó'
                            : ''}
                    </div>
                )}
                <label className={styles.actionDate}>{calcDate(date)}</label>
            </div>
            <div className={styles.msgContainer}>
                <PayloadComponent
                    content={payload}
                    showDecorators={showDecorators}
                />
            </div>
        </div>
    );
}
export default RoundedPayload;
