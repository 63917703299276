import { JSX } from 'react';
import { ActionWithPayloadContent } from './ActionWithPayload/ActionWithPayloadContent';
import { ActivityContent } from './Activity/ActivityContent';
import ActionTypes from '../../../../../../../../constants/ActionTypes';
import { ActionNotification } from '../../../../../../../../@Types/GroupedMenuNotification';
import TicketContent from './TicketContent/TicketContent';
import { Ticket } from '../../../../../../../../@Types/@Types';

export interface UngroupedNotificationContentProps {
    ticket: Ticket;
    notification: ActionNotification;
}

export function UngroupedNotificationContent({
    ticket,
    notification,
}: UngroupedNotificationContentProps): JSX.Element {
    const action = notification.action;

    switch (action.type) {
        case ActionTypes.COMMENT:
            return (
                <ActionWithPayloadContent
                    person={action.agent as any}
                    payload={action.payload}
                    isComment={true}
                />
            );
        case ActionTypes.AUTO_COMMENT:
            return (
                <ActionWithPayloadContent
                    payload={action.payload}
                    isComment={true}
                />
            );
        case ActionTypes.CLIENT_REPLY:
            return (
                <ActionWithPayloadContent
                    payload={action.payload}
                    isComment={false}
                />
            );
        case ActionTypes.ASSIGN_AGENTS:
        case ActionTypes.AUTO_ASSIGN_AGENTS:
            return <TicketContent label="Caso Asignado:" ticket={ticket} />;
        case ActionTypes.TRANSFER_AGENT:
            return (
                <TicketContent
                    label={`${action.agent} transfirió el caso:`}
                    ticket={ticket}
                />
            );
        case ActionTypes.ACTIVITY:
            return <ActivityContent />;
        default:
            break;
    }
    return <div></div>;
}
