import styles from './SurveyListItem.module.css';
import Avatar from '../../../shared/Avatar/Avatar';
import { Survey } from '../../../@Types/@Types';
interface SurveyListItemProps {
    /** The selected Survey */
    selectedElement?: Survey;
    /** The Survey to display */
    element?: Survey;
    /** Function called when the component is clicked */
    handleItemClick?: Function;
}
function SurveyListItem({
    selectedElement,
    element,
    handleItemClick,
}: SurveyListItemProps): JSX.Element {
    if (!element || !handleItemClick) {
        return <div></div>;
    }

    return (
        <div
            className={
                styles.container +
                (selectedElement?._id === element._id
                    ? ' selected-item-shadow'
                    : '')
            }
            onClick={(): void => handleItemClick(element, true)}
        >
            <div className={styles.sideBar}></div>
            <div className={styles.info}>
                <div className={styles.title}>{element.name}</div>
            </div>
            <div className="flexbox">
                <div className={styles.photo}>
                    <Avatar lbl={element.name} size={50} fontSize={40}>
                        <img
                            className={styles.elementIcon}
                            src={'/media/icons/surveyIcon.svg'}
                        ></img>
                    </Avatar>
                </div>
            </div>
        </div>
    );
}

export default SurveyListItem;
