import styles from './ClientCompanyComponent.module.css';
import Avatar from '../../../Avatar/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import Toggle from '../../../Toggle/Toggle';
import { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import React from 'react';
import { Client, Company } from '../../../../@Types/@Types';
import { RootState } from '../../../../utils/_store';
import { updateCompanyClientPermissions } from '../../../../controllers/CompaniesController/CompaniesService';
import EntityIcon from '../../../SmartIcons/EntityIcon';
import RoundedButton from '../../../RoundedButton/RoundedButton';
import { Link } from 'react-router-dom';
import { refreshCurrent } from '../../../../controllers/ClientsController/ClientsActions';
interface ClientCompanyProps {
    /** The ClientInfo to display */
    client: Client;
    /** The company to display */
    company: Company;
    handleDelete?: Function;
}
function ClientCompanyComponent({
    client,
    company,
    handleDelete,
}: ClientCompanyProps): JSX.Element {
    const companyEntity = useSelector(
        (state: RootState) =>
            state.site.entities[state.site.organization?.idCompanyEntity ?? '']
    );
    const [showPermissions, setShowPermissions] = useState(false);

    const [clientCompany, setClientCompany] = useState(
        client.companies.find((c) => c.idCompany === company._id)
    );
    const dispatch = useDispatch();

    const nPermissions = Object.values(clientCompany?.permissions ?? {}).filter(
        (p) => p === true
    ).length;

    if (!clientCompany) return <div></div>;
    return (
        <React.Fragment>
            <div className={styles.container}>
                <div className={styles.cardContainer}>
                    <div className={styles.sideBar}></div>
                    <div className={styles.info}>
                        <h4 className={styles.title}>
                            {company.values['name'] ?? companyEntity.name}
                        </h4>
                        <div className={styles.viewMoreBtn}>
                            <Link
                                to={`/companies/${company._id}`}
                                className="app-link"
                            >
                                <RoundedButton
                                    text={'Ver más'}
                                    backgroundColor={'var(--secondary)'}
                                    color={'white'}
                                    onClick={(): void => {}}
                                    padding="0px 10px"
                                />
                            </Link>
                        </div>
                    </div>
                    <div className="flexbox">
                        <div className={styles.photo}>
                            <Avatar
                                lbl={company.values['name'] ?? '?'}
                                size={50}
                                fontSize={30}
                            >
                                <EntityIcon
                                    idEntity={companyEntity._id}
                                    fill="var(--primary1)"
                                    style={{
                                        height: 35,
                                        width: 35,
                                    }}
                                />
                            </Avatar>
                        </div>
                    </div>
                    {handleDelete && (
                        <div
                            className={styles.removeBtn}
                            onClick={(): void => {
                                handleDelete();
                            }}
                        >
                            <ClearRoundedIcon fontSize="inherit" />
                        </div>
                    )}
                </div>
                {showPermissions && companyEntity.permissions && (
                    <div className={styles.permissionsContainer}>
                        {companyEntity.permissions.map((permission, index) => (
                            <div
                                className={styles.permission + ' flexbox'}
                                key={index}
                            >
                                <div
                                    className={
                                        styles.permissionLbl + ' noselect'
                                    }
                                >
                                    {permission.name}
                                </div>
                                <div className={styles.toggleContainer}>
                                    <Toggle
                                        size="small"
                                        checked={
                                            clientCompany?.permissions[
                                                permission.id
                                            ] === true
                                        }
                                        onChange={async (
                                            checked: boolean
                                        ): Promise<void> => {
                                            setClientCompany({
                                                ...clientCompany,
                                                permissions: {
                                                    ...clientCompany.permissions,
                                                    [permission.id]: checked,
                                                },
                                            });
                                            await updateCompanyClientPermissions(
                                                company._id,
                                                client._id,
                                                {
                                                    ...clientCompany?.permissions,
                                                    [permission.id]: checked,
                                                }
                                            );
                                            dispatch(refreshCurrent());
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {companyEntity.permissions &&
                    companyEntity.permissions.length > 0 && (
                        <div className={styles.permissionsTabContainer}>
                            <div
                                className={styles.permissionsTab}
                                onClick={(): void => {
                                    setShowPermissions(!showPermissions);
                                }}
                            >
                                Permisos
                                <div className={styles.countLbl}>
                                    {nPermissions}
                                </div>
                                <div className={styles.permissionsIcon}>
                                    {showPermissions ? (
                                        <ExpandLessIcon fontSize="inherit" />
                                    ) : (
                                        <ExpandMoreIcon fontSize="inherit" />
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        </React.Fragment>
    );
}

export default ClientCompanyComponent;
