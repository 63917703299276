import styles from './ActivityCard.module.css';
import Avatar from '../../../shared/Avatar/Avatar';
import Types from '../../../constants/ActionTypes';

interface ActivityCardProps {
    /** The currently displayed activity */
    activity: any;
}
function ActivityCard({ activity }: ActivityCardProps): JSX.Element {
    const calcPersonName = (): string => {
        if (activity.agent !== undefined) {
            return activity.agent.name;
        } else {
            return '';
        }
    };
    const getSpan = (
        texts: { text: string; bold: boolean }[]
    ): JSX.Element[] => {
        const spans: JSX.Element[] = [];
        texts.map((text, index) => {
            if (text.bold === true) {
                spans.push(
                    <span key={index} className={styles.highlighted}>
                        {text.text}
                    </span>
                );
            } else {
                spans.push(
                    <span key={index} className={styles.ticketWord}>
                        {text.text}
                    </span>
                );
            }
        });
        return spans;
    };
    const renderText = (): JSX.Element[] => {
        switch (activity.type) {
            case Types.CLASSIFY:
                return getSpan([
                    {
                        text: 'agregó un nuevo clasificador al caso',
                        bold: false,
                    },
                ]);
                break;
            case Types.SET_COMPANY:
                return getSpan([
                    {
                        text: 'agregó una empresa al caso',
                        bold: false,
                    },
                ]);
                break;
            case Types.COMMENT:
                return getSpan([{ text: 'comentó el caso', bold: false }]);
                break;
            case Types.REPLY:
                return getSpan([
                    { text: 'envió una respuesta al caso', bold: false },
                ]);
                break;
            case Types.ASSIGN_AGENTS:
                if (activity.assignees !== undefined) {
                    return getSpan([
                        { text: 'te asignó al caso', bold: false },
                    ]);
                }
                break;
            case Types.CLIENT_REPLY:
                return getSpan([
                    {
                        text: 'Nueva respuesta en el caso',
                        bold: false,
                    },
                ]);
                break;
            case Types.TRANSFER_AGENT:
                if (activity.transferee !== undefined) {
                    return getSpan([
                        { text: 'te transfirió el caso', bold: false },
                    ]);
                }
                break;
            default:
                return [<label key={'EMPTY'}></label>];
                break;
        }
        return [<label key={'EMPTY'}></label>];
    };

    const calcDate = (): string => {
        return activity?.creation_date.toLocaleDateString('es-CO', {
            month: 'long',
            day: 'numeric',
        });
    };
    return (
        <div className={styles.container}>
            <Avatar
                img={activity?.agent?.img}
                lbl={calcPersonName()}
                size={40}
            />
            <p className={styles.activityParagraph}>
                <span className={styles.agentName}>
                    {activity?.agent?.name}
                </span>
                {renderText()}
                <span className={styles.ticketName}>
                    {activity?.ticket?.subject
                        ? activity?.ticket?.subject
                        : activity?.ticket?.caseNumber}
                </span>
                <span className={styles.ticketWord}> el {calcDate()}</span>
            </p>
        </div>
    );
}
export default ActivityCard;
