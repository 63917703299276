import { AnyAction } from '@reduxjs/toolkit';
import { takeLatest, call, put, fork, select } from 'redux-saga/effects';
import { Agent, Area } from '../../@Types/@Types';
import { RootState } from '../../utils/_store';
import { AgentsMenuState } from './AgentsReducer';
import * as actions from './AgentsActions';
import { loadAgents, loadAgentsById } from './AgentsService';
import { loadAreas } from '../AreasController/AreasService';
import { SiteState } from '../_SiteController/SiteReducer';

/** Number of elements per Page */
const PAGE_SIZE = 20;

/**
 * Function called when new agents should be rendered(reset,search, or newpage)
 * @param action of type Search, reset or scroll
 */
function* getAgents(action: AnyAction): any {
    try {
        const siteInfo: SiteState = yield select(
            (state: RootState) => state.site
        );
        const menuInfo: AgentsMenuState = yield select(
            (state: RootState) => state.agentsMenu
        );
        let result = yield call(
            loadAgents,
            menuInfo.page,
            PAGE_SIZE,
            menuInfo.projectFilter ? siteInfo.idProject : undefined,
            menuInfo.areafilter,
            menuInfo.search
        );
        //Remove agents that are already selected
        if (actions.reset.match(action)) {
            result = result.filter(
                (agent: Agent) =>
                    action.payload.filterAgents.find(
                        (pAgent) => agent._id === pAgent._id
                    ) === undefined
            );
        } else {
            result = result.filter(
                (agent: Agent) =>
                    menuInfo.filterAgents.find(
                        (pAgent) => agent._id === pAgent._id
                    ) === undefined
            );
        }

        if (actions.reset.match(action)) {
            let areas: Area[] = [];
            if (
                action.payload.fetchAreas &&
                action.payload.projectFilter &&
                siteInfo.idProject
            ) {
                const areasResponse: {
                    roots: string[];
                    elements: Record<string, Area>;
                } = yield call(
                    loadAreas,
                    siteInfo.idProject,
                    'order',
                    undefined
                );
                areas = Object.values(areasResponse.elements).filter(
                    (area: Area) => area.active
                );
            }
            let selected: Agent[] = [];
            if (action.payload.selected.length > 0) {
                if ((action.payload.selected[0] as any)._id !== undefined) {
                    selected = action.payload.selected as any;
                } else {
                    selected = yield call(
                        loadAgentsById,
                        action.payload.selected as any
                    );
                }
            }
            result = result.map((agent: Agent) => {
                return {
                    ...agent,
                    selected:
                        selected.find((ele: Agent) => ele._id === agent._id) !==
                        undefined,
                };
            });
            yield put(
                actions.getSuccess({
                    elements: result,
                    noMoreElements: result.length < PAGE_SIZE,
                    areas,
                    selected,
                })
            );
        } else {
            result = result.map((agent: Agent) => {
                return {
                    ...agent,
                    selected:
                        menuInfo.selected.find(
                            (ele: Agent) => ele._id === agent._id
                        ) !== undefined,
                };
            });
            const noMoreElements =
                (menuInfo.page !== 1 && result.length === 0) ||
                (menuInfo.page === 1 && result.length < PAGE_SIZE);

            if (actions.pageScroll.match(action)) {
                result = [...menuInfo.elements].concat(result);
            }
            yield put(
                actions.getSuccess({
                    elements: result,
                    noMoreElements,
                })
            );
        }
    } catch (error) {
        //TODO handle errors
        console.error(error);
    }
}

function* watchGetAgentsRequest(): any {
    yield takeLatest(
        [
            actions.Types.RESET,
            actions.Types.PAGE_SCROLL,
            actions.Types.SEARCH,
            actions.Types.FILTER,
        ],
        getAgents
    );
}

export default [fork(watchGetAgentsRequest)];
