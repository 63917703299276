import styles from './Step.module.css';
import CBRStepTypes from '../../../../constants/Construction/CBRFormStepTypes';
import AYFStepTypes from '../../../../constants/Construction/AYFFormStepTypes';
import React, { useCallback } from 'react';
import { ConversationStep } from '../../../../@Types/ConversationTypes/ConversationStep';
import StepTypes from '../../../../constants/Conversations/ConversationStepTypes';
import { StepLocation } from '../../../../controllers/ConversationEditorController/ConversationEditorReducer';
import InfoTextStep from './Informative/InfoTextStep/InfoTextStep';
import TextStep from './Collection/TextStep/TextStep';
import CreateStep from './CreateStep/CreateStep';
import FileStep from './Collection/FileStep/FileStep';
import OpenStep from './Collection/OpenStep/OpenStep';
import InfoImageStep from './Informative/InfoImageStep/InfoImageStep';
import InfoStickerStep from './Informative/InfoStickerStep/InfoStickerStep';
import InfoDocumentStep from './Informative/InfoDocumentStep/InfoDocumentStep';
import InfoVideoStep from './Informative/InfoVideoStep/InfoVideoStep';
import InfoAudioStep from './Informative/InfoAudioStep/InfoAudioStep';
import ButtonsStep from './Collection/ButtonsStep/ButtonsStep';
import ListStep from './Collection/ListStep/ListStep';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../utils/_store';
import { useDrop } from 'react-dnd';
import { useRef } from 'react';
import { moveStep } from '../../../../controllers/ConversationEditorController/ConversationEditorActions';
import AddStep from '../AddStep/AddStep';
import BackStep from './Navigation/BackStep/BackStep';
import ExitStep from './Navigation/ExitStep/ExitStep';
import CBRStepMapper from './CBRStepMapper';
import AYFStepMapper from './AYFStepMapper';
import { OrgIntegrations } from '../../../../@Types/Organization';
import CreatableStepComponent from './Collection/CreatableStep/CreatableStep';
import LinkStep from './Informative/LinkStep/LinkStep';
import ListApiStepComponent from './Collection/ListApiStep/ListApiStep';
import EntityValueStepComponent from './Collection/EntityValueStep/EntityValueStep';

const PossibleStepTypes = Object.keys(StepTypes)
    .concat(Object.keys(CBRStepTypes))
    .concat(Object.keys(AYFStepTypes));

export interface StepComponentProps {
    path: StepLocation[];
    dragging?: boolean;
}

export interface DragItem {
    id: string;
    path: StepLocation[];
    lastMoved: string | undefined;
}

export interface Collection {
    isOver: boolean;
    canDrop: boolean;
    item: DragItem;
}

function StepComponent({
    path,
    idStep,
    ...others
}: StepComponentProps & { idStep?: string }): JSX.Element {
    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const step = useSelector(
        (state: RootState) => state.conversationEditor.steps[idStep ?? '']
    );
    const stepDependencies = useSelector(
        (state: RootState) =>
            state.conversationEditor.stepDependencies[idStep ?? '']
    );
    if (!step || !stepDependencies) {
        return <div></div>;
    }
    const integrations = useSelector(
        (state: RootState) => state.site.organization!.integrations
    );

    const moveItem = useCallback((idStep: string, location: StepLocation) => {
        dispatch(moveStep({ idStep, location }));
    }, []);
    const [{ canDrop, item }, drop] = useDrop<DragItem, void, Collection>(
        () => ({
            canDrop(item: DragItem): any {
                return (
                    path.find(
                        (location) => (location as any).idStep === item.id
                    ) === undefined
                );
            },
            accept: PossibleStepTypes,
            collect(monitor): any {
                return {
                    isOver: !!monitor.isOver(),
                    canDrop: monitor.canDrop(),
                    item: monitor.getItem(),
                };
            },
            hover(item: DragItem, monitor): any {
                if (!ref.current) {
                    return;
                }
                // Don't replace items with themselves
                if (item.id === idStep) {
                    return;
                }

                if (item.lastMoved === idStep) {
                    return;
                }
                if (!monitor.isOver({ shallow: true })) {
                    return;
                }
                const location = path[path.length - 1];
                if (location.controlled) {
                    return;
                }
                // Determine rectangle on screen
                const hoverBoundingRect = ref.current?.getBoundingClientRect();
                // Get vertical quarter
                const hoverQuarterY =
                    (hoverBoundingRect.bottom - hoverBoundingRect.top) / 4;
                // Determine mouse position
                const clientOffset = monitor.getClientOffset();
                if (clientOffset === null) {
                    return;
                }
                // Get pixels to the top
                const hoverClientY = clientOffset.y - hoverBoundingRect.top;
                const dragDown = stepDependencies.previousSteps.includes(
                    item.id
                );
                const selectedOption = (step as any).selectedOption;
                if (selectedOption) {
                    return;
                }
                // Only perform the move when the mouse has crossed a quarter of the items height
                // When dragging downwards, only move when the cursor is below 25%
                // When dragging upwards, only move when the cursor is above 25%
                // Dragging downwards
                if (dragDown && hoverClientY < hoverQuarterY) {
                    return;
                }
                // Dragging upwards
                if (!dragDown && hoverClientY > hoverQuarterY * 3) {
                    return;
                }

                if (!!monitor.canDrop()) {
                    // Time to actually perform the action
                    moveItem(item.id, location);
                    item.path[item.path.length - 1] = location;
                    item.lastMoved = idStep;
                }
            },
        }),
        [path, idStep, (step as any).selectedOption]
    );
    drop(ref);
    const disabledDrop =
        item?.id !== undefined &&
        !canDrop &&
        (path[path.length - 1] as any)?.idStep === item.id;
    return (
        <React.Fragment>
            <AddStep location={path[path.length - 1]} disabled={disabledDrop} />
            <div
                className={styles.container}
                ref={ref}
                style={{ opacity: step.disabled ? 0.5 : 1 }}
                onContextMenu={(event): void => {
                    event.preventDefault();
                }}
            >
                {disabledDrop && <div className={styles.disabledCurtain}></div>}
                {ConversationStepMapper({
                    ...others,
                    path,
                    step,
                    dragging: item?.id === idStep,
                    integrations,
                })}
            </div>
        </React.Fragment>
    );
}

export default StepComponent;

export interface ConversationStepMapperProps extends StepComponentProps {
    step: ConversationStep;
    integrations: OrgIntegrations;
}

export function ConversationStepMapper(
    props: ConversationStepMapperProps
): JSX.Element {
    const step = props.step as any;
    switch (props.step.type) {
        case StepTypes.INFO_TEXT_STEP:
            return <InfoTextStep {...props} step={step} />;
        case StepTypes.INFO_IMAGE_STEP:
            return <InfoImageStep {...props} step={step} />;
        case StepTypes.INFO_DOCUMENT_STEP:
            return <InfoDocumentStep {...props} step={step} />;
        case StepTypes.INFO_VIDEO_STEP:
            return <InfoVideoStep {...props} step={step} />;
        case StepTypes.INFO_AUDIO_STEP:
            return <InfoAudioStep {...props} step={step} />;
        case StepTypes.INFO_STICKER_STEP:
            return <InfoStickerStep {...props} step={step} />;
        case StepTypes.TEXT_STEP:
            return <TextStep {...props} step={step} />;
        case StepTypes.FILE_STEP:
            return <FileStep {...props} step={step} />;
        case StepTypes.OPEN_STEP:
            return <OpenStep {...props} step={step} />;
        case StepTypes.BUTTONS_STEP:
            return <ButtonsStep {...props} step={step} />;
        case StepTypes.LINK_STEP:
            return <LinkStep {...props} step={step} />;
        case StepTypes.LIST_STEP:
        case StepTypes.LIST_PAGE_STEP:
            return <ListStep {...props} step={step} />;
        case StepTypes.LIST_API_STEP:
            return <ListApiStepComponent {...props} step={step} />;
        case StepTypes.CREATE_PASSTHROUGH:
        case StepTypes.CREATE_DEFAULT:
            return <CreateStep {...props} step={step} />;
        case StepTypes.NAV_BACK_STEP:
            return <BackStep {...props} step={step} />;
        case StepTypes.NAV_EXIT_STEP:
            return <ExitStep {...props} step={step} />;
        case StepTypes.ENTITY_VALUE_STEP:
            return <EntityValueStepComponent {...props} step={step} />;
        case StepTypes.CREATABLE_STEP:
            return <CreatableStepComponent {...props} step={step} />;
        default:
            if (
                props.integrations.AYF === true &&
                (props.step as any).type.startsWith('AYF')
            ) {
                return <AYFStepMapper {...(props as any)} />;
            }
            if (
                props.integrations.CBR &&
                (props.step as any).type.startsWith('CBR')
            ) {
                return <CBRStepMapper {...(props as any)} />;
            }
            return <div>TODO</div>;
    }
}
