import styles from './DecisionDialog.module.css';
import EditContainer from '../../../../shared/EditContainer/EditContainer';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { nanoid } from 'nanoid';
import { DecisionData } from '../../../../@Types/FlowTypes/NodeTypes/DecisionNode';
import produce from 'immer';
import FlowTypes from '../../../../constants/Flows/FlowTypes';
import Dialog from '../../../../shared/Dialog/Dialog';
import ConditionTypes from '../../../../constants/Conditions/ConditionTypes';
import { useEffect, useMemo } from 'react';
import {
    ConditionContext,
    calcCondition,
    clearConditionEditor,
    setupConditionEditor,
} from '../../../../controllers/ConditionEditorController/ConditionEditorSlice';
import ConditionComponent from '../../../../shared/ConditionEditor/Condition';
import RoundedTextField from '../../../../shared/RoundedTextField/RoundedTextField';
import { Condition } from '../../../../@Types/ConditionTypes/Condition';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { checkAdmin } from '../../../../utils/PermissionsFunctions';

interface DecisionDialogProps {
    handleClose: () => void;
    /** The currently displayed DecisionNodeData */
    decision: DecisionData;
    /** Function called when the data of the current Node is updated */
    handleUpdate: React.Dispatch<(prevState: DecisionData) => DecisionData>;
    /** Function Called when a handle should be deleted. */
    handleDeleteHandle: React.Dispatch<
        (prevState: DecisionData) => {
            decision: DecisionData;
            idHandle: string;
        }
    >;
    /** The type of the current flow */
    flowType: FlowTypes;
}

function DecisionDialog({
    flowType,
    decision,
    handleClose,
    handleUpdate,
    handleDeleteHandle,
}: DecisionDialogProps): JSX.Element {
    const dispatch = useAppDispatch();

    const user = useAppSelector((state) => state.site.user);

    const handleSave = async (): Promise<void> => {
        const decisions = await Promise.all(
            decision.decisions.map(async (dec) => ({
                ...dec,
                condition: await dispatch(
                    calcCondition({
                        idCondition: dec.id,
                        keepSame: true,
                    }) as any
                ).unwrap(),
            }))
        );
        handleUpdate(() => ({ ...decision, decisions }));
        handleClose();
        dispatch(clearConditionEditor(decision.decisions.map((dec) => dec.id)));
    };

    useEffect(() => {
        if (!decision) return;
        const conditions: Record<string, Condition> = {};
        for (const dec of decision.decisions) {
            conditions[dec.id] = dec.condition;
        }
        dispatch(setupConditionEditor(conditions));
    }, []);

    const conditionTypes = useMemo(() => {
        const properties = [
            ConditionTypes.CLASSIFIER,
            ConditionTypes.TEXT,
            ConditionTypes.CLIENT,
            ConditionTypes.SENTIMENT,
            ConditionTypes.ENTITYVALUE,
            ConditionTypes.INTEGRATION,
            ConditionTypes.FLOW,
            ConditionTypes.COMPANY,
        ];
        if (flowType !== FlowTypes.ENTRY) {
            properties.push(ConditionTypes.ENTRY);
        }
        if (checkAdmin(user)) {
            properties.push(ConditionTypes.AI);
        }
        return properties;
    }, []);

    const handleAddDecision = (): void => {
        handleUpdate((decision) =>
            produce(decision, (newDecision: DecisionData) => {
                let unique = false;
                let id: string = '';
                while (!unique) {
                    id = nanoid().replace(/-/g, '_');
                    unique =
                        newDecision.decisions.find(
                            (element) => element.id === id
                        ) === undefined;
                }
                const condition = {
                    type: 'DEFAULT',
                } as const;
                dispatch(setupConditionEditor({ [id]: condition }));
                newDecision.decisions.push({
                    id,
                    name: 'Decisión',
                    condition: condition as any,
                });
            })
        );
    };

    const handleDeleteDecision = (indexDec: number): void => {
        const idHandle = decision.decisions[indexDec].id;
        handleDeleteHandle((decision) => ({
            decision: produce(decision, (newDecision: DecisionData) => {
                newDecision.decisions.splice(indexDec, 1);
            }),
            idHandle,
        }));
    };

    if (!decision) {
        return <div></div>;
    }
    return (
        <Dialog
            open={true}
            onClose={async (): Promise<void> => handleSave()}
            border={20}
            disableEnforceFocus
            maxWidth="90vw"
        >
            <div className={styles.container}>
                <div className={styles.top}>
                    <div className={styles.valueContainer}>
                        <EditContainer
                            object={decision}
                            field={'name'}
                            handleConfirm={(...args: any[]): void => {
                                handleUpdate((decision) => ({
                                    ...decision,
                                    name: args[2],
                                }));
                            }}
                        >
                            <label className={styles.nameLbl}>
                                {decision.name}
                            </label>
                            <input
                                className={styles.nameInput + ' edit-input'}
                                defaultValue={decision.name}
                                type="text"
                                size={15}
                            />
                        </EditContainer>
                    </div>
                </div>
                <div className={styles.decisionsContainer}>
                    {decision.decisions.map((dec, index: number) => (
                        <ConditionContext.Provider value={dec.id} key={dec.id}>
                            <ConditionComponent
                                context={{}}
                                allowEmpty={false}
                                idCondition={dec.id}
                                conditionTypes={conditionTypes}
                                handleDelete={(): void => {
                                    handleDeleteDecision(index);
                                }}
                            >
                                <div className={styles.decisionTitleContainer}>
                                    <div className={styles.descriptorTextField}>
                                        <RoundedTextField
                                            name="Nombre"
                                            label="Nombre"
                                            value={dec.name}
                                            onChange={(event): void => {
                                                handleUpdate((decision) =>
                                                    produce(
                                                        decision,
                                                        (decision) => {
                                                            decision.decisions[
                                                                index
                                                            ].name =
                                                                event.target.value;
                                                        }
                                                    )
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </ConditionComponent>
                        </ConditionContext.Provider>
                    ))}
                    <div className={styles.elseContainer} key={'OTHER'}>
                        <label className={styles.labelOthers}>
                            Lo Demás/Otros
                        </label>
                    </div>
                    <div className={styles.addBtnContainer}>
                        <div
                            className={styles.addBtn}
                            onClick={handleAddDecision}
                        >
                            <div className={styles.addIconContainer}>
                                <AddRoundedIcon fontSize="inherit" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

export default DecisionDialog;
