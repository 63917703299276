import { useSelector } from 'react-redux';
import {
    DraftEntityDataMappingTypes,
    DraftEntityDataTypes,
} from '../../../../constants/Draft/DraftEntityDataTypes';
import Context from '../Context';
import { RootState } from '../../../../utils/_store';
import EntityPropertyTypes from '../../../../constants/EntityPropertyTypes';
import { DraftEntityTypes } from '../../../../constants/Draft/DraftEntityTypes';
import { DraftStyleTypes } from '../../../../constants/Draft/DraftStyleTypes';
import { GenericContextProps } from '../TextEditorContext';

interface EntityValuesContextProps extends GenericContextProps {
    idEntity: string;
    path?: string[];
    type:
        | DraftEntityDataTypes.ENTITYVALUES
        | DraftEntityDataTypes.CLIENT_RELATIONSHIP;
}

function EntityValuesContext({
    type,
    path,
    idEntity,
    ...props
}: EntityValuesContextProps): JSX.Element {
    const entity = useSelector(
        (state: RootState) => state.site.entities[idEntity]
    );
    if (!entity) return <></>;

    const name = entity.steps[entity.idLabelStep];

    if (
        name.type === EntityPropertyTypes.TITLE ||
        name.type === EntityPropertyTypes.SEPARATOR
    )
        return <></>;

    return (
        <Context
            {...props}
            data={{
                type,
                path,
                renderType: 'INLINE',
                idEntity,
                block: {
                    depth: 0,
                    entityRanges: [
                        {
                            key: 0,
                            length: entity.name.length,
                            offset: 0,
                        },
                    ],
                    inlineStyleRanges: [
                        {
                            length: entity.name.length,
                            offset: 0,
                            style: DraftStyleTypes.BOLD,
                        },
                    ],
                    key: 'nested',
                    text: entity.name,
                    type: 'unstyled',
                },
                entityMap: {
                    '0': {
                        type: DraftEntityTypes.EUREKA,
                        mutability: 'IMMUTABLE',
                        data: {
                            type: DraftEntityDataMappingTypes.ENTITYVALUE_MAPPING,
                            idEntity,
                            idProperty: entity.idLabelStep,
                        },
                    },
                },
            }}
        />
    );
}

export default EntityValuesContext;
