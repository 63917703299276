import { OptionTypes } from '../../../constants/OptionTypes';
import { nanoid } from 'nanoid';
import {
    GenericEditorState,
    StepDependencies,
} from '../../../controllers/GenericEditorController/GenericEditorReducer';
import { SurveyStep } from '../../../@Types/SurveyTypes/SurveyStep';
import {
    Survey,
    SurveyStepLocation,
    UniqueSteps,
} from '../../../@Types/SurveyTypes/Survey';
import SurveyStepTypes from '../../../constants/SurveyStepTypes';
import { Section } from '../../../@Types/FormTypes/Form';

import produce from 'immer';
import LocationTypes from '../../../constants/LocationTypes';
type EditorState = GenericEditorState<
    Survey,
    SurveyStep,
    UniqueSteps,
    SurveyStepLocation,
    any
>;
function recursivelyCalcSize(
    steps: Record<string, SurveyStep>,
    idStep: string
): number {
    const step = steps[idStep];
    if (step.type === SurveyStepTypes.SELECTOR) {
        //Calcular el tamano recursivamente
        let maxOption = 0;
        for (const option of step.options) {
            let optionSize = 0;
            if (option.type === OptionTypes.NESTED) {
                for (const pIdStep of option.steps) {
                    optionSize += recursivelyCalcSize(steps, pIdStep);
                }
            }
            if (optionSize > maxOption) {
                maxOption = optionSize;
            }
        }
        return maxOption + step.size;
    } else if (step.type === SurveyStepTypes.CHECKBOX) {
        //Calcular el tamano recursivamente
        let maxSize = 0;
        if (step.steps)
            for (const pIdStep of step.steps) {
                maxSize += recursivelyCalcSize(steps, pIdStep);
            }
        let unCheckedSize = 0;
        if (step.uncheckedSteps)
            for (const pIdStep of step.uncheckedSteps) {
                unCheckedSize += recursivelyCalcSize(steps, pIdStep);
            }
        if (unCheckedSize > maxSize) {
            maxSize = unCheckedSize;
        }
        return maxSize + step.size;
    } else if (step.type === SurveyStepTypes.RATING && step.nestedSteps) {
        //Calcular el tamano recursivamente
        let maxOption = 0;
        for (const optionSteps of step.nestedSteps) {
            let optionSize = 0;

            for (const pIdStep of optionSteps) {
                optionSize += recursivelyCalcSize(steps, pIdStep);
            }

            if (optionSize > maxOption) {
                maxOption = optionSize;
            }
        }
        return maxOption + 4;
    } else {
        const nestedStep = step as any;
        if (nestedStep.steps !== undefined) {
            //Calcular el tamano recursivamente
            let stepSize = 0;
            for (const pIdStep of nestedStep.steps) {
                stepSize += recursivelyCalcSize(steps, pIdStep);
            }
            return stepSize + nestedStep.size;
        } else if (nestedStep.subStep) {
            return (
                nestedStep.size + recursivelyCalcSize(steps, nestedStep.subStep)
            );
        } else {
            return 4;
        }
    }
}

const handleAddStep = (
    state: EditorState,
    { step, location }: { step: SurveyStep; location: SurveyStepLocation }
): EditorState =>
    produce(state, (state) => {
        const uniqueSteps = state.uniqueSteps;
        if (
            uniqueSteps &&
            !uniqueSteps.primaryRatingStep &&
            step.type === SurveyStepTypes.RATING
        ) {
            uniqueSteps.primaryRatingStep = step.id;
        }
        const steps = state.steps;

        if (location.type === LocationTypes.SUBSTEP) {
            const parentStep = steps[location.idStep] as any;
            if (parentStep.subStep) return;
            parentStep.subStep = step.id;
        } else {
            const subSteps = calcLocationSteps(state, location);
            if (subSteps) {
                subSteps.push(step.id);
            }
        }
        steps[step.id] = step;
    });

const handleDeleteStep = (
    state: EditorState,
    { idStep, location }: { idStep: string; location: SurveyStepLocation }
): EditorState =>
    produce(state, (state) => {
        const steps = state.steps;

        delete steps[idStep];

        if (location.type === LocationTypes.SUBSTEP) {
            const parentStep = steps[location.idStep] as any;
            parentStep.subStep = null;
            return;
        }

        if (location.indexStep === null) return;
        const subSteps = calcLocationSteps(state, location);
        if (subSteps) {
            subSteps.splice(location.indexStep, 1);
        }
    });

function cloneStep(
    idStep: string,
    steps: Record<string, SurveyStep>,
    newSteps: Record<string, SurveyStep>
): string {
    const tempStep = { ...steps[idStep] };
    const newId = tempStep.type + '-' + nanoid();
    tempStep.id = newId;
    if (tempStep.type === SurveyStepTypes.SELECTOR) {
        tempStep.options = tempStep.options.map((option) => {
            const tempOption = { ...option };
            if (tempOption.type === OptionTypes.NESTED) {
                tempOption.steps.map((pStep) =>
                    cloneStep(pStep, steps, newSteps)
                );
            }
            return tempOption;
        });
    } else if (
        tempStep.type === SurveyStepTypes.RATING &&
        tempStep.nestedSteps
    ) {
        tempStep.nestedSteps = tempStep.nestedSteps.map((option) => {
            const tempOption = [...option];
            tempOption.map((pStep) => cloneStep(pStep, steps, newSteps));
            return tempOption;
        });
    } else if (tempStep.type === SurveyStepTypes.CHECKBOX) {
        tempStep.uncheckedSteps = tempStep.uncheckedSteps?.map(
            (idStep: string) => cloneStep(idStep, steps, newSteps)
        );
        tempStep.steps = tempStep.steps?.map((idStep: string) =>
            cloneStep(idStep, steps, newSteps)
        );
    } else {
        const nestedStep = tempStep as any;
        if (nestedStep.steps !== undefined) {
            nestedStep.steps = nestedStep.steps.map((idStep: string) =>
                cloneStep(idStep, steps, newSteps)
            );
        } else if (nestedStep.subStep) {
            nestedStep.subStep = cloneStep(nestedStep.subStep, steps, newSteps);
        }
    }
    newSteps[newId] = tempStep;
    return newId;
}

const handlePasteStep = (
    state: EditorState,
    location: SurveyStepLocation
): EditorState =>
    produce(state, (state) => {
        if (!state.copiedInfo) return;

        const copiedInfo = state.copiedInfo;
        const steps = state.steps;

        if (location.type === LocationTypes.SUBSTEP) {
            const parentStep = steps[location.idStep] as any;
            if (parentStep.subStep) return;
            parentStep.subStep = copiedInfo.newStepId;
        } else {
            if (location.indexStep === null) return;

            const subSteps = calcLocationSteps(state, location);
            if (!subSteps) return;

            subSteps.splice(location.indexStep + 1, 0, copiedInfo.newStepId);
        }

        for (const newStep of Object.values(copiedInfo.newSteps)) {
            steps[newStep.id] = newStep;
        }

        /** Make a new Copy */
        const newSteps = {};
        const newIdStep = cloneStep(
            copiedInfo.newStepId,
            copiedInfo.newSteps,
            newSteps
        );
        copiedInfo.newStepId = newIdStep;
        copiedInfo.newSteps = newSteps;
    });

const handleMoveStepUp = (
    state: EditorState,
    location: SurveyStepLocation
): EditorState =>
    produce(state, (state) => {
        if (
            location.type === LocationTypes.SUBSTEP ||
            location.indexStep === null ||
            location.indexStep < 1
        )
            return;
        const i = location.indexStep;
        const steps = calcLocationSteps(state, location);
        if (steps) {
            [steps[i], steps[i - 1]] = [steps[i - 1], steps[i]];
        }
    });

const handleMoveStepDown = (
    state: EditorState,
    location: SurveyStepLocation
): EditorState =>
    produce(state, (state) => {
        if (
            location.type === LocationTypes.SUBSTEP ||
            location.indexStep === null
        )
            return;
        const i = location.indexStep;
        const steps = calcLocationSteps(state, location);
        if (steps && location.indexStep < steps.length - 1) {
            [steps[i], steps[i + 1]] = [steps[i + 1], steps[i]];
        }
    });

const handleUpdateStep = (
    state: EditorState,
    { step }: { step: SurveyStep; location: SurveyStepLocation }
): EditorState =>
    produce(state, (state) => {
        state.steps[step.id] = step;
    });

const handleUpdateSteps = (
    state: EditorState,
    {
        steps,
    }: { steps: Record<string, SurveyStep>; location: SurveyStepLocation }
): EditorState =>
    produce(state, (state) => {
        state.steps = { ...state.steps, ...steps };
    });

const handleUpdateStepId = (
    state: EditorState,
    {
        location,
        idStep,
        newId,
    }: {
        idStep: string;
        newId: string;
        location: SurveyStepLocation;
    }
): EditorState =>
    produce(state, (state) => {
        const steps = state.steps;

        steps[newId] = {
            ...steps[idStep],
            id: newId,
        };
        delete steps[idStep];

        if (location.type === LocationTypes.SUBSTEP) {
            const parentStep = steps[location.idStep] as any;
            parentStep.subStep = newId;
            return;
        }

        if (location.indexStep === null) return;
        const subSteps = calcLocationSteps(state, location);
        if (subSteps) {
            subSteps[location.indexStep] = newId;
        }
    });

const calcLocationSteps = (
    state: EditorState,
    location: SurveyStepLocation
): string[] | void => {
    const steps = state.steps;

    if (location.type === LocationTypes.SECTION) {
        return state.sections[state.idSection!].steps;
    }

    const parentStep = steps[location.idStep];

    if (
        parentStep.type === SurveyStepTypes.SELECTOR &&
        location.type === LocationTypes.SELECTOR
    ) {
        const option = parentStep.options[location.indexOption];
        if (option.type === OptionTypes.NESTED) {
            return option.steps;
        }
    } else if (
        parentStep.type === SurveyStepTypes.RATING &&
        parentStep.nestedSteps &&
        location.type === LocationTypes.RATING
    ) {
        return parentStep.nestedSteps[location.indexOption];
    } else if (
        parentStep.type === SurveyStepTypes.CHECKBOX &&
        location.type === LocationTypes.CHECKBOX
    ) {
        if (location.checked) {
            if (!parentStep.steps) parentStep.steps = [];
            return parentStep.steps;
        } else {
            if (!parentStep.uncheckedSteps) parentStep.uncheckedSteps = [];
            return parentStep.uncheckedSteps;
        }
    } else {
        const nestedStep = parentStep as any;
        if (nestedStep.steps !== undefined) {
            return nestedStep.steps;
        }
    }
};

const calcDependencies = (state: EditorState): EditorState => {
    const newStepDeps = {};
    const currentDeps: StepDependencies = {
        dependencies: {},
        ancestors: [],
        previousSteps: [],
        idParent: null,
    };
    let idSection: string | null = state.firstSection;

    while (idSection !== null) {
        const section: Section = state.sections[idSection];
        for (const idStep of section.steps) {
            calcStepDeps(idStep, state.steps, newStepDeps, currentDeps, null);
        }
        idSection = section.nextSection;
    }
    return { ...state, stepDependencies: newStepDeps };
};

const calcStepDeps = (
    idStep: string,
    steps: Record<string, SurveyStep>,
    stepDeps: Record<string, StepDependencies>,
    deps: StepDependencies,
    idParent: string | null
): void => {
    const step = steps[idStep];
    stepDeps[idStep] = {
        ancestors: idParent
            ? [...deps.ancestors, idParent]
            : [...deps.ancestors],
        dependencies: { ...deps.dependencies },
        previousSteps: [...deps.previousSteps],
        idParent,
    };
    if (!deps.dependencies[step.type]) deps.dependencies[step.type] = [];
    deps.dependencies[step.type].push(idStep);
    deps.previousSteps.push(idStep);

    switch (step.type) {
        case SurveyStepTypes.SELECTOR: {
            for (const option of step.options) {
                if (option.type === OptionTypes.NESTED) {
                    const oDeps = JSON.parse(JSON.stringify(deps));
                    for (const idSub of option.steps) {
                        calcStepDeps(idSub, steps, stepDeps, oDeps, idStep);
                    }
                }
            }
            break;
        }
        case SurveyStepTypes.RATING: {
            if (step.nestedSteps) {
                for (const nestedSteps of step.nestedSteps) {
                    const nDeps = JSON.parse(JSON.stringify(deps));
                    for (const idSub of nestedSteps) {
                        calcStepDeps(idSub, steps, stepDeps, nDeps, idStep);
                    }
                }
            }
            break;
        }
        default: {
            const nestedStep = step as any;
            if (nestedStep.steps !== undefined) {
                for (const idSub of nestedStep.steps) {
                    calcStepDeps(idSub, steps, stepDeps, deps, idStep);
                }
            } else if (nestedStep.subStep) {
                calcStepDeps(nestedStep.subStep, steps, stepDeps, deps, idStep);
            }
            break;
        }
    }
};

export default {
    recursivelyCalcSize,
    handleAddStep,
    handleDeleteStep,
    cloneStep,
    handlePasteStep,
    handleUpdateStep,
    handleUpdateSteps,
    handleMoveStepUp,
    handleMoveStepDown,
    handleUpdateStepId,
    calcDependencies,
};
