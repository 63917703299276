import Avatar from '../../../shared/Avatar/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import styles from './ImageCard.module.css';
import { Agent } from '../../../@Types/Agent';
import VanillaToasts from '../../../shared/Toast/Toast.js';
import { useRef, useState } from 'react';
import { setImgProfile } from '../../../controllers/_SiteController/Services/UserService';
import Loader from '../../../shared/Loader/Loader';
import React from 'react';

interface ImageCardProps {
    userData: Agent;
    refresh: Function;
}
function ImageCard({ userData, refresh }: ImageCardProps): JSX.Element {
    const [loading, setLoading] = useState(false);
    const inputFile = useRef<HTMLInputElement>(null);
    const onButtonClick = (): void => {
        // `current` points to the mounted file input element
        const input = inputFile.current;
        if (input !== null) {
            input.click();
        }
    };

    const onFileDialogChange = (e: any): void => {
        const files = e.target.files;
        const newFile = files[0];
        if (newFile.type.includes('image')) {
            setImage(newFile);
        }
    };

    async function setImage(file: File): Promise<void> {
        if (file) {
            try {
                setLoading(true);
                await setImgProfile({ _id: userData._id, img: file });
                await refresh();
                setLoading(false);
            } catch (error: any) {
                setLoading(false);
                // TODO: Manejo de errores
                VanillaToasts.create({
                    title: 'Error:',
                    text: error.response?.data,
                    type: 'error',
                    timeout: 5000,
                    callback: function () {},
                });
            }
        }
    }
    if (loading) {
        return (
            <div className={styles.pictureCard}>
                <Loader size={60} />
            </div>
        );
    }
    return (
        <div className={styles.pictureCard} data-testid={'ImageCard'}>
            <div
                className={styles.picture}
                onClick={onButtonClick}
                data-testid={'avatar'}
            >
                <Avatar size={200} img={userData.img} lbl={userData.name}>
                    {userData.img === undefined && (
                        <PersonIcon
                            style={{
                                width: 200,
                                height: 200,
                                backgroundColor: '#BDBDBD',
                            }}
                        />
                    )}
                </Avatar>
                <label className={styles.editLbl}>Editar</label>
                <input
                    type="file"
                    ref={inputFile}
                    style={{ display: 'none' }}
                    onChange={onFileDialogChange}
                    accept="image/*"
                />
            </div>
            <p className={styles.email}>{userData.email}</p>
        </div>
    );
}
export default ImageCard;
