import { createRef, useState } from 'react';
import { StyleKeys } from '../../../../@Types/GenericForm';
import styles from './StyleEditor.module.css';
import { ChromePicker } from 'react-color';
import React from 'react';
import { ClickAwayListener, Popper } from '@material-ui/core';
import { updateStyle } from '../../../../controllers/GenericEditorController/GenericEditorActions';
import {
    useGenericEditorDispatch,
    useGenericEditorSelector,
} from '../../GenericFormEditorHooks';

const MaterialColors: { label: string; key: StyleKeys }[] = [
    {
        label: 'Color de Fondo en Pagina Independiente',
        key: 'standAloneBackgroundColor',
    },
    { label: 'Color de Fondo', key: 'backgroundColor' },
    { label: 'Color Principal', key: 'primaryColor' },
    { label: 'Color Secundario', key: 'secondaryColor' },
    { label: 'Color Contraste Principal', key: 'primaryContrastColor' },
    { label: 'Color Contraste Secundario', key: 'secondaryContrastColor' },
    { label: 'Color de Error', key: 'errorColor' },
    { label: 'Color del texto', key: 'textColor' },
    { label: 'Color de los Bordes', key: 'outlineColor' },
    { label: 'Color de las descripciones', key: 'descriptionTextColor' },
    { label: 'Color interno', key: 'stepBackgroundColor' },
];

function StyleEditor(): JSX.Element {
    const dispatch = useGenericEditorDispatch();
    const [refs] = useState<any[]>(MaterialColors.map(() => createRef()));
    const editorInfo = useGenericEditorSelector((state) => state);
    const [choosingColor, setChoosingColor] = useState<undefined | number>(
        undefined
    );
    return (
        <div className={styles.container}>
            <div className={styles.designLabel + ' noselect'}>Diseño:</div>
            {choosingColor !== undefined && (
                <ClickAwayListener
                    mouseEvent="onMouseDown"
                    onClickAway={(): void => {
                        setChoosingColor(undefined);
                    }}
                >
                    <Popper
                        open={true}
                        anchorEl={(refs[choosingColor] as any).current}
                        placement={'right-end'}
                        modifiers={{
                            preventOverflow: {
                                enabled: true,
                                priority: ['left', 'bottom'],
                            },
                        }}
                        style={{ zIndex: 3 }}
                    >
                        <div className={styles.colorPickContainer + ' shadow'}>
                            <ChromePicker
                                color={
                                    editorInfo.style[
                                        MaterialColors[choosingColor].key
                                    ] ?? '#ffffff'
                                }
                                disableAlpha={false}
                                onChange={(color: { hex: string }): void => {
                                    dispatch(
                                        updateStyle({
                                            ...editorInfo.style,
                                            [MaterialColors[choosingColor].key]:
                                                color.hex,
                                        })
                                    );
                                }}
                            />
                        </div>
                    </Popper>
                </ClickAwayListener>
            )}
            {MaterialColors.map(
                (color, index: number): JSX.Element => (
                    <div className={styles.stylesContainer} key={index}>
                        <div className={styles.stylesLabel + ' noselect'}>
                            {color.label}:
                        </div>
                        <div
                            className={styles.colorPick}
                            style={{
                                backgroundColor: editorInfo.style[color.key],
                            }}
                            ref={refs[index] as any}
                            onClick={(e): void => {
                                setChoosingColor(index);
                                e.stopPropagation();
                            }}
                        />
                    </div>
                )
            )}
        </div>
    );
}
export default StyleEditor;
