import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import { fetchVisualizationsTabs } from '../../../controllers/DashboardController/DashboardService';
import { Visualization } from '../../../@Types/Metabase';
import { prodesaAnaliticaConfig } from '../prodesaAnalitycConfg';

function a11yProps(index: any): Record<string, any> {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        backgroundColor: 'transparent',
        color: 'var(--secondary)',
        boxShadow: 'none',
        '& .Erk-MuiTabs-indicator': {
            backgroundColor: 'var(--secondary)',
        },
        marginTop: 'auto',
        marginBottom: 'auto',
    },
}));
interface TabsProps {
    /** Function called when the agent has been created */
    setVisualization: Function;
}
export default function ScrollableTabsButtonAuto({
    setVisualization,
}: TabsProps): JSX.Element {
    const classes = useStyles();
    const [currentTab, setCurrentTab] = React.useState(0);
    const idProject = useSelector((state: RootState) => state.site.idProject);
    const idOrganization = useSelector(
        (state: RootState) => state.site.organization?.idOrganization
    );
    const genericDashboard: Visualization = {
        title: 'Control de Casos',
        type: 'GenericDashboard',
        id: null,
        public: false,
        description: null,
    };
    const [options, setOptions] = React.useState<Visualization[]>([]);

    async function getVisualizationsAsync(): Promise<void> {
        try {
            if (idProject) {
                const visualizations = await fetchVisualizationsTabs(idProject);
                let copyVisualizations: Visualization[] = [...visualizations];
                if (idOrganization === 'prodesa') {
                    const visualizationsR: Visualization[] = [];
                    const visualitionsQuee: Visualization[] = [];
                    const mapCompounded: Record<string, true> = {};
                    for (let i = 0; i < visualizations.length; i++) {
                        const visualization = visualizations[i];
                        if (visualization.id !== null) {
                            const identifier =
                                prodesaAnaliticaConfig.idsMap?.[
                                    visualization.id
                                ];
                            if (identifier) {
                                const visualConfg =
                                    prodesaAnaliticaConfig.tabs[identifier];
                                const type = visualConfg.type;
                                switch (type) {
                                    case 'CompoundedDashboard':
                                        if (!mapCompounded[identifier]) {
                                            mapCompounded[identifier] = true;
                                            visualizationsR.push({
                                                ...visualization,
                                                title: visualConfg.title as any,
                                                type: 'CompoundedDashboard',
                                                ids: visualConfg.ids ?? {},
                                                idsOrder:
                                                    visualConfg.idsOrder ?? [],
                                                order: visualConfg.order,
                                            });
                                        }
                                        break;
                                    default:
                                        visualizationsR.push({
                                            ...visualization,
                                            title: visualConfg.title as any,
                                            order: visualConfg.order,
                                        });
                                }
                            } else {
                                visualitionsQuee.push(visualization);
                            }
                        }
                    }
                    visualizationsR.sort((a, b) => {
                        if (a.order && b.order) {
                            return a.order - b.order;
                        }
                        if (a.order) {
                            return -1;
                        }
                        if (b.order) {
                            return 1;
                        }
                        return 0;
                    });
                    copyVisualizations = visualizationsR;
                    copyVisualizations.push(...visualitionsQuee);
                    copyVisualizations.push(genericDashboard);
                } else {
                    copyVisualizations.push(genericDashboard);
                    copyVisualizations.sort((a) => {
                        if (a.title.toLowerCase().includes('tablero')) {
                            return -1;
                        } else {
                            return 1;
                        }
                    });
                }
                setOptions(copyVisualizations);
                setCurrentTab(0);
                setVisualization(copyVisualizations[0]);
            }
        } catch (error) {
            console.error(error);
            setOptions([genericDashboard]);
            setVisualization(genericDashboard);
        }
    }

    useEffect(() => {
        setVisualization(undefined);
        setOptions([]);
        setCurrentTab(0);
        getVisualizationsAsync();
    }, [idProject]);

    return (
        <AppBar position="static" classes={classes}>
            <Tabs
                value={currentTab}
                onChange={(
                    event: React.ChangeEvent<{}>,
                    newTab: number
                ): void => {
                    setCurrentTab(newTab);
                    const visualization = options[newTab];
                    if (visualization) {
                        setVisualization(visualization);
                    }
                }}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
            >
                {options.map((option, index) => (
                    <Tab
                        label={option.title}
                        {...a11yProps(index)}
                        key={index}
                    />
                ))}
            </Tabs>
        </AppBar>
    );
}
