import { Organization } from '../../../@Types/@Types';
import axiosInstance from '../../../AxiosAPI';

/**
 * Function that handles fetching all the info of the current organization
 * @returns the Organization
 */
export const loadOrganization = async (): Promise<Organization> => {
    let response = await axiosInstance.get('/organization');
    if (response?.data?.toString()?.startsWith('<!doctype html>')) {
        response = await axiosInstance.get(
            '/organization?timeStamp=' + new Date().toString()
        );
    }
    return response.data;
};

/**
 * Function that handles fetching all the info of the current organization for the org page
 * @returns the Organization
 */
export const fetchOrganization = async (): Promise<Organization> => {
    let response = await axiosInstance.get('/organization/info');
    return response.data;
};

export const updateOrganization = async (
    organization: Partial<Organization>
): Promise<void> => {
    await axiosInstance.patch('/organization', organization);
};

export const updateOrganizationIcon = async (file: File): Promise<void> => {
    const formData = new FormData();
    if (file.type.includes('image')) {
        formData.append('img', file, file.name);
    } else {
        throw new Error('File is not an image');
    }
    await axiosInstance.patch('/organization/icon', formData);
};

export const updateOrganizationLogo = async (file: File): Promise<void> => {
    const formData = new FormData();
    if (file.type.includes('image')) {
        formData.append('img', file, file.name);
    } else {
        throw new Error('File is not an image');
    }
    await axiosInstance.patch('/organization/logo', formData);
};

export default {
    fetchOrganization,
    updateOrganization,
    updateOrganizationIcon,
    updateOrganizationLogo,
};
