import styles from './IntegrationSuccesAction.module.css';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import React from 'react';

interface IntegrationSuccessActionProps {
    message: string;
    title: string;
}

function IntegrationSuccessAction({
    message,
    title,
}: IntegrationSuccessActionProps): JSX.Element {
    return (
        <div className={styles.container}>
            <div className={styles.messageContainer} title={title}>
                <div className={styles.iconContainer}>
                    <CheckCircleRoundedIcon fontSize="inherit" />
                </div>
                <label className={styles.messageLbl}>{message}</label>
            </div>
        </div>
    );
}
export default IntegrationSuccessAction;
