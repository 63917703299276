import React from 'react';
import { InfoDocumentStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import InformativeTypePicker from '../InformativeTypePicker/InformativeTypePicker';
import InformativeFileComponent from '../InformativeFilePicker/InformativeFilePicker';

interface InfoDocumentStepProps {
    step: InfoDocumentStep;
}

function InfoDocumentStepComponent({
    step,
}: InfoDocumentStepProps): JSX.Element {
    return (
        <React.Fragment>
            <InformativeTypePicker step={step} />
            <InformativeFileComponent
                idStep={step.id}
                file={step.file}
                maxSize={100}
                defaultFile={{
                    fileName: 'Capta.pdf',
                    url: '/Capta.pdf',
                }}
            />
        </React.Fragment>
    );
}

export default InfoDocumentStepComponent;
