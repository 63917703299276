import React from 'react';
import styles from './CommunicationNode.module.css';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import { Handle, Position } from 'react-flow-renderer';
import { CommunicationData } from '../../../../@Types/FlowTypes/NodeTypes/CommunicationNode';
import ChatIcon from '../../../../Icons/ChatIcon';
import NotificationIcon from '../../../../Icons/NotificationIcon';
import ActionTypes from '../../../../constants/ActionTypes';
interface CommunicationNodeProps {
    /** Node data needed to display the node */
    data: CommunicationData & {
        /** Function used to open the dialog  */
        openDialog: Function;
        error: boolean;
    };
}

function CommunicationNode({ data }: CommunicationNodeProps): JSX.Element {
    const hasError =
        data.type === ActionTypes.NOTIFICATION ||
        data.integration !== undefined;
    return (
        <>
            <Handle
                className={styles.leftHandle}
                type="target"
                position={Position.Left}
                style={{
                    top: '50%',
                    background: 'var(--primary1)',
                    border: '1px solid var(--accent)',
                }}
            />
            <div
                className={styles.container}
                style={
                    data.error
                        ? {
                              border: 'var(--error) 1px solid',
                              marginTop: '-1px',
                              marginLeft: '-1px',
                          }
                        : {}
                }
            >
                {data.type === ActionTypes.AUTO_REPLY ? (
                    <ChatIcon className={styles.icon} />
                ) : (
                    <NotificationIcon className={styles.icon} />
                )}
                <div
                    className={styles.config}
                    onClick={(): void => {
                        data.openDialog();
                    }}
                >
                    <SettingsRoundedIcon fontSize="inherit" />
                </div>
                <div className={styles.label}>{data.name}</div>
            </div>
            <Handle
                className={
                    hasError ? styles.rightHandleError : styles.rightHandle
                }
                type="source"
                position={Position.Right}
                style={{
                    top: hasError ? '30%' : '50%',
                    background: 'var(--primary1)',
                    border: '1px solid var(--accent)',
                }}
            />
            <Handle
                className={styles.errorHandle}
                type="source"
                position={Position.Right}
                id="ERROR"
                isConnectable={hasError}
                style={{
                    visibility: hasError ? 'visible' : 'hidden',
                    top: '70%',
                    background: 'var(--error)',
                    border: '1px solid var(--accent)',
                }}
            />
        </>
    );
}

export default CommunicationNode;
