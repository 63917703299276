import { Agent } from '../../../../@Types/@Types';
import styles from './Agent.module.css';
import Avatar from '../../../Avatar/Avatar';
interface AgentComponentProps {
    /** Agent to display */
    agent: Agent;
    /** Function to handle when agent is clicked on */
    handleClick: Function;
    /** If the current agent is the first one, to display the border-top */
    first: boolean;
    /** If the current agent is selected */
    selected: boolean;
    /** If multiple agents can be selected */
    multiple: boolean;
}
/**
 * Component used to display non-selected agents in the assign tab.
 */
function AgentComponent({
    agent,
    handleClick,
    first,
    selected,
    multiple,
}: AgentComponentProps): JSX.Element {
    if (agent) {
        return (
            <div
                className={styles.container}
                style={{
                    borderTop: first ? 'none' : '1px solid var(--light-grey)',
                    backgroundColor: selected ? 'var(--greyHover)' : '',
                }}
                onClick={(): void => {
                    handleClick();
                }}
            >
                <div
                    className={styles.avatarContainer}
                    style={{ marginLeft: multiple ? 30 : 10 }}
                >
                    <Avatar lbl={agent.name} img={agent.img} toolTip={false} />
                </div>
                <div className={styles.lblContainer}>
                    <label
                        className={styles.nameLbl}
                        style={{ fontWeight: selected ? 700 : 500 }}
                    >
                        {agent.name}
                    </label>
                    <label className={styles.emailContainer}>
                        {agent.email}
                    </label>
                </div>
            </div>
        );
    }
    return <div></div>;
}

export default AgentComponent;
