import { JSX } from 'react';
import styles from './TicketContent.module.css';
import { calcDate } from '../../../../../../../../../utils/DateFunctions';
import { Ticket } from '../../../../../../../../../@Types/@Types';

export interface TicketContentProps {
    label: string;
    ticket: Ticket;
}

function TicketContent({ label, ticket }: TicketContentProps): JSX.Element {
    return (
        <div className={styles.container}>
            <div className={styles.header}>{label}</div>
            <ul className={styles.content}>
                <div className={styles.row}>
                    <li className={styles.label}>Asunto:</li>
                    <div className={styles.value}>
                        {ticket.subject ? ticket.subject : 'Sin Asunto'}
                    </div>
                </div>
                <div className={styles.row}>
                    <li className={styles.label}>Vencimiento:</li>
                    <div className={styles.value}>
                        {calcDate(ticket.resolution_date, true)}
                    </div>
                </div>
            </ul>
        </div>
    );
}

export default TicketContent;
