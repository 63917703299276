import EntityPropertyTypes from '../constants/EntityPropertyTypes';
import FormStepIcon from './FormStepIcon';
import FormStepTypes from '../constants/FormStepTypes';
import TitleIcon from './TitleIcon';
import { EntityProperty } from '../@Types/EntityTypes/EntityProperty';
import { IconProps } from './@IconTypes';
import EntityIcon from '../shared/SmartIcons/EntityIcon';

interface EntityPropertyIconProps extends IconProps {
    property: EntityProperty;
}

function EntityPropertyIcon({
    property,
    ...props
}: EntityPropertyIconProps): JSX.Element {
    switch (property.type) {
        case EntityPropertyTypes.CHECKBOX:
            return <FormStepIcon type={FormStepTypes.CHECKBOX} {...props} />;
        case EntityPropertyTypes.AGENTPICKER:
            return <FormStepIcon type={FormStepTypes.AGENTPICKER} {...props} />;
        case EntityPropertyTypes.CLIENTPICKER:
            return (
                <FormStepIcon type={FormStepTypes.CLIENTPICKER} {...props} />
            );
        case EntityPropertyTypes.DATEPICKER:
            return <FormStepIcon type={FormStepTypes.DATEPICKER} {...props} />;
        case EntityPropertyTypes.TIMEPICKER:
            return <FormStepIcon type={FormStepTypes.TIMEPICKER} {...props} />;
        case EntityPropertyTypes.SELECTOR:
            return <FormStepIcon type={FormStepTypes.SELECTOR} {...props} />;
        case EntityPropertyTypes.FILEUPLOAD:
            return <FormStepIcon type={FormStepTypes.FILEUPLOAD} {...props} />;
        case EntityPropertyTypes.MAPPER:
            return <FormStepIcon type={FormStepTypes.MAPPER} {...props} />;
        case EntityPropertyTypes.SEPARATOR:
            return <FormStepIcon type={FormStepTypes.SEPARATOR} {...props} />;
        case EntityPropertyTypes.TEXTINPUT:
            return <FormStepIcon type={FormStepTypes.TEXTINPUT} {...props} />;
        case EntityPropertyTypes.TEXTAREA:
            return <FormStepIcon type={FormStepTypes.TEXTAREA} {...props} />;
        case EntityPropertyTypes.NAME:
        case EntityPropertyTypes.TITLE:
            return <TitleIcon {...props} />;
        case EntityPropertyTypes.ENTITY_RELATIONSHIP:
            return <EntityIcon {...props} idEntity={property.idEntity} />;
        default:
            return <></>;
    }
}
export default EntityPropertyIcon;
