import React, { useState } from 'react';
import styles from './CreateProject.module.css';
import RoundedTextField from '../../../RoundedTextField/RoundedTextField';
import RoundedButton from '../../../RoundedButton/RoundedButton';
import { Controller, useForm } from 'react-hook-form';
import projectService from '../../../../controllers/_SiteController/Services/ProjectsService';

interface ComponentProps {
    /**  Function called on submit/cancel */
    close: Function;
    /** Function called on project created */
    handleProjectCreated: Function;
}
/**
 * Create Project dialog
 */
function CreateProject({
    close,
    handleProjectCreated,
}: ComponentProps): JSX.Element {
    const [loading, setLoading] = useState(false);

    const {
        getValues,
        control,
        formState: { errors, isDirty, isValid },
        trigger,
    } = useForm({
        mode: 'onChange',
    });

    /**
     * Function called to handle the button click in the dialog
     * calls and waits for the service to finish.
     */
    const handleSubmit = async (): Promise<void> => {
        trigger();
        const values = getValues();
        if (isValid && isDirty) {
            try {
                setLoading(true);
                const newProject = await projectService.createProject(values);
                close();
                handleProjectCreated();
                location.href = `/${newProject._id}/tickets`;
            } catch (error) {
                //TODO handle errors
                console.error(error);
                setLoading(false);
            }
        }
    };

    return (
        <div className={styles.container + ' standard-dialog'}>
            <h2 className="center-anything">Crear Ambiente</h2>
            <div className="text-field-container">
                <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: 'El nombre es obligatorio',
                    }}
                    render={({ field }): JSX.Element => (
                        <RoundedTextField
                            {...field}
                            label="Nombre"
                            helperText={errors.name ? errors.name.message : ''}
                            error={!!errors.name}
                        />
                    )}
                />
            </div>
            <div className="text-field-container">
                <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: 'La descripción es obligatoria',
                    }}
                    render={({ field }): JSX.Element => (
                        <RoundedTextField
                            {...field}
                            label="Descripción"
                            multiline
                            helperText={
                                errors.description
                                    ? errors.description.message
                                    : ''
                            }
                            error={!!errors.description}
                        />
                    )}
                />
            </div>
            <div className="center-anything">
                <div className="button">
                    <RoundedButton
                        text="Cancelar"
                        onClick={(): void => {
                            close();
                        }}
                    />
                </div>
                <div className="button">
                    <RoundedButton
                        text="Crear Ambiente"
                        disabled={loading}
                        onClick={handleSubmit}
                    />
                </div>
            </div>
        </div>
    );
}

export default CreateProject;
