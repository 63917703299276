import styles from './Option.module.css';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { OptionTypes } from '../../../../constants/OptionTypes';
import RoundedTextField from '../../../RoundedTextField/RoundedTextField';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import React from 'react';
import NestedOption from './NestedOption/NestedOption';
import { StepComponentProps } from '../../../GenericFormEditor/Step/Step';
import {
    GLocation,
    SelectorOptionLocation,
} from '../../../../@Types/FormTypes/LocationTypes';
import { GSelector, SelectorOption } from '../../../../@Types/GenericFormSteps';
import FormConditionComponent from '../../../../pages/Forms/FormCondition/FormCondition';

export interface OptionComponentProps<
    S extends GSelector,
    U,
    L extends GLocation
> extends Omit<StepComponentProps<S, U, L>, 'location'> {
    option: SelectorOption;
    updateOption: Function;
    location: SelectorOptionLocation;
    handleDeleteOption: Function;
    handleParentClose?: Function;
}
function OptionComponent<S extends GSelector, U, L extends GLocation>({
    updateOption,
    handleDeleteOption,
    option,
    ...others
}: OptionComponentProps<S, U, L>): JSX.Element {
    return (
        <React.Fragment>
            <div className={styles.container}>
                <div className={styles.bullet} />
                <div className={styles.containerLbl}>
                    <RoundedTextField
                        label=""
                        value={option.label}
                        onChange={(e): void => {
                            updateOption({
                                ...option,
                                label: e.target.value,
                                value: e.target.value,
                            });
                        }}
                    ></RoundedTextField>
                </div>
                <div className={styles.btnsContainer}>
                    <div
                        className={styles.addBtn}
                        onClick={(): void => {
                            if (option.type === OptionTypes.DEFAULT) {
                                updateOption({
                                    ...option,
                                    type: OptionTypes.NESTED,
                                    steps: [],
                                });
                            } else {
                                updateOption({
                                    ...option,
                                    steps: undefined,
                                    type: OptionTypes.DEFAULT,
                                });
                            }
                        }}
                        title={
                            option.type === OptionTypes.DEFAULT
                                ? 'Agregar subpasos'
                                : 'Eliminar subpasos'
                        }
                        style={
                            option?.type === OptionTypes.NESTED
                                ? { color: 'var(--secondary)' }
                                : {}
                        }
                    >
                        <AddRoundedIcon fontSize="inherit" />
                    </div>
                    <FormConditionComponent
                        condition={option?.condition}
                        handleUpdate={(condition): void =>
                            updateOption({
                                ...option,
                                condition,
                            })
                        }
                        size={26}
                        iconSize={16}
                        borderRadius={5}
                        idStep={others.step.id}
                    />
                    <div
                        className={styles.deleteBtn}
                        onClick={(): void => {
                            handleDeleteOption();
                        }}
                    >
                        <DeleteRoundedIcon fontSize="inherit" />
                    </div>
                </div>
            </div>
            <div className={styles.contentContainer}>
                <OptionMapper
                    {...others}
                    option={option}
                    updateOption={updateOption}
                    handleDeleteOption={handleDeleteOption}
                />
            </div>
        </React.Fragment>
    );
}

export default OptionComponent;

function OptionMapper<S extends GSelector, U, L extends GLocation>(
    props: OptionComponentProps<S, U, L>
): JSX.Element {
    switch (props.option.type) {
        case OptionTypes.DEFAULT: {
            return <div></div>;
        }
        case OptionTypes.NESTED: {
            return <NestedOption {...props} />;
        }
        default:
            return <div></div>;
    }
}
