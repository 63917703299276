import React, { useMemo } from 'react';
import {
    ConnectionLineComponentProps,
    getBezierPath,
} from 'react-flow-renderer';
import FlowNodeTypes from '../../../../constants/Flows/FlowNodeTypes';
interface DefaultConnectionLineProps {
    id?: string;
    allowedConnection: boolean;
}
function DefaultConnectionLine({
    id = 'CURSOR',
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourceNode,
    sourceHandle,
    sourcePosition,
    targetPosition,
    allowedConnection = true,
}: ConnectionLineComponentProps & DefaultConnectionLineProps): JSX.Element {
    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    const isError = useMemo(
        (): boolean =>
            sourceNode?.type === FlowNodeTypes.COMMUNICATION &&
            sourceHandle?.id === 'ERROR',
        [sourceNode?.type, sourceHandle?.id]
    );

    return (
        <g>
            <path
                id={id}
                className="flowPath"
                d={edgePath}
                style={{
                    stroke: isError ? 'var(--error)' : 'var(--accent)',
                }}
            />
            {!allowedConnection && (
                <text dy={9}>
                    <textPath
                        href={`#${id}`}
                        style={{
                            fontSize: '25px',
                            fontWeight: 'bold',
                            fill: 'var(--error)',
                        }}
                        startOffset="50%"
                        textAnchor="middle"
                    >
                        X
                    </textPath>
                </text>
            )}
            <circle
                cx={targetX}
                cy={targetY}
                fill="var(--primary1)"
                r={3}
                stroke="var(--accent)"
                strokeWidth={1}
            />
        </g>
    );
}
export default DefaultConnectionLine;
