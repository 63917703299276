import React, { useEffect, useState } from 'react';
import styles from './LoaderBottom.module.css';
import { LinearProgress } from '@material-ui/core';
import ActionsService from '../../../../../../../../controllers/TicketsController/Actions/ActionsService';
import { useDispatch } from 'react-redux';
import * as RepliesCache from '../../../../../../../../utils/ReplyCache';
import { removeAction } from '../../../../../../../../controllers/TicketsController/Actions/ActionsActions';
import VanillaToast from '../../../../../../../../shared/Toast/Toast';
import { TempReplyAction } from '../../../../../../../../@Types/Action';
import CancelIcon from '@material-ui/icons/CloseRounded';
import RoundedIconButton from '../../../../../../../../shared/RoundedIconButton/RoundedIconButton';
import CustomTimeout from '../../../../../../../../utils/CustomTimeout';
import ConfirmDialog from '../../../../../../../../shared/ConfirmDialog/ConfirmDialog';
import RoundedPayload from '../../../../../../../../shared/RoundedPayload/RoundedPayload';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { loadTicketById } from '../../../../../../../../controllers/TicketsController/TicketsService';
import { refreshSuccess } from '../../../../../../../../controllers/TicketsController/TicketsActions';
import { Ticket } from '../../../../../../../../@Types/@Types';
import { PayloadEditorActions } from '../../../../../../../../controllers/PayloadEditorController/PayloadEditorSlice';

interface LoaderBottomProps {
    element: TempReplyAction;
    timeout: CustomTimeout;
    refreshCache: Function;
    ticket: Ticket;
}

export default function LoaderBottom({
    element,
    ticket,
    timeout,
    refreshCache,
}: LoaderBottomProps): JSX.Element {
    const { idProject, idTicket, _id } = element;
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const [openSendNow, setOpenSendNow] = useState(false);
    const dispatch = useDispatch();
    const [timeLeft, setTimeLeft] = useState(timeout.timeLeft());
    const getProgress = (timeLeft: number): number =>
        (1 - timeLeft / timeout.originalDelay) * 100;
    let idInterval: any;
    const handleSendNowClick = (): void => {
        timeout.pause();
        clearInterval(idInterval);
        setOpenSendNow(true);
    };

    const handleConfirmSendNow = async (
        _element: any,
        setDialogLoading: Function
    ): Promise<void> => {
        setDialogLoading(true);
        await timeout.executeNow(500);
        const newTicket = await loadTicketById(ticket.idProject, ticket._id);
        dispatch(refreshSuccess(newTicket));
        setDialogLoading(false);
        refreshCache();
    };

    const handleCancelSendNow = (): void => {
        timeout.resume();
        setOpenSendNow(false);
    };

    const handleDeleteClick = async (): Promise<void> => {
        timeout.pause();
        clearInterval(idInterval);
        setOpenDeleteConfirmation(true);
    };

    const handleConfirmDeleteClick = async (
        _element: any,
        setDialogLoading: Function
    ): Promise<void> => {
        setDialogLoading(true);
        try {
            await ActionsService.deleteTempReply(idProject, idTicket, _id);
            RepliesCache.removeReply(idProject, idTicket);
            dispatch(removeAction(_id));
            dispatch({
                ...PayloadEditorActions.reset(element.payload),
                meta: { idEditor: ticket._id },
            });
            setDialogLoading(false);
            setOpenDeleteConfirmation(false);
        } catch (error) {
            setDialogLoading(false);
            timeout.resume();
            VanillaToast.create({
                title: 'Failed to delete',
                text: error,
                type: 'error',
                timeout: 3000,
            });
        }
    };

    const handleCancelDeleteClick = (): void => {
        timeout.resume();
        setOpenDeleteConfirmation(false);
    };

    useEffect(() => {
        idInterval = setInterval(() => {
            const tl = timeout.timeLeft() - 300;
            setTimeLeft(tl);
            if (!timeout.isActive()) {
                refreshCache();
                clearInterval(idInterval);
            }
        }, 200);
        return (): void => {
            refreshCache();
            clearInterval(idInterval);
        };
    }, []);

    return (
        <div className={styles.container}>
            {openDeleteConfirmation && (
                <ConfirmDialog
                    element={element}
                    onClose={handleCancelDeleteClick}
                    onConfirm={handleConfirmDeleteClick}
                    title={'Descartar Respuesta'}
                    deleting
                    btnMsg="Descartar"
                    msg={'¿Está seguro que desea descartar su respuesta?'}
                >
                    <div
                        style={{
                            marginBottom: '15px',
                        }}
                    >
                        <RoundedPayload
                            isResponse
                            payload={element.payload}
                            date={element.creation_date}
                            name={element.agent.name}
                        />
                    </div>
                </ConfirmDialog>
            )}
            {openSendNow && (
                <ConfirmDialog
                    element={element}
                    onClose={handleCancelSendNow}
                    onConfirm={handleConfirmSendNow}
                    title={'Enviar Ahora'}
                    msg={'¿Está seguro que desea enviar su respuesta?'}
                    btnMsg={'Enviar'}
                >
                    <div
                        style={{
                            marginBottom: '15px',
                        }}
                    >
                        <RoundedPayload
                            isResponse
                            payload={element.payload}
                            date={element.creation_date}
                            name={element.agent.name}
                        />
                    </div>
                </ConfirmDialog>
            )}
            {timeLeft >= 0 ? (
                <React.Fragment>
                    <div className={styles.loader}>
                        <LinearProgress
                            variant="determinate"
                            value={getProgress(timeLeft + 400)}
                            classes={{
                                barColorPrimary: styles.loaderLine,
                                colorPrimary: styles.loaderFont,
                            }}
                        />
                    </div>
                    <div className={styles.sendNow} title="Enviar ahora">
                        <RoundedIconButton
                            color="var(--accent)"
                            backgroundColor="var(--defaultGrey)"
                            onClick={handleSendNowClick}
                            hoverColor="white"
                            hoverBackgroundColor="var(--secondary)"
                            padding={'3px'}
                        >
                            <CheckRoundedIcon
                                style={{
                                    width: 14,
                                    height: 14,
                                }}
                            />
                        </RoundedIconButton>
                    </div>
                    <div className={styles.cancel} title="Cancelar Envio">
                        <RoundedIconButton
                            color="var(--accent)"
                            backgroundColor="var(--defaultGrey)"
                            onClick={handleDeleteClick}
                            hoverColor="white"
                            hoverBackgroundColor="var(--secondary)"
                        >
                            <CancelIcon
                                style={{
                                    width: 14,
                                    height: 14,
                                    margin: '-1px',
                                }}
                            />
                        </RoundedIconButton>
                    </div>
                </React.Fragment>
            ) : (
                <div className={styles.loader}>
                    <LinearProgress
                        variant="indeterminate"
                        classes={{
                            barColorPrimary: styles.loaderLine,
                            colorPrimary: styles.loaderFont,
                        }}
                    />
                </div>
            )}
        </div>
    );
}
