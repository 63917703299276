import styles from './Classifier.module.css';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../utils/_store';
import { SearchClassifier } from '../../ClassifyMenu';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import React, { useState } from 'react';
interface ClassifierProps {
    /** Classifier to show */
    classifier: { idRoot: string; idValue: string | null };
    /** Function to call when the classifier is clicked */
    handleClick: Function;
    /** Function to call when the user wants to remove the classifier */
    onRemove?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    /** If the classifier is the last one on the list */
    last: boolean;
    /** The list of currently displayed classifiers */
    classifiers: Record<string, SearchClassifier>;
    /** The current search value */
    searching: boolean;
    /** the value of current search */
    search: string | undefined;
}
/**
 * Component that displays a classifier in the classifier list
 */
function ClassifierComponent({
    handleClick,
    onRemove,
    classifier,
    last,
    classifiers,
    searching,
    search,
}: ClassifierProps): JSX.Element {
    const siteInfo = useSelector((state: RootState) => state.site);
    const root = classifiers[classifier.idRoot];
    const [hovering, setHovering] = useState(false);
    let value = null;

    if (classifier.idValue) {
        value = classifiers[classifier.idValue];
        /** If value is not in the classifiers sent by the parent search for it in the siteInfo */
        if (!value) {
            value = { ...siteInfo.classifiers[classifier.idValue] };
            /** Add search to value */
            if (search) {
                const regex = new RegExp(search, 'gi');
                if (regex.test(value.name)) {
                    value.name = value.name.replace(
                        regex,
                        (match) => `<SEARCH:${match}>`
                    );
                }
                if (regex.test(value.description)) {
                    value.description = value.description.replace(
                        regex,
                        (match) => `<SEARCH:${match}>`
                    );
                }
            }
        }
    }

    /**
     * Function that renders the text with the search highlighted
     * @returns a string with span highlighted
     */
    const renderText = (text: string): any => {
        if (text) {
            var parts: any = text.split(/<SEARCH:(.*?)>/gi);
            for (var i = 1; i < parts.length; i += 2) {
                parts[i] = (
                    <span className={styles.search} key={i}>
                        {parts[i]}
                    </span>
                );
            }
            return parts;
        }
    };

    return (
        <div
            className={styles.container}
            onMouseEnter={(): void => {
                setHovering(true);
            }}
            onMouseLeave={(): void => {
                setHovering(false);
            }}
            onClick={(): void => {
                handleClick();
            }}
            style={{
                borderBottom: last ? 'none' : '1px solid var(--light-grey)',
            }}
        >
            <div className={styles.top}>
                <div
                    className={styles.colorIcon}
                    style={{ backgroundColor: root.color?.background }}
                ></div>
                <label
                    className={styles.nameLbl}
                    title={
                        value !== null && root._id !== value._id
                            ? root.name + ': ' + value.name
                            : root.name
                    }
                >
                    <span className={styles.rootLbl}>
                        {renderText(root.name)}
                    </span>

                    {value !== null && value._id !== root._id && (
                        <React.Fragment>
                            <span className={styles.dotsLbl}>: </span>
                            <span className={styles.valueLbl}>
                                {renderText(value.name)}
                            </span>
                        </React.Fragment>
                    )}
                </label>
                {searching && root.hasSearchInChild && (
                    <div className={styles.searchSymbol}>
                        <SearchRoundedIcon fontSize="inherit" />
                    </div>
                )}
                {value !== null && onRemove && (
                    <div className={styles.closeIcon} onClick={onRemove}>
                        {hovering ? (
                            <CloseRoundedIcon fontSize="inherit" />
                        ) : (
                            <CheckRoundedIcon fontSize="inherit" />
                        )}
                    </div>
                )}
            </div>
            {root.description?.trim() !== '' && (
                <label className={styles.bottom} title={root.description}>
                    {renderText(root.description)}
                </label>
            )}
        </div>
    );
}

export default ClassifierComponent;
