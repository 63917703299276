import styles from './CreatableStep.module.css';
import React, { useContext } from 'react';
import { CreatableStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import { useDispatch, useSelector } from 'react-redux';
import { updateStep } from '../../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import RoundedTextField from '../../../../../../shared/RoundedTextField/RoundedTextField';
import RoundedSelect from '../../../../../../shared/RoundedSelect/RoundedSelect';
import { RootState } from '../../../../../../utils/_store';
import Toggle from '../../../../../../shared/Toggle/Toggle';
import { MenuItem } from '@material-ui/core';
import RoundedConversationStepPicker from '../../../../../../shared/@Pickers/RoundedConversationStepPicker/RoundedConversationStepPicker';
import { calcSubSteps } from '../../../StepFunctions';
import PayloadEditor from '../../../../../../shared/TextEditor/PayloadEditor';
import {
    ApiKeyContext,
    BaseConversationPayloadConditionTypes,
} from '../../../ConversationEditor';
import { useCurrentProject } from '../../../../../../hooks';
import { TextEditorTypes } from '../../../../../../constants/TextEditorTypes';

interface CreatableStepProps {
    step: CreatableStep;
}

function CreatableStepComponent({ step }: CreatableStepProps): JSX.Element {
    const dispatch = useDispatch();
    const apiKey = useContext(ApiKeyContext);
    const project = useCurrentProject();
    const steps = useSelector(
        (state: RootState) => state.conversationEditor.steps
    );
    const previousSteps = useSelector(
        (state: RootState) =>
            state.conversationEditor.stepDependencies[step.id ?? '']
                .previousSteps
    );
    return (
        <React.Fragment>
            <div className={styles.headerContainer}>
                <RoundedTextField
                    label="Encabezado"
                    multiline
                    maxLength={60}
                    value={step.header}
                    onChange={(e): void => {
                        dispatch(
                            updateStep({ ...step, header: e.target.value })
                        );
                    }}
                ></RoundedTextField>
            </div>
            <div className={styles.container}>
                <div className={styles.editorContainer}>
                    <PayloadEditor
                        placeholder={'Mensaje'}
                        type={TextEditorTypes.WHATSAPP}
                        idEditor={`Conversation-${apiKey}-${step.id}`}
                        payload={{ draft: step.message }}
                        onChange={({ draft: message }): void => {
                            if (!message) return;
                            dispatch(updateStep({ ...step, message }));
                        }}
                        media={false}
                        context={{ client: true }}
                        conditions={{
                            types: BaseConversationPayloadConditionTypes,
                            context: {
                                conversation: {
                                    idStep: step.id,
                                },
                            },
                        }}
                        minHeight={144}
                        maxHeight={400}
                        required
                        maxLength={4096}
                    />
                </div>
            </div>
            <div className={styles.footerContainer}>
                <RoundedTextField
                    label="Pie"
                    multiline
                    maxLength={60}
                    value={step.footer}
                    onChange={(e): void => {
                        dispatch(
                            updateStep({ ...step, footer: e.target.value })
                        );
                    }}
                ></RoundedTextField>
            </div>
            <div className={styles.btnLblContainer}>
                <RoundedTextField
                    label="Botón de Agregar"
                    value={step.addBtnLabel}
                    maxLength={20}
                    onChange={(e): void => {
                        dispatch(
                            updateStep({
                                ...step,
                                addBtnLabel: e.target.value,
                            })
                        );
                    }}
                ></RoundedTextField>
            </div>
            <div className={styles.btnLblContainer}>
                <RoundedTextField
                    label="Botón de Finalizar"
                    value={step.endBtnLabel}
                    maxLength={20}
                    onChange={(e): void => {
                        dispatch(
                            updateStep({
                                ...step,
                                endBtnLabel: e.target.value,
                            })
                        );
                    }}
                ></RoundedTextField>
            </div>
            <div className={styles.maxToggleContainer}>
                <div className={styles.maxToggleLabel}>
                    Restringir Cantidad:
                </div>
                <div className={styles.toggleContainer}>
                    <Toggle
                        checked={step.max !== undefined}
                        onChange={(checked: boolean): void => {
                            dispatch(
                                updateStep({
                                    ...step,
                                    max: checked ? 1 : undefined,
                                })
                            );
                        }}
                    />
                </div>
            </div>
            {step.max !== undefined && (
                <div className={styles.maxInputContainer}>
                    <RoundedTextField
                        label="Cantidad Máxima"
                        value={step.max}
                        onChange={(e): void => {
                            const value = parseInt(e.target.value);
                            if (e.target.value === '') {
                                dispatch(
                                    updateStep({ ...step, max: '' as any })
                                );
                            } else {
                                if (value < 1) return;
                                dispatch(updateStep({ ...step, max: value }));
                            }
                        }}
                        focusColor={
                            (step.max as any) === '' || step.max === 0
                                ? 'var(--error)'
                                : 'var(--secondary)'
                        }
                        error={(step.max as any) === '' || step.max === 0}
                        type="number"
                    />
                </div>
            )}
            {project.concepts?.length > 0 && (
                <div className={styles.conceptsContainer}>
                    <div className={styles.toggleCont}>
                        <div className={styles.conceptLabel}>Concepto:</div>
                        <div className={styles.toggleContainer}>
                            <Toggle
                                size="small"
                                checked={step.concept !== undefined}
                                onChange={(checked: boolean): void => {
                                    dispatch(
                                        updateStep({
                                            ...step,
                                            concept: checked
                                                ? {
                                                      idConcept:
                                                          project.concepts[0]
                                                              .id,
                                                      mappings: {},
                                                  }
                                                : undefined,
                                        })
                                    );
                                }}
                            />
                        </div>
                    </div>
                    {step.concept && (
                        <React.Fragment>
                            <div className={styles.conceptPickerContainer}>
                                <RoundedSelect
                                    minWidth={210}
                                    height="31px"
                                    value={step.concept.idConcept}
                                    label="Concepto"
                                    containerMargin="0px"
                                    handleUpdate={(event): void => {
                                        if (step.concept) {
                                            dispatch(
                                                updateStep({
                                                    ...step,
                                                    concept: {
                                                        ...step.concept,
                                                        idConcept:
                                                            event.target.value,
                                                    },
                                                })
                                            );
                                        }
                                    }}
                                >
                                    {project.concepts.map((concept) => (
                                        <MenuItem
                                            value={concept.id}
                                            key={concept.id}
                                        >
                                            {concept.name}
                                        </MenuItem>
                                    ))}
                                </RoundedSelect>
                            </div>
                            {((): JSX.Element => {
                                const concept = project.concepts.find(
                                    (concept) =>
                                        concept.id === step.concept?.idConcept
                                );
                                const mappings = step.concept!.mappings;
                                if (!concept) return <div></div>;
                                return (
                                    <div
                                        className={styles.conceptPropContainer}
                                    >
                                        {concept.baseSteps.map(
                                            (idStep, index) => {
                                                const subStep =
                                                    concept.steps[idStep];
                                                if (!subStep)
                                                    return <div></div>;
                                                return (
                                                    <RoundedConversationStepPicker
                                                        key={index}
                                                        minWidth={200}
                                                        containerMargin="8px 0px"
                                                        stepOrder={[
                                                            ...(previousSteps ??
                                                                []),
                                                            ...calcSubSteps(
                                                                step.id,
                                                                steps
                                                            ),
                                                        ]}
                                                        label={
                                                            (subStep as any)
                                                                .label
                                                        }
                                                        height="31px"
                                                        value={
                                                            mappings[subStep.id]
                                                        }
                                                        handleUpdate={(
                                                            pStep
                                                        ): void => {
                                                            dispatch(
                                                                updateStep({
                                                                    ...step,
                                                                    concept: {
                                                                        ...step.concept!,
                                                                        mappings:
                                                                            {
                                                                                ...mappings,
                                                                                [subStep.id]:
                                                                                    pStep.id,
                                                                            },
                                                                    },
                                                                })
                                                            );
                                                        }}
                                                    />
                                                );
                                            }
                                        )}
                                    </div>
                                );
                            })()}
                        </React.Fragment>
                    )}
                </div>
            )}
        </React.Fragment>
    );
}

export default CreatableStepComponent;
