import EurekaForm from '../../../../../shared/FormRenderer/FormRenderer';
import { FormStyleTypes } from '@arquimedes.co/eureka-forms/dist/constants/FormStepTypes';
import FormStepTypes from '../../../../../constants/FormStepTypes';
import { ClienteInfo360 } from '../../../types';
import React from 'react';
import { OpenComponent } from '../../Utils/Openable/OpenComponent';
import { empStrOrUndToNA } from '../../Utils/otherFunctions';

interface EducacionProps {
    educacion: ClienteInfo360['educacion'];
}

export function Educacion({ educacion }: EducacionProps): JSX.Element {
    return (
        <OpenComponent title={'Educacion'}>
            <EurekaForm
                form={{
                    size: {
                        blockNum: 4,
                        blockSize: 210,
                        spacingSize: 20,
                    },
                    terms: [],
                    isStandAlone: false,
                    sections: {
                        SECTION: {
                            name: '',
                            nextSection: null,
                            id: 'SECTION',
                            steps: [
                                'TEXTINPUT-f_aDPZbcrJ',
                                'TEXTINPUT-SDxR3ZMEi8',
                                'TEXTINPUT-7yRl4e64aL',
                            ],
                        },
                    },
                    firstSection: 'SECTION',
                    name: 'Educacion',
                    style: {
                        backgroundColor: '#ffffff',
                        errorColor: '#cc2936',
                        standAloneBackgroundColor: '#ffffff',
                        primaryColor: '#2cb5b9',
                        outlineColor: '#b8b8b8',
                        secondaryContrastColor: '#ea7a15',
                        type: FormStyleTypes.MATERIAL,
                        descriptionTextColor: '#989898',
                        stepBackgroundColor: '#ffffff',
                        textColor: '#293241',
                        primaryContrastColor: '#ffffff',
                        secondaryColor: '#376e70',
                    },
                    hasCaptcha: false,
                    steps: {
                        'TEXTINPUT-7yRl4e64aL': {
                            size: 2,
                            description: '',
                            isSubject: false,
                            id: 'TEXTINPUT-7yRl4e64aL',
                            label: 'Nivel de Estudios',
                            type: FormStepTypes.TEXTINPUT,
                            required: false,
                        },
                        'TEXTINPUT-SDxR3ZMEi8': {
                            size: 2,
                            description: '',
                            isSubject: false,
                            id: 'TEXTINPUT-SDxR3ZMEi8',
                            label: 'Profesion',
                            type: FormStepTypes.TEXTINPUT,
                            required: false,
                        },
                        'TEXTINPUT-f_aDPZbcrJ': {
                            size: 2,
                            description: '',
                            isSubject: false,
                            id: 'TEXTINPUT-f_aDPZbcrJ',
                            label: 'Ocupacion',
                            type: FormStepTypes.TEXTINPUT,
                            required: false,
                        },
                    },
                }}
                values={{
                    'TEXTINPUT-7yRl4e64aL': empStrOrUndToNA(
                        educacion.nivelEstudio
                    ),
                    'TEXTINPUT-SDxR3ZMEi8': empStrOrUndToNA(
                        educacion.profesion
                    ),
                    'TEXTINPUT-f_aDPZbcrJ': empStrOrUndToNA(
                        educacion.ocupacion
                    ),
                }}
                postview={true}
            />
        </OpenComponent>
    );
}
