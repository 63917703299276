import { StepComponentProps } from '../../Step';
import styles from './ButtonsStep.module.css';
import React, { useRef, useState } from 'react';
import StepContainer from '../../StepContainer/StepContainer';
import DraftRenderer from '../../../../../../shared/DraftRenderer/DraftRenderer';
import { ButtonsStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import { EditorState, convertFromRaw } from 'draft-js';
import ClientReply from '../../ClientReply/ClientReply';
import { Menu, MenuItem } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../utils/_store';
import StepComponent from '../../Step';
import { LocationTypes } from '../../../../../../constants/Conversations/ConversationStepTypes';
import {
    updateStep,
    selectStep,
} from '../../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import AddStep from '../../../AddStep/AddStep';
interface ButtonsStepProps extends StepComponentProps {
    step: ButtonsStep;
}

function ButtonsStepComponent({
    step,
    path,
    dragging,
}: ButtonsStepProps): JSX.Element {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const clientsBtnRef = useRef<HTMLDivElement>(null);

    const selectedStep = useSelector(
        (state: RootState) => state.conversationEditor.selectedStep
    );
    const selectedOption = step.selectedOption
        ? step.options[step.selectedOption] ?? null
        : null;
    const mapButtons = (): JSX.Element => {
        return (
            <div className={styles.btnsContainer}>
                {step.optionList.map((idOption) => {
                    const option = step.options[idOption];
                    return (
                        <div
                            onClick={(): void => {
                                dispatch(
                                    updateStep({
                                        ...step,
                                        selectedOption:
                                            selectedOption?.id === option.id
                                                ? null
                                                : option.id,
                                    })
                                );
                                if (!selectedStep) {
                                    dispatch(selectStep({ id: step.id, path }));
                                }
                            }}
                            key={option.id}
                            className={
                                selectedOption?.id === option.id
                                    ? styles.selectedMessageBtn
                                    : styles.messageBtn
                            }
                        >
                            {option.label ? option.label : '(Por Definir)'}
                            {option.classifier && (
                                <img
                                    className={styles.classifierIcon}
                                    alt={'shapes'}
                                    src={'/media/icons/shapes.svg'}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <React.Fragment>
            <StepContainer
                path={path}
                step={step}
                header={step.header}
                footer={step.footer}
                bottomComponent={mapButtons()}
                disabledBottom
            >
                <div className={styles.messageContainer}>
                    <DraftRenderer
                        editorState={EditorState.createWithContent(
                            convertFromRaw(step.message)
                        )}
                    />
                </div>
            </StepContainer>
            <ClientReply padding={10} title={step.name}>
                <div className={styles.replyContent}>
                    <div
                        className={styles.replyBtn + ' noselect'}
                        ref={clientsBtnRef}
                        onClick={(): void => {
                            setOpen(true);
                        }}
                    >
                        {selectedOption ? (
                            selectedOption.label ? (
                                selectedOption.label
                            ) : (
                                '(Por Definir)'
                            )
                        ) : (
                            <label className={styles.unSelected}>
                                Sin Seleccionar
                            </label>
                        )}
                    </div>

                    <Menu
                        anchorEl={clientsBtnRef.current}
                        open={open}
                        onClose={(): void => {
                            setOpen(false);
                        }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        MenuListProps={{
                            style: { minWidth: 130 },
                        }}
                    >
                        {step.optionList.map((idOption, index) => {
                            const option = step.options[idOption];
                            return (
                                <MenuItem
                                    onClick={(): void => {
                                        dispatch(
                                            updateStep({
                                                ...step,
                                                selectedOption: option.id,
                                            })
                                        );
                                        setOpen(false);
                                    }}
                                    key={index}
                                >
                                    <div
                                        style={{
                                            minWidth:
                                                (clientsBtnRef.current
                                                    ?.offsetWidth ?? 0) - 32 ??
                                                0,
                                        }}
                                        className={styles.option}
                                    >
                                        {option.label
                                            ? option.label
                                            : '(Por Definir)'}
                                    </div>
                                </MenuItem>
                            );
                        })}
                    </Menu>
                </div>
            </ClientReply>

            {selectedOption &&
                selectedOption.steps.map((idStep: string, index: number) => (
                    <StepComponent
                        key={index}
                        idStep={idStep}
                        path={[
                            ...path,
                            {
                                idStep: step.id,
                                type: LocationTypes.BUTTON,
                                indexStep: index,
                                idOption: selectedOption.id,
                                label: selectedOption.label,
                            },
                        ]}
                    />
                ))}
            {selectedOption && (
                <AddStep
                    disabled={dragging}
                    location={{
                        idStep: step.id,
                        type: LocationTypes.BUTTON,
                        indexStep: selectedOption.steps.length,
                        idOption: selectedOption.id,
                        label: null,
                    }}
                />
            )}
            {selectedOption && (
                <div className={styles.endingContainer}>
                    <div className={styles.separator}></div>
                    <div className={styles.endingLbl}>
                        Fin:{' '}
                        {selectedOption.label
                            ? selectedOption.label
                            : '(Por Definir)'}
                    </div>
                    <div className={styles.endSeparator}></div>
                </div>
            )}
        </React.Fragment>
    );
}
export default ButtonsStepComponent;
