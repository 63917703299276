import styles from './WhatsappTemplateDetail.module.css';
import React, { useState } from 'react';
import { WhatsappTemplate } from '../../../@Types/WhatsappTemplate';
import RoundedTextField from '../../RoundedTextField/RoundedTextField';
import PayloadEditor, {
    PayloadEditorConditions,
} from '../../TextEditor/PayloadEditor';
import { nanoid } from '@reduxjs/toolkit';
import { WhatsappTemplateHeaderTypes } from '../../../constants/WhatsappTemplates/WhatsappTemplateHeaderTypes';
import produce from 'immer';
import { BaseWhatsappTemplateTypes } from '../../../constants/WhatsappTemplates/WhatsappTemplateTypes';
import { TextEditorTypes } from '../../../constants/TextEditorTypes';

interface WhatsappTemplateDetailProps {
    idEditor?: string;
    template: WhatsappTemplate;
    handleUpdate: (template: WhatsappTemplate) => void;
    payloadEditorConditions: PayloadEditorConditions;
}

function WhatsappTemplateDetailComponent({
    template,
    handleUpdate,
    payloadEditorConditions,
    ...props
}: WhatsappTemplateDetailProps): JSX.Element {
    const [idEditor] = useState(props.idEditor ?? nanoid());
    return (
        <div className={styles.container}>
            <div className={styles.headerContainer}>
                <label className={styles.editorLabel}>Encabezado:</label>
                <PayloadEditor
                    placeholder={'Encabezado'}
                    type={TextEditorTypes.WHATSAPP}
                    idEditor={idEditor + '-header'}
                    payload={{ draft: template.header?.text }}
                    onChange={({ draft: text }): void => {
                        if (!text) return;
                        handleUpdate({
                            ...template,
                            header: {
                                type: WhatsappTemplateHeaderTypes.TEXT,
                                text,
                            },
                        });
                    }}
                    media={false}
                    maxEntities={1}
                    context={{
                        client: true,
                        ticket: true,
                        notification:
                            template.type ===
                            BaseWhatsappTemplateTypes.VIEW_NOTIFICATION,
                    }}
                    conditions={payloadEditorConditions}
                    multiline={false}
                    maxLength={60}
                />
            </div>
            <div className={styles.editorContainer}>
                <label className={styles.editorLabel}>Cuerpo:</label>
                <PayloadEditor
                    placeholder={'Mensaje'}
                    type={TextEditorTypes.WHATSAPP}
                    idEditor={idEditor + '-body'}
                    payload={{ draft: template.body }}
                    onChange={({ draft: body }): void => {
                        if (!body) return;
                        handleUpdate({
                            ...template,
                            body,
                        });
                    }}
                    media={false}
                    context={{
                        client: true,
                        ticket: true,
                        notification:
                            template.type ===
                            BaseWhatsappTemplateTypes.VIEW_NOTIFICATION,
                    }}
                    conditions={payloadEditorConditions}
                    minHeight={144}
                    maxHeight={400}
                    required
                    maxLength={1024}
                />
            </div>
            <div className={styles.footerContainer}>
                <RoundedTextField
                    label="Pie"
                    multiline
                    maxLength={60}
                    value={template.footer}
                    onChange={(e): void => {
                        handleUpdate({
                            ...template,
                            footer: e.target.value,
                        });
                    }}
                ></RoundedTextField>
            </div>
            {template.buttons?.map((button, index) => (
                <div className={styles.btnLblContainer} key={index}>
                    <RoundedTextField
                        label="Botón"
                        value={button.text}
                        maxLength={20}
                        onChange={(e): void => {
                            handleUpdate(
                                produce(template, (template) => {
                                    if (template.buttons?.[index]) {
                                        template.buttons[index].text =
                                            e.target.value;
                                    }
                                })
                            );
                        }}
                    ></RoundedTextField>
                </div>
            ))}
        </div>
    );
}

export default WhatsappTemplateDetailComponent;
