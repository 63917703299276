import React, { useEffect, useRef, useState } from 'react';
import styles from './TemplateDetail.module.css';
import EditContainer from '../../../shared/EditContainer/EditContainer';
import { Template } from '../../../@Types/Template';
import RoundedPayload from '../../../shared/RoundedPayload/RoundedPayload';
import EditTemplatePayload from './EditTemplatePayload/EditTemplatePayload';
import { Payload } from '../../../@Types/Payload';
import ConfirmDialog from '../../../shared/ConfirmDialog/ConfirmDialog';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';

interface TemplateDetailProps {
    /** The selected Agent */
    selectedElement?: Template;
    /** Function called when an agent's value needs updating */
    handleUpdate: Function;
    handleUpdatePayload: (object: Template, value: Payload) => void;
    handleDelete: (object: Template) => void;
}
function TemplateDetail({
    selectedElement,
    handleUpdate,
    handleUpdatePayload,
    handleDelete,
}: TemplateDetailProps): JSX.Element {
    if (!selectedElement) {
        return <div></div>;
    }
    const [hide, setHide] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (containerRef.current) containerRef.current.scrollTop = 0;
        setHide(true);
        setTimeout(() => {
            setHide(false);
        }, 100);
    }, [selectedElement._id]);
    const [openDelete, setOpenDelete] = useState(false);

    return (
        <div className={styles.containerDetail} data-testid={'TemplateDetail'}>
            {openDelete && (
                <ConfirmDialog
                    onConfirm={async (
                        element: Template,
                        setLoading: Function
                    ): Promise<void> => {
                        try {
                            setLoading(true);
                            await handleDelete(selectedElement);
                            setOpenDelete(false);
                        } catch (error) {
                            //TODO: handle errors
                            console.error(error);
                            setLoading(false);
                        }
                    }}
                    element={selectedElement}
                    title="Borrar Plantilla"
                    btnMsg="Borrar"
                    deleting
                    msg="¿Está seguro de que desea borrar esta plantilla?"
                    onClose={(): void => {
                        setOpenDelete(false);
                    }}
                />
            )}
            <div className={styles.btnsContainer}>
                <div
                    className={styles.delBtn + ' grey-btn'}
                    data-testid={'TemplateDetail_DeleteBtn'}
                    onClick={(): void => {
                        setOpenDelete(true);
                    }}
                >
                    <DeleteRoundedIcon
                        style={{ fill: 'black' }}
                        fontSize="inherit"
                    />
                </div>
            </div>
            <div
                className={styles.titleContainer}
                data-testid={'titleContainer'}
            >
                <div className={styles.title} data-testid={'title'}>
                    <EditContainer
                        object={selectedElement}
                        field={'name'}
                        handleConfirm={handleUpdate}
                    >
                        <div className={styles.titlelbl}>
                            {selectedElement.name}
                        </div>
                        <input
                            className={styles.nameInput + ' edit-input'}
                            defaultValue={selectedElement.name}
                            type="text"
                            size={10}
                        />
                    </EditContainer>
                </div>
            </div>
            <div className={styles.descriptionLabel}>Descripción</div>
            <div
                className={styles.descriptionContainer}
                data-testid={'descriptionContainer'}
            >
                <div data-testid={'description'}>
                    <EditContainer
                        object={selectedElement}
                        field={'description'}
                        handleConfirm={handleUpdate}
                    >
                        <div className={styles.description}>
                            {selectedElement.description}
                        </div>
                        <textarea
                            className={styles.descriptionInput + ' edit-input'}
                            defaultValue={selectedElement.description}
                        />
                    </EditContainer>
                </div>
            </div>
            {!hide && (
                <div
                    className={styles.payloadContainer}
                    data-testid={'payloadContainer'}
                >
                    <div className={styles.payloadLabel}>Plantilla</div>
                    <div className={styles.payload}>
                        <RoundedPayload
                            payload={selectedElement.payload}
                            date={new Date()}
                            blockTitle={true}
                            showDecorators
                        />
                        <div className={styles.editBtn}>
                            <EditTemplatePayload
                                template={selectedElement}
                                handleUpdate={handleUpdatePayload}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
export default TemplateDetail;
