import styles from './InfoVideoStep.module.css';
import React, { useContext } from 'react';
import { InfoVideoStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import { useDispatch } from 'react-redux';
import { updateStep } from '../../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import InformativeTypePicker from '../InformativeTypePicker/InformativeTypePicker';
import InformativeFileComponent from '../InformativeFilePicker/InformativeFilePicker';
import {
    ApiKeyContext,
    BaseConversationPayloadConditionTypes,
} from '../../../ConversationEditor';
import PayloadEditor from '../../../../../../shared/TextEditor/PayloadEditor';
import { TextEditorTypes } from '../../../../../../constants/TextEditorTypes';

interface InfoVideoStepProps {
    step: InfoVideoStep;
}

function InfoVideoStepComponent({ step }: InfoVideoStepProps): JSX.Element {
    const dispatch = useDispatch();
    const apiKey = useContext(ApiKeyContext);
    return (
        <React.Fragment>
            <InformativeTypePicker step={step} />
            <InformativeFileComponent
                idStep={step.id}
                file={step.file}
                types={['video/mp4', 'video/3gp']}
                inputs="video/mp4,video/3gp"
                maxSize={16}
                type="VIDEO"
                defaultFile={{
                    fileName: 'Capta.mp4',
                    url: '/Capta.mp4',
                }}
            />
            <div className={styles.container}>
                <div className={styles.editorContainer}>
                    <PayloadEditor
                        placeholder={'Mensaje'}
                        type={TextEditorTypes.WHATSAPP}
                        idEditor={`Conversation-${apiKey}-${step.id}`}
                        payload={{
                            draft: step.caption,
                        }}
                        onChange={({ draft: caption }): void => {
                            if (!caption) return;
                            dispatch(updateStep({ ...step, caption }));
                        }}
                        media={false}
                        context={{
                            client: true,
                        }}
                        conditions={{
                            types: BaseConversationPayloadConditionTypes,
                            context: {
                                conversation: {
                                    idStep: step.id,
                                },
                            },
                        }}
                        minHeight={144}
                        maxHeight={400}
                        maxLength={1024}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

export default InfoVideoStepComponent;
