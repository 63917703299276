import React, { useEffect, useState } from 'react';
import styles from './PosVenta.module.css';
import { Form, Ticket } from '../../../../@Types/@Types';
import FormRenderer from '../../../../shared/FormRenderer/FormRenderer';
import PosVentaForm from './PosVentaForm';
import CBRFormStepTypes from '../../../../constants/Construction/CBRFormStepTypes';
import axiosInstance from '../../../../AxiosAPI';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../utils/_store';
import { loadFormHistory } from '../../../../controllers/FormsController/FormsService';
import EntryTypes from '../../../../constants/EntryTypes';
import ClientInfoTypes from '../../../../constants/ClientInfoTypes';
import FormStepTypes from '../../../../constants/FormStepTypes';
import { nanoid } from '@reduxjs/toolkit';
interface FormPostViewProps {
    /** Function inherited from the dialog to activate the loader */
    setLoading?: Function;
    /** The currently selected Ticket */
    ticket?: Ticket;
    /** The posted values  */
    values?: Record<string, unknown>;
    /** The caseNum of the postView PosVenta */
    posventaCaseNum?: number;
    /** Function called to display the confirmation dialog */
    handleConfirm?: Function;
}

const CBRClientInfoTypes = [
    {
        cbrType: CBRFormStepTypes.CBR_FIRST_NAME,
        clientInfoType: ClientInfoTypes.NAME,
    },
    {
        cbrType: CBRFormStepTypes.CBR_LAST_NAME,
        clientInfoType: ClientInfoTypes.LASTNAME,
    },
    {
        cbrType: CBRFormStepTypes.CBR_CEL,
        clientInfoType: ClientInfoTypes.CEL,
    },
    {
        cbrType: CBRFormStepTypes.CBR_EMAIL,
        clientInfoType: ClientInfoTypes.EMAIL,
    },
    {
        cbrType: CBRFormStepTypes.CBR_DOC,
        clientInfoType: ClientInfoTypes.IDENTIFIER,
    },
];

/**
 * Component rendered inside the postview dialog.
 */
function FormPostView({
    values,
    ticket,
    setLoading,
    handleConfirm,
    posventaCaseNum,
}: FormPostViewProps): JSX.Element {
    const [CBRForm, setCBRForm] = useState<
        { form: Form; values: Record<string, any> } | undefined
    >(undefined);

    const idProject = useSelector((state: RootState) => state.site.idProject);

    const fetchEntryData = async (): Promise<void> => {
        if (idProject && ticket?.formValues) {
            setLoading?.(true);
            if (
                ticket.entry.type === EntryTypes.FORM ||
                ticket.entry.type === EntryTypes.WHATSAPP
            ) {
                const response = await loadFormHistory(
                    idProject,
                    ticket.entry.idForm
                );
                if (response) {
                    const cbrValues: Record<string, any> = {};
                    const steps = Object.values(response.steps);
                    for (const step of steps) {
                        if (step.type.startsWith('CBR')) {
                            const value = ticket.formValues[step.id];
                            if (value !== undefined)
                                cbrValues[step.type] = value;
                            if (
                                (step.type as any) ===
                                CBRFormStepTypes.CBR_INCIDENCIAS
                            ) {
                                const value = ticket.formValues[step.id];
                                if (value) {
                                    for (const inStep of value) {
                                        cbrValues[inStep.idCommentStep] =
                                            ticket.formValues[
                                                inStep.idCommentStep
                                            ];
                                        cbrValues[inStep.idElementStep] =
                                            ticket.formValues[
                                                inStep.idElementStep
                                            ];
                                        cbrValues[inStep.idSpaceStep] =
                                            ticket.formValues[
                                                inStep.idSpaceStep
                                            ];
                                        recursivelyAddElementValues(
                                            inStep.idElementStep,
                                            cbrValues,
                                            ticket.formValues
                                        );
                                    }
                                }
                            }
                        }
                    }
                    if (
                        cbrValues[CBRFormStepTypes.CBR_INCIDENCIAS] ===
                        undefined
                    ) {
                        for (const step of steps) {
                            if (
                                step.type === FormStepTypes.MAPPER &&
                                Object.values(step.steps).find((step) =>
                                    step.type.startsWith('CBR_')
                                )
                            ) {
                                const value = ticket.formValues[step.id];
                                if (!value) continue;
                                const comment = Object.values(step.steps).find(
                                    (step) =>
                                        step.type === FormStepTypes.TEXTINPUT ||
                                        step.type === FormStepTypes.TEXTAREA
                                );
                                const espacio = Object.values(step.steps).find(
                                    (step) =>
                                        step.type ===
                                        (CBRFormStepTypes.CBR_TIPO_ESPACIO as any)
                                );
                                //Esto solo funciona cuando son 2 niveles!
                                const locativa = Object.values(step.steps).find(
                                    (step) =>
                                        step.type ===
                                            (CBRFormStepTypes.CBR_LOCATIVAS as any) &&
                                        (step as any).subStep !== null
                                );
                                if (comment && espacio && locativa) {
                                    const incidencias = [];
                                    for (const val of value) {
                                        const element = {
                                            idSpaceStep:
                                                espacio.id + '-' + nanoid(),
                                            idElementStep:
                                                locativa.id + '-' + nanoid(),
                                            idCommentStep:
                                                comment.id + '-' + nanoid(),
                                        };
                                        const subStep =
                                            (locativa as any).subStep +
                                            '-' +
                                            nanoid();
                                        cbrValues[element.idSpaceStep] =
                                            val[espacio.id];
                                        cbrValues[element.idElementStep] = {
                                            ...val[locativa.id],
                                            subStep,
                                        };
                                        cbrValues[subStep] =
                                            val[(locativa as any).subStep];
                                        cbrValues[element.idCommentStep] =
                                            val[comment.id]?.value ??
                                            val[comment.id];
                                        incidencias.push(element);
                                    }
                                    cbrValues[
                                        CBRFormStepTypes.CBR_INCIDENCIAS
                                    ] = incidencias;
                                }
                            }
                        }
                    }
                    for (const type of [
                        CBRFormStepTypes.CBR_CEL,
                        CBRFormStepTypes.CBR_DOC,
                        CBRFormStepTypes.CBR_TIPO_DOC,
                        CBRFormStepTypes.CBR_EMAIL,
                        CBRFormStepTypes.CBR_FIRST_NAME,
                        CBRFormStepTypes.CBR_MIDDLE_NAME,
                        CBRFormStepTypes.CBR_LAST_NAME,
                        CBRFormStepTypes.CBR_SECOND_LAST_NAME,
                        CBRFormStepTypes.CBR_PHONE,
                    ]) {
                        const value = ticket.formValues[type];
                        if (value !== undefined) cbrValues[type] = value;
                    }

                    for (const {
                        cbrType,
                        clientInfoType,
                    } of CBRClientInfoTypes) {
                        if (
                            !cbrValues[cbrType] &&
                            ticket.client[clientInfoType]
                        ) {
                            cbrValues[cbrType] = ticket.client[clientInfoType];
                        }
                    }

                    if (
                        !cbrValues[CBRFormStepTypes.CBR_TIPO_DOC] &&
                        ticket.client[ClientInfoTypes.IDENTIFIER_TYPE]
                    ) {
                        cbrValues[CBRFormStepTypes.CBR_TIPO_DOC] = {
                            id: ticket.client[ClientInfoTypes.IDENTIFIER_TYPE],
                        };
                    }
                    setCBRForm({
                        form: PosVentaForm as any,
                        values: cbrValues,
                    });
                }
            } else {
                setCBRForm({
                    form: PosVentaForm as any,
                    values: ticket.formValues,
                });
            }
            setLoading?.(false);
        }
    };

    useEffect(() => {
        if (values === undefined) {
            fetchEntryData();
        } else {
            const form = { ...PosVentaForm };
            if (posventaCaseNum) {
                form.steps = {
                    ...form.steps,
                    ['TITLE']: {
                        ...form.steps['TITLE'],
                        title: 'Consecutivo: ' + posventaCaseNum,
                    },
                };
            }
            setCBRForm({
                form: form as any,
                values,
            });
        }
    }, []);

    return (
        <div className={styles.container}>
            {CBRForm && (
                <div className={styles.widgetContainer}>
                    <FormRenderer
                        postview={values !== undefined}
                        form={CBRForm.form}
                        values={CBRForm.values ?? {}}
                        customSubmit={
                            values || !ticket
                                ? undefined
                                : async (
                                      values: Record<string, any>,
                                      reload: Function
                                  ): Promise<void> => {
                                      setLoading?.(true);
                                      try {
                                          const resp = await postPosventa(
                                              values,
                                              ticket._id
                                          );
                                          const num = resp.CBR.posventaCaseNum;
                                          // eslint-disable-next-line no-console
                                          console.log(num);
                                          if (num) {
                                              handleConfirm?.(num);
                                          }
                                          setLoading?.(false);
                                          reload?.();
                                      } catch (error) {
                                          console.error(
                                              'Error posting the posventa',
                                              error
                                          );
                                          setLoading?.(false);
                                      }
                                  }
                        }
                    />
                </div>
            )}
        </div>
    );
}
const postPosventa = async (
    formvalues: Record<string, any>,
    idTicket: string
): Promise<Record<string, any>> => {
    let response = await axiosInstance.post(
        '/cbr/posventa/' + idTicket,
        formvalues
    );
    return response?.data;
};

export default FormPostView;

const recursivelyAddElementValues = (
    idElementStep: string,
    cbrValues: Record<string, any>,
    formValues: Record<string, any>
): void => {
    const value = formValues[idElementStep];
    if (value?.subStep) {
        cbrValues[value.subStep] = formValues[value.subStep];
        recursivelyAddElementValues(value.subStep, cbrValues, formValues);
    }
};
