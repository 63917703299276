import { useRef, cloneElement } from 'react';
import { useDrag, useDrop } from 'react-dnd';
const style = {
    cursor: 'move',
};

interface DraggableItemProps {
    /** The id of the item to dispay */
    id: string;
    /** The visual elements to display inside the item */
    children: any;
    /** The position of the item */
    index: number;
    /** Function to call when item is hovered on */
    moveItem: Function;
}

function DraggableItem({
    id,
    children,
    index,
    moveItem,
}: DraggableItemProps): JSX.Element {
    const ref = useRef<HTMLDivElement>(null);
    const [{ handlerId }, drop] = useDrop({
        accept: 'DraggableItem',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: any, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical quarter
            const hoverQuarterY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 4;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            if (clientOffset === null) {
                return;
            }
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed a quarter of the items height
            // When dragging downwards, only move when the cursor is below 25%
            // When dragging upwards, only move when the cursor is above 25%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverQuarterY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverQuarterY) {
                return;
            }
            // Time to actually perform the action
            moveItem(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: 'DraggableItem',
        item: () => {
            return { id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));
    return (
        <div
            ref={ref}
            style={{ ...style, opacity }}
            data-handler-id={handlerId}
        >
            <div>{cloneElement(children)}</div>
        </div>
    );
}

export default DraggableItem;
