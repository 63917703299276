import { useState, useEffect } from 'react';
import styles from './ActionBar.module.css';
import { ActionTabs } from './ActionBar';
interface ActionBarResizerProps {
    draggable: boolean;
    containerRef: React.RefObject<HTMLDivElement>;
    setSelectedTab: (tab: ActionTabs | undefined) => void;
}

function ActionBarResizer({
    draggable,
    setSelectedTab,
    containerRef,
}: ActionBarResizerProps): JSX.Element {
    const [hovering, setHovering] = useState<boolean>(false);
    const [dragging, setDragging] = useState<boolean>(false);
    const [timer, setTimer] = useState<any>(null);

    /**
     * Deletes timeout on component exit.
     */
    useEffect((): (() => void) => {
        return (): void => {
            clearTimer(timer);
        };
    }, [timer]);

    return (
        <>
            <div
                className={styles.resizerLine}
                style={
                    hovering || dragging
                        ? {
                              backgroundColor: 'var(--secondary)',
                              borderColor: 'var(--secondary)',
                          }
                        : {}
                }
            ></div>
            <div
                className={styles.resizer}
                style={{
                    cursor: draggable ? 'ns-resize' : 'default',
                }}
                onMouseEnter={(): void => {
                    clearTimer(timer);
                    setTimer(
                        setTimeout(() => {
                            setHovering(true);
                        }, 300)
                    );
                }}
                onMouseLeave={(): void => {
                    clearTimer(timer);
                    if (hovering) setHovering(false);
                }}
                draggable={draggable}
                onDragStart={(): void => {
                    if (!containerRef.current || !draggable) return;
                    containerRef.current.style.transition = 'none';
                    setDragging(true);
                }}
                onDrag={(e): void => {
                    if (!containerRef.current || !draggable) return;
                    let height = window.innerHeight - e.clientY;
                    if (e.clientY === 0) return;
                    containerRef.current.style.transition = 'none';
                    if (height < 50) height = 0;
                    else if (height < 152) height = 152;
                    containerRef.current.style.height = `${height}px`;
                }}
                onDragEnd={(e): void => {
                    if (!containerRef.current || !draggable) return;
                    let height = window.innerHeight - e.clientY;
                    if (e.clientY === 0) return;
                    if (height < 50) height = 0;
                    else if (height < 152) height = 152;
                    containerRef.current.style.height = `${height}px`;
                    setTimeout(() => {
                        if (!containerRef.current) return;
                        containerRef.current.style.transition = 'height 0.5s';
                    }, 500);
                    setDragging(false);
                    if (height === 0) setSelectedTab(undefined);
                    else {
                        localStorage.setItem(
                            'actionBarHeight',
                            height.toString()
                        );
                    }
                }}
            ></div>
        </>
    );
}

export default ActionBarResizer;

const clearTimer = (timer: any): void => {
    try {
        clearTimeout(timer);
        // eslint-disable-next-line no-empty
    } catch (e) {}
};
