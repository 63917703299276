import { AnyAction } from '@reduxjs/toolkit';
import { TicketClassifier, Ticket } from '../../@Types/TicketTypes/Ticket';
import * as Actions from './TicketsActions';
export const defaultOrderby = 'RESOLUTION_ASC';
import TicketFilterTypes from '../../constants/TicketFilterTypes';
import { Agent, Company, EntityValue } from '../../@Types/@Types';
import { State } from '../../@Types/State';
import produce from 'immer';
import { Process } from '../../@Types/ProcessTypes/Process';
export interface TicketsPageState {
    /** The ordered ids of the tickets */
    order: string[];
    /** The tickets */
    elements: Record<string, Ticket>;
    /** The currently active filters */
    filters: TicketFilters;
    /** The  way the elements are currently ordered.*/
    orderBy: string;
    /** The current search, undefined if none */
    search: string | undefined;
    /** the index of the currently selectedElement */
    selectedElement: number | undefined;
    /** The number of pages currently loaded in the list */
    page: number;
    /** Active if no more pages are available */
    noMoreElements: boolean;
    /** If loader is active */
    loading: boolean;
    /** The currently loaded Processes */
    processes: Record<string, Process>;
}
export interface TicketFilters {
    classifiers: TicketClassifier[];
    all: boolean;
    withNotifications: boolean;
    type: TicketFilterTypes;
    states: State[];
    companies: Company[];
    entities: Record<string, EntityValue[]>;
    companyResponsible: boolean;
    agents: Agent[];
    crtStartDate: Date | null;
    crtEndDate: Date | null;
    clsStartDate: Date | null;
    clsEndDate: Date | null;
}
export const defaultFilters = {
    classifiers: [],
    all: false,
    withNotifications: false,
    type: TicketFilterTypes.ALL,
    states: [],
    companies: [],
    entities: {},
    companyResponsible: false,
    agents: [],
    crtStartDate: null,
    crtEndDate: null,
    clsStartDate: null,
    clsEndDate: null,
};

const initialState = {
    order: [],
    elements: {},
    filters: defaultFilters,
    orderBy: defaultOrderby,
    search: undefined,
    selectedElement: undefined,
    page: 1,
    noMoreElements: false,
    loading: true,
    processes: {},
};

/**
 * Redux Reducer that handles TicketsActions Tiggers
 * @param state The current state
 * @param action the action that was triggered
 * @returns the new state
 */
const TicketsReducer = (
    state: TicketsPageState = initialState,
    action: AnyAction
): TicketsPageState => {
    if (Actions.reset.match(action)) {
        return {
            ...state,
            order: [],
            elements: {},
            filters: defaultFilters,
            orderBy: defaultOrderby,
            search: undefined,
            selectedElement: undefined,
            page: 1,
            noMoreElements: false,
            loading: true,
        };
    } else if (Actions.resetSuccess.match(action)) {
        return {
            ...state,
            loading: false,
            page: action.payload.page,
            order: action.payload.order,
            elements: action.payload.elements,
            filters: action.payload.filters,
            orderBy: action.payload.orderBy,
            search: action.payload.search,
            noMoreElements: action.payload.noMoreElements,
            selectedElement: action.payload.selectedElement,
        };
    } else if (
        //Reset when saga needs to load the list
        Actions.softReset.match(action) ||
        Actions.refresh.match(action)
    ) {
        /** This si called when the list elements change but not the search and filters */
        return {
            ...state,
            order: [],
            elements: {},
            selectedElement: undefined,
            page: 1,
            noMoreElements: false,
            loading: true,
        };
    } else if (Actions.refreshSuccess.match(action)) {
        return produce(state, (newState) => {
            const notifications: Record<string, true> = {};
            const pendingNotificationsToMarkAsRead: Record<string, true> = {
                ...newState.elements[action.payload._id]
                    .pendingNotificationsToMarkAsRead,
            };
            for (const key in action.payload.notifications) {
                if (
                    !state.elements[action.payload._id]
                        .pendingNotificationsToMarkAsRead[key]
                ) {
                    notifications[key] = true;
                } else {
                    delete pendingNotificationsToMarkAsRead[key];
                }
            }
            action.payload.notifications = notifications;
            action.payload.pendingNotificationsToMarkAsRead =
                pendingNotificationsToMarkAsRead;
            newState.elements[action.payload._id] = action.payload;
            newState.loading = false;
        });
    } else if (Actions.addNotification.match(action)) {
        return produce(state, (newState) => {
            if (newState.elements[action.payload.idTicket]) {
                newState.elements[action.payload.idTicket].notifications[
                    action.payload.idNotification
                ] = true;
            }
        });
    } else if (Actions.removeNotification.match(action)) {
        return produce(state, (newState) => {
            if (newState.elements[action.payload.idTicket]) {
                if (
                    !newState.elements[action.payload.idTicket][
                        'notifications'
                    ][action.payload.idNotification]
                ) {
                    newState.elements[action.payload.idTicket][
                        'pendingNotificationsToMarkAsRead'
                    ][action.payload.idNotification] = true;
                }
            }
        });
    } else if (Actions.removeAllNotification.match(action)) {
        return produce(state, (newState) => {
            newState.elements[action.payload] = {
                ...newState.elements[action.payload],
                notifications: {},
            };
        });
    } else if (Actions.processSuccess.match(action)) {
        return produce(state, (newState) => {
            for (const process of action.payload) {
                newState.processes[process._id] = process;
            }
        });
    } else if (Actions.setLoading.match(action)) {
        return {
            ...state,
            loading: action.payload,
        };
    } else if (Actions.getSuccess.match(action)) {
        return {
            ...state,
            loading: false,
            order: action.payload.order,
            elements: action.payload.elements,
            noMoreElements: action.payload.noMoreElements,
            selectedElement:
                action.payload.selectedElement ?? state.selectedElement,
        };
    } else if (Actions.pageScroll.match(action)) {
        return { ...state, page: state.page + 1 };
    } else if (Actions.selectElement.match(action)) {
        return { ...state, selectedElement: action.payload };
    } else if (Actions.search.match(action)) {
        return {
            ...state,
            order: [],
            elements: {},
            selectedElement: undefined,
            page: 1,
            noMoreElements: false,
            loading: true,
            search: action.payload,
        };
    } else if (Actions.orderBy.match(action)) {
        return {
            ...state,
            order: [],
            elements: {},
            selectedElement: undefined,
            page: 1,
            noMoreElements: false,
            loading: true,
            search: undefined,
            orderBy: action.payload,
        };
    } else if (Actions.setFilters.match(action)) {
        return {
            ...state,
            order: [],
            elements: {},
            selectedElement: undefined,
            page: 1,
            noMoreElements: false,
            loading: true,
            filters: action.payload,
        };
    }
    return state;
};
export default TicketsReducer;

export const ticketsToQueryString = (
    filters: TicketFilters,
    orderBy: string = defaultOrderby,
    search: string | undefined,
    ignoreDefault?: boolean
): string => {
    const url = new URLSearchParams();
    if (filters.classifiers.length !== 0) {
        url.set(
            'classifiers',
            filters.classifiers.map((filter) => filter.idValue).join(',')
        );
    }
    if (filters.companies.length !== 0) {
        url.set(
            'companies',
            filters.companies.map((company) => company._id).join(',')
        );
    }

    const entities = Object.keys(filters.entities);
    if (entities.length > 0) {
        url.set(
            'entities',
            entities
                .filter((idEntity) => filters.entities[idEntity].length > 0)
                .map(
                    (idEntity) =>
                        `${idEntity}:${filters.entities[idEntity]
                            .map((e) => e._id)
                            .join(',')}`
                )
                .join(';')
        );
    }
    if (filters.agents.length !== 0) {
        url.set('agents', filters.agents.map((agent) => agent._id).join(','));
    }

    if (filters.states.length !== 0) {
        url.set('states', filters.states.map((state) => state._id).join(','));
    }

    if (filters.crtStartDate) {
        const newDate = new Date();
        newDate.setTime(filters.crtStartDate.getTime());
        newDate.setHours(0, 0, 0, 0);
        url.set('crtStartDate', newDate.getTime() + '');
    }

    if (filters.crtEndDate) {
        const newDate = new Date();
        newDate.setTime(filters.crtEndDate.getTime());
        newDate.setHours(23, 59, 59, 999);
        url.set('crtEndDate', newDate.getTime() + '');
    }

    if (filters.clsStartDate) {
        const newDate = new Date();
        newDate.setTime(filters.clsStartDate.getTime());
        newDate.setHours(0, 0, 0, 0);
        url.set('clsStartDate', newDate.getTime() + '');
    }

    if (filters.clsEndDate) {
        const newDate = new Date();
        newDate.setTime(filters.clsEndDate.getTime());
        newDate.setHours(23, 59, 59, 999);
        url.set('clsEndDate', newDate.getTime() + '');
    }

    if (
        (ignoreDefault &&
            filters.withNotifications !== defaultFilters.withNotifications) ||
        !ignoreDefault
    ) {
        url.set('withNotifications', '' + (filters.withNotifications ?? false));
    }

    if (
        (ignoreDefault && filters.all !== defaultFilters.all) ||
        !ignoreDefault
    ) {
        url.set('all', '' + (filters.all ?? false));
    }

    if (
        (ignoreDefault &&
            filters.companyResponsible !== defaultFilters.companyResponsible) ||
        !ignoreDefault
    ) {
        url.set(
            'companyResponsible',
            '' + (filters.companyResponsible ?? false)
        );
    }

    if (
        (ignoreDefault && filters.type !== defaultFilters.type) ||
        !ignoreDefault
    ) {
        url.set('type', '' + (filters.type ?? TicketFilterTypes.ALL));
    }
    if (search) {
        url.set('search', encodeURIComponent(search));
    } else if (
        (ignoreDefault && orderBy !== defaultOrderby) ||
        !ignoreDefault
    ) {
        url.set('orderBy', orderBy ?? defaultOrderby);
    }
    return url.toString();
};
