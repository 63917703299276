import styles from './ErrorAction.module.css';
import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
interface ErrorActionActionProps {
    message: string;
}

function ErrorAction({ message }: ErrorActionActionProps): JSX.Element {
    return (
        <div className={styles.container}>
            <div className={styles.warning}>
                <WarningIcon fontSize="inherit" />
            </div>
            <div className={styles.msgContainer}>
                <label className={styles.msgLbl}>{message}</label>
            </div>
        </div>
    );
}

export default ErrorAction;
