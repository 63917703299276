import { AnyAction } from '@reduxjs/toolkit';
import {
    takeLatest,
    call,
    put,
    fork,
    select,
    takeEvery,
} from 'redux-saga/effects';
import * as actions from './TicketsActions';
import { RootState } from '../../utils/_store';
import { loadTicketById, fetchTickets, loadTickets } from './TicketsService';
import { TicketsPageState, ticketsToQueryString } from './TicketsReducer';
import { Ticket } from '../../@Types/@Types';
import { fetchClassifiers } from '../_SiteController/Services/ClassifiersService';
import { addClassifiers } from '../_SiteController/SiteActions';
import * as actionsActions from './Actions/ActionsActions';
import { loadProcessHistoriesByIds } from '../ProcessController/ProcessHistoryService';
import { Process } from '../../@Types/ProcessTypes/Process';
/** Selector that returns the current ipProject */
const getIdProject = (state: RootState): string | undefined | null =>
    state.site.idProject;

const getNumActions = (state: RootState): number =>
    state.ticketActions.numActions;

/** Number of elements per Page */
const PAGE_SIZE = 20;

/**
 * Function called when new tickets should be rendered(reset,search, or newpage)
 * @param action of type Search, reset or scroll
 */
function* resetTickets(action: AnyAction): any {
    if (actions.reset.match(action)) {
        if (
            action.payload.idTicket ||
            (action.payload.queryString &&
                action.payload.queryString?.length > 0)
        ) {
            try {
                const idProject = yield select(getIdProject);
                const result = yield call(
                    loadTickets,
                    idProject,
                    action.payload.queryString,
                    action.payload.idTicket,
                    action.payload.forced
                );
                const page = Math.ceil(result.elements.length / PAGE_SIZE);
                let elements: Record<string, Ticket> = {};
                let order: string[] = [];
                for (const ticket of result.elements) {
                    elements[ticket._id] = ticket;
                    order.push(ticket._id);
                }
                result.page = page;
                result.noMoreElements =
                    result.elements.length < page * PAGE_SIZE;
                yield fork(fetchProcesses, idProject, result.elements);
                yield call(checkClassifiers, result.elements);
                result.elements = elements;
                result.order = order;
                yield put(actions.resetSuccess(result));
            } catch (error) {
                //TODO handle errors
                console.error(error);
            }
        } else {
            //Load stored state?
            yield getTickets(action);
        }
    }
}

/**
 * Function called when new tickets should be rendered(reset,search, or newpage)
 * @param action of type Search, reset or scroll
 */
function* getTickets(action: AnyAction): any {
    try {
        const idProject = yield select(getIdProject);
        const pageInfo = yield select((state: RootState) => state.ticketsPage);
        let result: Ticket[] = yield call(
            fetchTickets,
            idProject,
            pageInfo.page,
            PAGE_SIZE,
            pageInfo.filters,
            pageInfo.orderBy,
            pageInfo.search
        );
        yield fork(fetchProcesses, idProject, result);
        yield call(checkClassifiers, result);
        const noMoreElements =
            (pageInfo.page !== 1 && result.length === 0) ||
            (pageInfo.page === 1 && result.length < PAGE_SIZE);
        let elements: Record<string, Ticket> = {};
        let order: string[] = [];
        for (const ticket of result) {
            elements[ticket._id] = ticket;
            order.push(ticket._id);
        }
        if (actions.pageScroll.match(action)) {
            order = pageInfo.order.concat(order);
            elements = { ...pageInfo.elements, ...elements };
        }
        yield put(actions.getSuccess({ elements, order, noMoreElements }));
    } catch (error) {
        //TODO handle errors
        console.error(error);
    }
}

function* fetchProcesses(idProject: string, tickets: Ticket[]): any {
    try {
        const pageInfo = yield select((state: RootState) => state.ticketsPage);
        const missingProcesses: string[] = [];
        for (const ticket of tickets) {
            if (
                ticket.process &&
                !pageInfo.processes[ticket.process.idProcess]
            ) {
                missingProcesses.push(ticket.process.idProcess);
            }
        }
        if (missingProcesses.length > 0) {
            const processes: Process[] = yield call(
                loadProcessHistoriesByIds,
                idProject,
                missingProcesses
            );
            yield put(actions.processSuccess(processes));
        }
    } catch (error) {
        //TODO handle errors
        console.error(error);
    }
}

/**
 * Function called when the user goes back on the browser
 * @param action of type goBack
 */
function* goTo(action: AnyAction): any {
    try {
        if (actions.goTo.match(action)) {
            const idTicket = action.payload.idTicket;
            const pageInfo: TicketsPageState = yield select(
                (state: RootState) => state.ticketsPage
            );
            const selectedElement =
                pageInfo.elements[
                    pageInfo.order[pageInfo.selectedElement ?? -1]
                ];
            const queryChange =
                action.payload.queryString !==
                ticketsToQueryString(
                    pageInfo.filters,
                    pageInfo.orderBy,
                    pageInfo.search,
                    true
                );
            if (queryChange) {
                yield put(
                    actions.reset({
                        queryString: action.payload.queryString,
                        idTicket,
                        forced: action.payload.forced,
                    })
                );
            } else if (idTicket !== selectedElement?._id) {
                /** If the currently selected Ticket doesn't have the id in the url  */
                /** If user wants to unselect (no id in url) */
                if (idTicket === undefined) {
                    yield put(actions.selectElement(undefined));
                } else {
                    /** Check if ticket is in the current list. */
                    const index = pageInfo.order.findIndex(
                        (listElement) => listElement === idTicket
                    );
                    /** IF ticket is in current list select it. */
                    if (index >= 0) {
                        yield put(actions.selectElement(index));
                    } else {
                        /** Reload page as if new one. */
                        yield put(
                            actions.reset({
                                queryString: action.payload.queryString,
                                idTicket,
                                forced: action.payload.forced,
                            })
                        );
                    }
                }
            }
        }
    } catch (error) {
        //TODO handle errors
        console.error(error);
    }
}

/**
 * Function called to handle fetching the missing classifiers if any
 * @param tickets to check
 */
function* checkClassifiers(tickets: Ticket[]): any {
    try {
        const classifiers = yield select(
            (state: RootState) => state.site.classifiers
        );
        const missingClassifiers: string[] = [];
        for (const ticket of tickets) {
            for (const classifier of ticket.classifiers) {
                if (!classifiers[classifier.idRoot]) {
                    missingClassifiers.push(classifier.idRoot);
                }
                if (classifier.idValue && !classifiers[classifier.idValue]) {
                    missingClassifiers.push(classifier.idValue);
                }
            }
        }
        if (missingClassifiers.length > 0) {
            const classifiers = yield call(
                fetchClassifiers,
                missingClassifiers
            );
            yield put(addClassifiers({ classifiers }));
        }
    } catch (error) {
        //TODO handle errors
        console.error(error);
    }
}

/**
 * Function called when a ticket is classified to refresh its info
 * @param action of type Search, reset or scroll
 */
function* refreshTicket(action: AnyAction): any {
    try {
        const idProject = yield select(getIdProject);
        const pageInfo: TicketsPageState = yield select(
            (state: RootState) => state.ticketsPage
        );
        const element = actions.refreshTicket.match(action)
            ? pageInfo.elements[action.payload]
            : pageInfo.selectedElement !== undefined
            ? pageInfo.elements[pageInfo.order[pageInfo.selectedElement]]
            : ({} as any);
        if (element?._id) {
            let ticket = yield call(loadTicketById, idProject, element._id);
            yield call(fetchProcesses, idProject, [ticket]);
            yield put(actions.refreshSuccess(ticket));
            const localNumActions = yield select(getNumActions);
            if (ticket.numActions !== localNumActions) {
                yield put(actionsActions.refresh());
            }
        }
    } catch (error) {
        //TODO handle errors
        console.error(error);
    }
}

function* watchResetTickets(): any {
    yield takeLatest([actions.Types.RESET], resetTickets);
}

function* watchGetTicketsRequest(): any {
    yield takeLatest(
        [
            actions.Types.REFRESH,
            actions.Types.SOFT_RESET,
            actions.Types.PAGE_SCROLL,
            actions.Types.SEARCH,
            actions.Types.FILTER,
        ],
        getTickets
    );
}

function* watchRefreshTicket(): any {
    yield takeEvery(
        [
            actions.Types.REFRESH_CURRENT,
            actions.Types.REFRESH_TICKET,
            actionsActions.Types.APPEND_ACTION,
        ],
        refreshTicket
    );
}

function* watchGoBackTickets(): any {
    yield takeLatest([actions.Types.GO_TO], goTo);
}

export default [
    fork(watchResetTickets),
    fork(watchGetTicketsRequest),
    fork(watchRefreshTicket),
    fork(watchGoBackTickets),
];
