import styles from './SelectedElement.module.css';
import Avatar from '../../../Avatar/Avatar';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';

interface SelectedElementProps<Type> {
    /** Function to call element label */
    calcLbl: (element: Type) => string;
    /** Function to calc element Icon  */
    calcIcon?: (element: Type) => JSX.Element;
    /** Function to calc if element was deleted */
    calcIsDeleted?: (element: Type) => boolean;
    /** Element to display */
    element: Type;
    /** Function to call when the remove button is clicked */
    handleRemove: Function;
    /** If the element displayed is the firstone, to not render the border-top */
    first: boolean;
    /** Function called to view the selected element */
    handleView?: (element: Type) => void;
    /** Function called to config the selected element */
    handleConfig?: (element: Type) => void;
    /** if the element is draggable */
    draggable?: boolean;
}
/**
 * Component that displays the selected elements, if only one doenst show the delete
 */
function SelectedElement<Type>({
    calcLbl,
    calcIcon,
    calcIsDeleted,
    element,
    handleRemove,
    handleView,
    handleConfig,
    draggable = false,
    first,
}: SelectedElementProps<Type>): JSX.Element {
    return (
        <div
            className={styles.container}
            style={{
                borderTop: first ? 'none' : '1px solid var(--light-grey)',
                cursor: draggable ? 'move' : 'default',
            }}
        >
            <div className={styles.checkIcon}>
                <CheckRoundedIcon fontSize="inherit" />
            </div>
            <div className={styles.avatarContainer}>
                {calcIcon ? (
                    calcIcon(element)
                ) : (
                    <Avatar lbl={calcLbl(element)} toolTip={false} />
                )}
            </div>
            <div className={styles.lblContainer}>
                <label
                    className={styles.nameLbl}
                    style={{
                        cursor: draggable ? 'move' : 'default',
                        textDecoration: calcIsDeleted?.(element)
                            ? 'line-through'
                            : 'none',
                    }}
                    title={calcLbl(element)}
                >
                    {calcLbl(element)}
                </label>
            </div>
            <div className={styles.iconsContainer}>
                {handleConfig && (
                    <div
                        className={styles.settingsIcon}
                        onClick={(): void => {
                            handleConfig(element);
                        }}
                    >
                        <SettingsRoundedIcon fontSize="inherit" />
                    </div>
                )}
                {handleView && (
                    <div
                        className={styles.settingsIcon}
                        onClick={(): void => {
                            handleView(element);
                        }}
                    >
                        <VisibilityRoundedIcon fontSize="inherit" />
                    </div>
                )}
                <div
                    className={styles.closeIcon}
                    onClick={(): void => {
                        handleRemove();
                    }}
                >
                    <CloseRoundedIcon fontSize="inherit" />
                </div>
            </div>
        </div>
    );
}

export default SelectedElement;
