import React, { useEffect, useState } from 'react';
import styles from './SetPassword.module.css';
import LoginTextField from '../LoginTextField/LoginTextField';
import { Controller, useForm } from 'react-hook-form';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
    checkLink,
    setPassword,
} from '../../../controllers/_SiteController/Services/UserService';
import Loader from '../../../shared/Loader/Loader';

function SetPassword({ history, match }: RouteComponentProps): JSX.Element {
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [validToken, setValidToken] = useState(true);

    const {
        getValues,
        control,
        formState: { errors, isDirty },
        trigger,
        setError,
        clearErrors,
    } = useForm({
        mode: 'onTouched',
    });

    const handleCheckLink = async (): Promise<void> => {
        const token = (match.params as any).token;
        try {
            await checkLink(token);
        } catch (err) {
            setValidToken(false);
            await new Promise((r) => setTimeout(r, 15000));
            history.push('/login');
        }
    };
    useEffect((): void => {
        handleCheckLink();
    }, [match]);

    const handleSetPassword = async (): Promise<void> => {
        const isValid = await trigger();
        if (isValid && isDirty) {
            const passwords = getValues();
            if (passwords.password !== passwords.passwordConfirm) {
                setError('password', {
                    type: 'manual',
                    message: '',
                });
                setError('passwordConfirm', {
                    type: 'manual',
                    message: 'Las contraseñas deben coincidir',
                });
            } else {
                try {
                    setLoading(true);
                    await setPassword(
                        (match.params as any).token,
                        passwords.password
                    );
                    setRedirect(true);
                    await new Promise((r) => setTimeout(r, 6000));
                    history.push('/login');
                } catch (err) {
                    setValidToken(false);
                    setLoading(false);
                    //TODO Handle errors
                    console.error(err);
                }
            }
        }
    };

    const clearDifferentPasswordError = (): void => {
        clearErrors(['password', 'passwordConfirm']);
    };

    return (
        <React.Fragment>
            <div className={styles.leftPanel}>
                <div className={styles.logo}>
                    <img
                        alt={'Capta'}
                        className={'capta-logo'}
                        src={'/Capta.svg'}
                    ></img>
                </div>
                <h1>Restablece tu contraseña </h1>
                {!redirect && validToken && (
                    <React.Fragment>
                        <div className={styles.loginInputsContainer}>
                            <div className={styles.loginInput}>
                                <Controller
                                    name="password"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required:
                                            'La nueva contraseña es obligatoria',
                                    }}
                                    render={({ field }): JSX.Element => (
                                        <LoginTextField
                                            {...field}
                                            handleChange={
                                                clearDifferentPasswordError
                                            }
                                            name="password"
                                            placeholder="Nueva contraseña"
                                            error={!!errors.password}
                                            helperText={
                                                errors.password
                                                    ? errors.password.message
                                                    : ''
                                            }
                                        />
                                    )}
                                />
                            </div>
                            <div className={styles.loginInput}>
                                <Controller
                                    name="passwordConfirm"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required:
                                            'Debes confirmar la contraseña',
                                    }}
                                    render={({ field }): JSX.Element => (
                                        <LoginTextField
                                            {...field}
                                            handleChange={
                                                clearDifferentPasswordError
                                            }
                                            name="passwordConfirm"
                                            placeholder="Confirma tu contraseña"
                                            error={!!errors.password}
                                            helperText={
                                                errors.password
                                                    ? errors.password.message
                                                    : ''
                                            }
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <button
                            className={styles.loginButton}
                            onClick={(): void => {
                                if (!loading) {
                                    handleSetPassword();
                                }
                            }}
                            disabled={loading}
                        >
                            <label
                                style={{
                                    visibility: loading ? 'hidden' : 'visible',
                                }}
                            >
                                Restablecer
                            </label>
                            {loading && (
                                <div className={styles.loaderContainer}>
                                    <Loader color="white" position="unset" />
                                </div>
                            )}
                        </button>
                    </React.Fragment>
                )}
                {redirect && validToken && (
                    <React.Fragment>
                        <p>
                            Tu contraseña ha sido actualizada exitosamente, ya
                            puedes iniciar sesión con tu nueva contraseña.
                        </p>
                        <p>Estás siendo redirigido a la pantalla de login.</p>
                    </React.Fragment>
                )}
                {!validToken && (
                    <React.Fragment>
                        <p>
                            Este link para establecer tu contraseña ya expiró,
                            por favor genera uno nuevo en la sección{' '}
                            {'"Olvidaste tu contraseña"'} de la pantalla de
                            login.
                        </p>
                        <p>Estás siendo redirigido a la pantalla de login.</p>
                    </React.Fragment>
                )}
            </div>
            <div className={styles.rightPanel}>
                <div className={styles.rightPanelMessage}>
                    {validToken && (
                        <React.Fragment>
                            <h1>¡Bienvenid@!</h1>
                            <p>
                                Para establecer tu contraseña diligencia los
                                campos de contraseña y confirmación, después haz
                                click en el botón {'"Restablecer"'}
                            </p>
                            <p>
                                Una vez tu contraseña haya sido actualizada,
                                inicia sesión y gestiona tus PQRS.
                            </p>
                        </React.Fragment>
                    )}
                    {!validToken && (
                        <React.Fragment>
                            <h1>¡Ooops!</h1>
                            <p>Parece que este link expiró.</p>
                            <p>
                                Dirígete a la sección{' '}
                                {'"Olvidaste tu contraseña"'} y genera uno
                                nuevo.
                            </p>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}
export default withRouter(SetPassword);
