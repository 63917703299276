function SpaceIcon(props: any): JSX.Element {
    return (
        <svg
            fill={props.fill}
            className={props.class}
            style={props.style}
            width="512pt"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
        >
            <g id="space_type" data-name="space type">
                <path d="M29.74,39A10.21,10.21,0,0,0,27.61,39,1.63,1.63,0,0,0,26,40.61V53.35A1.63,1.63,0,0,0,27.61,55h1.74A1.63,1.63,0,0,0,31,53.35V40.61A1.63,1.63,0,0,0,29.74,39Z" />
                <path d="M57.35,39a10.27,10.27,0,0,0-2.13.05A1.63,1.63,0,0,0,54,40.61V53.35A1.63,1.63,0,0,0,55.61,55h1.74A1.63,1.63,0,0,0,59,53.35V40.61A1.63,1.63,0,0,0,57.35,39Z" />
                <path d="M33,54a1,1,0,0,0,1,1H51a1,1,0,0,0,1-1V49H33Z" />
                <path d="M49,42H36a3,3,0,0,0-3,3v2H52V45A3,3,0,0,0,49,42Z" />
                <path d="M54,31a5,5,0,0,0-5-5H36a5,5,0,0,0-5,5v6.39a3.62,3.62,0,0,1,1.86,2.24A4.68,4.68,0,0,1,33,41c1.37-1.37,4.2-1,16-1a5,5,0,0,1,2.68.78c.64.39.07.39.46-1.15a3.65,3.65,0,0,1,1.43-2C54.15,37.19,54,38.41,54,31Z" />
                <path d="M17,32c-5.85,0-4.33,0-4-.05a7.06,7.06,0,0,0,6-7,7,7,0,0,0-4.47,1.59,7.08,7.08,0,0,0-2.07-5.05,7.06,7.06,0,0,0-2.07,5.05,7,7,0,0,0-4.47-1.59,7.07,7.07,0,0,0,6,7c.36.08,1.89.05-4,.05l1.71,6H3V55H5V50H20v5h2V38H15.26ZM15,45H10V43h5Z" />
                <path d="M26,21h6L31,10H19L18,21h6V40.61a3.63,3.63,0,0,1,2-3.24Z" />
            </g>
        </svg>
    );
}
export default SpaceIcon;
