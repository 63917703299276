import { checkOrgPerm, checkProjPerm } from '../utils/PermissionsFunctions';
import { OrganizationPermissions, ProjectPermissions } from './Permissions';

export interface SideBarConfig {
    routes: SideBarConfigRoute[];
    checkPermFunction: Function;
}
export interface SideBarConfigRoute {
    /** The key of the route */
    key: string;
    /** Title of the Label*/
    label: string;
    /** filename of the label in the public/media/icons/ folder*/
    icon: string;
    /** location the button redirects to on click*/
    location: string;
    /**Permission */
    permissions?: (ProjectPermissions | OrganizationPermissions)[];
}

export const ProjectSideBarConfig: SideBarConfig = {
    checkPermFunction: checkProjPerm,
    routes: [
        {
            key: 'TICKETS',
            label: 'Mis Casos',
            icon: 'ticketIcon',
            location: '/tickets',
        },
        {
            key: 'ANALYTICS',
            label: 'Analítica',
            icon: 'analyticsIcon',
            location: '/analytics',
            permissions: [ProjectPermissions.ANALYTICS],
        },
        {
            key: 'AREAS',
            label: 'Áreas',
            icon: 'organizationIcon',
            location: '/areas',
            permissions: [ProjectPermissions.EDIT_PROJECT],
        },
        {
            key: 'CLASSIFIERS',
            label: 'Clasificadores',
            icon: 'classificationIcon',
            location: '/classifiers',
            permissions: [ProjectPermissions.EDIT_PROJECT],
        },
        {
            key: 'FLOW',
            label: 'Flujo',
            icon: 'flowIcon',
            location: '/flows',
            permissions: [ProjectPermissions.EDIT_PROJECT],
        },
        {
            key: 'ENTRY',
            label: 'Entradas',
            icon: 'entryIcon',
            location: '/entries',
            permissions: [ProjectPermissions.EDIT_PROJECT],
        },
        {
            key: 'SURVEYS',
            label: 'Encuestas',
            icon: 'surveyIcon',
            location: '/surveys',
            permissions: [ProjectPermissions.EDIT_PROJECT],
        },
        {
            key: 'TEMPLATES',
            label: 'Plantillas',
            icon: 'templateIcon',
            location: '/templates',
            permissions: [
                ProjectPermissions.EDIT_TEMPLATES,
                ProjectPermissions.EDIT_PROJECT,
            ],
        },
        // {
        //     key: 'CONFIGS',
        //     label: 'Configuración',
        //     icon: 'settingsIcon',
        //     location: '/project',
        //     permission: ProjectPermissions.EDIT_PROJECT,
        // },
    ],
};

export const OrganizationSideBarConfig: SideBarConfig = {
    checkPermFunction: checkOrgPerm,
    routes: [
        {
            key: 'AGENTS',
            label: 'Agentes',
            icon: 'filled-userIcon',
            location: '/agents',
            permissions: [OrganizationPermissions.AGENTS],
        },
        {
            key: 'CLIENTS',
            label: 'Clientes',
            icon: 'userIcon',
            location: '/clients',
            permissions: [OrganizationPermissions.CLIENTS],
        },
        {
            key: 'COMPANIES',
            label: 'Empresas',
            icon: 'handshake',
            location: '/companies',
            permissions: [OrganizationPermissions.COMPANIES],
        },
        {
            key: 'ENTITIES',
            label: '',
            icon: '',
            location: '',
        },
        {
            key: 'CONFIGS',
            label: 'Configuración',
            icon: 'settingsIcon',
            location: '/organization',
            permissions: [OrganizationPermissions.EDIT_ORGANIZATION],
        },
    ],
};

/**  EVENTS: {
            label: 'Eventos',
            icon: 'eventIcon',
            location: '/events',
        } */
