import { useSelector } from 'react-redux';
import ClientInfoTypes from '../../../../constants/ClientInfoTypes';
import { DraftEntityDataTypes } from '../../../../constants/Draft/DraftEntityDataTypes';
import Context from '../Context';
import { RootState } from '../../../../utils/_store';
import EntityContext from './EntityContext';
import ClientIcon from '../../../../Icons/ClientIcon';
import { GenericContextProps } from '../TextEditorContext';
import { DraftContext } from '../../../../@Types/Draft/DraftContext';
import EurekaElementMenuGroup from '../../../@Menus/ElementMenu/EurekaElementMenuGroup';

interface ClientContextProps extends GenericContextProps {
    context: DraftContext | undefined;
}

function ClientContext({ context, ...props }: ClientContextProps): JSX.Element {
    const idEntity = useSelector(
        (state: RootState) => state.site.organization?.idClientEntity
    );

    return (
        <EurekaElementMenuGroup
            {...props}
            icon={ClientIcon}
            label={'Cliente'}
            renderElements={(): JSX.Element => (
                <>
                    <Context
                        {...props}
                        data={{
                            type: DraftEntityDataTypes.CLIENT,
                            idProperty: ClientInfoTypes.NAME,
                        }}
                    />
                    <Context
                        {...props}
                        data={{
                            type: DraftEntityDataTypes.CLIENT,
                            idProperty: ClientInfoTypes.LASTNAME,
                        }}
                    />
                    <Context
                        {...props}
                        data={{
                            type: DraftEntityDataTypes.CLIENT,
                            idProperty: 'FULLNAME',
                        }}
                    />
                    <Context
                        {...props}
                        data={{
                            type: DraftEntityDataTypes.CLIENT,
                            idProperty: ClientInfoTypes.EMAIL,
                        }}
                    />
                    <Context
                        {...props}
                        data={{
                            type: DraftEntityDataTypes.CLIENT,
                            idProperty: ClientInfoTypes.CEL,
                        }}
                    />
                    <Context
                        {...props}
                        data={{
                            type: DraftEntityDataTypes.CLIENT,
                            idProperty: ClientInfoTypes.IDENTIFIER,
                        }}
                    />
                    {idEntity && (
                        <EntityContext
                            {...props}
                            context={context}
                            idEntity={idEntity}
                            type={DraftEntityDataTypes.CLIENT}
                        />
                    )}
                </>
            )}
        />
    );
}

export default ClientContext;
