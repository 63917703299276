import { JSX } from 'react';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import { TicketFilters } from '../../../controllers/TicketsController/TicketsReducer';
import styles from './TicketFilterNotifications.module.css';

export interface TicketFilterNotificationsProps {
    onClick: (filters: TicketFilters) => void;
}

export function TicketFilterNotifications({
    onClick,
}: TicketFilterNotificationsProps): JSX.Element {
    const pageInfo = useSelector((state: RootState) => state.ticketsPage);
    return (
        <div
            className={styles.button}
            onClick={(): void => {
                onClick({
                    ...pageInfo.filters,
                    withNotifications: !pageInfo.filters.withNotifications,
                });
            }}
            style={{
                backgroundColor: pageInfo.filters.withNotifications
                    ? 'var(--accent)'
                    : '',
            }}
        >
            <div className={styles.iconContainer}>
                <NotificationsIcon
                    style={{
                        height: 20,
                        width: 20,
                        color: pageInfo.filters.withNotifications
                            ? 'var(--primary1)'
                            : '',
                    }}
                />
            </div>
        </div>
    );
}
