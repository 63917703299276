import React, { useState } from 'react';
import styles from './ForgotPassword.module.css';
import LoginTextField from '../LoginTextField/LoginTextField';
import { Controller, useForm } from 'react-hook-form';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { sendResetLink } from '../../../controllers/_SiteController/Services/UserService';
import Loader from '../../../shared/Loader/Loader';

function ForgotPassword({ history }: RouteComponentProps): JSX.Element {
    const {
        getValues,
        control,
        formState: { errors, isDirty },
        trigger,
    } = useForm({
        mode: 'onTouched',
    });
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const handleClick = async (): Promise<void> => {
        const isValid = await trigger();
        if (isValid && isDirty) {
            try {
                setLoading(true);
                await new Promise((r) => setTimeout(r, 1000));
                await sendResetLink(getValues().email);
                setRedirect(true);
                await new Promise((r) => setTimeout(r, 5000));
                history.push('/login');
            } catch (err) {
                //TODO handle errors
                console.error(err);
                setLoading(false);
            }
        }
    };

    return (
        <React.Fragment>
            <div className={styles.leftPanel}>
                <div className={styles.logo}>
                    <img
                        alt={'Capta'}
                        className={'capta-logo'}
                        src={'/Capta.svg'}
                    />
                </div>
                <h1>Olvidaste tu contraseña </h1>
                {!redirect && (
                    <React.Fragment>
                        <div className={styles.loginInputsContainer}>
                            <div className={styles.loginInput}>
                                <Controller
                                    name="email"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        pattern: {
                                            // eslint-disable-next-line no-useless-escape
                                            value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            message: 'El correo no es válido',
                                        },
                                    }}
                                    render={({ field }): JSX.Element => (
                                        <LoginTextField
                                            {...field}
                                            handleEnter={handleClick}
                                            name="email"
                                            placeholder="Correo"
                                            email={true}
                                            helperText={
                                                errors.email
                                                    ? errors.email.message
                                                    : ''
                                            }
                                            error={!!errors.email}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className={styles.btnsContainer}>
                            <button
                                className={styles.GoBackLoginButton}
                                onClick={(): void => {
                                    if (!loading) {
                                        history.goBack();
                                    }
                                }}
                                disabled={loading}
                            >
                                <label
                                    style={{
                                        visibility: loading
                                            ? 'hidden'
                                            : 'visible',
                                        cursor: 'inherit',
                                    }}
                                >
                                    Regresar
                                </label>
                            </button>
                            <button
                                className={styles.loginButton}
                                onClick={(): void => {
                                    if (!loading) {
                                        handleClick();
                                    }
                                }}
                                disabled={loading}
                            >
                                <label
                                    style={{
                                        visibility: loading
                                            ? 'hidden'
                                            : 'visible',
                                        cursor: 'inherit',
                                    }}
                                >
                                    Restablecer
                                </label>
                                {loading && (
                                    <div className={styles.loaderContainer}>
                                        <Loader
                                            color="white"
                                            position="unset"
                                        />
                                    </div>
                                )}
                            </button>
                        </div>
                    </React.Fragment>
                )}
                {redirect && (
                    <React.Fragment>
                        <p>
                            Revisa tu bandeja de entrada, recibirás un link para
                            restablecer tu contraseña en algunos instantes.
                        </p>
                        <p>Estás siendo redirigido a la pantalla de login.</p>
                    </React.Fragment>
                )}
            </div>
            <div className={styles.rightPanel}>
                <div className={styles.rightPanelMessage}>
                    <h1>¡Bienvenid@!</h1>
                    <p>
                        Para restablecer tu contraseña ingresa tu correo y haz
                        click en el botón {'"Restablecer"'}.
                    </p>
                    <p>
                        Si estás registrado, recibirás un correo electrónico con
                        un link donde podrás restablecer tu contraseña.
                    </p>
                </div>
            </div>
        </React.Fragment>
    );
}
export default withRouter(ForgotPassword);
