import React, { useState, useEffect } from 'react';
import AreaDetailComponent from './AreaDetailComponent/AreaDetailComponent';
import { Agent, Area } from '../../../@Types/@Types';
import * as AgentService from '../../../controllers/AgentsController/AgentsService';
import Loader from '../../../shared/Loader/Loader';
import AgentViewDetail from '../AgentDetailView/AgentViewDetail';

interface AreaDetailProps {
    selectedElement?: string | undefined;
    /** The dictionary of elements */
    elements: Record<string, Area | Agent>;
    /** Function that handles adding a subarea to the area*/
    createSubArea: Function;
    /** Function that handles deleting the current area */
    handleClickDelete: Function;
    /** Function that handles removing an agent from the area */
    removeAgent: Function;
    /** Function that handles updating a value */
    handleUpdate: Function;
}

/**
 * Component that redirects between displaying the detail of an agent or an area
 */
function AreaDetail({
    selectedElement,
    elements,
    createSubArea,
    handleClickDelete,
    removeAgent,
    handleUpdate,
}: AreaDetailProps): JSX.Element {
    const [element, setElement] = useState<undefined | Agent | Area>(undefined);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        updateElement();
    }, [selectedElement, elements]);

    /**
     * Function that handles storing the current element in the state depending on its type
     */
    const updateElement = async (): Promise<void> => {
        setLoading(true);
        if (selectedElement) {
            const tempElem = elements[selectedElement];
            if (tempElem !== undefined) {
                setElement(tempElem as Area);
            } else {
                const agent = await AgentService.loadAgent(selectedElement);
                setElement(agent);
            }
        }
        setLoading(false);
    };

    if (loading) {
        return (
            <div style={{ marginLeft: -35, marginTop: -20 }}>
                <Loader />
            </div>
        );
    }

    if (!element) {
        return <div></div>;
    }

    if ((element as Area).subareas) {
        return (
            <AreaDetailComponent
                element={element as Area}
                createSubArea={createSubArea}
                handleClickDelete={handleClickDelete}
                removeAgent={removeAgent}
                handleUpdate={handleUpdate}
            />
        );
    } else {
        return (
            <AgentViewDetail
                selectedElement={element as Agent}
                handleUpdate={(): void => {}} //TODO arreglar bien agentes
            />
        );
    }
}

export default AreaDetail;
