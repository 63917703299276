import { ApiRequestQueryParam } from '../../../@Types/ApiRequest';
import RoundedTextField from '../../RoundedTextField/RoundedTextField';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import styles from './ApiRequestQueryParam.module.css';
import Toggle from '../../Toggle/Toggle';
import { DraftContext } from '../../../@Types/Draft/DraftContext';
import PayloadEditor, {
    PayloadEditorConditions,
} from '../../TextEditor/PayloadEditor';
import { TextEditorTypes } from '../../../constants/TextEditorTypes';

interface ApiParamProps {
    idEditor: string;
    label?: string;
    required?: boolean;
    context: DraftContext;
    conditions: PayloadEditorConditions;
    queryParam: ApiRequestQueryParam;
    handleUpdate: (queryParam: ApiRequestQueryParam) => void;
    handleDelete?: () => void;
}

function ApiRequestQueryParamComponent({
    label,
    required,
    context,
    idEditor,
    conditions,
    handleDelete,
    handleUpdate,
    queryParam,
}: ApiParamProps): JSX.Element {
    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <div className={styles.bullet}></div>
                <div className={styles.keyInput}>
                    {!label && (
                        <RoundedTextField
                            label="llave"
                            value={queryParam.key}
                            onChange={(e): void => {
                                handleUpdate({
                                    ...queryParam,
                                    key: e.target.value,
                                });
                            }}
                        ></RoundedTextField>
                    )}
                    {label && label}
                </div>
                {handleDelete && (
                    <div className={styles.deleteBtn} onClick={handleDelete}>
                        <DeleteRoundedIcon fontSize="inherit" />
                    </div>
                )}
            </div>
            <div className={styles.bottom}>
                <div className={styles.stepInfoContainer}>
                    <div className={styles.queryValueEditor}>
                        <PayloadEditor
                            idEditor={idEditor}
                            media={false}
                            placeholder="Valor"
                            multiline={false}
                            type={TextEditorTypes.INPUT}
                            context={context}
                            conditions={conditions}
                            payload={{
                                draft: queryParam.value,
                            }}
                            onChange={({ draft }): void => {
                                if (!draft) return;
                                handleUpdate({
                                    ...queryParam,
                                    value: draft,
                                });
                            }}
                        />
                    </div>
                    <div className={styles.requiredContainer}>
                        <div className={styles.requiredLabel + ' noselect'}>
                            Obligatorio:
                        </div>
                        <div className={styles.toggleContainer}>
                            <Toggle
                                disabled={required}
                                checked={queryParam.required === true}
                                onChange={(checked: boolean): void => {
                                    handleUpdate({
                                        ...queryParam,
                                        required: checked,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ApiRequestQueryParamComponent;
