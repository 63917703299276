import { IconProps } from './@IconTypes';

function LockedIcon({ className, style, fill }: IconProps): JSX.Element {
    return (
        <svg
            fill={fill}
            style={style}
            className={className}
            version="1.1"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M81.9,49.6c0-9-0.4-9.4-9.3-9.7c0,0,0,0,0,0c0-4,0-9-0.1-12.1C72,16,64.7,8.9,53,8.7c-2,0-4,0-6.1,0  c-13,0.1-20.6,8-20.4,20.9c0,0,0,0.1,0,0.1V40c-0.7,0-1.4,0-2.2,0c-5.8,0-7.1,1.4-7.1,7.2c0,12.3,0.1,24.6-0.1,36.9  c-0.1,4.7,1.8,6.9,6.5,6.9c17.4,0,34.7,0,52.1,0c4.4,0,6.4-2,6.3-6.5C81.8,72.9,81.9,61.3,81.9,49.6z M53.1,67.5v5.8  c0,2-1.6,3.5-3.5,3.5S46,75.2,46,73.3v-5.8c-2.9-1.3-5-4.3-5-7.8c0-4.7,3.8-8.5,8.5-8.5c4.7,0,8.5,3.8,8.5,8.5  C58.1,63.2,56,66.2,53.1,67.5z M61.7,39.9C61.7,39.9,61.7,39.9,61.7,39.9c-8.1,0-16.1,0.1-24.2,0.1v-8.5c0.1-0.6,0.1-1.2,0.1-1.7  c-0.1-7.3,4-10.3,13-10.1c7.4,0.1,11.1,3.6,11.1,10.6C61.7,33.5,61.7,36.7,61.7,39.9z" />
        </svg>
    );
}
export default LockedIcon;
