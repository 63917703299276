import styles from './DecisionNode.module.css';
import React from 'react';
import { Handle, Position } from 'react-flow-renderer';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import { DecisionData } from '../../../../@Types/FlowTypes/NodeTypes/DecisionNode';
interface DecisionNodeProps {
    /** Node data needed to display the node */
    data: DecisionData & {
        /** Function used to open the dialog  */
        openDialog: Function;
        error: boolean;
    };
    /** NodeId */
    id: string;
}

function DecisionNode({ data, id }: DecisionNodeProps): JSX.Element {
    return (
        <>
            <Handle
                className={styles.leftHandle}
                type="target"
                position={Position.Left}
                style={{
                    top: 'calc((100% - 35px) / 2 + 25px )',
                    background: 'var(--primary1)',
                    border: '1px solid var(--accent)',
                }}
            />
            <div
                className={styles.container}
                style={
                    data.error
                        ? {
                              border: 'var(--error) 1px solid',
                              marginTop: '-1px',
                              marginLeft: '-1px',
                          }
                        : {}
                }
            >
                <div className={styles.top}>
                    <label className={styles.title}>{data.name}</label>
                    <div
                        className={styles.config}
                        onClick={(): void => {
                            data.openDialog();
                        }}
                    >
                        <SettingsRoundedIcon fontSize="inherit" />
                    </div>
                </div>
                <div className={styles.contentContainer}>
                    {data.decisions.map((desision, index: number) => (
                        <div key={index} className={styles.decisionContainer}>
                            <label className={styles.decisionLbl}>
                                {desision.name}
                            </label>
                        </div>
                    ))}
                    {
                        <div
                            key={'OTHERS'}
                            className={styles.decisionContainer}
                        >
                            <label className={styles.decisionLbl}>
                                Lo Demás/Otros
                            </label>
                        </div>
                    }
                </div>
            </div>
            {data.decisions.map((decision, index) => (
                <Handle
                    className={styles.rightHandle}
                    key={index}
                    type="source"
                    position={Position.Right}
                    id={id + '-' + decision.id}
                    style={{
                        top: 35 + index * 25,
                        right: 4,
                        background: 'var(--primary1)',
                        border: '1px solid var(--accent)',
                        height: 4,
                        width: 4,
                    }}
                />
            ))}
            <Handle
                className={styles.rightHandle}
                key={'OTHERS'}
                type="source"
                position={Position.Right}
                id={id + '-' + data.else}
                style={{
                    top: 35 + data.decisions.length * 25,
                    right: 4,
                    background: 'var(--primary1)',
                    border: '1px solid var(--accent)',
                    height: 4,
                    width: 4,
                }}
            />
        </>
    );
}

export default React.memo(DecisionNode, (prevProps, nextProps) => {
    if (prevProps.data !== nextProps.data) {
        return false;
    }
    return true;
}) as typeof DecisionNode;
