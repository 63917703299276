import { useDispatch } from 'react-redux';
import StepTypes from '../../../../../constants/Conversations/ConversationStepTypes';
import { StepLocation } from '../../../../../controllers/ConversationEditorController/ConversationEditorReducer';
import { createConversationStep } from '../../StepFunctions';
import { addStep } from '../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import ApiIcon from '../../../../../Icons/ApiIcon';
import EurekaElementMenuElement from '../../../../../shared/@Menus/ElementMenu/EurekaElementMenuElement';
import EurekaElementMenuGroup from '../../../../../shared/@Menus/ElementMenu/EurekaElementMenuGroup';
import ListIcon from '../../../../../Icons/ListIcon';
interface ListStepMenuProps {
    location: StepLocation;
    handleClose: Function;
}
function ListStepMenu({
    location,
    handleClose,
}: ListStepMenuProps): JSX.Element {
    const dispatch = useDispatch();

    const handleAdd = (type: StepTypes): void => {
        dispatch(addStep({ step: createConversationStep(type), location }));
        handleClose();
    };

    return (
        <EurekaElementMenuGroup
            label={'Lista de Opciones'}
            icon={ListIcon}
            style={{ fontSize: 18 }}
            renderElements={(props): JSX.Element => (
                <>
                    <EurekaElementMenuElement
                        {...props}
                        Icon={ListIcon}
                        onClick={(): void => {
                            handleAdd(StepTypes.LIST_STEP);
                        }}
                        Label={'Lista con Secciones'}
                    />
                    <EurekaElementMenuElement
                        {...props}
                        Icon={ListIcon}
                        onClick={(): void => {
                            handleAdd(StepTypes.LIST_PAGE_STEP);
                        }}
                        Label={'Lista Paginada'}
                    />
                    <EurekaElementMenuElement
                        {...props}
                        Icon={ApiIcon}
                        onClick={(): void => {
                            handleAdd(StepTypes.LIST_API_STEP);
                        }}
                        Label={'Integración con API'}
                    />
                </>
            )}
            zIndex={1303}
        />
    );
}

export default ListStepMenu;
