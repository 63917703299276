import { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import styles from './AreasFilter.module.css';
import { Area } from '../../@Types/@Types';
import ApartmentRoundedIcon from '@material-ui/icons/ApartmentRounded';
import { Popper } from '@material-ui/core';

interface StyleProps {
    /** material ui style seed */
    seed?: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
}));

const useTextfieldStyles = (props: StyleProps): Function =>
    makeStyles(() => ({
        root: {
            [`& .${props.seed}MuiOutlinedInput-root:hover .${props.seed}MuiOutlinedInput-notchedOutline`]:
                {
                    borderColor: 'var(--secondary)',
                },
            [`& .${props.seed}MuiOutlinedInput-notchedOutline`]: {
                borderColor: '#0000003b',
            },
            [`& .${props.seed}MuiInputLabel-formControl`]: {
                marginTop: -10,
            },
            [`& .${props.seed}MuiOutlinedInput-root`]: {
                borderRadius: 10,
                '&.Mui-focused fieldset': {
                    borderColor: '#0000003b',
                },
                [`& .${props.seed}MuiAutocomplete-input`]: {
                    height: 22,
                    marginTop: -10,
                    fontSize: 12,
                    lineHeight: '12px',
                },
                [`& .${props.seed}MuiAutocomplete-input::placeholder`]: {
                    color: 'var(--greyFont)',
                    opacity: 1,
                },
                height: 22,
            },
            height: 22,
        },
    }));

interface RoundedMultiSelectProps extends StyleProps {
    /** Possible Options to select from */
    options: Area[];
    /** Currently selected values */
    value: Area | null;
    /** Function called when value is selected or deleted */
    handleUpdate: Function;
}
export default function AreasFilter({
    options,
    value,
    handleUpdate,
    seed = 'Erk-',
}: RoundedMultiSelectProps): JSX.Element {
    const classes = useStyles();
    const textFieldStyles = useTextfieldStyles({ seed })();
    const [inputValue, setInputValue] = useState('');
    return (
        <div className={classes.root}>
            <Autocomplete
                size="small"
                options={options}
                PopperComponent={({ style, ...props }: any): JSX.Element => (
                    <Popper {...props} style={{ ...style, zIndex: 1305 }} />
                )}
                getOptionLabel={(option: Area): string => {
                    if (option.name) {
                        return option.name;
                    } else {
                        return '';
                    }
                }}
                value={value}
                inputValue={inputValue}
                onInputChange={(_, newInputValue): void => {
                    setInputValue(newInputValue);
                }}
                renderOption={(area: Area): JSX.Element => (
                    <div className={styles.areaContainer}>
                        <div className={styles.iconContainer}>
                            <ApartmentRoundedIcon fontSize="inherit" />
                        </div>
                        <div className={styles.label}>{area.name}</div>
                    </div>
                )}
                onChange={(event, values): void => {
                    handleUpdate(values);
                }}
                renderInput={(params): JSX.Element => (
                    <TextField
                        className={textFieldStyles.root}
                        {...params}
                        variant="outlined"
                        label={''}
                        placeholder={'Filtrar'}
                    />
                )}
            />
        </div>
    );
}
