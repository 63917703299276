import ConditionOperators from '../constants/Conditions/ConditionOperators';
import ErkValueTypes from '../constants/ErkValueTypes';
import IconTypes from '../constants/IconTypes';
import IntegrationTypes, {
    IntegrationConfigurationTypes,
} from '../constants/IntegrationTypes';
import { ApiRequest } from './ApiRequest';
import { ErkValue } from './ErkValue';

export type Integration =
    | ConditionIntegration
    | DraftIntegration
    | DocumentIntegration
    | EntityValueFilterIntegration;

interface BaseIntegration {
    _id: string;
    name: string;
    icon?: IconTypes;
    active: boolean;
    description: string;
    idEntity?: string;
    idProject?: string;
    type: IntegrationTypes;
    configuration: IntegrationConfiguration;
}

export type IntegrationConfiguration =
    | LambdaIntegrationConfiguration
    | ApiIntegrationConfiguration;

export interface LambdaIntegrationConfiguration {
    type: IntegrationConfigurationTypes.LAMBDA;
    lambda?: string;
    integrationKey: string;
    payload: Record<string, LambdaIntegrationConfigurationParameter>;
}

export interface LambdaIntegrationConfigurationParameter {
    name: string;
    required: boolean;
    description: string;
    defaultValue?: ErkValue;
    configurableType?: ErkValueTypes;
}

export interface ApiIntegrationConfiguration {
    type: IntegrationConfigurationTypes.API;
    request: ApiRequest;
}

export interface ConditionIntegration extends BaseIntegration {
    type: IntegrationTypes.CONDITION;
    operators?: ConditionOperators[];
    valueLabel: string;
    valueType: 'string' | 'string-list' | 'none';
}

export interface DraftIntegration extends BaseIntegration {
    type: IntegrationTypes.DRAFT;
    editorText?: string;
    existsCondition?: boolean;
}

export interface DocumentIntegration extends BaseIntegration {
    type: IntegrationTypes.DOCUMENT;
}

export interface EntityValueFilterIntegration extends BaseIntegration {
    type: IntegrationTypes.ENTITY_VALUES_FILTER;
    idEntity: string;
    idProperty: string;
}

export function isIntegration(integration: any): integration is Integration {
    return (
        integration && !!IntegrationTypes[integration.type as IntegrationTypes]
    );
}
