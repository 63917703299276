import React from 'react';
import { useDispatch } from 'react-redux';
import StepTypes from '../../../../../constants/Conversations/ConversationStepTypes';
import { StepLocation } from '../../../../../controllers/ConversationEditorController/ConversationEditorReducer';
import { createConversationStep } from '../../StepFunctions';
import { addStep } from '../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import WarningIcon from '@material-ui/icons/Warning';
import EurekaElementMenuElement from '../../../../../shared/@Menus/ElementMenu/EurekaElementMenuElement';
import EurekaElementMenuGroup from '../../../../../shared/@Menus/ElementMenu/EurekaElementMenuGroup';
import ReorderIcon from '../../../../../Icons/ReorderIcon';
interface NavStepMenuProps {
    location: StepLocation;
    handleClose: Function;
}
function NavStepMenu({ location, handleClose }: NavStepMenuProps): JSX.Element {
    const dispatch = useDispatch();

    const handleAdd = (type: StepTypes): void => {
        dispatch(addStep({ step: createConversationStep(type), location }));
        handleClose();
    };

    return (
        <EurekaElementMenuGroup
            label={'Navegación'}
            icon={(props): JSX.Element => (
                <ReorderIcon
                    {...props}
                    style={{ ...props.style, transform: 'scaleY(-1)' }}
                />
            )}
            style={{ fontSize: 18 }}
            renderElements={(props): JSX.Element => (
                <>
                    <EurekaElementMenuElement
                        {...props}
                        Icon={(props): JSX.Element => (
                            <ReorderIcon
                                {...props}
                                style={{
                                    ...props.style,
                                    transform: 'scaleY(-1)',
                                }}
                            />
                        )}
                        onClick={(): void => {
                            handleAdd(StepTypes.NAV_BACK_STEP);
                        }}
                        Label={'Devolverse'}
                    />
                    <EurekaElementMenuElement
                        {...props}
                        Icon={WarningIcon}
                        onClick={(): void => {
                            handleAdd(StepTypes.NAV_EXIT_STEP);
                        }}
                        Label={'Finalizar'}
                    />
                </>
            )}
            zIndex={1303}
        />
    );
}

export default NavStepMenu;
