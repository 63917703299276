import { Client } from '../../../@Types/Client';
import {
    loadClientsById,
    loadClients,
} from '../../../controllers/ClientsController/ClientsService';
import GenericMenu, { GenericMenuProps } from '../GenericMenu/GenericMenu';

function ClientsMenu({
    title = 'Vincular Cliente',
    ...props
}: Omit<
    GenericMenuProps<Client>,
    'calcLbl' | 'loadInitialType' | 'loadElements' | 'label'
> & { title?: string }): JSX.Element {
    return (
        <GenericMenu<Client>
            {...props}
            title={title}
            label="Cliente"
            calcLbl={(client): string =>
                client.lastName
                    ? client.name + ' ' + client.lastName
                    : client.name ?? 'Sin Nombre'
            }
            loadInitialType={loadClientsById}
            loadElements={async (
                page: number,
                pageSize: number,
                search: string | undefined
            ): Promise<Client[]> => {
                return await loadClients(page, pageSize, {}, undefined, search);
            }}
        />
    );
}
export default ClientsMenu;
