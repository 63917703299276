import { IconProps } from './@IconTypes';

function StickerIcon({ className, style, fill }: IconProps): JSX.Element {
    return (
        <svg
            fill={fill}
            style={style}
            className={className}
            version="1.1"
            viewBox="160 180 400 400"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                stroke={fill}
                d="m432.83 233.93h-113.66c-22.594 0.050781-44.246 9.0469-60.223 25.023-15.977 15.977-24.973 37.629-25.023 60.223v113.66c0.050781 22.594 9.0469 44.246 25.023 60.223 15.977 15.973 37.629 24.973 60.223 25.02h75.773c43.57 0 123.13-79.371 123.13-123.13v-75.773c-0.046875-22.594-9.0469-44.246-25.02-60.223-15.977-15.977-37.629-24.973-60.223-25.023zm-179.96 198.91v-113.66c0-17.586 6.9844-34.449 19.418-46.883 12.434-12.434 29.297-19.418 46.883-19.418h113.66c17.582 0 34.449 6.9844 46.883 19.418 12.434 12.434 19.418 29.297 19.418 46.883v66.301h-28.605c-22.512 0.14844-44.059 9.1602-59.977 25.078s-24.93 37.465-25.078 59.977v28.605h-66.301c-17.586 0-34.449-6.9844-46.883-19.418-12.434-12.434-19.418-29.301-19.418-46.883zm151.55 64.598v-26.902c0.097656-17.504 7.0938-34.262 19.473-46.637 12.375-12.379 29.133-19.375 46.637-19.473h26.898c-18.625 41.305-51.703 74.383-93.008 93.008z"
            />
        </svg>
    );
}

export default StickerIcon;
