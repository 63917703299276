import { Visualization } from '../../@Types/Metabase';

export type AnaliticaConfig = {
    tabs: Record<string, Partial<Visualization>>;
    idsMap: Record<number, string>;
};

export type TabConfig = Visualization;

export const prodesaAnaliticaConfig: AnaliticaConfig = {
    tabs: {
        'Tablero Analítica': {
            title: 'Tablero analítica',
            type: 'CompoundedDashboard',
            ids: {
                251: 'Control',
                252: 'Edades',
                253: 'Agentes',
                254: 'Tipologías',
                255: 'Unidades',
                256: 'Control (P)',
                257: 'Satisfacción Cierre (P)',
                258: 'Daño (P)',
                259: 'Reincidencias (P)',
                260: 'Motivo Rechazo (P)',
                261: 'Tabla Monitoreo',
            },
            idsOrder: [251, 252, 253, 254, 255, 256, 257, 258, 259, 260, 261],
            order: 1,
        },
        'Tablero Control': {
            title: 'Tablero control',
            order: 3,
        },
        'Tablero Postventa': {
            title: 'Tablero postventa',
            order: 2,
        },
        'Actividad Agentes': {
            title: 'Actividad agentes',
            order: 4,
        },
        'Desempeño De Agentes': {
            title: 'Desempeño de agentes',
            order: 5,
        },
    },
    idsMap: {
        251: 'Tablero Analítica',
        252: 'Tablero Analítica',
        253: 'Tablero Analítica',
        254: 'Tablero Analítica',
        255: 'Tablero Analítica',
        256: 'Tablero Analítica',
        257: 'Tablero Analítica',
        258: 'Tablero Analítica',
        259: 'Tablero Analítica',
        260: 'Tablero Analítica',
        261: 'Tablero Analítica',
        158: 'Tablero Postventa',
        212: 'Tablero Control',
        3772: 'Actividad Agentes',
        150: 'Desempeño De Agentes',
    },
};
