import { AnyAction } from '@reduxjs/toolkit';
import { Company } from '../../@Types/@Types';
import * as Actions from './CompaniesActions';
import ScrollTypes from '../../constants/ScrollTypes';
const defaultOrderBy = 'latest';

export interface CompaniesPageState {
    /** The currently displayed Companies */
    elements: Company[];
    /** The currently active filters */
    filters: any[]; //TODO Definir filtros
    /** The  way the elements are currently ordered. undefined if search is active*/
    orderBy: string | undefined; //TODO definir orderby
    /** The current search, undefined if none */
    search: string | undefined;
    /** the index of the currently selectedElement */
    selectedElement: number | undefined;
    /** The number of pages currently loaded in the list */
    pageUp: number;
    pageDown: number;
    /** Active if no more pages are available */
    lastPage: boolean;
    /** If loader is active */
    loading: boolean;
}

const initialState = {
    elements: [],
    filters: [],
    orderBy: defaultOrderBy,
    search: undefined,
    selectedElement: undefined,
    pageUp: 1,
    pageDown: 1,
    lastPage: false,
    loading: true,
};

/**
 * Redux Reducer that handles CompaniesActions Tiggers
 * @param state The current state
 * @param action the action that was triggered
 * @returns the new state
 */
const CompaniesReducer = (
    state: CompaniesPageState = initialState,
    action: AnyAction
): CompaniesPageState => {
    if (Actions.reset.match(action)) {
        return {
            ...state,
            elements: [],
            filters: [],
            orderBy: defaultOrderBy,
            search: undefined,
            selectedElement: undefined,
            pageUp: 1,
            pageDown: 1,
            lastPage: false,
            loading: true,
        };
    } else if (Actions.resetSuccess.match(action)) {
        return {
            ...state,
            loading: false,
            elements: action.payload.elements,
            selectedElement: action.payload.selectedElement,
            pageUp: action.payload.page,
            pageDown: action.payload.page,
            lastPage: action.payload.lastPage,
            filters: action.payload.filters,
            orderBy: action.payload.orderBy,
            search: action.payload.search,
        };
    } else if (Actions.setLoading.match(action)) {
        return {
            ...state,
            loading: action.payload,
        };
    } else if (Actions.getSuccess.match(action)) {
        return {
            ...state,
            loading: false,
            selectedElement: action.payload.selectedElement,
            elements: action.payload.elements,
            lastPage: action.payload.lastPage,
        };
    } else if (Actions.pageScroll.match(action)) {
        if (action.payload === ScrollTypes.DOWN) {
            return { ...state, pageDown: state.pageDown + 1 };
        } else if (state.pageUp > 1)
            return { ...state, pageUp: state.pageUp - 1 };
    } else if (Actions.selectElement.match(action)) {
        return { ...state, selectedElement: action.payload };
    } else if (Actions.refreshSuccess.match(action)) {
        return {
            ...state,
            elements: state.elements.map((element) =>
                element._id === action.payload._id ? action.payload : element
            ),
        };
    } else if (Actions.search.match(action)) {
        return {
            ...state,
            elements: [],
            selectedElement: undefined,
            pageUp: 1,
            pageDown: 1,
            lastPage: false,
            loading: true,
            search: action.payload === '' ? undefined : action.payload,
            orderBy: action.payload === '' ? defaultOrderBy : undefined,
        };
    } else if (Actions.orderBy.match(action)) {
        return {
            ...state,
            search: undefined,
            orderBy: action.payload,
        };
    } else if (Actions.filter.match(action)) {
        return {
            ...state,
            filters: action.payload,
        };
    }
    return state;
};
export function companyToQueryString(
    filters: Record<string, any>,
    orderBy: string | undefined,
    search: string | undefined,
    ignoreDefault?: boolean
): string {
    const url = new URLSearchParams();
    if (search) {
        url.set('search', encodeURIComponent(search));
    }
    if ((ignoreDefault && orderBy !== defaultOrderBy) || !ignoreDefault) {
        url.set('orderBy', orderBy ?? defaultOrderBy);
    }
    return url.toString();
}
export default CompaniesReducer;
