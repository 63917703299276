import styles from './FileStep.module.css';
import { FileStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import { useDispatch } from 'react-redux';
import { updateStep } from '../../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import {
    ApiKeyContext,
    BaseConversationPayloadConditionTypes,
} from '../../../ConversationEditor';
import PayloadEditor from '../../../../../../shared/TextEditor/PayloadEditor';
import { useContext } from 'react';
import { TextEditorTypes } from '../../../../../../constants/TextEditorTypes';

interface FileStepProps {
    step: FileStep;
}

function FileStepComponent({ step }: FileStepProps): JSX.Element {
    const dispatch = useDispatch();
    const apiKey = useContext(ApiKeyContext);
    return (
        <div className={styles.container}>
            <div className={styles.editorContainer}>
                <PayloadEditor
                    placeholder={'Mensaje'}
                    type={TextEditorTypes.WHATSAPP}
                    idEditor={`Conversation-${apiKey}-${step.id}`}
                    payload={{ draft: step.message }}
                    onChange={({ draft: message }): void => {
                        if (!message) return;
                        dispatch(updateStep({ ...step, message }));
                    }}
                    media={false}
                    context={{ client: true }}
                    conditions={{
                        types: BaseConversationPayloadConditionTypes,
                        context: {
                            conversation: {
                                idStep: step.id,
                            },
                        },
                    }}
                    minHeight={144}
                    maxHeight={400}
                    required
                    maxLength={4096}
                />
            </div>
            {/* <div className={styles.toggleLabelContainer}>
                Mostrar en la Traza:
                <div className={styles.toggleContainer}>
                    <Toggle
                        checked={step.showInTrace}
                        onChange={(checked: boolean): void => {
                            dispatch(
                                updateStep({ ...step, showInTrace: checked })
                            );
                        }}
                    />
                </div>
            </div> */}
        </div>
    );
}

export default FileStepComponent;
