import React from 'react';
import { CBRElementStep as ElementStepType } from '../../../../../../@Types/ConversationTypes/CBRConversationStep';
import ListApiStep from '../../Collection/ListApiStep/ListApiStep';
import StepComponent, { StepComponentProps } from '../../Step';

interface CBRElementStepProps extends StepComponentProps {
    step: ElementStepType;
}

function CBRElementStep({ step, path }: CBRElementStepProps): JSX.Element {
    const renderSubStep = (): JSX.Element | void => {
        if (step.subStep) {
            return (
                <StepComponent
                    idStep={step.subStep}
                    path={
                        [
                            ...path,
                            {
                                idStep: step.id,
                                type: 'CBR_ELEMENT',
                                label: step.name,
                                controlled: true,
                            },
                        ] as any
                    }
                />
            );
        }
    };

    return (
        <React.Fragment>
            <ListApiStep step={step as any} path={path} />
            {renderSubStep()}
        </React.Fragment>
    );
}

export default CBRElementStep;
