import React, { useEffect, useState } from 'react';
import ListDetailLayout from '../../shared/ListDetailLayout/ListDetailLayout';
import PageTitle from '../../shared/PageTitle/PageTitle';
import Tree from '../../shared/Tree/Tree';
import ClassifierTreeItem from './ClassifierTreeItem/ClassifierTreeItem';
import ClassifierDetail from './ClassifierDetail/ClassifierDetail';
import Dialog from '../../shared/Dialog/Dialog';
import ConfirmDialog from '../../shared/ConfirmDialog/ConfirmDialog';
import CreateClassifier from './CreateClassifier/CreateClassifier';
import RoundedButton from '../../shared/RoundedButton/RoundedButton';
import Search from '../../shared/Search/Search';
import { useSelector, useDispatch } from 'react-redux';
import classifierService from '../../controllers/ClassifiersController/ClassifiersService';
import {
    reset,
    refresh,
    setLoading,
    selectElement,
    updateElement,
    search,
} from '../../controllers/ClassifiersController/ClassifiersActions';
import { RootState } from '../../utils/_store';
import { Classifier } from '../../@Types/Classifier';
import { refreshClassifiers } from '../../controllers/_SiteController/SiteActions';
function ClassifiersPage(): JSX.Element {
    const dispatch = useDispatch();
    const siteInfo = useSelector((state: RootState) => state.site);
    const pageInfo = useSelector((state: RootState) => state.classifiersPage);
    const [createDialog, setCreateDialog] = useState<any>(undefined);
    const [deleteDialog, setDeleteDialog] = useState(undefined);

    /** If project changes reset the content */
    useEffect(() => {
        dispatch(reset());
    }, [siteInfo.idProject]);

    return (
        <React.Fragment>
            {createDialog !== undefined && (
                <Dialog
                    open={createDialog !== undefined}
                    onClose={(): void => setCreateDialog(undefined)}
                >
                    <CreateClassifier
                        parent={createDialog}
                        close={(): void => setCreateDialog(undefined)}
                        handleCreated={(): void => {
                            dispatch(refresh());
                            dispatch(refreshClassifiers());
                        }}
                    />
                </Dialog>
            )}
            {deleteDialog !== undefined && (
                <ConfirmDialog
                    onConfirm={async (
                        element: Classifier,
                        setLoading: Function
                    ): Promise<void> => {
                        if (siteInfo.idProject) {
                            try {
                                setLoading(true);
                                await classifierService.deleteClassifier(
                                    siteInfo.idProject,
                                    element._id
                                );
                                dispatch(reset());
                                dispatch(refreshClassifiers());
                                setDeleteDialog(undefined);
                            } catch (error) {
                                //TODO: handle errors
                                console.error(error);
                                setLoading(false);
                            }
                        }
                    }}
                    deleting
                    element={deleteDialog}
                    title="Borrar Clasificador"
                    btnMsg="Borrar"
                    msg="¿Está seguro de que desea borrar este clasificador?"
                    onClose={(): void => {
                        setDeleteDialog(undefined);
                    }}
                />
            )}
            <div className="standard-layout">
                <PageTitle
                    title={'Clasificadores'}
                    route={
                        siteInfo?.projects[siteInfo?.idProject ?? '']?.name +
                        ' / Clasificadores'
                    }
                />
                <div className="filters-container flexbox">
                    <div data-testid={'ClassifiersPage_newButton'}>
                        <RoundedButton
                            text="Nuevo"
                            backgroundColor={'var(--secondary)'}
                            color={'white'}
                            onClick={(): void => {
                                setCreateDialog(null);
                            }}
                        />
                    </div>

                    <div className="search-container">
                        <Search
                            search={pageInfo.search}
                            handleLoadingSearch={(loading: boolean): void => {
                                dispatch(setLoading(loading));
                            }}
                            handleSearch={(value: string | undefined): void => {
                                dispatch(search(value));
                            }}
                        />
                    </div>
                </div>

                <ListDetailLayout
                    listWidth={450}
                    responsiveBreakpoint={1000}
                    selectElementLabel="Selecciona un clasificador"
                    loading={pageInfo.loading}
                    selectedElement={pageInfo.selectedElement}
                    handleSelectedElement={(
                        idSelectedElement: string
                    ): void => {
                        dispatch(selectElement(idSelectedElement));
                    }}
                >
                    <Tree
                        elements={pageInfo.elements}
                        roots={pageInfo.roots}
                        label="No hay clasificadores"
                        saveOrderChanges={async (
                            pRoots: string[]
                        ): Promise<void> => {
                            if (siteInfo.idProject) {
                                try {
                                    setLoading(true);
                                    await classifierService.updateOrderClassifiers(
                                        siteInfo.idProject,
                                        pRoots
                                    );
                                    dispatch(refreshClassifiers());
                                    dispatch(refresh());
                                } catch (error) {
                                    //TODO: handle errors
                                    console.error(error);
                                    setLoading(false);
                                }
                            }
                        }}
                    >
                        <ClassifierTreeItem isRoot={true} />
                    </Tree>
                    <ClassifierDetail
                        elements={pageInfo.elements}
                        handleClickDelete={setDeleteDialog}
                        handleUpdate={(
                            object: Classifier,
                            field: keyof Classifier,
                            value: string
                        ): void => {
                            dispatch(updateElement({ object, field, value }));
                        }}
                        createSubStructure={setCreateDialog}
                    />
                </ListDetailLayout>
            </div>
        </React.Fragment>
    );
}

export default ClassifiersPage;
