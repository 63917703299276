import { MenuItem } from '@material-ui/core';
import React from 'react';
import EntityPropertyOperators from '../../../../../constants/Conditions/EntityPropertyOperators';
import EntityProperties from '../../../../../constants/EntityPropertyTypes';
import RoundedSelect from '../../../../RoundedSelect/RoundedSelect';
import styles from '../../Condition.module.css';
import entityStyles from '../ClientCondition.module.css';

import { ClientEntity } from '../../../../../@Types/@Types';
import RoundedMultiSelect from '../../../../RoundedMultiSelect/RoundedMultiSelect';
import RoundedCheckBox from '../../../../RoundedCheckBox/RoundedCheckBox';
import RoundedDatePicker from '../../../../@Pickers/RoundedDatePicker/RoundedDatePicker';
import RoundedTextField from '../../../../RoundedTextField/RoundedTextField';
import {
    ClientCheckboxCondition,
    ClientDateCondition,
    ClientInfoTypeCondition,
    ClientPermissionCondition,
    ClientPropertyCondition,
    ClientTextAreaCondition,
    ClientTextInputCondition,
    ClientTimeCondition,
} from '../../../../../@Types/ConditionTypes/ClientCondition';
import {
    ClientInfoTypeLabels,
    ClientInfoTypes,
} from '../../../../../constants/ClientInfoTypes';
import ClientPropertyTypes from '../../../../../constants/ClientPropertyTypes';
import { ConditionProps } from '../../../Condition';
import EntityPropertyTypes from '../../../../../constants/EntityPropertyTypes';
import RoundedPhoneInput from '../../../../RoundedPhoneInput/RoundedPhoneInput';
import { isPhoneStep } from '../../../../../utils/PhoneFunctions';
interface ClientPropertyConditionProps
    extends ConditionProps<ClientPropertyCondition> {
    /** The entity to decide on */
    entity: ClientEntity;
}
function ClientPropertyConditionComponent({
    entity,
    condition,
    hoverDelete,
    handleUpdate,
}: ClientPropertyConditionProps): JSX.Element {
    const entityProperty = entity.steps[condition.idProperty ?? ''];

    const renderPropertyOperator = (): JSX.Element => {
        if (
            Object.values(ClientInfoTypes).includes(
                condition.propertyType as ClientInfoTypes
            ) ||
            condition.propertyType === ClientPropertyTypes.PERMISSIONS
        ) {
            return (
                <div className={styles.selectorContainer}>
                    <RoundedSelect
                        value={condition.propertyOperator ?? ''}
                        label="Operador"
                        containerMargin="0px"
                        error={hoverDelete}
                        handleUpdate={(event): void => {
                            handleUpdate({
                                ...condition,
                                propertyOperator: event.target.value,
                            });
                        }}
                    >
                        <MenuItem value={EntityPropertyOperators.EQUAL}>
                            Igual a
                        </MenuItem>
                        <MenuItem value={EntityPropertyOperators.NOTEQUAL}>
                            No igual a
                        </MenuItem>
                        <MenuItem value={EntityPropertyOperators.EXISTS}>
                            Existente
                        </MenuItem>
                        <MenuItem value={EntityPropertyOperators.NOTEXISTS}>
                            No existente
                        </MenuItem>
                    </RoundedSelect>
                </div>
            );
        }
        switch (condition.propertyType) {
            case EntityProperties.TEXTINPUT:
            case EntityProperties.CHECKBOX: {
                return (
                    <div className={styles.selectorContainer}>
                        <RoundedSelect
                            value={condition.propertyOperator ?? ''}
                            label="Operador"
                            containerMargin="0px"
                            error={hoverDelete}
                            handleUpdate={(event): void => {
                                handleUpdate({
                                    ...condition,
                                    propertyOperator: event.target.value,
                                });
                            }}
                        >
                            <MenuItem value={EntityPropertyOperators.EQUAL}>
                                Igual a
                            </MenuItem>
                            <MenuItem value={EntityPropertyOperators.NOTEQUAL}>
                                No igual a
                            </MenuItem>
                            <MenuItem value={EntityPropertyOperators.EXISTS}>
                                Existente
                            </MenuItem>
                            <MenuItem value={EntityPropertyOperators.NOTEXISTS}>
                                No existente
                            </MenuItem>
                        </RoundedSelect>
                    </div>
                );
            }
            case EntityProperties.TEXTAREA: {
                return (
                    <div className={styles.selectorContainer}>
                        <RoundedSelect
                            value={condition.propertyOperator ?? ''}
                            label="Operador"
                            containerMargin="0px"
                            error={hoverDelete}
                            handleUpdate={(event): void => {
                                handleUpdate({
                                    ...condition,
                                    propertyOperator: event.target.value,
                                });
                            }}
                        >
                            <MenuItem value={EntityPropertyOperators.EQUAL}>
                                Contiene
                            </MenuItem>
                            <MenuItem value={EntityPropertyOperators.NOTEQUAL}>
                                No contiene
                            </MenuItem>
                            <MenuItem value={EntityPropertyOperators.EXISTS}>
                                Existente
                            </MenuItem>
                            <MenuItem value={EntityPropertyOperators.NOTEXISTS}>
                                No existente
                            </MenuItem>
                        </RoundedSelect>
                    </div>
                );
            }
            case EntityProperties.DATEPICKER: {
                return (
                    <div className={styles.selectorContainer}>
                        <RoundedSelect
                            value={condition.propertyOperator ?? ''}
                            label="Operador"
                            containerMargin="0px"
                            error={hoverDelete}
                            handleUpdate={(event): void => {
                                handleUpdate({
                                    ...condition,
                                    propertyOperator: event.target.value,
                                });
                            }}
                        >
                            <MenuItem value={EntityPropertyOperators.EQUAL}>
                                Igual a
                            </MenuItem>
                            <MenuItem value={EntityPropertyOperators.NOTEQUAL}>
                                No igual a
                            </MenuItem>
                            <MenuItem value={EntityPropertyOperators.LESS}>
                                Menor que
                            </MenuItem>
                            <MenuItem value={EntityPropertyOperators.MORE}>
                                Mayor que
                            </MenuItem>
                            <MenuItem value={EntityPropertyOperators.EXISTS}>
                                Existente
                            </MenuItem>
                            <MenuItem value={EntityPropertyOperators.NOTEXISTS}>
                                No existente
                            </MenuItem>
                        </RoundedSelect>
                    </div>
                );
            }
            case EntityProperties.TIMEPICKER: {
                if (condition.propertyType !== entityProperty.type)
                    return <></>;

                return (
                    <React.Fragment>
                        <div className={styles.selectorContainer}>
                            <RoundedSelect
                                value={condition.propertyOperator}
                                label="Operador"
                                containerMargin="0px"
                                error={hoverDelete}
                                handleUpdate={(event): void => {
                                    handleUpdate({
                                        ...condition,
                                        propertyOperator: event.target.value,
                                    });
                                }}
                            >
                                {(entityProperty.working === undefined ||
                                    entityProperty.pickDays ||
                                    entityProperty.pickHours ||
                                    entityProperty.pickMinutes) && (
                                    <MenuItem
                                        value={EntityPropertyOperators.INCLUDES}
                                    >
                                        Contiene
                                    </MenuItem>
                                )}
                                {(entityProperty.working === undefined ||
                                    entityProperty.pickDays ||
                                    entityProperty.pickHours ||
                                    entityProperty.pickMinutes) && (
                                    <MenuItem
                                        value={
                                            EntityPropertyOperators.NOTINCLUDES
                                        }
                                    >
                                        No Contiene
                                    </MenuItem>
                                )}
                                <MenuItem
                                    value={EntityPropertyOperators.EXISTS}
                                >
                                    Existente
                                </MenuItem>
                                <MenuItem
                                    value={EntityPropertyOperators.NOTEXISTS}
                                >
                                    No existente
                                </MenuItem>
                            </RoundedSelect>
                        </div>
                        {isTimeCondition(condition) && (
                            <div className={styles.selectorContainer}>
                                <RoundedSelect
                                    value={condition.property}
                                    label="Propiedad"
                                    containerMargin="0px"
                                    error={hoverDelete}
                                    handleUpdate={(event): void => {
                                        handleUpdate({
                                            ...condition,
                                            property: event.target.value,
                                        });
                                    }}
                                >
                                    {entityProperty.pickDays && (
                                        <MenuItem value={'days'}>Días</MenuItem>
                                    )}
                                    {entityProperty.pickHours && (
                                        <MenuItem value={'hours'}>
                                            Horas
                                        </MenuItem>
                                    )}
                                    {entityProperty.pickMinutes && (
                                        <MenuItem value={'minutes'}>
                                            Minutos
                                        </MenuItem>
                                    )}
                                    {entityProperty.working === undefined && (
                                        <MenuItem value={'working'}>
                                            Tipo de Tiempo
                                        </MenuItem>
                                    )}
                                </RoundedSelect>
                            </div>
                        )}
                        {isTimeCondition(condition) && condition.property && (
                            <div className={styles.selectorContainer}>
                                <RoundedSelect
                                    value={condition.timePropertyOperator}
                                    label="Operador"
                                    containerMargin="0px"
                                    error={hoverDelete}
                                    handleUpdate={(event): void => {
                                        handleUpdate({
                                            ...condition,
                                            timePropertyOperator:
                                                event.target.value,
                                        });
                                    }}
                                >
                                    <MenuItem
                                        value={EntityPropertyOperators.EQUAL}
                                    >
                                        Igual a
                                    </MenuItem>
                                    <MenuItem
                                        value={EntityPropertyOperators.NOTEQUAL}
                                    >
                                        No igual a
                                    </MenuItem>
                                    {condition.property !== 'working' && (
                                        <MenuItem
                                            value={EntityPropertyOperators.LESS}
                                        >
                                            Menor que
                                        </MenuItem>
                                    )}
                                    {condition.property !== 'working' && (
                                        <MenuItem
                                            value={EntityPropertyOperators.MORE}
                                        >
                                            Mayor que
                                        </MenuItem>
                                    )}
                                </RoundedSelect>
                            </div>
                        )}
                    </React.Fragment>
                );
            }
            case EntityProperties.DRAFT_INTEGRATION: {
                return (
                    <div className={styles.selectorContainer}>
                        <RoundedSelect
                            value={condition.propertyOperator}
                            label="Operador"
                            containerMargin="0px"
                            error={hoverDelete}
                            handleUpdate={(event): void => {
                                handleUpdate({
                                    ...condition,
                                    propertyOperator: event.target.value,
                                });
                            }}
                        >
                            <MenuItem value={EntityPropertyOperators.EXISTS}>
                                Existente
                            </MenuItem>
                            <MenuItem value={EntityPropertyOperators.NOTEXISTS}>
                                No existente
                            </MenuItem>
                        </RoundedSelect>
                    </div>
                );
            }
            default:
                return <div></div>;
        }
    };

    const renderPropertyValue = (): JSX.Element => {
        if (
            !condition.propertyOperator ||
            condition.propertyOperator === EntityPropertyOperators.EXISTS ||
            condition.propertyOperator === EntityPropertyOperators.NOTEXISTS
        ) {
            return <div></div>;
        }
        if (condition.propertyType === ClientInfoTypes.CEL) {
            const typedCondition = condition as ClientInfoTypeCondition;
            return (
                <div className={styles.selectorContainer}>
                    <RoundedPhoneInput
                        label={
                            ClientInfoTypeLabels[
                                condition.propertyType as ClientInfoTypes
                            ]
                        }
                        value={typedCondition.propertyValue ?? ''}
                        error={hoverDelete}
                        height="40px"
                        onChange={(propertyValue): void => {
                            handleUpdate({
                                ...condition,
                                propertyValue,
                            });
                        }}
                    />
                </div>
            );
        }
        if (
            Object.values(ClientInfoTypes).includes(
                condition.propertyType as ClientInfoTypes
            )
        ) {
            const typedCondition = condition as ClientInfoTypeCondition;
            return (
                <div className={styles.selectorContainer}>
                    <RoundedTextField
                        label={
                            ClientInfoTypeLabels[
                                condition.propertyType as ClientInfoTypes
                            ]
                        }
                        value={typedCondition.propertyValue ?? ''}
                        height="40px"
                        error={hoverDelete}
                        onChange={(event): void => {
                            handleUpdate({
                                ...condition,
                                propertyValue: event.target.value as any,
                            });
                        }}
                    />
                </div>
            );
        } else if (condition.propertyType === ClientPropertyTypes.PERMISSIONS) {
            const typedCondition = condition as ClientPermissionCondition;
            return (
                <div className={styles.checkboxContainer}>
                    <RoundedCheckBox
                        padding="0px"
                        size="2rem"
                        error={hoverDelete}
                        onChange={(event): void => {
                            handleUpdate({
                                ...condition,
                                propertyValue: event.target.checked as any,
                            });
                        }}
                        checked={Boolean(typedCondition.propertyValue)}
                    />
                </div>
            );
        }

        switch (entityProperty?.type) {
            case EntityProperties.TEXTINPUT: {
                const typedCondition = condition as ClientTextInputCondition;
                if (isPhoneStep(entityProperty)) {
                    return (
                        <div className={styles.selectorContainer}>
                            <RoundedPhoneInput
                                label={entityProperty.label}
                                value={typedCondition.propertyValue ?? ''}
                                error={hoverDelete}
                                height="40px"
                                onChange={(propertyValue): void => {
                                    handleUpdate({
                                        ...condition,
                                        propertyValue: propertyValue as any,
                                    });
                                }}
                            />
                        </div>
                    );
                }
                return (
                    <div className={styles.selectorContainer}>
                        <RoundedTextField
                            label={entityProperty.label}
                            value={typedCondition.propertyValue ?? ''}
                            height="40px"
                            error={hoverDelete}
                            onChange={(event): void => {
                                handleUpdate({
                                    ...condition,
                                    propertyValue: event.target.value as any,
                                });
                            }}
                        />
                    </div>
                );
            }
            case EntityProperties.TEXTAREA: {
                const typedCondition = condition as ClientTextAreaCondition;
                return (
                    <div className={styles.multiSelectContainer}>
                        <RoundedMultiSelect
                            values={typedCondition.propertyValues ?? []}
                            options={[]} //TODO: generar sugestiones.
                            maxTags={5}
                            label="Valores"
                            fullWidth
                            error={hoverDelete}
                            handleUpdate={(values: string[]): void => {
                                handleUpdate({
                                    ...typedCondition,
                                    propertyValues: values,
                                });
                            }}
                        />
                    </div>
                );
            }
            case EntityProperties.CHECKBOX: {
                const typedCondition = condition as ClientCheckboxCondition;
                return (
                    <div className={styles.checkboxContainer}>
                        <RoundedCheckBox
                            padding="0px"
                            size="2rem"
                            error={hoverDelete}
                            onChange={(event): void => {
                                handleUpdate({
                                    ...typedCondition,
                                    propertyValue: event.target.checked,
                                });
                            }}
                            checked={Boolean(typedCondition.propertyValue)}
                        />
                    </div>
                );
            }
            case EntityProperties.DATEPICKER: {
                const typedCondition = condition as ClientDateCondition;
                return (
                    <div className={styles.selectorContainer}>
                        <RoundedDatePicker
                            label={entityProperty.label}
                            value={typedCondition.propertyValue ?? null}
                            error={hoverDelete}
                            onChange={(date): void => {
                                if (date) {
                                    handleUpdate({
                                        ...condition,
                                        propertyValue: date as any,
                                    });
                                }
                            }}
                        />
                    </div>
                );
            }
            case EntityProperties.TIMEPICKER: {
                const typedCondition = condition as ClientTimeCondition;
                if (!typedCondition.property) return <></>;
                if (typedCondition.property === 'working') {
                    return (
                        <div className={styles.checkboxContainer}>
                            <RoundedCheckBox
                                padding="0px"
                                size="2rem"
                                error={hoverDelete}
                                onChange={(event): void => {
                                    handleUpdate({
                                        ...typedCondition,
                                        value: event.target.checked as any,
                                    });
                                }}
                                checked={Boolean(typedCondition.value)}
                            />
                        </div>
                    );
                } else {
                    return (
                        <div className={styles.selectorContainer}>
                            <RoundedTextField
                                value={typedCondition.value}
                                label={calcTimeConditionLabel(typedCondition)}
                                inputProps={{ min: 0 }}
                                onChange={(event): void => {
                                    const copy = { ...typedCondition };
                                    copy.value = parseInt(event.target.value);
                                    handleUpdate(copy);
                                }}
                                type="number"
                            ></RoundedTextField>
                        </div>
                    );
                }
            }
            default:
                return <div></div>;
        }
    };
    return (
        <React.Fragment>
            <div className={styles.operatorContainer}>
                {renderPropertyOperator()}
            </div>
            <div className={entityStyles.valueContainer}>
                {renderPropertyValue()}
            </div>
        </React.Fragment>
    );
}
export default ClientPropertyConditionComponent;

const isTimeCondition = (
    condition: ClientPropertyCondition
): condition is ClientTimeCondition => {
    return (
        condition.propertyType === EntityPropertyTypes.TIMEPICKER &&
        (condition.propertyOperator === EntityPropertyOperators.INCLUDES ||
            condition.propertyOperator === EntityPropertyOperators.NOTINCLUDES)
    );
};

function calcTimeConditionLabel(typedCondition: ClientTimeCondition): string {
    switch (typedCondition.property) {
        case 'days':
            return 'Días';
        case 'hours':
            return 'Horas';
        case 'minutes':
            return 'Minutos';
        case 'working':
            return 'Tipo de Tiempo';
        default:
            return '';
    }
}
