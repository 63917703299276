import { ClickAwayListener, Popper, PopperProps } from '@material-ui/core';
import styles from './EurekaElementMenu.module.css';
import React from 'react';
import { EurekaElementMenuElementStyle } from './EurekaElementMenuElement';

interface EurekaElementMenuProps {
    zIndex?: number;
    anchorEl: PopperProps['anchorEl'];
    handleClose: () => void;
    renderElements: (
        props: { zIndex: number; style: EurekaElementMenuElementStyle },
        handleClose: () => void
    ) => JSX.Element | JSX.Element[];
    placement?: PopperProps['placement'];
    style?: EurekaElementMenuElementStyle;
}

function EurekaElementMenu({
    anchorEl,
    handleClose,
    zIndex = 1300,
    renderElements,
    style = { fontSize: 16 },
    placement = 'right-start',
}: EurekaElementMenuProps): JSX.Element {
    if (!anchorEl) return <></>;
    return (
        <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleClose}>
            <Popper
                open={true}
                anchorEl={anchorEl}
                placement={placement}
                modifiers={{
                    preventOverflow: {
                        enabled: true,
                        priority: ['top', 'bottom', 'left', 'right'],
                        boundariesElement: 'viewport',
                        padding: 20,
                    },
                }}
                style={{ zIndex }}
            >
                <div
                    className={styles.menuContainer}
                    style={{ fontSize: style.fontSize }}
                >
                    {renderElements({ style, zIndex: zIndex + 1 }, handleClose)}
                </div>
            </Popper>
        </ClickAwayListener>
    );
}

export default EurekaElementMenu;
