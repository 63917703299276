import React from 'react';
import styles from './ClientReply.module.css';

interface ClientReplyProps {
    children: any;
    padding?: number;
    title?: string;
}
function ClientReply({
    children,
    padding = 10,
    title,
}: ClientReplyProps): JSX.Element {
    return (
        <React.Fragment>
            <div style={{ height: padding }}></div>
            <div className={styles.replyCont}>
                {title && (
                    <div className={styles.locationTagContainer}>
                        <div className={styles.locationTag}>
                            <div className={styles.locationLbl}>{title}</div>
                        </div>
                    </div>
                )}
                <div className={styles.stepContainer}>
                    <div className={styles.trContainer}>
                        <div className={styles.tr}></div>
                    </div>
                    <div className={styles.trBorder}></div>
                    <div className={styles.messageCard}>{children}</div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ClientReply;
