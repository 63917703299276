import styles from './DateChangeDialog.module.css';
import { format } from 'date-fns';
import RoundedButton from '../../../../../../shared/RoundedButton/RoundedButton';
import React from 'react';
import { getLocale } from '../../../../../..';
import { Ticket } from '../../../../../../@Types/@Types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../utils/_store';
import ActionsService from '../../../../../../controllers/TicketsController/Actions/ActionsService';
import { appendAction } from '../../../../../../controllers/TicketsController/Actions/ActionsActions';
import VanillaToasts from '../../../../../../shared/Toast/Toast';

interface DateChangeDialogProps {
    selectedElement: Ticket;
    resolutionDate: Date;
    setLoading?: Function;
    onClose?: Function;
    loading?: boolean;
    onConfirm: Function;
}
function DateChangeDialog({
    selectedElement,
    onConfirm,
    resolutionDate,
    onClose,
    loading,
    setLoading,
}: DateChangeDialogProps): JSX.Element {
    const dispatch = useDispatch();
    const siteInfo = useSelector((state: RootState) => state.site);
    return (
        <React.Fragment>
            <h3 className="center-anything">Confirmar Cambio</h3>
            <div className={styles.message}>
                ¿Desea realizar el siguiente cambio?
            </div>
            <div className={styles.dateChange}>
                <label className={styles.dateLbl}>
                    <span className={styles.LblSpan}>Antes:</span>
                    {format(selectedElement.resolution_date, 'PPPp', {
                        locale: getLocale(),
                    })}
                </label>

                <label className={styles.dateLbl}>
                    <span className={styles.LblSpan}>Ahora:</span>
                    {format(resolutionDate, 'PPPp', {
                        locale: getLocale(),
                    })}
                </label>
            </div>
            <div className={styles.btnContainer}>
                <RoundedButton
                    fontSize={15}
                    padding={'0 10px 0 10px'}
                    text="Cancelar"
                    backgroundColor="var(--greyBtn)"
                    onClick={(): void => {
                        onClose?.();
                    }}
                />
                <div
                    className={styles.confirmBtn}
                    data-testid="DateChangeDialog_confirmBtn"
                >
                    <RoundedButton
                        fontSize={15}
                        padding={'0 10px 0 10px'}
                        text={'Confirmar'}
                        backgroundColor="var(--secondary)"
                        color="white"
                        onClick={async (): Promise<void> => {
                            if (setLoading && siteInfo.idProject) {
                                try {
                                    setLoading(true);
                                    const action =
                                        await ActionsService.changeResolutionDate(
                                            siteInfo.idProject,
                                            selectedElement._id,
                                            resolutionDate
                                        );
                                    dispatch(appendAction(action));
                                    onConfirm();
                                } catch (error) {
                                    VanillaToasts.create({
                                        title: 'Error al actualizar la fecha',
                                        text: error,
                                        type: 'error',
                                        timeout: 3000,
                                    });
                                    console.error(error);
                                    setLoading(false);
                                }
                            }
                        }}
                        disabled={loading}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}
export default DateChangeDialog;
