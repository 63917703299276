import React, { useMemo, useState } from 'react';
import { Area, Ticket } from '../../../../../../../@Types/@Types';
import { RootState } from '../../../../../../../utils/_store';
import styles from './AssignArea.module.css';
import AreaComponent from './Area/Area';
import { useDispatch, useSelector } from 'react-redux';
import RoundedButton from '../../../../../../../shared/RoundedButton/RoundedButton';
import ActionsService from '../../../../../../../controllers/TicketsController/Actions/ActionsService';
import AssignAreaDialog from '../AssignAreaDialog/AssignAreaDialog';
import { appendAction } from '../../../../../../../controllers/TicketsController/Actions/ActionsActions';
import VanillaToasts from '../../../../../../../shared/Toast/Toast';
interface AssignAreaProps {
    /** Function to call to close the menu */
    handleClose: Function;
    /** The currently selected Ticket */
    selectedTicket: Ticket;
    /** The current search value */
    search: string | undefined;
    /** The areas to display */
    areas: Area[];
}
function AssignArea({
    handleClose,
    selectedTicket,
    search,
    areas,
}: AssignAreaProps): JSX.Element {
    const dispatch = useDispatch();
    const siteInfo = useSelector((state: RootState) => state.site);
    const [showConfirm, setShowConfirm] = useState<Area | undefined>(undefined);
    const [selectedElement, setSelectedElement] = useState<Area | undefined>(
        undefined
    );

    const elements = useMemo(() => {
        if (search) {
            const regex = new RegExp(search, 'gi');
            return areas.filter(
                (area) => regex.test(area.name) || regex.test(area.description)
            );
        }
        return areas;
    }, [areas, search]);

    return (
        <div className={styles.container}>
            {showConfirm !== undefined && (
                <AssignAreaDialog
                    onConfirm={async (
                        element: Area,
                        setLoading: Function
                    ): Promise<void> => {
                        if (siteInfo.idProject) {
                            try {
                                setLoading(true);
                                const action = await ActionsService.assignArea(
                                    siteInfo.idProject,
                                    selectedTicket._id,
                                    element._id
                                );
                                dispatch(appendAction(action));
                                handleClose();
                            } catch (error) {
                                VanillaToasts.create({
                                    title: 'Error al assignar el area',
                                    text: error,
                                    type: 'error',
                                    timeout: 3000,
                                });
                                console.error(error);
                                setLoading(false);
                            }
                        }
                    }}
                    element={showConfirm}
                    title="Asignar Area"
                    elementName={showConfirm.name}
                    onClose={(): void => {
                        setShowConfirm(undefined);
                    }}
                />
            )}
            <div className={styles.listContainer}>
                {elements.map((area, index) => (
                    <AreaComponent
                        last={index === elements.length - 1}
                        area={area}
                        key={index}
                        handleClick={(): void => {
                            setSelectedElement(area);
                        }}
                        selected={selectedElement?._id === area._id}
                    />
                ))}
                {elements.length === 0 && (
                    <div className={styles.emptyContainer}>No hay areas</div>
                )}
            </div>
            {elements.length !== 0 && (
                <div className={styles.assignBtnContainer}>
                    <RoundedButton
                        text="Asignar"
                        onClick={async (): Promise<void> => {
                            if (selectedElement !== undefined) {
                                setShowConfirm(selectedElement);
                            }
                        }}
                        borderRadius={3}
                        disabled={selectedElement === undefined}
                    ></RoundedButton>
                </div>
            )}
        </div>
    );
}
export default AssignArea;
