import styles from './PayloadConfirmation.module.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../utils/_store';
import RoundedButton from '../../../../../shared/RoundedButton/RoundedButton';
import { Payload } from '../../../../../@Types/Payload';
import RoundedCheckBox from '../../../../../shared/RoundedCheckBox/RoundedCheckBox';
import { Action, Ticket } from '../../../../../@Types/@Types';
import { useMemo, useState } from 'react';
import RoundedPayload from '../../../../../shared/RoundedPayload/RoundedPayload';
import { ProjectTag } from '../../../../../@Types/Project';
import { ActivityTypes } from '../../../../../constants/ActivityTypes/ActivityTypes';
import React from 'react';
import { ActionTabs } from '../ActionBar';
import { ProjectPermissions } from '../../../../../constants/Permissions';
import { checkProjPerm } from '../../../../../utils/PermissionsFunctions';
import StateTypes from '../../../../../constants/StateTypes';
interface PayloadConfirmationProps {
    /** Function called to close the dialog */
    close: Function;
    /**  The payload to confirm*/
    payload: Payload;
    /** function called on confirm */
    handleConfirm: Function;
    /** Function called to handle loader */
    setLoading?: Function;
    /** The type of the payload to confirm */
    type: ActionTabs;
    /** The other actions */
    actions: Action[];
    /** The current Ticket */
    ticket: Ticket;
}

function PayloadConfirmation({
    handleConfirm,
    setLoading,
    actions,
    ticket,
    type,
    payload,
    close,
}: PayloadConfirmationProps): JSX.Element {
    const siteInfo = useSelector((state: RootState) => state.site);

    const [tags, setTags] = useState<any>({});

    const onSubmit = async (): Promise<void> => {
        try {
            setLoading?.(true);
            await handleConfirm(tags);
            setLoading?.(false);
            close();
        } catch (error) {
            setLoading?.(false);
            //TODO handle error
            console.error(error);
        }
    };

    const calcTitle = (): string => {
        switch (type) {
            case ActionTabs.REPLY:
                return 'Confirmar Respuesta al Cliente';
            case ActionTabs.COMMENT:
                return 'Confirmar Comentario';
            case ActionTabs.PRE_REPLY:
                return 'Confirmar Borrador';
            default:
                return '';
        }
    };

    const calcMessage = (): string => {
        switch (type) {
            case ActionTabs.REPLY:
                return '¿Estás seguro que deseas enviarle este mensaje al emisor?';
            case ActionTabs.COMMENT:
                return '¿Estás seguro que deseas enviar este comentario?';
            case ActionTabs.PRE_REPLY:
                return '¿Estás seguro que deseas enviar este borrador de respuesta al cliente?';
            default:
                return '';
        }
    };

    const calcBtnLbl = (): string => {
        switch (type) {
            case ActionTabs.REPLY:
                return 'Enviar Respuesta';
            case ActionTabs.COMMENT:
                return 'Comentar Internamente';
            case ActionTabs.PRE_REPLY:
                return 'Enviar Borrador';
            default:
                return '';
        }
    };

    return (
        <div
            className={styles.container}
            data-testid="payload_confirmation_container"
        >
            <div className={styles.containerLbl}>{calcTitle()}</div>
            <RoundedPayload
                payload={payload}
                date={new Date()}
                name={siteInfo.user?.name ?? ''}
                isResponse={type !== ActionTabs.COMMENT}
            />
            <div>
                <TagsComponent
                    tags={tags}
                    ticket={ticket}
                    payload={payload}
                    setTags={setTags}
                    actions={actions}
                    isResponse={type !== ActionTabs.COMMENT}
                    idOrganization={siteInfo.organization?.idOrganization}
                />
                <div className={styles.descriptionLbl}>{calcMessage()}</div>
            </div>
            <div className={styles.btnsContainer + ' flexbox'}>
                <div className={styles.btn}>
                    <RoundedButton
                        text="Cancelar"
                        onClick={(): void => {
                            close();
                        }}
                        backgroundColor="var(--light-grey)"
                        color="var(--accent)"
                        fontSize="16px"
                        height={30}
                    />
                </div>
                <div className={styles.btn}>
                    <RoundedButton
                        text={calcBtnLbl()}
                        backgroundColor="var(--secondary)"
                        color="white"
                        onClick={(): void => {
                            onSubmit();
                        }}
                        fontSize="16px"
                        height={30}
                    />
                </div>
            </div>
        </div>
    );
}

export default PayloadConfirmation;

interface PendingTag extends ProjectTag {
    requirements?: {
        label: string;
        files?: number;
        images?: number;
    }[];
}

interface TagsComponentProps {
    tags: Record<string, any>;
    setTags: Function;
    idOrganization: string | undefined;
    actions: Action[];
    isResponse: boolean;
    /** The current Ticket */
    ticket: Ticket;
    /**  The payload to confirm*/
    payload: Payload;
}
function TagsComponent({
    tags,
    ticket,
    setTags,
    payload,
    idOrganization,
    actions,
    isResponse,
}: TagsComponentProps): JSX.Element {
    const projectTags = useSelector(
        (state: RootState) =>
            state.site.projects[state.site.idProject ?? '']?.tags ?? []
    );
    const process = useSelector(
        (state: RootState) =>
            state.ticketsPage.processes[ticket.process?.idProcess ?? '']
    );
    const idProject = useSelector((state: RootState) => state.site.idProject);
    const user = useSelector((state: RootState) => state.site.user!);

    const files = payload.files?.length ?? 0;
    const images = Object.values(payload.images ?? {}).length;

    function canComplete(agents: string[]): boolean {
        return (
            ticket.state.type !== StateTypes.CLOSED &&
            (agents.includes(user._id) ||
                !!ticket.agents.find((agent) => agent._id === user._id) ||
                checkProjPerm(
                    user,
                    idProject,
                    ProjectPermissions.MANAGE_NOT_ASSIGNED
                ))
        );
    }

    const tagsToDisplay: PendingTag[] = useMemo(() => {
        const tags: PendingTag[] = projectTags.filter((tag) => !tag.partial);
        if (
            idOrganization === 'melendez' &&
            actions &&
            !actions.find(
                (action: any) =>
                    !!action.melendez_isFormal ||
                    !!action.tags?.melendez_isFormal
            )
        ) {
            tags.push({
                id: 'melendez_isFormal',
                label: 'Respuesta Definitiva al Cliente',
                confirmation: 'Respuesta definitiva al cliente',
            });
        }

        if (ticket.process && user) {
            for (const current of ticket.process.current) {
                const activity = process?.activities[current.idActivity];
                if (
                    current.type !== ActivityTypes.REPLY &&
                    current.type !== ActivityTypes.COMMENT
                )
                    continue;
                if (
                    !canComplete(current.agents) ||
                    activity?.type !== current.type
                )
                    continue;
                for (const tag of activity.tags) {
                    let currentTag = tags.find((pTag) => pTag.id === tag);
                    if (!currentTag) {
                        const projectTag = projectTags.find(
                            (pTag) => pTag.id === tag
                        );
                        if (!projectTag) continue;
                        currentTag = { ...projectTag };
                        tags.push(currentTag);
                    }
                    if (activity.files > 0 || activity.images > 0) {
                        if (!currentTag.requirements) {
                            currentTag.requirements = [];
                        }
                        currentTag.requirements.push({
                            label: activity.name,
                            files: activity.files,
                            images: activity.images,
                        });
                    }
                }
            }
        }

        return tags;
    }, []);

    if (isResponse) {
        return (
            <React.Fragment>
                {tagsToDisplay.map((tag, index) => (
                    <div className={styles.optionContainer} key={index}>
                        <div className={styles.toggleContainer}>
                            <div className={styles.optionLabel}>
                                {tag.confirmation}:
                            </div>
                            <div className={styles.checkboxContainer}>
                                <RoundedCheckBox
                                    checked={tags[tag.id] === true}
                                    onChange={(event): void => {
                                        const checked = event.target.checked;
                                        const tempTags = { ...tags };
                                        if (checked) {
                                            tempTags[tag.id] = true;
                                        } else {
                                            delete tempTags[tag.id];
                                        }
                                        setTags(tempTags);
                                    }}
                                />
                            </div>
                        </div>
                        {tags[tag.id] && tag.requirements && (
                            <div
                                key={index}
                                className={styles.warningContainer}
                            >
                                {tag.requirements.map((req) => (
                                    <>
                                        {req.files && files < req.files && (
                                            <div className={styles.tagWarning}>
                                                {req.label} requiere{' '}
                                                {req.files > 1
                                                    ? req.files + ' adjuntos'
                                                    : 'un adjunto'}
                                            </div>
                                        )}
                                        {req.images && images < req.images && (
                                            <div className={styles.tagWarning}>
                                                {req.label} requiere{' '}
                                                {req.images > 1
                                                    ? req.images + ' fotos'
                                                    : 'una foto'}
                                            </div>
                                        )}
                                    </>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </React.Fragment>
        );
    } else {
        return <div></div>;
    }
}
