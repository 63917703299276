import { AnyAction } from '@reduxjs/toolkit';
import { takeLatest, call, put, fork, select } from 'redux-saga/effects';
import { RootState } from '../../utils/_store';
import { GenericMenuState, defaultState } from './GenericMenuReducer';
import * as actions from './GenericMenuActions';

/** Number of elements per Page */
const PAGE_SIZE = 20;

type Type = any;

/**
 * Function called when new elements should be rendered(reset,search, or newpage)
 * @param action of type Search, reset or scroll
 */
function* getElements(action: AnyAction): any {
    try {
        const menuInfo: GenericMenuState<any, any> = yield select(
            (state: RootState) =>
                state.genericMenu[action.meta.key] ?? defaultState
        );
        const calcId = menuInfo.config.calcId;
        const result: Type[] = yield call(
            menuInfo.config.loadElements,
            menuInfo.page,
            PAGE_SIZE,
            menuInfo.search
        );

        const noMoreElements =
            (menuInfo.page !== 1 && result.length === 0) ||
            (menuInfo.page === 1 && result.length < PAGE_SIZE);

        const order = actions.pageScroll.match(action)
            ? [...menuInfo.order]
            : [];

        const elements = { ...menuInfo.elements };
        let selected = [...menuInfo.selected];

        if (actions.reset.match(action)) {
            let initialElements = action.payload.selected;
            if (
                action.payload.selected.length > 0 &&
                menuInfo.config.isInitialType(action.payload.selected)
            ) {
                initialElements = yield call(
                    menuInfo.config.loadInitialType,
                    action.payload.selected
                );
            }
            for (const element of initialElements) {
                if (!elements[calcId(element)]) {
                    elements[calcId(element)] = element;
                }
            }
            selected = initialElements.map((element) => calcId(element));
        }

        for (const element of result) {
            if (
                menuInfo.elementsToOmit.find(
                    (e) => calcId(e) === calcId(element)
                )
            )
                continue;
            elements[calcId(element)] = element;
            order.push(calcId(element));
        }

        yield put(
            actions.getSuccess(action.meta.key, {
                order,
                elements,
                selected,
                noMoreElements,
            })
        );
    } catch (error) {
        //TODO handle errors
        console.error(error);
    }
}

function* watchGetElementsRequest(): any {
    yield takeLatest(
        [actions.Types.RESET, actions.Types.PAGE_SCROLL, actions.Types.SEARCH],
        getElements
    );
}

export default [fork(watchGetElementsRequest)];
