import React, { useRef } from 'react';
import styles from './FlowSideBar.module.css';
import decisionStyles from './DecisionNode.module.css';
import areaStyles from './AreaNode.module.css';
import transformationStyles from './TransformationNode.module.css';
import assignStyles from './AssignNode.module.css';
import aiStyles from './AINode.module.css';
import communicationStyles from './CommunicationNode.module.css';
import agentStyles from './AgentNode.module.css';
import ApartmentRoundedIcon from '@material-ui/icons/ApartmentRounded';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import Avatar from '../../../shared/Avatar/Avatar';
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import { Area, Agent } from '../../../@Types/@Types';
import BrainIcon from '../../../Icons/BrainIcon';
import ChatIcon from '../../../Icons/ChatIcon';
import FlowNodeTypes from '../../../constants/Flows/FlowNodeTypes';
interface FlowSideBarProps {
    /** The flows areas to display in the sidebar */
    areas: Area[] | undefined;
    /** The flows agents to display in the sidebar */
    agents: Agent[] | undefined;
    /** If flow is of type entry */
    entry: boolean;
}
function FlowSideBar({ areas, agents, entry }: FlowSideBarProps): JSX.Element {
    const onDragStart = (
        event: any,
        nodeType: string,
        objectId?: string,
        offsetY?: number
    ): void => {
        const relativeX = event?.clientX - event?.target?.offsetLeft;
        let relativeY = event?.clientY - event?.target?.offsetTop;
        if (offsetY) {
            relativeY += offsetY;
        }
        event.dataTransfer.setData(
            'application/reactflow',
            [nodeType, relativeX, relativeY, objectId].join(',')
        );
        event.dataTransfer.effectAllowed = 'move';
    };

    const areasRef = useRef<HTMLDivElement>(null);
    const agentsRef = useRef<HTMLDivElement>(null);
    const renderAreas = (): JSX.Element | void => {
        if (areas !== undefined && areas.length > 0) {
            return (
                <React.Fragment>
                    <div className={styles.hr} />
                    <div className={styles.areasContainer}>
                        <div className={styles.subTitle}>Áreas</div>
                        <div
                            className={styles.areasContContainer}
                            ref={areasRef}
                        >
                            {areas.map((area, index) => (
                                <div key={index}>
                                    <div
                                        className={areaStyles.areaContainer}
                                        onDragStart={(event): void =>
                                            onDragStart(
                                                event,
                                                FlowNodeTypes.AREA,
                                                area._id,
                                                areasRef.current?.scrollTop ?? 0
                                            )
                                        }
                                        draggable
                                    >
                                        <div>
                                            <div
                                                className={areaStyles.container}
                                            >
                                                <ApartmentRoundedIcon fontSize="inherit" />
                                            </div>
                                        </div>
                                        <div className={areaStyles.label}>
                                            {area.name}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    };
    const renderAgents = (): JSX.Element | void => {
        if (agents !== undefined) {
            return (
                <React.Fragment>
                    <div className={styles.hr} />
                    <div className={styles.agentsContainer} ref={agentsRef}>
                        <div className={styles.agentsTopRow}>
                            <div className={styles.subTitle}>Agentes</div>
                            <div
                                className={styles.addAgent}
                                style={{ visibility: 'hidden' }} //TODO quitar esto?
                            >
                                <PersonAddRoundedIcon fontSize="inherit" />
                            </div>
                        </div>
                        {agents.map((agent, index) => {
                            return (
                                <div key={index}>
                                    <div
                                        className={agentStyles.agentContainer}
                                        onDragStart={(event): void => {
                                            onDragStart(
                                                event,
                                                FlowNodeTypes.AGENT,
                                                agent._id,
                                                agentsRef.current?.scrollTop
                                            );
                                        }}
                                        draggable
                                    >
                                        <div>
                                            <div
                                                className={
                                                    agentStyles.container
                                                }
                                            >
                                                <div
                                                    className={
                                                        agentStyles.cover
                                                    }
                                                ></div>
                                                <Avatar
                                                    size={40}
                                                    img={agent.img}
                                                    lbl={agent.name}
                                                />
                                            </div>
                                        </div>
                                        <div className={agentStyles.label}>
                                            {agent.name}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </React.Fragment>
            );
        }
    };
    return (
        <div className={styles.container}>
            <div>
                <div className={styles.sideBarTitle}>Pasos</div>
                <div className={styles.nodeContainer}>
                    <div
                        className={decisionStyles.container}
                        draggable
                        onDragStart={(event): void =>
                            onDragStart(event, FlowNodeTypes.DECISION)
                        }
                    >
                        <div className={decisionStyles.top}>
                            <label className={decisionStyles.title}>
                                Decisión
                            </label>
                        </div>
                        <div className={decisionStyles.contentContainer}>
                            <div
                                className={decisionStyles.decisionContainer}
                            ></div>
                            <div
                                className={decisionStyles.decisionContainer}
                            ></div>
                            <div
                                className={decisionStyles.decisionContainer}
                            ></div>
                        </div>
                    </div>
                </div>
                <div className={styles.hr} />
                <div className={styles.nodeContainer}>
                    <div
                        className={transformationStyles.container}
                        draggable
                        onDragStart={(event): void =>
                            onDragStart(event, FlowNodeTypes.TRANSFORMATION)
                        }
                    >
                        <div className={transformationStyles.top}>
                            <label className={transformationStyles.title}>
                                Transformación
                            </label>
                        </div>
                    </div>
                </div>
                <div className={styles.hr} />
                <div className={styles.nodeContainer}>
                    <div
                        className={aiStyles.container}
                        onDragStart={(event): void =>
                            onDragStart(event, FlowNodeTypes.AI)
                        }
                        draggable
                    >
                        <BrainIcon className={aiStyles.icon} />
                    </div>
                    {!entry && (
                        <div
                            className={assignStyles.container}
                            onDragStart={(event): void =>
                                onDragStart(event, FlowNodeTypes.ASSIGN)
                            }
                            draggable
                        >
                            <PeopleAltRoundedIcon fontSize="inherit" />
                        </div>
                    )}
                    <div
                        className={communicationStyles.container}
                        onDragStart={(event): void =>
                            onDragStart(event, FlowNodeTypes.COMMUNICATION)
                        }
                        draggable
                    >
                        <ChatIcon className={communicationStyles.icon} />
                    </div>
                </div>
                {renderAreas()}
                {renderAgents()}
            </div>
        </div>
    );
}

export default React.memo(FlowSideBar) as typeof FlowSideBar;
