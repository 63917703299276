import {} from '../../../../../@Types/ConditionTypes/ConversationStepConditions';
import Dialog from '../../../../../shared/Dialog/Dialog';
import styles from './Condition.module.css';
import ConditionEditor from '../../../../../shared/ConditionEditor/ConditionEditor';
import ConditionTypes from '../../../../../constants/Conditions/ConditionTypes';
import { ConversationCondition } from '../../../../../@Types/ConversationTypes/ConversationStep';

const ConversationConditionTypes = [
    ConditionTypes.CONVERSATION_STEP,
    ConditionTypes.COMPANY,
    ConditionTypes.ENTITYVALUE,
    ConditionTypes.CLIENT,
];

interface ConditionsProps {
    idStep: string;
    handleClose: Function;
    condition: ConversationCondition | undefined;
    title: string;
    handleUpdate: React.Dispatch<ConversationCondition | undefined>;
}
function Conditions({
    title,
    idStep,
    condition,
    handleClose,
    handleUpdate,
}: ConditionsProps): JSX.Element {
    return (
        <Dialog
            open
            onClose={(): void => handleClose()}
            border={20}
            maxWidth="90vw"
            disableEnforceFocus
        >
            <div className={styles.container}>
                <div className={styles.nameLbl}>{title}</div>
                <div className={styles.decisionsContainer}>
                    <ConditionEditor
                        condition={condition}
                        context={{
                            conversation: {
                                idStep,
                            },
                        }}
                        handleDelete={(): void => handleUpdate(undefined)}
                        handleUpdate={handleUpdate}
                        conditionTypes={ConversationConditionTypes}
                    />
                </div>
            </div>
        </Dialog>
    );
}

export default Conditions;
