import { useCallback, useMemo } from 'react';
import {
    DraftEntityData,
    isMappableEntityData,
} from '../../../@Types/Draft/DraftEntityData';
import { ContentState } from 'draft-js';
import { Tooltip, withStyles } from '@material-ui/core';
import { DraftEntityDataTypes } from '../../../constants/Draft/DraftEntityDataTypes';
import {
    PayloadEditorActions,
    usePayloadEditorDispatch,
    usePayloadEditorSelector,
} from '../../../controllers/PayloadEditorController/PayloadEditorSlice';
import Decorator from './Decorator';
import { DraftEntityTypes } from '../../../constants/Draft/DraftEntityTypes';
import styles from './Decorator.module.css';
import {
    useAppSelector,
    useClientEntity,
    useCurrentProject,
} from '../../../hooks';
import {
    isMultipleClientRelationshipEntityData,
    isMultipleEntityValueEntityData,
} from '../../../controllers/PayloadEditorController/PayloadFunctions';

export interface DecoratorComponentProps {
    children: JSX.Element;
    contentState: ContentState;
    entityKey: string;
    blockKey: string;
    start: number;
    end: number;
    editable?: boolean;
}

export function EurekaDecorator({
    end,
    start,
    children,
    editable,
    entityKey,
    blockKey,
}: DecoratorComponentProps): JSX.Element {
    const entities = useAppSelector((state) => state.site.entities);
    const project = useCurrentProject();
    const inserting = usePayloadEditorSelector(
        (state) => state.preview?.loading
    );
    const clientEntity = useClientEntity();
    const entity = usePayloadEditorSelector((state) =>
        state.editorState.getCurrentContent().getEntity(entityKey)
    );
    const data: DraftEntityData = entity.getData();
    const hasError = usePayloadEditorSelector((state) => state.hasError);
    const dispatch = usePayloadEditorDispatch();
    if (!data?.type) return children;

    const isMappable = useMemo(() => {
        let isMappable = isMappableEntityData(data);
        if (data.type === DraftEntityDataTypes.ENTITYVALUES) {
            if (!isMultipleEntityValueEntityData(data, entities, project))
                isMappable = false;
        } else if (data.type === DraftEntityDataTypes.CLIENT_RELATIONSHIP) {
            if (
                !isMultipleClientRelationshipEntityData(
                    data,
                    entities,
                    clientEntity
                )
            ) {
                isMappable = false;
            }
        }
        return isMappable;
    }, []);

    const setEditing = useCallback(() => {
        dispatch(
            PayloadEditorActions.setEditing({
                entityKey,
                blockKey,
            })
        );
    }, [start, end, blockKey, entityKey, dispatch]);

    return (
        <>
            <DraftToolTip
                placement="top"
                arrow
                interactive
                title={
                    <Decorator
                        data={data}
                        onEdit={editable ? setEditing : undefined}
                    />
                }
            >
                <span
                    id={entityKey}
                    onClick={editable ? setEditing : undefined}
                    className={inserting ? styles.inserting : undefined}
                    style={{
                        overflow: 'hidden',
                        position: 'relative',
                        scrollMarginTop: '12px',
                        color:
                            isMappable && !hasError
                                ? undefined
                                : 'var(--primary1)',
                        cursor: editable ? 'pointer' : 'default',
                        backgroundColor: hasError
                            ? 'var(--error)'
                            : isMappable
                            ? 'var(--light-grey)'
                            : 'var(--secondary)',
                        border: isMappable
                            ? '1px solid var(--greyBtn)'
                            : undefined,
                        padding: isMappable ? '2px 3px' : '0px 3px',
                        borderRadius: 5,
                        lineHeight: isMappable ? 1.8 : 1.4,
                    }}
                >
                    {children}
                </span>
            </DraftToolTip>
        </>
    );
}

export const EditableEurekaDecorator = (
    props: DecoratorComponentProps
): JSX.Element => {
    return <EurekaDecorator {...props} editable />;
};

export const DraftToolTip = withStyles(() => ({
    tooltip: {
        backgroundColor: 'var(--secondary)',
        marginBottom: '6px',
        padding: '0px',
        '& .Erk-MuiTooltip-arrow': { color: 'var(--secondary)' },
    },
}))(Tooltip);

export function EurekaDecoratorsStrategy(
    contentBlock: any,
    callback: any,
    contentState: any
): void {
    contentBlock.findEntityRanges((character: any) => {
        const entityKey = character.getEntity();
        return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() ===
                DraftEntityTypes.EUREKA
        );
    }, callback);
}
