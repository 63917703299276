import { createAction } from '@reduxjs/toolkit';
import { MenuGroupedNotification } from '../../@Types/GroupedMenuNotification';
import { NotificationFilterTypes } from './NotificationsMenuReducer';

export const Types = {
    RESET: 'NOTIFICATIONS_MENU/RESET',
    RESET_SUCCESS: 'NOTIFICATIONS_MENU/RESET_SUCCESS',
    SET_LOADING: 'NOTIFICATIONS_MENU/SET_LOADING',
    GET_SUCCESS: 'NOTIFICATIONS_MENU/GET_SUCCESS',
    PAGE_SCROLL: 'NOTIFICATIONS_MENU/PAGE_SCROLL',
    NEED_RESET: 'NOTIFICATIONS_MENU/NEED_RESET',
    MARK_TICKET_GROUPS_AS_READ: 'NOTIFICATIONS_MENU/MARK_TICKET_GROUPS_AS_READ',
};

/**
 * Called to reset the notifications list
 */
export const reset = createAction<{
    filterState: NotificationFilterTypes;
}>(Types.RESET);

/**
 * Called when the notifications list is loaded after a reset
 */

export const resetSuccess = createAction<{
    order: Record<string, number>;
    ticketsState: Record<string, boolean>;
    elements: MenuGroupedNotification[];
    page: number;
    noMoreElements: boolean;
}>(Types.RESET_SUCCESS);

/**
 * Called to toggle the loading state
 */
export const setLoading = createAction<boolean>(Types.SET_LOADING);

/**
 * Called when the saga has successfully retrieved the lists elements
 */
export const getSuccess = createAction<{
    order: Record<string, number>;
    elements: MenuGroupedNotification[];
    page: number;
    noMoreElements: boolean;
}>(Types.GET_SUCCESS);

/**
 * Called when the list needs to load more elements
 */

export const pageScroll = createAction(Types.PAGE_SCROLL);

/**
 * Called to indicate that the list needs to be reset
 */

export const needReset = createAction(Types.NEED_RESET);

/**
 * Called to mark a group of notifications as read
 */

export const markTicketGroupsAsRead = createAction<string>(
    Types.MARK_TICKET_GROUPS_AS_READ
);
