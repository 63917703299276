export enum FlowNodeTypes {
    EXIT = 'EXIT',
    ENTRY = 'ENTRY',
    AI = 'AI',
    COMMUNICATION = 'COMMUNICATION',
    AGENT = 'AGENT',
    ASSIGN = 'ASSIGN',
    AREA = 'AREA',
    TRANSFORMATION = 'TRANSFORMATION',
    DECISION = 'DECISION',
}

export default FlowNodeTypes;
