import React from 'react';
import styles from './CreateClient.module.css';
import { createClient } from '../../../controllers/ClientsController/ClientsService';
import ClientRenderer from '../../../shared/ClientRenderer/ClientRenderer';

interface CreateClientProps {
    /** Function called when the agent has been created */
    handleClientCreated: Function;
    /** Function called to close the Dialog */
    onClose?: Function;
    /** Function called to update the Dialog's loader */
    setLoading?: Function;
}
function CreateClient({
    handleClientCreated,
    setLoading,
}: CreateClientProps): JSX.Element {
    return (
        <div className={styles.container + ' standard-dialog'}>
            <div className={styles.title}>Crear Cliente</div>
            <ClientRenderer
                handleSave={async (client): Promise<void> => {
                    try {
                        setLoading?.(true);
                        await createClient(client);
                        setLoading?.(false);
                        handleClientCreated();
                    } catch (error) {
                        setLoading?.(false);
                    }
                }}
                refresh={(): void => {}}
                editable={true}
            />
        </div>
    );
}

export default CreateClient;
