import { AnyAction } from '@reduxjs/toolkit';
import { Agent, Area } from '../../@Types/@Types';
import * as Actions from './AgentsActions';

export interface AgentsMenuState {
    /** The currently active filters */
    areafilter: Area | null;
    /** The current search, undefined if none */
    search: string | undefined;
    /** the currently selected agents */
    selected: Agent[];
    /** The other agents */
    elements: SelectableAgent[];
    /** The current projects areas to filter */
    areas: Area[];
    /** The number of pages currently loaded in the list */
    page: number;
    /** Active if no more pages are available */
    noMoreElements: boolean;
    /** If loader is active */
    loading: boolean;
    /** If filters are showing */
    showFilters: boolean;
    /** if it should filter by the current project */
    projectFilter: boolean;
    /** Agents to not include in the menu */
    filterAgents: Agent[];
}

/**
 * Type used to select agents
 */
export interface SelectableAgent extends Agent {
    /** Use to indicate if agent is selected in the Menu  */
    selected: boolean;
}

const initialState = {
    elements: [],
    selected: [],
    areas: [],
    filterAgents: [],
    areafilter: null,
    projectFilter: false,
    search: undefined,
    page: 1,
    noMoreElements: false,
    loading: true,
    showFilters: false,
};

/**
 * Redux Reducer that handles AgentsActions Tiggers
 * @param state The current state
 * @param action the action that was triggered
 * @returns the new state
 */
const AgentsReducer = (
    state: AgentsMenuState = initialState,
    action: AnyAction
): AgentsMenuState => {
    if (Actions.reset.match(action)) {
        return {
            ...state,
            selected: [],
            projectFilter: action.payload.projectFilter,
            filterAgents: action.payload.filterAgents,
            elements: [],
            areafilter: null,
            search: undefined,
            page: 1,
            noMoreElements: false,
            loading: true,
            showFilters: false,
        };
    } else if (Actions.setLoading.match(action)) {
        return {
            ...state,
            loading: action.payload,
        };
    } else if (Actions.getSuccess.match(action)) {
        return {
            ...state,
            loading: false,
            elements: action.payload.elements,
            selected: action.payload.selected ?? [...state.selected],
            noMoreElements: action.payload.noMoreElements,
            areas: action.payload.areas ?? state.areas,
        };
    } else if (Actions.pageScroll.match(action)) {
        return { ...state, page: state.page + 1 };
    } else if (Actions.selectElements.match(action)) {
        return {
            ...state,
            selected: action.payload.selected,
            elements: action.payload.elements,
        };
    } else if (Actions.search.match(action)) {
        return {
            ...state,
            elements: [],
            page: 1,
            noMoreElements: false,
            loading: true,
            search: action.payload === '' ? undefined : action.payload,
        };
    } else if (Actions.filter.match(action)) {
        return {
            ...state,
            areafilter: action.payload,
            loading: true,
        };
    } else if (Actions.toggleFilters.match(action)) {
        return {
            ...state,
            showFilters: !state.showFilters,
        };
    }
    return state;
};
export default AgentsReducer;
