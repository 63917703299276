import styles from './ConversationEditor.module.css';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, match, Prompt } from 'react-router-dom';
import { RouterProps } from '../../../Router';
import NoMobile from '../../../shared/NoMobile/NoMobile';
import { RootState } from '../../../utils/_store';
import Dialog from '../../../shared/Dialog/Dialog';
import RoundedButton from '../../../shared/RoundedButton/RoundedButton';
import {
    copyStep,
    deleteStep,
    pasteStep,
    reset,
    setImgMaxWidth,
    setShowPreview,
} from '../../../controllers/ConversationEditorController/ConversationEditorActions';
import SaveDialog from '../../../shared/SaveDialog/SaveDialog';
import Loader from '../../../shared/Loader/Loader';
import PageTitle from '../../../shared/PageTitle/PageTitle';
// import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import { LocationTypes } from '../../../constants/Conversations/ConversationStepTypes';
import StepComponent from './StepList/Step';
import StepDetail from './StepDetail/StepDetail';
import React from 'react';
import CreateStep from './StepList/CreateStep/CreateStep';
import ConversationService from '../../../controllers/ConversationEditorController/ConversationService';
import ClientReply from './StepList/ClientReply/ClientReply';
import { DefaultCreateStep } from '../../../@Types/ConversationTypes/ConversationStep';
import StepTypes from '../../../constants/Conversations/ConversationStepTypes';
import { useDrop } from 'react-dnd';
import LastAddStep from './AddStep/LastAddStep';
import ConditionTypes from '../../../constants/Conditions/ConditionTypes';

export const BaseConversationPayloadConditionTypes = [
    ConditionTypes.CLIENT,
    ConditionTypes.COMPANY,
    ConditionTypes.CONVERSATION_STEP,
    ConditionTypes.ENTITYVALUE,
];

interface apiKeyRouteParam {
    /** Current entry */
    apiKey: string;
    /** Current Project */
    idProject: string;
}

export const ApiKeyContext = React.createContext('');

function ConversationPage({
    match,
    mobile,
}: {
    match: match<apiKeyRouteParam>;
} & RouterProps): JSX.Element {
    const dispatch = useDispatch();
    const [showSettingsDialog, setShowSettingsDialog] = useState(false);
    const siteInfo = useSelector((state: RootState) => state.site);
    const editorInfo = useSelector(
        (state: RootState) => state.conversationEditor,
        (prevInfo, nextInfo) => {
            //Only refresh if loading or showPreview changes
            return (
                prevInfo.loading === nextInfo.loading &&
                prevInfo.showPreview === nextInfo.showPreview
            );
        }
    );
    const selectedStep = useSelector(
        (state: RootState) => state.conversationEditor.selectedStep
    );
    const rootSteps = useSelector(
        (state: RootState) => state.conversationEditor.rootSteps
    );
    const [saving, setSaving] = useState(false);

    const listRef = useRef<HTMLDivElement>(null);
    const initialRef = useRef<HTMLDivElement>(null);
    const layoutRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (match.params.apiKey) {
            dispatch(reset(match.params.apiKey));
        }
    }, [match.params.idProject, match.params.apiKey]);

    /** Calcs the maxwidth of an image */
    const handleResize = (): void => {
        if (listRef.current) {
            dispatch(setImgMaxWidth(listRef?.current?.offsetWidth * 0.75 - 46));
        }
    };

    const handleKeyDown = (event: KeyboardEvent): any => {
        if (document.activeElement?.tagName === 'BODY' && selectedStep) {
            const code = event.which || event.keyCode;

            const charCode = String.fromCharCode(code).toLowerCase();
            if ((event.ctrlKey || event.metaKey) && charCode === 'c') {
                dispatch(copyStep(selectedStep.id));
            } else if ((event.ctrlKey || event.metaKey) && charCode === 'v') {
                const path = selectedStep.path;
                if (path) {
                    const location = { ...path[path.length - 1] };
                    location.indexStep = location.indexStep + 1;
                    dispatch(pasteStep(location));
                }
            } else if (event.key === 'Delete') {
                dispatch(deleteStep(selectedStep.id));
            }
        }
    };

    useEffect(() => {
        /** On load add a resize listener to calc check copy paste and del */
        window.addEventListener('keydown', handleKeyDown);
        const onBeforeUnload = (e: any): void => {
            if (
                siteInfo.user?.email === 'andres@capta.co' ||
                siteInfo.user?.email === 'cristina.acuna@capta.co'
            ) {
                e.preventDefault();
                e.returnValue = '';
            }
        };
        window.addEventListener('beforeunload', onBeforeUnload);
        return (): void => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('beforeunload', onBeforeUnload);
        };
    }, [selectedStep]);

    useEffect(() => {
        /** On load add a resize listener to calc the currentBreakpoint */
        window.addEventListener('resize', handleResize);
        handleResize();
        return (): void => {
            window.removeEventListener('resize', handleResize);
        };
    }, [listRef.current]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const initialDrop = useDrop(
        () => ({
            accept: Object.keys(StepTypes),
        }),
        []
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const layoutDrop = useDrop(
        () => ({
            accept: Object.keys(StepTypes),
            hover(_item, monitor): any {
                if (!layoutRef.current) {
                    return;
                }
                const hoverBoundingRect =
                    layoutRef.current?.getBoundingClientRect();
                // Determine mouse position
                const clientOffset = monitor.getClientOffset();
                if (clientOffset === null) {
                    return;
                }
                const hoverClientY = clientOffset.y - hoverBoundingRect.top;
                const bottom = hoverBoundingRect.bottom - hoverBoundingRect.top;
                if (hoverClientY < 100) {
                    layoutRef.current.scrollBy({
                        top: -15,
                        left: 0,
                    });
                } else if (hoverClientY > bottom - 100) {
                    layoutRef.current.scrollBy({
                        top: 15,
                        left: 0,
                    });
                }
            },
        }),
        []
    );

    if (mobile) {
        return <NoMobile />;
    }

    const mapSteps = (): JSX.Element[] => {
        return rootSteps.map((idStep: string, index) => (
            <StepComponent
                key={index}
                idStep={idStep}
                path={[
                    {
                        type: LocationTypes.ROOT,
                        indexStep: index,
                        label: null,
                    },
                ]}
            />
        ));
    };

    initialDrop[1](initialRef);
    layoutDrop[1](layoutRef);
    return (
        <ApiKeyContext.Provider value={match.params.apiKey}>
            <div className={styles.container}>
                {editorInfo.loading && (
                    <div className={styles.whiteCurtain}>
                        <Loader size={100} />
                    </div>
                )}
                {(siteInfo.user?.email === 'andres@capta.co' ||
                    siteInfo.user?.email === 'cristina.acuna@capta.co') && (
                    <Prompt message="Salir?" />
                )}
                <div className={styles.stepsContainer}>
                    <div className={styles.containerLine}></div>
                    <div className={styles.saveBtn}>
                        <RoundedButton
                            text="Guardar"
                            fontSize={22}
                            borderRadius={12}
                            padding="20px 12px"
                            onClick={(): void => {
                                setSaving(true);
                            }}
                        />
                    </div>
                    {/* <div
                    className={styles.overViewBtnContainer}
                    onClick={(): void => {
                        setShowSettingsDialog(true);
                    }}
                >
                    <SettingsRoundedIcon fontSize="inherit" />
                </div> */}
                    <div className={styles.pageTitleCont}>
                        <PageTitle
                            editable
                            title={editorInfo.entry?.entryName ?? ''}
                            route={
                                siteInfo?.projects[siteInfo?.idProject ?? '']
                                    ?.name +
                                ' / Entradas / ' +
                                editorInfo.entry?.entryName
                            }
                        />
                    </div>
                    {/* Preview */}
                    {/* <div className={styles.previewBtn}>
                    <RoundedButton
                        text="Probar"
                        fontSize={22}
                        borderRadius={12}
                        padding="20px 12px"
                        onClick={(): void => {
                            dispatch(setShowPreview(true));
                        }}
                    />
                </div> */}
                    {editorInfo.showPreview && (
                        <Dialog
                            maxWidth="100%"
                            disableEnforceFocus
                            onClose={(): void => {
                                dispatch(setShowPreview(false));
                            }}
                        >
                            <div></div>
                            {/* TODO: <ConversationPreview /> */}
                        </Dialog>
                    )}
                    {saving && (
                        <SaveComponent
                            setSaving={(): void => {
                                setSaving(false);
                            }}
                            idProject={match.params.idProject}
                        />
                    )}
                    {showSettingsDialog && (
                        <Dialog
                            open={showSettingsDialog}
                            onClose={(): void => setShowSettingsDialog(false)}
                            maxWidth="100%"
                        >
                            <div className={styles.settingsContainer}>
                                <div className={styles.settingsLabel}>
                                    Configuraciones
                                </div>
                            </div>
                        </Dialog>
                    )}
                    <div className={styles.layoutContainer} ref={layoutRef}>
                        <div className={styles.stepsLayout} ref={listRef}>
                            <div
                                className={styles.initialMessage}
                                ref={initialRef}
                            >
                                <ClientReply>
                                    <div className={styles.replyContent}>
                                        <div className={styles.w2}></div>
                                        <div className={styles.w3}></div>
                                        <div className={styles.w1}></div>
                                    </div>
                                </ClientReply>
                            </div>
                            {mapSteps()}
                            <LastAddStep
                                key={'ADD-END'}
                                location={{
                                    type: LocationTypes.ROOT,
                                    indexStep: rootSteps.length,
                                    label: null,
                                }}
                            />
                            <CreateStepComponent key={'CREATE-END'} />
                            <div
                                className={styles.padding}
                                key={'PADDING-END'}
                            ></div>
                        </div>
                    </div>
                </div>
                <div className={styles.detailContainer}>
                    <div className={styles.containerLine}></div>
                    <StepDetail />
                </div>
            </div>
        </ApiKeyContext.Provider>
    );
}
export default withRouter((props: any) => <ConversationPage {...props} />);

function CreateStepComponent(): JSX.Element {
    const step: DefaultCreateStep = useSelector(
        (state: RootState) =>
            state.conversationEditor.steps[
                state.conversationEditor.idCreate
            ] as DefaultCreateStep
    );
    if (!step) {
        return <div></div>;
    }
    return <CreateStep step={step} isDefault />;
}

function SaveComponent({
    setSaving,
    idProject,
}: {
    setSaving: () => void;
    idProject: string;
}): JSX.Element {
    const editorInfo = useSelector(
        (state: RootState) => state.conversationEditor
    );
    const siteInfo = useSelector((state: RootState) => state.site);
    return (
        <SaveDialog
            onClose={setSaving}
            handleSave={async (): Promise<void> => {
                if (editorInfo.entry && siteInfo.organization?.idOrganization) {
                    const clone = {
                        apiKey: editorInfo.entry.apiKey,
                        name: editorInfo.entry.entryName,
                        description: editorInfo.entry.description,
                        idOrganization: siteInfo.organization.idOrganization,
                        steps: { ...editorInfo.steps },
                        rootSteps: editorInfo.rootSteps,
                        idCreate: editorInfo.idCreate,
                    };

                    await ConversationService.saveConversation(
                        idProject,
                        editorInfo.entry.apiKey,
                        clone
                    );
                }
            }}
        />
    );
}
