import { useCallback, useRef, useState } from 'react';
import styles from './AddStep.module.css';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import React from 'react';
import { StepLocation } from '../../../../controllers/ConversationEditorController/ConversationEditorReducer';
import { useDrop } from 'react-dnd';
import StepTypes, {
    LocationTypes,
} from '../../../../constants/Conversations/ConversationStepTypes';
import { DragItem } from '../StepList/Step';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../utils/_store';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import {
    pasteStep,
    moveStep,
} from '../../../../controllers/ConversationEditorController/ConversationEditorActions';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import StepMenu from './StepMenu/StepMenu';

interface AddStepProps {
    location: StepLocation;
    disabled?: boolean;
}
function AddStep({ location, disabled }: AddStepProps): JSX.Element {
    const controlled = location.controlled === true;
    const [hovering, setHovering] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);
    const dispatch = useDispatch();
    const ref = useRef<HTMLDivElement>(null);
    const btnRef = useRef<HTMLDivElement>(null);
    const canPaste = useSelector(
        (state: RootState) => state.conversationEditor.copiedInfo !== undefined
    );
    const moveItem = useCallback((idStep: string, location: StepLocation) => {
        dispatch(moveStep({ idStep, location }));
    }, []);
    const [{}, drop] = useDrop<DragItem, void, {}>(
        () => ({
            canDrop(item: DragItem): any {
                if (disabled || controlled) {
                    return false;
                }
                const itemLocation = item.path[item.path.length - 1];
                if (itemLocation.type === location.type) {
                    if (itemLocation.type === LocationTypes.ROOT) {
                        return (
                            itemLocation.indexStep !== location.indexStep - 1 &&
                            itemLocation.indexStep !== location.indexStep - 2
                        );
                    }
                    if (
                        (itemLocation.type === LocationTypes.GROUP ||
                            itemLocation.type === LocationTypes.AUTH_ERROR) &&
                        itemLocation.idStep === (location as any).idStep
                    ) {
                        return (
                            itemLocation.indexStep !== location.indexStep - 1 &&
                            itemLocation.indexStep !== location.indexStep - 2
                        );
                    }
                    if (
                        (itemLocation.type === LocationTypes.BUTTON ||
                            itemLocation.type === LocationTypes.LIST) &&
                        itemLocation.idStep === (location as any).idStep &&
                        itemLocation.idOption === (location as any).idOption
                    ) {
                        return (
                            itemLocation.indexStep !== location.indexStep - 1 &&
                            itemLocation.indexStep !== location.indexStep - 2
                        );
                    }
                }
                return true;
            },
            accept: Object.keys(StepTypes),
            collect(monitor): any {
                return {
                    isOver: !!monitor.isOver(),
                    canDrop: monitor.canDrop(),
                    item: monitor.getItem(),
                };
            },
            hover(item: DragItem, monitor): any {
                if (!ref.current) {
                    return;
                }
                if (disabled || controlled) {
                    return;
                }
                // Don't replace items with themselves
                if (item.lastMoved) {
                    item.lastMoved = undefined;
                }
                if (!monitor.isOver({ shallow: true })) {
                    return;
                }
                if (!!monitor.canDrop()) {
                    // // Time to actually perform the action
                    moveItem(item.id, location);
                    item.path[item.path.length - 1] = location;
                }
            },
        }),
        [location, disabled, controlled]
    );

    const handleContextMenu = (event: React.MouseEvent): void => {
        event.preventDefault();
        if (canPaste && !controlled) {
            setContextMenu(
                contextMenu === null
                    ? {
                          mouseX: event.clientX + 2,
                          mouseY: event.clientY - 6,
                      }
                    : null
            );
        }
    };

    drop(ref);
    return (
        <React.Fragment>
            <div
                className={styles.container}
                onMouseEnter={(): void => {
                    if (!controlled) {
                        setHovering(true);
                    }
                }}
                onMouseLeave={(): void => {
                    setHovering(false);
                }}
                ref={ref}
                onContextMenu={handleContextMenu}
            >
                {disabled && <div className={styles.disabledCurtain}></div>}
                <div
                    className={styles.container}
                    style={{
                        visibility: hovering ? 'visible' : 'hidden',
                    }}
                >
                    <div className={styles.separator}></div>
                    <div className={styles.btnsContainer}>
                        <div
                            className={styles.btnContainer}
                            onClick={(): void => {
                                setShowMenu(true);
                            }}
                            ref={btnRef}
                        >
                            <div className={styles.addIconContainer}>
                                <AddRoundedIcon fontSize="inherit" />
                            </div>
                        </div>
                        {canPaste && (
                            <div
                                className={styles.pasteContainer}
                                onClick={(): void => {
                                    dispatch(pasteStep(location));
                                }}
                            >
                                <div className={styles.pasteIconContainer}>
                                    <GetAppRoundedIcon fontSize="inherit" />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={styles.endSeparator}></div>
                </div>
            </div>
            <Menu
                open={contextMenu !== null}
                onClose={(): void => {
                    setContextMenu(null);
                }}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
                onContextMenu={(event): void => {
                    event.preventDefault();
                    setContextMenu(null);
                }}
            >
                <MenuItem
                    onClick={(): void => {
                        dispatch(pasteStep(location));
                        setContextMenu(null);
                    }}
                    disabled={!canPaste}
                >
                    <ListItemIcon style={{ minWidth: 35 }}>
                        <GetAppRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Pegar</ListItemText>
                </MenuItem>
            </Menu>
            {showMenu && (
                <StepMenu
                    location={location}
                    handleClose={(): void => {
                        setShowMenu(false);
                    }}
                    anchorRef={btnRef}
                />
            )}
        </React.Fragment>
    );
}

export default AddStep;
