import { AnyAction } from '@reduxjs/toolkit';
import { takeLatest, call, put, fork, select } from 'redux-saga/effects';
import * as actions from './EntityValuesActions';
import {
    loadEntityValueById,
    loadEntityValues,
    loadEntityValuesByQuery,
} from './EntityValuesService';
import { RootState } from '../../utils/_store';
import {
    EntityValuesPageState,
    entityValuesToQueryString,
} from './EntityValuesReducer';
import VanillaToast from '../../shared/Toast/Toast';
import ScrollTypes from '../../constants/ScrollTypes';
import { Entity } from '../../@Types/@Types';

/** Number of elements per Page */
const PAGE_SIZE = 20;

function* resetEntityvalues(action: AnyAction): any {
    if (actions.reset.match(action)) {
        if (
            action.payload.idEntityValue ||
            action.payload.queryString.length > 0
        ) {
            const entity = action.payload.entity;
            try {
                const result = yield call(
                    loadEntityValuesByQuery,
                    action.payload.entity,
                    action.payload.queryString,
                    action.payload.idEntityValue
                );
                const changes = { id: false, query: false };
                result.lastPage = result.elements.length < PAGE_SIZE;
                if (
                    action.payload.idEntityValue &&
                    result.selectedElement === undefined
                ) {
                    changes.id = true;
                    VanillaToast.create({
                        title: `No se encontró ${
                            entity.feminine ? 'la' : 'el'
                        } ${entity.name}`,
                        text: `${entity.name} no encontrad${
                            entity.feminine ? 'a' : 'o'
                        }`,
                        type: 'error',
                        timeout: 5000,
                    });
                }
                if (
                    action.payload.queryString.length > 0 &&
                    Object.keys(result.filters.values).length === 0 &&
                    Object.keys(result.filters.relationships).length === 0 &&
                    !result.search
                ) {
                    changes.query = true;
                }
                if (changes.id || changes.query) {
                    const newQuery = entityValuesToQueryString(
                        entity,
                        result.filters,
                        result.orderBy,
                        result.search,
                        true
                    );
                    let basePath = `/entities/${entity._id}`;
                    if (result.selectedElement !== undefined) {
                        basePath +=
                            '/' + result.elements[result.selectedElement]._id;
                    }
                    action.payload.history.replace({
                        pathname: basePath,
                        search: newQuery,
                        state: {
                            prevPath: action.payload.history.location.pathname,
                        },
                    });
                }
                yield put(actions.resetSuccess(result));
            } catch (error) {
                console.error(error);
            }
        } else {
            yield getEntityValues(action);
        }
    }
}

/**
 * Function called when new entityValues should be rendered (reset,search, or newpage)
 * @param action of type Search, reset or scroll
 */
function* getEntityValues(action: AnyAction): any {
    try {
        const pageInfo: EntityValuesPageState = yield select(
            (state) => state.entityValuesPage
        );
        let nPage = 1;
        if (actions.pageScroll.match(action)) {
            nPage =
                action.payload === ScrollTypes.DOWN
                    ? pageInfo.pageDown
                    : pageInfo.pageUp;
        }
        let result = yield call(
            loadEntityValues,
            pageInfo.entity!,
            nPage,
            PAGE_SIZE,
            pageInfo.filters,
            pageInfo.orderBy,
            pageInfo.search
        );
        const addedLength = result.length;
        /** Calcs if more elements are available */
        const lastPage =
            (pageInfo.pageDown !== 1 && result.length === 0) ||
            (pageInfo.pageDown === 1 && result.length < PAGE_SIZE);
        let selectedElement = pageInfo.selectedElement;
        if (actions.pageScroll.match(action)) {
            if (action.payload === ScrollTypes.DOWN) {
                result = [...pageInfo.elements].concat(result);
            } else {
                if (selectedElement !== undefined) {
                    selectedElement += result.length;
                }
                result = result.concat(...pageInfo.elements);
            }
        }
        let list;
        if (
            actions.pageScroll.match(action) &&
            action.payload === ScrollTypes.UP
        ) {
            list = document.getElementById('entityValues-infiniteList');
        }
        yield put(
            actions.getSuccess({ selectedElement, elements: result, lastPage })
        );
        if (list) list.scrollTop = addedLength * 115;
    } catch (error) {
        //TODO handle errors
        console.error(error);
    }
}

/**
 * Function called when an entityvalue needs to refresh its info
 * @param action of type Search, reset or scroll
 */
function* refreshEntityValue(): any {
    try {
        const pageInfo: EntityValuesPageState = yield select(
            (state: RootState) => state.entityValuesPage
        );
        if (pageInfo.selectedElement !== undefined) {
            const idEntityValue =
                pageInfo.elements[pageInfo.selectedElement]._id;
            let result = yield call(
                loadEntityValueById,
                pageInfo.entity!._id,
                idEntityValue
            );
            yield put(actions.refreshSuccess(result));
        }
    } catch (error) {
        //TODO handle errors
        console.error(error);
    }
}

function* goTo(action: AnyAction): any {
    try {
        if (actions.goTo.match(action)) {
            const idEntityValue = action.payload.idEntityValue;
            const pageInfo: EntityValuesPageState = yield select(
                (state: RootState) => state.entityValuesPage
            );
            const entity: Entity = yield select(
                (state: RootState) =>
                    state.site.entities[action.payload.idEntity]
            );
            const selectedElement =
                pageInfo.elements[pageInfo.selectedElement ?? -1];
            const queryChange =
                action.payload.queryString !==
                entityValuesToQueryString(
                    entity,
                    pageInfo.filters,
                    pageInfo.orderBy,
                    pageInfo.search,
                    true
                );
            if (queryChange) {
                yield put(
                    actions.reset({
                        entity: entity,
                        history: action.payload.history,
                        queryString: action.payload.queryString,
                        idEntityValue,
                    })
                );
            } else if (idEntityValue !== selectedElement?._id) {
                /** If the currently selected Element doesn't have the id in the url  */
                /** If user wants to unselect (no id in url) */
                if (idEntityValue === undefined) {
                    yield put(actions.selectElement(undefined));
                } else {
                    /** Check if element is in the current list. */
                    const index = pageInfo.elements.findIndex(
                        (listElement) => listElement._id === idEntityValue
                    );
                    /** IF element is in current list select it. */
                    if (index >= 0) {
                        yield put(actions.selectElement(index));
                    } else {
                        /** Reload page as if new one. */
                        yield put(
                            actions.reset({
                                entity: entity,
                                history: action.payload.history,
                                queryString: action.payload.queryString,
                                idEntityValue,
                            })
                        );
                    }
                }
            }
        }
    } catch (error) {
        //TODO handle errors
        console.error(error);
    }
}

function* watchResetEntityValues(): any {
    yield takeLatest([actions.Types.RESET], resetEntityvalues);
}

function* watchGetEntityValuesRequest(): any {
    yield takeLatest(
        [
            actions.Types.REFRESH,
            actions.Types.SEARCH,
            actions.Types.PAGE_SCROLL,
            actions.Types.FILTER,
        ],
        getEntityValues
    );
}

function* watchRefreshEntityValue(): any {
    yield takeLatest([actions.Types.REFRESH_CURRENT], refreshEntityValue);
}

function* watchGoTo(): any {
    yield takeLatest([actions.Types.GO_TO], goTo);
}
export default [
    fork(watchGoTo),
    fork(watchGetEntityValuesRequest),
    fork(watchRefreshEntityValue),
    fork(watchResetEntityValues),
];
