import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiAccordion, { AccordionProps } from '@material-ui/core/Accordion';
interface StyleProps {
    width?: string;
    /** The color of the outline when it is not selected */
    outlineColor?: string;
    /** The color of the background  */
    backgroundColor?: string;
    /** the color of the text */
    color?: string;
}

const useAccordionStyles = (props: StyleProps): Function =>
    makeStyles(() => ({
        root: {
            maxWidth: '100%',
            width: props.width,
            color: props.color,
            backgroundColor: props.backgroundColor,
            border: '1px solid ' + props.outlineColor,
            boxShadow: 'none',
            '&:not(:last-child)': {
                borderBottom: 0,
            },
            '&:last-child ': {
                borderRadius: '0px 0px 10px 10px',
                // borderBottom: 0,
            },
            '&:first-child ': {
                borderRadius: '10px 10px 0px 0px',
                // borderBottom: 0,
            },
            '&:before': {
                display: 'none',
            },
            '&$expanded': {
                margin: 'auto',
            },
        },
        expanded: {},
    }));

interface RoundedAccordionProps extends StyleProps {}

function RoundedAccordion({
    width = '0px',
    color = 'var(--accent)',
    outlineColor = 'var(--outlineGrey)',
    backgroundColor = 'var(--primary1)',
    ...others
}: AccordionProps & RoundedAccordionProps): JSX.Element {
    const classes = useAccordionStyles({
        width,
        color,
        backgroundColor,
        outlineColor,
    })();
    return (
        <MuiAccordion
            classes={classes}
            {...others}
            square
            TransitionProps={{ unmountOnExit: true }}
        />
    );
}
export default RoundedAccordion;
