import axiosInstance from '../../AxiosAPI';
import axios from 'axios';

export async function getUploadUrls(
    files: Array<File>,
    publicFile?: boolean
): Promise<(Record<string, any> | string)[]> {
    const payload = files.map((file: File) => ({
        publicFile: publicFile ?? false,
        extension: file?.name?.split?.('.')?.pop(),
    }));
    const response = await axiosInstance.post('/files/upload', payload);
    return response.data ?? undefined;
}

export async function fetchDownloadUrl(
    S3Key: string,
    fileName?: string,
    download = true
): Promise<string> {
    const params = new URLSearchParams({});
    if (fileName) params.set('fileName', fileName);
    if (download) params.set('download', 'true');
    let response = await axiosInstance.get(
        `/files/${S3Key}?${params.toString()}`
    );
    return response.data;
}

export async function postFile(
    file: File,
    postInfo: Record<string, any>
): Promise<{ fileName: string; S3Key: string; url?: string }> {
    const formData = new FormData();
    Object.keys(postInfo.postInfo.fields).forEach((key) => {
        formData.append(key, postInfo.postInfo.fields[key]);
    });

    // Actual file has to be appended last.
    formData.append('file', file);

    await axios.post(postInfo.postInfo.url, formData, {
        timeout: 300000,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    const response: { fileName: string; S3Key: string; url?: string } = {
        fileName: file.name,
        S3Key: postInfo.S3Key,
    };
    if (postInfo.publicURL) {
        response.url = postInfo.publicURL;
    }
    return response;
}
