import { AnyAction } from '@reduxjs/toolkit';
import { Entry } from '../../@Types/@Types';
import * as Actions from './EntriesActions';
const defaultOrderby = 'latest';

export interface EntriesPageState {
    /** The currently displayed Entries */
    elements: Entry[];
    /** The currently active filters */
    filters: any[]; //TODO Definir filtros
    /** The  way the elements are currently ordered. undefined if search is active*/
    orderBy: string | undefined; //TODO definir orderby
    /** The current search, undefined if none */
    search: string | undefined;
    /** the index of the currently selectedElement */
    selectedElement: number | undefined;
    /** If loader is active */
    loading: boolean;
}

const initialState = {
    elements: [],
    filters: [],
    orderBy: defaultOrderby,
    search: undefined,
    selectedElement: undefined,
    loading: true,
};

/**
 * Redux Reducer that handles EntriesActions Tiggers
 * @param state The current state
 * @param action the action that was triggered
 * @returns the new state
 */
const EntriesReducer = (
    state: EntriesPageState = initialState,
    action: AnyAction
): EntriesPageState => {
    if (Actions.reset.match(action)) {
        return {
            ...state,
            elements: [],
            filters: [],
            orderBy: defaultOrderby,
            search: undefined,
            selectedElement: undefined,
            loading: true,
        };
    } else if (Actions.setLoading.match(action)) {
        return {
            ...state,
            loading: action.payload,
        };
    } else if (Actions.getSuccess.match(action)) {
        return {
            ...state,
            loading: false,
            elements: action.payload.elements,
            selectedElement: action.payload.selectedElement,
        };
    } else if (Actions.selectElement.match(action)) {
        return { ...state, selectedElement: action.payload };
    } else if (Actions.updateElement.match(action)) {
        const elements = [...state.elements];
        /** If element is selected update the field with the value */
        if (state.selectedElement !== undefined) {
            elements[state.selectedElement] = {
                ...elements[state.selectedElement],
                [action.payload.field]: action.payload.value,
            };
        }

        return {
            ...state,
            elements,
        };
    } else if (Actions.updateFailed.match(action)) {
        const elements = [...state.elements];
        let index = state.selectedElement;
        if (
            index === undefined ||
            elements[index].apiKey !== action.payload.apiKey
        ) {
            index = elements.findIndex(
                (e) => e.apiKey === action.payload.apiKey
            );
        }
        if (index !== -1) {
            elements[index] = action.payload;
        }
        return {
            ...state,
            elements,
        };
    } else if (Actions.search.match(action)) {
        return {
            ...state,
            elements: [],
            selectedElement: undefined,
            loading: true,
            search: action.payload === '' ? undefined : action.payload,
            orderBy: action.payload === '' ? defaultOrderby : undefined,
        };
    } else if (Actions.orderBy.match(action)) {
        return {
            ...state,
            search: undefined,
            orderBy: action.payload,
        };
    } else if (Actions.filter.match(action)) {
        return {
            ...state,
            filters: action.payload,
        };
    }
    return state;
};
export default EntriesReducer;
