import { DraftEntityDataMappingTypes } from '../../../../constants/Draft/DraftEntityDataTypes';
import Context from '../Context';
import EntityPropertyTypes from '../../../../constants/EntityPropertyTypes';
import { IconProps } from '@material-ui/core';
import { GenericContextProps } from '../TextEditorContext';
import { DraftContext } from '../../../../@Types/Draft/DraftContext';
import { useMemo } from 'react';
import { useEntity, useIdProject } from '../../../../hooks';
import { IntegrationsApi } from '../../../../controllers/IntegratrionsController/IntegrationsService';
import IntegrationTypes from '../../../../constants/IntegrationTypes';
import { DraftIntegration } from '../../../../@Types/Integration';

interface EntityContextProps extends GenericContextProps {
    idEntity: string;
    context: DraftContext | undefined;
    icon?: (props: IconProps) => JSX.Element;
}

function EntityContext({
    context,
    idEntity,
    ...props
}: EntityContextProps): JSX.Element {
    const idProject = useIdProject();
    const entity = useEntity(idEntity);
    if (!entity) return <></>;

    const properties = useMemo(() => {
        if (!context?.isPublic) return Object.values(entity.steps);
        return Object.values(entity.steps).filter(
            (property) =>
                property.id === entity.idLabelStep || (property as any).public
        );
    }, [entity.steps, context?.isPublic]);

    const { data } = IntegrationsApi.useLoadIntegrationsQuery({
        idProject,
        idEntity,
        type: IntegrationTypes.DRAFT,
    });
    const integrations = data as DraftIntegration[];

    return (
        <>
            {properties.map((property, index) => {
                if (
                    property.type === EntityPropertyTypes.TITLE ||
                    property.type === EntityPropertyTypes.SEPARATOR ||
                    property.type === EntityPropertyTypes.ENTITY_RELATIONSHIP
                )
                    return;
                return (
                    <Context
                        {...props}
                        isMapping
                        key={'idEntity-' + property.id + '-' + index}
                        data={{
                            type: DraftEntityDataMappingTypes.ENTITYVALUE_MAPPING,
                            idEntity,
                            idProperty: property.id,
                        }}
                    />
                );
            })}
            {!context?.isPublic &&
                integrations?.map(
                    (integration: DraftIntegration): JSX.Element => {
                        return (
                            <Context
                                {...props}
                                isMapping
                                key={integration._id}
                                data={{
                                    type: DraftEntityDataMappingTypes.ENTITYVALUE_INTEGRATION_MAPPING,
                                    idEntity,
                                    idIntegration: integration._id,
                                }}
                            />
                        );
                    }
                )}
        </>
    );
}

export default EntityContext;
