export enum ConversationStepTypes {
    INFO_IMAGE_STEP = 'INFO_IMAGE_STEP',
    INFO_TEXT_STEP = 'INFO_TEXT_STEP',
    INFO_DOCUMENT_STEP = 'INFO_DOCUMENT_STEP',
    INFO_CONTACT_STEP = 'INFO_CONTACT_STEP',
    INFO_LOCATION_STEP = 'INFO_LOCATION_STEP',
    INFO_VIDEO_STEP = 'INFO_VIDEO_STEP',
    INFO_AUDIO_STEP = 'INFO_AUDIO_STEP',
    INFO_STICKER_STEP = 'INFO_STICKER_STEP',
    LIST_STEP = 'LIST_STEP',
    LIST_PAGE_STEP = 'LIST_PAGE_STEP',
    LIST_API_STEP = 'LIST_API_STEP',
    BUTTONS_STEP = 'BUTTONS_STEP',
    LINK_STEP = 'LINK_STEP',
    TEXT_STEP = 'TEXT_STEP',
    FILE_STEP = 'FILE_STEP',
    OPEN_STEP = 'OPEN_STEP',
    API_STEP = 'API_STEP',
    GROUP_STEP = 'GROUP_STEP',
    NAV_EXIT_STEP = 'NAV_EXIT_STEP',
    NAV_BACK_STEP = 'NAV_BACK_STEP',
    CREATABLE_STEP = 'CREATABLE_STEP',
    CREATE_PASSTHROUGH = 'CREATE_PASSTHROUGH',
    CREATE_DEFAULT = 'CREATE_DEFAULT',
    ENTITY_VALUE_STEP = 'ENTITY_VALUE_STEP',
    TEMPLATE_STEP = 'TEMPLATE_STEP',
}

export const InformativeStepTypes = [
    ConversationStepTypes.INFO_TEXT_STEP,
    ConversationStepTypes.INFO_IMAGE_STEP,
    ConversationStepTypes.INFO_DOCUMENT_STEP,
    ConversationStepTypes.LINK_STEP,
    ConversationStepTypes.INFO_CONTACT_STEP,
    ConversationStepTypes.INFO_LOCATION_STEP,
    ConversationStepTypes.INFO_VIDEO_STEP,
    ConversationStepTypes.INFO_AUDIO_STEP,
];

export const CollectionStepTypes = [
    ConversationStepTypes.TEXT_STEP,
    ConversationStepTypes.LIST_STEP,
    ConversationStepTypes.LIST_API_STEP,
    ConversationStepTypes.LIST_PAGE_STEP,
    ConversationStepTypes.FILE_STEP,
    ConversationStepTypes.BUTTONS_STEP,
    ConversationStepTypes.OPEN_STEP,
    ConversationStepTypes.CREATABLE_STEP,
    ConversationStepTypes.ENTITY_VALUE_STEP,
];

export enum LocationTypes {
    ROOT = 'ROOT',
    LIST = 'LIST',
    BUTTON = 'BUTTON',
    GROUP = 'GROUP',
    CREATABLE = 'CREATABLE',
    AUTH_ERROR = 'AUTH_ERROR',
}

export default ConversationStepTypes;
