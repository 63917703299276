import styles from './Term.module.css';
import { useState } from 'react';
import { ClickAwayListener, MenuItem } from '@material-ui/core';
import RoundedCheckBox from '../../../../../shared/RoundedCheckBox/RoundedCheckBox';
import RoundedTextField from '../../../../../shared/RoundedTextField/RoundedTextField';
import RoundedSelect from '../../../../../shared/RoundedSelect/RoundedSelect';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { Term } from '../../../../../@Types/GenericForm';
interface TermProps {
    term: Term;
    updateTerm: Function;
    handleDelete: Function;
}

function TermComponent({
    term,
    updateTerm,
    handleDelete,
}: TermProps): JSX.Element {
    const [editing, setEditing] = useState(false);

    /**
     * Function that renders the highlighted text
     * @returns a string with span highlighted
     */
    const renderText = (text: string): any => {
        if (text) {
            var parts: any = text.split(/{{(.*?)}}/gi);
            for (var i = 1; i < parts.length; i += 2) {
                parts[i] = (
                    <span className={styles.highlight} key={i}>
                        {parts[i]}
                    </span>
                );
            }
            return parts;
        }
    };

    if (editing) {
        return (
            <ClickAwayListener
                mouseEvent="onMouseDown"
                onClickAway={(): void => {
                    setEditing(false);
                }}
            >
                <div className={styles.editingContainer}>
                    <div className={styles.btnContainer}>
                        <div
                            className={styles.deleteBtn}
                            onClick={(): void => {
                                handleDelete();
                                setEditing(false);
                            }}
                        >
                            <DeleteRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                    <RoundedTextField
                        backgroundColor="var(--primary1)"
                        label="Mensaje"
                        value={term.message}
                        helperText={
                            'Utilice {{ y }} para encapsular la parte del mensaje interactiva'
                        }
                        placeholder={'Acepto los {{Términos y Condiciones}}'}
                        onChange={(e: any): void => {
                            updateTerm({
                                ...term,
                                message: e.target.value,
                            });
                        }}
                    ></RoundedTextField>

                    <div className={styles.interactionContainer}>
                        <div className={styles.interactionLabel}>
                            Tipo de Interacción:{' '}
                        </div>
                        <div className={styles.interactionSelectorContainer}>
                            <RoundedSelect
                                fullWidth
                                handleUpdate={(e): void => {
                                    const type = e.target.value;
                                    if (type) {
                                        updateTerm({
                                            ...term,
                                            clickEffect: {
                                                type,
                                            },
                                        });
                                    } else {
                                        const tempTerm = { ...term };
                                        delete tempTerm.clickEffect;
                                        updateTerm(tempTerm);
                                    }
                                }}
                                value={term.clickEffect?.type ?? ''}
                                label={''}
                                displayEmpty
                                MenuProps={{ disablePortal: true }}
                                height={'31px'}
                            >
                                <MenuItem value="">
                                    <em>Ninguna</em>
                                </MenuItem>
                                <MenuItem value={'DIALOG'}>Modal</MenuItem>
                                <MenuItem value={'LINK'}>Link</MenuItem>
                            </RoundedSelect>
                        </div>
                    </div>
                    {term.clickEffect?.type === 'LINK' && (
                        <div className={styles.linkInteractionContainer}>
                            <RoundedTextField
                                label="Url del Link"
                                value={term.clickEffect.url}
                                onChange={(e): void => {
                                    updateTerm({
                                        ...term,
                                        clickEffect: {
                                            ...term.clickEffect,
                                            url: e.target.value,
                                        },
                                    });
                                }}
                            ></RoundedTextField>
                        </div>
                    )}
                    {term.clickEffect?.type === 'DIALOG' && (
                        <div className={styles.otherInteractionContainer}>
                            <div className={styles.dialogTitleContainer}>
                                <RoundedTextField
                                    label="Titulo del modal"
                                    value={term.clickEffect.title}
                                    onChange={(e): void => {
                                        updateTerm({
                                            ...term,
                                            clickEffect: {
                                                ...term.clickEffect,
                                                title: e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </div>
                            <div className={styles.dialogContentContainer}>
                                <RoundedTextField
                                    label="Contenido"
                                    multiline
                                    maxRows={6}
                                    minRows={4}
                                    value={term.clickEffect.text}
                                    onChange={(e): void => {
                                        updateTerm({
                                            ...term,
                                            clickEffect: {
                                                ...term.clickEffect,
                                                text: e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </ClickAwayListener>
        );
    } else {
        return (
            <div
                className={styles.container + ' noselect'}
                onClick={(): void => setEditing(true)}
            >
                <div className={styles.checkboxContainer}>
                    <RoundedCheckBox
                        padding="4px"
                        size="1.3rem"
                        checked
                        cantEdit
                    />
                </div>
                <div className={styles.messageContainer}>
                    {renderText(term.message)}
                </div>
            </div>
        );
    }
}

export default TermComponent;
