import { RawDraftContentState } from 'draft-js';
import { DraftEntityTypes } from '../../constants/Draft/DraftEntityTypes';
import { DraftEntityData, compareDraftEntityDatas } from './DraftEntityData';
import {
    KeysOfUnion,
    compareRecord,
    deepCompare,
} from '../../utils/DeepCompare';

export interface EurekaDraft extends Omit<RawDraftContentState, 'entityMap'> {
    entityMap: DraftEntityMap;
}

type DraftEntity = ImageDraftEntity | EurekaDraftEntity;

export type DraftEntityMap = Record<string, DraftEntity>;

export interface ImageDraftEntity {
    type: DraftEntityTypes.IMAGE;
    mutability: 'IMMUTABLE';
    data: {
        imageKey: string;
    };
}

export interface EurekaDraftEntity {
    type: DraftEntityTypes.EUREKA;
    mutability: 'IMMUTABLE';
    data: DraftEntityData;
}

export function compareDrafts(
    actual: EurekaDraft,
    expected: EurekaDraft
): boolean {
    return (
        deepCompare(actual, expected, ['entityMap']) &&
        compareRecord(
            actual.entityMap,
            expected.entityMap,
            compareDraftEntities
        )
    );
}

export function compareDraftEntities(
    actual: DraftEntity | undefined,
    expected: DraftEntity | undefined
): boolean {
    if (!actual || !expected) return true;
    const omits: KeysOfUnion<DraftEntity>[] = [];
    if (
        actual.type === DraftEntityTypes.EUREKA &&
        actual.type === expected.type
    ) {
        if (!compareDraftEntityDatas(actual.data, expected.data)) return false;
        omits.push('data');
    }
    return deepCompare(actual, expected, omits);
}
