import { AnyAction } from '@reduxjs/toolkit';
import { Entry } from '../../@Types/@Types';
import { ConversationStep } from '../../@Types/ConversationTypes/ConversationStep';
import Types, {
    LocationTypes,
} from '../../constants/Conversations/ConversationStepTypes';
import StepTypes from '../../constants/Conversations/ConversationStepTypes';
import * as Actions from './ConversationEditorActions';
import { nanoid } from 'nanoid';
import produce from 'immer';
import { createConversationStep } from '../../pages/Conversations/ConversationEditor/StepFunctions';
import { convertFromRaw } from 'draft-js';
import CBRStepTypes from '../../constants/Construction/CBRFormStepTypes';
import { EntityValueOptionTypes } from '../../constants/OptionTypes';
import { EurekaDraft } from '../../@Types/Draft/Draft';
import { ApiOptionTypes } from '../../constants/ApiOptionTypes';

export interface StepDependencies {
    dependencies: Record<string, string[]>; //Steptype => arreglo de pasos anteriores.
    previousSteps: string[]; //pila de los pasos anteriores para poder usar en condiciones
    idParent: string | null;
}

export interface ConversationPageState {
    /** If loader is active */
    loading: boolean;
    /** If the preview is being displayed */
    showPreview: boolean;
    /** The id of the selectedStep */
    selectedStep: { id: string; path: StepLocation[] | null } | undefined;
    /** All the steps in the conversation */
    steps: Record<string, ConversationStep>;
    /** The conversation's root steps */
    rootSteps: string[];
    /** Record of the StepDependencies of each step */
    stepDependencies: Record<string, StepDependencies>;
    /** The Conversation's Entry */
    entry: Entry | undefined;
    /** The conversation's create step */
    idCreate: string;
    /** The current Max width of draftJS imgs in the list. */
    imgMaxWidth: number;
    /** The currently copied information */
    copiedInfo:
        | {
              newStepId: string;
              newSteps: Record<string, ConversationStep>;
          }
        | undefined;
}

interface BaseLocation {
    type: LocationTypes;
    label: string | null;
    controlled?: boolean;
}

export interface ListOptionLocation extends BaseLocation {
    type: LocationTypes.LIST;
    idStep: string;
    idOption: string;
    indexStep: number;
}

export interface ButtonOptionLocation extends BaseLocation {
    type: LocationTypes.BUTTON;
    idStep: string;
    idOption: string;
    indexStep: number;
}

export interface GroupStepLocation extends BaseLocation {
    type: LocationTypes.GROUP;
    idStep: string;
    indexStep: number;
}

export interface RootLocation extends BaseLocation {
    type: LocationTypes.ROOT;
    indexStep: number;
}
export interface CreatableLocation extends BaseLocation {
    type: LocationTypes.CREATABLE;
    idStep: string;
    indexStep: number;
}

export interface AuthErrorLocation extends BaseLocation {
    type: LocationTypes.AUTH_ERROR;
    idStep: string;
    indexStep: number;
}

export type StepLocation =
    | ListOptionLocation
    | ButtonOptionLocation
    | GroupStepLocation
    | CreatableLocation
    | RootLocation
    | AuthErrorLocation;

const initialState = {
    loading: true,
    showPreview: false,
    selectedStep: undefined,
    steps: {},
    stepDependencies: {},
    rootSteps: [],
    entry: undefined,
    idCreate: 'CREATE',
    imgMaxWidth: 0,
    copiedInfo: undefined,
};

/**
 * Redux Reducer that handles ConversationsActions Tiggers
 * @param state The current state
 * @param action the action that was triggered
 * @returns the new state
 */
const ConversationsReducer = (
    state: ConversationPageState = initialState,
    action: AnyAction
): ConversationPageState => {
    if (Actions.reset.match(action)) {
        if (Actions.reset.match(action)) {
            return {
                ...state,
                ...(initialState as any),
            };
        }
    } else if (Actions.getSuccess.match(action)) {
        //TODO: precalc dependencies!
        return calcDependencies({
            ...state,
            entry: action.payload.entry,
            steps: action.payload.conversation.steps,
            rootSteps: action.payload.conversation.rootSteps,
            loading: false,
            copiedInfo: undefined,
        });
    } else if (Actions.selectStep.match(action)) {
        return {
            ...state,
            selectedStep: action.payload,
        };
    } else if (Actions.updateSteps.match(action)) {
        return {
            ...state,
            steps: action.payload,
        };
    } else if (Actions.setShowPreview.match(action)) {
        return {
            ...state,
            showPreview: action.payload,
        };
    } else if (Actions.addStep.match(action)) {
        const newState = produce(state, (state) => {
            state.steps[action.payload.step.id] = action.payload.step;
            if (action.payload.steps) {
                for (const step of action.payload.steps) {
                    state.steps[step.id] = step;
                }
            }
            state.selectedStep = { id: action.payload.step.id, path: null };
        });
        return calcDependencies(
            action.payload.location
                ? addStep(
                      newState,
                      action.payload.step.id,
                      action.payload.location
                  )
                : newState
        );
    } else if (Actions.deleteStep.match(action)) {
        return calcDependencies(deleteStep(state, action.payload));
    } else if (Actions.updateConv.match(action)) {
        const newState = produce(state, (state) => {
            if (action.payload.step) {
                state.steps[action.payload.step.id] = action.payload.step;
            }
            if (action.payload.addSteps) {
                if (action.payload.addSteps) {
                    for (const step of action.payload.addSteps) {
                        state.steps[step.id] = step;
                    }
                }
            }
            if (action.payload.deleteSteps) {
                for (const idStep of action.payload.deleteSteps) {
                    const stepLocation = calcStepLocation(state, idStep);
                    if (stepLocation) {
                        const { index, steps } = stepLocation;
                        steps.splice(index, 1);
                    }
                    delete state.steps[idStep];
                    if (state.selectedStep?.id === idStep) {
                        state.selectedStep = undefined;
                    }
                }
            }
        });
        return calcDependencies(newState);
    } else if (Actions.pasteStep.match(action)) {
        //If step has already been copied
        if (state.copiedInfo !== undefined) {
            const newSteps: Record<string, ConversationStep> = {};
            const newIdStep = cloneStep(
                state.copiedInfo.newStepId,
                state.copiedInfo.newSteps,
                newSteps
            );
            const newState = produce(state, (state) => {
                for (const newStep of Object.values(newSteps)) {
                    state.steps[newStep.id] = newStep;
                }
                state.selectedStep = { id: newIdStep, path: null };
            });
            return calcDependencies(
                addStep(newState, newIdStep, action.payload)
            );
        }
    } else if (Actions.copyStep.match(action)) {
        const newSteps = {};
        const newStepId = cloneStep(action.payload, state.steps, newSteps);
        return { ...state, copiedInfo: { newStepId, newSteps } };
    } else if (Actions.cutStep.match(action)) {
        const newSteps = {};
        const newStepId = cloneStep(action.payload, state.steps, newSteps);
        return calcDependencies(
            deleteStep(
                { ...state, copiedInfo: { newStepId, newSteps } },
                action.payload
            )
        );
    } else if (Actions.updateStep.match(action)) {
        return {
            ...state,
            steps: {
                ...state.steps,
                [action.payload.id]: action.payload,
            },
        };
    } else if (Actions.updateStepFile.match(action)) {
        const step: any = {
            ...state.steps[action.payload.idStep],
            file: action.payload.file,
        };
        if (action.payload.dimensions) {
            step.width = action.payload.dimensions.width;
            step.ratio = action.payload.dimensions.ratio;
        }
        return {
            ...state,
            steps: {
                ...state.steps,
                [action.payload.idStep]: step as any,
            },
        };
    } else if (Actions.updateInfoStepType.match(action)) {
        const step: any = {
            ...state.steps[state.selectedStep?.id ?? ''],
        };
        const type = action.payload;
        if (type !== step.type) {
            let message: EurekaDraft | null = null;
            switch (step.type) {
                case StepTypes.LINK_STEP:
                case StepTypes.INFO_TEXT_STEP:
                    message = step.message;
                    break;
                case StepTypes.INFO_IMAGE_STEP:
                case StepTypes.INFO_DOCUMENT_STEP:
                case StepTypes.INFO_VIDEO_STEP:
                    message = step.caption;
                    break;
                default:
                    break;
            }
            const newStep = createConversationStep(type, step.id);

            if (message !== null) {
                switch (newStep.type) {
                    case StepTypes.LINK_STEP:
                    case StepTypes.INFO_TEXT_STEP:
                        if (convertFromRaw(message).hasText())
                            newStep.message = message;
                        break;
                    case StepTypes.INFO_IMAGE_STEP:
                    case StepTypes.INFO_DOCUMENT_STEP:
                    case StepTypes.INFO_VIDEO_STEP:
                        newStep.caption = message;
                        break;
                    default:
                        break;
                }
            }
            return {
                ...state,
                steps: {
                    ...state.steps,
                    [step.id]: newStep as any,
                },
            };
        }
        return {
            ...state,
        };
    } else if (Actions.moveStep.match(action)) {
        return calcDependencies(moveStep(state, action.payload));
    } else if (Actions.updateStepId.match(action)) {
        return calcDependencies(handleUpdateStepId(state, action.payload));
    }
    if (Actions.setImgMaxWidth.match(action)) {
        return { ...state, imgMaxWidth: action.payload };
    }
    return state;
};
export default ConversationsReducer;

const calcDependencies = (
    state: ConversationPageState
): ConversationPageState => {
    const newStepDeps = {};
    const currentDeps = {
        dependencies: {},
        previousSteps: [],
        idParent: null,
    };
    for (const idStep of state.rootSteps) {
        calcStepDependencies(
            idStep,
            state.steps,
            newStepDeps,
            currentDeps,
            null
        );
    }
    return { ...state, stepDependencies: newStepDeps };
};

const calcStepDependencies = (
    idStep: string,
    steps: Record<string, ConversationStep>,
    stepDeps: Record<string, StepDependencies>,
    deps: StepDependencies,
    idParent: string | null
): void => {
    const step = steps[idStep];
    stepDeps[idStep] = {
        dependencies: { ...deps.dependencies },
        previousSteps: [...deps.previousSteps],
        idParent,
    };
    if (!deps.dependencies[step.type]) deps.dependencies[step.type] = [];
    deps.dependencies[step.type].push(idStep);
    deps.previousSteps.push(idStep);

    if (step.type === Types.TEXT_STEP && step.authentication) {
        for (const idSub of step.authentication.errorSteps) {
            calcStepDependencies(idSub, steps, stepDeps, deps, idStep);
        }
    } else if (step.type === Types.BUTTONS_STEP) {
        for (const idOption of step.optionList) {
            const option = step.options[idOption];
            if (option) {
                const oDeps = JSON.parse(JSON.stringify(deps));
                for (const idSub of option.steps) {
                    calcStepDependencies(idSub, steps, stepDeps, oDeps, idStep);
                }
            }
        }
    } else if (
        step.type === Types.LIST_STEP ||
        step.type === Types.LIST_PAGE_STEP
    ) {
        const options = Object.values(step.options);
        for (const option of options) {
            const oDeps = JSON.parse(JSON.stringify(deps));
            for (const idSub of option.steps) {
                calcStepDependencies(idSub, steps, stepDeps, oDeps, idStep);
            }
        }
    } else if (step.type === Types.LIST_API_STEP) {
        const options = Object.values(step.options);
        for (const option of options) {
            if (option.type === ApiOptionTypes.HIDE) continue;
            const oDeps = JSON.parse(JSON.stringify(deps));
            for (const idSub of option.steps) {
                calcStepDependencies(idSub, steps, stepDeps, oDeps, idStep);
            }
        }
    } else if (
        step.type === StepTypes.GROUP_STEP ||
        step.type === StepTypes.CREATABLE_STEP
    ) {
        for (const idSub of step.steps) {
            calcStepDependencies(idSub, steps, stepDeps, deps, idStep);
        }
    } else if (step.type === Types.ENTITY_VALUE_STEP) {
        for (const option of Object.values(step.options)) {
            if (option.type === EntityValueOptionTypes.NESTED) {
                const oDeps = JSON.parse(JSON.stringify(deps));
                for (const idSub of option.steps) {
                    calcStepDependencies(idSub, steps, stepDeps, oDeps, idStep);
                }
            }
        }
    } else if (step.type.startsWith('CBR')) {
        if ((step as any).type === CBRStepTypes.CBR_LOCATIVAS) {
            if ((step as any).subStep) {
                calcStepDependencies(
                    (step as any).subStep,
                    steps,
                    stepDeps,
                    deps,
                    idStep
                );
            }
        }
    }
};

const addStep = (
    state: ConversationPageState = initialState,
    idStep: string,
    location: StepLocation
): ConversationPageState => {
    return produce(state, (state) => {
        const subSteps = calcLocationSteps(state, location);
        if (subSteps) {
            subSteps.splice(location.indexStep, 0, idStep);
        }
    });
};

const deleteStep = (
    state: ConversationPageState = initialState,
    idStep: string
): ConversationPageState => {
    return produce(state, (state) => {
        const stepLocation = calcStepLocation(state, idStep);
        if (stepLocation) {
            const { index, steps } = stepLocation;
            steps.splice(index, 1);
        }
        delete state.steps[idStep];
        state.selectedStep = undefined;
    });
};

function cloneStep(
    idStep: string,
    steps: Record<string, ConversationStep>,
    newSteps: Record<string, ConversationStep>
): string {
    const tempStep = { ...steps[idStep] };
    const newId = tempStep.type + '-' + nanoid();
    tempStep.id = newId;
    if (tempStep.type === Types.TEXT_STEP && tempStep.authentication) {
        tempStep.authentication.errorSteps =
            tempStep.authentication.errorSteps.map((idStep: string) =>
                cloneStep(idStep, steps, newSteps)
            );
    } else if (tempStep.type === Types.BUTTONS_STEP) {
        const tempOptions: Record<string, any> = {};
        const tempList: string[] = [];

        tempStep.optionList = tempStep.optionList.map((idOption) => {
            const option = { ...tempStep.options[idOption] };
            const newOptionId = newId + ';' + nanoid();
            option.id = newOptionId;
            option.steps = option.steps.map((pStep) =>
                cloneStep(pStep, steps, newSteps)
            );
            tempOptions[newOptionId] = option;
            tempList.push(newOptionId);
            return newOptionId;
        });
        tempStep.options = tempOptions;
    } else if (
        tempStep.type === Types.LIST_STEP ||
        tempStep.type === Types.LIST_PAGE_STEP
    ) {
        const tempOptions: Record<string, any> = {};
        tempStep.sections = tempStep.sections.map((section) => ({
            ...section,
            options: section.options.map((idOption) => {
                const option = { ...tempStep.options[idOption] };
                const newOptionId = newId + ';' + nanoid();
                option.id = newOptionId;
                option.steps = option.steps.map((pStep) =>
                    cloneStep(pStep, steps, newSteps)
                );
                tempOptions[newOptionId] = option;
                return newOptionId;
            }),
        }));
        tempStep.options = tempOptions;
    } else if (tempStep.type === Types.LIST_API_STEP) {
        const tempOptions: Record<string, any> = {};
        for (const [idOption, option] of Object.entries(tempStep.options)) {
            if (option.type === ApiOptionTypes.HIDE) continue;
            tempOptions[idOption] = {
                ...option,
                steps: option.steps.map((pStep) =>
                    cloneStep(pStep, steps, newSteps)
                ),
            };
        }
        tempStep.options = tempOptions;
    } else if (tempStep.type === Types.ENTITY_VALUE_STEP) {
        const tempOptions: Record<string, any> = {};
        for (const [idOption, option] of Object.entries(tempStep.options)) {
            if (option.type === EntityValueOptionTypes.NESTED) {
                tempOptions[idOption] = {
                    ...option,
                    steps: option.steps.map((pStep) =>
                        cloneStep(pStep, steps, newSteps)
                    ),
                };
            } else {
                tempOptions[idOption] = option;
            }
        }
        tempStep.options = tempOptions;
    } else {
        const nestedStep = tempStep as any;
        if (nestedStep.steps !== undefined) {
            nestedStep.steps = nestedStep.steps.map((idStep: string) =>
                cloneStep(idStep, steps, newSteps)
            );
        }
    }
    newSteps[newId] = tempStep;
    return newId;
}

const moveStep = (
    state: ConversationPageState = initialState,
    payload: { idStep: string; location: StepLocation }
): ConversationPageState =>
    produce(state, (state) => {
        const { idStep, location } = payload;
        const stepLocation = calcStepLocation(state, idStep);
        if (stepLocation) {
            const { index, steps } = stepLocation;
            steps.splice(index, 1);
        }
        const subSteps = calcLocationSteps(state, location);
        if (subSteps) {
            subSteps.splice(location.indexStep, 0, idStep);
        }
    });

export const handleUpdateStepId = (
    state: ConversationPageState = initialState,
    payload: {
        idStep: string;
        newId: string;
    }
): ConversationPageState => {
    const { idStep, newId } = payload;
    return produce(state, (state) => {
        const stepLocation = calcStepLocation(state, idStep);
        if (stepLocation) {
            const { index, steps } = stepLocation;
            steps[index] = newId;
        }
        state.steps[newId] = state.steps[idStep];
        state.steps[newId].id = newId;
        delete state.steps[idStep];
        if (state.selectedStep?.id === idStep) {
            state.selectedStep = { id: newId, path: null };
        }
    });
};

export const calcLocationSteps = (
    state: ConversationPageState,
    location: StepLocation
): string[] | void => {
    if (location.type == LocationTypes.ROOT) {
        return state.rootSteps;
    }
    const parentStep = state.steps[location.idStep];
    if (
        parentStep.type === Types.TEXT_STEP &&
        location.type === LocationTypes.AUTH_ERROR &&
        parentStep.authentication
    ) {
        return parentStep.authentication.errorSteps;
    } else if (
        (parentStep.type === Types.BUTTONS_STEP &&
            location.type === LocationTypes.BUTTON) ||
        ((parentStep.type === Types.LIST_STEP ||
            parentStep.type === Types.LIST_PAGE_STEP) &&
            location.type === LocationTypes.LIST)
    ) {
        const option = parentStep.options[location.idOption];
        if (option) {
            return option.steps;
        }
    } else if (
        parentStep.type === Types.LIST_API_STEP &&
        location.type === LocationTypes.LIST
    ) {
        const option = parentStep.options[location.idOption];
        if (option.type !== ApiOptionTypes.HIDE) {
            return option.steps;
        }
    } else if (
        (parentStep.type === Types.GROUP_STEP &&
            location.type === LocationTypes.GROUP) ||
        (parentStep.type === Types.CREATABLE_STEP &&
            location.type === LocationTypes.CREATABLE)
    ) {
        if (parentStep.steps) {
            return parentStep.steps;
        }
    } else if (
        parentStep.type === Types.ENTITY_VALUE_STEP &&
        location.type === LocationTypes.LIST
    ) {
        const option = parentStep.options[location.idOption];
        if (option.type === EntityValueOptionTypes.NESTED) {
            return option.steps;
        }
    }
};

const calcStepLocation = (
    state: ConversationPageState,
    idStep: string
): { index: number; steps: string[] } | void => {
    const idParent = state.stepDependencies[idStep].idParent;
    if (idParent) {
        const parentStep = state.steps[idParent];
        if (parentStep.type === Types.TEXT_STEP && parentStep.authentication) {
            const index = parentStep.authentication.errorSteps.findIndex(
                (id) => id === idStep
            );
            if (index !== -1)
                return { index, steps: parentStep.authentication.errorSteps };
        } else if (
            parentStep.type === Types.BUTTONS_STEP ||
            parentStep.type === Types.LIST_STEP ||
            parentStep.type === Types.LIST_PAGE_STEP
        ) {
            for (const idOption of Object.keys(parentStep.options)) {
                const option = parentStep.options[idOption];
                const index = option.steps.findIndex((id) => id === idStep);
                if (index !== -1) return { index, steps: option.steps };
            }
        } else if (parentStep.type === Types.LIST_API_STEP) {
            for (const idOption of Object.keys(parentStep.options)) {
                const option = parentStep.options[idOption];
                if (option.type === ApiOptionTypes.HIDE) continue;
                const index = option.steps.findIndex((id) => id === idStep);
                if (index !== -1) return { index, steps: option.steps };
            }
        } else if (
            parentStep.type === Types.GROUP_STEP ||
            parentStep.type === Types.CREATABLE_STEP
        ) {
            const index = parentStep.steps.findIndex((id) => id === idStep);
            if (index !== -1) return { index, steps: parentStep.steps };
        } else if (parentStep.type === Types.ENTITY_VALUE_STEP) {
            for (const option of Object.values(parentStep.options)) {
                if (option.type === EntityValueOptionTypes.NESTED) {
                    const index = option.steps.findIndex((id) => id === idStep);
                    if (index !== -1) return { index, steps: option.steps };
                }
            }
        }
    } else {
        const index = state.rootSteps.findIndex((id) => id === idStep);
        if (index !== -1) return { index, steps: state.rootSteps };
    }
};
