import { MenuItem } from '@material-ui/core';
import RoundedSelect, {
    RoundedSelectProps,
} from '../../RoundedSelect/RoundedSelect';
import styles from './RoundedIconPicker.module.css';
import IconTypes from '../../../constants/IconTypes';
import InputIcon from '../../InputIcon/InputIcon';

interface IconPickerProps
    extends Omit<RoundedSelectProps, 'handleUpdate' | 'value'> {
    /** Currently selected clientProperty id */
    value: IconTypes | string;
    /** function called when value changes */
    handleUpdate: (type: IconTypes) => void;
}
const menuStyle = {
    paddingLeft: 0,
    paddingRight: 0,
};

function IconPicker({
    value,
    handleUpdate,
    ...others
}: IconPickerProps): JSX.Element {
    return (
        <RoundedSelect
            value={value}
            paddingLeft={0}
            paddingRight={0}
            handleUpdate={(event): void => {
                const value = event.target.value ?? '';
                handleUpdate(value);
            }}
            minWidth={50}
            {...others}
        >
            {Object.keys(IconTypes).map((icon) => {
                return (
                    <MenuItem style={menuStyle} value={icon} key={icon}>
                        <div className={styles.property}>
                            <InputIcon icon={icon} />
                        </div>
                    </MenuItem>
                );
            })}
        </RoundedSelect>
    );
}
export default IconPicker;
