import React from 'react';
import { Checkbox, CheckboxProps, makeStyles } from '@material-ui/core';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import CheckBoxOutlineBlankRoundedIcon from '@material-ui/icons/CheckBoxOutlineBlankRounded';
interface StyleProps {
    error?: boolean;
    padding?: string;
    uncheckedColor?: string;
    checkedColor?: string;
    checkedHoverColor?: string;
    errorColor?: string;
    disabledColor?: string;
    size?: number | string;
    cantEdit?: boolean;
}

const useCheckBoxStyles = (props: StyleProps): Function =>
    makeStyles(() => ({
        root: {
            padding: props.padding,
            fontSize: props.size,
            color: props.error ? props.errorColor : props.uncheckedColor,
            '&.Mui-checked': {
                color: props.checkedColor,
            },
            '&.Mui-checked:hover': {
                backgroundColor: props.checkedHoverColor,
            },
            '&.Mui-disabled': {
                cursor: 'default',
                color: props.cantEdit
                    ? props.disabledColor
                    : props.checkedColor,
            },
            '&:hover': {
                backgroundColor: props.cantEdit
                    ? 'transparent'
                    : props.checkedHoverColor,
            },
        },
    }));

interface ComponentProps extends StyleProps {
    onChange?: Function;
    checked: boolean;
}

/**
 * Generic rounded checkbox
 */
function CustomCheckBox({
    padding = '9px',
    onChange,
    error = false,
    size = '1.5rem',
    checkedColor = 'var(--secondary)',
    checkedHoverColor = 'var(--transparentsecondary)',
    uncheckedColor = 'var(--greyFont)',
    disabledColor = 'var(--disabledGrey)',
    errorColor = 'var(--error)',
    cantEdit = false,
    checked,
    ...others
}: ComponentProps & Omit<CheckboxProps, 'size'>): JSX.Element {
    const classes = useCheckBoxStyles({
        size,
        error,
        padding,
        cantEdit,
        errorColor,
        checkedColor,
        disabledColor,
        uncheckedColor,
        checkedHoverColor,
    })();

    return (
        <Checkbox
            {...others}
            disabled={cantEdit}
            classes={classes}
            checked={checked}
            onChange={onChange}
            required={true}
            icon={<CheckBoxOutlineBlankRoundedIcon fontSize="inherit" />}
            checkedIcon={<CheckBoxRoundedIcon fontSize="inherit" />}
        />
    );
}

/**
 * Generic datepicker with apps designs. Is class do to the use in the react-hook-forms library
 */
class RoundedCheckBox extends React.Component<
    ComponentProps & Omit<CheckboxProps, 'size'>
> {
    render(): JSX.Element {
        return <CustomCheckBox {...this.props} />;
    }
}
export default RoundedCheckBox;
