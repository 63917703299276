import { Agent, Area } from '../../@Types/@Types';
import axiosInstance from '../../AxiosAPI';

/**
 * Function that loads the agents' list
 * @param page currently viewing
 * @param pageSize to request
 * @param idProject the id project to view
 * @param areafilter currently active areaFilter
 * @param search search query, undefined if no search is active
 * @returns array of Agents that match the inputs
 */
export const loadAgents = async (
    page: number,
    pageSize: number,
    idProject: string | undefined | null,
    areafilter: Area | null,
    search: string | undefined
): Promise<Agent[]> => {
    let url = `/agents?page=${page}&pageSize=${pageSize}`;
    if (idProject) {
        url += `&idProject=${idProject}`;
    }
    if (areafilter !== null) {
        url += `&areafilter=${areafilter._id}`;
    }
    if (search !== undefined) {
        url += `&search=${encodeURIComponent(search)}`;
    } else {
        url += '&orderBy=latest';
    }
    const response = await axiosInstance.get(url);
    return response.data;
};

/**
 * Function that loads the agents by a given list of ids
 * @param agents the list of ids
 */
export const loadAgentsById = async (agents: string[]): Promise<Agent[]> => {
    const response = await axiosInstance.get(`/agents?id=${agents.join(',')}`);
    return response.data;
};

export default { loadAgents };
