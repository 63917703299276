import { useMemo } from 'react';
import { ErkValue } from '../../../@Types/ErkValue';
import { IntegrationsApi } from '../../../controllers/IntegratrionsController/IntegrationsService';
import { useCurrentProject } from '../../../hooks';
import { IntegrationConfigurationTypes } from '../../../constants/IntegrationTypes';
import ErkValueTypes from '../../../constants/ErkValueTypes';
import { TextEditorTypes } from '../../../constants/TextEditorTypes';
import PayloadEditor from '../../TextEditor/PayloadEditor';
import React from 'react';
import PreviousConversationStepsPicker from '../../@Pickers/PreviousConversationStepPicker/PreviousConversationStepsPicker';
import PreviousFormStepsPicker from '../../@Pickers/PreviousFormStepPicker/PreviousFormStepsPicker';
import { DraftContext } from '../../../@Types/Draft/DraftContext';

interface IntegrationErkValuesPickerProps {
    context?: DraftContext;
    idIntegration: string;
    values: Record<string, ErkValue> | undefined;
    handleUpdate: (values: Record<string, ErkValue> | undefined) => void;
}

function IntegrationErkValuesPicker({
    context,
    idIntegration,
    values,
    handleUpdate,
}: IntegrationErkValuesPickerProps): JSX.Element {
    const { data: integration } =
        IntegrationsApi.useLoadIntegrationQuery(idIntegration);
    const project = useCurrentProject();
    const overrides = project.integrationOverrides?.[idIntegration];
    const params = useMemo(() => {
        if (!integration) return [];
        if (
            integration.configuration.type ===
            IntegrationConfigurationTypes.LAMBDA
        ) {
            return Object.entries(integration.configuration.payload);
        } else {
            return [];
        }
    }, [integration?.configuration]);

    return (
        <>
            {params.map(([key, value]) => {
                const erkValue =
                    values?.[key] ?? overrides?.[key] ?? value.defaultValue;
                switch (value.configurableType) {
                    case ErkValueTypes.DRAFT: {
                        return (
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                                key={idIntegration + '-' + key}
                            >
                                <div
                                    style={{
                                        fontSize: '12px',
                                        marginLeft: '15px',
                                        color: 'var(--accent)',
                                    }}
                                >
                                    {value.name}:
                                </div>
                                <PayloadEditor
                                    idEditor={idIntegration + '-' + key}
                                    media={false}
                                    placeholder={value.name}
                                    multiline={false}
                                    type={TextEditorTypes.INPUT}
                                    context={context}
                                    payload={{
                                        draft:
                                            erkValue?.type ===
                                            ErkValueTypes.DRAFT
                                                ? erkValue.value
                                                : undefined,
                                    }}
                                    onChange={({ draft }): void => {
                                        if (!draft) return;
                                        handleUpdate({
                                            ...values,
                                            [key]: {
                                                type: ErkValueTypes.DRAFT,
                                                value: draft,
                                            },
                                        });
                                    }}
                                />
                            </div>
                        );
                    }
                    case ErkValueTypes.STEP: {
                        if (context?.conversation?.idStep) {
                            return (
                                <PreviousConversationStepsPicker
                                    key={idIntegration + '-' + key}
                                    value={
                                        erkValue?.type === ErkValueTypes.STEP
                                            ? erkValue.idStep
                                            : undefined
                                    }
                                    label={value.name}
                                    required={value.required}
                                    helperText={value.description}
                                    idStep={context.conversation?.idStep}
                                    handleUpdate={(step): void => {
                                        handleUpdate({
                                            ...values,
                                            [key]: {
                                                type: ErkValueTypes.STEP,
                                                idStep: step.id,
                                            },
                                        });
                                    }}
                                />
                            );
                        } else if (context?.form?.idStep) {
                            return (
                                <PreviousFormStepsPicker
                                    key={idIntegration + '-' + key}
                                    value={
                                        erkValue?.type === ErkValueTypes.STEP
                                            ? erkValue.idStep
                                            : undefined
                                    }
                                    label={value.name}
                                    required={value.required}
                                    helperText={value.description}
                                    idStep={context.form?.idStep}
                                    handleUpdate={(idStep): void => {
                                        handleUpdate({
                                            ...values,
                                            [key]: {
                                                idStep,
                                                type: ErkValueTypes.STEP,
                                            },
                                        });
                                    }}
                                />
                            );
                        }
                        return <React.Fragment key={key}></React.Fragment>;
                    }
                    default:
                        return <React.Fragment key={key}></React.Fragment>;
                }
            })}
        </>
    );
}

export default IntegrationErkValuesPicker;
