import styles from './ClassifyMenu.module.css';
import React, { useState, useEffect, useRef } from 'react';
import Search from '../../Search/Search';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { TicketClassifier } from '../../../@Types/TicketTypes/Ticket';
import Classifiers from './Classifiers/Classifiers';
import Classifying from './Classifying/Classifying';
import { Classifier } from '../../../@Types/@Types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import Dialog from '../../Dialog/Dialog';
import Toggle from '../../Toggle/Toggle';

/** Helper interface to optimize the render when a user is searching something */
export interface SearchClassifier extends Classifier {
    /** If the classifier has a child that has the term being searched */
    hasSearchInChild?: boolean;
    /** If the classifier has the term being serched */
    hasSearch?: boolean;
}

/**
 * Object that stores the values currently being classified
 */
export interface ClassifierObj {
    /** root of the classifier currently bieng classified */
    idRoot: string;
    /** Currently classified value */
    idValue: string | null;
    /** parent of the currently displayed classifiers */
    current: string;
}
interface ClassifiersMenuProps {
    /** Element the menu is anchored to (classifiers button in the sidebar) */
    anchorRef: any;
    /** Function called when the menu closes */
    handleClose: Function;
    /** Function called when classifiers change */
    handleChange: Function;
    /** Functino called when the parent toggle changes */
    handleParentToggleChange?: Function;
    /** The currently selected classifiers */
    classifiers: TicketClassifier[];
    /** The current parent value */
    parentValue?: boolean;
    /** The Label of the menu */
    menuLabel?: string;
    /** if roots are allowed to classify */
    allowRoots?: boolean;
    /** if multiple classifiers can be selected */
    multiple?: boolean;
    /** The Zindex to the display the menu at (default 3) */
    zIndex?: number;
    /** If the app is mobile */
    mobile?: boolean;
    /** If the menu should call the handleChange function on each small change */
    alwaysHot?: boolean;
    /** if the menu should display the parent toggle on the top right */
    showParentToggle?: boolean;
}
function ClassifiersMenu({
    anchorRef,
    handleClose,
    menuLabel = 'Clasificar',
    allowRoots,
    classifiers,
    handleChange,
    zIndex = 3,
    parentValue,
    multiple = true,
    alwaysHot = false,
    showParentToggle = false,
    mobile = false,
    handleParentToggleChange,
}: ClassifiersMenuProps): JSX.Element {
    const siteInfo = useSelector((state: RootState) => state.site);

    const [currentClassifiers, setCurrentClassifiers] =
        useState<TicketClassifier[]>(classifiers);

    const [info, setInfo] = useState<{
        roots: string[];
        classifiers: Record<string, SearchClassifier>;
    }>({ roots: siteInfo.projectRoots, classifiers: siteInfo.classifiers });

    const [search, setSearch] = useState<string | undefined>(undefined);

    /** Classifier currently being classfied */
    const [classifyingObj, setClassifyingObj] = useState<
        ClassifierObj | undefined
    >(undefined);

    const emptyRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        /** Filter the classifiers with the search value, add the <SEARCH tag when
         *  value is found and store if has the searched value or children that do */
        if (search !== undefined) {
            const roots: string[] = [];
            const classifiers: Record<string, SearchClassifier> = {};
            const regex = new RegExp(search, 'gi');
            const clasifs = Object.values({ ...siteInfo }.classifiers);
            for (let i = 0; i < clasifs.length; i++) {
                const element: SearchClassifier = { ...clasifs[i] };
                if (
                    element.idProject === siteInfo.idProject &&
                    element.active
                ) {
                    let found = false;
                    if (regex.test(element.name)) {
                        element.name = element.name.replace(
                            regex,
                            (match) => `<SEARCH:${match}>`
                        );
                        found = true;
                    }
                    if (regex.test(element.description)) {
                        element.description = element.description.replace(
                            regex,
                            (match) => `<SEARCH:${match}>`
                        );
                        found = true;
                    }
                    if (found) {
                        element.hasSearch = true;
                        let idRoot = element._id;
                        if (element.path) {
                            for (const p of element.path) {
                                if (classifiers[p]) {
                                    classifiers[p] = {
                                        ...classifiers[p],
                                        hasSearchInChild: true,
                                    };
                                } else {
                                    classifiers[p] = {
                                        ...siteInfo.classifiers[p],
                                        hasSearchInChild: true,
                                    };
                                }
                            }
                            idRoot = element.path[0];
                        }
                        if (!roots.includes(idRoot)) {
                            roots.push(idRoot);
                        }
                        classifiers[element._id] = element;
                    }
                }
            }
            for (const classif of Object.values(classifiers)) {
                classif.children = classif.children.filter(
                    (child) => classifiers[child] !== undefined
                );
            }
            setInfo({
                roots,
                classifiers,
            });
        } else {
            /** Filter valid classifers */
            const classifiers: Record<string, SearchClassifier> = {};
            const clasifs = Object.values({ ...siteInfo }.classifiers);
            for (const element of clasifs) {
                if (
                    element.idProject === siteInfo.idProject &&
                    element.active
                ) {
                    classifiers[element._id] = element;
                }
            }
            setInfo({
                roots: siteInfo.projectRoots,
                classifiers,
            });
        }
    }, [search]);

    /**
     * Function that stores a new change in the dictionary of changes
     */
    const change = (
        classifier: TicketClassifier,
        hotRefresh: boolean
    ): void => {
        if (multiple) {
            const classifiers = [...currentClassifiers];
            let found = false;
            for (let i = 0; i < classifiers.length; i++) {
                if (classifiers[i].idRoot === classifier.idRoot) {
                    classifiers[i] = classifier;
                    found = true;
                    if (classifier.idValue === null) {
                        classifiers.splice(i, 1);
                    }
                }
            }
            if (!found && classifier.idValue !== null) {
                classifiers.push(classifier);
            }
            if (hotRefresh || alwaysHot) {
                handleChange(classifiers);
            }
            setCurrentClassifiers(classifiers);
        } else {
            if (hotRefresh || alwaysHot) {
                handleChange([classifier]);
            }
            if (classifier.idValue !== null) {
                setCurrentClassifiers([classifier]);
            } else {
                setCurrentClassifiers([]);
            }
        }
    };

    const renderOptions = (): JSX.Element | void => {
        if (classifyingObj === undefined) {
            return (
                <Classifiers
                    searching={search !== undefined}
                    setClassifyingObj={setClassifyingObj}
                    change={change}
                    currentClassifiers={currentClassifiers}
                    info={info}
                    search={search}
                    allowRoots={allowRoots}
                />
            );
        }
        return (
            <Classifying
                searching={search !== undefined}
                classifyingObj={classifyingObj}
                setClassifyingObj={setClassifyingObj}
                change={change}
                info={info}
                search={search}
                allowRoots={allowRoots}
            />
        );
    };

    const renderContent = (): JSX.Element => {
        return (
            <div
                className={
                    mobile ? styles.mobileContainer : styles.emptyContainer
                }
                onClick={(e): void => {
                    if (emptyRef.current === e.target) {
                        handleChange(currentClassifiers);
                        handleClose(currentClassifiers);
                    }
                }}
                ref={emptyRef}
            >
                <div className={styles.container}>
                    <div className={styles.title}>
                        {menuLabel}
                        {showParentToggle && (
                            <div className={styles.transferBtn + ' noselect'}>
                                Agrupar:
                                <div className={styles.transferToggle}>
                                    <Toggle
                                        size={'small'}
                                        checked={parentValue === true}
                                        onChange={(): void => {
                                            handleParentToggleChange?.(
                                                parentValue !== true
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={styles.searchContainer}>
                        <Search
                            wait={500}
                            placeholder={'Filtrar clasificadores'}
                            search={search}
                            handleSearch={(value: any): void => {
                                if (value.trim() === '') {
                                    setSearch(undefined);
                                } else {
                                    setSearch(value);
                                }
                            }}
                        />
                    </div>
                    {renderOptions()}
                </div>
            </div>
        );
    };
    if (mobile) {
        return (
            <Dialog
                onClose={(): void => {
                    handleChange(currentClassifiers);
                    handleClose(currentClassifiers);
                }}
                maxWidth="100vw"
                border={10}
                transparent
            >
                {renderContent()}
            </Dialog>
        );
    } else {
        return (
            <ClickAwayListener
                mouseEvent="onMouseDown"
                onClickAway={(): void => {
                    handleChange(currentClassifiers);
                    handleClose(currentClassifiers);
                }}
            >
                <Popper
                    open={true}
                    anchorEl={anchorRef.current}
                    placement={'bottom-start'}
                    modifiers={{
                        preventOverflow: {
                            enabled: true,
                            priority: ['top', 'bottom', 'left', 'right'],
                            boundariesElement: 'window',
                        },
                    }}
                    style={{ zIndex }}
                >
                    {renderContent()}
                </Popper>
            </ClickAwayListener>
        );
    }
}
export default ClassifiersMenu;
