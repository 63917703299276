import styles from './EntityListItem.module.css';
import Avatar from '../../../shared/Avatar/Avatar';
import { Entity } from '../../../@Types/@Types';
import EntityIcon from '../../../shared/SmartIcons/EntityIcon';
interface EntityListItemProps {
    /** The selected Entity */
    selectedElement?: Entity;
    /** The Entity to display */
    element?: Entity;
    /** Function called when the component is clicked */
    handleItemClick?: Function;
}
function EntityListItem({
    selectedElement,
    element,
    handleItemClick,
}: EntityListItemProps): JSX.Element {
    if (!element || !handleItemClick) {
        return <div></div>;
    }

    return (
        <div
            className={
                styles.container +
                (selectedElement?._id === element._id
                    ? ' selected-item-shadow'
                    : '')
            }
            onClick={(): void => handleItemClick(element, true)}
        >
            <div className={styles.sideBar}></div>
            <div className={styles.info}>
                <div className={styles.title}>{element.name}</div>
            </div>
            <div className="flexbox">
                <div className={styles.photo}>
                    <Avatar lbl={element.name} size={50} fontSize={20}>
                        <EntityIcon
                            idEntity={element._id}
                            fill="var(--primary1)"
                            style={{
                                height: 35,
                                width: 35,
                            }}
                        />
                    </Avatar>
                </div>
            </div>
        </div>
    );
}

export default EntityListItem;
