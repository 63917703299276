import React from 'react';

import RoundedFormStepPicker from '../../../@Pickers/RoundedFormStepPicker/RoundedFormStepPicker';
import styles from '../../Condition.module.css';
import { ConditionProps } from '../../Condition';
import RoundedSelect from '../../../RoundedSelect/RoundedSelect';
import { MenuItem } from '@material-ui/core';
import ConditionOperators from '../../../../constants/Conditions/ConditionOperators';
import FormStepTypes from '../../../../constants/FormStepTypes';
import RoundedTextField from '../../../RoundedTextField/RoundedTextField';
import RoundedMultiSelect from '../../../RoundedMultiSelect/RoundedMultiSelect';
import RoundedEntityValuePicker from '../../../@Pickers/RoundedEntityValuePicker/RoundedEntityValuePicker';
import {
    ExistanceFormStepCondition,
    FormStepCondition,
    TimePickerFormStepCondition,
} from '../../../../@Types/ConditionTypes/FormStepCondition';
import RoundedCheckBox from '../../../RoundedCheckBox/RoundedCheckBox';
import RoundedDatePicker from '../../../@Pickers/RoundedDatePicker/RoundedDatePicker';
import RoundedAgentPicker from '../../../@Pickers/RoundedAgentPicker/RoundedAgentPicker';
import ConditionTypes from '../../../../constants/Conditions/ConditionTypes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../utils/_store';
import { ClassifierOptionTypes } from '../../../../constants/OptionTypes';
import {
    useFormEditorSelector,
    useFormPreviousSteps,
} from '../../../../pages/Forms/FormReducerFunctions';
import { isPhoneStep } from '../../../../utils/PhoneFunctions';
import RoundedPhoneInput from '../../../RoundedPhoneInput/RoundedPhoneInput';

const FormStepConditionStepTypes: FormStepTypes[] = [
    ...Object.values(FormStepTypes).filter(
        (type) =>
            ![
                FormStepTypes.TITLE,
                FormStepTypes.COLLAPSIBLE,
                FormStepTypes.SEPARATOR,
            ].includes(type)
    ),
] as any;

interface FormStepConditionProps extends ConditionProps<FormStepCondition> {}
function FormStepConditionComponent({
    context,
    condition,
    hoverDelete,
    handleUpdate,
}: FormStepConditionProps): JSX.Element {
    if (!context.form) return <></>;

    const step = useFormEditorSelector(
        (state) => state.steps[condition.idStep]
    );

    const classifiers = useSelector(
        (state: RootState) => state.site.classifiers
    );

    const previousSteps = useFormPreviousSteps(context?.form.idStep);

    const renderStepOperators = (): JSX.Element[] => {
        const operators = [
            <MenuItem value={ConditionOperators.EXISTS} key={'EXISTS'}>
                Tiene valor
            </MenuItem>,
            <MenuItem value={ConditionOperators.NOTEXISTS} key={'NOTEXISTS'}>
                No tiene valor
            </MenuItem>,
        ];

        if (
            [
                FormStepTypes.AGENTPICKER,
                FormStepTypes.CHECKBOX,
                FormStepTypes.CLASSIFIER_SELECTOR,
                FormStepTypes.DATEPICKER,
                FormStepTypes.ENTITYVALUEPICKER,
                // FormStepTypes.RATING,
                FormStepTypes.SELECTOR,
                FormStepTypes.TEXTINPUT,
            ].includes(step.type)
        ) {
            operators.push(
                <MenuItem value={ConditionOperators.EQUAL} key="EQUAL">
                    Igual a
                </MenuItem>,
                <MenuItem value={ConditionOperators.NOTEQUAL} key="NOTEQUAL">
                    No igual a
                </MenuItem>
            );
        }
        if (step.type === FormStepTypes.DATEPICKER) {
            operators.push(
                <MenuItem value={ConditionOperators.LESS} key="LESS">
                    Anterior a
                </MenuItem>,
                <MenuItem value={ConditionOperators.MORE} key="MORE">
                    Después de
                </MenuItem>
            );
        }
        // if (step.type === FormStepTypes.RATING) {
        //     operators.push(
        //         <MenuItem value={ConditionOperators.LESS} key="LESS">
        //             Menor que
        //         </MenuItem>,
        //         <MenuItem value={ConditionOperators.MORE} key="MORE">
        //             Mayor que
        //         </MenuItem>
        //     );
        // }
        if (
            step.type === FormStepTypes.TEXTAREA ||
            (step.type === FormStepTypes.TEXTINPUT && !isPhoneStep(step)) ||
            (step.type === FormStepTypes.TIMEPICKER &&
                (step.working === undefined ||
                    step.pickDays ||
                    step.pickHours ||
                    step.pickMinutes))
        ) {
            operators.push(
                <MenuItem value={ConditionOperators.INCLUDES} key="INCLUDES">
                    Contiene
                </MenuItem>,
                <MenuItem
                    value={ConditionOperators.NOTINCLUDES}
                    key="NOTINCLUDES"
                >
                    No contiene
                </MenuItem>
            );
        }

        return operators;
    };

    const renderStepValue = (): JSX.Element => {
        if (
            condition.operator === undefined ||
            isExistanceFormStepCondition(condition)
        ) {
            return <></>;
        }
        switch (step.type) {
            case FormStepTypes.TEXTINPUT: {
                if (step.type !== condition.stepType) return <></>;
                if (isPhoneStep(step)) {
                    return (
                        <div className={styles.selectorContainer}>
                            <RoundedPhoneInput
                                label={step.label}
                                value={condition.value ?? ''}
                                error={hoverDelete}
                                height="40px"
                                onChange={(value): void => {
                                    handleUpdate({
                                        ...condition,
                                        value,
                                    });
                                }}
                            />
                        </div>
                    );
                }
                return (
                    <div className={styles.selectorContainer}>
                        <RoundedTextField
                            label={step.label}
                            value={condition.value ?? ''}
                            error={hoverDelete}
                            height="40px"
                            onChange={(event): void => {
                                handleUpdate({
                                    ...condition,
                                    value: event.target.value,
                                });
                            }}
                        />
                    </div>
                );
            }
            case FormStepTypes.SELECTOR: {
                if (step.type !== condition.stepType) return <></>;
                return (
                    <div className={styles.selectorContainer}>
                        <RoundedSelect
                            value={condition.value ?? ''}
                            label={step.label}
                            containerMargin="0px"
                            error={hoverDelete}
                            handleUpdate={(event): void => {
                                handleUpdate({
                                    ...condition,
                                    value: event.target.value,
                                });
                            }}
                        >
                            {step.options.map((option) => (
                                <MenuItem
                                    value={option.value}
                                    key={option.value}
                                >
                                    {option.label}
                                </MenuItem>
                            ))}
                        </RoundedSelect>
                    </div>
                );
            }
            case FormStepTypes.CHECKBOX: {
                if (step.type !== condition.stepType) return <></>;
                return (
                    <div className={styles.checkboxContainer}>
                        <RoundedCheckBox
                            padding="0px"
                            size="2rem"
                            error={hoverDelete}
                            onChange={(event): void => {
                                handleUpdate({
                                    ...condition,
                                    value: event.target.checked,
                                });
                            }}
                            checked={Boolean(condition.value)}
                        />
                    </div>
                );
            }
            case FormStepTypes.DATEPICKER: {
                if (step.type !== condition.stepType) return <></>;
                return (
                    <div className={styles.selectorContainer}>
                        <RoundedDatePicker
                            label={step.label}
                            value={condition.value ?? null}
                            error={hoverDelete}
                            onChange={(date): void => {
                                if (date) {
                                    handleUpdate({
                                        ...condition,
                                        value: date as any,
                                    });
                                }
                            }}
                        />
                    </div>
                );
            }
            case FormStepTypes.TIMEPICKER: {
                const typedCondition = condition as TimePickerFormStepCondition;
                if (!typedCondition.property) return <></>;
                if (typedCondition.property === 'working') {
                    return (
                        <div className={styles.checkboxContainer}>
                            <RoundedCheckBox
                                padding="0px"
                                size="2rem"
                                error={hoverDelete}
                                onChange={(event): void => {
                                    handleUpdate({
                                        ...typedCondition,
                                        value: !!event.target.checked,
                                    } as any);
                                }}
                                checked={Boolean(typedCondition.value)}
                            />
                        </div>
                    );
                } else {
                    return (
                        <div className={styles.selectorContainer}>
                            <RoundedTextField
                                height="40px"
                                value={typedCondition.value}
                                label={calcTimeConditionLabel(typedCondition)}
                                inputProps={{ min: 0 }}
                                onChange={(event): void => {
                                    const copy = { ...typedCondition };
                                    copy.value = parseInt(event.target.value);
                                    handleUpdate(copy);
                                }}
                                type="number"
                            ></RoundedTextField>
                        </div>
                    );
                }
            }
            case FormStepTypes.AGENTPICKER: {
                if (step.type !== condition.stepType) return <></>;
                return (
                    <div className={styles.multiSelectContainer}>
                        <RoundedAgentPicker
                            value={condition.values ?? []}
                            label={step.label}
                            multiple={true}
                            height="40px"
                            error={hoverDelete}
                            handleUpdate={(agents): void => {
                                handleUpdate({
                                    ...condition,
                                    values: agents as any,
                                });
                            }}
                        />
                    </div>
                );
            }
            case FormStepTypes.ENTITYVALUEPICKER: {
                if (step.type !== condition.stepType) return <></>;
                return (
                    <div className={styles.selectorContainer}>
                        <RoundedEntityValuePicker
                            value={condition.values ?? []}
                            multiple={true}
                            height="40px"
                            error={hoverDelete}
                            idEntity={step.idEntity}
                            handleUpdate={(entityValues): void => {
                                handleUpdate({
                                    ...condition,
                                    values: entityValues as any,
                                });
                            }}
                        />
                    </div>
                );
            }
            case FormStepTypes.TEXTAREA: {
                if (step.type !== condition.stepType) return <></>;
                return (
                    <div className={styles.multiSelectContainer}>
                        <RoundedMultiSelect
                            values={condition.values ?? []}
                            options={[]} //TODO: generar sugestiones.
                            maxTags={5}
                            label="Valores"
                            fullWidth
                            error={hoverDelete}
                            handleUpdate={(values: string[]): void => {
                                handleUpdate({
                                    ...condition,
                                    values: values,
                                });
                            }}
                        />
                    </div>
                );
            }
            case FormStepTypes.CLASSIFIER_SELECTOR: {
                if (step.type !== condition.stepType) return <></>;
                return (
                    <div className={styles.selectorContainer}>
                        <RoundedSelect
                            value={condition.idValue ?? ''}
                            label={step.label}
                            containerMargin="0px"
                            error={hoverDelete}
                            handleUpdate={(event): void => {
                                const idClassifier = event.target.value;
                                if (!idClassifier) return;
                                const classifier = classifiers[idClassifier];
                                if (!classifier) return;
                                handleUpdate({
                                    ...condition,
                                    idValue: idClassifier,
                                    idRoot: classifier.path![0],
                                });
                            }}
                        >
                            {step.idClassifier &&
                                classifiers[step.idClassifier].children
                                    .filter(
                                        (idOption) =>
                                            step.options[idOption]?.type !==
                                            ClassifierOptionTypes.HIDE
                                    )
                                    .map((idClassifier) => {
                                        const classifier =
                                            classifiers[idClassifier];
                                        return (
                                            <MenuItem
                                                value={classifier._id}
                                                key={classifier._id}
                                            >
                                                {classifier?.name}
                                            </MenuItem>
                                        );
                                    })}
                        </RoundedSelect>
                    </div>
                );
            }
            default:
                return <div></div>;
        }
    };
    return (
        <React.Fragment>
            <div
                style={{
                    display: 'flex',
                }}
            >
                <RoundedFormStepPicker
                    steps={previousSteps}
                    value={condition.idStep}
                    label="Propiedad"
                    containerMargin="5px"
                    error={hoverDelete}
                    stepTypes={FormStepConditionStepTypes}
                    handleUpdate={(idStep, stepType): void => {
                        handleUpdate({
                            operator: condition.operator,
                            type: ConditionTypes.FORM_STEP,
                            idStep,
                            stepType,
                        } as FormStepCondition);
                    }}
                />
            </div>
            {step && (
                <React.Fragment>
                    <RoundedSelect
                        value={condition.operator ?? ''}
                        label="Operador"
                        containerMargin="5px"
                        error={hoverDelete}
                        handleUpdate={(event): void => {
                            handleUpdate({
                                ...condition,
                                operator: event.target.value,
                            });
                        }}
                    >
                        {renderStepOperators()}
                    </RoundedSelect>
                    {step.type === FormStepTypes.TIMEPICKER &&
                        isTimeCondition(condition) && (
                            <React.Fragment>
                                <RoundedSelect
                                    value={condition.property}
                                    label="Propiedad"
                                    containerMargin="5px"
                                    error={hoverDelete}
                                    handleUpdate={(event): void => {
                                        handleUpdate({
                                            ...condition,
                                            property: event.target.value,
                                        });
                                    }}
                                >
                                    {step.pickDays && (
                                        <MenuItem value={'days'}>Días</MenuItem>
                                    )}
                                    {step.pickHours && (
                                        <MenuItem value={'hours'}>
                                            Horas
                                        </MenuItem>
                                    )}
                                    {step.pickMinutes && (
                                        <MenuItem value={'minutes'}>
                                            Minutos
                                        </MenuItem>
                                    )}
                                    {step.working === undefined && (
                                        <MenuItem value={'working'}>
                                            Tipo de Tiempo
                                        </MenuItem>
                                    )}
                                </RoundedSelect>
                                {condition.property && (
                                    <RoundedSelect
                                        value={condition.propertyOperator}
                                        label="Operador"
                                        containerMargin="5px"
                                        error={hoverDelete}
                                        handleUpdate={(event): void => {
                                            handleUpdate({
                                                ...condition,
                                                propertyOperator:
                                                    event.target.value,
                                            });
                                        }}
                                    >
                                        <MenuItem
                                            value={ConditionOperators.EQUAL}
                                        >
                                            Igual a
                                        </MenuItem>
                                        <MenuItem
                                            value={ConditionOperators.NOTEQUAL}
                                        >
                                            No igual a
                                        </MenuItem>
                                        {condition.property !== 'working' && (
                                            <MenuItem
                                                value={ConditionOperators.LESS}
                                            >
                                                Menor que
                                            </MenuItem>
                                        )}
                                        {condition.property !== 'working' && (
                                            <MenuItem
                                                value={ConditionOperators.MORE}
                                            >
                                                Mayor que
                                            </MenuItem>
                                        )}
                                    </RoundedSelect>
                                )}
                            </React.Fragment>
                        )}
                    <div className={styles.valueContainer}>
                        {renderStepValue()}
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
export default FormStepConditionComponent;

function isExistanceFormStepCondition(
    condition: FormStepCondition
): condition is ExistanceFormStepCondition {
    return (
        condition.operator === ConditionOperators.EXISTS ||
        condition.operator === ConditionOperators.NOTEXISTS
    );
}

const isTimeCondition = (
    condition: FormStepCondition
): condition is TimePickerFormStepCondition => {
    return (
        condition.stepType === FormStepTypes.TIMEPICKER &&
        (condition.operator === ConditionOperators.INCLUDES ||
            condition.operator === ConditionOperators.NOTINCLUDES)
    );
};

function calcTimeConditionLabel(
    typedCondition: TimePickerFormStepCondition
): string {
    switch (typedCondition.property) {
        case 'days':
            return 'Días';
        case 'hours':
            return 'Horas';
        case 'minutes':
            return 'Minutos';
        case 'working':
            return 'Tipo de Tiempo';
        default:
            return '';
    }
}
