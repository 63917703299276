import { makeStyles, Icon } from '@material-ui/core';
import RoundedGenericPicker, {
    RoundedGenericPickerProps,
} from '../RoundedGenericPicker/RoundedGenericPicker';
import { Area } from '../../../@Types/Area';
import { loadAreas } from '../../../controllers/AreasController/AreasService';
import ApartmentRoundedIcon from '@material-ui/icons/ApartmentRounded';

function RoundedAreaPicker({
    label,
    idProject,
    ...props
}: Omit<
    RoundedGenericPickerProps<Area>,
    'calcLbl' | 'loadInitialType' | 'loadElements' | 'elementLabel'
> & { idProject: string }): JSX.Element {
    return (
        <RoundedGenericPicker<Area>
            {...props}
            IconComponent={StateIcon}
            label={label}
            elementLabel="Area"
            calcLbl={(area): string => area.name ?? 'Area'}
            loadInitialType={async (ids: string[]): Promise<Area[]> => {
                const response = await loadAreas(
                    idProject,
                    undefined,
                    undefined
                );
                return Object.values(response.elements).filter((area) =>
                    ids.includes(area._id)
                );
            }}
            loadElements={async (
                page: number,
                pageSize: number,
                search: string | undefined
            ): Promise<Area[]> => {
                const response = await loadAreas(idProject, undefined, search);
                return Object.values(response.elements).filter(
                    (area) => area.active
                );
            }}
        />
    );
}
export default RoundedAreaPicker;

function StateIcon(props: any): JSX.Element {
    const useStyles = makeStyles({
        iconRoot: {
            position: 'absolute',
            right: '6px',
            marginTop: '1px',
        },
        imageIcon: {
            height: 21,
            width: 21,
        },
    });
    const classes = useStyles();
    return (
        <Icon classes={{ root: classes.iconRoot }} className={props.className}>
            <ApartmentRoundedIcon fontSize="inherit" />
        </Icon>
    );
}
