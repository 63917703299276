import styles from './AgentGridElement.module.css';
import Avatar from '../../../../../shared/Avatar/Avatar';
import { useState } from 'react';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { Agent } from '../../../../../@Types/@Types';

interface AgentGridElement {
    /** Agent to display */
    element: Agent;
    /** Function that handles removing the agent */
    removeAgent: Function;
}
function AgentGridElement({
    element,
    removeAgent,
}: AgentGridElement): JSX.Element {
    const [displayDelete, setDisplayDelete] = useState(false);
    return (
        <div
            className={styles.container}
            onMouseOver={(): void => {
                setDisplayDelete(true);
            }}
            onMouseLeave={(): void => {
                setDisplayDelete(false);
            }}
        >
            {displayDelete && (
                <div
                    className={styles.containerHover}
                    onClick={(): void => {
                        removeAgent(element);
                    }}
                >
                    <ClearRoundedIcon
                        style={{ fill: 'var(--error)' }}
                        fontSize="inherit"
                    />
                </div>
            )}
            <div className={styles.photoContainer}>
                <div className={styles.avatarContainer}>
                    <Avatar size={35} img={element.img} lbl={element.name} />
                </div>
            </div>
            <div className={styles.basicInfoContainer}>
                <div className={styles.nameContainer}>
                    <label className={styles.nameLabel}>{element.name}</label>
                </div>
                <div className={styles.jobTitleContainer}>
                    <label className={styles.jobTitleLabel}>
                        {element.jobTitle}
                    </label>
                </div>
            </div>
        </div>
    );
}

export default AgentGridElement;
