import styles from './ClassifierPill.module.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../utils/_store';
import TrendingFlatRoundedIcon from '@material-ui/icons/TrendingFlatRounded';
interface ClassifierPillProps {
    /** The root of the classifier to display */
    idRoot: string;
    /** The value of the classifier to display */
    idValue: string;
    /** If the classifier should show the path */
    showPath?: boolean;
    /** If the classifier should show the tooltip on hover */
    showToolTip?: boolean;
    /** Function to call when classifier is clicked on */
    handleClick?: Function;
    /** The type of cursor to show */
    cursor?: string;
    /** If the classifier is displayed inactive */
    showInactive?: boolean;
}
function ClassifierPill({
    idRoot,
    idValue,
    showPath = false,
    showToolTip = true,
    handleClick,
    cursor,
    showInactive = true,
}: ClassifierPillProps): JSX.Element {
    const classifiers = useSelector(
        (state: RootState) => state.site.classifiers
    );

    const root = classifiers[idRoot];
    const value = classifiers[idValue];

    const calcValuePath = (): [any, string] => {
        if (!value.path || value.path.length === 0) {
            return [value.name, value.name];
        } else {
            const parents = value.path.map((id) => classifiers[id]);
            if (parents.length > 0) {
                parents.shift();
            }
            const path = parents.map(
                (parent) => parent?.name ?? '(Clasificador Eliminado)'
            );
            path.push(value.name);
            const elements = [];
            parents.forEach((parent, index) => {
                if (parent) {
                    elements.push(
                        <span key={'T-' + index}>
                            &#x200E;{parent?.name?.toString()}&#x200E;
                        </span>
                    );
                    elements.push(
                        <div className={styles.arrowIconContainer} key={index}>
                            <TrendingFlatRoundedIcon fontSize="inherit" />
                        </div>
                    );
                }
            });
            elements.push(
                <span key={'Element'}>
                    &#x200E;{value.name.toString()}&#x200E;
                </span>
            );
            return [elements, path.join(' > ')];
        }
    };

    if (
        root !== undefined &&
        value !== undefined &&
        (value.active || showInactive)
    ) {
        const path = calcValuePath();
        const string = path[1];
        const valueInfo = showPath ? path[0] : value.name;
        const cursr = cursor
            ? cursor
            : handleClick !== undefined
            ? 'pointer'
            : 'default';
        return (
            <div
                className={styles.container}
                style={{
                    cursor: cursr,
                }}
                onClick={(): void => {
                    if (handleClick !== undefined) {
                        handleClick();
                    }
                }}
            >
                <div
                    className={styles.pill}
                    style={{
                        backgroundColor: root.color?.background,
                        color: root.color?.font,
                        cursor: cursr,
                    }}
                    title={
                        showToolTip
                            ? root.name +
                              ': ' +
                              string +
                              (!root.active || !value.active
                                  ? ' (Eliminado del Ambiente)'
                                  : '')
                            : ''
                    }
                >
                    <div className={styles.pillLbl}>
                        {root.name}:{' '}
                        <div className={styles.valueSpan}>{valueInfo}</div>
                    </div>
                </div>
            </div>
        );
    } else {
        return <div></div>;
    }
}
export default ClassifierPill;
