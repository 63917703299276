/**
 * Transformation node transformation types
 */
enum TYPES {
    AUTOMATIC = 'AUTOMATIC',
    EXTERNAL = 'EXTERNAL',
    // MANUAL = 'MANUAL',
}

export default TYPES;
