import { NavExitStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import { StepComponentProps } from '../../Step';
import StepContainer from '../../StepContainer/StepContainer';
import styles from './ExitStep.module.css';
import WarningIcon from '@material-ui/icons/Warning';
interface ExitStepProps extends StepComponentProps {
    step: NavExitStep;
}

function ExitStep({ step, path }: ExitStepProps): JSX.Element {
    return (
        <StepContainer hidden step={step} path={path}>
            <div className={styles.passthroughContainer}>
                <div className={styles.iconContainer}>
                    <WarningIcon fontSize="inherit" />
                </div>
                <div className={styles.passthoughLbl}>{step.name}</div>
            </div>
        </StepContainer>
    );
}
export default ExitStep;
