import styles from './NameComponent.module.css';
import { useController } from 'react-hook-form';
import { useState } from 'react';
import ClientInfoTypes, {
    ClientInfoTypeLabels,
} from '../../../constants/ClientInfoTypes';
import RoundedTextField from '../../RoundedTextField/RoundedTextField';
import { calcStepWidth } from '@arquimedes.co/eureka-forms/dist/FormSteps/StepFunctions';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import React from 'react';
import { CustomStepProps } from '@arquimedes.co/eureka-forms/dist/FormSteps/CustomStep';

interface NamePropertyProps extends CustomStepProps {
    /** children to render */
    children?: React.ReactElement<any, string>;
    originalName?: string;
    originalLastName?: string;
}
function NameProperty({
    form,
    children,
    widthStats,
    editable,
    originalName,
    originalLastName,
}: NamePropertyProps): JSX.Element {
    const [editing, setEditing] = useState(false);
    const value = originalName + ' ' + originalLastName;

    const name = useController({
        name: ClientInfoTypes.NAME,
        defaultValue: originalName,
    });

    const lastname = useController({
        name: ClientInfoTypes.LASTNAME,
        defaultValue: originalLastName,
    });

    if (value.trim() && !editing) {
        return (
            <div
                className={styles.container}
                style={{
                    cursor: editable ? 'pointer' : 'default ',
                }}
                onClick={(): void => {
                    if (editable) {
                        setEditing(true);
                    }
                }}
            >
                <div className={styles.titleLbl}>{value}</div>
                {editable && (
                    <div className={styles.editContainer}>
                        <EditRoundedIcon fontSize="inherit" />
                    </div>
                )}
                {children !== undefined && children}
            </div>
        );
    } else {
        return (
            <React.Fragment>
                <div
                    className={styles.container}
                    style={{
                        width:
                            widthStats.currentBreakPoint <= 2
                                ? '100%'
                                : calcStepWidth(2, form.size),
                        minHeight: '45px',
                    }}
                >
                    <RoundedTextField
                        {...name.field}
                        label={ClientInfoTypeLabels[ClientInfoTypes.NAME]}
                        inputRef={name.field.ref}
                        fontWeight={400}
                        seed="EF-"
                    ></RoundedTextField>
                </div>
                <div
                    className={styles.container}
                    style={{
                        width:
                            widthStats.currentBreakPoint <= 2
                                ? '100%'
                                : calcStepWidth(2, form.size),
                        minHeight: '43px',
                    }}
                >
                    <RoundedTextField
                        {...lastname.field}
                        label={ClientInfoTypeLabels[ClientInfoTypes.LASTNAME]}
                        inputRef={lastname.field.ref}
                        fontWeight={400}
                        seed="EF-"
                    ></RoundedTextField>
                </div>
            </React.Fragment>
        );
    }
}

export default NameProperty;
