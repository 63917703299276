import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select, { SelectProps } from '@material-ui/core/Select';
import { FormHelperText, MenuItem } from '@material-ui/core';
import ThumbsUpDownRoundedIcon from '@material-ui/icons/ThumbsUpDownRounded';
import styles from './RatingTypePicker.module.css';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import GradeRoundedIcon from '@material-ui/icons/GradeRounded';
import { RatingTypes } from '../../../../constants/FormStepTypes';

interface StyleProps {
    /** The color of the helper text when not error */
    helperTextColor?: string;
    /** The color of the outline when selected and hovered on */
    focusColor?: string;
    /** The color of the outline when it is not selected */
    outlineColor?: string;
    /** The color of the error to display */
    errorColor?: string;
    /** If the style should change on hover */
    cantEdit?: boolean;
    /** The hight of the container */
    height?: string;
    /** The color of the text in the form */
    color?: string;
}
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const useOutlinedInputStyles = (props: StyleProps): Function =>
    makeStyles({
        root: {
            cursor: props.cantEdit ? 'default' : 'default',
            height: props.height,
            '& $notchedOutline': {
                borderColor: props.outlineColor,
            },
            '&:hover $notchedOutline': {
                borderColor: props.cantEdit
                    ? props.outlineColor
                    : props.focusColor,
            },
            '& .Mui-disabled': {
                cursor: 'default',
                color: props.color,
            },
            '& .Erk-MuiSelect-root': {
                paddingLeft: 0,
                paddingBottom: 0,
                paddingTop: 0,
            },
            '& .Mui-error': {
                color: props.errorColor,
            },
            '&.Mui-error .Erk-MuiOutlinedInput-notchedOutline': {
                borderColor: props.outlineColor,
            },
            '&$focused $notchedOutline': {
                borderColor: props.focusColor,
            },
            '& .Erk-MuiSelect-icon.Mui-disabled': {
                color: props.outlineColor,
            },
            borderRadius: 10,
            color: props.color,
        },
        focused: {},
        notchedOutline: {},
    });

const useLabelInputStyles = (props: StyleProps): Function =>
    makeStyles(() => ({
        focused: {
            color: props.focusColor + ' !important',
        },
        root: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: 'calc(100% - 45px)',
            textOverflow: 'ellipsis',
            marginTop: props.height != '40px' ? '-4px' : '0px',
            '&.Erk-MuiInputLabel-shrink': {
                marginTop: '0px',
                maxWidth: 'calc(100% - 5px)',
            },
            '& .Mui-error': {
                color: props.errorColor,
            },
            '&.Mui-error': {
                color: props.errorColor,
            },
        },
    }));

const useHelperTextStyles = (props: StyleProps): Function =>
    makeStyles(() => ({
        root: {
            color: props.helperTextColor,
            '&.Mui-error': {
                color: props.errorColor,
            },
        },
    }));

interface RatingTypePickerProps extends Omit<SelectProps, 'color'>, StyleProps {
    /** Currently selected value */
    value: RatingTypes | undefined;
    /** The posible properties to select */
    options?: RatingTypes[];
    /** function called when value changes */
    handleUpdate: (property: RatingTypes) => void;
    /** Strig to place in the label */
    label?: string;
    /** Minimum width in px of the component */
    minWidth?: number;
    /** If outline should be error color */
    highlightError?: boolean;
    /** The helper Text to display */
    helperText?: string;
    /** the margin around the selector */
    containerMargin?: string;
    /** If the app is mobile */
    mobile?: boolean;
}
function CustomSelect({
    value,
    options = Object.values(RatingTypes),
    handleUpdate,
    label,
    minWidth,
    helperText,
    color = '#293241',
    errorColor = '#cc2936',
    focusColor = '#3d5a7f',
    outlineColor = '#0000003b',
    helperTextColor = '#989898',
    cantEdit = false,
    containerMargin = '0px',
    height = '40px',
    required,
    error,
    ...others
}: RatingTypePickerProps): JSX.Element {
    const classes = useStyles();
    const outlinedInputClasses = useOutlinedInputStyles({
        helperTextColor,
        outlineColor,
        errorColor,
        focusColor,
        cantEdit,
        height,
        color,
    })();
    const labelClasses = useLabelInputStyles({
        helperTextColor,
        outlineColor,
        errorColor,
        focusColor,
        cantEdit,
        height,
        color,
    })();
    const helperTextClasses = useHelperTextStyles({
        helperTextColor,
        outlineColor,
        errorColor,
        focusColor,
        cantEdit,
        height,
        color,
    })();

    return (
        <FormControl
            variant="outlined"
            className={classes.formControl}
            size="small"
            style={
                minWidth !== undefined
                    ? { minWidth, outlineColor, margin: containerMargin }
                    : {
                          margin: containerMargin,
                      }
            }
            fullWidth
            required={required}
            error={error}
        >
            <InputLabel classes={labelClasses}>{label}</InputLabel>
            <Select
                {...others}
                value={value ?? ''}
                onChange={(event): void => {
                    handleUpdate(event.target.value as RatingTypes);
                }}
                input={
                    <OutlinedInput
                        disabled={cantEdit}
                        name={label}
                        label={label + (required ? ' *' : '')}
                        classes={outlinedInputClasses}
                    />
                }
            >
                {options.map((property) => renderProperty(property))}
            </Select>
            {helperText !== undefined && (
                <FormHelperText classes={helperTextClasses}>
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
}

/**
 * Generic textfield with apps designs. Is class do to the use in the react-hook-forms library
 */
class RatingTypePicker extends React.Component<RatingTypePickerProps> {
    render(): JSX.Element {
        return <CustomSelect {...this.props} />;
    }
}
export default RatingTypePicker;

function renderProperty(property: RatingTypes | undefined): JSX.Element {
    switch (property) {
        case RatingTypes.LIKE: {
            return (
                <MenuItem
                    key={property}
                    value={property}
                    style={{
                        paddingLeft: 0,
                    }}
                >
                    <div className={styles.property}>
                        <div className={styles.icon}>
                            <ThumbsUpDownRoundedIcon fontSize="inherit" />
                        </div>
                        Me Gusta
                    </div>
                </MenuItem>
            );
        }
        case RatingTypes.SATISFACTION: {
            return (
                <MenuItem
                    key={property}
                    value={property}
                    style={{ paddingLeft: 0 }}
                >
                    <div className={styles.property}>
                        <div className={styles.icon}>
                            <SentimentSatisfiedAltIcon fontSize="inherit" />
                        </div>
                        Satisfacción
                    </div>
                </MenuItem>
            );
        }
        case RatingTypes.SCALE: {
            return (
                <MenuItem
                    key={property}
                    value={property}
                    style={{ paddingLeft: 0 }}
                >
                    <div className={styles.property}>
                        <div className={styles.icon}>
                            <GradeRoundedIcon fontSize="inherit" />
                        </div>
                        Escala
                    </div>
                </MenuItem>
            );
        }
        default:
            return <div></div>;
    }
}
