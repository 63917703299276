import React from 'react';
import { match, RouteComponentProps, withRouter } from 'react-router-dom';
import { FormEntry } from '../../../@Types/Entry';
import RoundedButton from '../../../shared/RoundedButton/RoundedButton';
import styles from './EntryDetail.module.css';
interface EntryFormProps {
    entry: FormEntry;
    match: match<{ idProject: string }>;
}
function EntryFormcomponent({
    entry,
    history,
    match,
}: EntryFormProps & RouteComponentProps): JSX.Element {
    return (
        <React.Fragment>
            <div className={styles.flujoLabel}>Formulario</div>
            <img
                alt={'Form'}
                className={styles.formFoto}
                src={'/media/form.png'}
            ></img>
            <div className={styles.flujo}>
                <div
                    className={styles.formBtn}
                    data-testid={'EntryForm_Button'}
                >
                    <RoundedButton
                        text="Ver formulario"
                        backgroundColor="var(--secondary)"
                        color="white"
                        onClick={(): void => {
                            history.push(
                                `/${match.params.idProject}/entries/${entry.apiKey}/form`
                            );
                        }}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(EntryFormcomponent);
