import { Agent } from '../../../../../../../../@Types/@Types';
import styles from './Agent.module.css';
import Avatar from '../../../../../../../../shared/Avatar/Avatar';
interface AgentComponentProps {
    /** Agent to display */
    agent: Agent;
    /** Function to handle when agent is clicked on */
    handleClick: Function;
    /** If the current agent is the first one, to display the border-top */
    first: boolean;
}
/**
 * Component used to display non-selected agents in the assign tab.
 */
function AgentComponent({
    agent,
    handleClick,
    first,
}: AgentComponentProps): JSX.Element {
    if (agent) {
        return (
            <div
                className={styles.container}
                style={{
                    borderTop: first ? 'none' : '1px solid var(--light-grey)',
                }}
                onClick={(): void => {
                    handleClick();
                }}
            >
                <div className={styles.avatarContainer}>
                    <Avatar lbl={agent.name} img={agent.img} toolTip={false} />
                </div>
                <div className={styles.lblContainer}>
                    <label className={styles.nameLbl}>{agent.name}</label>
                    <label className={styles.emailContainer}>
                        {agent.email}
                    </label>
                </div>
            </div>
        );
    }
    return <div></div>;
}

export default AgentComponent;
