import React from 'react';
import SizeEditor from './SizeEditor/SizeEditor';
import StyleEditor from './StyleEditor/StyleEditor';

interface DefaultConfigsProps {
    editStyle?: boolean;
    editSize?: boolean;
}

function DefaultConfigs({
    editStyle = false,
    editSize = false,
}: DefaultConfigsProps): JSX.Element {
    return (
        <React.Fragment>
            {editStyle && <StyleEditor />}
            {editSize && <SizeEditor />}
        </React.Fragment>
    );
}

export default DefaultConfigs;
