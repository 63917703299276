import { TempReplyAction } from '../../../../../../../../@Types/Action';
import React, { useEffect, useState } from 'react';
import styles from '../LoaderBottom/LoaderBottom.module.css';
import { LinearProgress } from '@material-ui/core';
import RoundedIconButton from '../../../../../../../../shared/RoundedIconButton/RoundedIconButton';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import CancelIcon from '@material-ui/icons/CloseRounded';
import ConfirmDialog from '../../../../../../../../shared/ConfirmDialog/ConfirmDialog';
import RoundedPayload from '../../../../../../../../shared/RoundedPayload/RoundedPayload';
import ActionsService from '../../../../../../../../controllers/TicketsController/Actions/ActionsService';
import {
    removeAction,
    updateAction,
} from '../../../../../../../../controllers/TicketsController/Actions/ActionsActions';
import * as RepliesCache from '../../../../../../../../utils/ReplyCache';
import { useDispatch } from 'react-redux';
import { refresh } from '../../../../../../../../controllers/TicketsController/Actions/ActionsActions';

interface SelfPendingBottomProps {
    element: TempReplyAction;
    refreshCache: Function;
}
export function SelfPendingBottom({
    element,
    refreshCache,
}: SelfPendingBottomProps): JSX.Element {
    const _id = element._id;
    const idProject = element.idProject;
    const idTicket = element.idTicket;
    const dispatch = useDispatch();
    const [openSendNow, setOpenSendNow] = useState(false);
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

    useEffect(() => {
        return (): void => {
            refreshCache();
        };
    });

    const handleSendNowClick = (): void => {
        setOpenSendNow(true);
    };

    const handleCancelSendNow = (): void => {
        setOpenSendNow(false);
    };

    const handleConfirmSendNow = async (
        _element: any,
        setDialogLoading: Function
    ): Promise<void> => {
        setDialogLoading(true);
        try {
            const update = await ActionsService.persistTempReply(
                idProject,
                idTicket,
                _id
            );
            dispatch(
                updateAction({
                    idAction: _element._id,
                    update,
                })
            );
            setDialogLoading(false);
            setOpenSendNow(false);
            RepliesCache.removeReply(idProject, idTicket);
        } catch (error) {
            setDialogLoading(false);
            refreshCache();
            dispatch(refresh());
        }
    };

    const handleDeleteClick = (): void => {
        setOpenDeleteConfirmation(true);
    };
    const handleCancelDelete = (): void => {
        setOpenDeleteConfirmation(false);
    };

    const handleConfirmDelete = async (
        _element: any,
        setDialogLoading: Function
    ): Promise<void> => {
        setDialogLoading(true);
        try {
            await ActionsService.deleteTempReply(idProject, idTicket, _id);
            dispatch(removeAction(_id));
            RepliesCache.removeReply(idProject, idTicket);
            setDialogLoading(false);
            setOpenDeleteConfirmation(false);
        } catch (error) {
            setDialogLoading(false);
            refreshCache();
            dispatch(refresh());
        }
    };

    return (
        <div className={styles.container}>
            {openDeleteConfirmation && (
                <ConfirmDialog
                    element={element}
                    onClose={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                    title={'Descartar Respuesta'}
                    deleting
                    btnMsg="Descartar"
                    msg={'¿Está seguro que desea descartar su respuesta?'}
                >
                    <div
                        style={{
                            marginBottom: '15px',
                        }}
                    >
                        <RoundedPayload
                            isResponse
                            payload={element.payload}
                            date={element.creation_date}
                            name={element.agent.name}
                        />
                    </div>
                </ConfirmDialog>
            )}
            {openSendNow && (
                <ConfirmDialog
                    element={element}
                    onClose={handleCancelSendNow}
                    onConfirm={handleConfirmSendNow}
                    title={'Enviar Ahora'}
                    msg={'¿Está seguro que desea enviar su respuesta?'}
                    btnMsg={'Enviar'}
                >
                    <div
                        style={{
                            marginBottom: '15px',
                        }}
                    >
                        <RoundedPayload
                            isResponse
                            payload={element.payload}
                            date={element.creation_date}
                            name={element.agent.name}
                        />
                    </div>
                </ConfirmDialog>
            )}
            <div className={styles.loader}>
                <LinearProgress
                    variant="indeterminate"
                    classes={{
                        barColorPrimary: styles.loaderLine,
                        colorPrimary: styles.loaderFont,
                    }}
                />
            </div>
            <div className={styles.sendNow} title="Enviar ahora">
                <RoundedIconButton
                    color="var(--accent)"
                    backgroundColor="var(--defaultGrey)"
                    onClick={handleSendNowClick}
                    hoverColor="white"
                    hoverBackgroundColor="var(--secondary)"
                    padding={'3px'}
                >
                    <CheckRoundedIcon
                        style={{
                            width: 14,
                            height: 14,
                        }}
                    />
                </RoundedIconButton>
            </div>
            <div className={styles.cancel} title="Cancelar">
                <RoundedIconButton
                    color="var(--accent)"
                    backgroundColor="var(--defaultGrey)"
                    onClick={handleDeleteClick}
                    hoverColor="white"
                    hoverBackgroundColor="var(--secondary)"
                    padding={'3px'}
                >
                    <CancelIcon
                        style={{
                            width: 14,
                            height: 14,
                            margin: '-1px',
                        }}
                    />
                </RoundedIconButton>
            </div>
        </div>
    );
}
