import styles from './AssignDialog.module.css';
import Toggle from '../../../../shared/Toggle/Toggle';
import types from '../../../../constants/Flows/AssignTypes';
import RoundedSelect from '../../../../shared/RoundedSelect/RoundedSelect';
import MenuItem from '@material-ui/core/MenuItem';
import { AssignData } from '../../../../@Types/FlowTypes/NodeTypes/AssignNode';
import produce from 'immer';

interface AssignDialogProps {
    /** The data of the AssignNode */
    assign: AssignData;
    /** Function called to handle when a value is updated */
    handleUpdate: Function;
    /** Function called when auto has been activated */
    handleAutoActivated: Function;
}
function AssignDialog({
    assign,
    handleUpdate,
    handleAutoActivated,
}: AssignDialogProps): JSX.Element {
    return (
        <div className={styles.container}>
            <div className={styles.scrollContainer}>
                <div className={styles.top}>
                    <div className={styles.valueContainer}>
                        <label className={styles.nameLbl}>Asignación</label>
                    </div>
                </div>
                <div className={styles.autoContainer}>
                    <label className={styles.autoLbl}>
                        Asignación Automatica:
                    </label>
                    <div className={styles.togglecontainer}>
                        <Toggle
                            checked={assign.type === types.AUTO}
                            onChange={(checked: boolean): void => {
                                if (checked) {
                                    handleAutoActivated({
                                        ...assign,
                                        type: types.AUTO,
                                    });
                                } else {
                                    handleUpdate({
                                        ...assign,
                                        type: types.RANDOM,
                                    });
                                }
                            }}
                        />
                    </div>
                </div>
                {assign.type !== types.AUTO && (
                    <div className={styles.notAutoContainer}>
                        <RoundedSelect
                            value={assign.type}
                            label="Tipo de Asignacion"
                            handleUpdate={(event): void => {
                                const type = event.target.value;
                                const copy = {
                                    ...assign,
                                    type,
                                };
                                handleUpdate(copy);
                            }}
                        >
                            <MenuItem value={types.RANDOM}>Al azar</MenuItem>
                            <MenuItem value={types.LOAD}>Menor Carga</MenuItem>
                            <MenuItem value={types.ALL}>Todos</MenuItem>
                        </RoundedSelect>
                    </div>
                )}
                {assign.type === types.AUTO && (
                    <div className={styles.internalContainer}>
                        <label className={styles.internalLbl}>
                            Asignar al agente que lo creó internamente:
                        </label>
                        <div className={styles.togglecontainer}>
                            <Toggle
                                checked={!!assign.assignInternalAgent}
                                onChange={(checked: boolean): void => {
                                    handleUpdate(
                                        produce(assign, (assign) => {
                                            if (checked) {
                                                assign.assignInternalAgent =
                                                    true;
                                            } else
                                                delete assign.assignInternalAgent;
                                        })
                                    );
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default AssignDialog;
