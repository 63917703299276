import { InmuebleInfo360 } from '../../../types';
import { OpenComponent } from '../../Utils/Openable/OpenComponent';
import MaterialTable from '../../Utils/MaterialTable';
import { calcDate } from '../../../../../utils/DateFunctions';
import { getPrettyCurrencyValue } from '../../Utils/otherFunctions';
import { useEffect, useState } from 'react';

interface PagosProps {
    pagos: InmuebleInfo360['cartera']['pagos'];
}

export function Pagos({ pagos }: PagosProps): JSX.Element {
    const [pagosOrdenados, setPagosOrdenados] = useState<
        InmuebleInfo360['cartera']['pagos']
    >([]);

    useEffect(() => {
        setPagosOrdenados(ordenarPagos(pagos));
    }, []);
    function ordenarPagos(pagos: PagosProps['pagos']): any[] {
        return pagos.sort((a, b) => {
            return (
                new Date(a.fechaConsigna).getTime() -
                new Date(b.fechaConsigna).getTime()
            );
        });
    }

    const mapCount: Record<string, number> = {};
    return (
        <OpenComponent title={'Pagos'}>
            <MaterialTable
                style={{
                    primaryColor: '#3d5a7f',
                    outlineColor: 'rgba(196,184,184,0.52)',
                    fontSize: 13,
                }}
                part={{
                    title: '',
                    headers: [
                        {
                            label: 'Pago',
                        },
                        {
                            label: 'Doc Numero',
                        },
                        {
                            label: 'Tipo',
                        },
                        {
                            label: 'Fecha',
                        },
                        {
                            label: 'Valor',
                        },
                    ],
                    rows: pagosOrdenados.map((pago) => {
                        if (!mapCount[pago.concepto]) {
                            mapCount[pago.concepto] = 0;
                        }
                        mapCount[pago.concepto]++;
                        return {
                            isHeader: false,
                            data: [
                                {
                                    value: `Pago No.${
                                        mapCount[pago.concepto]
                                    } ${pago.concepto}`,
                                },
                                {
                                    value: pago.numeroDocumento,
                                },
                                {
                                    value: pago.claseProyecto,
                                },
                                {
                                    value: pago.fechaConsigna
                                        ? calcDate(
                                              new Date(pago.fechaConsigna),
                                              true
                                          )
                                        : 'N/A',
                                },
                                {
                                    value: getPrettyCurrencyValue(pago.pago),
                                },
                            ],
                        };
                    }),
                    totals: [],
                }}
            />
        </OpenComponent>
    );
}
