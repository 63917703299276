import RoundedGenericPicker, {
    RoundedGenericPickerProps,
} from '../RoundedGenericPicker/RoundedGenericPicker';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import { EntityIconComponent } from '../../../pages/Entities/EntityEditor/EntityConfig/EntityRelationship/EntityRelationship';
import { Entity } from '../../../@Types/@Types';

function RoundedEntityPicker({
    allowedEntities,
    ...props
}: Omit<
    RoundedGenericPickerProps<Entity>,
    'calcLbl' | 'loadInitialType' | 'loadElements' | 'elementLabel' | 'label'
> & {
    allowedEntities?: string[];
}): JSX.Element {
    const entities = useSelector((state: RootState) => state.site.entities);
    return (
        <RoundedGenericPicker<Entity>
            {...props}
            IconComponent={EntityIconComponent}
            label={'Entidad'}
            elementLabel={'Entidad'}
            calcLbl={(entity): string => entity.name}
            loadInitialType={async (ids: string[]): Promise<Entity[]> => {
                return Object.values(entities)
                    .filter((entity) => ids.includes(entity._id))
                    .sort((a, b) => a.name.localeCompare(b.name, 'es'));
            }}
            loadElements={async (): Promise<Entity[]> => {
                return Object.values(entities)
                    .filter(
                        (entity) =>
                            !allowedEntities ||
                            allowedEntities.includes(entity._id)
                    )
                    .sort((a, b) => a.name.localeCompare(b.name, 'es'));
            }}
        />
    );
}
export default RoundedEntityPicker;
