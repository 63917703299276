import axiosInstance from '../../../AxiosAPI';
import { Project } from '../../../@Types/@Types';

/**
 * Function that fetches all the projects of the organization
 * @returns array of Projects
 */
export const loadProjects = async (): Promise<Project[]> => {
    let response = await axiosInstance.get('/projects');
    if (response?.data?.toString().startsWith('<!doctype html>')) {
        response = await axiosInstance.get(
            '/projects?timeStamp=' + new Date().toString()
        );
    }
    const projects = response.data;
    for (const key of Object.keys(projects)) {
        projects[key] = {
            ...projects[key],
            creation_date: new Date(projects[key].creation_date),
        };
    }
    return projects;
};

/**
 * Function that handles the project creation
 * @param payload object with the values to create a project
 * @returns the project that was created
 */
export const createProject = async (
    payload: Record<string, unknown>
): Promise<Project> => {
    let response = await axiosInstance.post('/projects', payload);
    return <Project>response.data;
};

export default {
    loadProjects,
    createProject,
};
