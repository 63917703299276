import styles from './EntityValueListItem.module.css';
import Avatar from '../../../shared/Avatar/Avatar';
import { Entity, EntityValue } from '../../../@Types/@Types';
import EntityIcon from '../../../shared/SmartIcons/EntityIcon';
interface EntityValueListItemProps {
    entity: Entity;
    /** The selected EntityValue */
    selectedElement?: EntityValue;
    /** The EntityValue to display */
    element?: EntityValue;
    /** Function called when the component is clicked */
    handleItemClick?: Function;
}
function EntityValueListItem({
    entity,
    selectedElement,
    element,
    handleItemClick,
}: EntityValueListItemProps): JSX.Element {
    if (!element || !handleItemClick) {
        return <div></div>;
    }

    return (
        <div
            className={
                styles.container +
                (selectedElement?._id === element._id
                    ? ' selected-item-shadow'
                    : '')
            }
            onClick={(): void => handleItemClick(element, true)}
        >
            <div className={styles.sideBar}></div>
            <div className={styles.info}>
                <h4 className={styles.title}>
                    {element.values[entity.idLabelStep] ?? entity.name}
                </h4>
            </div>
            <div className="flexbox">
                <div className={styles.photo}>
                    <Avatar
                        lbl={element.values[entity.idLabelStep] ?? entity.name}
                        size={50}
                        fontSize={30}
                    >
                        <EntityIcon
                            idEntity={entity._id}
                            fill="var(--primary1)"
                            style={{
                                height: 35,
                                width: 35,
                            }}
                        />
                    </Avatar>
                </div>
            </div>
        </div>
    );
}

export default EntityValueListItem;
