import { Survey } from '../../@Types/@Types';
import axiosInstance from '../../AxiosAPI';
/**
 * Function that loads the surveys' list
 * @param page currently viewing
 * @param pageSize to request
 * @param search search query, undefined if no search is active
 * @returns array of Surveys that match the inputs
 */
export const loadSurveys = async (
    idProject: string,
    page: number,
    pageSize: number,
    search: string | undefined
): Promise<Survey[]> => {
    let url = `/projects/${idProject}/surveys?page=${page}&pageSize=${pageSize}`;
    if (search !== undefined) {
        url += `&search=${encodeURIComponent(search)}`;
    }
    const response = await axiosInstance.get(url);
    if (response.data.size === undefined) {
        response.data.size = {
            spacingSize: response.data.spacingSize ?? 20,
            blockNum: response.data.blockNum ?? 3,
            blockSize: response.data.blockSize ?? 210,
        };
    }
    return response.data;
};

/**
 * Function that loads the companies by a given list of ids
 * @param companies the list of ids
 */
export const loadSurveysById = async (surveys: string[]): Promise<Survey[]> => {
    const response = await axiosInstance.get(
        `/surveys?id=${surveys.join(',')}`
    );
    return response.data;
};

/**
 * Function called to handle the conexion with the server to create an survey
 * @param survey Record of values to create the survey with
 */
export const createSurvey = async (
    idProject: string,
    survey: Record<string, unknown>
): Promise<void> => {
    await axiosInstance.post(`/projects/${idProject}/surveys`, survey);
};

/**
 * Function that handles the async update of an surveys value
 * @param idSurvey of the survey to update
 * @param survey Record of values to update
 */
export const updateSurvey = async (
    idProject: string,
    idSurvey: string,
    survey: Record<keyof Survey, any>
): Promise<void> => {
    await axiosInstance.patch(
        `/projects/${idProject}/surveys/` + idSurvey,
        survey
    );
};

/**
 * Function that loads an survey
 * @param idSurvey the survey to load
 * @returns Survey that matches the input
 */
export const loadSurvey = async (
    idProject: string,
    idSurvey: string
): Promise<Survey> => {
    const response = await axiosInstance.get(
        `/projects/${idProject}/surveys/${idSurvey}`
    );
    const survey = response.data;
    if (survey.size === undefined) {
        survey.size = {
            spacingSize: survey.spacingSize ?? 20,
            blockNum: survey.blockNum ?? 3,
            blockSize: survey.blockSize ?? 210,
        };
    }
    return survey;
};

/**
 * Function that saves the survey, sends an error if the survey is not valid
 * @param idEntry the form's entry id
 * @param pSurvey the survey to save
 */
export const saveSurvey = async (
    idProject: string,
    idSurvey: string,
    pSurvey: Survey
): Promise<void> => {
    const survey: any = { ...pSurvey };
    delete survey['_id'];
    delete survey['creation_date'];

    if (!survey.style.primaryContrastColor) {
        survey.style.primaryContrastColor = '#FFFFFF';
    }
    if (!survey.style.secondaryContrastColor) {
        survey.style.secondaryContrastColor = '#FFFFFF';
    }
    if (!survey.style.stepBackgroundColor) {
        survey.style.stepBackgroundColor = '#FFFFFF';
    }
    await axiosInstance.patch(
        `/projects/${idProject}/surveys/${idSurvey}`,
        survey
    );
};

export default {
    loadSurveys,
    createSurvey,
    updateSurvey,
    loadSurvey,
    saveSurvey,
};
