import { useRef, useState } from 'react';
import styles from './AddStep.module.css';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import React from 'react';
import { StepLocation } from '../../../../controllers/ConversationEditorController/ConversationEditorReducer';
import { useDrop } from 'react-dnd';
import StepTypes from '../../../../constants/Conversations/ConversationStepTypes';
import { DragItem } from '../StepList/Step';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../utils/_store';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { pasteStep } from '../../../../controllers/ConversationEditorController/ConversationEditorActions';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import StepMenu from './StepMenu/StepMenu';

interface LastAddStepProps {
    location: StepLocation;
}
function LastAddStep({ location }: LastAddStepProps): JSX.Element {
    const [showMenu, setShowMenu] = useState(false);
    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);
    const dispatch = useDispatch();
    const ref = useRef<HTMLDivElement>(null);
    const btnRef = useRef<HTMLDivElement>(null);
    const canPaste = useSelector(
        (state: RootState) => state.conversationEditor.copiedInfo !== undefined
    );
    const [{}, drop] = useDrop<DragItem, void, {}>(
        () => ({
            accept: Object.keys(StepTypes),
            collect(): any {
                return {};
            },
            hover(item: DragItem): any {
                if (!ref.current) {
                    return;
                }
                // Don't replace items with themselves
                if (item.lastMoved) {
                    item.lastMoved = undefined;
                    return;
                }
            },
        }),
        [location]
    );

    const handleContextMenu = (event: React.MouseEvent): void => {
        event.preventDefault();
        if (canPaste) {
            setContextMenu(
                contextMenu === null
                    ? {
                          mouseX: event.clientX + 2,
                          mouseY: event.clientY - 6,
                      }
                    : null
            );
        }
    };

    drop(ref);
    return (
        <React.Fragment>
            <div
                className={styles.lastContainer}
                ref={ref}
                onContextMenu={handleContextMenu}
            >
                <div className={styles.container}>
                    <div className={styles.btnsContainer}>
                        <div
                            className={styles.lastBtnContainer}
                            onClick={(): void => {
                                setShowMenu(true);
                            }}
                            ref={btnRef}
                        >
                            <div className={styles.addIconContainer}>
                                <AddRoundedIcon fontSize="inherit" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Menu
                open={contextMenu !== null}
                onClose={(): void => {
                    setContextMenu(null);
                }}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
                onContextMenu={(event): void => {
                    event.preventDefault();
                    setContextMenu(null);
                }}
            >
                <MenuItem
                    onClick={(): void => {
                        dispatch(pasteStep(location));
                        setContextMenu(null);
                    }}
                    disabled={!canPaste}
                >
                    <ListItemIcon style={{ minWidth: 35 }}>
                        <GetAppRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Pegar</ListItemText>
                </MenuItem>
            </Menu>
            {showMenu && (
                <StepMenu
                    location={location}
                    handleClose={(): void => {
                        setShowMenu(false);
                    }}
                    anchorRef={btnRef}
                />
            )}
        </React.Fragment>
    );
}

export default LastAddStep;
