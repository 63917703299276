import React, { memo } from 'react';
import styles from './AreaNode.module.css';
import ApartmentRoundedIcon from '@material-ui/icons/ApartmentRounded';
import { Handle, Position } from 'react-flow-renderer';
import { Area } from '../../../../@Types/@Types';
interface AreaNodeProps {
    /** Area to display */
    data: Area & { error: boolean };
}
function AreaNode({ data }: AreaNodeProps): JSX.Element {
    return (
        <>
            <Handle
                className={styles.leftHandle}
                type="target"
                position={Position.Left}
                id="AREA"
                style={{
                    top: '50%',
                    background: 'var(--primary1)',
                    border: '1px solid var(--accent)',
                    height: 4,
                    width: 4,
                }}
            />
            <div
                className={styles.container}
                style={
                    data.error
                        ? {
                              border: 'var(--error) 1px solid',
                              marginTop: '-1px',
                              marginLeft: '-1px',
                          }
                        : {}
                }
            >
                <ApartmentRoundedIcon fontSize="inherit" />
                <div className={styles.label}>{data.name}</div>
            </div>
        </>
    );
}

export default memo(AreaNode);
