import { Area } from '../@Types/Area';
import { Classifier } from '../@Types/Classifier';

export const searchFunction = (
    elements: Record<string, Area | Classifier>,
    search: string
): { roots: string[]; elements: Record<string, Area | Classifier> } => {
    const roots: string[] = [];
    const classifiers: Record<string, Area | Classifier> = {};
    const regex = new RegExp(search, 'gi');
    const clasifs = Object.values(elements);
    for (let i = 0; i < clasifs.length; i++) {
        const element: Area | Classifier = { ...clasifs[i] };
        let found = false;
        if (regex.test(element.name)) {
            found = true;
        }
        if (regex.test(element.description)) {
            found = true;
        }
        if (found) {
            let idRoot = element._id;
            if (element.path) {
                for (const p of element.path) {
                    if (classifiers[p]) {
                        classifiers[p] = {
                            ...classifiers[p],
                        };
                    } else {
                        classifiers[p] = {
                            ...elements[p],
                        };
                    }
                }
                idRoot = element.path[0];
            }
            if (!roots.includes(idRoot)) {
                roots.push(idRoot);
            }
            classifiers[element._id] = element;
        }
    }
    return { roots, elements };
};
