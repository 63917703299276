import styles from './EntityValueClientComponent.module.css';
import Avatar from '../../../../../shared/Avatar/Avatar';
import { EntityValueClient } from '../../../../../@Types/EntityValue';
import Toggle from '../../../../../shared/Toggle/Toggle';
import RoundedButton from '../../../../../shared/RoundedButton/RoundedButton';
import { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import React from 'react';
import { updateEntityValueClientPermissions } from '../../../../../controllers/EntityValuesController/EntityValuesService';
import ClientDetail from '../../EntityValueClientDetail/EntityValueClienteDetail';
import Dialog from '../../../../../shared/Dialog/Dialog';
import { Client } from '../../../../../@Types/Client';
import produce from 'immer';
import { Entity } from '../../../../../@Types/@Types';
interface EntityValueClientProps {
    /** The ClientInfo to display */
    client: EntityValueClient | Client;
    /** the entity of the entityValue to display */
    entity: Entity;
    /** The id of the current EntityValue */
    idEntityValue: string;
    handleUpdate: Function;
    handleDelete?: Function;
    editable?: boolean;
}
function EntityValueClientComponent({
    client,
    entity,
    idEntityValue,
    handleUpdate,
    handleDelete,
    editable = false,
}: EntityValueClientProps): JSX.Element {
    const [showPermissions, setShowPermissions] = useState(false);
    const [showDetail, setShowDetail] = useState(false);

    const calcName = (): string | undefined => {
        const name = client.lastName
            ? client.name + ' ' + client.lastName
            : client.name;
        return name?.trim() ? name : undefined;
    };

    const clientEntityValue = client.entities?.[entity._id]?.find(
        (entityValue) => entityValue.idEntityValue === idEntityValue
    );

    const nPermissions = Object.values(
        clientEntityValue?.permissions ?? {}
    ).filter((p) => p === true).length;
    return (
        <React.Fragment>
            {showDetail && (
                <Dialog
                    open={showDetail}
                    maxWidth={'90vw'}
                    onClose={(): void => {
                        setShowDetail(false);
                    }}
                >
                    <ClientDetail client={client} />
                </Dialog>
            )}
            <div className={styles.container}>
                <div className={styles.cardContainer}>
                    <div className={styles.sideBar}></div>
                    <div className={styles.info}>
                        <h4 className={styles.title}>
                            {calcName() ?? '(Sin nombre)'}
                        </h4>
                        {client.email && (
                            <label className={styles.infoLbl}>
                                {client.email}
                            </label>
                        )}
                        {client.cel && (
                            <label className={styles.infoLbl}>
                                {client.cel}
                            </label>
                        )}
                        <div className={styles.bottomContainer}>
                            {(client as EntityValueClient).ticketNum !==
                                undefined && (
                                <label className={styles.caseLbl}>
                                    Casos Registrados:{' '}
                                    {(client as EntityValueClient).ticketNum}
                                </label>
                            )}
                            <div className={styles.viewMoreBtn}>
                                <RoundedButton
                                    text="Ver más"
                                    backgroundColor={'var(--secondary)'}
                                    color={'white'}
                                    onClick={(): void => {
                                        setShowDetail(true);
                                    }}
                                    padding="0px 10px"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flexbox">
                        <div className={styles.photo}>
                            <Avatar
                                lbl={calcName() ?? '?'}
                                size={50}
                                fontSize={30}
                            ></Avatar>
                        </div>
                    </div>
                    {handleDelete && (
                        <div
                            className={styles.removeBtn}
                            onClick={(): void => {
                                handleDelete();
                            }}
                        >
                            <ClearRoundedIcon fontSize="inherit" />
                        </div>
                    )}
                </div>
                {showPermissions && entity.permissions && (
                    <div className={styles.permissionsContainer}>
                        {entity.permissions.map((permission, index) => (
                            <div
                                className={styles.permission + ' flexbox'}
                                key={index}
                            >
                                <div
                                    className={
                                        styles.permissionLbl + ' noselect'
                                    }
                                >
                                    {permission.name}
                                </div>
                                <div className={styles.toggleContainer}>
                                    <Toggle
                                        size="small"
                                        checked={
                                            clientEntityValue?.permissions[
                                                permission.id
                                            ] === true
                                        }
                                        disabled={!editable}
                                        onChange={(checked: boolean): void => {
                                            let perms = {};
                                            handleUpdate(
                                                produce(client, (client) => {
                                                    const entityValues =
                                                        client.entities[
                                                            entity._id
                                                        ];
                                                    if (entityValues) {
                                                        for (const entityValue of entityValues) {
                                                            if (
                                                                entityValue.idEntityValue ===
                                                                idEntityValue
                                                            ) {
                                                                entityValue.permissions[
                                                                    permission.id
                                                                ] = checked;
                                                            }
                                                            perms =
                                                                entityValue.permissions;
                                                        }
                                                    }
                                                })
                                            );
                                            updateEntityValueClientPermissions(
                                                entity._id,
                                                idEntityValue,
                                                client._id,
                                                perms
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {entity.permissions && entity.permissions.length > 0 && (
                    <div className={styles.permissionsTabContainer}>
                        <div
                            className={styles.permissionsTab}
                            onClick={(): void => {
                                setShowPermissions(!showPermissions);
                            }}
                        >
                            Permisos
                            <div className={styles.countLbl}>
                                {nPermissions}
                            </div>
                            <div className={styles.permissionsIcon}>
                                {showPermissions ? (
                                    <ExpandLessIcon fontSize="inherit" />
                                ) : (
                                    <ExpandMoreIcon fontSize="inherit" />
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

export default EntityValueClientComponent;
