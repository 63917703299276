import styles from './AttachmentsMenu.module.css';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { PayloadFile } from '../../../../../../@Types/Payload';
import FileComponent from '../../../../../../shared/FileComponent/FileComponent';
import { useState } from 'react';
import { fetchDownloadUrl } from '../../../../../../controllers/FilesController/FilesService';
async function wait(): Promise<any> {
    return await new Promise((resolve) => setTimeout(resolve, 500));
}
interface AttachmentsMenuProps {
    handleClose: Function;
    anchorRef: any;
    attachments: PayloadFile[];
    idTicket: string;
    idProject: string;
}

export default function AttachmentsMenu({
    handleClose,
    anchorRef,
    attachments,
}: AttachmentsMenuProps): JSX.Element {
    const [downloadingAll, setDownloadingAll] = useState(false);
    const handleDownloadAll = async (): Promise<void> => {
        try {
            setDownloadingAll(true);
            for (const file of attachments) {
                if (file.S3Key && file.fileName) {
                    const fileUrl = await fetchDownloadUrl(
                        file.S3Key,
                        file.fileName
                    );
                    var a = document.createElement('a');
                    a.href = fileUrl;
                    a.setAttribute('download', file.fileName);
                    a.click();
                    await wait();
                }
            }
            setDownloadingAll(false);
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <ClickAwayListener
            onClickAway={(): void => {
                handleClose();
            }}
        >
            <Popper
                open={true}
                anchorEl={anchorRef.current}
                placement={'bottom-start'}
                modifiers={{
                    preventOverflow: {
                        enabled: true,
                        priority: ['top', 'bottom', 'left', 'right'],
                        boundariesElement: 'viewport',
                        padding: 20,
                    },
                }}
                style={{ zIndex: 3 }}
            >
                <div className={styles.container}>
                    {attachments.map((file, index) => (
                        <div className={styles.fileContainer} key={index}>
                            <FileComponent
                                file={file}
                                loadingOverride={downloadingAll}
                            />
                        </div>
                    ))}
                    {attachments.length > 1 && (
                        <div
                            onClick={(): void => {
                                handleDownloadAll();
                            }}
                            className={styles.allOption}
                        >
                            Descargar Todos
                        </div>
                    )}
                </div>
            </Popper>
        </ClickAwayListener>
    );
}
