import { StepComponentProps } from '../../Step';
import styles from './LinkStep.module.css';
import React from 'react';
import StepContainer from '../../StepContainer/StepContainer';
import { LinkStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import DraftRenderer from '../../../../../../shared/DraftRenderer/DraftRenderer';
import { EditorState, convertFromRaw } from 'draft-js';
import LaunchIcon from '../../../../../../Icons/LaunchIcon';
import { Link } from 'react-router-dom';

interface LinkStepProps extends StepComponentProps {
    step: LinkStep;
}

function LinkStepComponent({ step, path }: LinkStepProps): JSX.Element {
    return (
        <StepContainer
            step={step}
            path={path}
            header={step.header}
            footer={step.footer}
            bottomComponent={
                <div className={styles.btnsContainer}>
                    <Link
                        to={{
                            pathname: step.url ? step.url : 'https://capta.co',
                        }}
                        target="_blank"
                        style={{ textDecoration: 'none' }}
                    >
                        <div className={styles.messageBtn}>
                            <LaunchIcon
                                style={{ width: 20, height: 20, marginTop: 1 }}
                                fill="var(--secondary)"
                            />
                            {step.label ? step.label : '(Por Definir)'}
                        </div>
                    </Link>
                </div>
            }
            disabledBottom
        >
            <div className={styles.messageContainer}>
                <DraftRenderer
                    editorState={EditorState.createWithContent(
                        convertFromRaw(step.message)
                    )}
                />
            </div>
        </StepContainer>
    );
}

export default LinkStepComponent;
