import { useSelector } from 'react-redux';
import { AutomaticSubTicketTransformation } from '../../../../../@Types/FlowTypes/NodeTypes/TransformationNode';
import { TicketClassifier } from '../../../../../@Types/TicketTypes/Ticket';
import RoundedClassifierPicker from '../../../../../shared/@Pickers/RoundedClassifierPicker/RoundedClassifierPicker';
import RoundedFlowPicker from '../../../../../shared/@Pickers/RoundedFlowPicker/RoundedFlowPicker';
import RoundedStatePicker from '../../../../../shared/@Pickers/RoundedStatePicker/RoundedStatePicker';
import RoundedTextField from '../../../../../shared/RoundedTextField/RoundedTextField';
import Toggle from '../../../../../shared/Toggle/Toggle';
import { RootState } from '../../../../../utils/_store';
import styles from './SubTicketTransformation.module.css';
import PayloadEditor from '../../../../../shared/TextEditor/PayloadEditor';
interface SubTicketTransformationProps {
    /** The data of the transformation to display */
    transformation: AutomaticSubTicketTransformation & {
        /** Function used to open the dialog  */
        openDialog: Function;
    };
    /** Function called when a value needs to update */
    handleUpdate: Function;
}

function SubTicketTransformation({
    transformation,
    handleUpdate,
}: SubTicketTransformationProps): JSX.Element {
    const states = useSelector(
        (state: RootState) =>
            state.site.projects[state.site.idProject ?? '']?.states
    );

    const state = states[transformation.idState ?? ''];

    return (
        <div className={styles.container}>
            <div className={styles.toggleLabelContainer}>
                Asignar a un Flujo:
                <div className={styles.toggleContainer}>
                    <Toggle
                        checked={transformation.idFlow !== undefined}
                        onChange={(checked: boolean): void => {
                            handleUpdate({
                                ...transformation,
                                idFlow: checked ? '' : undefined,
                            });
                        }}
                    />
                </div>
            </div>
            {transformation.idFlow !== undefined && (
                <div className={styles.contentContainer}>
                    <RoundedFlowPicker
                        height="40px"
                        fullWidth={false}
                        minWidth={300}
                        label="Flujo"
                        handleUpdate={(flowElement): void => {
                            if (flowElement?.length > 0) {
                                handleUpdate({
                                    ...transformation,
                                    idFlow: flowElement[0],
                                });
                            }
                        }}
                        value={
                            transformation.idFlow
                                ? [transformation.idFlow as any]
                                : []
                        }
                    />
                </div>
            )}
            <div className={styles.toggleLabelContainer}>
                Asunto:
                <div className={styles.toggleContainer}>
                    <Toggle
                        checked={transformation.subject !== undefined}
                        onChange={(checked: boolean): void => {
                            handleUpdate({
                                ...transformation,
                                subject: checked ? '' : undefined,
                            });
                        }}
                    />
                </div>
            </div>
            {transformation.subject !== undefined && (
                <div className={styles.contentContainer}>
                    <RoundedTextField
                        label={'Asunto'}
                        height="40px"
                        value={transformation.subject}
                        onChange={(event): void => {
                            handleUpdate({
                                ...transformation,
                                subject: event.target.value,
                            });
                        }}
                    />
                </div>
            )}
            <div className={styles.toggleLabelContainer}>
                Estado:
                <div className={styles.toggleContainer}>
                    <Toggle
                        checked={transformation.idState !== undefined}
                        onChange={(checked: boolean): void => {
                            handleUpdate({
                                ...transformation,
                                idState: checked ? null : undefined,
                            });
                        }}
                    />
                </div>
            </div>
            {transformation.idState !== undefined && (
                <div className={styles.contentContainer}>
                    <RoundedStatePicker
                        height="40px"
                        fullWidth={false}
                        minWidth={300}
                        label="Estado"
                        handleUpdate={(state): void => {
                            if (state) {
                                handleUpdate({
                                    ...transformation,
                                    idState: (state as any)._id,
                                });
                            }
                        }}
                        value={state ? [state] : []}
                        options={Object.values(states)}
                    />
                </div>
            )}
            <div className={styles.toggleLabelContainer}>
                Fecha de Vencimiento:
                <div className={styles.toggleContainer}>
                    <Toggle
                        checked={transformation.numDays !== undefined}
                        onChange={(checked: boolean): void => {
                            handleUpdate({
                                ...transformation,
                                numDays: checked ? 0 : undefined,
                                numHours: checked ? 0 : undefined,
                            });
                        }}
                    />
                </div>
            </div>
            {transformation.numDays !== undefined && (
                <div className={styles.contentContainer}>
                    <div className={styles.numPickerContainer}>
                        <RoundedTextField
                            value={transformation.numDays ?? 0}
                            label="Días"
                            inputProps={{ min: 0 }}
                            onChange={(event): void => {
                                const copy = { ...transformation };
                                copy.numDays = parseInt(event.target.value);
                                handleUpdate(copy);
                            }}
                            height="40px"
                            type="number"
                        ></RoundedTextField>
                    </div>
                    <div className={styles.numPickerContainer}>
                        <RoundedTextField
                            value={transformation.numHours ?? 0}
                            label="Horas"
                            height="40px"
                            inputProps={{ min: 0 }}
                            onChange={(event): void => {
                                const copy = { ...transformation };
                                copy.numHours = parseInt(event.target.value);
                                handleUpdate(copy);
                            }}
                            type="number"
                        ></RoundedTextField>
                    </div>
                </div>
            )}
            <div className={styles.toggleLabelContainer}>
                Clasificadores:
                <div className={styles.toggleContainer}>
                    <Toggle
                        checked={transformation.classifiers !== undefined}
                        onChange={(checked: boolean): void => {
                            handleUpdate({
                                ...transformation,
                                classifiers: checked ? [] : undefined,
                            });
                        }}
                    />
                </div>
            </div>
            {transformation.classifiers !== undefined && (
                <div className={styles.contentContainer}>
                    <RoundedClassifierPicker
                        handleUpdate={(
                            classifiers: TicketClassifier[]
                        ): void => {
                            handleUpdate({
                                ...transformation,
                                classifiers,
                            });
                        }}
                        height="40px"
                        label="Clasificadores"
                        multiple
                        value={transformation.classifiers}
                    />
                </div>
            )}

            <div className={styles.toggleLabelContainer}>
                Cuerpo:
                <div className={styles.toggleContainer}>
                    <Toggle
                        checked={transformation.content !== undefined}
                        onChange={(checked: boolean): void => {
                            handleUpdate({
                                ...transformation,
                                content: checked ? {} : undefined,
                            });
                        }}
                    />
                </div>
            </div>
            {transformation.content !== undefined && (
                <div className={styles.editorContentContainer}>
                    <PayloadEditor
                        idEditor="FlowsEditor"
                        placeholder={'Cuerpo'}
                        payload={transformation.content}
                    />
                </div>
            )}
        </div>
    );
}

export default SubTicketTransformation;
