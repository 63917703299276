import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { RootState } from '../../../../../../utils/_store';
import EntityIcon from '../../../../../../shared/SmartIcons/EntityIcon';
import EntityRelationshipMenu from './EntityRelationshipMenu';
import { EntityValueStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import { StepLocation } from '../../../../../../controllers/ConversationEditorController/ConversationEditorReducer';
import { createEntityStep } from '../../../StepFunctions';
import { useCurrentProject } from '../../../../../../hooks';
import EurekaElementMenuElement from '../../../../../../shared/@Menus/ElementMenu/EurekaElementMenuElement';
import EurekaElementMenuGroup from '../../../../../../shared/@Menus/ElementMenu/EurekaElementMenuGroup';
interface EntityMenuProps {
    idEntity: string;
    shown: string[];
    location: StepLocation;
    handleAdd: (step: EntityValueStep) => void;
}
function EntityMenu({
    idEntity,
    location,
    handleAdd,
    shown,
}: EntityMenuProps): JSX.Element {
    const project = useCurrentProject();
    const entity = useSelector(
        (state: RootState) => state.site.entities[idEntity]
    );

    const relationships = useMemo(
        () =>
            entity.relationships.filter(
                (relationship) => !shown.includes(relationship.idEntity)
            ),
        [entity, shown]
    );

    if (relationships.length > 0) {
        return (
            <EurekaElementMenuGroup
                label={entity.name}
                zIndex={1300}
                icon={(props): JSX.Element => (
                    <EntityIcon {...props} idEntity={entity._id} />
                )}
                onClick={(): void => {
                    handleAdd(createEntityStep(project, entity));
                }}
                style={{ fontSize: 18 }}
                renderElements={(props): JSX.Element[] =>
                    relationships.map((relationship) => (
                        <EntityRelationshipMenu
                            menuProps={props}
                            shown={[
                                ...shown,
                                ...relationships.map((rel) => rel.idEntity),
                            ]}
                            path={[
                                {
                                    idEntity,
                                    hidden: false,
                                },
                            ]}
                            relationship={relationship}
                            handleAdd={handleAdd}
                            location={location}
                            key={relationship.idEntity}
                        />
                    ))
                }
            />
        );
    } else {
        return (
            <EurekaElementMenuElement
                style={{ fontSize: 18 }}
                Label={entity.name}
                onClick={(): void => {
                    handleAdd(createEntityStep(project, entity));
                }}
                Icon={(props): JSX.Element => (
                    <EntityIcon {...props} idEntity={entity._id} />
                )}
            />
        );
    }
}

export default EntityMenu;
