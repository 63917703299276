import styles from './Agent.module.css';
import Avatar from '../../../../../../../shared/Avatar/Avatar';
import NotInterestedRoundedIcon from '@material-ui/icons/NotInterestedRounded';
import { useState } from 'react';
import { Agent } from '../../../../../../../@Types/@Types';
interface AgentProps {
    /** Agent to display */
    agent: Agent;
    /** If the agent was removed */
    removed: boolean;
}

/**
 * Component used to display an agent in an agents action, showing the prohibited icon if it was removed
 */
function AgentComponent({ agent, removed }: AgentProps): JSX.Element {
    const [hovering, setHovering] = useState(false);
    return (
        <div
            className={styles.container}
            onMouseEnter={(): void => {
                setHovering(true);
            }}
            onMouseLeave={(): void => {
                setHovering(false);
            }}
        >
            <Avatar lbl={agent?.name} img={agent?.img} toolTip={true} />
            <label className={styles.nameLbl}>{agent?.name}</label>
            {removed && (
                <div
                    className={styles.trashContainer}
                    style={{
                        backgroundColor: hovering
                            ? 'transparent'
                            : 'rgba(128, 128, 128, 0.6)',
                        width: hovering ? '20px' : '100%',
                        marginLeft: hovering ? 'calc(50% - 10px)' : '0px',
                    }}
                >
                    <NotInterestedRoundedIcon fontSize="inherit" />
                </div>
            )}
        </div>
    );
}

export default AgentComponent;
