import React from 'react';
import styles from './CompanyClientDetail.module.css';
import { Client } from '../../../../@Types/@Types';
import ClientRenderer from '../../../../shared/ClientRenderer/ClientRenderer';
import { updateClient } from '../../../../controllers/ClientsController/ClientsService';
interface ClientDetailProps {
    /**Ticket client */
    client: Client;
}
/**
 * Component rendered inside the create area dialog.
 */
function ClientDetail({ client }: ClientDetailProps): JSX.Element {
    return (
        <div className={styles.container + ' standard-dialog'}>
            <div className={styles.title}>Información del cliente</div>
            <ClientRenderer
                client={client}
                handleSave={async (pClient): Promise<void> => {
                    await updateClient(client._id, pClient);
                }}
                editable={true}
            />
        </div>
    );
}
export default ClientDetail;
