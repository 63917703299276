import { createAction } from '@reduxjs/toolkit';
import { Ticket } from '../../@Types/TicketTypes/Ticket';
import { TicketFilters } from './TicketsReducer';
import { Process } from '../../@Types/ProcessTypes/Process';
export const Types = {
    RESET: 'TICKETS/RESET',
    RESET_SUCCESS: 'TICKETS/RESET_SUCCESS',
    SOFT_RESET: 'TICKETS/SOFT_RESET',
    REFRESH_CURRENT: 'TICKETS/REFRESH_CURRENT',
    REFRESH_TICKET: 'TICKETS/REFRESH_TICKET',
    REFRESH: 'TICKETS/REFRESH',
    REFRESH_SUCCESS: 'TICKETS/REFRESH_SUCCESS',
    GET_SUCCESS: 'TICKETS/GET_SUCCESS',
    ADD_NOTIFICATION: 'TICKETS/ADD_NOTIFICATION',
    REMOVE_NOTIFICATION: 'TICKETS/REMOVE_NOTIFICATION',
    REMOVE_ALL_NOTIFICATION: 'TICKETS/REMOVE_ALL_NOTIFICATION',
    PAGE_SCROLL: 'TICKETS/NEW_PAGE_SCROLL',
    TICKET_DETAIL: 'TICKETS/TICKET_DETAIL',
    SELECT: 'TICKETS/SELECT_ELEMENT',
    SEARCH: 'TICKETS/SEARCH',
    FILTER: 'TICKETS/FILTER',
    LOADING: 'TICKETS/LOADING',
    ORDER: 'TICKETS/ORDER',
    GO_TO: 'TICKETS/GO_TO',
    PROCESS_SUCCESS: 'TICKETS/PROCESS_SUCCESS',
};

/**
 * Called when the tickets page is first loaded
 */
export const reset = createAction<{
    queryString?: string;
    forced?: boolean;
    idTicket?: string;
}>(Types.RESET);

/**
 * Called when the tickets page is first loaded
 */
export const resetSuccess = createAction<{
    order: string[];
    elements: Record<string, Ticket>;
    page: number;
    selectedElement?: number;
    noMoreElements: boolean;
    filters: TicketFilters;
    orderBy: string;
    search: string | undefined;
}>(Types.RESET_SUCCESS);

/**
 * Called when the list needs soft resetting
 */
export const softReset = createAction(Types.SOFT_RESET);

/**
 * Called when the selectedElement needs to be refreshed, true if actions need to be refreshed
 */
export const refreshCurrent = createAction<boolean | undefined>(
    Types.REFRESH_CURRENT
);

/**
 * Called when the selectedElement needs to be refreshed
 */
export const refreshTicket = createAction<string>(Types.REFRESH_TICKET);

/**
 * Called when all the page needs to be reset without deleting filters
 */
export const refresh = createAction(Types.REFRESH);

/**
 * Called when the selectedElement has successfully been fetched for refresh
 */
export const refreshSuccess = createAction<Ticket>(Types.REFRESH_SUCCESS);

/** Called to increase the unseen notification counter on a ticket **/
export const addNotification = createAction<{
    idTicket: string;
    idNotification: string;
}>(Types.ADD_NOTIFICATION);

export const removeNotification = createAction<{
    idTicket: string;
    idNotification: string;
}>(Types.REMOVE_NOTIFICATION);

/** Called to set the unseen notification counter to 0 on a ticket **/
export const removeAllNotification = createAction<string>(
    Types.REMOVE_ALL_NOTIFICATION
);

/**
 * Called to toggle the loader
 */
export const setLoading = createAction(
    Types.LOADING,
    function prepare(loading: boolean = true) {
        return {
            payload: loading,
        };
    }
);

/**
 * Called when the list needs to load more elements
 */
export const pageScroll = createAction(Types.PAGE_SCROLL);

/**
 * Called when the selectedElement (index) changes value
 */
export const selectElement = createAction<number | undefined>(Types.SELECT);

/**
 * Called when the search value changes
 */
export const search = createAction<string | undefined>(Types.SEARCH);

/**
 * Called when user wants to view a ticket by id.
 */
export const getTicketDetail = createAction<string>(Types.TICKET_DETAIL);

/**
 *  Called when the orderby value changes
 */
export const orderBy = createAction<string>(Types.ORDER);

/**
 *  Called when the saga has successfully retrieved the lists elements
 */
export const getSuccess = createAction<{
    order: string[];
    elements: Record<string, Ticket>;
    noMoreElements: boolean;
    selectedElement?: number;
}>(Types.GET_SUCCESS);

/**
 * Called when the user updates the filters
 */
export const setFilters = createAction<TicketFilters>(Types.FILTER);

/**
 * Called when the user wants to go to a specific ticket with query params.
 */
export const goTo = createAction<{
    idTicket: string;
    queryString?: string;
    forced?: boolean;
}>(Types.GO_TO);
/**
 * Called when the user wants to go to a specific ticket with query params.
 */
export const processSuccess = createAction<Process[]>(Types.PROCESS_SUCCESS);
