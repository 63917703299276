import styles from './ApiSelectorStep.module.css';
import RoundedTextField from '../../RoundedTextField/RoundedTextField';
import React, { cloneElement, useMemo, useRef, useState } from 'react';
import { GenericStepProps } from '../../GenericFormEditor/Step/Step';
import { ApiSelector } from '../../../@Types/FormTypes/FormStep';
import { calcStepWidth } from '../../GenericFormEditor/StepFunctions';
import RoundedSmartSelect from '../../RoundedSmartSelect/RoundedSmartSelect';
import InputIcon from '../../InputIcon/InputIcon';
import RoundedIconPicker from '../../@Pickers/RoundedIconPicker/RoundedIconPicker';
import IconTypes from '../../../constants/IconTypes';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { GLocation } from '../../../@Types/FormTypes/LocationTypes';
import { ApiSelectorOptionTypes } from '../../../constants/OptionTypes';
import Option from './Option/Option';
import LocationTypes from '../../../constants/LocationTypes';
import Loader from '../../Loader/Loader';
import { ApiSelectorParamTypes } from '../../../constants/FormStepTypes';
import ApiParam from './Param/ApiParam';
import produce from 'immer';
import { ApiRequest, ApiRequestOption } from '../../../@Types/ApiRequest';
import EurekaElementMenu from '../../@Menus/ElementMenu/EurekaElementMenu';
import EurekaElementMenuElement from '../../@Menus/ElementMenu/EurekaElementMenuElement';

interface ApiSelectorStepProps {
    /** children to render */
    children?: React.ReactElement<any, string>;
}

function ApiSelectorStep<U, L extends GLocation>({
    step,
    size,
    editing,
    children,
    location,
    updateStep,
    ...others
}: GenericStepProps<ApiSelector, U, L> & ApiSelectorStepProps): JSX.Element {
    const addBtnRef = useRef<any>();
    const [showMenu, setShowMenu] = useState(false);

    const [options] = useState<{
        ids: string[];
        options: Record<string, ApiRequestOption>;
        request: ApiRequest;
    } | null>(null);

    // const loadData = async (): Promise<void> => {
    //     try {
    //         const data = await fetchApiRequest(
    //             step.request,
    //             idOrganization,
    //             step.idAttribute,
    //             step.labelAttribute
    //         );
    //         const options: Record<string, ApiRequestOption> = {};
    //         const ids: string[] = [];
    //         for (const item of data) {
    //             options[item.id] = item;
    //             ids.push(item.id);
    //         }
    //         setOptions({ options, ids, request: step.request });
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    // useEffect(() => {
    //     if (!deepCompare(step.request, options?.request)) {
    //         loadData();
    //     }
    // }, [step.request]);

    const showAdd = useMemo(() => {
        if (!options) return false;
        return options.ids.some((idOption) => !step.options[idOption]);
    }, [options, step.options]);

    const mapOptions = (): JSX.Element => {
        if (!options)
            return (
                <div className={styles.loaderContainer}>
                    <Loader size={50} />
                </div>
            );
        return (
            <div className={styles.optionsList}>
                {options.ids
                    .filter((idOption) => step.options[idOption])
                    .map((idOption, index) => {
                        const option = step.options[idOption];
                        return (
                            <Option
                                key={index}
                                {...others}
                                location={{
                                    type: LocationTypes.API_SELECTOR,
                                    idRootStep: location.idRootStep,
                                    idStep: step.id,
                                    idOption,
                                    indexStep: null,
                                }}
                                label={options.options[idOption].label}
                                option={option}
                                updateOption={(option): void => {
                                    updateStep(
                                        produce(step, (step) => {
                                            step.options[idOption] = option;
                                        })
                                    );
                                }}
                                handleDeleteOption={(): void => {
                                    updateStep(
                                        produce(step, (step) => {
                                            delete step.options[idOption];
                                        })
                                    );
                                }}
                                size={size}
                                step={step}
                            />
                        );
                    })}
            </div>
        );
    };

    if (editing) {
        return (
            <React.Fragment>
                {showMenu && options && (
                    <EurekaElementMenu
                        anchorEl={addBtnRef.current}
                        placement="bottom-start"
                        handleClose={(): void => {
                            setShowMenu(false);
                        }}
                        renderElements={(
                            props,
                            handleClose
                        ): JSX.Element | JSX.Element[] => {
                            return options.ids
                                .filter((idOption) => !step.options[idOption])
                                .map((idOption) => {
                                    const option = options.options[idOption];
                                    return (
                                        <EurekaElementMenuElement
                                            {...props}
                                            key={idOption}
                                            Icon={(): JSX.Element => (
                                                <InputIcon
                                                    icon={
                                                        step.icon ??
                                                        IconTypes.GENERIC
                                                    }
                                                />
                                            )}
                                            Label={option.label}
                                            onClick={(): void => {
                                                updateStep(
                                                    produce(step, (step) => {
                                                        step.options[idOption] =
                                                            {
                                                                type: ApiSelectorOptionTypes.DEFAULT,
                                                            };
                                                    })
                                                );
                                                handleClose();
                                            }}
                                        />
                                    );
                                });
                        }}
                    />
                )}
                <div className={styles.editingContainer}>
                    <div className={styles.inputContainer}>
                        <RoundedTextField
                            label="Etiqueta"
                            value={step.label}
                            onChange={(e): void => {
                                updateStep({ ...step, label: e.target.value });
                            }}
                        ></RoundedTextField>
                    </div>
                    <RoundedTextField
                        label="Descripción"
                        value={step.description ?? ''}
                        onChange={(e): void => {
                            updateStep({
                                ...step,
                                description: e.target.value,
                            });
                        }}
                    ></RoundedTextField>
                    {step.showIcon && (
                        <div className={styles.iconContainer}>
                            <div className={styles.iconLabel}>Icono:</div>
                            <div className={styles.toggleContainer}>
                                <RoundedIconPicker
                                    value={step.icon as IconTypes}
                                    handleUpdate={(icon): void => {
                                        updateStep({
                                            ...step,
                                            icon,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    <div className={styles.urlContainer}>
                        <RoundedTextField
                            label="Url"
                            value={step.url ?? ''}
                            onChange={(e): void => {
                                updateStep({
                                    ...step,
                                    url: e.target.value,
                                });
                            }}
                        ></RoundedTextField>
                    </div>
                    {/* TODO: Poder editar etiqueta y id del dato */}
                    <React.Fragment>
                        <div className={styles.paramsLabel}>Parámetros:</div>
                        <div className={styles.paramsContainer}>
                            {step.queryParams?.map((param, index) => (
                                <ApiParam
                                    key={index}
                                    step={step}
                                    handleUpdate={(param): void => {
                                        const queryParams = [
                                            ...step.queryParams,
                                        ];
                                        queryParams[index] = param;
                                        const tempStep: ApiSelector = {
                                            ...step,
                                            queryParams,
                                        };
                                        updateStep(tempStep);
                                    }}
                                    handleDelete={(): void => {
                                        const queryParams = [
                                            ...step.queryParams,
                                        ];
                                        queryParams.splice(index, 1);
                                        updateStep({
                                            ...step,
                                            queryParams,
                                        });
                                    }}
                                    param={param}
                                />
                            ))}
                            <div
                                className={styles.addParam}
                                onClick={(): void => {
                                    updateStep({
                                        ...step,
                                        queryParams: [
                                            ...step.queryParams,
                                            {
                                                key: 'parametro',
                                                value: 'valor',
                                                type: ApiSelectorParamTypes.VALUE,
                                            },
                                        ],
                                    });
                                }}
                            >
                                <div className={styles.addIconContainer}>
                                    <AddRoundedIcon fontSize="inherit" />
                                </div>
                                <div
                                    className={
                                        styles.addOptionLbL + ' noselect'
                                    }
                                >
                                    Agregar Parámetro
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    {children &&
                        cloneElement(children, {
                            step,
                            updateStep,
                            uniqueSteps: others.uniqueSteps,
                            updateUniqueSteps: others.updateUniqueSteps,
                        })}
                    {options && options.ids.length > 0 && (
                        <React.Fragment>
                            <div className={styles.optionsLabel}>Opciones:</div>
                            {mapOptions()}
                            {showAdd && (
                                <div
                                    className={styles.addOption}
                                    onClick={(): void => {
                                        setShowMenu(true);
                                    }}
                                    ref={addBtnRef}
                                >
                                    <div className={styles.addIconContainer}>
                                        <AddRoundedIcon fontSize="inherit" />
                                    </div>
                                    <div
                                        className={
                                            styles.addOptionLbL + ' noselect'
                                        }
                                    >
                                        Agregar Opción
                                    </div>
                                </div>
                            )}
                            <div className={styles.optionsLabel}>
                                Opción por Defecto:
                            </div>
                            <div className={styles.defaultValueContainer}>
                                <RoundedSmartSelect
                                    value={step.defaultValue}
                                    fullWidth
                                    getOptionSelected={(
                                        option,
                                        value
                                    ): boolean => option.id === value.id}
                                    getValueString={(value): string =>
                                        value?.id
                                    }
                                    backgroundColor="var(--primary1)"
                                    label={step.label}
                                    handleUpdate={(option): void => {
                                        updateStep(
                                            produce(step, (step) => {
                                                step.defaultValue = option;
                                            })
                                        );
                                    }}
                                    options={options.ids
                                        .filter(
                                            (idOption) =>
                                                step.options[idOption]?.type !==
                                                ApiSelectorOptionTypes.HIDE
                                        )
                                        .map(
                                            (idOption) =>
                                                options.options[idOption]
                                        )}
                                    required={step.required}
                                    helperText={step.description ?? ''}
                                    height={'31px'}
                                    searchable={step.searchable}
                                    icon={
                                        step.icon && step.showIcon ? (
                                            <InputIcon icon={step.icon} />
                                        ) : undefined
                                    }
                                />
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </React.Fragment>
        );
    } else {
        return (
            <div
                style={{
                    width: calcStepWidth(step.size, size),
                    maxWidth: '100%',
                    marginBottom: step.description ? '0px' : '5px',
                    minHeight:
                        step.description || step.required ? '50px' : '38px',
                }}
            >
                <RoundedSmartSelect
                    value={step.defaultValue}
                    fullWidth
                    backgroundColor="var(--primary1)"
                    label={step.label}
                    required={step.required}
                    helperText={step.description ?? ''}
                    height={'31px'}
                    searchable={step.searchable}
                    icon={
                        step.icon && step.showIcon ? (
                            <InputIcon icon={step.icon} />
                        ) : undefined
                    }
                />
            </div>
        );
    }
}

export default React.memo(ApiSelectorStep, (prev, next) => {
    const { step, stepEqualityChecker } = prev;
    const { step: newStep } = next;
    if (
        step.size !== newStep.size ||
        step.url !== newStep.url ||
        step.label !== newStep.label ||
        step.icon !== newStep.icon ||
        step.showIcon !== newStep.showIcon ||
        step.description !== newStep.description ||
        step.searchable !== newStep.searchable ||
        step.required !== newStep.required ||
        step.queryParams !== newStep.queryParams ||
        step.pathParams !== newStep.pathParams ||
        step.description !== newStep.description ||
        step.idAttribute !== newStep.idAttribute ||
        step.labelAttribute !== newStep.labelAttribute ||
        step.options !== newStep.options ||
        step.defaultValue !== newStep.defaultValue ||
        step.options !== newStep.options
    ) {
        return false;
    }

    return stepEqualityChecker?.(prev, next) ?? true;
}) as typeof ApiSelectorStep;
