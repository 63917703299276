import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select, { SelectProps } from '@material-ui/core/Select';
import { FormHelperText, Icon, MenuItem } from '@material-ui/core';
import RootClassifierPill from '../../ClassifierPill/RootClassifierPill';
import RootClassifiersMenu from '../../@Menus/RootClassifiersMenu/RootClassifiersMenu';

interface StyleProps {
    /** The color of the outline when selected and hovered on */
    focusColor?: string;
    /** The color of the outline when it is not selected */
    outlineColor?: string;
    /** The color of the background  */
    backgroundColor?: string;
    /** The color of the error to display */
    errorColor?: string;
    /** If the style should change on hover */
    cantEdit?: boolean;
    /** The hight of the container */
    height?: string;
    /** The color of the text in the form */
    color?: string;
    /** material ui style seed */
    seed?: string;
}
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const useOutlinedInputStyles = (props: StyleProps): Function =>
    makeStyles({
        root: {
            cursor: props.cantEdit ? 'default' : 'default',
            height: props.height,
            backgroundColor: props.backgroundColor,
            '& $notchedOutline': {
                borderColor: props.outlineColor,
            },
            '&:hover $notchedOutline': {
                borderColor: props.cantEdit
                    ? props.outlineColor
                    : props.focusColor,
            },
            '& .Mui-disabled': {
                cursor: 'default',
                color: props.color,
            },
            '& .Mui-error': {
                color: props.errorColor,
            },
            [`&.Mui-error .${props.seed}MuiOutlinedInput-notchedOutline`]: {
                borderColor: props.outlineColor,
            },
            '&$focused $notchedOutline': {
                borderColor: props.focusColor,
            },
            [`& .${props.seed}MuiSelect-icon.Mui-disabled`]: {
                color: props.outlineColor,
            },
            [`& .${props.seed}MuiSelect-iconOpen`]: {
                transform: 'none',
            },
            borderRadius: 10,
            color: props.color,
        },
        focused: {},
        notchedOutline: {},
    });

const useLabelInputStyles = (props: StyleProps): Function =>
    makeStyles(() => ({
        focused: {
            color: props.focusColor + ' !important',
        },
        root: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: 'calc(100% - 45px)',
            textOverflow: 'ellipsis',
            marginTop: props.height != '40px' ? '-4px' : '0px',
            '&.Erk-MuiInputLabel-shrink': {
                marginTop: '0px',
                maxWidth: 'calc(100% - 5px)',
            },
            '& .Mui-error': {
                color: props.errorColor,
            },
            '&.Mui-error': {
                color: props.errorColor,
            },
        },
    }));

const useHelperTextStyles = (props: StyleProps): Function =>
    makeStyles(() => ({
        root: {
            '&.Mui-error': {
                color: props.errorColor,
            },
        },
    }));

const useMenuStyles = (props: StyleProps): Function =>
    makeStyles(() => ({
        root: {
            [`& .${props.seed}MuiPaper-root`]: {
                backgroundColor: 'black',
                display: 'none',
            },
        },
    }));

interface RoundedClassifierPickerProps
    extends Omit<SelectProps, 'color'>,
        StyleProps {
    /** Currently selected value */
    value: string[];
    /** function called when value changes */
    handleUpdate: (classifiers: string[]) => void;
    /** Strig to place in the label */
    label?: string;
    /** Minimum width in px of the component */
    minWidth?: number;
    /** The helper Text to display */
    helperText?: string;
    /** the margin around the selector */
    containerMargin?: string;
    /** If the select displays more than one classifier*/
    multiple?: boolean;
    /** If the app is mobile */
    mobile?: boolean;
    /** If the selector should occupy the width of the parent or grow as the content grows */
    fullWidth?: boolean;
}

function CustomSelect({
    value,
    handleUpdate,
    label,
    minWidth,
    helperText,
    color = 'var(--accent)',
    errorColor = 'var(--error)',
    focusColor = 'var(--secondary)',
    outlineColor = 'var(--outlineGrey)',
    backgroundColor = 'var(--primary1)',
    seed = 'Erk-',
    cantEdit = false,
    containerMargin = '0px',
    height = '31px',
    required,
    error,
    multiple = false,
    fullWidth = true,
    ...others
}: RoundedClassifierPickerProps): JSX.Element {
    const StyleProps = {
        focusColor,
        outlineColor,
        backgroundColor,
        errorColor,
        cantEdit,
        color,
        seed,
        height,
    };
    const classes = useStyles();
    const outlinedInputClasses = useOutlinedInputStyles(StyleProps)();
    const labelClasses = useLabelInputStyles(StyleProps)();
    const helperTextClasses = useHelperTextStyles(StyleProps)();
    const menuClasses = useMenuStyles(StyleProps)();
    const selectRef = React.useRef<HTMLDivElement>(null);
    const [showMenu, setShowMenu] = useState(false);

    return (
        <React.Fragment>
            {showMenu && (
                <RootClassifiersMenu
                    multiple={multiple}
                    menuLabel="Seleccionar Clasificador"
                    anchorRef={selectRef}
                    value={value}
                    handleChange={handleUpdate}
                    zIndex={1301}
                    handleClose={(): void => setShowMenu(false)}
                />
            )}
            <FormControl
                variant="outlined"
                className={classes.formControl}
                size="small"
                style={
                    minWidth !== undefined
                        ? { minWidth, outlineColor, margin: containerMargin }
                        : {
                              margin: containerMargin,
                          }
                }
                fullWidth={fullWidth}
                required={required}
                error={error}
            >
                {label && (
                    <InputLabel classes={labelClasses}>{label}</InputLabel>
                )}
                <Select
                    {...others}
                    ref={selectRef}
                    value={value.length > 0 ? 0 : ''}
                    IconComponent={ClassifierIcon}
                    onClose={(): void => {
                        setShowMenu(false);
                    }}
                    onOpen={(): void => {
                        setShowMenu(true);
                    }}
                    input={
                        <OutlinedInput
                            disabled={cantEdit}
                            name={label}
                            label={
                                label
                                    ? label + (required ? ' *' : '')
                                    : undefined
                            }
                            classes={outlinedInputClasses}
                        />
                    }
                    renderValue={(): undefined | React.ReactNode => {
                        return (
                            <div
                                style={{
                                    fontFamily: 'Nunito',
                                    height: 22,
                                    width: 'fit-content',
                                    display: 'flex',
                                    cursor: 'pointer',
                                    maxWidth: fullWidth
                                        ? 'calc(100% - 5px)'
                                        : '100%',
                                    marginRight: 5,
                                    gap: 5,
                                }}
                            >
                                {value.map((idRoot, index) => (
                                    <RootClassifierPill
                                        key={index}
                                        idRoot={idRoot}
                                    />
                                ))}
                            </div>
                        );
                    }}
                    MenuProps={{
                        PopoverClasses: menuClasses,
                        style: { zIndex: 1300 },
                    }}
                >
                    <MenuItem value={0}></MenuItem>
                </Select>
                {helperText !== undefined && (
                    <FormHelperText classes={helperTextClasses}>
                        {helperText}
                    </FormHelperText>
                )}
            </FormControl>
        </React.Fragment>
    );
}

/**
 * Generic textfield with apps designs. Is class do to the use in the react-hook-forms library
 */
class RoundedClassifierPicker extends React.Component<RoundedClassifierPickerProps> {
    render(): JSX.Element {
        return <CustomSelect {...this.props} />;
    }
}
export default RoundedClassifierPicker;

function ClassifierIcon(props: any): JSX.Element {
    const useStyles = makeStyles({
        iconRoot: {
            position: 'absolute',
            right: '7px',
            marginTop: '2px',
        },
        imageIcon: {
            height: 21,
            width: 21,
        },
    });
    const classes = useStyles();
    return (
        <Icon classes={{ root: classes.iconRoot }} className={props.className}>
            <img
                className={classes.imageIcon}
                style={{ display: 'flex', height: 'inherit', width: 'inherit' }}
                src="/media/icons/greyshapes.svg"
            />
        </Icon>
    );
}
