import { AnyAction } from '@reduxjs/toolkit';
import { takeLatest, call, put, fork, select } from 'redux-saga/effects';
import * as actions from './EntriesActions';
import { loadEntries, updateEntry } from './EntriesService';
import { RootState } from '../../utils/_store';
import VanillaToast from '../../shared/Toast/Toast';
import { Entry } from '../../@Types/Entry';

/** Number of elements per Page */
/** Selector that returns the current idProject */
const getIdProject = (state: RootState): string | undefined | null =>
    state.site.idProject;

/**
 * Function called when new entries should be rendered (reset,search, or newpage)
 * @param action of type Search, reset or scroll
 */
function* getEntries(action: AnyAction): any {
    try {
        const idProject = yield select(getIdProject);
        const pageInfo = yield select((state) => state.entriesPage);
        let result = yield call(
            loadEntries,
            idProject,
            pageInfo.filters,
            pageInfo.orderBy,
            pageInfo.search
        );
        let selectedElement = undefined;
        if (actions.reset.match(action)) {
            if (action.payload.apiKey) {
                selectedElement = result.findIndex(
                    (element: Entry) => element.apiKey === action.payload.apiKey
                );
            }
        }

        yield put(actions.getSuccess({ elements: result, selectedElement }));
    } catch (error) {
        //TODO handle errors
        console.error(error);
    }
}

/**
 * Function called when an entry's value is updated optimistically to patch the updates to the server asyncly
 * @param action of type UPDATE
 */
function* putEntry(action: AnyAction): any {
    if (actions.updateElement.match(action)) {
        try {
            const idProject = yield select(getIdProject);
            const payload: Record<string, unknown> = {};
            payload[action.payload.field] = action.payload.value;
            yield call(
                updateEntry,
                action.payload.object.apiKey,
                idProject,
                payload
            );
        } catch (error: any) {
            VanillaToast.create({
                title: 'Error al actualizar la entrada',
                text: error,
                type: 'error',
                timeout: 5000,
            });
            yield put(actions.updateFailed(action.payload.object));
        }
    }
}

function* watchGetEntriesRequest(): any {
    yield takeLatest([actions.Types.RESET, actions.Types.SEARCH], getEntries);
}

function* watchUpdateEntry(): any {
    yield takeLatest([actions.Types.UPDATE], putEntry);
}

export default [fork(watchGetEntriesRequest), fork(watchUpdateEntry)];
