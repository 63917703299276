import React, { useRef, useState } from 'react';
import styles from './ImageEditor.module.css';
import Loader from '../../../shared/Loader/Loader';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { BaseBranding } from '../../../@Types/Branding';

interface OrganizationFileEditorProps {
    images: BaseBranding['images'];
    field: 'iconUrl' | 'logoUrl';
    updateFile: (file: File) => void;
    heightImage: number;
    backgroundColor?: string;
}

export default function ImageEditor({
    images,
    field,
    updateFile,
    heightImage,
    backgroundColor = 'white',
}: OrganizationFileEditorProps): JSX.Element {
    const [loading, setLoading] = useState(false);
    const inputFile = useRef<HTMLInputElement>(null);
    const [imageSRC, setImageSRC] = useState(images[field]);

    const setImage = async (newFile: File): Promise<void> => {
        setLoading(true);
        await updateFile(newFile);
        setImageSRC(URL.createObjectURL(newFile));
        setLoading(false);
    };

    const onFileDialogChange = async (e: any): Promise<void> => {
        const files = e.target.files;
        const newFile = files[0];
        if (newFile.type.includes('image')) {
            setImageSRC(URL.createObjectURL(newFile));
            await setImage(newFile);
        }
    };

    const onButtonClick = (): void => {
        // `current` points to the mounted file input element
        inputFile.current?.click();
    };

    return (
        <div
            className={styles.container}
            style={{ minHeight: heightImage + 8 }}
            data-testid={'ImageEditor'}
        >
            <div
                className={styles.imageContainer}
                style={{
                    cursor: loading ? 'default' : 'pointer',
                    maxHeight: heightImage,
                    backgroundColor,
                }}
                onClick={(): void => {
                    if (!loading) {
                        onButtonClick();
                        //Esto que hace?
                    }
                }}
            >
                {loading && (
                    <div className={styles.loaderContainer}>
                        <Loader size="40px" position="relative" />
                    </div>
                )}
                <img
                    className={styles.image}
                    src={imageSRC}
                    alt={'img_' + field}
                    style={{
                        maxHeight: heightImage,
                        opacity: loading ? 0.5 : 1,
                    }}
                />
                <input
                    type="file"
                    accept="image/*"
                    ref={inputFile}
                    onChange={onFileDialogChange}
                    style={{ display: 'none' }}
                />
            </div>

            <div
                className={
                    loading
                        ? styles.disabledEditContainer
                        : styles.editContainer
                }
                onClick={(): void => {
                    if (!loading) {
                        onButtonClick();
                    }
                }}
            >
                <EditRoundedIcon fontSize="inherit" />
            </div>
        </div>
    );
}
