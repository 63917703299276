import { useSelector } from 'react-redux';
import stepMenuStyles from '../../StepMenu.module.css';
import { EntityValuePicker } from '../../../../../@Types/FormTypes/FormStep';
import styles from './EntityRelationshipMenu.module.css';
import React, { useRef, useState } from 'react';
import { Popper } from '@material-ui/core';
import { RootState } from '../../../../../utils/_store';
import EntityIcon from '../../../../../shared/SmartIcons/EntityIcon';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import { nanoid } from 'nanoid';
import FormStepTypes, {
    EntityValuePathTypes,
} from '../../../../../constants/FormStepTypes';
import IconTypes from '../../../../../constants/IconTypes';
import LocationTypes from '../../../../../constants/LocationTypes';
import { FormStepLocation } from '../../../../../@Types/FormTypes/Form';
import { EntityRelationship } from '../../../../../@Types/EntityTypes/Entity';
import { useCurrentProject } from '../../../../../hooks';
interface EntityRelationshipMenuProps {
    relationship: EntityRelationship;
    path?: { idEntity: string; hidden: boolean }[];
    shown: string[];
    location: FormStepLocation;
    handleAdd: (step: EntityValuePicker) => void;
}
function EntityRelationshipMenu({
    shown,
    path = [],
    location,
    handleAdd,
    relationship,
}: EntityRelationshipMenuProps): JSX.Element {
    const [hovering, setHovering] = useState(false);
    const [hoveringMenu, setHoveringMenu] = useState(false);
    const project = useCurrentProject();
    const entity = useSelector(
        (state: RootState) => state.site.entities[relationship.idEntity]
    );
    const btnRef = useRef<HTMLDivElement>(null);

    const relationships = [
        ...entity.relationships,
        ...(relationship.relationships ?? []),
    ].filter((relationship) => !shown.includes(relationship.idEntity));

    return (
        <React.Fragment>
            <div
                className={stepMenuStyles.option}
                onMouseEnter={(): void => {
                    setHovering(true);
                }}
                onMouseLeave={(): void => {
                    setHovering(false);
                }}
                onClick={(): void => {
                    let idValue = undefined;
                    for (const [key, value] of Object.entries(
                        project.values.values
                    )) {
                        if (
                            value.type === FormStepTypes.ENTITYVALUEPICKER &&
                            value.idEntity === entity._id
                        ) {
                            idValue = key;
                        }
                    }
                    const defaultValue: EntityValuePicker = {
                        id:
                            FormStepTypes.ENTITYVALUEPICKER +
                            '-' +
                            entity._id +
                            '-' +
                            nanoid(),
                        type: FormStepTypes.ENTITYVALUEPICKER,
                        label: entity.name,
                        icon: entity.icon ?? IconTypes.GENERIC,
                        searchable: true,
                        showIcon: true,
                        description: entity.description,
                        idEntity: entity._id,
                        required: false,
                        filters: [],
                        path: path.map(({ idEntity, hidden }) =>
                            hidden
                                ? {
                                      idEntity,
                                      type: EntityValuePathTypes.VALUE,
                                      idEntityValue: null,
                                  }
                                : {
                                      idEntity,
                                      type: EntityValuePathTypes.STEP,
                                      any: true,
                                  }
                        ),
                        options: {},
                        size: 1,
                        idValue,
                    };
                    if (location.type === LocationTypes.SECTION) {
                        defaultValue.maxSize = 1;
                    }
                    handleAdd(defaultValue);
                }}
                ref={btnRef}
                style={{
                    backgroundColor: hovering ? ' var(--light-grey)' : 'white',
                }}
            >
                <div className={styles.entityInputContainer}>
                    <div className={styles.entityIconContainer}>
                        <EntityIcon
                            fill={'var(--secondary)'}
                            idEntity={entity._id}
                        />
                    </div>
                </div>
                <div className={stepMenuStyles.optionLabel}>{entity.name}</div>
                {relationships.length > 0 && (
                    <div className={styles.relationshipContainer}>
                        <ArrowDownwardRoundedIcon fontSize="inherit" />
                    </div>
                )}
            </div>
            {relationships.length && (hovering || hoveringMenu) && (
                <Popper
                    open={true}
                    anchorEl={btnRef.current}
                    placement={'bottom-end'}
                    modifiers={{
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: 'viewport',
                        },
                    }}
                    style={{ zIndex: 3 }}
                >
                    <div
                        className={styles.container}
                        onMouseEnter={(): void => {
                            setHoveringMenu(true);
                        }}
                        onMouseLeave={(): void => {
                            setHoveringMenu(false);
                        }}
                    >
                        <div className={styles.bottomLeftPadding}></div>
                        <div>
                            {relationships.map((subRel) => {
                                return (
                                    <EntityRelationshipMenu
                                        shown={[
                                            ...shown,
                                            ...relationships.map(
                                                (rel) => rel.idEntity
                                            ),
                                        ]}
                                        path={[
                                            ...path,
                                            {
                                                idEntity: entity._id,
                                                hidden: !relationship.multiple,
                                            },
                                        ]}
                                        relationship={subRel}
                                        handleAdd={handleAdd}
                                        location={location}
                                        key={subRel.idEntity}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </Popper>
            )}
        </React.Fragment>
    );
}

export default EntityRelationshipMenu;
