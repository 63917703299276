import styles from './TextAreaConfig.module.css';
import Toggle from '../../../../shared/Toggle/Toggle';
import React from 'react';
import { FormStep, TextArea } from '../../../../@Types/FormTypes/FormStep';

interface TextAreaConfigProps {
    step?: TextArea;
    updateStep?: (step: FormStep) => void;
}
function TextAreaConfig({
    step,
    updateStep,
}: TextAreaConfigProps): JSX.Element {
    if (!step || !updateStep) {
        return <div></div>;
    }
    return (
        <div className={styles.isContentContainer}>
            <div className={styles.isContentLabel + ' noselect'}>
                Cuerpo del Caso:
            </div>
            <div className={styles.toggleContainer}>
                <Toggle
                    checked={step.isPrimary === true}
                    onChange={(checked: boolean): void => {
                        updateStep({
                            ...step,
                            isPrimary: checked,
                        });
                    }}
                />
            </div>
        </div>
    );
}
export default TextAreaConfig;
