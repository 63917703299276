import ConversationStepTypes from '../../../../../constants/Conversations/ConversationStepTypes';
import { useDispatch, useSelector } from 'react-redux';
import {
    StepLocation,
    calcLocationSteps,
} from '../../../../../controllers/ConversationEditorController/ConversationEditorReducer';
import { addStep } from '../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import { createConversationStep } from '../../StepFunctions';
import InfoStepMenu from './InfoStepMenu';
import ListStepMenu from './ListStepMenu';
import CreateStepMenu from './CreateStepMenu';
import NavStepMenu from './NavStepMenu';
import { RootState } from '../../../../../utils/_store';
import CBRStepMenu from './CBRStepMenu/CBRStepMenu';
import AYFStepMenu from './AYFStepMenu/AYFStepMenu';
import EntityMenu from './EntityMenu/EntityMenu';
import { EntityValueStep } from '../../../../../@Types/ConversationTypes/ConversationStep';
import OtherEntitiesMenu from './OtherEntitiesMenu/OtherEntitiesMenu';
import { useMemo } from 'react';
import { useCurrentProject } from '../../../../../hooks';
import EurekaElementMenu from '../../../../../shared/@Menus/ElementMenu/EurekaElementMenu';
import EurekaElementMenuElement from '../../../../../shared/@Menus/ElementMenu/EurekaElementMenuElement';
import SubjectIcon from '@material-ui/icons/Subject';
import styles from './StepMenu.module.css';
import FileIcon from '../../../../../Icons/FileIcon';
import TitleIcon from '../../../../../Icons/TitleIcon';
import CreatableListIcon from '../../../../../Icons/CreatableListIcon';

interface StepMenuProps {
    handleClose: () => void;
    anchorRef: any;
    location: StepLocation;
}

function StepMenu({
    handleClose,
    anchorRef,
    location,
}: StepMenuProps): JSX.Element {
    const dispatch = useDispatch();
    const organization = useSelector(
        (state: RootState) => state.site.organization
    );
    const project = useCurrentProject();

    const handleAdd = (type: ConversationStepTypes): void => {
        dispatch(addStep({ step: createConversationStep(type), location }));
        handleClose();
    };
    const editorInfo = useSelector(
        (state: RootState) => state.conversationEditor
    );
    const siteEntities = useSelector((state: RootState) => state.site.entities);
    const entities = useMemo((): string[] => {
        const entities = project.entities.map((entity) => entity.idEntity);
        if (!location) return entities;
        if (!editorInfo) return entities;
        const steps = calcLocationSteps(editorInfo, location) ?? [];
        const idStep = steps[location.indexStep - 1] ?? steps[0];
        if (!idStep) return entities;
        const entityValueSteps =
            editorInfo.stepDependencies[idStep]?.dependencies[
                ConversationStepTypes.ENTITY_VALUE_STEP
            ] ?? [];
        if (
            editorInfo.steps[idStep]?.type ===
            ConversationStepTypes.ENTITY_VALUE_STEP
        ) {
            entityValueSteps.push(idStep);
        }
        for (const idStep of entityValueSteps) {
            const step = editorInfo.steps[idStep];
            if (step?.type === ConversationStepTypes.ENTITY_VALUE_STEP) {
                if (
                    !entities.includes(step.idEntity) &&
                    siteEntities[step.idEntity]?.relationships.filter(
                        (relationship) =>
                            relationship.idEntity !== step.idEntity
                    ).length > 0
                ) {
                    entities.push(step.idEntity);
                }
            }
        }
        return entities;
    }, [editorInfo, siteEntities]);

    return (
        <EurekaElementMenu
            anchorEl={anchorRef.current}
            handleClose={handleClose}
            placement="bottom"
            style={{ fontSize: 18 }}
            renderElements={(
                props,
                handleClose
            ): JSX.Element | JSX.Element[] => {
                const elements: JSX.Element[] = [];

                elements.push(
                    <InfoStepMenu
                        key="INFO"
                        location={location}
                        handleClose={(): void => {
                            handleClose();
                        }}
                    />
                );
                elements.push(
                    <EurekaElementMenuElement
                        {...props}
                        Icon={TitleIcon}
                        Label={'Solicitud de Texto'}
                        key={ConversationStepTypes.TEXT_STEP}
                        onClick={(): void => {
                            handleAdd(ConversationStepTypes.TEXT_STEP);
                        }}
                    />
                );
                elements.push(
                    <EurekaElementMenuElement
                        {...props}
                        Icon={FileIcon}
                        Label={'Solicitud de Archivo'}
                        key={ConversationStepTypes.FILE_STEP}
                        onClick={(): void => {
                            handleAdd(ConversationStepTypes.FILE_STEP);
                        }}
                    />
                );

                elements.push(
                    <ListStepMenu
                        key="LIST"
                        location={location}
                        handleClose={(): void => {
                            handleClose();
                        }}
                    />
                );
                elements.push(
                    <EurekaElementMenuElement
                        {...props}
                        Label={'Mensaje con Botón'}
                        key={ConversationStepTypes.BUTTONS_STEP}
                        onClick={(): void => {
                            handleAdd(ConversationStepTypes.BUTTONS_STEP);
                        }}
                        Icon={(): JSX.Element => (
                            <div className={styles.btnIcon}></div>
                        )}
                    />
                );
                elements.push(
                    <EurekaElementMenuElement
                        Label={'Solicitud Abierta'}
                        onClick={(): void => {
                            handleAdd(ConversationStepTypes.OPEN_STEP);
                        }}
                        {...props}
                        key={ConversationStepTypes.OPEN_STEP}
                        Icon={(): JSX.Element => (
                            <div className={styles.textAreaContainer}>
                                <SubjectIcon fontSize="inherit" />
                            </div>
                        )}
                    />
                );
                elements.push(
                    <CreateStepMenu
                        key="CREATE"
                        location={location}
                        handleClose={(): void => {
                            handleClose();
                        }}
                    />
                );
                elements.push(
                    <NavStepMenu
                        key="NAV"
                        location={location}
                        handleClose={(): void => {
                            handleClose();
                        }}
                    />
                );
                for (const idEntity of entities) {
                    elements.push(
                        <EntityMenu
                            shown={project.entities.map(
                                (entity) => entity.idEntity
                            )}
                            key={idEntity}
                            idEntity={idEntity}
                            location={location}
                            handleAdd={(step: EntityValueStep): void => {
                                dispatch(
                                    addStep({
                                        step,
                                        location,
                                    })
                                );
                                handleClose();
                            }}
                        />
                    );
                }
                elements.push(
                    <EurekaElementMenuElement
                        {...props}
                        Icon={CreatableListIcon}
                        Label={'Lista de Elementos'}
                        key={ConversationStepTypes.CREATABLE_STEP}
                        onClick={(): void => {
                            handleAdd(ConversationStepTypes.CREATABLE_STEP);
                        }}
                    />
                );

                // for (const concept of project.concepts) {
                //     elements.push(
                //         <ConceptMenu
                //             key={concept.id}
                //             location={location}
                //             concept={concept}
                //             handleAdd={handleAdd}
                //         />
                //     );
                // }

                elements.push(
                    <OtherEntitiesMenu
                        key="OTHER-ENTITIES"
                        handleAdd={(step: EntityValueStep): void => {
                            dispatch(
                                addStep({
                                    step,
                                    location,
                                })
                            );
                            handleClose();
                        }}
                    />
                );

                if (organization!.integrations.AYF) {
                    elements.push(
                        <AYFStepMenu
                            location={location}
                            key="AYF"
                            handleClose={(): void => {
                                handleClose();
                            }}
                        />
                    );
                }
                if (organization!.integrations.CBR) {
                    elements.push(
                        <CBRStepMenu
                            key="CBR"
                            location={location}
                            handleClose={(): void => {
                                handleClose();
                            }}
                        />
                    );
                }
                return elements;
            }}
            zIndex={3}
        />
    );
}
export default StepMenu;
