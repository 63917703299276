import { Classifier } from '../../../../../../../../@Types/@Types';
import styles from './Parent.module.css';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
interface ParentProps {
    /** The classifier to display */
    classifier: Classifier;
    /** The function to call when the classifier is clicked on */
    handleClick: Function;
    /** If the classifier is the selected value */
    selected: boolean;
}
/**
 * Component that shows a single parent of the classifier currently bien classified.
 * It can be clicked on to show its children
 */
function Parent({
    classifier,
    handleClick,
    selected,
}: ParentProps): JSX.Element {
    /** Function that renders a search value with a highlight */
    const renderText = (text: string): any => {
        if (text) {
            var parts: any = text.split(/<SEARCH:(.*?)>/gi);
            for (var i = 1; i < parts.length; i += 2) {
                parts[i] = (
                    <span className={styles.search} key={i}>
                        {parts[i]}
                    </span>
                );
            }
            return parts;
        }
    };
    return (
        <div
            className={styles.container}
            onClick={(): void => {
                handleClick();
            }}
        >
            <div className={styles.checkIcon}>
                {selected && <CheckRoundedIcon fontSize="inherit" />}
            </div>
            <label className={selected ? styles.selectedLbl : styles.nameLBl}>
                {renderText(classifier.name)}
            </label>
            <div className={styles.dropDownBtn}>
                <ExpandMoreRoundedIcon fontSize="inherit" />
            </div>
        </div>
    );
}

export default Parent;
