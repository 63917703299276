import styles from './Payload.module.css';
import { EditorState, convertFromRaw } from 'draft-js';
import FileComponent from '../FileComponent/FileComponent';
import { getRawText } from '../../utils/draftFunctions';
import { Payload } from '../../@Types/Payload';
import DraftRenderer from '../DraftRenderer/DraftRenderer';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../utils/_store';
interface PayloadProps {
    content: Payload;
    tags?: Record<string, boolean>;
    /** If the editor should show Eureka Decorators */
    showDecorators?: boolean;
}
function PayloadComponent({
    content,
    tags,
    showDecorators = false,
}: PayloadProps): JSX.Element {
    const editorState = EditorState.createWithContent(
        convertFromRaw(getRawText(content.draft, content.text))
    );

    const hasText = editorState.getCurrentContent().hasText();
    return (
        <div className={styles.container}>
            {hasText && (
                <div
                    className={styles.textContainer}
                    data-testid="draft_renderer_container"
                >
                    <DraftRenderer
                        editorState={editorState}
                        images={content.images}
                        showDecorators={showDecorators}
                    />
                </div>
            )}

            {content?.files && content.files.length > 0 && (
                <div
                    className={styles.filesContainer + ' flexbox'}
                    style={
                        hasText
                            ? {
                                  borderTop: '1px solid var(--light-grey)',
                              }
                            : {}
                    }
                >
                    {content.files.map((file, index) => (
                        <div
                            className={styles.fileContainer}
                            key={index}
                            data-testid="payload_files_container"
                        >
                            <FileComponent file={file} />
                        </div>
                    ))}
                </div>
            )}
            {tags && <TagsComponent tags={tags} />}
        </div>
    );
}

export default PayloadComponent;

interface TagsComponentProps {
    tags: Record<string, boolean>;
}

function TagsComponent({ tags }: TagsComponentProps): JSX.Element {
    const projectTags = useSelector(
        (state: RootState) =>
            state.site.projects[state.site.idProject ?? '']?.tags ?? []
    );

    const tagsToDisplay = useMemo(() => {
        const tagsToDisplay = [];
        if (tags.melendez_isFormal)
            tagsToDisplay.push('Respuesta Definitiva al Cliente');
        for (const [key, value] of Object.entries(tags)) {
            const projectTag = projectTags.find((t) => t.id === key);
            if (value && projectTag?.label) {
                tagsToDisplay.push(projectTag.label);
            }
        }
        return tagsToDisplay;
    }, [tags]);

    if (tagsToDisplay.length === 0) return <></>;

    return (
        <React.Fragment>
            {tagsToDisplay.map((tag, index) => (
                <div className={styles.validationContainer} key={index}>
                    {tag}
                </div>
            ))}
        </React.Fragment>
    );
}
