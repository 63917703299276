import styles from './CBRStepMenu.module.css';
import stepMenuStyles from '../../StepMenu.module.css';
import { CBRFormStep } from '../../../../../@Types/FormTypes/CBRFormStep';
import CBRStepTypes, {
    CBRFormStepTypes,
} from '../../../../../constants/Construction/CBRFormStepTypes';
import { useRef, useState } from 'react';
import React from 'react';
import { Popper } from '@material-ui/core';
import ProjectIcon from '../../../../../Icons/Construction/ProjectIcon';
import PropertyIcon from '../../../../../Icons/Construction/PropertyIcon';
import SpaceIcon from '../../../../../Icons/Construction/SpaceIcon';
import IdentifierIcon from '../../../../../Icons/IdentifierIcon';
import LeakIcon from '../../../../../Icons/Construction/LeakIcon';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import { nanoid } from 'nanoid';
import { useFormEditorSelector } from '../../../FormReducerFunctions';
interface CBRStepMenuProps {
    handleAdd: (step: CBRFormStep) => void;
}
function CBRStepMenu({ handleAdd }: CBRStepMenuProps): JSX.Element {
    const [hovering, setHovering] = useState(false);
    const [hoveringMenu, setHoveringMenu] = useState(false);
    const editorSteps = useFormEditorSelector((state) => state.steps);
    const btnRef = useRef<HTMLDivElement>(null);

    const findStep = (type: CBRStepTypes): boolean => {
        return (
            Object.values(editorSteps).find(
                (step) => (step as any).type === type
            ) !== undefined
        );
    };
    return (
        <React.Fragment>
            <div
                className={stepMenuStyles.option}
                onMouseEnter={(): void => {
                    setHovering(true);
                }}
                onMouseLeave={(): void => {
                    setHovering(false);
                }}
                ref={btnRef}
                style={{
                    backgroundColor:
                        hovering || hoveringMenu
                            ? ' var(--light-grey)'
                            : 'white',
                }}
            >
                <div className={styles.cbrIcon}>
                    <img
                        className={styles.cbrImg}
                        src="/media/icons/sinco-cbr.svg"
                    />
                </div>
                <div className={stepMenuStyles.optionLabel}>CBR Posventas</div>
            </div>
            {(hovering || hoveringMenu) && (
                <Popper
                    open={true}
                    anchorEl={btnRef.current}
                    placement={'right-end'}
                    modifiers={{
                        preventOverflow: {
                            enabled: true,
                            priority: ['right', 'left'],
                            boundariesElement: 'viewport',
                        },
                    }}
                    style={{ zIndex: 3 }}
                >
                    <div
                        className={styles.container}
                        onMouseEnter={(): void => {
                            setHoveringMenu(true);
                        }}
                        onMouseLeave={(): void => {
                            setHoveringMenu(false);
                        }}
                    >
                        <div className={styles.bottomLeftPadding}></div>
                        <div>
                            <div
                                className={
                                    findStep(CBRStepTypes.CBR_FIRST_NAME)
                                        ? stepMenuStyles.existingOption
                                        : stepMenuStyles.option
                                }
                                onClick={(): void => {
                                    handleAdd(
                                        createCBRFormStep(
                                            CBRStepTypes.CBR_FIRST_NAME
                                        )
                                    );
                                }}
                            >
                                <div className={styles.textInputContainer}>
                                    A..
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Primer Nombre*
                                </div>
                            </div>
                            <div
                                className={
                                    findStep(CBRStepTypes.CBR_MIDDLE_NAME)
                                        ? stepMenuStyles.existingOption
                                        : stepMenuStyles.option
                                }
                                onClick={(): void => {
                                    handleAdd(
                                        createCBRFormStep(
                                            CBRStepTypes.CBR_MIDDLE_NAME
                                        )
                                    );
                                }}
                            >
                                <div className={styles.textInputContainer}>
                                    A..
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Segundo Nombre
                                </div>
                            </div>
                            <div
                                className={
                                    findStep(CBRStepTypes.CBR_LAST_NAME)
                                        ? stepMenuStyles.existingOption
                                        : stepMenuStyles.option
                                }
                                onClick={(): void => {
                                    handleAdd(
                                        createCBRFormStep(
                                            CBRStepTypes.CBR_LAST_NAME
                                        )
                                    );
                                }}
                            >
                                <div className={styles.textInputContainer}>
                                    A..
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Primer Apellido*
                                </div>
                            </div>
                            <div
                                className={
                                    findStep(CBRStepTypes.CBR_SECOND_LAST_NAME)
                                        ? stepMenuStyles.existingOption
                                        : stepMenuStyles.option
                                }
                                onClick={(): void => {
                                    handleAdd(
                                        createCBRFormStep(
                                            CBRStepTypes.CBR_SECOND_LAST_NAME
                                        )
                                    );
                                }}
                            >
                                <div className={styles.textInputContainer}>
                                    A..
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Segundo Apellido
                                </div>
                            </div>
                            <div
                                className={
                                    findStep(CBRStepTypes.CBR_TIPO_DOC)
                                        ? stepMenuStyles.existingOption
                                        : stepMenuStyles.option
                                }
                                onClick={(): void => {
                                    handleAdd(
                                        createCBRFormStep(
                                            CBRStepTypes.CBR_TIPO_DOC
                                        )
                                    );
                                }}
                            >
                                <div className={styles.selectInputContainer}>
                                    <ArrowDropDownIcon fontSize="inherit" />
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Tipo de Documento*
                                </div>
                            </div>
                            <div
                                className={
                                    findStep(CBRStepTypes.CBR_DOC)
                                        ? stepMenuStyles.existingOption
                                        : stepMenuStyles.option
                                }
                                onClick={(): void => {
                                    handleAdd(
                                        createCBRFormStep(CBRStepTypes.CBR_DOC)
                                    );
                                }}
                            >
                                <div className={styles.inputContainer}>
                                    <div className={styles.iconContainer}>
                                        <IdentifierIcon
                                            fill="var(--secondary)"
                                            style={{
                                                height: 'inherit',
                                                width: 'inherit',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Documento*
                                </div>
                            </div>
                            <div
                                className={
                                    findStep(CBRStepTypes.CBR_CEL)
                                        ? stepMenuStyles.existingOption
                                        : stepMenuStyles.option
                                }
                                onClick={(): void => {
                                    handleAdd(
                                        createCBRFormStep(CBRStepTypes.CBR_CEL)
                                    );
                                }}
                            >
                                <div className={styles.textInputContainer}>
                                    1..
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Celular*
                                </div>
                            </div>
                            <div
                                className={
                                    findStep(CBRStepTypes.CBR_PHONE)
                                        ? stepMenuStyles.existingOption
                                        : stepMenuStyles.option
                                }
                                onClick={(): void => {
                                    handleAdd(
                                        createCBRFormStep(
                                            CBRStepTypes.CBR_PHONE
                                        )
                                    );
                                }}
                            >
                                <div className={styles.textInputContainer}>
                                    1..
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Teléfono Fijo
                                </div>
                            </div>
                            <div
                                className={
                                    findStep(CBRStepTypes.CBR_EMAIL)
                                        ? stepMenuStyles.existingOption
                                        : stepMenuStyles.option
                                }
                                onClick={(): void => {
                                    handleAdd(
                                        createCBRFormStep(
                                            CBRStepTypes.CBR_EMAIL
                                        )
                                    );
                                }}
                            >
                                <div className={styles.textInputContainer}>
                                    @..
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Correo*
                                </div>
                            </div>
                            <div
                                className={
                                    findStep(CBRStepTypes.CBR_TIPO_SOLICITANTE)
                                        ? stepMenuStyles.existingOption
                                        : stepMenuStyles.option
                                }
                                onClick={(): void => {
                                    handleAdd(
                                        createCBRFormStep(
                                            CBRStepTypes.CBR_TIPO_SOLICITANTE
                                        )
                                    );
                                }}
                            >
                                <div className={styles.agentInputContainer}>
                                    <PersonRoundedIcon fontSize="inherit" />
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Tipo de solicitante*
                                </div>
                            </div>
                            <div
                                className={
                                    findStep(CBRStepTypes.CBR_MACRO_PROYECTO)
                                        ? stepMenuStyles.existingOption
                                        : stepMenuStyles.option
                                }
                                onClick={(): void => {
                                    handleAdd(
                                        createCBRFormStep(
                                            CBRStepTypes.CBR_MACRO_PROYECTO
                                        )
                                    );
                                }}
                            >
                                <div className={styles.inputContainer}>
                                    <div className={styles.iconContainer}>
                                        <ProjectIcon
                                            fill="var(--secondary)"
                                            style={{
                                                height: 'inherit',
                                                width: 'inherit',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Macro Proyecto
                                </div>
                            </div>
                            <div
                                className={
                                    findStep(CBRStepTypes.CBR_PROYECTO)
                                        ? stepMenuStyles.existingOption
                                        : stepMenuStyles.option
                                }
                                onClick={(): void => {
                                    handleAdd(
                                        createCBRFormStep(
                                            CBRStepTypes.CBR_PROYECTO
                                        )
                                    );
                                }}
                            >
                                <div className={styles.inputContainer}>
                                    <div className={styles.iconContainer}>
                                        <ProjectIcon
                                            fill="var(--secondary)"
                                            style={{
                                                height: 'inherit',
                                                width: 'inherit',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Proyecto*
                                </div>
                            </div>
                            <div
                                className={
                                    findStep(CBRStepTypes.CBR_INMUEBLE)
                                        ? stepMenuStyles.existingOption
                                        : stepMenuStyles.option
                                }
                                onClick={(): void => {
                                    handleAdd(
                                        createCBRFormStep(
                                            CBRStepTypes.CBR_INMUEBLE
                                        )
                                    );
                                }}
                            >
                                <div className={styles.inputContainer}>
                                    <div className={styles.iconContainer}>
                                        <PropertyIcon
                                            fill="var(--secondary)"
                                            style={{
                                                height: 'inherit',
                                                width: 'inherit',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Inmueble*
                                </div>
                            </div>
                            <div
                                className={
                                    findStep(CBRStepTypes.CBR_TIPO_ESPACIO)
                                        ? stepMenuStyles.existingOption
                                        : stepMenuStyles.option
                                }
                                onClick={(): void => {
                                    handleAdd(
                                        createCBRFormStep(
                                            CBRStepTypes.CBR_TIPO_ESPACIO
                                        )
                                    );
                                }}
                            >
                                <div className={styles.inputContainer}>
                                    <div className={styles.iconContainer}>
                                        <SpaceIcon
                                            fill="var(--secondary)"
                                            style={{
                                                height: 'inherit',
                                                width: 'inherit',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Tipo de Espacio
                                </div>
                            </div>
                            <div
                                className={stepMenuStyles.option}
                                onClick={(): void => {
                                    handleAdd(
                                        createCBRFormStep(
                                            CBRStepTypes.CBR_LOCATIVAS
                                        )
                                    );
                                }}
                            >
                                <div className={styles.inputContainer}>
                                    <div className={styles.iconContainer}>
                                        <LeakIcon
                                            fill="var(--secondary)"
                                            style={{
                                                height: 'inherit',
                                                width: 'inherit',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Elemento
                                </div>
                            </div>
                            <div
                                className={
                                    findStep(CBRStepTypes.CBR_INCIDENCIAS)
                                        ? stepMenuStyles.existingOption
                                        : stepMenuStyles.option
                                }
                                onClick={(): void => {
                                    handleAdd({
                                        id:
                                            CBRStepTypes.CBR_INCIDENCIAS +
                                            '-' +
                                            nanoid(),
                                        type: CBRStepTypes.CBR_INCIDENCIAS,
                                        label: 'Incidencias',
                                        description:
                                            'Por favor indícanos las incidencias que desees reportar con su respectivo espacio.',
                                        unitLabel: 'Incidencia',
                                        addBtnLabel: 'Agregar Incidencia',
                                        spaceStep: {
                                            label: 'Espacio',
                                            description: '',
                                            required: true,
                                            showIcon: true,
                                            searchable: true,
                                            size: 1,
                                        },
                                        required: false,
                                        elementStep: {
                                            label: 'Elemento',
                                            description: '',
                                            required: true,
                                            showIcon: true,
                                            searchable: true,
                                            size: 1,
                                            subStep: {
                                                label: 'Elemento Hijo',
                                                description: '',
                                                required: true,
                                                showIcon: true,
                                                searchable: true,
                                                size: 1,
                                            },
                                        },
                                        commentStep: {
                                            label: 'Comentarios',
                                            description: '',
                                            required: false,
                                        },
                                    });
                                }}
                            >
                                <div className={styles.inputContainer}>
                                    <div className={styles.iconContainer}>
                                        <LeakIcon
                                            fill="var(--secondary)"
                                            style={{
                                                height: 'inherit',
                                                width: 'inherit',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Incidencias*
                                </div>
                            </div>
                        </div>
                    </div>
                </Popper>
            )}
        </React.Fragment>
    );
}

export default CBRStepMenu;

export function createCBRFormStep(
    type: CBRFormStepTypes,
    id = type + '-' + nanoid(10)
): CBRFormStep {
    const baseStep = {
        id,
    };

    switch (type) {
        case CBRFormStepTypes.CBR_FIRST_NAME:
            return {
                ...baseStep,
                type,
                label: 'Primer Nombre',
                description: '',
                required: true,
                size: 1,
            };
        case CBRFormStepTypes.CBR_MIDDLE_NAME:
            return {
                ...baseStep,
                type,
                label: 'Segundo Nombre',
                description: '',
                required: true,
                size: 1,
            };
        case CBRFormStepTypes.CBR_LAST_NAME:
            return {
                ...baseStep,
                type,
                label: 'Primer Apellido',
                description: '',
                required: true,
                size: 1,
            };
        case CBRFormStepTypes.CBR_SECOND_LAST_NAME:
            return {
                ...baseStep,
                type,
                label: 'Segundo Apellido',
                description: '',
                required: true,
                size: 1,
            };
        case CBRFormStepTypes.CBR_TIPO_DOC:
            return {
                ...baseStep,
                type,
                label: 'Tipo de documento',
                description: '',
                required: true,
                searchable: true,
                size: 1,
            };
        case CBRFormStepTypes.CBR_DOC:
            return {
                ...baseStep,
                type,
                label: 'Documento',
                description: '',
                required: true,
                showIcon: true,
                size: 1,
            };
        case CBRFormStepTypes.CBR_CEL:
            return {
                ...baseStep,
                type,
                label: 'Celular',
                description: '',
                required: true,
                size: 1,
            };
        case CBRFormStepTypes.CBR_PHONE:
            return {
                ...baseStep,
                type,
                label: 'Teléfono Fijo',
                description: '',
                required: true,
                size: 1,
            };
        case CBRFormStepTypes.CBR_EMAIL:
            return {
                ...baseStep,
                type,
                label: 'Correo',
                description: '',
                required: true,
                size: 1,
            };
        case CBRFormStepTypes.CBR_TIPO_SOLICITANTE:
            return {
                ...baseStep,
                type,
                label: 'Tipo de solicitante',
                description: '',
                required: true,
                showIcon: true,
                searchable: true,
                size: 1,
            };
        case CBRFormStepTypes.CBR_MACRO_PROYECTO:
            return {
                ...baseStep,
                type,
                label: 'Macro Proyecto',
                description: '',
                required: false,
                showIcon: true,
                searchable: true,
                steps: [],
                size: 1,
                maxSize: 1,
            };
        case CBRFormStepTypes.CBR_PROYECTO:
            return {
                ...baseStep,
                type,
                label: 'Proyecto',
                description: '',
                required: true,
                showIcon: true,
                searchable: true,
                steps: [],
                size: 1,
                maxSize: 1,
            };
        case CBRFormStepTypes.CBR_INMUEBLE:
            return {
                ...baseStep,
                type,
                label: 'Inmueble',
                description: '',
                required: true,
                showIcon: true,
                searchable: true,
                size: 1,
            };
        case CBRFormStepTypes.CBR_TIPO_ESPACIO:
            return {
                ...baseStep,
                type,
                label: 'Tipo de Espacio',
                description: '',
                required: true,
                showIcon: true,
                searchable: true,
                size: 1,
            };
        case CBRFormStepTypes.CBR_LOCATIVAS:
            return {
                ...baseStep,
                type,
                subStep: null,
                label: 'Elemento',
                description: '',
                required: true,
                showIcon: true,
                searchable: true,
                size: 1,
            };
        default:
            throw Error();
    }
}
