import React from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../@Types/State';
import { RootState } from '../../utils/_store';
import styles from './StatePill.module.css';

interface StatePillProps {
    idState: string | undefined;
    state?: State;
    hideLabel?: boolean;
}

function StatePill({ idState, state, hideLabel }: StatePillProps): JSX.Element {
    const siteInfo = useSelector((state: RootState) => state.site);
    const states = siteInfo.projects[siteInfo.idProject ?? '']?.states;

    if (state) {
        return (
            <div className={styles.statePill} title={'Estado: ' + state.label}>
                <div className={styles.statePillLbl}>
                    {!hideLabel && <span>Estado:</span>}
                    <div
                        className={styles.stateValueSpan}
                        style={{ marginLeft: hideLabel ? 0 : 4 }}
                    >
                        &#x200E;{state.label ?? 'En Proceso'}&#x200E;
                    </div>
                </div>
            </div>
        );
    } else if (states) {
        const stateValue = states[idState ?? ''];
        if (stateValue) {
            return (
                <div
                    className={styles.statePill}
                    title={'Estado: ' + stateValue.label}
                >
                    <div className={styles.statePillLbl}>
                        {!hideLabel && <span>Estado:</span>}
                        <div
                            className={styles.stateValueSpan}
                            style={{ marginLeft: hideLabel ? 0 : 4 }}
                        >
                            {stateValue.label ?? 'En Proceso'}
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div></div>;
        }
    } else {
        return <div></div>;
    }
}

export default StatePill;
