import styles from './NavBackStep.module.css';
import { NavBackStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks';
import { CollectionStepTypes } from '../../../../../../constants/Conversations/ConversationStepTypes';
import RoundedConversationStepPicker from '../../../../../../shared/@Pickers/RoundedConversationStepPicker/RoundedConversationStepPicker';
import { updateStep } from '../../../../../../controllers/ConversationEditorController/ConversationEditorActions';

interface NavBackStepProps {
    step: NavBackStep;
}

function NavBackStepComponent({ step }: NavBackStepProps): JSX.Element {
    const previousSteps = useAppSelector(
        (state) =>
            state.conversationEditor.stepDependencies[step.id ?? '']
                .previousSteps
    );
    const dispatch = useAppDispatch();
    const editorSteps = useAppSelector(
        (state) => state.conversationEditor.steps
    );

    const allowedSteps = useMemo(() => {
        const allowedSteps: string[] = [];
        let found = false;
        let pending = [];
        for (const idStep of previousSteps) {
            const step = editorSteps[idStep];
            if (CollectionStepTypes.includes(step.type) || found) {
                if (!found && pending.length > 0) {
                    allowedSteps.push(...pending);
                    pending = [];
                }
                found = true;
                allowedSteps.push(idStep);
            } else if (!found) {
                pending.push(idStep);
            }
        }
        return allowedSteps;
    }, [previousSteps]);

    return (
        <div className={styles.container}>
            <div className={styles.pickerContainer}>
                <RoundedConversationStepPicker
                    containerMargin="0px"
                    stepOrder={allowedSteps}
                    label="Paso a devolverse"
                    value={step.idBackStep}
                    handleUpdate={(prevStep): void => {
                        if (!step) return;
                        dispatch(
                            updateStep({ ...step, idBackStep: prevStep.id })
                        );
                    }}
                />
            </div>
        </div>
    );
}

export default NavBackStepComponent;
