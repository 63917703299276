import { ClickAwayListener, Popper } from '@material-ui/core';
import styles from './AttachmentsMenu.module.css';
import AddRoundedIcon from '@material-ui/icons/AddRounded';

import {
    PayloadEditorFile,
    PayloadEditorStateTypes,
} from '../../../../controllers/PayloadEditorController/PayloadEditorTypes';
import FileComponent from '../../../FileComponent/FileComponent';
import {
    PayloadEditorActions,
    usePayloadEditorDispatch,
} from '../../../../controllers/PayloadEditorController/PayloadEditorSlice';
interface FileUploadMenuProps {
    handleClose: Function;
    handleAddFiles: Function;
    anchorRef: any;
    files: PayloadEditorFile[];
    zIndex?: number;
}

function AttachmentsMenu({
    files,
    anchorRef,
    handleClose,
    handleAddFiles,
    zIndex = 1301,
}: FileUploadMenuProps): JSX.Element {
    const dispatch = usePayloadEditorDispatch();
    return (
        <ClickAwayListener
            mouseEvent="onMouseDown"
            onClickAway={(e): void => {
                if (anchorRef.current?.contains(e.target)) {
                    handleAddFiles();
                } else {
                    handleClose();
                }
            }}
        >
            <Popper
                open={true}
                disablePortal
                anchorEl={anchorRef.current}
                placement={'bottom-start'}
                modifiers={{
                    preventOverflow: {
                        enabled: true,
                        priority: ['top', 'bottom', 'left', 'right'],
                        boundariesElement: 'viewport',
                    },
                }}
                style={{ zIndex }}
            >
                <div className={styles.container}>
                    <div className={styles.attachmentsContainer}>
                        <div className={styles.attachmentsLbl}>Adjuntos:</div>
                        <div
                            className={styles.addBtn}
                            onClick={(): void => handleAddFiles()}
                        >
                            <AddRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                    <div className={styles.filesContainer}>
                        {files.map((file, index) => (
                            <FileComponent
                                zIndex={zIndex + 1}
                                key={index}
                                file={file}
                                loadingOverride={
                                    file.state ===
                                    PayloadEditorStateTypes.UPLOADING
                                }
                                handleRemove={(): void => {
                                    dispatch(
                                        PayloadEditorActions.removeFile(file.id)
                                    );
                                }}
                                error={
                                    file.state === PayloadEditorStateTypes.ERROR
                                        ? file.message
                                        : undefined
                                }
                            />
                        ))}
                    </div>
                </div>
            </Popper>
        </ClickAwayListener>
    );
}
export default AttachmentsMenu;
