import { AnyAction } from '@reduxjs/toolkit';
import { takeLatest, call, put, fork, select } from 'redux-saga/effects';
import { RootState } from '../../utils/_store';
import { refreshClassifiers } from '../_SiteController/SiteActions';
import * as actions from './ClassifiersActions';
import { loadClassifiers, updateClassifier } from './ClassifiersService';
import VanillaToast from '../../shared/Toast/Toast';

/** Selector that returns the current ipProject */
const getIdProject = (state: RootState): string | undefined | null =>
    state.site.idProject;

/**
 * Function called when the classifiers should be rendered(reset, refresh or search)
 * @param action of type Search, refresh or search
 */
function* getClassifiers(): any {
    try {
        const idProject = yield select(getIdProject);
        const pageInfo = yield select((state) => state.classifiersPage);
        let result = yield call(
            loadClassifiers,
            idProject,
            pageInfo.orderBy,
            pageInfo.search
        );
        yield put(actions.getSuccess(result));
    } catch (error) {
        //TODO handle errors
        console.error(error);
    }
}

/**
 * Function called when an classifiers's value is updated optimistically to patch the updates to the server asyncly
 * @param action of type UPDATE
 */
function* putClassifier(action: AnyAction): any {
    if (actions.updateElement.match(action)) {
        try {
            const idProject = yield select(getIdProject);
            const payload: Record<string, unknown> = {};
            payload[action.payload.field] = action.payload.value;
            yield call(
                updateClassifier,
                idProject,
                action.payload.object._id,
                payload
            );
            yield put(refreshClassifiers());
        } catch (error) {
            VanillaToast.create({
                title: 'Error al actualizar el clasificador',
                text: error,
                type: 'error',
                timeout: 5000,
            });
            yield put(actions.updateFailed(action.payload.object));
            yield put(refreshClassifiers());
        }
    }
}

function* watchGetClassifiersRequest(): any {
    yield takeLatest(
        [actions.Types.RESET, actions.Types.REFRESH, actions.Types.SEARCH],
        getClassifiers
    );
}

function* watchUpdateClassifier(): any {
    yield takeLatest([actions.Types.UPDATE], putClassifier);
}

export default [fork(watchGetClassifiersRequest), fork(watchUpdateClassifier)];
