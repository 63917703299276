import React from 'react';
import { InfoAudioStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import InformativeTypePicker from '../InformativeTypePicker/InformativeTypePicker';
import InformativeFileComponent from '../InformativeFilePicker/InformativeFilePicker';

interface InfoAudioStepProps {
    step: InfoAudioStep;
}

function InfoAudioStepComponent({ step }: InfoAudioStepProps): JSX.Element {
    return (
        <React.Fragment>
            <InformativeTypePicker step={step} />
            <InformativeFileComponent
                idStep={step.id}
                file={step.file}
                type="STICKER"
                types={['image/jpeg', 'image/png', 'image/webp']}
                inputs={'image/*'}
            />
        </React.Fragment>
    );
}

export default InfoAudioStepComponent;
