import styles from './NameStep.module.css';
import RoundedTextField from '../../../../../shared/RoundedTextField/RoundedTextField';
import { GenericStepProps } from '../../../../../shared/GenericFormEditor/Step/Step';
import { Name } from '../../../../../@Types/EntityTypes/EntityProperty';
import { GLocation } from '../../../../../@Types/FormTypes/LocationTypes';
import React from 'react';
function NameStep<
    StepType extends Name,
    UniqueType,
    LocationType extends GLocation
>({
    editing,
    step,
    updateStep,
    level,
}: GenericStepProps<StepType, UniqueType, LocationType>): JSX.Element {
    if (editing) {
        return (
            <div className={styles.editingContainer}>
                <RoundedTextField
                    label="Campo"
                    style={{ marginBottom: 15 }}
                    value={step.label ?? ''}
                    onChange={(e): void => {
                        updateStep({ ...step, label: e.target.value });
                    }}
                    fontSize="24px"
                    fontWeight={400}
                ></RoundedTextField>
                <RoundedTextField
                    label="Descripción"
                    value={step.description ?? ''}
                    onChange={(e): void => {
                        updateStep({ ...step, description: e.target.value });
                    }}
                    multiline
                    minRows={3}
                ></RoundedTextField>
            </div>
        );
    } else {
        return (
            <div
                className={styles.container}
                style={{ width: level === 0 ? '900px' : '100%' }}
            >
                <div className={styles.nameLbl}>({step.label})</div>
                {step.description && (
                    <p
                        className={styles.descriptionPar}
                        style={{
                            margin: step.label ? '10px 0px' : '0px 0px 5px 0px',
                        }}
                    >
                        {step.description}
                    </p>
                )}
            </div>
        );
    }
}

export default React.memo(NameStep, (prev, next) => {
    const { step, stepEqualityChecker } = prev;
    const { step: newStep } = next;
    if (
        step.label !== newStep.label ||
        step.description !== newStep.description
    ) {
        return false;
    }

    return stepEqualityChecker?.(prev, next) ?? true;
}) as typeof NameStep;
