import { format } from 'date-fns';
import { getLocale } from '..';

/** Returns the diff in milliseconds between midnight and the time of the date given. */
export function getMsSinceMidnight(d: any): number {
    var e = new Date(d);
    return d - e.setHours(0, 0, 0, 0);
}

/**
 * Calcs the date given the current locale
 * @param the date to calc
 * @returns localestring of an action
 */
export const calcDate = (date: Date, xsm?: boolean): string => {
    try {
        return format(date, xsm ? 'P' : 'Pp', {
            locale: getLocale(),
        });
    } catch (error) {
        return '';
    }
};
