import { Entity, EntityValue } from '../../../@Types/@Types';
import { EntityValueFilters } from '../../../controllers/EntityValuesController/EntityValuesReducer';

import {
    loadEntityValues,
    loadEntityValuesById,
} from '../../../controllers/EntityValuesController/EntityValuesService';
import Avatar from '../../Avatar/Avatar';
import EntityIcon from '../../SmartIcons/EntityIcon';
import GenericMenu, { GenericMenuProps } from '../GenericMenu/GenericMenu';

function EntityValuesMenu({
    entity,
    filters,
    ...props
}: Omit<
    GenericMenuProps<EntityValue, string>,
    'calcLbl' | 'loadInitialType' | 'loadElements' | 'label'
> & { entity: Entity; filters?: EntityValueFilters }): JSX.Element {
    return (
        <GenericMenu<EntityValue, string>
            {...props}
            label={entity.name}
            pluralLabel={entity.pluralName}
            calcLbl={(entityValue): string =>
                entityValue.values[entity.idLabelStep] ?? entity.name
            }
            loadInitialType={async (ids): Promise<EntityValue[]> =>
                await loadEntityValuesById(entity._id, ids)
            }
            calcIcon={(entityValue): JSX.Element => (
                <Avatar
                    lbl={
                        entityValue.values?.[entity.idLabelStep] ?? entity.name
                    }
                    size={30}
                    fontSize={30}
                >
                    <EntityIcon
                        idEntity={entity._id}
                        fill="var(--primary1)"
                        style={{
                            height: 21,
                            width: 21,
                        }}
                    />
                </Avatar>
            )}
            loadElements={async (
                page: number,
                pageSize: number,
                search: string | undefined
            ): Promise<EntityValue[]> => {
                return await loadEntityValues(
                    entity,
                    page,
                    pageSize,
                    filters,
                    undefined,
                    search
                );
            }}
        />
    );
}
export default EntityValuesMenu;
