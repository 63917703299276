import { makeStyles, Icon } from '@material-ui/core';
import { Area, Project } from '../../../@Types/@Types';
import { loadAreas } from '../../../controllers/AreasController/AreasService';
import ApartmentRoundedIcon from '@material-ui/icons/ApartmentRounded';

import RoundedGenericPicker, {
    RoundedGenericPickerProps,
} from '../RoundedGenericPicker/RoundedGenericPicker';
import FlowTypes from '../../../constants/Flows/FlowTypes';
import { useCurrentProject } from '../../../hooks';

export type FlowElement =
    | (Project & { type: FlowTypes.PROJECT })
    | (Area & { type: FlowTypes.AREA });

const loadFlowElements = async (
    project: Project,
    search: string | undefined
): Promise<FlowElement[]> => {
    const elements: FlowElement[] = [];
    let found = search === undefined;

    if (search) {
        const regex = new RegExp(search, 'gi');
        found = regex.test(project.name);
    }

    if (found) {
        elements.push({ ...project, type: FlowTypes.PROJECT });
    }

    const areaResp = await loadAreas(project._id, undefined, search);
    const areas = Object.values(areaResp.elements);
    for (const area of areas) {
        let found = search === undefined;

        if (search) {
            const regex = new RegExp(search, 'gi');
            found = regex.test(area.name);
        }
        if (found) {
            elements.push({ ...area, type: FlowTypes.AREA });
        }
    }
    return elements;
};

function RoundedFlowPicker({
    label = 'Flujo',
    ...props
}: Omit<
    RoundedGenericPickerProps<FlowElement>,
    'calcLbl' | 'loadInitialType' | 'loadElements' | 'elementLabel'
>): JSX.Element {
    const project = useCurrentProject();

    if (!project) {
        return <div></div>;
    }

    return (
        <RoundedGenericPicker<FlowElement>
            {...props}
            IconComponent={FlowIcon}
            label={label}
            elementLabel="Flujo"
            calcLbl={(flowElement): string => {
                if (flowElement.type === FlowTypes.PROJECT) {
                    return flowElement.name + ' (Proyecto)';
                } else {
                    return flowElement.name;
                }
            }}
            calcIcon={(flowElement): JSX.Element => {
                if (flowElement.type === FlowTypes.PROJECT) {
                    return (
                        <img
                            style={{
                                display: 'flex',
                                width: 24,
                                marginRight: 6,
                                height: 24,
                            }}
                            src="/media/icons/blueflowIcon.svg"
                        />
                    );
                } else {
                    return (
                        <div
                            style={{
                                width: 27,
                                height: 30,
                                fontSize: 24,
                                paddingLeft: 3,
                                color: 'var(--secondary)',
                            }}
                        >
                            <ApartmentRoundedIcon fontSize="inherit" />
                        </div>
                    );
                }
            }}
            loadInitialType={async (ids: string[]): Promise<FlowElement[]> => {
                const elements: FlowElement[] = [];
                const flowElements = await loadFlowElements(project, undefined);
                for (const id of ids) {
                    elements.push(
                        flowElements.find(
                            (element) => element.idFlow === id
                        ) as FlowElement
                    );
                }
                return elements;
            }}
            loadElements={async (
                page: number,
                _pageSize: number,
                search: string | undefined
            ): Promise<FlowElement[]> => {
                if (page !== 1) return [];
                return await loadFlowElements(project, search);
            }}
        />
    );
}
export default RoundedFlowPicker;

function FlowIcon(props: any): JSX.Element {
    const useStyles = makeStyles({
        iconRoot: {
            position: 'absolute',
            right: '7px',
        },
        imageIcon: {
            height: 23,
            width: 23,
        },
    });
    const classes = useStyles();
    return (
        <Icon classes={{ root: classes.iconRoot }} className={props.className}>
            <img
                className={classes.imageIcon}
                style={{ display: 'flex', height: 'inherit', width: 'inherit' }}
                src="/media/icons/blueflowIcon.svg"
            />
        </Icon>
    );
}
