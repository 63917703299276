import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './utils/reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import store from './utils/_store';
import './styles/index.css';
import './styles/standard-layout.css';
import './styles/standard-dialog.css';
import './styles/flow-dnd.css';
export const localeMap: any = {
    en: enLocale,
    'en-US': enLocale,
    es: esLocale,
};

export const getLocale = (): any => {
    return (
        // localeMap[
        //     navigator.languages && navigator.languages.length
        //         ? navigator.languages[0]
        //         : (navigator as any).userLanguage ||
        //           navigator.language ||
        //           (navigator as any).browserLanguage
        // ] ?? localeMap.es
        localeMap.es
    );
};
// import { createTheme, ThemeProvider } from '@material-ui/core';

//TODO: poner el mismo font en todo quando haya VRT para revisar los daños

// const muiTheme = createTheme({
//     typography: {
//         fontFamily: 'Nunito',
//     },
// });

ReactDOM.render(
    <Provider store={store}>
        <Router>
            {/* <ThemeProvider theme={muiTheme}> */}
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale()}>
                <App />
            </MuiPickersUtilsProvider>
            {/* </ThemeProvider> */}
        </Router>
    </Provider>,
    document.getElementById('root')
);

// eslint-disable-next-line no-console
reportWebVitals(console.log); // TODO: Learn more: https://bit.ly/CRA-vitals
