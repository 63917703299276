import { ClienteInfo360 } from '../../../types';
import styles from '../ClientInfo.module.css';
import React from 'react';
import { empStrOrUndToNA } from '../../Utils/otherFunctions';

interface BaseInfoProps {
    clientInfo: ClienteInfo360;
}

export const BaseInfo: React.FC<BaseInfoProps> = ({ clientInfo }) => {
    return (
        <div className={styles.baseInfo}>
            <div className={styles.row}>
                <div className={styles.label}>Nombre:</div>
                <div className={styles.value}>
                    {empStrOrUndToNA(clientInfo.datosPersonales.nombreCompleto)}
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.label}>Teléfono:</div>
                <div className={styles.value}>
                    {empStrOrUndToNA(clientInfo.datosContacto.telefono)}
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.label}>Celular:</div>
                <div className={styles.value}>
                    {empStrOrUndToNA(clientInfo.datosContacto.celular)}
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.label}>Correo:</div>
                <div className={styles.value}>
                    {empStrOrUndToNA(clientInfo.datosContacto.correo)}
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.label}>Ciudad:</div>
                <div className={styles.value}>
                    {empStrOrUndToNA(clientInfo.residenciaActual.ciudad)}
                </div>
            </div>
        </div>
    );
};
