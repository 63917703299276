import { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { FormControl } from '@material-ui/core';
// import Chip from '@material-ui/core/Chip'; -- Just in case we decide to use custom chips

interface StyleProps {
    /** The current text color of the label */
    textColor?: string;
    /** The color of the outline when selected and hovered on */
    focusColor?: string;
    /** The color of the outline when it is not selected */
    outlineColor?: string;
    /** The color of the error to display */
    errorColor?: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const useTextfieldStyles = (props: StyleProps): Function =>
    makeStyles(() => ({
        root: {
            '& input': {
                minWidth: '70px !important',
            },
            '& .Erk-MuiOutlinedInput-root:hover .Erk-MuiOutlinedInput-notchedOutline':
                {
                    borderColor: props.focusColor,
                },
            '& .Erk-MuiOutlinedInput-notchedOutline': {
                borderColor: props.outlineColor,
            },
            '& .Erk-MuiOutlinedInput-root': {
                borderRadius: 10,
                '&.Mui-focused fieldset': {
                    borderColor: props.focusColor,
                },
            },
            '& .Mui-error': {
                color: props.errorColor,
                backgroundColor: props.errorColor,
            },
            '&.Mui-error .Erk-MuiOutlinedInput-notchedOutline': {
                borderColor: props.outlineColor,
            },
            '& label.Mui-focused': {
                color: props.focusColor,
            },
        },
    }));

interface RoundedMultiSelectProps extends StyleProps {
    /** String to display in the label */
    label?: string;
    /** Text to display when nothing is selected */
    placeholder?: string;
    /** Minimum width in px of the component */
    minWidth?: number;
    /** the margin around the selector */
    containerMargin?: string;
    /** Possible Options to select from */
    options?: any[];
    /** Currently selected values */
    values?: any[];
    /** Function called when value is selected or deleted */
    handleUpdate: Function;
    /** If the value is required */
    required?: boolean;
    /** Max number of tags to display when componen is not focused */
    maxTags?: number;
    /** If the input has an error */
    error?: boolean;
    /** If the selector should occupy the width of the parent or grow as the content grows */
    fullWidth?: boolean;
}
export default function RoundedMultiSelect({
    error,
    minWidth,
    required,
    maxTags = 3,
    values = [],
    options = [],
    handleUpdate,
    fullWidth = true,
    label = 'Valores',
    containerMargin = '0px',
    placeholder = 'Valores',
    errorColor = 'var(--error)',
    focusColor = 'var(--secondary)',
    outlineColor = 'var(--outlineGrey)',
    ...others
}: RoundedMultiSelectProps): JSX.Element {
    const classes = useStyles();
    const textFieldStyles = useTextfieldStyles({
        focusColor,
        outlineColor,
        errorColor,
    })();
    const [inputValue, setInputValue] = useState('');
    return (
        <FormControl
            variant="outlined"
            className={
                classes.formControl + (error ? ' Mui-error-override' : '')
            }
            size="small"
            style={
                minWidth !== undefined
                    ? { minWidth, outlineColor, margin: containerMargin }
                    : {
                          margin: containerMargin,
                      }
            }
            fullWidth={fullWidth}
            required={required}
            error={error}
        >
            <Autocomplete
                {...others}
                multiple
                limitTags={maxTags}
                size="small"
                options={options}
                getOptionLabel={(option): string => option}
                value={values}
                inputValue={inputValue}
                onInputChange={(_, newInputValue): void => {
                    setInputValue(newInputValue);
                }}
                freeSolo
                // renderTags={(value, getTagProps) =>  -- Just in case we decide to use custom chips
                //     value.map((option, index) => (
                //         <Chip
                //             key={index}
                //             variant="outlined"
                //             label={option.title}
                //             size="small"
                //             {...getTagProps({ index })}
                //         />
                //     ))
                // }
                onChange={(event, values): void => {
                    handleUpdate(values);
                }}
                onBlur={(): void => {
                    if (inputValue) {
                        setInputValue('');
                        handleUpdate([...values, inputValue]);
                    }
                }}
                renderInput={(params): JSX.Element => (
                    <TextField
                        className={textFieldStyles.root}
                        {...params}
                        variant="outlined"
                        label={label}
                        placeholder={placeholder}
                    />
                )}
            />
        </FormControl>
    );
}
