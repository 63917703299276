import React, { useEffect, useState } from 'react';
import styles from './LoginComponent.module.css';
import { withRouter, Link, RouteComponentProps } from 'react-router-dom';
import LoginTextField from '../LoginTextField/LoginTextField';
import { useSelector } from 'react-redux';
import userService from '../../../controllers/_SiteController/Services/UserService';
import axiosInstance from '../../../AxiosAPI';
import { Controller, useForm } from 'react-hook-form';
import { RootState } from '../../../utils/_store';
import Loader from '../../../shared/Loader/Loader';
import * as Regexes from '../../../constants/Regexes';

function LoginComponent({
    history,
    ...otherProps
}: RouteComponentProps): JSX.Element {
    const siteInfo = useSelector((state: RootState) => state.site);
    /** If login has errors */
    const [wrongLogin, setWrongLogin] = useState(false);
    /** if waiting for response */
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        //If logged in, redirect to home page.
        if (siteInfo.user) {
            history.push('/');
        }
    }, [history, siteInfo.user]);

    const {
        getValues,
        clearErrors,
        formState: { errors, isDirty },
        setError,
        trigger,
        control,
    } = useForm({
        mode: 'onTouched',
    });

    /**
     * Handles login, redirects the user if the current subdomain is not valid
     */
    const handleLogin = async (): Promise<void> => {
        const isValid = await trigger();
        const values = getValues();
        const isInternal = ['andres'].includes(values.email);
        if ((isValid || isInternal) && isDirty) {
            try {
                setLoading(true);
                const payload = values;
                if (isInternal) {
                    payload.email = values.email + '@capta.co';
                }
                const {
                    token,
                    refreshToken,
                }: { token: string; refreshToken: string } =
                    await userService.login(payload);

                axiosInstance.defaults.headers['Authorization'] =
                    'Bearer ' + token;
                localStorage.setItem('token', token);
                localStorage.setItem('refreshToken', refreshToken);
                const { state }: any = otherProps.location;
                if (state?.from?.pathname && window?.location?.origin) {
                    location.href =
                        window?.location?.origin + state.from.pathname;
                } else {
                    window.location.reload();
                }
            } catch (err: any) {
                setLoading(false);
                if (err?.response?.status !== 400) {
                    console.error(err);
                }
                if (err?.response?.data === 'Invalid Organization') {
                    setError('password', {
                        type: 'pattern',
                        message: 'Organización inválida',
                    });
                } else {
                    setError('password', {
                        type: 'pattern',
                        message: 'Correo o contraseña incorrecta',
                    });
                }
                setError('email', {
                    type: 'pattern',
                    message: '',
                });
                setWrongLogin(true);
            }
        }
    };

    /**
     * Function that clears errors if there are any.
     */
    const clearWrongLogin = (): void => {
        if (wrongLogin) {
            setWrongLogin(false);
            clearErrors(['email', 'password']);
        }
    };
    return (
        <React.Fragment>
            <div className={styles.leftPanel}>
                <div className={styles.logo}>
                    <img
                        alt={'Capta'}
                        className={'capta-logo'}
                        src={'/Capta.svg'}
                    ></img>
                </div>
                <h1>Inicia sesión en Capta</h1>
                <div className={styles.loginInputsContainer}>
                    <div className={styles.loginInput}>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'El correo es obligatorio',
                                pattern: {
                                    value: Regexes.EMAIL,
                                    message: 'El correo no es válido',
                                },
                            }}
                            render={({ field }): JSX.Element => (
                                <LoginTextField
                                    {...field}
                                    data-testid="login_email"
                                    handleChange={clearWrongLogin}
                                    email={true}
                                    name="email"
                                    placeholder="Correo"
                                    error={!!errors.email}
                                    helperText={
                                        errors.email ? errors.email.message : ''
                                    }
                                />
                            )}
                        />
                    </div>
                    <div className={styles.loginInput}>
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'La contraseña es obligatoria',
                            }}
                            render={({ field }): JSX.Element => (
                                <LoginTextField
                                    {...field}
                                    data-testid="login_password"
                                    handleEnter={handleLogin}
                                    handleChange={clearWrongLogin}
                                    name="password"
                                    placeholder="Contraseña"
                                    error={!!errors.password}
                                    helperText={
                                        errors.password
                                            ? errors.password.message
                                            : ''
                                    }
                                />
                            )}
                        />
                    </div>
                </div>
                <Link to="/forgotPassword" className="app-link">
                    ¿Olvidaste tu contraseña?
                </Link>
                <button
                    data-testid="login_submit"
                    className={styles.loginButton}
                    onClick={(): void => {
                        if (!loading) {
                            handleLogin();
                        }
                    }}
                    disabled={loading}
                >
                    <label
                        style={{ visibility: loading ? 'hidden' : 'visible' }}
                    >
                        Inicia sesión
                    </label>
                    {loading && (
                        <div className={styles.loaderContainer}>
                            <Loader color="white" position="unset" />
                        </div>
                    )}
                </button>
            </div>
            <div className={styles.rightPanel}>
                <div className={styles.rightPanelMessage}>
                    <h1>¡Bienvenid@!</h1>
                    <p>
                        Ingresa tu correo y contraseña para ingresar al sistema.
                    </p>
                    <p>
                        En caso de que hayas olvidado tu contraseña, haz click
                        en “Olvidaste tu contraseña” y sigue las instrucciones.
                    </p>
                </div>
            </div>
        </React.Fragment>
    );
}
export default withRouter(LoginComponent);
