import React, { useState } from 'react';
import styles from './Prodesa360Button.module.css';
import Dialog from '../../shared/Dialog/Dialog';
import Icon360 from './360Icon';
import { Info360 } from './Info360/Info360';
import { Ticket } from '../../@Types/TicketTypes/Ticket';

interface Prodesa360ButtonProps {
    ticket?: Ticket;
}

function Prodesa360Button({ ticket }: Prodesa360ButtonProps): JSX.Element {
    const [showMenu, setShowMenu] = useState(false);
    return (
        <React.Fragment>
            <Dialog
                open={showMenu}
                onClose={(): void => setShowMenu(false)}
                maxWidth={'100vw'}
                maxHeight={'100vh'}
                transparent={true}
            >
                <Info360 ticket={ticket} />
            </Dialog>
            <div
                className={styles.container}
                onClick={(): void => setShowMenu(true)}
            >
                <div className={styles.button}>
                    <div className={styles.iconContainer}>
                        <Icon360
                            fill={'var(--accent)'}
                            style={{
                                marginLeft: -1,
                                width: 23,
                                height: 23,
                            }}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Prodesa360Button;
