import styles from './AYFStepMenu.module.css';
import stepMenuStyles from '../StepMenu.module.css';
import AYFStepTypes from '../../../../../../constants/Construction/AYFFormStepTypes';
import { useRef, useState } from 'react';
import React from 'react';
import { Popper } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { addStep } from '../../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import { StepLocation } from '../../../../../../controllers/ConversationEditorController/ConversationEditorReducer';
import { createAYFConversationStep } from './AYFStepFunctions';
import DescriptionIcon from '@material-ui/icons/Description';

interface AYFStepMenuProps {
    location: StepLocation;
    handleClose: Function;
}
function AYFStepMenu({ location, handleClose }: AYFStepMenuProps): JSX.Element {
    const dispatch = useDispatch();
    const [hovering, setHovering] = useState(false);
    const [hoveringMenu, setHoveringMenu] = useState(false);

    const btnRef = useRef<HTMLDivElement>(null);

    const handleAdd = (type: AYFStepTypes): void => {
        dispatch(
            addStep({ step: createAYFConversationStep(type) as any, location })
        );
    };
    return (
        <React.Fragment>
            <div
                className={stepMenuStyles.option}
                onMouseEnter={(): void => {
                    setHovering(true);
                }}
                onMouseLeave={(): void => {
                    setHovering(false);
                }}
                ref={btnRef}
                style={{
                    backgroundColor:
                        hovering || hoveringMenu
                            ? ' var(--light-grey)'
                            : 'white',
                }}
            >
                <div className={styles.ayfIcon}>
                    <img
                        className={styles.ayfImg}
                        src="/media/icons/sinco-ayf.svg"
                    />
                </div>
                <div className={stepMenuStyles.optionLabel}>
                    A&F - Proveedores
                </div>
            </div>
            {(hovering || hoveringMenu) && (
                <Popper
                    open={true}
                    anchorEl={btnRef.current}
                    placement={'right-end'}
                    modifiers={{
                        preventOverflow: {
                            enabled: true,
                            priority: ['right', 'left'],
                            boundariesElement: 'viewport',
                        },
                    }}
                    style={{ zIndex: 3 }}
                >
                    <div
                        className={styles.container}
                        onMouseEnter={(): void => {
                            setHoveringMenu(true);
                        }}
                        onMouseLeave={(): void => {
                            setHoveringMenu(false);
                        }}
                    >
                        <div className={styles.bottomLeftPadding}></div>
                        <div>
                            <div
                                className={stepMenuStyles.option}
                                onClick={(): void => {
                                    handleAdd(AYFStepTypes.AYF_YEAR);
                                    handleAdd(AYFStepTypes.AYF_START_MONTH);
                                    handleAdd(AYFStepTypes.AYF_END_MONTH);
                                    handleClose();
                                }}
                            >
                                <div className={styles.documentContainer}>
                                    <DescriptionIcon
                                        fill="var(--secondary)"
                                        style={{
                                            height: 'inherit',
                                            width: 'inherit',
                                        }}
                                    />
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Certificados
                                </div>
                            </div>
                            <div
                                className={stepMenuStyles.option}
                                onClick={(): void => {
                                    handleAdd(AYFStepTypes.AYF_ICA_CITY);
                                    handleClose();
                                }}
                            >
                                <div className={styles.documentContainer}>
                                    <DescriptionIcon
                                        fill="var(--secondary)"
                                        style={{
                                            height: 'inherit',
                                            width: 'inherit',
                                        }}
                                    />
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Ciudad ICA
                                </div>
                            </div>
                            <div
                                className={stepMenuStyles.option}
                                onClick={(): void => {
                                    handleAdd(AYFStepTypes.AYF_BILL_NUM);
                                    handleAdd(AYFStepTypes.AYF_YEAR);
                                    handleAdd(AYFStepTypes.AYF_START_MONTH);
                                    handleAdd(AYFStepTypes.AYF_END_MONTH);
                                    handleClose();
                                }}
                            >
                                <div className={styles.documentContainer}>
                                    <DescriptionIcon
                                        fill="var(--secondary)"
                                        style={{
                                            height: 'inherit',
                                            width: 'inherit',
                                        }}
                                    />
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Extracto
                                </div>
                            </div>
                            <div
                                className={stepMenuStyles.option}
                                onClick={(): void => {
                                    handleAdd(AYFStepTypes.AYF_CUT_OFF_DATE);
                                    handleClose();
                                }}
                            >
                                <div className={styles.documentContainer}>
                                    <DescriptionIcon
                                        fill="var(--secondary)"
                                        style={{
                                            height: 'inherit',
                                            width: 'inherit',
                                        }}
                                    />
                                </div>
                                <div className={stepMenuStyles.optionLabel}>
                                    Cuentas Por Pagar
                                </div>
                            </div>
                        </div>
                    </div>
                </Popper>
            )}
        </React.Fragment>
    );
}

export default AYFStepMenu;
