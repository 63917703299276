import styles from './EntityValueDetail.module.css';
import React from 'react';
import { EntityValue } from '../../../@Types/EntityValue';
import EntityRenderer from '../../../shared/EntityRenderer/EntityRenderer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import { refreshCurrent } from '../../../controllers/EntityValuesController/EntityValuesActions';
import { updateEntityValue } from '../../../controllers/EntityValuesController/EntityValuesService';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import SincoQueries from '../../../custom/Sinco/Queries/Queries';
import { EntityPermissions } from '../../../constants/Permissions';
import { checkEntityPerm } from '../../../utils/PermissionsFunctions';
import { Entity } from '../../../@Types/@Types';
import EntityValueClients from './EntityValueClients/EntityValueClients';
import BidirectionalComponent from './BidirectionalComponent/BidirectionalComponent';
interface EntityValueDetailProps {
    /** The the current Entity */
    entity: Entity;
    /** The selected Entry */
    selectedElement?: EntityValue;
    /** Function that handles deleting the current classifier */
    handleClickDelete?: Function;
    /** Function that handles when the user wants to clone the current entityValue */
    handleCloneEntityValue?: (entityValue: EntityValue) => void;
}
function EntityValueDetail({
    entity,
    selectedElement,
    handleClickDelete,
    handleCloneEntityValue,
}: EntityValueDetailProps): JSX.Element {
    const user = useSelector((state: RootState) => state.site.user);
    const organization = useSelector(
        (state: RootState) => state.site.organization
    );
    const clientEntity = useSelector(
        (state: RootState) =>
            state.site.entities[state.site.organization?.idClientEntity ?? '']
    );

    const dispatch = useDispatch();

    if (!selectedElement) {
        return <div></div>;
    }
    if (!entity) {
        return <div></div>;
    }
    return (
        <React.Fragment>
            <div className={styles.container} key={selectedElement._id}>
                <div
                    className={styles.entityContainer}
                    style={{
                        width:
                            (entity.size.blockSize + entity.size.spacingSize) *
                            entity.size.blockNum,
                    }}
                >
                    <EntityRenderer
                        entity={entity}
                        internal
                        customSend={async (update): Promise<void> => {
                            await updateEntityValue(
                                entity._id,
                                selectedElement._id,
                                update
                            );
                            dispatch(refreshCurrent());
                        }}
                        editable={checkEntityPerm(
                            user,
                            entity._id,
                            EntityPermissions.EDIT
                        )}
                        entityValue={selectedElement}
                        customNameBtns={
                            <React.Fragment>
                                {organization?.idOrganization === 'sinco' &&
                                    entity._id ===
                                        '6158bbad03ae6e2fc810c1aa' && (
                                        <SincoQueries
                                            company={selectedElement}
                                        />
                                    )}
                                {handleCloneEntityValue !== undefined && (
                                    <div
                                        className={styles.copyBtn + ' grey-btn'}
                                        onClick={(): void => {
                                            handleCloneEntityValue(
                                                selectedElement
                                            );
                                        }}
                                        title="Copiar"
                                    >
                                        <FileCopyRoundedIcon
                                            style={{ fill: 'black' }}
                                            fontSize="inherit"
                                        />
                                    </div>
                                )}
                                {handleClickDelete !== undefined && (
                                    <div
                                        className={styles.delBtn + ' grey-btn'}
                                        onClick={(): void => {
                                            handleClickDelete(selectedElement);
                                        }}
                                    >
                                        <DeleteRoundedIcon
                                            style={{ fill: 'black' }}
                                            fontSize="inherit"
                                        />
                                    </div>
                                )}
                            </React.Fragment>
                        }
                    />
                </div>
                {entity.bidirectionals
                    ?.filter(
                        (idEntity) => idEntity !== organization?.idClientEntity
                    )
                    .map((idEntity, index) => (
                        <BidirectionalComponent
                            entity={entity}
                            key={index}
                            idBidirectional={idEntity}
                            entityValue={selectedElement}
                        />
                    ))}
                {clientEntity &&
                    clientEntity.relationships.find(
                        (relationship) => relationship.idEntity === entity._id
                    ) && (
                        <EntityValueClients
                            entity={entity}
                            entityValue={selectedElement}
                        />
                    )}
            </div>
        </React.Fragment>
    );
}
export default EntityValueDetail;
