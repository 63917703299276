import { EditorState, convertFromRaw } from 'draft-js';
import React from 'react';
import { CreatableStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import { LocationTypes } from '../../../../../../constants/Conversations/ConversationStepTypes';
import DraftRenderer from '../../../../../../shared/DraftRenderer/DraftRenderer';
import AddStep from '../../../AddStep/AddStep';
import ClientReply from '../../ClientReply/ClientReply';
import StepComponent, { StepComponentProps } from '../../Step';
import StepContainer from '../../StepContainer/StepContainer';
import styles from './CreatableStep.module.css';
interface CreatableStepComponentProps extends StepComponentProps {
    step: CreatableStep;
    controlled?: boolean;
}
function CreatableStepComponent({
    step,
    path,
    controlled = false,
}: CreatableStepComponentProps): JSX.Element {
    return (
        <React.Fragment>
            <div className={styles.startContainer}>
                <div className={styles.separator}></div>
                <div className={styles.endingLbl}>Inicio: {step.name}</div>
                <div className={styles.endSeparator}></div>
            </div>
            {step.steps.map((idStep: string, index: number) => (
                <StepComponent
                    key={index}
                    idStep={idStep}
                    path={[
                        ...path,
                        {
                            idStep: step.id,
                            type: LocationTypes.CREATABLE,
                            indexStep: index,
                            label: step.name,
                            controlled,
                        },
                    ]}
                />
            ))}
            <AddStep
                location={{
                    idStep: step.id,
                    type: LocationTypes.CREATABLE,
                    indexStep: step.steps.length,
                    label: step.name,
                    controlled,
                }}
            />
            <StepContainer
                path={path}
                step={step as any}
                header={step.header}
                footer={step.footer}
                bottomComponent={
                    <div className={styles.btnsContainer}>
                        <div className={styles.messageBtn}>
                            {step.addBtnLabel}
                        </div>
                        <div className={styles.messageBtn}>
                            {step.endBtnLabel}
                        </div>
                    </div>
                }
                disabledBottom
            >
                <div className={styles.messageContainer}>
                    <DraftRenderer
                        editorState={EditorState.createWithContent(
                            convertFromRaw(step.message)
                        )}
                    />
                </div>
            </StepContainer>
            <ClientReply padding={10} title={step.name}>
                <div className={styles.replyContent}>
                    <div className={styles.replyBtn + ' noselect'}>
                        {step.endBtnLabel}
                    </div>
                </div>
            </ClientReply>
        </React.Fragment>
    );
}
export default CreatableStepComponent;
