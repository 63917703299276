import React, { useRef } from 'react';
import { Agent } from '../../../@Types/Agent';
import styles from '../../Agents/AgentDetail/AgentDetail.module.css';
import Avatar from '../../../shared/Avatar/Avatar';

interface AgentDetailProps {
    /** The selected Agent */
    selectedElement?: Agent;
    /** Function called when an agent's value needs updating */
    handleUpdate: Function;
    /** If the agents permissions should be shown */
}
function AgentDetail({ selectedElement }: AgentDetailProps): JSX.Element {
    if (!selectedElement) {
        return <div></div>;
    }
    const containerRef = useRef<HTMLDivElement>(null);

    return (
        <div
            className={styles.container}
            ref={containerRef}
            data-testid={'AgentDetail'}
        >
            <div className="flexbox">
                <div className={styles.photoContainer}>
                    <div className={styles.avatarContainer}>
                        <Avatar
                            img={selectedElement.img}
                            lbl={selectedElement.name}
                            size={100}
                            fontSize={60}
                        ></Avatar>
                    </div>
                    <div className={styles.nameContainer} data-testid={'name'}>
                        <label className={styles.nameLbl}>
                            {selectedElement.name}
                        </label>
                    </div>
                </div>
                <div className={styles.basicInfoContainer}>
                    <p className={styles.label}>Correo</p>
                    <div
                        className={styles.valueContainer}
                        data-testid={'email'}
                    >
                        <p className={styles.value}>{selectedElement.email}</p>
                    </div>
                    <p className={styles.label}>Cargo</p>
                    <div
                        className={styles.valueContainer}
                        data-testid={'jobTitle'}
                    >
                        <p className={styles.value}>
                            {selectedElement.jobTitle}
                        </p>
                    </div>
                    <p className={styles.label}>Area Organizacional</p>
                    <div
                        className={styles.valueContainer}
                        data-testid={'orgArea'}
                    >
                        <p className={styles.value}>
                            {selectedElement.orgArea ?? 'Sin Area'}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AgentDetail;
