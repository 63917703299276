import Dialog from '../../../../../../../shared/Dialog/Dialog';
import styles from './AssignAreaDialog.module.css';
import RoundedButton from '../../../../../../../shared/RoundedButton/RoundedButton';
import React from 'react';

interface AssignProps {
    /** Element being assignd */
    element: any;
    /** Function that handles the closing event */
    onClose: () => void;
    /** Function called when user decides to assign */
    onConfirm: Function;
    /** Name of the element to Assign */
    elementName: string;
    /** Title of the dialog */
    title: string;
}

/**
 * Component that displays the confirm deletion can also be used to cancel an action.
 */
function Assign({
    element,
    onClose,
    onConfirm,
    elementName,
    title,
}: AssignProps): JSX.Element {
    return (
        <Dialog open={element !== null} onClose={onClose}>
            <InnerAssignDialog
                element={element}
                onClose={onClose}
                onConfirm={onConfirm}
                title={title}
                elementName={elementName}
            ></InnerAssignDialog>
        </Dialog>
    );
}
export default Assign;

interface InnerDialogProps {
    /** Element being assigned */
    element: any;
    /** Function that handles the closing event */
    onClose: () => void;
    /** Function called when user decides to assign an area */
    onConfirm: Function;
    /** Title of the dialog */
    title: string;
    /** Function that toggles the loader in the modal, inherited from the modal. */
    setLoading?: Function;
    /** if loader should be active */
    loading?: boolean;
    /** Name of the element to assign */
    elementName: string;
}

function InnerAssignDialog({
    element,
    onClose,
    onConfirm,
    title,
    setLoading,
    loading,
    elementName,
}: InnerDialogProps): JSX.Element {
    const renderMsg = (): JSX.Element => {
        return (
            <React.Fragment>
                <div className={styles.message}>
                    ¿Está seguro que desea asignarle este caso a
                    <span className={styles.nameSpan}>{elementName}</span>?
                </div>
                <div className={styles.btnContainer + ' center-anything'}>
                    <div className="button">
                        <RoundedButton
                            text="Cancelar"
                            backgroundColor="var(--greyBtn)"
                            onClick={(): void => {
                                onClose();
                            }}
                        />
                    </div>
                    <div className="button">
                        <RoundedButton
                            text={'Asignar'}
                            backgroundColor="var(--secondary)"
                            color="white"
                            onClick={(): void => {
                                onConfirm(element, setLoading);
                            }}
                            disabled={loading}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    return (
        <div className={styles.container + ' standard-dialog'}>
            <h3 className="center-anything">{title}</h3>
            {renderMsg()}
        </div>
    );
}
