import RoundedSelect from '../../RoundedSelect/RoundedSelect';
import { MenuItem } from '@material-ui/core';
import { getLocale } from '../../..';
import { format as DatefnsFormat } from 'date-fns';

interface DateFormatPickerProps {
    value: string | undefined;
    onChange: React.Dispatch<string>;
}

const DateFnsFormats = [
    'P',
    'p',
    'Pp',
    'PP',
    'PPp',
    'PPP',
    'PPPp',
    'PPPP',
    'PPPPp',
];

function DateFormatPicker({
    value,
    onChange,
}: DateFormatPickerProps): JSX.Element {
    return (
        <RoundedSelect
            containerMargin="0px"
            label="Formato de la Fecha"
            value={value}
            handleUpdate={(event): void =>
                onChange(event.target.value as string)
            }
        >
            {DateFnsFormats.map((format) => (
                <MenuItem key={format} value={format}>
                    {DatefnsFormat(new Date(2018, 9, 5, 14, 27), format, {
                        locale: getLocale(),
                    })}
                </MenuItem>
            ))}
        </RoundedSelect>
    );
}

export default DateFormatPicker;
