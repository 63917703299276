import styles from './ActivityTicketEntry.module.css';
import { Ticket } from '../../../../../@Types/TicketTypes/Ticket';
import FormRenderer from '../../../../../shared/FormRenderer/FormRenderer';
import { Form } from '../../../../../@Types/@Types';
import FormStepTypes from '../../../../../constants/FormStepTypes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../utils/_store';
import { useEffect, useMemo, useState } from 'react';
import { CreateAction } from '../../../../../@Types/Action';
import { loadCreateAction } from '../../../../../controllers/TicketsController/Actions/ActionsService';
import { createFormStep } from '../../../../Forms/StepMenu/StepMenuMapper';
import { MapperStyleTypes } from '@arquimedes.co/eureka-forms/dist/constants/FormStepTypes';
import { useCurrentProject } from '../../../../../hooks';

interface ActivityTicketEntryProps {
    ticket: Ticket;
    action?: CreateAction;
}
/**
 * Component rendered inside the postview dialog.
 */
function ActivityTicketEntryComponent({
    ticket,
    action,
}: ActivityTicketEntryProps): JSX.Element {
    const project = useCurrentProject();
    const entities = useSelector((state: RootState) => state.site.entities);
    const [createAction, setCreateAction] = useState<
        CreateAction | undefined | null
    >(action);

    const fetchAction = async (): Promise<void> => {
        if (project?._id && !createAction) {
            try {
                const response = await loadCreateAction(
                    project?._id,
                    ticket._id
                );
                setCreateAction(response);
            } catch (error) {
                setCreateAction(null);
            }
        }
    };

    useEffect(() => {
        fetchAction();
    }, []);
    const form = useMemo(() => {
        if (!createAction) return null;
        return {
            form: {
                steps: {
                    subject: {
                        id: 'subject',
                        type: FormStepTypes.TEXTINPUT,
                        label: 'Asunto',
                        description: '',
                        required: true,
                        size: 4,
                        isSubject: true,
                    },
                    content: {
                        id: 'content',
                        type: FormStepTypes.TEXTAREA,
                        label: 'Cuerpo',
                        description: '',
                        required: true,
                        hasTextEditor: true,
                        isPrimary: true,
                    },
                    adjuntos: {
                        id: 'adjuntos',
                        type: FormStepTypes.FILEUPLOAD,
                        label: 'Adjuntos',
                        description: '',
                        required: false,
                    },
                    ...Object.entries(project.values.values).reduce(
                        (a, [key, value]) => ({
                            ...a,
                            [key]: {
                                ...createFormStep(
                                    value.type,
                                    null,
                                    value.type ===
                                        FormStepTypes.ENTITYVALUEPICKER &&
                                        value.idEntity
                                        ? entities[value.idEntity]
                                        : undefined,
                                    key
                                ),
                                size: 2,
                            },
                        }),
                        {}
                    ),
                    ...project.concepts.reduce(
                        (a, concept) => ({
                            ...a,
                            [concept.id]: {
                                id: concept.id,
                                type: FormStepTypes.MAPPER,
                                label: concept.name,
                                description: '',
                                required: false,
                                unitLabel: '',
                                creatable: false,
                                style: MapperStyleTypes.PILL,
                                rootSteps: concept.rootSteps,
                                steps: concept.steps,
                            },
                        }),
                        {}
                    ),
                },
                size: baseSize,
            },
            values: {
                subject: ticket.subject,
                content: {
                    draft: ticket.original.draft,
                    value: ticket.original.text,
                },
                adjuntos: ticket.content.files,
                ...Object.entries(createAction.values).reduce(
                    (a, [key, value]) => ({
                        ...a,
                        [key]: value.value,
                    }),
                    {}
                ),
                ...createAction.concepts,
            },
            rootSteps: [
                'subject',
                'content',
                'adjuntos',
                ...project.values.order,
                ...project.concepts.map((concept) => concept.id),
            ],
        };
    }, [ticket, project, createAction]);
    if (!project || !form) return <div></div>;
    return (
        <div className={styles.container}>
            <div className={styles.containerLbl}>{ticket.entry.name}</div>
            <div
                className={styles.widgetContainer}
                style={{
                    width:
                        (baseSize.blockSize + baseSize.spacingSize) *
                            baseSize.blockNum +
                        40,
                }}
            >
                <FormRenderer
                    postview={true}
                    partial
                    form={form.form}
                    values={form.values}
                    rootSteps={form.rootSteps}
                />
            </div>
        </div>
    );
}

export default ActivityTicketEntryComponent;

const baseSize: Form['size'] = {
    blockNum: 4,
    blockSize: 210,
    spacingSize: 20,
};
