import { DraftEntityDataTypes } from '../../../../constants/Draft/DraftEntityDataTypes';
import Context from '../Context';
import { EntityContextGroup } from './EntityContext';
import EntityValuesContext from './EntityValuesContext';
// import ConceptContext from './ConceptContext';
import ProjectIcon from '../../../../Icons/ProjectIcon';
import { GenericContextProps } from '../TextEditorContext';
import { DraftContext } from '../../../../@Types/Draft/DraftContext';
import { useCurrentProject } from '../../../../hooks';
import EurekaElementMenuGroup from '../../../@Menus/ElementMenu/EurekaElementMenuGroup';
import GearsIcon from '../../../../Icons/GearsIcon';

function ProjectContexts({
    nested,
    context,
    ...props
}: GenericContextProps & {
    nested: boolean;
    context: DraftContext | undefined;
}): JSX.Element {
    const project = useCurrentProject();

    return (
        <>
            {(context?.ticket || context?.client) &&
                project?.entities
                    .filter((entity) => !nested || !entity.multiple)
                    .map((entity, index) =>
                        entity.multiple ? (
                            <EntityValuesContext
                                {...props}
                                key={index}
                                idEntity={entity.idEntity}
                                type={DraftEntityDataTypes.ENTITYVALUES}
                            />
                        ) : (
                            <EntityContextGroup
                                {...props}
                                key={index}
                                context={context}
                                idEntity={entity.idEntity}
                                type={DraftEntityDataTypes.ENTITYVALUES}
                            />
                        )
                    )}
            {/* {context?.ticket &&
                !nested &&
                project?.concepts.map((concept, index) => (
                    <ConceptContext key={index} {...props} concept={concept} />
                ))} */}
            {project && (
                <EurekaElementMenuGroup
                    {...props}
                    icon={ProjectIcon}
                    label={'Ambiente'}
                    renderElements={(): JSX.Element => {
                        return (
                            <>
                                <Context
                                    {...props}
                                    data={{
                                        type: DraftEntityDataTypes.PROJECT,
                                        property: 'NAME',
                                    }}
                                />
                                <Context
                                    {...props}
                                    data={{
                                        type: DraftEntityDataTypes.PROJECT,
                                        property: 'DESCRIPTION',
                                    }}
                                />
                                {context?.ticket &&
                                    !nested &&
                                    project.values.order.length > 0 && (
                                        <EurekaElementMenuGroup
                                            {...props}
                                            icon={GearsIcon}
                                            label={'Información Vinculada'}
                                            renderElements={(): JSX.Element => {
                                                return (
                                                    <>
                                                        {project.values.order.map(
                                                            (
                                                                idValue,
                                                                index
                                                            ) => (
                                                                <Context
                                                                    {...props}
                                                                    key={index}
                                                                    data={{
                                                                        type: DraftEntityDataTypes.TICKET_VALUE,
                                                                        idValue,
                                                                    }}
                                                                />
                                                            )
                                                        )}
                                                    </>
                                                );
                                            }}
                                        />
                                    )}
                            </>
                        );
                    }}
                />
            )}
        </>
    );
}

export default ProjectContexts;
