import React, { ReactElement, useContext, useMemo } from 'react';
import { DraftEntityData } from '../../../@Types/Draft/DraftEntityData';
import styles from './EntityEditor.module.css';
import { EntityDataMapper } from '../EntityDataMapper';
import ConditionEditor, {
    ConditionContext,
} from '../../ConditionEditor/ConditionEditor';
import { DraftEntityDataTypes } from '../../../constants/Draft/DraftEntityDataTypes';
import ConditionTypes from '../../../constants/Conditions/ConditionTypes';
import produce from 'immer';
import Toggle from '../../Toggle/Toggle';
import { PayloadEditorContext } from '../../../controllers/PayloadEditorController/PayloadEditorSlice';
import RoundedTextField from '../../RoundedTextField/RoundedTextField';

interface EntityEditorContainerProps {
    data: DraftEntityData;
    onChange: React.Dispatch<DraftEntityData>;
    children?: ReactElement;
    conditions: { types: ConditionTypes[]; context?: ConditionContext };
}

function EntityEditorContainer({
    data,
    onChange,
    children,
    conditions,
}: EntityEditorContainerProps): JSX.Element {
    const { Icon, Label } = useMemo(() => EntityDataMapper(data), [data]);
    const idEditor = useContext(PayloadEditorContext);
    return (
        <div className={styles.container}>
            <div className={styles.topContainer}>
                <div className={styles.iconContainer}>
                    <Icon
                        fill="var(--secondary)"
                        contrast="var(--primary1)"
                        style={{
                            width: '40px',
                            height: '40px',
                        }}
                    />
                </div>
                <div className={styles.labelContainer}>
                    {typeof Label === 'string' ? Label : <Label />}
                </div>
            </div>
            {conditions.types.length > 0 && (
                <div className={styles.conditionContainer}>
                    <div className={styles.conditionLbl}>
                        {data.type !== DraftEntityDataTypes.CONDITION_MET
                            ? 'Mostrar Condicionalmente:'
                            : 'Condición:'}
                        {data.type !== DraftEntityDataTypes.CONDITION_MET && (
                            <Toggle
                                size="small"
                                checked={data.condition !== undefined}
                                onChange={(checked: boolean): void => {
                                    onChange(
                                        produce(data, (data) => {
                                            if (!checked) {
                                                delete data.condition;
                                            } else {
                                                data.condition = {
                                                    type: 'DEFAULT',
                                                } as any;
                                            }
                                        })
                                    );
                                }}
                            />
                        )}
                    </div>
                    {(data.condition !== undefined ||
                        data.type === DraftEntityDataTypes.CONDITION_MET) && (
                        <div className={styles.conditionEditorContainer}>
                            <ConditionEditor
                                idCondition={idEditor + '-DraftEntityEditor'}
                                condition={data.condition}
                                conditionTypes={conditions.types}
                                context={conditions.context}
                                handleUpdate={null}
                            />
                        </div>
                    )}
                </div>
            )}
            {children}
            <div className={styles.fallbackContainer}>
                <div className={styles.prefixLblContainer}>
                    Valor por defecto:
                    <Toggle
                        size="small"
                        checked={data.fallback !== undefined}
                        onChange={(checked: boolean): void => {
                            onChange(
                                produce(data, (data) => {
                                    if (checked) {
                                        data.fallback = '';
                                    } else {
                                        delete data.fallback;
                                    }
                                })
                            );
                        }}
                    />
                </div>
                {data.fallback !== undefined && (
                    <div className={styles.fallbackInputContainer}>
                        <RoundedTextField
                            value={data.fallback}
                            label="Valor por defecto"
                            onChange={(event): void => {
                                onChange(
                                    produce(data, (data) => {
                                        data.fallback = event.target.value;
                                    })
                                );
                            }}
                        />
                    </div>
                )}
            </div>
            <div className={styles.bottomContainer}>
                <div className={styles.prefixContainer}>
                    <div className={styles.prefixLblContainer}>
                        Prefijo:
                        <Toggle
                            size="small"
                            checked={data.prefix !== undefined}
                            onChange={(checked: boolean): void => {
                                onChange(
                                    produce(data, (data) => {
                                        if (checked) {
                                            data.prefix = '';
                                        } else {
                                            delete data.prefix;
                                        }
                                    })
                                );
                            }}
                        />
                    </div>
                    {data.prefix !== undefined && (
                        <div className={styles.prefixInputContainer}>
                            <RoundedTextField
                                value={data.prefix}
                                label="Prefijo"
                                onChange={(event): void => {
                                    onChange(
                                        produce(data, (data) => {
                                            data.prefix = event.target.value;
                                        })
                                    );
                                }}
                            />
                        </div>
                    )}
                </div>
                <div className={styles.prefixContainer}>
                    <div className={styles.prefixLblContainer}>
                        Sufijo:
                        <Toggle
                            size="small"
                            checked={data.suffix !== undefined}
                            onChange={(checked: boolean): void => {
                                onChange(
                                    produce(data, (data) => {
                                        if (checked) {
                                            data.suffix = '';
                                        } else {
                                            delete data.suffix;
                                        }
                                    })
                                );
                            }}
                        />
                    </div>
                    {data.suffix !== undefined && (
                        <div className={styles.prefixInputContainer}>
                            <RoundedTextField
                                value={data.suffix}
                                label="Sufijo"
                                onChange={(event): void => {
                                    onChange(
                                        produce(data, (data) => {
                                            data.suffix = event.target.value;
                                        })
                                    );
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default EntityEditorContainer;
