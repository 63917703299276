import React, { useEffect, useState } from 'react';
import styles from './CreateTicket.module.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import { Client, Form } from '../../../@Types/@Types';
import { loadInternalForms } from '../../../controllers/FormsController/FormsService';
import FormRenderer from '../../../shared/FormRenderer/FormRenderer';
import RoundedButton from '../../../shared/RoundedButton/RoundedButton';
import { Link } from 'react-router-dom';
import { EditorState, convertFromRaw } from 'draft-js';
import { mapDraftEntities } from '@arquimedes.co/eureka-forms/dist/Utils/DraftFunctions';
import { DraftEntityTypes } from '@arquimedes.co/eureka-forms/dist/constants/Draft/DraftEntityTypes';
import { DraftEntityDataTypes } from '@arquimedes.co/eureka-forms/dist/constants/Draft/DraftEntityDataTypes';
import { TicketPropertyTypes } from '@arquimedes.co/eureka-forms/dist/constants/TicketPropertyTypes';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import RoundedClientPicker from '../../../shared/@Pickers/RoundedClientPicker/RoundedClientPicker';
import ClientInfoTypeStep from './ClientInfoTypeStep';
import ToolIcon from '../../../Icons/ToolIcon';
interface CreateTicketProps {
    /** Function that handles when canceled or created */
    close: Function;
    /** Function inherited from the dialog to activate the loader */
    setLoading?: Function;
    /** Function called to handle confirmation of creation to refresh the list */
    handleConfirmed: Function;
}
/**
 * Component rendered inside the create ticket dialog.
 */
function CreateTicket({ close, setLoading }: CreateTicketProps): JSX.Element {
    const siteInfo = useSelector((state: RootState) => state.site);
    const [forms, setForms] = useState<undefined | Form[]>(undefined);
    const [currentForm, setCurrentForm] = useState<undefined | number>(0);
    const [open, setOpen] = useState<boolean>(false);
    const [client, setClient] = useState<undefined | Client>(undefined);
    const fetchForms = async (): Promise<void> => {
        if (siteInfo.idProject) {
            const response = await loadInternalForms(siteInfo.idProject);
            setLoading?.(false);
            if (response) {
                setForms(response);
            }
        }
    };

    useEffect(() => {
        setLoading?.(true);
        fetchForms();
    }, []);
    return (
        <div className={styles.container}>
            <div className={styles.topContainer}>
                <div className={styles.containerLbl}>Crear Caso</div>
                {forms && forms.length > 0 && (
                    <React.Fragment>
                        <div className={styles.formNav}>
                            {forms.map((form, index) => (
                                <div
                                    key={index}
                                    className={
                                        index === currentForm
                                            ? styles.selectedFormName
                                            : styles.formName
                                    }
                                    onClick={(): void => {
                                        setCurrentForm(undefined);
                                        setTimeout(() => {
                                            setCurrentForm(index);
                                        }, 100);
                                    }}
                                >
                                    {form.name}
                                </div>
                            ))}
                        </div>
                        <div
                            className={styles.configContainer}
                            style={{
                                height: open ? 80 : 0,
                            }}
                        >
                            <div className={styles.configContent}>
                                <div className={styles.clientPickerContainer}>
                                    <RoundedClientPicker
                                        label={'Cliente'}
                                        handleUpdate={([client]): void => {
                                            setClient(client);
                                        }}
                                        value={client ? [client] : []}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className={styles.configTab}
                            onClick={(): void => setOpen(!open)}
                        >
                            {open ? (
                                <div className={styles.expandLessIcon}>
                                    <ExpandLessRoundedIcon
                                        style={{
                                            margin: -6,
                                            width: 28,
                                            height: 28,
                                        }}
                                        fill={'var(--primary1)'}
                                    />
                                </div>
                            ) : (
                                <ToolIcon
                                    style={{ width: 15, height: 15 }}
                                    fill={'var(--primary1)'}
                                />
                            )}
                            Herramientas
                        </div>
                    </React.Fragment>
                )}
            </div>
            {forms?.length === 0 && (
                <div className={styles.noFormsFoundLabel}>
                    No hay formularios internos
                </div>
            )}
            {forms && forms.length > 0 && (
                <div className={styles.formContainer}>
                    <div className={styles.scrollContainer}>
                        <div className={styles.widgetContainer}>
                            {currentForm !== undefined && (
                                <React.Fragment>
                                    <FormRenderer
                                        form={forms[currentForm]}
                                        values={{
                                            INTERNAL_CREATING_AGENT:
                                                siteInfo.user?._id,
                                        }}
                                        customClientInfoStep={(
                                            props
                                        ): JSX.Element => (
                                            <ClientInfoTypeStep
                                                {...props}
                                                client={client}
                                            />
                                        )}
                                        customConfirmation={(
                                            { caseNumber, url },
                                            renderIcon,
                                            renderConfirmation,
                                            _renderLink,
                                            onClose
                                        ): JSX.Element => {
                                            const idTicket = url.substring(
                                                url.lastIndexOf('/') + 1
                                            );
                                            const editorState =
                                                EditorState.createWithContent(
                                                    convertFromRaw(
                                                        mapDraftEntities(
                                                            {
                                                                form: forms[
                                                                    currentForm
                                                                ] as any,
                                                                dependencies:
                                                                    {},
                                                                ticket: {
                                                                    caseNumber,
                                                                },
                                                            },
                                                            {
                                                                blocks: [
                                                                    {
                                                                        depth: 0,
                                                                        data: {},
                                                                        inlineStyleRanges:
                                                                            [
                                                                                {
                                                                                    offset: 15,
                                                                                    length: 6,
                                                                                    style: 'BOLD',
                                                                                },
                                                                            ],
                                                                        text: 'El caso número ABC123 ha sido registrado!',
                                                                        type: 'unstyled',
                                                                        key: '1',
                                                                        entityRanges:
                                                                            [
                                                                                {
                                                                                    offset: 15,
                                                                                    length: 6,
                                                                                    key: 0,
                                                                                },
                                                                            ],
                                                                    },
                                                                ],
                                                                entityMap: {
                                                                    '0': {
                                                                        type: DraftEntityTypes.EUREKA,
                                                                        data: {
                                                                            type: DraftEntityDataTypes.TICKET,
                                                                            property:
                                                                                TicketPropertyTypes.CASENUMBER,
                                                                        },
                                                                        mutability:
                                                                            'IMMUTABLE',
                                                                    },
                                                                },
                                                            }
                                                        )
                                                    )
                                                );
                                            return (
                                                <React.Fragment>
                                                    {renderIcon()}
                                                    {renderConfirmation(
                                                        editorState
                                                    )}
                                                    <div
                                                        className={
                                                            styles.btnContainer
                                                        }
                                                    >
                                                        <Link
                                                            to={`/${siteInfo.idProject}/tickets/${idTicket}?forced=true`}
                                                            className={
                                                                'app-link'
                                                            }
                                                            onClick={(): void => {
                                                                onClose();
                                                                close();
                                                            }}
                                                        >
                                                            <RoundedButton
                                                                fontSize={24}
                                                                id="goToCase"
                                                                padding="20px 20px"
                                                                text={
                                                                    'Ir al caso'
                                                                }
                                                            />
                                                        </Link>
                                                    </div>
                                                </React.Fragment>
                                            );
                                        }}
                                    />
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CreateTicket;
