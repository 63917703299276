import styles from './AgentListItem.module.css';
import Avatar from '../../../shared/Avatar/Avatar';
import { Agent } from '../../../@Types/@Types';

interface AgentListItemProps {
    /** The selected Agent */
    selectedElement?: Agent;
    /** The Agent to display */
    element?: Agent;
    /** Function called when the component is clicked */
    handleItemClick?: Function;
}
function AgentListItem({
    selectedElement,
    element,
    handleItemClick,
}: AgentListItemProps): JSX.Element {
    if (!element || !handleItemClick) {
        return <div></div>;
    }
    return (
        <div
            className={
                styles.container +
                (selectedElement?._id === element._id
                    ? ' selected-item-shadow'
                    : '')
            }
            data-testid={'AgentListItem'}
            onClick={(): void => handleItemClick(element, true)}
        >
            <div className={styles.sideBar}></div>
            <div className={styles.info}>
                <h4 data-testid={'ALI_name'} className={styles.title}>
                    {element.name}
                </h4>
                <div data-testid={'ALI_email'} className={styles.email}>
                    {element.email}
                </div>
            </div>
            <div className="flexbox">
                <div className={styles.photo}>
                    <Avatar
                        img={element.img}
                        lbl={element.name}
                        size={50}
                        fontSize={30}
                    />
                </div>
            </div>
        </div>
    );
}

export default AgentListItem;
