import React from 'react';
import { Client } from '../../../@Types/Client';
import { useDispatch } from 'react-redux';
import { refreshCurrent } from '../../../controllers/ClientsController/ClientsActions';
import { updateClient } from '../../../controllers/ClientsController/ClientsService';
import ClientRenderer from '../../../shared/ClientRenderer/ClientRenderer';
interface ClientDetailProps {
    /** The selected Entry */
    selectedElement?: Client;
    /** Function that handles deleting the current classifier */
    handleClickDelete?: Function;
}
function ClientDetail({ selectedElement }: ClientDetailProps): JSX.Element {
    const dispatch = useDispatch();
    if (!selectedElement) {
        return <div></div>;
    }

    return (
        <ClientRenderer
            key={selectedElement._id}
            client={selectedElement}
            handleSave={async (client): Promise<void> => {
                await updateClient(selectedElement._id, client);
                dispatch(refreshCurrent());
            }}
            refresh={(): void => {
                dispatch(refreshCurrent());
            }}
            editable={true}
            showEntityValues
            showTickets
        />
    );
}
export default ClientDetail;
