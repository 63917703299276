import { combineReducers, Action } from 'redux';
import siteReducer from '../controllers/_SiteController/SiteReducer';
import genericEditorReducer from '../controllers/GenericEditorController/GenericEditorReducer';
import areasPageReducer from '../controllers/AreasController/AreasReducer';
import agentsPageReducer from '../controllers/AgentsController/AgentsReducer';
import EntitiesReducer from '../controllers/EntitiesController/EntitiesReducer';
import entriesPageReducer from '../controllers/EntriesController/EntriesReducer';
import ticketsPageReducer from '../controllers/TicketsController/TicketsReducer';
import templatesPageReducer from '../controllers/TemplatesController/TemplatesReducer';
import ticketActionsReducer from '../controllers/TicketsController/Actions/ActionsReducer';
import classifiersPageReducer from '../controllers/ClassifiersController/ClassifiersReducer';
import ticketAssignAgentsReducer from '../controllers/TicketsController/AssignAgents/AgentsReducer';
import genericMenuReducer from '../controllers/GenericMenuController/GenericMenuReducer';
import agentsMenuReducer from '../controllers/AgentsMenuController/AgentsReducer';
import ticketTransferAgentsReducer from '../controllers/TicketsController/TransferAgent/AgentsReducer';
import companiesPageReducer from '../controllers/CompaniesController/CompaniesReducer';
import entityValuesPageReducer from '../controllers/EntityValuesController/EntityValuesReducer';
import clientsPageReducer from '../controllers/ClientsController/ClientsReducer';
import { PayloadEditorReducer } from '../controllers/PayloadEditorController/PayloadEditorSlice';
import surveysPageReducer from '../controllers/SurveysController/SurveysReducer';
import socketsReducer from '../controllers/SocketsController/SocketsReducer';
import conversationEditorReducer from '../controllers/ConversationEditorController/ConversationEditorReducer';
import { ConditionEditorReducer } from '../controllers/ConditionEditorController/ConditionEditorSlice';
import { RootApi } from './_api';
import NotificationsMenuReducer from '../controllers/NotificationsMenuController/NotificationsMenuReducer';

const reducers = combineReducers({
    site: siteReducer,
    areasPage: areasPageReducer,
    entitiesPage: EntitiesReducer,
    payloadEditors: PayloadEditorReducer,
    agentsPage: agentsPageReducer,
    agentsMenu: agentsMenuReducer,
    socketsReducer: socketsReducer,
    entriesPage: entriesPageReducer,
    templatesPage: templatesPageReducer,
    ticketsPage: ticketsPageReducer,
    surveysPage: surveysPageReducer,
    companiesPage: companiesPageReducer,
    entityValuesPage: entityValuesPageReducer,
    clientsPage: clientsPageReducer,
    ticketActions: ticketActionsReducer,
    genericMenu: genericMenuReducer,
    genericEditor: genericEditorReducer,
    classifiersPage: classifiersPageReducer,
    conditionEditor: ConditionEditorReducer,
    conversationEditor: conversationEditorReducer,
    ticketAssignAgents: ticketAssignAgentsReducer,
    ticketTransferAgents: ticketTransferAgentsReducer,
    NotificationsMenu: NotificationsMenuReducer,
    api: RootApi.reducer,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function rootReducer(state: any, action: Action) {
    return reducers(state, action);
}

export default rootReducer;
