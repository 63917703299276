import { useDispatch } from 'react-redux';
import StepTypes from '../../../../../constants/Conversations/ConversationStepTypes';
import { StepLocation } from '../../../../../controllers/ConversationEditorController/ConversationEditorReducer';
import {
    createConversationStep,
    CreateConversationStepOptions,
} from '../../StepFunctions';
import { addStep } from '../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import TicketIcon from '../../../../../Icons/TicketIcon';
import EurekaElementMenuElement from '../../../../../shared/@Menus/ElementMenu/EurekaElementMenuElement';
import EurekaElementMenuGroup from '../../../../../shared/@Menus/ElementMenu/EurekaElementMenuGroup';

interface CreateStepMenuProps {
    location: StepLocation;
    handleClose: Function;
}
function CreateStepMenu({
    location,
    handleClose,
}: CreateStepMenuProps): JSX.Element {
    const dispatch = useDispatch();

    const handleAdd = (
        type: StepTypes,
        options?: CreateConversationStepOptions
    ): void => {
        dispatch(
            addStep({
                step: createConversationStep(type, undefined, options),
                location,
            })
        );
        handleClose();
    };

    return (
        <EurekaElementMenuGroup
            label={'Crear Caso'}
            icon={TicketIcon}
            style={{ fontSize: 18 }}
            renderElements={(props): JSX.Element => (
                <>
                    <EurekaElementMenuElement
                        {...props}
                        Icon={TicketIcon}
                        onClick={(): void => {
                            handleAdd(StepTypes.CREATE_PASSTHROUGH);
                        }}
                        Label={'Sin Confirmación'}
                    />
                    <EurekaElementMenuElement
                        {...props}
                        Icon={TicketIcon}
                        onClick={(): void => {
                            handleAdd(StepTypes.CREATE_PASSTHROUGH, {
                                message: true,
                            });
                        }}
                        Label={'Con Confirmación'}
                    />
                    <EurekaElementMenuElement
                        {...props}
                        Icon={TicketIcon}
                        onClick={(): void => {
                            handleAdd(StepTypes.CREATE_DEFAULT);
                        }}
                        Label={'Gestión Múltiple'}
                    />
                </>
            )}
            zIndex={1303}
        />
    );
}

export default CreateStepMenu;
