import { createAction } from '@reduxjs/toolkit';
import { GenericMenuConfig } from './GenericMenuReducer';

export const Types = {
    CLEAR: 'GENERICMENU/CLEAR',
    RESET: 'GENERICMENU/RESET',
    GET_SUCCESS: 'GENERICMENU/GET_SUCCESS',
    PAGE_SCROLL: 'GENERICMENU/NEW_PAGE_SCROLL',
    SELECT: 'GENERICMENU/SELECT_ELEMENT',
    SEARCH: 'GENERICMENU/SEARCH',
    UPDATE: 'GENERICMENU/UPDATE',
    LOADING: 'GENERICMENU/LOADING',
};

function prepare<Type>(
    key: string,
    preview: Type
): { meta: { key: string }; payload: Type } {
    return {
        meta: { key },
        payload: preview,
    };
}

/**
 * Called when the generic menu is first loaded
 */
export const reset = createAction(
    Types.RESET,
    prepare<{
        selected: any[];
        elementsToOmit: any[];
        config: GenericMenuConfig<any, any>;
    }>
);

/**
 * Called when the list needs to update the value of an element
 */
export const update = createAction(Types.UPDATE, prepare<any>);

/**
 * Called to toggle the loader
 */
export const setLoading = createAction(
    Types.LOADING,
    function prepare(key: string, loading: boolean = true) {
        return { meta: { key }, payload: loading };
    }
);

/**
 * Called when the list needs to load more elements
 */
export const pageScroll = createAction(
    Types.PAGE_SCROLL,
    function prepare(key: string) {
        return { meta: { key }, payload: {} };
    }
);

/**
 * Called when the selectedElements change value
 */
export const selectElements = createAction(Types.SELECT, prepare<string[]>);

/**
 * Called when the search value changes
 */
export const search = createAction(Types.SEARCH, prepare<string>);

/**
 * Called to clear the menu state
 */
export const clear = createAction(Types.CLEAR, function prepare(key: string) {
    return { meta: { key }, payload: {} };
});
/**
 *  Called when the saga has sucessfully retrieved the lists elements
 */
export const getSuccess = createAction(
    Types.GET_SUCCESS,
    prepare<{
        order: string[];
        selected: string[];
        elements: Record<string, any>;
        noMoreElements: boolean;
    }>
);
