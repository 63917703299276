import { makeStyles, Icon } from '@material-ui/core';
import RoundedGenericPicker, {
    RoundedGenericPickerProps,
} from '../RoundedGenericPicker/RoundedGenericPicker';
import { Process } from '../../../@Types/ProcessTypes/Process';
import { loadProcessesByIds } from '../../../controllers/ProcessController/ProcessService';
import { loadProcesses } from '../../../controllers/ProcessController/ProcessService';

function RoundedProcessPicker({
    label,
    idProject,
    ...props
}: Omit<
    RoundedGenericPickerProps<Process>,
    'calcLbl' | 'loadInitialType' | 'loadElements' | 'elementLabel'
> & { idProject: string }): JSX.Element {
    return (
        <RoundedGenericPicker<Process>
            {...props}
            IconComponent={StateIcon}
            label={label}
            elementLabel="Proceso"
            calcLbl={(process): string => process.name ?? 'Proceso'}
            loadInitialType={async (ids: string[]): Promise<Process[]> => {
                return await loadProcessesByIds(idProject, ids);
            }}
            loadElements={async (
                page: number,
                pageSize: number,
                search: string | undefined
            ): Promise<Process[]> => {
                return await loadProcesses(
                    idProject,
                    page,
                    pageSize,
                    [],
                    undefined,
                    search
                );
            }}
        />
    );
}
export default RoundedProcessPicker;
function StateIcon(props: any): JSX.Element {
    const useStyles = makeStyles({
        iconRoot: {
            position: 'absolute',
            right: '6px',
            marginTop: '1px',
        },
        imageIcon: {
            height: 21,
            width: 21,
        },
    });
    const classes = useStyles();
    return (
        <Icon classes={{ root: classes.iconRoot }} className={props.className}>
            <img
                className={classes.imageIcon}
                style={{ display: 'flex' }}
                src="/media/icons/greycycle.svg"
            />
        </Icon>
    );
}
