import { useRef, useState } from 'react';
import { EntityValueFilters } from '../../../../controllers/EntityValuesController/EntityValuesReducer';
import { RootState } from '../../../../utils/_store';
import styles from './EntityValuesFiltersMenu.module.css';
import { useSelector } from 'react-redux';
import EntityFilters from '../../../Tickets/TicketFilters/EntityFilters/EntityFilters';
import Dialog from '../../../../shared/Dialog/Dialog';
import { ClickAwayListener, Popper } from '@material-ui/core';
import ValueFilters from './ValueFilters/ValueFilters';

enum TABS {
    VALUES = 'VALUES',
}

interface EntityValueFiltersMenuProps {
    idEntity: string;
    /** Element the menu is anchored to (classifiers button in the sidebar) */
    anchorRef: any;
    /** Function called when the menu closes */
    handleClose: Function;
    /** Function called when classifiers change */
    handleChange: (filters: EntityValueFilters) => void;
    /** The currently selected classifiers */
    filters: EntityValueFilters;
    /** If the layout is mobile */
    layoutIsMobile?: boolean;
}
function EntityValueFiltersMenu({
    idEntity,
    anchorRef,
    handleClose,
    layoutIsMobile,
    filters,
    handleChange,
}: EntityValueFiltersMenuProps): JSX.Element {
    const entities = useSelector((state: RootState) => state.site.entities);
    const entity = entities[idEntity];

    const [selectedTab, setSelectedTab] = useState<TABS | string>(
        entity?.idFilterSteps?.length
            ? TABS.VALUES
            : entity?.relationships?.[0]?.idEntity
    );
    const emptyRef = useRef<HTMLDivElement>(null);
    if (!entity) return <div></div>;
    const renderSelectedTab = (): JSX.Element => {
        switch (selectedTab) {
            case TABS.VALUES:
                return (
                    <ValueFilters
                        entity={entity}
                        values={filters.values}
                        handleChange={(values: Record<string, any>): void => {
                            handleChange({ ...filters, values });
                        }}
                        tabsComponent={
                            <TabsComponent
                                idEntity={idEntity}
                                selectedTab={selectedTab}
                                filters={filters}
                                setSelectedTab={setSelectedTab}
                            />
                        }
                    />
                );
            default:
                if (entities[selectedTab] !== undefined) {
                    return (
                        <EntityFilters
                            idEntity={selectedTab}
                            initialEntityValues={
                                filters.relationships[selectedTab]
                            }
                            handleChange={(entityValues: any): void => {
                                handleChange({
                                    ...filters,
                                    relationships: {
                                        ...filters.relationships,
                                        [selectedTab]: entityValues,
                                    },
                                });
                            }}
                            tabsComponent={
                                <TabsComponent
                                    idEntity={idEntity}
                                    selectedTab={selectedTab}
                                    setSelectedTab={setSelectedTab}
                                    filters={filters}
                                />
                            }
                        />
                    );
                }
                return <div></div>;
        }
    };

    const renderContent = (): JSX.Element => {
        return (
            <div
                className={
                    layoutIsMobile
                        ? styles.mobileContainer
                        : styles.emptyContainer
                }
                onClick={(e): void => {
                    if (emptyRef.current === e.target) {
                        handleClose();
                    }
                }}
                ref={emptyRef}
            >
                <div className={styles.container}>
                    <div className={styles.filtersHeaderContainer}>
                        <span className={styles.title}>Filtrar Casos</span>
                    </div>
                    {renderSelectedTab()}
                </div>
            </div>
        );
    };

    if (layoutIsMobile) {
        return (
            <Dialog
                onClose={(): void => {
                    handleClose();
                }}
                maxWidth="100vw"
                border={10}
                transparent
            >
                {renderContent()}
            </Dialog>
        );
    } else {
        return (
            <ClickAwayListener
                mouseEvent="onMouseDown"
                onClickAway={(): void => {
                    handleClose();
                }}
            >
                <Popper
                    open={true}
                    anchorEl={anchorRef.current}
                    placement={'bottom-start'}
                    modifiers={{
                        preventOverflow: {
                            enabled: true,
                            priority: ['top', 'bottom', 'left', 'right'],
                            boundariesElement: 'viewport',
                            padding: 20,
                        },
                    }}
                    style={{ zIndex: 3 }}
                >
                    {renderContent()}
                </Popper>
            </ClickAwayListener>
        );
    }
}
export default EntityValueFiltersMenu;

interface TabsComponentProps {
    idEntity: string;
    selectedTab: TABS | string;
    setSelectedTab: Function;
    filters: EntityValueFilters;
}
function TabsComponent({
    filters,
    idEntity,
    selectedTab,
    setSelectedTab,
}: TabsComponentProps): JSX.Element {
    const entities = useSelector((state: RootState) => state.site.entities);
    const entity = entities[idEntity];
    return (
        <div className={styles.tabsContainer}>
            {entity?.idFilterSteps?.length > 0 && (
                <TabComponent
                    tab={TABS.VALUES}
                    label="Valores"
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    count={Object.keys(filters.values).length}
                />
            )}
            {entity.relationships.map((relationship) => {
                return (
                    <TabComponent
                        key={relationship.idEntity}
                        tab={relationship.idEntity}
                        label={relationship.name}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        count={
                            filters.relationships[relationship.idEntity]
                                ?.length ?? 0
                        }
                    />
                );
            })}
        </div>
    );
}

interface TabComponentProps {
    tab: string;
    label: string;
    count: number;
    selectedTab: string;
    setSelectedTab: Function;
}

function TabComponent({
    tab,
    label,
    count,
    selectedTab,
    setSelectedTab,
}: TabComponentProps): JSX.Element {
    return (
        <div
            className={
                (tab === selectedTab ? styles.selectedOption : styles.option) +
                ' noselect'
            }
            onClick={(): void => {
                setSelectedTab(tab);
            }}
        >
            {label}
            <div className={styles.countContainer}>
                {count > 0 && <div className={styles.countLbl}>{count}</div>}
            </div>
        </div>
    );
}
