import React, { useRef, useState } from 'react';
import { Menu, MenuItem, IconButton } from '@material-ui/core';
import { logout } from '../../../controllers/_SiteController/Services/UserService';
import Avatar from '../../Avatar/Avatar';
import styles from './Profile.module.css';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../../../utils/_store';
interface ProfileProps {
    mobile: boolean;
    handleClose?: Function;
}
function Profile({ mobile, handleClose }: ProfileProps): JSX.Element {
    const ref = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState<any>(false);
    const user = useSelector((state: RootState) => state.site.user);
    if (user) {
        if (mobile) {
            return (
                <React.Fragment>
                    <MenuItem>
                        <Link
                            to="/profile"
                            className={styles.link + ' app-link'}
                            onClick={(): void => {
                                handleClose?.();
                                setOpen(false);
                            }}
                        >
                            Perfil
                        </Link>
                    </MenuItem>
                    <MenuItem
                        onClick={async (): Promise<void> => {
                            setOpen(false);
                            try {
                                await logout();
                            } catch (err) {
                                console.error(err);
                            }
                            localStorage.clear();
                            sessionStorage.clear();
                            window.location.reload();
                            // window.location.replace(
                            //     // eslint-disable-next-line no-undef
                            //     `${process.env.REACT_APP_PREFIX}${process.env.REACT_APP_DOMAIN}`
                            // );
                        }}
                    >
                        <span className={styles.link}>Logout</span>
                    </MenuItem>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div className={styles.navBarAvatar} ref={ref}>
                        <IconButton
                            style={{
                                width: 42,
                                height: 42,
                                padding: 0,
                            }}
                            aria-label="Mi Perfil"
                            aria-haspopup="true"
                            onClick={(): void => {
                                setOpen(true);
                            }}
                        >
                            <Avatar size={42} lbl={user.name} img={user.img} />
                            {open && <div className={styles.avatarArrow} />}
                        </IconButton>
                    </div>
                    <Menu
                        id="simple-menu"
                        anchorEl={ref.current}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={(): void => {
                            handleClose?.();
                            setOpen(false);
                        }}
                    >
                        <MenuItem>
                            <Link
                                to="/profile"
                                className={styles.link + ' app-link'}
                                onClick={(): void => {
                                    handleClose?.();
                                    setOpen(false);
                                }}
                            >
                                Perfil
                            </Link>
                        </MenuItem>
                        <MenuItem
                            onClick={async (): Promise<void> => {
                                setOpen(false);
                                try {
                                    await logout();
                                } catch (err) {
                                    console.error(err);
                                }
                                localStorage.clear();
                                window.location.reload();
                                // window.location.replace(
                                //     // eslint-disable-next-line no-undef
                                //     `${process.env.REACT_APP_PREFIX}${process.env.REACT_APP_DOMAIN}`
                                // );
                            }}
                        >
                            Logout
                        </MenuItem>
                    </Menu>
                </React.Fragment>
            );
        }
    } else {
        return <div></div>;
    }
}
export default Profile;
