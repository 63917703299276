import styles from './InformativeFilePicker.module.css';
import FileComponent from '../../../../../../shared/FileComponent/FileComponent';
import VanillaToasts from '../../../../../../shared/Toast/Toast';
import { useRef, useState } from 'react';
import { FileObject } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import {
    getUploadUrls,
    postFile,
} from '../../../../../../controllers/FilesController/FilesService';
import { uploadSticker } from '../../../../../../controllers/ConversationEditorController/ConversationService';
import { useDispatch } from 'react-redux';
import { updateStepFile } from '../../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import { loadImage } from '../../../../../../utils/draftFunctions';

interface InformativeFileComponent {
    idStep: string;
    file: FileObject | null;
    types?: string[];
    inputs?: string;
    maxSize?: number; //In MB
    type?: 'IMAGE' | 'VIDEO' | 'STICKER';
    defaultFile?: any;
    /** Function to call on file update */
    onChange?: Function;
}
function InformativeFileComponent({
    file,
    types,
    idStep,
    inputs,
    type,
    maxSize,
    defaultFile = {
        fileName: 'Capta.png',
        url: '/CaptaSqr.png',
    },
}: InformativeFileComponent): JSX.Element {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<File | null>(null);
    const inputFile = useRef<HTMLInputElement>(null);

    const onFileDialogChange = (e: any): void => {
        const files = e.target.files;
        const newFile = files[0];
        if (types === undefined || types.includes(newFile.type)) {
            setImage(newFile);
        }
        if (inputFile.current) {
            inputFile.current.value = '';
        }
    };

    const calcDimensions = async (media: {
        width: number;
        height: number;
    }): Promise<{ width: number; ratio: number }> => {
        const ratio = media.width / media.height;
        let width = media.width;
        let height = media.height;
        //width > height
        if (ratio > 1) {
            if (width > 200) {
                width = 200;
            }
        } else {
            if (height > 200) {
                width = 200 * ratio;
            }
        }
        return { width, ratio };
    };

    async function setImage(file: File): Promise<void> {
        if (file) {
            try {
                const sizeInMB = file.size / 1024 ** 2;
                if (maxSize === undefined || sizeInMB <= maxSize) {
                    setLoading(file);
                    if (type === 'STICKER') {
                        const url = await uploadSticker(file);
                        const dimensions = await calcDimensions(
                            await loadImage(url)
                        );
                        dispatch(
                            updateStepFile({
                                idStep,
                                file: {
                                    url,
                                    fileName: file.name.split('.')[0] + '.webp',
                                },
                                dimensions,
                            } as any)
                        );
                    } else {
                        const fileLinks = await getUploadUrls([file], true);
                        const uploadedFile = await postFile(
                            file,
                            fileLinks[0] as any
                        );
                        let dimensions = null;
                        if (type === 'IMAGE' && uploadedFile.url) {
                            dimensions = await calcDimensions(
                                await loadImage(uploadedFile.url)
                            );
                        }
                        if (type === 'VIDEO' && uploadedFile.url) {
                            dimensions = await calcDimensions(
                                await loadVideo(uploadedFile.url)
                            );
                        }
                        dispatch(
                            updateStepFile({
                                idStep,
                                file: uploadedFile,
                                dimensions,
                            } as any)
                        );
                    }
                    setLoading(null);
                }
            } catch (error: any) {
                setLoading(null);
                // TODO: Manejo de errores
                VanillaToasts.create({
                    title: 'Error:',
                    text: error.response?.data,
                    type: 'error',
                    timeout: 5000,
                    callback: function () {},
                });
            }
        }
    }

    return (
        <div>
            <div className={styles.fileLabel}>
                Archivo:
                {maxSize !== undefined && (
                    <label className={styles.maxSize}>
                        Tamaño máximo: {maxSize}MB
                    </label>
                )}
            </div>
            <div className={styles.fileContainer}>
                <input
                    type="file"
                    ref={inputFile}
                    style={{ display: 'none' }}
                    onChange={onFileDialogChange}
                    accept={inputs}
                />

                <div className={styles.selectedFileContainer}>
                    <FileComponent
                        file={
                            loading
                                ? { file: loading, state: 'UPLOADING' }
                                : file
                                ? file
                                : defaultFile
                        }
                        large
                        handleEdit={(): void => {
                            if (inputFile.current !== null) {
                                inputFile.current.click();
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default InformativeFileComponent;

function loadVideo(url: string): Promise<{ width: number; height: number }> {
    return new Promise((resolve) => {
        // create the video element
        const video = document.createElement('video');

        // place a listener on it
        video.addEventListener(
            'loadedmetadata',
            (event: any) => {
                const video = event?.path?.[0];
                resolve({
                    height: video.videoHeight ?? 0,
                    width: video.videoWidth ?? 0,
                });
            },
            false
        );

        // start download meta-datas
        video.src = url;
    });
}
