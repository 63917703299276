import styles from './EntryListItem.module.css';
import Avatar from '../../../shared/Avatar/Avatar';
import { Entry } from '../../../@Types/@Types';
// import ExtensionRoundedIcon from '@material-ui/icons/ExtensionRounded';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import FormatListNumberedRoundedIcon from '@material-ui/icons/FormatListNumberedRounded';
import EntryTypes from '../../../constants/EntryTypes';
import WhatsappIcon from '../../../Icons/WhatsappIcon';
interface EntryListItemProps {
    /** The selected Entry */
    selectedElement?: Entry;
    /** The Entry to display */
    element?: Entry;
    /** Function called when the component is clicked */
    handleItemClick?: Function;
}
function EntryListItem({
    selectedElement,
    element,
    handleItemClick,
}: EntryListItemProps): JSX.Element {
    if (!element || !handleItemClick) {
        return <div></div>;
    }

    return (
        <div
            className={
                styles.container +
                (selectedElement?.apiKey === element.apiKey
                    ? ' selected-item-shadow'
                    : '')
            }
            onClick={(): void => handleItemClick(element, true)}
        >
            <div className={styles.sideBar}></div>
            <div className={styles.info}>
                <h4 className={styles.title}>{element.entryName}</h4>
                <p className={styles.description}>{element.description}</p>
            </div>
            <div className="flexbox">
                <div className={styles.photo}>
                    <EntryIcon element={element} size={50} fontSize={30} />
                </div>
            </div>
        </div>
    );
}

export default EntryListItem;

interface EntryIconProps {
    element: Entry;
    size?: number;
    fontSize?: number;
}

export function EntryIcon({
    size,
    element,
    fontSize,
}: EntryIconProps): JSX.Element {
    const calcIcon = (): JSX.Element => {
        switch (element.type) {
            case EntryTypes.FORM:
                return (
                    <FormatListNumberedRoundedIcon fontSize="inherit"></FormatListNumberedRoundedIcon>
                );
            case EntryTypes.WHATSAPP:
                return (
                    <WhatsappIcon
                        fill="white"
                        style={{
                            height: 32,
                            width: 32,
                        }}
                    />
                );
            // case EntryTypes.API:
            //     return (
            //         <ExtensionRoundedIcon fontSize="inherit"></ExtensionRoundedIcon>
            //     );
            case EntryTypes.EMAIL: {
                return (
                    <MailOutlineRoundedIcon fontSize="inherit"></MailOutlineRoundedIcon>
                );
            }
            default:
                return <div></div>;
        }
    };
    return (
        <Avatar lbl={element.entryName} size={size} fontSize={fontSize}>
            {calcIcon()}
        </Avatar>
    );
}
