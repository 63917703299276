import { Icon, makeStyles } from '@material-ui/core';
import {
    Entity,
    EntityRelationship,
} from '../../../../../@Types/EntityTypes/Entity';
import RoundedTextField from '../../../../../shared/RoundedTextField/RoundedTextField';
import styles from './EntityRelationship.module.css';
import GenericEntityIcon from '../../../../../Icons/Entities/GenericEntityIcon';
import RoundedGenericPicker from '../../../../../shared/@Pickers/RoundedGenericPicker/RoundedGenericPicker';
import {
    loadEntities,
    loadEntitiesById,
} from '../../../../../controllers/EntitiesController/EntitiesService';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { RootState } from '../../../../../utils/_store';
import { useSelector } from 'react-redux';
import Avatar from '../../../../../shared/Avatar/Avatar';
import EntityIcon from '../../../../../shared/SmartIcons/EntityIcon';
import EntityRelationships from '../EntityRelationships';
import Toggle from '../../../../../shared/Toggle/Toggle';
import RoundedButton from '../../../../../shared/RoundedButton/RoundedButton';
import React, { useState } from 'react';
import RelationshipEditor from '../../RelationshipEditor/RelationshipEditor';

interface EntityRelationshipComponentProps {
    relationship: EntityRelationship;
    handleUpdate: (relationship: EntityRelationship) => void;
    handleDelete: () => void;
    path: string[];
}

function EntityRelationshipComponent({
    path,
    relationship,
    handleDelete,
    handleUpdate,
}: EntityRelationshipComponentProps): JSX.Element {
    const [showEdit, setShowEdit] = useState(false);
    const entity = useSelector(
        (state: RootState) => state.site.entities[relationship.idEntity]
    );
    return (
        <React.Fragment>
            {showEdit && (
                <RelationshipEditor
                    relationship={relationship}
                    onClose={(relationship): void => {
                        handleUpdate(relationship);
                        setShowEdit(false);
                    }}
                />
            )}
            <div className={styles.container}>
                <div className={styles.inputContainer}>
                    <RoundedGenericPicker<Entity, string>
                        value={[entity]}
                        handleUpdate={([relationshipEntity]): void => {
                            handleUpdate({
                                ...relationship,
                                idEntity: relationshipEntity._id,
                                name: relationshipEntity.name,
                                description: relationshipEntity.description,
                            });
                        }}
                        IconComponent={EntityIconComponent}
                        calcIcon={(entity): JSX.Element => (
                            <Avatar lbl={entity.name} size={30} fontSize={30}>
                                <EntityIcon
                                    idEntity={entity._id}
                                    fill="var(--primary1)"
                                    style={{
                                        height: 21,
                                        width: 21,
                                    }}
                                />
                            </Avatar>
                        )}
                        multiple={false}
                        label={'Entidad'}
                        elementLabel={'Entidad'}
                        pluralLabel={'Entidades'}
                        calcLbl={(entity): string => entity.name}
                        loadInitialType={async (
                            ids: string[]
                        ): Promise<Entity[]> => await loadEntitiesById(ids)}
                        loadElements={async (
                            page: number,
                            pageSize: number,
                            search: string | undefined
                        ): Promise<Entity[]> =>
                            await loadEntities(page, pageSize, search)
                        }
                    />

                    <div
                        className={styles.deleteBtn}
                        onClick={(): void => {
                            handleDelete();
                        }}
                    >
                        <DeleteRoundedIcon fontSize="inherit" />
                    </div>
                </div>
                <div className={styles.inputContainer}>
                    <RoundedTextField
                        label="Nombre"
                        value={relationship.name}
                        onChange={(e): void => {
                            handleUpdate({
                                ...relationship,
                                name: e.target.value,
                            });
                        }}
                    ></RoundedTextField>
                </div>
                <div className={styles.inputContainer2}>
                    <RoundedTextField
                        label="Descripción"
                        value={relationship.description}
                        onChange={(e): void => {
                            handleUpdate({
                                ...relationship,
                                description: e.target.value,
                            });
                        }}
                    ></RoundedTextField>
                </div>
                <div className={styles.toggleContainer}>
                    <div className={styles.toggleLabel}>Múltiples:</div>
                    <Toggle
                        checked={relationship.multiple}
                        onChange={(checked): void => {
                            handleUpdate({
                                ...relationship,
                                multiple: checked,
                            });
                        }}
                    />
                </div>
                <div className={styles.toggleContainer}>
                    <div className={styles.toggleLabel}>
                        {entity.feminine ? 'Obligatoria' : 'Obligatorio'}
                    </div>
                    <Toggle
                        checked={relationship.required}
                        onChange={(checked): void => {
                            handleUpdate({
                                ...relationship,
                                required: checked,
                            });
                        }}
                    />
                </div>
                {path.length === 0 && (
                    <div className={styles.toggleContainer}>
                        <div className={styles.toggleLabel}>Bidireccional:</div>
                        <Toggle
                            checked={relationship.bidirectional}
                            onChange={(checked): void => {
                                handleUpdate({
                                    ...relationship,
                                    bidirectional: checked,
                                });
                            }}
                        />
                    </div>
                )}
                <div className={styles.btnContainer}>
                    <RoundedButton
                        text="Editar Pasos"
                        onClick={(): void => {
                            setShowEdit(true);
                        }}
                    />
                </div>
                <EntityRelationships
                    path={[...path, relationship.idEntity]}
                    relationships={relationship.relationships ?? []}
                    handleUpdate={(relationships): void => {
                        handleUpdate({ ...relationship, relationships });
                    }}
                />
            </div>
        </React.Fragment>
    );
}
export default EntityRelationshipComponent;

export function EntityIconComponent(props: any): JSX.Element {
    const useStyles = makeStyles({
        iconRoot: {
            position: 'absolute',
            right: '7px',
        },
        imageIcon: {
            height: 23,
            width: 23,
        },
    });
    const classes = useStyles();
    return (
        <Icon classes={{ root: classes.iconRoot }} className={props.className}>
            <GenericEntityIcon
                style={{
                    width: '100%',
                    height: '100%',
                }}
            />
        </Icon>
    );
}
