import { IconProps } from './@IconTypes';

function TutorialsIcon({ className, style, fill }: IconProps): JSX.Element {
    return (
        <svg
            fill={'transparent'}
            style={style}
            className={className}
            version="1.1"
            viewBox="0 0 60 60"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M52.5 25L30 12.5L7.5 25L15 29.1668M52.5 25L45 29.1668M52.5 25C54.0323 25.766 55 27.332 55 29.045V42.4995M15 29.1668L30 37.5L45 29.1668M15 29.1668V44.1668L30 52.5L45 44.1668V29.1668"
                stroke-width="5"
                stroke={fill}
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}
export default TutorialsIcon;
