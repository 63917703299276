import { AnyAction } from '@reduxjs/toolkit';
import { Classifier } from '../../@Types/@Types';
import * as Actions from './ClassifiersActions';
const defaultOrderby = 'order';

export interface ClassifiersPageState {
    /** The project's Classifiers */
    elements: Record<string, Classifier>;
    /** The project's ids of the Classifiers Classifiers that are roots */
    roots: string[];
    /** The  way the elements are currently ordered. undefined if search is active*/
    orderBy: string | undefined; //TODO definir orderby
    /** The current search, undefined if none */
    search: string | undefined;
    /** the index of the currently selectedElement */
    selectedElement: string | undefined;
    /** If loader is active */
    loading: boolean;
}

const initialState = {
    elements: {},
    roots: [],
    orderBy: defaultOrderby,
    search: undefined,
    selectedElement: undefined,
    loading: true,
};
/**
 * Redux Reducer that handles ClassifiersActions Tiggers
 * @param state The current state
 * @param action the action that was triggered
 * @returns the new state
 */
const ClassifiersReducer = (
    state: ClassifiersPageState = initialState,
    action: AnyAction
): ClassifiersPageState => {
    if (Actions.reset.match(action)) {
        return {
            ...state,
            elements: {},
            roots: [],
            orderBy: defaultOrderby,
            search: undefined,
            selectedElement: undefined,
            loading: true,
        };
    } else if (Actions.setLoading.match(action)) {
        return {
            ...state,
            loading: action.payload,
        };
    } else if (Actions.getSuccess.match(action)) {
        return {
            ...state,
            loading: false,
            elements: action.payload.elements,
            roots: action.payload.roots,
        };
    } else if (Actions.selectElement.match(action)) {
        return { ...state, selectedElement: action.payload };
    } else if (Actions.updateElement.match(action)) {
        if (state.selectedElement) {
            return {
                ...state,
                elements: {
                    ...state.elements,
                    [state.selectedElement]: {
                        ...state.elements[state.selectedElement],
                        [action.payload.field]: action.payload.value,
                    },
                },
            };
        } else {
            return { ...state };
        }
    } else if (Actions.updateFailed.match(action)) {
        if (state.elements[action.payload._id]) {
            return {
                ...state,
                elements: {
                    ...state.elements,
                    [action.payload._id]: action.payload,
                },
            };
        } else {
            return { ...state };
        }
    } else if (Actions.search.match(action)) {
        if (action.payload === '') {
            return {
                ...state,
                search: undefined,
                orderBy: defaultOrderby,
            };
        } else {
            return {
                ...state,
                search: action.payload,
                orderBy: undefined,
            };
        }
    } else if (Actions.orderBy.match(action)) {
        return {
            ...state,
            search: undefined,
            orderBy: action.payload,
        };
    }
    return state;
};
export default ClassifiersReducer;
