import { IconProps } from './@IconTypes';

function InfoIcon({
    className,
    style,
    fill,
    contrast,
}: IconProps): JSX.Element {
    return (
        <svg
            fill={contrast}
            style={style}
            className={className}
            version="1.1"
            viewBox="0 0 14 14"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                stroke={fill}
                d="M7 12.25C9.8995 12.25 12.25 9.8995 12.25 7C12.25 4.1005 9.8995 1.75 7 1.75C4.1005 1.75 1.75 4.1005 1.75 7C1.75 9.8995 4.1005 12.25 7 12.25Z"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                stroke={fill}
                d="M7.005 4.6665H7V4.6715H7.005V4.6665Z"
                strokeWidth="1.5"
                strokeLinejoin="round"
            />
            <path
                stroke={fill}
                d="M7 7V9.33333"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default InfoIcon;
