import styles from './SeparatorStep.module.css';
import Toggle from '../../Toggle/Toggle';
import { GenericStepProps } from '../../GenericFormEditor/Step/Step';
import { GSeparator } from '../../../@Types/GenericFormSteps';
import { GLocation } from '../../../@Types/FormTypes/LocationTypes';
import React from 'react';

function SeparatorStep<StepType extends GSeparator, U, L extends GLocation>({
    step,
    editing,
    updateStep,
}: GenericStepProps<StepType, U, L>): JSX.Element {
    if (editing) {
        return (
            <div className={styles.editingContainer}>
                <div className={styles.labelContainer}>Separador</div>
                <div className={styles.transparentContainer}>
                    <div className={styles.transparentLabel + ' noselect'}>
                        Transparente:
                    </div>
                    <div className={styles.toggleContainer}>
                        <Toggle
                            checked={step.transparent}
                            onChange={(checked: boolean): void => {
                                updateStep({
                                    ...step,
                                    transparent: checked,
                                });
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div
                className={styles.container}
                style={{
                    backgroundColor: step.transparent
                        ? 'transparent'
                        : 'var(--secondary)',
                }}
            ></div>
        );
    }
}

export default React.memo(SeparatorStep, (prev, next) => {
    const { step, stepEqualityChecker } = prev;
    const { step: newStep } = next;
    if (step.transparent !== newStep.transparent) {
        return false;
    }

    return stepEqualityChecker?.(prev, next) ?? true;
}) as typeof SeparatorStep;
