import React, { useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

import styles from './Organization.module.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import {
    EntityPermissions,
    OrganizationPermissions,
} from '../../../constants/Permissions';
import { checkOrgPerm } from '../../../utils/PermissionsFunctions';
import { useAppSelector } from '../../../hooks';
interface OrganizationProps {
    mobile: boolean;
    handleClose?: Function;
}
function Organization({ mobile, handleClose }: OrganizationProps): JSX.Element {
    const refBtn = useRef<HTMLDivElement>(null);
    const user = useSelector((state: RootState) => state.site.user);
    const organization = useSelector(
        (state: RootState) => state.site.organization
    );

    const entities = useAppSelector((state) => state.site.entities);

    const to = useMemo((): string => {
        if (checkOrgPerm(user, organization, OrganizationPermissions.AGENTS))
            return '/agents';
        if (checkOrgPerm(user, organization, OrganizationPermissions.COMPANIES))
            return '/companies';

        if (user?.permissions.entities) {
            const siteEntities = Object.values(entities);
            siteEntities.sort((a, b) => a.name.localeCompare(b.name));
            const entity = siteEntities.find(
                (entity) =>
                    !!user?.permissions.entities[entity._id]?.[
                        EntityPermissions.VIEW
                    ]
            );
            if (entity) {
                return `/entities/${entity._id}`;
            }
        }
        return '/';
    }, [user]);

    return (
        <div
            className={styles.organizacionDropdown}
            style={{ marginLeft: mobile ? '0px' : '20px' }}
        >
            <Link
                to={to}
                className={styles.link + ' app-link'}
                onClick={(): void => {
                    handleClose?.();
                }}
            >
                <Button
                    style={{
                        textTransform: 'none',
                        fontFamily: 'Nunito',
                        fontSize: 18,
                        color: 'var(--accent)',
                    }}
                >
                    <div ref={refBtn}>Organización</div>
                </Button>
            </Link>
        </div>
    );
}
export default Organization;
