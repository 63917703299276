import { DraftEntityDataTypes } from '../../../../constants/Draft/DraftEntityDataTypes';
import NotificationPropertyTypes from '../../../../constants/NotificationPropertyTypes';
import Context from '../Context';
import { GenericContextProps } from '../TextEditorContext';

interface NotificationContextProps extends GenericContextProps {}

function NotificationContext({
    ...props
}: NotificationContextProps): JSX.Element {
    const type = DraftEntityDataTypes.NOTIFICATION;
    return (
        <>
            <Context
                {...props}
                data={{
                    type,
                    property: NotificationPropertyTypes.SUBJECT,
                }}
            />
        </>
    );
}

export default NotificationContext;
