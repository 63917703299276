import { AnyAction } from '@reduxjs/toolkit';
import { Agent, Area } from '../../../@Types/@Types';
import { AssignableAgent } from '../../../@Types/Agent';
import * as Actions from './AgentsActions';

export interface AgentsPageState {
    /** The currently displayed Agents */
    elements: AssignableAgent[];
    /** The currently active filters */
    areafilter: Area | null;
    /** The current search, undefined if none */
    search: string | undefined;
    /** the ids of the currently assigned agents */
    assignedAgents: Agent[];
    /** The number of pages currently loaded in the list */
    page: number;
    /** Active if no more pages are available */
    noMoreElements: boolean;
    /** If loader is active */
    loading: boolean;
    /** The current AssignChanges */
    changes: Record<string, boolean>;
    /** If filters are showing */
    showFilters: boolean;
}

const initialState = {
    elements: [],
    areafilter: null,
    search: undefined,
    assignedAgents: [],
    page: 1,
    noMoreElements: false,
    loading: true,
    changes: {},
    showFilters: false,
};

/**
 * Redux Reducer that handles AgentsActions Tiggers
 * @param state The current state
 * @param action the action that was triggered
 * @returns the new state
 */
const AgentsReducer = (
    state: AgentsPageState = initialState,
    action: AnyAction
): AgentsPageState => {
    if (Actions.resetAssign.match(action)) {
        return {
            ...state,
            elements: [],
            areafilter: null,
            search: undefined,
            assignedAgents: action.payload,
            page: 1,
            noMoreElements: false,
            loading: true,
            changes: {},
            showFilters: false,
        };
    } else if (Actions.setLoading.match(action)) {
        return {
            ...state,
            loading: action.payload,
        };
    } else if (Actions.getSuccess.match(action)) {
        return {
            ...state,
            loading: false,
            elements: action.payload.elements,
            noMoreElements: action.payload.noMoreElements,
        };
    } else if (Actions.pageScroll.match(action)) {
        return { ...state, page: state.page + 1 };
    } else if (Actions.selectElements.match(action)) {
        return {
            ...state,
            assignedAgents: action.payload.assigned,
            elements: action.payload.elements,
        };
    } else if (Actions.search.match(action)) {
        return {
            ...state,
            elements: [],
            page: 1,
            noMoreElements: false,
            loading: true,
            search: action.payload === '' ? undefined : action.payload,
        };
    } else if (Actions.filter.match(action)) {
        return {
            ...state,
            areafilter: action.payload,
            loading: true,
        };
    } else if (Actions.setChanges.match(action)) {
        return {
            ...state,
            changes: action.payload,
        };
    } else if (Actions.toggleFilters.match(action)) {
        return {
            ...state,
            showFilters: !state.showFilters,
        };
    }
    return state;
};
export default AgentsReducer;
