import { StepComponentProps } from '../../Step';
import styles from './FileStep.module.css';
import React from 'react';
import StepContainer from '../../StepContainer/StepContainer';
import ClientReply from '../../ClientReply/ClientReply';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import { FileStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import DraftRenderer from '../../../../../../shared/DraftRenderer/DraftRenderer';
import { EditorState, convertFromRaw } from 'draft-js';
interface FileStepProps extends StepComponentProps {
    step: FileStep;
}

function FileStepComponent({ step, path }: FileStepProps): JSX.Element {
    return (
        <React.Fragment>
            <StepContainer step={step} path={path}>
                <div className={styles.messageContainer}>
                    <DraftRenderer
                        editorState={EditorState.createWithContent(
                            convertFromRaw(step.message)
                        )}
                    />
                </div>
            </StepContainer>
            <ClientReply>
                <div className={styles.replyContent}>
                    <div className={styles.documentIcon}>
                        <DescriptionRoundedIcon fontSize="inherit" />
                    </div>
                    <div className={styles.w1}></div>
                </div>
            </ClientReply>
        </React.Fragment>
    );
}

export default FileStepComponent;
