import { useState, useEffect } from 'react';
import styles from './HomePage.module.css';
import TicketListItem from '../Tickets/TicketListItem/TicketListItem';
import ProjectCard from './ProjectCard/ProjectCard';
import ActivityCard from './ActivityCard/ActivityCard';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { changeProject } from '../../controllers/_SiteController/SiteActions';
import {
    loadPendingTickets,
    loadExpiredTickets,
    loadPendingTicketsPerProject,
    loadRecentActivities,
} from '../../controllers/HomeController/HomeService';
import { RootState } from '../../utils/_store';
import { Ticket } from '../../@Types/TicketTypes/Ticket';
import { Action } from '../../@Types/Action';
import { RouterProps } from '../../Router';

interface HomeInfo {
    pendingInfo: { tickets: Ticket[]; count: number };
    expiredInfo: { tickets: Ticket[]; count: number };
    activities: Action[];
    nTickets: Record<string, number>;
}

function Home({ mobile }: RouterProps): JSX.Element {
    const [today] = useState(new Date()); //Todo esta fecha se deberia recalcular en 1 hr?
    const siteInfo = useSelector((state: RootState) => state.site);
    const [homeInfo, setHomeInfo] = useState<HomeInfo | null>(null);

    const dispatch = useDispatch();

    const loadInfo = async (): Promise<void> => {
        const [pendingInfo, expiredInfo, activities, nTickets] =
            await Promise.all([
                loadPendingTickets(),
                loadExpiredTickets(),
                loadRecentActivities(),
                loadPendingTicketsPerProject(),
            ]);
        setHomeInfo({ pendingInfo, expiredInfo, activities, nTickets });
    };

    useEffect(() => {
        loadInfo();
    }, [siteInfo.projects]);

    if (homeInfo !== null) {
        return (
            <div className={styles.container}>
                <div className={styles.leftContainer}>
                    <div className={styles.topContainer}>
                        <div className={styles.projectsTitle}>
                            Mis Ambientes
                        </div>
                        <div className={styles.projectsContainer + ' flexbox'}>
                            {Object.values(siteInfo.projects)
                                .filter(
                                    (project) =>
                                        siteInfo.user?.permissions?.projects?.[
                                            project._id
                                        ]?.VIEW === true
                                )
                                .sort(
                                    (a, b) =>
                                        a.creation_date.getTime() -
                                        b.creation_date.getTime()
                                )
                                .map((project, index) => (
                                    <div
                                        key={index}
                                        className={styles.projectCardContainer}
                                    >
                                        <Link
                                            to={`/${project._id}/tickets`}
                                            className={'app-link'}
                                        >
                                            <ProjectCard
                                                handleClick={(): void => {
                                                    dispatch(
                                                        changeProject({
                                                            idProject:
                                                                project._id,
                                                        })
                                                    );
                                                }}
                                                project={project}
                                                nTickets={
                                                    homeInfo.nTickets[
                                                        project._id
                                                    ]
                                                }
                                            />
                                        </Link>
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className={styles.recentContainer}>
                        <div className={styles.activityTitle}>
                            Actividad Reciente
                        </div>
                        <div className={styles.containerActivities}>
                            {homeInfo.activities.map((activity, index) => (
                                <Link
                                    to={`/${activity.idProject}/tickets/${
                                        (activity.ticket as any)?._id
                                    }`}
                                    className={'app-link'}
                                    key={index}
                                >
                                    <ActivityCard activity={activity} />
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
                {!mobile && (
                    <div className={styles.rightColumn}>
                        {homeInfo.pendingInfo.count > 0 && (
                            <div>
                                <label className={styles.pendingTitle}>
                                    Casos Pendientes (
                                    {homeInfo.pendingInfo.count})
                                </label>
                                <div className={styles.pendingTicketsContainer}>
                                    {homeInfo.pendingInfo.tickets.map(
                                        (ticket, index) => (
                                            <Link
                                                to={`/${ticket.idProject}/tickets?search=${ticket.caseNumber}`}
                                                className={'app-link'}
                                                key={index}
                                            >
                                                <TicketListItem
                                                    key={index}
                                                    today={today}
                                                    element={ticket}
                                                />
                                            </Link>
                                        )
                                    )}
                                </div>
                            </div>
                        )}
                        {homeInfo.expiredInfo.count !== 0 && (
                            <div>
                                <label className={styles.expiredTitle}>
                                    Casos Vencidos ({homeInfo.expiredInfo.count}
                                    )
                                </label>
                                <div className={styles.pendingTicketsContainer}>
                                    {homeInfo.expiredInfo.tickets.map(
                                        (ticket, index) => (
                                            <Link
                                                to={`/${ticket.idProject}/tickets?search=${ticket.caseNumber}`}
                                                className={'app-link'}
                                                key={index}
                                            >
                                                <TicketListItem
                                                    key={index}
                                                    today={today}
                                                    element={ticket}
                                                />
                                            </Link>
                                        )
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    } else {
        return <div></div>;
    }
}
export default Home;
