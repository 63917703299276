import React from 'react';
import { IconButton } from '@material-ui/core';
import styles from './Interactions.module.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../utils/_store';
interface InteractionsProps {
    /** If interactions are being filtered */
    active: boolean;
    /** Function to call to toggle interactions */
    setActive: Function;
}

function Interactions({ active, setActive }: InteractionsProps): JSX.Element {
    const nInteractions = useSelector(
        (state: RootState) => state.ticketActions.numInteractions
    );
    return (
        <div className={styles.container} title="Interacciones">
            <IconButton
                aria-label="Interactions"
                style={{
                    width: 40,
                    height: 40,
                    padding: 0,
                }}
                onClick={(): void => {
                    setActive(!active);
                }}
            >
                <div className={styles.interaction}>
                    <div className={styles.badge}>{nInteractions}</div>
                    <img
                        alt={'Interaction'}
                        className={styles.interactionImg}
                        src={
                            active
                                ? '/media/icons/bluechat.svg'
                                : '/media/icons/chat.svg'
                        }
                    ></img>
                </div>
            </IconButton>
        </div>
    );
}
export default Interactions;
