import { MenuItem } from '@material-ui/core';
import React from 'react';
import ConditionOperators from '../../../constants/Conditions/ConditionOperators';
import SentimentTypes from '../../../constants/SentimentTypes';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import styles from './Condition.module.css';
import { SentimentCondition } from '../../../@Types/ConditionTypes/TicketPropertyConditions';
import { ConditionProps } from '../Condition';
import RoundedSelect from '../../RoundedSelect/RoundedSelect';

interface SentimentConditionProps extends ConditionProps<SentimentCondition> {}

function SentimentConditionComponent({
    condition,
    hoverDelete,
    handleUpdate,
}: SentimentConditionProps): JSX.Element {
    return (
        <React.Fragment>
            <div className={styles.operatorContainer}>
                <div className={styles.selectorContainer}>
                    <RoundedSelect
                        value={condition.operator ?? ''}
                        label="Operador"
                        containerMargin="0px"
                        error={hoverDelete}
                        handleUpdate={(event): void => {
                            handleUpdate({
                                ...condition,
                                operator: event.target.value,
                            });
                        }}
                    >
                        <MenuItem value={ConditionOperators.EQUAL}>
                            Es igual a
                        </MenuItem>
                        <MenuItem value={ConditionOperators.NOTEQUAL}>
                            No es igual a
                        </MenuItem>
                    </RoundedSelect>
                </div>
            </div>
            <div className={styles.valueContainer}>
                <div className={styles.multiSelectContainer}>
                    <RoundedSelect
                        value={condition.value ?? SentimentTypes.NONE}
                        label="Sentimiento"
                        containerMargin="0px"
                        error={hoverDelete}
                        handleUpdate={(event): void => {
                            handleUpdate({
                                ...condition,
                                value: event.target.value,
                            });
                        }}
                    >
                        <MenuItem
                            value={SentimentTypes.NONE}
                            style={{ paddingLeft: 10 }}
                        >
                            No Detectado
                        </MenuItem>
                        <MenuItem
                            value={SentimentTypes.NEGATIVE}
                            style={{ paddingLeft: 14 }}
                        >
                            <div className={styles.sentiment}>
                                <div className={styles.sentimentIconContainer}>
                                    <SentimentVeryDissatisfiedIcon fontSize="inherit" />
                                </div>
                                Negativo
                            </div>
                        </MenuItem>
                        <MenuItem
                            value={SentimentTypes.NEUTRAL}
                            style={{ paddingLeft: 14 }}
                        >
                            <div className={styles.sentiment}>
                                <div className={styles.sentimentIconContainer}>
                                    <SentimentSatisfiedIcon fontSize="inherit" />
                                </div>
                                Neutral
                            </div>
                        </MenuItem>
                        <MenuItem
                            value={SentimentTypes.POSITIVE}
                            style={{ paddingLeft: 14 }}
                        >
                            <div className={styles.sentiment}>
                                <div className={styles.sentimentIconContainer}>
                                    <SentimentSatisfiedAltIcon fontSize="inherit" />
                                </div>
                                Positivo
                            </div>
                        </MenuItem>
                    </RoundedSelect>
                </div>
            </div>
        </React.Fragment>
    );
}
export default SentimentConditionComponent;
