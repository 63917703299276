import EurekaForm from '../../../../../shared/FormRenderer/FormRenderer';
import { FormStyleTypes } from '@arquimedes.co/eureka-forms/dist/constants/FormStepTypes';
import FormStepTypes from '../../../../../constants/FormStepTypes';
import { ClienteInfo360 } from '../../../types';
import React from 'react';
import { OpenComponent } from '../../Utils/Openable/OpenComponent';
import { empStrOrUndToNA } from '../../Utils/otherFunctions';

interface datosPersonalProps {
    datosCliente: ClienteInfo360;
}

export function DatosPersonalesInfo({
    datosCliente,
}: datosPersonalProps): JSX.Element {
    return (
        <OpenComponent title={'Datos Personales'}>
            <EurekaForm
                form={{
                    size: {
                        blockNum: 4,
                        blockSize: 210,
                        spacingSize: 20,
                    },
                    terms: [],
                    isStandAlone: false,
                    sections: {
                        SECTION: {
                            name: '',
                            nextSection: null,
                            id: 'SECTION',
                            steps: [
                                'TEXTINPUT-f_aDPZbcrJ',
                                'TEXTINPUT-SDxR3ZMEi8',
                                'TEXTINPUT-7yRl4e64aL',
                                'TEXTINPUT-QpLGI7KXz6',
                                'TEXTINPUT-7yRl4e64aM',
                            ],
                        },
                    },
                    firstSection: 'SECTION',
                    name: 'Datos Contacto',
                    style: {
                        backgroundColor: '#ffffff',
                        errorColor: '#cc2936',
                        standAloneBackgroundColor: '#ffffff',
                        primaryColor: '#2cb5b9',
                        outlineColor: '#b8b8b8',
                        secondaryContrastColor: '#ea7a15',
                        type: FormStyleTypes.MATERIAL,
                        descriptionTextColor: '#989898',
                        stepBackgroundColor: '#ffffff',
                        textColor: '#293241',
                        primaryContrastColor: '#ffffff',
                        secondaryColor: '#376e70',
                    },
                    hasCaptcha: false,
                    steps: {
                        'TEXTINPUT-7yRl4e64aL': {
                            size: 2,
                            description: '',
                            isSubject: false,
                            id: 'TEXTINPUT-7yRl4e64aL',
                            label: 'Conyuge',
                            type: FormStepTypes.TEXTINPUT,
                            required: false,
                        },
                        'TEXTINPUT-SDxR3ZMEi8': {
                            size: 2,
                            description: '',
                            isSubject: false,
                            id: 'TEXTINPUT-SDxR3ZMEi8',
                            label: 'Edad',
                            type: FormStepTypes.TEXTINPUT,
                            required: false,
                        },
                        'TEXTINPUT-QpLGI7KXz6': {
                            size: 2,
                            description: '',
                            isSubject: false,
                            id: 'TEXTINPUT-QpLGI7KXz6',
                            label: 'Hijos',
                            type: FormStepTypes.TEXTINPUT,
                            required: false,
                        },
                        'TEXTINPUT-f_aDPZbcrJ': {
                            size: 2,
                            description: '',
                            isSubject: false,
                            id: 'TEXTINPUT-f_aDPZbcrJ',
                            label: 'Estado Civil',
                            type: FormStepTypes.TEXTINPUT,
                            required: false,
                        },
                        'TEXTINPUT-7yRl4e64aM': {
                            size: 2,
                            description: '',
                            isSubject: false,
                            id: 'TEXTINPUT-7yRl4e64aM',
                            label: 'Dirección',
                            type: FormStepTypes.TEXTINPUT,
                            required: false,
                        },
                    },
                }}
                values={{
                    'TEXTINPUT-f_aDPZbcrJ': empStrOrUndToNA(
                        datosCliente.datosPersonales.estadoCivil
                    ),
                    'TEXTINPUT-SDxR3ZMEi8':
                        datosCliente.datosPersonales.edad ?? 'NA',
                    'TEXTINPUT-7yRl4e64aL': empStrOrUndToNA(
                        datosCliente.datosPersonales.nombreConyugue
                    ),
                    'TEXTINPUT-QpLGI7KXz6':
                        datosCliente.datosPersonales.numeroHijos ?? 'NA',
                    'TEXTINPUT-7yRl4e64aM': empStrOrUndToNA(
                        datosCliente.residenciaActual.direccion
                    ),
                }}
                postview={true}
            />
        </OpenComponent>
    );
}
