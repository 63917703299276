import { useState } from 'react';
import { EntityValueOptionTypes } from '../../../../../constants/OptionTypes';
import StepComponent from '../../../../GenericFormEditor/Step/Step';
import styles from './NestedOption.module.css';
import AddStepBtn from '../../../../GenericFormEditor/AddStepBtn/AddStepBtn';
import { GEntityValuePicker } from '../../../../../@Types/GenericFormSteps';
import { GLocation } from '../../../../../@Types/FormTypes/LocationTypes';
import { OptionComponentProps } from '../Option';
function NestedOption<S extends GEntityValuePicker, U, L extends GLocation>({
    option,
    level,
    location,
    handleParentClose,
    stepMenuComponent,
    ...others
}: OptionComponentProps<S, U, L>): JSX.Element {
    const [openId, setOpenId] = useState<string | undefined>(undefined);
    if (option.type !== EntityValueOptionTypes.NESTED) {
        return <div></div>;
    }
    const mapSteps = (): JSX.Element[] => {
        return option.steps.map((idStep: string, index) => {
            return (
                <StepComponent<S, U, L>
                    {...others}
                    level={level + 1}
                    onOpen={(): void => {
                        setOpenId(idStep);
                    }}
                    canMoveUp={index !== 0}
                    canMoveDown={index !== option.steps.length - 1}
                    location={{ ...location, indexStep: index } as any}
                    key={index}
                    openId={openId}
                    idStep={idStep}
                    stepMenuComponent={stepMenuComponent}
                    handleParentClose={handleParentClose}
                />
            );
        });
    };

    return (
        <div className={styles.stepsContainer}>
            {mapSteps()}
            <div className={styles.addBtnContainer}>
                <AddStepBtn
                    size="35px"
                    location={location}
                    stepMenuComponent={stepMenuComponent}
                />
            </div>
        </div>
    );
}
export default NestedOption;
