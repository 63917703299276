import { useContext } from 'react';
import { TypedUseSelectorHook } from 'react-redux';
import { IdGenericEditorContext } from './GenericFormEditor';
import {
    DefaultGenericEditorState,
    GenericEditorState,
} from '../../controllers/GenericEditorController/GenericEditorReducer';
import { AppDispatch } from '../../utils/_store';
import { AnyAction } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '../../hooks';

export const useGenericEditorDispatch: () => AppDispatch = (): AppDispatch => {
    const idEditor = useContext(IdGenericEditorContext);
    const dispatch = useAppDispatch();

    const appDispatch = (action: AnyAction): AnyAction => {
        if (!action.meta?.idEditor) action.meta = { idEditor };
        return dispatch(action);
    };

    return appDispatch as AppDispatch;
};

export const useGenericEditorSelector: TypedUseSelectorHook<
    GenericEditorState<any, any, any, any, any>
> = (selector: any, options: any) => {
    const idEditor = useContext(IdGenericEditorContext);
    return useAppSelector(
        (state) =>
            selector(
                state.genericEditor[idEditor] ?? DefaultGenericEditorState
            ),
        options
    );
};
