import styles from './TextStep.module.css';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { TextStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import { useDispatch } from 'react-redux';
import {
    updateConv,
    updateStep,
} from '../../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import RoundedSelect from '../../../../../../shared/RoundedSelect/RoundedSelect';
import {
    StringValidations,
    StringValidationValues,
} from '../../../../../../constants/StringValidationTypes';
import { MenuItem } from '@material-ui/core';
import RoundedTextField from '../../../../../../shared/RoundedTextField/RoundedTextField';
import Toggle from '../../../../../../shared/Toggle/Toggle';
import { ClientInfoTypes } from '../../../../../../constants/ClientInfoTypes';
import EntityPropertyTypes from '../../../../../../constants/EntityPropertyTypes';
import RoundedEntityPropertyPicker from '../../../../../../shared/@Pickers/RoundedEntityPropertyPicker/RoundedEntityPropertyPicker';
import RoundedClientPropertyPicker from '../../../../../../shared/@Pickers/RoundedClientPropertyPicker/RoundedClientPropertyPicker';
import ClientPropertyTypes from '../../../../../../constants/ClientPropertyTypes';
import {
    ApiKeyContext,
    BaseConversationPayloadConditionTypes,
} from '../../../ConversationEditor';
import PayloadEditor from '../../../../../../shared/TextEditor/PayloadEditor';
import { TextEditorTypes } from '../../../../../../constants/TextEditorTypes';
import { useAppSelector } from '../../../../../../hooks';
import produce from 'immer';
import { ConversationAuthTypes } from '../../../../../../constants/Conversations/ConversationAuthTypes';
import ConversationStepTypes from '../../../../../../constants/Conversations/ConversationStepTypes';
import { stringToDraft } from '../../../../../../utils/draftFunctions';
import CBRIcon from '../../../../../../Icons/Custom/CBRIcon';
import RoundedConversationStepPicker from '../../../../../../shared/@Pickers/RoundedConversationStepPicker/RoundedConversationStepPicker';
import { nanoid } from '@reduxjs/toolkit';
interface TextStepProps {
    step: TextStep;
}

function TextStepComponent({
    step,
    showClientInfoType = true,
    showSubject = true,
    showCompanyMatcher = true,
    showValidation = true,
}: TextStepProps & {
    showClientInfoType?: boolean;
    showSubject?: boolean;
    showCompanyMatcher?: boolean;
    showValidation?: boolean;
}): JSX.Element {
    const dispatch = useDispatch();
    const apiKey = useContext(ApiKeyContext);
    const organization = useAppSelector((state) => state.site.organization);
    const [matchCompany, setMatchCompany] = useState(
        step.idCompanyMatcher !== undefined
    );
    const idCompanyEntity = useAppSelector(
        (state) => state.site.organization?.idCompanyEntity
    );

    const canAuth = useMemo(() => {
        return !!organization?.integrations.CBR;
    }, [organization]);

    const previousSteps = useAppSelector(
        (state) =>
            state.conversationEditor.stepDependencies[step.id ?? '']
                .previousSteps
    );

    useEffect(() => {
        setMatchCompany(step.idCompanyMatcher !== undefined);
    }, [step.id]);

    return (
        <div className={styles.container}>
            <div className={styles.editorContainer}>
                <PayloadEditor
                    placeholder={'Mensaje'}
                    type={TextEditorTypes.WHATSAPP}
                    idEditor={`Conversation-${apiKey}-${step.id}`}
                    payload={{ draft: step.message }}
                    onChange={({ draft: message }): void => {
                        if (!message) return;
                        dispatch(updateStep({ ...step, message }));
                    }}
                    media={false}
                    context={{ client: true }}
                    conditions={{
                        types: BaseConversationPayloadConditionTypes,
                        context: {
                            conversation: {
                                idStep: step.id,
                            },
                        },
                    }}
                    minHeight={144}
                    maxHeight={400}
                    required
                    maxLength={4096}
                />
            </div>
            <div className={styles.validationContainer}>
                <div className={styles.validationLabel}>Validación: </div>
                <div className={styles.validationSelectorContainer}>
                    <RoundedSelect
                        fullWidth
                        handleUpdate={(e): void => {
                            const tempStep = { ...step };
                            const type = e.target.value;
                            if (type) {
                                tempStep.validation =
                                    StringValidationValues[type];
                            } else {
                                delete tempStep.validation;
                            }
                            dispatch(updateStep(tempStep));
                        }}
                        value={step.validation?.type ?? ''}
                        label={''}
                        displayEmpty
                        MenuProps={{ disablePortal: true }}
                    >
                        <MenuItem value="" key="NONE">
                            <em>Ninguna</em>
                        </MenuItem>
                        {StringValidations.map((val) => (
                            <MenuItem value={val.type} key={val.type}>
                                {val.label}
                            </MenuItem>
                        ))}
                    </RoundedSelect>
                </div>
            </div>
            {showValidation && step.validation?.type === 'OTHER' && (
                <div className={styles.otherValidationContainer}>
                    <div className={styles.inputContainer}>
                        <RoundedTextField
                            label="Valor (Expressión Regular)"
                            value={step.validation.value}
                            onChange={(e): void => {
                                if (step.validation) {
                                    dispatch(
                                        updateStep({
                                            ...step,
                                            validation: {
                                                ...step.validation,
                                                value: e.target.value,
                                            },
                                        })
                                    );
                                }
                            }}
                        ></RoundedTextField>
                    </div>

                    <RoundedTextField
                        label="Mensaje de Error"
                        value={step.validation.message}
                        maxLength={1024}
                        onChange={(e): void => {
                            if (step.validation) {
                                dispatch(
                                    updateStep({
                                        ...step,
                                        validation: {
                                            ...step.validation,
                                            message: e.target.value,
                                        },
                                    })
                                );
                            }
                        }}
                    ></RoundedTextField>
                </div>
            )}
            {canAuth && (
                <div className={styles.authContainer}>
                    <div className={styles.authLabelContainer}>
                        Autenticación:
                        <div className={styles.toggleContainer}>
                            <Toggle
                                checked={!!step.authentication}
                                onChange={(checked: boolean): void => {
                                    if (checked) {
                                        const idMessage =
                                            ConversationStepTypes.INFO_TEXT_STEP +
                                            '-' +
                                            nanoid(10);
                                        const idBack =
                                            ConversationStepTypes.NAV_BACK_STEP +
                                            '-' +
                                            nanoid(10);
                                        dispatch(
                                            updateConv({
                                                step: produce(step, (step) => {
                                                    step.authentication = {
                                                        type: ConversationAuthTypes.CBR_IDENTIFIER,
                                                        idIdentifierStep: '',
                                                        errorSteps: [
                                                            idMessage,
                                                            idBack,
                                                        ],
                                                    };
                                                }),
                                                addSteps: [
                                                    {
                                                        type: ConversationStepTypes.INFO_TEXT_STEP,
                                                        message: stringToDraft(
                                                            'La fecha proporcionada no es correcta.'
                                                        ),
                                                        id: idMessage,
                                                        name: 'Mensaje de Autenticación Fallida',
                                                    },
                                                    {
                                                        type: ConversationStepTypes.NAV_BACK_STEP,
                                                        id: idBack,
                                                        idBackStep: step.id,
                                                        name: 'Volver a Intentar',
                                                    },
                                                ],
                                            })
                                        );
                                    } else if (step.authentication) {
                                        dispatch(
                                            updateConv({
                                                deleteSteps:
                                                    step.authentication
                                                        .errorSteps,
                                                step: produce(step, (step) => {
                                                    delete step.authentication;
                                                }),
                                            })
                                        );
                                    }
                                }}
                            />
                        </div>
                    </div>
                    {step.authentication && (
                        <>
                            <div className={styles.authSelectContainer}>
                                <RoundedSelect
                                    fullWidth
                                    handleUpdate={(e): void => {
                                        dispatch(
                                            updateStep(
                                                produce(step, (step) => {
                                                    step.authentication!.type =
                                                        e.target.value;
                                                })
                                            )
                                        );
                                    }}
                                    value={step.authentication?.type ?? ''}
                                    label={'Autenticación'}
                                    displayEmpty
                                    MenuProps={{ disablePortal: true }}
                                >
                                    {organization?.integrations.CBR && (
                                        <MenuItem
                                            value={
                                                ConversationAuthTypes.CBR_IDENTIFIER
                                            }
                                        >
                                            <div
                                                className={styles.cbrValidation}
                                            >
                                                <CBRIcon
                                                    style={{
                                                        width: 28,
                                                        height: 28,
                                                        marginLeft: 10,
                                                        marginRight: 10,
                                                    }}
                                                />
                                                Fecha de Nacimiento
                                            </div>
                                        </MenuItem>
                                    )}
                                    {organization?.integrations.CBR && (
                                        <MenuItem
                                            value={
                                                ConversationAuthTypes.CBR_COMPANY_IDENTIFIER
                                            }
                                        >
                                            <div
                                                className={styles.cbrValidation}
                                            >
                                                <CBRIcon
                                                    style={{
                                                        width: 28,
                                                        height: 28,
                                                        marginLeft: 10,
                                                        marginRight: 10,
                                                    }}
                                                />
                                                Fecha de la Empresa
                                            </div>
                                        </MenuItem>
                                    )}
                                </RoundedSelect>
                            </div>
                            <div className={styles.authSelectContainer}>
                                <RoundedConversationStepPicker
                                    stepOrder={previousSteps}
                                    value={step.authentication.idIdentifierStep}
                                    label="Identificación"
                                    handleUpdate={(identifierStep): void => {
                                        dispatch(
                                            updateStep(
                                                produce(step, (step) => {
                                                    step.authentication!.idIdentifierStep =
                                                        identifierStep.id;
                                                })
                                            )
                                        );
                                    }}
                                ></RoundedConversationStepPicker>
                            </div>
                        </>
                    )}
                </div>
            )}
            {showSubject && (
                <div className={styles.toggleLabelContainer}>
                    Asunto del Caso:
                    <div className={styles.toggleContainer}>
                        <Toggle
                            checked={step.isSubject}
                            onChange={(checked: boolean): void => {
                                dispatch(
                                    updateStep({ ...step, isSubject: checked })
                                );
                            }}
                        />
                    </div>
                </div>
            )}
            {/* <div className={styles.toggleLabelContainer}>
                Mostrar en la Traza:
                <div className={styles.toggleContainer}>
                    <Toggle
                        checked={step.showInTrace}
                        onChange={(checked: boolean): void => {
                            dispatch(
                                updateStep({ ...step, showInTrace: checked })
                            );
                        }}
                    />
                </div>
            </div> */}
            {showClientInfoType && (
                <div className={styles.clientContainer}>
                    <div className={styles.toggleLabelContainer}>
                        <div className={styles.clientLbl}>
                            Información del Cliente:
                        </div>
                        <div className={styles.toggleContainer}>
                            <Toggle
                                checked={
                                    step.clientInfoType !== undefined ||
                                    step.idClientProperty !== undefined
                                }
                                onChange={(checked: boolean): void => {
                                    const tempStep = checked
                                        ? {
                                              ...step,
                                              clientInfoType:
                                                  ClientInfoTypes.NAME,
                                          }
                                        : {
                                              ...step,
                                              clientInfoType: undefined,
                                              idClientProperty: undefined,
                                          };
                                    dispatch(updateStep(tempStep));
                                }}
                            />
                        </div>
                    </div>
                    {(step.clientInfoType !== undefined ||
                        step.idClientProperty !== undefined) && (
                        <React.Fragment>
                            <div className={styles.clientInfoContainer}>
                                <div className={styles.clientInfoLabel}>
                                    Tipo:{' '}
                                </div>
                                <div
                                    className={
                                        styles.clientInfoSelectorContainer
                                    }
                                >
                                    <RoundedClientPropertyPicker
                                        height="31px"
                                        fullWidth
                                        label={''}
                                        MenuProps={{ disablePortal: true }}
                                        value={
                                            step.clientInfoType ??
                                            step.idClientProperty
                                        }
                                        propertyTypes={[
                                            ClientPropertyTypes.CLIENT_INFO_TYPE,
                                            EntityPropertyTypes.TEXTINPUT,
                                        ]}
                                        handleUpdate={(idProperty): void => {
                                            if (
                                                Object.values(
                                                    ClientInfoTypes
                                                ).includes(
                                                    idProperty as ClientInfoTypes
                                                )
                                            ) {
                                                dispatch(
                                                    updateStep({
                                                        ...step,
                                                        clientInfoType:
                                                            idProperty as ClientInfoTypes,
                                                        idClientProperty:
                                                            undefined,
                                                    })
                                                );
                                            } else {
                                                dispatch(
                                                    updateStep({
                                                        ...step,
                                                        clientInfoType:
                                                            undefined,
                                                        idClientProperty:
                                                            idProperty,
                                                    })
                                                );
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            )}
            {showCompanyMatcher && idCompanyEntity && (
                <React.Fragment>
                    <div className={styles.toggleLabelContainer}>
                        Vincular Empresa:
                        <div className={styles.toggleContainer}>
                            <Toggle
                                checked={matchCompany}
                                onChange={(checked: boolean): void => {
                                    if (!checked) {
                                        dispatch(
                                            updateStep({
                                                ...step,
                                                idCompanyMatcher: undefined,
                                            })
                                        );
                                    }
                                    setMatchCompany(checked);
                                }}
                            />
                        </div>
                    </div>
                    {matchCompany && (
                        <div className={styles.propertySelectorContainer}>
                            <RoundedEntityPropertyPicker
                                height="31px"
                                idEntity={idCompanyEntity}
                                value={step.idCompanyMatcher}
                                label="Propiedad"
                                autoWidth
                                propertyTypes={[EntityPropertyTypes.TEXTINPUT]}
                                handleUpdate={(property): void => {
                                    if (
                                        property?.type ===
                                        EntityPropertyTypes.TEXTINPUT
                                    ) {
                                        dispatch(
                                            updateStep({
                                                ...step,
                                                idCompanyMatcher: property.id,
                                            })
                                        );
                                    }
                                }}
                            />
                        </div>
                    )}
                </React.Fragment>
            )}
            {(step.clientInfoType !== undefined ||
                step.idClientProperty !== undefined ||
                matchCompany) && (
                <div className={styles.recurringLabelContainer}>
                    <div className={styles.clientLbl}>Preguntar Siempre:</div>
                    <div className={styles.toggleContainer}>
                        <Toggle
                            checked={step.recurring === true}
                            onChange={(checked: boolean): void => {
                                dispatch(
                                    updateStep({
                                        ...step,
                                        recurring: checked,
                                    })
                                );
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default TextStepComponent;
