import { useEffect, useState } from 'react';
import styles from './WhatsappTicketEntry.module.css';
import { useSelector } from 'react-redux';
import { WhatsappTicketEntry } from '../../../../../@Types/TicketTypes/Ticket';
import { RootState } from '../../../../../utils/_store';
import FormRenderer from '../../../../../shared/FormRenderer/FormRenderer';
import { loadFormHistory } from '../../../../../controllers/FormsController/FormsService';
import { Form } from '../../../../../@Types/@Types';

interface FormPostViewProps {
    /** The values sent by the user to display */
    values: Record<string, any>;
    /** The ticket's the Entry */
    entry: WhatsappTicketEntry;
}
/**
 * Component rendered inside the postview dialog.
 */
function WhatsappPostView({ entry, values }: FormPostViewProps): JSX.Element {
    const idProject = useSelector((state: RootState) => state.site.idProject);
    const [form, setForm] = useState<Form | undefined | null>(undefined);

    const fetchForm = async (): Promise<void> => {
        if (idProject) {
            try {
                const response = await loadFormHistory(idProject, entry.idForm);
                setForm(response);
            } catch (error) {
                setForm(null);
            }
        }
    };
    useEffect(() => {
        fetchForm();
    }, []);

    if (form === undefined) return <div></div>;
    return (
        <div className={styles.container}>
            <div className={styles.containerLbl}>{entry.name}</div>
            {!form && (
                <div className={styles.formNotFound}>
                    No fue posible cargar la conversación
                </div>
            )}
            {form && (
                <div
                    className={styles.widgetContainer}
                    style={{
                        width: form.size
                            ? (form.size.blockSize + form.size.spacingSize) *
                                  form.size.blockNum +
                              40
                            : 980,
                    }}
                >
                    <FormRenderer
                        postview={true}
                        form={form}
                        values={values}
                        partial
                    />
                </div>
            )}
        </div>
    );
}

export default WhatsappPostView;
