import React, { cloneElement, useState, useRef, useEffect } from 'react';
import styles from './EditContainer.module.css';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import RoundedIconButton from '../RoundedIconButton/RoundedIconButton';
import CancelIcon from '@material-ui/icons/CloseRounded';
import ConfirmIcon from '@material-ui/icons/CheckRounded';

//TODO : VOLVER A HACER ESTO!
interface EditContainerProps {
    /** Function to call when the user confirms the changes */
    handleConfirm: Function;
    /** The children to render first the label and then the input to show when editing */
    children: any;
    /** The field to edit */
    field: any;
    /** The object to edit */
    object: any;
    /** The validator of the error*/
    validatorManager?: ValidatorManager;
}

interface ValidatorManager {
    validator: (value: any) => boolean;
    errorMessage: string;
}

function EditContainer({
    handleConfirm,
    children,
    field,
    object,
    validatorManager,
}: EditContainerProps): JSX.Element {
    const [open, setOpen] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const buttonsRef = useRef<HTMLDivElement>(null);
    const [errorInput, setErrorInput] = useState(false);

    const confirm = (): void => {
        if (field !== undefined && object !== undefined && inputRef.current) {
            handleConfirm(object, field, inputRef.current.value);
        } else {
            handleConfirm();
        }
        setOpen(false);
    };

    useEffect(() => {
        setOpen(false);
    }, [object]);

    const inputProps: {
        open: boolean;
        ref: any;
        onChange: any;
        style: any;
    } = {
        open,
        ref: undefined,
        onChange: undefined,
        style: errorInput
            ? { ...children[1].props.style, color: 'var(--error)' }
            : children[1].props.style,
    };
    if (field !== undefined && object !== undefined) {
        inputProps.ref = inputRef;
    }

    if (validatorManager) {
        inputProps.onChange = (): void => {
            if (inputRef.current)
                setErrorInput(
                    !validatorManager.validator(inputRef.current.value)
                );
        };
    }

    return (
        <div
            className={
                open
                    ? styles.container + ' ' + styles.hovered
                    : styles.container
            }
            onClick={(event): void => {
                if (!buttonsRef.current?.contains(event.target as any)) {
                    setOpen(true);
                }
            }}
        >
            <ClickAwayListener
                onClickAway={(event): void => {
                    if (!open) return;
                    if (!buttonsRef.current?.contains(event.target as any)) {
                        if (!errorInput) {
                            confirm();
                        }
                    }
                }}
            >
                <div>
                    <div
                        style={{
                            visibility: open ? 'hidden' : 'visible',
                            height: open ? 0 : 'auto',
                        }}
                    >
                        {cloneElement(children[0], {
                            open,
                        })}
                    </div>
                    <div>{open && cloneElement(children[1], inputProps)}</div>
                </div>
            </ClickAwayListener>
            {open && (
                <div
                    className={styles.confirmationContainer + ' flexbox'}
                    ref={buttonsRef}
                >
                    <div
                        className={styles.confirmationButton}
                        data-testid={'EditContainer_ConfirmButton'}
                    >
                        <RoundedIconButton
                            backgroundColor="var(--secondary)"
                            color="white"
                            onClick={(): void => {
                                confirm();
                            }}
                            disabled={errorInput}
                        >
                            <ConfirmIcon />
                        </RoundedIconButton>
                    </div>
                    <div className={styles.confirmationButton}>
                        <RoundedIconButton
                            color="var(--accent)"
                            backgroundColor="var(--defaultGrey)"
                            onClick={(): void => {
                                setOpen(false);
                                setErrorInput(false);
                            }}
                        >
                            <CancelIcon />
                        </RoundedIconButton>
                    </div>
                </div>
            )}
            {errorInput && (
                <div className={styles.errorMessage}>
                    {validatorManager?.errorMessage}
                </div>
            )}
        </div>
    );
}
export default EditContainer;
