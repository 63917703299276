import styles from './EntityValueStep.module.css';
import React, { useContext, useRef } from 'react';
import { EntityValueStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import { useDispatch } from 'react-redux';
import { updateStep } from '../../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import RoundedTextField from '../../../../../../shared/RoundedTextField/RoundedTextField';
import EntityPropertyTypes from '../../../../../../constants/EntityPropertyTypes';
// import Toggle from '../../../../../../shared/Toggle/Toggle';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import {
    EntityValueFilterTypes,
    EntityValuePathTypes,
} from '../../../../../../constants/FormStepTypes';
import EntityValuePath from '../../../../../../shared/@Steps/EntityValueStep/EntityValuePath/EntityValuePath';
import EntityValueFilter from '../../../../../../shared/@Steps/EntityValueStep/EntityValueFilter/EntityValueFilter';
import PayloadEditor from '../../../../../../shared/TextEditor/PayloadEditor';
import {
    ApiKeyContext,
    BaseConversationPayloadConditionTypes,
} from '../../../ConversationEditor';
import { TextEditorTypes } from '../../../../../../constants/TextEditorTypes';
import IntegrationTypes from '../../../../../../constants/IntegrationTypes';
import { IntegrationsApi } from '../../../../../../controllers/IntegratrionsController/IntegrationsService';
import { useAppSelector, useIdProject } from '../../../../../../hooks';
import { EntityValueFilterIntegration } from '../../../../../../@Types/Integration';

interface EntityValueStepProps {
    step: EntityValueStep;
}

function EntityValueStepComponent({ step }: EntityValueStepProps): JSX.Element {
    const dispatch = useDispatch();
    const apiKey = useContext(ApiKeyContext);
    const addBtnRef = useRef<any>();

    const entity = useAppSelector(
        (state) => state.site.entities[step.idEntity]
    );

    const properties = Object.values(entity.steps).filter(
        (property) => property.type === EntityPropertyTypes.ENTITY_RELATIONSHIP
    );

    const idProject = useIdProject();

    const { data: integrations } = IntegrationsApi.useLoadIntegrationsQuery({
        idEntity: entity._id,
        idProject,
        type: IntegrationTypes.ENTITY_VALUES_FILTER,
    });

    const newProperty = properties.filter(
        (property) =>
            !step.filters.find((filter) => filter.idProperty === property.id)
    )[0];

    const newIntegration = integrations?.filter(
        (integration) =>
            !step.filters.find(
                (filter) =>
                    filter.type === EntityValueFilterTypes.INTEGRATION &&
                    filter.idIntegration === integration._id
            )
    )[0];

    const canFilter =
        properties.length > 0 || (integrations && integrations?.length > 0);

    return (
        <React.Fragment>
            <div className={styles.headerContainer}>
                <RoundedTextField
                    label="Encabezado"
                    multiline
                    maxLength={60}
                    value={step.header}
                    onChange={(e): void => {
                        dispatch(
                            updateStep({ ...step, header: e.target.value })
                        );
                    }}
                ></RoundedTextField>
            </div>
            <div className={styles.container}>
                <div className={styles.editorContainer}>
                    <PayloadEditor
                        placeholder={'Mensaje'}
                        type={TextEditorTypes.WHATSAPP}
                        idEditor={`Conversation-${apiKey}-${step.id}`}
                        payload={{ draft: step.message }}
                        onChange={({ draft: message }): void => {
                            if (!message) return;
                            dispatch(updateStep({ ...step, message }));
                        }}
                        media={false}
                        context={{ client: true }}
                        conditions={{
                            types: BaseConversationPayloadConditionTypes,
                            context: {
                                conversation: {
                                    idStep: step.id,
                                },
                            },
                        }}
                        minHeight={144}
                        maxHeight={400}
                        required
                        maxLength={4096}
                    />
                </div>
            </div>
            <div className={styles.footerContainer}>
                <RoundedTextField
                    label="Pie"
                    multiline
                    maxLength={60}
                    value={step.footer}
                    onChange={(e): void => {
                        dispatch(
                            updateStep({ ...step, footer: e.target.value })
                        );
                    }}
                ></RoundedTextField>
            </div>
            <div className={styles.btnLblContainer}>
                <RoundedTextField
                    label="Botón"
                    value={step.label}
                    maxLength={20}
                    onChange={(e): void => {
                        dispatch(
                            updateStep({
                                ...step,
                                label: e.target.value,
                            })
                        );
                    }}
                ></RoundedTextField>
            </div>
            <div className={styles.pathContainer}>
                {step.path.map((path, index) => {
                    if (
                        path.type === EntityValuePathTypes.VALUE &&
                        path.idEntityValue === null
                    )
                        return <div key={path.idEntity + '' + index}></div>;
                    return (
                        <EntityValuePath
                            path={path}
                            key={path.idEntity + '' + index}
                            handleUpdate={(path): void => {
                                const newPath = [...step.path];
                                newPath[index] = path;
                                dispatch(
                                    updateStep({
                                        ...step,
                                        path: newPath,
                                    })
                                );
                            }}
                        />
                    );
                })}
            </div>
            {/* <div className={styles.toggleLabelContainer}>
                Mostrar en la Traza:
                <div className={styles.toggleContainer}>
                    <Toggle
                        checked={step.showInTrace}
                        onChange={(checked: boolean): void => {
                            dispatch(
                                updateStep({ ...step, showInTrace: checked })
                            );
                        }}
                    />
                </div>
            </div> */}
            {canFilter && (
                <React.Fragment>
                    <div className={styles.filtersLabel}>Filtros:</div>
                    <div className={styles.filtersContainer}>
                        {step.filters.map((filter, index) => (
                            <EntityValueFilter
                                key={index}
                                filter={filter}
                                idEntity={step.idEntity}
                                elementsToOmit={step.filters.map((filter) =>
                                    filter.type ===
                                    EntityValueFilterTypes.INTEGRATION
                                        ? filter.idIntegration
                                        : filter.idProperty
                                )}
                                showRequired={false}
                                handleUpdate={(filter): void => {
                                    const newFilters = [...step.filters];
                                    newFilters[index] = filter;
                                    dispatch(
                                        updateStep({
                                            ...step,
                                            filters: newFilters,
                                        })
                                    );
                                }}
                                handleDelete={(): void => {
                                    const newFilters = [...step.filters];
                                    newFilters.splice(index, 1);
                                    dispatch(
                                        updateStep({
                                            ...step,
                                            filters: newFilters,
                                        })
                                    );
                                }}
                                context={{ conversation: { idStep: step.id } }}
                            />
                        ))}
                        {(newIntegration || newProperty) && (
                            <div
                                className={styles.addOption}
                                onClick={(): void => {
                                    if (newIntegration) {
                                        dispatch(
                                            updateStep({
                                                ...step,
                                                filters: [
                                                    ...step.filters,
                                                    {
                                                        idProperty: (
                                                            newIntegration as EntityValueFilterIntegration
                                                        ).idProperty,
                                                        type: EntityValueFilterTypes.INTEGRATION,
                                                        idIntegration:
                                                            newIntegration._id,
                                                    },
                                                ],
                                            })
                                        );
                                    } else if (
                                        newProperty?.type ===
                                        EntityPropertyTypes.ENTITY_RELATIONSHIP
                                    ) {
                                        dispatch(
                                            updateStep({
                                                ...step,
                                                filters: [
                                                    ...step.filters,
                                                    {
                                                        idProperty:
                                                            newProperty.id,
                                                        type: EntityValueFilterTypes.STEP,
                                                        any: true,
                                                        required: false,
                                                    },
                                                ],
                                            })
                                        );
                                    } else {
                                        dispatch(
                                            updateStep({
                                                ...step,
                                                filters: [
                                                    ...step.filters,
                                                    {
                                                        idProperty:
                                                            newProperty.id,
                                                        type: EntityValueFilterTypes.VALUE,
                                                        value: [],
                                                    },
                                                ],
                                            })
                                        );
                                    }
                                }}
                                ref={addBtnRef}
                            >
                                <div className={styles.addIconContainer}>
                                    <AddRoundedIcon fontSize="inherit" />
                                </div>
                                <div
                                    className={
                                        styles.addOptionLbL + ' noselect'
                                    }
                                >
                                    Agregar Filtro
                                </div>
                            </div>
                        )}
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default EntityValueStepComponent;
