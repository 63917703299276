import styles from './AYFCityConfig.module.css';
import React from 'react';
import { UniqueSteps } from '../../../../../@Types/FormTypes/Form';
import { AYFCityStep } from '../../../../../@Types/FormTypes/AYFFormStep';
import RoundedFormStepPicker from '../../../../../shared/@Pickers/RoundedFormStepPicker/RoundedFormStepPicker';
import FormStepTypes from '../../../../../constants/FormStepTypes';
import { useFormEditorSelector } from '../../../FormReducerFunctions';

interface AYFCityConfigProps {
    step?: AYFCityStep;
    uniqueSteps?: UniqueSteps;
    updateUniqueSteps?: (unique: UniqueSteps) => void;
    updateStep?: (step: AYFCityStep) => void;
}
function AYFCityConfig({
    step,
    updateStep,
    uniqueSteps,
    updateUniqueSteps,
}: AYFCityConfigProps): JSX.Element {
    const steps = useFormEditorSelector((state) => state.steps);
    if (!step || !uniqueSteps || !updateUniqueSteps || !updateStep) {
        return <div></div>;
    }

    return (
        <div className={styles.nitContainer}>
            <RoundedFormStepPicker
                steps={Object.values(steps)}
                value={step.idNitStep}
                label="Paso de Nit"
                autoWidth
                stepTypes={[FormStepTypes.TEXTINPUT]}
                handleUpdate={(idNitStep): void => {
                    updateStep({
                        ...step,
                        idNitStep,
                    });
                }}
            />
        </div>
    );
}
export default AYFCityConfig;
