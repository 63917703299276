import React from 'react';
import { IntegrationsApi } from '../../../controllers/IntegratrionsController/IntegrationsService';
import { ConditionContext } from '../ConditionEditor';
import { ConditionIntegration } from '../../../@Types/Integration';
import styles from './Condition.module.css';
import { MenuItem } from '@material-ui/core';
import RoundedSelect from '../../RoundedSelect/RoundedSelect';
import ConditionOperators from '../../../constants/Conditions/ConditionOperators';
import { IntegrationCondition } from '../../../@Types/ConditionTypes/IntegrationConditions';
import RoundedTextField from '../../RoundedTextField/RoundedTextField';
import RoundedMultiSelect from '../../RoundedMultiSelect/RoundedMultiSelect';
import { skipToken } from '@reduxjs/toolkit/query';

type PIntegrationCondition = Pick<
    IntegrationCondition,
    'integrationOperator' | 'values' | 'value'
>;

interface IntegrationConditionProps {
    idIntegration: string;
    context: ConditionContext;
    condition: PIntegrationCondition;
    hoverDelete: boolean;
    handleUpdate: (condition: PIntegrationCondition) => void;
}

function IntegrationConditionComponent({
    // context,
    condition,
    hoverDelete,
    handleUpdate,
    idIntegration,
}: IntegrationConditionProps): JSX.Element {
    const { data } = IntegrationsApi.useLoadIntegrationQuery(
        idIntegration ?? skipToken
    );

    const integration: ConditionIntegration | undefined =
        data as ConditionIntegration;

    const renderPropertyOperator = (): JSX.Element => {
        const operators = integration?.operators;
        if (!operators) return <></>;
        return (
            <div className={styles.selectorContainer}>
                <RoundedSelect
                    value={condition.integrationOperator ?? ''}
                    label="Operador"
                    containerMargin="0px"
                    error={hoverDelete}
                    handleUpdate={(event): void => {
                        handleUpdate({
                            ...condition,
                            integrationOperator: event.target.value,
                        });
                    }}
                >
                    {operators.includes(ConditionOperators.EQUAL) && (
                        <MenuItem value={ConditionOperators.EQUAL}>
                            Igual a
                        </MenuItem>
                    )}
                    {operators.includes(ConditionOperators.EQUAL) && (
                        <MenuItem value={ConditionOperators.NOTEQUAL}>
                            No igual a
                        </MenuItem>
                    )}
                    {operators.includes(ConditionOperators.EQUAL) && (
                        <MenuItem value={ConditionOperators.LESS}>
                            Menor que
                        </MenuItem>
                    )}
                    {operators.includes(ConditionOperators.EQUAL) && (
                        <MenuItem value={ConditionOperators.MORE}>
                            Mayor que
                        </MenuItem>
                    )}
                </RoundedSelect>
            </div>
        );
    };

    const renderValue = (): JSX.Element => {
        if (!integration) return <></>;
        switch (integration.valueType) {
            case 'string': {
                return (
                    <div
                        style={{
                            display: 'flex',
                            margin: '0px 5px',
                        }}
                    >
                        <div className={styles.selectorContainer}>
                            <RoundedTextField
                                label={integration.valueLabel}
                                value={condition.value ?? ''}
                                height="40px"
                                error={hoverDelete}
                                onChange={(event): void => {
                                    handleUpdate({
                                        ...condition,
                                        value: event.target.value as any,
                                    });
                                }}
                            />
                        </div>
                    </div>
                );
            }
            case 'string-list': {
                return (
                    <div
                        style={{
                            display: 'flex',
                            margin: '0px 5px',
                        }}
                    >
                        <div className={styles.multiSelectContainer}>
                            <RoundedMultiSelect
                                label={integration.valueLabel}
                                values={condition.value ?? []}
                                error={hoverDelete}
                                handleUpdate={(values: string[]): void => {
                                    handleUpdate({
                                        ...condition,
                                        value: values,
                                    });
                                }}
                            />
                        </div>
                    </div>
                );
            }
            default:
                return <></>;
        }
    };

    //TODO: Condiciones con contexto especifico
    return (
        <React.Fragment>
            {integration?.operators && (
                <div className={styles.operatorContainer}>
                    {renderPropertyOperator()}
                </div>
            )}
            {renderValue()}
        </React.Fragment>
    );
}

export default IntegrationConditionComponent;
