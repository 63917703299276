import { IconProps } from '@material-ui/core';
import FormStepTypes from '../constants/FormStepTypes';
import TitleIcon from './TitleIcon';
import TextIcon from './TextIcon';
import AgentIcon from './AgentIcon';
import ClientIcon from './ClientIcon';
import CalendarIcon from './CalendarIcon';
import ParagraphIcon from './ParagraphIcon';
import OptionsIcon from './OptionsIcon';
import CheckboxIcon from './CheckboxIcon';
import ClassifierIcon from './ClassifierIcon';
import ApiIcon from './ApiIcon';
import HandShakeIcon from './Entities/HandshakeIcon';
import FileIcon from './FileIcon';
import SmartIcon from '../shared/SmartIcons/SmartIcon';
import StarIcon from './StarIcon';
import MapperIcon from './MapperIcon';
import ClockIcon from './ClockIcon';

interface FormStepIconProps extends IconProps {
    type: FormStepTypes;
}

function FormStepIcon({ type, ...props }: FormStepIconProps): JSX.Element {
    switch (type) {
        case FormStepTypes.TITLE:
            return <TitleIcon {...props} />;
        case FormStepTypes.TEXTINPUT:
            return <TextIcon {...props} />;
        case FormStepTypes.AGENTPICKER:
            return <AgentIcon {...props} />;
        case FormStepTypes.CLIENTPICKER:
            return <ClientIcon {...props} />;
        case FormStepTypes.DATEPICKER:
            return <CalendarIcon {...props} />;
        case FormStepTypes.TIMEPICKER:
            return <ClockIcon {...props} />;
        case FormStepTypes.TEXTAREA:
            return <ParagraphIcon {...props} />;
        case FormStepTypes.SELECTOR:
            return <OptionsIcon {...props} />;
        case FormStepTypes.CHECKBOX:
            return <CheckboxIcon {...props} />;
        case FormStepTypes.CLASSIFIER_SELECTOR:
            return <ClassifierIcon {...props} />;
        case FormStepTypes.API_SELECTOR:
            return <ApiIcon {...props} />;
        case FormStepTypes.COMPANYPICKER:
            return <HandShakeIcon {...props} />;
        case FormStepTypes.ENTITYVALUEPICKER:
            return <SmartIcon {...props} />;
        case FormStepTypes.FILEUPLOAD:
            return <FileIcon {...props} />;
        case FormStepTypes.RATING:
            return <StarIcon {...props} />;
        case FormStepTypes.MAPPER:
            return <MapperIcon {...props} />;
        case FormStepTypes.COLLAPSIBLE:
        case FormStepTypes.SEPARATOR:
        default:
            return <></>;
    }
}
export default FormStepIcon;
