import ConditionTypes from '../../constants/Conditions/ConditionTypes';
import { Condition } from '../../@Types/ConditionTypes/Condition';
import {
    calcCondition,
    ConditionContext,
    setupConditionEditor,
    clearConditionEditor,
    updateCondition,
} from '../../controllers/ConditionEditorController/ConditionEditorSlice';
import { nanoid } from '@reduxjs/toolkit';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from '../../hooks';
import ConditionComponent from './Condition';

export interface ConditionContext {
    form?: {
        idStep: string;
    };
    conversation?: {
        idStep: string;
    };
    entityValue?: {
        idEntity: string;
        public?: boolean;
    };
}

export interface ConditionEditorProps<C extends Condition> {
    idCondition?: string;
    children?: React.ReactNode;
    condition: C | undefined;
    conditionTypes: ConditionTypes[];
    /** Function called when the component closes */
    handleUpdate: ((condition: C | undefined) => void) | null;
    handleDelete?: () => void;
    context?: ConditionContext;
}

function ConditionEditor<C extends Condition>({
    children,
    condition = { type: 'DEFAULT' } as any,
    handleUpdate,
    handleDelete,
    conditionTypes,
    context = {},
    ...props
}: ConditionEditorProps<C>): JSX.Element {
    const [idCondition] = useState(props.idCondition ?? nanoid());

    const dispatch = useAppDispatch();

    const updateChanges = async (): Promise<void> => {
        const condition: C | undefined = await dispatch(
            calcCondition({ idCondition }) as any
        ).unwrap();
        if (handleUpdate) handleUpdate(condition);
        dispatch(clearConditionEditor([idCondition]));
    };

    useEffect(() => {
        dispatch(setupConditionEditor({ [idCondition]: condition }));
        return (): void => {
            updateChanges();
        };
    }, []);

    const handleDeleteRoot = useCallback(() => {
        const action = updateCondition({
            idCondition,
            condition: { type: 'DEFAULT' },
        });
        (action as any).meta = { idCondition };
        dispatch(action);
        handleDelete?.();
    }, []);

    return (
        <ConditionContext.Provider value={idCondition}>
            <ConditionComponent
                context={context}
                children={children}
                allowEmpty={handleDelete !== undefined}
                idCondition={idCondition}
                handleDelete={handleDelete ? handleDeleteRoot : undefined}
                conditionTypes={conditionTypes}
            />
        </ConditionContext.Provider>
    );
}
export default ConditionEditor;
