import { makeStyles, Icon } from '@material-ui/core';
import { Company } from '../../../@Types/Company';
import {
    loadCompaniesById,
    loadCompanies,
} from '../../../controllers/CompaniesController/CompaniesService';
import RoundedGenericPicker, {
    RoundedGenericPickerProps,
} from '../RoundedGenericPicker/RoundedGenericPicker';

function RoundedCompanyPicker({
    label = 'Empresa',
    ...props
}: Omit<
    RoundedGenericPickerProps<Company>,
    'calcLbl' | 'loadInitialType' | 'loadElements' | 'elementLabel'
>): JSX.Element {
    return (
        <RoundedGenericPicker<Company>
            {...props}
            IconComponent={CompanyIcon}
            label={label}
            elementLabel="Empresa"
            calcLbl={(company): string => company.values.name ?? 'Empresa'}
            loadInitialType={loadCompaniesById}
            loadElements={async (
                page: number,
                pageSize: number,
                search: string | undefined
            ): Promise<Company[]> => {
                return await loadCompanies(
                    page,
                    pageSize,
                    [],
                    undefined,
                    search
                );
            }}
        />
    );
}
export default RoundedCompanyPicker;

function CompanyIcon(props: any): JSX.Element {
    const useStyles = makeStyles({
        iconRoot: {
            position: 'absolute',
            right: '7px',
        },
        imageIcon: {
            height: 23,
            width: 23,
        },
    });
    const classes = useStyles();
    return (
        <Icon classes={{ root: classes.iconRoot }} className={props.className}>
            <img
                className={classes.imageIcon}
                style={{ display: 'flex', height: 'inherit', width: 'inherit' }}
                src="/media/icons/greyhandshake.svg"
            />
        </Icon>
    );
}
