import { createAction } from '@reduxjs/toolkit';
import { Client } from '../../@Types/@Types';
import { History } from 'history';
import ScrollTypes from '../../constants/ScrollTypes';

export const Types = {
    RESET: 'CLIENTS/RESET',
    REFRESH: 'CLIENTS/REFRESH',
    GET_SUCCESS: 'CLIENTS/GET_SUCCESS',
    REFRESH_CURRENT: 'CLIENTS/REFRESH_CURRENT',
    REFRESH_CURRENT_SUCCESS: 'CLIENTS/REFRESH_CURRENT_SUCCESS',
    SELECT: 'CLIENTS/SELECT_ELEMENT',
    SEARCH: 'CLIENTS/SEARCH',
    LOADING: 'CLIENTS/LOADING',
    ORDER: 'CLIENTS/ORDER',
    FILTER: 'CLIENTS/FILTER',
    PAGE_SCROLL: 'CLIENTS/NEW_PAGE_SCROLL',
    PAGE_SCROLL_UP: 'CLIENTS/PAGE_SCROLL_UP',
    RESET_SUCCESS: 'CLIENTS/RESET_SUCCESS',
    GO_TO: 'CLIENTS/GO_TO',
};

/**
 * Called when the clients page is first loaded
 */
export const reset = createAction<{
    history: History;
    queryString: string;
    idClient?: string;
}>(Types.RESET);

export const goTo = createAction<{
    history: History;
    queryString: string;
    idClient?: string;
}>(Types.GO_TO);

export const resetSuccess = createAction<{
    elements: Client[];
    selectedElement?: number;
    lastPage: boolean;
    page: number;
    filters: {};
    orderBy: string | undefined;
    search: string | undefined;
}>(Types.RESET_SUCCESS);
/**
 * Called when the clients need to be refreshed
 */
export const refresh = createAction(Types.REFRESH);

/**
 * Called when the selectedElement needs to be refreshed
 */
export const refreshCurrent = createAction(Types.REFRESH_CURRENT);

/**
 * Called when the selectedElement has successfully been fetched for refresh
 */
export const refreshSuccess = createAction<Client>(
    Types.REFRESH_CURRENT_SUCCESS
);

/**
 * Called when the list needs to load more elements
 */
export const pageScroll = createAction<ScrollTypes>(Types.PAGE_SCROLL);

/**
 * Called to toggle the loader
 */
export const setLoading = createAction(
    Types.LOADING,
    function prepare(loading: boolean = true) {
        return {
            payload: loading,
        };
    }
);

/**
 * Called when the selectedElement (index) changes value
 */
export const selectElement = createAction<number | undefined>(Types.SELECT);

/**
 * Called when the search value changes
 */
export const search = createAction<string>(Types.SEARCH);

/**
 *  Called when the orderby value changes
 */
export const orderBy = createAction<string>(Types.ORDER);

/**
 * Called when the filter values change
 */
export const filter = createAction<string[]>(Types.FILTER);

/**
 *  Called when the saga has sucessfully retrieved the lists elements
 */
export const getSuccess = createAction<{
    selectedElement: undefined | number;
    elements: Client[];
    lastPage: boolean;
}>(Types.GET_SUCCESS);
