import styles from './ClassifierSelectorStep.module.css';
import RoundedTextField from '../../../../shared/RoundedTextField/RoundedTextField';
import RoundedSelect from '../../../../shared/RoundedSelect/RoundedSelect';
import Option from './Option/Option';
import {
    ClassifierSelector,
    FormClassifierSelectorOption,
    FormStep,
} from '../../../../@Types/FormTypes/FormStep';
import TrendingFlatRoundedIcon from '@material-ui/icons/TrendingFlatRounded';
import SwapHorizRoundedIcon from '@material-ui/icons/SwapHorizRounded';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../utils/_store';
import { useEffect, useRef, useState } from 'react';
import { Classifier } from '../../../../@Types/@Types';
import { generateNestedOptions } from '../AuxStepfunctions';
import ClassifyMenu from '../../../../shared/@Menus/ClassifyMenu/ClassifyMenu';
import { TicketClassifier } from '../../../../@Types/TicketTypes/Ticket';
import { GenericStepProps } from '../../../../shared/GenericFormEditor/Step/Step';
import {
    FormStepLocation,
    UniqueSteps,
} from '../../../../@Types/FormTypes/Form';
import { calcStepWidth } from '../../../../shared/GenericFormEditor/StepFunctions';
import { updateSteps } from '../../../../controllers/GenericEditorController/GenericEditorActions';
import Toggle from '../../../../shared/Toggle/Toggle';
import LocationTypes from '../../../../constants/LocationTypes';
import React from 'react';
import { useGenericEditorDispatch } from '../../../../shared/GenericFormEditor/GenericFormEditorHooks';

function ClassifierSelectorStep({
    size,
    step,
    level,
    editing,
    location,
    updateStep,
    uniqueSteps,
    updateUniqueSteps,
    handleParentClose,
    ...others
}: GenericStepProps<
    ClassifierSelector,
    UniqueSteps,
    FormStepLocation
>): JSX.Element {
    const dispatch = useGenericEditorDispatch();
    const siteClassifiers = useSelector(
        (state: RootState) => state.site.classifiers
    );
    const [showMenu, setShowMenu] = useState(false);
    const [classifier, setClassifier] = useState<null | Classifier>(null);
    const btnRef = useRef<HTMLDivElement>(null);
    const calcValuePath = (classifier: Classifier): [any, string] => {
        if (!classifier.path || classifier.path.length === 0) {
            return [classifier.name, classifier.name];
        } else {
            const parents = classifier.path.map((id) => siteClassifiers[id]);

            const path = parents.map((parent) => parent.name);
            path.push(classifier.name);
            const elements = [];
            parents.forEach((parent, index) => {
                elements.push(
                    <span key={'T-' + index}>
                        &#x200E;{parent.name.toString()}&#x200E;
                    </span>
                );
                elements.push(
                    <div className={styles.arrowIconContainer} key={index}>
                        <TrendingFlatRoundedIcon fontSize="inherit" />
                    </div>
                );
            });
            elements.push(
                <span key={'Element'}>
                    &#x200E;{classifier.name.toString()}&#x200E;
                </span>
            );
            return [elements, path.join(' > ')];
        }
    };

    useEffect(() => {
        if (step.idClassifier) {
            const tempClassifier = siteClassifiers[step.idClassifier];
            if (tempClassifier?.active) {
                setClassifier(tempClassifier);
            }
        } else {
            setClassifier(null);
        }
    }, [step.idClassifier]);

    const mapOptions = (): JSX.Element[] => {
        const children =
            siteClassifiers[step.idClassifier ?? '']?.children ?? [];
        const classifs = children
            .map((idChild) => siteClassifiers[idChild])
            .sort((c1, c2) => {
                const c1Name = c1.clientName ? c1.clientName : c1.name;
                const c2Name = c2.clientName ? c2.clientName : c2.name;
                return c1Name.localeCompare(c2Name);
            });
        if (!classifs || classifs.length === 0) {
            return [
                <div className={styles.emptyLbl} key={'EMPTY'}>
                    No hay Clasificadores
                </div>,
            ];
        }
        return classifs.map((classif, index) => (
            <Option
                key={index}
                {...others}
                location={{
                    type: LocationTypes.CLASSIFIER_SELECTOR,
                    idRootStep: location.idRootStep,
                    idStep: step.id,
                    idClassifier: classif._id,
                    indexStep: null,
                }}
                level={level + 1}
                classifier={classif}
                option={step.options[classif._id]}
                updateOption={(option: FormClassifierSelectorOption): void => {
                    const tempStep = { ...step, options: { ...step.options } };
                    tempStep.options[classif._id] = option;
                    updateStep(tempStep);
                }}
                removeOption={(): void => {
                    const tempStep = { ...step, options: { ...step.options } };
                    delete tempStep.options[classif._id];
                    updateStep(tempStep);
                }}
                handleParentClose={handleParentClose}
                size={size}
                uniqueSteps={uniqueSteps}
                updateUniqueSteps={updateUniqueSteps}
                step={step}
            />
        ));
    };

    const calcTicketClassifier = (): TicketClassifier[] => {
        if (classifier) {
            if (classifier.path === null) {
                return [{ idRoot: classifier._id, idValue: classifier._id }];
            } else {
                return [
                    { idRoot: classifier.path[0], idValue: classifier._id },
                ];
            }
        } else {
            return [];
        }
    };

    if (editing) {
        return (
            <div className={styles.editingContainer}>
                {showMenu && (
                    <ClassifyMenu
                        menuLabel="Seleccionar Clasificador"
                        anchorRef={btnRef}
                        multiple={false}
                        allowRoots
                        classifiers={calcTicketClassifier()}
                        handleChange={(
                            classifiers: TicketClassifier[]
                        ): void => {
                            if (
                                classifiers.length > 0 &&
                                classifiers[0].idValue
                            ) {
                                const steps: Record<string, FormStep> = {};
                                steps[step.id] = {
                                    ...step,
                                    idClassifier: classifiers[0].idValue,
                                    options: generateNestedOptions(
                                        siteClassifiers[classifiers[0].idValue],
                                        siteClassifiers,
                                        steps
                                    ),
                                };
                                dispatch(
                                    updateSteps({
                                        steps,
                                        location,
                                    })
                                );
                            } else {
                                updateStep({
                                    ...step,
                                    idClassifier: null,
                                    options: {},
                                });
                            }
                        }}
                        handleClose={(): void => {
                            setShowMenu(false);
                        }}
                        zIndex={1303}
                    />
                )}
                <div className={styles.inputContainer}>
                    <div className={styles.classifierNameLbl}>
                        Clasificador:
                    </div>
                    <div className={styles.classifierLbl}>
                        <div className={styles.valueSpan}>
                            &#x200E;
                            {classifier
                                ? calcValuePath(classifier)[0]
                                : 'Por Definir'}
                            &#x200E;
                        </div>
                    </div>
                    <div
                        className={styles.swapIcon}
                        ref={btnRef}
                        onClick={(): void => {
                            setShowMenu(true);
                        }}
                    >
                        <SwapHorizRoundedIcon fontSize="inherit" />
                    </div>
                </div>
                <div className={styles.inputContainer}>
                    <RoundedTextField
                        label="Etiqueta (Opcional)"
                        value={step.label}
                        onChange={(e): void => {
                            updateStep({
                                ...step,
                                label: e.target.value,
                            });
                        }}
                    ></RoundedTextField>
                </div>
                <RoundedTextField
                    label="Descripción"
                    value={step.description ?? ''}
                    onChange={(e): void => {
                        updateStep({
                            ...step,
                            description: e.target.value,
                        });
                    }}
                ></RoundedTextField>
                <div className={styles.subjectContainer}>
                    <div className={styles.subjectLabel}>Es el asunto:</div>
                    <div className={styles.toggleContainer}>
                        <Toggle
                            checked={step.isSubject === true}
                            onChange={(checked: boolean): void => {
                                updateStep({
                                    ...step,
                                    isSubject: checked,
                                });
                            }}
                        />
                    </div>
                </div>
                <div className={styles.optionsLabel}>Opciones: </div>
                <div className={styles.optionsList}>{mapOptions()}</div>
            </div>
        );
    } else {
        return (
            <div
                style={{
                    width: calcStepWidth(step.size, size),
                    maxWidth: '100%',
                    minHeight:
                        step.description || step.required ? '50px' : '38px',
                }}
            >
                <RoundedSelect
                    fullWidth
                    value=""
                    label={
                        step.label
                            ? step.label
                            : classifier
                            ? classifier.name
                            : 'Por Definir'
                    }
                    required={step.required}
                    helperText={step.description}
                    backgroundColor="var(--primary1)"
                    containerMargin="0px"
                    height={'31px'}
                ></RoundedSelect>
            </div>
        );
    }
}

export default React.memo(ClassifierSelectorStep, (prev, next) => {
    const { step, stepEqualityChecker } = prev;
    const { step: newStep } = next;
    if (
        step.size !== newStep.size ||
        step.label !== newStep.label ||
        step.maxSize !== newStep.maxSize ||
        step.required !== newStep.required ||
        step.searchable !== newStep.searchable ||
        step.description !== newStep.description ||
        step.idClassifier !== newStep.idClassifier ||
        step.isSubject !== newStep.isSubject ||
        step.options !== newStep.options
    ) {
        return false;
    }

    return stepEqualityChecker?.(prev, next) ?? true;
}) as typeof ClassifierSelectorStep;
