import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';

let headers = {};

const axiosInstance = axios.create({
    baseURL: '/api',
    timeout: 30000,
    headers,
});

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        const originalRequest = error.config;
        if (
            error?.response &&
            error?.response?.status === 401 &&
            error?.response?.data === 'TokenExpiredError'
        ) {
            return axiosInstance
                .get('/auth/refresh', {
                    headers: {
                        Authorization:
                            'Bearer ' + localStorage.getItem('refreshToken'),
                    },
                })
                .then((response) => {
                    localStorage.setItem('token', response.data.token);
                    axiosInstance.defaults.headers['Authorization'] =
                        'Bearer ' + response.data.token;
                    originalRequest.headers['Authorization'] =
                        'Bearer ' + response.data.token;
                    if (
                        originalRequest.method === 'post' &&
                        originalRequest.headers['Content-Type'] ===
                            'application/json'
                    ) {
                        originalRequest.data = JSON.parse(originalRequest.data);
                    }

                    return axios.request(originalRequest).catch(() => {
                        localStorage.clear();
                        window.location.reload();
                    });
                })
                .catch(() => {
                    localStorage.clear();
                    window.location.reload();
                });
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;

export const axiosBaseQuery =
    (): BaseQueryFn<
        {
            url: string;
            method?: AxiosRequestConfig['method'];
            data?: AxiosRequestConfig['data'];
            params?: AxiosRequestConfig['params'];
            headers?: AxiosRequestConfig['headers'];
        },
        unknown,
        unknown
    > =>
    async ({
        url,
        method,
        data,
        params,
        headers,
    }): Promise<{
        data?: any;
        error?: {
            status: number | undefined;
            data: any;
        };
    }> => {
        try {
            const result = await axiosInstance({
                url,
                method,
                data,
                params,
                headers,
            });
            return { data: result.data };
        } catch (axiosError) {
            const err = axiosError as AxiosError;
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            };
        }
    };
