import styles from './AddStepBtn.module.css';
import { cloneElement, useRef, useState } from 'react';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import * as Actions from '../../../controllers/GenericEditorController/GenericEditorActions';
import React from 'react';
import { GBaseStep } from '../../../@Types/GenericForm';
import {
    GLocation,
    SectionLocation,
} from '../../../@Types/FormTypes/LocationTypes';
import { useGenericEditorDispatch } from '../GenericFormEditorHooks';

interface AddStepBtnProps<L extends GLocation> {
    location: L | SectionLocation;
    size?: string;
    stepMenuComponent?: JSX.Element;
}

function AddStepBtn<S extends GBaseStep, L extends GLocation>({
    stepMenuComponent,
    location,
    size = '46px',
}: AddStepBtnProps<L>): JSX.Element {
    const addStepRef = useRef<HTMLDivElement>(null);
    const [addStep, setAddStep] = useState(false);
    const dispatch = useGenericEditorDispatch();
    return (
        <React.Fragment>
            <div
                className={styles.addBtn}
                style={{ width: size, height: size }}
                onClick={(): void => {
                    setAddStep(true);
                }}
                ref={addStepRef}
            >
                <div
                    className={styles.addIconContainer}
                    style={{ fontSize: size }}
                >
                    <AddRoundedIcon fontSize="inherit" />
                </div>
            </div>

            {addStep &&
                stepMenuComponent !== undefined &&
                cloneElement(stepMenuComponent, {
                    anchorRef: addStepRef,
                    handleClose: (): void => {
                        setAddStep(false);
                    },
                    location,
                    handleAdd: (step: S): void => {
                        dispatch(
                            Actions.addStep({
                                step,
                                location,
                            })
                        );
                        setAddStep(false);
                    },
                })}
        </React.Fragment>
    );
}

export default AddStepBtn;
