import styles from './StepContainer.module.css';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../utils/_store';
import { ConversationStep } from '../../../../../@Types/ConversationTypes/ConversationStep';
import {
    copyStep,
    cutStep,
    moveStep,
    pasteStep,
    selectStep,
} from '../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import React from 'react';
import { EditorState } from 'draft-js';
import DraftRenderer from '../../../../../shared/DraftRenderer/DraftRenderer';
import TrendingFlatRoundedIcon from '@material-ui/icons/TrendingFlatRounded';
import { LocationTypes } from '../../../../../constants/Conversations/ConversationStepTypes';
import { useDrag } from 'react-dnd';
import { StepLocation } from '../../../../../controllers/ConversationEditorController/ConversationEditorReducer';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import CutIcon from '../../../../../Icons/CutIcon';
import FormatListBulletedRoundedIcon from '@material-ui/icons/FormatListBulletedRounded';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import DisabledIcon from '../../../../../Icons/DisabledIcon';

interface StepContainerProps {
    step: ConversationStep;
    children: any;
    header?: string;
    footer?: string;
    draftFooter?: EditorState;
    bottomComponent?: JSX.Element;
    disabledBottom?: boolean;
    path: StepLocation[] | null;
    draggable?: boolean;
    /** If the step is hidden to the user */
    hidden?: boolean;
}
function StepContainer({
    step,
    header,
    footer,
    path,
    children,
    draftFooter,
    bottomComponent,
    disabledBottom = false,
    draggable = true,
    hidden = false,
}: StepContainerProps): JSX.Element {
    const dispatch = useDispatch();
    const [{}, drag] = useDrag({
        type: step.type,
        item: { id: step.id, path },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
            const didDrop = monitor.didDrop();
            if (!didDrop && path) {
                dispatch(
                    moveStep({
                        idStep: step.id,
                        location: path[path.length - 1],
                    })
                );
            }
        },
    });
    const [hovering, setHovering] = useState(false);
    const [hoveringTail, setHoveringTail] = useState(false);
    const [hoveringLocation, setHoveringLocation] = useState(false);
    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);
    const selectedStep = useSelector(
        (state: RootState) => state.conversationEditor.selectedStep?.id
    );
    const selected = selectedStep === step.id;
    const currentStyle = {
        borderColor: selected ? 'var(--greyFont)' : 'var(--greyBtn)',
    };
    const canPaste = useSelector(
        (state: RootState) => state.conversationEditor.copiedInfo !== undefined
    );

    const handleContextMenu = (event: React.MouseEvent): void => {
        event.preventDefault();
        if (draggable && !path?.[path?.length - 1].controlled) {
            setContextMenu(
                contextMenu === null
                    ? {
                          mouseX: event.clientX + 2,
                          mouseY: event.clientY - 6,
                      }
                    : null
            );
        }
    };

    const renderHeader = (): JSX.Element | void => {
        if (header && header.length > 0) {
            return <div className={styles.headerContainer}>{header}</div>;
        }
    };
    const renderFooter = (): JSX.Element | void => {
        if (draftFooter && draftFooter.getCurrentContent().hasText()) {
            return (
                <div className={styles.draftFooterContainer}>
                    <DraftRenderer editorState={draftFooter} />
                </div>
            );
        } else if (footer && footer.length > 0) {
            return <div className={styles.footerContainer}>{footer}</div>;
        }
    };

    const renderPath = (): JSX.Element | void => {
        if (path && path.length > 1) {
            const elements: JSX.Element[] = [];
            let title = '';
            for (let i = 1; i < path.length - 1; i++) {
                const currentPath = path[i];
                elements.push(
                    <div className={styles.arrowIconContainer} key={i}>
                        <TrendingFlatRoundedIcon fontSize="inherit" />
                    </div>
                );
                elements.push(
                    <span key={'T-' + i}>
                        &#x200E;
                        {calcPath(currentPath)}&#x200E;
                    </span>
                );
                title = title + `${currentPath.label} -> `;
            }
            const parent = path[path.length - 1];
            if (parent) {
                title = title + `${parent.label}`;
            }
            return (
                <div className={styles.locationTagContainer}>
                    <div
                        className={styles.locationTag}
                        style={currentStyle}
                        onMouseEnter={(): void => {
                            setHoveringLocation(true);
                        }}
                        onMouseLeave={(): void => {
                            setHoveringLocation(false);
                        }}
                        onClick={(): void => {
                            dispatch(selectStep({ id: step.id, path }));
                        }}
                        onContextMenu={handleContextMenu}
                    >
                        <div className={styles.locationContent}>
                            <div className={styles.pill} title={title}>
                                <div className={styles.pillLbl}>
                                    <div className={styles.valueSpan}>
                                        {elements}
                                    </div>
                                    &#x200E;{calcPath(parent)}&#x200E;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };
    return (
        <React.Fragment>
            <div
                className={
                    hovering || hoveringTail || hoveringLocation || selected
                        ? styles.thickStepContainer
                        : styles.stepContainer
                }
            >
                <div
                    className={hidden ? styles.hiddenCont : styles.stepCont}
                    ref={
                        draggable && !path?.[path?.length - 1].controlled
                            ? drag
                            : undefined
                    }
                >
                    {renderPath()}
                    <div className={styles.cardContainer}>
                        {!hidden && (
                            <div className={styles.trContainer}>
                                <div
                                    className={styles.tr}
                                    style={currentStyle}
                                    onMouseEnter={(): void => {
                                        setHoveringTail(true);
                                    }}
                                    onMouseLeave={(): void => {
                                        setHoveringTail(false);
                                    }}
                                    onClick={(): void => {
                                        dispatch(
                                            selectStep({ id: step.id, path })
                                        );
                                    }}
                                    onContextMenu={handleContextMenu}
                                ></div>
                            </div>
                        )}
                        {!hidden && (
                            <div
                                className={styles.trBorder}
                                style={currentStyle}
                            ></div>
                        )}
                        <div
                            className={
                                hidden ? styles.hiddenCard : styles.messageCard
                            }
                            onMouseEnter={(): void => {
                                setHovering(true);
                            }}
                            onMouseLeave={(): void => {
                                setHovering(false);
                            }}
                            onClick={(): void => {
                                dispatch(selectStep({ id: step.id, path }));
                            }}
                            onContextMenu={handleContextMenu}
                            style={currentStyle}
                        >
                            {step.disabled && (
                                <div className={styles.disabledIcon}>
                                    <DisabledIcon
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                        }}
                                        fill="var(--error)"
                                    />
                                </div>
                            )}
                            <div className={styles.cardCurtain}></div>
                            {renderHeader()}
                            {children}
                            {renderFooter()}
                        </div>
                    </div>
                    {bottomComponent !== undefined && (
                        <div
                            className={styles.bottomComponent + ' noselect'}
                            onMouseEnter={(): void => {
                                if (!disabledBottom) setHovering(true);
                            }}
                            onMouseLeave={(): void => {
                                if (!disabledBottom) setHovering(false);
                            }}
                            onClick={(): void => {
                                if (!disabledBottom)
                                    dispatch(selectStep({ id: step.id, path }));
                            }}
                            onContextMenu={handleContextMenu}
                        >
                            {bottomComponent}
                        </div>
                    )}
                </div>
            </div>
            <Menu
                open={contextMenu !== null}
                onClose={(): void => {
                    setContextMenu(null);
                }}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
                onContextMenu={(event): void => {
                    event.preventDefault();
                    setContextMenu(null);
                }}
            >
                <MenuItem
                    onClick={(): void => {
                        setContextMenu(null);
                        dispatch(cutStep(step.id));
                    }}
                >
                    <ListItemIcon style={{ minWidth: 35 }}>
                        <CutIcon
                            fill="#757575"
                            style={{ width: 19, height: 19 }}
                        />
                    </ListItemIcon>
                    <ListItemText style={{ paddingRight: 15 }}>
                        Cortar
                    </ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={(): void => {
                        setContextMenu(null);
                        dispatch(copyStep(step.id));
                    }}
                >
                    <ListItemIcon style={{ minWidth: 35 }}>
                        <FileCopyRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Copiar</ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={(): void => {
                        if (path) {
                            const location = { ...path[path.length - 1] };
                            location.indexStep = location.indexStep + 1;
                            dispatch(pasteStep(location));
                        }
                        setContextMenu(null);
                    }}
                    disabled={!canPaste}
                >
                    <ListItemIcon style={{ minWidth: 35 }}>
                        <GetAppRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Pegar</ListItemText>
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}
export default StepContainer;
const calcPath = (location: StepLocation): JSX.Element => {
    switch (location.type) {
        case LocationTypes.BUTTON:
            return (
                <span>
                    <div className={styles.iconContainer}>
                        <div className={styles.btnIcon}></div>
                    </div>
                    &#x200E;{location.label ?? '(Por Definir)'}&#x200E;
                </span>
            );
        case LocationTypes.LIST:
            return (
                <span>
                    <div className={styles.iconContainer}>
                        <div className={styles.listIcon}>
                            <FormatListBulletedRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                    &#x200E;{location.label ?? '(Por Definir)'}&#x200E;
                </span>
            );
        case LocationTypes.GROUP:
            return <div className={styles.btnIcon}></div>;
        default:
            if (location.label) {
                return <span>{location.label}</span>;
            }
            return <div></div>;
    }
};
