import { IconProps } from './@IconTypes';

function ShapesIcon({ className, style, fill }: IconProps): JSX.Element {
    return (
        <svg
            fill={fill}
            style={style}
            className={className}
            version="1.1"
            viewBox="0 0 100 125"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M68.22955,46.9704,94.82184,93.0296A1.3136,1.3136,0,0,1,93.6842,95H40.49963a1.31357,1.31357,0,0,1-1.13757-1.9704L65.95435,46.9704A1.31358,1.31358,0,0,1,68.22955,46.9704ZM59.408,50.708l3.25488-5.6377a5.11422,5.11422,0,0,1,8.8584.00147l3.25421,5.63647A23.50013,23.50013,0,1,0,59.408,50.708ZM5.90533,70H47.09418a.90563.90563,0,0,0,.90558-.90552V47.98682A27.28708,27.28708,0,0,1,39.79175,28.5c0-.50342.016-1.00317.043-1.5H5.90533a.90562.90562,0,0,0-.90557.90552v41.189A.90562.90562,0,0,0,5.90533,70Z" />
        </svg>
    );
}
export default ShapesIcon;
