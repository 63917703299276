import React, { useEffect, useState } from 'react';
import { Entity } from '../../../../@Types/@Types';
import { EntityValue } from '../../../../@Types/EntityValue';
import styles from './BidirectionalComponent.module.css';
import Loader from '../../../../shared/Loader/Loader';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../utils/_store';
import EntityValueComponent from './EntityValueComponent/EntityValueComponent';
import { loadEntityValues } from '../../../../controllers/EntityValuesController/EntityValuesService';
import { entityValuesToQueryString } from '../../../../controllers/EntityValuesController/EntityValuesReducer';

interface EntityValueClientsProps {
    entity: Entity;
    entityValue: EntityValue;
    idBidirectional: string;
}

function EntityValueClients({
    entity,
    entityValue,
    idBidirectional,
}: EntityValueClientsProps): JSX.Element {
    const [entityValues, setEntityValues] = useState<EntityValue[] | null>(
        null
    );

    const bidirectionalEntity = useSelector(
        (state: RootState) => state.site.entities[idBidirectional]
    );

    const filters = {
        relationships: {
            [entity._id]: [entityValue],
        },
        values: {},
    };

    async function load(): Promise<void> {
        if (entityValue._id) {
            const entityValues = await loadEntityValues(
                bidirectionalEntity,
                1,
                100,
                filters
            );
            setEntityValues(entityValues);
        }
    }

    useEffect(() => {
        load();
    }, [entityValue]);

    return (
        <React.Fragment>
            <div className={styles.clientsContainer}>
                <div className={styles.clientsLbl}>
                    {bidirectionalEntity.pluralName}:
                </div>
                <div className={styles.clientsListContainer + ' flexbox'}>
                    {!entityValues && (
                        <div className={styles.loaderContainer}>
                            <Loader size={50} />
                        </div>
                    )}
                    {entityValues && entityValues.length === 0 && (
                        <div className={styles.emptyLbl}>
                            {entity.name} sin{' '}
                            {bidirectionalEntity.pluralName.toLocaleLowerCase()}
                        </div>
                    )}
                    {entityValues &&
                        entityValues.map((entityValues, index) => (
                            <EntityValueComponent
                                refresh={loadEntityValues}
                                idEntity={entity._id}
                                entityValue={entityValues}
                                entity={bidirectionalEntity}
                                mainEntityValue={entityValue}
                                key={index}
                                handleUpdate={(entityValue: any): void => {
                                    const tempEntityValues = [...entityValue];
                                    tempEntityValues[index] = entityValue;
                                    setEntityValues(tempEntityValues);
                                }}
                                filters={
                                    '?' +
                                    entityValuesToQueryString(
                                        bidirectionalEntity,
                                        filters,
                                        undefined,
                                        undefined,
                                        true
                                    )
                                }
                            />
                        ))}
                </div>
            </div>
        </React.Fragment>
    );
}

export default EntityValueClients;
