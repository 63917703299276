import StepComponent, { StepComponentProps } from '../../Step';
import styles from './ListApiStep.module.css';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import StepContainer from '../../StepContainer/StepContainer';
import DraftRenderer from '../../../../../../shared/DraftRenderer/DraftRenderer';
import { ListApiStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import { updateStep } from '../../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import { EditorState, convertFromRaw } from 'draft-js';
import ClientReply from '../../ClientReply/ClientReply';
import { useDispatch } from 'react-redux';
import { ApiOptionTypes } from '../../../../../../constants/ApiOptionTypes';
import AddStep from '../../../AddStep/AddStep';
import { LocationTypes } from '../../../../../../constants/Conversations/ConversationStepTypes';
import { useAppSelector } from '../../../../../../hooks';
import { Menu, MenuItem } from '@material-ui/core';
import {
    ApiRequest,
    ApiRequestOption,
    compareRequests,
} from '../../../../../../@Types/ApiRequest';
import { fetchApiRequest } from '../../../../../../controllers/ApiRequestService';
import Loader from '../../../../../../shared/Loader/Loader';
import produce from 'immer';
interface ListApiStepProps extends StepComponentProps {
    step: ListApiStep;
}

function ListApiStepComponent({
    step,
    path,
    dragging,
}: ListApiStepProps): JSX.Element {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [openClients, setOpenClients] = useState(false);
    const btnRef = useRef<HTMLDivElement>(null);
    const idOrganization = useAppSelector(
        (state) => state.site.organization!.idOrganization
    );
    const clientsBtnRef = useRef<HTMLDivElement>(null);
    const [timer, setTimer] = useState<any>(null);
    const [request, setRequest] = useState<ApiRequest | undefined>();
    const [options, setOptions] = useState<{
        ids: string[];
        options: Record<string, ApiRequestOption>;
    } | null>(null);

    const loadData = async (): Promise<void> => {
        setRequest(step.request);
        try {
            setOptions(null);
            if (!step.request) return;
            const data = await fetchApiRequest(step.request, idOrganization);
            const options: Record<string, ApiRequestOption> = {};
            const ids: string[] = [];
            for (const item of data) {
                options[item.id] = item;
                ids.push(item.id);
            }
            setOptions({ options, ids });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (!request) loadData();
        else if (!compareRequests(step.request, request)) {
            try {
                clearTimeout(timer);
                setTimer(
                    setTimeout(() => {
                        loadData();
                        setTimer(null);
                    }, 3000)
                );
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }
    }, [step.request]);

    useEffect(() => {
        return (): void => {
            try {
                clearTimeout(timer);
                // eslint-disable-next-line no-empty
            } catch (e) {}
        };
    }, []);

    const selectedOption = useMemo(() => {
        if (!step.selectedOption || !step.options || !options) return null;
        return {
            value: options?.options[step.selectedOption],
            option: step.options[step.selectedOption],
        };
    }, [options, step]);
    return (
        <React.Fragment>
            {(open || openClients) && (
                <ListMenu
                    idStep={step.id}
                    loading={!options}
                    options={options?.ids.map((id) => options.options[id])}
                    anchorRef={open ? btnRef : clientsBtnRef}
                    handleClose={(): void => {
                        setOpen(false);
                        setOpenClients(false);
                    }}
                    handleSelect={(id): void => {
                        dispatch(
                            updateStep(
                                produce(step, (step) => {
                                    step.selectedOption = id;
                                    if (!step.options[id]) {
                                        step.options[id] = {
                                            type: ApiOptionTypes.NESTED,
                                            steps: [],
                                        };
                                    }
                                })
                            )
                        );
                    }}
                />
            )}
            <StepContainer
                step={step}
                path={path}
                header={step.header}
                footer={step.footer}
                bottomComponent={
                    <div className={styles.btnsContainer}>
                        <div
                            onClick={(): void => {
                                if (step.request) setOpen(true);
                            }}
                            className={styles.messageBtn}
                            ref={btnRef}
                        >
                            {step.label ? step.label : '(Por Definir)'}
                        </div>
                    </div>
                }
                disabledBottom
            >
                <div className={styles.messageContainer}>
                    <DraftRenderer
                        editorState={EditorState.createWithContent(
                            convertFromRaw(step.message)
                        )}
                    />
                </div>
            </StepContainer>
            <ClientReply padding={10} title={step.name}>
                <div className={styles.replyContent}>
                    <div
                        className={styles.replyBtn + ' noselect'}
                        ref={clientsBtnRef}
                        onClick={(): void => {
                            if (!options || !step.request) return;
                            setOpenClients(true);
                        }}
                    >
                        {selectedOption ? (
                            selectedOption.value.label ? (
                                selectedOption.value.label
                            ) : (
                                '(Por Definir)'
                            )
                        ) : (
                            <div className={styles.unSelected}>
                                {!options && step.request ? (
                                    <Loader size={20} />
                                ) : (
                                    'Sin Seleccionar'
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </ClientReply>

            {selectedOption &&
                selectedOption.option.type === ApiOptionTypes.NESTED && (
                    <React.Fragment>
                        {selectedOption.option.steps.map(
                            (idStep: string, index: number) => (
                                <StepComponent
                                    key={index}
                                    idStep={idStep}
                                    path={[
                                        ...path,
                                        {
                                            idStep: step.id,
                                            type: LocationTypes.LIST,
                                            indexStep: index,
                                            idOption: step.selectedOption!,
                                            label: selectedOption.value.label,
                                        },
                                    ]}
                                />
                            )
                        )}
                        <AddStep
                            disabled={dragging}
                            location={{
                                idStep: step.id,
                                type: LocationTypes.LIST,
                                indexStep: selectedOption.option.steps.length,
                                idOption: step.selectedOption!,
                                label: null,
                            }}
                        />
                    </React.Fragment>
                )}

            {selectedOption && (
                <div className={styles.endingContainer}>
                    <div className={styles.separator}></div>
                    <div className={styles.endingLbl}>
                        Fin:{' '}
                        {selectedOption.value.label
                            ? selectedOption.value.label
                            : '(Por Definir)'}
                    </div>
                    <div className={styles.endSeparator}></div>
                </div>
            )}
        </React.Fragment>
    );
}
export default ListApiStepComponent;

interface ListMenuProps {
    idStep: string;
    anchorRef: any;
    loading?: boolean;
    handleClose: Function;
    options: ApiRequestOption[] | undefined;
    handleSelect: (id: string) => void;
}
function ListMenu({
    idStep,
    options,
    loading,
    anchorRef,
    handleClose,
    handleSelect,
}: ListMenuProps): JSX.Element {
    const step: ListApiStep = useAppSelector(
        (state) => state.conversationEditor.steps[idStep ?? ''] as ListApiStep
    );
    if (!step) {
        return <div></div>;
    }

    const allowedOptions = useMemo(() => {
        return options?.filter(
            (option) => step.options[option.id]?.type !== ApiOptionTypes.HIDE
        );
    }, []);

    return (
        <Menu
            anchorEl={anchorRef.current}
            open={true}
            onClose={(): void => {
                handleClose();
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            MenuListProps={{
                style: { minWidth: 130 },
            }}
        >
            {loading && (
                <MenuItem>
                    <div
                        style={{
                            height: 25,
                            width: '100%',
                            position: 'relative',
                        }}
                    >
                        <Loader size={25} />
                    </div>
                </MenuItem>
            )}
            {allowedOptions?.length === 0 && (
                <MenuItem>
                    <div
                        style={{
                            minWidth: anchorRef.current?.offsetWidth - 32 ?? 0,
                        }}
                        className={styles.option}
                    >
                        Sin Opciones
                    </div>
                </MenuItem>
            )}
            {allowedOptions?.map((option) => {
                const stepOption = step.options[option.id];
                return (
                    <MenuItem
                        onClick={(): void => {
                            handleSelect(option.id);
                            handleClose();
                        }}
                        key={option.id}
                    >
                        <div
                            style={{
                                minWidth:
                                    anchorRef.current?.offsetWidth - 32 ?? 0,
                            }}
                            className={styles.option}
                        >
                            {option.label}
                            {stepOption?.type !== ApiOptionTypes.HIDE &&
                                stepOption?.classifier && (
                                    <img
                                        className={styles.classifierIcon}
                                        alt={'shapes'}
                                        src={'/media/icons/shapes.svg'}
                                    />
                                )}
                        </div>
                    </MenuItem>
                );
            })}
        </Menu>
    );
}
