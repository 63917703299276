import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import InputAdornment from '@material-ui/core/InputAdornment';
import LockIcon from '@material-ui/icons/Lock';

const CustomTextField = withStyles({
    root: {
        '& input': {
            padding: '6px 0px',
            backgroundColor: 'var(--greyHover)',
            fontWeight: 300,
            height: 40,
            borderRadius: 10,
        },
        '& label': {
            marginTop: '-4px',
        },
        '& label.Erk-MuiInputLabel-shrink': {
            margin: -2,
        },
        '& input + fieldset': {
            borderRadius: 10,
        },
        '& .Erk-MuiInput-underline:after': {
            borderBottomColor: 'var(--secondary)',
        },
        '& .Erk-MuiOutlinedInput-root': {
            backgroundColor: 'var(--greyHover)',
            borderRadius: 10,
            '&.Mui-focused fieldset': {
                borderColor: 'var(--secondary)',
            },
        },
        '& label.Mui-focused': {
            color: 'var(--secondary)',
        },
    },
})(TextField);

interface ComponentProps {
    /** Function called on Enter */
    handleEnter: Function;
    /** IF is of type email */
    email: boolean;
    /** Function called on change */
    onChange: React.ChangeEventHandler;
    /** Function called on change */
    handleChange: Function;
}
class LoginTextField extends React.Component<ComponentProps | any> {
    render(): JSX.Element {
        const { handleEnter, email, onChange, handleChange, ...others } =
            this.props;
        return (
            <CustomTextField
                {...others}
                variant="outlined"
                size="small"
                fullWidth
                type={email ? 'email' : 'password'}
                onChange={(event): void => {
                    handleChange?.();
                    onChange?.(event);
                }}
                InputProps={{
                    onKeyPress: (event): void => {
                        if (handleEnter && event.key === 'Enter') {
                            handleEnter();
                        }
                    },
                    startAdornment: (
                        <InputAdornment position="start">
                            {email ? <MailOutlineIcon /> : <LockIcon />}
                        </InputAdornment>
                    ),
                }}
            />
        );
    }
}

export default LoginTextField;
