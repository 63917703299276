import styles from './CreateTicket.module.css';
import Avatar from '../../../../../../shared/Avatar/Avatar';
import TicketIcon from '../../../../../../Icons/TicketIcon';
import { AutoCreateTicketAction } from '../../../../../../@Types/Action';
import { Link } from 'react-router-dom';

interface CreateTicketComponentProps {
    ticket: AutoCreateTicketAction['ticket'];
}

function CreateTicketComponent({
    ticket,
}: CreateTicketComponentProps): JSX.Element {
    return (
        <div className={styles.container}>
            <Link
                to={`/${ticket.idProject}/tickets/${ticket.idTicket}`}
                className={'app-link'}
            >
                <div className={styles.ticketContainer}>
                    <Avatar size={30} fontSize={30}>
                        <TicketIcon
                            fill="white"
                            style={{ width: 20, height: 20 }}
                        />
                    </Avatar>
                    <label className={styles.nameLbl}>
                        {ticket.subject + ' '}
                        <span className={styles.caseLbl}>
                            ({ticket.caseNumber})
                        </span>
                    </label>
                </div>
            </Link>
        </div>
    );
}

export default CreateTicketComponent;
