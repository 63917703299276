import { IconProps } from './@IconTypes';

function CommentIcon({
    fill,
    className,
    style,
    contrast = 'none',
}: IconProps): JSX.Element {
    return (
        <svg
            fill={contrast}
            className={className}
            style={style}
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 40 40"
            width="24"
        >
            <path
                d="M20 36C11.4234 36 4.4706 28.8365 4.4706 20C4.4706 11.1634 11.4234 4 20 4C28.5767 4 35.5294 11.1634 35.5294 20C35.5294 24.2608 33.9129 28.1326 31.2773 31L34.4007 34.6728C34.8405 35.1899 34.4841 36 33.8167 36H20Z"
                strokeWidth="2"
                stroke={fill}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                stroke={fill}
                d="M29 17L14 17"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                stroke={fill}
                d="M29 22L18 22"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default CommentIcon;
