import { AnyAction } from 'redux';
import * as Actions from './NotificationsMenuActions';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { loadNotificationsMenu } from './NotificationsMenuService';
import { RootState } from '../../utils/_store';
import { MenuGroupedNotification } from '../../@Types/GroupedMenuNotification';
import StateTypes from '../../constants/StateTypes';

/** Number of elements per Page */
const PAGE_SIZE = 10;

/**
 * Function called when new tickets should be rendered(reset,search, or newpage)
 * @param action of type Search, reset or scroll
 */
function* resetNotifications(action: AnyAction): any {
    if (Actions.reset.match(action)) {
        try {
            let result: MenuGroupedNotification[] = yield call(
                loadNotificationsMenu,
                1,
                PAGE_SIZE,
                action.payload.filterState
            );
            result = result.filter((element) => element.ticket);
            const page = Math.ceil(result.length / PAGE_SIZE);
            const ticketsState: Record<string, boolean> = {};
            for (let i = 0; i < result.length; i++) {
                const element = result[i];
                const state = element.ticket?.state;
                ticketsState[element.ticket._id] = !(
                    state === StateTypes.CLOSED
                );
            }
            yield put(
                Actions.resetSuccess({
                    order: {},
                    ticketsState: {},
                    elements: result,
                    page: page,
                    noMoreElements: result.length < PAGE_SIZE,
                })
            );
            yield put(Actions.setLoading(false));
        } catch (e) {
            console.error(e);
        }
    }
}

/**
 * Function called when the user scrolls the page
 */

function* getNotifications(action: AnyAction): any {
    const stateInfo = yield select(
        (state: RootState) => state.NotificationsMenu
    );
    let result = yield call(
        loadNotificationsMenu,
        stateInfo.page,
        PAGE_SIZE,
        stateInfo.filterState
    );
    const noMoreElements =
        (stateInfo.page !== 1 && result.length === 0) ||
        (stateInfo.page === 1 && result.length < PAGE_SIZE);
    if (Actions.pageScroll.match(action)) {
        result = [...stateInfo.elements, ...result];
    }
    yield put(
        Actions.getSuccess({
            order: {},
            elements: result,
            page: stateInfo.page,
            noMoreElements: noMoreElements,
        })
    );
}

function* watchResetNotifications(): any {
    yield takeLatest([Actions.Types.RESET], resetNotifications);
}

function* watchGetNotificationsRequest(): any {
    yield takeLatest([Actions.Types.PAGE_SCROLL], getNotifications);
}

export default [
    fork(watchResetNotifications),
    fork(watchGetNotificationsRequest),
];
