import List from '../../MenuList/MenuList';
import SelectedAgentComponent from './SelectedAgent/SelectedAgent';
import { SelectableAgent } from '../../../controllers/AgentsMenuController/AgentsReducer';
import AgentComponent from './Agent/Agent';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import {
    selectElements,
    pageScroll,
    reset,
    setLoading,
} from '../../../controllers/AgentsMenuController/AgentsActions';
import { Agent } from '../../../@Types/@Types';
import React, { useEffect } from 'react';
import styles from './AgentsMenu.module.css';
interface AgentsListProps {
    listRef: any;
    handleChange?: Function;
    multiple: boolean;
    /** The ids or objects of the initially selected Agents */
    initialAgents: Agent[] | string[];
    /** The initially selected Agents */
    filterAgents: Agent[];
    /** if the menu should only display the current project's agents */
    projectFilter: boolean;
    /** if the area filters should be shown */
    showAreaFilters: boolean;
}

function AgentsList({
    listRef,
    handleChange,
    multiple,
    showAreaFilters,
    projectFilter,
    filterAgents,
    initialAgents,
}: AgentsListProps): JSX.Element {
    const dispatch = useDispatch();
    const menuInfo = useSelector((state: RootState) => state.agentsMenu);
    useEffect(() => {
        dispatch(
            reset({
                selected: initialAgents,
                filterAgents,
                projectFilter,
                fetchAreas: projectFilter && showAreaFilters,
            })
        );
        if (listRef?.current?.scrollTop) {
            listRef.current.scrollTop = 0;
        }
        return (): void => {
            dispatch(setLoading(true));
        };
    }, []);
    const change = (agent: Agent, selected: boolean): void => {
        if (multiple) {
            const agents = [...menuInfo.selected];
            const withoutAgent = agents.filter(
                (agnt) => agnt._id !== agent._id
            );
            if (selected && withoutAgent.length === agents.length) {
                agents.push(agent);
                dispatch(
                    selectElements({
                        selected: agents,
                        elements: menuInfo.elements.map((agt) =>
                            agt._id === agent._id
                                ? { ...agt, selected: true }
                                : agt
                        ),
                    })
                );
                handleChange?.(agents);
            }
            if (!selected && withoutAgent.length < agents.length) {
                dispatch(
                    selectElements({
                        selected: withoutAgent,
                        elements: menuInfo.elements.map((agt) =>
                            agt._id === agent._id
                                ? { ...agt, selected: false }
                                : agt
                        ),
                    })
                );
                handleChange?.(withoutAgent);
            }
        } else {
            dispatch(
                selectElements({
                    selected: selected ? [agent] : [],
                    elements: menuInfo.elements.map((agt) => ({
                        ...agt,
                        selected: agt._id === agent._id && selected,
                    })),
                })
            );
            handleChange?.(selected ? agent : null);
        }
    };

    /**
     * Function that renders the lists agents, if search is active the selected agents
     * will be search in the browser and the rest in the backend.
     */
    const renderAgents = (): JSX.Element => {
        let selectedAgents: JSX.Element[] = [];
        let agents: Agent[] = menuInfo.selected;
        if (menuInfo.search) {
            const regex = new RegExp(menuInfo.search, 'gi');
            agents = agents.filter(
                (agent) =>
                    regex.test(agent.name) ||
                    regex.test(agent.email) ||
                    regex.test(agent.jobTitle)
            );
        }
        selectedAgents = agents.map((agent, index) => (
            <SelectedAgentComponent
                agent={agent}
                key={index}
                handleRemove={(): void => {
                    change(agent, false);
                }}
                first={index === 0}
            />
        ));

        let first = selectedAgents.length;
        const notSelected = menuInfo.elements.map(
            (agent: SelectableAgent, index): JSX.Element | void => {
                if (!multiple || !agent.selected)
                    return (
                        <AgentComponent
                            first={multiple ? first++ === 0 : index === 0}
                            agent={agent}
                            selected={!multiple && agent.selected}
                            multiple={multiple}
                            key={index}
                            handleClick={(): void => {
                                change(
                                    agent,
                                    !multiple ? !agent.selected : true
                                );
                            }}
                        />
                    );
            }
        );
        return (
            <React.Fragment>
                {multiple && selectedAgents}
                {notSelected}
                {selectedAgents.length === 0 && notSelected.length === 0 && (
                    <div className={styles.noAgentsContainer}>
                        No hay agentes
                    </div>
                )}
            </React.Fragment>
        );
    };

    return (
        <List
            noMoreElements={menuInfo.noMoreElements}
            listRef={listRef}
            onPageScroll={(): void => {
                dispatch(pageScroll());
            }}
            loading={menuInfo.loading}
            elements={menuInfo.elements}
        >
            {renderAgents()}
        </List>
    );
}

export default AgentsList;
