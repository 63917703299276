import { Agent } from '../../../../../../../../@Types/@Types';
import styles from './SelectedAgent.module.css';
import Avatar from '../../../../../../../../shared/Avatar/Avatar';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
interface SelectedAgentProps {
    /** Agent to display */
    agent: Agent;
    /** Function to call when the remove button is clicked */
    handleRemove: Function;
    /** If the agent displayed is the firstone, to not render the border-top */
    first: boolean;
    /** If the agent can be removed */
    canRemove: boolean;
}
/**
 * Component that displays the selected agents, if only one doenst show the delete
 */
function SelectedAgent({
    agent,
    handleRemove,
    first,
    canRemove,
}: SelectedAgentProps): JSX.Element {
    return (
        <div
            className={styles.agentContainer}
            style={{
                borderTop: first ? 'none' : '1px solid var(--light-grey)',
            }}
            data-testid={'SelectedAgent_Container'}
        >
            <div className={styles.checkIcon}>
                <CheckRoundedIcon fontSize="inherit" />
            </div>
            <div className={styles.avatarContainer}>
                <Avatar lbl={agent.name} img={agent.img} toolTip={false} />
            </div>
            <div className={styles.lblContainer}>
                <label className={styles.nameLbl}>{agent.name}</label>
                <label className={styles.emailContainer}>{agent.email}</label>
            </div>
            {canRemove && (
                <div
                    className={styles.closeIcon}
                    onClick={(): void => {
                        handleRemove();
                    }}
                    data-testid={'SelectedAgent_CloseIcon'}
                >
                    <CloseRoundedIcon fontSize="inherit" />
                </div>
            )}
        </div>
    );
}

export default SelectedAgent;
