import React from 'react';
import styles from './CreateSurvey.module.css';
import RoundedTextField from '../../../shared/RoundedTextField/RoundedTextField';
import RoundedButton from '../../../shared/RoundedButton/RoundedButton';
import { Controller, useForm } from 'react-hook-form';
import surveyService from '../../../controllers/SurveysController/SurveysService';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';

interface CreateSurveyProps {
    /** Function that handles when canceled or created */
    close: Function;
    /** Function that handles when survey has been created */
    handleCreated: Function;
    /** Function inherited from the dialog to activate the loader */
    setLoading?: Function;
}
/**
 * Component rendered inside the create survey dialog.
 */
function CreateSurvey({
    close,
    handleCreated,
    setLoading,
}: CreateSurveyProps): JSX.Element {
    const idProject = useSelector((state: RootState) => state.site.idProject);
    const {
        getValues,
        control,
        formState: { errors, isDirty, isValid },
        trigger,
    } = useForm({
        mode: 'onChange',
    });

    /**
     * Function triggered when user want to create an survey, checks if values are valid and calls the correct service
     */
    const handleCreate = async (): Promise<void> => {
        trigger();
        const values = getValues();
        if (!values.description) {
            values.description = '';
        }
        if (isValid && isDirty && setLoading && idProject) {
            try {
                setLoading(true);
                await surveyService.createSurvey(idProject, values);
                setLoading(false);
                handleCreated();
                close();
            } catch (error) {
                setLoading(false);
                //TODO handle error
                console.error(error);
            }
        }
    };

    return (
        <div className={styles.container + ' standard-dialog'}>
            <h2 className="center-anything">Crear Encuesta</h2>
            <div className="text-field-container">
                <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: 'El nombre es obligatorio',
                    }}
                    render={({ field }): JSX.Element => (
                        <RoundedTextField
                            {...field}
                            label="Nombre"
                            helperText={errors.name ? errors.name.message : ''}
                            error={!!errors.name}
                        />
                    )}
                />
            </div>
            <div className="text-field-container">
                <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    render={({ field }): JSX.Element => (
                        <RoundedTextField
                            {...field}
                            label="Descripción"
                            helperText={
                                errors.description
                                    ? errors.description.message
                                    : ''
                            }
                            error={!!errors.description}
                        />
                    )}
                />
            </div>
            <div className="center-anything">
                <div className="button">
                    <RoundedButton
                        text="Cancelar"
                        onClick={(): void => {
                            close();
                        }}
                    />
                </div>
                <div className="button">
                    <RoundedButton
                        text="Crear Encuesta"
                        backgroundColor="var(--secondary)"
                        color="white"
                        disabled={!!errors.name}
                        onClick={handleCreate}
                    />
                </div>
            </div>
        </div>
    );
}
export default CreateSurvey;
