import styles from './GenericFormEditor.module.css';
import { RouterProps } from '../../Router';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import NoMobile from '../../shared/NoMobile/NoMobile';
import React, { cloneElement, useRef, useState, createContext } from 'react';
import Section from './Section/Section';
import Dialog from '../Dialog/Dialog';
import DefaultConfigs from './DefaultConfigs/DefaultConfigs';
import Overview from './Overview/Overview';
import { Project } from '../../@Types/@Types';
import RoundedButton from '../RoundedButton/RoundedButton';
import { GBaseStep, GForm } from '../../@Types/GenericForm';
import FormPreview from './Preview/FormPreview';
import {
    setShowPreview,
    setShowSettings,
    updateData,
} from '../../controllers/GenericEditorController/GenericEditorActions';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import SaveDialog from './SaveDialog/SaveDialog';
import { GenericStepProps } from './Step/Step';
import {
    GenericEditorState,
    denormalizeForm,
} from '../../controllers/GenericEditorController/GenericEditorReducer';
import { AppProps } from '@arquimedes.co/eureka-forms/dist/App/App';
import { GLocation } from '../../@Types/FormTypes/LocationTypes';
import {
    useGenericEditorDispatch,
    useGenericEditorSelector,
} from './GenericFormEditorHooks';

export interface BaseEditorProps<D> {
    multiSection: boolean;
    calcCurrentRoute?: (
        project: Project | undefined,
        data: D,
        sectionName: string | undefined
    ) => string;
    /** Custom steps to display */
    customSteps?: AppProps['customSteps'];
}

export interface RecursiveConfigProps {
    configComponent?: JSX.Element;
    editStyle?: boolean;
    editSize?: boolean;
}

export interface RecursiveSectionProps<
    S extends GBaseStep,
    U,
    L extends GLocation
> {
    /** If the user can edit the steps' ids */
    editId?: boolean;
    stepMapper: (props: GenericStepProps<S, U, L>) => JSX.Element;
    stepMenuComponent?: JSX.Element;
    stepMapperProps?: Record<string, any>;
    customStepBtns?: (props: GenericStepProps<S, U, L>) => JSX.Element;
}

interface SaveProps<S extends GBaseStep, U, D> {
    handleSave: (form: GForm<S, U>, data: D) => Promise<void>;
    getFormFromData: (data: D) => GForm<S, U>;
}

interface ComponentProps<S extends GBaseStep, U, L extends GLocation, D>
    extends RouterProps,
        BaseEditorProps<D>,
        RecursiveConfigProps,
        RecursiveSectionProps<S, U, L>,
        SaveProps<S, U, D> {}

function GenericFormEditorComponent<
    F extends GForm<S, U>,
    S extends GBaseStep,
    U,
    L extends GLocation,
    D
>({
    mobile,
    handleSave,
    customSteps,
    multiSection,
    configComponent,
    editStyle = false,
    editSize = false,
    editId = false,
    calcCurrentRoute,
    getFormFromData,
    ...others
}: ComponentProps<S, U, L, D>): JSX.Element {
    let { path } = useRouteMatch();
    const dispatch = useGenericEditorDispatch();

    const editorInfo = useGenericEditorSelector<
        GenericEditorState<F, S, U, L, D>
    >((state) => state);

    const containerRef = useRef<HTMLDivElement>(null);
    const [saving, setSaving] = useState(false);
    if (!editorInfo) return <div></div>;
    if (mobile) {
        return <NoMobile />;
    }

    const getGenericForm = (): GForm<S, U> => {
        return denormalizeForm(
            {
                name: editorInfo.name,
                firstSection: editorInfo.firstSection,
                steps: editorInfo.steps,
                sections: editorInfo.sections,
                uniqueSteps: editorInfo.uniqueSteps,
                size: editorInfo.size,
                style: editorInfo.style,
                terms: editorInfo.terms,
            },
            editorInfo.config.calcExtraSteps?.(editorInfo.data)
        );
    };

    const renderContent = (): JSX.Element => {
        if (multiSection) {
            return (
                <div className={styles.container} ref={containerRef}>
                    <Switch>
                        <Route path={`${path}/:idSection`}>
                            <Section<F, S, U, L, D>
                                multiSection={multiSection}
                                calcCurrentRoute={calcCurrentRoute}
                                editId={editId}
                                containerRef={containerRef}
                                {...others}
                            />
                        </Route>
                        <Route path={`${path}/`}>
                            <Overview
                                multiSection={true}
                                configComponent={configComponent}
                                editStyle={editStyle}
                                editSize={editSize}
                                calcCurrentRoute={calcCurrentRoute}
                            />
                        </Route>
                    </Switch>
                </div>
            );
        } else {
            return (
                <div className={styles.container} ref={containerRef}>
                    {editorInfo.showSettings && (
                        <Dialog
                            open={editorInfo.showSettings}
                            onClose={(): void => {
                                dispatch(setShowSettings(false));
                            }}
                            maxWidth="100%"
                        >
                            <div className={styles.settingsContainer}>
                                <div className={styles.settingsLabel}>
                                    Configuraciones
                                </div>
                                {configComponent !== undefined &&
                                    cloneElement(configComponent, {
                                        data: editorInfo.data,
                                        handleUpdate: (data: D): void => {
                                            dispatch(updateData(data));
                                        },
                                    })}
                                <DefaultConfigs
                                    editStyle={editStyle}
                                    editSize={editSize}
                                />
                            </div>
                        </Dialog>
                    )}
                    <Section<F, S, U, L, D>
                        containerRef={containerRef}
                        multiSection={multiSection}
                        calcCurrentRoute={calcCurrentRoute}
                        editId={editId}
                        setShowSettings={(): void => {
                            dispatch(setShowSettings());
                        }}
                        {...others}
                    />
                </div>
            );
        }
    };

    return (
        <div className={styles.container}>
            <div
                className={styles.saveBtn}
                data-testid={'GenericFormEditor_saveBtn'}
            >
                <RoundedButton
                    text="Guardar"
                    fontSize={22}
                    borderRadius={12}
                    padding="20px 12px"
                    onClick={(): void => {
                        setSaving(true);
                    }}
                />
            </div>
            {/* Preview */}
            <div
                className={styles.previewBtn}
                onClick={(): void => {
                    dispatch(setShowPreview(true));
                }}
            >
                <VisibilityRoundedIcon fontSize="inherit" />
            </div>
            {editorInfo.showPreview && (
                <Dialog
                    maxWidth="100%"
                    disableEnforceFocus
                    onClose={(): void => {
                        dispatch(setShowPreview(false));
                    }}
                >
                    <FormPreview
                        form={{
                            ...getFormFromData(editorInfo.data),
                            ...getGenericForm(),
                        }}
                        customSteps={customSteps}
                    />
                </Dialog>
            )}
            {saving && (
                <SaveDialog
                    onClose={(): void => {
                        setSaving(false);
                    }}
                    form={getGenericForm()}
                    handleSave={handleSave}
                    data={editorInfo.data}
                />
            )}
            {renderContent()}
        </div>
    );
}

export const IdGenericEditorContext = createContext<string>('');

function GenericFormEditor<
    F extends GForm<S, U>,
    S extends GBaseStep,
    U,
    L extends GLocation,
    D
>({
    idEditor,
    ...props
}: ComponentProps<S, U, L, D> & { idEditor: string }): JSX.Element {
    return (
        <IdGenericEditorContext.Provider value={idEditor}>
            <GenericFormEditorComponent<F, S, U, L, D> {...props} />
        </IdGenericEditorContext.Provider>
    );
}

export default GenericFormEditor;
