import styles from './ListStep.module.css';
import React, { useContext, useState } from 'react';
import { ListStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import { useDispatch } from 'react-redux';
import { updateStep } from '../../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import RoundedTextField from '../../../../../../shared/RoundedTextField/RoundedTextField';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RoundedRadio from '../../../../../../shared/RoundedRadio/RoundedRadio';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { nanoid } from 'nanoid';
import RoundedClassifierPicker from '../../../../../../shared/@Pickers/RoundedClassifierPicker/RoundedClassifierPicker';
import FilterIcon from '../../../../../../Icons/FilterIcon';
import produce from 'immer';
import Conditions from '../../Condition/Condition';
import Toggle from '../../../../../../shared/Toggle/Toggle';
import {
    ApiKeyContext,
    BaseConversationPayloadConditionTypes,
} from '../../../ConversationEditor';
import PayloadEditor from '../../../../../../shared/TextEditor/PayloadEditor';
import { TextEditorTypes } from '../../../../../../constants/TextEditorTypes';

interface ListStepProps {
    step: ListStep;
}

function ListStepComponent({ step }: ListStepProps): JSX.Element {
    const dispatch = useDispatch();
    const apiKey = useContext(ApiKeyContext);
    const [showConditions, setShowConditions] = useState<undefined | string>();
    const mapSectionOptions = (
        section: {
            label: string;
            options: string[];
        },
        indexSection: number
    ): JSX.Element[] => {
        return section.options.map((idOption) => {
            const option = step.options[idOption];
            return (
                <div className={styles.optionContainer} key={idOption}>
                    <div className={styles.optionContentContainer}>
                        <div
                            className={styles.optionRadio}
                            title={'Seleccionar Opción'}
                        >
                            <RoundedRadio
                                checked={idOption === step.selectedOption}
                                onChange={(): void => {
                                    dispatch(
                                        updateStep({
                                            ...step,
                                            selectedOption: idOption,
                                        })
                                    );
                                }}
                            />
                        </div>
                        <div className={styles.optionLabelInput}>
                            <RoundedTextField
                                label="Opción"
                                multiline
                                maxLength={20}
                                value={option.label}
                                onChange={(e): void => {
                                    dispatch(
                                        updateStep({
                                            ...step,
                                            options: {
                                                ...step.options,
                                                [idOption]: {
                                                    ...option,
                                                    label: e.target.value,
                                                },
                                            },
                                        })
                                    );
                                }}
                            ></RoundedTextField>
                        </div>

                        <div className={styles.btnsContainer}>
                            <div
                                className={styles.conditionsBtnContainer}
                                onClick={(): void => {
                                    setShowConditions(option.id);
                                }}
                            >
                                <FilterIcon
                                    style={{
                                        display: 'flex',
                                        height: 18,
                                        width: 18,
                                        marginTop: 2,
                                    }}
                                    fill="inherit"
                                />
                            </div>
                            {section.options.length > 1 && (
                                <div
                                    className={styles.deleteBtn}
                                    onClick={(): void => {
                                        const newStep = produce(
                                            step,
                                            (tempStep) => {
                                                tempStep.sections[
                                                    indexSection
                                                ] = {
                                                    ...section,
                                                    options:
                                                        section.options.filter(
                                                            (opt) =>
                                                                opt !== idOption
                                                        ),
                                                };
                                                delete tempStep.options[
                                                    idOption
                                                ];
                                                if (
                                                    tempStep.selectedOption ===
                                                    idOption
                                                ) {
                                                    tempStep.selectedOption =
                                                        null;
                                                }
                                            }
                                        );
                                        dispatch(updateStep(newStep));
                                    }}
                                >
                                    <DeleteRoundedIcon fontSize="inherit" />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={styles.optionDescriptionContainer}>
                        <RoundedTextField
                            label="Descripción"
                            multiline
                            maxLength={72}
                            value={option.description}
                            onChange={(e): void => {
                                dispatch(
                                    updateStep({
                                        ...step,
                                        options: {
                                            ...step.options,
                                            [idOption]: {
                                                ...option,
                                                description: e.target.value,
                                            },
                                        },
                                    })
                                );
                            }}
                        ></RoundedTextField>
                    </div>
                    <div className={styles.optionClassifierContainer}>
                        <div className={styles.classifierNameLbl}>
                            Clasificador:
                        </div>
                        <div className={styles.optionClassifierInput}>
                            <RoundedClassifierPicker
                                fullWidth
                                alwaysHot
                                label=""
                                height="32px"
                                value={
                                    option.classifier ? [option.classifier] : []
                                }
                                handleUpdate={(classifiers): void => {
                                    const newStep = produce(
                                        step,
                                        (tempStep) => {
                                            tempStep.options[
                                                idOption
                                            ].classifier = classifiers?.[0];
                                        }
                                    );
                                    dispatch(updateStep(newStep));
                                }}
                            />
                        </div>
                    </div>
                </div>
            );
        });
    };

    const mapSections = (): JSX.Element[] => {
        return step.sections.map((section, indexSection) => {
            return (
                <div
                    className={styles.sectionContainer}
                    key={'SEC-' + indexSection}
                >
                    <div className={styles.sectionLblContainer}>
                        <div className={styles.sectionInput}>
                            <RoundedTextField
                                label="Sección (Opcional)"
                                multiline
                                maxLength={24}
                                value={section.label}
                                onChange={(e): void => {
                                    const tempStep = { ...step };
                                    tempStep.sections = [...step.sections];
                                    tempStep.sections[indexSection] = {
                                        ...tempStep.sections[indexSection],
                                        label: e.target.value,
                                    };
                                    dispatch(updateStep(tempStep));
                                }}
                            ></RoundedTextField>
                        </div>
                        {step.sections.length > 1 && (
                            <div
                                className={styles.deleteSectionContainer}
                                onClick={(): void => {
                                    const newStep = produce(
                                        step,
                                        (tempStep) => {
                                            for (const idOption of section.options) {
                                                delete tempStep.options[
                                                    idOption
                                                ];
                                                if (
                                                    tempStep.selectedOption ===
                                                    idOption
                                                ) {
                                                    tempStep.selectedOption =
                                                        null;
                                                }
                                            }
                                            tempStep.sections.splice(
                                                indexSection,
                                                1
                                            );
                                        }
                                    );
                                    dispatch(updateStep(newStep));
                                }}
                            >
                                <DeleteRoundedIcon fontSize="inherit" />
                            </div>
                        )}
                    </div>
                    <div className={styles.sectionOptionsContainer}>
                        {mapSectionOptions(section, indexSection)}
                        {Object.keys(step.options).length < 10 && (
                            <div
                                className={styles.addOption}
                                onClick={(): void => {
                                    const newStep = produce(
                                        step,
                                        (tempStep) => {
                                            const id = step.id + ';' + nanoid();
                                            const newOption = {
                                                id,
                                                label: '',
                                                description: '',
                                                steps: [],
                                                conditions: [],
                                            };
                                            tempStep.options[id] = newOption;
                                            tempStep.sections[
                                                indexSection
                                            ].options.push(id);
                                        }
                                    );
                                    dispatch(updateStep(newStep));
                                }}
                            >
                                <div className={styles.addIconContainer}>
                                    <AddRoundedIcon fontSize="inherit" />
                                </div>
                                <div
                                    className={
                                        styles.addOptionLbL + ' noselect'
                                    }
                                >
                                    Agregar Opción
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            );
        });
    };

    return (
        <React.Fragment>
            {showConditions && (
                <Conditions
                    handleClose={(): void => {
                        setShowConditions(undefined);
                    }}
                    condition={step.options[showConditions].condition}
                    title={step.name}
                    handleUpdate={(condition): void => {
                        dispatch(
                            updateStep(
                                produce(step, (newStep) => {
                                    const option =
                                        newStep.options[showConditions];
                                    if (option) {
                                        option.condition = condition;
                                    }
                                })
                            )
                        );
                    }}
                    idStep={step.id}
                />
            )}
            <div className={styles.headerContainer}>
                <RoundedTextField
                    label="Encabezado"
                    multiline
                    maxLength={60}
                    value={step.header}
                    onChange={(e): void => {
                        dispatch(
                            updateStep({ ...step, header: e.target.value })
                        );
                    }}
                ></RoundedTextField>
            </div>
            <div className={styles.container}>
                <div className={styles.editorContainer}>
                    <PayloadEditor
                        placeholder={'Mensaje'}
                        type={TextEditorTypes.WHATSAPP}
                        idEditor={`Conversation-${apiKey}-${step.id}`}
                        payload={{ draft: step.message }}
                        onChange={({ draft: message }): void => {
                            if (!message) return;
                            dispatch(updateStep({ ...step, message }));
                        }}
                        media={false}
                        context={{ client: true }}
                        conditions={{
                            types: BaseConversationPayloadConditionTypes,
                            context: {
                                conversation: {
                                    idStep: step.id,
                                },
                            },
                        }}
                        minHeight={144}
                        maxHeight={400}
                        required
                        maxLength={4096}
                    />
                </div>
            </div>
            <div className={styles.footerContainer}>
                <RoundedTextField
                    label="Pie"
                    multiline
                    maxLength={60}
                    value={step.footer}
                    onChange={(e): void => {
                        dispatch(
                            updateStep({ ...step, footer: e.target.value })
                        );
                    }}
                ></RoundedTextField>
            </div>
            <div className={styles.btnLblContainer}>
                <RoundedTextField
                    label="Botón"
                    value={step.label}
                    maxLength={20}
                    onChange={(e): void => {
                        dispatch(
                            updateStep({
                                ...step,
                                label: e.target.value,
                            })
                        );
                    }}
                ></RoundedTextField>
            </div>
            <div className={styles.toggleLabelContainer}>
                Asunto del Caso:
                <div className={styles.toggleContainer}>
                    <Toggle
                        checked={step.isSubject}
                        onChange={(checked: boolean): void => {
                            dispatch(
                                updateStep({
                                    ...step,
                                    isSubject: checked,
                                })
                            );
                        }}
                    />
                </div>
            </div>
            <div className={styles.toggleLabelContainer}>
                Mostrar en la Traza:
                <div className={styles.toggleContainer}>
                    <Toggle
                        checked={step.showInTrace}
                        onChange={(checked: boolean): void => {
                            dispatch(
                                updateStep({
                                    ...step,
                                    showInTrace: checked,
                                })
                            );
                        }}
                    />
                </div>
            </div>
            <div className={styles.optionsContainer}>
                <label className={styles.sectionLbl}>Opciones:</label>
                {mapSections()}
                {Object.keys(step.options).length < 10 && (
                    <div
                        className={styles.addSection}
                        onClick={(): void => {
                            const newStep = produce(step, (tempStep) => {
                                const id = step.id + ';' + nanoid();
                                const newOption = {
                                    id,
                                    label: '',
                                    description: '',
                                    steps: [],
                                    conditions: [],
                                };
                                tempStep.options[id] = newOption;
                                tempStep.sections.push({
                                    label: '',
                                    options: [id],
                                });
                            });
                            dispatch(updateStep(newStep));
                        }}
                    >
                        <div className={styles.addIconContainer}>
                            <AddRoundedIcon fontSize="inherit" />
                        </div>
                        <div className={styles.addOptionLbL + ' noselect'}>
                            Agregar Sección
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

export default ListStepComponent;
