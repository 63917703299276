import { Client } from '../../@Types/@Types';
import axiosInstance from '../../AxiosAPI';

export const loadClientsByQuery = async (
    queryString: string,
    idClient?: string
): Promise<{
    elements: Client[];
    page: number;
    selectedElement?: number;
    filters: {};
    orderBy: string;
}> => {
    const query = new URLSearchParams(queryString);
    const result = await axiosInstance.get(
        `/clients/load${idClient ? '/' + idClient : ''}` + '?' + query
    );
    return { ...result.data };
};

/**
 * Function that loads the clients' list
 * @param page currently viewing
 * @param pageSize to request
 * @param filters currently active
 * @param orderBy method ordered by, undefined if search is active
 * @param search search query, undefined if no search is active
 * @returns array of Clients that match the inputs
 */
export const loadClients = async (
    page: number,
    pageSize: number,
    filters: {
        idCompany?: string;
    },
    orderBy: string | undefined,
    search: string | undefined
): Promise<Client[]> => {
    let url = `/clients?page=${page}&pageSize=${pageSize}`;

    if (filters.idCompany) {
        url += `&idCompany=${filters.idCompany}`;
    }

    if (search !== undefined) {
        url += `&search=${encodeURIComponent(search)}`;
    } else {
        url += `&orderBy=${orderBy}`;
    }
    const resp = await axiosInstance.get(url);
    return resp.data;
};

/**
 * Function called to handle the conexion with the server to create an client
 * @param client Record of values to create the client with
 */
export const createClient = async (
    client: Record<string, unknown>
): Promise<void> => {
    await axiosInstance.post('/clients', client);
};

/**
 * Function that handles the async update of an client's value
 * @param id of the client to update
 * @param client Record of values to update
 */
export const updateClient = async (
    id: string,
    client: Partial<Client>
): Promise<void> => {
    await axiosInstance.patch(`/clients/${id}`, client);
};

/**
 * Function that fetches a single client given its id
 * @param idClient the id of the client to fetch
 * @returns ticket
 */
export const loadClientById = async (idClient: string): Promise<Client> => {
    const response = await axiosInstance.get(`/clients/${idClient}`);
    return response.data;
};

/**
 * Function that loads the clients by a given list of ids
 * @param clients the list of ids
 */
export const loadClientsById = async (clients: string[]): Promise<Client[]> => {
    const response = await axiosInstance.get(
        `/clients?id=${clients.join(',')}`
    );
    return response.data;
};

/**
 * Function called to handle the conexion with the server to delete a client
 * @param idClient the id of the client to delete
 */
export const deleteClient = async (idClient: string): Promise<void> => {
    await axiosInstance.delete(`/clients/${idClient}`);
};

/**
 * Function called to fetch the unique identifierTypes in the DB
 */
export const fetchIdentifierTypes = async (): Promise<string[]> => {
    const response = await await axiosInstance.get('/clients/identifierTypes');
    return response.data;
};

export default {
    loadClients,
    createClient,
    updateClient,
    loadClientById,
    loadClientsById,
    deleteClient,
};
