import styles from './ClassifiersSideBar.module.css';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import ClassifierPill from '../../../../../shared/ClassifierPill/ClassifierPill';
import ClassifiersMenu, {
    ClassifierObj,
} from './ClassifiersMenu/ClassifiersMenu';
import { RootState } from '../../../../../utils/_store';
import { Ticket } from '../../../../../@Types/@Types';
import { TicketClassifier } from '../../../../../@Types/TicketTypes/Ticket';
import { ProjectPermissions } from '../../../../../constants/Permissions';
import { useTicketPermission } from '../../../../../utils/PermissionsFunctions';

interface ClassifiersSideBarProps {
    /** currently displayed ticket */
    selectedElement: Ticket;
    /** If the layout is mobile */
    layoutIsMobile?: boolean;
}
function ClassifiersSideBar({
    layoutIsMobile,
    selectedElement,
}: ClassifiersSideBarProps): JSX.Element {
    const [hovering, setHovering] = useState(false);

    const classifiers = useSelector(
        (state: RootState) => state.site.classifiers
    );
    const classifierRoots = useSelector(
        (state: RootState) => state.site.classifierRoots
    );

    const [menuItem, setMenuItem] = useState<ClassifierObj | undefined | null>(
        undefined
    );
    const headerRef = useRef<HTMLDivElement>(null);

    const canChange = useTicketPermission(
        selectedElement,
        ProjectPermissions.CLASSIFY
    );

    const calcClassifiers = (): TicketClassifier[] => {
        const elements: TicketClassifier[] = [];
        //Iterate the roots in order
        for (const root of classifierRoots) {
            //If a clasifier is of the same root add it to the list.
            for (const classifier of selectedElement.classifiers) {
                if (classifier.idRoot === root) {
                    elements.push(classifier);
                }
            }
        }
        return elements;
    };

    return (
        <div className={styles.container}>
            {menuItem !== undefined && (
                <ClassifiersMenu
                    layoutIsMobile={layoutIsMobile}
                    selectedElement={selectedElement}
                    anchorRef={headerRef}
                    menuItem={menuItem}
                    handleClose={(): void => {
                        setMenuItem(undefined);
                    }}
                />
            )}
            <div
                ref={headerRef}
                className={styles.header + ' noselect'}
                data-testid="ClassifiersSideBar_header"
                onMouseOver={(): void => {
                    if (menuItem === undefined && canChange) {
                        setHovering(true);
                    }
                }}
                onMouseLeave={(): void => {
                    setHovering(false);
                }}
                onClick={(): void => {
                    if (canChange) {
                        setMenuItem(null);
                        setHovering(false);
                    }
                }}
                style={canChange ? { cursor: 'pointer' } : {}}
            >
                <label
                    className={styles.titleLbl}
                    style={{
                        color: hovering ? 'var(--contrast)' : 'var(--accent)',
                        cursor: canChange ? 'pointer' : 'default',
                    }}
                >
                    Clasificadores
                </label>
                {canChange && (
                    <div
                        className={styles.editContainer}
                        style={{
                            color: hovering
                                ? 'var(--contrast)'
                                : 'var(--accent)',
                        }}
                    >
                        <EditRoundedIcon fontSize="inherit" />
                    </div>
                )}
            </div>
            <div className={styles.pillsContainer}>
                {calcClassifiers().map((classifier, index) => (
                    <div key={index} className={styles.pillContainer}>
                        <div
                            className={
                                canChange
                                    ? styles.pillCont
                                    : styles.pillContClosed
                            }
                        >
                            <ClassifierPill
                                handleClick={
                                    canChange
                                        ? (): void => {
                                              let current = classifier.idRoot;
                                              const element =
                                                  classifiers[
                                                      classifier.idValue
                                                  ];
                                              //Open the dialog showing the classifier's siblings
                                              if (
                                                  element &&
                                                  element.path &&
                                                  element.path.length > 0
                                              ) {
                                                  current =
                                                      element.path[
                                                          element.path.length -
                                                              1
                                                      ];
                                              }
                                              setMenuItem({
                                                  idRoot: classifier.idRoot,
                                                  idValue: classifier.idValue,
                                                  current,
                                              });
                                          }
                                        : undefined
                                }
                                showPath={true}
                                idRoot={classifier.idRoot}
                                idValue={classifier.idValue}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
export default ClassifiersSideBar;
