import styles from './RefreshButton.module.css';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
interface RefreshButtonProps {
    onClick: Function;
}
function RefreshButton({ onClick }: RefreshButtonProps): JSX.Element {
    return (
        <div
            className={styles.container}
            onClick={(): void => {
                onClick();
            }}
        >
            <div className={styles.button}>
                <div className={styles.iconContainer}>
                    <RefreshRoundedIcon fontSize="inherit" />
                </div>
            </div>
        </div>
    );
}
export default RefreshButton;
