import React, { useEffect, useState } from 'react';
import ListDetailLayout from '../../shared/ListDetailLayout/ListDetailLayout';
import PageTitle from '../../shared/PageTitle/PageTitle';
import Tree from '../../shared/Tree/Tree';
import AreaTreeItem from './AreaTreeItem/AreaTreeItem';
import AreaDetail from './AreaDetail/AreaDetail';
import Dialog from '../../shared/Dialog/Dialog';
import ConfirmDialog from '../../shared/ConfirmDialog/ConfirmDialog';
import CreateArea from './CreateArea/CreateArea';
import Search from '../../shared/Search/Search';
import RoundedButton from '../../shared/RoundedButton/RoundedButton';
import { useSelector, useDispatch } from 'react-redux';
import {
    reset,
    refresh as refreshAction,
    setLoading,
    selectElement,
    updateElement,
    search,
} from '../../controllers/AreasController/AreasActions';
import areaService from '../../controllers/AreasController/AreasService';
import { RootState } from '../../utils/_store';
import { Area, Agent } from '../../@Types/@Types';
function AreasPage(): JSX.Element {
    const dispatch = useDispatch();
    const siteInfo = useSelector((state: RootState) => state.site);
    const pageInfo = useSelector((state: RootState) => state.areasPage);
    const [createDialog, setCreateDialog] = useState<any>(undefined);
    const [deleteDialog, setDeleteDialog] = useState(undefined);
    const [removeAgentDialog, setRemoveAgentDialog] = useState<any>(undefined);

    /** If project changes reset the content */
    useEffect(() => {
        dispatch(reset());
    }, [siteInfo.idProject]);

    /** Called when content needs to refresh */
    const refresh = (): void => {
        dispatch(refreshAction());
    };

    return (
        <React.Fragment>
            {createDialog !== undefined && (
                <Dialog
                    open={createDialog !== undefined}
                    onClose={(): void => setCreateDialog(undefined)}
                >
                    <CreateArea
                        parent={createDialog}
                        close={(): void => setCreateDialog(undefined)}
                        handleCreated={refresh}
                    />
                </Dialog>
            )}
            {deleteDialog !== undefined && (
                <ConfirmDialog
                    onConfirm={async (
                        element: Area,
                        setLoading: Function
                    ): Promise<void> => {
                        if (siteInfo.idProject) {
                            try {
                                setLoading(true);
                                await areaService.deleteArea(
                                    siteInfo.idProject,
                                    element._id
                                );
                                dispatch(reset());
                                setDeleteDialog(undefined);
                            } catch (error) {
                                //TODO: handle errors
                                console.error(error);
                                setLoading(false);
                            }
                        }
                    }}
                    element={deleteDialog}
                    deleting
                    title="Borrar Área"
                    btnMsg="Borrar"
                    msg="¿Está seguro que desea borrar esta área?"
                    onClose={(): void => {
                        setDeleteDialog(undefined);
                    }}
                />
            )}
            {removeAgentDialog !== undefined && (
                <ConfirmDialog
                    onConfirm={async (
                        element: {
                            agent: Agent;
                            parent: Area;
                        },
                        setLoading: Function
                    ): Promise<void> => {
                        if (siteInfo.idProject) {
                            try {
                                setLoading(true);
                                await areaService.removeAgent(
                                    siteInfo.idProject,
                                    element.parent._id,
                                    element.agent._id
                                );
                                dispatch(refreshAction());
                                setRemoveAgentDialog(undefined);
                            } catch (error) {
                                //TODO handle errors
                                console.error(error);
                                setLoading(false);
                            }
                        }
                    }}
                    deleting
                    element={removeAgentDialog}
                    title="Remover Agente"
                    btnMsg="Remover"
                    msg="¿Está seguro que desea remover este agente?"
                    onClose={(): void => {
                        setRemoveAgentDialog(undefined);
                    }}
                />
            )}
            <div className="standard-layout">
                <PageTitle
                    title={'Áreas'}
                    route={
                        siteInfo?.projects[siteInfo?.idProject ?? '']?.name +
                        ' / Áreas'
                    }
                />
                <div className="filters-container flexbox">
                    <div data-testid={'AreasPage_NewButton'}>
                        <RoundedButton
                            text="Nueva"
                            backgroundColor={'var(--secondary)'}
                            color={'white'}
                            onClick={(): void => {
                                setCreateDialog(null);
                            }}
                        />
                    </div>
                    <div className="search-container">
                        <Search
                            search={pageInfo.search}
                            handleLoadingSearch={(loading: boolean): void => {
                                dispatch(setLoading(loading));
                            }}
                            handleSearch={(value: string | undefined): void => {
                                dispatch(search(value));
                            }}
                        />
                    </div>
                </div>
                <ListDetailLayout
                    listWidth={450}
                    responsiveBreakpoint={1000}
                    selectElementLabel="Selecciona una área"
                    loading={pageInfo.loading}
                    selectedElement={pageInfo.selectedElement}
                    handleSelectedElement={(
                        idSelectedElement: string
                    ): void => {
                        dispatch(selectElement(idSelectedElement));
                    }}
                >
                    <Tree
                        data-testid={'Area_tree'}
                        elements={pageInfo.elements}
                        roots={pageInfo.roots}
                        label="No hay áreas"
                        saveOrderChanges={async (
                            pRoots: string[]
                        ): Promise<void> => {
                            if (siteInfo.idProject) {
                                try {
                                    setLoading(true);
                                    await areaService.updateOrderAreas(
                                        siteInfo.idProject,
                                        pRoots
                                    );
                                    refresh();
                                } catch (error) {
                                    //TODO: handle errors
                                    console.error(error);
                                    setLoading(false);
                                }
                            }
                        }}
                    >
                        <AreaTreeItem isRoot={true} />
                    </Tree>
                    <AreaDetail
                        elements={pageInfo.elements}
                        handleClickDelete={setDeleteDialog}
                        handleUpdate={(
                            object: Area,
                            field: keyof Area,
                            value: string
                        ): void => {
                            dispatch(updateElement({ object, field, value }));
                        }}
                        createSubArea={setCreateDialog}
                        removeAgent={({
                            agent,
                            parent,
                        }: {
                            agent: Agent;
                            parent: Area;
                        }): void => {
                            setRemoveAgentDialog({ agent, parent });
                        }}
                    />
                </ListDetailLayout>
            </div>
        </React.Fragment>
    );
}

export default AreasPage;
