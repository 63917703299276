import styles from './LinkStep.module.css';
import React, { useContext } from 'react';
import { LinkStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import InformativeTypePicker from '../InformativeTypePicker/InformativeTypePicker';
import PayloadEditor from '../../../../../../shared/TextEditor/PayloadEditor';
import {
    ApiKeyContext,
    BaseConversationPayloadConditionTypes,
} from '../../../ConversationEditor';
import { useAppDispatch } from '../../../../../../hooks';
import { updateStep } from '../../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import RoundedTextField from '../../../../../../shared/RoundedTextField/RoundedTextField';
import { TextEditorTypes } from '../../../../../../constants/TextEditorTypes';
interface LinkStepProps {
    step: LinkStep;
}

function LinkStepComponent({ step }: LinkStepProps): JSX.Element {
    const apiKey = useContext(ApiKeyContext);
    const dispatch = useAppDispatch();
    return (
        <React.Fragment>
            <InformativeTypePicker step={step} />
            <div className={styles.headerContainer}>
                <RoundedTextField
                    label="Encabezado"
                    multiline
                    maxLength={60}
                    value={step.header}
                    onChange={(e): void => {
                        dispatch(
                            updateStep({ ...step, header: e.target.value })
                        );
                    }}
                ></RoundedTextField>
            </div>
            <div className={styles.container}>
                <div className={styles.editorContainer}>
                    <PayloadEditor
                        placeholder={'Mensaje'}
                        type={TextEditorTypes.WHATSAPP}
                        idEditor={`Conversation-${apiKey}-${step.id}`}
                        payload={{
                            draft: step.message,
                        }}
                        onChange={({ draft: message }): void => {
                            if (!message) return;
                            dispatch(updateStep({ ...step, message }));
                        }}
                        media={false}
                        context={{
                            client: true,
                        }}
                        conditions={{
                            types: BaseConversationPayloadConditionTypes,
                            context: {
                                conversation: {
                                    idStep: step.id,
                                },
                            },
                        }}
                        minHeight={144}
                        maxHeight={400}
                        required
                        maxLength={4096}
                    />
                </div>
            </div>
            <div className={styles.footerContainer}>
                <RoundedTextField
                    label="Pie"
                    multiline
                    maxLength={60}
                    value={step.footer}
                    onChange={(e): void => {
                        dispatch(
                            updateStep({ ...step, footer: e.target.value })
                        );
                    }}
                ></RoundedTextField>
            </div>
            <div className={styles.btnLblContainer}>
                <RoundedTextField
                    label="Botón"
                    value={step.label}
                    maxLength={20}
                    onChange={(e): void => {
                        dispatch(
                            updateStep({
                                ...step,
                                label: e.target.value,
                            })
                        );
                    }}
                ></RoundedTextField>
            </div>
            <div className={styles.urlContainer}>
                <RoundedTextField
                    label="Url"
                    placeholder="https://capta.co"
                    value={step.url}
                    onChange={(e): void => {
                        dispatch(
                            updateStep({
                                ...step,
                                url: e.target.value,
                            })
                        );
                    }}
                ></RoundedTextField>
            </div>
        </React.Fragment>
    );
}

export default LinkStepComponent;
