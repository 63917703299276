import styles from './FileComponent.module.css';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Loader from '../Loader/Loader';
import { useEffect, useRef, useState } from 'react';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import { fetchDownloadUrl } from '../../controllers/FilesController/FilesService';
import React from 'react';
import { PayloadFile } from '../../@Types/Payload';
import { FileObject } from '../../@Types/ConversationTypes/ConversationStep';
import { Fab, Popper, withStyles } from '@material-ui/core';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import {
    PayloadEditorFile,
    PayloadEditorStateTypes,
} from '../../controllers/PayloadEditorController/PayloadEditorTypes';

interface FileComponentProps {
    file: PayloadFile | FileObject | PayloadEditorFile;
    loadingOverride?: boolean;
    handleEdit?: () => void;
    handleRemove?: () => void;
    large?: boolean;
    error?: string;
    zIndex?: number;
}

function FileComponent({
    file,
    error,
    handleEdit,
    handleRemove,
    large = false,
    zIndex = 3,
    loadingOverride = false,
}: FileComponentProps): JSX.Element {
    const [downloading, setDownloading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [timer, setTimer] = useState<any>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);

    /**
     * Deletes timeout on component exit.
     */
    useEffect((): (() => void) => {
        return (): void => {
            clearTimer(timer);
        };
    }, [timer]);

    if (!file) return <div></div>;

    const downloadFile = async (download = false): Promise<void> => {
        if (
            isPayloadEditorFile(file) &&
            file.state !== PayloadEditorStateTypes.COMPLETED
        )
            return;
        try {
            let fileUrl;
            setDownloading(true);
            setOpen(false);
            if (!isFileObject(file)) {
                fileUrl = await fetchDownloadUrl(
                    file.S3Key,
                    file.fileName,
                    download
                );
            } else fileUrl = file.url;
            var a = document.createElement('a');
            a.href = fileUrl;
            if (isFileObject(file) || !download) {
                a.target = '_blank';
            }
            a.setAttribute('download', file.fileName);
            a.click();
        } catch (error) {
            console.error(error);
        }
        setDownloading(false);
        setOpen(false);
    };

    const calcIcon = (): JSX.Element => {
        if (downloading || loadingOverride) {
            return (
                <div className={styles.loaderContainer}>
                    <Loader size={26} color={'var(--secondary)'} />
                </div>
            );
        } else if (error) {
            return (
                <div
                    className={styles.documentIcon}
                    style={{ color: 'var(--error)' }}
                >
                    <ErrorRoundedIcon />
                </div>
            );
        } else {
            return (
                <div
                    className={styles.documentIcon}
                    style={{ color: 'var(--secondary)' }}
                >
                    <DescriptionRoundedIcon fontSize="inherit" />
                </div>
            );
        }
    };

    const downloadable =
        !(downloading || loadingOverride) &&
        ((file as PayloadFile).S3Key !== undefined ||
            (file as FileObject).url !== undefined);

    return (
        <div className={styles.contContainer}>
            <div
                className={large ? styles.largeContainer : styles.container}
                ref={containerRef}
            >
                <div className={styles.iconContainer}>{calcIcon()}</div>
                <div
                    className={
                        downloadable ? styles.downloadableLbl : styles.label
                    }
                    style={{
                        textDecoration: open ? 'underline' : undefined,
                    }}
                    onMouseEnter={(): void => {
                        if (!downloadable) return;
                        clearTimer(timer);
                        setTimer(
                            setTimeout(() => {
                                setOpen(true);
                            }, 500)
                        );
                    }}
                    onClick={(e): void => {
                        if (!downloadable) return;
                        clearTimer(timer);
                        if (
                            !open ||
                            !menuRef.current?.contains(e.target as any)
                        )
                            downloadFile();
                    }}
                    onMouseLeave={(): void => {
                        clearTimer(timer);
                        if (open) setOpen(false);
                    }}
                >
                    <Popper
                        open={open}
                        anchorEl={containerRef.current}
                        placement={'bottom-start'}
                        ref={menuRef}
                        modifiers={{
                            preventOverflow: {
                                enabled: true,
                                priority: ['top', 'bottom', 'left', 'right'],
                                boundariesElement: 'viewport',
                            },
                        }}
                        style={{ zIndex }}
                    >
                        <div className={styles.iconsContainer}>
                            <FileFab
                                variant="extended"
                                onClick={(): void => {
                                    setOpen(false);
                                    downloadFile(true);
                                }}
                            >
                                <GetAppRoundedIcon />
                                Descargar
                            </FileFab>
                            <OpenFab
                                variant="extended"
                                onClick={(): void => {
                                    setOpen(false);
                                    downloadFile(false);
                                }}
                            >
                                <VisibilityRoundedIcon />
                                Abrir
                            </OpenFab>
                        </div>
                    </Popper>
                    {file.fileName}
                </div>
                {handleEdit !== undefined && (
                    <div className={styles.editBtn} onClick={handleEdit}>
                        <EditRoundedIcon fontSize="inherit" />
                    </div>
                )}
                {handleRemove !== undefined && (
                    <div className={styles.deleteBtn} onClick={handleRemove}>
                        <ClearRoundedIcon fontSize="inherit" />
                    </div>
                )}
            </div>
            {error && <div className={styles.errorMsg}>{error}</div>}
        </div>
    );
}

export default FileComponent;

function isFileObject(
    file: PayloadFile | FileObject | PayloadEditorFile
): file is FileObject {
    return (file as PayloadFile).S3Key === undefined;
}

function isPayloadEditorFile(
    file: PayloadFile | FileObject | PayloadEditorFile
): file is PayloadEditorFile {
    return (file as PayloadEditorFile).state !== undefined;
}

const FileFab = withStyles(() => ({
    root: {
        color: 'var(--primary1)',
        backgroundColor: 'var(--secondary)',
        padding: '5px 15px 5px 12px',
        height: 'fit-content',
        fontSize: 13,
        '&.Erk-MuiFab-root:hover': {
            backgroundColor: 'var(--secondary)',
        },
    },
}))(Fab);

const OpenFab = withStyles(() => ({
    root: {
        color: 'black',
        backgroundColor: 'var(--light-grey)',
        fontSize: 13,
        height: 'fit-content',
        padding: '5px 15px 5px 12px',
        '&.Erk-MuiFab-root:hover': {
            backgroundColor: 'var(--light-grey)',
        },
    },
}))(Fab);

const clearTimer = (timer: any): void => {
    try {
        clearTimeout(timer);
        // eslint-disable-next-line no-empty
    } catch (e) {}
};
