import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Menu, MenuItem, Divider, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import styles from './Projects.module.css';
import RoundedButton from '../../RoundedButton/RoundedButton';
import Dialog from '../../Dialog/Dialog';
import CreateProject from './CreateProject/CreateProject';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import { ProjectSideBarConfig } from '../../../constants/SidebarConfigs';
import { checkAdmin } from '../../../utils/PermissionsFunctions';
interface ProjectProps {
    mobile: boolean;
    handleClose?: Function;
}
function Projects({ mobile, handleClose }: ProjectProps): JSX.Element {
    const siteInfo = useSelector((state: RootState) => state.site);
    const [createProjectDialog, setCreateProjectDialog] = useState(false);
    const refBtn = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);

    const handleProjectCreated = (): void => {
        handleClose?.();
        setOpen(false);
    };

    const calcPath = (idProject: string): string => {
        const path = location.pathname.split('/');
        let lastPath = path[path.length - 1];
        const projectlocations = ProjectSideBarConfig.routes.map(
            (route) => route.location
        );
        if (!projectlocations.includes('/' + lastPath)) {
            lastPath = 'tickets';
        }
        return `/${idProject}/${lastPath}`;
    };

    const calcProjects = (): JSX.Element[] => {
        return Object.values(siteInfo.projects)
            .filter(
                (project) =>
                    siteInfo.user?.permissions?.projects?.[project._id]
                        ?.VIEW === true
            )
            .sort(
                (a, b) => a.creation_date.getTime() - b.creation_date.getTime()
            )
            .map((project, i) => (
                <MenuItem key={i} style={{ padding: 0 }}>
                    <Link
                        to={calcPath(project._id)}
                        className={styles.link + ' app-link'}
                        style={{
                            fontWeight:
                                siteInfo.idProject === project._id
                                    ? 'bold'
                                    : 'inherit',
                        }}
                        onClick={(): void => {
                            handleClose?.();
                            setOpen(false);
                        }}
                    >
                        {project.name}
                    </Link>
                </MenuItem>
            ));
    };
    const renderMenu = (): JSX.Element => {
        if (mobile) {
            if (open) {
                return (
                    <React.Fragment>
                        {calcProjects()}
                        <Divider style={{ width: '100%' }} />
                    </React.Fragment>
                );
            } else {
                return <div></div>;
            }
        } else {
            if (open) {
                return (
                    <Menu
                        style={{ marginTop: -5 }}
                        anchorEl={refBtn.current}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        keepMounted
                        open={open}
                        onClose={(): void => {
                            handleClose?.();
                            setOpen(false);
                        }}
                    >
                        {calcProjects()}
                        {checkAdmin(siteInfo.user) && (
                            <Divider style={{ width: '100%' }} />
                        )}
                        {checkAdmin(siteInfo.user) && (
                            <div style={{ margin: '10px 17px 2px 17px' }}>
                                <RoundedButton
                                    text="Crear Ambiente"
                                    backgroundColor={'var(--secondary)'}
                                    color={'white'}
                                    onClick={(): void =>
                                        setCreateProjectDialog(true)
                                    }
                                />
                            </div>
                        )}
                    </Menu>
                );
            } else {
                return <div></div>;
            }
        }
    };

    return (
        <React.Fragment>
            <Dialog
                open={createProjectDialog}
                onClose={(): void => setCreateProjectDialog(false)}
            >
                <CreateProject
                    handleProjectCreated={handleProjectCreated}
                    close={(): void => setCreateProjectDialog(false)}
                />
            </Dialog>
            <div
                className={styles.projectsDropdown}
                data-testid="project_projectsDropdown"
                style={
                    mobile
                        ? {
                              marginLeft: '0px',
                              width: '100%',
                          }
                        : { marginLeft: '75px' }
                }
            >
                <Button
                    style={{
                        textTransform: 'none',
                        fontFamily: 'Nunito',
                        fontSize: 18,
                        color: open ? 'var(--contrast)' : 'var(--accent)',
                    }}
                    onClick={(): void => {
                        setOpen(!open);
                    }}
                >
                    <div ref={refBtn}>Ambientes</div>
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>
            </div>
            {renderMenu()}
        </React.Fragment>
    );
}

export default Projects;
