import React, { useEffect } from 'react';
import styles from './CreateEntityValue.module.css';
import entityValuesService from '../../../controllers/EntityValuesController/EntityValuesService';
import EntityRenderer from '../../../shared/EntityRenderer/EntityRenderer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import { EntityValue } from '../../../@Types/EntityValue';

interface CreateEntityValueProps {
    /** The id of the current entity */
    idEntity: string;
    /** The EntityValue to be clone */
    baseEntityValue?: EntityValue;
    /** Function called when the agent has been created */
    handleEntityValueCreated: Function;
    /** Function called to close the Dialog */
    onClose?: Function;
    /** Function called to update the Dialog's loader */
    setLoading?: Function;
}
function CreateEntityValue({
    idEntity,
    handleEntityValueCreated,
    onClose,
    setLoading,
    baseEntityValue,
}: CreateEntityValueProps): JSX.Element {
    const siteInfo = useSelector((state: RootState) => state.site);

    const entity = siteInfo.entities[idEntity];

    useEffect(() => {
        if (entity === undefined) {
            onClose?.();
        }
    }, []);

    if (entity) {
        return (
            <div className={styles.container + ' standard-dialog'}>
                <h2 className="center-anything">Crear {entity.name}</h2>
                <div
                    className={styles.entityContainer}
                    style={{
                        width:
                            (entity.size.blockSize + entity.size.spacingSize) *
                            entity.size.blockNum,
                    }}
                >
                    <EntityRenderer
                        entity={entity}
                        creating
                        entityValue={
                            baseEntityValue
                                ? JSON.parse(
                                      JSON.stringify({
                                          ...baseEntityValue,
                                          _id: undefined,
                                          creation_date: undefined,
                                      })
                                  )
                                : undefined
                        }
                        customSend={async (entityValue): Promise<void> => {
                            try {
                                await new Promise((resolve) =>
                                    setTimeout(resolve, 1000)
                                );
                                await entityValuesService.createEntityValue(
                                    entity._id,
                                    {
                                        ...entityValue,
                                        idEntity: entity._id,
                                    }
                                );
                                handleEntityValueCreated();
                            } catch (error) {
                                //TODO handle errors
                                console.error(error);
                                setLoading?.(false);
                            }
                        }}
                        // editable
                    />
                </div>
            </div>
        );
    } else {
        return <div></div>;
    }
}

export default CreateEntityValue;
