import { AnyAction } from '@reduxjs/toolkit';
import { takeLatest, call, put, fork } from 'redux-saga/effects';
import * as actions from './GenericEditorActions';

/**
 * Function called when a form should be loaded
 * @param action of type reset
 */
function* getData(action: AnyAction): any {
    try {
        if (actions.reset.match(action)) {
            const result = yield call(
                action.payload.loadData,
                ...(action.payload.loadParams ?? [])
            );
            yield put(actions.getSuccess(action.meta.idEditor, result));
        }
    } catch (error) {
        //TODO handle errors
        console.error(error);
    }
}

function* watchGetDataRequest(): any {
    yield takeLatest([actions.Types.RESET], getData);
}

export default [fork(watchGetDataRequest)];
