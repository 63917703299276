import { useSelector } from 'react-redux';
import React, { useCallback, useMemo } from 'react';
import { RootState } from '../../../../../../utils/_store';
import EntityIcon from '../../../../../../shared/SmartIcons/EntityIcon';
import { EntityValuePathTypes } from '../../../../../../constants/FormStepTypes';
import { EntityRelationship } from '../../../../../../@Types/EntityTypes/Entity';
import { StepLocation } from '../../../../../../controllers/ConversationEditorController/ConversationEditorReducer';
import { EntityValueStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import { createEntityStep } from '../../../StepFunctions';
import { useCurrentProject } from '../../../../../../hooks';
import EurekaElementMenuElement, {
    EurekaElementMenuElementStyle,
} from '../../../../../../shared/@Menus/ElementMenu/EurekaElementMenuElement';
import EurekaElementMenuGroup from '../../../../../../shared/@Menus/ElementMenu/EurekaElementMenuGroup';
interface EntityRelationshipMenuProps {
    menuProps: {
        zIndex: number;
        style: EurekaElementMenuElementStyle;
    };
    relationship: EntityRelationship;
    path?: { idEntity: string; hidden: boolean }[];
    shown: string[];
    location: StepLocation;
    handleAdd: (step: EntityValueStep) => void;
}
function EntityRelationshipMenu({
    shown,
    menuProps,
    path = [],
    location,
    handleAdd,
    relationship,
}: EntityRelationshipMenuProps): JSX.Element {
    const project = useCurrentProject();
    const entity = useSelector(
        (state: RootState) => state.site.entities[relationship.idEntity]
    );

    const relationships = useMemo(
        () =>
            [
                ...entity.relationships,
                ...(relationship.relationships ?? []),
            ].filter((relationship) => !shown.includes(relationship.idEntity)),
        [entity, relationship, shown]
    );

    const onClick = useCallback(() => {
        handleAdd(
            createEntityStep(
                project,
                entity,
                path.map(({ idEntity, hidden }) =>
                    hidden
                        ? {
                              idEntity,
                              type: EntityValuePathTypes.VALUE,
                              idEntityValue: null,
                          }
                        : {
                              idEntity,
                              type: EntityValuePathTypes.STEP,
                              any: true,
                          }
                )
            )
        );
    }, []);

    if (relationships.length > 0) {
        return (
            <EurekaElementMenuGroup
                {...menuProps}
                label={entity.name}
                icon={(props): JSX.Element => (
                    <EntityIcon {...props} idEntity={entity._id} />
                )}
                onClick={onClick}
                placement={'bottom-end'}
                renderElements={(props): JSX.Element[] =>
                    relationships.map((subRel) => (
                        <EntityRelationshipMenu
                            menuProps={props}
                            shown={[
                                ...shown,
                                ...relationships.map((rel) => rel.idEntity),
                            ]}
                            path={[
                                ...path,
                                {
                                    idEntity: entity._id,
                                    hidden: !relationship.multiple,
                                },
                            ]}
                            relationship={subRel}
                            handleAdd={handleAdd}
                            location={location}
                            key={subRel.idEntity}
                        />
                    ))
                }
            />
        );
    } else {
        return (
            <EurekaElementMenuElement
                style={menuProps.style}
                Label={entity.name}
                onClick={onClick}
                Icon={(props): JSX.Element => (
                    <EntityIcon {...props} idEntity={entity._id} />
                )}
            />
        );
    }
}

export default EntityRelationshipMenu;
