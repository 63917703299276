import styles from './ClassifiersMenu.module.css';
import React, { useState, useEffect, useRef } from 'react';
import Search from '../../../../../../shared/Search/Search';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {
    Ticket,
    TicketClassifier,
} from '../../../../../../@Types/TicketTypes/Ticket';
import Classifiers from './Classifiers/Classifiers';
import Classifying from './Classifying/Classifying';
import ActionsService from '../../../../../../controllers/TicketsController/Actions/ActionsService';
import { Classifier } from '../../../../../../@Types/@Types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../utils/_store';
import Dialog from '../../../../../../shared/Dialog/Dialog';
import { appendAction } from '../../../../../../controllers/TicketsController/Actions/ActionsActions';
import VanillaToasts from '../../../../../../shared/Toast/Toast';

/** Helper interface to optimize the render when a user is searching something */
export interface SearchClassifier extends Classifier {
    /** If the classifier has a child that has the term being searched */
    hasSearchInChild?: boolean;
    /** If the classifier has the term being serched */
    hasSearch?: boolean;
}

/**
 * Object that stores the values currently being classified
 */
export interface ClassifierObj {
    /** root of the classifier currently bieng classified */
    idRoot: string;
    /** Currently classified value */
    idValue: string | null;
    /** parent of the currently displayed classifiers */
    current: string;
}
interface ClassifiersMenuProps {
    /** Element the menu is anchored to (classifiers button in the sidebar) */
    anchorRef: any;
    /** Function called when the menu closes */
    handleClose: Function;
    /** The currently displayed ticket */
    selectedElement: Ticket;
    /** If a classifiered was clicked on instead of the classifiers button */
    menuItem: ClassifierObj | null;
    /** If the layout is mobile */
    layoutIsMobile?: boolean;
}
function ClassifiersMenu({
    anchorRef,
    handleClose,
    selectedElement,
    layoutIsMobile,
    menuItem,
}: ClassifiersMenuProps): JSX.Element {
    const dispatch = useDispatch();
    const siteInfo = useSelector((state: RootState) => state.site);

    const [currentClassifiers, setCurrentClassifiers] = useState(
        selectedElement.classifiers
    );

    const [info, setInfo] = useState<{
        roots: string[];
        classifiers: Record<string, SearchClassifier>;
    }>({ roots: siteInfo.projectRoots, classifiers: siteInfo.classifiers });

    /** Dictionary of changes made while the menu is open. */
    const [changes, setChanges] = useState<Record<string, TicketClassifier>>(
        {}
    );
    const [search, setSearch] = useState<string | undefined>(undefined);

    /** Classifier currently being classfied */
    const [classifyingObj, setClassifyingObj] = useState<
        ClassifierObj | undefined
    >(menuItem ? menuItem : undefined);

    const emptyRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        /** Filter the classifiers with the search value, add the <SEARCH tag when
         *  value is found and store if has the searched value or children that do */
        if (search !== undefined) {
            const roots: string[] = [];
            const classifiers: Record<string, SearchClassifier> = {};
            const regex = new RegExp(search, 'gi');
            const clasifs = Object.values({ ...siteInfo }.classifiers);
            for (let i = 0; i < clasifs.length; i++) {
                const element: SearchClassifier = { ...clasifs[i] };
                if (
                    element.idProject === selectedElement.idProject &&
                    element.active
                ) {
                    let found = false;
                    if (regex.test(element.name)) {
                        element.name = element.name.replace(
                            regex,
                            (match) => `<SEARCH:${match}>`
                        );
                        found = true;
                    }
                    if (regex.test(element.description)) {
                        element.description = element.description.replace(
                            regex,
                            (match) => `<SEARCH:${match}>`
                        );
                        found = true;
                    }
                    if (found) {
                        element.hasSearch = true;
                        let idRoot = element._id;
                        if (element.path) {
                            for (const p of element.path) {
                                if (classifiers[p]) {
                                    classifiers[p] = {
                                        ...classifiers[p],
                                        hasSearchInChild: true,
                                    };
                                } else {
                                    classifiers[p] = {
                                        ...siteInfo.classifiers[p],
                                        hasSearchInChild: true,
                                    };
                                }
                            }
                            idRoot = element.path[0];
                        }
                        if (!roots.includes(idRoot)) {
                            roots.push(idRoot);
                        }
                        classifiers[element._id] = element;
                    }
                }
            }
            for (const classif of Object.values(classifiers)) {
                classif.children = classif.children.filter(
                    (child) => classifiers[child] !== undefined
                );
            }
            setInfo({
                roots,
                classifiers,
            });
        } else {
            /** Filter valid classifers */
            const classifiers: Record<string, SearchClassifier> = {};
            const clasifs = Object.values({ ...siteInfo }.classifiers);
            for (const element of clasifs) {
                if (
                    element.idProject === selectedElement.idProject &&
                    element.active
                ) {
                    classifiers[element._id] = element;
                }
            }
            setInfo({
                roots: siteInfo.projectRoots,
                classifiers,
            });
        }
    }, [search]);

    /**
     * Function that stores a new change in the dictionary of changes
     */
    const change = (classifier: TicketClassifier): void => {
        const tempChanges = { ...changes };
        tempChanges[classifier.idRoot] = classifier;
        setChanges(tempChanges);
        const classifiers = [...currentClassifiers];
        let found = false;
        for (let i = 0; i < classifiers.length; i++) {
            if (classifiers[i].idRoot === classifier.idRoot) {
                classifiers[i] = classifier;
                found = true;
                if (classifier.idValue === null) {
                    classifiers.splice(i, 1);
                }
            }
        }
        if (!found && classifier.idValue !== null) {
            classifiers.push(classifier);
        }

        setCurrentClassifiers(classifiers);
    };

    /**
     * Function that calls the services for each change made by the user an refreshes the info
     */
    const saveChanges = async (): Promise<void> => {
        const chngs = Object.values(changes);
        for (const change of chngs) {
            try {
                const action = await ActionsService.classify(
                    selectedElement.idProject,
                    selectedElement._id,
                    change
                );
                dispatch(appendAction(action));
            } catch (error) {
                VanillaToasts.create({
                    title: 'Error al clasificar',
                    text: error,
                    type: 'error',
                    timeout: 3000,
                });
                console.error(error);
            }
        }
        handleClose();
    };

    const renderOptions = (): JSX.Element | void => {
        if (classifyingObj === undefined) {
            return (
                <Classifiers
                    searching={search !== undefined}
                    setClassifyingObj={setClassifyingObj}
                    change={change}
                    currentClassifiers={currentClassifiers}
                    info={info}
                    search={search}
                />
            );
        }
        return (
            <Classifying
                searching={search !== undefined}
                classifyingObj={classifyingObj}
                setClassifyingObj={setClassifyingObj}
                layoutIsMobile={layoutIsMobile}
                onSelect={change}
                info={info}
                search={search}
            />
        );
    };

    const renderContent = (): JSX.Element => {
        return (
            <div
                className={
                    layoutIsMobile
                        ? styles.mobileContainer
                        : styles.emptyContainer
                }
                onClick={(e): void => {
                    if (emptyRef.current === e.target) {
                        saveChanges();
                    }
                }}
                ref={emptyRef}
            >
                <div className={styles.container}>
                    <div className={styles.title}>Clasificar el Ticket</div>
                    <div
                        className={styles.searchContainer}
                        data-testid="ClassifiersMenu_searchContainer"
                    >
                        <Search
                            wait={500}
                            placeholder={'Filtrar clasificadores'}
                            search={search}
                            handleSearch={(value: any): void => {
                                if (value.trim() === '') {
                                    setSearch(undefined);
                                } else {
                                    setSearch(value);
                                }
                            }}
                        />
                    </div>
                    {renderOptions()}
                </div>
            </div>
        );
    };

    if (layoutIsMobile) {
        return (
            <Dialog
                onClose={(): void => {
                    saveChanges();
                }}
                maxWidth="100vw"
                border={10}
                transparent
            >
                {renderContent()}
            </Dialog>
        );
    } else {
        return (
            <ClickAwayListener
                mouseEvent="onMouseDown"
                onClickAway={(): void => {
                    saveChanges();
                }}
            >
                <Popper
                    open={true}
                    anchorEl={anchorRef.current}
                    placement={'bottom-end'}
                    modifiers={{
                        preventOverflow: {
                            enabled: true,
                            priority: ['top', 'bottom', 'left', 'right'],
                            boundariesElement: 'viewport',
                            padding: 20,
                        },
                    }}
                    style={{ zIndex: 2 }}
                >
                    {renderContent()}
                </Popper>
            </ClickAwayListener>
        );
    }
}
export default ClassifiersMenu;
