import { Area } from '../../../../../../../../@Types/@Types';
import styles from './Area.module.css';
import ApartmentRoundedIcon from '@material-ui/icons/ApartmentRounded';
interface AreaComponentProps {
    /** Area to display */
    area: Area;
    /** Function to call when Area is clicked on */
    handleClick: Function;
    /** If the area is the last one on the list */
    last: boolean;
    /** If the area is the selected one */
    selected: boolean;
}
function AreaComponent({
    area,
    handleClick,
    last,
    selected,
}: AreaComponentProps): JSX.Element {
    if (area) {
        return (
            <div
                className={styles.container}
                style={{
                    borderBottom: last ? 'none' : '1px solid var(--light-grey)',
                    backgroundColor: selected ? 'var(--greyHover)' : '',
                }}
                onClick={(): void => {
                    handleClick();
                }}
            >
                <div className={styles.iconContainer}>
                    <ApartmentRoundedIcon fontSize="inherit" />
                </div>
                <div className={styles.lblContainer}>
                    <label
                        className={styles.nameLbl}
                        style={{ fontWeight: selected ? 'bold' : 500 }}
                    >
                        {area.name}
                    </label>
                    <label className={styles.descriptionContainer}>
                        {area.description}
                    </label>
                </div>
            </div>
        );
    }
    return <div></div>;
}

export default AreaComponent;
