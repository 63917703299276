import { useEffect, useState } from 'react';
import { Process } from '../../../../../../@Types/ProcessTypes/Process';
import styles from './ProcessChange.module.css';
import TrendingFlatRoundedIcon from '@material-ui/icons/TrendingFlatRounded';
import Avatar from '../../../../../../shared/Avatar/Avatar';
import { loadProcessById } from '../../../../../../controllers/ProcessController/ProcessService';
import StateIcon from '../../../../../../Icons/StateIcon';
import Loader from '../../../../../../shared/Loader/Loader';
import NotInterestedRoundedIcon from '@material-ui/icons/NotInterestedRounded';
import FormRenderer from '../../../../../../shared/FormRenderer/FormRenderer';
import SmartAgentGroup from '../../../../../../shared/SmartAgentGroup/SmartAgentGroup';

interface ProcessChangeComponentProps {
    idProject: string;
    idOriginal: string | null;
    idValue: string | null;
    originalRoles: Record<string, string[]> | null;
    roles: Record<string, string[]> | null;
    values: Record<string, any> | null;
}

function ProcessChangeComponent({
    roles,
    values,
    idValue,
    idProject,
    idOriginal,
    originalRoles,
}: ProcessChangeComponentProps): JSX.Element {
    const [processes, setProcesses] = useState<
        | {
              original?: Process;
              value?: Process;
          }
        | undefined
    >(undefined);

    const loadProcesses = async (): Promise<void> => {
        let value, original;
        try {
            if (idValue) value = await loadProcessById(idProject, idValue);
        } catch (e) {
            console.error(e);
        }

        try {
            if (idOriginal)
                original = await loadProcessById(idProject, idOriginal);
        } catch (e) {
            console.error(e);
        }

        setProcesses({ original, value });
    };

    useEffect(() => {
        loadProcesses();
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.changeContainer}>
                {idOriginal !== idValue && idOriginal !== null && (
                    <>
                        <div className={styles.changeContainer}>
                            <ProcessComponent
                                process={processes?.original}
                                removed={idValue === null}
                            />
                        </div>
                    </>
                )}
                {idOriginal !== idValue && idOriginal !== null && idValue && (
                    <div className={styles.arrowContainer}>
                        <TrendingFlatRoundedIcon fontSize="inherit" />
                    </div>
                )}
                {idValue && (
                    <div className={styles.changeContainer}>
                        <ProcessComponent process={processes?.value} />
                    </div>
                )}
            </div>
            {roles !== null &&
                Object.values(roles).find((role) => role.length > 0) && (
                    <div
                        className={styles.rolesContainer}
                        style={{
                            visibility: processes?.value ? 'visible' : 'hidden',
                        }}
                    >
                        {Object.entries(roles)
                            .filter(
                                ([idRole, agents]) =>
                                    !(
                                        agents.length === 0 &&
                                        areEqual(
                                            originalRoles?.[idRole] ?? [],
                                            agents
                                        )
                                    )
                            )
                            .map(([idRole, agents]) => (
                                <div
                                    className={styles.roleContainer}
                                    key={idRole}
                                >
                                    <div className={styles.roleLbl}>
                                        {processes?.value?.roles.find(
                                            (role) => role.id === idRole
                                        )?.name ?? 'Rol'}
                                        :
                                    </div>
                                    {idOriginal === idValue &&
                                        originalRoles &&
                                        !areEqual(
                                            originalRoles[idRole],
                                            agents
                                        ) && (
                                            <div
                                                className={styles.originalRole}
                                            >
                                                <SmartAgentGroup
                                                    max={4}
                                                    agents={
                                                        originalRoles[idRole]
                                                    }
                                                ></SmartAgentGroup>
                                                {originalRoles[idRole]
                                                    ?.length === 0 && (
                                                    <div
                                                        className={
                                                            styles.assignedLbl
                                                        }
                                                        title="Todos"
                                                    >
                                                        *
                                                    </div>
                                                )}
                                                <div
                                                    className={
                                                        styles.roleArrowContainer
                                                    }
                                                >
                                                    <TrendingFlatRoundedIcon fontSize="inherit" />
                                                </div>
                                            </div>
                                        )}
                                    {agents === null ? (
                                        <div className={styles.loaderContainer}>
                                            <Loader size={20} />
                                        </div>
                                    ) : (
                                        <SmartAgentGroup
                                            max={4}
                                            agents={agents}
                                        ></SmartAgentGroup>
                                    )}
                                    {agents?.length === 0 && (
                                        <div
                                            className={styles.assignedLbl}
                                            title="Todos"
                                        >
                                            *
                                        </div>
                                    )}
                                </div>
                            ))}
                    </div>
                )}

            {values &&
                Object.values(values).length > 0 &&
                processes?.value?.values.parameters &&
                processes?.value?.values.parameters.length > 0 && (
                    <div className={styles.formContainer}>
                        <FormRenderer
                            rootSteps={processes?.value?.values.parameters}
                            postview={true}
                            form={{
                                size: {
                                    blockNum: 1,
                                    blockSize: 320,
                                    spacingSize: 0,
                                },
                                steps: processes?.value?.values.steps,
                            }}
                            values={values}
                        />
                    </div>
                )}
        </div>
    );
}

export default ProcessChangeComponent;

function ProcessComponent({
    process,
    removed,
}: {
    process: Process | undefined;
    removed?: boolean;
}): JSX.Element {
    return (
        <div className={styles.processContainer}>
            <Avatar lbl={process?.name ?? ''} size={30} fontSize={30}>
                <StateIcon fill="white" style={{ width: 20, height: 20 }} />
            </Avatar>
            <label className={styles.nameLbl}>{process?.name ?? ''}</label>
            {removed && (
                <div className={styles.removedContainer}>
                    <NotInterestedRoundedIcon fontSize="inherit" />
                </div>
            )}
        </div>
    );
}

function areEqual(a: string[], b: string[]): boolean {
    return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index])
    );
}
