import { DateTimePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import isSameDay from 'date-fns/isSameDay';
import React, { cloneElement, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import { differenceInDays } from 'date-fns';
import { getMsSinceMidnight } from '../../../../../utils/DateFunctions';
interface StyleProps {
    /** Cant edit */
    cantEdit?: boolean;
}

const useDatePickerStyles = (props: StyleProps): Function =>
    makeStyles(() => ({
        root: {
            borderRadius: 10,
            backgroundColor: 'var(--primary1)',
            '& input, textarea': {
                cursor: props.cantEdit ? 'default' : 'pointer',
                padding: '7px 0px 10px 0px',
                fontWeight: '300',
                color: 'var(--accent)',
                'text-align': 'center',
                fontSize: '1rem',
                '&::placeholder': {
                    fontSize: '1rem',
                },
                backgroundColor: 'var(--primary1)',
                borderRadius: 10,
            },
            '&.Erk-MuiInput-underline': {
                '& span': {
                    fontSize: '1rem',
                    paddingRight: 0,
                    paddingLeft: 0,
                },
                '&:before': {
                    borderBottom: '1px solid var(--light-grey)',
                },
                '&:hover:before': {
                    borderBottom: '1px solid var(--light-grey)',
                },
                '&:after': {
                    borderBottom: '1px solid var(--light-grey)',
                },
            },
            '& .Erk-MuiInputAdornment-root': {
                '& button': {
                    marginRight: -20,
                    fontSize: 18,
                    padding: 16,
                },
            },
            '& .Mui-error': {
                color: 'var(--error)',
            },
            '&.Mui-disabled': {
                cursor: 'default',
                color: 'rgba(0, 0, 0, 0.38)',
            },
        },
    }));

const useDatePickerLabelStyles = makeStyles(() => ({
    root: {
        marginTop: '-4px',
        fontSize: '1rem',
        color: 'var(--accent)',
        '&.Mui-focused': {
            color: 'var(--secondary)',
        },
        '&.Erk-MuiInputLabel-shrink': {
            marginTop: '0px',
            fontSize: '1rem',
        },
        '& .Mui-error': {
            color: 'var(--error)',
        },
        '&.Mui-error': {
            color: 'var(--error)',
        },
    },
}));
const useDatePickerHelperTextStyles = makeStyles(() => ({
    root: {
        color: 'var(--accent)',
        '&.Mui-error': {
            color: 'var(--error)',
        },
    },
}));
const useDatePickerDialogStyles = makeStyles(() => ({
    datePicker: {
        '& .Erk-MuiPickersModal-dialogRoot': {
            color: 'var(--accent)',
            '& .Erk-MuiDialogActions-root': {
                '& .Erk-MuiButton-root:hover': {
                    backgroundColor: 'var(--transparentsecondary)',
                },
                '& .Erk-MuiButton-label': {
                    color: 'var(--secondary)',
                },
                '& .Erk-MuiTouchRipple-root': {
                    color: 'var(--secondary)',
                },
            },
        },
        //Days labels
        '& .Erk-MuiPickersDay-day': {
            color: 'var(--accent)',
        },
        // Selected day label
        '& .Erk-MuiPickersDay-daySelected': {
            color: 'var(--primary1)',
            backgroundColor: 'var(--secondary)',
        },
        //Title
        '& .Erk-MuiPickersToolbar-toolbar': {
            color: 'var(--primary1)',
            backgroundColor: 'var(--secondary)',
        },
        '& .Erk-MuiPickerDTTabs-tabs': {
            color: 'var(--primary1)',
            backgroundColor: 'var(--secondary)',
        },
        '& .Erk-MuiPickersYear-yearSelected, .Erk-MuiPickersYear-root:focus': {
            color: 'var(--secondary)',
        },
        '& .Erk-MuiTabs-indicator': {
            backgroundColor: 'var(--contrast)',
        },
        '& .Erk-MuiPickersClockNumber-clockNumber': {
            color: 'var(--accent)',
        },
        '& .Erk-MuiPickersClockNumber-clockNumberSelected': {
            color: 'var(--primary1)',
        },
        '& .Erk-MuiPickersClock-pin, .Erk-MuiPickersClockPointer-pointer': {
            backgroundColor: 'var(--secondary)',
        },
        '& .Erk-MuiPickersClockPointer-noPoint': {
            backgroundColor: 'var(--secondary)',
        },
        '& .Erk-MuiPickersClockPointer-thumb': {
            borderColor: 'var(--secondary)',
        },
        '& .Erk-MuiPickersDay-dayDisabled': {
            color: 'rgba(0, 0, 0, 0.38)',
            pointerEvents: 'all',
        },
        '& .Erk-MuiPickersDay-current': {
            color: 'var(--primary1)',
            backgroundColor: 'var(--secondary2)',
        },
        '& .Erk-MuiPickersDay-hidden': {
            opacity: 0.4,
            pointerEvents: 'none',
        },
        '& .Erk-MuiPickersDay-hidden.Erk-MuiPickersDay-current': {
            opacity: 0.8,
            pointerEvents: 'none',
        },
    },
}));

interface CustomDatePickerProps extends StyleProps {
    /** Date the ticket was created to display in calendar */
    creation_date: Date;
    /** the ref of the input */
    inputRef: any;
}

function CustomDatePicker({
    cantEdit = false,
    creation_date,
    inputRef,
    onChange,
    value,
    ...others
}: KeyboardDatePickerProps & CustomDatePickerProps): JSX.Element {
    const classes = useDatePickerStyles({
        cantEdit,
    })();
    const labelClasses = useDatePickerLabelStyles();
    const { datePicker } = useDatePickerDialogStyles();

    const helperTextStyles = useDatePickerHelperTextStyles();
    const creationMs = getMsSinceMidnight(creation_date);
    const resolutionMs = getMsSinceMidnight(value);
    const okRef = useRef<HTMLDivElement>(null);
    return (
        <DateTimePicker
            {...others}
            value={value}
            onChange={onChange}
            disabled={cantEdit}
            required
            fullWidth
            inputRef={inputRef}
            format="PPP"
            InputLabelProps={{ classes: labelClasses }}
            DialogProps={
                {
                    onClose: () => {
                        okRef.current?.click(); //Triggers ok on dialog close
                    },
                    disableEnforceFocus: true,
                    className: datePicker,
                    cancelLabel: 'Cancelar',
                    okLabel: <div ref={okRef}>Ok</div>,
                } as any //Fixes the typescript declaration of the library: https://material-ui-pickers.dev/api/KeyboardDatePicker
            }
            shouldDisableDate={(day): boolean =>
                day !== null &&
                creation_date.getTime() - creationMs > day.getTime()
            }
            InputProps={{
                required: true,
                classes,
                disabled: cantEdit,
            }}
            error={false}
            renderDay={(
                day,
                selectedDate,
                dayInCurrentMonth,
                dayComponent
            ): JSX.Element => {
                if (!day) {
                    return dayComponent;
                }
                day.setTime(day.getTime() + resolutionMs);
                if (isSameDay(day, creation_date)) {
                    return (
                        <div title="Fecha de Emissión">
                            {cloneElement(dayComponent, {
                                current: true,
                            })}
                        </div>
                    );
                } else {
                    const dayDif = day
                        ? differenceInDays(day, creation_date)
                        : 0;
                    if (selectedDate && isSameDay(day, selectedDate)) {
                        return (
                            <div
                                title={
                                    'Fecha de Vencimiento (' +
                                    dayDif +
                                    ' días desde su creación)'
                                }
                            >
                                {cloneElement(dayComponent, {
                                    current: false,
                                })}
                            </div>
                        );
                    } else {
                        return (
                            <div
                                title={
                                    dayDif > 0
                                        ? dayDif +
                                          ` día${
                                              dayDif > 1 ? 's' : ''
                                          } desde su creación`
                                        : ''
                                }
                            >
                                {cloneElement(dayComponent, {
                                    current: false,
                                    disabled: day < new Date(),
                                })}
                            </div>
                        );
                    }
                }
            }}
            FormHelperTextProps={{
                classes: helperTextStyles,
            }}
            minDateMessage=" "
        />
    );
}

/**
 * Generic datepicker with apps designs. Is class due to the use in the react-hook-forms library
 */
class ResolutionDatePicker extends React.Component<
    CustomDatePickerProps & KeyboardDatePickerProps
> {
    render(): JSX.Element {
        return <CustomDatePicker {...this.props} />;
    }
}
export default ResolutionDatePicker;
