import React from 'react';
import styles from './AssignNode.module.css';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import { Handle, Position } from 'react-flow-renderer';
import { AssignData } from '../../../../@Types/FlowTypes/NodeTypes/AssignNode';
import types from '../../../../constants/Flows/AssignTypes';
interface AssignNodeProps {
    /** Node data needed to display the node */
    data: AssignData & {
        /** Function used to open the dialog  */
        openDialog: Function;
        error: boolean;
    };
}

function AssignNode({ data }: AssignNodeProps): JSX.Element {
    const calcTitle = (): string => {
        switch (data.type) {
            case types.AUTO:
                return 'Automática';
            case types.RANDOM:
                return 'Al Azar';
            case types.LOAD:
                return 'Menor Carga';
            case types.ALL:
                return 'Todos';
            default:
                return '';
        }
    };
    return (
        <>
            <Handle
                className={styles.leftHandle}
                type="target"
                position={Position.Left}
                style={{
                    top: '50%',
                    background: 'var(--primary1)',
                    border: '1px solid var(--accent)',
                }}
            />
            <div
                className={styles.container}
                style={
                    data.error
                        ? {
                              border: 'var(--error) 1px solid',
                              marginTop: '-1px',
                              marginLeft: '-1px',
                          }
                        : {}
                }
            >
                <PeopleAltRoundedIcon fontSize="inherit" />
                <div
                    className={styles.config}
                    onClick={(): void => {
                        data.openDialog();
                    }}
                >
                    <SettingsRoundedIcon fontSize="inherit" />
                </div>
                <div className={styles.label}>{calcTitle()}</div>
            </div>

            <Handle
                className={styles.rightHandle}
                type="source"
                position={Position.Right}
                id="ASSIGN"
                isConnectable={data.type !== types.AUTO}
                style={{
                    visibility: data.type === types.AUTO ? 'hidden' : 'visible',
                    top: '50%',
                    background: 'var(--primary1)',
                    border: '1px solid var(--accent)',
                }}
            />
        </>
    );
}

export default AssignNode;
