import { AgentPropertyTypes } from '../../../../constants/AgentPropertyTypes';
import {
    DraftEntityDataMappingTypes,
    DraftEntityDataTypes,
} from '../../../../constants/Draft/DraftEntityDataTypes';
import Context from '../Context';
import { GenericContextProps } from '../TextEditorContext';

interface AgentContextProps extends GenericContextProps {
    isMapping?: boolean;
}

function AgentContext({ isMapping, ...props }: AgentContextProps): JSX.Element {
    const type = isMapping
        ? DraftEntityDataMappingTypes.AGENT_MAPPING
        : DraftEntityDataTypes.AGENT;
    return (
        <>
            <Context
                {...props}
                data={{
                    type,
                    property: AgentPropertyTypes.FIRST_NAME,
                }}
                isMapping={isMapping}
            />
            <Context
                {...props}
                data={{
                    type,
                    property: AgentPropertyTypes.NAME,
                }}
                isMapping={isMapping}
            />
            <Context
                {...props}
                data={{
                    type,
                    property: AgentPropertyTypes.EMAIL,
                }}
                isMapping={isMapping}
            />
            <Context
                {...props}
                data={{
                    type,
                    property: AgentPropertyTypes.JOB_TITLE,
                }}
                isMapping={isMapping}
            />
        </>
    );
}

export default AgentContext;
