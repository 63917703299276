import styles from './GenericMenu.module.css';
import React, { useEffect, useRef, useState } from 'react';
import Search from '../../Search/Search';
import Popper, { PopperPlacementType } from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import {
    clear,
    reset,
    search,
    setLoading,
} from '../../../controllers/GenericMenuController/GenericMenuActions';
import RoundedButton from '../../RoundedButton/RoundedButton';
import Dialog from '../../Dialog/Dialog';
import GenericList from './GenericList';
import { defaultState } from '../../../controllers/GenericMenuController/GenericMenuReducer';
import { nanoid } from '@reduxjs/toolkit';

export interface GenericMenuProps<Type, InitialType = string> {
    /** The ref of the anchor*/
    anchorRef?: React.MutableRefObject<any>;
    /** Function to call to close the menu */
    handleClose?: Function | null;
    /** The initially selected elements */
    initialElements?: Type[] | InitialType[];
    /** The elements to filter out */
    elementsToOmit?: Type[];
    /** The title of the menu insted of seleccionar */
    title?: string;
    /** The label of the Elements */
    label: string;
    pluralLabel?: string;
    /** Function used to get the element's unique key */
    calcId?: (element: Type) => string;
    /** Function to call element label */
    calcLbl: (element: Type) => string;
    /** Function to calc element Icon  */
    calcIcon?: (element: Type) => JSX.Element;
    /** Function to calc if element was deleted */
    calcIsDeleted?: (element: Type) => boolean;
    /** Function to calc if should load by ids */
    isInitialType?: (
        elements: InitialType[] | Type[]
    ) => elements is InitialType[];
    /** If the menu allows selecting multiple elements */
    multiple?: boolean;
    canChangeOrder?: boolean;
    /** Function called when the selected elements change */
    handleUpdate?: (elements: Type[], noMoreElements: boolean) => void;
    /** confirmation button text */
    confirm?: string;
    /** Function called on confirm */
    handleConfirm?: (element: Type) => Promise<void>;
    zIndex?: number;
    placement?: PopperPlacementType;
    marginTop?: string;
    /** material ui style seed */
    seed?: string;
    /** If the app is mobile */
    mobile?: boolean;
    /** async function to call to load elements by their initial value */
    loadInitialType: (value: InitialType[]) => Promise<Type[]>;
    /** async function to call to load a list of elements */
    loadElements: (
        page: number,
        pageSize: number,
        search: string | undefined
    ) => Promise<Type[]>;
    /** Function called to view the selected element */
    handleView?: (element: Type) => void;
    /** Function called to config the selected element */
    handleConfig?: (element: Type) => void;
    /** If the menu can be clearable */
    clearable?: boolean;
    customButton?: (
        ref: React.MutableRefObject<any>,
        setShowBtn: () => void,
        noMoreElements: boolean
    ) => JSX.Element;
    /** The id of the menu to display */
    idMenu?: string;
    /** Controlled Menu */
    open?: boolean;
    customMenuButtons?: () => JSX.Element;
}
function GenericMenu<Type, InitialType = string>({
    clearable,
    anchorRef,
    title,
    calcLbl,
    calcIcon,
    calcIsDeleted,
    handleClose,
    multiple = true,
    initialElements = [],
    elementsToOmit = [],
    handleUpdate,
    handleConfirm,
    zIndex = 2,
    seed = 'Erk-',
    confirm = 'Confirmar',
    placement = 'bottom-start',
    label = '',
    pluralLabel = label + 's',
    marginTop = '0px',
    mobile = false,
    loadElements,
    customButton,
    loadInitialType,
    isInitialType = (
        elements: InitialType[] | Type[]
    ): elements is InitialType[] => typeof elements[0] === 'string',
    calcId = (element: any): string => element._id,
    handleView,
    handleConfig,
    canChangeOrder = false,
    customMenuButtons,
    ...props
}: GenericMenuProps<Type, InitialType>): JSX.Element {
    const dispatch = useDispatch();
    const emptyRef = useRef<HTMLDivElement>(null);
    const [idMenu] = useState(props.idMenu ?? nanoid());
    const menuInfo = useSelector(
        (state: RootState) => state.genericMenu[idMenu] ?? defaultState
    );

    const [showBtn, setShowBtn] = useState(false);

    const listRef = useRef<HTMLDivElement>(null);
    const btnRef = useRef<any>();
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (handleClose === null || props.open !== undefined) {
            dispatch(
                reset(idMenu, {
                    selected: initialElements,
                    elementsToOmit,
                    config: {
                        loadInitialType,
                        loadElements,
                        calcId,
                        isInitialType,
                    },
                })
            );
            if (listRef?.current?.scrollTop) {
                listRef.current.scrollTop = 0;
            }
        }
    }, [elementsToOmit.length]);

    useEffect(() => {
        if (!menuInfo.search) {
            setShowBtn(menuInfo.order.length > 0 || !menuInfo.noMoreElements);
        }
    }, [menuInfo.order, menuInfo.noMoreElements]);

    useEffect(() => {
        if (handleClose === null || props.open !== undefined) {
            return (): void => {
                dispatch(clear(idMenu));
            };
        }
        return;
    }, []);

    const renderContent = (): JSX.Element => {
        return (
            <div
                data-testid={'GenericMenu'}
                style={{ marginTop }}
                className={
                    mobile ? styles.mobileContainer : styles.emptyContainer
                }
                onClick={(e): void => {
                    if (emptyRef.current === e.target) {
                        if (handleClose === null) {
                            if (search !== undefined)
                                dispatch(search(idMenu, ''));
                            setOpen(false);
                        } else {
                            handleClose?.();
                        }
                    }
                }}
                ref={emptyRef}
            >
                <div className={styles.container}>
                    <div className={styles.title}>
                        <div className={styles.titleLbl}>
                            {title ??
                                'Seleccionar ' +
                                    (multiple ? pluralLabel : label)}
                        </div>
                        <div className={styles.btnsContainer}>
                            {customMenuButtons?.()}
                        </div>
                    </div>
                    <div className={styles.searchContainer}>
                        <Search
                            wait={500}
                            autoFocus={true}
                            placeholder={'Buscar ' + pluralLabel}
                            search={menuInfo.search}
                            seed={seed}
                            handleLoadingSearch={(loading: boolean): void => {
                                dispatch(setLoading(idMenu, loading));
                            }}
                            handleSearch={(value: any): void => {
                                if (listRef.current)
                                    listRef.current.scrollTop = 0;
                                dispatch(search(idMenu, value));
                            }}
                        />
                    </div>
                    <div
                        className={styles.listContainer}
                        data-testid={'GenericMenu_list'}
                    >
                        <GenericList<Type, InitialType>
                            idMenu={idMenu}
                            label={pluralLabel}
                            calcLbl={calcLbl}
                            calcIsDeleted={calcIsDeleted}
                            canChangeOrder={canChangeOrder}
                            calcIcon={calcIcon}
                            calcId={calcId}
                            listRef={listRef}
                            load={
                                !(
                                    handleClose === null ||
                                    props.open !== undefined
                                )
                            }
                            handleUpdate={
                                handleClose === null
                                    ? (...args): void => {
                                          handleUpdate?.(...args);
                                          setOpen(false);
                                      }
                                    : handleUpdate
                            }
                            multiple={multiple}
                            initialElements={initialElements}
                            elementsToOmit={elementsToOmit}
                            loadElements={loadElements}
                            loadInitialType={loadInitialType}
                            isInitialType={isInitialType}
                            handleView={handleView}
                            handleConfig={handleConfig}
                        />
                        {handleConfirm !== undefined && (
                            <div
                                className={styles.confirmBtnContainer}
                                data-testid={'GenericMenu_Confirm'}
                            >
                                <RoundedButton
                                    text={confirm}
                                    onClick={(): void => {
                                        if (
                                            menuInfo.selected.length > 0 ||
                                            clearable
                                        ) {
                                            const elements =
                                                menuInfo.selected.map(
                                                    (id) =>
                                                        menuInfo.elements[id]
                                                );
                                            handleConfirm(
                                                multiple
                                                    ? elements
                                                    : elements[0]
                                            );
                                        }
                                    }}
                                    borderRadius={3}
                                    disabled={
                                        menuInfo.selected.length === 0 &&
                                        !clearable
                                    }
                                ></RoundedButton>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };
    const renderContainer = (): JSX.Element => {
        if (mobile) {
            return (
                <Dialog
                    onClose={(): void => {
                        handleClose?.() ?? setOpen(false);
                    }}
                    maxWidth="100vw"
                    border={10}
                    transparent
                >
                    {renderContent()}
                </Dialog>
            );
        } else {
            return (
                <ClickAwayListener
                    mouseEvent="onMouseDown"
                    onClickAway={(): void => {
                        handleClose?.() ?? setOpen(false);
                    }}
                >
                    <Popper
                        open={true}
                        anchorEl={anchorRef?.current ?? btnRef.current}
                        placement={placement}
                        modifiers={{
                            preventOverflow: {
                                enabled: true,
                                priority: ['top', 'bottom', 'left', 'right'],
                                boundariesElement: 'viewport',
                                padding: 20,
                            },
                        }}
                        style={{ zIndex }}
                    >
                        {renderContent()}
                    </Popper>
                </ClickAwayListener>
            );
        }
    };

    return (
        <React.Fragment>
            {(props.open ?? (!!handleClose || open)) && renderContainer()}
            {customButton?.(
                btnRef,
                () => {
                    setOpen(true);
                },
                !showBtn
            )}
        </React.Fragment>
    );
}
export default GenericMenu;
