import { AnyAction } from '@reduxjs/toolkit';
import { Action } from '../../../@Types/@Types';
import * as Actions from './ActionsActions';
import * as TicketActions from '../TicketsActions';
import produce from 'immer';
import ActionTypes from '../../../constants/ActionTypes';
import { formatAction, genericAppendRule } from './utils';

export interface ActionsPageState {
    /** The currently displayed Actions */
    elements: Action[];
    /** The number of interactions */
    numInteractions: number;
    /** If loader is active */
    loading: boolean;
    /** The current Max width of draftJS imgs in the list. */
    imgMaxWidth: number;
    /** If the posventa dialog should be displayed */
    showCBRPosVenta: boolean;
    /** the number of actions */
    numActions: number;
}

const initialState = {
    elements: [],
    numInteractions: 0,
    loading: true,
    imgMaxWidth: 0,
    showCBRPosVenta: false,
    numActions: 0,
};

/**
 * Redux Reducer that handles ActionsActions Tiggers
 * @param state The current state
 * @param action the action that was triggered
 * @returns the new state
 */
const ActionsReducer = (
    state: ActionsPageState = initialState,
    action: AnyAction
): ActionsPageState => {
    if (Actions.setImgMaxWidth.match(action)) {
        return { ...state, imgMaxWidth: action.payload };
    } else if (
        Actions.reset.match(action) ||
        TicketActions.reset.match(action) ||
        TicketActions.refresh.match(action) ||
        TicketActions.selectElement.match(action) ||
        (TicketActions.refreshCurrent.match(action) && action.payload)
    ) {
        return {
            ...state,
            elements: [],
            numInteractions: 0,
            numActions: 0,
            loading: true,
        };
    } else if (Actions.setLoading.match(action)) {
        return {
            ...state,
            loading: action.payload,
        };
    } else if (Actions.getSuccess.match(action)) {
        return {
            ...state,
            loading: false,
            elements: action.payload.elements,
            numInteractions: action.payload.numInteractions,
            numActions: action.payload.numActions,
        };
    } else if (Actions.setShowCBRPosVenta.match(action)) {
        return {
            ...state,
            showCBRPosVenta: action.payload,
        };
    } else if (Actions.removeAction.match(action)) {
        return produce(state, (newState) => {
            const index = newState.elements.findIndex(
                (element) => element._id === action.payload
            );
            if (index !== -1) {
                newState.elements.splice(index, 1);
            }
        });
    } else if (Actions.updateAction.match(action)) {
        return produce(state, (newState) => {
            const update = action.payload.update;
            if (update.creation_date)
                update.creation_date = new Date(update.creation_date);

            const index = newState.elements.findIndex((element) => {
                return element._id === action.payload.idAction;
            });
            if (index !== -1) {
                let newAction: Action = newState.elements[index];
                if (
                    newAction.type === update.type ||
                    (newAction.type === ActionTypes.TEMP_REPLY &&
                        update.type === ActionTypes.REPLY)
                ) {
                    newAction = {
                        ...newAction,
                        ...update,
                    };
                }
                newState.elements[index] = newAction;
            }
        });
    } else if (Actions.appendAction.match(action)) {
        return produce(state, (newState) => {
            formatAction(action.payload);
            genericAppendRule(action.payload, newState);
            newState.numActions += 1;
        });
    } else if (Actions.updateActionType.match(action)) {
        const { idAction, type, date } = action.payload;
        return produce(state, (newState) => {
            let index = newState.elements.findIndex(
                (element) => element._id === idAction
            );
            if (index !== -1) {
                newState.elements[index].type = type;
                if (date) {
                    newState.elements[index].creation_date = date;
                }
            }
        });
    } else if (Actions.sendToTheBottom.match(action)) {
        const idAction = action.payload.idAction;
        return produce(state, (newState) => {
            let index = newState.elements.findIndex(
                (element) => element._id === idAction
            );
            if (index !== -1) {
                const copy = newState.elements[index];
                newState.elements.splice(index, 1);
                newState.elements.push(copy);
            }
        });
    }
    return state;
};
export default ActionsReducer;
