import styles from './ClientEntityRelationship.module.css';
import React, { useEffect, useRef, useState } from 'react';
import { Client } from '../../../@Types/Client';
import { EntityValue } from '../../../@Types/EntityValue';
import {
    loadEntityValuesById,
    removeEntityValueClient,
} from '../../../controllers/EntityValuesController/EntityValuesService';
import RoundedButton from '../../RoundedButton/RoundedButton';
import Loader from '../../Loader/Loader';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import { addEntityValueClient } from '../../../controllers/EntityValuesController/EntityValuesService';
import { useSelector } from 'react-redux';
import ClientEntityValueComponent from './ClientEntityValue/ClientEntityValue';
import { RootState } from '../../../utils/_store';
import EntityValuesMenu from '../../@Menus/EntityValuesMenu/EntityValuesMenu';
import { EntityRelationship } from '../../../@Types/EntityTypes/Entity';

interface ClientEntityRelationshipProps {
    client: Client;
    refresh?: Function;
    relationship: EntityRelationship;
}
function ClientEntityRelationship({
    client,
    refresh,
    relationship,
}: ClientEntityRelationshipProps): JSX.Element {
    const [entityValues, setEntityValues] = useState<EntityValue[] | null>(
        null
    );
    const [showAdd, setShowAdd] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState<string | undefined>(
        undefined
    );

    const addBtnRef = useRef<HTMLDivElement>(null);
    async function loadEntityValues(): Promise<void> {
        const entityValues = await loadEntityValuesById(
            relationship.idEntity,
            client.entities[relationship.idEntity]?.map(
                (c) => c.idEntityValue
            ) ?? []
        );
        setEntityValues(entityValues);
    }

    useEffect(() => {
        loadEntityValues();
    }, [client.entities]);

    const entity = useSelector(
        (state: RootState) => state.site.entities[relationship.idEntity]
    );
    if (!entity) return <div></div>;

    return (
        <React.Fragment>
            {deleteDialog !== undefined && (
                <ConfirmDialog
                    onConfirm={async (
                        element: string,
                        setLoading: Function
                    ): Promise<void> => {
                        try {
                            setLoading(true);
                            await removeEntityValueClient(
                                relationship.idEntity,
                                deleteDialog,
                                client._id
                            );
                            refresh?.();
                            setDeleteDialog(undefined);
                        } catch (error) {
                            //TODO: handle errors
                            console.error(error);
                            setLoading(false);
                        }
                    }}
                    element={deleteDialog}
                    title={'Desvincular ' + entity.name}
                    btnMsg="Desvincular"
                    deleting
                    msg={`¿Está seguro de que desea desvincular el cliente de ${
                        entity.feminine ? 'esta' : 'este'
                    } ${entity.name}?`}
                    onClose={(): void => {
                        setDeleteDialog(undefined);
                    }}
                />
            )}
            {showAdd && (
                <EntityValuesMenu
                    anchorRef={addBtnRef}
                    entity={entity}
                    handleClose={(): void => {
                        setShowAdd(false);
                    }}
                    placement="bottom-end"
                    zIndex={1301}
                    marginTop={'-5px'}
                    multiple={false}
                    elementsToOmit={entityValues ?? undefined}
                    handleConfirm={async (
                        entityValue: EntityValue
                    ): Promise<void> => {
                        try {
                            if (
                                !relationship.multiple &&
                                entityValues &&
                                entityValues.length === 1
                            ) {
                                await removeEntityValueClient(
                                    relationship.idEntity,
                                    entityValues[0]._id,
                                    client._id
                                );
                            }
                            await addEntityValueClient(
                                relationship.idEntity,
                                entityValue._id,
                                client._id
                            );
                            refresh?.();
                            loadEntityValues();
                            setShowAdd(false);
                        } catch (error) {
                            //TODO: handle errors
                            console.error(error);
                        }
                    }}
                />
            )}
            <div className={styles.entityValuesContainer}>
                <div className={styles.entityValuesLbl}>
                    {relationship.name}:
                    <div className={styles.addBtnContainer} ref={addBtnRef}>
                        <RoundedButton
                            text={
                                (!relationship.multiple &&
                                entityValues &&
                                entityValues?.length === 1
                                    ? 'Cambiar '
                                    : 'Agregar ') + entity.name
                            }
                            backgroundColor={'var(--secondary)'}
                            color={'white'}
                            onClick={(): void => {
                                setShowAdd(true);
                            }}
                            padding="2px 13px"
                        />
                    </div>
                </div>
                <div className={styles.entityValuesListContainer + ' flexbox'}>
                    {!entityValues && (
                        <div className={styles.loaderContainer}>
                            <Loader size={50} />
                        </div>
                    )}
                    {entityValues && entityValues.length === 0 && (
                        <div className={styles.emptyLbl}>
                            Cliente sin {entity.pluralName.toLowerCase()}
                        </div>
                    )}
                    {entityValues &&
                        entityValues.map((entityValue, index) => (
                            <ClientEntityValueComponent
                                client={client}
                                entityValue={entityValue}
                                key={index}
                                handleUpdate={(entityValue: any): void => {
                                    const tempEntityValues = [...entityValues];
                                    tempEntityValues[index] = entityValue;
                                    setEntityValues(tempEntityValues);
                                }}
                                handleDelete={
                                    !relationship.required ||
                                    (relationship.required &&
                                        entityValues.length > 1)
                                        ? (): void => {
                                              setDeleteDialog(entityValue._id);
                                          }
                                        : undefined
                                }
                            />
                        ))}
                </div>
            </div>
        </React.Fragment>
    );
}
export default ClientEntityRelationship;
