import styles from './Create.module.css';
import AddRoundedIcon from '@material-ui/icons/AddRounded';

interface CreateProps {
    /** Function Triggered onClick */
    handleCreate: Function;
    /** Text on the button */
    label: string;
    /** Text Fontsize on button */
    fontSize: number;
    /** Button Width */
    width?: number;
    /** The ref of the btn */
    btnRef?: any;
}
/** Component used to add elements to a grid of subitems in a tree */
function Create({
    handleCreate,
    label,
    btnRef,
    fontSize = 20,
    width,
}: CreateProps): JSX.Element {
    const style: React.CSSProperties = {
        fontSize,
    };
    /** If with is not undefined set it as fixed */
    if (width) {
        style.width = width;
    }

    return (
        <div
            className={styles.container + ' noselect'}
            ref={btnRef}
            onClick={(): void => {
                handleCreate();
            }}
        >
            <div className={styles.addBtn + ' secondary-btn'}>
                <AddRoundedIcon style={{ fill: 'white' }} fontSize="inherit" />
            </div>
            <div className={styles.label} style={style}>
                {label}
            </div>
        </div>
    );
}
export default Create;
