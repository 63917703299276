import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './TemplatesButton.module.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import TemplateIcon from '../../../Icons/TemplateIcon';
import { ClickAwayListener, Popper } from '@material-ui/core';
import { Template } from '../../../@Types/Template';
import {
    usePayloadEditorDispatch,
    PayloadEditorActions,
} from '../../../controllers/PayloadEditorController/PayloadEditorSlice';
import { useLoadTemplatesQuery } from '../../../controllers/TemplatesController/TemplatesService';
import Loader from '../../Loader/Loader';
import TemplateComponent from './TemplateComponent/TemplateComponent';
import Search from '../../Search/Search';

interface TemplatesButtonProps {
    zIndex?: number;
}

function TemplatesButton({ zIndex = 1300 }: TemplatesButtonProps): JSX.Element {
    const idProject = useSelector((state: RootState) => state.site.idProject);
    if (!idProject) return <></>;
    const btnRef = useRef<HTMLButtonElement>(null);
    const { data } = useLoadTemplatesQuery({ idProject });
    const [timer, setTimer] = useState<any>(undefined);
    const [open, setOpen] = useState(false);
    const dispatch = usePayloadEditorDispatch();
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState<string>('');

    /**
     * Deletes timeout on component exit.
     */
    useEffect((): (() => void) => {
        return (): void => {
            clearTimer(timer);
        };
    }, [timer]);

    const templates = useMemo((): Template[] => {
        if (!search) return data ?? [];
        const regex = new RegExp(search, 'gi');
        return (
            data?.filter(
                (template) =>
                    regex.test(template.name) ||
                    regex.test(template.description)
            ) ?? []
        );
    }, [data, search]);

    return (
        <>
            {open && data && (
                <ClickAwayListener
                    mouseEvent="onMouseDown"
                    onClickAway={(): void => setOpen(false)}
                >
                    <Popper
                        open={true}
                        disablePortal
                        anchorEl={btnRef.current}
                        placement={'right-end'}
                        modifiers={{
                            preventOverflow: {
                                enabled: true,
                                priority: ['top', 'bottom', 'left', 'right'],
                            },
                        }}
                        style={{ zIndex: zIndex + 1 }}
                    >
                        <div
                            data-testid="TemplatesMenu"
                            className={styles.emptyContainer}
                            style={{
                                height:
                                    data.length > 5
                                        ? '298px'
                                        : 67 + 46 * data.length + 'px',
                            }}
                        >
                            <div className={styles.container}>
                                <div className={styles.titleContainer}>
                                    <div className={styles.title}>
                                        Plantillas:
                                    </div>
                                </div>
                                <div className={styles.searchContainer}>
                                    <Search
                                        wait={500}
                                        autoFocus={true}
                                        placeholder={'Buscar'}
                                        search={search}
                                        handleLoadingSearch={setLoading}
                                        handleSearch={(
                                            value,
                                            loading
                                        ): void => {
                                            setSearch(value);
                                            setLoading(loading);
                                        }}
                                    />
                                </div>
                                {loading && (
                                    <div className={styles.loaderContainer}>
                                        <Loader />
                                    </div>
                                )}
                                <div
                                    className={styles.scrollContainer}
                                    onMouseLeave={(): void => {
                                        clearTimer(timer);
                                        dispatch(
                                            PayloadEditorActions.clearPreview()
                                        );
                                    }}
                                >
                                    {!loading && templates.length === 0 && (
                                        <div
                                            className={
                                                styles.noTemplateContainer
                                            }
                                        >
                                            No se encontraron plantillas
                                        </div>
                                    )}
                                    {!loading && templates.length > 0 && (
                                        <div
                                            className={
                                                styles.templatesContainer
                                            }
                                            style={{
                                                paddingBottom:
                                                    data.length > 5 ? 5 : 0,
                                            }}
                                            data-testid="TemplatesMenuList"
                                        >
                                            {templates.map((template) => (
                                                <TemplateComponent
                                                    template={template}
                                                    key={template._id}
                                                    onMouseEnter={(): void => {
                                                        clearTimer(timer);
                                                        setTimer(
                                                            setTimeout(() => {
                                                                dispatch(
                                                                    PayloadEditorActions.preview(
                                                                        template
                                                                    )
                                                                );
                                                            }, 500)
                                                        );
                                                    }}
                                                    onClick={(): void => {
                                                        clearTimer(timer);
                                                        dispatch(
                                                            PayloadEditorActions.calcTemplate(
                                                                template
                                                            )
                                                        );
                                                        setOpen(false);
                                                    }}
                                                    onMouseLeave={(): void => {}}
                                                />
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Popper>
                </ClickAwayListener>
            )}
            <button
                className={styles.templateBtn}
                ref={btnRef}
                onClick={(): void => {
                    setOpen(true);
                }}
                data-testid={'TemplateButton'}
            >
                <TemplateIcon
                    style={{
                        display: 'flex',
                        background: 'white',
                    }}
                    fill="inherit"
                />
            </button>
        </>
    );
}
export default React.memo(TemplatesButton, () => true);

const clearTimer = (timer: any): void => {
    try {
        clearTimeout(timer);
        // eslint-disable-next-line no-empty
    } catch (e) {}
};
