export enum ActivityTypes {
    //Cambio de estado
    STATE = 'STATE',
    /** Haga algo en x tiempo */
    TIME = 'TIME',
    /** Haga algo en esta fecha */
    DATE = 'DATE',
    /** Muestre formulario a agentes */
    FORM = 'FORM',
    /** Muestre formulario al cliente */
    CLIENT_FORM = 'CLIENT_FORM',
    /**  Mostrar formulario a agentes*/
    VIEW_FORM = 'VIEW_FORM',
    /** Mostrar formulario al cliente*/
    CLIENT_VIEW_FORM = 'CLIENT_VIEW_FORM',
    NOTIFICATION = 'NOTIFICATION',
    /** Schedule a date with a client */
    SCHEDULE = 'SCHEDULE',
    SCHEDULE_CANCEL = 'SCHEDULE_CANCEL',
    /** Sets a date for an appointment with the client */
    AI_APPOINTMENT_SET = 'AI_APPOINTMENT_SET',
    /** A group of activities */
    GROUP = 'GROUP',
    /** An agent makes a comment */
    COMMENT = 'COMMENT',
    /** An agent makes a reply */
    REPLY = 'REPLY',
    /** An agent makes a pre reply */
    PRE_REPLY = 'PRE_REPLY',
    INTEGRATION_SUCCESS = 'INTEGRATION_SUCCESS',
}
