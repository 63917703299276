import styles from './ListaCasos.module.css';
import React, { useState } from 'react';
import TicketListItem from '../../../../../pages/Tickets/TicketListItem/TicketListItem';
import { OpenComponent } from '../../Utils/Openable/OpenComponent';
import Loader from '../../../../../shared/Loader/Loader';
import { Ticket } from '../../../../../@Types/TicketTypes/Ticket';

interface ListaCasosProps {
    title: string;
    loading: boolean;
    tickets: Ticket[] | undefined;
    showOpen?: boolean;
    organization: any;
}

export function ListaCasos({
    tickets,
    loading,
    title,
    showOpen,
    organization,
}: ListaCasosProps): JSX.Element {
    const [now] = useState(new Date());
    return (
        <OpenComponent title={title} showOpen={showOpen}>
            <div className={styles.list}>
                {loading ? (
                    <div className={styles.loader}>
                        <Loader position={'relative'} />
                    </div>
                ) : !tickets ? (
                    <div></div>
                ) : (
                    <>
                        {tickets.length === 0 ? (
                            <div className={styles.noResults}>
                                No se encuentran casos
                            </div>
                        ) : (
                            <>
                                {tickets.map((ticket, index) => {
                                    return (
                                        <TicketListItem
                                            today={now}
                                            element={ticket}
                                            handleItemClick={(
                                                ticket: Ticket
                                            ): void => {
                                                const url = `${process.env.REACT_APP_PREFIX}${organization?.idOrganization}.${process.env.REACT_APP_DOMAIN}/${ticket.idProject}/tickets/${ticket._id}`;
                                                var win = window.open(
                                                    url,
                                                    '_blank'
                                                );
                                                // Cambiar el foco al nuevo tab (punto opcional)
                                                win?.focus();
                                            }}
                                            key={index}
                                        />
                                    );
                                })}
                            </>
                        )}
                    </>
                )}
            </div>
        </OpenComponent>
    );
}
