import styles from './EntitySideBar.module.css';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Avatar from '../../../../../shared/Avatar/Avatar';
import React, { useState, useRef } from 'react';
import { EntityValue, Ticket } from '../../../../../@Types/@Types';
import { ProjectEntity } from '../../../../../@Types/Project';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../utils/_store';
import EntityValuesMenu from '../../../../../shared/@Menus/EntityValuesMenu/EntityValuesMenu';
import { refreshCurrent } from '../../../../../controllers/TicketsController/TicketsActions';
import ActionsService from '../../../../../controllers/TicketsController/Actions/ActionsService';
import EntityIcon from '../../../../../shared/SmartIcons/EntityIcon';

interface EntitySideBarProps {
    /** The entity to display */
    projectEntity: ProjectEntity;
    /** The currently selected ticket */
    ticket: Ticket;
    /** If the entity can be modified */
    canChange: boolean;
    /** If the layout is mobile */
    layoutIsMobile?: boolean;
}
function EntitySideBar({
    ticket,
    canChange,
    projectEntity,
    layoutIsMobile,
}: EntitySideBarProps): JSX.Element {
    const { multiple, idEntity, manual } = projectEntity;
    const entity = useSelector(
        (state: RootState) => state.site.entities[idEntity]
    );
    const [hovering, setHovering] = useState(false);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const headerRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();

    const entityValues = ticket.entities?.[entity._id] ?? [];

    if (!entity || (!manual && entityValues.length === 0)) return <div></div>;
    return (
        <React.Fragment>
            <div className={styles.hr} />
            <div className={styles.container}>
                {menuOpen && (
                    <EntityValuesMenu
                        entity={entity}
                        anchorRef={headerRef}
                        handleClose={(): void => {
                            setMenuOpen(false);
                        }}
                        title={
                            (entityValues.length > 0
                                ? 'Cambiar '
                                : 'Seleccionar') +
                            (multiple ? entity.pluralName : entity.name)
                        }
                        initialElements={entityValues}
                        placement="bottom-end"
                        multiple={multiple}
                        confirm={
                            entityValues.length > 0 ? 'Cambiar ' : 'Seleccionar'
                        }
                        clearable
                        mobile={layoutIsMobile}
                        handleConfirm={async (
                            entityValue: EntityValue | EntityValue[]
                        ): Promise<void> => {
                            try {
                                await ActionsService.setEntity(
                                    ticket.idProject,
                                    ticket._id,
                                    entity._id,
                                    (entityValue
                                        ? Array.isArray(entityValue)
                                            ? entityValue
                                            : [entityValue]
                                        : []
                                    ).map((entityValue) => entityValue._id)
                                );
                                dispatch(refreshCurrent());
                                setMenuOpen(false);
                            } catch (error) {
                                console.error(error);
                            }
                        }}
                    />
                )}
                <div
                    ref={headerRef}
                    className={styles.header + ' noselect'}
                    data-testid={`EntitySideBar_${entity._id}_header`}
                    onMouseOver={(): void => {
                        if (!menuOpen && canChange) {
                            setHovering(true);
                        }
                    }}
                    onMouseLeave={(): void => {
                        setHovering(false);
                    }}
                    onClick={(): void => {
                        if (canChange) {
                            setMenuOpen(true);
                            setHovering(false);
                        }
                    }}
                    style={canChange ? { cursor: 'pointer' } : {}}
                >
                    <label
                        className={styles.titleLbl}
                        style={{
                            color: hovering
                                ? 'var(--contrast)'
                                : 'var(--accent)',
                            cursor: canChange ? 'pointer' : 'default',
                        }}
                    >
                        {projectEntity.multiple
                            ? entity.pluralName
                            : entity.name}
                    </label>
                    {canChange && (
                        <div
                            className={styles.editContainer}
                            style={{
                                color: hovering
                                    ? 'var(--contrast)'
                                    : 'var(--accent)',
                            }}
                        >
                            <EditRoundedIcon fontSize="inherit" />
                        </div>
                    )}
                </div>
                {entityValues.length > 0 && (
                    <div
                        className={styles.entitiesContainer}
                        data-testid={`EntitySideBar_${entity._id}_entitiesContainer`}
                    >
                        {entityValues.map((entityValue, index) => (
                            <div className={styles.entityContainer} key={index}>
                                <Avatar
                                    lbl={
                                        entityValue.values[
                                            entity.idLabelStep
                                        ] ?? entity.name
                                    }
                                    toolTip={false}
                                    size={30}
                                    fontSize={30}
                                >
                                    <EntityIcon
                                        idEntity={entity._id}
                                        fill="var(--primary1)"
                                        style={{
                                            height: 21,
                                            width: 21,
                                        }}
                                    />
                                </Avatar>
                                <label className={styles.nameLbl}>
                                    {entityValue.values[entity.idLabelStep] ??
                                        entity.name}
                                </label>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}
export default EntitySideBar;
