import { MenuItem } from '@material-ui/core';
import RoundedSelect, {
    RoundedSelectProps,
} from '../../RoundedSelect/RoundedSelect';
import React, { useMemo } from 'react';
import EntityProperties from '../../../constants/EntityPropertyTypes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import {
    ClientInfoTypeLabels,
    ClientInfoTypes,
} from '../../../constants/ClientInfoTypes';
import EntityPropertyTypes from '../../../constants/EntityPropertyTypes';
import { PropertyComponent } from '../RoundedEntityPropertyPicker/RoundedEntityPropertyPicker';
import ClientPropertyTypes from '../../../constants/ClientPropertyTypes';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import styles from './RoundedClientPropertyPicker.module.css';
import { Integration } from '../../../@Types/Integration';
import { IntegrationsApi } from '../../../controllers/IntegratrionsController/IntegrationsService';
import ApiIcon from '../../../Icons/ApiIcon';
import SmartIcon from '../../SmartIcons/SmartIcon';
import { useIdProject } from '../../../hooks';
import IntegrationTypes from '../../../constants/IntegrationTypes';

type ClientProperties = Exclude<
    EntityProperties,
    | EntityPropertyTypes.AGENTPICKER
    | EntityPropertyTypes.CLIENTPICKER
    | EntityPropertyTypes.NAME
>;

interface RoundedClientPropertyPickerProps
    extends Omit<RoundedSelectProps, 'handleUpdate' | 'value'> {
    /** Currently selected clientProperty id */
    value: string | undefined;
    /** The posible properties to select */
    propertyTypes?: (ClientProperties | ClientPropertyTypes)[];
    /** The posible integrations to select */
    integrationTypes?: IntegrationTypes[];
    /** function called when value changes */
    handleUpdate: (
        idProperty: string | ClientInfoTypes,
        propertyType:
            | ClientPropertyTypes.PERMISSIONS
            | ClientProperties
            | ClientInfoTypes
            | IntegrationTypes
    ) => void;
}
const menuStyle = {
    paddingLeft: 0,
};

function RoundedClientPropertyPicker({
    value,
    propertyTypes = Object.values(EntityPropertyTypes).filter(
        (type) =>
            type !== EntityPropertyTypes.NAME &&
            type !== EntityPropertyTypes.AGENTPICKER
    ) as any,
    handleUpdate,
    integrationTypes = [],
    ...others
}: RoundedClientPropertyPickerProps): JSX.Element {
    const idProject = useIdProject();
    const entity = useSelector((state: RootState) => state.site.clientEntity);

    const entities = useSelector((state: RootState) => state.site.entities);

    const permissions = useMemo(() => {
        let permissions: { name: string; id: string }[] = [];
        for (const entity of Object.values(entities)) {
            permissions = [...permissions, ...(entity.permissions ?? [])];
        }
        return permissions;
    }, [entities]);

    const { data: entityIntegrations } =
        IntegrationsApi.useLoadIntegrationsQuery({
            idEntity: entity?._id,
            idProject,
        });

    const integrations = useMemo(
        () =>
            entityIntegrations?.filter(
                (integration) =>
                    integration._id === value ||
                    (integration &&
                        // !elementsToOmit.includes(integration._id) &&
                        integrationTypes.includes(integration.type))
            ) as Integration[],
        [entityIntegrations, integrationTypes]
    );

    const renderClientInfo = (): JSX.Element[] => {
        return Object.values(ClientInfoTypes)
            .filter((type) => type !== ClientInfoTypes.IDENTIFIER_TYPE)
            .map((type) => (
                <MenuItem value={type} key={type}>
                    <div style={{ paddingLeft: 16 }}>
                        {ClientInfoTypeLabels[type]}
                    </div>
                </MenuItem>
            ));
    };

    const renderPermissions = (): JSX.Element[] => {
        return permissions.map((permission) => (
            <MenuItem
                style={menuStyle}
                value={permission.id}
                key={permission.id}
            >
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.propertyLbl}>
                            <LockRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                    {permission.name}
                </div>
            </MenuItem>
        ));
    };
    return (
        <RoundedSelect
            value={value}
            paddingLeft={0}
            containerMargin="0px"
            handleUpdate={(event): void => {
                const value = event.target.value ?? '';
                if (Object.values(ClientInfoTypes).includes(value)) {
                    handleUpdate(value, value);
                } else if (
                    permissions.find((permission) => permission.id === value)
                ) {
                    handleUpdate(value, ClientPropertyTypes.PERMISSIONS);
                } else {
                    const property = entity?.steps[value];
                    if (property) {
                        handleUpdate(property.id, property.type);
                    } else {
                        const integration = integrations?.find(
                            (integration) => integration._id === value
                        );
                        if (!integration) return;
                        handleUpdate(
                            value,
                            integration.type === IntegrationTypes.DRAFT
                                ? EntityProperties.DRAFT_INTEGRATION
                                : integration.type
                        );
                    }
                }
            }}
            {...others}
        >
            {propertyTypes.includes(ClientPropertyTypes.CLIENT_INFO_TYPE) &&
                renderClientInfo()}
            {propertyTypes.includes(ClientPropertyTypes.PERMISSIONS) &&
                renderPermissions()}
            {Object.values(entity?.steps ?? {})
                .filter((step) => propertyTypes.includes(step.type))
                .map((property) => {
                    return (
                        <MenuItem
                            key={property.id}
                            value={property.id}
                            style={menuStyle}
                        >
                            <PropertyComponent property={property} />
                        </MenuItem>
                    );
                })}
            {integrations?.map((integration) => (
                <MenuItem
                    key={integration._id}
                    value={integration._id}
                    style={{
                        paddingLeft: 0,
                    }}
                >
                    <div className={styles.property}>
                        <div className={styles.icon}>
                            {integration.icon ? (
                                <SmartIcon icon={integration.icon} />
                            ) : (
                                <ApiIcon />
                            )}
                        </div>
                        {integration.name}
                    </div>
                </MenuItem>
            ))}
        </RoundedSelect>
    );
}
export default RoundedClientPropertyPicker;
