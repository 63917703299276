import React from 'react';
import { DraftContext } from '../../../../@Types/Draft/DraftContext';
import WhatsappIcon from '../../../../Icons/WhatsappIcon';
import { GenericContextProps } from '../TextEditorContext';
import Context from '../Context';
import { DraftEntityDataTypes } from '../../../../constants/Draft/DraftEntityDataTypes';
import EurekaElementMenuGroup from '../../../@Menus/ElementMenu/EurekaElementMenuGroup';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../utils/_store';
import { CollectionStepTypes } from '../../../../constants/Conversations/ConversationStepTypes';

interface ConversationStepContext extends GenericContextProps {
    context: DraftContext | undefined;
}

function ConversationStepContext({
    context,
    zIndex,
    ...props
}: ConversationStepContext): JSX.Element {
    const steps = useSelector(
        (state: RootState) => state.conversationEditor.steps
    );
    const previousSteps: string[] = useSelector(
        (state: RootState) =>
            state.conversationEditor.stepDependencies[
                context?.conversation?.idStep ?? ''
            ]?.previousSteps
    );

    const filteredSteps = previousSteps?.filter((idStep) =>
        CollectionStepTypes.includes(steps[idStep].type)
    );

    if (!previousSteps || previousSteps.length === 0) return <></>;

    return (
        <EurekaElementMenuGroup
            zIndex={zIndex + 1}
            icon={WhatsappIcon}
            label="Pasos Anteriores"
            renderElements={(menuProps): JSX.Element => (
                <React.Fragment>
                    {filteredSteps.map((idStep) => (
                        <Context
                            {...props}
                            {...menuProps}
                            key={idStep}
                            data={{
                                type: DraftEntityDataTypes.CONVERSATION_STEP,
                                idStep,
                            }}
                        />
                    ))}
                </React.Fragment>
            )}
        />
    );
}

export default ConversationStepContext;
