import { createAction } from '@reduxjs/toolkit';
import { Agent } from '../../@Types/Agent';
import { AgentPermissions } from '../../@Types/Agent';
import ScrollTypes from '../../constants/ScrollTypes';

export const Types = {
    RESET: 'AGENTS/RESET',
    RESET_SUCCESS: 'AGENTS/RESET_SUCCESS',
    REFRESH: 'AGENTS/REFRESH',
    GET_SUCCESS: 'AGENTS/GET_SUCCESS',
    PAGE_SCROLL: 'AGENTS/NEW_PAGE_SCROLL',
    SELECT: 'AGENTS/SELECT_ELEMENT',
    UPDATE: 'AGENTS/UPDATE_ELEMENT',
    UPDATE_FAILED: 'AGENTS/UPDATE_FAILED',
    UPDATE_PERMS: 'AGENTS/UPDATE_PERMS',
    SEARCH: 'AGENTS/SEARCH',
    LOADING: 'AGENTS/LOADING',
    ORDER: 'AGENTS/ORDER',
    FILTER: 'AGENTS/FILTER',
    GO_TO: 'AGENTS/GO_TO',
    UPDATE_ON_VACATION: 'AGENTS/UPDATE_ON_VACATION',
};

/**
 * Called when the agents page is first loaded
 */
export const reset = createAction<{
    history: any;
    queryString: string;
    idAgent?: string;
}>(Types.RESET);

export const resetSuccess = createAction<{
    elements: Agent[];
    selectedElement?: number;
    lastPage: boolean;
    page: number;
    filters: any[];
    orderBy: string | undefined;
    search: string | undefined;
}>(Types.RESET_SUCCESS);

/**
 * Called when list has changed
 */
export const refresh = createAction(Types.REFRESH);

/**
 * Called to toggle the loader
 */
export const setLoading = createAction(
    Types.LOADING,
    function prepare(loading: boolean = true) {
        return {
            payload: loading,
        };
    }
);

/**
 * Called when the list needs to load more elements
 */
export const pageScroll = createAction<ScrollTypes>(Types.PAGE_SCROLL);

/**
 * Called when the selectedElement (index) changes value
 */
export const selectElement = createAction<number | undefined>(Types.SELECT);

/**
 * Called when a value of the selected Agent is updated
 */

export const updateOnVacation = createAction<boolean>(Types.UPDATE_ON_VACATION);

export const updateElement = createAction<{
    object: Agent;
    field: keyof Agent;
    value: string | boolean;
}>(Types.UPDATE);

export const updateFailed = createAction<Agent>(Types.UPDATE_FAILED);

/**
 * Called when the permissions of the selected Agent are updated
 */
export const updatePermissions = createAction<AgentPermissions>(
    Types.UPDATE_PERMS
);

/**
 * Called when the search value changes
 */
export const search = createAction<string>(Types.SEARCH);

/**
 *  Called when the orderby value changes
 */
export const orderBy = createAction<string>(Types.ORDER);

/**
 * Called when the filter values change
 */
export const filter = createAction<string[]>(Types.FILTER);

/**
 *  Called when the saga has sucessfully retrieved the lists elements
 */
export const getSuccess = createAction<{
    selectedElement: number | undefined;
    elements: Agent[];
    lastPage: boolean;
}>(Types.GET_SUCCESS);

export const goTo = createAction<{
    history: any;
    idAgent: string;
    queryString: string;
}>(Types.GO_TO);
