// Use throughout your app instead of plain `useDispatch` and `useSelector`

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from './utils/_store';
import { Project } from './@Types/Project';
import { Entity } from './@Types/@Types';

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useCurrentProject = (): Project => {
    return useAppSelector(
        (state) => state.site.projects[state.site.idProject ?? '']
    );
};

export const useIdProject = (): string | undefined => {
    return useAppSelector((state) => state.site.idProject ?? undefined);
};

export const useClientEntity = (): Entity | undefined => {
    return useAppSelector(
        (state) =>
            state.site.entities[state.site.organization?.idClientEntity ?? '']
    );
};

export const useEntity = (idEntity: string): Entity | undefined => {
    return useAppSelector((state) => state.site.entities[idEntity ?? '']);
};
