import { StepComponentProps } from '../../Step';
import styles from './InfoDocumentStep.module.css';
import React, { useRef } from 'react';
import StepContainer from '../../StepContainer/StepContainer';
import { InfoDocumentStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import DraftRenderer from '../../../../../../shared/DraftRenderer/DraftRenderer';
import { EditorState, convertFromRaw } from 'draft-js';
import FileComponent from '../../../../../../shared/FileComponent/FileComponent';

interface InfoDocumentStepProps extends StepComponentProps {
    step: InfoDocumentStep;
}

function InfoDocumentStepComponent({
    step,
    path,
}: InfoDocumentStepProps): JSX.Element {
    const content = convertFromRaw(step.caption);
    const documentRef = useRef<HTMLDivElement>(null);
    return (
        <StepContainer step={step} path={path}>
            <div className={styles.documentContainer} ref={documentRef}>
                <FileComponent file={step.file} large />
            </div>
            {content.hasText() && (
                <div
                    className={styles.messageContainer}
                    style={{
                        width: documentRef?.current?.offsetWidth ?? '100%',
                    }}
                >
                    <DraftRenderer
                        editorState={EditorState.createWithContent(content)}
                    />
                </div>
            )}
        </StepContainer>
    );
}

export default InfoDocumentStepComponent;
