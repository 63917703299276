import { AnyAction } from '@reduxjs/toolkit';
import { takeLatest, call, put, fork, select } from 'redux-saga/effects';
import * as actions from './EntitiesActions';
import { loadEntities, updateEntity } from './EntitiesService';
import VanillaToast from '../../shared/Toast/Toast';

/** Number of elements per Page */
const PAGE_SIZE = 20;

/**
 * Function called when new entities should be rendered (reset,search, or newpage)
 * @param action of type Search, reset or scroll
 */
function* getEntities(action: AnyAction): any {
    try {
        const pageInfo = yield select((state) => state.entitiesPage);
        let result = yield call(
            loadEntities,
            pageInfo.page,
            PAGE_SIZE,
            pageInfo.search
        );
        /** Calcs if more elements are available */
        const noMoreElements =
            (pageInfo.page !== 1 && result.length === 0) ||
            (pageInfo.page === 1 && result.length < PAGE_SIZE);

        if (actions.pageScroll.match(action)) {
            result = [...pageInfo.elements].concat(result);
        }
        yield put(actions.getSuccess({ elements: result, noMoreElements }));
    } catch (error) {
        //TODO handle errors
        console.error(error);
    }
}

/**
 * Function called when an entity's value is updated optimistically to patch the updates to the server asyncly
 * @param action of type UPDATE
 */
function* putEntity(action: AnyAction): any {
    if (actions.updateElement.match(action)) {
        try {
            const payload: Record<string, unknown> = {};
            payload[action.payload.field] = action.payload.value;
            yield call(updateEntity, action.payload.object._id, payload);
        } catch (error) {
            VanillaToast.create({
                title: 'Error al actualizar la entidad',
                text: error,
                type: 'error',
                timeout: 5000,
            });
            yield put(actions.updateFailed(action.payload.object));
        }
    }
}

function* watchGetEntitiesRequest(): any {
    yield takeLatest(
        [actions.Types.RESET, actions.Types.PAGE_SCROLL, actions.Types.SEARCH],
        getEntities
    );
}

function* watchUpdateEntity(): any {
    yield takeLatest([actions.Types.UPDATE], putEntity);
}

export default [fork(watchGetEntitiesRequest), fork(watchUpdateEntity)];
