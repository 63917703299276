import { IconProps } from './@IconTypes';

function UnlockedIcon({ className, style, fill }: IconProps): JSX.Element {
    return (
        <svg
            fill={fill}
            style={style}
            className={className}
            version="1.1"
            viewBox="2 0 99 98"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M83.6,48.6c0-8.9-0.4-9.3-9.2-9.6c0,0,0,0,0,0c0-3.9,0-8.9-0.1-12C73.8,15.4,66.6,8.4,54.9,8.1c-2,0-4,0-6,0  C36,8.2,28.6,16,28.7,28.8c0,3.7,1.1,5.4,5.2,5.3c3.7-0.1,5.9-0.5,5.8-5.1c-0.1-7.3,4-10.2,12.8-10c7.3,0.1,10.9,3.6,11,10.5  c0,3.2,0,6.3,0,9.5c0,0,0,0,0,0c0,0,0,0,0,0c-12.3,0.1-24.6,0.1-37,0.2c-5.7,0-7,1.4-7,7.1c0,12.2,0.1,24.3-0.1,36.5  c-0.1,4.7,1.8,6.8,6.4,6.8c17.2,0,34.3,0,51.5,0c4.3,0,6.3-1.9,6.3-6.4C83.5,71.6,83.6,60.1,83.6,48.6z M55.1,66.3V72  c0,1.9-1.6,3.5-3.5,3.5s-3.5-1.6-3.5-3.5v-5.7c-2.9-1.3-4.9-4.3-4.9-7.7c0-4.7,3.8-8.4,8.4-8.4c4.7,0,8.4,3.8,8.4,8.4  C60,62.1,58,65,55.1,66.3z" />
        </svg>
    );
}
export default UnlockedIcon;
