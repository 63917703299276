import styles from './Agents.module.css';
import { Agent } from '../../../../../../@Types/@Types';
import AgentComponent from './Agent/Agent';
interface AgentsProps {
    /** Agents to display */
    agents: Agent[];
    /** If the agents being displayed were removed */
    removed: boolean;
}
/**
 * Component used to display the agents content of an action, it being of them removed or assigned
 */
function Agents({ agents, removed }: AgentsProps): JSX.Element {
    return (
        <div className={styles.container + ' flexbox'}>
            {agents.map((agent, index) => (
                <AgentComponent key={index} agent={agent} removed={removed} />
            ))}
        </div>
    );
}

export default Agents;
