import {
    EntityValuePicker,
    FormStep,
} from '../../../../@Types/FormTypes/FormStep';
import styles from './EntityValueStepConfig.module.css';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import React from 'react';
import { stringToDraft } from '../../../../utils/draftFunctions';
import produce from 'immer';
import EntityValuePickerDialog from './EntityValuePickerDialog/EntityValuePickerDialog';
import { FormStepLocation } from '../../../../@Types/FormTypes/Form';

interface EntityValueStepConfigProps {
    step?: EntityValuePicker;
    location: FormStepLocation;
    updateStep?: (step: FormStep) => void;
}

function EntityValueStepConfig({
    step,
    location,
    updateStep,
}: EntityValueStepConfigProps): JSX.Element {
    if (!step || !updateStep || !location) {
        return <div></div>;
    }
    return (
        <React.Fragment>
            <div className={styles.dialogsLabel}>Alertas:</div>
            {step.dialogs && step.dialogs.length && (
                <div className={styles.dialogsContainer}>
                    {step.dialogs?.map((dialog, index) => (
                        <EntityValuePickerDialog
                            key={index}
                            step={step}
                            location={location}
                            dialog={dialog}
                            handleUpdate={(dialog): void => {
                                updateStep(
                                    produce(step, (step) => {
                                        if (step.dialogs?.[index])
                                            step.dialogs[index] = dialog;
                                    })
                                );
                            }}
                            handleDelete={(): void => {
                                updateStep(
                                    produce(step, (step) => {
                                        step.dialogs?.splice(index, 1);
                                    })
                                );
                            }}
                        />
                    ))}
                </div>
            )}
            <div
                className={styles.addDialog}
                onClick={(): void => {
                    updateStep({
                        ...step,
                        dialogs: [
                            ...(step.dialogs ?? []),
                            {
                                type: 'INFO',
                                message: stringToDraft('Información'),
                            },
                        ],
                    });
                }}
            >
                <div className={styles.addIconContainer}>
                    <AddRoundedIcon fontSize="inherit" />
                </div>
                <div className={styles.addDialogLbL + ' noselect'}>
                    Agregar Alerta
                </div>
            </div>
        </React.Fragment>
    );
}

export default EntityValueStepConfig;
