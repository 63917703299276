import { IconProps } from './@IconTypes';

function FilterIcon({ className, style, fill }: IconProps): JSX.Element {
    return (
        <svg
            fill={fill}
            style={
                {
                    ...style,
                    enableBackground: 'new 0 0 950.001 950.001',
                } as any
            }
            className={className}
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="950.001px"
            height="950.001px"
            viewBox="0 0 950.001 950.001"
        >
            <g>
                <g>
                    <path
                        d="M847.999,0.004L102.004,0c-12.847,0-23.826,9.261-25.99,21.925c-3.811,22.311-5.796,45.244-5.796,68.642
			c0,89.48,30.074,177.528,84.996,248.189c37.469,48.207,90.985,79.211,135.705,119.741c41.243,37.379,62.397,77.425,74.786,130.858
			c22.388,96.557,26.812,197.796,29.41,296.562c0.467,17.741-0.11,35.776,0.343,53.647c0.215,8.47,9.713,13.343,16.696,8.546
			c33.969-23.328,113.479-77.931,141.485-97.164c6.427-4.413,10.563-11.436,11.303-19.196
			c6.163-64.745,14.067-239.202,27.092-281.09c28.804-92.642,134.067-129.735,192.947-199.773
			c60.998-72.558,94.802-165.541,94.802-260.32c0-23.396-1.985-46.329-5.796-68.638C871.825,9.265,860.846,0.004,847.999,0.004z"
                    />
                </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
        </svg>
    );
}
export default FilterIcon;
