import styles from './EntityValueFilters.module.css';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { useRef, useState } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import { EntityValueFilters } from '../../../controllers/EntityValuesController/EntityValuesReducer';
import EntityValueFiltersMenu from './EntityValueFiltersMenu/EntityValuesFiltersMenu';
interface TicketFiltersProps {
    idEntity: string;
    handleFilter: (filters: EntityValueFilters) => void;
    mobile: boolean;
}
function EntityValueFiltersComponent({
    mobile,
    idEntity,
    handleFilter,
}: TicketFiltersProps): JSX.Element {
    const btnRef = useRef<HTMLDivElement>(null);
    const [showMenu, setShowMenu] = useState(false);
    const filters = useSelector(
        (state: RootState) => state.entityValuesPage.filters
    );
    const nFilters =
        Object.values(filters.values ?? {}).length +
        Object.values(filters.relationships ?? {}).reduce(
            (n, entityValues) => n + entityValues.length,
            0
        );

    return (
        <React.Fragment>
            {showMenu && (
                <EntityValueFiltersMenu
                    idEntity={idEntity}
                    anchorRef={btnRef}
                    filters={filters}
                    handleChange={(filters: EntityValueFilters): void => {
                        handleFilter(filters);
                    }}
                    handleClose={(): void => {
                        setShowMenu(false);
                    }}
                    layoutIsMobile={mobile}
                />
            )}
            <div className={styles.container}>
                <div
                    className={styles.button}
                    ref={btnRef}
                    onClick={(): void => {
                        setShowMenu(true);
                    }}
                >
                    <label className={styles.filtrosLbl}>Filtros</label>
                    <div className={styles.countContainer}>
                        {nFilters > 0 && (
                            <div className={styles.countLbl}>{nFilters}</div>
                        )}
                    </div>
                    <div className={styles.dropDownContainer}>
                        <div className={styles.dropDown}>
                            <ExpandMoreRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default EntityValueFiltersComponent;
