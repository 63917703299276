import React from 'react';
import styles from './Queries.module.css';
import { useState } from 'react';
import QueriesDialog from './QueriesDialog/QueriesDialog';
import PollRoundedIcon from '@material-ui/icons/PollRounded';
import { Company } from '../../../@Types/@Types';
interface QueriesProps {
    company: Company | undefined;
}
/**
 * Component rendered inside the create area dialog.
 */
function Queries({ company }: QueriesProps): JSX.Element {
    const [showDialog, setShowDialog] = useState(false);
    if (company) {
        return (
            <React.Fragment>
                {company.values['SINCO/idCliente'] && (
                    <div
                        className={styles.analyticsBtn}
                        onClick={(): void => {
                            setShowDialog(true);
                        }}
                    >
                        <PollRoundedIcon fontSize="inherit" />
                    </div>
                )}
                {company.values['SINCO/idCliente'] && showDialog && (
                    <QueriesDialog
                        name={company.values.name ?? 'Empresa'}
                        idCliente={company.values['SINCO/idCliente']}
                        onClose={(): void => {
                            setShowDialog(false);
                        }}
                        negocio={company.values['SELECTOR-Negocio']}
                    />
                )}
            </React.Fragment>
        );
    } else {
        return <div></div>;
    }
}
export default Queries;
