import axiosInstance from '../../AxiosAPI';
import { Ticket } from '../../@Types/TicketTypes/Ticket';
import { Action } from '../../@Types/Action';

export const loadPendingTickets = async (): Promise<{
    count: number;
    tickets: Ticket[];
}> => {
    const response = await axiosInstance.get('/home/pending');
    return {
        count: response.data.count,
        tickets: response?.data?.data?.map(
            (ticket: any): Ticket =>
                ({
                    ...ticket,
                    resolution_date: new Date(ticket.resolution_date),
                    creation_date: new Date(ticket.creation_date),
                } as Ticket)
        ),
    };
};
export const loadExpiredTickets = async (): Promise<{
    count: number;
    tickets: Ticket[];
}> => {
    const response = await axiosInstance.get('/home/expired');
    return {
        count: response.data.count,
        tickets: response.data.data.map(
            (ticket: any): Ticket =>
                ({
                    ...ticket,
                    resolution_date: new Date(ticket.resolution_date),
                    creation_date: new Date(ticket.creation_date),
                } as Ticket)
        ),
    };
};
export const loadPendingTicketsPerProject = async (): Promise<
    Record<string, number>
> => {
    let url = '/home/projectPending';
    const response = await axiosInstance.get(url);
    return response.data;
};
export const loadRecentActivities = async (): Promise<Action[]> => {
    let url = '/home/recentActivities';

    const response = await axiosInstance.get(url);
    const resp = response.data.map(
        (action: any): Action =>
            ({
                ...action,
                creation_date: new Date(action.creation_date),
            } as Action)
    );
    return resp;
};
export default {
    loadPendingTickets,
    loadExpiredTickets,
    loadPendingTicketsPerProject,
    loadRecentActivities,
};
