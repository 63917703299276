import styles from './CreateStep.module.css';
import React, { useContext } from 'react';
import {
    ConversationStep,
    CreateStep,
    DefaultCreateStep,
} from '../../../../../@Types/ConversationTypes/ConversationStep';
import { useDispatch } from 'react-redux';
import { updateStep } from '../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import StepTypes from '../../../../../constants/Conversations/ConversationStepTypes';
import Toggle from '../../../../../shared/Toggle/Toggle';
import RoundedTextField from '../../../../../shared/RoundedTextField/RoundedTextField';
import { CreateMessage } from '../../StepFunctions';
import {
    ApiKeyContext,
    BaseConversationPayloadConditionTypes,
} from '../../ConversationEditor';
import PayloadEditor from '../../../../../shared/TextEditor/PayloadEditor';
import { TextEditorTypes } from '../../../../../constants/TextEditorTypes';

interface CreateStepProps {
    step: CreateStep;
}

function CreateStepComponent({ step }: CreateStepProps): JSX.Element {
    const dispatch = useDispatch();
    const apiKey = useContext(ApiKeyContext);
    const renderToggle = (): JSX.Element => {
        return (
            <div className={styles.typeContainer}>
                Gestión Múltiple:
                <div className={styles.toggleContainer}>
                    <Toggle
                        checked={step.type === StepTypes.CREATE_DEFAULT}
                        onChange={(checked: boolean): void => {
                            let newStep: ConversationStep;
                            if (checked) {
                                newStep = {
                                    ...step,
                                    type: StepTypes.CREATE_DEFAULT,
                                    showUrl: true,
                                    header: 'Caso Registrado',
                                    message: step.message ?? CreateMessage,
                                    footer: 'Para consultarlo, haz clic en el siguiente botón:',
                                    label: 'Ir al caso',
                                };
                            } else {
                                newStep = {
                                    id: step.id,
                                    name: step.name,
                                    idValue: step.idValue,
                                    message: step.message,
                                    disabled: step.disabled,
                                    condition: step.condition,
                                    type: StepTypes.CREATE_PASSTHROUGH,
                                };
                            }
                            dispatch(updateStep(newStep));
                        }}
                    />
                </div>
            </div>
        );
    };
    if (step.type === StepTypes.CREATE_PASSTHROUGH) {
        return (
            <React.Fragment>
                {renderToggle()}
                <div className={styles.toggleLabelContainer}>
                    Mensaje:
                    <div className={styles.toggleContainer}>
                        <Toggle
                            checked={step.message !== undefined}
                            onChange={(checked: boolean): void => {
                                const message = checked
                                    ? CreateMessage
                                    : undefined;
                                dispatch(
                                    updateStep({
                                        ...step,
                                        message,
                                    })
                                );
                            }}
                        />
                    </div>
                </div>
                {step.message !== undefined && (
                    <div className={styles.container}>
                        <div className={styles.editorContainer}>
                            <PayloadEditor
                                placeholder={'Mensaje'}
                                type={TextEditorTypes.WHATSAPP}
                                idEditor={`Conversation-${apiKey}-${step.id}`}
                                payload={{
                                    draft: step.message,
                                }}
                                onChange={({ draft: message }): void => {
                                    if (!message) return;
                                    dispatch(updateStep({ ...step, message }));
                                }}
                                media={false}
                                context={{
                                    client: true,
                                    ticket: true,
                                }}
                                conditions={{
                                    types: BaseConversationPayloadConditionTypes,
                                    context: {
                                        conversation: {
                                            idStep: step.id,
                                        },
                                    },
                                }}
                                minHeight={144}
                                maxHeight={400}
                                required
                                maxLength={4096}
                            />
                        </div>
                    </div>
                )}
                {step.message === undefined && (
                    <div className={styles.passthroughHelperMessage}>
                        * El caso se creará automaticamente sin informarle al
                        cliente
                    </div>
                )}
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                {renderToggle()}
                <div className={styles.toggleLabelContainer}>
                    Enlace al portal de clientes:
                    <div className={styles.toggleContainer}>
                        <Toggle
                            checked={step.showUrl}
                            onChange={(checked: boolean): void => {
                                let newStep: DefaultCreateStep;
                                if (checked) {
                                    newStep = {
                                        ...step,
                                        showUrl: true,
                                        header: 'Caso Registrado',
                                        footer: 'Para consultarlo, haz clic en el siguiente botón:',
                                        label: 'Ir al caso',
                                    };
                                } else {
                                    newStep = {
                                        ...step,
                                        showUrl: false,
                                    };
                                }
                                dispatch(updateStep(newStep));
                            }}
                        />
                    </div>
                </div>
                {step.showUrl && (
                    <div className={styles.headerContainer}>
                        <RoundedTextField
                            label="Encabezado"
                            multiline
                            maxLength={60}
                            value={step.header}
                            onChange={(e): void => {
                                dispatch(
                                    updateStep({
                                        ...step,
                                        header: e.target.value,
                                    })
                                );
                            }}
                        ></RoundedTextField>
                    </div>
                )}
                <div className={styles.container}>
                    <div className={styles.editorContainer}>
                        <PayloadEditor
                            placeholder={'Mensaje'}
                            type={TextEditorTypes.WHATSAPP}
                            idEditor={`Conversation-${apiKey}-${step.id}`}
                            payload={{
                                draft: step.message,
                            }}
                            onChange={({ draft: message }): void => {
                                if (!message) return;
                                dispatch(updateStep({ ...step, message }));
                            }}
                            media={false}
                            context={{
                                client: true,
                                ticket: true,
                            }}
                            conditions={{
                                types: BaseConversationPayloadConditionTypes,
                                context: {
                                    conversation: {
                                        idStep: step.id,
                                    },
                                },
                            }}
                            // context={{ ticket: true }}
                            minHeight={144}
                            maxHeight={400}
                            required
                            maxLength={4096}
                        />
                    </div>
                </div>
                {step.showUrl && (
                    <div className={styles.footerContainer}>
                        <RoundedTextField
                            label="Pie"
                            multiline
                            maxLength={60}
                            value={step.footer}
                            onChange={(e): void => {
                                dispatch(
                                    updateStep({
                                        ...step,
                                        footer: e.target.value,
                                    })
                                );
                            }}
                        ></RoundedTextField>
                    </div>
                )}

                {step.showUrl && (
                    <div className={styles.btnLblContainer}>
                        <RoundedTextField
                            label="Botón"
                            value={step.label}
                            maxLength={20}
                            onChange={(e): void => {
                                dispatch(
                                    updateStep({
                                        ...step,
                                        label: e.target.value,
                                    })
                                );
                            }}
                        ></RoundedTextField>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default CreateStepComponent;
