import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select, { SelectProps } from '@material-ui/core/Select';
import { FormHelperText } from '@material-ui/core';

interface StyleProps {
    /** The color of the outline when selected and hovered on */
    focusColor?: string;
    /** The color of the outline when it is not selected */
    outlineColor?: string;
    /** The color of the background  */
    backgroundColor?: string;
    /** The color of the error to display */
    errorColor?: string;
    /** If the style should change on hover */
    cantEdit?: boolean;
    /** The hight of the container */
    height?: string;
    /** The color of the text in the form */
    color?: string;
    /** The fontsize of the content */
    fontSize?: string;
    /** The weight of the font of the value and the placeholder */
    fontWeight?: any;
    /** If input is readOnly */
    readOnly?: boolean;
    /** The color of the helper text when not error */
    helperTextColor?: string;
    /** The borderRadius of the input */
    borderRadius?: number;
    /** The padding to the left of the input */
    paddingLeft?: number;
    /** The padding to the right of the input */
    paddingRight?: number;
    /** If custom icon exists and should be displayed */
    showIcon?: boolean;
}
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const useOutlinedInputStyles = (props: StyleProps): Function =>
    makeStyles({
        root: {
            cursor: props.cantEdit ? 'default' : 'default',
            height: props.height,
            borderRadius: props.borderRadius,
            backgroundColor: props.backgroundColor,
            fontSize: props.fontSize,
            '& $notchedOutline': {
                borderColor: props.outlineColor,
                '& legend': {
                    fontSize: 'calc(' + props.fontSize + ' * 0.75)',
                },
            },
            '&:hover $notchedOutline': {
                borderColor: props.cantEdit
                    ? props.outlineColor
                    : props.focusColor,
            },
            '& .Mui-disabled': {
                cursor: 'default',
                color: props.color,
                pointerEvents: 'none',
            },
            '& .Mui-error': {
                color: props.errorColor,
            },
            '&.Mui-error .Erk-MuiOutlinedInput-notchedOutline': {
                borderColor: props.outlineColor,
            },
            '&.Mui-disabled .Erk-MuiOutlinedInput-notchedOutline': {
                borderColor: props.outlineColor,
            },
            '& .Erk-MuiSelect-root': {
                paddingLeft: props.paddingLeft,
                paddingRight: props.paddingRight,
            },
            '&$focused $notchedOutline': {
                borderColor: props.focusColor,
            },
            '& .Erk-MuiSelect-icon': {
                color: props.color?.startsWith('#')
                    ? props.color + '8a'
                    : props.color,
            },
            '& .Erk-MuiSelect-icon.Mui-disabled': {
                color: props.outlineColor,
            },
            '& .Erk-MuiSelect-icon.iconOutlined': {
                right: 8,
            },
            '& .Erk-MuiSelect-iconOpen': {
                transform: props.showIcon ? 'none' : 'rotate(180deg)',
            },
            color: props.color,
        },
        focused: {},
        notchedOutline: {},
    });

const useLabelInputStyles = (props: StyleProps): Function =>
    makeStyles(() => ({
        focused: {
            color: props.focusColor + ' !important',
        },
        root: {
            color: props.color?.startsWith('#')
                ? props.color + '8a'
                : props.color,
            fontWeight: props.fontWeight,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: 'calc(100% - 45px)',
            textOverflow: 'ellipsis',
            marginTop: props.height != '40px' ? '-4px' : '0px',
            '&.Erk-MuiInputLabel-shrink': {
                marginTop: '0px',
                maxWidth: 'calc(100% - 5px)',
            },
            '& .Mui-error': {
                color: props.errorColor,
            },
            '&.Mui-error': {
                color: props.errorColor,
            },
        },
    }));

const useHelperTextStyles = (props: StyleProps): Function =>
    makeStyles(() => ({
        root: {
            color: props.helperTextColor,
            '&.Mui-error': {
                color: props.errorColor,
            },
        },
    }));

export interface RoundedSelectProps
    extends Omit<SelectProps, 'color'>,
        StyleProps {
    /** children to display in the options */
    children?: any;
    /** Currently selected value */
    value: any;
    /** function called when value changes */
    handleUpdate?: (
        event: React.ChangeEvent<{ name?: string | undefined; value: any }>,
        child?: React.ReactNode
    ) => void;
    /** String to place in the label */
    label?: string;
    /** Minimum width in px of the component */
    minWidth?: number;
    /** The color of the outline when selected and hovered on */
    focusColor?: string;
    /** If outline should be error color */
    highlightError?: boolean;
    /** The helper Text to display */
    helperText?: string;
    /** the margin around the selector */
    containerMargin?: string;
    /** The icon to display */
    iconComponent?: any;
    /** the components ref */
    ref?: any;
}

function CustomSelect({
    children,
    value = '',
    handleUpdate,
    label,
    minWidth,
    helperText,
    color = '#293241',
    errorColor = 'var(--error)',
    focusColor = 'var(--secondary)',
    outlineColor = 'var(--outlineGrey)',
    helperTextColor = '#989898',
    backgroundColor = 'var(--primary1)',
    readOnly = false,
    borderRadius = 10,
    paddingLeft = 14,
    paddingRight = 32,
    cantEdit = false,
    showIcon = true,
    containerMargin = '8px',
    height = '40px',
    fontSize = '1rem',
    required,
    iconComponent,
    error,
    ...others
}: RoundedSelectProps): JSX.Element {
    const styleProps: StyleProps = {
        color,
        height,
        readOnly,
        fontSize,
        cantEdit,
        errorColor,
        focusColor,
        paddingLeft,
        paddingRight,
        outlineColor,
        borderRadius,
        helperTextColor,
        backgroundColor,
        showIcon: showIcon && iconComponent !== undefined,
    };
    const classes = useStyles();
    const outlinedInputClasses = useOutlinedInputStyles(styleProps)();
    const labelClasses = useLabelInputStyles(styleProps)();
    const helperTextClasses = useHelperTextStyles(styleProps)();
    const [open, setOpen] = useState(false);
    return (
        <FormControl
            variant="outlined"
            className={classes.formControl}
            size="small"
            style={
                minWidth !== undefined
                    ? { minWidth, outlineColor, margin: containerMargin }
                    : {
                          margin: containerMargin,
                      }
            }
            fullWidth
            required={required}
            error={error}
        >
            {label && <InputLabel classes={labelClasses}>{label}</InputLabel>}
            <Select
                {...others}
                value={value}
                MenuProps={{}}
                onChange={handleUpdate}
                {...(showIcon && iconComponent
                    ? { IconComponent: iconComponent }
                    : {})}
                open={open}
                onFocus={(e): void => {
                    if (!e.relatedTarget?.closest('.Erk-MuiPaper-root')) {
                        setOpen(true);
                    }
                }}
                onBlur={(): void => {
                    setOpen(false);
                }}
                onClose={(): void => {
                    setOpen(false);
                }}
                onOpen={(): void => {
                    setOpen(true);
                }}
                input={
                    <OutlinedInput
                        disabled={cantEdit}
                        name={label}
                        label={
                            label ? label + (required ? ' *' : '') : undefined
                        }
                        classes={outlinedInputClasses}
                    />
                }
            >
                {children}
            </Select>
            {helperText !== undefined && (
                <FormHelperText classes={helperTextClasses}>
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
}

/**
 * Generic textfield with apps designs. Is class do to the use in the react-hook-forms library
 */
class RoundedSelect extends React.Component<RoundedSelectProps> {
    render(): JSX.Element {
        return <CustomSelect {...this.props} />;
    }
}
export default RoundedSelect;
