import { nanoid } from '@reduxjs/toolkit';
import { Classifier } from '../../../@Types/@Types';
import {
    FormClassifierSelectorOption,
    FormStep,
} from '../../../@Types/FormTypes/FormStep';
import Types, { FormStepTypes } from '../../../constants/FormStepTypes';
import { ClassifierOptionTypes } from '../../../constants/OptionTypes';

export function generateNestedOptions(
    classifier: Classifier,
    classifiers: Record<string, Classifier>,
    steps: Record<string, FormStep>
): Record<string, FormClassifierSelectorOption> {
    const options: Record<string, FormClassifierSelectorOption> = {};
    const subclassifiers = classifier.children.map(
        (child) => classifiers[child]
    );
    for (const subClass of subclassifiers) {
        const subSubClass = subClass.children.map(
            (child) => classifiers[child]
        );
        if (subClass.active && subSubClass.length > 0) {
            const newId = Types.CLASSIFIER_SELECTOR + '-' + nanoid();
            options[subClass._id] = {
                idClassifier: subClass._id,
                type: ClassifierOptionTypes.NESTED,
                steps: [newId],
            };
            steps[newId] = {
                id: newId,
                type: Types.CLASSIFIER_SELECTOR,
                idClassifier: subClass._id,
                label: '',
                description: '',
                options: generateNestedOptions(subClass, classifiers, steps),
                required: false,
                isSubject: false,
                searchable: false,
                size: 1,
                maxSize: 1,
            };
        }
    }
    return options;
}

export function calcFormStepLabel(step: FormStep): string {
    if (!step) return '';
    switch (step.type) {
        case FormStepTypes.SEPARATOR:
            return '';
        case FormStepTypes.TITLE:
            return step.title;
        default:
            return step.label;
    }
}
