import styles from './NotificationListItem.module.css';
import { JSX, useState } from 'react';
import ActionNotification from './ActionNotification/ActionNotification';
import { MenuGroupedNotification } from '../../../../../@Types/GroupedMenuNotification';

export interface NotificationListItemProps {
    /** The notification to display */
    element?: MenuGroupedNotification;
    forToast?: boolean;
}

export default function NotificationListItem({
    element,
    forToast,
}: NotificationListItemProps): JSX.Element {
    if (!element) return <></>;
    let [openDropdown, setOpenDropdown] = [
        false,
        (s: boolean): void => {
            openDropdown = s;
        },
    ];
    if (!forToast) {
        [openDropdown, setOpenDropdown] = useState<boolean>(false);
    }
    const nNotification = element.subs.length;
    return (
        <div
            className={styles.fullContainer}
            style={{
                paddingTop: forToast ? 0 : 18,
                margin: forToast ? 0 : '0px 5px 0px 10px',
            }}
        >
            {!forToast && element.unread > 0 && (
                <div className={styles.notificationIcon}>
                    {nNotification > 1
                        ? element.unread < 100
                            ? element.unread
                            : '99+'
                        : element.subs.length > 1
                        ? 1
                        : '!'}
                </div>
            )}
            <div
                className={styles.container}
                id={'notification-' + element._id}
            >
                {(element.type === 'ACTION' || forToast) && (
                    <ActionNotification
                        element={element}
                        forToast={forToast}
                        setOpenDropdown={setOpenDropdown}
                        openDropdown={openDropdown}
                    />
                )}
            </div>
            {nNotification > 1 && !openDropdown && (
                <div className={styles.cascadeContainer}>
                    {cascadeRender(3)}
                </div>
            )}
        </div>
    );
}

function cascadeRender(n: number): JSX.Element {
    const cascade = [];
    n = n > 3 ? 3 : n;
    for (let i = 0; i < n; i++) {
        cascade.push(<div key={i} className={styles.box} />);
    }
    return <>{cascade}</>;
}
