import React, { useState } from 'react';
import styles from './CreateAgent.module.css';
import RoundedTextField from '../../../shared/RoundedTextField/RoundedTextField';
import RoundedButton from '../../../shared/RoundedButton/RoundedButton';
import { Controller, useForm } from 'react-hook-form';
import agentService from '../../../controllers/AgentsController/AgentsService';
import Toggle from '../../../shared/Toggle/Toggle';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import {
    checkOrgPerm,
    checkProjPerm,
} from '../../../utils/PermissionsFunctions';
import {
    EntityPermissionsList,
    OrganizationPermissions,
    OrganizationPermissionsList,
    OrganizationWithoutCompaniesPermissionsList,
    ProjectPermissionsList,
} from '../../../constants/Permissions';
import { AgentPermissions } from '../../../@Types/Agent';
import ProjectPermissions from '../AgentDetail/ProjectPermissions/ProjectPermissions';
import { ProjectPermissions as ProjectPermissionTypes } from '../../../constants/Permissions';

interface CreateAgentProps {
    /** Function called when the agent has been created */
    handleAgentCreated: Function;
    /** Function called to close the Dialog */
    onClose?: Function;
    /** Function called to update the Dialog's loader */
    setLoading?: Function;
    /** If the container is loading */
    loading?: boolean;
}
function CreateAgent({
    handleAgentCreated,
    onClose,
    loading,
    setLoading,
}: CreateAgentProps): JSX.Element {
    const siteInfo = useSelector((state: RootState) => state.site);
    const [currentPermissions, setCurrentPermissions] =
        useState<AgentPermissions>({
            organization: {},
            projects: {},
            entities: {},
        });

    const [all, setAll] = useState(false);
    const {
        getValues,
        control,
        formState: { errors, isDirty, isValid },
    } = useForm({
        mode: 'onChange',
    });

    const handleSubmit = async (): Promise<void> => {
        if (setLoading && onClose) {
            try {
                setLoading(true);
                await agentService.createAgent({
                    ...getValues(),
                    permissions: currentPermissions,
                });
                handleAgentCreated();
                onClose();
            } catch (error) {
                //TODO handle errors
                console.error(error);
                setLoading(false);
            }
        }
    };

    const handleTogglePerms = (): void => {
        const perms: AgentPermissions = {
            organization: {},
            projects: {},
            entities: {},
        };
        const orgPerm = checkOrgPerm(
            siteInfo.user,
            siteInfo.organization,
            OrganizationPermissions.PERMISSIONS
        );
        //If has org permissions activate all org permissions
        if (orgPerm) {
            const permList = siteInfo.organization?.idCompanyEntity
                ? OrganizationPermissionsList
                : OrganizationWithoutCompaniesPermissionsList;
            for (const perm of permList) {
                perms.organization[perm.key] = !all;
            }
        }
        //Get the project the user has permissions of
        const projects = Object.values(siteInfo.projects).filter(
            (project) =>
                checkOrgPerm(
                    siteInfo.user,
                    siteInfo.organization,
                    OrganizationPermissions.PERMISSIONS
                ) ||
                siteInfo.user?.permissions.projects?.[project._id]
                    ?.PERMISSIONS === true
        );
        //Toggle the projects permissions
        for (const project of projects) {
            const projPerms: Record<string, boolean> = {};
            for (const perm of ProjectPermissionsList) {
                projPerms[perm.key] = !all;
            }
            perms.projects[project._id] = projPerms;
        }

        const entities = Object.values(siteInfo.entities).filter(
            (entity) =>
                entity._id !== siteInfo.organization?.idClientEntity &&
                entity._id !== siteInfo.organization?.idCompanyEntity
        );

        for (const entity of entities) {
            const entityPerms: Record<string, boolean> = {};
            for (const perm of EntityPermissionsList(entity)) {
                entityPerms[perm.key] = !all;
            }
            perms.entities[entity._id] = entityPerms;
        }

        setCurrentPermissions(perms);
        setAll(!all);
    };

    const renderEntityPermissions = (): JSX.Element[] => {
        const entities = Object.values(siteInfo.entities).filter(
            (entity) =>
                entity._id !== siteInfo.organization?.idClientEntity &&
                entity._id !== siteInfo.organization?.idCompanyEntity
        );

        return entities.map((entity) => (
            <React.Fragment>
                <label className={styles.orgLblName}>
                    {entity.pluralName}:
                </label>
                <div className={styles.orgPermissionsList}>
                    {EntityPermissionsList(entity).map((permission, index) => (
                        <div className={styles.orgPermission} key={index}>
                            <div
                                className={
                                    styles.orgPermissionLbl + ' noselect'
                                }
                            >
                                {permission.label}
                            </div>
                            <div className={styles.toggleContainer}>
                                <Toggle
                                    size="small"
                                    checked={
                                        currentPermissions.entities?.[
                                            entity._id
                                        ]?.[permission.key] === true
                                    }
                                    onChange={(checked: boolean): void => {
                                        const newPermissions = {
                                            ...currentPermissions,
                                            entities: {
                                                ...currentPermissions.entities,
                                                [entity._id]: {
                                                    ...(currentPermissions
                                                        .entities?.[
                                                        entity._id
                                                    ] ?? {}),
                                                    [permission.key]: checked,
                                                },
                                            },
                                        };
                                        setCurrentPermissions(newPermissions);
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        ));
    };

    return (
        <div
            className={styles.container + ' standard-dialog'}
            data-testid={'CreateAgent'}
        >
            <h2 className="center-anything">Invitar agente</h2>
            <div className="text-field-container" data-testid={'name'}>
                <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: 'El nombre es obligatorio',
                    }}
                    render={({ field }): JSX.Element => (
                        <RoundedTextField
                            {...field}
                            label="Nombre"
                            helperText={errors.name ? errors.name.message : ''}
                            error={!!errors.name}
                        />
                    )}
                />
            </div>
            <div className="text-field-container" data-testid={'email'}>
                <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                        pattern: {
                            // eslint-disable-next-line no-useless-escape
                            value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: 'El correo no es válido',
                        },
                    }}
                    render={({ field }): JSX.Element => (
                        <RoundedTextField
                            {...field}
                            label="Correo"
                            helperText={
                                errors.email ? errors.email.message : ''
                            }
                            error={!!errors.email}
                        />
                    )}
                />
            </div>
            <div
                className="text-field-container"
                style={{ marginBottom: 0 }}
                data-testid={'jobTitle'}
            >
                <Controller
                    name="jobTitle"
                    control={control}
                    defaultValue=""
                    render={({ field }): JSX.Element => (
                        <RoundedTextField
                            {...field}
                            label="Cargo"
                            helperText={
                                errors.jobTitle ? errors.jobTitle.message : ''
                            }
                            error={!!errors.jobTitle}
                        />
                    )}
                />
            </div>

            <div className={styles.permissionsContainer}>
                <div className={styles.permssionsToggleContainer}>
                    <label className={styles.permissionsLbl}>Permisos</label>
                    <RoundedButton
                        padding="4px 10px"
                        text={all ? 'Desactivar Todos' : 'Activar Todos'}
                        backgroundColor={
                            all ? 'var(--greyBtn)' : 'var(--secondary2)'
                        }
                        color={'var(--accent)'}
                        onClick={(): void => {
                            handleTogglePerms();
                        }}
                    />
                </div>

                <div className={styles.subtitlesContainer}>
                    {checkOrgPerm(
                        siteInfo.user,
                        siteInfo.organization,
                        OrganizationPermissions.PERMISSIONS
                    ) && (
                        <div className={styles.permissionListContainer}>
                            <label className={styles.orgLblName}>
                                Organizacionales:
                            </label>
                            <div className={styles.orgPermissionsList}>
                                {(siteInfo.organization?.idCompanyEntity
                                    ? OrganizationPermissionsList
                                    : OrganizationWithoutCompaniesPermissionsList
                                ).map((permission, index) => (
                                    <div
                                        className={styles.orgPermission}
                                        key={index}
                                    >
                                        <div
                                            className={
                                                styles.orgPermissionLbl +
                                                ' noselect'
                                            }
                                        >
                                            {permission.label}
                                        </div>
                                        <div className={styles.toggleContainer}>
                                            <Toggle
                                                size="small"
                                                checked={
                                                    currentPermissions
                                                        .organization?.[
                                                        permission.key
                                                    ] === true
                                                }
                                                onChange={(
                                                    checked: boolean
                                                ): void => {
                                                    const newPermissions = {
                                                        ...currentPermissions,
                                                        organization: {
                                                            ...currentPermissions.organization,
                                                            [permission.key]:
                                                                checked,
                                                        },
                                                    };
                                                    setCurrentPermissions(
                                                        newPermissions
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {renderEntityPermissions()}
                        </div>
                    )}
                    <div className={styles.projectPermsContainer}>
                        {Object.values(siteInfo.projects)
                            .filter(
                                (project) =>
                                    checkOrgPerm(
                                        siteInfo.user,
                                        siteInfo.organization,
                                        OrganizationPermissions.PERMISSIONS
                                    ) ||
                                    checkProjPerm(
                                        siteInfo.user,
                                        project._id,
                                        ProjectPermissionTypes.PERMISSIONS
                                    )
                            )
                            .sort(
                                (a, b) =>
                                    a.creation_date.getTime() -
                                    b.creation_date.getTime()
                            )
                            .map((project, index) => (
                                <ProjectPermissions
                                    key={index}
                                    index={index}
                                    project={project}
                                    permissions={
                                        currentPermissions.projects?.[
                                            project._id
                                        ] ?? {}
                                    }
                                    handleChange={(permissions): void => {
                                        const newPermissions = {
                                            ...currentPermissions,
                                            projects: {
                                                ...currentPermissions.projects,
                                                [project._id]: permissions,
                                            },
                                        };
                                        setCurrentPermissions(newPermissions);
                                    }}
                                />
                            ))}
                    </div>
                </div>
            </div>

            <div className="center-anything" style={{ marginBottom: 10 }}>
                <div className="button">
                    <RoundedButton
                        text="Cancelar"
                        onClick={(): void => {
                            onClose?.();
                        }}
                    />
                </div>
                <div className="button" data-testid={'submitButton'}>
                    <RoundedButton
                        text="Crear agente"
                        backgroundColor="var(--secondary)"
                        color="white"
                        disabled={
                            Object.keys(errors).length > 1 ||
                            !isDirty ||
                            !isValid ||
                            loading
                        }
                        onClick={handleSubmit}
                    />
                </div>
            </div>
        </div>
    );
}

export default CreateAgent;
