import React, { useEffect } from 'react';
import styles from './QueriesDialog.module.css';
import { useState } from 'react';
import { MenuItem } from '@material-ui/core';
import DashboardService from '../../../../controllers/DashboardController/DashboardService';
import RoundedSelect from '../../../../shared/RoundedSelect/RoundedSelect';
import Dialog from '../../../../shared/Dialog/Dialog';
interface QueriesDialogProps {
    /** The id of the company to query */
    idCliente: string;
    /** The company name to display */
    name: string;
    /** Function called to close the dialog */
    onClose: () => void;
    /** The company business model to display */
    negocio: string;
}
/**
 * Component rendered inside the create area dialog.
 */
function QueriesDialog({
    idCliente,
    name,
    onClose,
    negocio,
}: QueriesDialogProps): JSX.Element {
    const [query, setQuery] = useState('Estado del cliente');
    const [queryLink, setQueryLink] = React.useState('');
    const loadQuery = async (query: string): Promise<void> => {
        setQueryLink(
            await DashboardService.fetchCardByName(query, {
                ClienteId: idCliente,
            })
        );
    };
    useEffect(() => {
        loadQuery('Estado del cliente');
    }, []);
    const menuItems = [
        'Estado del cliente',
        'Páginas visitadas',
        'Control BD',
        'Tarifas',
        'Arriendos',
        'Bases de datos',
        'Contratos',
        'Hosting',
        'PMA',
        'PSF',
        'Propuestas',
        'BD Instaladas',
        'Usuarios',
        'Cobros POS',
        'Saldos de cartera',
        'Boletines',
        'Contactos',
        'Desarrollos',
        'Gestión',
        'Módulos',
        'SubMódulos',
    ];
    menuItems.sort();
    return (
        <Dialog maxWidth="100%" onClose={onClose}>
            <div className={styles.container}>
                <div className={styles.containerLbl}>Información de {name}</div>
                <div className={styles.selector}>
                    <RoundedSelect
                        minWidth={240}
                        value={query}
                        label="Consulta"
                        handleUpdate={(event): void => {
                            setQuery(event.target.value);
                            loadQuery(event.target.value);
                        }}
                    >
                        {menuItems.map((item) => {
                            if (item === 'Arriendos' && negocio !== 'Arriendo')
                                return null;
                            if (item === 'Hosting' && negocio === 'Arriendo')
                                return null;
                            return <MenuItem value={item}>{item}</MenuItem>;
                        })}
                    </RoundedSelect>
                </div>
                {queryLink !== '' && (
                    <iframe
                        src={queryLink}
                        width="100%"
                        height="100%"
                        frameBorder="0"
                    ></iframe>
                )}
            </div>
        </Dialog>
    );
}
export default QueriesDialog;
