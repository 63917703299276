import { StepComponentProps } from '../../Step';
import styles from './InfoTextStep.module.css';
import React from 'react';
import StepContainer from '../../StepContainer/StepContainer';
import { InfoTextStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import DraftRenderer from '../../../../../../shared/DraftRenderer/DraftRenderer';
import { EditorState, convertFromRaw } from 'draft-js';

interface InfoTextStepProps extends StepComponentProps {
    step: InfoTextStep;
}

function InfoTextStepComponent({ step, path }: InfoTextStepProps): JSX.Element {
    return (
        <StepContainer step={step} path={path}>
            <div className={styles.messageContainer}>
                <DraftRenderer
                    editorState={EditorState.createWithContent(
                        convertFromRaw(step.message)
                    )}
                />
            </div>
        </StepContainer>
    );
}

export default InfoTextStepComponent;
