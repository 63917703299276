import styles from './CompanyClientComponent.module.css';
import Avatar from '../../../../shared/Avatar/Avatar';
import { CompanyClient } from '../../../../@Types/Company';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../utils/_store';
import Toggle from '../../../../shared/Toggle/Toggle';
import RoundedButton from '../../../../shared/RoundedButton/RoundedButton';
import { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import React from 'react';
import { updateCompanyClientPermissions } from '../../../../controllers/CompaniesController/CompaniesService';
import ClientDetail from '../CompanyClientDetail/CompanyClienteDetail';
import Dialog from '../../../../shared/Dialog/Dialog';
import { Client } from '../../../../@Types/Client';
interface CompanyClientProps {
    /** The ClientInfo to display */
    client: CompanyClient | Client;
    /** The id of the current Company */
    idCompany: string;
    handleUpdate: Function;
    handleDelete?: Function;
}
function CompanyClientComponent({
    client,
    idCompany,
    handleUpdate,
    handleDelete,
}: CompanyClientProps): JSX.Element {
    const companyEntity = useSelector(
        (state: RootState) =>
            state.site.entities[state.site.organization?.idCompanyEntity ?? '']
    );
    const [showPermissions, setShowPermissions] = useState(false);
    const [showDetail, setShowDetail] = useState(false);

    const calcName = (): string | undefined => {
        const name = client.lastName
            ? client.name + ' ' + client.lastName
            : client.name;
        return name?.trim() ? name : undefined;
    };

    const clientCompany = client.companies.find(
        (company) => company.idCompany === idCompany
    );
    const nPermissions = Object.values(clientCompany?.permissions ?? {}).filter(
        (p) => p === true
    ).length;
    return (
        <React.Fragment>
            {showDetail && (
                <Dialog
                    open={showDetail}
                    maxWidth={'90vw'}
                    onClose={(): void => {
                        setShowDetail(false);
                    }}
                >
                    <ClientDetail client={client} />
                </Dialog>
            )}
            <div className={styles.container}>
                <div className={styles.cardContainer}>
                    <div className={styles.sideBar}></div>
                    <div className={styles.info}>
                        <h4 className={styles.title}>
                            {calcName() ?? '(Sin nombre)'}
                        </h4>
                        {client.email && (
                            <label className={styles.infoLbl}>
                                {client.email}
                            </label>
                        )}
                        {client.cel && (
                            <label className={styles.infoLbl}>
                                {client.cel}
                            </label>
                        )}

                        {(client as CompanyClient).ticketNum && (
                            <div className={styles.bottomContainer}>
                                <label className={styles.caseLbl}>
                                    Casos Registrados:{' '}
                                    {(client as CompanyClient).ticketNum}
                                </label>
                                <div className={styles.viewMoreBtn}>
                                    <RoundedButton
                                        text="Ver más"
                                        backgroundColor={'var(--secondary)'}
                                        color={'white'}
                                        onClick={(): void => {
                                            setShowDetail(true);
                                        }}
                                        padding="0px 10px"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flexbox">
                        <div className={styles.photo}>
                            <Avatar
                                lbl={calcName() ?? '?'}
                                size={50}
                                fontSize={30}
                            ></Avatar>
                        </div>
                    </div>
                    {handleDelete && (
                        <div
                            className={styles.removeBtn}
                            onClick={(): void => {
                                handleDelete();
                            }}
                        >
                            <ClearRoundedIcon fontSize="inherit" />
                        </div>
                    )}
                </div>
                {showPermissions && companyEntity.permissions && (
                    <div className={styles.permissionsContainer}>
                        {companyEntity.permissions.map((permission, index) => (
                            <div
                                className={styles.permission + ' flexbox'}
                                key={index}
                            >
                                <div
                                    className={
                                        styles.permissionLbl + ' noselect'
                                    }
                                >
                                    {permission.name}
                                </div>
                                <div className={styles.toggleContainer}>
                                    <Toggle
                                        size="small"
                                        checked={
                                            clientCompany?.permissions[
                                                permission.id
                                            ] === true
                                        }
                                        onChange={(checked: boolean): void => {
                                            const tempClient = { ...client };
                                            const companies = [
                                                ...tempClient.companies,
                                            ];
                                            for (const company of companies) {
                                                if (
                                                    company.idCompany ===
                                                    idCompany
                                                ) {
                                                    company.permissions[
                                                        permission.id
                                                    ] = checked;
                                                }
                                            }
                                            handleUpdate(tempClient);
                                            updateCompanyClientPermissions(
                                                idCompany,
                                                client._id,
                                                {
                                                    ...clientCompany?.permissions,
                                                    [permission.id]: checked,
                                                }
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {companyEntity.permissions &&
                    companyEntity.permissions.length > 0 && (
                        <div className={styles.permissionsTabContainer}>
                            <div
                                className={styles.permissionsTab}
                                onClick={(): void => {
                                    setShowPermissions(!showPermissions);
                                }}
                            >
                                Permisos
                                <div className={styles.countLbl}>
                                    {nPermissions}
                                </div>
                                <div className={styles.permissionsIcon}>
                                    {showPermissions ? (
                                        <ExpandLessIcon fontSize="inherit" />
                                    ) : (
                                        <ExpandMoreIcon fontSize="inherit" />
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        </React.Fragment>
    );
}

export default CompanyClientComponent;
