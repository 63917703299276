export enum IconTypes {
    PROJECT = 'PROJECT',
    PROPERTY = 'PROPERTY',
    SPACE = 'SPACE',
    LEAK = 'LEAK',
    PERSON = 'PERSON',
    DOCUMENT = 'DOCUMENT',
    HANDSHAKE = 'HANDSHAKE',
    CHECKLIST = 'CHECKLIST',
    SUPPLIER = 'SUPPLIER',
    GENERIC = 'GENERIC',
    LOCATION = 'LOCATION',
    CALENDAR = 'CALENDAR',
}
export default IconTypes;
