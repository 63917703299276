export enum ClientInfoTypes {
    NAME = 'name',
    LASTNAME = 'lastName',
    IDENTIFIER = 'identifier',
    IDENTIFIER_TYPE = 'identifierType',
    CEL = 'cel',
    EMAIL = 'email',
}

export const ClientInfoTypeLabels = {
    [ClientInfoTypes.NAME]: 'Nombre(s)',
    [ClientInfoTypes.LASTNAME]: 'Apellido(s)',
    [ClientInfoTypes.IDENTIFIER]: 'Identificación',
    [ClientInfoTypes.IDENTIFIER_TYPE]: 'Tipo de Identificación',
    [ClientInfoTypes.CEL]: 'Celular',
    [ClientInfoTypes.EMAIL]: 'Correo',
};

export default ClientInfoTypes;
