import { InmuebleInfo360 } from '../../../types';
import { TramitesTable } from './TramitesTable/TramitesTable';
import styles from './Tramites.module.css';

interface TramitesProps {
    tramites: InmuebleInfo360['tramites'];
}

export function Tramites({ tramites }: TramitesProps): JSX.Element {
    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <TramitesTable tramites={tramites} />
            </div>
        </div>
    );
}
