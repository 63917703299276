import { nanoid } from 'nanoid';
import produce from 'immer';
import { RawDraftContentState } from 'draft-js';
import { EurekaDraft } from '../@Types/Draft/Draft';
export function stringToDraft(text: string): EurekaDraft {
    let draftStructure = {
        blocks: [] as any[],
        entityMap: {},
    };
    text.split('\n').forEach((element, index) => {
        draftStructure.blocks.push({
            key: String(index),
            text: element,
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        });
    });
    return draftStructure as EurekaDraft;
}

export const getRawText = (draft?: EurekaDraft, text?: string): EurekaDraft => {
    if (draft?.blocks !== undefined) {
        return draft;
    } else if (text !== undefined) {
        return stringToDraft(text);
    } else {
        return {
            blocks: [
                {
                    key: 'fudmo',
                    text: '',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                },
            ],
            entityMap: {},
        };
    }
};

/**
 * Trims empty blocks around an image if it is sent in the beggining or at the end
 */
export const draftRawTrim = (
    original: EurekaDraft | RawDraftContentState
): EurekaDraft => {
    return produce(original, (draft) => {
        if (
            draft.blocks.length > 2 &&
            draft.blocks[1].type === 'atomic' &&
            draft.blocks[1].text === ' ' &&
            draft.blocks[1].entityRanges.length > 0 &&
            draft.blocks[0].type === 'unstyled' &&
            draft.blocks[0].text === ''
        ) {
            draft.blocks.splice(0, 1);
        }
        const newLength = draft.blocks.length;
        if (
            newLength > 1 &&
            draft.blocks[newLength - 2].type === 'atomic' &&
            draft.blocks[newLength - 2].text === ' ' &&
            draft.blocks[newLength - 2].entityRanges.length > 0 &&
            draft.blocks[newLength - 1].type === 'unstyled' &&
            draft.blocks[newLength - 1].text === ''
        ) {
            draft.blocks.splice(newLength - 1, 1);
        }
    }) as EurekaDraft;
};

export const invertDraftRawTrim = (
    original: EurekaDraft | RawDraftContentState
): EurekaDraft => {
    return produce(original, (draft) => {
        if (draft.blocks[0].type === 'atomic' && draft.blocks[0].text === ' ') {
            draft.blocks.splice(0, 0, {
                key: nanoid(),
                text: '',
                type: 'unstyled',
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [],
                data: {},
            });
        }
        if (
            draft.blocks[draft.blocks.length - 1].type === 'atomic' &&
            draft.blocks[draft.blocks.length - 1].text === ' '
        ) {
            draft.blocks.push({
                key: nanoid(),
                text: '',
                type: 'unstyled',
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [],
                data: {},
            });
        }
    }) as EurekaDraft;
};

export const calcImageEntityData = async (
    localSrc: string
): Promise<{ ratio: number; width: any; height: any; imageKey: string }> => {
    const imageKey = nanoid();
    let { width, height } = await loadImage(localSrc);
    const ratio = width / height;
    if (ratio > 1 && width > 330) {
        width = 330;
    } else if (ratio <= 1 && height > 300) {
        width = 300 * ratio;
    }
    return {
        ratio,
        height,
        width,
        imageKey,
    };
};

export const loadImage = (src: string): any =>
    new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = (): any => resolve(img);
        img.onerror = reject;
        img.src = src;
    });
