import { createAction } from '@reduxjs/toolkit';
import { Entry } from '../../@Types/@Types';
import { Conversation } from '../../@Types/ConversationTypes/Conversation';
import { ConversationStep } from '../../@Types/ConversationTypes/ConversationStep';
import { StepLocation } from './ConversationEditorReducer';
import StepTypes from '../../constants/Conversations/ConversationStepTypes';

export const Types = {
    RESET: 'CONVERSATION/RESET',
    LOADING: 'CONVERSATION/LOADING',
    GET_SUCCESS: 'CONVERSATION/GET_SUCCESS',
    PREVIEW: 'CONVERSATION/PREVIEW',
    ADD_STEP: 'CONVERSATION/ADD_STEP',
    SELECT: 'CONVERSATION/SELECT_STEP',
    UPDATE_STEP: 'CONVERSATION/UPDATE_STEP',
    UPDATE: 'CONVERSATION/UPDATE',
    UPDATE_STEP_FILE: 'CONVERSATION/UPDATE_STEP_FILE',
    UPDATE_STEPS: 'CONVERSATION/UPDATE_STEPS',
    UPDATE_INFO_TYPE: 'CONVERSATION/UPDATE_INFO_TYPE',
    CUT_STEP: 'CONVERSATION/CUT_STEP',
    COPY_STEP: 'CONVERSATION/COPY_STEP',
    DELETE_STEP: 'CONVERSATION/DELETE_STEP',
    PASTE_STEP: 'CONVERSATION/PASTE_STEP',
    MOVE_STEP: 'CONVERSATION/MOVE_STEP',
    SET_IMG_MAX_WIDTH: 'CONVERSATION/SET_IMG_MAX_WIDTH',
    UPDATE_STEP_ID: 'CONVERSATION/UPDATE_STEP_ID',
};

/**
 * Called when the forms page is first loaded
 */
export const reset = createAction<string>(Types.RESET);

/**
 * Called to toggle the loader
 */
export const setLoading = createAction(
    Types.LOADING,
    function prepare(loading: boolean = true) {
        return {
            payload: loading,
        };
    }
);

export const getSuccess = createAction<{
    conversation: Conversation;
    entry: Entry;
}>(Types.GET_SUCCESS);

/**
 * Called to toggle the preview
 */
export const setShowPreview = createAction(
    Types.PREVIEW,
    function prepare(preview: boolean = true) {
        return {
            payload: preview,
        };
    }
);

/**
 * Called when a step is selected
 */
export const selectStep = createAction<{
    id: string;
    path: StepLocation[] | null;
}>(Types.SELECT);

/**
 * Called when a new step is added to the current section
 */
export const updateStep = createAction<ConversationStep>(Types.UPDATE_STEP);

/**
 * Called when the file of the step changes
 */
export const updateStepFile = createAction<{
    idStep: string;
    file: any;
    dimensions: { width: number; ratio: number } | null;
}>(Types.UPDATE_STEP_FILE);

/**
 * Called when a new step is added to the current section
 */
export const updateSteps = createAction<Record<string, ConversationStep>>(
    Types.UPDATE_STEPS
);

/**
 * Called when the type of the selectedStep changes
 */
export const updateInfoStepType = createAction<StepTypes>(
    Types.UPDATE_INFO_TYPE
);

/**
 * Called when a new step is added to the current section
 */
export const addStep = createAction<{
    step: ConversationStep;
    steps?: ConversationStep[];
    location?: StepLocation;
}>(Types.ADD_STEP);

export const updateConv = createAction<{
    step?: ConversationStep;
    addSteps?: ConversationStep[];
    deleteSteps?: string[];
}>(Types.UPDATE);

/**
 * Called when the user wants to cut a step
 */
export const cutStep = createAction<string>(Types.CUT_STEP);

/**
 * Called when the user wants to copy a step to paste somewhere else
 */
export const copyStep = createAction<string>(Types.COPY_STEP);

/**
 * Called when the user wants to delete a step
 */
export const deleteStep = createAction<string>(Types.DELETE_STEP);

/**
 * Called when the user wants to paste a step, parentStepId is the id if the step's parent, null if is current section
 */
export const pasteStep = createAction<StepLocation>(Types.PASTE_STEP);

/**
 * Called when the user wants to paste a step, parentStepId is the id if the step's parent, null if is current section
 */
export const moveStep = createAction<{
    idStep: string;
    location: StepLocation;
}>(Types.MOVE_STEP);
/**
 * Called when the user wants to change the id of a step
 */
export const updateStepId = createAction<{
    idStep: string;
    newId: string;
}>(Types.UPDATE_STEP_ID);

/**
 * Called to set the current img Max width
 */
export const setImgMaxWidth = createAction<number>(Types.SET_IMG_MAX_WIDTH);
