import React from 'react';
import styles from './ClientInfo.module.css';
import Search from '../../../../shared/Search/Search';
import Loader from '../../../../shared/Loader/Loader';
import { DatosPersonalesInfo } from './DatosPersonales/DatosPersonalesInfo';
import { Educacion } from './Educacion/Educacion';
import { Referencias } from './Referencias/Referencias';
import { Inmuebles } from './Inmuebles/Inmuebles';
import { BaseInfo } from './BaseInfo/BaseInfo';
import { ClienteInfo360, ControlInfo360 } from '../../types';
import Rating from '../../../../shared/Rating/Rating';
import { RatingTypes } from '../../../../constants/FormStepTypes';

interface ClientInfoProps {
    identificacion: string;
    setIdentificacion: (identification: string) => void;
    idAgrupacion: number | undefined;
    setIdAgrupacion: (idAgrupacion: number | undefined) => void;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    setLoadingInmueble: (loading: boolean) => void;
    setLoadingTickets: (loading: boolean) => void;
    clientInfo: ClienteInfo360 | undefined;
    controlInfo: ControlInfo360 | undefined;
    loadingTickets: boolean;
}
export function ClientInfo({
    identificacion,
    setIdentificacion,
    idAgrupacion,
    setIdAgrupacion,
    loading,
    setLoading,
    setLoadingInmueble,
    setLoadingTickets,
    clientInfo,
    controlInfo,
    loadingTickets,
}: ClientInfoProps): JSX.Element {
    return (
        <div className={styles.container}>
            <div className={styles.divider}>
                <div className={styles.searchBar}>
                    <Search
                        handleLoadingSearch={(loading: boolean): void => {
                            setLoading(loading);
                            setLoadingInmueble(loading);
                            setLoadingTickets(loading);
                        }}
                        handleSearch={(search: string): void => {
                            setIdentificacion(search);
                            setIdAgrupacion(undefined);
                        }}
                        search={identificacion}
                    />
                </div>
            </div>
            {loading ? (
                <div className={styles.loader}>
                    <Loader position={'relative'} />
                </div>
            ) : !clientInfo ? (
                <>
                    {identificacion ? (
                        <div className={styles.noResults}>
                            No se encuentran clientes
                        </div>
                    ) : (
                        <div className={styles.noResults}>
                            Ingrese una identificación
                        </div>
                    )}
                </>
            ) : (
                <div className={styles.infoContainer}>
                    <div className={styles.divider}>
                        <BaseInfo clientInfo={clientInfo} />
                    </div>
                    <div className={styles.divider}>
                        <Inmuebles
                            inmuebles={clientInfo.inmuebles}
                            idAgrupacion={idAgrupacion}
                            setIdAgrupacion={setIdAgrupacion}
                        />
                    </div>
                    <div className={styles.divider}>
                        <DatosPersonalesInfo datosCliente={clientInfo} />
                    </div>
                    <div className={styles.divider}>
                        <Educacion educacion={clientInfo.educacion} />
                    </div>
                    <div className={styles.divider}>
                        <Referencias referencias={clientInfo.referencias} />
                    </div>
                    <div className={styles.divider}>
                        <div className={styles.ratingTitle}>Calificación</div>
                        {loadingTickets ? (
                            <div className={styles.loaderRating}>
                                <Loader position={'relative'} />
                            </div>
                        ) : (
                            <>
                                {controlInfo?.rating === undefined ? (
                                    <div className={styles.noRating}>
                                        No hay calificación
                                    </div>
                                ) : (
                                    <Rating
                                        name="rating"
                                        value={controlInfo?.rating}
                                        onChange={(): void => {}}
                                        inputRef={null}
                                        focusColor="var(--primary1)"
                                        unSelectedColor="var(--greyFont)"
                                        type={RatingTypes.SATISFACTION}
                                        disabled={true}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
