import styles from './RatingStep.module.css';
import RoundedTextField from '../../RoundedTextField/RoundedTextField';
import React, { cloneElement, useRef } from 'react';
import { GenericStepProps } from '../../GenericFormEditor/Step/Step';
import { calcStepWidth } from '../../GenericFormEditor/StepFunctions';
import RatingTypePicker from './RatingTypePicker/RatingTypePicker';
import Toggle from '../../Toggle/Toggle';
import Rating from '../../Rating/Rating';
import { RatingTypes } from '../../../constants/FormStepTypes';
import NestedRating from './NestedRating/NestedRating';
import { GLocation } from '../../../@Types/FormTypes/LocationTypes';
import LocationTypes from '../../../constants/LocationTypes';
import { GRating } from '../../../@Types/GenericFormSteps';

interface RatingStepProps {
    /** children to render */
    children?: React.ReactElement<any, string>;
}

function RatingStep<StepType extends GRating, U, L extends GLocation>({
    size,
    step,
    editing,
    children,
    location,
    updateStep,
    ...others
}: GenericStepProps<StepType, U, L> & RatingStepProps): JSX.Element {
    const inputRef = useRef<HTMLDivElement>(null);

    const mapOptions = (): JSX.Element => {
        return (
            <React.Fragment>
                {step.nestedSteps &&
                    step.nestedSteps.map((values, index) => {
                        const value =
                            step.ratingType === RatingTypes.LIKE
                                ? index
                                : index + 1;
                        return (
                            <React.Fragment key={index}>
                                <div className={styles.optionContainer}>
                                    <div className={styles.bulletContainer}>
                                        <div className={styles.bullet} />
                                    </div>
                                    <div
                                        className={styles.optionRatingContainer}
                                    >
                                        <Rating
                                            name={step.id + '-editor-' + value}
                                            value={value}
                                            preview
                                            onChange={(): void => {}}
                                            inputRef={null}
                                            focusColor={'var(--secondary)'}
                                            unSelectedColor={
                                                'var(--outlineGrey)'
                                            }
                                            type={step.ratingType}
                                        />
                                    </div>
                                </div>
                                <NestedRating
                                    {...others}
                                    step={step}
                                    size={size}
                                    location={{
                                        type: LocationTypes.RATING,
                                        idRootStep: location.idRootStep,
                                        idStep: step.id,
                                        indexOption: index,
                                        indexStep: null,
                                    }}
                                />
                            </React.Fragment>
                        );
                    })}
            </React.Fragment>
        );
    };
    if (editing) {
        return (
            <div className={styles.editingContainer}>
                <div className={styles.inputContainer}>
                    <RoundedTextField
                        label="Etiqueta"
                        value={step.label}
                        onChange={(e): void => {
                            updateStep({ ...step, label: e.target.value });
                        }}
                    ></RoundedTextField>
                </div>
                <RoundedTextField
                    label="Descripción"
                    value={step.description ?? ''}
                    onChange={(e): void => {
                        updateStep({
                            ...step,
                            description: e.target.value,
                        });
                    }}
                ></RoundedTextField>
                {children &&
                    cloneElement(children, {
                        step,
                        updateStep,
                        uniqueSteps: others.uniqueSteps,
                        updateUniqueSteps: others.updateUniqueSteps,
                    })}
                <div className={styles.ratingTypeContainer}>
                    <div className={styles.ratingTypeLbl}>
                        Tipo de Calificación:
                    </div>
                    <div className={styles.ratingTypeSelectorContainer}>
                        <RatingTypePicker
                            height="31px"
                            value={step.ratingType}
                            handleUpdate={(value): void => {
                                const tempStep = { ...step, ratingType: value };
                                if (tempStep.nestedSteps) {
                                    tempStep.nestedSteps = Array.from(
                                        {
                                            length: getMaxOptions(value),
                                        },
                                        (v, i) => i
                                    ).map(
                                        (_value, index) =>
                                            tempStep.nestedSteps?.[index] ?? []
                                    );
                                }
                                updateStep(tempStep);
                            }}
                            label=""
                            containerMargin="0px"
                        />
                    </div>
                </div>
                <div className={styles.ratingTypeContainer}>
                    <div className={styles.ratingTypeLbl}>SubPasos:</div>
                    <div className={styles.toggleContainer}>
                        <Toggle
                            checked={step.nestedSteps !== null}
                            onChange={(checked: boolean): void => {
                                const tempStep = { ...step };
                                if (checked) {
                                    tempStep.nestedSteps = Array.from(
                                        {
                                            length: getMaxOptions(
                                                step.ratingType
                                            ),
                                        },
                                        (v, i) => i
                                    ).map(() => []);
                                } else {
                                    tempStep.nestedSteps = null;
                                }
                                updateStep(tempStep);
                            }}
                        />
                    </div>
                </div>
                <div className={styles.optionsList}>{mapOptions()}</div>
            </div>
        );
    } else {
        return (
            <div
                className={styles.prevContainer}
                style={{
                    width: calcStepWidth(4, size),
                    maxWidth: '100%',
                }}
            >
                <div className={styles.labelLabel}>
                    {step.label + (step.required ? ' *' : '')}
                </div>
                <div className={styles.ratingContainer}>
                    <Rating
                        name={step.id + '-editor'}
                        value={null}
                        onChange={(): void => {}}
                        inputRef={inputRef}
                        focusColor={'var(--secondary)'}
                        unSelectedColor={'var(--outlineGrey)'}
                        type={step.ratingType}
                    />
                </div>
                {step.description && (
                    <div className={styles.stepDescriptionLabel}>
                        {step.description}
                    </div>
                )}
            </div>
        );
    }
}

export default React.memo(RatingStep, (prev, next) => {
    const { step, stepEqualityChecker } = prev;
    const { step: newStep } = next;
    if (
        step.label !== newStep.label ||
        step.description !== newStep.description ||
        step.required !== newStep.required ||
        step.ratingType !== newStep.ratingType ||
        step.nestedSteps !== newStep.nestedSteps
    ) {
        return false;
    }

    return stepEqualityChecker?.(prev, next) ?? true;
}) as typeof RatingStep;

function getMaxOptions(type: RatingTypes): number {
    switch (type) {
        case RatingTypes.LIKE:
            return 2;
        case RatingTypes.SATISFACTION:
            return 4;
        case RatingTypes.SCALE:
            return 5;
        default:
            return 0;
    }
}
