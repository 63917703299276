import styles from './EurekaElementMenu.module.css';
import { IconProps } from '../../../Icons/@IconTypes';
import { CSSProperties } from 'react';

export interface EurekaElementMenuElementStyle {
    fontSize?: CSSProperties['fontSize'];
    minWidth?: CSSProperties['minWidth'];
    iconWidth?: CSSProperties['width'];
}

interface EurekaElementMenuElementProps {
    Icon: (props: IconProps) => JSX.Element;
    Label: string | (() => JSX.Element);
    onClick: () => void;
    style?: EurekaElementMenuElementStyle;
}

function EurekaElementMenuElement({
    Icon,
    Label,
    onClick,
    style = { fontSize: 16 },
}: EurekaElementMenuElementProps): JSX.Element {
    const { fontSize, minWidth, iconWidth } = style;

    return (
        <>
            <div
                className={styles.container}
                onClick={onClick}
                style={{ minWidth, fontSize }}
            >
                <div className={styles.icon} style={{ width: iconWidth }}>
                    <Icon
                        fill="var(--secondary)"
                        contrast="var(--primary1)"
                        style={{
                            width: '26px',
                            height: '26px',
                            fontSize: 24,
                            lineHeight: '24px',
                            color: 'var(--secondary)',
                            fontWeight: 800,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    />
                </div>
                <div className={styles.label}>
                    {typeof Label === 'string' ? Label : <Label />}
                </div>
            </div>
        </>
    );
}

export default EurekaElementMenuElement;
