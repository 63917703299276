import styles from './EntityValuePath.module.css';
import React from 'react';
import { EntityValuePickerPath } from '../../../../@Types/GenericFormSteps';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../utils/_store';
import RoundedSelect from '../../../RoundedSelect/RoundedSelect';
import { EntityValuePathTypes } from '../../../../constants/FormStepTypes';
import { MenuItem } from '@material-ui/core';
import RoundedEntityValuePicker from '../../../@Pickers/RoundedEntityValuePicker/RoundedEntityValuePicker';

interface EntityValuePathProps {
    path: EntityValuePickerPath;
    handleUpdate: (filter: EntityValuePickerPath) => void;
}

function EntityValuePath({
    path,
    handleUpdate,
}: EntityValuePathProps): JSX.Element {
    const entity = useSelector(
        (state: RootState) => state.site.entities[path.idEntity]
    );
    if (!entity) return <div></div>;
    return (
        <div className={styles.container}>
            <div className={styles.entityLbl}>{entity.name}:</div>
            <div className={styles.info}>
                <div className={styles.filterTypeSelector}>
                    <RoundedSelect
                        autoWidth
                        containerMargin="0px"
                        label="Tipo"
                        value={path.type}
                        handleUpdate={(e): void => {
                            const type = e.target.value as EntityValuePathTypes;
                            switch (type) {
                                case EntityValuePathTypes.STEP:
                                    handleUpdate({
                                        idEntity: path.idEntity,
                                        type,
                                        any: true,
                                    });
                                    break;
                                case EntityValuePathTypes.VALUE:
                                default:
                                    handleUpdate({
                                        idEntity: path.idEntity,
                                        type,
                                        idEntityValue: undefined,
                                    });
                                    break;
                            }
                        }}
                    >
                        <MenuItem value={EntityValuePathTypes.STEP}>
                            Cualquier paso de {entity.name} anterior
                        </MenuItem>
                        <MenuItem value={EntityValuePathTypes.VALUE}>
                            {entity.feminine ? 'la' : 'el'} siguiente{' '}
                            {entity.name}
                        </MenuItem>
                    </RoundedSelect>
                </div>
                {path.type === EntityValuePathTypes.VALUE && (
                    <div className={styles.entityValueInput}>
                        <RoundedEntityValuePicker
                            height={'40px'}
                            minWidth={300}
                            multiline
                            multiple={false}
                            value={
                                path.idEntityValue
                                    ? [path.idEntityValue as any]
                                    : []
                            }
                            handleUpdate={([entityValue]): void => {
                                handleUpdate({
                                    ...path,
                                    idEntityValue: entityValue,
                                });
                            }}
                            idEntity={path.idEntity}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
export default EntityValuePath;
