import ConditionOperators from '../../constants/Conditions/ConditionOperators';
import ConditionTypes from '../../constants/Conditions/ConditionTypes';
import {
    KeysOfUnion,
    compareArrays,
    deepCompare,
} from '../../utils/DeepCompare';
import { Company } from '../Company';
import { EntityValue } from '../EntityValue';
import Relationship from '../Relationship';
import { AICondition } from './AICondition';
import { ClientCondition } from './ClientCondition';
import { ConversationStepCondition } from './ConversationStepConditions';
import { EntityCondition } from './EntityCondition';
import { ExpressionCondition } from './ExpressionCondition';
import { FlowCondition } from './FlowConditions';
import { FormStepCondition } from './FormStepCondition';
import {
    IntegrationCondition,
    DraftIntegrationCondition,
} from './IntegrationConditions';
import {
    EntryCondition,
    TicketPropertyCondition,
} from './TicketPropertyConditions';

export interface GCondition {
    type: string;
    operator: ConditionOperators;
}

export type ExtractConditionType<
    Types extends readonly Exclude<ConditionTypes, ConditionTypes.EXPRESSION>[]
> =
    | Extract<Condition, { type: Types[number] }>
    | ExpressionCondition<Extract<Condition, { type: Types[number] }>>;

export type NonExpressionCondition =
    | ClientCondition
    | TicketPropertyCondition
    | FormStepCondition
    | ConversationStepCondition
    | CompanyCondition
    | EntityValueCondition
    | EntryCondition
    | FlowCondition
    | AICondition
    | IntegrationCondition
    | DraftIntegrationCondition
    | EntityValueRelationshipCondition;

export type Condition =
    | ExpressionCondition<NonExpressionCondition>
    | NonExpressionCondition;

export type CompanyCondition = EntityCondition<ConditionTypes.COMPANY, Company>;

export type EntityValueCondition = EntityCondition<
    ConditionTypes.ENTITYVALUE,
    EntityValue
>;

export type EntityValueRelationshipCondition = EntityCondition<
    ConditionTypes.RELATIONSHIP,
    Relationship
>;

export function compareConditions(
    actual: Condition | undefined,
    expected: Condition | undefined
): boolean {
    if (!actual || !expected) return true;
    const omits: KeysOfUnion<Condition>[] = [];

    if (
        actual.type === ConditionTypes.EXPRESSION &&
        expected.type === ConditionTypes.EXPRESSION
    ) {
        if (
            !compareArrays(
                actual.conditions,
                expected.conditions,
                compareConditions
            )
        )
            return false;
        omits.push('conditions');
    }

    return deepCompare<Condition>(actual, expected, omits);
}
