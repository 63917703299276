import styles from './CBRPosVenta.module.css';
import { useDispatch } from 'react-redux';
import RoundedButton from '../../../../../../shared/RoundedButton/RoundedButton';
import { setShowCBRPosVenta } from '../../../../../../controllers/TicketsController/Actions/ActionsActions';
import React from 'react';

interface CBRPosVentaActionProps {
    posventaCaseNum: number;
}

function CBRPosVentaAction({
    posventaCaseNum,
}: CBRPosVentaActionProps): JSX.Element {
    const dispatch = useDispatch();
    return (
        <div>
            <div className={styles.topContainer + ' flexbox'}>
                <div className={styles.interaction}>
                    <img
                        alt={'Interaction'}
                        className={styles.interactionImg}
                        src={'/media/icons/sinco-cbr.svg'}
                    ></img>
                </div>
                <div className={styles.numContainer}>
                    Consecutivo:
                    <label className={styles.numLbl}>{posventaCaseNum}</label>
                </div>
            </div>
            <div className={styles.btnContainer}>
                <RoundedButton
                    text={'Ver Posventa'}
                    backgroundColor={'var(--secondary)'}
                    color="white"
                    onClick={(): void => {
                        dispatch(setShowCBRPosVenta(true));
                    }}
                />
            </div>
        </div>
    );
}

export default CBRPosVentaAction;
