import { useState } from 'react';
import StepComponent, {
    StepComponentProps,
} from '../../../GenericFormEditor/Step/Step';
import styles from './NestedRating.module.css';
import AddStepBtn from '../../../GenericFormEditor/AddStepBtn/AddStepBtn';
import {
    GLocation,
    RatingOptionLocation,
} from '../../../../@Types/FormTypes/LocationTypes';
import { GRating } from '../../../../@Types/GenericFormSteps';

interface NestedRatingProps<S extends GRating, U, L extends GLocation>
    extends Omit<StepComponentProps<S, U, L>, 'location'> {
    location: RatingOptionLocation;
}
function NestedRating<S extends GRating, U, L extends GLocation>({
    step,
    level,
    location,
    stepMenuComponent,
    ...others
}: NestedRatingProps<S, U, L>): JSX.Element {
    const [openId, setOpenId] = useState<string | undefined>(undefined);

    const mapSteps = (): JSX.Element[] => {
        const nestedSteps = step.nestedSteps?.[location.indexOption];
        if (!nestedSteps) {
            return [<div></div>];
        } else {
            return nestedSteps.map((idStep: string, index) => {
                return (
                    <StepComponent<S, U, L>
                        {...others}
                        level={level + 1}
                        onOpen={(): void => {
                            setOpenId(idStep);
                        }}
                        canMoveUp={index !== 0}
                        canMoveDown={index !== nestedSteps.length - 1}
                        location={{ ...location, indexStep: index } as any}
                        key={index}
                        openId={openId}
                        idStep={idStep}
                        stepMenuComponent={stepMenuComponent}
                    />
                );
            });
        }
    };

    return (
        <div>
            {mapSteps()}
            <div
                className={styles.addBtnContainer}
                style={{ width: `calc(100% - ${(level + 1) * 30}px)` }}
            >
                <AddStepBtn
                    size="35px"
                    location={location}
                    stepMenuComponent={stepMenuComponent}
                />
            </div>
        </div>
    );
}
export default NestedRating;
