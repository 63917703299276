import React, { memo } from 'react';
import styles from './ExitNode.module.css';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import { Handle, Position } from 'react-flow-renderer';
function ExitNode(data: { error: boolean }): JSX.Element {
    return (
        <>
            <Handle
                className={styles.leftHandle}
                type="target"
                position={Position.Left}
                style={{
                    top: '50%',
                    background: 'var(--primary1)',
                    border: '1px solid var(--accent)',
                }}
            />
            <div
                className={styles.container}
                style={
                    data.error
                        ? {
                              border: 'var(--error) 1px solid',
                              marginTop: '-1px',
                              marginLeft: '-1px',
                          }
                        : {}
                }
            >
                <ExitToAppRoundedIcon fontSize="inherit" />
            </div>
        </>
    );
}

export default memo(ExitNode);
