import { InmuebleInfo360 } from '../../../types';
import MaterialTable from '../../Utils/MaterialTable';
import { getPrettyCurrencyValue } from '../../Utils/otherFunctions';
import { OpenComponent } from '../../Utils/Openable/OpenComponent';

interface CierreFinancieroProps {
    conceptosMap: InmuebleInfo360['cartera']['conceptosMap'];
}

export function CierreFinanciero({
    conceptosMap,
}: CierreFinancieroProps): JSX.Element {
    const cuotaInicial = conceptosMap.subs[1];
    const credito = conceptosMap.subs[2];
    const reforma = conceptosMap.subs[3];
    const subsidio = conceptosMap.subs[4];
    const otros = conceptosMap.subs[5];
    const interesMora = conceptosMap.subs[6];
    const total = {
        concepto: 'Valor venta',
        valorPactado:
            cuotaInicial.valorPactado +
            credito.valorPactado +
            subsidio.valorPactado +
            reforma.valorPactado,
        saldo:
            cuotaInicial.saldo + credito.saldo + subsidio.saldo + reforma.saldo,
        mora:
            (cuotaInicial.mora ?? 0) +
            (credito.mora ?? 0) +
            (subsidio.mora ?? 0) +
            (reforma.mora ?? 0),
    };
    const saldos = {
        concepto: 'SALDOS',
        valorPactado: interesMora.valorPactado + otros.valorPactado,
        saldo: interesMora.saldo + otros.saldo,
        mora: (interesMora.mora ?? 0) + (otros.mora ?? 0),
    };
    return (
        <OpenComponent title={'Cierre Financiero'} showOpen={true}>
            <MaterialTable
                style={{
                    primaryColor: '#3d5a7f',
                    outlineColor: 'rgba(196,184,184,0.52)',
                    fontSize: 14,
                }}
                part={{
                    title: '',
                    headers: [
                        { label: 'Concepto' },
                        { label: 'Pactado' },
                        { label: 'Pagado' },
                        { label: 'Saldo' },
                        { label: 'Mora' },
                    ],
                    rows: [
                        {
                            isHeader: false,
                            data: [
                                { value: 'Cuota inicial' },
                                {
                                    value: getPrettyCurrencyValue(
                                        cuotaInicial.valorPactado
                                    ),
                                },
                                {
                                    value: getPrettyCurrencyValue(
                                        cuotaInicial.valorPactado -
                                            cuotaInicial.saldo
                                    ),
                                },
                                {
                                    value: getPrettyCurrencyValue(
                                        cuotaInicial.saldo
                                    ),
                                },
                                {
                                    value: getPrettyCurrencyValue(
                                        cuotaInicial.mora
                                    ),
                                },
                            ],
                        },
                        {
                            isHeader: false,
                            data: [
                                { value: 'Reforma' },
                                {
                                    value: getPrettyCurrencyValue(
                                        reforma.valorPactado
                                    ),
                                },
                                {
                                    value: getPrettyCurrencyValue(
                                        reforma.valorPactado - reforma.saldo
                                    ),
                                },
                                {
                                    value: getPrettyCurrencyValue(
                                        reforma.saldo
                                    ),
                                },
                                {
                                    value: getPrettyCurrencyValue(reforma.mora),
                                },
                            ],
                        },
                        {
                            isHeader: false,
                            data: [
                                { value: 'Crédito' },
                                {
                                    value: getPrettyCurrencyValue(
                                        credito.valorPactado
                                    ),
                                },
                                {
                                    value: getPrettyCurrencyValue(
                                        credito.valorPactado - credito.saldo
                                    ),
                                },
                                {
                                    value: getPrettyCurrencyValue(
                                        credito.saldo
                                    ),
                                },
                                {
                                    value: getPrettyCurrencyValue(credito.mora),
                                },
                            ],
                        },
                        {
                            isHeader: false,
                            data: [
                                { value: 'Subsidio' },
                                {
                                    value: getPrettyCurrencyValue(
                                        subsidio.valorPactado
                                    ),
                                },
                                {
                                    value: getPrettyCurrencyValue(
                                        subsidio.valorPactado - subsidio.saldo
                                    ),
                                },
                                {
                                    value: getPrettyCurrencyValue(
                                        subsidio.saldo
                                    ),
                                },
                                {
                                    value: getPrettyCurrencyValue(
                                        subsidio.mora
                                    ),
                                },
                            ],
                        },
                        {
                            isHeader: true,
                            data: [
                                { value: 'Valor venta' },
                                {
                                    value: getPrettyCurrencyValue(
                                        total.valorPactado
                                    ),
                                },
                                {
                                    value: getPrettyCurrencyValue(
                                        total.valorPactado - total.saldo
                                    ),
                                },
                                {
                                    value: getPrettyCurrencyValue(total.saldo),
                                },
                                {
                                    value: getPrettyCurrencyValue(total.mora),
                                },
                            ],
                        },
                        {
                            isHeader: false,
                            data: [
                                { value: 'Gastos y otros' },
                                {
                                    value: getPrettyCurrencyValue(
                                        otros.valorPactado
                                    ),
                                },
                                {
                                    value: getPrettyCurrencyValue(
                                        otros.valorPactado - otros.saldo
                                    ),
                                },
                                {
                                    value: getPrettyCurrencyValue(otros.saldo),
                                },
                                {
                                    value: getPrettyCurrencyValue(otros.mora),
                                },
                            ],
                        },
                        {
                            isHeader: false,
                            data: [
                                { value: 'Intereses de mora' },
                                {
                                    value: getPrettyCurrencyValue(
                                        interesMora.valorPactado
                                    ),
                                },
                                {
                                    value: getPrettyCurrencyValue(
                                        interesMora.valorPactado -
                                            interesMora.saldo
                                    ),
                                },
                                {
                                    value: getPrettyCurrencyValue(
                                        interesMora.saldo
                                    ),
                                },
                                {
                                    value: getPrettyCurrencyValue(
                                        interesMora.mora
                                    ),
                                },
                            ],
                        },
                    ],
                    totals: [
                        { label: 'Saldos' },
                        {
                            label: getPrettyCurrencyValue(saldos.valorPactado),
                        },
                        {
                            label: getPrettyCurrencyValue(
                                saldos.valorPactado - saldos.saldo
                            ),
                        },
                        {
                            label: getPrettyCurrencyValue(saldos.saldo),
                        },
                        {
                            label: getPrettyCurrencyValue(saldos.mora),
                        },
                    ],
                }}
            />
        </OpenComponent>
    );
}
