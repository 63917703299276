import React, { createRef, useState } from 'react';
import { Styles } from '../../../@Types/Organization';
import styles from './StyleEditor.module.css';
import { ClickAwayListener, Popper } from '@material-ui/core';
import { ChromePicker } from 'react-color';
import RoundedButton from '../../../shared/RoundedButton/RoundedButton';

export type StyleKeys = keyof Styles;

interface StyleEditorProps {
    stylesOrg: Styles;
    handleUpdate: Function;
}

const MaterialColors: { label: string; key: StyleKeys }[] = [
    { label: 'Color Principal', key: 'primaryColor' },
    { label: 'Color Secundario', key: 'secondaryColor' },
    { label: 'Color de Contraste', key: 'contrastColor' },
    { label: 'Color de la barra de navegación', key: 'navbarColor' },
];

export default function StyleEditor({
    stylesOrg,
    handleUpdate,
}: StyleEditorProps): JSX.Element {
    const [originalColors, setOriginalColors] = useState(stylesOrg);
    const [colors, setColors] = useState(stylesOrg);
    const [refs] = useState<any[]>(MaterialColors.map(() => createRef()));
    const [loading, setLoading] = useState(false);
    const [choosingColor, setChoosingColor] = useState<undefined | number>(
        undefined
    );
    const confirm = (choosingColor: number, colors: Styles): void => {
        setColors({
            ...colors,
            [MaterialColors[choosingColor].key]:
                colors[MaterialColors[choosingColor].key],
        });
        setChoosingColor(undefined);
    };
    const reset = async (): Promise<void> => {
        setLoading(true);
        setColors(originalColors);
        setLoading(false);
    };
    const isChanged = (): boolean => {
        return JSON.stringify(colors) !== JSON.stringify(originalColors);
    };

    return (
        <div className={styles.container} data-testid={'StyleEditor'}>
            {choosingColor !== undefined && (
                <ClickAwayListener
                    mouseEvent="onMouseDown"
                    onClickAway={(): void => {
                        confirm(choosingColor, colors);
                    }}
                >
                    <Popper
                        open={true}
                        anchorEl={(refs[choosingColor] as any).current}
                        placement={'right-start'}
                        modifiers={{
                            preventOverflow: {
                                enabled: true,
                                priority: ['left', 'top'],
                            },
                        }}
                        style={{ zIndex: 3 }}
                    >
                        <div className={styles.colorPickContainer + ' shadow'}>
                            <ChromePicker
                                color={
                                    colors[MaterialColors[choosingColor].key]
                                }
                                disableAlpha={false}
                                onChange={(color: { hex: string }): void => {
                                    setColors({
                                        ...colors,
                                        [MaterialColors[choosingColor].key]:
                                            color.hex,
                                    });
                                }}
                            />
                        </div>
                    </Popper>
                </ClickAwayListener>
            )}
            <div className={styles.stylesContainer} data-testid={'colorList'}>
                {MaterialColors.map(
                    (color, index: number): JSX.Element => (
                        <div className={styles.styleContainer} key={index}>
                            <div className={styles.stylesLabel + ' noselect'}>
                                {color.label}:
                            </div>
                            <div
                                className={styles.colorPick}
                                style={{
                                    backgroundColor: colors[color.key],
                                }}
                                data-testid={'color'}
                                ref={refs[index] as any}
                                onClick={(e): void => {
                                    setChoosingColor(index);
                                    e.stopPropagation();
                                }}
                            />
                        </div>
                    )
                )}
            </div>
            <div className={styles.buttonsContainer}>
                <div
                    className={styles.buttonContainer}
                    data-testid={'resetButton'}
                >
                    <RoundedButton
                        color="var(--accent)"
                        backgroundColor="var(--defaultGrey)"
                        onClick={(): void => {
                            setLoading(true);
                            reset();
                            setLoading(false);
                        }}
                        text={'Restaurar colores'}
                        disabled={!isChanged() || loading}
                    />
                </div>
                <div
                    className={styles.buttonContainer}
                    data-testid={'updateButton'}
                >
                    <RoundedButton
                        color="white"
                        backgroundColor="var(--secondary)"
                        onClick={async (): Promise<void> => {
                            setLoading(true);
                            await handleUpdate(colors);
                            setOriginalColors(colors);
                            setLoading(false);
                        }}
                        loading={loading}
                        text={'Actualizar colores'}
                        disabled={!isChanged()}
                    />
                </div>
            </div>
        </div>
    );
}
