import styles from './EditComment.module.css';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import React, { useState } from 'react';
import Dialog from '../../../../../../../shared/Dialog/Dialog';

import { CommentAction } from '../../../../../../../@Types/Action';
import { Payload } from '../../../../../../../@Types/Payload';
import {
    setLoading,
    updateAction,
} from '../../../../../../../controllers/TicketsController/Actions/ActionsActions';
import ActionsService from '../../../../../../../controllers/TicketsController/Actions/ActionsService';
import RoundedButton from '../../../../../../../shared/RoundedButton/RoundedButton';
import {
    PayloadEditorActions,
    usePayloadEditorDispatch,
} from '../../../../../../../controllers/PayloadEditorController/PayloadEditorSlice';
import PayloadEditor from '../../../../../../../shared/TextEditor/PayloadEditor';

interface EditCommentProps {
    comment: CommentAction;
}

function EditComment({ comment }: EditCommentProps): JSX.Element {
    const [openEdit, setOpenEdit] = useState(false);
    const dispatch = usePayloadEditorDispatch();
    const handleSend = async (): Promise<void> => {
        try {
            const payload: Payload | null = await dispatch(
                PayloadEditorActions.calcPayload({
                    idEditor: 'Comment',
                }) as any
            ).unwrap();
            if (!payload) return;
            const action = await ActionsService.editComment(
                comment.idProject,
                comment.idTicket,
                comment._id,
                payload
            );
            dispatch(
                updateAction({
                    idAction: comment._id,
                    update: action,
                })
            );
            setOpenEdit(false);
        } catch (error) {
            console.error(error);
            dispatch(setLoading(false));
        }
    };
    const handleClose = (): void => {
        setOpenEdit(false);
    };
    const handleOpen = (): void => {
        setOpenEdit(true);
    };

    return (
        <React.Fragment>
            <Dialog
                onClose={handleClose}
                open={openEdit}
                border={10}
                maxWidth={900}
            >
                <div
                    className={styles.editContainer}
                    data-testid={'Editor_Container'}
                >
                    <div className={styles.editHeader}>Editar Comentario</div>
                    <div className={styles.editorContainer}>
                        <PayloadEditor
                            idEditor="Comment"
                            autoFocus
                            placeholder={'Editar'}
                            payload={comment.payload}
                        />
                    </div>
                    <div className={styles.buttonsContainer}>
                        <div className={styles.cancelButton}>
                            <RoundedButton
                                text={'Descartar'}
                                onClick={handleClose}
                                backgroundColor={'var(--defaultGrey)'}
                                color={'var(--accent)'}
                                fontSize="20px"
                                padding="4px 14px"
                                height={30}
                            />
                        </div>
                        <div className={styles.saveButton}>
                            <RoundedButton
                                text={'Guardar'}
                                onClick={handleSend}
                                fontSize="20px"
                                padding="4px 14px"
                                height={30}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>

            <div className={styles.container}>
                <EditRoundedIcon onClick={handleOpen} />
            </div>
        </React.Fragment>
    );
}
export default EditComment;
