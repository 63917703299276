import styles from './SurveyConfig.module.css';
import EditContainer from '../../../../shared/EditContainer/EditContainer';
import React from 'react';
import { Survey } from '../../../../@Types/@Types';
import { updateName } from '../../../../controllers/GenericEditorController/GenericEditorActions';
import { useGenericEditorDispatch } from '../../../../shared/GenericFormEditor/GenericFormEditorHooks';
interface SurveyConfigProps {
    data?: any;
    handleUpdate?: Function;
}
function SurveyConfig({ data, handleUpdate }: SurveyConfigProps): JSX.Element {
    if (data === undefined) {
        return <div></div>;
    }
    const survey: Survey = data;
    const dispatch = useGenericEditorDispatch();

    const handleUpdateSurvey = (survey: Survey): void => {
        if (handleUpdate) {
            handleUpdate(survey);
        }
    };

    return (
        <React.Fragment>
            <div className={styles.descriptionLabel + ' noselect'}>Nombre</div>
            <div className={styles.descriptionInputContainer}>
                <EditContainer
                    object={survey}
                    field={'name'}
                    handleConfirm={(
                        _object: Omit<
                            Survey,
                            'steps' | 'sections' | 'uniqueSteps'
                        >,
                        field: keyof Omit<
                            Survey,
                            'steps' | 'sections' | 'uniqueSteps'
                        >,
                        value: string
                    ): void => {
                        if (survey) {
                            const tempSurvey = { ...survey };
                            tempSurvey.name = value;
                            handleUpdateSurvey(tempSurvey);
                            dispatch(updateName(value));
                        }
                    }}
                >
                    <div className={styles.description}>{survey.name}</div>
                    <input
                        className={styles.descriptionInput + ' edit-input'}
                        defaultValue={survey.name}
                        type="text"
                    />
                </EditContainer>
            </div>
            <div className={styles.descriptionLabel + ' noselect'}>
                Descripción
            </div>
            <div className={styles.descriptionInputContainer}>
                <EditContainer
                    object={survey}
                    field={'description'}
                    handleConfirm={(
                        _object: Omit<
                            Survey,
                            'steps' | 'sections' | 'uniqueSteps'
                        >,
                        field: keyof Omit<
                            Survey,
                            'steps' | 'sections' | 'uniqueSteps'
                        >,
                        value: string
                    ): void => {
                        if (survey) {
                            const tempSurvey = { ...survey };
                            tempSurvey.description = value;
                            handleUpdateSurvey(tempSurvey);
                        }
                    }}
                >
                    <div className={styles.description}>
                        {survey.description}
                    </div>
                    <input
                        className={styles.descriptionInput + ' edit-input'}
                        defaultValue={survey.description}
                        type="text"
                    />
                </EditContainer>
            </div>
        </React.Fragment>
    );
}

export default SurveyConfig;
