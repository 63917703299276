import React, { cloneElement, useState, useEffect, useCallback } from 'react';
import defaultStyles from './Tree.module.css';
import LowPriorityRoundedIcon from '@material-ui/icons/LowPriorityRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Loader from '../Loader/Loader';
import DraggableItem from './DraggableItem/DraggableItem';

interface TreeProps {
    /** The component that will be replicated in the list */
    children?: any;
    /** If loader is active */
    loading?: boolean;
    /** The root ids of the elements */
    roots: string[];
    /** The dictionary of elements */
    elements: Record<string, unknown>;
    /** the label to show on the tree if no elements are found*/
    label: string;
    /** toggles selecting a new element */
    handleItemClick?: Function;
    /** called when the user saves the reordering */
    saveOrderChanges: Function;
    /** the currently selected element */
    selectedElement?: any;
    /**  If the layout is mobile*/
    layoutIsMobile?: boolean;
    /** styles to override the default ones */
    styles?: any;
}
/**
 * Generic Tree component.
 */
function Tree({
    children,
    loading,
    roots,
    elements,
    label,
    handleItemClick,
    saveOrderChanges,
    selectedElement,
    layoutIsMobile,
    styles = defaultStyles,
}: TreeProps): JSX.Element {
    const [currentRoots, setCurrentRoots] = useState(roots);

    useEffect(() => {
        setCurrentRoots(roots);
    }, [roots]);
    const moveItem = useCallback(
        (dragIndex, hoverIndex) => {
            const dragItem = currentRoots[dragIndex];
            setCurrentRoots(
                update(currentRoots, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragItem],
                    ],
                })
            );
        },
        [currentRoots]
    );
    const [editing, setEditing] = useState(false);

    const mapElement = (id: string, index: number): JSX.Element => (
        <React.Fragment key={index}>
            {cloneElement(children, {
                idElement: id,
                elements,
                handleItemClick,
                selectedElement,
                editing,
                key: index,
                layoutIsMobile,
            })}
            {index !== currentRoots.length - 1 && (
                <div className={styles.treeHr} key={'hr' + index} />
            )}
        </React.Fragment>
    );

    /** If editing render elements wrapped with the draggable item */
    const renderElements = (): JSX.Element => {
        if (!loading && currentRoots) {
            if (editing) {
                return (
                    <DndProvider backend={HTML5Backend}>
                        {currentRoots.map((idRoot, index) => (
                            <DraggableItem
                                key={idRoot}
                                index={index}
                                id={idRoot}
                                moveItem={moveItem}
                            >
                                {mapElement(idRoot, index)}
                            </DraggableItem>
                        ))}
                    </DndProvider>
                );
            } else {
                return (
                    <React.Fragment>
                        {currentRoots.map((idRoot, index) =>
                            mapElement(idRoot, index)
                        )}
                    </React.Fragment>
                );
            }
        } else {
            return <div></div>;
        }
    };
    const orderable = saveOrderChanges !== undefined;
    return (
        <div className={styles.container} data-testid={'Tree_Container'}>
            {orderable && (
                <div className={styles.orderableBtnContainer}>
                    {!editing && (
                        <div
                            className={styles.orderBtn + ' secondary-btn'}
                            onClick={(): void => {
                                handleItemClick?.(undefined, !layoutIsMobile); //TODO aqui se podria poner una variable para que
                                // el mansaje del detalle le indique al usuario como ordenar la lista
                                setEditing(true);
                            }}
                        >
                            <LowPriorityRoundedIcon
                                style={{ fill: 'white' }}
                                fontSize="inherit"
                            />
                        </div>
                    )}
                    {editing && (
                        <React.Fragment>
                            <div
                                className={styles.orderBtn + ' secondary-btn'}
                                onClick={(): void => {
                                    saveOrderChanges(currentRoots);
                                    setEditing(false);
                                }}
                            >
                                <CheckRoundedIcon
                                    style={{ fill: 'white' }}
                                    fontSize="inherit"
                                />
                            </div>
                            <div
                                className={styles.orderBtn + ' grey-btn'}
                                onClick={(): void => {
                                    setCurrentRoots(roots);
                                    setEditing(false);
                                }}
                            >
                                <ClearRoundedIcon
                                    style={{ fill: 'black' }}
                                    fontSize="inherit"
                                />
                            </div>
                        </React.Fragment>
                    )}
                </div>
            )}
            {loading && (
                <div
                    className={styles.loaderContainer + ' center-anything'}
                    style={{ color: 'var(--secondary)' }}
                >
                    <Loader />
                </div>
            )}
            {!loading && currentRoots?.length === 0 && (
                <div className={styles.emptyTree}>
                    <h5 className="center-anything">{label}</h5>
                </div>
            )}
            <div className={styles.treeContainer}>{renderElements()}</div>
        </div>
    );
}

export default Tree;
