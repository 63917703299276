import React, { JSX, useRef, useState } from 'react';
import { Badge, IconButton, withStyles } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import styles from './NotificationsBell.module.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../utils/_store';
import { NotificationsMenu } from '../NotificationsMenu/NotificationsMenu';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';

export interface NotificationsProps {}
function NotificationsBell({}: NotificationsProps): JSX.Element {
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const notificationsCount = useSelector(
        (state: RootState) => Object.keys(state.site.notifications.map).length
    );
    const iconRef = useRef(null);

    return (
        <div className={styles.navBarNotificationsCont}>
            <IconButton
                aria-label="Notifications"
                style={{
                    width: 42,
                    height: 40,
                    padding: 0,
                }}
                onClick={(): void => {
                    setOpenMenu(!openMenu);
                }}
            >
                <CustomBadge
                    overlap="circular"
                    badgeContent={notificationsCount}
                    max={notificationsCount}
                    color="primary"
                >
                    <NotificationsIcon
                        ref={iconRef}
                        fontSize="inherit"
                        className={styles.navBarNotificationsIcon}
                        style={{
                            width: 38,
                            height: 38,
                            boxShadow: openMenu
                                ? '0 0 0 1px var(--accent)'
                                : undefined,
                            borderRadius: '50%',
                        }}
                    />
                </CustomBadge>
            </IconButton>
            {openMenu && (
                <ClickAwayListener
                    onClickAway={(event): void => {
                        const element = event.target as any;
                        // eslint-disable-next-line quotes
                        if (!element.closest(`[class^='uf-']`))
                            setOpenMenu(false);
                    }}
                >
                    <Popper
                        open={openMenu}
                        anchorEl={iconRef.current}
                        style={{
                            zIndex: 6,
                        }}
                        placement={'bottom-end'}
                    >
                        <NotificationsMenu />
                    </Popper>
                </ClickAwayListener>
            )}
        </div>
    );
}
export default NotificationsBell;

const CustomBadge = withStyles({
    root: {
        '& .Erk-MuiBadge-colorPrimary': {
            backgroundColor: 'var(--contrast)',
        },
    },
})(Badge);
