import React, { useEffect, useState, useRef } from 'react';
import styles from './MenuList.module.css';
import Loader from '../Loader/Loader';

interface MenuListProps {
    /** List ref so that the parent can reset it */
    listRef: any;
    /** Children to display */
    children: any;
    /** The current elements being displayed, doesnt create them because they are of diferent types */
    elements: any[];
    /** If it should be showing a loader */
    loading: boolean;
    /** Function to call when the dots enter the users view */
    onPageScroll: Function;
    /** If there are any elements left */
    noMoreElements: boolean;
}

function MenuList({
    listRef,
    children,
    elements,
    loading,
    onPageScroll,
    noMoreElements,
}: MenuListProps): JSX.Element {
    // tracking on which page we currently are
    // Is false if the Interaction observer has been mounted
    const [firstTime, setFirstTime] = useState(true);
    // add loader refrence
    const loaderRef = useRef<HTMLDivElement>(null);

    const canAutoLoad = 'IntersectionObserver' in window;

    useEffect((): void => {
        if (loading && elements.length === 0) {
            // If it is loading and has no elements it means that the list is refreshing completely
            // So the list must go to its initial state
            setFirstTime(true);
        }
        if (!loading && firstTime && canAutoLoad) {
            // Only happens when the observer has not been created yet
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 1.0,
            };
            // initialize IntersectionObserver over the loading element
            const observer = new IntersectionObserver(handleObserver, options);
            if (loaderRef.current) {
                observer.observe(loaderRef.current);
                setFirstTime(false);
            }
        }
        //TODO maybe handle the leftover observers? or does garbage collector take them
    }, [loading, elements]);

    // here we handle what happens when user scrolls to the loading element
    // in this case we just update page variable
    const handleObserver = (entities: any): void => {
        const target = entities[0];
        if (target.isIntersecting) {
            onPageScroll();
        }
    };
    return (
        <div
            ref={listRef}
            className={styles.infiniteList}
            data-testid="MenuList_infiniteList"
        >
            {loading && (
                <div
                    className={styles.loaderContainer + ' center-anything'}
                    style={{ color: 'var(--secondary)' }}
                >
                    <Loader size={25} />
                </div>
            )}
            {!loading && children}
            {!noMoreElements && !loading && canAutoLoad && (
                <div className={styles.loaderDots} ref={loaderRef}>
                    <span className={styles.loaderDot}>.</span>
                    <span className={styles.loaderDot}>.</span>
                    <span className={styles.loaderDot}>.</span>
                </div>
            )}
            {!noMoreElements && !loading && !canAutoLoad && (
                <div
                    className={styles.loadMoreBtn}
                    onClick={(): void => {
                        onPageScroll();
                    }}
                >
                    Cargar Más
                </div>
            )}
        </div>
    );
}

export default MenuList;
