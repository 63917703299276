import { InmuebleInfo360 } from '../../../../types';
import styles from './Unidades.module.css';
import { OpenComponent } from '../../../Utils/Openable/OpenComponent';
import React from 'react';
import { empStrOrUndToNA } from '../../../Utils/otherFunctions';

interface UnidadesProps {
    unidades: InmuebleInfo360['infoAgrupacion']['unidades'];
}

export function Unidades({ unidades }: UnidadesProps): JSX.Element {
    return (
        <OpenComponent title={'Unidades'}>
            <div className={styles.list}>
                {unidades.map((unidad, index) => (
                    <div className={styles.element} key={index}>
                        <div className={styles.sideBar} />
                        <div className={styles.info}>
                            <div className={styles.row}>
                                <div className={styles.label}>Nombre:</div>
                                <div className={styles.value}>
                                    {empStrOrUndToNA(unidad.nombre)}
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.label}>Tipo Unidad:</div>
                                <div className={styles.value}>
                                    {empStrOrUndToNA(unidad.tipoUnidad)}
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.label}>
                                    Tipo Inmueble:
                                </div>
                                <div className={styles.value}>
                                    {empStrOrUndToNA(unidad.tipoInmueble)}
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.row}>
                                    <div className={styles.label}>
                                        Area Privada:
                                    </div>
                                    <div className={styles.value}>
                                        {unidad.areaPrivada ?? 'N/A'}
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.label}>
                                        Area Construida:
                                    </div>
                                    <div className={styles.value}>
                                        {unidad.areaConstruida ?? 'N/A'}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.label}>
                                    Matricula Inmobiliaria:
                                </div>
                                <div className={styles.value}>
                                    {empStrOrUndToNA(
                                        unidad.matriculaInmobiliaria
                                    )}
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.label}>
                                    Cédula Catastral:
                                </div>
                                <div className={styles.value}>
                                    {empStrOrUndToNA(unidad.cedulaCatastral)}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </OpenComponent>
    );
}
