import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select, { SelectProps } from '@material-ui/core/Select';
import { FormHelperText, MenuItem } from '@material-ui/core';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import AgentsMenu from '../../@Menus/AgentsMenu/AgentsMenu';
import { Agent } from '../../../@Types/@Types';
import { loadAgentsById } from '../../../controllers/AgentsController/AgentsService';

interface StyleProps {
    /** The color of the outline when selected and hovered on */
    focusColor?: string;
    /** The color of the outline when it is not selected */
    outlineColor?: string;
    /** The color of the background  */
    backgroundColor?: string;
    /** The color of the error to display */
    errorColor?: string;
    /** If the style should change on hover */
    cantEdit?: boolean;
    /** The hight of the container */
    height?: string;
    /** The color of the text in the form */
    color?: string;
    /** material ui style seed */
    seed?: string;
}
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const useOutlinedInputStyles = (props: StyleProps): Function =>
    makeStyles({
        root: {
            cursor: props.cantEdit ? 'default' : 'default',
            height: props.height,
            backgroundColor: props.backgroundColor,
            '& $notchedOutline': {
                borderColor: props.outlineColor,
            },
            '&:hover $notchedOutline': {
                borderColor: props.cantEdit
                    ? props.outlineColor
                    : props.focusColor,
            },
            '& .Mui-disabled': {
                cursor: 'default',
                color: props.color,
            },
            '& .Mui-error': {
                color: props.errorColor,
            },
            [`&.Mui-error .${props.seed}MuiOutlinedInput-notchedOutline`]: {
                borderColor: props.outlineColor,
            },
            '&$focused $notchedOutline': {
                borderColor: props.focusColor,
            },
            [`& .${props.seed}MuiSelect-icon.Mui-disabled`]: {
                color: props.outlineColor,
            },
            [`& .${props.seed}MuiSelect-iconOpen`]: {
                transform: 'none',
            },
            borderRadius: 10,
            color: props.color,
        },
        focused: {},
        notchedOutline: {},
    });

const useLabelInputStyles = (props: StyleProps): Function =>
    makeStyles(() => ({
        focused: {
            color: props.focusColor + ' !important',
        },
        root: {
            marginTop: props.height != '40px' ? '-4px' : '0px',
            [`&.${props.seed}MuiInputLabel-shrink`]: {
                marginTop: '0px',
            },
            '& .Mui-error': {
                color: props.errorColor,
            },
            '&.Mui-error': {
                color: props.errorColor,
            },
        },
    }));

const useHelperTextStyles = (props: StyleProps): Function =>
    makeStyles(() => ({
        root: {
            '&.Mui-error': {
                color: props.errorColor,
            },
        },
    }));

const useMenuStyles = (props: StyleProps): Function =>
    makeStyles(() => ({
        root: {
            [`& .${props.seed}MuiPaper-root`]: {
                backgroundColor: 'black',
                display: 'none',
            },
        },
    }));

interface RoundedAgentPickerProps
    extends Omit<SelectProps, 'color'>,
        StyleProps {
    /** Currently selected value */
    value: Agent[] | string[];
    /** function called when value changes */
    handleUpdate?: (event: Agent[], child?: React.ReactNode) => void;
    /** Strig to place in the label */
    label: string;
    /** Minimum width in px of the component */
    minWidth?: number;
    /** The helper Text to display */
    helperText?: string;
    /** the margin around the selector */
    containerMargin?: string;
    /** If the select displays more than one agent*/
    multiple?: boolean;
    /** If the menu only displays agents in the current project */
    projectFilter?: boolean;
    /** If the menu displays the areas filter selector */
    showAreaFilters?: boolean;
    /** If the app is mobile */
    mobile?: boolean;
    /** Agents to not include in the list */
    filterAgents?: Agent[];
}

function CustomSelect({
    value,
    handleUpdate,
    label,
    minWidth,
    helperText,
    color = 'var(--accent)',
    errorColor = 'var(--error)',
    focusColor = 'var(--secondary)',
    outlineColor = 'var(--outlineGrey)',
    backgroundColor = 'var(--primary1)',
    cantEdit = false,
    containerMargin = '0px',
    seed = 'Erk-',
    height = '31px',
    required,
    error,
    multiple = false,
    projectFilter = false,
    showAreaFilters = false,
    mobile = false,
    filterAgents,
    ...others
}: RoundedAgentPickerProps): JSX.Element {
    const classes = useStyles();
    const StyleProps = {
        focusColor,
        outlineColor,
        backgroundColor,
        errorColor,
        cantEdit,
        color,
        seed,
        height,
    };
    const outlinedInputClasses = useOutlinedInputStyles(StyleProps)();
    const labelClasses = useLabelInputStyles(StyleProps)();
    const helperTextClasses = useHelperTextStyles(StyleProps)();
    const menuClasses = useMenuStyles(StyleProps)();
    const inputLabel = React.useRef<HTMLLabelElement>(null);
    const selectRef = React.useRef<HTMLDivElement>(null);
    const [labelWidth, setLabelWidth] = useState(0);
    const [showMenu, setShowMenu] = useState(false);
    const [loading, setLoading] = useState<boolean | undefined>(undefined);
    useEffect(() => {
        if (inputLabel.current) setLabelWidth(inputLabel.current.offsetWidth);
    }, [label]);

    const fetchAgentsInfo = async (): Promise<void> => {
        setLoading(true);
        const agents = await loadAgentsById(value as any);
        handleUpdate?.(agents);
        setLoading(false);
    };

    useEffect(() => {
        if (value.length > 0 && (value[0] as any)._id === undefined) {
            fetchAgentsInfo();
        } else {
            setLoading(false);
        }
    }, [value]); //Quitar esta dependencia y manejar los estados bien!

    return (
        <React.Fragment>
            {showMenu && loading === false && (
                <AgentsMenu
                    mobile={mobile}
                    anchorRef={selectRef}
                    handleClose={(): void => {
                        setShowMenu(false);
                    }}
                    showAreaFilters={showAreaFilters}
                    projectFilter={projectFilter}
                    filterAgents={filterAgents}
                    seed={seed}
                    placement="bottom-start"
                    initialAgents={value}
                    zIndex={1301}
                    marginTop={'-5px'}
                    multiple={multiple}
                    handleChange={(value: any): void => {
                        handleUpdate?.(multiple ? value : value ? [value] : []);
                    }}
                />
            )}
            <FormControl
                variant="outlined"
                className={classes.formControl}
                size="small"
                style={
                    minWidth !== undefined
                        ? { minWidth, outlineColor, margin: containerMargin }
                        : {
                              margin: containerMargin,
                          }
                }
                fullWidth
                required={required}
                error={error}
            >
                <InputLabel ref={inputLabel} classes={labelClasses}>
                    {label}
                </InputLabel>
                <Select
                    {...others}
                    ref={selectRef}
                    value={value.length > 0 ? 0 : ''}
                    onChange={handleUpdate as any}
                    IconComponent={PersonRoundedIcon}
                    onClose={(): void => {
                        setShowMenu(false);
                    }}
                    onOpen={(): void => {
                        setShowMenu(true);
                    }}
                    input={
                        <OutlinedInput
                            disabled={cantEdit}
                            labelWidth={labelWidth}
                            name={label}
                            classes={outlinedInputClasses}
                        />
                    }
                    renderValue={(): undefined | React.ReactNode => {
                        if (
                            value.length > 0 &&
                            (value[0] as any).name !== undefined
                        ) {
                            return (value as any)
                                .map((agent: Agent) => agent.name)
                                .join(', ');
                        }

                        return undefined;
                    }}
                    MenuProps={{
                        PopoverClasses: menuClasses,
                        style: { zIndex: mobile ? 1299 : 1300 },
                    }}
                >
                    <MenuItem value={0}></MenuItem>
                </Select>
                {helperText !== undefined && (
                    <FormHelperText classes={helperTextClasses}>
                        {helperText}
                    </FormHelperText>
                )}
            </FormControl>
        </React.Fragment>
    );
}

/**
 * Generic textfield with apps designs. Is class do to the use in the react-hook-forms library
 */
class RoundedAgentPicker extends React.Component<RoundedAgentPickerProps> {
    render(): JSX.Element {
        return <CustomSelect {...this.props} />;
    }
}
export default RoundedAgentPicker;
