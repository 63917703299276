import { IconProps } from './@IconTypes';

function CaptaIcon({
    className,
    style,
    fill = '#3d5a7f',
}: IconProps): JSX.Element {
    return (
        <svg
            style={style}
            className={className}
            version="1.1"
            viewBox="55 100 970 860"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill={fill}
                d="M295.61,640.65a108.63,108.63,0,1,1,80-182H490.38C460.68,380,384.57,323.89,295.61,323.89,180.84,323.89,87.49,417.24,87.49,532s93.35,208.16,208.12,208.16c88.71,0,164.63-55.8,194.52-134.11H375A108.18,108.18,0,0,1,295.61,640.65Z"
            />
            <path
                fill={fill}
                d="M862.87,334.24v24.29c-28.63-20-69.1-34.64-109.09-34.64-89,0-165,56.09-194.77,134.74H673.77a108.65,108.65,0,1,1,.63,147.42H559.26c29.89,78.31,105.81,134.11,194.52,134.11a191.54,191.54,0,0,0,109.09-34.64v28H966.44V334.24Z"
            />
            <path
                fill="#ed6c4d"
                d="M815.41,531.57a61.28,61.28,0,0,1-114.82,29.78l-.3-.52H349.9l-.28.52a61.34,61.34,0,1,1,.5-58l.29.53H699.46l.28-.53a61.29,61.29,0,0,1,115.67,28.26Z"
            />
        </svg>
    );
}
export default CaptaIcon;
