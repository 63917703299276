import styles from './ClientCompanies.module.css';
import React, { useEffect, useRef, useState } from 'react';
import { Client } from '../../../@Types/Client';
import { Company } from '../../../@Types/Company';
import {
    loadCompaniesById,
    removeCompanyClient,
} from '../../../controllers/CompaniesController/CompaniesService';
import RoundedButton from '../../RoundedButton/RoundedButton';
import Loader from '../../Loader/Loader';
import ClientCompanyComponent from './ClientCompanyComponent/ClientCompanyComponent';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import CompaniesMenu from '../../@Menus/CompaniesMenu/CompaniesMenu';
import { addCompanyClient } from '../../../controllers/CompaniesController/CompaniesService';

interface ClientCompaniesProps {
    client: Client;
    refresh?: Function;
}
function ClientCompanies({
    client,
    refresh,
}: ClientCompaniesProps): JSX.Element {
    const [companies, setCompanies] = useState<Company[] | null>(null);
    const [showAdd, setShowAdd] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState<string | undefined>(
        undefined
    );

    const addBtnRef = useRef<HTMLDivElement>(null);
    async function loadCompanies(): Promise<void> {
        const companies = await loadCompaniesById(
            client.companies.map((c) => c.idCompany)
        );
        setCompanies(companies);
    }

    useEffect(() => {
        loadCompanies();
    }, [client.companies]);

    return (
        <React.Fragment>
            {deleteDialog !== undefined && (
                <ConfirmDialog
                    onConfirm={async (
                        element: string,
                        setLoading: Function
                    ): Promise<void> => {
                        try {
                            setLoading(true);
                            await removeCompanyClient(deleteDialog, client._id);
                            refresh?.();
                            setDeleteDialog(undefined);
                        } catch (error) {
                            //TODO: handle errors
                            console.error(error);
                            setLoading(false);
                        }
                    }}
                    element={deleteDialog}
                    title="Desvincular Empresa"
                    btnMsg="Desvincular"
                    deleting
                    msg={
                        '¿Está seguro de que desea desvincular el cliente de esta empresa?'
                    }
                    onClose={(): void => {
                        setDeleteDialog(undefined);
                    }}
                />
            )}
            {showAdd && (
                <CompaniesMenu
                    anchorRef={addBtnRef}
                    handleClose={(): void => {
                        setShowAdd(false);
                    }}
                    placement="bottom-end"
                    zIndex={1301}
                    marginTop={'-5px'}
                    multiple={false}
                    elementsToOmit={companies ?? undefined}
                    handleConfirm={async (company: Company): Promise<void> => {
                        try {
                            await addCompanyClient(company._id, client._id);
                            refresh?.();
                            setShowAdd(false);
                        } catch (error) {
                            //TODO: handle errors
                            console.error(error);
                        }
                    }}
                />
            )}
            <div className={styles.entityValuesContainer}>
                <div className={styles.entityValuesLbl}>
                    Empresas:
                    <div className={styles.addBtnContainer} ref={addBtnRef}>
                        <RoundedButton
                            text="Agregar Empresa"
                            backgroundColor={'var(--secondary)'}
                            color={'white'}
                            onClick={(): void => {
                                setShowAdd(true);
                            }}
                            padding="2px 13px"
                        />
                    </div>
                </div>
                <div className={styles.entityValuesListContainer + ' flexbox'}>
                    {!companies && (
                        <div className={styles.loaderContainer}>
                            <Loader size={50} />
                        </div>
                    )}
                    {companies && companies.length === 0 && (
                        <div className={styles.emptyLbl}>
                            Cliente sin empresas
                        </div>
                    )}
                    {companies &&
                        companies.map((company, index) => (
                            <ClientCompanyComponent
                                client={client}
                                company={company}
                                key={index}
                                handleDelete={(): void => {
                                    setDeleteDialog(company._id);
                                }}
                            />
                        ))}
                </div>
            </div>
        </React.Fragment>
    );
}
export default ClientCompanies;
