export type WhatsappTemplateTypes =
    | BaseWhatsappTemplateTypes
    | CaptaWhatsappTemplateTypes
    | CustomWhatsappTemplateTypes;

export enum BaseWhatsappTemplateTypes {
    /** Mensaje de Caso Recibido (Si el cliente escribió por otro canal) */
    RECEIVED = 'RECEIVED',
    /** Caso cerrado (Sin encuesta) */
    CLOSED = 'CLOSED',
    /** Caso cerrado (Con encuesta) */ //Por ahora con link al caso, luego con Encuesta directamente en WPP.
    CLOSED_SURVEY = 'CLOSED_SURVEY',
    /** Hubo una respuesta en el caso */
    CLIENT_LINK = 'CLIENT_LINK',
    /** Hay una notificación nueva */
    VIEW_NOTIFICATION = 'VIEW_NOTIFICATION',
    /** Hay una respuesta nueva */
    VIEW_REPLY = 'VIEW_REPLY',
}

export enum CaptaWhatsappTemplateTypes {
    /** Enviar una notificación hacia un agente (LINEA CAPTA) */
    CAPTA_NOTIFICATION = 'CAPTA_NOTIFICATION',
    /** Hay una notificación nueva(enviada desde una linea de Capta) */
    CAPTA_VIEW_NOTIFICATION = 'CAPTA_VIEW_NOTIFICATION',
}

export enum CustomWhatsappTemplateTypes {
    /** Enviar una notificación (Hacia otros, no durante conv) */
    NOTIFICATION = 'NOTIFICATION',
    /** Enviar un template en una conv */
    CONVERSATION_STEP = 'CONVERSATION_STEP',
}

export const TicketLinkTemplateTypes = [
    BaseWhatsappTemplateTypes.RECEIVED,
    BaseWhatsappTemplateTypes.CLOSED,
    BaseWhatsappTemplateTypes.CLOSED_SURVEY,
    BaseWhatsappTemplateTypes.CLIENT_LINK,
] as const;
export type TicketLinkTemplateTypes = (typeof TicketLinkTemplateTypes)[number];

export const QuickReplyTemplateTypes = [
    BaseWhatsappTemplateTypes.VIEW_NOTIFICATION,
    BaseWhatsappTemplateTypes.VIEW_REPLY,
    CaptaWhatsappTemplateTypes.CAPTA_NOTIFICATION,
    CaptaWhatsappTemplateTypes.CAPTA_VIEW_NOTIFICATION,
] as const;
export type QuickReplyTemplateTypes = (typeof QuickReplyTemplateTypes)[number];
