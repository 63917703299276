import { PayloadEditorTemplateContext } from './PayloadEditorTypes';
import axiosInstance from '../../AxiosAPI';
import { EurekaDraft } from '../../@Types/Draft/Draft';

export const mapDraftEntities = async (
    context: PayloadEditorTemplateContext,
    draft: EurekaDraft
): Promise<EurekaDraft> => {
    const params = new URLSearchParams(context as Record<string, string>);
    const response = await axiosInstance.post(
        '/draft/mapEntities?' + params.toString(),
        draft
    );
    return response.data;
};

export const generateAIComment = async (
    idTicket: string,
    draft: EurekaDraft
): Promise<EurekaDraft> => {
    const params = new URLSearchParams({ idTicket });
    const response = await axiosInstance.post(
        '/draft/generateAIComment?' + params.toString(),
        draft
    );
    return response.data;
};

export const generateAIReply = async (
    idTicket: string,
    draft: EurekaDraft
): Promise<EurekaDraft> => {
    const params = new URLSearchParams({ idTicket });
    const response = await axiosInstance.post(
        '/draft/generateAIReply?' + params.toString(),
        draft
    );
    return response.data;
};
