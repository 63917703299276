import React, { useState } from 'react';
import styles from './CreateOutboundTicket.module.css';
import Dialog from '../../../shared/Dialog/Dialog';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import RoundedButton from '../../../shared/RoundedButton/RoundedButton';
interface CreateOutboundTicketProps {
    /** Function called to handle confirmation of creation to refresh the list */
    handleConfirmed: Function;
}

function CreateOutboundTicket({
    handleConfirmed,
}: CreateOutboundTicketProps): JSX.Element {
    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <RoundedButton
                text=""
                onClick={(): void => setOpen(true)}
                width="27px"
                height="27px"
            >
                <div className={styles.iconContainer}>
                    <SendRoundedIcon fontSize="inherit" />
                </div>
            </RoundedButton>

            <Dialog
                maxWidth="100vw"
                open={open}
                disableEnforceFocus
                onClose={(): void => setOpen(false)}
            >
                <OutboundTicket handleConfirmed={handleConfirmed} />
            </Dialog>
        </React.Fragment>
    );
}

export default CreateOutboundTicket;

interface OutboundTicketProps extends CreateOutboundTicketProps {
    setLoading?: Function;
}

function OutboundTicket({ setLoading }: OutboundTicketProps): JSX.Element {
    setLoading;
    return (
        <div className={styles.container}>
            <div className={styles.containerLbl}>Comunicarse con Cliente</div>
            <div className={styles.widgetContainer}>
                {/* <FormRenderer
                    form={forms[currentForm]}
                    values={{
                        INTERNAL_CREATING_AGENT: siteInfo.user?._id,
                    }}
                    handleConfirmed={(): void => {
                        handleConfirmed();
                    }}
                /> */}
            </div>
        </div>
    );
}
