import Avatar from '../../../../../../shared/Avatar/Avatar';
import styles from './TransferAgent.module.css';
import TrendingFlatRoundedIcon from '@material-ui/icons/TrendingFlatRounded';
import { Agent } from '../../../../../../@Types/@Types';
interface TransferAgentProps {
    /** Agents removed by the transfer */
    agentsRemoved: Agent[];
    /** The agent the ticket was transfered to  */
    transferee: Agent;
}

/**
 * Component that displays the content of the transfer to agent action
 */
function TransferAgent({
    agentsRemoved,
    transferee,
}: TransferAgentProps): JSX.Element {
    /* TODO implementar un avatar group propio para que no muestre mas de 4 fotos. */
    return (
        <div className={styles.container}>
            <div className={styles.agentsRemovedContainer + ' flexbox'}>
                {agentsRemoved.map((agent, index) => (
                    <Avatar key={index} lbl={agent.name} img={agent.img} />
                ))}
            </div>
            <div className={styles.arrowContainer}>
                <TrendingFlatRoundedIcon fontSize="inherit" />
            </div>
            <div className={styles.newAgentContainer}>
                <Avatar lbl={transferee.name} img={transferee.img} />
                <label className={styles.nameLbl}>{transferee.name}</label>
            </div>
        </div>
    );
}
export default TransferAgent;
