import { Company } from '../../../../../../@Types/@Types';
import styles from './SetCompany.module.css';
import TrendingFlatRoundedIcon from '@material-ui/icons/TrendingFlatRounded';
import React from 'react';
interface SetCompanyProps {
    originalCompany: Company;
    company: Company;
}

function SetCompany({
    originalCompany,
    company,
}: SetCompanyProps): JSX.Element {
    return (
        <div className={styles.container}>
            {/*  TODO This could be unified with the removed agents from the transfer to agent action */}
            {originalCompany && (
                <React.Fragment>
                    <div className={styles.companyContainer}>
                        <div className={styles.companyIconContainer}>
                            <img
                                alt={originalCompany?.values?.name ?? 'Empresa'}
                                title={
                                    originalCompany?.values?.name ?? 'Empresa'
                                }
                                className={styles.companyIcon}
                                src={'/media/icons/handshake.svg'}
                            ></img>
                        </div>
                        <label className={styles.nameLbl}>
                            {originalCompany?.values?.name ?? 'Empresa'}
                        </label>
                    </div>
                    <div className={styles.arrowContainer}>
                        <TrendingFlatRoundedIcon fontSize="inherit" />
                    </div>
                </React.Fragment>
            )}
            <div className={styles.company}>
                <div className={styles.companyContainer}>
                    <div className={styles.companyIconContainer}>
                        <img
                            alt={company?.values?.name ?? 'Empresa'}
                            title={company?.values?.name ?? 'Empresa'}
                            className={styles.companyIcon}
                            src={'/media/icons/handshake.svg'}
                        ></img>
                    </div>
                    <label className={styles.nameLbl}>
                        {company?.values?.name ?? 'Empresa'}
                    </label>
                </div>
            </div>
        </div>
    );
}
export default SetCompany;
