import { useState } from 'react';
import Autocomplete, {
    createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { Popper } from '@material-ui/core';
import axiosInstance from '../../../AxiosAPI';

const filter = createFilterOptions<{ label: string; inputValue?: string }>();

interface StyleProps {
    /** material ui style seed */
    seed?: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
}));

const useTextfieldStyles = (props: StyleProps, height: number): Function =>
    makeStyles(() => ({
        root: {
            [`& .${props.seed}MuiOutlinedInput-root:hover .${props.seed}MuiOutlinedInput-notchedOutline`]:
                {
                    borderColor: 'var(--secondary)',
                },
            [`& .${props.seed}MuiOutlinedInput-notchedOutline`]: {
                borderColor: '#0000003b',
            },
            [`& .${props.seed}MuiInputLabel-formControl`]: {
                marginTop: -10,
            },
            [`& .${props.seed}MuiOutlinedInput-root`]: {
                borderRadius: 10,
                '&.Mui-focused fieldset': {
                    borderColor: '#0000003b',
                },
                [`& .${props.seed}MuiAutocomplete-input`]: {
                    height: height,
                    marginTop: -10,
                },
                [`& .${props.seed}MuiAutocomplete-input::placeholder`]: {
                    color: 'var(--greyFont)',
                    opacity: 1,
                },
                height: height,
            },
            height: height,
        },
    }));

interface RoundedOrgAreaPickerProps extends StyleProps {
    /** Currently selected value */
    value: any;
    /** Function called when value is selected or deleted */
    handleUpdate: Function;
    /** Boolean that indicates if a new option could be created */
    creatable?: Boolean;
    /** Boolean that indicates if a new option could be created */
    placeholder?: string;
    /** Height in px of the component */
    height?: number;
}
export default function RoundedOrgAreaPicker({
    value,
    handleUpdate,
    seed = 'Erk-',
    creatable = true,
    placeholder = 'Sin Area',
    height = 40,
}: RoundedOrgAreaPickerProps): JSX.Element {
    const [loading, setLoading] = useState<boolean>(true);
    const fetchAreas = async (): Promise<void> => {
        const response = await axiosInstance.get('agents/areas');
        setLoading(false);
        setOptions(response.data.map((option: string) => ({ label: option })));
    };

    const [options, setOptions] = useState<string[]>([]);
    const classes = useStyles();
    const textFieldStyles = useTextfieldStyles({ seed }, height)();
    return (
        <div className={classes.root}>
            <Autocomplete
                size="small"
                fullWidth
                freeSolo={Boolean(creatable)}
                popupIcon={false}
                onOpen={(): void => {
                    if (options.length === 0) {
                        fetchAreas();
                    }
                }}
                loading={loading}
                loadingText={'Cargando...'}
                options={options}
                getOptionLabel={(option: any): string => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    } else if (option.inputValue) {
                        return option.inputValue;
                    } else {
                        // Regular option
                        return option.label;
                    }
                }}
                PopperComponent={({ style, ...props }: any): JSX.Element => (
                    <Popper {...props} style={{ ...style, zIndex: 1305 }} />
                )}
                value={value ?? ''}
                onChange={(event, newValue): void => {
                    if (typeof newValue === 'string') {
                        handleUpdate(newValue);
                    } else if (newValue && newValue.inputValue) {
                        handleUpdate(newValue.inputValue); // Create a new value from the user input
                    } else {
                        handleUpdate(newValue?.label);
                    }
                }}
                filterOptions={(options, params): any[] => {
                    const filtered = filter(options, params);
                    // Suggest the creation of a new value
                    if (params.inputValue !== '' && creatable) {
                        filtered.push({
                            inputValue: params.inputValue,
                            label: `Crear "${params.inputValue}"`,
                        });
                    }
                    return filtered;
                }}
                renderOption={(option: any): string => option.label}
                renderInput={(params): JSX.Element => (
                    <TextField
                        className={textFieldStyles.root}
                        {...params}
                        variant="outlined"
                        label={''}
                        placeholder={placeholder}
                    />
                )}
                noOptionsText="No hay opciones"
            />
        </div>
    );
}
