import { Trigger } from '../../../../../@Types/ProcessTypes/Activity';
import ActivityTriggerTypes from '../../../../../constants/ActivityTypes/ActivityTriggerType';
import RoundedButton from '../../../../../shared/RoundedButton/RoundedButton';
import styles from './SubmitBtns.module.css';

interface SubmitBtnsProps {
    triggers?: Trigger[];
    defaultTrigger?: Trigger;
    postview?: boolean;
    viewFormLabel?: string | null;
    onSubmit?: Function;
    handleSubmit?: (
        trigger?: Trigger,
        values?: Record<string, any>
    ) => Promise<void>;
    fontSize?: string;
    idTrigger?: string | null;
}

function SubmitBtns({
    onSubmit,
    triggers,
    idTrigger,
    handleSubmit,
    defaultTrigger,
    postview = false,
    viewFormLabel,
}: SubmitBtnsProps): JSX.Element {
    return (
        <div className={'flexbox ' + styles.formBtnContainer}>
            {triggers?.map((trigger) => (
                <div
                    style={
                        idTrigger === trigger.id && postview
                            ? {
                                  border: '2px solid var(--secondary)',
                                  borderRadius: 12,
                              }
                            : {}
                    }
                    key={trigger.id}
                >
                    <RoundedButton
                        onClick={async (): Promise<void> => {
                            await handleSubmit?.(trigger);
                        }}
                        loading={idTrigger === trigger.id && !postview}
                        disabled={postview || !!idTrigger}
                        backgroundColor={calcTriggerTypeColor(
                            trigger.triggerType
                        )}
                        text={trigger.label}
                        fontSize={'15px'}
                        padding="8px 16px"
                    />
                </div>
            ))}
            {defaultTrigger && (
                <div
                    style={
                        idTrigger === defaultTrigger.id && postview
                            ? {
                                  border: '2px solid var(--secondary)',
                                  borderRadius: 12,
                              }
                            : {}
                    }
                    key={'main'}
                >
                    <RoundedButton
                        onClick={async (): Promise<void> => {
                            const values = await onSubmit?.();
                            if (values)
                                await handleSubmit?.(defaultTrigger, values);
                            else if (!onSubmit)
                                await handleSubmit?.(defaultTrigger, {});
                        }}
                        loading={idTrigger === defaultTrigger.id && !postview}
                        disabled={postview || !!idTrigger}
                        backgroundColor={calcTriggerTypeColor(
                            defaultTrigger.triggerType
                        )}
                        text={defaultTrigger.label}
                        fontSize={'15px'}
                        padding="8px 16px"
                    />
                </div>
            )}
            {viewFormLabel && (
                <div key={'viewMore'}>
                    <RoundedButton
                        onClick={(): void => {
                            handleSubmit?.();
                        }}
                        text={viewFormLabel}
                        fontSize={'15px'}
                        padding="8px 16px"
                    />
                </div>
            )}
        </div>
    );
}
export default SubmitBtns;
function calcTriggerTypeColor(type: ActivityTriggerTypes): string {
    switch (type) {
        case ActivityTriggerTypes.DEFAULT:
            return 'var(--secondary)';
        case ActivityTriggerTypes.ERROR:
            return 'var(--error)';
        case ActivityTriggerTypes.CONTRAST:
            return 'var(--contrast)';
        default:
            return 'var(--secondary)';
    }
}
