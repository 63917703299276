import produce from 'immer';
import { DraftEntityMap, EurekaDraft } from '../../@Types/Draft/Draft';
import { DraftEntityTypes } from '../../constants/Draft/DraftEntityTypes';
import { mapCondition } from '../ConditionEditorController/ConditionFunctions';
import {
    ClientRelationshipDraftEntityData,
    EntityValuesDraftEntityData,
    isMappableEntityData,
} from '../../@Types/Draft/DraftEntityData';
import { Project } from '../../@Types/Project';
import { Entity } from '../../@Types/@Types';

export function mapEurekaDraft(draft: EurekaDraft): EurekaDraft {
    return produce(draft, (draft) => {
        mapDraftEntityMap(draft.entityMap);
    });
}

export function mapDraftEntityMap(entityMap: DraftEntityMap): DraftEntityMap {
    return produce(entityMap, (entityMap) => {
        for (const entity of Object.values(entityMap)) {
            if (entity.type !== DraftEntityTypes.EUREKA) continue;
            const { data } = entity;
            if (data.condition) data.condition = mapCondition(data.condition);
            if (isMappableEntityData(data)) {
                data.entityMap = mapDraftEntityMap(data.entityMap);
            }
        }
    });
}

export function isMultipleEntityValueEntityData(
    { path, idEntity }: EntityValuesDraftEntityData,
    entities: Record<string, Entity>,
    project: Project | undefined
): boolean {
    if (!path) {
        const projectEntity = project?.entities.find(
            (entity) => entity.idEntity == idEntity
        );
        return !!projectEntity?.multiple;
    }
    const parent = entities[path[path.length - 1]];
    const relationship = parent?.relationships.find(
        (rel) => rel.idEntity === idEntity
    );
    return !!relationship?.multiple;
}

export function isMultipleClientRelationshipEntityData(
    { path, idEntity }: ClientRelationshipDraftEntityData,
    entities: Record<string, Entity>,
    clientEntity: Entity | undefined
): boolean {
    if (!path) {
        const relationship = clientEntity?.relationships.find(
            (rel) => rel.idEntity === idEntity
        );
        return !!relationship?.multiple;
    }
    const parent = entities[path[path.length - 1]];
    const relationship = parent?.relationships.find(
        (rel) => rel.idEntity === idEntity
    );
    return !!relationship?.multiple;
}
