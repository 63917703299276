import { createAction } from '@reduxjs/toolkit';
import { Action } from '../../../@Types/@Types';
import * as TicketActions from '../../../@Types/Action';
import ActionTypes from '../../../constants/ActionTypes';

export const Types = {
    SET_IMG_MAX_WIDTH: 'SET_IMG_MAX_WIDTH',
    RESET: 'TICKETACTIONS/RESET',
    REFRESH: 'TICKETACTIONS/REFRESH',
    GET: 'TICKETACTIONS/GET_REQUEST',
    GET_SUCCESS: 'TICKETACTIONS/GET_SUCCESS',
    LOADING: 'TICKETACTIONS/LOADING',
    SHOW_CBR_POSVENTA: 'TICKETACTIONS/SHOW_CBR_POSVENTA',
    ADD_ACTION: 'TICKETACTIONS/ADD_ACTION',
    REMOVE_ACTION: 'TICKETACTIONS/REMOVE_ACTION',
    UPDATE_ACTION_TYPE: 'TICKETACTIONS/UPDATE_ACTION_TYPE',
    UPDATE_ACTION: 'TICKETACTIONS/UPDATE_ACTION',
    APPEND_ACTION: 'TICKETACTIONS/APPEND_ACTION',
    TO_BOTTOM: 'TICKETACTIONS/TO_BOTTOM',
};
/**
 * Called to set the current img Max width
 */
export const setImgMaxWidth = createAction<number>(Types.SET_IMG_MAX_WIDTH);

/**
 * Called when the tickets page is first loaded
 */
export const reset = createAction(Types.RESET);

/**
 * Called when the list needs soft resetting
 */
export const refresh = createAction(Types.REFRESH);

/**
 * Called to add a an action to the list
 */
export const addAction = createAction<TicketActions.Action>(Types.ADD_ACTION);

/**
 * Called to delete an action from the list
 */
export const removeAction = createAction<string>(Types.REMOVE_ACTION);

/**
 * Called to update the type of existing action
 */
export const updateActionType = createAction<{
    idAction: string;
    type: ActionTypes;
    date?: Date;
}>(Types.UPDATE_ACTION_TYPE);

export const updateAction = createAction<{
    idAction: string;
    update:
        | Partial<TicketActions.ReplyAction>
        | Partial<TicketActions.TempReplyAction>
        | Partial<TicketActions.CommentAction>;
}>(Types.UPDATE_ACTION);

export const appendAction = createAction<Action>(Types.APPEND_ACTION);

export const sendToTheBottom = createAction<{
    idAction: string;
}>(Types.TO_BOTTOM);

/**
 * Called to toggle the loader
 */
export const setLoading = createAction(
    Types.LOADING,
    function prepare(loading: boolean = true) {
        return {
            payload: loading,
        };
    }
);

/**
 * Called to toggle the loader
 */
export const setShowCBRPosVenta = createAction(
    Types.SHOW_CBR_POSVENTA,
    function prepare(show: boolean = true) {
        return {
            payload: show,
        };
    }
);

/**
 * Called when the list needs to get the elements
 */
export const pageScroll = createAction(Types.GET);

/**
 *  Called when the saga has sucessfully retrieved the lists elements
 */
export const getSuccess = createAction<{
    elements: Action[];
    numInteractions: number;
    numActions: number;
}>(Types.GET_SUCCESS);
