import { IconProps } from './@IconTypes';

function ProjectIcon({
    className,
    style,
    fill = '#3d5a7f',
}: IconProps): JSX.Element {
    return (
        <svg
            style={style}
            className={className}
            fill="none"
            version="1.1"
            viewBox="0 0 1290 855"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="1127"
                cy="103"
                r="75.5"
                stroke={fill}
                strokeWidth="43"
            />
            <circle
                cx="1127"
                cy="428"
                r="75.5"
                stroke={fill}
                strokeWidth="43"
            />
            <circle
                cx="1127"
                cy="747"
                r="75.5"
                stroke={fill}
                strokeWidth="43"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M937 77H1033V125H937V404H1033V452H937V721.007L937.333 721H1034V769H937.333L926.257 766.5L914.677 760.5L903.097 750L897.559 744L892.524 738L889 730V726V722V452H767V404H889V124V118V116L892.5 108L897.5 102L903 96L914.5 85.5L926 79.5L937 77Z"
                fill={fill}
            />
            <path
                d="M244.72 513.602C230.414 513.616 216.331 510.061 203.745 503.259C191.16 496.458 180.47 486.624 172.644 474.648C164.819 462.673 160.104 448.934 158.927 434.677C157.75 420.419 160.149 406.094 165.906 392.997C171.662 379.901 180.595 368.447 191.895 359.674C203.195 350.901 216.505 345.085 230.62 342.754C244.734 340.423 259.208 341.649 272.729 346.323C286.25 350.996 298.391 358.969 308.053 369.518H398.913C375.401 307.254 315.147 262.833 244.72 262.833C153.86 262.833 79.9584 336.736 79.9584 427.587C79.9584 518.439 153.86 592.381 244.72 592.381C314.949 592.381 375.052 548.206 398.715 486.21H307.571C299.546 494.859 289.821 501.756 279.006 506.47C268.191 511.183 256.518 513.611 244.72 513.602Z"
                fill={fill}
            />
            <path
                d="M694.181 271.027V290.257C671.515 274.423 639.477 262.833 607.818 262.833C537.36 262.833 477.193 307.238 453.625 369.503H544.477C556.161 356.74 571.424 347.799 588.27 343.846C605.116 339.893 622.763 341.113 638.904 347.347C655.046 353.581 668.933 364.538 678.75 378.787C688.567 393.036 693.858 409.915 693.932 427.219C694.006 444.522 688.859 461.446 679.164 475.778C669.469 490.111 655.677 501.187 639.589 507.558C623.501 513.929 605.866 515.3 588.986 511.492C572.107 507.683 556.769 498.872 544.975 486.21H453.823C477.486 548.206 537.589 592.381 607.818 592.381C638.728 592.255 668.861 582.687 694.181 564.957V587.124H776.174V271.027H694.181Z"
                fill={fill}
            />
            <path
                d="M656.228 427.247C656.223 437.989 652.652 448.425 646.077 456.919C639.501 465.413 630.292 471.485 619.895 474.182C609.497 476.878 598.498 476.048 588.623 471.82C578.748 467.593 570.555 460.208 565.329 450.822L565.092 450.411H287.7L287.478 450.822C282.281 460.312 274.063 467.794 264.129 472.081C254.196 476.368 243.115 477.215 232.645 474.488C222.175 471.76 212.916 465.614 206.336 457.025C199.757 448.436 196.235 437.896 196.328 427.077C196.421 416.258 200.125 405.78 206.851 397.305C213.578 388.831 222.941 382.845 233.457 380.299C243.972 377.752 255.037 378.79 264.895 383.248C274.754 387.706 282.841 395.328 287.874 404.906L288.103 405.325H564.435L564.656 404.906C569.668 395.267 577.769 387.588 587.662 383.1C597.556 378.612 608.669 377.574 619.223 380.153C629.776 382.731 639.159 388.776 645.869 397.321C652.578 405.865 656.226 416.414 656.228 427.278V427.247Z"
                fill="#ED6C4D"
            />
            <path
                d="M1155 102.5C1155 117.688 1142.69 130 1127.5 130C1112.31 130 1100 117.688 1100 102.5C1100 87.3122 1112.31 75 1127.5 75C1142.69 75 1155 87.3122 1155 102.5Z"
                fill="#ED6C4D"
            />
            <path
                d="M1155 427.5C1155 442.688 1142.69 455 1127.5 455C1112.31 455 1100 442.688 1100 427.5C1100 412.312 1112.31 400 1127.5 400C1142.69 400 1155 412.312 1155 427.5Z"
                fill="#ED6C4D"
            />
            <path
                d="M1155 746.5C1155 761.688 1142.69 774 1127.5 774C1112.31 774 1100 761.688 1100 746.5C1100 731.312 1112.31 719 1127.5 719C1142.69 719 1155 731.312 1155 746.5Z"
                fill="#ED6C4D"
            />
        </svg>
    );
}
export default ProjectIcon;
