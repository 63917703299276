import axiosInstance from '../../../AxiosAPI';
import {
    Action,
    AssignAgentsAction,
    AssignAreaAction,
    AutoscaleAction,
    ChangeProcessAction,
    ChangeResolutionDateAction,
    ClassifyAction,
    CommentAction,
    CreateAction,
    RemoveAgentsAction,
    ReplyAction,
    SetCompanyAction,
    SetEntityAction,
    TempReplyAction,
    TransferAgentAction,
    TransferAreaAction,
} from '../../../@Types/Action';
import { Agent } from '../../../@Types/Agent.js';
import Types from '../../../constants/ActionTypes';
import { Payload } from '../../../@Types/Payload';
import {
    addPendingReply,
    getTicketReply,
    RepliesCache,
} from '../../../utils/ReplyCache';
import { formatAction } from './utils';
import { AssignProcessRoles } from '../../../@Types/FlowTypes/NodeTypes/TransformationNode';
import ActivityPersonaTypes from '../../../constants/ActivityTypes/ActivityPersonaTypes';

const TIMEINTERVAL = 2 * 60 * 1000;

/**
 * Function that loads the ticket' action list
 * @param idProject the id of the current project
 * @param idTicket the id of the current ticket
 * @param idAgent
 * @returns a list of the actions
 */
export const loadActions = async (
    idProject: string,
    idTicket: string,
    idAgent: string
): Promise<{
    actions: Action[];
    numActions: number;
}> => {
    let url = `/projects/${idProject}/tickets/${idTicket}/actions`;
    let response = await axiosInstance.get(url);
    const replyCache = getTicketReply(idProject, idTicket);
    const actions: Action[] = [];
    let dateHasChanged = false;
    const numActions: number = response.data.length;
    /**
     * Collect multiple actions of the same type in a small time interval by the same user
     */
    let current: any | null = null;
    for (const action of response.data as Action[]) {
        formatAction(action);
        switch (action.type) {
            case Types.CLASSIFY:
                /** IF action is of CLASSIFY type and is of the same agent as the previous one and the time diff is less than the time interval */
                if (
                    current?.type === action.type &&
                    current.agent._id === action.agent._id &&
                    !(action as any).isSubTicket &&
                    Math.abs(
                        current.creation_date - action.creation_date.getTime()
                    ) < TIMEINTERVAL
                ) {
                    //   current.classifiers[action.idRoot] = { ...action }; To only show last change to each classifier
                    current.classifiers.push({ ...action });
                } else {
                    if (current) {
                        actions.push(current);
                    }
                    current = {
                        ...action,
                        classifiers: [{ ...action }],
                    };
                }
                break;
            case Types.AUTO_CLASSIFY:
                /** IF action is of AUTO_CLASSIFY type and is of the same flow as the previous one and the time diff is less than the time interval */
                if (
                    current?.type === action.type &&
                    // current.flow._id === action.flow._id &&
                    Math.abs(
                        current.creation_date - action.creation_date.getTime()
                    ) < TIMEINTERVAL
                ) {
                    current.classifiers.push({ ...action });
                } else {
                    if (current) {
                        actions.push(current);
                    }
                    current = {
                        ...action,
                        classifiers: [{ ...action }],
                    };
                }
                break;
            case Types.REMOVE_AGENT:
                /** IF action is of REMOVE_AGENT type and is of the same agent as the previous one and the time diff is less than the time interval */
                if (
                    current?.type === action.type &&
                    current.agent._id === action.agent._id &&
                    Math.abs(
                        current.creation_date - action.creation_date.getTime()
                    ) < TIMEINTERVAL
                ) {
                    current.removees.push({
                        ...(action as Action & { agentRemoved: Agent })
                            .agentRemoved,
                    });
                } else {
                    if (current) {
                        actions.push(current);
                    }
                    current = {
                        ...action,
                        removees: [
                            {
                                ...(action as Action & { agentRemoved: Agent })
                                    .agentRemoved,
                            },
                        ],
                    };
                }
                break;
            case Types.CHANGE_STATE:
                /** IF action is of CLASSIFY type and is of the same agent as the previous one and the time diff is less than the time interval */
                if (
                    current?.type === action.type &&
                    current.agent._id === action.agent._id &&
                    Math.abs(
                        current.creation_date - action.creation_date.getTime()
                    ) < TIMEINTERVAL
                ) {
                    current.states.push({ ...action });
                } else {
                    if (current) {
                        actions.push(current);
                    }
                    current = {
                        ...action,
                        states: [{ ...action }],
                    };
                }
                break;
            case Types.AUTO_CHANGE_STATE:
                if (
                    current?.type === action.type &&
                    // current.flow._id === action.flow._id &&
                    Math.abs(
                        current.creation_date - action.creation_date.getTime()
                    ) < TIMEINTERVAL
                ) {
                    current.states.push({ ...action });
                } else {
                    if (current) {
                        actions.push(current);
                    }
                    current = {
                        ...action,
                        states: [{ ...action }],
                    };
                }
                break;
            default:
                if (current !== null) {
                    actions.push(current);
                    current = null;
                }
                if (Object.values(Types).includes(action.type)) {
                    actions.push(action);
                }
                break;
        }
    }
    /** If current is not null at the end add it to the action list */
    if (current) {
        actions.push(current);
    }

    for (const action of actions) {
        //Esconde el primer auto resolution date.
        if (
            action.type === Types.AUTO_CHANGE_RESOLUTION_DATE &&
            !dateHasChanged &&
            idProject !== '62028aabd4aa0a001bdbbaac' //Melendez mostrar resolution date original
        ) {
            action.hideOriginal = true;
            dateHasChanged = true;
        }
        switch (action.type) {
            case Types.TEMP_REPLY:
                if (action.agent._id !== idAgent || !replyCache) {
                    addPendingReply(
                        idProject,
                        idTicket,
                        action._id,
                        action.agent._id === idAgent
                    );
                }
                break;
            default:
                break;
        }
    }

    return {
        numActions,
        actions,
    };
};

export const loadCreateAction = async (
    idProject: string,
    idTicket: string
): Promise<CreateAction> => {
    const response = await axiosInstance.get(
        `/projects/${idProject}/tickets/${idTicket}/actions/create`
    );
    formatAction(response.data);
    return response.data;
};

/**
 * Function called to save a comment made by the current user as an action to a ticket
 * @param idProject the id of the current project
 * @param idTicket the id of the current ticket
 * @param payload with the message and its contents
 */
export const comment = async (
    idProject: string,
    idTicket: string,
    payload: Payload
): Promise<CommentAction> => {
    let url = `/projects/${idProject}/tickets/${idTicket}/actions/comment`;
    return (await axiosInstance.post(url, { payload })).data;
};

export const editComment = async (
    idProject: string,
    idTicket: string,
    idAction: string,
    payload: Payload
): Promise<CommentAction> => {
    let url = `/projects/${idProject}/tickets/${idTicket}/actions/comment/${idAction}`;
    return (await axiosInstance.patch(url, { payload })).data;
};

/**
 * Function called to save a prereply made by the current user as an action to a ticket
 * @param idProject the id of the current project
 * @param idTicket the id of the current ticket
 * @param payload with the message and its contents
 */
export const preReply = async (
    idProject: string,
    idTicket: string,
    payload: Payload,
    tags: Record<string, boolean>
): Promise<void> => {
    let url = `/projects/${idProject}/tickets/${idTicket}/actions/prereply`;
    return (
        await axiosInstance.post(url, {
            payload,
            tags,
        })
    ).data;
};

/**
 * Function called to save a prereply made by the current user as an action to a ticket
 * @param idProject the id of the current project
 * @param idTicket the id of the current ticket
 * @param payload with the message and its contents
 */
export const preReplyRevision = async (
    idProject: string,
    idTicket: string,
    payload: Payload,
    comment: string,
    tags: Record<string, boolean>
): Promise<void> => {
    let url = `/projects/${idProject}/tickets/${idTicket}/actions/prereply`;
    return (
        await axiosInstance.patch(url, {
            comment,
            payload,
            tags,
        })
    ).data;
};

/**
 * Function called to save a prereply made by the current user as an action to a ticket
 * @param idProject the id of the current project
 * @param idTicket the id of the current ticket
 * @param payload with the message and its contents
 */
export const confirmPreReply = async (
    idProject: string,
    idTicket: string,
    payload: Payload,
    tags: Record<string, boolean>
): Promise<void> => {
    let url = `/projects/${idProject}/tickets/${idTicket}/actions/prereply/confirm`;
    return (
        await axiosInstance.post(url, {
            payload,
            tags,
        })
    ).data;
};
/**
 * Function called to save a reply to the client made by the current user as an action to a ticket
 * @param idProject the id of the current project
 * @param idTicket the id of the current ticket
 * @param payload with the message and its contents
 */
export const reply = async (
    idProject: string,
    idTicket: string,
    payload: Payload,
    tags: Record<string, boolean>,
    delay: number
): Promise<ReplyAction | TempReplyAction> => {
    let url = `/projects/${idProject}/tickets/${idTicket}/actions/reply`;
    return (
        await axiosInstance.post(url, {
            payload,
            tags,
            delay,
        })
    ).data;
};

export const deleteTempReply = async (
    idProject: string,
    idTicket: string,
    idAction: string
): Promise<void> => {
    let url = `/projects/${idProject}/tickets/${idTicket}/actions/reply/${idAction}`;
    await axiosInstance.delete(url);
};

export const persistTempReply = async (
    idProject: string,
    idTicket: string,
    idAction: string
): Promise<ReplyAction> => {
    let url = `/projects/${idProject}/tickets/${idTicket}/actions/reply/${idAction}`;
    return (await axiosInstance.patch(url)).data;
};

export const sendPendingReplies = async (
    repliesCache: RepliesCache
): Promise<void> => {
    let url = '/SendReplyCache';
    await axiosInstance.patch(url, repliesCache);
};

/**
 * Function called to update the state ticket
 * @param idProject the id of the current project
 * @param idTicket the id of the current ticket
 * @param idValue the id of the closing state
 */
export const changeState = async (
    idProject: string,
    idTicket: string,
    idValue: string
): Promise<void> => {
    let url = `/projects/${idProject}/tickets/${idTicket}/actions/changeState`;
    await axiosInstance.post(url, { idValue });
};

/**
 * Function called to update a tickes classifier
 * @param idProject id of the project the ticket is part of
 * @param idTicket id of the ticket to update
 * @param payload idRoot and idValue of the classification
 */
export const classify = async (
    idProject: string,
    idTicket: string,
    payload: { idRoot: string; idValue: string }
): Promise<ClassifyAction> => {
    let url = `/projects/${idProject}/tickets/${idTicket}/actions/classify`;
    return (await axiosInstance.post(url, payload)).data;
};

/**
 * Function called to assign multiple agents to a ticket
 * @param idProject id of the project the ticket is part of
 * @param idTicket id of the ticket to update
 * @param idAgents array of ids of agents to assign
 */
export const assignAgents = async (
    idProject: string,
    idTicket: string,
    idAgents: string[]
): Promise<AssignAgentsAction> => {
    let url = `/projects/${idProject}/tickets/${idTicket}/actions/assignAgents`;
    return (await axiosInstance.post(url, { agents: idAgents })).data;
};

/**
 * Function called to remove an agent from a ticket
 * @param idProject id of the project the ticket is part of
 * @param idTicket id of the ticket to update
 * @param idAgent if of the agent to remove
 */
export const removeAgent = async (
    idProject: string,
    idTicket: string,
    idAgent: string
): Promise<RemoveAgentsAction> => {
    let url = `/projects/${idProject}/tickets/${idTicket}/actions/removeAgent`;
    return (await axiosInstance.post(url, { idAgentRemove: idAgent })).data;
};

/**
 * Function called to transfer a ticket to an agent
 * @param idProject id of the project the ticket is part of
 * @param idTicket id of the ticket to update
 * @param idAgent id of the agent to transfer the ticket to
 * @param removeAll if removing all agents or just the user
 */
export const transferAgent = async (
    idProject: string,
    idTicket: string,
    idAgent: string,
    removeAll: boolean
): Promise<TransferAgentAction> => {
    let url = `/projects/${idProject}/tickets/${idTicket}/actions/transferAgent`;
    return (
        await axiosInstance.post(url, {
            idAgentTransfer: idAgent,
            removeAll,
        })
    ).data;
};

/**
 * Function called to identify a tickets' company
 * @param idProject id of the project the ticket is part of
 * @param idTicket id of the ticket to update
 * @param idCompany id of the to company
 */
export const setCompany = async (
    idProject: string,
    idTicket: string,
    idCompany: string
): Promise<SetCompanyAction> => {
    let url = `/projects/${idProject}/tickets/${idTicket}/actions/setCompany`;
    return (await axiosInstance.post(url, { idCompany })).data;
};

/**
 * Function called to identify a tickets' entity
 * @param idProject id of the project the ticket is part of
 * @param idTicket id of the ticket to update
 * @param idEntity id of the entity
 * @param idEntityValue id of the entityValue to set
 * @param values of the relationship
 */
export const setEntity = async (
    idProject: string,
    idTicket: string,
    idEntity: string,
    value: string[]
): Promise<SetEntityAction> => {
    let url = `/projects/${idProject}/tickets/${idTicket}/actions/setEntity/${idEntity}`;
    return (await axiosInstance.post(url, { value })).data;
};

/**
 * Function called to change a tickets' process
 * @param idProject id of the project the ticket is part of
 * @param idTicket id of the ticket to update
 * @param idProcess id of the process
 * @param roles dict of role data to assign
 * @param values of the relationship
 */
export const setProcessRoles = async (
    idProject: string,
    idTicket: string,
    pRoles: Record<string, (string | Agent)[]>
): Promise<ChangeProcessAction> => {
    let url = `/projects/${idProject}/tickets/${idTicket}/actions/setProcessRoles`;
    const roles: Record<string, string[]> = {};
    for (const [idRole, agents] of Object.entries(pRoles)) {
        roles[idRole] = agents.map((agent) =>
            typeof agent === 'string' ? agent : agent._id
        );
    }
    return (await axiosInstance.post(url, { roles })).data;
};

/**
 * Function called to change a tickets' process
 * @param idProject id of the project the ticket is part of
 * @param idTicket id of the ticket to update
 * @param idProcess id of the process
 * @param roles dict of role data to assign
 * @param values of the relationship
 */
export const setProcess = async (
    idProject: string,
    idTicket: string,
    idProcess: string,
    pRoles: AssignProcessRoles,
    values: Record<string, any>
): Promise<ChangeProcessAction> => {
    let url = `/projects/${idProject}/tickets/${idTicket}/actions/setProcess`;
    const roles: AssignProcessRoles = {};
    for (const [idRole, role] of Object.entries(pRoles)) {
        switch (role.type) {
            case ActivityPersonaTypes.AGENT:
                roles[idRole] = {
                    ...role,
                    agents: role.agents.map((agent) =>
                        typeof agent === 'string' ? agent : agent._id
                    ),
                };
                break;
            case ActivityPersonaTypes.AREA:
                roles[idRole] = {
                    ...role,
                    idArea:
                        typeof role.idArea === 'string'
                            ? role.idArea
                            : role.idArea._id,
                };
                break;
            case ActivityPersonaTypes.ENTITYVALUE:
                roles[idRole] = {
                    ...role,
                    idEntity:
                        typeof role.idEntity === 'string'
                            ? role.idEntity
                            : role.idEntity._id,
                    fallback: role.fallback?.map((agent) =>
                        typeof agent === 'string' ? agent : agent._id
                    ),
                };
                break;
            default:
                break;
        }
    }
    return (await axiosInstance.post(url, { idProcess, roles, values })).data;
};

/**
 * Function called to change a tickets' process
 * @param idProject id of the project the ticket is part of
 * @param idTicket id of the ticket to update
 * @param idProcess id of the process
 * @param roles dict of role data to assign
 * @param values of the relationship
 */
export const removeProcess = async (
    idProject: string,
    idTicket: string
): Promise<ChangeProcessAction> => {
    let url = `/projects/${idProject}/tickets/${idTicket}/actions/removeProcess`;
    return (await axiosInstance.post(url)).data;
};

/**
 * Function called to transfer a ticket to an area
 * @param idProject id of the project the ticket is part of
 * @param idTicket id of the ticket to update
 * @param idArea id of the Area to transfer to
 * @param removeAll if removing all agents or just the user
 */
export const transferArea = async (
    idProject: string,
    idTicket: string,
    idArea: string,
    removeAll: boolean
): Promise<TransferAreaAction> => {
    let url = `/projects/${idProject}/tickets/${idTicket}/actions/transferArea`;
    return (
        await axiosInstance.post(url, { idAreaTransfer: idArea, removeAll })
    ).data;
};

/**
 * Function called to assign a ticket to an area
 * @param idProject id of the project the ticket is part of
 * @param idTicket id of the ticket to update
 * @param idArea id of the Area to transfer to
 */
export const assignArea = async (
    idProject: string,
    idTicket: string,
    idArea: string
): Promise<AutoscaleAction> => {
    let url = `/projects/${idProject}/tickets/${idTicket}/actions/assignArea`;
    return (await axiosInstance.post(url, { idArea })).data;
};

/**
 * Function called to assign a ticket to an area
 * @param idProject id of the project the ticket is part of
 * @param idTicket id of the ticket to update
 * @param idArea id of the Area to transfer to
 */
export const autoscale = async (
    idProject: string,
    idTicket: string
): Promise<AssignAreaAction> => {
    return (
        await axiosInstance.post(
            `/projects/${idProject}/tickets/${idTicket}/actions/autoscale`
        )
    ).data;
};

/**
 * Function called to change the resolution date of a ticket
 * @param idProject id of the project the ticket is part of
 * @param idTicket id of the ticket to update
 * @param resolutionDate the new date.
 */
export const changeResolutionDate = async (
    idProject: string,
    idTicket: string,
    resolutionDate: Date
): Promise<ChangeResolutionDateAction> => {
    let url = `/projects/${idProject}/tickets/${idTicket}/actions/changeDate`;
    return (await axiosInstance.post(url, { resolution_date: resolutionDate }))
        .data;
};
export default {
    loadActions,
    preReplyRevision,
    confirmPreReply,
    preReply,
    comment,
    autoscale,
    editComment,
    reply,
    classify,
    setCompany,
    setEntity,
    changeState,
    assignAgents,
    removeAgent,
    transferAgent,
    transferArea,
    assignArea,
    changeResolutionDate,
    deleteTempReply,
    persistTempReply,
    sendPendingReplies,
};
