import { createAction } from '@reduxjs/toolkit';
import { Classifier } from '../../@Types/@Types';

export const Types = {
    RESET: 'CLASSIFIERS/RESET',
    REFRESH: 'CLASSIFIERS/REFRESH',
    LOADING: 'CLASSIFIERS/LOADING',
    GET_SUCCESS: 'CLASSIFIERS/GET_SUCCESS',
    UPDATE: 'CLASSIFIERS/UPDATE_ELEMENT',
    UPDATE_FAILED: 'CLASSIFIERS/UPDATE_FAILED',
    SELECT: 'CLASSIFIERS/SELECT_ELEMENT',
    SEARCH: 'CLASSIFIERS/SEARCH',
    ORDER: 'CLASSIFIERS/ORDER',
};

/**
 * Called when the classifiers page is first loaded
 */
export const reset = createAction(Types.RESET);

/**
 * Called when the list needs soft resetting
 */
export const refresh = createAction(Types.REFRESH);

/**
 * Called to toggle the loader
 */
export const setLoading = createAction(
    Types.LOADING,
    function prepare(loading: boolean = true) {
        return {
            payload: loading,
        };
    }
);

/**
 * Called when the selectedElement (id) changes value
 */
export const selectElement = createAction<string>(Types.SELECT);

/**
 * Called when a value of the selected classifier is updated
 */
export const updateElement = createAction<{
    object: Classifier;
    field: keyof Classifier;
    value: string;
}>(Types.UPDATE);

export const updateFailed = createAction<Classifier>(Types.UPDATE_FAILED);
/**
 *  Called when the saga has sucessfully retrieved the lists elements
 */
export const getSuccess = createAction<{
    elements: Record<string, Classifier>;
    roots: string[];
}>(Types.GET_SUCCESS);

/**
 * Called when the search value changes
 */
export const search = createAction<string | undefined>(Types.SEARCH);

/**
 *  Called when the orderby value changes
 */
export const orderBy = createAction<string>(Types.ORDER);
