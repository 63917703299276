import { RouteComponentProps, withRouter, match } from 'react-router-dom';
import { RouterProps } from '../../Router';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../utils/_store';
import {
    goTo,
    pageScroll,
    refresh,
    reset,
    search,
    selectElement,
    setLoading,
    updateElement,
    updatePayload,
} from '../../controllers/TemplatesController/TemplatesActions';
import Dialog from '../../shared/Dialog/Dialog';
import ListDetailLayout from '../../shared/ListDetailLayout/ListDetailLayout';
import PageTitle from '../../shared/PageTitle/PageTitle';
import RoundedButton from '../../shared/RoundedButton/RoundedButton';
import Search from '../../shared/Search/Search';
import List from '../../shared/List/List';
import TemplateListItem from './TemplateListItem/TemplateListItem';
import { templatesToQueryString } from '../../controllers/TemplatesController/TemplatesReducer';
import { Template } from '../../@Types/Template';
import TemplateDetail from './TemplateDetail/TemplateDetail';
import CreateTemplate from './CreateTemplate/CreateTemplate';
import TemplatesService from '../../controllers/TemplatesController/TemplatesService';
import { Payload } from '../../@Types/Payload';

interface IdTemplateRouteParam {
    /** Current Ticket */
    idTemplate?: string;
    /** The id of the currentProject */
    idProject: string;
}
interface TemplatesPageProps extends RouteComponentProps {
    match: match<IdTemplateRouteParam>;
}

function TemplatesPage({
    history,
    location,
    match: {
        params: { idTemplate, idProject },
    },
}: TemplatesPageProps & RouterProps): JSX.Element {
    const dispatch = useDispatch();
    const pageInfo = useSelector((state: RootState) => state.templatesPage);
    const listRef = useRef<HTMLDivElement>(null);
    const siteInfo = useSelector((state: RootState) => state.site);
    const [createDialog, setCreateDialog] = useState(false);

    useEffect(() => {
        if (listRef.current) listRef.current.scrollTop = 0;
        dispatch(reset({ history, queryString: location.search, idTemplate }));
    }, [siteInfo.idProject]);

    useEffect(() => {
        window.addEventListener('popstate', handleGoBack);
        return (): void => {
            window.removeEventListener('popstate', handleGoBack);
        };
    }, [siteInfo.idProject]);

    function handleGoBack(): void {
        const paths = window.location.pathname.split('/');
        if (paths[2] !== 'templates') return;
        const idTemplate = paths[3];
        //If same project
        if (paths.length > 2 && paths[1] == idProject) {
            dispatch(
                goTo({
                    history,
                    idTemplate,
                    queryString: window.location.search,
                })
            );
        }

        if (listRef.current) scrollToSelected(idTemplate, listRef);
    }

    function scrollToSelected(selected: any, listRef: any): void {
        const selectedNode = listRef.current.querySelector(
            '.selected-item-shadow'
        );
        if (selected && selectedNode) {
            listRef.current.scrollTo(
                0,
                selectedNode.offsetTop - listRef.current.offsetTop - 10
            );
        } else {
            listRef.current.scrollTop = 0;
        }
    }

    const updateUrl = (
        filters: {},
        orderBy: string | undefined,
        search: string | undefined,
        type: 'FILTER' | 'SEARCH' | 'ORDER'
    ): void => {
        let url = `/${idProject}/templates`;
        const filtersUrl = templatesToQueryString(
            filters,
            orderBy,
            search,
            true
        );
        const urlPayload = {
            pathname: url,
            search: filtersUrl,
            state: { changeType: type },
        };
        if ((history.location?.state as any)?.changeType) {
            history.replace(urlPayload);
        } else {
            history.push(urlPayload);
        }
    };
    return (
        <React.Fragment>
            {createDialog && (
                <Dialog
                    open={createDialog}
                    onClose={(): void => setCreateDialog(false)}
                >
                    <CreateTemplate
                        handleCreated={(): void => {
                            // TODO: Cuando hayan filtros toca activar el de ordenar por fecha de creación para que el nuevo quede de primeras
                            //Es llamar un action que le ponga loading cambie filtros y vuelva a pedir todo.
                            dispatch(refresh());
                            if (listRef.current) listRef.current.scrollTop = 0;
                        }}
                        close={(): void => {
                            setCreateDialog(false);
                        }}
                    />
                </Dialog>
            )}
            <div className="standard-layout">
                <PageTitle
                    title={'Plantillas'}
                    route={siteInfo.organization?.name + ' / plantillas'}
                />
                <div className="filters-container flexbox">
                    <div data-testid={'TemplatesPage_NewButton'}>
                        <RoundedButton
                            text="Nueva"
                            backgroundColor={'var(--secondary)'}
                            color={'white'}
                            onClick={(): void => {
                                setCreateDialog(true);
                            }}
                        />
                    </div>
                    <div className="search-container">
                        <Search
                            search={pageInfo.search}
                            handleLoadingSearch={(loading: boolean): void => {
                                dispatch(setLoading(loading));
                            }}
                            handleSearch={(value: string): void => {
                                /** Refresh the list and send it back to the top */
                                if (listRef.current)
                                    listRef.current.scrollTop = 0;
                                dispatch(search(value));
                                updateUrl(
                                    pageInfo.filters,
                                    pageInfo.orderBy,
                                    value,
                                    'SEARCH'
                                );
                            }}
                        />
                    </div>
                </div>
                <ListDetailLayout
                    listWidth={450}
                    responsiveBreakpoint={1000}
                    selectElementLabel="Selecciona una plantilla"
                    loading={pageInfo.loading}
                    selectedElement={
                        pageInfo.selectedElement !== undefined
                            ? pageInfo.elements[pageInfo.selectedElement]
                            : undefined
                    }
                    handleSelectedElement={(element?: Template): void => {
                        const index = pageInfo.elements.findIndex(
                            (listElement) => listElement._id === element?._id
                        );
                        history.push({
                            pathname: `/${idProject}/templates${
                                element ? '/' + element._id : ''
                            }`,
                            search: history.location.search,
                            state: {
                                prevPath: history.location.pathname,
                            },
                        });
                        dispatch(selectElement(index));
                    }}
                >
                    <List
                        elements={pageInfo.elements}
                        firstPage={pageInfo.pageUp === 1}
                        lastPage={pageInfo.lastPage}
                        listRef={listRef}
                        listId={'templates-infiniteList'}
                        onPageScroll={(direction): void => {
                            dispatch(pageScroll(direction));
                        }}
                        firstFocusScroll={scrollToSelected}
                        label="No hay plantillas"
                    >
                        <TemplateListItem />
                    </List>
                    <TemplateDetail
                        handleUpdate={(
                            object: Template,
                            field: keyof Omit<Template, 'payload'>,
                            value: string
                        ): void => {
                            dispatch(updateElement({ object, field, value }));
                        }}
                        handleUpdatePayload={(
                            object: Template,
                            value: Payload
                        ): void => {
                            dispatch(
                                updatePayload({
                                    object,
                                    field: 'payload',
                                    value,
                                })
                            );
                        }}
                        handleDelete={async (
                            object: Template
                        ): Promise<void> => {
                            await TemplatesService.deleteTemplate(
                                object._id,
                                idProject
                            );
                            dispatch(refresh());
                        }}
                    />
                </ListDetailLayout>
            </div>
        </React.Fragment>
    );
}

export default withRouter((props: any) => <TemplatesPage {...props} />);
