import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';

interface StyleProps {
    /** The color of the text */
    textColor?: string;
    /** The color of the outline when selected and hovered on */
    focusColor?: string;
    /** The color of the error to display */
    errorColor?: string;
    /** The color of the outline when it is not selected */
    outlineColor?: string;
    /** The backgroundColor of the input */
    backgroundColor?: string;
    /** The height of the container */
    height?: string;
    /** The borderRadius of the input */
    borderRadius?: number;
    /** If input is readOnly */
    readOnly?: boolean;
    /** The padding of the input */
    padding?: string;
    /** The size of the font to display the input in */
    fontSize?: number | string;
    /** The weight of the font of the value and the placeholder */
    fontWeight?: number | string;
    /** If the input is multiline */
    multiline?: boolean;
    /** Cant edit */
    cantEdit?: boolean;
    /** The color of the helper text when not error */
    helperTextColor?: string;
    /** The icon to display */
    icon?: React.ReactNode;
    /** material ui style seed */
    seed?: string;
    /** The max length of the string */
    maxLength?: number;
}

const useTextfieldStyles = (props: StyleProps): Function =>
    makeStyles(() => ({
        root: {
            backgroundColor: props.backgroundColor,
            borderRadius: props.borderRadius,
            [`& .${props.seed}MuiInputBase-root`]: {
                height: props.multiline ? '100%' : props.height,
            },
            '& input, textarea': {
                cursor: props.cantEdit ? 'default' : 'text',
                padding: props.multiline ? '0px' : props.padding,
                fontWeight: props.fontWeight,
                color: props.textColor,
                fontSize: props.fontSize,
                '&::placeholder': {
                    fontSize: props.fontSize,
                },
                backgroundColor: props.backgroundColor,
                borderRadius: props.multiline ? '0px' : props.borderRadius,
            },
            '& label': {
                marginTop: props.height != '40px' ? '-4px' : '0px',
                fontSize: props.fontSize,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 'calc(100% - 22px)',
            },
            [`& label.${props.seed}MuiInputLabel-shrink`]: {
                marginTop: '0px',
                maxWidth: 'calc(100% - 5px)',
            },
            '& input + fieldset': {
                borderRadius: props.borderRadius,
            },
            [`& .${props.seed}MuiInput-underline:after`]: {
                borderBottomColor: props.outlineColor,
            },
            [`& .${props.seed}MuiOutlinedInput-root`]: {
                borderRadius: props.borderRadius,
                '&.Mui-focused fieldset': {
                    borderColor: props.focusColor,
                    borderWidth: props.readOnly ? 1 : 2,
                },
                [`& .${props.seed}MuiOutlinedInput-notchedOutline`]: {
                    borderColor: props.outlineColor,
                    '& legend': {
                        fontSize: 'calc(' + props.fontSize + ' * 0.75)',
                    },
                },
            },
            [`& .${props.seed}MuiOutlinedInput-root:hover .${props.seed}MuiOutlinedInput-notchedOutline`]:
                {
                    borderColor: props.cantEdit
                        ? props.outlineColor
                        : props.focusColor,
                },
            '& label.Mui-focused': {
                color: props.focusColor,
            },
            '& .Mui-error': {
                color: props.errorColor,
            },
            [`& .${props.seed}MuiOutlinedInput-adornedEnd`]: {
                paddingRight: '7px',
            },
            [`& .${props.seed}MuiFormHelperText-root`]: {
                color: props.helperTextColor,
                marginRight: props.maxLength ? 40 : 14,
            },
            [`& .${props.seed}MuiOutlinedInput-multiline`]: {
                padding: props.padding,
                backgroundColor: props.backgroundColor,
            },
        },
    }));

interface RoundedTextFieldProps extends StyleProps {
    /** The value of the textfield */
    value?: any;
}
function CustomTextfield({
    focusColor = 'var(--secondary)',
    helperTextColor = '#989898',
    outlineColor = 'var(--outlineGrey)',
    backgroundColor = 'white',
    textColor = 'var(--accent)',
    readOnly = false,
    borderRadius = 10,
    padding = '6px 12px',
    fontSize = '1rem',
    seed = 'Erk-',
    height,
    errorColor = 'var(--error)',
    fontWeight = '300',
    multiline = false,
    cantEdit = false,
    maxLength,
    value,
    icon,
    ...others
}: TextFieldProps & RoundedTextFieldProps): JSX.Element {
    const classes = useTextfieldStyles({
        height,
        padding,
        textColor,
        errorColor,
        focusColor,
        outlineColor,
        helperTextColor,
        backgroundColor,
        borderRadius,
        readOnly,
        fontSize,
        fontWeight,
        multiline,
        cantEdit,
        seed,
        maxLength,
    })();

    const length = maxLength ? value?.length : null;
    const inputProps: any = {
        readOnly,
        disabled: cantEdit,
        endAdornment: icon,
        spellCheck: true,
    };
    if (maxLength) {
        inputProps.inputProps = { maxLength };
    }
    return (
        <div style={{ position: 'relative', width: '100%', maxWidth: '100%' }}>
            <TextField
                classes={classes}
                {...others}
                value={value ?? ''}
                multiline={multiline}
                variant="outlined"
                size="small"
                fullWidth
                InputProps={inputProps}
            />
            {length !== null && (
                <div
                    style={
                        others.helperText
                            ? {
                                  position: 'absolute',
                                  bottom: '3px',
                                  right: '8px',
                                  fontSize: '12px',
                                  color: helperTextColor,
                              }
                            : {
                                  fontSize: '12px',
                                  color: helperTextColor,
                                  textAlign: 'right',
                                  paddingRight: '8px',
                                  paddingTop: '2px',
                                  paddingBottom: '2px',
                              }
                    }
                >
                    {length}/{maxLength}
                </div>
            )}
        </div>
    );
}

/**
 * Generic textfield with apps designs. Is class do to the use in the react-hook-forms library
 */
class RoundedTextField extends React.Component<
    RoundedTextFieldProps & TextFieldProps
> {
    render(): JSX.Element {
        return <CustomTextfield {...this.props} />;
    }
}
export default RoundedTextField;
