import styles from './Classifier.module.css';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import React, { useState } from 'react';
import { Classifier } from '../../../../@Types/Classifier';
interface ClassifierProps {
    selected: boolean;
    /** Classifier to show */
    classifier: Classifier;
    /** Function to call when the classifier is clicked */
    handleSelect: Function;
    /** Function to call when the user wants to remove the classifier */
    handleRemove: () => void;
}
/**
 * Component that displays a classifier in the classifier list
 */
function ClassifierComponent({
    selected,
    classifier,
    handleRemove,
    handleSelect,
}: ClassifierProps): JSX.Element {
    const [hovering, setHovering] = useState(false);

    /**
     * Function that renders the text with the search highlighted
     * @returns a string with span highlighted
     */
    const renderText = (text: string): any => {
        if (text) {
            var parts: any = text.split(/<SEARCH:(.*?)>/gi);
            for (var i = 1; i < parts.length; i += 2) {
                parts[i] = (
                    <span className={styles.search} key={i}>
                        {parts[i]}
                    </span>
                );
            }
            return parts;
        }
    };

    return (
        <div
            className={styles.container}
            onMouseEnter={(): void => {
                setHovering(true);
            }}
            onMouseLeave={(): void => {
                setHovering(false);
            }}
            onClick={(): void => handleSelect()}
            style={{
                borderBottom: '1px solid var(--light-grey)',
            }}
        >
            <div className={styles.top}>
                <div
                    className={styles.colorIcon}
                    style={{ backgroundColor: classifier.color?.background }}
                ></div>
                <label className={styles.nameLbl} title={classifier.name}>
                    <span className={styles.rootLbl}>
                        {renderText(classifier.name)}
                    </span>
                </label>
                {selected && (
                    <div
                        className={styles.closeIcon}
                        onClick={(e): void => {
                            e.preventDefault();
                            handleRemove();
                        }}
                    >
                        {hovering ? (
                            <CloseRoundedIcon fontSize="inherit" />
                        ) : (
                            <CheckRoundedIcon fontSize="inherit" />
                        )}
                    </div>
                )}
            </div>
            {classifier.description?.trim() !== '' && (
                <label className={styles.bottom} title={classifier.description}>
                    {renderText(classifier.description)}
                </label>
            )}
        </div>
    );
}

export default ClassifierComponent;
