import Types from '../../../constants/ActionTypes';
import TYPES from '../../../constants/ActionTypes';
import { Action } from '../../../@Types/Action';
import { Agent } from '../../../@Types/Agent';
import { ActionsPageState } from './ActionsReducer';
import { ActivityTypes } from '../../../constants/ActivityTypes/ActivityTypes';

const TIMEINTERVAL = 2 * 60 * 1000;

export function formatAction(action: Action): void {
    if (action.creation_date)
        action.creation_date = new Date(action.creation_date);
    switch (action.type) {
        case Types.ACTIVITY:
            switch (action.activityType) {
                case ActivityTypes.SCHEDULE:
                case ActivityTypes.SCHEDULE_CANCEL:
                    action.scheduleDate = {
                        startDate: new Date(action.scheduleDate.startDate),
                        endDate: new Date(action.scheduleDate.endDate),
                    };
                    break;
                default:
                    break;
            }
            break;
        case Types.AUTO_CHANGE_RESOLUTION_DATE:
        case Types.CHANGE_RESOLUTION_DATE:
            action.original_resolution_date = new Date(
                action.original_resolution_date
            );
            action.resolution_date = new Date(action.resolution_date);
            break;
        case Types.COMMENT:
            if (action.history) {
                action.history.lastEdit = new Date(action.history.lastEdit);
                for (const version of action.history.versions) {
                    version.creation_date = new Date(version.creation_date);
                }
            }
            break;
        default:
            break;
    }
}

export function genericAppendRule(
    action: Action,
    actionsPageState: ActionsPageState
): void {
    let list = actionsPageState.elements;
    appendNode(action, list, list.length ? list.length - 1 : list.length);
}

function appendNode(action: Action, list: Action[], index: number): void {
    if (index === -1) {
        list.unshift(action);
        return;
    }
    const compare = list[index];
    if (compare && compare.creation_date > action.creation_date) {
        appendNode(action, list, index - 1);
    } else {
        switch (action.type) {
            case Types.CLASSIFY:
                if (
                    compare?.type === TYPES.CLASSIFY &&
                    compare.agent._id === action.agent._id &&
                    !(action as any).isSubTicket &&
                    Math.abs(
                        compare.creation_date.getTime() -
                            action.creation_date.getTime()
                    ) < TIMEINTERVAL
                ) {
                    const actions = compare.classifiers;
                    let subIndex = actions.length;
                    for (const i = 0; i < actions.length; i) {
                        if (
                            (actions[subIndex - 1] as unknown as Action)
                                .creation_date < action.creation_date
                        ) {
                            break;
                        }
                        subIndex -= 1;
                    }
                    actions.splice(subIndex, 0, action as any);
                } else {
                    list.push({
                        ...action,
                        classifiers: [{ ...action } as any],
                    });
                }
                break;
            case Types.AUTO_CLASSIFY:
                /** IF action is of AUTO_CLASSIFY type and is of the same flow as the previous one and the time diff is less than the time interval */
                if (
                    compare?.type === TYPES.AUTO_CLASSIFY &&
                    Math.abs(
                        compare.creation_date.getTime() -
                            action.creation_date.getTime()
                    ) < TIMEINTERVAL
                ) {
                    const actions = compare.classifiers;
                    let subIndex = actions.length;
                    for (const i = 0; i < actions.length; i) {
                        if (
                            (actions[subIndex - 1] as unknown as Action)
                                .creation_date < action.creation_date
                        ) {
                            break;
                        }
                        subIndex -= 1;
                    }
                    actions.splice(subIndex, 0, action as any);
                } else {
                    list.push({
                        ...action,
                        classifiers: [{ ...action } as any],
                    });
                }
                break;
            case Types.REMOVE_AGENT:
                /** IF action is of REMOVE_AGENT type and is of the same agent as the previous one and the time diff is less than the time interval */
                const act = action as typeof action & { agentRemoved: Agent };
                if (
                    compare?.type === TYPES.REMOVE_AGENT &&
                    compare.agent._id === action.agent._id &&
                    !(action as any).isSubTicket &&
                    Math.abs(
                        compare.creation_date.getTime() -
                            action.creation_date.getTime()
                    ) < TIMEINTERVAL
                ) {
                    const actions = compare.removees;
                    let subIndex = actions.length;
                    for (const i = 0; i < actions.length; i) {
                        if (
                            (actions[subIndex - 1] as unknown as Action)
                                .creation_date < action.creation_date
                        ) {
                            break;
                        }
                        subIndex -= 1;
                    }
                    actions.splice(subIndex, 0, action as any);
                } else {
                    list.push({
                        ...action,
                        removees: [{ ...act.agentRemoved }],
                    });
                }
                break;
            case Types.CHANGE_STATE:
                /** IF action is of CLASSIFY type and is of the same agent as the previous one and the time diff is less than the time interval */
                if (
                    compare?.type === TYPES.CHANGE_STATE &&
                    compare.agent._id === action.agent._id &&
                    !(action as any).isSubTicket &&
                    Math.abs(
                        compare.creation_date.getTime() -
                            action.creation_date.getTime()
                    ) < TIMEINTERVAL
                ) {
                    //   current.classifiers[action.idRoot] = { ...action }; To only show last change to each classifier
                    const actions = compare.states;
                    let subIndex = actions.length;
                    for (const i = 0; i < actions.length; i) {
                        if (
                            (actions[subIndex - 1] as unknown as Action)
                                .creation_date < action.creation_date
                        ) {
                            break;
                        }
                        subIndex -= 1;
                    }
                    actions.splice(subIndex, 0, action as any);
                } else {
                    list.push({ ...action, states: [{ ...action } as any] });
                }
                break;
            case Types.AUTO_CHANGE_STATE:
                if (
                    compare?.type === TYPES.AUTO_CHANGE_STATE &&
                    Math.abs(
                        compare.creation_date.getTime() -
                            action.creation_date.getTime()
                    ) < TIMEINTERVAL
                ) {
                    const actions = compare.states;
                    let subIndex = actions.length;
                    for (const i = 0; i < actions.length; i) {
                        if (
                            (actions[subIndex - 1] as unknown as Action)
                                .creation_date < action.creation_date
                        ) {
                            break;
                        }
                        subIndex -= 1;
                    }
                    actions.splice(subIndex, 0, action as any);
                } else {
                    list.push({ ...action, states: [{ ...action } as any] });
                }
                break;
            default:
                list.push(action);
                break;
        }
    }
}
