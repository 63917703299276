import React, { useEffect, useState, useRef } from 'react';
import { cloneElement } from 'react';
import styles from './List.module.css';
import Loader from '../Loader/Loader';
import ScrollTypes from '../../constants/ScrollTypes';

interface ListProps {
    /** Ref of the list container so that the parent can controll the scroll position */
    listRef: any;
    /** The type of Component to display the children in */
    children: any;
    /** If the list should show the loader */
    loading?: boolean;
    /** The elements to display */
    elements: any;
    /** The label to show when no elements are found*/
    label: string;
    /** The function to call when element has been clicked on */
    handleItemClick?: (selectedElement: any) => void;
    /** The currently selected element */
    selectedElement?: any;
    /** Function to call when user wants to load more elements */
    onPageScroll?: (direction: ScrollTypes) => void;
    /** Bolean that indicates if need to change the focus element */
    firstFocusScroll?: (selected: any, listRef: any) => void;
    /** The padding to the left of the list */
    paddingLeft?: number;
    /** The padding to the right of the list */
    paddingRight?: number;
    /** The padding to the bottom of the list */
    paddingBottom?: number;
    /** If no more elements are available to display, backend has nothing left */
    lastPage: boolean;
    /** If no more elements are available to display in the upper, backend has nothing left */
    firstPage?: boolean;
    listId?: string;
    width?: string;
}

/**
 * Generic List element to display multiple items with paged information
 */
function List({
    listRef,
    children,
    loading,
    elements,
    label,
    handleItemClick,
    selectedElement,
    onPageScroll,
    firstFocusScroll,
    paddingRight = 0,
    paddingLeft = 25,
    paddingBottom = 0,
    lastPage,
    firstPage = true,
    listId,
    width,
}: ListProps): JSX.Element {
    // Is false if the Interaction observer has been mounted
    const [firstTimeScroll, setFirstTimeScroll] = useState(true);

    useEffect(() => {
        if (loading && elements.length === 0 && !firstTimeScroll) {
            setFirstTimeScroll(true);
        }
        if (!loading && firstTimeScroll && firstFocusScroll) {
            firstFocusScroll(selectedElement, listRef);
            setFirstTimeScroll(false);
        }
    }, [loading, elements.length]);

    return (
        <div
            ref={listRef}
            className={styles.infiniteList}
            id={listId}
            style={{
                paddingLeft,
                paddingRight,
                paddingBottom,
                width,
            }}
            data-testid={'List_Container'}
        >
            {loading && (
                <div
                    className={styles.loaderContainer + ' center-anything'}
                    style={{ color: 'var(--secondary)' }}
                >
                    <Loader zIndex={2} />
                </div>
            )}
            {!loading && elements && elements.length === 0 && (
                <h5 className="center-anything">{label}</h5>
            )}
            {!firstPage && !loading && (
                <ListLoader
                    handleLoadMore={(): void => {
                        onPageScroll?.(ScrollTypes.UP);
                    }}
                />
            )}
            {!loading &&
                elements &&
                elements.map((element: any, i: number) =>
                    cloneElement(children, {
                        key: i,
                        index: i,
                        element,
                        handleItemClick,
                        selectedElement,
                    })
                )}
            {!lastPage && !loading && (
                <ListLoader
                    marginTop={-38}
                    handleLoadMore={(): void => {
                        onPageScroll?.(ScrollTypes.DOWN);
                    }}
                />
            )}
        </div>
    );
}

export default List;

interface ListLoaderProps {
    marginTop?: React.CSSProperties['marginTop'];
    handleLoadMore: () => void;
}

function ListLoader({
    marginTop,
    handleLoadMore,
}: ListLoaderProps): JSX.Element {
    const ref = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState(false);

    const callbackFunction = ([entry]: IntersectionObserverEntry[]): void => {
        if (!entry.isIntersecting && loading) setLoading(false);
        else if (entry.isIntersecting && !loading) {
            handleLoadMore();
            setLoading(true);
        }
    };

    const canAutoLoad = 'IntersectionObserver' in window;

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '20px',
            threshold: 0.5,
        };
        const observer = new IntersectionObserver(callbackFunction, options);
        if (canAutoLoad && ref.current) observer.observe(ref.current);
        return (): void => {
            if (canAutoLoad && ref.current) observer.unobserve(ref.current);
        };
    }, [ref, canAutoLoad]);

    if (canAutoLoad) {
        return (
            <div ref={ref} style={{ marginTop }} className={styles.loaderDots}>
                <span className={styles.loaderDot}>.</span>
                <span className={styles.loaderDot}>.</span>
                <span className={styles.loaderDot}>.</span>
            </div>
        );
    } else {
        return (
            <div className={styles.loadMoreBtn} onClick={handleLoadMore}>
                Cargar Más
            </div>
        );
    }
}
