/**
 * Function returns if the color recieved should have a white or black font depending on its contrast
 * @param bgColor the hexcolor to check
 * @returns the hex string of white or black depending on the contrast with the given color
 */
export function calcTextColor(bgColor: string): string {
    var color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#ffffff';
}
