import styles from './ActionBar.module.css';
import React, {
    useState,
    useRef,
    useEffect,
    useMemo,
    useCallback,
} from 'react';
import ActionsService from '../../../../controllers/TicketsController/Actions/ActionsService';
import {
    appendAction,
    updateAction,
} from '../../../../controllers/TicketsController/Actions/ActionsActions';
import { useSelector } from 'react-redux';
import { Ticket } from '../../../../@Types/@Types';
import { RootState } from '../../../../utils/_store';
import PayloadConfirmation from './PayloadConfirmation/PayloadConfirmation';
import Dialog from '../../../../shared/Dialog/Dialog';
import { ProjectPermissions } from '../../../../constants/Permissions';
import { useTicketPermission } from '../../../../utils/PermissionsFunctions';
import * as RepliesCache from '../../../../utils/ReplyCache';
import CustomTimeout from '../../../../utils/CustomTimeout';
import ActionTypes from '../../../../constants/ActionTypes';
import VanillaToasts from '../../../../shared/Toast/Toast';
import { ChannelTypes } from '../../../../constants/ChannelTypes';
import PayloadEditor from '../../../../shared/TextEditor/PayloadEditor';
import { Payload } from '../../../../@Types/Payload';
import {
    PayloadEditorActions,
    usePayloadEditorDispatch,
} from '../../../../controllers/PayloadEditorController/PayloadEditorSlice';
import ActionBarResizer from './ActionBarResizer';
import { useCurrentProject } from '../../../../hooks';
import StateTypes from '../../../../constants/StateTypes';
import {
    generateAIComment,
    generateAIReply,
} from '../../../../controllers/PayloadEditorController/PayloadEditorService';
import { EurekaDraft } from '../../../../@Types/Draft/Draft';

interface ActionBarProps {
    /** The currently selected Ticket */
    selectedElement: Ticket;
    /** If the current ticket is closed */
    isClosed: boolean;
}

export enum ActionTabs {
    COMMENT = 'COMMENT',
    REPLY = 'REPLY',
    PRE_REPLY = 'PRE_REPLY',
}

function ActionBar({ selectedElement, isClosed }: ActionBarProps): JSX.Element {
    const project = useCurrentProject();
    const [selectedTab, setSelectedTab] = useState<ActionTabs | undefined>(
        undefined
    );
    const [showConfirmation, setShowConfirmation] = useState<
        Payload | undefined
    >(undefined);

    const titlesRef = useRef<HTMLDivElement>(null);
    const actionBarRef = useRef<HTMLDivElement>(null);

    const dispatch = usePayloadEditorDispatch();
    const actionsPage = useSelector((state: RootState) => state.ticketActions);

    const canComment = useTicketPermission(selectedElement, null);

    /** Closes Actionbar when case closes. */
    useEffect(() => {
        if (
            selectedElement.state.type === StateTypes.CLOSED &&
            selectedTab !== undefined
        ) {
            if (selectedTab === ActionTabs.COMMENT && canComment) return;
            toggleTab(
                selectedTab === ActionTabs.REPLY && canComment
                    ? ActionTabs.COMMENT
                    : undefined
            )();
        }
    }, [canComment, selectedElement.state]);

    const placeholder = useMemo((): string => {
        switch (selectedTab) {
            case ActionTabs.REPLY:
                return 'Ingrese su respuesta al cliente';
            case ActionTabs.COMMENT:
                return 'Ingrese su comentario interno';
            default:
                return '';
        }
    }, [selectedTab]);

    const handleSend = useCallback(
        async (payload: Payload): Promise<boolean | void> => {
            if (
                payload &&
                (payload.text !== '' ||
                    (payload.files && payload.files.length > 0))
            ) {
                try {
                    if (selectedTab === ActionTabs.COMMENT) {
                        try {
                            const action = await ActionsService.comment(
                                selectedElement.idProject,
                                selectedElement._id,
                                payload
                            );
                            dispatch(appendAction(action));
                        } catch (error) {
                            VanillaToasts.create({
                                title: 'Error al crear el comentario',
                                text: error,
                                type: 'error',
                                timeout: 3000,
                            });
                            console.error(error);
                        }
                    } else {
                        setShowConfirmation(payload);
                        return false;
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        },
        [selectedTab, selectedElement, dispatch]
    );

    const handleConfirmReply = async (
        tags: Record<string, boolean>
    ): Promise<void> => {
        if (!showConfirmation) return;
        if (project.skipTempReply) {
            try {
                const action = await ActionsService.reply(
                    selectedElement.idProject,
                    selectedElement._id,
                    showConfirmation,
                    tags,
                    0
                );
                dispatch(appendAction(action));
            } catch (error) {
                VanillaToasts.create({
                    title: 'Error al crear el comentario',
                    text: error,
                    type: 'error',
                    timeout: 3000,
                });
                console.error(error);
            }
            dispatch({
                ...PayloadEditorActions.reset(),
                meta: { idEditor: selectedElement._id },
            });
            return;
        }
        const delay =
            selectedElement.channel?.type == ChannelTypes.WHATSAPP
                ? 10000
                : 40000;
        const idTicket = selectedElement._id;
        const idProject = selectedElement.idProject;
        const oldTemp = RepliesCache.getTicketReply(idProject, idTicket);
        if (oldTemp) {
            dispatch(
                updateAction({
                    idAction: oldTemp.id,
                    update: {
                        type: ActionTypes.REPLY,
                        creation_date: new Date(),
                    },
                })
            );
            RepliesCache.removeReply(idProject, idTicket);
        }
        const tempReply = await ActionsService.reply(
            idProject,
            idTicket,
            showConfirmation,
            tags,
            delay
        );
        const idTempReply = tempReply._id;
        const timeout = new CustomTimeout(async () => {
            try {
                const result = await ActionsService.persistTempReply(
                    idProject,
                    idTicket,
                    idTempReply
                );
                dispatch(
                    updateAction({
                        idAction: idTempReply,
                        update: result,
                    })
                );
                RepliesCache.removeReply(idProject, idTicket);
            } catch (error) {
                RepliesCache.setReplyAsFailed(idProject, idTicket, idTempReply);
            }
        }, delay);
        RepliesCache.addTempReply(idProject, idTicket, idTempReply, timeout);
        dispatch(appendAction(tempReply));
        dispatch({
            ...PayloadEditorActions.reset(),
            meta: { idEditor: selectedElement._id },
        });
    };

    const hasReplyPermission = useTicketPermission(
        selectedElement,
        ProjectPermissions.REPLY
    );

    /** If the current user can reply to the client */
    const canReply =
        /** Ticket is not closed */
        !isClosed &&
        /** Element is not child */
        !selectedElement.path &&
        selectedElement.channel &&
        hasReplyPermission;

    const handleUnfocus = useCallback(
        (e): boolean => !titlesRef.current?.contains(e.target as Node),
        [titlesRef.current]
    );

    const templatesContext = useMemo(
        () => ({
            idTicket: selectedElement._id,
        }),
        [selectedElement]
    );

    const toggleTab = useCallback(
        (tab: ActionTabs | undefined) => (): void => {
            const same = selectedTab === tab;
            if (selectedTab && (!tab || same)) {
                const height = actionBarRef.current?.clientHeight.toString();
                if (height) localStorage.setItem('actionBarHeight', height);
                if (actionBarRef.current) {
                    actionBarRef.current.style.height = '0px';
                }
            } else if (!selectedTab && tab) {
                const height = localStorage.getItem('actionBarHeight') ?? '295';
                if (actionBarRef.current) {
                    actionBarRef.current.style.height = height + 'px';
                }
            }
            setSelectedTab(same ? undefined : tab);
        },
        [selectedTab]
    );

    const generateAI = useMemo(() => {
        if (selectedTab === ActionTabs.REPLY) {
            return async (draft: EurekaDraft): Promise<EurekaDraft> =>
                await generateAIReply(selectedElement._id, draft);
        } else if (selectedTab === ActionTabs.COMMENT) {
            return async (draft: EurekaDraft): Promise<EurekaDraft> =>
                await generateAIComment(selectedElement._id, draft);
        } else return undefined;
    }, [selectedElement._id, selectedTab]);

    return (
        <React.Fragment>
            {showConfirmation !== undefined && selectedTab && (
                <Dialog
                    maxWidth="100%"
                    disableEnforceFocus
                    onClose={(): void => setShowConfirmation(undefined)}
                >
                    <PayloadConfirmation
                        close={(): void => setShowConfirmation(undefined)}
                        type={selectedTab}
                        payload={showConfirmation}
                        handleConfirm={handleConfirmReply}
                        actions={actionsPage.elements}
                        ticket={selectedElement}
                    />
                </Dialog>
            )}
            <div className={styles.container} data-testid="ActionBar_container">
                <div className={styles.titles + ' noselect'} ref={titlesRef}>
                    {canReply && (
                        <div
                            className={
                                selectedTab === ActionTabs.REPLY
                                    ? styles.selectedTab
                                    : styles.tab
                            }
                            onClick={toggleTab(ActionTabs.REPLY)}
                        >
                            <label className={styles.lblTab}>
                                Responder al Cliente
                            </label>
                        </div>
                    )}
                    {canComment && (
                        <div
                            className={
                                selectedTab === ActionTabs.COMMENT
                                    ? styles.selectedTab
                                    : styles.tab
                            }
                            onClick={toggleTab(ActionTabs.COMMENT)}
                        >
                            <label className={styles.lblTab}>
                                Comentario Interno
                            </label>
                        </div>
                    )}
                </div>
                <div className={styles.actionForm} ref={actionBarRef}>
                    <ActionBarResizer
                        draggable={selectedTab !== undefined}
                        containerRef={actionBarRef}
                        setSelectedTab={setSelectedTab}
                    />
                    {selectedTab !== undefined && (
                        <div className={styles.textField}>
                            <PayloadEditor
                                disableUnmount
                                idEditor={selectedElement._id}
                                placeholder={placeholder}
                                autoFocus={500}
                                allowFullScreen
                                allowEntities={false}
                                templates={templatesContext}
                                shouldUnfocus={handleUnfocus}
                                onSubmit={handleSend}
                                generateAI={generateAI}
                            />
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}

export default ActionBar;
