import { makeStyles, Icon } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Entry } from '../../../@Types/Entry';
import {
    loadEntriesByApiKeys,
    loadEntries,
} from '../../../controllers/EntriesController/EntriesService';
import { EntryIcon } from '../../../pages/Entries/EntryListItem/EntryListItem';
import { RootState } from '../../../utils/_store';
import RoundedGenericPicker, {
    RoundedGenericPickerProps,
} from '../RoundedGenericPicker/RoundedGenericPicker';
import EntryTypes from '../../../constants/EntryTypes';

interface RoundedEntryPickerProps
    extends Omit<
        RoundedGenericPickerProps<Entry>,
        'calcLbl' | 'loadInitialType' | 'loadElements' | 'elementLabel'
    > {
    entryTypes?: EntryTypes[];
}

function RoundedEntryPicker({
    label = 'Entrada',
    entryTypes,
    ...props
}: RoundedEntryPickerProps): JSX.Element {
    const idProject = useSelector((state: RootState) => state.site.idProject);
    return (
        <RoundedGenericPicker<Entry>
            {...props}
            IconComponent={IconComponent}
            label={label}
            elementLabel="Entrada"
            calcIcon={(entry): JSX.Element => <EntryIcon element={entry} />}
            calcId={(element): string => element?.apiKey}
            calcLbl={(entry): string => entry.entryName ?? 'Entrada'}
            loadInitialType={async (apiKeys): Promise<Entry[]> =>
                idProject ? await loadEntriesByApiKeys(idProject, apiKeys) : []
            }
            loadElements={async (
                page: number,
                pageSize: number,
                search: string | undefined
            ): Promise<Entry[]> => {
                if (!idProject || page > 1) return [];
                const entries = await loadEntries(
                    idProject,
                    [],
                    undefined,
                    search
                );
                if (!entryTypes) return entries;
                return entries.filter((entry) =>
                    entryTypes.includes(entry.type)
                );
            }}
        />
    );
}
export default RoundedEntryPicker;

function IconComponent(props: any): JSX.Element {
    const useStyles = makeStyles({
        iconRoot: {
            position: 'absolute',
            right: '7px',
        },
        imageIcon: {
            height: 23,
            width: 23,
        },
    });
    const classes = useStyles();
    return (
        <Icon classes={{ root: classes.iconRoot }} className={props.className}>
            <img
                className={classes.imageIcon}
                style={{ display: 'flex', height: 'inherit', width: 'inherit' }}
                src="/media/icons/greyEntry.svg"
            />
        </Icon>
    );
}
