import styles from './AreaDetailComponent.module.css';
import React, { useRef, useState } from 'react';
import Create from '../../../../shared/Create/Create';
import SubAreaGridElement from './SubAreaGridElement/SubAreaGridElement';
import AgentGridElement from './AgentGridElement/AgentGridElement';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import EditContainer from '../../../../shared/EditContainer/EditContainer';
import RoundedButton from '../../../../shared/RoundedButton/RoundedButton';
import TrendingFlatRoundedIcon from '@material-ui/icons/TrendingFlatRounded';
import { RouteComponentProps, withRouter, match } from 'react-router-dom';
import { Agent, Area } from '../../../../@Types/@Types';
import { RootState } from '../../../../utils/_store';
import { useDispatch, useSelector } from 'react-redux';
import AgentsMenu from '../../../../shared/@Menus/AgentsMenu/AgentsMenu';
import * as AreaService from '../../../../controllers/AreasController/AreasService';
import { refresh } from '../../../../controllers/AreasController/AreasActions';

interface AreaDetailComponentProps {
    /** Area to display */
    element: Area;
    /** Function that handles adding a subarea to the area*/
    createSubArea: Function;
    /** Function that handles deleting the current area */
    handleClickDelete: Function;
    /** Function that handles removing an agent from the area */
    removeAgent: Function;
    /** Function that handles updating a value */
    handleUpdate: Function;
    match: match<{ idProject: string }>;
}

/**
 * Component that renders the detail of an area
 */
function AreaDetailComponent({
    element,
    removeAgent,
    createSubArea,
    handleUpdate,
    handleClickDelete,
    history,
    match,
}: AreaDetailComponentProps & RouteComponentProps): JSX.Element {
    const dispatch = useDispatch();
    const idProject = useSelector((state: RootState) => state.site.idProject);
    const elements = useSelector(
        (state: RootState) => state.areasPage.elements
    );
    const [showAddAgent, setShowAddAgent] = useState(false);
    const createBtnRef = useRef<HTMLDivElement>(null);
    /**
     * Computes the visual element to show as the title
     * if path is longer than 3 it returns ... in the begining othewise returns the entire path
     * @returns the title to display
     */
    const calcTitle = (): any => {
        if (!element.path || element.path.length === 0) {
            return element.name;
        } else {
            const parents = element.path.map((id) => elements[id]);
            if (parents.length < 3) {
                const elements = [];
                parents.forEach((parent, index) => {
                    elements.push(
                        <span key={'T-' + index}>
                            &#x200E;{parent.name.toString()}&#x200E;
                        </span>
                    );
                    elements.push(
                        <div className={styles.arrowIconContainer} key={index}>
                            <TrendingFlatRoundedIcon fontSize="inherit" />
                        </div>
                    );
                });
                elements.push(
                    <span key={'element'}>
                        &#x200E;{element.name.toString()}&#x200E;
                    </span>
                );
                return elements;
            } else {
                return [
                    <span>&#x200E;{parents[0].name.toString()}&#x200E;</span>,
                    ' ... ',
                    <span>
                        &#x200E;{parents[parents.length - 1].name.toString()}
                        &#x200E;
                    </span>,
                    ,
                    <div className={styles.arrowIconContainer} key="arrow">
                        <TrendingFlatRoundedIcon fontSize="inherit" />
                    </div>,
                    <span key={'element'}>
                        &#x200E;{element.name.toString()}&#x200E;
                    </span>,
                ];
            }
        }
    };
    /**
     * Renders the area's subareas and the create subarea button in a grid
     * @returns subareas
     */
    const renderSubAreas = (): JSX.Element => {
        return (
            <React.Fragment>
                <div className={styles.label}>Sub Áreas</div>
                <div
                    className={styles.subAreasContainer + ' flexbox'}
                    data-testid={'subareasContainer'}
                >
                    {element.subareas.map((id, index) => {
                        return (
                            <div
                                className={styles.subElementContainer}
                                key={index}
                                data-testid={'subElementContainer'}
                            >
                                <SubAreaGridElement element={elements[id]} />
                            </div>
                        );
                    })}
                    <div
                        className={styles.subElementContainerCreate}
                        data-testid={'addSubArea'}
                    >
                        <Create
                            handleCreate={(): void => {
                                createSubArea(element);
                            }}
                            label={'Agregar Sub Área'}
                            fontSize={14}
                            width={135}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    /**
     * Renders the Area's flow
     */
    const renderFlujo = (): JSX.Element => {
        //TODO Precalcular una foto del flujo?
        return (
            <div>
                <img
                    alt={'Flow'}
                    className={styles.flowFoto}
                    src={'/media/flow.png'}
                ></img>
                <div className={styles.flowBtn}>
                    <RoundedButton
                        text="Ver Flujo"
                        backgroundColor="var(--secondary)"
                        color="white"
                        onClick={(): void => {
                            history.push(
                                `/${match.params.idProject}/flows/${element.idFlow}`
                            );
                        }}
                    />
                </div>
            </div>
        );
    };

    /**
     * Renders the areas agents and the add agent button in a grid
     * @returns visual agents
     */
    const renderMembers = (): JSX.Element => {
        const area = element;
        return (
            <React.Fragment>
                <div className={styles.label}>Miembros</div>
                <div
                    className={styles.membersContainer + ' flexbox'}
                    data-testid={'membersContainer'}
                >
                    {element.agents.map((agent, index) => {
                        return (
                            <div
                                className={styles.subElementContainer}
                                key={index}
                                data-testid={'subElementContainer'}
                            >
                                <AgentGridElement
                                    element={agent}
                                    removeAgent={(element: Agent): void => {
                                        removeAgent({
                                            agent: element,
                                            parent: area,
                                        });
                                    }}
                                />
                            </div>
                        );
                    })}
                    <div
                        className={styles.subElementContainerCreate}
                        data-testid={'addAgentContainer'}
                    >
                        {showAddAgent && (
                            <AgentsMenu
                                anchorRef={createBtnRef}
                                handleClose={(): void => {
                                    setShowAddAgent(false);
                                }}
                                filterAgents={element.agents}
                                multiple={false}
                                handleConfirm={async (
                                    agent: Agent
                                ): Promise<void> => {
                                    if (idProject) {
                                        await AreaService.addAgent(
                                            idProject,
                                            element._id,
                                            agent._id
                                        );
                                        setShowAddAgent(false);
                                        dispatch(refresh());
                                    }
                                }}
                                confirm="Agregar"
                            />
                        )}
                        <Create
                            width={135}
                            btnRef={createBtnRef}
                            handleCreate={(): void => {
                                setShowAddAgent(true);
                            }}
                            label={'Agregar Miembro'}
                            fontSize={14}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    return (
        <div
            className={styles.containerDetail}
            data-testid={'AreaDetailContainer'}
        >
            <div
                className={styles.titleContainer}
                data-testid={'titleContainer'}
            >
                <div className={styles.title}>
                    <EditContainer
                        object={element}
                        field={'name'}
                        handleConfirm={handleUpdate}
                    >
                        <div className={styles.titlelbl}>{calcTitle()}</div>
                        <input
                            className={styles.nameInput + ' edit-input'}
                            defaultValue={element.name}
                            type="text"
                            size={10}
                        />
                    </EditContainer>

                    <div>
                        <div
                            className={styles.delBtn + ' grey-btn'}
                            onClick={(): void => {
                                handleClickDelete(element);
                            }}
                            data-testid={'AreaDetail_deleteButton'}
                        >
                            <DeleteRoundedIcon
                                style={{ fill: 'black' }}
                                fontSize="inherit"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.descriptionLabel}>Descripción</div>
            <div
                className={styles.descriptionContainer}
                data-testid={'descriptionContainer'}
            >
                <EditContainer
                    object={element}
                    field={'description'}
                    handleConfirm={handleUpdate}
                >
                    <div className={styles.description}>
                        {element.description}
                    </div>
                    <textarea
                        className={styles.descriptionInput + ' edit-input'}
                        defaultValue={element.description}
                    />
                </EditContainer>
            </div>
            <div className={styles.flujoLabel}>Flujo de Entrada</div>
            <div className={styles.flujo}>{renderFlujo()}</div>
            {renderSubAreas()}
            {renderMembers()}
        </div>
    );
}
export default withRouter(AreaDetailComponent);
