import styles from './EntityRelationshipPicker.module.css';
import { Controller, FieldError } from 'react-hook-form';
import { calcStepWidth } from '@arquimedes.co/eureka-forms/dist/FormSteps/StepFunctions';
import { EntityValue } from '../../../@Types/@Types';
import {
    EntityRelationshipPicker as EntityRelationshipPickerType,
    MapperProperty,
} from '../../../@Types/EntityTypes/EntityProperty';
import Relationship from '../../../@Types/Relationship';
import update from 'immutability-helper';
import EntityPropertyTypes from '../../../constants/EntityPropertyTypes';
import { EntityValueFilters } from '../../../controllers/EntityValuesController/EntityValuesReducer';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { MapperStyleTypes } from '@arquimedes.co/eureka-forms/dist/constants/FormStepTypes';
import { Entity, EntityRelationship } from '../../../@Types/EntityTypes/Entity';
import { CustomMapperStep } from '@arquimedes.co/eureka-forms/dist/FormSteps/MapperStep/MaterialMapperStep/MaterialMapperStep';
import RoundedEntityRelationshipPicker from './RoundedEntityRelationshipPicker/RoundedEntityRelationshipPicker';
import { loadEntityValuesById } from '../../../controllers/EntityValuesController/EntityValuesService';
import RoundedButton from '../../RoundedButton/RoundedButton';
import { RootState } from '../../../utils/_store';
import { useSelector } from 'react-redux';
import EntityValuesMenu from '../../@Menus/EntityValuesMenu/EntityValuesMenu';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DragIndicatorRoundedIcon from '@material-ui/icons/DragIndicatorRounded';
import { CustomStepProps } from '@arquimedes.co/eureka-forms/dist/FormSteps/CustomStep';
import { MapperElement } from '@arquimedes.co/eureka-forms/dist/@Types/MapperElement';
import { EntityValueFilterTypes } from '../../../constants/FormStepTypes';
import { FullEntityValue } from '../../../@Types/EntityValue';
export interface EntityPickerStepProps extends Omit<CustomStepProps, 'step'> {
    step: EntityRelationshipPickerType;
    entityValue?: EntityValue | Relationship | undefined;
}

function MapperEntityRelationship({
    step,
    filters,
    elements,
    relationship,
    customStepProps,
    ...others
}: EntityPickerStepProps & {
    error: FieldError | undefined;
    relationship: EntityRelationship;
    filters: EntityValueFilters;
    onChange: (elements: MapperElement<Relationship>[]) => void;
    elements: MapperElement<Relationship>[];
    inputRef: any;
}): JSX.Element {
    const [entityValues, setEntityValues] = useState<
        Record<string, FullEntityValue> | undefined
    >(undefined);
    const idStep = [...customStepProps.path, step.id].join('.');
    const entity = useSelector(
        (state: RootState) => state.site.entities[relationship.idEntity]
    );
    const loadEntityValues = async (): Promise<void> => {
        const original: MapperElement<Relationship>[] =
            customStepProps.relationshipsValues?.[idStep] ?? [];
        const ids: string[] = [];
        for (const value of original) {
            if (value.value?.idEntityValue) {
                ids.push(value.value.idEntityValue);
            }
        }
        if (ids.length === 0) return;
        const values = await loadEntityValuesById(relationship.idEntity, ids);
        const newEntityValues: Record<string, FullEntityValue> = {};
        for (const entityValue of values) {
            newEntityValues[entityValue._id] = entityValue;
        }
        setEntityValues(newEntityValues);
    };

    useEffect(() => {
        loadEntityValues();
    }, []);

    const elementsToOmit = useMemo(() => {
        return elements
            .filter((element) => !element.deleted)
            .map(
                (element) =>
                    entityValues?.[element.value?.idEntityValue ?? ''] ??
                    ({ _id: element.value?.idEntityValue } as any)
            );
    }, [entityValues, elements]);

    const mapperStep: MapperProperty = {
        ...step,
        id: idStep,
        type: EntityPropertyTypes.MAPPER,
        style: step.style.type as MapperStyleTypes,
        description: step.description ?? '',
        addBtnLabel: '',
        unitLabel: '',
        required: step.required,
        steps: relationship.steps,
        rootSteps: relationship.rootSteps,
        creatable: true,
    };

    const moveItem = useCallback(
        (dragIndex, hoverIndex) => {
            const dragItem = elements[dragIndex];
            others.onChange(
                update(elements, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragItem],
                    ],
                })
            );
        },
        [elements]
    );
    const renderComponent = (): JSX.Element => {
        return (
            <CustomMapperStep<Relationship>
                editable={others.editable}
                inputRef={others.inputRef}
                onChange={({ elements }): void => others.onChange(elements)}
                error={others.error}
                value={{
                    elements,
                    page: 0,
                }}
                step={mapperStep as any}
                customAdd={(ref, disabled, handleAddElement): JSX.Element => (
                    <AddRelationshipButton
                        idStep={idStep}
                        inputRef={ref}
                        disabled={disabled}
                        handleAddElement={handleAddElement}
                        required={step.required}
                        relationship={relationship}
                        filters={filters}
                        elementsToOmit={elementsToOmit}
                        addEntityValue={(entityValue): void => {
                            setEntityValues({
                                ...entityValues,
                                [entityValue._id]: {
                                    ...entityValue,
                                    active: true,
                                },
                            });
                        }}
                    />
                )}
                customElementRender={
                    elements.length > 1
                        ? RenderElementFunction(moveItem)
                        : undefined
                }
                loading={entityValues === undefined}
                calcCustomStepProps={(
                    element,
                    customStepProps
                ): Record<string, any> => ({
                    ...customStepProps,
                    entity: relationship,
                    path: [...customStepProps.path, step.id, element.id],
                })}
                customElementModifiers={{
                    calcName: (element): string =>
                        entityValues?.[element.value?.idEntityValue ?? '']
                            ?.values[entity.idLabelStep] ?? entity.name,
                    isDeleted: (element): boolean => {
                        const entityValue =
                            entityValues?.[element.value?.idEntityValue ?? ''];
                        return !!entityValue && !entityValue.active;
                    },
                }}
            />
        );
    };
    if (elements.length > 1) {
        return (
            <DndProvider backend={HTML5Backend}>
                {renderComponent()}
            </DndProvider>
        );
    } else return renderComponent();
}

const RenderElementFunction = (
    /** Function to call when item is hovered on */
    moveItem: Function
) =>
    function RenderElement(
        element: MapperElement<Relationship>,
        index: number,
        renderComponent: (
            renderElementButtons?: (element: MapperElement<any>) => JSX.Element
        ) => JSX.Element
    ): JSX.Element {
        const dragRef = useRef<HTMLDivElement>(null);
        const previewRef = useRef<HTMLDivElement>(null);
        const [{ handlerId }, drop] = useDrop({
            accept: 'DraggableItem',
            collect(monitor) {
                return {
                    handlerId: monitor.getHandlerId(),
                };
            },
            hover(item: any, monitor) {
                if (!dragRef.current) {
                    return;
                }
                const dragIndex = item.index;
                const hoverIndex = index;
                // Don't replace items with themselves
                if (dragIndex === hoverIndex) {
                    return;
                }
                // Determine rectangle on screen
                const hoverBoundingRect =
                    dragRef.current?.getBoundingClientRect();
                // Get vertical quarter
                const hoverQuarterY =
                    (hoverBoundingRect.bottom - hoverBoundingRect.top) / 4;
                // Determine mouse position
                const clientOffset = monitor.getClientOffset();
                if (clientOffset === null) {
                    return;
                }
                // Get pixels to the top
                const hoverClientY = clientOffset.y - hoverBoundingRect.top;
                // Only perform the move when the mouse has crossed a quarter of the items height
                // When dragging downwards, only move when the cursor is below 25%
                // When dragging upwards, only move when the cursor is above 25%
                // Dragging downwards
                if (dragIndex < hoverIndex && hoverClientY < hoverQuarterY) {
                    return;
                }
                // Dragging upwards
                if (dragIndex > hoverIndex && hoverClientY > hoverQuarterY) {
                    return;
                }
                // Time to actually perform the action
                moveItem(dragIndex, hoverIndex);
                item.index = hoverIndex;
            },
        });
        const [{ isDragging }, drag, preview] = useDrag({
            type: 'DraggableItem',
            item: () => {
                return { id: element.id, index };
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });
        const opacity = isDragging ? 0 : 1;
        drag(drop(dragRef));
        drop(preview(previewRef));
        const renderIcon = (): JSX.Element => {
            return (
                <div ref={dragRef} className={styles.dragIcon}>
                    <DragIndicatorRoundedIcon fontSize="inherit" />
                </div>
            );
        };

        return (
            <div
                ref={previewRef}
                style={{ opacity }}
                data-handler-id={handlerId}
            >
                {renderComponent(renderIcon)}
            </div>
        );
    };

export default EntityRelationshipPickerComponent;

function EntityRelationshipPickerComponent(
    props: EntityPickerStepProps
): JSX.Element {
    const { step, customStepProps, dependencyStore, entityValue } = props;
    const entity: Entity | EntityRelationship = customStepProps.entity;
    const stepEntity = useSelector(
        (state: RootState) => state.site.entities[step.idEntity]
    );
    const filters = useMemo(() => {
        const filters: EntityValueFilters = {
            values: {},
            relationships: {},
        };
        if (!step.filters) return filters;
        for (const filter of step.filters) {
            const property = stepEntity.steps[filter.idProperty];
            if (property.type !== EntityPropertyTypes.ENTITY_RELATIONSHIP) {
                if (filter.type === EntityValueFilterTypes.VALUE) {
                    filters.values[filter.idProperty] = filter.value;
                } else if (
                    filter.type === EntityValueFilterTypes.STEP &&
                    filter.idStep
                ) {
                    filters.values[filter.idProperty] =
                        dependencyStore[filter.idStep]?.value;
                }
            } else {
                if (filter.type === EntityValueFilterTypes.VALUE) {
                    filters.relationships[property.idEntity] = filter.value;
                } else if (
                    filter.type ===
                        EntityValueFilterTypes.ENTITY_RELATIONSHIP &&
                    (entityValue as EntityValue)?._id
                ) {
                    filters.relationships[property.idEntity] = [
                        entityValue as EntityValue,
                    ];
                } else if (
                    filter.type === EntityValueFilterTypes.STEP &&
                    filter.idStep
                ) {
                    filters.relationships[property.idEntity] = dependencyStore[
                        filter.idStep
                    ]?.value?.map(
                        (relationship: any) =>
                            relationship.entityValue ??
                            relationship.idEntityValue
                    );
                }
            }
        }
        return filters;
    }, [
        entity,
        step,
        ...(step.filters?.map((filter) =>
            filter.type === EntityValueFilterTypes.STEP && filter.idStep
                ? dependencyStore[filter.idStep]?.value
                : filter
        ) ?? []),
    ]);
    const relationship = useMemo(() => {
        return entity.relationships?.find(
            (relationship) => relationship.idEntity === step.idEntity
        );
    }, [step, entity]);
    if (!relationship) return <div></div>;
    return (
        <Controller
            name={step.id}
            defaultValue={customStepProps.relationshipsValues?.[step.id] ?? []}
            rules={
                Object.values(MapperStyleTypes).includes(
                    step.style?.type as MapperStyleTypes
                )
                    ? {
                          validate: (array): boolean =>
                              !step.required ||
                              (step.required &&
                                  array.filter(
                                      (elem: MapperElement<Relationship>) =>
                                          !elem.deleted
                                  ).length > 0),
                      }
                    : {
                          required: props.step.required
                              ? 'Este campo es obligatorio'
                              : undefined,
                      }
            }
            shouldUnregister
            render={({ field, fieldState: { error } }): JSX.Element => {
                if (
                    Object.values(MapperStyleTypes).includes(
                        step.style?.type as MapperStyleTypes
                    )
                ) {
                    return (
                        <MapperEntityRelationship
                            {...props}
                            elements={field.value}
                            inputRef={field.ref}
                            error={error}
                            onChange={field.onChange}
                            filters={filters}
                            relationship={relationship}
                        />
                    );
                } else {
                    const {
                        step,
                        form,
                        editable,
                        postview,
                        widthStats,
                        handleStepDep,
                    } = props;
                    return (
                        <div
                            className={styles.container}
                            style={{
                                width:
                                    widthStats.currentBreakPoint <= step.size
                                        ? '100%'
                                        : calcStepWidth(step.size, form.size),
                                minHeight:
                                    step.description ||
                                    (!postview && editable && step.required)
                                        ? '55px'
                                        : '43px',
                            }}
                        >
                            <RoundedEntityRelationshipPicker
                                idStep={step.id}
                                value={field.value}
                                onBlur={field.onBlur}
                                handleUpdate={(data: Relationship[]): void => {
                                    handleStepDep(data ?? null);
                                    field.onChange(data);
                                }}
                                inputRef={field.ref}
                                filters={filters}
                                relationship={relationship}
                                multiple={step.multiple}
                                cantEdit={editable === false || postview}
                                seed="EF-"
                                label={step.label}
                                helperText={
                                    error?.message ??
                                    step.description ??
                                    undefined
                                }
                                error={!!error}
                                required={step.required}
                            />
                        </div>
                    );
                }
            }}
        />
    );
}

interface AddRelationshipButtonProps {
    idStep: string;
    relationship: EntityRelationship;
    filters: EntityValueFilters;
    inputRef: any;
    disabled: boolean;
    required: boolean;
    elementsToOmit: EntityValue[] | undefined;
    addEntityValue: (entityValue: EntityValue, noMoreElements: boolean) => void;
    handleAddElement: (element: Relationship) => void;
}

function AddRelationshipButton({
    idStep,
    filters,
    required,
    disabled,
    relationship,
    addEntityValue,
    elementsToOmit,
    handleAddElement,
}: AddRelationshipButtonProps): JSX.Element {
    const entity = useSelector(
        (state: RootState) => state.site.entities[relationship.idEntity]
    );
    return (
        <React.Fragment>
            <EntityValuesMenu
                idMenu={idStep}
                seed="EF-"
                entity={entity}
                elementsToOmit={elementsToOmit}
                multiple={false}
                filters={filters}
                zIndex={1303}
                handleUpdate={([entityValue], noMoreElements): void => {
                    addEntityValue(entityValue, noMoreElements);
                    handleAddElement?.({
                        idEntity: relationship.idEntity,
                        idEntityValue: entityValue._id,
                        values: {},
                    });
                }}
                handleClose={null}
                customButton={(
                    ref,
                    setShowMenu,
                    noMoreElements
                ): JSX.Element => (
                    <div
                        ref={ref}
                        style={{
                            visibility: noMoreElements ? 'hidden' : 'visible',
                        }}
                    >
                        <RoundedButton
                            disabled={disabled}
                            text={'Agregar' + (required ? ' *' : '')}
                            fontSize={'1rem'}
                            padding={'5px 15px 5px 15px'}
                            onClick={(): void => {
                                if (!disabled) {
                                    setShowMenu();
                                }
                            }}
                        />
                    </div>
                )}
            />
        </React.Fragment>
    );
}
