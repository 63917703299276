import { useState } from 'react';
import { ClassifierOptionTypes } from '../../../../../../constants/OptionTypes';
import styles from './NestedOption.module.css';
import { ClassifierOptionProps } from '../Option';
import StepComponent from '../../../../../../shared/GenericFormEditor/Step/Step';
import { FormStep } from '../../../../../../@Types/FormTypes/FormStep';
import {
    FormStepLocation,
    UniqueSteps,
} from '../../../../../../@Types/FormTypes/Form';
import AddStepBtn from '../../../../../../shared/GenericFormEditor/AddStepBtn/AddStepBtn';
function NestedOption({
    option,
    level,
    location,
    stepMenuComponent,
    ...others
}: ClassifierOptionProps): JSX.Element {
    const [openId, setOpenId] = useState<string | undefined>(undefined);
    if (!option || option.type !== ClassifierOptionTypes.NESTED) {
        return <div></div>;
    }
    const mapSteps = (): JSX.Element[] => {
        return option.steps.map((idStep: string, index) => {
            return (
                <StepComponent<FormStep, UniqueSteps, FormStepLocation>
                    key={index}
                    {...others}
                    idStep={idStep}
                    onOpen={(): void => {
                        setOpenId(idStep);
                    }}
                    canMoveUp={index !== 0}
                    canMoveDown={index !== option.steps.length - 1}
                    level={level + 1}
                    location={{ ...location, indexStep: index }}
                    openId={openId}
                    stepMenuComponent={stepMenuComponent}
                />
            );
        });
    };

    return (
        <div className={styles.stepsContainer}>
            {mapSteps()}
            <div className={styles.addBtnContainer}>
                <AddStepBtn
                    size="35px"
                    location={location}
                    stepMenuComponent={stepMenuComponent}
                />
            </div>
        </div>
    );
}
export default NestedOption;
