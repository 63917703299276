import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';

interface StyleProps {
    /** The color of the background */
    backgroundColor: string;
    /** the color of the icon */
    color: string;
    /** The color of the icon when hovering */
    hoverColor?: string;
    /** The color of the background when hovering */
    hoverBackgroundColor?: string;
    /** the padding around the content */
    padding?: string;
}

const useButtonClasses = (props: StyleProps): Function =>
    makeStyles(() => ({
        root: {
            textTransform: 'none',
            fontFamily: 'Nunito',
            fontSize: 14,
            borderRadius: '50%',
            boxShadow: 'none',
            backgroundColor: props.backgroundColor,
            color: props.color,
            padding: props.padding,
            '&:hover': {
                boxShadow: 'none',
                backgroundColor:
                    props.hoverBackgroundColor ?? props.backgroundColor,
                color: props.hoverColor ?? props.color,
            },
            '&:disabled': {
                backgroundColor: props.backgroundColor,
                color: props.color,
                opacity: 0.5,
            },
        },
    }));

interface RoundedIconButtonProps extends StyleProps {
    /** Function to call when the button is clicked */
    onClick: Function;
    /** If the button is disabled */
    disabled?: boolean;
    /** children to display */
    children: any;
}
function RoundedIconButton({
    backgroundColor,
    color,
    hoverColor,
    padding = '4px',
    onClick,
    disabled,
    children,
    hoverBackgroundColor,
}: RoundedIconButtonProps): JSX.Element {
    const classes = useButtonClasses({
        backgroundColor,
        color,
        padding,
        hoverColor: hoverColor ?? color,
        hoverBackgroundColor: hoverBackgroundColor ?? backgroundColor,
    })();
    return (
        <IconButton
            onClick={(): void => {
                onClick();
            }}
            disabled={disabled}
            classes={classes}
        >
            {children}
        </IconButton>
    );
}
export default RoundedIconButton;
