import styles from './Option.module.css';
import { EntityValueOptionTypes } from '../../../../constants/OptionTypes';
import React from 'react';
import NestedOption from './NestedOption/NestedOption';
import { StepComponentProps } from '../../../GenericFormEditor/Step/Step';
import {
    EntityValueOptionLocation,
    GLocation,
} from '../../../../@Types/FormTypes/LocationTypes';
import {
    EntityValuePickerOption,
    GEntityValuePicker,
} from '../../../../@Types/GenericFormSteps';
import { EntityValue } from '../../../../@Types/EntityValue';
import { Entity } from '../../../../@Types/@Types';
import NotInterestedRoundedIcon from '@material-ui/icons/NotInterestedRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import FormConditionComponent from '../../../../pages/Forms/FormCondition/FormCondition';

export interface OptionComponentProps<
    S extends GEntityValuePicker,
    U,
    L extends GLocation
> extends Omit<StepComponentProps<S, U, L>, 'location'> {
    option: EntityValuePickerOption;
    updateOption: (option: EntityValuePickerOption) => void;
    location: EntityValueOptionLocation;
    handleDeleteOption: Function;
    handleParentClose?: Function;
    entityValue: EntityValue;
    entity: Entity;
}
function OptionComponent<S extends GEntityValuePicker, U, L extends GLocation>({
    option,
    entity,
    entityValue,
    updateOption,
    handleDeleteOption,
    ...others
}: OptionComponentProps<S, U, L>): JSX.Element {
    return (
        <React.Fragment>
            <div className={styles.container}>
                <div className={styles.bullet} />
                <div
                    className={styles.containerLbl}
                    style={
                        option.type === EntityValueOptionTypes.HIDE
                            ? {
                                  textDecoration: 'line-through',
                              }
                            : {}
                    }
                >
                    {entityValue.values[entity.idLabelStep] ?? entity.name}
                </div>
                <div className={styles.btnsContainer}>
                    <div
                        className={styles.hideBtn}
                        onClick={(): void => {
                            updateOption({
                                type:
                                    option.type !== EntityValueOptionTypes.HIDE
                                        ? EntityValueOptionTypes.HIDE
                                        : EntityValueOptionTypes.DEFAULT,
                            });
                        }}
                        style={
                            option.type !== EntityValueOptionTypes.HIDE
                                ? {}
                                : {
                                      color: 'var(--error)',
                                  }
                        }
                    >
                        <NotInterestedRoundedIcon fontSize="inherit" />
                    </div>
                    <div
                        className={styles.addBtn}
                        onClick={(): void => {
                            if (option.type !== EntityValueOptionTypes.NESTED) {
                                updateOption({
                                    ...option,
                                    type: EntityValueOptionTypes.NESTED,
                                    steps: [],
                                });
                            } else {
                                updateOption({
                                    ...option,
                                    type: EntityValueOptionTypes.DEFAULT,
                                });
                            }
                        }}
                        style={
                            option.type === EntityValueOptionTypes.NESTED
                                ? { color: 'var(--secondary)' }
                                : {}
                        }
                    >
                        <AddRoundedIcon fontSize="inherit" />
                    </div>
                    {option.type !== EntityValueOptionTypes.HIDE && (
                        <FormConditionComponent
                            condition={option.condition}
                            handleUpdate={(condition): void =>
                                updateOption({
                                    ...option,
                                    condition,
                                })
                            }
                            size={26}
                            iconSize={16}
                            borderRadius={5}
                            idStep={others.step.id}
                        />
                    )}
                    <div
                        className={styles.deleteBtn}
                        onClick={(): void => {
                            handleDeleteOption();
                        }}
                    >
                        <DeleteRoundedIcon fontSize="inherit" />
                    </div>
                </div>
            </div>
            <div className={styles.contentContainer}>
                <OptionMapper
                    {...others}
                    option={option}
                    entity={entity}
                    entityValue={entityValue}
                    updateOption={updateOption}
                    handleDeleteOption={handleDeleteOption}
                />
            </div>
        </React.Fragment>
    );
}

export default OptionComponent;

function OptionMapper<S extends GEntityValuePicker, U, L extends GLocation>(
    props: OptionComponentProps<S, U, L>
): JSX.Element {
    switch (props.option.type) {
        case EntityValueOptionTypes.HIDE: {
            return <div></div>;
        }
        case EntityValueOptionTypes.NESTED: {
            return <NestedOption {...props} />;
        }
        default:
            return <div></div>;
    }
}
