import styles from './ChangeResolutionDate.module.css';
import TrendingFlatRoundedIcon from '@material-ui/icons/TrendingFlatRounded';
import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';
import React from 'react';
import { format } from 'date-fns';
import { getLocale } from '../../../../../..';
interface ChangeResolutionDateProps {
    originalResolutionDate: Date;
    resolutionDate: Date;
    hideOriginal?: boolean;
}

function ChangeResolutionDate({
    originalResolutionDate,
    resolutionDate,
    hideOriginal = false,
}: ChangeResolutionDateProps): JSX.Element {
    let dateFormat = 'PPP';
    if (
        originalResolutionDate.getHours() !== resolutionDate.getHours() ||
        originalResolutionDate.getMinutes() !== resolutionDate.getMinutes() ||
        hideOriginal
    ) {
        dateFormat = 'PPPp';
    }
    const formattedOriginalDate = format(originalResolutionDate, dateFormat, {
        locale: getLocale(),
    });
    const formattedResolutionDate = format(resolutionDate, dateFormat, {
        locale: getLocale(),
    });
    return (
        <div className={styles.container}>
            {!hideOriginal && (
                <React.Fragment>
                    <div
                        className={styles.dateContainer}
                        title={formattedOriginalDate}
                    >
                        <div className={styles.iconContainer}>
                            <AccessTimeRoundedIcon fontSize="inherit" />
                        </div>
                        <label className={styles.dateLbl}>
                            {formattedOriginalDate}
                        </label>
                    </div>
                    <div className={styles.arrowContainer}>
                        <TrendingFlatRoundedIcon fontSize="inherit" />
                    </div>
                </React.Fragment>
            )}
            <div
                className={styles.dateContainer}
                title={formattedResolutionDate}
            >
                <div className={styles.iconContainer}>
                    <AccessTimeRoundedIcon fontSize="inherit" />
                </div>
                <label className={styles.dateLbl}>
                    {formattedResolutionDate}
                </label>
            </div>
        </div>
    );
}
export default ChangeResolutionDate;
