import styles from './InformativeTypePicker.module.css';
import { MenuItem } from '@material-ui/core';
import RoundedSelect from '../../../../../../shared/RoundedSelect/RoundedSelect';
import StepTypes from '../../../../../../constants/Conversations/ConversationStepTypes';
import TitleIcon from '@material-ui/icons/Title';
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import PlayCircleOutlineRoundedIcon from '@material-ui/icons/PlayCircleOutlineRounded';
import AudiotrackRoundedIcon from '@material-ui/icons/AudiotrackRounded';
import React from 'react';
import { useDispatch } from 'react-redux';
import { updateInfoStepType } from '../../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import { ConversationStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import LinkIcon from '../../../../../../Icons/LinkIcon';
interface InformativeTypePickerProps {
    step: ConversationStep;
}
function InformativeTypePicker({
    step,
}: InformativeTypePickerProps): JSX.Element {
    const dispatch = useDispatch();
    return (
        <div className={styles.typeLblContainer}>
            Tipo:
            <div className={styles.typePickerContainer}>
                <RoundedSelect
                    value={step.type}
                    handleUpdate={(event: any): void => {
                        dispatch(updateInfoStepType(event.target.value));
                    }}
                    height={'31px'}
                >
                    <MenuItem
                        value={StepTypes.INFO_TEXT_STEP}
                        style={{
                            paddingLeft: 14,
                        }}
                    >
                        <div className={styles.typeContainer}>
                            <div className={styles.icon}>
                                <div className={styles.iconContainer}>
                                    <TitleIcon fontSize="inherit" />
                                </div>
                            </div>
                            Texto
                        </div>
                    </MenuItem>
                    <MenuItem
                        value={StepTypes.INFO_IMAGE_STEP}
                        style={{
                            paddingLeft: 14,
                        }}
                    >
                        <div className={styles.typeContainer}>
                            <div className={styles.icon}>
                                <div className={styles.iconContainer}>
                                    <ImageRoundedIcon fontSize="inherit" />
                                </div>
                            </div>
                            Imagen
                        </div>
                    </MenuItem>
                    <MenuItem
                        value={StepTypes.INFO_DOCUMENT_STEP}
                        style={{
                            paddingLeft: 14,
                        }}
                    >
                        <div className={styles.typeContainer}>
                            <div className={styles.icon}>
                                <div className={styles.iconContainer}>
                                    <DescriptionRoundedIcon fontSize="inherit" />
                                </div>
                            </div>
                            Documento
                        </div>
                    </MenuItem>
                    <MenuItem
                        value={StepTypes.LINK_STEP}
                        style={{
                            paddingLeft: 14,
                        }}
                    >
                        <div className={styles.typeContainer}>
                            <div className={styles.icon}>
                                <div className={styles.iconContainer}>
                                    <LinkIcon
                                        fill="var(--secondary)"
                                        style={{ width: 40, height: 40 }}
                                    />
                                </div>
                            </div>
                            Enlace
                        </div>
                    </MenuItem>
                    <MenuItem
                        value={StepTypes.INFO_VIDEO_STEP}
                        style={{
                            paddingLeft: 14,
                        }}
                    >
                        <div className={styles.typeContainer}>
                            <div className={styles.icon}>
                                <div className={styles.iconContainer}>
                                    <PlayCircleOutlineRoundedIcon fontSize="inherit" />
                                </div>
                            </div>
                            Video
                        </div>
                    </MenuItem>
                    <MenuItem
                        value={StepTypes.INFO_AUDIO_STEP}
                        style={{
                            paddingLeft: 14,
                        }}
                    >
                        <div className={styles.typeContainer}>
                            <div className={styles.icon}>
                                <div className={styles.iconContainer}>
                                    <AudiotrackRoundedIcon fontSize="inherit" />
                                </div>
                            </div>
                            Audio
                        </div>
                    </MenuItem>
                    <MenuItem
                        value={StepTypes.INFO_STICKER_STEP}
                        style={{
                            paddingLeft: 14,
                        }}
                    >
                        <div className={styles.typeContainer}>
                            <div className={styles.icon}>
                                <div className={styles.iconContainer}>
                                    <img
                                        src="/media/icons/stickerIcon.svg"
                                        className={styles.stickerIcon}
                                    />
                                </div>
                            </div>
                            Sticker
                        </div>
                    </MenuItem>
                </RoundedSelect>
            </div>
        </div>
    );
}

export default InformativeTypePicker;
