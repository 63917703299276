import React, { useEffect, useState, useRef } from 'react';
import ListDetailLayout from '../../shared/ListDetailLayout/ListDetailLayout';
import PageTitle from '../../shared/PageTitle/PageTitle';
import List from '../../shared/List/List';
import EntityValueDetail from './EntityValueDetail/EntityValueDetail';
import EntityValueListItem from './EntityValueListItem/EntityValueListItem';
import CreateEntityValue from './CreateEntityValue/CreateEntityValue';
import RoundedButton from '../../shared/RoundedButton/RoundedButton';
import Search from '../../shared/Search/Search';
import Dialog from '../../shared/Dialog/Dialog';
import { useSelector, useDispatch } from 'react-redux';
import {
    reset,
    selectElement,
    search,
    setLoading,
    pageScroll,
    refresh,
    goTo,
    setFilters,
} from '../../controllers/EntityValuesController/EntityValuesActions';
import { RootState } from '../../utils/_store';
import { EntityValue } from '../../@Types/@Types';
import * as EntityValuesService from '../../controllers/EntityValuesController/EntityValuesService';
import ConfirmDialog from '../../shared/ConfirmDialog/ConfirmDialog';
import { EntityPermissions } from '../../constants/Permissions';
import { checkEntityPerm } from '../../utils/PermissionsFunctions';
import PageNotFound from '../404/PageNotFound';
import { RouteComponentProps, match, withRouter } from 'react-router-dom';
import {
    EntityValueFilters,
    entityValuesToQueryString,
} from '../../controllers/EntityValuesController/EntityValuesReducer';
import Filters from './EntityValueFilters/EntityValueFilters';
import { RouterProps } from '../../Router';
interface idEntityRouteParam {
    /** Current entity */
    idEntity: string;
    /** The current entityValue */
    idEntityValue: string;
}

interface EntityValuesPageProps extends RouteComponentProps {
    match: match<idEntityRouteParam>;
}

function EntityValuesPage({
    history,
    location,
    match,
    mobile,
}: EntityValuesPageProps & RouterProps): JSX.Element {
    const idEntity = match.params.idEntity ?? '';
    const dispatch = useDispatch();
    const pageInfo = useSelector((state: RootState) => state.entityValuesPage);
    const user = useSelector((state: RootState) => state.site.user);
    const organization = useSelector(
        (state: RootState) => state.site.organization
    );
    const entity = useSelector(
        (state: RootState) => state.site.entities[idEntity]
    );
    const [createDialog, setCreateDialog] = useState<
        EntityValue | undefined | true
    >(undefined);
    const [deleteDialog, setDeleteDialog] = useState<EntityValue | undefined>(
        undefined
    );
    const listRef = useRef<HTMLDivElement>(null);

    const calcQuery = (): string => {
        if (!entity) return '';
        const query = new URLSearchParams(location.search);
        if ([...query.keys()].length === 0 && entity.defaultFilters) {
            const values: Record<string, any> = entity.defaultFilters;
            const valuesArr = query.get('values')?.split(';') ?? [];
            for (const val of valuesArr) {
                const [idProperty, value] = val.split(':');
                values[idProperty] = value;
            }
            const newValues = [];
            for (const [key, value] of Object.entries(values)) {
                if (value !== undefined) {
                    newValues.push(`${key}:${value}`);
                }
            }
            if (newValues.length > 0)
                query.append('values', newValues.join(';'));
        }
        return query.toString();
    };

    useEffect(() => {
        if (entity)
            dispatch(
                reset({
                    entity,
                    history,
                    queryString: calcQuery(),
                    idEntityValue: match.params.idEntityValue,
                })
            );
        if (listRef.current) listRef.current.scrollTop = 0;
    }, [match.params.idEntity]);

    useEffect(() => {
        window.addEventListener('popstate', handleGoBack);
        return (): void => {
            window.removeEventListener('popstate', handleGoBack);
        };
    }, []);

    function handleGoBack(): void {
        const paths = window.location.pathname.split('/');
        if (paths[1] !== 'entities') return;
        const idEntityValue = paths[3];
        dispatch(
            goTo({
                idEntity,
                history,
                idEntityValue,
                queryString: calcQuery(),
            })
        );
        if (listRef.current) scrollToSelected(idEntityValue, listRef);
    }

    function scrollToSelected(selected: any, listRef: any): void {
        const selectedNode = listRef.current.querySelector(
            '.selected-item-shadow'
        );
        if (selected && selectedNode) {
            listRef.current.scrollTo(
                0,
                selectedNode.offsetTop - listRef.current.offsetTop - 10
            );
        } else {
            listRef.current.scrollTop = 0;
        }
    }

    const updateUrl = (
        filters: EntityValueFilters,
        orderBy: string | undefined,
        search: string | undefined,
        type: 'FILTER' | 'SEARCH' | 'ORDER'
    ): void => {
        let url = '/entities/' + entity._id + '/';
        const filtersUrl = entityValuesToQueryString(
            entity,
            filters,
            orderBy,
            search,
            true
        );
        const urlPayload = {
            pathname: url,
            search: filtersUrl,
            state: { changeType: type },
        };
        if ((history.location?.state as any)?.changeType) {
            history.replace(urlPayload);
        } else {
            history.push(urlPayload);
        }
    };

    if (!entity) {
        return <PageNotFound />;
    }

    return (
        <React.Fragment>
            {createDialog && (
                <Dialog
                    open={!!createDialog}
                    onClose={(): void => setCreateDialog(undefined)}
                    maxWidth="100%"
                    disableEnforceFocus={true}
                >
                    <CreateEntityValue
                        baseEntityValue={
                            createDialog === true ? undefined : createDialog
                        }
                        handleEntityValueCreated={(): void => {
                            // TODO: Cuando hayan filtros toca activar el de ordenar por fecha de creación para que el nuevo quede de primeras
                            //Es llamar un action que le ponga loading cambie filtros y vuelva a pedir todo.
                            //TODO corregir bugs, mismo problema en clientes
                            dispatch(refresh());
                            setCreateDialog(undefined);
                            if (listRef.current) listRef.current.scrollTop = 0;
                        }}
                        idEntity={idEntity}
                    />
                </Dialog>
            )}
            {deleteDialog !== undefined && (
                <ConfirmDialog
                    onConfirm={async (
                        element: EntityValue,
                        setLoading: Function
                    ): Promise<void> => {
                        try {
                            setLoading(true);
                            await EntityValuesService.deleteEntityValue(
                                idEntity,
                                element._id
                            );
                            dispatch(refresh());
                            setDeleteDialog(undefined);
                        } catch (error) {
                            //TODO: handle errors
                            console.error(error);
                            setLoading(false);
                        }
                    }}
                    element={deleteDialog}
                    title={'Borrar ' + entity.name}
                    btnMsg="Borrar"
                    deleting
                    msg={`¿Está seguro de que desea borrar ${
                        entity.feminine ? 'esta' : 'este'
                    } ${entity.name.toLowerCase()}?`}
                    onClose={(): void => {
                        setDeleteDialog(undefined);
                    }}
                />
            )}
            <div className="standard-layout">
                <PageTitle
                    title={entity.pluralName}
                    route={organization?.name + ' / ' + entity.pluralName}
                />
                <div className="filters-container flexbox">
                    {checkEntityPerm(
                        user,
                        idEntity,
                        EntityPermissions.CREATE
                    ) && (
                        <RoundedButton
                            text={entity.feminine ? 'Nueva' : 'Nuevo'}
                            backgroundColor={'var(--secondary)'}
                            color={'white'}
                            onClick={(): void => {
                                setCreateDialog(true);
                            }}
                        />
                    )}
                    {(!!entity.idFilterSteps?.length ||
                        !!entity.relationships?.filter(
                            (relationship) => relationship.idEntity !== idEntity
                        )?.length) && (
                        <Filters
                            idEntity={idEntity}
                            handleFilter={(
                                filters: EntityValueFilters
                            ): void => {
                                if (listRef.current)
                                    listRef.current.scrollTop = 0;
                                dispatch(setFilters(filters));
                                updateUrl(
                                    filters,
                                    pageInfo.orderBy,
                                    pageInfo.search,
                                    'FILTER'
                                );
                            }}
                            mobile={mobile}
                        />
                    )}
                    <div className="search-container">
                        <Search
                            search={pageInfo.search}
                            handleLoadingSearch={(loading: boolean): void => {
                                dispatch(setLoading(loading));
                            }}
                            handleSearch={(value: string): void => {
                                /** Refresh the list and send it back to the top */
                                if (listRef.current)
                                    listRef.current.scrollTop = 0;
                                dispatch(search(value));
                                updateUrl(
                                    pageInfo.filters,
                                    pageInfo.orderBy,
                                    value,
                                    'SEARCH'
                                );
                            }}
                        />
                    </div>
                </div>

                <ListDetailLayout
                    listWidth={450}
                    responsiveBreakpoint={1000}
                    selectElementLabel={`Selecciona ${
                        entity.feminine ? 'una' : 'un'
                    } ${entity.name.toLowerCase()}`}
                    loading={pageInfo.loading}
                    selectedElement={
                        pageInfo.selectedElement !== undefined
                            ? pageInfo.elements[pageInfo.selectedElement]
                            : undefined
                    }
                    handleSelectedElement={(element?: EntityValue): void => {
                        const index = pageInfo.elements.findIndex(
                            (listElement) => listElement._id === element?._id
                        );
                        history.push({
                            pathname: `/entities/${idEntity}${
                                element ? '/' + element._id : ''
                            }`,
                            search: history.location.search,
                            state: {
                                prevPath: history.location.pathname,
                            },
                        });
                        dispatch(selectElement(index));
                    }}
                >
                    <List
                        elements={pageInfo.elements}
                        firstPage={pageInfo.pageUp === 1}
                        lastPage={pageInfo.lastPage}
                        listRef={listRef}
                        listId={'entityValues-infiniteList'}
                        firstFocusScroll={scrollToSelected}
                        onPageScroll={(direction): void => {
                            dispatch(pageScroll(direction));
                        }}
                        label={'No hay ' + entity.pluralName.toLowerCase()}
                    >
                        <EntityValueListItem entity={entity} />
                    </List>
                    <EntityValueDetail
                        entity={entity}
                        handleCloneEntityValue={(
                            entityValue: EntityValue
                        ): void => {
                            setCreateDialog(entityValue);
                        }}
                        handleClickDelete={setDeleteDialog}
                    />
                </ListDetailLayout>
            </div>
        </React.Fragment>
    );
}

export default withRouter((props: any) => <EntityValuesPage {...props} />);
