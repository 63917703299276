import styles from './TransformationNode.module.css';
import React from 'react';
import { Handle, Position } from 'react-flow-renderer';
import types from '../../../../constants/Flows/TransformationTypes';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';

import AutomaticContent from './Content/AutomaticContent/AutomaticContent';
import { Transformation } from '../../../../@Types/FlowTypes/NodeTypes/TransformationNode';
import { TicketPropertyTypes } from '../../../../constants/TicketPropertyTypes';

interface TransformationNodeProps {
    /** Transformation Node data */
    data: Transformation & {
        /** Function used to open the dialog  */
        openDialog: Function;
        error: boolean;
    };
}

function TransformationNode({ data }: TransformationNodeProps): JSX.Element {
    const calcType = (): string | void => {
        if (data.type === types.AUTOMATIC) {
            switch (data.property) {
                case TicketPropertyTypes.CLASSIFIER:
                    return 'Clasificador';
                case TicketPropertyTypes.RESOLUTION_DATE:
                    return 'Fecha de Vencimiento';
                case TicketPropertyTypes.STATE:
                    return 'Estado';
                case TicketPropertyTypes.SUBJECT:
                    return 'Asunto';
                case TicketPropertyTypes.SURVEY:
                    return 'Encuesta';
                case TicketPropertyTypes.CHANNEL:
                    return 'Canal de Comunicación';
                case TicketPropertyTypes.SUBTICKET:
                    return 'SubCaso';
                case TicketPropertyTypes.PROCESS:
                    return 'Proceso';
                default:
                    return '';
            }
        }
    };

    const calcTypeContent = (): JSX.Element | void => {
        if (data.type === types.AUTOMATIC) {
            return <AutomaticContent data={data} />;
        }
    };
    return (
        <>
            <Handle
                className={styles.leftHandle}
                type="target"
                position={Position.Left}
                style={{
                    top: '50%',
                    background: 'var(--primary1)',
                    border: '1px solid var(--accent)',
                }}
            />
            <div
                className={styles.container}
                style={
                    data.error
                        ? {
                              border: 'var(--error) 1px solid',
                              marginTop: '-1px',
                              marginLeft: '-1px',
                          }
                        : {}
                }
            >
                <div className={styles.top}>
                    <label className={styles.title}>{data.name}</label>
                    <div
                        className={styles.config}
                        onClick={(): void => {
                            data.openDialog();
                        }}
                    >
                        <SettingsRoundedIcon fontSize="inherit" />
                    </div>
                </div>
                <div className={styles.contentContainer}>
                    {data.type !== null && (
                        <label className={styles.typeLbl}>
                            Tipo: {calcType()}
                        </label>
                    )}
                    {calcTypeContent()}
                </div>
            </div>
            <Handle
                className={styles.rightHandle}
                type="source"
                position={Position.Right}
                style={{
                    top: '50%',
                    background: 'var(--primary1)',
                    border: '1px solid var(--accent)',
                }}
            />
        </>
    );
}

export default React.memo(TransformationNode, (prevProps, nextProps) => {
    if (prevProps.data !== nextProps.data) {
        return false;
    }
    return true;
}) as typeof TransformationNode;
