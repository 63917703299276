import styles from './CommunicationDialog.module.css';
import {
    CommunicationData,
    NotificationData,
    NotificationRecipient,
} from '../../../../@Types/FlowTypes/NodeTypes/CommunicationNode';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../utils/_store';
import EditContainer from '../../../../shared/EditContainer/EditContainer';
import RoundedTextField from '../../../../shared/RoundedTextField/RoundedTextField';
import CommunicationTypeSelector from './CommunicationTypeSelector/CommunicationTypeSelector';
import NotificationRecipientTypes from '../../../../constants/Flows/NotificationRecipientTypes';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RecipientComponent from './RecipientComponent/RecipientComponent';
import produce from 'immer';
import ActionTypes from '../../../../constants/ActionTypes';
import { checkAdmin } from '../../../../utils/PermissionsFunctions';
import PayloadEditor, {
    BasePayloadEditorConditionTypes,
} from '../../../../shared/TextEditor/PayloadEditor';
import { DraftContext } from '../../../../@Types/Draft/DraftContext';
import { useMemo } from 'react';
import FlowTypes from '../../../../constants/Flows/FlowTypes';
import ConditionTypes from '../../../../constants/Conditions/ConditionTypes';
import CommunicationIntegration from './CommunicationIntegration/CommunicationIntegration';

const PayloadContext: DraftContext = {
    client: true,
    ticket: true,
};

interface CommunicationDialogProps {
    idFlow: string;
    /** The id of the current node */
    nodeId: string;
    /** The data of the transformation to display */
    data: CommunicationData & {
        /** Function used to open the dialog  */
        openDialog: Function;
    };
    /** The type of the current flow */
    flowType: FlowTypes;
    /** Function called when a value needs to update */
    handleUpdate: Function;
}

function CommunicationDialog({
    data,
    idFlow,
    nodeId,
    flowType,
    handleUpdate,
}: CommunicationDialogProps): JSX.Element {
    const user = useSelector((state: RootState) => state.site.user);

    const conditions = useMemo(() => {
        const types = [...BasePayloadEditorConditionTypes];
        types.push(ConditionTypes.FLOW);
        if (flowType !== FlowTypes.ENTRY) {
            types.push(ConditionTypes.ENTRY);
        }
        return { types };
    }, []);

    if (!data || !user) {
        return <div></div>;
    }

    return (
        <div className={styles.container}>
            <div className={styles.scrollContainer}>
                <div className={styles.top}>
                    <div className={styles.valueContainer}>
                        <EditContainer
                            object={data}
                            field={'name'}
                            handleConfirm={(
                                object: CommunicationData,
                                field: keyof CommunicationData,
                                value: string
                            ): void => {
                                handleUpdate({
                                    ...data,
                                    name: value,
                                });
                            }}
                        >
                            <label className={styles.nameLbl}>
                                {data.name}
                            </label>
                            <input
                                className={styles.nameInput + ' edit-input'}
                                defaultValue={data.name}
                                type="text"
                                size={15}
                            />
                        </EditContainer>
                    </div>
                </div>
                <div className={styles.typeContainer}>
                    Tipo:
                    <div className={styles.typeSelectorContainer}>
                        <CommunicationTypeSelector
                            height="31px"
                            value={data.type}
                            handleUpdate={(type): void => {
                                if (type === ActionTypes.AUTO_REPLY) {
                                    handleUpdate({
                                        ...data,
                                        name:
                                            data.name === 'Notificación'
                                                ? 'Respuesta'
                                                : data.name,
                                        type,
                                        subject: undefined,
                                        recipients: undefined,
                                    });
                                } else {
                                    handleUpdate({
                                        ...data,
                                        type,
                                        name:
                                            data.name === 'Respuesta'
                                                ? 'Notificación'
                                                : data.name,
                                        subject: 'Notificación',
                                        recipients: [
                                            {
                                                type: NotificationRecipientTypes.EMAIL,
                                                email: user?.email,
                                            },
                                        ],
                                    });
                                }
                            }}
                        />
                    </div>
                </div>
                {data.type === ActionTypes.NOTIFICATION && (
                    <div className={styles.subjectContainer}>
                        <RoundedTextField
                            label="Asunto"
                            value={data.subject}
                            onChange={(e): void => {
                                handleUpdate({
                                    ...data,
                                    subject: e.target.value,
                                });
                            }}
                        />
                    </div>
                )}

                <div className={styles.editorContentContainer}>
                    <PayloadEditor
                        autoFocus
                        idEditor={`Flow-${idFlow}-${nodeId}`}
                        placeholder={
                            data.type === ActionTypes.AUTO_REPLY
                                ? 'Respuesta'
                                : 'Notificación'
                        }
                        context={PayloadContext}
                        disableUnmount
                        conditions={conditions}
                        payload={data.payload}
                    />
                </div>

                {data.type === ActionTypes.NOTIFICATION && (
                    <div className={styles.recipientsContainer}>
                        <div className={styles.recipientsLbl}>Notificar a:</div>
                        <div className={styles.recipientList}>
                            {data.recipients.map((recipient, index) => (
                                <RecipientComponent
                                    key={index}
                                    recipient={recipient}
                                    handleUpdate={(
                                        value: NotificationRecipient
                                    ): void => {
                                        handleUpdate(
                                            produce(
                                                data,
                                                (newData: NotificationData) => {
                                                    newData.recipients[index] =
                                                        value;
                                                }
                                            )
                                        );
                                    }}
                                    handleDelete={(): void => {
                                        handleUpdate(
                                            produce(
                                                data,
                                                (newData: NotificationData) => {
                                                    newData.recipients.splice(
                                                        index,
                                                        1
                                                    );
                                                }
                                            )
                                        );
                                    }}
                                />
                            ))}
                        </div>
                        <div
                            className={styles.addBtn}
                            onClick={(): void => {
                                handleUpdate({
                                    ...data,
                                    recipients: [
                                        ...data.recipients,
                                        {
                                            type: NotificationRecipientTypes.EMAIL,
                                            email: user.email,
                                        },
                                    ],
                                });
                            }}
                        >
                            <div className={styles.addIconContainer}>
                                <AddRoundedIcon fontSize="inherit" />
                            </div>
                        </div>
                    </div>
                )}
                {(checkAdmin(user) ||
                    user.email ===
                        'direcciongeneral@apoyosfinancieros.com') && (
                    <CommunicationIntegration
                        nodeId={nodeId}
                        data={data}
                        handleUpdate={handleUpdate}
                    />
                )}
            </div>
        </div>
    );
}

export default CommunicationDialog;
