import { InmuebleInfo360 } from '../../../../types';
import styles from './Entidades.module.css';
import {
    empStrOrUndToNA,
    getPrettyCurrencyValue,
    joinEntityStrings,
} from '../../../Utils/otherFunctions';
import { OpenComponent } from '../../../Utils/Openable/OpenComponent';

interface EntidadesProps {
    entidades: InmuebleInfo360['cartera']['entidades'];
    conceptosMap: InmuebleInfo360['cartera']['conceptosMap'];
}

export function Entidades({
    entidades,
    conceptosMap,
}: EntidadesProps): JSX.Element {
    const creditoValor = conceptosMap.subs[2].valorPactado;
    const subsidioValor = conceptosMap.subs[4].valorPactado;
    const cesantiasValor = conceptosMap.subs[7].valorPactado;
    const ahorroValor = conceptosMap.subs[8].valorPactado;
    return (
        <OpenComponent title={'Entidades'}>
            <div className={styles.container}>
                <div className={styles.entidad}>
                    <div className={styles.label}>Crédito</div>
                    <div className={styles.valor}>
                        <div className={styles.subLabel}>Valor crédito:</div>
                        <div className={styles.subValor}>
                            {getPrettyCurrencyValue(creditoValor)}
                        </div>
                    </div>
                    <div className={styles.nombre}>
                        <div className={styles.subLabel}>Entidad crédito:</div>
                        <div className={styles.subValor}>
                            {empStrOrUndToNA(
                                joinEntityStrings(entidades.entidadCredito)
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.entidad}>
                    <div className={styles.label}>Subsidio</div>
                    <div className={styles.valor}>
                        <div className={styles.subLabel}>Valor subsidio:</div>
                        <div className={styles.subValor}>
                            {getPrettyCurrencyValue(subsidioValor)}
                        </div>
                    </div>
                    <div className={styles.nombre}>
                        <div className={styles.subLabel}>Entidad subsidio:</div>
                        <div className={styles.subValor}>
                            {empStrOrUndToNA(
                                joinEntityStrings(entidades.entidadSubsidio)
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.entidad}>
                    <div className={styles.label}>Cesantías</div>
                    <div className={styles.valor}>
                        <div className={styles.subLabel}>Valor cesantías:</div>
                        <div className={styles.subValor}>
                            {getPrettyCurrencyValue(cesantiasValor)}
                        </div>
                    </div>
                    <div className={styles.nombre}>
                        <div className={styles.subLabel}>
                            Entidad cesantías:
                        </div>
                        <div className={styles.subValor}>
                            {empStrOrUndToNA(
                                joinEntityStrings(entidades.entidadCesantias)
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.entidad}>
                    <div className={styles.label}>Ahorros</div>
                    <div className={styles.valor}>
                        <div className={styles.subLabel}>Valor ahorros:</div>
                        <div className={styles.subValor}>
                            {getPrettyCurrencyValue(ahorroValor)}
                        </div>
                    </div>
                    <div className={styles.nombre}>
                        <div className={styles.subLabel}>Entidad ahorros:</div>
                        <div className={styles.subValor}>
                            {empStrOrUndToNA(
                                joinEntityStrings(entidades.entidadAhorro)
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </OpenComponent>
    );
}
