import { Company } from '../../../@Types/Company';
import {
    loadCompaniesById,
    loadCompanies,
} from '../../../controllers/CompaniesController/CompaniesService';
import GenericMenu, { GenericMenuProps } from '../GenericMenu/GenericMenu';

function CompaniesMenu(
    props: Omit<
        GenericMenuProps<Company, string>,
        'calcLbl' | 'loadInitialType' | 'loadElements' | 'label'
    >
): JSX.Element {
    return (
        <GenericMenu<Company, string>
            {...props}
            label="Empresa"
            calcLbl={(company): string => company.values.name ?? 'Empresa'}
            loadInitialType={loadCompaniesById}
            loadElements={async (
                page: number,
                pageSize: number,
                search: string | undefined
            ): Promise<Company[]> => {
                return await loadCompanies(
                    page,
                    pageSize,
                    [],
                    undefined,
                    search
                );
            }}
        />
    );
}
export default CompaniesMenu;
