import React from 'react';
import styles from './CreateSubTicket.module.css';
import RoundedTextField from '../../../../../../shared/RoundedTextField/RoundedTextField';
import RoundedButton from '../../../../../../shared/RoundedButton/RoundedButton';
import { Controller, useForm } from 'react-hook-form';
import ticketService from '../../../../../../controllers/TicketsController/TicketsService';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../utils/_store';
import { Ticket } from '../../../../../../@Types/@Types';
interface CreateSubTicketProps {
    /** Function that handles when canceled or created */
    close: Function;
    /** Parent of the ticket currently being created, null if root */
    parent: Ticket;
    /** Function that handles when ticket has been created */
    handleCreated: Function;
    /** Function inherited from the dialog to activate the loader */
    setLoading?: Function;
}
/**
 * Component rendered inside the create ticket dialog.
 */
function CreateSubTicket({
    close,
    parent,
    handleCreated,
    setLoading,
}: CreateSubTicketProps): JSX.Element {
    const idProject = useSelector((state: RootState) => state.site.idProject);

    const {
        getValues,
        control,
        formState: { errors, isDirty, isValid },
        trigger,
    } = useForm({
        mode: 'onChange',
    });

    /**
     * Function triggered when user want to create an ticket, checks if values are valid and calls the correct service
     */
    const handleCreate = async (): Promise<void> => {
        trigger();
        const values = getValues();

        if (isValid && isDirty && idProject && setLoading) {
            try {
                setLoading(true);
                await ticketService.createSubTicket(
                    idProject,
                    parent._id,
                    values
                );

                setLoading(false);
                handleCreated();
                close();
            } catch (error) {
                setLoading(false);
                //TODO handle error
                console.error(error);
            }
        }
    };

    return (
        <div className={styles.container + ' standard-dialog'}>
            <h2 className="center-anything">Crear SubCaso</h2>
            {parent !== null && (
                <div className={styles.parentInfo}>
                    <label className={styles.label}>
                        Padre: {parent.subject}
                    </label>
                </div>
            )}
            <div className="text-field-container">
                <Controller
                    name="subject"
                    defaultValue={''}
                    control={control}
                    rules={{
                        required: 'El asunto es obligatorio',
                    }}
                    render={({ field }): JSX.Element => (
                        <RoundedTextField
                            {...field}
                            label="Asunto"
                            helperText={
                                errors.subject ? errors.subject.message : ''
                            }
                            error={!!errors.subject}
                        />
                    )}
                />
            </div>
            <div className="text-field-container">
                <Controller
                    name="description"
                    control={control}
                    defaultValue={''}
                    rules={{
                        required: 'La descripción es obligatoria',
                    }}
                    render={({ field }): JSX.Element => (
                        <RoundedTextField
                            {...field}
                            label="Descripción"
                            multiline={true}
                            helperText={
                                errors.description
                                    ? errors.description.message
                                    : ''
                            }
                            error={!!errors.description}
                        />
                    )}
                />
            </div>
            <div className="center-anything">
                <div className="button">
                    <RoundedButton
                        text="Cancelar"
                        onClick={(): void => {
                            close();
                        }}
                    />
                </div>
                <div className="button">
                    <RoundedButton
                        text="Crear SubCaso"
                        backgroundColor="var(--secondary)"
                        color="white"
                        disabled={!!errors.subject && !!errors.description}
                        onClick={handleCreate}
                    />
                </div>
            </div>
        </div>
    );
}
export default CreateSubTicket;
