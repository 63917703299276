import React, { useEffect, useState } from 'react';
import { Ticket } from '../../../@Types/@Types';
import {
    Process,
    ProcessVisualElement,
} from '../../../@Types/ProcessTypes/Process';
import styles from './ProcessElement.module.css';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { ActivityTypes } from '../../../constants/ActivityTypes/ActivityTypes';
import { format } from 'date-fns';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';
import { getLocale } from '../../..';
import SmartAgentGroup from '../../SmartAgentGroup/SmartAgentGroup';

interface ProcessElementProps {
    /** The ticket to display */
    ticket: Ticket;
    /** The process to display */
    process: Process;
    element: CalculatedVisualElement;
    last: boolean;
}

interface CalculatedVisualElement extends ProcessVisualElement {
    progress: number | null;
    subElements: CalculatedVisualElement[];
}

function ProcessElement({
    ticket,
    process,
    element,
    last,
}: ProcessElementProps): JSX.Element {
    const ticketProcess = ticket.process;
    if (!ticketProcess) return <div></div>;
    const [open, setOpen] = useState(
        element.progress !== null &&
            element.progress > 0 &&
            element.progress < 100 &&
            element.subElements.length > 0
    );

    useEffect(() => {
        setOpen(
            element.progress !== null &&
                element.progress > 0 &&
                element.progress < 100 &&
                element.subElements.length > 0
        );
    }, [ticket.process]);

    const executions = ticketProcess.activities[element.idActivity];
    const activity = executions?.[executions?.length - 1];

    const hasSubElements =
        element.subElements.find(
            (sub) => !sub.optional || (sub.progress && sub.progress > 0)
        ) &&
        (element.progress === 0 || element.progress === 100);
    const renderIcon = (): JSX.Element => {
        if (element.progress === null) {
            return (
                <div className={styles.errorIcon}>
                    <WarningRoundedIcon
                        fontSize="inherit"
                        style={{ marginTop: 1, marginLeft: 2 }}
                    />
                </div>
            );
        } else if (element.progress === 100) {
            return (
                <div className={styles.fullIcon}>
                    <CheckRoundedIcon
                        fontSize="inherit"
                        style={{ marginTop: 2, marginLeft: 2 }}
                    />
                </div>
            );
        } else if (element.progress > 0) {
            let progress = element.progress;
            if (progress > 90) progress = 90;
            if (progress < 10) progress = 10;
            return (
                <div
                    className={styles.partialIcon}
                    style={{ '--p': progress + '%' } as any}
                ></div>
            );
        } else {
            return <div className={styles.emptyIcon}></div>;
        }
    };
    const renderSubElements = (): JSX.Element => {
        const elementsToRender = element.subElements.filter(
            (e) => !e.hidden || e.progress
        );

        return (
            <div className={styles.subElementsContainer}>
                {elementsToRender.map((e, index) => (
                    <ProcessElement
                        key={index}
                        ticket={ticket}
                        process={process}
                        element={e}
                        last={index === elementsToRender.length - 1}
                    />
                ))}
            </div>
        );
    };

    const renderContent = (): JSX.Element | void => {
        if (
            activity &&
            element.progress &&
            element.progress > 0 &&
            element.progress < 100
        ) {
            switch (activity.type) {
                case ActivityTypes.FORM:
                    const date = new Date(activity.resolution_date);
                    return (
                        <React.Fragment>
                            {activity.resolution_date && (
                                <div
                                    className={
                                        open
                                            ? styles.dateContent
                                            : styles.dateInfo
                                    }
                                >
                                    <ScheduleRoundedIcon
                                        fontSize="inherit"
                                        style={{ color: 'var(--secondary)' }}
                                    />
                                    <label
                                        className={styles.dateLbl}
                                        style={
                                            new Date() > date
                                                ? {
                                                      color: 'var(--error)',
                                                  }
                                                : {}
                                        }
                                    >
                                        {format(date, 'Pp', {
                                            locale: getLocale(),
                                        })}
                                    </label>
                                </div>
                            )}
                            {/*  Esto es un machete para mostrar fechas en lvp con grupos, toca rediseñarlo*/}
                            {!open && (
                                <div className={styles.agentsInfo}>
                                    <SmartAgentGroup
                                        max={4}
                                        agents={activity.agents}
                                    ></SmartAgentGroup>
                                </div>
                            )}
                        </React.Fragment>
                    );
                case ActivityTypes.TIME:
                case ActivityTypes.DATE:
                    return (
                        <React.Fragment>
                            <div className={styles.dateInfo}>
                                <ScheduleRoundedIcon
                                    fontSize="inherit"
                                    style={{ color: 'var(--secondary)' }}
                                />
                                <label className={styles.dateLbl}>
                                    {format(new Date(activity.date), 'Pp', {
                                        locale: getLocale(),
                                    })}
                                </label>
                            </div>
                        </React.Fragment>
                    );
                case ActivityTypes.REPLY:
                    if (!activity.resolution_date) return <></>;
                    return (
                        <React.Fragment>
                            <div className={styles.dateInfo}>
                                <ScheduleRoundedIcon
                                    fontSize="inherit"
                                    style={{ color: 'var(--secondary)' }}
                                />
                                <label className={styles.dateLbl}>
                                    {format(
                                        new Date(activity.resolution_date),
                                        'Pp',
                                        {
                                            locale: getLocale(),
                                        }
                                    )}
                                </label>
                            </div>
                        </React.Fragment>
                    );
                default:
                    break;
            }
        }
    };
    return (
        <React.Fragment>
            <div
                className={styles.header}
                style={{
                    cursor:
                        hasSubElements || element.idAction
                            ? 'pointer'
                            : 'default',
                }}
                onClick={(): void => {
                    if (hasSubElements) {
                        setOpen(!open);
                    } else if (element.idAction) {
                        const list = document.getElementById('actions-list');
                        const selectedNode: any = list?.querySelector(
                            'action-' + element.idAction
                        );
                        if (list && selectedNode) {
                            list.scrollTo(
                                0,
                                selectedNode.offsetTop - list.offsetTop - 10
                            );
                        }
                    }
                }}
            >
                <div className={styles.iconContainer}>{renderIcon()}</div>
                <label
                    className={styles.label}
                    style={{
                        cursor:
                            hasSubElements || element.idAction
                                ? 'pointer'
                                : 'default',
                    }}
                >
                    {element.label + (open ? ':' : '')}
                </label>
                {hasSubElements && (
                    <div className={styles.showMoreIcon}>
                        {open ? (
                            <ExpandLessIcon fontSize="inherit" />
                        ) : (
                            <ExpandMoreIcon fontSize="inherit" />
                        )}
                    </div>
                )}
            </div>
            {open && renderContent()}
            {open && renderSubElements()}
            {!open && !last && (
                <div className={styles.infoContainer}>
                    <div className={styles.separatorCont}>
                        <div
                            className={styles.separator}
                            style={{
                                backgroundColor:
                                    element.progress === 100
                                        ? 'var(--secondary)'
                                        : '#bdbdbd',
                            }}
                        ></div>
                    </div>
                    <div className={styles.info}>{renderContent()}</div>
                </div>
            )}
            {!open &&
                last &&
                activity &&
                !!element.progress &&
                element.progress > 0 &&
                element.progress < 100 && (
                    <div className={styles.lastInfo}>{renderContent()}</div>
                )}
        </React.Fragment>
    );
}
export default ProcessElement;
