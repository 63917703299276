import React, { useState } from 'react';
import styles from './CreateClassifier.module.css';
import RoundedTextField from '../../../shared/RoundedTextField/RoundedTextField';
import RoundedButton from '../../../shared/RoundedButton/RoundedButton';
import { TwitterPicker } from 'react-color';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Controller, useForm } from 'react-hook-form';
import { calcTextColor } from '../../../utils/colors';
import classifierService from '../../../controllers/ClassifiersController/ClassifiersService';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import { Classifier } from '../../../@Types/@Types';
interface CreateClassifierProps {
    /** Function that handles when canceled or created */
    close: Function;
    /** Parent of the Classifier currently being created, null if root */
    parent: Classifier | null;
    /** Function that handles when classifier has been created */
    handleCreated: Function;
    /** Function inherited from the dialog to activate the loader */
    setLoading?: Function;
}
/**
 * Component rendered inside the create classifier dialog.
 */
function CreateClassifier({
    close,
    parent,
    handleCreated,
    setLoading,
}: CreateClassifierProps): JSX.Element {
    const idProject = useSelector((state: RootState) => state.site.idProject);
    const [choosingColor, setChoosingColor] = useState(false);
    const [currentColor, setCurrentColor] = useState('#123245'); //Todo calcular color que no se esta usando.
    const {
        getValues,
        control,
        formState: { errors, isDirty, isValid },
        trigger,
    } = useForm({
        mode: 'onChange',
    });

    /**
     * Function triggered when user want to create an classifier, checks if values are valid and calls the correct service
     */
    const handleCreate = async (): Promise<void> => {
        trigger();
        const values = getValues();
        const color = {
            background: currentColor,
            font: calcTextColor(currentColor),
        };
        if (!values.description) {
            values.description = '';
        }
        if (isValid && isDirty && idProject && setLoading) {
            try {
                setLoading(true);
                if (parent === null) {
                    values.color = color;
                    await classifierService.createRoot(idProject, values);
                } else {
                    values.parent = parent._id;
                    await classifierService.createNode(idProject, values);
                }
                handleCreated();
                close();
            } catch (error) {
                setLoading(false);
                //TODO handle error
                console.error(error);
            }
        }
    };

    return (
        <div
            className={styles.container + ' standard-dialog'}
            data-testid={'CreateClassifierContainer'}
        >
            <h2 className="center-anything">Crear Clasificador</h2>
            {parent !== null && (
                <div className={styles.parentInfo}>
                    <label className={styles.label}>Padre: {parent.name}</label>
                </div>
            )}
            <div
                className="text-field-container"
                data-testid={'CreateClassifier_name'}
            >
                <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: 'El nombre es obligatorio',
                    }}
                    render={({ field }): JSX.Element => (
                        <RoundedTextField
                            {...field}
                            label="Nombre"
                            helperText={errors.name ? errors.name.message : ''}
                            error={!!errors.name}
                        />
                    )}
                />
            </div>
            <div
                className="text-field-container"
                data-testid={'CreateClassifier_description'}
            >
                <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    render={({ field }): JSX.Element => (
                        <RoundedTextField
                            {...field}
                            label="Descripción"
                            helperText={
                                errors.description
                                    ? errors.description.message
                                    : ''
                            }
                            error={!!errors.description}
                        />
                    )}
                />
            </div>

            {parent === null && (
                <div className="info-field-container">
                    <div className={styles.colorPickCont}>
                        <label className={styles.label}>Color:</label>
                        <div
                            className={styles.colorPick}
                            style={{ backgroundColor: currentColor }}
                            data-testid={'CreateClassifier_colorPick'}
                            onClick={(): void => {
                                setChoosingColor(true);
                            }}
                        />
                        {choosingColor && (
                            <div
                                className={
                                    styles.colorPickContainer + ' shadow'
                                }
                            >
                                <ClickAwayListener
                                    mouseEvent="onMouseDown"
                                    onClickAway={(): void => {
                                        setChoosingColor(false);
                                    }}
                                >
                                    <TwitterPicker
                                        color={currentColor}
                                        // colors={[
                                        //     '#F26430',
                                        //     '#E3B505',
                                        //     '#6CC551',
                                        //     '#2C6E49',
                                        //     '#0081AF',
                                        //     '#33658A',
                                        //     '#ABB8C3',
                                        //     '#cc2936',
                                        //     '#C45AB3',
                                        //     '#700353',
                                        // ]}
                                        triangle="hide"
                                        onChangeComplete={(color): void => {
                                            setChoosingColor(false);
                                            setCurrentColor(color.hex);
                                        }}
                                    />
                                </ClickAwayListener>
                            </div>
                        )}
                    </div>
                </div>
            )}
            <div className="center-anything">
                <div className="button">
                    <RoundedButton
                        text="Cancelar"
                        onClick={(): void => {
                            close();
                        }}
                    />
                </div>
                <div
                    className="button"
                    data-testid={'CreateClassifier_confirm'}
                >
                    <RoundedButton
                        text="Crear Clasificador"
                        backgroundColor="var(--secondary)"
                        color="white"
                        disabled={!!errors.name}
                        onClick={handleCreate}
                    />
                </div>
            </div>
        </div>
    );
}
export default CreateClassifier;
