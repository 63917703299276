import React, { useEffect, useState } from 'react';
import styles from './FormPreview.module.css';
import { GBaseStep, GForm } from '../../../@Types/GenericForm';
import DesktopWindowsRoundedIcon from '@material-ui/icons/DesktopWindowsRounded';
import SmartphoneRoundedIcon from '@material-ui/icons/SmartphoneRounded';
import FormRenderer from '../../FormRenderer/FormRenderer';
import { CustomStep } from '@arquimedes.co/eureka-forms/dist/FormSteps/CustomStep';
interface FormPreviewProps<S extends GBaseStep, U> {
    /** The form to preview */
    form: GForm<S, U>;
    /** Custom steps to display */
    customSteps?: Record<string, CustomStep>;
}
/**
 * Component rendered inside the preview dialog.
 */
function FormPreview<S extends GBaseStep, U>({
    form,
    customSteps,
}: FormPreviewProps<S, U>): JSX.Element {
    const [mobile, setMobile] = useState(false);
    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [mobile]);

    return (
        <div className={styles.container}>
            <div
                className={styles.previewBtn}
                onClick={(): void => {
                    setMobile(!mobile);
                }}
            >
                {mobile ? (
                    <DesktopWindowsRoundedIcon fontSize="inherit" />
                ) : (
                    <SmartphoneRoundedIcon fontSize="inherit" />
                )}
            </div>
            {form && (
                <div
                    className={
                        mobile
                            ? styles.mobileWidgetContainer
                            : styles.widgetContainer
                    }
                    style={
                        mobile
                            ? {}
                            : {
                                  width:
                                      (form.size.blockSize +
                                          form.size.spacingSize) *
                                          form.size.blockNum +
                                      60,
                              }
                    }
                >
                    <FormRenderer
                        preview={true}
                        form={{
                            ...form,
                            classifiers: undefined,
                        }}
                        customSteps={customSteps}
                        customSubmitBtns={(): JSX.Element => <div></div>}
                    />
                </div>
            )}
        </div>
    );
}

export default FormPreview;
