import StepTypes, {
    LocationTypes,
} from '../../../constants/Conversations/ConversationStepTypes';
import {
    ConversationStep,
    EntityValueStep,
} from '../../../@Types/ConversationTypes/ConversationStep';
import { nanoid } from 'nanoid';
import { Entity, Project } from '../../../@Types/@Types';
import ConversationStepTypes from '../../../constants/Conversations/ConversationStepTypes';
import CBRFormStepTypes from '../../../constants/Construction/CBRFormStepTypes';
import { calcBaseCBRConvStepName } from './AddStep/StepMenu/CBRStepMenu/CBRStepFunctions';
import { EntityValueOptionTypes } from '../../../constants/OptionTypes';
import {
    EntityValuePickerFilter,
    EntityValuePickerPath,
} from '../../../@Types/GenericFormSteps';
import FormStepTypes from '../../../constants/FormStepTypes';
import { stringToDraft } from '../../../utils/draftFunctions';
import { EurekaDraft } from '../../../@Types/Draft/Draft';
import { DraftEntityTypes } from '../../../constants/Draft/DraftEntityTypes';
import { DraftEntityDataTypes } from '../../../constants/Draft/DraftEntityDataTypes';
import { TicketPropertyTypes } from '../../../constants/TicketPropertyTypes';
import {
    ConversationPageState,
    StepLocation,
    StepDependencies,
    calcLocationSteps,
} from '../../../controllers/ConversationEditorController/ConversationEditorReducer';
import { ApiOptionTypes } from '../../../constants/ApiOptionTypes';

export const CreateMessage: EurekaDraft = {
    blocks: [
        {
            key: 'fjvto',
            text: 'Tu caso número ABC123 ha sido registrado en nuestra plataforma, pronto te estaremos dando respuesta.',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [
                {
                    offset: 15,
                    length: 6,
                    style: 'BOLD',
                },
            ],
            entityRanges: [
                {
                    offset: 15,
                    length: 6,
                    key: 0,
                },
            ],
            data: {},
        },
    ],
    entityMap: {
        '0': {
            type: DraftEntityTypes.EUREKA,
            mutability: 'IMMUTABLE',
            data: {
                type: DraftEntityDataTypes.TICKET,
                property: TicketPropertyTypes.CASENUMBER,
            },
        },
    },
};

export interface CreateConversationStepOptions {
    message?: boolean;
}

export const calcBaseConvStepName = (type: StepTypes): string => {
    switch (type) {
        case StepTypes.INFO_TEXT_STEP:
        case StepTypes.INFO_IMAGE_STEP:
        case StepTypes.INFO_DOCUMENT_STEP:
        case StepTypes.INFO_VIDEO_STEP:
        case StepTypes.INFO_AUDIO_STEP:
        case StepTypes.INFO_STICKER_STEP:
            return 'Mensaje Informativo';
        case StepTypes.TEXT_STEP:
            return 'Solicitud de Texto';
        case StepTypes.FILE_STEP:
            return 'Solicitud de Archivo';
        case StepTypes.LIST_STEP:
        case StepTypes.LIST_API_STEP:
        case StepTypes.LIST_PAGE_STEP:
            return 'Solicitud de Lista';
        case StepTypes.BUTTONS_STEP:
            return 'Solicitud de Botones';
        case StepTypes.OPEN_STEP:
            return 'Cuerpo del Caso';
        case StepTypes.LINK_STEP:
            return 'Mensaje de Enlace';
        case StepTypes.CREATE_PASSTHROUGH:
        case StepTypes.CREATE_DEFAULT:
            return 'Creación del Caso';
        case StepTypes.NAV_BACK_STEP:
            return 'Devolverse';
        case StepTypes.NAV_EXIT_STEP:
            return 'Finalizar la Conversación';
        case StepTypes.CREATABLE_STEP:
            return 'Solicitud de Elementos';
        default:
            if (type.startsWith('CBR_')) {
                return calcBaseCBRConvStepName(type as any);
            }
            throw Error();
    }
};

export function createConversationStep(
    type: StepTypes,
    id?: string,
    options?: CreateConversationStepOptions
): ConversationStep {
    const baseStep = {
        id: id ?? type + '-' + nanoid(10),
        conditions: [],
        name: calcBaseConvStepName(type),
    };

    switch (type) {
        case StepTypes.INFO_TEXT_STEP:
            return {
                ...baseStep,
                type,
                name: 'Mensaje Informativo',
                message: stringToDraft('Mensaje Informativo'),
            };
        case StepTypes.INFO_IMAGE_STEP:
            return {
                ...baseStep,
                type,
                name: 'Mensaje Informativo',
                caption: stringToDraft(''),
                file: {
                    fileName: 'Capta.png',
                    url: 'https://content.capta.co/Capta/Files/CaptaSqr.png',
                },
                width: 200,
                ratio: 1,
            };
        case StepTypes.INFO_DOCUMENT_STEP:
            return {
                ...baseStep,
                type,
                name: 'Mensaje Informativo',
                caption: stringToDraft(''),
                file: {
                    fileName: 'Capta.pdf',
                    url: 'https://content.capta.co/Capta/Files/Capta.pdf',
                },
            };
        case StepTypes.INFO_VIDEO_STEP:
            return {
                ...baseStep,
                type,
                name: 'Mensaje Informativo',
                caption: stringToDraft(''),
                file: {
                    fileName: 'Capta.mp4',
                    url: 'https://content.capta.co/Capta/Files/Capta.mp4',
                },
                width: 200,
                ratio: 1,
            };
        case StepTypes.INFO_AUDIO_STEP:
            return {
                ...baseStep,
                type,
                name: 'Mensaje Informativo',
                file: {
                    fileName: 'Audio.mp3',
                    url: 'https://content.capta.co/Capta/Files/Audio.mp3',
                },
            };
        case StepTypes.INFO_STICKER_STEP:
            return {
                ...baseStep,
                type,
                name: 'Mensaje Informativo',
                file: {
                    fileName: 'Capta.png',
                    url: 'https://content.capta.co/Capta/Files/CaptaSqr.png',
                },
                width: 200,
                ratio: 1,
            };
        case StepTypes.TEXT_STEP:
            return {
                ...baseStep,
                type,
                name: 'Solicitud de Texto',
                message: stringToDraft('Solicitud de Texto'),
                isSubject: false,
                showInTrace: false,
            };
        case StepTypes.FILE_STEP:
            return {
                ...baseStep,
                type,
                name: 'Solicitud de Archivo',
                message: stringToDraft('Solicitud de Archivo'),
                showInTrace: false,
            };
        case StepTypes.LIST_STEP:
        case StepTypes.LIST_PAGE_STEP:
            const idListOption = baseStep.id + ';' + nanoid();
            return {
                ...baseStep,
                type,
                name: 'Solicitud de Lista',
                header: '',
                message: stringToDraft(
                    'Selecciona  una de las siguientes opciones'
                ),
                footer: '',
                label: 'Seleccionar',
                options: {
                    [idListOption]: {
                        id: idListOption,
                        label: 'Opción',
                        description: '',
                        steps: [],
                    },
                },
                sections: [{ label: '', options: [idListOption] }],
                selectedOption: null,
                isSubject: false,
                showInTrace: false,
            };
        case StepTypes.LIST_API_STEP:
            return {
                ...baseStep,
                type,
                name: 'Solicitud de Lista',
                header: '',
                message: stringToDraft(
                    'Selecciona  una de las siguientes opciones'
                ),
                footer: '',
                label: 'Seleccionar',
                request: {
                    url: stringToDraft(''),
                    queryParams: [],
                },
                options: {},
                selectedOption: null,
                showInTrace: false,
            };
        case StepTypes.BUTTONS_STEP:
            const idButtonOption = baseStep.id + ';' + nanoid();
            return {
                ...baseStep,
                type,
                name: 'Solicitud de Botones',
                header: '',
                message: stringToDraft(
                    'Selecciona  una de las siguientes opciones'
                ),
                footer: '',
                options: {
                    [idButtonOption]: {
                        id: idButtonOption,
                        label: 'Opción',
                        steps: [],
                    },
                },
                optionList: [idButtonOption],
                selectedOption: null,
                isSubject: false,
                showInTrace: false,
            };
        case StepTypes.LINK_STEP:
            return {
                ...baseStep,
                type,
                name: 'Mensaje de Enlace',
                header: '',
                message: stringToDraft(
                    'Para más información haz clic en el siguiente enlace'
                ),
                footer: '',
                label: 'Ver más',
                url: '',
            };
        case StepTypes.OPEN_STEP:
            return {
                ...baseStep,
                type,
                name: 'Cuerpo del Caso',
                header: '',
                message: stringToDraft('Cuerpo del Caso'),
                footer: '',
                allowFiles: true,
                label: 'Crear Solicitud',
                isPrimary: true,
                showInTrace: false,
            };

        case StepTypes.CREATE_PASSTHROUGH:
            if (options?.message) {
                return {
                    type,
                    ...baseStep,
                    name: 'Creación del Caso',
                    message: CreateMessage,
                };
            }
            return { type, ...baseStep };
        case StepTypes.CREATE_DEFAULT:
            return {
                type,
                ...baseStep,
                showUrl: true,
                header: 'Caso Registrado',
                message: CreateMessage,
                footer: 'Para consultarlo, haz clic en el siguiente botón:',
                label: 'Ir al caso',
            };
        case StepTypes.NAV_BACK_STEP:
            return {
                ...baseStep,
                type,
                idBackStep: '',
                name: 'Devolverse',
            };
        case StepTypes.NAV_EXIT_STEP:
            return {
                ...baseStep,
                type,
                name: 'Finalizar la Conversación',
            };
        case StepTypes.CREATABLE_STEP:
            return {
                ...baseStep,
                type,
                name: 'Solicitud de Elementos',
                addBtnLabel: 'Agregar',
                endBtnLabel: 'Finalizar',
                message: stringToDraft('Deseas agregar otro Elemento?'),
                steps: [],
            };
        default:
            throw Error();
    }
}

export function createEntityStep(
    project: Project,
    entity: Entity,
    path: EntityValuePickerPath[] = [],
    filters: EntityValuePickerFilter[] = []
): EntityValueStep {
    let idValue = undefined;
    for (const [key, value] of Object.entries(project.values.values)) {
        if (
            value.type === FormStepTypes.ENTITYVALUEPICKER &&
            value.idEntity === entity._id
        ) {
            idValue = key;
        }
    }

    return {
        id: StepTypes.ENTITY_VALUE_STEP + '-' + entity._id + '-' + nanoid(),
        type: StepTypes.ENTITY_VALUE_STEP,
        name: 'Solicitud de ' + entity.name,
        message: stringToDraft(
            `Selecciona ${entity.feminine ? 'la' : 'el'} ${entity.name}`
        ),
        label: 'Seleccionar',
        showInTrace: false,
        idEntity: entity._id,
        filters,
        path,
        options: {},
        idValue,
    };
}

export function calcSubSteps(
    idStep: string,
    steps: Record<string, ConversationStep>
): string[] {
    const ids = [];
    const step = steps[idStep];
    switch (step.type) {
        case ConversationStepTypes.TEXT_STEP:
            if (step.authentication) {
                for (const idSubStep of step.authentication.errorSteps) {
                    ids.push(idSubStep, ...calcSubSteps(idSubStep, steps));
                }
            }
            break;
        case ConversationStepTypes.BUTTONS_STEP:
        case ConversationStepTypes.LIST_STEP:
        case ConversationStepTypes.LIST_PAGE_STEP:
            for (const idOption of Object.keys(step.options)) {
                const option = step.options[idOption];
                for (const idSubStep of option.steps) {
                    ids.push(idSubStep, ...calcSubSteps(idSubStep, steps));
                }
            }
            break;
        case ConversationStepTypes.LIST_API_STEP:
            for (const idOption of Object.keys(step.options)) {
                const option = step.options[idOption];
                if (option?.type === ApiOptionTypes.NESTED) {
                    for (const idSubStep of option.steps) {
                        ids.push(idSubStep, ...calcSubSteps(idSubStep, steps));
                    }
                }
            }
            break;
        case ConversationStepTypes.ENTITY_VALUE_STEP:
            for (const option of Object.values(step.options)) {
                if (option.type === EntityValueOptionTypes.NESTED) {
                    for (const idSubStep of option.steps) {
                        ids.push(idSubStep, ...calcSubSteps(idSubStep, steps));
                    }
                }
            }
            break;
        case ConversationStepTypes.GROUP_STEP:
        case ConversationStepTypes.CREATABLE_STEP:
            for (const idSubStep of step.steps) {
                ids.push(idSubStep, ...calcSubSteps(idSubStep, steps));
            }
            break;
        default:
            if ((step as any).type === CBRFormStepTypes.CBR_LOCATIVAS) {
                if ((step as any).subStep) {
                    ids.push(
                        (step as any).subStep,
                        ...calcSubSteps((step as any).subStep, steps)
                    );
                }
            }
            break;
    }
    return ids;
}

export const calcLocationPreviousSteps = (
    state: ConversationPageState,
    location: StepLocation
): StepDependencies['previousSteps'] => {
    let idStep;
    const subSteps = calcLocationSteps(state, location);
    if (location.type === LocationTypes.ROOT) {
        const index = location.indexStep ?? state.rootSteps.length;
        idStep = state.rootSteps[index - 1];
    } else if (subSteps && subSteps.length > 0) {
        const index = location.indexStep
            ? location.indexStep + 1
            : subSteps.length - 1;
        idStep = subSteps[index];
    } else {
        idStep = location.idStep;
    }
    return [...(state.stepDependencies[idStep]?.previousSteps ?? []), idStep];
};
