import { createAction } from '@reduxjs/toolkit';
import { Agent, Area } from '../../../@Types/@Types';
export const Types = {
    RESET: 'TICKET_AGENTS_TRANSFER/RESET',
    GET_SUCCESS: 'TICKET_AGENTS_TRANSFER/GET_SUCCESS',
    PAGE_SCROLL: 'TICKET_AGENTS_TRANSFER/NEW_PAGE_SCROLL',
    SELECT: 'TICKET_AGENTS_TRANSFER/SELECT_ELEMENT',
    SEARCH: 'TICKET_AGENTS_TRANSFER/SEARCH',
    LOADING: 'TICKET_AGENTS_TRANSFER/LOADING',
    FILTER: 'TICKET_AGENTS_TRANSFER/FILTER',
    SET_CHANGES: 'TICKET_AGENTS_TRANSFER/SET_CHANGES',
    TOGGLE_FILTERS: 'TICKET_AGENTS_TRANSFER/TOGGLE_FILTERS',
};

/**
 * Called when the agents page is first loaded, recives list of current agents to filter them out.
 */
export const resetTransfer = createAction(Types.RESET);

/**
 * Called to toggle the loader
 */
export const setLoading = createAction(
    Types.LOADING,
    function prepare(loading: boolean = true) {
        return {
            payload: loading,
        };
    }
);

/**
 * Called when the list needs to load more elements
 */
export const pageScroll = createAction(Types.PAGE_SCROLL);

/**
 * Called when the selectedElement (index) changes value
 */
export const selectElement = createAction<number>(Types.SELECT);

/**
 * Called when the search value changes
 */
export const search = createAction<string>(Types.SEARCH);

/**
 * Called when the filter values change
 */
export const filter = createAction<Area | null>(Types.FILTER);

/**
 *  Called when the saga has sucessfully retrieved the lists elements
 */
export const getSuccess = createAction<{
    elements: Agent[];
    noMoreElements: boolean;
}>(Types.GET_SUCCESS);

/**
 * Toggles if showing or not the filters
 */
export const toggleFilters = createAction(Types.TOGGLE_FILTERS);
