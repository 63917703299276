import { JSX, useEffect } from 'react';
import styles from './FloatingNotifications.module.css';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../hooks';
import { FloatingNotificationsSelectors } from '../../controllers/_SiteController/SiteReducer';
import { FullNotification } from '../../@Types/Notification';
import NotificationListItem from '../Navbar/Notifications/NotificationsMenu/NotificationListItem/NotificationListItem';
import { removeFloatingNotification } from '../../controllers/_SiteController/SiteActions';

function FloatingNotifications(): JSX.Element {
    const notifications = useAppSelector((state) =>
        FloatingNotificationsSelectors.selectAll(state)
    );
    return (
        <div className={styles.notificationContainer}>
            {notifications.map((notification, index) => {
                return (
                    <FloatingNotification
                        key={index}
                        hide={
                            notifications.length > 3 &&
                            index < notifications.length - 3
                        }
                        notification={notification}
                    ></FloatingNotification>
                );
            })}
        </div>
    );
}

export default FloatingNotifications;

interface FloatingNotificationProps {
    notification: FullNotification;
    hide: boolean;
}

function FloatingNotification({
    hide,
    notification,
}: FloatingNotificationProps): JSX.Element {
    const dispatch = useDispatch();
    let className = styles.notification;

    useEffect(() => {
        setTimeout(() => {
            handleRemove();
        }, 3000);
    }, []);

    useEffect(() => {
        if (hide) handleRemove();
    }, [hide]);

    const handleRemove = (): void => {
        className += ' ' + styles.notificationFadeOut;
        setTimeout(() => {
            dispatch(removeFloatingNotification(notification._id));
        }, 500);
    };

    return (
        <div className={className}>
            <NotificationListItem
                forToast={true}
                element={notification as any}
            />
        </div>
    );
}
