import React from 'react';
import styles from './Referencias.module.css';
import { ClienteInfo360 } from '../../../types';
import { OpenComponent } from '../../Utils/Openable/OpenComponent';
import { empStrOrUndToNA } from '../../Utils/otherFunctions';
interface RefereciasProps {
    referencias: ClienteInfo360['referencias'];
}

export function Referencias({ referencias }: RefereciasProps): JSX.Element {
    return (
        <OpenComponent title={'Referencias'}>
            <div className={styles.list}>
                {referencias.map((referencia, index) => (
                    <div className={styles.element} key={index}>
                        <div className={styles.sideBar}></div>
                        <div className={styles.info}>
                            <div className={styles.row}>
                                <div className={styles.label}>Nombre</div>
                                <div className={styles.value}>
                                    {empStrOrUndToNA(referencia.nombre)}
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.label}>Teléfono</div>
                                <div className={styles.value}>
                                    {empStrOrUndToNA(referencia.telefono)}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </OpenComponent>
    );
}
