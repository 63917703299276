import React from 'react';
import styles from './CreateArea.module.css';
import RoundedTextField from '../../../shared/RoundedTextField/RoundedTextField';
import RoundedButton from '../../../shared/RoundedButton/RoundedButton';
import { Controller, useForm } from 'react-hook-form';
import areaService from '../../../controllers/AreasController/AreasService';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import { Area } from '../../../@Types/@Types';
interface CreateAreaProps {
    /** Function that handles when canceled or created */
    close: Function;
    /** Parent of the area currently being created, null if root */
    parent: Area | null;
    /** Function that handles when area has been created */
    handleCreated: Function;
    /** Function inherited from the dialog to activate the loader */
    setLoading?: Function;
}
/**
 * Component rendered inside the create area dialog.
 */
function CreateArea({
    close,
    parent,
    handleCreated,
    setLoading,
}: CreateAreaProps): JSX.Element {
    const idProject = useSelector((state: RootState) => state.site.idProject);

    const {
        getValues,
        control,
        formState: { errors, isDirty, isValid },
        trigger,
    } = useForm({
        mode: 'onChange',
    });

    /**
     * Function triggered when user want to create an area, checks if values are valid and calls the correct service
     */
    const handleCreate = async (): Promise<void> => {
        trigger();
        const values = getValues();
        if (!values.description) {
            values.description = '';
        }
        if (isValid && isDirty && idProject && setLoading) {
            try {
                setLoading(true);
                if (parent === null) {
                    await areaService.createArea(idProject, values);
                } else {
                    values.parent = parent._id;
                    await areaService.createSubArea(idProject, values);
                }
                setLoading(false);
                handleCreated();
                close();
            } catch (error) {
                setLoading(false);
                //TODO handle error
                console.error(error);
            }
        }
    };

    return (
        <div
            className={styles.container + ' standard-dialog'}
            data-testid={'CreateAreaContainer'}
        >
            <h2 className="center-anything">Crear área</h2>
            {parent !== null && (
                <div className={styles.parentInfo}>
                    <label className={styles.label}>Padre: {parent.name}</label>
                </div>
            )}
            <div
                className="text-field-container"
                data-testid={'CreateArea_name'}
            >
                <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: 'El nombre es obligatorio',
                    }}
                    render={({ field }): JSX.Element => (
                        <RoundedTextField
                            {...field}
                            label="Nombre"
                            helperText={errors.name ? errors.name.message : ''}
                            error={!!errors.name}
                        />
                    )}
                />
            </div>
            <div
                className="text-field-container"
                data-testid={'CreateArea_description'}
            >
                <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    render={({ field }): JSX.Element => (
                        <RoundedTextField
                            {...field}
                            label="Descripción"
                            helperText={
                                errors.description
                                    ? errors.description.message
                                    : ''
                            }
                            error={!!errors.description}
                        />
                    )}
                />
            </div>
            <div className="center-anything">
                <div className="button">
                    <RoundedButton
                        text="Cancelar"
                        onClick={(): void => {
                            close();
                        }}
                    />
                </div>
                <div className="button" data-testid={'CreateArea_Confirm'}>
                    <RoundedButton
                        text="Crear área"
                        backgroundColor="var(--secondary)"
                        color="white"
                        disabled={!!errors.name}
                        onClick={handleCreate}
                    />
                </div>
            </div>
        </div>
    );
}
export default CreateArea;
