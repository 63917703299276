import axiosInstance from '../../../AxiosAPI';
import { Classifier } from '../../../@Types/@Types';

/**
 *  Function that load the classifiers of the current organization
 * @returns {roots, elements}
 */
export const loadClassifiers = async (): Promise<{
    /** an array if the ids of the root elements */
    roots: string[];
    /** a map of the organization's classifiers */
    elements: Record<string, Classifier>;
}> => {
    let response = await axiosInstance.get('/organization/classifiers');
    const roots: string[] = [];
    const elements: Record<string, Classifier> = {};
    if (response?.data && Array.isArray(response.data)) {
        for (const elem of response.data) {
            /** Cast to classifer */
            const element: Classifier = <Classifier>elem;
            /** Instantiate the Date */
            element.creation_date = new Date(element.creation_date);
            /** If path is null add to list of roots */
            if (element.path === null) {
                roots.push(element._id);
            }
            /** Add to map of elements */
            elements[element._id] = element;
        }
    } else {
        throw new Error();
    }
    return {
        roots: roots.sort(
            (root1, root2) =>
                (elements[root1].order ?? 0) - (elements[root2].order ?? 0)
        ),
        elements,
    };
};

/**
 * Function called to fetch specific classifiers by their id
 * @param classifiers
 * @returns
 */
export const fetchClassifiers = async (
    classifiers: string[]
): Promise<
    /** a map of the organization's classifiers */
    Classifier[]
> => {
    let response = await axiosInstance.get(
        '/organization/classifiers?id=' + classifiers.join(',')
    );
    const elements: Classifier[] = [];
    if (response?.data) {
        for (const elem of response.data) {
            /** Cast to classifer */
            const element: Classifier = <Classifier>elem;
            /** Instantiate the Date */
            element.creation_date = new Date(element.creation_date);
            /** If path is null add to list of roots */
            elements.push(element);
        }
    }
    return elements;
};

export default {
    loadClassifiers,
    fetchClassifiers,
};
