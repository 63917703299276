import { nanoid } from 'nanoid';
import CBRStepTypes from '../../../../../../constants/Construction/CBRFormStepTypes';
import { CBRConversationStep } from '../../../../../../@Types/ConversationTypes/CBRConversationStep';
import { stringToDraft } from '../../../../../../utils/draftFunctions';

export const calcBaseCBRConvStepName = (type: CBRStepTypes): string => {
    switch (type) {
        case CBRStepTypes.CBR_FIRST_NAME:
            return 'Solicitud de Nombre';
        case CBRStepTypes.CBR_MIDDLE_NAME:
            return 'Solicitud de Segundo Nombre';
        case CBRStepTypes.CBR_LAST_NAME:
            return 'Solicitud de Apellido';
        case CBRStepTypes.CBR_SECOND_LAST_NAME:
            return 'Solicitud de Segundo Apellido';
        case CBRStepTypes.CBR_DOC:
            return 'Solicitud de Documento';
        case CBRStepTypes.CBR_CEL:
            return 'Solicitud de Celular';
        case CBRStepTypes.CBR_PHONE:
            return 'Solicitud de Teléfono';
        case CBRStepTypes.CBR_EMAIL:
            return 'Solicitud de Correo';
        case CBRStepTypes.CBR_MACRO_PROYECTO:
            return 'Solicitud de Macro Proyecto';
        case CBRStepTypes.CBR_PROYECTO:
            return 'Solicitud de Proyecto';
        case CBRStepTypes.CBR_TIPO_DOC:
            return 'Solicitud de Tipo del Documento';
        case CBRStepTypes.CBR_TIPO_SOLICITANTE:
            return 'Solicitud de Tipo de Solicitante';
        case CBRStepTypes.CBR_INMUEBLE:
            return 'Solicitud de Inmueble';
        case CBRStepTypes.CBR_TIPO_ESPACIO:
            return 'Solicitud de Espacio';
        case CBRStepTypes.CBR_LOCATIVAS:
            return 'Solicitud de Locativas';
        case CBRStepTypes.CBR_COMENTARIO:
            return 'Solicitud de Comentario';
        default:
            throw Error();
    }
};
export function createCBRConversationStep(
    type: CBRStepTypes,
    id?: string
): CBRConversationStep {
    const baseStep = {
        id: id ?? type + '-' + nanoid(10),
        name: calcBaseCBRConvStepName(type),
        conditions: [],
    };
    switch (type) {
        case CBRStepTypes.CBR_FIRST_NAME:
            return {
                ...baseStep,
                type,
                message: stringToDraft('Indícanos tu nombre'),
                isSubject: false,
                showInTrace: false,
            };
        case CBRStepTypes.CBR_MIDDLE_NAME:
            return {
                ...baseStep,
                type,
                message: stringToDraft('Indícanos tu segundo nombre'),
                isSubject: false,
                showInTrace: false,
            };
        case CBRStepTypes.CBR_LAST_NAME:
            return {
                ...baseStep,
                type,

                message: stringToDraft('Indícanos tu apellido'),
                isSubject: false,
                showInTrace: false,
            };
        case CBRStepTypes.CBR_SECOND_LAST_NAME:
            return {
                ...baseStep,
                type,
                message: stringToDraft('Indícanos tu segundo apellido'),
                isSubject: false,
                showInTrace: false,
            };
        case CBRStepTypes.CBR_DOC:
            return {
                ...baseStep,
                type,
                message: stringToDraft('Indícanos el número del documento '),
                isSubject: false,
                showInTrace: false,
            };
        case CBRStepTypes.CBR_CEL:
            return {
                ...baseStep,
                type,
                message: stringToDraft('Indícanos tu celular'),
                isSubject: false,
                showInTrace: false,
            };
        case CBRStepTypes.CBR_PHONE:
            return {
                ...baseStep,
                type,
                message: stringToDraft('Indícanos tu teléfono'),
                isSubject: false,
                showInTrace: false,
            };
        case CBRStepTypes.CBR_EMAIL:
            return {
                ...baseStep,
                type,
                message: stringToDraft('Indícanos tu correo'),
                isSubject: false,
                showInTrace: false,
            };
        case CBRStepTypes.CBR_MACRO_PROYECTO:
            return {
                ...baseStep,
                type,
                message: stringToDraft('Selecciona el macro proyecto'),
                label: 'Seleccionar',
                showInTrace: false,
                request: {
                    url: stringToDraft(''),
                    queryParams: [],
                },
                options: {},
            };
        case CBRStepTypes.CBR_PROYECTO:
            return {
                ...baseStep,
                type,
                message: stringToDraft('Selecciona el proyecto'),
                label: 'Seleccionar',
                showInTrace: false,
                request: {
                    url: stringToDraft(''),
                    queryParams: [],
                },
                options: {},
            };
        case CBRStepTypes.CBR_TIPO_DOC:
            return {
                ...baseStep,
                type,
                message: stringToDraft('Selecciona el tipo de documento'),
                label: 'Seleccionar',
                showInTrace: false,
                request: {
                    url: stringToDraft(''),
                    queryParams: [],
                },
                options: {},
            };
        case CBRStepTypes.CBR_TIPO_SOLICITANTE:
            return {
                ...baseStep,
                type,
                message: stringToDraft('Selecciona el tipo de solicitante'),
                label: 'Seleccionar',
                showInTrace: false,
                request: {
                    url: stringToDraft(''),
                    queryParams: [],
                },
                options: {},
            };
        case CBRStepTypes.CBR_INMUEBLE:
            return {
                ...baseStep,
                type,
                message: stringToDraft('Indícanos el inmueble'),
                isSubject: false,
                showInTrace: false,
            };
        case CBRStepTypes.CBR_TIPO_ESPACIO:
            return {
                ...baseStep,
                type,
                message: stringToDraft('Selecciona el espacio'),
                label: 'Seleccionar',
                showInTrace: false,
                request: {
                    url: stringToDraft(''),
                    queryParams: [],
                },
                options: {},
            };
        case CBRStepTypes.CBR_LOCATIVAS:
            return {
                ...baseStep,
                type,
                message: stringToDraft('Selecciona el elemento del espacio'),
                label: 'Seleccionar',
                subStep: null,
                showInTrace: false,
                request: {
                    url: stringToDraft(''),
                    queryParams: [],
                },
                options: {},
            };
        case CBRStepTypes.CBR_COMENTARIO: {
            return {
                ...baseStep,
                type,
                message: stringToDraft(
                    'Agrega un comentario para tu incidencia'
                ),
                isSubject: false,
                showInTrace: false,
            };
        }
        default:
            throw Error();
    }
}
