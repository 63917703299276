import { AnyAction } from '@reduxjs/toolkit';
import { takeLatest, call, put, fork, select } from 'redux-saga/effects';
import * as actions from './CompaniesActions';
import {
    loadCompanies,
    loadCompaniesByQuery,
    loadCompanyById,
} from './CompaniesService';
import { RootState } from '../../utils/_store';
import { CompaniesPageState, companyToQueryString } from './CompaniesReducer';
import VanillaToast from '../../shared/Toast/Toast';
import ScrollTypes from '../../constants/ScrollTypes';

/** Number of elements per Page */
const PAGE_SIZE = 20;
function* resetCompanies(action: AnyAction): any {
    if (actions.reset.match(action)) {
        if (action.payload.idCompany || action.payload.queryString.length > 0) {
            try {
                const result = yield call(
                    loadCompaniesByQuery,
                    action.payload.queryString,
                    action.payload.idCompany
                );
                const changes = { id: false, query: false };
                result.lastPage = result.elements.length < PAGE_SIZE;
                if (
                    action.payload.idCompany &&
                    result.selectedElement === undefined
                ) {
                    changes.id = true;
                    VanillaToast.create({
                        title: 'No se encontró la Empresa',
                        text: `Empresa ${action.payload.idCompany} no encontrada`,
                        type: 'error',
                        timeout: 5000,
                    });
                }
                if (
                    action.payload.queryString.length > 0 &&
                    Object.keys(result.filters).length == 0 &&
                    !result.search
                ) {
                    changes.query = true;
                }
                if (changes.id || changes.query) {
                    const newQuery = companyToQueryString(
                        result.filters,
                        result.orderBy,
                        result.search,
                        true
                    );
                    let basePath = '/companies';
                    if (result.selectedElement !== undefined) {
                        basePath +=
                            '/' + result.elements[result.selectedElement]._id;
                    }
                    action.payload.history.replace({
                        pathname: basePath,
                        search: newQuery,
                        state: {
                            prevPath: action.payload.history.location.pathname,
                        },
                    });
                }
                yield put(actions.resetSuccess(result));
            } catch (error) {
                console.error(error);
            }
        } else {
            yield getCompanies(action);
        }
    }
}

/**
 * Function called when new companies should be rendered (reset,search, or newpage)
 * @param action of type Search, reset or scroll
 */
function* getCompanies(action: AnyAction): any {
    try {
        const pageInfo = yield select((state) => state.companiesPage);
        let nPage = 1;
        if (actions.pageScroll.match(action)) {
            nPage =
                action.payload === ScrollTypes.DOWN
                    ? pageInfo.pageDown
                    : pageInfo.pageUp;
        }
        let result = yield call(
            loadCompanies,
            nPage,
            PAGE_SIZE,
            pageInfo.filters,
            pageInfo.orderBy,
            pageInfo.search
        );
        const addedLength = result.length;
        /** Calcs if more elements are available */
        const lastPage =
            (pageInfo.pageDown !== 1 && result.length === 0) ||
            (pageInfo.pageDown === 1 && result.length < PAGE_SIZE);
        let selectedElement = pageInfo.selectedElement;
        if (actions.pageScroll.match(action)) {
            if (action.payload === ScrollTypes.DOWN) {
                result = [...pageInfo.elements].concat(result);
            } else {
                if (selectedElement !== undefined) {
                    selectedElement += result.length;
                }
                result = result.concat(...pageInfo.elements);
            }
        }
        let list;
        if (
            actions.pageScroll.match(action) &&
            action.payload === ScrollTypes.UP
        ) {
            list = document.getElementById('companies-infiniteList');
        }
        yield put(
            actions.getSuccess({ selectedElement, elements: result, lastPage })
        );
        if (list) list.scrollTop = addedLength * 115;
    } catch (error) {
        //TODO handle errors
        console.error(error);
    }
}

/**
 * Function called when a company needs to refresh its info
 * @param action of type Search, reset or scroll
 */
function* refreshCompany(): any {
    try {
        const pageInfo = yield select(
            (state: RootState) => state.companiesPage
        );
        if (pageInfo.selectedElement !== undefined) {
            const idCompany = pageInfo.elements[pageInfo.selectedElement]._id;
            let result = yield call(loadCompanyById, idCompany);
            yield put(actions.refreshSuccess(result));
        }
    } catch (error) {
        //TODO handle errors
        console.error(error);
    }
}

function* goTo(action: AnyAction): any {
    try {
        if (actions.goTo.match(action)) {
            const idCompany = action.payload.idCompany;
            const pageInfo: CompaniesPageState = yield select(
                (state: RootState) => state.companiesPage
            );
            const selectedElement =
                pageInfo.elements[pageInfo.selectedElement ?? -1];
            const queryChange =
                action.payload.queryString !==
                companyToQueryString(
                    pageInfo.filters,
                    pageInfo.orderBy,
                    pageInfo.search,
                    true
                );
            if (queryChange) {
                yield put(
                    actions.reset({
                        history: action.payload.history,
                        queryString: action.payload.queryString,
                        idCompany,
                    })
                );
            } else if (idCompany !== selectedElement?._id) {
                /** If the currently selected Element doesn't have the id in the url  */
                /** If user wants to unselect (no id in url) */
                if (idCompany === undefined) {
                    yield put(actions.selectElement(undefined));
                } else {
                    /** Check if element is in the current list. */
                    const index = pageInfo.elements.findIndex(
                        (listElement) => listElement._id === idCompany
                    );
                    /** IF element is in current list select it. */
                    if (index >= 0) {
                        yield put(actions.selectElement(index));
                    } else {
                        /** Reload page as if new one. */
                        yield put(
                            actions.reset({
                                history: action.payload.history,
                                queryString: action.payload.queryString,
                                idCompany,
                            })
                        );
                    }
                }
            }
        }
    } catch (error) {
        //TODO handle errors
        console.error(error);
    }
}
function* watchResetCompanies(): any {
    yield takeLatest([actions.Types.RESET], resetCompanies);
}

function* watchGetCompaniesRequest(): any {
    yield takeLatest(
        [
            actions.Types.REFRESH,
            actions.Types.SEARCH,
            actions.Types.PAGE_SCROLL,
            actions.Types.FILTER,
        ],
        getCompanies
    );
}

function* watchRefreshCompany(): any {
    yield takeLatest([actions.Types.REFRESH_CURRENT], refreshCompany);
}

function* watchGoTo(): any {
    yield takeLatest([actions.Types.GO_TO], goTo);
}

export default [
    fork(watchGoTo),
    fork(watchGetCompaniesRequest),
    fork(watchRefreshCompany),
    fork(watchResetCompanies),
];
