import { InmuebleInfo360 } from '../../../../types';
import MaterialTable from '../../../Utils/MaterialTable';
import { calcDate } from '../../../../../../utils/DateFunctions';
import { OpenComponent } from '../../../Utils/Openable/OpenComponent';

interface TramitesTableProps {
    tramites: InmuebleInfo360['tramites'];
}

export function TramitesTable({ tramites }: TramitesTableProps): JSX.Element {
    const now = new Date();
    return (
        <OpenComponent title={'Tramites'} showOpen={true}>
            <MaterialTable
                style={{
                    primaryColor: '#3d5a7f',
                    outlineColor: 'rgba(196,184,184,0.52)',
                    fontSize: 14,
                }}
                part={{
                    title: '',
                    headers: [
                        {
                            label: 'Siglas',
                        },
                        {
                            label: 'Trámite',
                        },
                        {
                            label: 'Estado',
                        },
                        {
                            label: 'Fecha de compromiso',
                        },
                        {
                            label: 'Fecha de cumplimiento',
                        },
                    ],
                    rows: tramites.map((tramite: any) => ({
                        isHeader: false,
                        data: [
                            {
                                value: tramite.codigo,
                            },
                            {
                                value: tramite.nombre,
                            },
                            {
                                value: tramite.fechaCumplimiento
                                    ? 'Cumplido'
                                    : now.getTime() >
                                      new Date(
                                          tramite.fechaCompromiso
                                      ).getTime()
                                    ? 'Vencido'
                                    : 'Pendiente',
                            },
                            {
                                value: tramite.fechaCompromiso
                                    ? calcDate(
                                          new Date(tramite.fechaCompromiso),
                                          true
                                      )
                                    : 'N/A',
                            },
                            {
                                value: tramite.fechaCumplimiento
                                    ? calcDate(
                                          new Date(tramite.fechaCumplimiento),
                                          true
                                      )
                                    : 'N/A',
                            },
                        ],
                    })),
                }}
            />
        </OpenComponent>
    );
}
