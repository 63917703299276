import { CompoundedDashboard } from '../../../../@Types/Metabase';
import { useState } from 'react';
import styles from './SubTabs.module.css';

interface SubTabsProps {
    visualization: CompoundedDashboard;
    urlLinks: string[];
    setUrlLink: Function;
    setLoading: Function;
}

function SubTabs({
    visualization,
    setUrlLink,
    urlLinks,
    setLoading,
}: SubTabsProps): JSX.Element {
    const [selected, setSelected] = useState(0);

    return (
        <div className={styles.optionsNav}>
            {visualization.idsOrder.map((id, index) => {
                return (
                    <div
                        className={
                            index === selected
                                ? styles.selectedFormName
                                : styles.formName
                        }
                        key={index}
                        onClick={(): void => {
                            setLoading(true);
                            setUrlLink(urlLinks[index]);
                            setSelected(index);
                        }}
                    >
                        {visualization.ids[id]}
                    </div>
                );
            })}
        </div>
    );
}

export default SubTabs;
