import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    loadTokens,
    loadGuest,
} from './controllers/_SiteController/SiteActions';
import {
    Switch,
    Route,
    Redirect,
    withRouter,
    RouteComponentProps,
} from 'react-router-dom';
import Login from './pages/Login/LoginPage';
import RouterComponent from './Router';
import { RootState } from './utils/_store';
import { User } from './@Types/User';
import './shared/Toast/Toast.css';
import './styles/DraftEditor.css';
import {
    createGenerateClassName,
    StylesProvider,
    useMediaQuery,
} from '@material-ui/core';
import UpdateDialog from './shared/UpdateDialog/UpdateDialog';
import FloatingNotifications from './shared/FloatingNotifications/FloatingNotifications';
export const responsiveBreakpoint = 768;

const generateClassName = createGenerateClassName({
    seed: 'Erk',
    productionPrefix: 'eureka',
});

function App({ history }: RouteComponentProps): JSX.Element {
    const user = useSelector((state: RootState) => state.site.user);
    const dispatch = useDispatch();

    /**
     * Checks if local storage has tokens and dispatches action to tell the SiteSaga to handle them
     * If query string has tokens(only has them if user logged in from no subdomain) it handles them and also sends them to the saga
     */
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token') || localStorage.getItem('token');
        const refreshToken =
            urlParams.get('refreshToken') ||
            localStorage.getItem('refreshToken');
        if (urlParams.toString()) {
            urlParams.delete('token');
            urlParams.delete('refreshToken');
            const searchString = urlParams.toString();
            if (searchString) {
                history.replace({
                    search: `?${searchString}`,
                });
            }
        }

        if (token && refreshToken) {
            dispatch(loadTokens({ token, refreshToken }));
        } else {
            dispatch(loadGuest());
        }
    }, [dispatch]);

    const mobile = useMediaQuery(`(max-width:${responsiveBreakpoint}px)`, {
        noSsr: true,
    });
    return (
        <StylesProvider generateClassName={generateClassName}>
            <h1 className="wdn-text-hidden">Capta</h1>
            <FloatingNotifications />
            {user !== undefined && (
                <Switch>
                    <Route
                        path={[
                            '/login',
                            '/setPassword/:token',
                            '/forgotPassword',
                        ]}
                    >
                        <Login />
                    </Route>
                    <PrivateRoute path="*" user={user}>
                        <UpdateDialog user={user} />
                        <RouterComponent mobile={mobile} />
                    </PrivateRoute>
                </Switch>
            )}
        </StylesProvider>
    );
}

export default withRouter(App);

interface PrivateRouteProps {
    /** Children that should be rendered if user is logged in */
    children: React.ReactNode;
    /** Current Path */
    path: string;
    /** Current User */
    user: User | undefined | null;
}

function PrivateRoute({
    children,
    user,
    ...rest
}: PrivateRouteProps): JSX.Element {
    return (
        <Route
            {...rest}
            render={({ location }): JSX.Element | React.ReactNode =>
                user ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}
