import { Agent, Area, Flow } from '../../@Types/@Types';
import axiosInstance from '../../AxiosAPI';
import FlowNodeTypes from '../../constants/Flows/FlowNodeTypes';
import Types from '../../constants/Flows/FlowTypes';

/**
 * Function that loads a flow
 * @param idProject the id of the flow's project
 * @param idFlow the flow's id
 * @returns {flow, agents, areas}
 */
export const loadFlow = async (
    idProject: string,
    idFlow: string
): Promise<{
    /** The Flow */
    flow: Flow;
    /** The flows areas, undefined if it is of type ENTRY */
    areas?: Area[];
    /** The flows agents, undefined if it is of type ENTRY */
    agents?: Agent[];
}> => {
    let flow: Flow = (
        await axiosInstance.get(`/projects/${idProject}/flows/${idFlow}`)
    ).data;

    if (flow.type === Types.PROJECT) {
        /** Fetch & Filter only root Areas */
        let response = await axiosInstance.get(`/projects/${idProject}/areas`);
        const areas = response.data.filter((area: Area) => area.path === null);
        /** Fetch the projects Agents */
        const agents = (
            await axiosInstance.get(`/projects/${idProject}/agents`)
        ).data;
        agents.sort((a: any, b: any) => {
            return a.name.localeCompare(b.name);
        });
        areas.sort((a: any, b: any) => {
            return a.name.localeCompare(b.name);
        });

        for (const element of flow.visualElements) {
            if (
                element.type === FlowNodeTypes.AGENT &&
                Object.keys(element.data).length === 1
            ) {
                const agent = agents.find(
                    (agent: any) => agent._id === element.data._id
                );
                if (!agent) {
                    throw new Error();
                }
                element.data = agent;
            }
            if (
                element.type === FlowNodeTypes.AREA &&
                Object.keys(element.data).length === 1
            ) {
                const area = areas.find(
                    (agent: any) => agent._id === element.data._id
                );
                if (!area) {
                    throw new Error();
                }
                element.data = area;
            }
        }
        return {
            flow,
            agents: agents,
            areas,
        };
    } else if (flow.type === Types.AREA) {
        /** Fetch the Area's info */
        let response = await axiosInstance.get(
            `/projects/${idProject}/areas/${flow.idArea}`
        );
        for (const element of flow.visualElements) {
            if (
                element.type === FlowNodeTypes.AGENT &&
                Object.keys(element.data).length === 1
            ) {
                const agent = response.data?.agents.find(
                    (agent: any) => agent._id === element.data._id
                );
                if (!agent) {
                    // throw new Error();
                    // eslint-disable-next-line no-console
                    console.log('MISSING AGENT:', element.data._id);
                }
                element.data = agent ?? {};
            }
            if (
                element.type === FlowNodeTypes.AREA &&
                Object.keys(element.data).length === 1
            ) {
                const area = response.data?.subareas.find(
                    (agent: any) => agent._id === element.data._id
                );
                if (!area) {
                    throw new Error();
                }
                element.data = area;
            }
        }
        return {
            flow,
            agents: response.data?.agents,
            areas: response.data?.subareas,
        };
    } else if (flow.type === Types.ENTRY) {
        return {
            flow,
            agents: undefined,
            areas: undefined,
        };
    }
    return {
        flow,
        agents: [],
        areas: [],
    };
};

/**
 * Function that validates the flow's elements
 * @param idProject the id of the flow's project
 * @param idFlow the flow's id
 * @param elements the elements to validate
 * @returns
 */
export const validateElements = async (
    idProject: string,
    idFlow: string,
    elements: any
): Promise<string> => {
    let response = await axiosInstance.put(
        `/projects/${idProject}/flows/validate/${idFlow}`,
        elements,
        { timeout: 30000 }
    );
    return response.data?.id;
};

/**
 * Function that saves the idTempflow that has been validated
 * @param idProject the id of the flow's project
 * @param idFlow the flow's id
 * @param idTemp the tempId to save
 */
export const saveElements = async (
    idProject: string,
    idFlow: string,
    idTemp: string
): Promise<void> => {
    await axiosInstance.put(`/projects/${idProject}/flows/save/${idFlow}`, {
        idTemporal: idTemp,
    });
};

export default {
    loadFlow,
};
