/**
 * Transformation node transformation types
 */
enum TYPES {
    POSITIVE = 'POSITIVE',
    NEGATIVE = 'NEGATIVE',
    NEUTRAL = 'NEUTRAL',
    NONE = 'NONE',
}

export default TYPES;
