import styles from './TextAreaConfig.module.css';
import Toggle from '../../../../../shared/Toggle/Toggle';
import React from 'react';
import { TextArea } from '../../../../../@Types/SurveyTypes/SurveyStep';
import { UniqueSteps } from '../../../../../@Types/SurveyTypes/Survey';

interface TextAreaConfigProps {
    step?: TextArea;
    uniqueSteps?: UniqueSteps;
    updateUniqueSteps?: (unique: UniqueSteps) => void;
}
function TextAreaConfig({
    step,
    uniqueSteps,
    updateUniqueSteps,
}: TextAreaConfigProps): JSX.Element {
    if (!step || !uniqueSteps || !updateUniqueSteps) {
        return <div></div>;
    }
    return (
        <div className={styles.isContentContainer}>
            <div className={styles.isContentLabel + ' noselect'}>
                Comentario de la Encuesta:
            </div>
            <div className={styles.toggleContainer}>
                <Toggle
                    checked={step.id === uniqueSteps.commentStep}
                    onChange={(checked: boolean): void => {
                        updateUniqueSteps({
                            ...uniqueSteps,
                            commentStep: checked ? step.id : null,
                        });
                    }}
                />
            </div>
        </div>
    );
}
export default TextAreaConfig;
