import styles from './NoMobile.module.css';

/**
 * Component that displays the desktop message over a white screen
 */
function NoMobile(): JSX.Element {
    return (
        <div className="whiteCurtain" style={{ zIndex: 7 }}>
            <div className={styles.noMobileMsg}>
                Por favor entrar desde un computador.
            </div>
        </div>
    );
}
export default NoMobile;
