import { InmuebleInfo360 } from '../../../../types';
import styles from './Compradores.module.css';
import { OpenComponent } from '../../../Utils/Openable/OpenComponent';
import React from 'react';
import { empStrOrUndToNA } from '../../../Utils/otherFunctions';

interface CompradoresProps {
    compradores: InmuebleInfo360['datosGenerales']['datosCompradores'];
}
export function Compradores({ compradores }: CompradoresProps): JSX.Element {
    return (
        <OpenComponent title={'Compradores'}>
            <div className={styles.list}>
                {compradores.map((comprador, index) => (
                    <div className={styles.element} key={index}>
                        <div className={styles.sideBar} />
                        <div className={styles.info}>
                            <div className={styles.row}>
                                <div className={styles.label}>Nombre:</div>
                                <div className={styles.value}>
                                    {empStrOrUndToNA(comprador.nombre)}
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.label}>Teléfono</div>
                                <div className={styles.value}>
                                    {empStrOrUndToNA(comprador.telefono)}
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.label}>Celular</div>
                                <div className={styles.value}>
                                    {empStrOrUndToNA(comprador.celular)}
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.label}>Correo</div>
                                <div className={styles.value}>
                                    {empStrOrUndToNA(comprador.correo)}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </OpenComponent>
    );
}
