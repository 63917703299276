import { AnyAction } from '@reduxjs/toolkit';
import { takeLatest, call, put, fork, select } from 'redux-saga/effects';

import { RootState } from '../../utils/_store';
import * as actions from './AreasActions';
import { loadAreas, updateArea } from './AreasService';
import VanillaToast from '../../shared/Toast/Toast';

/** Selector that returns the current ipProject */
const getIdProject = (state: RootState): string | undefined | null =>
    state.site.idProject;

/**
 * Function called when the areas should be rendered(reset, refresh or search)
 * @param action of type Search, refresh or search
 */
function* getAreas(): any {
    try {
        const idProject = yield select(getIdProject);
        const pageInfo = yield select((state) => state.areasPage);
        let result = yield call(
            loadAreas,
            idProject,
            pageInfo.orderBy,
            pageInfo.search
        );
        yield put(actions.getSuccess(result));
    } catch (error) {
        //TODO handle errors
        console.error(error);
    }
}

/**
 * Function called when an areas's value is updated optimistically to patch the updates to the server asyncly
 * @param action of type UPDATE
 */
function* putArea(action: AnyAction): any {
    if (actions.updateElement.match(action)) {
        try {
            const idProject = yield select(getIdProject);
            const payload: Record<string, unknown> = {};
            payload[action.payload.field] = action.payload.value;
            yield call(
                updateArea,
                idProject,
                action.payload.object._id,
                payload
            );
        } catch (error) {
            VanillaToast.create({
                title: 'Error al actualizar la area',
                text: error,
                type: 'error',
                timeout: 5000,
            });
            yield put(actions.updateFailed(action.payload.object));
        }
    }
}

function* watchGetAreasRequest(): any {
    yield takeLatest(
        [actions.Types.RESET, actions.Types.REFRESH, actions.Types.SEARCH],
        getAreas
    );
}

function* watchUpdateArea(): any {
    yield takeLatest([actions.Types.UPDATE], putArea);
}

export default [fork(watchGetAreasRequest), fork(watchUpdateArea)];
