import { InmuebleInfo360 } from '../../../types';
import styles from './InfoAgrupacion.module.css';
import { CierreVenta } from './CierreVenta/CierreVenta';
import { Unidades } from './Unidades/Unidades';

interface InfoAgrupacionProps {
    infoAgrupacion: InmuebleInfo360['infoAgrupacion'];
    conceptosMap: InmuebleInfo360['cartera']['conceptosMap'];
}

export function InfoAgrupacion({
    infoAgrupacion,
    conceptosMap,
}: InfoAgrupacionProps): JSX.Element {
    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <CierreVenta
                    cierreVenta={infoAgrupacion.cierreVenta}
                    conceptosMap={conceptosMap}
                />
            </div>
            <div className={styles.section}>
                <Unidades unidades={infoAgrupacion.unidades} />
            </div>
        </div>
    );
}
