import styles from './TicketFilters.module.css';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { useRef, useState } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import FiltersMenu from './FiltersMenu';
import { TicketFilters } from '../../../controllers/TicketsController/TicketsReducer';
import TicketFilterTypes from '../../../constants/TicketFilterTypes';
interface TicketFiltersProps {
    handleFilter: (filters: TicketFilters) => void;
    mobile: boolean;
}
function TicketFiltersComponent({
    handleFilter,
    mobile,
}: TicketFiltersProps): JSX.Element {
    const btnRef = useRef<HTMLDivElement>(null);
    const [showMenu, setShowMenu] = useState(false);
    const pageInfo = useSelector((state: RootState) => state.ticketsPage);
    let nFilters =
        pageInfo.filters.classifiers.length +
        pageInfo.filters.companies.length +
        Object.values(pageInfo.filters.entities).reduce(
            (sum, entityValues) => sum + entityValues.length,
            0
        ) +
        pageInfo.filters.agents.length +
        pageInfo.filters.states.length +
        countOtherFilters(pageInfo.filters);

    return (
        <React.Fragment>
            {showMenu && (
                <FiltersMenu
                    anchorRef={btnRef}
                    filters={pageInfo.filters}
                    handleChange={(filters: TicketFilters): void => {
                        handleFilter(filters);
                    }}
                    handleClose={(): void => {
                        setShowMenu(false);
                    }}
                    layoutIsMobile={mobile}
                />
            )}
            <div className={styles.container}>
                <div
                    className={styles.button}
                    ref={btnRef}
                    onClick={(): void => {
                        setShowMenu(true);
                    }}
                >
                    <label className={styles.filtrosLbl}>Filtros</label>
                    <div className={styles.countContainer}>
                        {nFilters > 0 && (
                            <div className={styles.countLbl}>{nFilters}</div>
                        )}
                    </div>
                    <div className={styles.dropDownContainer}>
                        <div className={styles.dropDown}>
                            <ExpandMoreRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default TicketFiltersComponent;

export const countOtherFilters = (filters: TicketFilters): number => {
    let num = 0;
    if (filters.all) {
        num += 1;
    }
    if (filters.companyResponsible) {
        num += 1;
    }
    if (filters.type !== TicketFilterTypes.ALL) {
        num += 1;
    }
    if (filters.crtStartDate) {
        num += 1;
    }
    if (filters.crtEndDate) {
        num += 1;
    }
    if (filters.clsStartDate) {
        num += 1;
    }
    if (filters.clsEndDate) {
        num += 1;
    }
    return num;
};
