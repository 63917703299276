import { AnyAction } from '@reduxjs/toolkit';
import { takeLatest, call, put, fork, select } from 'redux-saga/effects';
import * as actions from './AgentsActions';
import { loadAgents, loadAgentsByQuery, updateAgent } from './AgentsService';
import VanillaToast from '../../shared/Toast/Toast';
import { RootState } from '../../utils/_store';
import { AgentsPageState, agentsToQueryString } from './AgentsReducer';
import ScrollTypes from '../../constants/ScrollTypes';

/** Number of elements per Page */
const PAGE_SIZE = 20;

function* resetAgents(action: AnyAction): any {
    if (actions.reset.match(action)) {
        if (action.payload.idAgent || action.payload.queryString.length > 0) {
            try {
                const result = yield call(
                    loadAgentsByQuery,
                    action.payload.queryString,
                    action.payload.idAgent
                );
                const changes = { id: false, query: false };
                result.lastPage = result.elements.length < PAGE_SIZE;
                if (
                    action.payload.idAgent &&
                    result.selectedElement === undefined
                ) {
                    changes.id = true;
                    VanillaToast.create({
                        title: 'No se encontró el agente',
                        text: `Agente ${action.payload.idAgent} no encontrado`,
                        type: 'error',
                        timeout: 5000,
                    });
                }
                if (
                    action.payload.queryString.length > 0 &&
                    Object.keys(result.filters).length == 0 &&
                    !result.search
                ) {
                    changes.query = true;
                }
                if (changes.id || changes.query) {
                    const newQuery = agentsToQueryString(
                        result.filters,
                        result.orderBy,
                        result.search,
                        true
                    );
                    let basePath = '/agents';
                    if (result.selectedElement !== undefined) {
                        basePath +=
                            '/' + result.elements[result.selectedElement]._id;
                    }
                    action.payload.history.replace({
                        pathname: basePath,
                        search: newQuery,
                        state: {
                            prevPath: action.payload.history.location.pathname,
                        },
                    });
                }
                yield put(actions.resetSuccess(result));
            } catch (error) {
                console.error(error);
            }
        } else {
            yield getAgents(action);
        }
    }
}

/**
 * Function called when new agents should be rendered (reset,search, or newpage)
 * @param action of type Search, reset or scroll
 */
function* getAgents(action: AnyAction): any {
    try {
        const pageInfo = yield select((state) => state.agentsPage);
        let nPage = 1;
        if (actions.pageScroll.match(action)) {
            nPage =
                action.payload === ScrollTypes.DOWN
                    ? pageInfo.pageDown
                    : pageInfo.pageUp;
        }
        let result = yield call(
            loadAgents,
            nPage,
            PAGE_SIZE,
            pageInfo.filters,
            pageInfo.orderBy,
            pageInfo.search
        );
        const addedLength = result.length;
        /** Calcs if more elements are available */
        const lastPage =
            (pageInfo.pageDown !== 1 && result.length === 0) ||
            (pageInfo.pageDown === 1 && result.length < PAGE_SIZE);
        let selectedElement = pageInfo.selectedElement;
        if (actions.pageScroll.match(action)) {
            if (action.payload === ScrollTypes.DOWN) {
                result = [...pageInfo.elements].concat(result);
            } else {
                if (selectedElement !== undefined) {
                    selectedElement += result.length;
                }
                result = result.concat(...pageInfo.elements);
            }
        }
        let list;
        if (
            actions.pageScroll.match(action) &&
            action.payload === ScrollTypes.UP
        ) {
            list = document.getElementById('agents-infiniteList');
        }
        yield put(
            actions.getSuccess({ selectedElement, elements: result, lastPage })
        );
        if (list) list.scrollTop = addedLength * 115;
    } catch (error) {
        //TODO handle errors
    }
}

/**
 * Function called when an agent's value is updated optimistically to patch the updates to the server asyncly
 * @param action of type UPDATE
 */
function* putAgent(action: AnyAction): any {
    if (actions.updateElement.match(action)) {
        try {
            const payload: Record<string, unknown> = {};
            payload[action.payload.field] = action.payload.value;
            yield call(updateAgent, action.payload.object._id, payload);
        } catch (error) {
            VanillaToast.create({
                title: 'Error al actualizar el agente',
                text: error,
                type: 'error',
                timeout: 5000,
            });
            yield put(actions.updateFailed(action.payload.object));
        }
    }
}

function* goTo(action: AnyAction): any {
    try {
        if (actions.goTo.match(action)) {
            const idAgent = action.payload.idAgent;
            const pageInfo: AgentsPageState = yield select(
                (state: RootState) => state.agentsPage
            );
            const selectedElement =
                pageInfo.elements[pageInfo.selectedElement ?? -1];
            const queryChange =
                action.payload.queryString !==
                agentsToQueryString(
                    pageInfo.filters,
                    pageInfo.orderBy,
                    pageInfo.search,
                    true
                );
            if (queryChange) {
                yield put(
                    actions.reset({
                        history: action.payload.history,
                        queryString: action.payload.queryString,
                        idAgent,
                    })
                );
            } else if (idAgent !== selectedElement?._id) {
                /** If the currently selected Element doesn't have the id in the url  */
                /** If user wants to unselect (no id in url) */
                if (idAgent === undefined) {
                    yield put(actions.selectElement(undefined));
                } else {
                    /** Check if element is in the current list. */
                    const index = pageInfo.elements.findIndex(
                        (listElement) => listElement._id === idAgent
                    );
                    /** IF element is in current list select it. */
                    if (index >= 0) {
                        yield put(actions.selectElement(index));
                    } else {
                        /** Reload page as if new one. */
                        yield put(
                            actions.reset({
                                history: action.payload.history,
                                queryString: action.payload.queryString,
                                idAgent,
                            })
                        );
                    }
                }
            }
        }
    } catch (error) {
        //TODO handle errors
        console.error(error);
    }
}

function* watchGoTo(): any {
    yield takeLatest([actions.Types.GO_TO], goTo);
}

function* watchResetAgents(): any {
    yield takeLatest([actions.Types.RESET], resetAgents);
}

function* watchGetAgentsRequest(): any {
    yield takeLatest(
        [
            actions.Types.REFRESH,
            actions.Types.PAGE_SCROLL,
            actions.Types.SEARCH,
        ],
        getAgents
    );
}

function* watchUpdateAgent(): any {
    yield takeLatest([actions.Types.UPDATE], putAgent);
}

export default [
    fork(watchResetAgents),
    fork(watchGetAgentsRequest),
    fork(watchUpdateAgent),
    fork(watchGoTo),
];
