export enum DraftEntityDataTypes {
    AGENT = 'AGENT', //Arriba
    DATE = 'DATE', //Utils
    NESTED = 'NESTED', // Toca renderizar un subdraft
    AGENTS = 'AGENTS', //
    CLIENT = 'CLIENT', //
    CLIENT_INTEGRATION = 'CLIENT_INTEGRATION',
    CLIENT_RELATIONSHIP = 'CLIENT_RELATIONSHIP',
    TICKET = 'TICKET', //
    COMPANY = 'COMPANY', //
    PROJECT = 'PROJECT', // proyecto
    CONCEPT = 'CONCEPT', // proyecto
    CONDITION_MET = 'CONDITION_MET', //Utils
    ACTION = 'ACTION', //Arriba
    ACTIVITY = 'ACTIVITY', //Arriba
    PROCESS = 'PROCESS', //
    CLASSIFIER = 'CLASSIFIER',
    TICKET_VALUE = 'TICKET_VALUE', // proyecto
    TICKET_LINK = 'TICKET_LINK', // Utils
    ORGANIZATION = 'ORGANIZATION', // TODO
    ENTITYVALUES = 'ENTITYVALUES', // proyecto
    NOTIFICATION = 'NOTIFICATION',
    FORM_STEP = 'FORM_STEP',
    CONVERSATION_STEP = 'CONVERSATION_STEP',
    INTEGRATION = 'INTEGRATION',
}

export enum DraftEntityDataMappingTypes {
    ENTITYVALUE_MAPPING = 'ENTITYVALUE_MAPPING',
    AGENT_MAPPING = 'AGENT_MAPPING',
    CONCEPT_MAPPING = 'CONCEPT_MAPPING',
    ENTITYVALUE_INTEGRATION_MAPPING = 'ENTITYVALUE_INTEGRATION_MAPPING',
}
