import React from 'react';
import styles from './RelationshipDialog.module.css';
import Relationship from '../../@Types/Relationship';
import { EntityValue } from '../../@Types/@Types';
import { useSelector } from 'react-redux';
import { RootState } from '../../utils/_store';
import { AppProps } from '@arquimedes.co/eureka-forms/dist/App/App';
import FormRenderer from '../FormRenderer/FormRenderer';
interface RelationshipDialogProps {
    idEntity: string;
    customSubmit: AppProps['customSubmit'];
    /**Ticket client */
    relationship: Relationship;
    entityValue?: EntityValue | Relationship;
    relatedEntityValue: EntityValue;
}
/**
 * Component rendered inside the create area dialog.
 */
function RelationshipDialog({
    idEntity,
    customSubmit,
    relationship,
    entityValue,
    relatedEntityValue,
}: RelationshipDialogProps): JSX.Element {
    const siteInfo = useSelector((state: RootState) => state.site);
    const entityValueEntity = siteInfo.entities[idEntity];
    const relatedEntityValueEntity =
        siteInfo.entities[relatedEntityValue.idEntity];
    const entityRelationship = entityValueEntity.relationships.find(
        (relationship) => relationship.idEntity === relatedEntityValue.idEntity
    );
    if (!entityValueEntity || !relatedEntityValue || !entityRelationship)
        return <div></div>;
    return (
        <div className={styles.container + ' standard-dialog'}>
            <div className={styles.title}>
                {
                    relatedEntityValue.values[
                        relatedEntityValueEntity.idLabelStep
                    ]
                }{' '}
                de{' '}
                {entityValue?.values[entityValueEntity.idLabelStep] ??
                    entityValueEntity.name}
            </div>
            <FormRenderer
                editable={true}
                values={relationship.values}
                form={{
                    steps: entityRelationship.steps,
                    size: {
                        blockNum: 3,
                        blockSize: 210,
                        spacingSize: 20,
                    },
                }}
                rootSteps={entityRelationship.rootSteps}
                sendLabel="Guardar"
                customSubmit={customSubmit}
            />
        </div>
    );
}
export default RelationshipDialog;
