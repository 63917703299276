import produce from 'immer';
import { Agent } from '../../@Types/Agent';
import { Client } from '../../@Types/Client';
import { Company } from '../../@Types/Company';
import { Condition } from '../../@Types/ConditionTypes/Condition';
import { Entry } from '../../@Types/Entry';
import ConditionOperators from '../../constants/Conditions/ConditionOperators';
import ConditionTypes from '../../constants/Conditions/ConditionTypes';
import EntityPropertyOperators from '../../constants/Conditions/EntityPropertyOperators';
import EntityPropertyTypes from '../../constants/EntityPropertyTypes';
import ConversationStepTypes from '../../constants/Conversations/ConversationStepTypes';
import FormStepTypes from '../../constants/FormStepTypes';

export const mapCondition = <Type extends Condition>(
    condition: Type | undefined
): Type | undefined => {
    if (!condition || !condition.type || condition.type === ('DEFAULT' as any))
        return undefined;
    const mappedCondition = produce(condition, (condition): any => {
        switch (condition.type) {
            case ConditionTypes.EXPRESSION: {
                const conditions = [];
                for (const subCondition of condition.conditions) {
                    const mappedCondition = mapCondition(subCondition);
                    if (mappedCondition) conditions.push(mappedCondition);
                }
                if (conditions.length === 0) return undefined;
                else if (conditions.length === 1) return conditions[0];
                condition.conditions = conditions;
                break;
            }
            case ConditionTypes.COMPANY: {
                if (
                    condition.operator === ConditionOperators.EQUAL ||
                    condition.operator === ConditionOperators.NOTEQUAL
                ) {
                    condition.values = condition.values.map(
                        (value) => (value as Company)._id ?? value
                    ) as any;
                } else if (
                    (condition.operator === ConditionOperators.INCLUDES ||
                        condition.operator ===
                            ConditionOperators.NOTINCLUDES) &&
                    (condition.propertyOperator ===
                        EntityPropertyOperators.EQUAL ||
                        condition.propertyOperator ===
                            EntityPropertyOperators.NOTEQUAL) &&
                    condition.propertyType === EntityPropertyTypes.AGENTPICKER
                ) {
                    //Map agents to ids in EntityAgentsCondition
                    condition.propertyValues = condition.propertyValues.map(
                        (value) => (value as Agent)._id ?? value
                    ) as any;
                }
                break;
            }
            case ConditionTypes.CLIENT: {
                if (
                    condition.operator === ConditionOperators.EQUAL ||
                    condition.operator === ConditionOperators.NOTEQUAL
                ) {
                    condition.values = condition.values.map(
                        (value) => (value as Client)._id ?? value
                    ) as any;
                }
                break;
            }
            case ConditionTypes.ENTITYVALUE: {
                if (
                    condition.operator === ConditionOperators.EQUAL ||
                    condition.operator === ConditionOperators.NOTEQUAL
                ) {
                    condition.values = condition.values.map(
                        (value) => (value as Company)._id ?? value
                    ) as any;
                } else if (
                    (condition.operator === ConditionOperators.INCLUDES ||
                        condition.operator ===
                            ConditionOperators.NOTINCLUDES) &&
                    (condition.propertyOperator ===
                        EntityPropertyOperators.EQUAL ||
                        condition.propertyOperator ===
                            EntityPropertyOperators.NOTEQUAL) &&
                    condition.propertyType === EntityPropertyTypes.AGENTPICKER
                ) {
                    //Map agents to ids in EntityAgentsCondition
                    condition.propertyValues = condition.propertyValues.map(
                        (value) => (value as Agent)._id ?? value
                    ) as any;
                }
                break;
            }
            case ConditionTypes.ENTRY: {
                if (
                    condition.operator === ConditionOperators.EQUAL ||
                    condition.operator === ConditionOperators.NOTEQUAL
                ) {
                    condition.values = condition.values.map(
                        (value) => (value as Entry).apiKey ?? value
                    ) as any;
                }
                break;
            }
            case ConditionTypes.CONVERSATION_STEP: {
                switch (condition.stepType) {
                    case ConversationStepTypes.ENTITY_VALUE_STEP: {
                        if (
                            condition.operator === ConditionOperators.EQUAL ||
                            condition.operator === ConditionOperators.NOTEQUAL
                        ) {
                            condition.values = condition.values.map(
                                (value) => (value as any)._id ?? value
                            );
                        }
                        break;
                    }
                }
                break;
            }
            case ConditionTypes.FORM_STEP: {
                switch (condition.stepType) {
                    case FormStepTypes.AGENTPICKER:
                    case FormStepTypes.ENTITYVALUEPICKER:
                        if (
                            condition.operator === ConditionOperators.EQUAL ||
                            condition.operator === ConditionOperators.NOTEQUAL
                        ) {
                            condition.values = condition.values.map(
                                (value) => (value as any)._id ?? value
                            );
                        }
                        break;
                }
                break;
            }
            default:
                break;
        }
    });
    return mappedCondition;
};
