import styles from './SizeEditor.module.css';
import RoundedTextField from '../../../RoundedTextField/RoundedTextField';
import { updateSize } from '../../../../controllers/GenericEditorController/GenericEditorActions';
import { FormSize } from '../../../../@Types/GenericForm';
import {
    useGenericEditorDispatch,
    useGenericEditorSelector,
} from '../../GenericFormEditorHooks';

function SizeEditor(): JSX.Element {
    const dispatch = useGenericEditorDispatch();
    const editorInfo = useGenericEditorSelector((state) => state);
    return (
        <div className={styles.settingsContainer}>
            <div className={styles.designLabel + ' noselect'}>Tamaño:</div>
            <div className={styles.settingsInputContainer}>
                <RoundedTextField
                    type="number"
                    label="BlockNum"
                    value={editorInfo.size.blockNum}
                    onChange={(e): void => {
                        const value = parseInt(e.target.value);
                        if (0 < value && value <= 4) {
                            dispatch(
                                updateSize({
                                    ...editorInfo.size,
                                    blockNum: value as FormSize['blockNum'],
                                })
                            );
                        }
                    }}
                />
            </div>
            <div className={styles.settingsInputContainer}>
                <RoundedTextField
                    type="number"
                    label="Block Size"
                    value={editorInfo.size.blockSize}
                    onChange={(e): void => {
                        const value = parseInt(e.target.value);
                        dispatch(
                            updateSize({
                                ...editorInfo.size,
                                blockSize: value,
                            })
                        );
                    }}
                />
            </div>
            <div className={styles.settingsInputContainer}>
                <RoundedTextField
                    type="number"
                    label="Spacing Size"
                    value={editorInfo.size.spacingSize}
                    onChange={(e): void => {
                        const value = parseInt(e.target.value);
                        dispatch(
                            updateSize({
                                ...editorInfo.size,
                                spacingSize: value,
                            })
                        );
                    }}
                />
            </div>
        </div>
    );
}
export default SizeEditor;
