import { useMemo } from 'react';
import { Organization } from '../@Types/Organization';
import { Ticket } from '../@Types/TicketTypes/Ticket';
import { User } from '../@Types/User';
import {
    EntityPermissions,
    OrganizationPermissions,
    ProjectPermissions,
} from '../constants/Permissions';
import StateTypes from '../constants/StateTypes';
import { useAppSelector } from '../hooks';

/**
 *  Function that checks if the current user has a specific permission in the organization
 *  @param permission to check if the user has
 *  @returns if user has permission or not
 */
export function checkOrgPerm(
    user: User | undefined | null,
    organization: Organization | undefined,
    /** The OrganizationPermission to check */
    permission: OrganizationPermissions
): boolean {
    if (!user || !organization || !permission) return false;
    if (permission === OrganizationPermissions.COMPANIES) {
        if (!organization.idCompanyEntity) {
            return false;
        }
    }
    return user.permissions?.organization?.[permission] === true;
}

export function checkOrgPerms(
    user: User | undefined | null,
    organization: Organization | undefined,
    /** The OrganizationPermission to check */
    permissions: OrganizationPermissions[] | null = null
): boolean {
    if (!user || !organization) return false;

    if (!permissions) {
        return !!Object.values(OrganizationPermissions).find((permission) =>
            checkOrgPerm(user, organization, permission)
        );
    }

    return (
        permissions.find((permission) =>
            checkOrgPerm(user, organization, permission)
        ) !== undefined
    );
}

/**
 *  Function that checks if the current user has a specific permission in an entity
 *  @param siteInfo
 *  @param idEntity entity to check
 *  @param permission to check if the user has
 *  @returns if user has permission or not in the specified entity
 */
export function checkEntityPerm(
    user: User | undefined | null,
    idEntity: string,
    /** The EntityPermissions to check */
    permission: EntityPermissions
): boolean {
    if (!user || !idEntity || !permission) return false;
    return user.permissions?.entities?.[idEntity]?.[permission] === true;
}

export function checkEntityPerms(
    user: User | undefined | null,
    idEntity: string,
    /** The EntityPermissions to check */
    permissions: EntityPermissions[]
): boolean {
    if (!user || !idEntity) return false;
    return (
        permissions.find((permission) =>
            checkEntityPerm(user, idEntity, permission)
        ) !== undefined
    );
}

/**
 *  Function that checks if the current user has a specific permission in the current project
 *  @param permission to check if the user has
 *  @returns if user has permission or not in the current project
 */
export function checkProjPerm(
    user: User | undefined | null,
    idProject: string | undefined | null,
    /** The ProjectPermission to check */
    permission: ProjectPermissions
): boolean {
    if (!user || !idProject || !permission) return false;
    return user.permissions?.projects?.[idProject]?.[permission] === true;
}

/**
 *  Function that checks if the current user has a capta admin privilages
 *  @returns if user has admin privilages or not
 */
export function checkAdmin(user: User | undefined | null): boolean {
    return (
        !!user?.email &&
        [
            'andres@capta.co',
            'juandiego@capta.co',
            'mateo.aguirre@capta.co',
        ].includes(user.email)
    );
}

export function checkProjPerms(
    user: User | undefined | null,
    idProject: string,
    /** The ProjectPermission to check */
    permissions: ProjectPermissions[]
): boolean {
    if (!user) return false;
    return (
        permissions.find((permission) =>
            checkProjPerm(user, idProject, permission)
        ) !== undefined
    );
}

/**
 *  hook that checks if the current user can execute a specific permission
 *  returns true if has a specific permission and is assigned to the ticket or has the manage permission
 *  @param ticket the ticket to check
 *  @param permission to check if the user has
 *  @returns if user has permission or not
 */
export function useTicketPermission(
    ticket: Ticket | undefined,
    /** The ProjectPermission to check */
    permission: ProjectPermissions | null = null
): boolean {
    const user = useAppSelector((state) => state.site.user);
    if (!ticket || !user) return false;

    return useMemo(() => {
        const isClosed = ticket.state?.type === StateTypes.CLOSED;
        const isAssigned =
            ticket.agents.find((agent) => agent._id === user._id) !== undefined;

        const permissions = user.permissions.projects[ticket.idProject];
        if (!permissions) return false;

        const canManage = !!permissions[ProjectPermissions.MANAGE_NOT_ASSIGNED];

        if (isClosed && !permissions[ProjectPermissions.EDIT_CLOSED]) {
            return false;
        }
        if (permission === null) {
            return isAssigned || canManage;
        }
        return !!permissions[permission] && (canManage || isAssigned);
    }, [ticket.idProject, ticket.agents, user.permissions, permission]);
}
