import { IconProps } from './@IconTypes';

function FlowIcon({ className, style, fill }: IconProps): JSX.Element {
    return (
        <svg
            fill={fill}
            style={style}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            height="404pt"
            viewBox="-3 0 404 404"
            width="404pt"
        >
            <path d="m387.816406 0h-87.269531c-5.523437 0-10 4.476562-10 10v38h-42.910156c-22.082031.023438-39.976563 17.917969-40 40v63.453125h-30.546875c-5.523438 0-10 4.480469-10 10v30.546875h-59.816406v-38c0-5.523438-4.476563-10-10-10h-87.273438c-5.523438 0-10 4.476562-10 10v96c0 5.523438 4.476562 10 10 10h87.273438c5.523437 0 10-4.476562 10-10v-38h59.816406v30.546875c0 5.519531 4.476562 10 10 10h30.546875v63.453125c.023437 22.082031 17.917969 39.976562 40 40h42.910156v38c0 5.523438 4.476563 10 10 10h87.269531c5.523438 0 10-4.476562 10-10v-96c0-5.523438-4.476562-10-10-10h-87.269531c-5.523437 0-10 4.476562-10 10v38h-42.910156c-11.042969-.011719-19.988281-8.960938-20-20v-63.453125h26.179687c5.523438 0 10-4.480469 10-10v-81.09375c0-5.523437-4.476562-10-10-10h-26.179687v-63.453125c.011719-11.039062 8.957031-19.988281 20-20h42.910156v38c0 5.523438 4.476563 10 10 10h87.269531c5.523438 0 10-4.476562 10-10v-96c0-5.523438-4.476562-10-10-10zm-300.542968 240h-67.273438v-76h67.273438zm223.273437 68h67.269531v76h-67.269531zm-66.730469-75.453125h-56.726562v-61.09375h56.726562zm134-136.546875h-67.269531v-76h67.269531zm0 0" />
        </svg>
    );
}
export default FlowIcon;
