import List from '../../MenuList/MenuList';
import SelectedElementComponent from './SelectedElement/SelectedElement';
import ElementComponent from './Element/Element';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import {
    selectElements,
    pageScroll,
    reset,
    clear,
} from '../../../controllers/GenericMenuController/GenericMenuActions';
import update from 'immutability-helper';
import React, { useCallback, useEffect, useMemo } from 'react';
import styles from './GenericMenu.module.css';
import { defaultState } from '../../../controllers/GenericMenuController/GenericMenuReducer';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableItem from '../../Tree/DraggableItem/DraggableItem';
interface GenericListProps<Type, InitialType> {
    idMenu: string;
    /** The label of the current generic type */
    label: string;
    /** if the list should trigger the load */
    load?: boolean;
    /** Function used to get the element's unique key */
    calcId?: (element: Type) => string;
    /** Function to calc element label */
    calcLbl: (element: Type) => string;
    /** Function to calc if element was deleted */
    calcIsDeleted?: (element: Type) => boolean;
    /** Function to calc element Icon  */
    calcIcon?: (element: Type) => JSX.Element;
    canChangeOrder?: boolean;
    listRef: any;
    handleUpdate?: (elements: Type[], noMoreElements: boolean) => void;
    multiple: boolean;
    /** The initially selected elements */
    initialElements: InitialType[] | Type[];
    /** The elements to filter out */
    elementsToOmit: Type[];
    /** Function to calc if should load by initial values */
    isInitialType?: (
        elements: InitialType[] | Type[]
    ) => elements is InitialType[];
    /** async function to call to load elements by their initial values */
    loadInitialType: (value: InitialType[]) => Promise<Type[]>;
    /** async function to call to load a list of elements */
    loadElements: (
        page: number,
        pageSize: number,
        search: string | undefined
    ) => Promise<Type[]>;
    /** Function called to view the selected element */
    handleView?: (element: Type) => void;
    /** Function called to config the selected element */
    handleConfig?: (element: Type) => void;
    /** Dependencies to reset on */
    dependencies?: any[];
}

function GenericList<Type, InitialType>({
    idMenu,
    loadElements,
    loadInitialType,
    isInitialType = (
        elements: InitialType[] | Type[]
    ): elements is InitialType[] => typeof elements[0] === 'string',
    calcLbl,
    calcIcon,
    listRef,
    label,
    load = true,
    handleUpdate,
    initialElements = [],
    elementsToOmit,
    multiple,
    calcId = (element: any): string => element._id,
    handleView,
    dependencies,
    handleConfig,
    calcIsDeleted,
    canChangeOrder = false,
}: GenericListProps<Type, InitialType>): JSX.Element {
    const dispatch = useDispatch();
    const menuInfo = useSelector(
        (state: RootState) => state.genericMenu[idMenu] ?? defaultState
    );
    useEffect(() => {
        if (!load) return;
        dispatch(
            reset(idMenu, {
                selected: initialElements,
                elementsToOmit,
                config: {
                    loadInitialType,
                    loadElements,
                    calcId,
                    isInitialType,
                },
            })
        );
        if (listRef?.current?.scrollTop) {
            listRef.current.scrollTop = 0;
        }
    }, dependencies ?? []);

    useEffect(() => {
        if (!load) return;
        return (): void => {
            dispatch(clear(idMenu));
        };
    }, []);
    const { selected, others } = useMemo(() => {
        if (menuInfo.search) {
            const selected = [];
            const others = [];
            for (const id of menuInfo.order) {
                if (menuInfo.selected.includes(id)) {
                    selected.push(id);
                } else {
                    others.push(id);
                }
            }
            return { selected, others };
        }
        return {
            selected: menuInfo.selected,
            others: menuInfo.order.filter(
                (id) => !menuInfo.selected.includes(id)
            ),
        };
    }, [menuInfo.selected, menuInfo.order]);

    const moveItem = useCallback(
        (dragIndex, hoverIndex) => {
            const dragItem = menuInfo.selected[dragIndex];
            const newOrder = update(menuInfo.selected, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragItem],
                ],
            });
            dispatch(selectElements(idMenu, newOrder));
            handleUpdate?.(
                newOrder.map((id) => menuInfo.elements[id]),
                menuInfo.noMoreElements
            );
        },
        [menuInfo.selected]
    );

    const change = (element: Type, select: boolean): void => {
        if (multiple) {
            const elements = menuInfo.selected.filter(
                (e) => e !== calcId(element)
            );
            if (select && menuInfo.selected.length === elements.length) {
                elements.push(calcId(element));
            }
            dispatch(selectElements(idMenu, elements));
            handleUpdate?.(
                elements.map((id) => menuInfo.elements[id]),
                menuInfo.noMoreElements &&
                    elements.length === selected.length + others.length
            );
        } else {
            dispatch(selectElements(idMenu, select ? [calcId(element)] : []));
            handleUpdate?.(
                select ? [element] : [],
                menuInfo.noMoreElements && menuInfo.order.length === 1
            );
        }
    };

    const renderList = (): JSX.Element => {
        return (
            <List
                noMoreElements={menuInfo.noMoreElements}
                listRef={listRef}
                onPageScroll={(): void => {
                    dispatch(pageScroll(idMenu));
                }}
                loading={menuInfo.loading}
                elements={[...selected, ...others]}
            >
                {multiple &&
                    selected.map((id, index) => {
                        const element = menuInfo.elements[id];
                        if (!element) return <></>;

                        if (canChangeOrder && selected.length > 1) {
                            return (
                                <DraggableItem
                                    key={element.idEntityValue}
                                    index={index}
                                    id={element.idEntityValue}
                                    moveItem={moveItem}
                                >
                                    <SelectedElementComponent<Type>
                                        draggable
                                        calcLbl={calcLbl}
                                        calcIcon={calcIcon}
                                        calcIsDeleted={calcIsDeleted}
                                        handleView={handleView}
                                        handleConfig={handleConfig}
                                        element={element}
                                        key={id}
                                        handleRemove={(): void => {
                                            change(element, false);
                                        }}
                                        first={index === 0}
                                    />
                                </DraggableItem>
                            );
                        }

                        return (
                            <SelectedElementComponent<Type>
                                calcLbl={calcLbl}
                                calcIcon={calcIcon}
                                calcIsDeleted={calcIsDeleted}
                                handleView={handleView}
                                handleConfig={handleConfig}
                                element={element}
                                key={id}
                                handleRemove={(): void => {
                                    change(element, false);
                                }}
                                first={index === 0}
                            />
                        );
                    })}
                {(multiple ? others : menuInfo.order).map((id, index) => {
                    const element = menuInfo.elements[id];
                    if (!element) return <></>;
                    const isSelected = !multiple && selected.includes(id);
                    return (
                        <ElementComponent<Type>
                            calcLbl={calcLbl}
                            calcId={calcId}
                            calcIcon={calcIcon}
                            calcIsDeleted={calcIsDeleted}
                            first={
                                multiple
                                    ? selected.length === 0 && index === 0
                                    : index === 0
                            }
                            element={element}
                            handleView={
                                handleView
                                    ? (): void => {
                                          handleView(element);
                                      }
                                    : undefined
                            }
                            handleConfig={
                                handleConfig
                                    ? (): void => {
                                          handleConfig(element);
                                      }
                                    : undefined
                            }
                            multiple={multiple}
                            selected={isSelected}
                            key={id}
                            handleClick={(): void => {
                                change(element, !isSelected);
                                if (!isSelected) handleConfig?.(element);
                            }}
                        />
                    );
                })}
                {((multiple && selected.length === 0 && others.length === 0) ||
                    (!multiple && menuInfo.order.length === 0)) && (
                    <div className={styles.noElementsContainer}>
                        No hay {label}
                    </div>
                )}
            </List>
        );
    };

    if (multiple && canChangeOrder && selected.length > 1) {
        return <DndProvider backend={HTML5Backend}>{renderList()}</DndProvider>;
    } else return renderList();
}

export default GenericList;
