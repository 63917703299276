import { Ticket } from '../../../../@Types/@Types';
import EntryTypes from '../../../../constants/EntryTypes';
import ActivityTicketEntry from './ActivityTicketEntry/ActivityTicketEntry';
import EmailTicketEntry from './EmailTicketEntry/EmailTicketEntry';
import FormTicketEntry from './FormTicketEntry/FormTicketEntry';
import WhatsappTicketEntry from './WhatsappTicketEntry/WhatsappTicketEntry';

interface TicketEntryProps {
    /** Function inherited from the dialog to activate the loader */
    setLoading?: Function;
    ticket: Ticket;
}

function TicketEntry({ ticket }: TicketEntryProps): JSX.Element {
    switch (ticket.entry.type) {
        case EntryTypes.MIGRATION:
        case EntryTypes.FORM:
            return (
                <FormTicketEntry
                    entry={ticket.entry}
                    values={ticket.formValues ?? {}}
                />
            );
        case EntryTypes.WHATSAPP:
            return (
                <WhatsappTicketEntry
                    entry={ticket.entry}
                    values={ticket.formValues ?? {}}
                />
            );
        case EntryTypes.EMAIL:
            return <EmailTicketEntry ticket={ticket} />;
        case EntryTypes.ACTIVITY:
            return <ActivityTicketEntry ticket={ticket} />;
        default:
            break;
    }
    return <div></div>;
}
export default TicketEntry;
