import { useSelector } from 'react-redux';
import {
    DraftEntityDataMappingTypes,
    DraftEntityDataTypes,
} from '../../../../constants/Draft/DraftEntityDataTypes';
import Context from '../Context';
import { RootState } from '../../../../utils/_store';
import EntityPropertyTypes from '../../../../constants/EntityPropertyTypes';
import EntityIcon from '../../../SmartIcons/EntityIcon';
import { DraftEntityTypes } from '../../../../constants/Draft/DraftEntityTypes';
import {
    ClientRelationshipDraftEntityData,
    EntityValuesDraftEntityData,
} from '../../../../@Types/Draft/DraftEntityData';
import { DraftStyleTypes } from '../../../../constants/Draft/DraftStyleTypes';
import { IconProps } from '@material-ui/core';
import { GenericContextProps } from '../TextEditorContext';
import { DraftContext } from '../../../../@Types/Draft/DraftContext';
import { useMemo } from 'react';
import EurekaElementMenuGroup from '../../../@Menus/ElementMenu/EurekaElementMenuGroup';
import { useEntity, useIdProject } from '../../../../hooks';
import { IntegrationsApi } from '../../../../controllers/IntegratrionsController/IntegrationsService';
import IntegrationTypes from '../../../../constants/IntegrationTypes';
import { DraftIntegration } from '../../../../@Types/Integration';
import EntityValuesContext from './EntityValuesContext';

interface EntityContextProps extends GenericContextProps {
    idEntity: string;
    path?: string[];
    isMapping?: boolean;
    type:
        | DraftEntityDataTypes.CLIENT
        | DraftEntityDataTypes.COMPANY
        | DraftEntityDataTypes.ENTITYVALUES
        | DraftEntityDataTypes.CLIENT_RELATIONSHIP;
    context: DraftContext | undefined;
    icon?: (props: IconProps) => JSX.Element;
}

function EntityContext({
    type,
    path,
    context,
    idEntity,
    isMapping,
    ...props
}: EntityContextProps): JSX.Element {
    const idProject = useIdProject();
    const entity = useEntity(idEntity);
    if (!entity) return <></>;

    const properties = useMemo(() => {
        if (!context?.isPublic) return Object.values(entity.steps);
        return Object.values(entity.steps).filter(
            (property) =>
                property.id === entity.idLabelStep || (property as any).public
        );
    }, [entity.steps, context?.isPublic]);

    const { data } = IntegrationsApi.useLoadIntegrationsQuery({
        idProject,
        idEntity,
        type: IntegrationTypes.DRAFT,
    });
    const integrations = data as DraftIntegration[];

    return (
        <>
            {properties.map((property, index) => {
                if (
                    property.type === EntityPropertyTypes.TITLE ||
                    property.type === EntityPropertyTypes.SEPARATOR ||
                    property.type === EntityPropertyTypes.ENTITY_RELATIONSHIP
                )
                    return;
                return (
                    <Context
                        {...props}
                        key={'idEntity-' + property.id + '-' + index}
                        data={
                            type === DraftEntityDataTypes.ENTITYVALUES ||
                            type === DraftEntityDataTypes.CLIENT_RELATIONSHIP
                                ? (():
                                      | EntityValuesDraftEntityData
                                      | ClientRelationshipDraftEntityData => {
                                      const text =
                                          property.id === entity.idLabelStep
                                              ? entity.name
                                              : property.label +
                                                ` (${entity.name})`;

                                      return {
                                          type,
                                          renderType: 'INLINE',
                                          idEntity,
                                          path,
                                          block: {
                                              depth: 0,
                                              entityRanges: [
                                                  {
                                                      key: 0,
                                                      length: text.length,
                                                      offset: 0,
                                                  },
                                              ],
                                              inlineStyleRanges: [
                                                  {
                                                      length: text.length,
                                                      offset: 0,
                                                      style: DraftStyleTypes.BOLD,
                                                  },
                                              ],
                                              key: 'nested',
                                              text,
                                              type: 'unstyled',
                                          },
                                          entityMap: {
                                              '0': {
                                                  type: DraftEntityTypes.EUREKA,
                                                  mutability: 'IMMUTABLE',
                                                  data: {
                                                      type: DraftEntityDataMappingTypes.ENTITYVALUE_MAPPING,
                                                      idEntity,
                                                      idProperty: property.id,
                                                  },
                                              },
                                          },
                                      };
                                  })()
                                : {
                                      type,
                                      idProperty: property.id,
                                  }
                        }
                        isMapping={isMapping}
                    />
                );
            })}
            {!context?.isPublic &&
                integrations?.map(
                    (integration: DraftIntegration): JSX.Element => {
                        return (
                            <Context
                                {...props}
                                key={integration._id}
                                data={
                                    type ===
                                        DraftEntityDataTypes.ENTITYVALUES ||
                                    type ===
                                        DraftEntityDataTypes.CLIENT_RELATIONSHIP
                                        ? (():
                                              | EntityValuesDraftEntityData
                                              | ClientRelationshipDraftEntityData => {
                                              const text =
                                                  (integration.editorText ??
                                                      integration.name) +
                                                  ` (${entity.name})`;

                                              return {
                                                  type,
                                                  renderType: 'INLINE',
                                                  idEntity,
                                                  path,
                                                  block: {
                                                      depth: 0,
                                                      entityRanges: [
                                                          {
                                                              key: 0,
                                                              length: text.length,
                                                              offset: 0,
                                                          },
                                                      ],
                                                      inlineStyleRanges: [
                                                          {
                                                              length: text.length,
                                                              offset: 0,
                                                              style: DraftStyleTypes.BOLD,
                                                          },
                                                      ],
                                                      key: 'nested',
                                                      text,
                                                      type: 'unstyled',
                                                  },
                                                  entityMap: {
                                                      '0': {
                                                          type: DraftEntityTypes.EUREKA,
                                                          mutability:
                                                              'IMMUTABLE',
                                                          data: {
                                                              type: DraftEntityDataMappingTypes.ENTITYVALUE_INTEGRATION_MAPPING,
                                                              idEntity,
                                                              idIntegration:
                                                                  integration._id,
                                                          },
                                                      },
                                                  },
                                              };
                                          })()
                                        : {
                                              type: DraftEntityDataTypes.CLIENT_INTEGRATION,
                                              idIntegration: integration._id,
                                          }
                                }
                                isMapping={isMapping}
                            />
                        );
                    }
                )}
            {(type === DraftEntityDataTypes.ENTITYVALUES ||
                type === DraftEntityDataTypes.CLIENT_RELATIONSHIP) &&
                entity.relationships?.map((relationship): JSX.Element => {
                    if (relationship.multiple)
                        return (
                            <EntityValuesContext
                                {...props}
                                type={type}
                                key={relationship.idEntity}
                                idEntity={relationship.idEntity}
                                path={[...(path ?? []), idEntity]}
                            />
                        );
                    return (
                        <EntityContextGroup
                            {...props}
                            type={type}
                            context={context}
                            key={relationship.idEntity}
                            idEntity={relationship.idEntity}
                            path={[...(path ?? []), idEntity]}
                        />
                    );
                })}
        </>
    );
}

export default EntityContext;

export function EntityContextGroup(props: EntityContextProps): JSX.Element {
    const { idEntity, zIndex } = props;
    const entity = useSelector(
        (state: RootState) => state.site.entities[idEntity]
    );
    if (!entity) return <></>;
    return (
        <EurekaElementMenuGroup
            zIndex={zIndex}
            icon={
                props.icon ??
                ((props): JSX.Element => (
                    <EntityIcon {...props} idEntity={idEntity} />
                ))
            }
            label={entity.name}
            renderElements={(): JSX.Element => {
                return <EntityContext {...props} />;
            }}
        />
    );
}
