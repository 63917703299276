import React, { useEffect, useRef, useState } from 'react';
import styles from './ActionWithPayloadContent.module.css';
import { convertFromRaw, EditorState } from 'draft-js';
import { getRawText } from '../../../../../../../../../utils/draftFunctions';
import DraftRenderer from '../../../../../../../../DraftRenderer/DraftRenderer';
import { Payload } from '../../../../../../../../../@Types/Payload';

export interface CommentProps {
    person?: string;
    payload: Payload;
    isComment?: boolean;
}

export function ActionWithPayloadContent({
    person,
    payload,
    isComment = true,
}: CommentProps): JSX.Element {
    const editorRef = useRef<any>(null);
    const editorState = EditorState.createWithContent(
        convertFromRaw(getRawText(payload?.draft, payload?.text))
    );
    useEffect(() => {
        /** On load add a resize listener to check if the content fits in the contentcard */
        window.addEventListener('resize', handleResize);
        handleResize();
        setTimeout(() => {
            handleResize();
        }, 500);
        return window.removeEventListener('resize', handleResize);
    }, [payload, editorRef.current]);

    const [, setCanShowMore] = useState(true);

    /** Calcs if the contentcard has more to show */
    const handleResize = (): void => {
        setCanShowMore(
            editorRef.current &&
                (editorRef?.current?.scrollHeight ??
                    editorRef?.current?.editor?.scrollHeight) > 50
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                {isComment
                    ? person
                        ? `${person} comentó: `
                        : 'Nuevo Comentario: '
                    : 'El Cliente Respondió: '}
            </div>
            <div className={styles.textContainerContainer}>
                <div className={styles.textContainer}>
                    <DraftRenderer
                        editorRef={editorRef}
                        editorState={editorState}
                        images={{}}
                    />
                </div>
            </div>
        </div>
    );
}
