import { createAction } from '@reduxjs/toolkit';
import { Company } from '../../@Types/@Types';
import { History } from 'history';
import ScrollTypes from '../../constants/ScrollTypes';

export const Types = {
    RESET: 'COMPANIES/RESET',
    RESET_SUCCESS: 'COMPANIES/RESET_SUCCESS',
    REFRESH: 'COMPANIES/REFRESH',
    GET_SUCCESS: 'COMPANIES/GET_SUCCESS',
    REFRESH_CURRENT: 'COMPANIES/REFRESH_CURRENT',
    REFRESH_CURRENT_SUCCESS: 'COMPANIES/REFRESH_CURRENT_SUCCESS',
    SELECT: 'COMPANIES/SELECT_ELEMENT',
    SEARCH: 'COMPANIES/SEARCH',
    LOADING: 'COMPANIES/LOADING',
    ORDER: 'COMPANIES/ORDER',
    FILTER: 'COMPANIES/FILTER',
    PAGE_SCROLL: 'COMPANIES/NEW_PAGE_SCROLL',
    GO_TO: 'COMPANIES/GO_TO',
};

/**
 * Called when the companies page is first loaded
 */
export const reset = createAction<{
    history: History;
    queryString: string;
    idCompany?: string;
}>(Types.RESET);

export const goTo = createAction<{
    history: History;
    queryString: string;
    idCompany?: string;
}>(Types.GO_TO);

export const resetSuccess = createAction<{
    elements: Company[];
    selectedElement?: number;
    lastPage: boolean;
    page: number;
    filters: [];
    orderBy: string | undefined;
    search: string | undefined;
}>(Types.RESET_SUCCESS);

/**
 * Called when the list have been change
 */
export const refresh = createAction(Types.REFRESH);

/**
 * Called when the selectedElement needs to be refreshed
 */
export const refreshCurrent = createAction(Types.REFRESH_CURRENT);

/**
 * Called when the selectedElement has successfully been fetched for refresh
 */
export const refreshSuccess = createAction<Company>(
    Types.REFRESH_CURRENT_SUCCESS
);

/**
 * Called when the list needs to load more elements
 */
export const pageScroll = createAction<ScrollTypes>(Types.PAGE_SCROLL);

/**
 * Called to toggle the loader
 */
export const setLoading = createAction(
    Types.LOADING,
    function prepare(loading: boolean = true) {
        return {
            payload: loading,
        };
    }
);

/**
 * Called when the selectedElement (index) changes value
 */
export const selectElement = createAction<number | undefined>(Types.SELECT);

/**
 * Called when the search value changes
 */
export const search = createAction<string>(Types.SEARCH);

/**
 *  Called when the orderby value changes
 */
export const orderBy = createAction<string>(Types.ORDER);

/**
 * Called when the filter values change
 */
export const filter = createAction<string[]>(Types.FILTER);

/**
 *  Called when the saga has sucessfully retrieved the lists elements
 */
export const getSuccess = createAction<{
    selectedElement: number | undefined;
    elements: Company[];
    lastPage: boolean;
}>(Types.GET_SUCCESS);
