import { MenuGroupedNotification } from '../../../../../../../@Types/GroupedMenuNotification';
import React, { JSX, useMemo } from 'react';
import styles from './GroupedNotification.module.css';
import {
    KeyboardArrowDownOutlined,
    KeyboardArrowUpOutlined,
} from '@material-ui/icons';
import { UngroupedNotification } from '../UngroupedNotification/UngroupedNotification';
import TicketIcon from '../../../../../../../Icons/TicketIcon';
import ActionTypes from '../../../../../../../constants/ActionTypes';

export interface GroupedNotificationProps {
    notification: MenuGroupedNotification;
    openDropdown: boolean;
    setOpenDropdown: Function;
}

const labelsNotificationIndex = {
    [ActionTypes.COMMENT]: 'Comentario de Agente',
    [ActionTypes.AUTO_COMMENT]: 'Comentario Automático',
    [ActionTypes.CLIENT_REPLY]: 'Respuesta de Cliente',
    [ActionTypes.ASSIGN_AGENTS]: 'Caso Asignado',
    [ActionTypes.AUTO_ASSIGN_AGENTS]: 'Caso Asignado',
    [ActionTypes.TRANSFER_AGENT]: 'Caso Transferido',
    [ActionTypes.ACTIVITY]: 'Actividad Asignada',
} as const;

export function GroupedNotification({
    notification,
    openDropdown,
    setOpenDropdown,
}: GroupedNotificationProps): JSX.Element {
    const message = useMemo(() => {
        let message = '';
        let current = {
            type: '' as keyof typeof labelsNotificationIndex,
            count: 0,
        };
        for (const sub of notification.subs) {
            const type = sub.action.type;
            if (type && type !== current.type) {
                if (current.count > 0) {
                    if (message !== '') {
                        message += ', ';
                    }
                    message += labelsNotificationIndex[current.type];
                    if (current.count > 1) {
                        message += ' (' + current.count + ')';
                    }
                }
                current = { type: type as any, count: 1 };
            } else {
                current.count += 1;
            }
        }
        if (current.count > 0) {
            if (message !== '') {
                message += ', ';
            }
            message += labelsNotificationIndex[current.type];
            if (current.count > 1) {
                message += ' (' + current.count + ')';
            }
        }
        return message;
    }, []);
    return (
        <div className={styles.container}>
            {openDropdown ? (
                <div className={styles.ungroupedList}>
                    {notification.subs.map((sub, index) => {
                        return (
                            <div key={index} className={styles.ungroupedItem}>
                                {!sub.seen && (
                                    <div className={styles.notificationIcon}>
                                        !
                                    </div>
                                )}
                                <div className={styles.sideBar} />
                                <UngroupedNotification
                                    notification={sub}
                                    ticket={notification.ticket}
                                />
                            </div>
                        );
                    })}
                    <div
                        className={styles.dropUp}
                        onClick={(): void => setOpenDropdown(false)}
                    >
                        <KeyboardArrowUpOutlined
                            preserveAspectRatio={'none'}
                            className={styles.dropdownIcon}
                            style={{
                                position: 'absolute',
                                width: 63,
                                height: 28,
                            }}
                        />
                    </div>
                </div>
            ) : (
                <div
                    className={styles.content}
                    onClick={(): void => setOpenDropdown(true)}
                >
                    <div className={styles.infoContainer}>
                        <div className={styles.message}>{message}</div>
                        <div className={styles.icon}>
                            <div className={styles.numberIcon}>
                                {notification.total > 99
                                    ? '99+'
                                    : notification.total}
                            </div>
                            <TicketIcon
                                fill={'var(--secondary)'}
                                style={{
                                    width: 45,
                                    height: 45,
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.dropdown}>
                        <KeyboardArrowDownOutlined
                            preserveAspectRatio={'none'}
                            className={styles.dropdownIcon}
                            style={{
                                width: 63,
                                height: 28,
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
