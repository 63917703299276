import { AvatarGroup, AvatarGroupProps } from '@material-ui/lab';
import SmartAvatar from '../Avatar/SmartAvatar';
import { makeStyles } from '@material-ui/core';

interface StyleProps {
    /** material ui style seed */
    seed?: string;
    /** Size of the avatar */
    size?: number;
    /** The size of the font to display */
    fontSize?: number;
}

const useStyles = (props: StyleProps): Function =>
    makeStyles(() => ({
        root: {
            [`& .${props.seed}MuiAvatarGroup-avatar`]: {
                width: props.size,
                height: props.size,
                fontSize: props.fontSize,
                marginLeft: 0,
                backgroundColor: 'var(--secondary)',
                border: 'none',
            },
        },
    }));

interface SmartAgentGroupProps extends StyleProps, AvatarGroupProps {
    /** If shows text on hover */
    toolTip?: boolean;

    /** The ids of the agents to display */
    agents?: string[];
}
/**
 * Component for the agent group
 */
function SmartAgentGroup({
    agents,
    toolTip,
    size = 20,
    fontSize = 12,
    seed = 'Erk-',
    ...props
}: SmartAgentGroupProps): JSX.Element {
    const classes = useStyles({ seed, size, fontSize })();
    if (!agents?.length) return <></>;
    return (
        <AvatarGroup {...props} classes={classes}>
            {agents.map((idAgent) => (
                <SmartAvatar
                    idAgent={idAgent}
                    key={idAgent}
                    size={size}
                    toolTip={toolTip}
                    fontSize={fontSize}
                />
            ))}
        </AvatarGroup>
    );
}

export default SmartAgentGroup;
