import { RawDraftContentBlock } from 'draft-js';
import {
    DraftEntityDataMappingTypes,
    DraftEntityDataTypes,
} from '../../constants/Draft/DraftEntityDataTypes';
import { Condition, compareConditions } from '../ConditionTypes/Condition';
import { AgentPropertyTypes } from '../../constants/AgentPropertyTypes';
import { TicketPropertyTypes } from '../../constants/TicketPropertyTypes';
import { DraftEntityMap, compareDrafts } from './Draft';
import ClientInfoTypes from '../../constants/ClientInfoTypes';
import { UserTypes } from '../../constants/UserTypes';
import { FormStepCondition } from '../ConditionTypes/FormStepCondition';
import { Time } from '../Time';
import NotificationPropertyTypes from '../../constants/NotificationPropertyTypes';
import {
    KeysOfUnion,
    compareArrays,
    deepCompare,
} from '../../utils/DeepCompare';
import { ErkValue } from '../ErkValue';

export type DraftEntityData =
    | AgentDraftEntityData
    | ClassifierDraftEntityData
    | ClientDraftEntityData
    | ClientIntegrationDraftEntityData
    | CompanyDraftEntityData
    | ConditionMetDraftEntityData
    | DateDraftEntityData
    | OrganizationDraftEntityData
    | ProjectDraftEntityData
    | TicketDateDraftEntityData
    | TicketDraftEntityData
    | TicketLinkDraftEntityData
    | TicketValueDraftEntityData
    | MappableDraftEntityData
    | NotificationDraftEntityData
    | AgentMappingDraftEntityData
    | ConceptMappingDraftEntityData
    | EntityValueMappingDraftEntityData
    | EntityValueIntegrationMappingDraftEntityData
    | FormStepDraftEntityData
    | ConversationStepDraftEntityData
    | IntegrationDraftEntityData;

export interface BaseDraftEntityData {
    type: DraftEntityDataTypes | DraftEntityDataMappingTypes;
    prefix?: string;
    suffix?: string;
    fallback?: string;
    condition?: Condition;
}

interface AgentDraftEntityData extends BaseDraftEntityData {
    type: DraftEntityDataTypes.AGENT;
    property: AgentPropertyTypes;
}

interface ClassifierDraftEntityData extends BaseDraftEntityData {
    type: DraftEntityDataTypes.CLASSIFIER;
    idRoot: string;
}

interface ClientDraftEntityData extends BaseDraftEntityData {
    type: DraftEntityDataTypes.CLIENT;
    idProperty: ClientInfoTypes | 'FULLNAME' | string;
}
interface ClientIntegrationDraftEntityData extends BaseDraftEntityData {
    type: DraftEntityDataTypes.CLIENT_INTEGRATION;
    idIntegration: string;
    values?: Record<string, ErkValue>;
}

interface CompanyDraftEntityData extends BaseDraftEntityData {
    type: DraftEntityDataTypes.COMPANY;
    idProperty: string;
}

interface ConditionMetDraftEntityData extends BaseDraftEntityData {
    type: DraftEntityDataTypes.CONDITION_MET;
    condition: Condition;
    ifTrue?: string;
    ifFalse?: string;
}

export interface DateDraftEntityData extends BaseDraftEntityData {
    type: DraftEntityDataTypes.DATE;
    time: Time;
    /** date-fns formats */
    format?: string;
}

interface OrganizationDraftEntityData extends BaseDraftEntityData {
    type: DraftEntityDataTypes.ORGANIZATION;
    property: 'NAME';
}

interface ProjectDraftEntityData extends BaseDraftEntityData {
    type: DraftEntityDataTypes.PROJECT;
    property: 'NAME' | 'DESCRIPTION';
}

interface TicketDateDraftEntityData extends BaseDraftEntityData {
    type: DraftEntityDataTypes.TICKET;
    property:
        | TicketPropertyTypes.RESOLUTION_DATE
        | TicketPropertyTypes.CREATION_DATE
        | TicketPropertyTypes.CLOSED_DATE;
    /** date-fns formats */
    format?: string;
}

interface TicketDraftEntityData extends BaseDraftEntityData {
    type: DraftEntityDataTypes.TICKET;
    property:
        | TicketPropertyTypes.CASENUMBER
        | TicketPropertyTypes.SUBJECT
        | TicketPropertyTypes.STATE
        | TicketPropertyTypes.TEXT;
}

interface TicketLinkDraftEntityData extends BaseDraftEntityData {
    type: DraftEntityDataTypes.TICKET_LINK;
    linkType: UserTypes;
}

interface TicketValueDraftEntityData extends BaseDraftEntityData {
    type: DraftEntityDataTypes.TICKET_VALUE;
    idValue: string;
}
export interface NotificationDraftEntityData extends BaseDraftEntityData {
    type: DraftEntityDataTypes.NOTIFICATION;
    property: NotificationPropertyTypes;
}

export type MappableDraftEntityData =
    | AgentsDraftEntityData
    | ConceptsDraftEntityData
    | EntityValuesDraftEntityData
    | ClientRelationshipDraftEntityData
    | NestedDraftEntityData;

export interface BaseMappableDraftEntityData extends BaseDraftEntityData {
    block: RawDraftContentBlock;
    entityMap: DraftEntityMap;
}

export interface NestedDraftEntityData extends BaseMappableDraftEntityData {
    type: DraftEntityDataTypes.NESTED;
}

export interface AgentsDraftEntityData extends BaseMappableDraftEntityData {
    type: DraftEntityDataTypes.AGENTS;
    renderType: 'INLINE' | 'LIST';
    separator?: string;
}
export interface ConceptsDraftEntityData extends BaseMappableDraftEntityData {
    type: DraftEntityDataTypes.CONCEPT;
    idConcept: string;
    renderType: 'INLINE' | 'LIST';
    separator?: string;
    filters?: FormStepCondition[];
}

export interface EntityValuesDraftEntityData
    extends BaseMappableDraftEntityData {
    path?: string[];
    type: DraftEntityDataTypes.ENTITYVALUES;
    idEntity: string;
    renderType: 'INLINE' | 'LIST';
    separator?: string;
}

export interface ClientRelationshipDraftEntityData
    extends BaseMappableDraftEntityData {
    path?: string[];
    type: DraftEntityDataTypes.CLIENT_RELATIONSHIP;
    idEntity: string;
    renderType: 'INLINE' | 'LIST';
    separator?: string;
}

export interface FormStepDraftEntityData extends BaseDraftEntityData {
    type: DraftEntityDataTypes.FORM_STEP;
    idStep: string;
}

export interface ConversationStepDraftEntityData extends BaseDraftEntityData {
    type: DraftEntityDataTypes.CONVERSATION_STEP;
    idStep: string;
}
export function isMappableEntityData(
    data: DraftEntityData
): data is MappableDraftEntityData {
    return (
        data.type === DraftEntityDataTypes.NESTED ||
        data.type === DraftEntityDataTypes.AGENTS ||
        data.type === DraftEntityDataTypes.CONCEPT ||
        data.type === DraftEntityDataTypes.CLIENT_RELATIONSHIP ||
        data.type === DraftEntityDataTypes.ENTITYVALUES
    );
}

export interface AgentMappingDraftEntityData extends BaseDraftEntityData {
    type: DraftEntityDataMappingTypes.AGENT_MAPPING;
    property: AgentPropertyTypes;
}
export interface ConceptMappingDraftEntityData extends BaseDraftEntityData {
    type: DraftEntityDataMappingTypes.CONCEPT_MAPPING;
    idConcept: string;
    idStep: string;
}

export interface EntityValueMappingDraftEntityData extends BaseDraftEntityData {
    type: DraftEntityDataMappingTypes.ENTITYVALUE_MAPPING;
    idEntity: string;
    idProperty: string;
}

export interface EntityValueIntegrationMappingDraftEntityData
    extends BaseDraftEntityData {
    type: DraftEntityDataMappingTypes.ENTITYVALUE_INTEGRATION_MAPPING;
    idEntity: string;
    idIntegration: string;
    values?: Record<string, ErkValue>;
}

interface IntegrationDraftEntityData extends BaseDraftEntityData {
    type: DraftEntityDataTypes.INTEGRATION;
    idIntegration: string;
    values?: Record<string, ErkValue>;
}

export function compareDraftEntityDatas(
    data1: DraftEntityData | undefined,
    data2: DraftEntityData | undefined
): boolean {
    if (!data1 || !data2) return true;
    if (!!data1 !== !!data2) return false;
    const omits: KeysOfUnion<DraftEntityData>[] = [];

    if (hasCondition(data1)) {
        if (!hasCondition(data1)) return false;
        if (!compareConditions(data1.condition, data1.condition)) return false;
        omits.push('condition');
    }

    if (isMappableEntityData(data1)) {
        if (!isMappableEntityData(data2)) return false;
        if (
            !compareDrafts(
                {
                    blocks: [data1.block],
                    entityMap: data1.entityMap,
                },
                {
                    blocks: [data2.block],
                    entityMap: data2.entityMap,
                }
            )
        )
            return false;
        omits.push('block', 'entityMap');
        if (
            data1.type === DraftEntityDataTypes.CONCEPT &&
            data1.type === data2.type
        ) {
            if (!compareArrays(data1.filters, data2.filters, compareConditions))
                return false;
            omits.push('filters');
        }
    }
    return deepCompare(data1, data2, omits);
}

function hasCondition(
    condition: DraftEntityData
): condition is Extract<DraftEntityData, { condition: Condition }> {
    return 'condition' in condition;
}
