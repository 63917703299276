import React from 'react';

interface MaterialTableDocumentPartProps {
    style: {
        primaryColor: string;
        outlineColor: string;
        fontSize?: number;
    };
    part: {
        title: string;
        headers: {
            label: string;
        }[];
        rows: {
            isHeader: boolean;
            data: {
                value: string;
                colSpan?: number;
            }[];
        }[];
        totals?: {
            label: string;
            colSpan?: number;
            textAlign?: 'start' | 'end';
        }[];
    };
}

function MaterialTable({
    style: { fontSize, primaryColor, outlineColor },
    part: { title, headers, rows, totals },
}: MaterialTableDocumentPartProps): JSX.Element {
    const renderPadding = (): JSX.Element => (
        <tr>
            {headers.map((_header, colIndex) => (
                <th
                    key={colIndex}
                    style={{
                        borderLeft:
                            colIndex !== 0
                                ? '1px solid ' + outlineColor
                                : undefined,
                        height: 5,
                    }}
                ></th>
            ))}
        </tr>
    );

    return (
        <div
            style={{
                paddingTop: 15,
                minWidth: 'fit-content',
                width: '100%',
            }}
        >
            <div
                style={{
                    border: '1px solid ' + outlineColor,
                    borderRadius: '15px 15px 0px 0px',
                    borderBottom: 'none',
                    paddingTop: 5,
                    fontSize: fontSize ?? 12,
                    color: primaryColor,
                    textAlign: 'center',
                    fontWeight: 'bold',
                    paddingBottom: 2,
                }}
            >
                {title}
            </div>
            <table
                style={{
                    width: '100%',
                    borderCollapse: 'collapse',
                    verticalAlign: 'center',
                    borderLeft: '1px solid ' + outlineColor,
                    borderRight: '1px solid ' + outlineColor,
                }}
            >
                <thead>
                    <tr
                        style={{
                            pageBreakInside: 'avoid',
                            backgroundColor: outlineColor,
                        }}
                    >
                        {headers.map((header, index) => (
                            <th
                                style={{
                                    fontSize: fontSize ?? 12,
                                    fontWeight: 900,
                                    textAlign: 'start',
                                    width: '100px',
                                    padding: '5px 10px',
                                }}
                                key={'h' + index}
                            >
                                {header.label}
                            </th>
                        ))}
                    </tr>
                    {renderPadding()}
                </thead>
                <tbody>
                    {rows.map((row, index) => {
                        if (row.isHeader) {
                            return (
                                <React.Fragment key={'r' + index}>
                                    {renderPadding()}
                                    <tr
                                        style={{
                                            pageBreakInside: 'avoid',
                                            pageBreakAfter: 'auto',
                                            backgroundColor: outlineColor,
                                        }}
                                    >
                                        {row.data.map((data, index) => (
                                            <th
                                                style={{
                                                    fontSize: fontSize ?? 12,
                                                    fontWeight: 900,
                                                    textAlign: 'start',
                                                    width: '100px',
                                                    padding: '5px 10px',
                                                }}
                                                colSpan={data.colSpan}
                                                key={'h' + index}
                                            >
                                                {data.value}
                                            </th>
                                        ))}
                                    </tr>
                                    {renderPadding()}
                                </React.Fragment>
                            );
                        }
                        return (
                            <tr
                                key={index}
                                style={{
                                    pageBreakAfter: 'auto',
                                    fontSize: fontSize ?? 12,
                                }}
                            >
                                {row.data.map((data, colIndex) => (
                                    <td
                                        style={{
                                            padding: '2px 10px',
                                            borderLeft:
                                                colIndex !== 0
                                                    ? '1px solid ' +
                                                      outlineColor
                                                    : undefined,
                                            borderRight:
                                                '1px solid ' + outlineColor,
                                        }}
                                        colSpan={data.colSpan}
                                        key={index + '-' + colIndex}
                                    >
                                        {data.value}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
                {renderPadding()}
                {totals && (
                    <tr
                        style={{
                            pageBreakAfter: 'auto',
                            paddingTop: 5,
                            paddingBottom: 5,
                            backgroundColor: outlineColor,
                            textAlign: 'start',
                            fontSize: fontSize ?? 12,
                            fontWeight: 900,
                        }}
                    >
                        {totals.map((total, colIndex) => (
                            <td
                                colSpan={total.colSpan}
                                style={{
                                    padding: '5px 10px 4px 10px',
                                    textAlign: total.textAlign as any,
                                }}
                                key={'total-' + colIndex}
                            >
                                {total.label}
                            </td>
                        ))}
                    </tr>
                )}
                <tfoot>
                    <tr
                        style={{
                            borderBottom: '1px solid ' + outlineColor,
                        }}
                    ></tr>
                </tfoot>
            </table>
            <div
                style={{
                    paddingTop: 5,
                    border: '1px solid ' + outlineColor,
                    borderTop: 'none',
                    borderRadius: '0px 0px 15px 15px',
                    paddingBottom: 10,
                }}
            ></div>
        </div>
    );
}

export default MaterialTable;
