import { IconProps } from './@IconTypes';

function AssignActivityIcon({
    fill,
    className,
    style,
}: IconProps): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill={fill}
            width="35px"
            height="35px"
            viewBox="0 0 35 35"
            className={className}
            style={style}
        >
            <path d="M14.3076 4.50782L13.3103 3.51057C13.0254 3.22564 12.598 3.22564 12.3131 3.51057L6.04465 9.77901L3.52778 7.26213C3.24285 6.97721 2.81546 6.97721 2.53053 7.26213L1.53328 8.25939C1.24835 8.54431 1.24835 8.97171 1.53328 9.25664L5.0474 12.7708C5.33233 13.0557 5.71223 13.1982 6.04465 13.1982C6.42455 13.1982 6.75697 13.0557 7.0419 12.7708L14.3076 5.50507C14.545 5.26763 14.545 4.79275 14.3076 4.50782Z" />
            <path d="M28.7447 11.2991H16.3978C15.8754 11.2991 15.448 10.8717 15.448 10.3493V8.44976C15.448 7.92739 15.8754 7.5 16.3978 7.5H28.7447C29.267 7.5 29.6944 7.92739 29.6944 8.44976V10.3493C29.6944 10.8717 29.267 11.2991 28.7447 11.2991Z" />
            <path d="M28.7448 19.8469H13.5486C13.0263 19.8469 12.5989 19.4195 12.5989 18.8971V16.9976C12.5989 16.4752 13.0263 16.0479 13.5486 16.0479H28.7448C29.2672 16.0479 29.6946 16.4752 29.6946 16.9976V18.8971C29.6946 19.4195 29.2672 19.8469 28.7448 19.8469Z" />
            <path d="M7.84929 19.8469H5.94976C5.42739 19.8469 5 19.4195 5 18.8971V16.9976C5 16.4752 5.42739 16.0479 5.94976 16.0479H7.84929C8.37166 16.0479 8.79905 16.4752 8.79905 16.9976V18.8971C8.79905 19.4195 8.37166 19.8469 7.84929 19.8469Z" />
            <path d="M7.84929 28.3948H5.94976C5.42739 28.3948 5 27.9674 5 27.445V25.5455C5 25.0231 5.42739 24.5957 5.94976 24.5957H7.84929C8.37166 24.5957 8.79905 25.0231 8.79905 25.5455V27.445C8.79905 27.9674 8.37166 28.3948 7.84929 28.3948Z" />
            <path d="M28.7448 28.3948H13.5486C13.0263 28.3948 12.5989 27.9674 12.5989 27.445V25.5455C12.5989 25.0231 13.0263 24.5957 13.5486 24.5957H28.7448C29.2672 24.5957 29.6946 25.0231 29.6946 25.5455V27.445C29.6946 27.9674 29.2672 28.3948 28.7448 28.3948Z" />
        </svg>
    );
}

export default AssignActivityIcon;
