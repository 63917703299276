import React, { memo } from 'react';
import styles from './AgentNode.module.css';
import { Handle, Position } from 'react-flow-renderer';
import Avatar from '../../../../shared/Avatar/Avatar';
import { Agent } from '../../../../@Types/Agent';

interface AgentNodeProps {
    /** Agent to display */
    data: Agent & { error: boolean };
}

function AgentNode({ data }: AgentNodeProps): JSX.Element {
    return (
        <>
            <Handle
                className={styles.leftHandle}
                type="target"
                position={Position.Left}
                id="AGENT"
                style={{
                    top: '50%',
                    background: 'var(--primary1)',
                    border: '1px solid var(--accent)',
                    height: 4,
                    width: 4,
                    zIndex: 1,
                }}
            />
            <div
                className={styles.container}
                style={
                    data.error
                        ? {
                              border: 'var(--error) 1px solid',
                              marginTop: '-1px',
                              marginLeft: '-1px',
                          }
                        : {}
                }
            >
                <div className={styles.cover}></div>
                <Avatar img={data.img} lbl={data.name} />
                <div className={styles.label}>{data.name}</div>
            </div>
        </>
    );
}

export default memo(AgentNode);
