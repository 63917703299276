import React from 'react';
import { ConditionContext } from '../ConditionEditor';
import styles from './Condition.module.css';
import { IntegrationCondition } from '../../../@Types/ConditionTypes/IntegrationConditions';
import IntegrationTypes from '../../../constants/IntegrationTypes';
import { MenuItem } from '@material-ui/core';
import ConditionOperators from '../../../constants/Conditions/ConditionOperators';
import RoundedSelect from '../../RoundedSelect/RoundedSelect';
import RoundedIntegrationPicker from '../../@Pickers/RoundedIntegrationPicker/RoundedIntegrationPicker';
import IntegrationConditionComponent from './IntegrationCondition';

interface IntegrationsConditionProps {
    context: ConditionContext;
    condition: IntegrationCondition;
    hoverDelete: boolean;
    handleUpdate: (condition: IntegrationCondition) => void;
}

function IntegrationsConditionComponent({
    context,
    condition,
    hoverDelete,
    handleUpdate,
}: IntegrationsConditionProps): JSX.Element {
    return (
        <React.Fragment>
            <div className={styles.operatorContainer}>
                <div className={styles.selectorContainer}>
                    <RoundedSelect
                        value={condition.operator ?? ''}
                        label="Operador"
                        containerMargin="0px"
                        error={hoverDelete}
                        handleUpdate={(event): void => {
                            handleUpdate({
                                ...condition,
                                operator: event.target.value,
                            });
                        }}
                    >
                        <MenuItem value={ConditionOperators.INTEGRATION_MET}>
                            Cumple
                        </MenuItem>
                        <MenuItem value={ConditionOperators.NOTINTEGRATION_MET}>
                            No Cumple
                        </MenuItem>
                    </RoundedSelect>
                </div>
            </div>
            <div className={styles.operatorContainer}>
                <div
                    className={styles.selectorContainer}
                    style={{ minWidth: 240 }}
                >
                    <RoundedIntegrationPicker
                        label={'Integración'}
                        value={condition.idIntegration}
                        onChange={(idIntegration): void => {
                            handleUpdate({
                                ...condition,
                                idIntegration: idIntegration,
                                integrationOperator: undefined,
                                value: undefined,
                                values: {},
                            });
                        }}
                        types={[IntegrationTypes.CONDITION]}
                    />
                </div>
            </div>

            {condition.idIntegration && (
                <IntegrationConditionComponent
                    idIntegration={condition.idIntegration}
                    context={context}
                    condition={condition}
                    hoverDelete={hoverDelete}
                    handleUpdate={(cond): void => {
                        handleUpdate({ ...condition, ...cond });
                    }}
                />
            )}
        </React.Fragment>
    );
}

export default IntegrationsConditionComponent;
