import { AnyAction } from '@reduxjs/toolkit';
import { Entity } from '../../@Types/@Types';
import * as Actions from './EntitiesActions';

export interface EntitiesPageState {
    /** The currently displayed Entities */
    elements: Entity[];
    /** The current search, undefined if none */
    search: string | undefined;
    /** the index of the currently selectedElement */
    selectedElement: number | undefined;
    /** The number of pages currently loaded in the list */
    page: number;
    /** Active if no more pages are available */
    noMoreElements: boolean;
    /** If loader is active */
    loading: boolean;
}

const initialState = {
    elements: [],
    search: undefined,
    selectedElement: undefined,
    page: 1,
    noMoreElements: false,
    loading: true,
};

/**
 * Redux Reducer that handles EntitiesActions Tiggers
 * @param state The current state
 * @param action the action that was triggered
 * @returns the new state
 */
const EntitiesReducer = (
    state: EntitiesPageState = initialState,
    action: AnyAction
): EntitiesPageState => {
    if (Actions.reset.match(action)) {
        return {
            ...state,
            elements: [],
            search: undefined,
            selectedElement: undefined,
            page: 1,
            noMoreElements: false,
            loading: true,
        };
    } else if (Actions.setLoading.match(action)) {
        return {
            ...state,
            loading: action.payload,
        };
    } else if (Actions.getSuccess.match(action)) {
        return {
            ...state,
            loading: false,
            elements: action.payload.elements,
            noMoreElements: action.payload.noMoreElements,
        };
    } else if (Actions.pageScroll.match(action)) {
        return { ...state, page: state.page + 1 };
    } else if (Actions.selectElement.match(action)) {
        return { ...state, selectedElement: action.payload };
    } else if (Actions.updateElement.match(action)) {
        const elements = [...state.elements];
        /** If element is selected update the field with the value */
        if (state.selectedElement !== undefined) {
            elements[state.selectedElement] = {
                ...elements[state.selectedElement],
                [action.payload.field]: action.payload.value,
            };
        }

        return {
            ...state,
            elements,
        };
    } else if (Actions.updateFailed.match(action)) {
        const elements = [...state.elements];
        let index = state.selectedElement;
        if (index === undefined || elements[index]._id !== action.payload._id) {
            index = elements.findIndex((e) => e._id === action.payload._id);
        }
        if (index !== -1) {
            elements[index] = action.payload;
        }
        return {
            ...state,
            elements,
        };
    } else if (Actions.search.match(action)) {
        return {
            ...state,
            elements: [],
            selectedElement: undefined,
            page: 1,
            noMoreElements: false,
            loading: true,
            search: action.payload === '' ? undefined : action.payload,
        };
    }
    return state;
};
export default EntitiesReducer;
