import { DraftEntityDataTypes } from '../../../../constants/Draft/DraftEntityDataTypes';
import Context from '../Context';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../utils/_store';
import { GenericContextProps } from '../TextEditorContext';
import EurekaElementMenuGroup from '../../../@Menus/ElementMenu/EurekaElementMenuGroup';

function OrganizationContext(props: GenericContextProps): JSX.Element {
    const organization = useSelector(
        (state: RootState) => state.site.organization
    );
    if (!organization) return <></>;

    return (
        <EurekaElementMenuGroup
            {...props}
            icon={(props): JSX.Element => (
                <img {...props} src={organization.branding.images.iconUrl} />
            )}
            label={'Organización'}
            renderElements={(): JSX.Element => {
                return (
                    <>
                        <Context
                            {...props}
                            data={{
                                type: DraftEntityDataTypes.ORGANIZATION,
                                property: 'NAME',
                            }}
                        />
                    </>
                );
            }}
        />
    );
}

export default OrganizationContext;
