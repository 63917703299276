import styles from './StepMenu.module.css';
import { Suspense, useCallback, useMemo } from 'react';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import StepTypes, { FormStepTypes } from '../../../constants/FormStepTypes';
import { FormStep } from '../../../@Types/FormTypes/FormStep';
import { nanoid } from 'nanoid';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import { Icon } from '@material-ui/core';
import lazy from 'react-lazy-with-preload';
import PlaylistAddRoundedIcon from '@material-ui/icons/PlaylistAddRounded';
import { MapperStyleTypes } from '@arquimedes.co/eureka-forms/dist/constants/FormStepTypes';
import OtherEntitiesMenu from './OtherEntitiesMenu/OtherEntitiesMenu';
import ConceptMenu from './ConceptMenu/ConceptMenu';
import EntityMenu from './EntityMenu/EntityMenu';
import { FormStepLocation } from '../../../@Types/FormTypes/Form';
import StepMenuMapper from './StepMenuMapper';
import ApiIcon from '../../../Icons/ApiIcon';
import IconTypes from '../../../constants/IconTypes';
import LocationTypes from '../../../constants/LocationTypes';
import {
    calcLocationSteps,
    useFormEditorSelector,
} from '../FormReducerFunctions';
import { checkAdmin } from '../../../utils/PermissionsFunctions';
import { useCurrentProject } from '../../../hooks';
const CBRStepMenu = lazy(
    () => import('./Construction/CBRStepMenu/CBRStepMenu')
);

const AYFStepMenu = lazy(
    () => import('./Construction/AYFStepMenu/AYFStepMenu')
);

interface StepMenuProps {
    handleClose?: Function;
    handleAdd?: (step: FormStep) => void;
    anchorRef?: any;
    location?: FormStepLocation; //Null if main create btn
    zIndex?: number;
}

export default function StepMenu({
    handleClose,
    anchorRef,
    location,
    zIndex = 3,
    ...props
}: StepMenuProps): JSX.Element {
    if (!handleClose || !props.handleAdd || !anchorRef || !location) {
        return <div></div>;
    }
    const user = useSelector((state: RootState) => state.site.user);
    const organization = useSelector(
        (state: RootState) => state.site.organization
    );
    const project = useCurrentProject();
    const editorInfo = useFormEditorSelector((editorInfo) => editorInfo);

    const siteEntities = useSelector((state: RootState) => state.site.entities);

    const entities = useMemo((): string[] => {
        const entities = project.entities.map((entity) => entity.idEntity);
        if (!location) return entities;
        if (!editorInfo) return entities;
        const subSteps = calcLocationSteps(editorInfo, location, false) ?? [];
        let idStep = subSteps[subSteps.length - 1] ?? location.idRootStep;
        if (idStep === 'NULL' && location.type === LocationTypes.SECTION) {
            const steps =
                editorInfo.sections[editorInfo.idSection ?? ''].steps ?? [];
            idStep = steps[steps.length - 1];
        }
        if (!idStep) return entities;
        const entityValueSteps =
            editorInfo.stepDependencies[idStep]?.dependencies[
                FormStepTypes.ENTITYVALUEPICKER
            ] ?? [];
        if (
            editorInfo.steps[idStep]?.type === FormStepTypes.ENTITYVALUEPICKER
        ) {
            entityValueSteps.push(idStep);
        }
        for (const idStep of entityValueSteps) {
            const step = editorInfo.steps[idStep];
            if (step?.type === FormStepTypes.ENTITYVALUEPICKER) {
                if (
                    !entities.includes(step.idEntity) &&
                    siteEntities[step.idEntity]?.relationships.filter(
                        (relationship) =>
                            relationship.idEntity !== step.idEntity
                    ).length > 0
                ) {
                    entities.push(step.idEntity);
                }
            }
        }
        return entities;
    }, [editorInfo, siteEntities, location]);
    const calcSteps = (): JSX.Element[] => {
        const elements: JSX.Element[] = [];

        for (const type of [
            FormStepTypes.TITLE,
            FormStepTypes.TEXTINPUT,
            FormStepTypes.SELECTOR,
            FormStepTypes.CLASSIFIER_SELECTOR,
            FormStepTypes.DATEPICKER,
            FormStepTypes.TIMEPICKER,
            FormStepTypes.AGENTPICKER,
        ]) {
            elements.push(
                <StepMenuMapper
                    location={location}
                    handleAdd={handleAdd}
                    key={type}
                    type={type}
                />
            );
        }

        if (organization?.idCompanyEntity) {
            elements.push(
                <div
                    className={styles.option}
                    key="COMPANYPICKER"
                    onClick={(): void => {
                        handleAdd({
                            id: StepTypes.COMPANYPICKER + '-' + nanoid(),
                            type: StepTypes.COMPANYPICKER,
                            label: 'Empresa',
                            description: '',
                            required: false,
                            multiple: false,
                            size: 1,
                        });
                    }}
                >
                    <div className={styles.agentInputContainer}>
                        <div className={styles.companyIconContainer}>
                            <Icon fontSize="inherit">
                                <img
                                    className={styles.companyImg}
                                    src="/media/icons/bluehandshake.svg"
                                />
                            </Icon>
                        </div>
                    </div>
                    <div className={styles.optionLabel}>Empresa (Interno)</div>
                </div>
            );
        }
        for (const idEntity of entities) {
            elements.push(
                <EntityMenu
                    shown={project.entities.map((entity) => entity.idEntity)}
                    key={idEntity}
                    idEntity={idEntity}
                    location={location}
                    handleAdd={handleAdd}
                />
            );
        }

        for (const type of [
            FormStepTypes.TEXTAREA,
            FormStepTypes.FILEUPLOAD,
            FormStepTypes.CHECKBOX,
            FormStepTypes.SEPARATOR,
        ]) {
            elements.push(
                <StepMenuMapper
                    location={location}
                    handleAdd={handleAdd}
                    key={type}
                    type={type}
                />
            );
        }

        elements.push(
            <div
                className={styles.option}
                key="MAPPER"
                onClick={(): void => {
                    handleAdd({
                        id: StepTypes.MAPPER + '-' + nanoid(),
                        type: StepTypes.MAPPER,
                        label: 'Elementos',
                        description: '',
                        addBtnLabel: 'Agregar',
                        required: false,
                        unitLabel: 'Elemento',
                        creatable: true,
                        style: MapperStyleTypes.PILL,
                        rootSteps: [],
                        steps: {},
                    });
                }}
            >
                <div className={styles.mapperIcon}>
                    <PlaylistAddRoundedIcon fontSize="inherit" />
                </div>
                <div className={styles.optionLabel}>Lista de Elementos</div>
            </div>
        );

        for (const concept of project.concepts) {
            elements.push(
                <ConceptMenu
                    key={concept.id}
                    location={location}
                    concept={concept}
                    handleAdd={handleAdd}
                />
            );
        }

        elements.push(
            <OtherEntitiesMenu
                key="OTHER-ENTITIES"
                location={location}
                handleAdd={handleAdd}
            />
        );

        if (checkAdmin(user)) {
            elements.push(
                <div
                    className={styles.option}
                    key="API"
                    onClick={(): void => {
                        handleAdd({
                            id: StepTypes.API_SELECTOR + '-' + nanoid(),
                            type: StepTypes.API_SELECTOR,
                            label: 'Elementos',
                            description: '',
                            required: false,
                            showIcon: false,
                            icon: IconTypes.GENERIC,
                            idAttribute: 'id',
                            labelAttribute: 'label',
                            options: {},
                            pathParams: [],
                            queryParams: [],
                            searchable: false,
                            size: 1,
                            url: '',
                        });
                    }}
                >
                    <div className={styles.mapperIcon}>
                        <ApiIcon />
                    </div>
                    <div className={styles.optionLabel}>
                        Integración Externa
                    </div>
                </div>
            );
        }

        if (organization!.integrations.AYF) {
            elements.push(
                <Suspense
                    fallback={<div className={styles.option}></div>}
                    key="AYF"
                >
                    <AYFStepMenu handleAdd={handleAdd as any} />
                </Suspense>
            );
        }
        if (organization!.integrations.CBR) {
            elements.push(
                <Suspense
                    fallback={<div className={styles.option}></div>}
                    key="CBR"
                >
                    <CBRStepMenu handleAdd={handleAdd as any} />
                </Suspense>
            );
        }
        return elements;
    };

    const handleAdd = useCallback(
        (step: FormStep, idValue?: string) => {
            if (idValue && project.values.values[idValue]) {
                step.idValue = idValue;
            }
            props.handleAdd?.(step);
        },
        [props.handleAdd]
    );

    return (
        <ClickAwayListener
            mouseEvent="onMouseDown"
            onClickAway={(): void => {
                handleClose();
            }}
        >
            <Popper
                open={true}
                anchorEl={anchorRef.current}
                placement={'bottom'}
                modifiers={{
                    preventOverflow: {
                        enabled: true,
                        priority: ['top', 'bottom', 'left', 'right'],
                        boundariesElement: 'viewport',
                        padding: 20,
                    },
                }}
                style={{ zIndex }}
            >
                <div
                    className={styles.container}
                    style={{
                        borderRadius: organization!.integrations.CBR
                            ? '10px 0px 0px 10px'
                            : 10,
                    }}
                >
                    {calcSteps()}
                </div>
            </Popper>
        </ClickAwayListener>
    );
}
