import { IconProps } from '../@IconTypes';

const CBRIcon = ({ className, style }: IconProps): JSX.Element => (
    <svg
        style={style}
        className={className}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 496 494.203"
    >
        <title>SINCO CBR</title>
        <g>
            <rect fill="#a64a5e" y="176.415" width="496" height="317.787" />
            <g>
                <path
                    fill="#ffffff"
                    d="M179.411,281.246a108.077,108.077,0,0,0-19.768-13.179,51.937,51.937,0,0,0-24.261-5.99,55.3,55.3,0,0,0-23.362,4.792A56.873,56.873,0,0,0,92.85,279.749a65.329,65.329,0,0,0-12.879,19.169,62.642,62.642,0,0,0-4.792,23.362,55.3,55.3,0,0,0,4.792,23.362A59.994,59.994,0,0,0,92.85,364.812a60.6,60.6,0,0,0,67.092,12.58,57.013,57.013,0,0,0,19.469-14.077,10.757,10.757,0,0,1,3-2.1,7.6,7.6,0,0,1,3.594-.6c2.1,0,3.594.6,4.493,2.1a9.051,9.051,0,0,1,1.5,4.792,8.158,8.158,0,0,1-2.1,5.99,73.459,73.459,0,0,1-54.512,23.662,68.261,68.261,0,0,1-29.053-5.99,73.719,73.719,0,0,1-39.536-39.536A73.082,73.082,0,0,1,60.8,322.58a68.26,68.26,0,0,1,5.99-29.053,76.39,76.39,0,0,1,15.874-23.662,72.3,72.3,0,0,1,23.662-16.174,74.176,74.176,0,0,1,58.406,0A83.309,83.309,0,0,1,188.7,270.164a10.757,10.757,0,0,1,2.1,3,8.291,8.291,0,0,1,.9,3.894,5.585,5.585,0,0,1-2.1,4.792,7.046,7.046,0,0,1-4.792,1.5A5.542,5.542,0,0,1,179.411,281.246Z"
                />
                <path
                    fill="#ffffff"
                    d="M220.145,254.589a6.17,6.17,0,0,1,2.1-5.092,6.569,6.569,0,0,1,5.092-2.1h41.633a36.816,36.816,0,0,1,14.077,2.7,33.5,33.5,0,0,1,11.382,7.787,34.741,34.741,0,0,1,7.787,11.681,38.277,38.277,0,0,1,3,14.077,30.853,30.853,0,0,1-3.894,15.575,35.853,35.853,0,0,1-9.884,12.28A44.883,44.883,0,0,1,307.9,327.671a44.034,44.034,0,0,1,6.29,23.362,43.357,43.357,0,0,1-3.594,17.672,48.293,48.293,0,0,1-9.585,14.377,47.387,47.387,0,0,1-14.377,9.884,44.394,44.394,0,0,1-17.672,3.594H227.633a6.17,6.17,0,0,1-5.092-2.1,6.569,6.569,0,0,1-2.1-5.092V254.589Zm14.676,7.488v43.729h34.444a20.1,20.1,0,0,0,8.386-1.8,23.52,23.52,0,0,0,6.889-4.792,24.768,24.768,0,0,0,4.493-6.889,24.208,24.208,0,0,0,1.5-8.087,25.985,25.985,0,0,0-1.5-8.386,23.765,23.765,0,0,0-4.493-7.488,21.944,21.944,0,0,0-6.889-4.792,20.1,20.1,0,0,0-8.386-1.8H234.821Zm0,58.106v62.3h34.444a27.975,27.975,0,0,0,11.981-2.7,32.608,32.608,0,0,0,9.884-6.889,28.611,28.611,0,0,0,6.589-9.884,31.15,31.15,0,0,0,0-23.961,30.286,30.286,0,0,0-16.473-16.473,31.662,31.662,0,0,0-11.981-2.4Z"
                />
                <path
                    fill="#ffffff"
                    d="M344.744,254.889a7.218,7.218,0,0,1,2.1-5.391,6.569,6.569,0,0,1,5.092-2.1H390.27a38.164,38.164,0,0,1,15.575,3.295,46.449,46.449,0,0,1,12.879,8.686,39.068,39.068,0,0,1,8.686,12.879,38.164,38.164,0,0,1,3.295,15.575,36.816,36.816,0,0,1-2.7,14.077,44.553,44.553,0,0,1-6.889,11.681,39.231,39.231,0,0,1-10.783,8.986,43.692,43.692,0,0,1-13.179,4.792L432.8,385.179a7.4,7.4,0,0,1,1.2,2.7,10.641,10.641,0,0,1,.6,3,7.139,7.139,0,0,1-1.8,4.193,6.4,6.4,0,0,1-5.092,2.4,6.02,6.02,0,0,1-3.894-1.2,14.2,14.2,0,0,1-3-3l-40.135-65.295H359.42v62.3a6.17,6.17,0,0,1-2.1,5.092,6.569,6.569,0,0,1-5.092,2.1,6.17,6.17,0,0,1-5.092-2.1,6.569,6.569,0,0,1-2.1-5.092V254.889Zm14.676,7.188V313h31.15a23.492,23.492,0,0,0,9.884-2.1,25.494,25.494,0,0,0,8.087-5.391,23.937,23.937,0,0,0,5.391-8.087,26.479,26.479,0,0,0,2.1-9.884,23.492,23.492,0,0,0-2.1-9.884,25.494,25.494,0,0,0-5.391-8.087,23.936,23.936,0,0,0-8.087-5.391,26.479,26.479,0,0,0-9.884-2.1Z"
                />
            </g>
            <rect fill="#943e49" width="496" height="152.754" />
            <g>
                <g>
                    <path
                        fill="#ffffff"
                        d="M147.063,87.459a9.844,9.844,0,0,0-1.2-5.092,8.805,8.805,0,0,0-3-3.594,11.279,11.279,0,0,0-4.493-2.7c-1.8-.9-3.594-1.5-5.691-2.4-1.8-.6-3.894-1.2-5.99-2.1s-3.894-1.8-5.691-2.7A14.1,14.1,0,0,1,116.512,65a7.344,7.344,0,0,1-1.8-4.792,11.47,11.47,0,0,1,1.5-5.691,15.028,15.028,0,0,1,4.193-4.493,18.209,18.209,0,0,1,5.391-2.7,20.754,20.754,0,0,1,5.99-.9,17.078,17.078,0,0,1,3.894.3,22.063,22.063,0,0,1,4.193,1.2,10.568,10.568,0,0,1,3.894,2.4,35.9,35.9,0,0,1,3.594,3.295c.6.6.6,1.5,0,1.8-.6.6-1.2.9-1.5.6-.6,0-.9-.3-.9-.6a11.5,11.5,0,0,0-3.295-3.295,10.122,10.122,0,0,0-3.594-1.8,7.809,7.809,0,0,0-3.295-.9,11.236,11.236,0,0,0-3-.3,23.12,23.12,0,0,0-4.493.6,12.364,12.364,0,0,0-4.493,2.1,10.325,10.325,0,0,0-3.594,3.594,9.282,9.282,0,0,0-1.5,5.092,6.223,6.223,0,0,0,1.5,3.594,11.317,11.317,0,0,0,3.594,2.7,23.185,23.185,0,0,0,4.792,2.1c1.8.6,3.295,1.2,4.493,1.5,2.7,1.2,5.391,2.1,7.488,3.295a47.118,47.118,0,0,1,5.691,3.594,13.261,13.261,0,0,1,3.594,4.493,12.614,12.614,0,0,1,1.2,5.99,16.323,16.323,0,0,1-1.5,6.589,14.638,14.638,0,0,1-4.193,5.391,22.784,22.784,0,0,1-5.691,3.594,17.573,17.573,0,0,1-6.889,1.2,33,33,0,0,1-5.391-.6,14.747,14.747,0,0,1-5.092-1.8l-4.493-2.7a15.882,15.882,0,0,1-3-3.594,1.909,1.909,0,0,1,0-1.8c.3-.6.6-.6,1.2-.6a2.288,2.288,0,0,1,1.5.6,9.363,9.363,0,0,0,2.7,3.295,19.1,19.1,0,0,0,4.193,2.4,26.357,26.357,0,0,0,4.493,1.5,24.91,24.91,0,0,0,3.894.6,12.155,12.155,0,0,0,5.691-1.2,13.043,13.043,0,0,0,4.792-3,12.51,12.51,0,0,0,3.295-4.493A9.579,9.579,0,0,0,147.063,87.459Z"
                    />
                    <path
                        fill="#ffffff"
                        d="M131.787,106.329a36.7,36.7,0,0,1-5.691-.6,21.9,21.9,0,0,1-5.691-2.1,25.866,25.866,0,0,1-4.792-3,13.261,13.261,0,0,1-3.594-4.493,3.622,3.622,0,0,1,0-3.594,3.8,3.8,0,0,1,5.99,0,5.285,5.285,0,0,0,2.4,2.7,22.34,22.34,0,0,0,3.594,2.1,17.319,17.319,0,0,0,4.193,1.5,12.987,12.987,0,0,0,8.386-.3,12.563,12.563,0,0,0,4.193-2.7,19.229,19.229,0,0,0,3-3.894,12.557,12.557,0,0,0,1.2-4.792,9.567,9.567,0,0,0-.9-4.193,11.727,11.727,0,0,0-2.4-3c-1.2-.9-2.7-1.8-4.193-2.7-1.8-.9-3.594-1.5-5.391-2.4-1.8-.6-3.894-1.2-5.99-2.1a59.862,59.862,0,0,1-6.29-3,21.9,21.9,0,0,1-4.792-4.193,9.4,9.4,0,0,1-2.1-6.29,13.913,13.913,0,0,1,1.8-6.889,18.337,18.337,0,0,1,4.493-5.092,16.118,16.118,0,0,1,5.99-3,19.854,19.854,0,0,1,11.082-.6,26.357,26.357,0,0,1,4.493,1.5,32.736,32.736,0,0,1,4.493,2.4,16.014,16.014,0,0,1,3.894,3.894,3.252,3.252,0,0,1,0,4.493,3.971,3.971,0,0,1-3.594,1.2,3.194,3.194,0,0,1-2.4-1.8,11.438,11.438,0,0,0-2.7-2.7c-.9-.6-2.1-1.2-3-1.8-.9-.3-2.1-.6-3-.9a17.823,17.823,0,0,0-6.889.3,9.283,9.283,0,0,0-3.894,1.8,14.2,14.2,0,0,0-3,3,8.495,8.495,0,0,0-1.2,3.894,2.719,2.719,0,0,0,.9,2.1,11.727,11.727,0,0,0,3,2.4,27.386,27.386,0,0,0,4.193,2.1,34.864,34.864,0,0,1,4.493,1.8c3,1.2,5.391,2.4,7.787,3.295a23.019,23.019,0,0,1,5.99,3.894,15.439,15.439,0,0,1,3.894,5.092,16.639,16.639,0,0,1,1.5,6.889,16.238,16.238,0,0,1-1.8,7.488,22.44,22.44,0,0,1-4.493,5.99,17.1,17.1,0,0,1-6.29,3.894C136.879,105.729,134.483,106.329,131.787,106.329Z"
                    />
                </g>
                <g>
                    <path
                        fill="#ffffff"
                        d="M169.826,47.623a1.5,1.5,0,0,1,3,0v55.111a1.5,1.5,0,0,1-3,0Z"
                    />
                    <path
                        fill="#ffffff"
                        d="M171.324,106.329a3.461,3.461,0,0,1-3.594-3.594V47.623a3.594,3.594,0,0,1,7.188,0v55.111C174.618,104.831,173.42,106.329,171.324,106.329Z"
                    />
                </g>
                <g>
                    <path
                        fill="#ffffff"
                        d="M194.985,47.623a1.647,1.647,0,0,1,.9-1.5h1.2c.3.3.6.3.9.6l35.642,51.517V47.623a1.5,1.5,0,0,1,3,0v55.111a1.415,1.415,0,0,1-1.5,1.5,1.556,1.556,0,0,1-1.2-.6L197.981,52.116v50.618a1.5,1.5,0,0,1-3,0Z"
                    />
                    <path
                        fill="#ffffff"
                        d="M235.121,106.329a3.17,3.17,0,0,1-2.7-1.5L199.778,58.705v44.029a3.594,3.594,0,1,1-7.188,0V47.623a3.683,3.683,0,0,1,2.1-3.295,4.05,4.05,0,0,1,2.7,0,3.609,3.609,0,0,1,1.5,1.5l32.048,45.826V47.623a3.594,3.594,0,1,1,7.188,0v55.111A3.022,3.022,0,0,1,235.121,106.329Z"
                    />
                </g>
                <g>
                    <path
                        fill="#ffffff"
                        d="M285.44,49.121a22.985,22.985,0,0,0-10.184,2.1,26.944,26.944,0,0,0-14.077,14.077,23.881,23.881,0,0,0-2.1,10.184,22.985,22.985,0,0,0,2.1,10.184,26.944,26.944,0,0,0,14.077,14.077,23.881,23.881,0,0,0,10.184,2.1,25.427,25.427,0,0,0,11.382-2.7,26.523,26.523,0,0,0,8.986-7.188,1.556,1.556,0,0,1,1.2-.6,1.415,1.415,0,0,1,1.5,1.5,1.1,1.1,0,0,1-.3.9,32.559,32.559,0,0,1-9.884,8.087,27.169,27.169,0,0,1-12.879,3,29.815,29.815,0,0,1-11.382-2.4A29.693,29.693,0,0,1,258.483,86.86a28.67,28.67,0,0,1-2.4-11.382,27.693,27.693,0,0,1,1.2-8.686,28.9,28.9,0,0,1,3.594-7.488,22.647,22.647,0,0,1,5.691-6.29,29.163,29.163,0,0,1,7.188-4.493,19.038,19.038,0,0,1,5.391-1.8,36.7,36.7,0,0,1,5.691-.6,29.523,29.523,0,0,1,11.382,2.1,25.5,25.5,0,0,1,9.285,6.29c.6.6.9.9.9,1.2v.6c0,.9-.6,1.2-1.5,1.2h-.6q-.449,0-.9-.9a31.909,31.909,0,0,0-8.386-5.691A23.792,23.792,0,0,0,285.44,49.121Z"
                    />
                    <path
                        fill="#ffffff"
                        d="M285.44,106.329a31.662,31.662,0,0,1-11.981-2.4,30.286,30.286,0,0,1-16.473-16.473,32.868,32.868,0,0,1-2.4-11.981,31.712,31.712,0,0,1,1.5-9.285,25.306,25.306,0,0,1,3.894-8.087,31.4,31.4,0,0,1,5.99-6.589,26.027,26.027,0,0,1,7.787-4.792,20.879,20.879,0,0,1,5.691-1.8,31.829,31.829,0,0,1,18.271,1.8A29.22,29.22,0,0,1,307.9,53.614c.6.6,1.2,1.2,1.2,1.8a2.7,2.7,0,0,1,.3,1.5,3.143,3.143,0,0,1-3.295,3.295,2.244,2.244,0,0,1-1.5-.3c-.3-.3-.9-.6-1.5-1.2a23.424,23.424,0,0,0-7.787-5.092,25.662,25.662,0,0,0-18.87,0,22.121,22.121,0,0,0-7.488,5.092,23.424,23.424,0,0,0-5.092,7.787,24.407,24.407,0,0,0-1.8,9.285,23.334,23.334,0,0,0,1.8,9.285,22.121,22.121,0,0,0,5.092,7.488,25.512,25.512,0,0,0,7.488,5.092c5.99,2.7,13.778,2.4,20.068-.6a25.606,25.606,0,0,0,8.386-6.589,2.92,2.92,0,0,1,2.7-1.2A3.461,3.461,0,0,1,311.2,92.85a7.619,7.619,0,0,1-.6,2.1,33.68,33.68,0,0,1-10.783,8.686A40.7,40.7,0,0,1,285.44,106.329Z"
                    />
                </g>
                <g>
                    <path
                        fill="#ffffff"
                        d="M353.13,46.126a29.815,29.815,0,0,1,11.382,2.4A29.693,29.693,0,0,1,380.087,64.1a28.67,28.67,0,0,1,2.4,11.382,29.815,29.815,0,0,1-2.4,11.382,29.693,29.693,0,0,1-15.575,15.575,28.23,28.23,0,0,1-22.763,0A29.693,29.693,0,0,1,326.174,86.86a28.67,28.67,0,0,1-2.4-11.382,29.815,29.815,0,0,1,2.4-11.382,29.693,29.693,0,0,1,15.575-15.575A29.815,29.815,0,0,1,353.13,46.126Zm0,3a22.985,22.985,0,0,0-10.184,2.1A26.944,26.944,0,0,0,328.87,65.295a23.881,23.881,0,0,0-2.1,10.184,22.985,22.985,0,0,0,2.1,10.184,26.944,26.944,0,0,0,14.077,14.077,25.78,25.78,0,0,0,20.367,0,26.944,26.944,0,0,0,14.077-14.077,23.881,23.881,0,0,0,2.1-10.184,23.881,23.881,0,0,0-2.1-10.184,26.944,26.944,0,0,0-14.077-14.077A22.985,22.985,0,0,0,353.13,49.121Z"
                    />
                    <path
                        fill="#ffffff"
                        d="M353.13,106.329a31.662,31.662,0,0,1-11.981-2.4,30.286,30.286,0,0,1-16.473-16.473,32.868,32.868,0,0,1-2.4-11.981,31.662,31.662,0,0,1,2.4-11.981A30.286,30.286,0,0,1,341.15,47.024a31.01,31.01,0,0,1,24.261,0A30.286,30.286,0,0,1,381.884,63.5a32.868,32.868,0,0,1,2.4,11.981,31.662,31.662,0,0,1-2.4,11.981,30.286,30.286,0,0,1-16.473,16.473A33.218,33.218,0,0,1,353.13,106.329Zm0-55.111a23.334,23.334,0,0,0-9.285,1.8,22.121,22.121,0,0,0-7.488,5.092,23.424,23.424,0,0,0-5.092,7.787,24.407,24.407,0,0,0-1.8,9.285,23.334,23.334,0,0,0,1.8,9.285,22.121,22.121,0,0,0,5.092,7.488,23.87,23.87,0,0,0,26.357,5.092,23.424,23.424,0,0,0,7.787-5.092,25.512,25.512,0,0,0,5.092-7.488,24.407,24.407,0,0,0,1.8-9.285,23.334,23.334,0,0,0-1.8-9.285,26.925,26.925,0,0,0-5.092-7.787,23.424,23.424,0,0,0-7.787-5.092A25.944,25.944,0,0,0,353.13,51.217Z"
                    />
                </g>
            </g>
        </g>
    </svg>
);
export default CBRIcon;
