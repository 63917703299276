import styles from './Classifiers.module.css';
import ClassifierComponent from './Classifier/Classifier';
import { TicketClassifier } from '../../../../../../../@Types/TicketTypes/Ticket';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../utils/_store';
import { SearchClassifier } from '../ClassifiersMenu';
interface ClassifiersProps {
    /** Function to call when the user wants to change something. */
    change: Function;
    /** Function to change the value of the current classifier bieng classified */
    setClassifyingObj: Function;
    /** List of all the currently classified classifiers in the ticket */
    currentClassifiers: TicketClassifier[];
    /** The roots and classifiers available to display */
    info: { roots: string[]; classifiers: Record<string, SearchClassifier> };
    /** If the user is searching */
    searching: boolean;
    /** the value of current search */
    search: string | undefined;
    /** If the layout is mobile */
    layoutIsMobile?: boolean;
}
function Classifiers({
    change,
    currentClassifiers,
    setClassifyingObj,
    layoutIsMobile,
    info,
    searching,
    search,
}: ClassifiersProps): JSX.Element {
    const siteInfo = useSelector((state: RootState) => state.site);

    //Search, filter only roots with search info.
    if (info.roots.length > 0) {
        const selectedRoots: { idRoot: string; selected: TicketClassifier }[] =
            [];
        const roots = [];
        const selected = [...currentClassifiers];
        //Generate array of selected roots with its selected value and the rest of the roots.
        for (let j = 0; j < info.roots.length; j++) {
            const idRoot = info.roots[j];
            const root = info.classifiers[idRoot];
            let found = false;
            for (let i = 0; i < selected.length; i++) {
                if (selected[i].idRoot === root._id) {
                    found = true;
                    selectedRoots.push({
                        idRoot,
                        selected: selected[i],
                    });
                    selected.slice(i, 1);
                }
            }
            if (!found) {
                roots.push(idRoot);
            }
        }
        return (
            <div
                className={
                    layoutIsMobile ? styles.mobileContainer : styles.container
                }
                data-testid="Classifiers_container"
            >
                {selectedRoots.map(({ idRoot, selected }, index) => {
                    let selectedValue = info.classifiers[selected.idValue];
                    if (!selectedValue) {
                        selectedValue = siteInfo.classifiers[selected.idValue];
                    }
                    if (!selectedValue) {
                        return <div key={idRoot} />;
                    }
                    return (
                        <ClassifierComponent
                            searching={searching}
                            search={search}
                            key={idRoot}
                            last={
                                roots.length === 0 &&
                                index === selectedRoots.length - 1
                            }
                            handleClick={(): void => {
                                setClassifyingObj({
                                    idRoot: selected.idRoot,
                                    idValue: selected.idValue,
                                    current: selectedValue.path
                                        ? selectedValue.path[
                                              selectedValue.path.length - 1
                                          ]
                                        : selected.idRoot,
                                });
                            }}
                            onRemove={(
                                event: React.MouseEvent<
                                    HTMLDivElement,
                                    MouseEvent
                                >
                            ): void => {
                                event.stopPropagation();
                                change({ idRoot, idValue: null });
                            }}
                            classifier={selected}
                            classifiers={info.classifiers}
                        />
                    );
                })}
                {roots.map((idRoot, index) => (
                    <ClassifierComponent
                        searching={searching}
                        search={search}
                        key={idRoot}
                        last={index === roots.length - 1}
                        classifier={{ idRoot, idValue: null }}
                        classifiers={info.classifiers}
                        handleClick={(): void => {
                            setClassifyingObj({
                                idRoot: idRoot,
                                idValue: null,
                                current: idRoot,
                            });
                        }}
                    />
                ))}
            </div>
        );
    } else {
        return (
            <div className={styles.emptyContainer}>No hay clasificadores</div>
        );
    }
}
export default Classifiers;
