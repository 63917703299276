import { useEffect, useRef } from 'react';
import {
    Entity,
    EntityRelationship,
} from '../../../../@Types/EntityTypes/Entity';
import Dialog from '../../../../shared/Dialog/Dialog';
import { EditorConfig } from '../../../../controllers/GenericEditorController/GenericEditorReducer';
import { EntityPropertyMapper } from '../EntityPropertyMappers';
import EntityPropertyTypes from '../../../../constants/EntityPropertyTypes';
import NameProperty from '../../../../shared/EntityRenderer/NameProperty/NameProperty';
import EntityRelationshipPicker from '../../../../shared/EntityRenderer/EntityRelationshipPickerStep/EntityRelationshipPickerStep';
import PropertyMenu from '../PropertyMenu/PropertyMenu';
import Section from '../../../../shared/GenericFormEditor/Section/Section';
import { reset } from '../../../../controllers/GenericEditorController/GenericEditorActions';
import ReducerFunctions from '../EntityReducerFunctions';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../utils/_store';
import { checkAdmin } from '../../../../utils/PermissionsFunctions';
import { IdGenericEditorContext } from '../../../../shared/GenericFormEditor/GenericFormEditor';

const idEditor = 'ENTITY-RELATIONSHIP';

interface RelationshipEditorProps {
    relationship: EntityRelationship;
    onClose: (relationship: EntityRelationship) => void;
}

function RelationshipEditor({
    relationship,
    onClose,
}: RelationshipEditorProps): JSX.Element {
    const entity = useSelector(
        (state: RootState) => state.site.entities[relationship.idEntity]
    );
    const editorInfo = useSelector(
        (state: RootState) => state.genericEditor[idEditor]
    );
    const user = useSelector((state: RootState) => state.site.user);
    const containerRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(
            reset(idEditor, {
                loadData: () => {
                    return relationship as any;
                },
                //Function to edit the initial state
                getInfoOnSuccess: () => {
                    return {
                        firstSection: 'FIRST',
                        sections: {
                            FIRST: {
                                id: 'FIRST',
                                steps: relationship.rootSteps,
                                name: entity.name,
                                nextSection: null,
                            },
                        },
                        steps: relationship.steps,
                        uniqueSteps: {},
                    };
                },
                ...ReducerFunctions,
            } as EditorConfig<Entity, any, any, any, any>)
        );
    }, []);

    return (
        <Dialog
            maxWidth={'90vw'}
            open={true}
            onClose={(): void => {
                onClose({
                    ...relationship,
                    steps: editorInfo.steps,
                    rootSteps:
                        editorInfo.sections[editorInfo.firstSection].steps ??
                        [],
                });
            }}
        >
            <div ref={containerRef}>
                <IdGenericEditorContext.Provider value={idEditor}>
                    <Section<any, any, any, any, any>
                        stepMenuComponent={<PropertyMenu zIndex={1303} />}
                        multiSection={false}
                        editId={checkAdmin(user)}
                        customSteps={{
                            [EntityPropertyTypes.NAME]: {
                                component: NameProperty as any,
                            },
                            [EntityPropertyTypes.ENTITY_RELATIONSHIP]: {
                                component: (props: any): JSX.Element => (
                                    <EntityRelationshipPicker {...props} />
                                ),
                            },
                        }}
                        stepMapper={EntityPropertyMapper}
                        containerRef={containerRef}
                        stepMapperProps={undefined}
                    />
                </IdGenericEditorContext.Provider>
            </div>
        </Dialog>
    );
}
export default RelationshipEditor;
