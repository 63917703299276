import { createAction } from '@reduxjs/toolkit';
import {
    Section,
    FormSize,
    FormStyle,
    Term,
    GBaseStep,
    GForm,
} from '../../@Types/GenericForm';
import {
    EditorConfig,
    GenericEditorError,
    SuccessInfo,
} from './GenericEditorReducer';
import {
    GLocation,
    SectionLocation,
} from '../../@Types/FormTypes/LocationTypes';

export const Types = {
    RESET: 'GENERIC-EDITOR/RESET',
    GET_SUCCESS: 'GENERIC-EDITOR/GET_SUCCESS',
    PREVIEW: 'GENERIC-EDITOR/PREVIEW',
    SETTINGS: 'GENERIC-EDITOR/SETTINGS',
    CONDITIONS: 'GENERIC-EDITOR/CONDITIONS',
    UPDATE_DATA: 'GENERIC-EDITOR/UPDATE_DATA',
    UPDATE_NAME: 'GENERIC-EDITOR/UPDATE_NAME',
    UPDATE_TERMS: 'GENERIC-EDITOR/UPDATE_TERMS',
    UPDATE_STYLE: 'GENERIC-EDITOR/UPDATE_STYLE',
    UPDATE_SIZE: 'GENERIC-EDITOR/UPDATE_SIZE',
    UPDATE_SECTION: 'GENERIC-EDITOR/UPDATE_SECTION',
    CURRENT: 'GENERIC-EDITOR/CURRENT',
    ADD_SECTION: 'GENERIC-EDITOR/ADD_SECTION',
    ADD_STEP: 'GENERIC-EDITOR/ADD_STEP',
    UPDATE_STEP: 'GENERIC-EDITOR/UPDATE_STEP',
    UPDATE_STEPS: 'GENERIC-EDITOR/UPDATE_STEPS',
    COPY_STEP: 'GENERIC-EDITOR/COPY_STEP',
    DELETE_STEP: 'GENERIC-EDITOR/DELETE_STEP',
    PASTE_STEP: 'GENERIC-EDITOR/PASTE_STEP',
    MOVEUP_STEP: 'GENERIC-EDITOR/MOVEUP_STEP',
    UPDATE_UNIQUE_STEPS: 'GENERIC-EDITOR/UPDATE_UNIQUE_STEPS',
    MOVEDOWN_STEP: 'GENERIC-EDITOR/MOVEDOWN_STEP',
    UPDATE_STEP_ID: 'GENERIC-EDITOR/UPDATE_STEP_ID',
    SET_ERROR: 'GENERIC-EDITOR/SET_ERROR',
};

function prepare<Type>(
    idEditor: string,
    payload: Type
): { meta: { idEditor: string }; payload: Type } {
    return {
        meta: { idEditor },
        payload,
    };
}

/**
 * Called when the forms page is first loaded
 */
export const reset = createAction(
    Types.RESET,
    prepare<EditorConfig<GForm<any, any>, GBaseStep, any, GLocation, any>>
);

export const getSuccess = createAction(
    Types.GET_SUCCESS,
    prepare<SuccessInfo<any, any>>
);

/**
 * Called when the id of the currentSection changes
 */
export const setCurrentSection = createAction<string | undefined>(
    Types.CURRENT
);

/**
 * Called when a value of the data needs updating (cant be steps or sections)
 */
export const updateData = createAction<any>(Types.UPDATE_DATA);

/**
 * Called when a new step is added to the current section
 */
export const updateUniqueSteps = createAction<any>(Types.UPDATE_UNIQUE_STEPS);

/**
 * Called when the forms name is updated
 */
export const updateName = createAction<string>(Types.UPDATE_NAME);

/**
 * Called when the terms need to change
 */
export const updateTerms = createAction<Term[]>(Types.UPDATE_TERMS);

/**
 * Called when the style of the form needs to change
 */
export const updateStyle = createAction<FormStyle>(Types.UPDATE_STYLE);

/**
 * Called when size of the form needs to change
 */
export const updateSize = createAction<FormSize>(Types.UPDATE_SIZE);

/**
 * Called when the user needs to update a section
 */
export const updateSection = createAction<Section>(Types.UPDATE_SECTION);
/**
 * Called when a new section is added
 */
export const addSection = createAction<Section>(Types.ADD_SECTION);

/**
 * Called when an error is found
 */
export const setError = createAction<GenericEditorError>(Types.SET_ERROR);
/**
 * Called to toggle the preview
 */
export const setShowPreview = createAction(
    Types.PREVIEW,
    function prepare(showPreview: boolean = true) {
        return { payload: showPreview };
    }
);

/**
 * Called to toggle the settings
 */
export const setShowSettings = createAction(
    Types.SETTINGS,
    function prepare(showSettings: boolean = true) {
        return {
            payload: showSettings,
        };
    }
);

/**
 * Called when the step in the given location needs to be updated
 */
export const updateStep = createAction<{
    step: GBaseStep;
    location: SectionLocation | GLocation;
}>(Types.UPDATE_STEP);

/**
 * Called when multiple the steps are updated
 */
export const updateSteps = createAction<{
    steps: Record<string, GBaseStep>;
    location: SectionLocation | GLocation;
}>(Types.UPDATE_STEPS);

/**
 * Called when a new step is added to a location
 */
export const addStep = createAction<{
    step: GBaseStep;
    location: SectionLocation | GLocation;
}>(Types.ADD_STEP);

/**
 * Called when the user wants to copy a step with the given id
 */
export const copyStep = createAction<{
    idStep: string;
    location: SectionLocation | GLocation;
}>(Types.COPY_STEP);

/**
 * Called when the user wants to delete a step in a given location
 */
export const deleteStep = createAction<{
    idStep: string;
    location: SectionLocation | GLocation;
}>(Types.DELETE_STEP);

/**
 * Called when the user wants to paste a step in a given location
 */
export const pasteStep = createAction<SectionLocation | GLocation>(
    Types.PASTE_STEP
);

/**
 * Called when the user wants to the step in the given location up
 */
export const moveStepUp = createAction<SectionLocation | GLocation>(
    Types.MOVEUP_STEP
);
/**
 * Called when the user wants to move the step in the given location down
 */
export const moveStepDown = createAction<SectionLocation | GLocation>(
    Types.MOVEDOWN_STEP
);

/**
 * Called when the user wants to change the id of a step
 */
export const updateStepId = createAction<{
    idStep: string;
    newId: string;
    location: SectionLocation | GLocation;
}>(Types.UPDATE_STEP_ID);
