import { MenuItem } from '@material-ui/core';
import NotificationRecipientTypes from '../../../../../../constants/Flows/NotificationRecipientTypes';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import RoundedSelect, {
    RoundedSelectProps,
} from '../../../../../../shared/RoundedSelect/RoundedSelect';
import styles from './RecipientTypeSelector.module.css';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import WhatsappIcon from '../../../../../../Icons/WhatsappIcon';

interface RecipientTypeSelectorProps
    extends Omit<RoundedSelectProps, 'handleUpdate' | 'value'> {
    /** Currently selected clientProperty id */
    value: NotificationRecipientTypes;
    /** function called when value changes */
    handleUpdate: (type: NotificationRecipientTypes) => void;
    /** If user can pick company values */
    pickCompany?: boolean;
}
const menuStyle = {
    paddingLeft: 0,
};

function RecipientTypeSelector({
    value,
    pickCompany = false,
    handleUpdate,
    ...others
}: RecipientTypeSelectorProps): JSX.Element {
    return (
        <RoundedSelect
            value={value}
            paddingLeft={0}
            handleUpdate={(event): void => {
                const value = event.target.value ?? '';
                handleUpdate(value);
            }}
            {...others}
        >
            <MenuItem
                style={menuStyle}
                value={NotificationRecipientTypes.EMAIL}
            >
                <div className={styles.property}>
                    <div className={styles.mailIcon}>
                        <MailOutlineRoundedIcon fontSize="inherit" />
                    </div>
                    Correo
                </div>
            </MenuItem>
            <MenuItem
                style={menuStyle}
                value={NotificationRecipientTypes.WHATSAPP}
            >
                <div className={styles.property}>
                    <div className={styles.whatsappIcon}>
                        <WhatsappIcon
                            fill="var(--secondary)"
                            style={{
                                height: 32,
                                width: 32,
                            }}
                        />
                    </div>
                    Whatsapp
                </div>
            </MenuItem>
            <MenuItem
                style={menuStyle}
                value={NotificationRecipientTypes.CLIENT}
            >
                <div className={styles.property}>
                    <div className={styles.icon}>
                        <div className={styles.clientContainer}>
                            <PersonRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                    Cliente
                </div>
            </MenuItem>
            {pickCompany && (
                <MenuItem
                    style={menuStyle}
                    value={NotificationRecipientTypes.COMPANY}
                >
                    <div className={styles.property}>
                        <div className={styles.icon}>
                            <img
                                className={styles.companyIcon}
                                src="/media/icons/bluehandshake.svg"
                            />
                        </div>
                        Empresa
                    </div>
                </MenuItem>
            )}
        </RoundedSelect>
    );
}
export default RecipientTypeSelector;
