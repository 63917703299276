import { IndividualVisualization } from '../../@Types/Metabase';
import axiosInstance from '../../AxiosAPI';

/**
 * Fetch the metabase card with de name specified by parameter, and put the params in the jwt
 * @param name
 * @param params
 * @returns iframe url.
 */
export const fetchCardByName = async (
    name: string,
    params: Record<string, any>
): Promise<string> => {
    const cardLink = await axiosInstance.post(
        '/metabase/cards/name/' + name,
        params
    );
    return cardLink.data;
};

/**
 * Fetch the metabase public visualizations.
 * @param idProject
 * @returns list of visualizations.
 */
export const fetchVisualizationsTabs = async (
    idProject: string
): Promise<IndividualVisualization[]> => {
    const visualizations = await axiosInstance.get(
        '/metabase/visualizations/' + idProject
    );
    return visualizations.data;
};

/**
 * Fetch the.
 * @param idProject
 * @returns list of visualizations.
 */
export const fetchVisualization = async (
    idVisualization: number,
    type: 'dashboard' | 'card' | 'GenericDashboard',
    idProject: string
): Promise<string> => {
    let params = { idProject: idProject };

    const route =
        type === 'dashboard' ? '/metabase/dashboards/' : '/metabase/cards/';
    let url = await axiosInstance.post(route + idVisualization, params);
    return url.data;
};

export default {
    fetchCardByName,
    fetchVisualizationsTabs,
    fetchVisualization,
};
