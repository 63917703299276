export enum SurveyStepTypes {
    RATING = 'RATING',
    TITLE = 'TITLE',
    TEXTAREA = 'TEXTAREA',
    DATEPICKER = 'DATEPICKER',
    TIMEPICKER = 'TIMEPICKER',
    FILEUPLOAD = 'FILEUPLOAD',
    TEXTINPUT = 'TEXTINPUT',
    SEPARATOR = 'SEPARATOR',
    SELECTOR = 'SELECTOR',
    CLASSIFIER_SELECTOR = 'CLASSIFIER_SELECTOR',
    CHECKBOX = 'CHECKBOX',
}

export default SurveyStepTypes;
