import React, { useEffect } from 'react';
import {
    Route,
    Switch,
    useRouteMatch,
    withRouter,
    match,
    RouteProps,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    OrganizationSideBarConfig,
    ProjectSideBarConfig,
} from './constants/SidebarConfigs';
import { changeProject } from './controllers/_SiteController/SiteActions';
import Navbar from './shared/Navbar/Navbar';
import SideBar from './shared/Sidebar/Sidebar';
import HomePage from './pages/Home/HomePage';
import FlowsPage from './pages/Flows/FlowsPage';
import AgentsPage from './pages/Agents/AgentsPage';
import TicketsPage from './pages/Tickets/TicketsPage';
import AreasPage from './pages/Areas/AreasPage';
import ClassifiersPage from './pages/Classifiers/ClassifiersPage';
import { RootState } from './utils/_store';
import ProfileDetail from './pages/Profile/ProfileDetail';
import Analytics from './pages/Analytics/Analytics';
import EntriesPage from './pages/Entries/EntriesPage';
import CompaniesPage from './pages/Companies/CompaniesPage';
import FormPage from './pages/Forms/Form';
import EntitiesPage from './pages/Entities/EntitiesPage';
import OrganizationPage from './pages/Organization/OrganizationPage';
import EntityEditor from './pages/Entities/EntityEditor/EntityEditor';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
    OrganizationPermissions,
    ProjectPermissions,
} from './constants/Permissions';
import PageNotFound from './pages/404/PageNotFound';
import {
    checkAdmin,
    checkOrgPerm,
    checkProjPerm,
} from './utils/PermissionsFunctions';
import SurveysPage from './pages/Surveys/SurveysPage';
import SurveyEditor from './pages/Surveys/SurveyEditor/SurveyEditor';
import ConversationEditor from './pages/Conversations/ConversationEditor/ConversationEditor';
import ClientsPage from './pages/Clients/ClientsPage';
import EntityValuesPage from './pages/EntityValues/EntityValuesPage';
import TemplatesPage from './pages/Templates/TemplatesPage';
import DraftEditor from './pages/DraftEditor/DraftEditor';
import { NotificationsMenu } from './shared/Navbar/Notifications/NotificationsMenu/NotificationsMenu';

import ConversationTemplatesEditor from './pages/Conversations/ConversationTemplates/ConversationTemplates';
export interface RouterProps {
    mobile: boolean;
}

function Router({ mobile }: RouterProps): JSX.Element {
    return (
        <React.Fragment>
            <Navbar mobile={mobile} />
            <div className="app">
                <Switch>
                    <Route exact path="/">
                        <HomePage mobile={mobile} />
                    </Route>
                    <Route path="/draft">
                        <DraftEditor />
                    </Route>
                    <Route
                        path={[
                            '/organization',
                            '/agents',
                            '/clients',
                            '/companies',
                            '/entities',
                        ]}
                    >
                        <OrganizationRouter mobile={mobile} />
                    </Route>
                    <Route path={'/profile'}>
                        <ProfileDetail />
                    </Route>
                    <Route path={'/notifications'}>
                        <NotificationsMenu isPage={true} />
                    </Route>
                    <Route path="/:idProject">
                        <ProjectRouter mobile={mobile} />
                    </Route>
                </Switch>
            </div>
        </React.Fragment>
    );
}
interface OrganizationRouterProps {
    mobile: boolean;
}
/** Handles the routes that are part of the org. */
function OrganizationRouter({ mobile }: OrganizationRouterProps): JSX.Element {
    const user = useSelector((state: RootState) => state.site.user);
    const org = useSelector((state: RootState) => state.site.organization);
    return (
        <React.Fragment>
            <SideBar
                config={OrganizationSideBarConfig}
                absoluteRouting={true}
                mobile={mobile}
            />
            <Switch>
                <PrivateRoute
                    path={'/organization'}
                    allowed={checkOrgPerm(
                        user,
                        org,
                        OrganizationPermissions.VIEW
                    )}
                >
                    <OrganizationPage />
                </PrivateRoute>
                <PrivateRoute
                    path={'/agents/:idAgent?'}
                    allowed={checkOrgPerm(
                        user,
                        org,
                        OrganizationPermissions.AGENTS
                    )}
                >
                    <AgentsPage />
                </PrivateRoute>
                <PrivateRoute
                    path={'/clients/:idClient?'}
                    allowed={checkOrgPerm(
                        user,
                        org,
                        OrganizationPermissions.CLIENTS
                    )}
                >
                    <ClientsPage />
                </PrivateRoute>
                <PrivateRoute
                    path={'/companies/:idCompany?'}
                    allowed={checkOrgPerm(
                        user,
                        org,
                        OrganizationPermissions.COMPANIES
                    )}
                >
                    <CompaniesPage />
                </PrivateRoute>
                <PrivateRoute
                    path={'/entities/edit/:idEntity'}
                    allowed={checkAdmin(user)}
                >
                    <EntityEditor mobile={mobile} />
                </PrivateRoute>
                <PrivateRoute
                    path={'/entities/edit'}
                    allowed={checkAdmin(user)}
                >
                    <EntitiesPage />
                </PrivateRoute>
                <Route path={'/entities/:idEntity/:idEntityValue?'}>
                    <EntityValuesPage />
                </Route>
            </Switch>
        </React.Fragment>
    );
}

interface IdProjectRouteParam {
    /** Current Project */
    idProject: string;
}

interface ProjectProps {
    match: match<IdProjectRouteParam>;
    mobile: boolean;
}

/**
 * Handles the routes that are part of the current project
 * @param match: current url state
 * @param mobile a boolean that indicates if the page is mobile
 * @returns If idProject is not valid this returns and empty div
 */
function Project({ match, mobile }: ProjectProps): JSX.Element {
    let { path } = useRouteMatch();
    const user = useSelector((state: RootState) => state.site.user);
    const idProject = useSelector((state: RootState) => state.site.idProject);
    const dispatch = useDispatch();
    useEffect(() => {
        if (idProject !== match.params.idProject) {
            dispatch(changeProject({ idProject: match.params.idProject }));
        }
    }, [idProject, match.params.idProject]);
    if (idProject) {
        return (
            <React.Fragment>
                <SideBar config={ProjectSideBarConfig} mobile={mobile} />
                <Switch>
                    <PrivateRoute
                        allowed={checkProjPerm(
                            user,
                            idProject,
                            ProjectPermissions.VIEW
                        )}
                        path={`${path}/tickets/:idTicket?`}
                    >
                        <TicketsPage mobile={mobile} />
                    </PrivateRoute>
                    <PrivateRoute
                        allowed={
                            checkProjPerm(
                                user,
                                idProject,
                                ProjectPermissions.EDIT_TEMPLATES
                            ) ||
                            checkProjPerm(
                                user,
                                idProject,
                                ProjectPermissions.EDIT_PROJECT
                            )
                        }
                        path={`${path}/templates/:idTemplate?`}
                    >
                        <TemplatesPage />
                    </PrivateRoute>
                    <PrivateRoute
                        path={`${path}/analytics`}
                        allowed={checkProjPerm(
                            user,
                            idProject,
                            ProjectPermissions.ANALYTICS
                        )}
                    >
                        <Analytics mobile={mobile} />
                    </PrivateRoute>
                    <PrivateRoute
                        path={`${path}/flows/:idFlow`}
                        allowed={checkProjPerm(
                            user,
                            idProject,
                            ProjectPermissions.EDIT_PROJECT
                        )}
                    >
                        <FlowsPage mobile={mobile} />
                    </PrivateRoute>
                    <PrivateRoute
                        path={`${path}/entries/:apiKey/form`}
                        allowed={checkProjPerm(
                            user,
                            idProject,
                            ProjectPermissions.EDIT_PROJECT
                        )}
                    >
                        <FormPage mobile={mobile} />
                    </PrivateRoute>
                    <PrivateRoute
                        path={`${path}/entries/:apiKey/conversation`}
                        allowed={checkProjPerm(
                            user,
                            idProject,
                            ProjectPermissions.EDIT_PROJECT
                        )}
                    >
                        <DndProvider backend={HTML5Backend}>
                            <ConversationEditor mobile={mobile} />
                        </DndProvider>
                    </PrivateRoute>
                    <PrivateRoute
                        path={`${path}/entries/:apiKey/templates`}
                        allowed={checkProjPerm(
                            user,
                            idProject,
                            ProjectPermissions.EDIT_PROJECT
                        )}
                    >
                        <ConversationTemplatesEditor mobile={mobile} />
                    </PrivateRoute>
                    <PrivateRoute
                        path={`${path}/entries/:apiKey?`}
                        allowed={checkProjPerm(
                            user,
                            idProject,
                            ProjectPermissions.EDIT_PROJECT
                        )}
                    >
                        <EntriesPage />
                    </PrivateRoute>
                    <PrivateRoute
                        path={`${path}/areas`}
                        allowed={checkProjPerm(
                            user,
                            idProject,
                            ProjectPermissions.EDIT_PROJECT
                        )}
                    >
                        <AreasPage />
                    </PrivateRoute>
                    <PrivateRoute
                        path={`${path}/classifiers`}
                        allowed={checkProjPerm(
                            user,
                            idProject,
                            ProjectPermissions.EDIT_PROJECT
                        )}
                    >
                        <ClassifiersPage />
                    </PrivateRoute>
                    <PrivateRoute
                        path={`${path}/surveys/:idSurvey/edit`}
                        allowed={checkProjPerm(
                            user,
                            idProject,
                            ProjectPermissions.EDIT_PROJECT
                        )}
                    >
                        <SurveyEditor />
                    </PrivateRoute>
                    <PrivateRoute
                        path={`${path}/surveys`}
                        allowed={checkProjPerm(
                            user,
                            idProject,
                            ProjectPermissions.EDIT_PROJECT
                        )}
                    >
                        <SurveysPage />
                    </PrivateRoute>
                    <PrivateRoute path={`${path}/draft`} allowed={true}>
                        <DraftEditor />
                    </PrivateRoute>
                </Switch>
            </React.Fragment>
        );
    } else if (idProject === undefined) {
        return <PageNotFound />;
    } else {
        return <div></div>;
    }
}
const ProjectRouter = withRouter((props: any) => <Project {...props} />);

export default Router;

interface PrivateRouteProps extends RouteProps {
    allowed: boolean;
    children: any;
}
function PrivateRoute({
    children,
    allowed,
    ...others
}: PrivateRouteProps): JSX.Element {
    if (allowed) {
        return <Route {...others}>{children}</Route>;
    } else {
        return <PageNotFound />;
    }
}
