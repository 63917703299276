import { IconProps } from './@IconTypes';
import { JSX } from 'react';

function PendingEmailIcon({ className, style, fill }: IconProps): JSX.Element {
    return (
        <svg
            fill={fill}
            style={style}
            className={className}
            version="1.1"
            viewBox="0 0 17 14"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.375 4.5C15.4105 4.5 16.25 3.66053 16.25 2.625C16.25 1.58947 15.4105 0.75 14.375 0.75C13.3395 0.75 12.5 1.58947 12.5 2.625C12.5 3.66053 13.3395 4.5 14.375 4.5ZM15.5 11.35V5.80796C15.1481 5.93233 14.7695 6 14.375 6C14.2483 6 14.1231 5.99301 14 5.9794V11.35C14 11.735 13.7059 12 13.4 12H2.6C2.29411 12 2 11.735 2 11.35V4.46074L6.33461 9.2322C7.22743 10.2149 8.77258 10.2149 9.66537 9.2322L12.9063 5.66457C12.4479 5.44262 12.0471 5.11999 11.733 4.72537L8.55515 8.22353C8.25755 8.55112 7.74245 8.55112 7.44485 8.22353L2.69953 3H11.0206C11.007 2.87687 11 2.75175 11 2.625C11 2.23054 11.0676 1.85188 11.1921 1.5H2.6C1.89838 1.5 1.2851 1.85192 0.908247 2.37594C0.650555 2.73425 0.5 3.17624 0.5 3.65V11.35C0.5 12.5115 1.41473 13.5 2.6 13.5H13.4C14.5853 13.5 15.5 12.5115 15.5 11.35Z"
            />
        </svg>
    );
}

export default PendingEmailIcon;
