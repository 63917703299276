export enum FormStyleTypes {
    MATERIAL = 'MATERIAL',
}

export enum FormStepTypes {
    TITLE = 'TITLE',
    TIMEPICKER = 'TIMEPICKER',
    TEXTAREA = 'TEXTAREA',
    DATEPICKER = 'DATEPICKER',
    FILEUPLOAD = 'FILEUPLOAD',
    TEXTINPUT = 'TEXTINPUT',
    SEPARATOR = 'SEPARATOR',
    SELECTOR = 'SELECTOR',
    CLASSIFIER_SELECTOR = 'CLASSIFIER_SELECTOR',
    CHECKBOX = 'CHECKBOX',
    AGENTPICKER = 'AGENTPICKER',
    CLIENTPICKER = 'CLIENTPICKER',
    COMPANYPICKER = 'COMPANYPICKER',
    RATING = 'RATING',
    MAPPER = 'MAPPER',
    ENTITYVALUEPICKER = 'ENTITYVALUEPICKER',
    API_SELECTOR = 'API_SELECTOR',
    COLLAPSIBLE = 'COLLAPSIBLE',
}

export default FormStepTypes;

export enum RatingTypes {
    SCALE = 'SCALE',
    SATISFACTION = 'SATISFACTION',
    LIKE = 'LIKE',
}

export enum EntityValueFilterTypes {
    STEP = 'STEP',
    VALUE = 'VALUE',
    INTEGRATION = 'INTEGRATION',
    ENTITY_RELATIONSHIP = 'ENTITY_RELATIONSHIP',
}

export enum EntityValuePathTypes {
    STEP = 'STEP',
    VALUE = 'VALUE',
}

export enum ApiSelectorParamTypes {
    STEP = 'STEP',
    VALUE = 'VALUE',
}
