import styles from './TitleStep.module.css';
import RoundedTextField from '../../RoundedTextField/RoundedTextField';
import { GenericStepProps } from '../../GenericFormEditor/Step/Step';
import { GTitle } from '../../../@Types/GenericFormSteps';
import { GLocation } from '../../../@Types/FormTypes/LocationTypes';
import React from 'react';
function TitleStep<
    StepType extends GTitle,
    UniqueType,
    LocationType extends GLocation
>({
    editing,
    step,
    updateStep,
}: GenericStepProps<StepType, UniqueType, LocationType>): JSX.Element {
    if (editing) {
        return (
            <div className={styles.editingContainer}>
                <RoundedTextField
                    label="Titulo"
                    style={{ marginBottom: 15 }}
                    value={step.title ?? ''}
                    onChange={(e): void => {
                        updateStep({ ...step, title: e.target.value });
                    }}
                    height="auto"
                    fontSize="24px"
                    fontWeight={400}
                ></RoundedTextField>
                <RoundedTextField
                    label="Descripción"
                    value={step.description ?? ''}
                    onChange={(e): void => {
                        updateStep({ ...step, description: e.target.value });
                    }}
                    multiline
                    minRows={3}
                ></RoundedTextField>
            </div>
        );
    } else {
        return (
            <div className={styles.container} style={{ width: '900px' }}>
                <div className={styles.titleLbl}>{step.title}</div>
                {step.description && (
                    <p
                        className={styles.descriptionPar}
                        style={{
                            margin: step.title ? '10px 0px' : '0px 0px 5px 0px',
                        }}
                    >
                        {step.description}
                    </p>
                )}
            </div>
        );
    }
}

export default React.memo(TitleStep, (prev, next) => {
    const { step, stepEqualityChecker } = prev;
    const { step: newStep } = next;
    if (
        step.title !== newStep.title ||
        step.description !== newStep.description
    ) {
        return false;
    }

    return stepEqualityChecker?.(prev, next) ?? true;
}) as typeof TitleStep;
