import styles from './Classifier.module.css';
import { useState } from 'react';
import TrendingFlatRoundedIcon from '@material-ui/icons/TrendingFlatRounded';
import NotInterestedRoundedIcon from '@material-ui/icons/NotInterestedRounded';
import React from 'react';
import ClassifierPill from '../../../../../../../shared/ClassifierPill/ClassifierPill';
import { Classification } from '../../../../../../../@Types/Action';

function Classifier({
    idOriginal,
    idValue,
    idRoot,
}: Classification): JSX.Element {
    const [hovering, setHovering] = useState(false);

    if (idValue === null) {
        if (idOriginal === null) {
            return <div></div>;
        }
        return (
            <div className={styles.container}>
                <div
                    className={styles.pillContainer}
                    onMouseEnter={(): void => {
                        setHovering(true);
                    }}
                    onMouseLeave={(): void => {
                        setHovering(false);
                    }}
                >
                    <ClassifierPill
                        idRoot={idRoot}
                        idValue={idOriginal}
                        showPath={true}
                    />
                    <div
                        className={styles.trashContainer}
                        style={{
                            backgroundColor: hovering
                                ? 'transparent'
                                : 'rgba(128, 128, 128, 0.7)',
                            width: hovering ? '20px' : '100%',
                            marginLeft: hovering ? 'calc(50% - 10px)' : '0px',
                        }}
                    >
                        <NotInterestedRoundedIcon fontSize="inherit" />
                    </div>
                </div>
            </div>
        );
    } else {
        if (idOriginal !== null) {
            return (
                <div className={styles.container}>
                    <div className={styles.pillContainer}>
                        <ClassifierPill
                            idRoot={idRoot}
                            idValue={idOriginal}
                            showPath={true}
                        />
                    </div>
                    <div className={styles.arrowContainer}>
                        <TrendingFlatRoundedIcon fontSize="inherit" />
                    </div>
                    <div className={styles.pillContainer}>
                        <ClassifierPill
                            idRoot={idRoot}
                            idValue={idValue}
                            showPath={true}
                        />
                    </div>
                </div>
            );
        } else {
            return (
                <div className={styles.container}>
                    <div className={styles.pillContainer}>
                        <ClassifierPill
                            idRoot={idRoot}
                            idValue={idValue}
                            showPath={true}
                        />
                    </div>
                </div>
            );
        }
    }
}
export default Classifier;
