import React from 'react';
import { useDispatch } from 'react-redux';
import StepTypes from '../../../../../constants/Conversations/ConversationStepTypes';
import { StepLocation } from '../../../../../controllers/ConversationEditorController/ConversationEditorReducer';
import { createConversationStep } from '../../StepFunctions';
import { addStep } from '../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import TitleIcon from '@material-ui/icons/Title';
import LinkIcon from '../../../../../Icons/LinkIcon';
import InfoIcon from '../../../../../Icons/InfoIcon';
import EurekaElementMenuElement from '../../../../../shared/@Menus/ElementMenu/EurekaElementMenuElement';
import EurekaElementMenuGroup from '../../../../../shared/@Menus/ElementMenu/EurekaElementMenuGroup';
import ImageIcon from '../../../../../Icons/ImageIcon';
import VideoIcon from '../../../../../Icons/VideoIcon';
import AudioIcon from '../../../../../Icons/AudioIcon';
import StickerIcon from '../../../../../Icons/StickerIcon';
import FileIcon from '../../../../../Icons/FileIcon';
interface InfoStepMenuProps {
    location: StepLocation;
    handleClose: Function;
}
function InfoStepMenu({
    location,
    handleClose,
}: InfoStepMenuProps): JSX.Element {
    const dispatch = useDispatch();

    const handleAdd = (type: StepTypes): void => {
        dispatch(addStep({ step: createConversationStep(type), location }));
        handleClose();
    };

    return (
        <React.Fragment>
            <EurekaElementMenuGroup
                icon={InfoIcon}
                label="Informativo"
                renderElements={(props): JSX.Element => (
                    <>
                        <EurekaElementMenuElement
                            {...props}
                            Icon={TitleIcon}
                            Label={'Texto'}
                            onClick={(): void => {
                                handleAdd(StepTypes.INFO_TEXT_STEP);
                            }}
                        />
                        <EurekaElementMenuElement
                            {...props}
                            Icon={ImageIcon}
                            Label={'Imagen'}
                            onClick={(): void => {
                                handleAdd(StepTypes.INFO_IMAGE_STEP);
                            }}
                        />
                        <EurekaElementMenuElement
                            {...props}
                            Icon={FileIcon}
                            Label={'Documento'}
                            onClick={(): void => {
                                handleAdd(StepTypes.INFO_DOCUMENT_STEP);
                            }}
                        />
                        <EurekaElementMenuElement
                            {...props}
                            Icon={LinkIcon}
                            Label={'Enlace'}
                            onClick={(): void => {
                                handleAdd(StepTypes.LINK_STEP);
                            }}
                        />
                        <EurekaElementMenuElement
                            {...props}
                            Icon={VideoIcon}
                            Label={'Vídeo'}
                            onClick={(): void => {
                                handleAdd(StepTypes.INFO_VIDEO_STEP);
                            }}
                        />
                        <EurekaElementMenuElement
                            {...props}
                            Icon={AudioIcon}
                            Label={'Audio'}
                            onClick={(): void => {
                                handleAdd(StepTypes.INFO_AUDIO_STEP);
                            }}
                        />
                        <EurekaElementMenuElement
                            {...props}
                            Icon={StickerIcon}
                            Label={'Sticker'}
                            onClick={(): void => {
                                handleAdd(StepTypes.INFO_STICKER_STEP);
                            }}
                        />
                    </>
                )}
                style={{
                    fontSize: 18,
                }}
                zIndex={1303}
            />
        </React.Fragment>
    );
}

export default InfoStepMenu;
