import React from 'react';
import { CBRElementStep as ElementStepType } from '../../../../../../@Types/ConversationTypes/CBRConversationStep';
import ListApiStep from '../../Collection/ListApiStep/ListApiStep';
import CBRStepTypes from '../../../../../../constants/Construction/CBRFormStepTypes';
import styles from './CBRElementStep.module.css';
import {
    addStep,
    deleteStep,
    updateStep,
} from '../../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import { useDispatch } from 'react-redux';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import { createCBRConversationStep } from '../../../AddStep/StepMenu/CBRStepMenu/CBRStepFunctions';
import { ContentState, EditorState } from 'draft-js';

interface CBRElementStepProps {
    step: ElementStepType;
}

function CBRElementStep({ step }: CBRElementStepProps): JSX.Element {
    const dispatch = useDispatch();
    return (
        <React.Fragment>
            <ListApiStep step={step as any} />
            <div className={styles.subStepContainer}>
                <div className={styles.subStepLbl}>SubElemento:</div>
                {!step.subStep && (
                    <div
                        className={styles.addSubElement}
                        onClick={(): void => {
                            const newStep = createCBRConversationStep(
                                CBRStepTypes.CBR_LOCATIVAS
                            ) as any;
                            newStep.message = EditorState.createWithContent(
                                ContentState.createFromText(
                                    'Selecciona el elemento hijo'
                                )
                            );
                            dispatch(
                                updateStep({
                                    ...step,
                                    subStep: newStep.id,
                                } as any)
                            );
                            dispatch(
                                addStep({
                                    step: newStep,
                                })
                            );
                        }}
                    >
                        <div className={styles.addIconContainer}>
                            <AddRoundedIcon fontSize="inherit" />
                        </div>
                        <div className={styles.addSubElementLbL + ' noselect'}>
                            Agregar SubElemento
                        </div>
                    </div>
                )}
                {step.subStep && (
                    <div
                        className={styles.addSubElement}
                        onClick={(): void => {
                            if (step.subStep) {
                                dispatch(deleteStep(step.subStep));
                                dispatch(
                                    updateStep({
                                        ...step,
                                        subStep: null,
                                    } as any)
                                );
                            }
                        }}
                    >
                        <div className={styles.addIconContainer}>
                            <RemoveRoundedIcon fontSize="inherit" />
                        </div>
                        <div className={styles.addSubElementLbL + ' noselect'}>
                            Borrar SubElemento
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

export default CBRElementStep;
