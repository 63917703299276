import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select, { SelectProps } from '@material-ui/core/Select';
import { CircularProgress, FormHelperText, MenuItem } from '@material-ui/core';
import GenericMenu, {
    GenericMenuProps,
} from '../../@Menus/GenericMenu/GenericMenu';

interface StyleProps {
    /** The color of the outline when selected and hovered on */
    focusColor?: string;
    /** The color of the outline when it is not selected */
    outlineColor?: string;
    /** The color of the background  */
    backgroundColor?: string;
    /** The color of the error to display */
    errorColor?: string;
    /** If the style should change on hover */
    cantEdit?: boolean;
    /** The hight of the container */
    height?: string;
    /** The color of the text in the form */
    color?: string;
    /** material ui style seed */
    seed?: string;
    /** Boolean if menu should be kept open even on close */
    keepOpen?: boolean;
}
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const useOutlinedInputStyles = (props: StyleProps): Function =>
    makeStyles({
        root: {
            cursor: props.cantEdit ? 'default' : 'default',
            height: props.height,
            backgroundColor: props.backgroundColor,
            '& $notchedOutline': {
                borderColor: props.outlineColor,
            },
            '&:hover $notchedOutline': {
                borderColor: props.cantEdit
                    ? props.outlineColor
                    : props.focusColor,
            },
            '& .Mui-disabled': {
                cursor: 'default',
                color: props.color,
            },
            '& .Mui-error': {
                color: props.errorColor,
            },
            [`&.Mui-error .${props.seed}MuiOutlinedInput-notchedOutline`]: {
                borderColor: props.outlineColor,
            },
            '&$focused $notchedOutline': {
                borderColor: props.focusColor,
            },
            [`& .${props.seed}MuiSelect-icon.Mui-disabled`]: {
                color: props.outlineColor,
            },
            [`& .${props.seed}MuiSelect-iconOpen`]: {
                transform: 'none',
            },
            borderRadius: 10,
            color: props.color,
        },
        focused: {},
        notchedOutline: {},
    });

const useLabelInputStyles = (props: StyleProps): Function =>
    makeStyles(() => ({
        focused: {
            color: props.focusColor + ' !important',
        },
        root: {
            marginTop: props.height != '40px' ? '-4px' : '0px',
            [`&.${props.seed}MuiInputLabel-shrink`]: {
                marginTop: '0px',
            },
            '& .Mui-error': {
                color: props.errorColor,
            },
            '&.Mui-error': {
                color: props.errorColor,
            },
        },
    }));

const useHelperTextStyles = (props: StyleProps): Function =>
    makeStyles(() => ({
        root: {
            '&.Mui-error': {
                color: props.errorColor,
            },
        },
    }));

const useMenuStyles = (props: StyleProps): Function =>
    makeStyles(() => ({
        root: {
            [`& .${props.seed}MuiPaper-root`]: {
                backgroundColor: 'black',
                display: 'none',
            },
        },
    }));

export interface RoundedGenericPickerProps<Type, InitialType = string>
    extends Omit<SelectProps, 'color'>,
        StyleProps {
    ref?: any;
    idMenu?: string;
    /** Currently selected value */
    value: Type[] | InitialType[];
    /** function called when value changes */
    handleUpdate?: (elements: Type[], firstLoad?: boolean) => void;
    /** Strig to place in the label */
    label: string;
    /** Function used to get the element's unique key */
    calcId?: (element: Type) => string;
    /** Function to calc element label */
    calcLbl: (element: Type) => string;
    /** Function to calc if element was deleted */
    calcIsDeleted?: (element: Type) => boolean;
    /** Function to calc element Icon  */
    calcIcon?: (element: Type) => JSX.Element;
    /** Function to calc if should load by initial value */
    isInitialType?: (
        elements: InitialType[] | Type[],
        firstTime?: boolean
    ) => elements is InitialType[];
    /** The name of the generic element */
    elementLabel: string;
    /** The name of multiple generic elements */
    pluralLabel?: string;
    /** Minimum width in px of the component */
    minWidth?: number;
    /** The helper Text to display */
    helperText?: string;
    /** the margin around the selector */
    containerMargin?: string;
    /** If the select displays more than one element*/
    multiple?: boolean;
    /** IconComponent */
    IconComponent?: React.ElementType<any>;
    /** If the app is mobile */
    mobile?: boolean;
    /** async function to call to load elements by their initial value */
    loadInitialType: (values: InitialType[]) => Promise<Type[]>;
    /** async function to call to load a list of elements */
    loadElements: (
        page: number,
        pageSize: number,
        search: string | undefined
    ) => Promise<Type[]>;
    /** Function called to view the selected element */
    handleView?: (element: Type) => void;
    /** Function called to config the selected element */
    handleConfig?: (element: Type) => void;
    canChangeOrder?: boolean;
    customMenuButtons?: () => JSX.Element;
    handleConfirm?: GenericMenuProps<Type, InitialType>['handleConfirm'];
    /** Label to display if Empty*/
    emptyLabel?: string;
}

function CustomSelect<Type, InitialType>({
    value = [],
    handleUpdate,
    label,
    idMenu,
    calcLbl,
    calcIcon,
    calcIsDeleted,
    elementLabel,
    pluralLabel = elementLabel + 's',
    minWidth,
    helperText,
    color = 'var(--accent)',
    errorColor = 'var(--error)',
    focusColor = 'var(--secondary)',
    outlineColor = 'var(--outlineGrey)',
    backgroundColor = 'var(--primary1)',
    cantEdit = false,
    containerMargin = '0px',
    seed = 'Erk-',
    height = '31px',
    required,
    error,
    handleView,
    handleConfig,
    loadInitialType,
    multiple = false,
    mobile = false,
    IconComponent,
    loadElements,
    calcId = (element: any): string => element._id,
    isInitialType = (
        elements: InitialType[] | Type[]
    ): elements is InitialType[] => typeof elements[0] === 'string',
    keepOpen = false,
    customMenuButtons,
    handleConfirm,
    canChangeOrder,
    emptyLabel,
    ...others
}: RoundedGenericPickerProps<Type, InitialType>): JSX.Element {
    const classes = useStyles();
    const StyleProps = {
        focusColor,
        outlineColor,
        backgroundColor,
        errorColor,
        cantEdit,
        color,
        seed,
        height,
    };
    const outlinedInputClasses = useOutlinedInputStyles(StyleProps)();
    const labelClasses = useLabelInputStyles(StyleProps)();
    const helperTextClasses = useHelperTextStyles(StyleProps)();
    const menuClasses = useMenuStyles(StyleProps)();
    const inputLabel = React.useRef<HTMLLabelElement>(null);
    const selectRef = React.useRef<HTMLDivElement>(null);
    const [labelWidth, setLabelWidth] = useState(0);
    const [showMenu, setShowMenu] = useState(false);
    const [loading, setLoading] = useState<boolean | undefined>(undefined);
    useEffect(() => {
        if (inputLabel.current) setLabelWidth(inputLabel.current.offsetWidth);
    }, [label]);

    const fetchInitialData = async (): Promise<void> => {
        if (value.length > 0 && isInitialType(value, true) && loadInitialType) {
            setLoading(true);
            const elements = await loadInitialType(value);
            handleUpdate?.(elements, true);
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchInitialData();
    }, []);

    return (
        <React.Fragment>
            {showMenu && loading === false && (
                <GenericMenu<Type, InitialType>
                    mobile={mobile}
                    anchorRef={selectRef}
                    handleClose={(): void => {
                        if (!keepOpen) setShowMenu(false);
                    }}
                    handleConfirm={handleConfirm}
                    canChangeOrder={canChangeOrder}
                    idMenu={idMenu}
                    label={elementLabel}
                    pluralLabel={pluralLabel}
                    seed={seed}
                    placement="bottom-start"
                    initialElements={value}
                    zIndex={1301}
                    marginTop={'-5px'}
                    multiple={multiple}
                    handleUpdate={(elements): void => {
                        handleUpdate?.(elements);
                        if (!multiple && elements.length > 0) {
                            setShowMenu(false);
                        }
                    }}
                    calcLbl={calcLbl}
                    calcIsDeleted={calcIsDeleted}
                    calcIcon={calcIcon}
                    loadElements={loadElements}
                    loadInitialType={loadInitialType}
                    calcId={calcId}
                    handleView={handleView}
                    handleConfig={handleConfig}
                    customMenuButtons={customMenuButtons}
                />
            )}
            <FormControl
                variant="outlined"
                className={classes.formControl}
                size="small"
                style={
                    minWidth !== undefined
                        ? { minWidth, outlineColor, margin: containerMargin }
                        : {
                              margin: containerMargin,
                          }
                }
                fullWidth
                required={required}
                error={error}
            >
                <InputLabel ref={inputLabel} classes={labelClasses}>
                    {label}
                </InputLabel>
                <Select
                    {...others}
                    ref={selectRef}
                    value={value.length > 0 || emptyLabel ? 0 : ''}
                    onChange={handleUpdate as any}
                    IconComponent={
                        loading
                            ? (props: any): any => (
                                  <CircularProgress
                                      {...props}
                                      size={22}
                                      style={{ color: '#757575' }}
                                  />
                              )
                            : IconComponent
                    }
                    open={false}
                    onFocus={(): void => {
                        setShowMenu(true);
                    }}
                    onClose={(): void => {
                        setShowMenu(false);
                    }}
                    onOpen={(): void => {
                        setShowMenu(true);
                    }}
                    input={
                        <OutlinedInput
                            disabled={cantEdit}
                            labelWidth={labelWidth}
                            name={label}
                            classes={outlinedInputClasses}
                        />
                    }
                    renderValue={(): undefined | React.ReactNode => {
                        if (
                            !loading &&
                            value.length > 0 &&
                            calcId(value[0] as any) !== undefined
                        ) {
                            return (value as any)
                                .map((element: Type) => calcLbl(element))
                                .join(', ');
                        }
                        if (!loading && value.length === 0) {
                            return emptyLabel;
                        }
                        return undefined;
                    }}
                    MenuProps={{
                        PopoverClasses: menuClasses,
                        style: { zIndex: mobile ? 1299 : 1300 },
                    }}
                >
                    <MenuItem value={0}></MenuItem>
                </Select>
                {helperText !== undefined && (
                    <FormHelperText classes={helperTextClasses}>
                        {helperText}
                    </FormHelperText>
                )}
            </FormControl>
        </React.Fragment>
    );
}

/**
 * Generic textfield with apps designs. Is class do to the use in the react-hook-forms library
 */
class RoundedGenericPicker<Type, InitialType = string> extends React.Component<
    RoundedGenericPickerProps<Type, InitialType>
> {
    render(): JSX.Element {
        return <CustomSelect<Type, InitialType> {...this.props} />;
    }
}
export default RoundedGenericPicker;
