import styles from './RelationshipEntityValueFilter.module.css';
import RoundedSelect from '../../../../RoundedSelect/RoundedSelect';
import React from 'react';
import { MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../utils/_store';
import RoundedEntityValuePicker from '../../../../@Pickers/RoundedEntityValuePicker/RoundedEntityValuePicker';
import Toggle from '../../../../Toggle/Toggle';
import { EntityRelationshipPicker } from '../../../../../@Types/EntityTypes/EntityProperty';
import { EntityValuePickerFilter } from '../../../../../@Types/GenericFormSteps';
import { EntityValueFilterTypes } from '../../../../../constants/FormStepTypes';

interface RelationshipEntityValueFilterProps {
    filter: EntityValuePickerFilter;
    property: EntityRelationshipPicker;
    handleUpdate: (filter: EntityValuePickerFilter) => void;
    showRequired?: boolean;
}

function RelationshipEntityValueFilter({
    filter,
    property,
    handleUpdate,
    showRequired = true,
}: RelationshipEntityValueFilterProps): JSX.Element {
    const entity = useSelector(
        (state: RootState) => state.site.entities[property.idEntity]
    );
    if (filter.type === EntityValueFilterTypes.ENTITY_RELATIONSHIP)
        return <></>;
    return (
        <React.Fragment>
            <div className={styles.bottom}>
                <div className={styles.filterTypeSelector}>
                    <RoundedSelect
                        autoWidth
                        containerMargin="0px"
                        label="Tipo de filtro"
                        value={filter.type}
                        handleUpdate={(e): void => {
                            const type = e.target
                                .value as EntityValueFilterTypes;
                            switch (type) {
                                case EntityValueFilterTypes.STEP:
                                    handleUpdate({
                                        idProperty: filter.idProperty,
                                        type,
                                        any: true,
                                        required: false,
                                    });
                                    break;
                                case EntityValueFilterTypes.VALUE:
                                    handleUpdate({
                                        idProperty: filter.idProperty,
                                        type,
                                        value: [],
                                    });
                                    break;
                                default:
                                    break;
                            }
                        }}
                    >
                        <MenuItem value={EntityValueFilterTypes.STEP}>
                            Cualquier paso de {entity.name} anterior
                        </MenuItem>
                        <MenuItem value={EntityValueFilterTypes.VALUE}>
                            Un
                            {entity.feminine ? 'a de las' : 'o de los'}{' '}
                            siguientes {entity.pluralName}
                        </MenuItem>
                    </RoundedSelect>
                </div>
            </div>
            {showRequired && filter.type === EntityValueFilterTypes.STEP && (
                <div className={styles.requiredContainer}>
                    <div className={styles.requiredLabel + ' noselect'}>
                        Obligatorio:
                    </div>
                    <div className={styles.toggleContainer}>
                        <Toggle
                            checked={filter.required === true}
                            onChange={(checked: boolean): void => {
                                handleUpdate({
                                    ...filter,
                                    required: checked,
                                });
                            }}
                        />
                    </div>
                </div>
            )}
            {filter.type === EntityValueFilterTypes.VALUE && (
                <div className={styles.entityValueInput}>
                    <RoundedEntityValuePicker
                        height={'40px'}
                        minWidth={300}
                        multiline
                        value={filter.value}
                        multiple
                        handleUpdate={(value): void => {
                            handleUpdate({ ...filter, value });
                        }}
                        idEntity={property.idEntity}
                    />
                </div>
            )}
        </React.Fragment>
    );
}
export default RelationshipEntityValueFilter;
