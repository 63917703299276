import { MenuItem } from '@material-ui/core';
import React, { useMemo } from 'react';
import { ClientCondition } from '../../../../@Types/ConditionTypes/ClientCondition';
import ConditionOperators, {
    ConditionEqualsOperators,
} from '../../../../constants/Conditions/ConditionOperators';
import EntityProperties from '../../../../constants/EntityPropertyTypes';
import RoundedSelect from '../../../RoundedSelect/RoundedSelect';
import styles from '../Condition.module.css';
import entityStyles from './ClientCondition.module.css';
import ClientPropertyConditionComponent from './ClientPropertyCondition/ClientPropertyCondition';
import RoundedClientPropertyPicker from '../../../@Pickers/RoundedClientPropertyPicker/RoundedClientPropertyPicker';
import ClientPropertyTypes from '../../../../constants/ClientPropertyTypes';
import RoundedClientPicker from '../../../@Pickers/RoundedClientPicker/RoundedClientPicker';
import { ConditionProps } from '../../Condition';
import produce from 'immer';
import IntegrationCondition from '../IntegrationCondition';
import RoundedEntityPropertyPicker from '../../../@Pickers/RoundedEntityPropertyPicker/RoundedEntityPropertyPicker';
import { isIntegration } from '../../../../@Types/Integration';
import IntegrationTypes from '../../../../constants/IntegrationTypes';
import { IntegrationsApi } from '../../../../controllers/IntegratrionsController/IntegrationsService';
import { useAppSelector, useIdProject } from '../../../../hooks';

interface ClientConditionProps extends ConditionProps<ClientCondition> {}
function ClientConditionComponent({
    context,
    condition,
    hoverDelete,
    handleUpdate,
}: ClientConditionProps): JSX.Element {
    const idProject = useIdProject();
    const entity = useAppSelector((state) => state.site.clientEntity);

    if (!entity) {
        return <div></div>;
    }

    const { data: integrations } = IntegrationsApi.useLoadIntegrationsQuery({
        idProject,
        idEntity: entity._id,
        type: IntegrationTypes.CONDITION,
    });

    const hasIntegrations = useMemo(() => {
        return integrations && integrations?.length > 0;
    }, [integrations]);

    const renderValue = (): JSX.Element => {
        if (
            condition.operator === ConditionOperators.EQUAL ||
            condition.operator === ConditionOperators.NOTEQUAL
        ) {
            return (
                <div className={styles.valueContainer}>
                    <div className={styles.multiSelectContainer}>
                        <RoundedClientPicker
                            value={(condition.values as any) ?? []}
                            label={'Clientes'}
                            multiple={true}
                            height="40px"
                            error={hoverDelete}
                            handleUpdate={(clients): void => {
                                handleUpdate({
                                    ...condition,
                                    values: clients as any,
                                });
                            }}
                        />
                    </div>
                </div>
            );
        } else if (
            condition.operator === ConditionOperators.INCLUDES ||
            condition.operator === ConditionOperators.NOTINCLUDES
        ) {
            return (
                <React.Fragment>
                    <div className={entityStyles.propertySelectorContainer}>
                        <RoundedClientPropertyPicker
                            fullWidth
                            label={'Propiedad'}
                            value={condition.idProperty}
                            propertyTypes={[
                                ClientPropertyTypes.CLIENT_INFO_TYPE,
                                ClientPropertyTypes.PERMISSIONS,
                                EntityProperties.TEXTINPUT,
                                EntityProperties.TEXTAREA,
                                EntityProperties.CHECKBOX,
                                EntityProperties.DATEPICKER,
                                EntityProperties.TIMEPICKER,
                            ]}
                            integrationTypes={[IntegrationTypes.DRAFT]}
                            handleUpdate={(idProperty, propertyType): void => {
                                handleUpdate({
                                    type: condition.type,
                                    operator: condition.operator,
                                    idProperty,
                                    propertyType,
                                } as any);
                            }}
                        />
                    </div>

                    <ClientPropertyConditionComponent
                        entity={entity}
                        context={context}
                        condition={condition}
                        hoverDelete={hoverDelete}
                        handleUpdate={handleUpdate as any}
                    />
                </React.Fragment>
            );
        } else if (
            condition.operator === ConditionOperators.INTEGRATION_MET ||
            condition.operator === ConditionOperators.NOTINTEGRATION_MET
        ) {
            return (
                <React.Fragment>
                    <div className={entityStyles.propertySelectorContainer}>
                        <RoundedEntityPropertyPicker
                            idEntity={entity._id}
                            fullWidth
                            value={condition.idIntegration}
                            label="Integración"
                            propertyTypes={[]}
                            integrationTypes={[IntegrationTypes.CONDITION]}
                            error={hoverDelete}
                            handleUpdate={(property): void => {
                                if (!isIntegration(property)) return;
                                handleUpdate({
                                    ...condition,
                                    idIntegration: property?._id,
                                });
                            }}
                        />
                    </div>
                    <IntegrationCondition
                        context={context}
                        condition={condition}
                        hoverDelete={hoverDelete}
                        handleUpdate={handleUpdate as any}
                        idIntegration={condition.idIntegration}
                    />
                </React.Fragment>
            );
        } else {
            return <div></div>;
        }
    };

    return (
        <React.Fragment>
            <div className={styles.operatorContainer}>
                <div className={styles.selectorContainer}>
                    <RoundedSelect
                        value={condition.operator ?? ''}
                        label="Operador"
                        containerMargin="0px"
                        error={hoverDelete}
                        handleUpdate={(event): void => {
                            handleUpdate(
                                produce(condition, (condition) => {
                                    const operator = event.target.value;
                                    if (
                                        ConditionEqualsOperators.includes(
                                            condition.operator
                                        ) &&
                                        !ConditionEqualsOperators.includes(
                                            operator
                                        )
                                    ) {
                                        delete (condition as any).values;
                                    }
                                    condition.operator = operator;
                                })
                            );
                        }}
                    >
                        <MenuItem value={ConditionOperators.EQUAL}>
                            Es igual a
                        </MenuItem>
                        <MenuItem value={ConditionOperators.NOTEQUAL}>
                            No es igual a
                        </MenuItem>
                        <MenuItem value={ConditionOperators.INCLUDES}>
                            Contiene
                        </MenuItem>
                        <MenuItem value={ConditionOperators.NOTINCLUDES}>
                            No contiene
                        </MenuItem>
                        {(hasIntegrations ||
                            condition.operator ===
                                ConditionOperators.INTEGRATION_MET) && (
                            <MenuItem
                                value={ConditionOperators.INTEGRATION_MET}
                            >
                                Cumple
                            </MenuItem>
                        )}
                        {(hasIntegrations ||
                            condition.operator ===
                                ConditionOperators.NOTINTEGRATION_MET) && (
                            <MenuItem
                                value={ConditionOperators.NOTINTEGRATION_MET}
                            >
                                No cumple
                            </MenuItem>
                        )}
                    </RoundedSelect>
                </div>
            </div>
            {renderValue()}
        </React.Fragment>
    );
}
export default ClientConditionComponent;
