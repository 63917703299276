import { StringValidation } from '../@Types/StringValidation';

enum StringValidationTypes {
    OTHER = 'OTHER',
    PHONE = 'PHONE',
    ALPHA = 'ALPHA',
    NUM = 'NUM',
    EMAIL = 'EMAIL',
    YEAR = 'YEAR',
    DATE = 'DATE',
}
export default StringValidationTypes;

export enum StringValidationIntegrationTypes {
    CBR_IDENTIFIER = 'CBR_IDENTIFIER',
    CBR_COMPANY_IDENTIFIER = 'CBR_COMPANY_IDENTIFIER',
}

export const StringValidations: {
    type: StringValidationTypes;
    label: string;
}[] = [
    { type: StringValidationTypes.EMAIL, label: 'Correo' },
    {
        type: StringValidationTypes.PHONE,
        label: 'Teléfono',
    },
    { type: StringValidationTypes.ALPHA, label: 'Solo letras' },
    { type: StringValidationTypes.NUM, label: 'Solo números' },
    { type: StringValidationTypes.YEAR, label: 'Año' },
    { type: StringValidationTypes.DATE, label: 'Fecha (dd/mm/aaaa)' },
    { type: StringValidationTypes.OTHER, label: 'Otra' },
];

export const StringValidationValues: Record<string, StringValidation> = {
    [StringValidationTypes.EMAIL]: {
        value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            .source,
        message: 'El correo no es válido',
        type: StringValidationTypes.EMAIL,
    },
    [StringValidationTypes.PHONE]: {
        value: /^([0-9]*)$/.source,
        message: 'El teléfono no es válido',
        type: StringValidationTypes.PHONE,
    },
    [StringValidationTypes.ALPHA]: {
        value: /^[a-zA-Z\w]*$/.source,
        message: 'Solo puede ingresar letras',
        type: StringValidationTypes.ALPHA,
    },
    [StringValidationTypes.NUM]: {
        value: /^[0-9]*$/.source,
        message: 'Solo puede ingresar números',
        type: StringValidationTypes.NUM,
    },
    [StringValidationTypes.YEAR]: {
        value: /^[0-9]{4}$/.source,
        message: 'El año no es válido',
        type: StringValidationTypes.YEAR,
    },
    [StringValidationTypes.DATE]: {
        value: /^(?:(?:31(\/|-)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
            .source,
        message: 'La fecha no es válida (dd/mm/aaaa)',
        type: StringValidationTypes.DATE,
    },
    [StringValidationTypes.OTHER]: {
        value: '',
        message: 'El valor no es válido',
        type: StringValidationTypes.OTHER,
    },
};
