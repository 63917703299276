import React, { useState } from 'react';
import { WhatsappTemplate } from '../../../@Types/WhatsappTemplate';
import styles from './WhatsappTemplateMessage.module.css';
import DraftRenderer from '../../DraftRenderer/DraftRenderer';
import { EditorState, convertFromRaw } from 'draft-js';
import WhatsappTemplateMessageButton from './WhatsappTemplateMessageButton/WhatsappTemplateMessageButton';
import { WhatsappTemplateTypes } from '../../../constants/WhatsappTemplates/WhatsappTemplateTypes';
import { WhatsappTemplateStatusTypes } from '../../../constants/WhatsappTemplates/WhatsappTemplateStatusTypes';
import PendingIcon from '../../../Icons/PendingIcon';
import WarningIcon from '../../../Icons/WarningIcon';
import DoneIcon from '../../../Icons/DoneIcon';

interface WhatsappTemplateMessageProps {
    label: string;
    description: string;
    type: WhatsappTemplateTypes;
    templates: Record<WhatsappTemplateTypes, WhatsappTemplate> | null;
    onSelect: (type: WhatsappTemplateTypes) => void;
    selected: WhatsappTemplateTypes | null;
}

function WhatsappTemplateMessage({
    type,
    label,
    onSelect,
    selected,
    templates,
    description,
}: WhatsappTemplateMessageProps): JSX.Element {
    const [hovering, setHovering] = useState(false);
    const [hoveringTail, setHoveringTail] = useState(false);

    if (!templates) {
        return <div></div>;
    }

    const template = templates[type];

    if (!template) return <></>;

    const currentStyle = {
        borderColor: selected ? 'var(--greyFont)' : 'var(--greyBtn)',
    };
    const header = template.header
        ? EditorState.createWithContent(convertFromRaw(template.header.text))
        : null;

    const isSelected = selected === type;

    const calcStateIcon = (): JSX.Element | void => {
        if (template.status === WhatsappTemplateStatusTypes.PENDING) {
            return (
                <div
                    title="Pendiente Aprobación"
                    style={{ width: 26, height: 26 }}
                >
                    <PendingIcon
                        style={{ width: 26, height: 26 }}
                        fill={'var(--secondary2)'}
                    />
                </div>
            );
        } else if (template.status === WhatsappTemplateStatusTypes.REJECTED) {
            return (
                <div title="Rechazado" style={{ width: 26, height: 26 }}>
                    <WarningIcon
                        style={{ width: 26, height: 26 }}
                        fill={'var(--error)'}
                    />
                </div>
            );
        } else if (template.status === WhatsappTemplateStatusTypes.APPROVED) {
            return (
                <div title="Aprobado" style={{ width: 26, height: 26 }}>
                    <DoneIcon
                        style={{ width: 26, height: 26 }}
                        fill={'var(--secondary)'}
                    />
                </div>
            );
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.templateLabel}>
                {label}
                {calcStateIcon()}
            </div>
            <p className={styles.templateDescription}>{description}</p>
            <div
                className={
                    hovering || hoveringTail || isSelected
                        ? styles.selectedCont
                        : styles.messageCont
                }
                onClick={(): void => onSelect(type)}
            >
                <div className={styles.cardContainer}>
                    <div className={styles.trContainer}>
                        <div
                            className={styles.tr}
                            style={currentStyle}
                            onMouseEnter={(): void => {
                                setHoveringTail(true);
                            }}
                            onMouseLeave={(): void => {
                                setHoveringTail(false);
                            }}
                        ></div>
                    </div>
                    <div className={styles.trBorder} style={currentStyle}></div>
                    <div
                        className={styles.messageCard}
                        style={currentStyle}
                        onMouseEnter={(): void => {
                            setHovering(true);
                        }}
                        onMouseLeave={(): void => {
                            setHovering(false);
                        }}
                    >
                        <div className={styles.cardCurtain}></div>
                        {header && header.getCurrentContent().hasText() && (
                            <div className={styles.headerContainer}>
                                <DraftRenderer editorState={header} />
                            </div>
                        )}
                        <div className={styles.messageContainer}>
                            <DraftRenderer
                                editorState={EditorState.createWithContent(
                                    convertFromRaw(template.body)
                                )}
                            />
                        </div>
                        {template.footer && template.footer.length > 0 && (
                            <div className={styles.footerContainer}>
                                {template.footer}
                            </div>
                        )}
                    </div>
                </div>
                {template.buttons && template.buttons.length > 0 && (
                    <div
                        className={styles.btnsContainer}
                        onMouseEnter={(): void => {
                            setHovering(true);
                        }}
                        onMouseLeave={(): void => {
                            setHovering(false);
                        }}
                    >
                        {template.buttons.map((button, index) => (
                            <WhatsappTemplateMessageButton
                                key={index}
                                button={button}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default WhatsappTemplateMessage;
