import editorStyles from '../GenericFormEditor.module.css';
import React, { cloneElement, useEffect } from 'react';
import PageTitle from '../../../shared/PageTitle/PageTitle';
// import FormMap from './FormMap/FormMap';
import styles from './Overview.module.css';
// import { Edge, isNode, Node, Position } from 'react-flow-renderer';
// import dagre from 'dagre';
import Loader from '../../../shared/Loader/Loader';
import { GBaseStep, GForm } from '../../../@Types/GenericForm';
import DefaultConfigs from '../DefaultConfigs/DefaultConfigs';
import {
    updateData,
    updateName,
} from '../../../controllers/GenericEditorController/GenericEditorActions';
import { BaseEditorProps, RecursiveConfigProps } from '../GenericFormEditor';
import { GenericEditorState } from '../../../controllers/GenericEditorController/GenericEditorReducer';
import SectionNode from './FormMap/SectionNode/SectionNode';
import { GLocation } from '../../../@Types/FormTypes/LocationTypes';
import {
    useGenericEditorDispatch,
    useGenericEditorSelector,
} from '../GenericFormEditorHooks';
import { useCurrentProject } from '../../../hooks';

interface OverviewProps<D> extends BaseEditorProps<D>, RecursiveConfigProps {}

function Overview<
    F extends GForm<S, U>,
    S extends GBaseStep,
    U,
    L extends GLocation,
    D
>({
    configComponent,
    editStyle = false,
    editSize = false,
    calcCurrentRoute,
}: OverviewProps<D>): JSX.Element {
    // const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);
    // const [elements, setElements] = useState<(Node<any> | Edge<any>)[]>([]);
    // const [loading, setLoading] = useState(false);
    const loading = false;
    const dispatch = useGenericEditorDispatch();
    const project = useCurrentProject();
    const editorInfo = useGenericEditorSelector<
        GenericEditorState<F, S, U, L, D>
    >((state) => state);
    useEffect(() => {
        // if (!editorInfo.loading) {
        //     const elements = calcFormElements(
        //         editorInfo.sections,
        //         editorInfo.steps
        //     );
        //     setElements(elements);
        // }
    }, [editorInfo.sections, editorInfo.steps]);

    if (editorInfo.loading) {
        return (
            <div className={editorStyles.whiteCurtain}>
                <Loader size={100} />
            </div>
        );
    }

    const renderTitle = (): JSX.Element => {
        if (!calcCurrentRoute) return <div></div>;
        const route = calcCurrentRoute(project, editorInfo.data, undefined);
        return (
            <PageTitle
                loading={loading}
                editable
                title={editorInfo.name}
                route={route}
                handleUpdate={(value: string): void => {
                    dispatch(updateName(value));
                }}
            />
        );
    };

    return (
        <div className={styles.container}>
            <div>
                {renderTitle()}
                <div className={styles.mapContainer}>
                    {loading && <Loader size={100} zIndex={3} />}
                    <SectionNode
                        data={editorInfo.sections[editorInfo.firstSection]}
                    />
                    <div className={styles.helpMessage}>
                        *Selecciona una sección para editarla
                    </div>
                </div>

                <div className={styles.detailContainer}>
                    {!loading &&
                        configComponent !== undefined &&
                        cloneElement(configComponent, {
                            data: editorInfo.data,
                            handleUpdate: (data: D): void => {
                                dispatch(updateData(data));
                            },
                        })}
                    {!loading && (
                        <DefaultConfigs
                            editStyle={editStyle}
                            editSize={editSize}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default Overview;

// function calcFormElements<S extends GBaseStep>(
//     sections: Record<string, Section>,
//     steps: Record<string, S>
// ): (Edge | Node)[] {
//     const edges: Edge[] = [];
//     const elems = Object.values(sections).map((section) => {
//         const sectionEdges: Record<string, Edge> = {};
//         for (const idStep of section.steps) {
//             steps[idStep];
//             // if (step.type === 'SELECTOR') {//TODO, Custom step handler for the graph
//             //     for (const option of step.options) {
//             //         if (option.type === 'ADD_SECTION') {
//             //             sectionEdges[option.idSection] = {
//             //                 id: 'EDGE-' + section.id + '-' + option.idSection,
//             //                 source: section.id,
//             //                 target: option.idSection,
//             //             };
//             //         }
//             //     }
//             // }
//         }
//         if (section.nextSection) {
//             sectionEdges[section.nextSection] = {
//                 id: 'EDGE-' + section.id + '-' + section.nextSection,
//                 source: section.id,
//                 target: section.nextSection,
//             };
//         }
//         Object.values(sectionEdges).forEach((edge) => {
//             edges.push(edge);
//         });
//
//         return {
//             id: section.id,
//             data: section,
//             type: 'section',
//             position: { x: 0, y: 0 },
//         } as Node;
//     });
//     const elements = [...elems, ...edges];
//     const dagreGraph = new dagre.graphlib.Graph();
//     dagreGraph.setDefaultEdgeLabel(() => ({}));
//     dagreGraph.setGraph({ rankdir: 'LR' });
//     elements.forEach((el) => {
//         if (isNode(el)) {
//             dagreGraph.setNode(el.id, {
//                 width: 120,
//                 height: 160,
//             });
//         } else {
//             dagreGraph.setEdge(el.source, el.target);
//         }
//     });
//     dagre.layout(dagreGraph);
//     return elements.map((el) => {
//         if (isNode(el)) {
//             const nodeWithPosition = dagreGraph.node(el.id);
//             el.targetPosition = Position.Left;
//             el.sourcePosition = Position.Right;
//             el.position = {
//                 x: nodeWithPosition.x - 120 / 2 + Math.random() / 1000,
//                 y: nodeWithPosition.y - 160 / 2,
//             };
//         }
//         return el;
//     });
// }
