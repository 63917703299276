import styles from './Option.module.css';
import {
    FormClassifierSelectorOption,
    FormStep,
} from '../../../../../@Types/FormTypes/FormStep';
import NotInterestedRoundedIcon from '@material-ui/icons/NotInterestedRounded';
import React from 'react';
import { Classifier } from '../../../../../@Types/@Types';
import { ClassifierOptionTypes } from '../../../../../constants/OptionTypes';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import NestedOption from './NestedOption/NestedOption';
import { StepComponentProps } from '../../../../../shared/GenericFormEditor/Step/Step';
import {
    FormStepLocation,
    UniqueSteps,
} from '../../../../../@Types/FormTypes/Form';
import { ClassifierSelectorOptionLocation } from '../../../../../@Types/FormTypes/LocationTypes';
import FormConditionComponent from '../../../FormCondition/FormCondition';
export interface ClassifierOptionProps
    extends StepComponentProps<FormStep, UniqueSteps, FormStepLocation> {
    option: FormClassifierSelectorOption | undefined;
    updateOption: Function;
    removeOption: Function;
    location: ClassifierSelectorOptionLocation;
    classifier: Classifier;
}

function ClassifierOptionComponent({
    updateOption,
    removeOption,
    option,
    classifier,
    ...others
}: ClassifierOptionProps): JSX.Element {
    return (
        <React.Fragment>
            <div className={styles.container}>
                <div className={styles.bullet} />
                <div
                    className={styles.containerLbl}
                    style={
                        option?.type === ClassifierOptionTypes.HIDE
                            ? {
                                  textDecoration: 'line-through',
                              }
                            : {}
                    }
                >
                    {classifier.clientName
                        ? classifier.clientName + ' (' + classifier.name + ')'
                        : classifier.name}
                </div>
                <div className={styles.btnsContainer}>
                    <div
                        className={styles.hideBtn}
                        onClick={(): void => {
                            if (option?.type === ClassifierOptionTypes.HIDE) {
                                removeOption();
                            } else {
                                updateOption({
                                    type: ClassifierOptionTypes.HIDE,
                                    idClassifier: classifier._id,
                                });
                            }
                        }}
                        style={
                            option?.type !== ClassifierOptionTypes.HIDE
                                ? {}
                                : {
                                      color: 'var(--error)',
                                  }
                        }
                    >
                        <NotInterestedRoundedIcon fontSize="inherit" />
                    </div>
                    <div
                        className={styles.addBtn}
                        onClick={(): void => {
                            if (option?.type === ClassifierOptionTypes.NESTED) {
                                if (option.condition) {
                                    updateOption({
                                        type: ClassifierOptionTypes.DEFAULT,
                                        idClassifier: classifier._id,
                                        condition: option.condition,
                                    });
                                } else {
                                    removeOption();
                                }
                            } else {
                                updateOption({
                                    type: ClassifierOptionTypes.NESTED,
                                    idClassifier: classifier._id,
                                    steps: [],
                                    condition: (option as any)?.condition,
                                });
                            }
                        }}
                        style={
                            option?.type === ClassifierOptionTypes.NESTED
                                ? { color: 'var(--secondary)' }
                                : {}
                        }
                    >
                        <AddRoundedIcon fontSize="inherit" />
                    </div>
                    {option?.type !== ClassifierOptionTypes.HIDE && (
                        <FormConditionComponent
                            condition={(option as any)?.condition}
                            handleUpdate={(condition): void =>
                                updateOption({
                                    ...(option ?? {
                                        type: ClassifierOptionTypes.DEFAULT,
                                        idClassifier: classifier._id,
                                    }),
                                    condition,
                                })
                            }
                            size={26}
                            iconSize={16}
                            borderRadius={5}
                            idStep={others.step.id}
                        />
                    )}
                </div>
            </div>

            <div className={styles.contentContainer}>
                <OptionMapper
                    {...others}
                    classifier={classifier}
                    option={option}
                    updateOption={updateOption}
                    removeOption={removeOption}
                />
            </div>
        </React.Fragment>
    );
}

export default ClassifierOptionComponent;
function OptionMapper(props: ClassifierOptionProps): JSX.Element {
    if (props.option) {
        switch (props.option.type) {
            case ClassifierOptionTypes.HIDE: {
                return <div></div>;
            }
            case ClassifierOptionTypes.NESTED: {
                return <NestedOption {...props} />;
            }
            default:
                return <div></div>;
        }
    }
    return <div></div>;
}
