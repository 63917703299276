import styles from './ConversationTemplates.module.css';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter, match } from 'react-router-dom';
import { RouterProps } from '../../../Router';
import { RootState } from '../../../utils/_store';
import RoundedButton from '../../../shared/RoundedButton/RoundedButton';
import SaveDialog from '../../../shared/SaveDialog/SaveDialog';
import Loader from '../../../shared/Loader/Loader';
import PageTitle from '../../../shared/PageTitle/PageTitle';
import React from 'react';
import ConversationService from '../../../controllers/ConversationEditorController/ConversationService';
import NoMobile from '../../../shared/NoMobile/NoMobile';
import WhatsappTemplateDetailComponent from '../../../shared/WhatsappTemplate/WhatsappTemplateDetail/WhatsappTemplateDetail';
import { WhatsappTemplate } from '../../../@Types/WhatsappTemplate';
import ConditionTypes from '../../../constants/Conditions/ConditionTypes';
import { WhatsappEntry } from '../../../@Types/Entry';
import WhatsappTemplateMessage from '../../../shared/WhatsappTemplate/WhatsappTemplateMessage/WhatsappTemplateMessage';
import {
    BaseWhatsappTemplateTypes,
    WhatsappTemplateTypes,
} from '../../../constants/WhatsappTemplates/WhatsappTemplateTypes';

interface apiKeyRouteParam {
    /** Current entry */
    apiKey: string;
    /** Current Project */
    idProject: string;
}

export const ApiKeyContext = React.createContext('');

function ConversationTemplatesPage({
    match,
    mobile,
}: {
    match: match<apiKeyRouteParam>;
} & RouterProps): JSX.Element {
    const siteInfo = useSelector((state: RootState) => state.site);
    const [saving, setSaving] = useState(false);
    const [template, setTemplate] = useState<WhatsappTemplateTypes | null>(
        null
    );
    const [entry, setEntry] = useState<WhatsappEntry | null>(null);
    const [templates, setTemplates] = useState<Record<
        string,
        WhatsappTemplate
    > | null>({});

    const loadInfo = async (
        idProject: string,
        apiKey: string
    ): Promise<void> => {
        const { entry, templates } =
            await ConversationService.loadConversationTemplates(
                idProject,
                apiKey
            );
        setTemplates(templates);
        setEntry(entry);
    };

    useEffect(() => {
        if (match.params.apiKey) {
            loadInfo(match.params.idProject, match.params.apiKey);
        }
    }, [match.params.idProject, match.params.apiKey]);

    if (mobile) {
        return <NoMobile />;
    }

    return (
        <div className={styles.container}>
            {(!templates || !entry) && (
                <div className={styles.whiteCurtain}>
                    <Loader size={100} />
                </div>
            )}
            <div className={styles.templatesContainer}>
                <div className={styles.containerLine}></div>
                <div className={styles.pageTitleCont}>
                    <PageTitle
                        editable
                        title={entry?.entryName ?? ''}
                        route={
                            siteInfo?.projects[siteInfo?.idProject ?? '']
                                ?.name +
                            ' / Entradas / ' +
                            entry?.entryName
                        }
                    />
                </div>
                <div className={styles.saveBtn}>
                    <RoundedButton
                        text="Guardar"
                        fontSize={22}
                        borderRadius={12}
                        padding="20px 12px"
                        onClick={(): void => {
                            setSaving(true);
                        }}
                    />
                </div>

                {saving && (
                    <SaveDialog
                        onClose={(): void => {
                            setSaving(false);
                        }}
                        handleSave={async (): Promise<void> => {
                            if (!siteInfo.idProject || !templates) return;
                            const response =
                                await ConversationService.saveConversationTemplates(
                                    siteInfo.idProject,
                                    match.params.apiKey,
                                    templates
                                );
                            setTemplates(response);
                            setTemplate(null);
                        }}
                    />
                )}
                <div className={styles.templatesList}>
                    <WhatsappTemplateMessage
                        label="Mensaje de Cierre"
                        description="Mensaje que notifica el cierre de un caso que NO tiene encuesta de satisfacción."
                        type={BaseWhatsappTemplateTypes.CLOSED}
                        templates={templates}
                        selected={template}
                        onSelect={setTemplate}
                    />
                    <WhatsappTemplateMessage
                        label="Mensaje de Cierre con Encuesta"
                        description="Mensaje que notifica el cierre de un caso que SÍ tiene encuesta de satisfacción."
                        type={BaseWhatsappTemplateTypes.CLOSED_SURVEY}
                        templates={templates}
                        onSelect={setTemplate}
                        selected={template}
                    />
                    <WhatsappTemplateMessage
                        label="Respuesta de un Agente"
                        description="Mensaje que notifica la respuesta de un agente cuando han pasado más de 24 horas desde la última interacción."
                        type={BaseWhatsappTemplateTypes.VIEW_REPLY}
                        templates={templates}
                        selected={template}
                        onSelect={setTemplate}
                    />
                    <WhatsappTemplateMessage
                        label="Respuesta con Link"
                        description="Mensaje que notifica la respuesta de un agente cuando el caso es de Gestión Múltiple."
                        type={BaseWhatsappTemplateTypes.CLIENT_LINK}
                        templates={templates}
                        onSelect={setTemplate}
                        selected={template}
                    />
                    <WhatsappTemplateMessage
                        label="Notificación Pendiente"
                        description="Mensaje que informa de una notificación nueva."
                        type={BaseWhatsappTemplateTypes.VIEW_NOTIFICATION}
                        templates={templates}
                        onSelect={setTemplate}
                        selected={template}
                    />
                </div>
            </div>
            <div className={styles.detailContainer}>
                <div className={styles.containerLine}></div>
                {!template && (
                    <div className={styles.emptyDetail}>
                        Selecciona un Mensaje
                    </div>
                )}
                {template && templates?.[template] && (
                    <WhatsappTemplateDetailComponent
                        template={templates[template]}
                        handleUpdate={(update): void =>
                            setTemplates((templates) => ({
                                ...templates,
                                [template]: update,
                            }))
                        }
                        payloadEditorConditions={{
                            types: [
                                ConditionTypes.CLIENT,
                                ConditionTypes.COMPANY,
                                ConditionTypes.ENTITYVALUE,
                            ],
                        }}
                    />
                )}
            </div>
        </div>
    );
}
export default withRouter((props: any) => (
    <ConversationTemplatesPage {...props} />
));
