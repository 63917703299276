import {
    PayloadEditorActions,
    PayloadEditorContext,
    usePayloadEditorDispatch,
} from '../../../controllers/PayloadEditorController/PayloadEditorSlice';
import { DraftEntityData } from '../../../@Types/Draft/DraftEntityData';
import { DraftStyleTypes } from '../../../constants/Draft/DraftStyleTypes';
import { useContext, useMemo } from 'react';
import { GenericContextProps } from './TextEditorContext';
import { EntityDataMapper } from '../EntityDataMapper';
import { PayloadEditorConceptsContext } from '../PayloadEditor';
import { TextEditorTypeContext } from '../TextEditor';
import { IdGenericEditorContext } from '../../GenericFormEditor/GenericFormEditor';
import EurekaElementMenuElement from '../../@Menus/ElementMenu/EurekaElementMenuElement';

interface ContextProps extends GenericContextProps {
    autoEdit?: boolean;
    data: DraftEntityData;
    entityStyles?: DraftStyleTypes[];
    isMapping?: boolean;
}

function Context({
    data,
    handleClose,
    isMapping,
    autoEdit = false,
    entityStyles = [DraftStyleTypes.BOLD],
    handleUpdate,
}: ContextProps): JSX.Element {
    const dispatch = usePayloadEditorDispatch();
    const idEditor = useContext(PayloadEditorContext);
    const idGenericEditor = useContext(IdGenericEditorContext);
    const editorType = useContext(TextEditorTypeContext);
    const concepts = useContext(PayloadEditorConceptsContext);
    const { Icon, Label, MenuLabel } = useMemo(
        () => EntityDataMapper(data),
        [data]
    );
    const Lbl = MenuLabel ?? Label;
    return (
        <EurekaElementMenuElement
            Icon={Icon}
            Label={Lbl}
            onClick={(): void => {
                if (handleUpdate) {
                    handleUpdate(data);
                } else {
                    dispatch(
                        PayloadEditorActions.addEntity({
                            editorType,
                            idGenericEditor,
                            autoEdit,
                            isMapping,
                            idEditor,
                            data,
                            concepts,
                            styles: entityStyles,
                        })
                    );
                }
                handleClose();
            }}
        />
    );
}

export default Context;
