import { InmuebleInfo360 } from '../../../types';
import styles from './DatosGenerales.module.css';
import React from 'react';
import { Compradores } from './Compradores/Compradores';
import { InfoProyecto } from './InfoProyecto/InfoProyecto';
interface DatosGeneralesProps {
    datosGenerales: InmuebleInfo360['datosGenerales'];
}

export function DatosGenerales({
    datosGenerales,
}: DatosGeneralesProps): JSX.Element {
    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <InfoProyecto infoProyecto={datosGenerales.infoProyecto} />
            </div>
            <div className={styles.section}>
                <Compradores compradores={datosGenerales.datosCompradores} />
            </div>
        </div>
    );
}
