import styles from './InmuebleInfo.module.css';
import React, { useState } from 'react';
import Loader from '../../../../shared/Loader/Loader';
import { DatosGenerales } from './DatosGenerales/DatosGenerales';
import { ClienteInfo360, InmuebleInfo360 } from '../../types';
import { InfoAgrupacion } from './InfoAgrupacion/InfoAgrupacion';
import { Tramites } from './Tramites/Tramites';
import { Cartera } from './Cartera/Cartera';

interface InmuebleInfoProps {
    idAgrupacion: number | undefined;
    inmuebleInfo: InmuebleInfo360 | undefined;
    loading: boolean;
    clientInfo: ClienteInfo360 | undefined;
}

const options = [
    {
        label: 'Datos Generales',
        index: 0,
    },
    {
        label: 'Información Agrupación',
        index: 1,
    },
    {
        label: 'Tramites',
        index: 2,
    },
    {
        label: 'Cartera',
        index: 3,
    },
];

export function InmuebleInfo({
    inmuebleInfo,
    loading,
    clientInfo,
}: InmuebleInfoProps): JSX.Element {
    const [selectedOption, setSelectedOption] = useState<number>(0);
    return (
        <div className={styles.container}>
            <div className={styles.optionsNav}>
                {options.map((form, index) => (
                    <div
                        key={form.index}
                        className={
                            index === selectedOption
                                ? styles.selectedFormName
                                : styles.formName
                        }
                        onClick={(): void => {
                            setSelectedOption(-1);
                            setTimeout(() => {
                                setSelectedOption(index);
                            }, 100);
                        }}
                    >
                        {form.label}
                    </div>
                ))}
            </div>
            {loading ? (
                <div className={styles.loaderContainer}>
                    <Loader position={'relative'} />
                </div>
            ) : !inmuebleInfo ? (
                <>
                    {clientInfo ? (
                        <>
                            {clientInfo.inmuebles.length > 0 ? (
                                <div className={styles.noResults}>
                                    Seleccione un inmueble
                                </div>
                            ) : (
                                <div className={styles.noResults}>
                                    No se encuentran inmuebles
                                </div>
                            )}
                        </>
                    ) : (
                        <div></div>
                    )}
                </>
            ) : (
                <div className={styles.infoContainer}>
                    {renderOption(selectedOption, inmuebleInfo)}
                </div>
            )}
        </div>
    );
}

function renderOption(
    option: number,
    inmuebleInfo: InmuebleInfo360
): JSX.Element {
    switch (option) {
        case 0:
            return (
                <DatosGenerales datosGenerales={inmuebleInfo.datosGenerales} />
            );
        case 1:
            return (
                <InfoAgrupacion
                    infoAgrupacion={inmuebleInfo.infoAgrupacion}
                    conceptosMap={inmuebleInfo.cartera.conceptosMap}
                />
            );
        case 2:
            return <Tramites tramites={inmuebleInfo.tramites} />;
        case 3:
            return <Cartera cartera={inmuebleInfo.cartera} />;
        default:
            return <div></div>;
    }
}
