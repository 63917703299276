import { createAction } from '@reduxjs/toolkit';
import { Area } from '../../@Types/@Types';

export const Types = {
    RESET: 'AREAS/RESET',
    REFRESH: 'AREAS/REFRESH',
    LOADING: 'AREAS/LOADING',
    SELECT: 'AREAS/SELECT_ELEMENT',
    UPDATE: 'AREAS/UPDATE_ELEMENT',
    UPDATE_FAILED: 'AREAS/UPDATE_ELEMENT_FAILED',
    GET_SUCCESS: 'AREAS/GET_SUCCESS',
    SEARCH: 'AREAS/SEARCH',
    ORDER: 'AREAS/ORDER',
};

/**
 * Called when the areas page is first loaded
 */
export const reset = createAction(Types.RESET);

/**
 * Called when the list needs soft resetting
 */
export const refresh = createAction(Types.REFRESH);

/**
 * Called to toggle the loader
 */
export const setLoading = createAction(
    Types.LOADING,
    function prepare(loading: boolean = true) {
        return {
            payload: loading,
        };
    }
);

/**
 * Called when the selectedElement (id) changes value
 */
export const selectElement = createAction<string>(Types.SELECT);

/**
 * Called when a value of the selected Area is updated
 */
export const updateElement = createAction<{
    object: Area;
    field: keyof Area;
    value: string;
}>(Types.UPDATE);

export const updateFailed = createAction<Area>(Types.UPDATE_FAILED);
/**
 *  Called when the saga has sucessfully retrieved the lists elements
 */
export const getSuccess = createAction<{
    elements: Record<string, Area>;
    roots: string[];
}>(Types.GET_SUCCESS);

/**
 * Called when the search value changes
 */
export const search = createAction<string | undefined>(Types.SEARCH);

/**
 *  Called when the orderby value changes
 */
export const orderBy = createAction<string>(Types.ORDER);
