import { takeLatest, call, put, fork, select } from 'redux-saga/effects';
import { Interactions } from '../../../constants/ActionTypes';
import * as actions from './ActionsActions';
import * as TicketActions from '../TicketsActions';
import * as siteActions from '../../_SiteController/SiteActions';
import * as NotificationsMenuActions from '../../NotificationsMenuController/NotificationsMenuActions';
import store, { RootState } from '../../../utils/_store';
import { loadActions } from './ActionsService';
import { Action } from '../../../@Types/@Types';
import Types from '../../../constants/ActionTypes';
import { fetchClassifiers } from '../../_SiteController/Services/ClassifiersService';
import { addClassifiers } from '../../_SiteController/SiteActions';
import axiosInstance from '../../../AxiosAPI';
import { Process } from '../../../@Types/ProcessTypes/Process';
import { loadProcessHistoriesByIds } from '../../ProcessController/ProcessHistoryService';

/** Selector that returns the current ipProject */
const getIdProject = (state: RootState): string | undefined | null =>
    state.site.idProject;

const getIdAgent = (state: RootState): string | undefined =>
    state.site.user?._id;

/**
 * Function called when new actions should be rendered(reset,refresh)
 */
function* getActions(): any {
    try {
        const idProject = yield select(getIdProject);
        const pageInfo = yield select((state: RootState) => state.ticketsPage);
        const idAgent = yield select(getIdAgent);

        const idTicket = pageInfo.order[pageInfo.selectedElement];
        if (idTicket !== undefined) {
            /** If socket has not connected refresh when id does. */
            if (!axiosInstance.defaults.headers['erk-idsocket']) {
                const interval = setInterval(async () => {
                    if (axiosInstance.defaults.headers['erk-idsocket']) {
                        store.dispatch(actions.refresh());
                        clearInterval(interval);
                    }
                }, 1000);
                setTimeout(() => {
                    clearInterval(interval);
                }, 10000);
            }

            let result = yield call(loadActions, idProject, idTicket, idAgent);
            const actionsList = result.actions;
            yield call(checkClassifiers, actionsList);
            yield fork(fetchProcesses, idProject, result.actions);
            const numInteractions = actionsList.filter((action: Action) =>
                Interactions.includes(action.type)
            ).length;
            yield put(
                actions.getSuccess({
                    elements: actionsList,
                    numInteractions: numInteractions as number,
                    numActions: result.numActions as number,
                })
            );
            const ticket = pageInfo.elements[idTicket];
            yield put(TicketActions.removeAllNotification(idTicket));
            yield put(
                NotificationsMenuActions.markTicketGroupsAsRead(idTicket)
            );
            yield put(
                siteActions.removeNotificationIds(
                    Object.keys(ticket.notifications)
                )
            );
        }
    } catch (error) {
        //TODO handle errors
        console.error(error);
    }
}

/**
 * Function called to handle fetching the missing classifiers if any
 * @param tickets to check
 */
function* checkClassifiers(actions: Action[]): any {
    try {
        const classifiers = yield select(
            (state: RootState) => state.site.classifiers
        );
        const missingClassifiers: string[] = [];
        /** Iterate all actions */
        for (const action of actions) {
            /** If action has classifers check if they exist in the store */
            if (
                action.type === Types.AUTO_CLASSIFY ||
                action.type === Types.CLASSIFY
            ) {
                for (const classifier of action.classifiers) {
                    /** if it doesnt exist add it to the missing array */
                    if (!classifiers[classifier.idRoot]) {
                        missingClassifiers.push(classifier.idRoot);
                    }
                    if (
                        classifier.idOriginal &&
                        !classifiers[classifier.idOriginal]
                    ) {
                        missingClassifiers.push(classifier.idOriginal);
                    }
                    if (
                        classifier.idValue &&
                        !classifiers[classifier.idValue]
                    ) {
                        missingClassifiers.push(classifier.idValue);
                    }
                }
            }
        }
        if (missingClassifiers.length > 0) {
            const classifiers = yield call(
                fetchClassifiers,
                missingClassifiers
            );
            yield put(addClassifiers({ classifiers }));
        }
    } catch (error) {
        //TODO handle errors
        console.error(error);
    }
}

function* fetchProcesses(idProject: string, actions: Action[]): any {
    try {
        const pageInfo = yield select((state: RootState) => state.ticketsPage);
        const missingProcesses: string[] = [];
        for (const action of actions) {
            if (
                action.type === Types.ACTIVITY &&
                !pageInfo.processes[action.idProcess]
            ) {
                missingProcesses.push(action.idProcess);
            }
        }
        if (missingProcesses.length > 0) {
            const processes: Process[] = yield call(
                loadProcessHistoriesByIds,
                idProject,
                missingProcesses
            );
            yield put(TicketActions.processSuccess(processes));
        }
    } catch (error) {
        //TODO handle errors
        console.error(error);
    }
}

function* watchGetActionsRequest(): any {
    yield takeLatest(
        [
            actions.Types.RESET,
            actions.Types.REFRESH,
            TicketActions.Types.SELECT,
            TicketActions.Types.RESET_SUCCESS,
        ],
        getActions
    );
}

export default [fork(watchGetActionsRequest)];
