import { StepComponentProps } from '../../Step';
import styles from './InfoVideoStep.module.css';
import React, { useEffect, useState } from 'react';
import StepContainer from '../../StepContainer/StepContainer';
import { InfoVideoStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import DraftRenderer from '../../../../../../shared/DraftRenderer/DraftRenderer';
import PlayCircleOutlineRoundedIcon from '@material-ui/icons/PlayCircleOutlineRounded';
import { EditorState, convertFromRaw } from 'draft-js';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../utils/_store';

interface InfoVideoStepProps extends StepComponentProps {
    step: InfoVideoStep;
}

function InfoVideoStepComponent({
    step,
    path,
}: InfoVideoStepProps): JSX.Element {
    const content = convertFromRaw(step.caption);
    const maxWidth = useSelector(
        (state: RootState) => state.conversationEditor.imgMaxWidth
    );
    const [url, setUrl] = useState<string | undefined>(step.file.url);
    const isPlaceholder =
        url === 'https://content.capta.co/Capta/Files/Capta.mp4';
    const { width, ratio } = step;
    const calcHeight = (): number => {
        if (!isNaN(maxWidth) && maxWidth > 0 && maxWidth < width) {
            return maxWidth / ratio + 7;
        } else {
            const newHeight = width / ratio + 7;
            return !isNaN(newHeight) ? newHeight : 0;
        }
    };
    useEffect(() => {
        if (step.file.url !== url) {
            setUrl(undefined);
            setTimeout(() => {
                setUrl(step.file.url);
            }, 500);
        }
    }, [step.file.url]);

    return (
        <StepContainer step={step} path={path}>
            <div
                className={styles.videoContainer}
                style={{ minHeight: calcHeight() }}
            >
                {url !== undefined && (
                    <video
                        className={
                            isPlaceholder
                                ? styles.videoPlayerPlaceHolder
                                : styles.videoPlayer
                        }
                        style={{
                            height: 'auto',
                            width,
                            maxWidth: '100%',
                        }}
                    >
                        <source src={url} /> Your browser does not support HTML5
                        video.
                    </video>
                )}
                {url !== undefined && (
                    <div className={styles.videoIcon}>
                        <PlayCircleOutlineRoundedIcon fontSize="inherit" />
                    </div>
                )}
            </div>
            {content.hasText() && (
                <div
                    className={styles.messageContainer}
                    style={{
                        width:
                            !isNaN(maxWidth) && width > maxWidth
                                ? maxWidth
                                : width,
                    }}
                >
                    <DraftRenderer
                        editorState={EditorState.createWithContent(content)}
                    />
                </div>
            )}
        </StepContainer>
    );
}

export default InfoVideoStepComponent;
