import { StepComponentProps } from '../../Step';
import styles from './ListStep.module.css';
import React, { useRef, useState } from 'react';
import StepContainer from '../../StepContainer/StepContainer';
import DraftRenderer from '../../../../../../shared/DraftRenderer/DraftRenderer';
import { ListStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import { EditorState, convertFromRaw } from 'draft-js';
import ClientReply from '../../ClientReply/ClientReply';
import { Menu, MenuItem } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../utils/_store';
import StepComponent from '../../Step';
import { LocationTypes } from '../../../../../../constants/Conversations/ConversationStepTypes';
import { updateStep } from '../../../../../../controllers/ConversationEditorController/ConversationEditorActions';
import AddStep from '../../../AddStep/AddStep';
interface ListStepProps extends StepComponentProps {
    step: ListStep;
}

function ListStepComponent({
    step,
    path,
    dragging,
}: ListStepProps): JSX.Element {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [openClients, setOpenClients] = useState(false);
    const btnRef = useRef<HTMLDivElement>(null);
    const clientsBtnRef = useRef<HTMLDivElement>(null);
    const selectedOption = step.selectedOption
        ? step.options[step.selectedOption] ?? null
        : null;

    return (
        <React.Fragment>
            {(open || openClients) && (
                <ListMenu
                    idStep={step.id}
                    anchorRef={open ? btnRef : clientsBtnRef}
                    handleClose={(): void => {
                        setOpen(false);
                        setOpenClients(false);
                    }}
                    handleSelect={(id): void => {
                        dispatch(updateStep({ ...step, selectedOption: id }));
                    }}
                />
            )}
            <StepContainer
                step={step}
                path={path}
                header={step.header}
                footer={step.footer}
                bottomComponent={
                    <div className={styles.btnsContainer}>
                        <div
                            onClick={(): void => {
                                setOpen(true);
                            }}
                            className={styles.messageBtn}
                            ref={btnRef}
                        >
                            {step.label ? step.label : '(Por Definir)'}
                        </div>
                    </div>
                }
                disabledBottom
            >
                <div className={styles.messageContainer}>
                    <DraftRenderer
                        editorState={EditorState.createWithContent(
                            convertFromRaw(step.message)
                        )}
                    />
                </div>
            </StepContainer>
            <ClientReply padding={10} title={step.name}>
                <div className={styles.replyContent}>
                    <div
                        className={styles.replyBtn + ' noselect'}
                        ref={clientsBtnRef}
                        onClick={(): void => {
                            setOpenClients(true);
                        }}
                    >
                        {selectedOption ? (
                            selectedOption.label ? (
                                selectedOption.label
                            ) : (
                                '(Por Definir)'
                            )
                        ) : (
                            <label className={styles.unSelected}>
                                Sin Seleccionar
                            </label>
                        )}
                    </div>
                </div>
            </ClientReply>

            {selectedOption &&
                selectedOption.steps.map((idStep: string, index: number) => (
                    <StepComponent
                        key={index}
                        idStep={idStep}
                        path={[
                            ...path,
                            {
                                idStep: step.id,
                                type: LocationTypes.LIST,
                                indexStep: index,
                                idOption: selectedOption.id,
                                label: selectedOption.label,
                            },
                        ]}
                    />
                ))}
            {selectedOption && (
                <AddStep
                    disabled={dragging}
                    location={{
                        idStep: step.id,
                        type: LocationTypes.LIST,
                        indexStep: selectedOption.steps.length,
                        idOption: selectedOption.id,
                        label: null,
                    }}
                />
            )}
            {selectedOption && (
                <div className={styles.endingContainer}>
                    <div className={styles.separator}></div>
                    <div className={styles.endingLbl}>
                        Fin:{' '}
                        {selectedOption.label
                            ? selectedOption.label
                            : '(Por Definir)'}
                    </div>
                    <div className={styles.endSeparator}></div>
                </div>
            )}
        </React.Fragment>
    );
}
export default ListStepComponent;

interface ListMenuProps {
    idStep: string;
    anchorRef: any;
    handleClose: Function;
    handleSelect: (id: string) => void;
}
function ListMenu({
    idStep,
    anchorRef,
    handleClose,
    handleSelect,
}: ListMenuProps): JSX.Element {
    const step: ListStep = useSelector(
        (state: RootState) =>
            state.conversationEditor.steps[idStep ?? ''] as ListStep
    );
    if (!step) {
        return <div></div>;
    }
    return (
        <Menu
            anchorEl={anchorRef.current}
            open={true}
            onClose={(): void => {
                handleClose();
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            MenuListProps={{
                style: { minWidth: 130 },
            }}
        >
            {step.sections.map((section, index) => {
                return (
                    <div className={styles.sectionMenu} key={index}>
                        {section.label && (
                            <div className={styles.sectionMenuLbl}>
                                {section.label}
                            </div>
                        )}
                        {section.options.map((idOption) => {
                            const option = step.options[idOption];
                            return (
                                <MenuItem
                                    onClick={(): void => {
                                        handleSelect(idOption);
                                        handleClose();
                                    }}
                                    key={idOption}
                                >
                                    <div
                                        style={{
                                            minWidth:
                                                anchorRef.current?.offsetWidth -
                                                    32 ?? 0,
                                        }}
                                        className={styles.option}
                                    >
                                        {option.label
                                            ? option.label
                                            : '(Por Definir)'}
                                        <div
                                            className={styles.optionDescription}
                                        >
                                            {option.description}
                                        </div>
                                        {option.classifier && (
                                            <img
                                                className={
                                                    styles.classifierIcon
                                                }
                                                alt={'shapes'}
                                                src={'/media/icons/shapes.svg'}
                                            />
                                        )}
                                    </div>
                                </MenuItem>
                            );
                        })}
                    </div>
                );
            })}
        </Menu>
    );
}
