import styles from '../TicketContent/TicketContent.module.css';

export interface ActivityContentProps {}

export function ActivityContent({}: ActivityContentProps): JSX.Element {
    return (
        <div className={styles.container}>
            <div className={styles.header}>Actividad Asignada:</div>
            <div className={styles.content}>
                Tienes una nueva actividad asignada!
            </div>
        </div>
    );
}
