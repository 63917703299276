import React, { useRef, useState } from 'react';
import styles from './ClientDetail.module.css';
import ClientRenderer from '../../../../shared/ClientRenderer/ClientRenderer';
import TicketsService, {
    updateTicketClient,
} from '../../../../controllers/TicketsController/TicketsService';
import { Client } from '../../../../@Types/Client';
import { Ticket } from '../../../../@Types/TicketTypes/Ticket';
import VanillaToasts from '../../../../shared/Toast/Toast.js';
import ClientsMenu from '../../../../shared/@Menus/ClientsMenu/ClientsMenu';
import ConfirmDialog from '../../../../shared/ConfirmDialog/ConfirmDialog';
import Dialog from '../../../../shared/Dialog/Dialog';
import AgentIcon from '../../../../Icons/AgentIcon';
import { refreshCurrent } from '../../../../controllers/TicketsController/TicketsActions';
import { useDispatch } from 'react-redux';

interface ClientDetailProps {
    ticket: Ticket;
    /**Ticket client */
    client: Client;
    refreshClient: Function;
}
/**
 * Component rendered inside the create area dialog.
 */
function ClientDetail({
    ticket,
    client,
    refreshClient,
}: ClientDetailProps): JSX.Element {
    const [showMenu, setShowMenu] = useState(false);
    const [showView, setShowView] = useState<Client>();
    const [confirmation, setConfirmation] = useState<Client>();
    const switchRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    return (
        <div className={styles.container + ' standard-dialog'}>
            <div className={styles.title}>
                Información del cliente
                <div
                    className={styles.swapIcon}
                    ref={switchRef}
                    onClick={(): void => {
                        setShowMenu(true);
                    }}
                    title={'Unificar con otro cliente'}
                >
                    <AgentIcon
                        fill="var(--primary1)"
                        style={{
                            width: 15,
                            height: 15,
                            border: '2px solid var(--primary1)',
                            borderRadius: 20,
                            padding: 2,
                        }}
                    />
                    <AgentIcon
                        fill="var(--primary1)"
                        style={{
                            marginLeft: -8,
                            width: 15,
                            height: 15,
                            border: '2px solid var(--primary1)',
                            borderRadius: 20,
                            padding: 2,
                        }}
                    />
                </div>
            </div>
            {showView && (
                <Dialog
                    open={!!showView}
                    maxWidth={'90vw'}
                    onClose={(): void => {
                        setShowView(undefined);
                    }}
                    zIndex={1301}
                >
                    <div className={styles.clientContainer}>
                        <ClientRenderer client={showView} editable={false} />
                    </div>
                </Dialog>
            )}
            {showMenu && (
                <ClientsMenu
                    zIndex={1301}
                    anchorRef={switchRef}
                    handleClose={(): void => {
                        if (showView) return;
                        setShowMenu(false);
                    }}
                    handleView={(client: Client): void => {
                        setShowView(client);
                    }}
                    title={'Unificar Clientes'}
                    placement="bottom-end"
                    multiple={false}
                    confirm={'Unificar'}
                    elementsToOmit={[]}
                    handleConfirm={async (client): Promise<void> => {
                        setShowMenu(false);
                        setConfirmation(client);
                    }}
                />
            )}
            {confirmation && (
                <ConfirmDialog
                    onClose={(): void => {
                        setConfirmation(undefined);
                    }}
                    element={confirmation}
                    msg="¿Está seguro de que desea unificar estos clientes?"
                    onConfirm={async (
                        element: Client,
                        setLoading: (loading: boolean) => void
                    ): Promise<void> => {
                        setLoading(true);
                        try {
                            await TicketsService.mergeClients(
                                ticket.idProject,
                                ticket._id,
                                element._id
                            );
                            setConfirmation(undefined);
                            dispatch(refreshCurrent());
                        } catch (error) {
                            VanillaToasts.create({
                                title: 'Error al unificar clientes',
                                text: error,
                                type: 'error',
                                timeout: 3000,
                            });
                            console.error(error);
                        }
                        setLoading(false);
                    }}
                    title={'Unificar Clientes'}
                    btnMsg={'Unificar'}
                />
            )}
            <ClientRenderer
                client={client}
                handleSave={async (pClient): Promise<void> => {
                    await updateTicketClient(
                        ticket.idProject,
                        ticket._id,
                        pClient
                    );
                }}
                refresh={refreshClient}
                editable={true}
                showEntityValues={true}
            />
        </div>
    );
}
export default ClientDetail;
