import styles from './FlowsPage.module.css';
import React, { useState, useRef, useEffect } from 'react';

import { ReactFlowProvider, Edge, Node } from 'react-flow-renderer';
import NoMobile from '../../shared/NoMobile/NoMobile';
import FlowsService from '../../controllers/FlowsController/FlowsService';
import Sidebar from './FlowSideBar/FlowSideBar';
import { withRouter, match, Prompt } from 'react-router-dom';
import Loader from '../../shared/Loader/Loader';
import FlowDialog, { DialogObjType } from './FlowsDialog/FlowsDialog';
import { Agent, Area, Flow } from '../../@Types/@Types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../utils/_store';
import { changeProject } from '../../controllers/_SiteController/SiteActions';
import RoundedButton from '../../shared/RoundedButton/RoundedButton';
import SaveDialog from './SaveDialog/SaveDialog';
//Todo al salir debe salir el alert de si esta seguro de salir sin guardar.
import FlowComponent from './FlowComponent';
import { RouterProps } from '../../Router';
import FlowTypes from '../../constants/Flows/FlowTypes';
import { checkAdmin } from '../../utils/PermissionsFunctions';
import {
    PayloadEditorActions,
    usePayloadEditorDispatch,
} from '../../controllers/PayloadEditorController/PayloadEditorSlice';
interface IdProjectRouteParam {
    /** Current Project */
    idProject: string;
    /** Current Flow */
    idFlow: string;
}

function FlowsPage({
    match,
    mobile,
}: {
    match: match<IdProjectRouteParam>;
} & RouterProps): JSX.Element {
    const dispatch = useDispatch();
    const payloadEditorDispatch = usePayloadEditorDispatch();
    const [loading, setLoading] = useState(true);
    const [flow, setFlow] = useState<Flow>();
    const [agents, setAgents] = useState<Agent[]>();
    const [areas, setAreas] = useState<Area[]>();
    const siteInfo = useSelector((state: RootState) => state.site);
    const [saving, setSaving] = useState<boolean>();
    const [elements, setElements] = useState<(Node | Edge)[]>([]);
    const reactFlowWrapper = useRef<HTMLDivElement>(null);
    const [dialogObj, setDialogObj] = useState<DialogObjType>(undefined);
    const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);
    const [nodeToFocus, setNodeToFocus] = useState<string | undefined>(
        undefined
    );

    useEffect((): void => {
        const id = match.params.idFlow;
        const idProj = match.params.idProject;
        if (siteInfo.idProject !== idProj) {
            dispatch(changeProject({ idProject: idProj }));
        } else {
            if (id && idProj && !mobile) {
                loadFlow(siteInfo.idProject, id);
            }
        }
    }, [mobile, siteInfo.idProject, match.params.idProject]);

    useEffect(() => {
        const onBeforeUnload = (e: any): void => {
            if (checkAdmin(siteInfo.user)) {
                e.preventDefault();
                e.returnValue = '';
            }
        };
        window.addEventListener('beforeunload', onBeforeUnload);
        return (): void => {
            payloadEditorDispatch(
                PayloadEditorActions.clearPayloadEditorByStartsWith(
                    `Flow-${match.params.idFlow}`
                )
            );
            window.removeEventListener('beforeunload', onBeforeUnload);
        };
    }, []);

    const loadFlow = async (idProject: string, id: string): Promise<void> => {
        const resp = await FlowsService.loadFlow(idProject, id);
        setFlow(resp.flow);
        setAgents(resp.agents);
        setAreas(resp.areas);
    };
    return (
        <div className={styles.pageContainer}>
            {mobile && <NoMobile />}
            {checkAdmin(siteInfo.user) && <Prompt message="Salir?" />}
            {loading && (
                <div className={styles.whiteCurtain}>
                    <Loader size={100} />
                </div>
            )}
            {flow !== undefined && (
                <ReactFlowProvider>
                    <FlowDialog
                        idFlow={match.params.idFlow}
                        dialogObj={dialogObj}
                        setElements={setElements}
                        elements={elements}
                        flowType={flow.type}
                        setDialogObj={setDialogObj}
                    />
                    {saving && (
                        <SaveDialog
                            handleFocusNode={setNodeToFocus}
                            onClose={(): void => {
                                setSaving(false);
                            }}
                            setElements={setElements}
                            flow={flow}
                            reactFlowInstance={reactFlowInstance}
                        />
                    )}
                    <div className={styles.container + ' dndflow noselect'}>
                        <Sidebar
                            areas={areas}
                            agents={agents}
                            entry={flow.type === FlowTypes.ENTRY}
                        />
                        <div
                            className={
                                styles.flowWrapper + ' reactflow-wrapper'
                            }
                            ref={reactFlowWrapper}
                        >
                            <FlowComponent
                                flow={flow}
                                areas={areas}
                                agents={agents}
                                elements={elements}
                                dialogObj={dialogObj}
                                setLoading={setLoading}
                                setElements={setElements}
                                nodeToFocus={nodeToFocus}
                                setDialogObj={setDialogObj}
                                setNodeToFocus={setNodeToFocus}
                                reactFlowWrapper={reactFlowWrapper}
                                reactFlowInstance={reactFlowInstance}
                                setReactFlowInstance={setReactFlowInstance}
                            />
                            <div className={styles.save}>
                                <RoundedButton
                                    text="Guardar"
                                    fontSize={25}
                                    borderRadius={12}
                                    padding="20px 22px"
                                    onClick={(): void => {
                                        setSaving(true);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </ReactFlowProvider>
            )}
        </div>
    );
}

export default withRouter((props: any) => <FlowsPage {...props} />);
