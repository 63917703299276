import React, { useState } from 'react';
import { FormStep } from '../../../@Types/FormTypes/FormStep';
import styles from './StepConfigBtn.module.css';
import GearsIcon from '../../../Icons/GearsIcon';
import Dialog from '../../../shared/Dialog/Dialog';
import Toggle from '../../../shared/Toggle/Toggle';
import FormStepTypes from '../../../constants/FormStepTypes';
import RoundedFormStepPicker from '../../../shared/@Pickers/RoundedFormStepPicker/RoundedFormStepPicker';
import { FormStepLocation } from '../../../@Types/FormTypes/Form';
import { GenericStepProps } from '../../../shared/GenericFormEditor/Step/Step';
import { useCurrentProject } from '../../../hooks';

function StepConfigBtn<S extends FormStep, U, L extends FormStepLocation>({
    step,
    updateStep,
}: GenericStepProps<S, U, L>): JSX.Element {
    const [open, setOpen] = useState(false);
    const project = useCurrentProject();
    if (!project.values?.values) return <div></div>;

    let possibleValues = Object.values(project.values.values);
    if (step.type === FormStepTypes.CLASSIFIER_SELECTOR) {
        possibleValues = possibleValues.filter(
            (value) =>
                value.type === step.type &&
                (!value.idClassifier ||
                    value.idClassifier === step.idClassifier)
        );
    } else if (step.type === FormStepTypes.ENTITYVALUEPICKER) {
        possibleValues = possibleValues.filter(
            (value) =>
                value.type === step.type &&
                (!value.idEntity || value.idEntity === step.idEntity)
        );
    } else {
        possibleValues = possibleValues.filter(
            (value) => value.type === step.type
        );
    }

    const showConfig = possibleValues.length > 0;

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={(): void => setOpen(false)}
                border={20}
                maxWidth="60vw"
            >
                <div className={styles.container}>
                    <div className={styles.valueContainer}>
                        <div className={styles.valueLabel}>
                            Vincular al caso:
                        </div>
                        <div className={styles.toggleContainer}>
                            <Toggle
                                checked={!!step.idValue}
                                onChange={(checked: boolean): void => {
                                    updateStep({
                                        ...step,
                                        idValue: checked
                                            ? possibleValues[0].id
                                            : undefined,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    {step.idValue && (
                        <div className={styles.pickerContainer}>
                            <RoundedFormStepPicker
                                steps={possibleValues as FormStep[]}
                                value={step.idValue}
                                allowCustom
                                containerMargin="0px"
                                handleUpdate={(idValue): void => {
                                    updateStep({
                                        ...step,
                                        idValue,
                                    });
                                }}
                            />
                        </div>
                    )}
                </div>
            </Dialog>
            {showConfig && (
                <div
                    className={styles.configBtn}
                    onClick={(): void => {
                        setOpen(true);
                    }}
                >
                    <GearsIcon className={styles.configIcon} />
                </div>
            )}
        </React.Fragment>
    );
}

export default StepConfigBtn;
