import { UngroupedNotificationContent } from './UngroupedNotificationContent/UngroupedNotificationContent';
import { JSX } from 'react';
import styles from './UngroupedNotification.module.css';
import { Link } from 'react-router-dom';
import { ActionNotification } from '../../../../../../../@Types/GroupedMenuNotification';
import { renderNotificationIcon } from '../../../../NotificationFilterOptions/NotificationFilterOption';
import { Ticket } from '../../../../../../../@Types/@Types';
import ActionTypes from '../../../../../../../constants/ActionTypes';

export interface UngroupedNotificationProps {
    ticket: Ticket;
    notification: ActionNotification;
}

export function UngroupedNotification({
    ticket,
    notification,
}: UngroupedNotificationProps): JSX.Element {
    return (
        <Link
            to={`/${ticket.idProject}/tickets/${ticket._id}`}
            style={{
                textDecoration: 'none',
                color: 'inherit',
                width: '100%',
                maxWidth: 'calc(100% - 16px)',
            }}
        >
            <div className={styles.container}>
                <div className={styles.infoContainer}>
                    <UngroupedNotificationContent
                        ticket={ticket}
                        notification={notification}
                    />
                </div>
                <div className={styles.leftContainer}>
                    <div className={styles.hour}>
                        {new Date(
                            notification.creation_date
                        ).toLocaleTimeString(navigator.language, {
                            hour: '2-digit',
                            minute: '2-digit',
                        })}
                    </div>
                    <div className={styles.iconContainer}>
                        {renderNotificationIcon(
                            notification.action.type,
                            notification.action.type ===
                                ActionTypes.CLIENT_REPLY
                                ? 40
                                : 45
                        )}
                    </div>
                </div>
            </div>
        </Link>
    );
}
