import { IconProps } from './@IconTypes';

function MegaphoneIcon({ className, style, fill }: IconProps): JSX.Element {
    return (
        <svg
            fill={fill}
            style={style}
            className={className}
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 32 36"
        >
            <path d="M21.7,5.5c-0.8-0.6-1.9-0.7-2.8-0.3l-9.5,4H5c-1.7,0-3,1.3-3,3v3.5c0,1.7,1.3,3,3,3v6.3C5,26.1,5.9,27,6.9,27h1.7  c1.1,0,1.9-0.9,1.9-1.9v-5.8l8.3,3.5c1.5,0.6,3.3-0.1,3.9-1.6c0.2-0.4,0.2-0.8,0.2-1.1V8C23,7,22.5,6.1,21.7,5.5z M4,15.8v-3.5  c0-0.6,0.4-1,1-1h3.5v5.5H5C4.4,16.8,4,16.3,4,15.8z M8.5,25L7,25.1v-6.3h1.5V25z M21,20c0,0.6-0.4,1-1,1c-0.1,0-0.3,0-0.4-0.1  l-9.1-3.8V11l9.1-3.9c0.5-0.2,1.1,0,1.3,0.5C21,7.8,21,7.9,21,8V20z" />
            <path d="M25,15h4c0.6,0,1-0.4,1-1s-0.4-1-1-1h-4c-0.6,0-1,0.4-1,1S24.4,15,25,15z" />
            <path d="M25,11c0.2,0,0.4-0.1,0.6-0.2l4-3c0.4-0.3,0.5-1,0.2-1.4c-0.3-0.4-1-0.5-1.4-0.2l-4,3c-0.4,0.3-0.5,1-0.2,1.4  C24.4,10.9,24.7,11,25,11z" />
            <path d="M25.6,17.2c-0.4-0.3-1.1-0.2-1.4,0.2c-0.3,0.4-0.2,1.1,0.2,1.4l4,3c0.4,0.3,1.1,0.2,1.4-0.2c0.3-0.4,0.2-1.1-0.2-1.4l0,0  L25.6,17.2z" />
        </svg>
    );
}
export default MegaphoneIcon;
