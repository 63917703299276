import styles from './Detail.module.css';
import { cloneElement } from 'react';
import Loader from '../../Loader/Loader';

interface DetailProps {
    /** If the detail should show the loader */
    loading: boolean;
    /** The elements to display */
    children: any;
    /** The currently selected element */
    selectedElement: any;
    /** The label to display if no element is selected */
    label: string;
    /** If the detail has paddingAndScroll */
    paddingAndScroll: boolean;
    /** If the layout is in extra small mode */
    xsm?: boolean;
}
function Detail({
    loading,
    children,
    selectedElement,
    label,
    paddingAndScroll,
}: DetailProps): JSX.Element {
    if (loading) {
        return (
            <div className={styles.containerLoader}>
                <Loader />;
            </div>
        );
    } else {
        return (
            <div
                style={
                    paddingAndScroll
                        ? {
                              padding: '20px 35px 0 35px',
                              width: 'calc(100% - 70px)',
                              height: 'calc(100% - 20px)',
                              overflowY: 'scroll',
                          }
                        : {
                              padding: 0,
                              width: '100%',
                              height: '100%',
                          }
                }
                className={styles.container}
            >
                {!selectedElement && (
                    <div
                        className={styles.specialMessageContainer + ' flexbox'}
                    >
                        <h5 className={styles.specialMessage}>{label}</h5>
                    </div>
                )}
                {/* TODO Review this. */}
                {/* {!selectedElement && router?.pathname?.endsWith('/areas') && (
                    <div
                        className={styles.specialMessageContainer + ' flexbox'}
                    >
                        <div className={styles.flowContainer}>
                            <div className={styles.flowTitle}>
                                Flujo Principal
                            </div>
                            <img
                                alt={'Flow'}
                                className={styles.flowFoto}
                                src={'/media/flow.png'}
                            ></img>
                            <div className={styles.flowBtn}>
                                <RoundedButton
                                    borderRadius={15}
                                    fontSize={20}
                                    padding={'20px 15px 20px 15px'}
                                    text="Ver Flujo"
                                    backgroundColor="var(--secondary)"
                                    color="white"
                                    onClick={() => {
                                        // router.push(
                                        //     '/flows/' +
                                        //         router.query.project.idflow
                                        // );
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )} */}
                {selectedElement &&
                    cloneElement(children, {
                        selectedElement,
                    })}
            </div>
        );
    }
}

export default Detail;
