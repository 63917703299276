import styles from './ParentSideBar.module.css';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Ticket } from '../../../../../@Types/@Types';
import { goTo } from '../../../../../controllers/TicketsController/TicketsActions';
import StatePill from '../../../../../shared/StatePill/StatePill';
import { RouterProps, withRouter } from 'react-router-dom';
interface ParentSideBarProps extends RouterProps {
    /** currently displayed ticket */
    selectedElement: Ticket;
}
function ParentSideBar({
    history,
    selectedElement,
}: ParentSideBarProps): JSX.Element {
    const dispatch = useDispatch();
    const headerRef = useRef<HTMLDivElement>(null);
    const calcSubTickets = (): JSX.Element => {
        return (
            <div
                title={selectedElement.father?.subject}
                className={styles.subTicketSubject}
                key={0}
                onClick={(): void => {
                    history.push(
                        `/${selectedElement?.father?.idProject}/tickets/${selectedElement?.father?._id}`
                    );
                    dispatch(
                        goTo({
                            idTicket: selectedElement?.father?._id ?? '',
                            queryString: '',
                        })
                    );
                }}
            >
                <label className={styles.subjectLabel}>
                    {selectedElement.father?.subject
                        ? selectedElement.father?.subject
                        : selectedElement.father?.caseNumber}
                </label>
                <div className={styles.pillContainer}>
                    <StatePill
                        state={undefined}
                        idState={String(selectedElement.father?.state)}
                        hideLabel={true}
                    />
                </div>
            </div>
        );
    };
    return (
        <div className={styles.container}>
            <div
                ref={headerRef}
                className={styles.header + ' noselect'}
                onClick={(): void => {}}
            >
                <div
                    ref={headerRef}
                    className={styles.subHeader + ' noselect'}
                    onClick={(): void => {}}
                >
                    <label className={styles.titleLbl}>Caso Principal</label>
                </div>
                <div className={styles.subTicketContainer}>
                    {calcSubTickets()}
                </div>
            </div>
        </div>
    );
}

export default withRouter((props: any) => <ParentSideBar {...props} />);
