import { InmuebleInfo360 } from '../../../../types';
import styles from './InfoProyecto.module.css';
import { OpenComponent } from '../../../Utils/Openable/OpenComponent';
import React from 'react';
import { empStrOrUndToNA } from '../../../Utils/otherFunctions';

interface InfoProyectoProps {
    infoProyecto: InmuebleInfo360['datosGenerales']['infoProyecto'];
}

export function InfoProyecto({ infoProyecto }: InfoProyectoProps): JSX.Element {
    return (
        <OpenComponent title={'Información Proyecto'} showOpen={true}>
            <div className={styles.baseInfo}>
                <div className={styles.row}>
                    <div className={styles.label}>Super proyecto:</div>
                    <div className={styles.value}>
                        {empStrOrUndToNA(infoProyecto.superProyecto)}
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.label}>Macro proyecto:</div>
                    <div className={styles.value}>
                        {empStrOrUndToNA(infoProyecto.macroProyecto)}
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.label}>Proyecto:</div>
                    <div className={styles.value}>
                        {empStrOrUndToNA(infoProyecto.proyecto)}
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.label}>Tipo Proyecto:</div>
                    <div className={styles.value}>
                        {empStrOrUndToNA(infoProyecto.tipoProyecto)}
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.label}>Dirección:</div>
                    <div className={styles.value}>
                        {empStrOrUndToNA(infoProyecto.direccion)}
                    </div>
                </div>
            </div>
            <div className={styles.baseInfo}>
                <div className={styles.row}>
                    <div className={styles.label}>Entidad Fiduciaria:</div>
                    <div className={styles.value}>
                        {empStrOrUndToNA(
                            infoProyecto.entidadFiduciaria.razonSocial
                        )}
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.label}>Nit:</div>
                    <div className={styles.value}>
                        {empStrOrUndToNA(infoProyecto.entidadFiduciaria.nit)}
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.label}>
                        Entidad Banco Constructor:
                    </div>
                    <div className={styles.value}>
                        {empStrOrUndToNA(
                            infoProyecto.entidadBancoConstructor.razonSocial
                        )}
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.label}>Nit:</div>
                    <div className={styles.value}>
                        {empStrOrUndToNA(
                            infoProyecto.entidadBancoConstructor.nit
                        )}
                    </div>
                </div>
            </div>
        </OpenComponent>
    );
}
