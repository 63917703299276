import { Name } from '../../../@Types/EntityTypes/EntityProperty';
import styles from './NameProperty.module.css';
import { useController } from 'react-hook-form';
import RoundedTextField from '../../RoundedTextField/RoundedTextField';
import { useState } from 'react';
import { CustomStepProps } from '@arquimedes.co/eureka-forms/dist/FormSteps/CustomStep';

interface NamePropertyProps extends Omit<CustomStepProps, 'step'> {
    step: Name;
    /** children to render */
    children?: React.ReactElement<any, string>;
    creating?: boolean;
}
function NameProperty({
    children,
    step,
    creating,
    editable,
    originalValue,
}: NamePropertyProps): JSX.Element {
    const [editing, setEditing] = useState(false);

    const {
        field,
        fieldState: { error },
    } = useController({
        name: step.id,
        defaultValue: originalValue ?? '',
        rules: {
            required: step.required ? 'Este campo es obligatorio' : undefined,
        },
    });

    if (editing || creating) {
        return (
            <div
                className={styles.container}
                style={{
                    minHeight:
                        step.description || step.required ? '55px' : '48px',
                }}
            >
                <div className={styles.inputContainer}>
                    <RoundedTextField
                        {...field}
                        label={step.label}
                        inputRef={field.ref}
                        required={step.required}
                        fontWeight={400}
                        seed="EF-"
                        helperText={error ?? step.description}
                        error={!!error}
                    />
                </div>
                {children !== undefined && !creating && (
                    <div className={styles.btnsContainer}>{children}</div>
                )}
            </div>
        );
    } else {
        return (
            <div
                className={styles.container}
                style={{
                    minHeight:
                        step.description || step.required ? '55px' : '48px',
                    cursor:
                        !originalValue || (editable ?? step.editable)
                            ? 'pointer'
                            : 'default ',
                }}
                onClick={(): void => {
                    if (!originalValue || (editable ?? step.editable)) {
                        setEditing(true);
                    }
                }}
            >
                <div className={styles.titleLbl}>
                    {originalValue ?? '(Sin Nombre)'}
                </div>
                {children !== undefined && !creating && (
                    <div className={styles.btnsContainer}>{children}</div>
                )}
            </div>
        );
    }
}

export default NameProperty;
