import { EurekaFormProps } from '@arquimedes.co/eureka-forms';
import FormRenderer, { FormRendererProps } from '../FormRenderer';
import Dialog from '../../Dialog/Dialog';
import styles from './FormDialog.module.css';

interface FormDialogProps
    extends Omit<EurekaFormProps, 'formData' | 'valuesData'>,
        FormRendererProps {
    onClose: () => void;
    label?: string;
}

function FormDialogContent({
    label,
    ...others
}: Omit<FormDialogProps, 'onClose'> & {
    scrollToTop?: () => void;
}): JSX.Element {
    return (
        <div className={styles.formDialogContainer}>
            {label && <div className={styles.containerLbl}>{label}</div>}
            <FormRenderer {...others} />
        </div>
    );
}

function FormDialog({ onClose, ...others }: FormDialogProps): JSX.Element {
    return (
        <Dialog maxWidth="100%" disableEnforceFocus onClose={onClose}>
            <FormDialogContent {...others} />
        </Dialog>
    );
}

export default FormDialog;
