import { compareArrays } from '../utils/DeepCompare';
import { EurekaDraft, compareDrafts } from './Draft/Draft';

export interface ApiRequest {
    url: EurekaDraft;
    queryParams: ApiRequestQueryParam[];
    // /** Esto se utiliza para mostrar o no mostrar cosas */
    // integration?: ApiRequestIntegrationTypes;
}

export interface ApiRequestQueryParam {
    key: string;
    value: EurekaDraft;
    required: boolean;
}

export interface ApiRequestOption {
    id: string;
    label: string;
    value?: any;
}

export function compareRequests(
    actual: ApiRequest | undefined,
    expected: ApiRequest | undefined
): boolean {
    if (!actual || !expected) return true;
    if (!compareDrafts(actual.url, expected.url)) return false;
    return compareArrays(
        actual.queryParams,
        expected.queryParams,
        compareQueryParams
    );
}

export function compareQueryParams(
    actual: ApiRequestQueryParam | undefined,
    expected: ApiRequestQueryParam | undefined
): boolean {
    if (!actual || !expected) return true;
    return (
        actual.key === expected.key &&
        actual.required === expected.required &&
        compareDrafts(actual.value, expected.value)
    );
}
