import styles from './RootClassifiersMenu.module.css';
import { useState, useRef, useMemo } from 'react';
import Search from '../../Search/Search';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Dialog from '../../Dialog/Dialog';
import { useAppSelector } from '../../../hooks';
import ClassifierComponent from './Classifier/Classifier';

interface ClassifiersMenuProps {
    /** Element the menu is anchored to (classifiers button in the sidebar) */
    anchorRef: any;
    /** Function called when the menu closes */
    handleClose: Function;
    /** Function called when classifiers change */
    handleChange: (classifiers: string[]) => void;
    /** The currently selected root classifiers */
    value: string[];
    /** The Label of the menu */
    menuLabel?: string;
    /** if multiple classifiers can be selected */
    multiple?: boolean;
    /** The Zindex to the display the menu at (default 3) */
    zIndex?: number;
    /** If the app is mobile */
    mobile?: boolean;
}
function ClassifiersMenu({
    value,
    anchorRef,
    handleClose,
    menuLabel = 'Clasificar',
    handleChange,
    zIndex = 3,
    multiple = true,
    mobile = false,
}: ClassifiersMenuProps): JSX.Element {
    const classifiers = useAppSelector((state) => state.site.classifiers);

    const roots = useAppSelector((state) => state.site.projectRoots);

    const [search, setSearch] = useState<string | undefined>(undefined);

    const emptyRef = useRef<HTMLDivElement>(null);

    const data = useMemo(() => {
        let data = roots
            .map((idRoot) => ({
                ...classifiers[idRoot],
                selected: value.includes(idRoot),
            }))
            .filter((c) => !!c?.active);
        if (search) {
            const regex = new RegExp(search, 'gi');
            data = data.filter(
                (classifier) =>
                    classifier.name.match(regex) ||
                    classifier.description.match(regex)
            );
        }

        return data;
    }, [search, classifiers, roots, value]);

    const renderContent = (): JSX.Element => {
        return (
            <div
                className={
                    mobile ? styles.mobileContainer : styles.emptyContainer
                }
                onClick={(e): void => {
                    if (emptyRef.current === e.target) handleClose();
                }}
                ref={emptyRef}
            >
                <div className={styles.container}>
                    <div className={styles.title}>{menuLabel}</div>
                    <div className={styles.searchContainer}>
                        <Search
                            wait={500}
                            placeholder={'Filtrar clasificadores'}
                            search={search}
                            handleSearch={(value: any): void => {
                                if (value.trim() === '') {
                                    setSearch(undefined);
                                } else {
                                    setSearch(value);
                                }
                            }}
                        />
                    </div>
                    {data.length > 0 ? (
                        <div
                            className={styles.listContainer}
                            style={{
                                borderTop: '1px solid var(--light-grey)',
                            }}
                        >
                            {data.map((classifier, index) => (
                                <ClassifierComponent
                                    key={index}
                                    classifier={classifier}
                                    selected={classifier.selected}
                                    handleRemove={(): void => {
                                        handleChange(
                                            value.filter(
                                                (id) => id !== classifier._id
                                            )
                                        );
                                    }}
                                    handleSelect={(): void => {
                                        if (multiple) {
                                            if (
                                                value.includes(classifier._id)
                                            ) {
                                                handleChange(
                                                    value.filter(
                                                        (id) =>
                                                            id !==
                                                            classifier._id
                                                    )
                                                );
                                            } else {
                                                handleChange([
                                                    ...value,
                                                    classifier._id,
                                                ]);
                                            }
                                        } else {
                                            handleChange([classifier._id]);
                                        }
                                    }}
                                />
                            ))}
                        </div>
                    ) : (
                        <div
                            className={styles.emptyContainer}
                            style={{
                                borderTop: '1px solid var(--light-grey)',
                            }}
                        >
                            No hay clasificadores
                        </div>
                    )}
                </div>
            </div>
        );
    };
    if (mobile) {
        return (
            <Dialog
                onClose={(): void => handleClose()}
                maxWidth="100vw"
                border={10}
                transparent
            >
                {renderContent()}
            </Dialog>
        );
    } else {
        return (
            <ClickAwayListener
                mouseEvent="onMouseDown"
                onClickAway={(): void => handleClose()}
            >
                <Popper
                    open={true}
                    anchorEl={anchorRef.current}
                    placement={'bottom-start'}
                    modifiers={{
                        preventOverflow: {
                            enabled: true,
                            priority: ['top', 'bottom', 'left', 'right'],
                            boundariesElement: 'window',
                        },
                    }}
                    style={{ zIndex }}
                >
                    {renderContent()}
                </Popper>
            </ClickAwayListener>
        );
    }
}
export default ClassifiersMenu;
