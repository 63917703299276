import React, { useState } from 'react';
import styles from './FailedBottom.module.css';
import ActionsService from '../../../../../../../../controllers/TicketsController/Actions/ActionsService';
import * as RepliesCache from '../../../../../../../../utils/ReplyCache';
import { useDispatch } from 'react-redux';
import VanillaToast from '../../../../../../../../shared/Toast/Toast';
import { TempReplyAction } from '../../../../../../../../@Types/Action';
import {
    removeAction,
    updateActionType,
} from '../../../../../../../../controllers/TicketsController/Actions/ActionsActions';
import RoundedIconButton from '../../../../../../../../shared/RoundedIconButton/RoundedIconButton';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import CancelIcon from '@material-ui/icons/CloseRounded';
import ActionTypes from '../../../../../../../../constants/ActionTypes';
import WarningIcon from '@material-ui/icons/Warning';
import CustomTimeout from '../../../../../../../../utils/CustomTimeout';
import ConfirmDialog from '../../../../../../../../shared/ConfirmDialog/ConfirmDialog';
import RoundedPayload from '../../../../../../../../shared/RoundedPayload/RoundedPayload';
import { Ticket } from '../../../../../../../../@Types/TicketTypes/Ticket';
import { ChannelTypes } from '../../../../../../../../constants/ChannelTypes';

interface FailedBottomProps {
    element: TempReplyAction;
    refreshCache: Function;
    ticket: Ticket;
}

export function FailedBottom({
    element,
    refreshCache,
    ticket,
}: FailedBottomProps): JSX.Element {
    const { idProject, idTicket, _id } = element;
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const delay = ticket.channel?.type == ChannelTypes.WHATSAPP ? 10000 : 40000;
    const dispatch = useDispatch();

    const handleDeleteClick = (): void => {
        setOpenDeleteConfirmation(true);
    };

    const handleCancelDelete = (): void => {
        setOpenDeleteConfirmation(false);
    };

    const handleConfirmDelete = async (): Promise<void> => {
        try {
            await ActionsService.deleteTempReply(idProject, idTicket, _id);
            dispatch(removeAction(_id));
            RepliesCache.removeReply(idProject, idTicket);
        } catch (error) {
            VanillaToast.create({
                title: 'Failed to delete',
                text: error,
                type: 'error',
                timeout: 3000,
            });
        }
    };

    const handleRetry = async (): Promise<void> => {
        const timeout = new CustomTimeout(async () => {
            try {
                const result = await ActionsService.persistTempReply(
                    idProject,
                    idTicket,
                    _id
                );
                dispatch(
                    updateActionType({
                        idAction: _id,
                        type: ActionTypes.REPLY,
                        date: new Date(result.creation_date),
                    })
                );
                RepliesCache.removeReply(idProject, idTicket);
            } catch (error) {
                RepliesCache.setReplyAsFailed(idProject, idTicket, _id);
            }
        }, delay);
        RepliesCache.resetFailedReply(idProject, idTicket, _id, timeout);
        refreshCache();
    };

    return (
        <div className={styles.container}>
            {openDeleteConfirmation && (
                <ConfirmDialog
                    element={element}
                    onClose={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                    title={'Descartar Respuesta'}
                    deleting
                    btnMsg="Descartar"
                    msg={'¿seguro que desea descartar su respuesta?'}
                >
                    <div
                        style={{
                            marginBottom: '15px',
                        }}
                    >
                        <RoundedPayload
                            isResponse
                            payload={element.payload}
                            date={element.creation_date}
                            name={element.agent.name}
                        />
                    </div>
                </ConfirmDialog>
            )}
            <div className={styles.warning}>
                <WarningIcon fontSize="inherit" />
            </div>
            <div className={styles.failedText}>Error al Enviar</div>
            <div className={styles.retryButton} title="Reintentar">
                <RoundedIconButton
                    color="var(--accent)"
                    backgroundColor="var(--defaultGrey)"
                    onClick={handleRetry}
                    hoverColor="white"
                    hoverBackgroundColor="var(--secondary)"
                    padding={'3px'}
                >
                    <RefreshRoundedIcon
                        style={{
                            width: 14,
                            height: 14,
                        }}
                    />
                </RoundedIconButton>
            </div>
            <div className={styles.cancelButton} title="Cancelar">
                <RoundedIconButton
                    color="var(--accent)"
                    backgroundColor="var(--defaultGrey)"
                    onClick={handleDeleteClick}
                    hoverColor="white"
                    hoverBackgroundColor="var(--secondary)"
                    padding={'3px'}
                >
                    <CancelIcon
                        style={{
                            width: 14,
                            height: 14,
                        }}
                    />
                </RoundedIconButton>
            </div>
        </div>
    );
}
