import { MenuItem } from '@material-ui/core';
import React from 'react';
import styles from './Condition.module.css';
import { ConditionProps } from '../Condition';
import { TextCondition } from '../../../@Types/ConditionTypes/TicketPropertyConditions';
import RoundedSelect from '../../RoundedSelect/RoundedSelect';
import ConditionOperators from '../../../constants/Conditions/ConditionOperators';
import RoundedMultiSelect from '../../RoundedMultiSelect/RoundedMultiSelect';

interface TextConditionProps extends ConditionProps<TextCondition> {}
function TextConditionComponent({
    condition,
    hoverDelete,
    handleUpdate,
}: TextConditionProps): JSX.Element {
    return (
        <React.Fragment>
            <div className={styles.operatorContainer}>
                <div className={styles.selectorContainer}>
                    <RoundedSelect
                        value={condition.operator ?? ''}
                        label="Operador"
                        containerMargin="0px"
                        error={hoverDelete}
                        handleUpdate={(event): void => {
                            handleUpdate({
                                ...condition,
                                operator: event.target.value,
                            });
                        }}
                    >
                        <MenuItem value={ConditionOperators.INCLUDES}>
                            Contiene
                        </MenuItem>
                        <MenuItem value={ConditionOperators.NOTINCLUDES}>
                            No contiene
                        </MenuItem>
                    </RoundedSelect>
                </div>
            </div>
            <div className={styles.valueContainer}>
                <div className={styles.multiSelectContainer}>
                    <RoundedMultiSelect
                        values={condition.values ?? []}
                        options={[]} //TODO: generar sugestiones.
                        maxTags={5}
                        label="Valores"
                        fullWidth
                        error={hoverDelete}
                        handleUpdate={(values: string[]): void => {
                            handleUpdate({
                                ...condition,
                                values: values,
                            });
                        }}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}
export default TextConditionComponent;

export const checkForTextChanges = (
    prevCondition: TextCondition,
    nextCondition: TextCondition
): boolean => {
    if (
        prevCondition.values?.length !== nextCondition.values?.length ||
        !prevCondition.values?.every((v, i) => v === nextCondition.values[i])
    ) {
        return false;
    }
    return true;
};
