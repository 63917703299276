import { Classifier } from '../../../../@Types/Classifier';
import styles from './SubClassifier.module.css';
interface SubstructureGridElementProps {
    /** The Classifer to display */
    element: Classifier;
}
function SubstructureGridElement({
    element,
}: SubstructureGridElementProps): JSX.Element {
    return (
        <div className={styles.container}>
            <img
                className={styles.iconContainer}
                alt={'shapes'}
                src={'/media/icons/shapes.svg'}
            />
            <div className={styles.label} title={element.name}>
                {element.name}
            </div>
        </div>
    );
}

export default SubstructureGridElement;
