import { MenuItem } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import ConditionOperators, {
    ConditionEqualsOperators,
} from '../../../../constants/Conditions/ConditionOperators';
import EntityProperties from '../../../../constants/EntityPropertyTypes';
import RoundedCompanyPicker from '../../../@Pickers/RoundedCompanyPicker/RoundedCompanyPicker';
import RoundedEntityPropertyPicker from '../../../@Pickers/RoundedEntityPropertyPicker/RoundedEntityPropertyPicker';
import RoundedSelect from '../../../RoundedSelect/RoundedSelect';
import { RootState } from '../../../../utils/_store';
import styles from '../Condition.module.css';
import entityStyles from './EntityCondition.module.css';
import EntityPropertyConditionComponent from './EntityPropertyCondition/EntityPropertyCondition';
import RoundedEntityValuePicker from '../../../@Pickers/RoundedEntityValuePicker/RoundedEntityValuePicker';
import { ConditionProps } from '../../Condition';
import produce from 'immer';
import { EntityValueCondition } from '../../../../@Types/ConditionTypes/Condition';
import { isIntegration } from '../../../../@Types/Integration';
import { IntegrationsApi } from '../../../../controllers/IntegratrionsController/IntegrationsService';
import { useIdProject } from '../../../../hooks';
import IntegrationTypes from '../../../../constants/IntegrationTypes';
import IntegrationCondition from '../IntegrationCondition';

const PrivatePropertyConditionTypes = [
    EntityProperties.SELECTOR, //COMENTAR/DESCOMENTAR PARA VER MAS OPCIONES
    EntityProperties.TEXTINPUT,
    EntityProperties.TEXTAREA,
    EntityProperties.CHECKBOX,
    EntityProperties.AGENTPICKER,
    EntityProperties.DATEPICKER,
    EntityProperties.TIMEPICKER,
];
const PublicPropertyConditionTypes = [
    EntityProperties.SELECTOR, //COMENTAR/DESCOMENTAR PARA VER MAS OPCIONES
    EntityProperties.TEXTINPUT,
    EntityProperties.TEXTAREA,
    EntityProperties.CHECKBOX,
    EntityProperties.DATEPICKER,
    EntityProperties.TIMEPICKER,
];

interface EntityConditionProps extends ConditionProps<EntityValueCondition> {}
function EntityConditionComponent({
    context,
    condition,
    hoverDelete,
    handleUpdate,
}: EntityConditionProps): JSX.Element {
    const idProject = useIdProject();
    const entity = useSelector(
        (state: RootState) =>
            state.site.entities[
                condition.idEntity ??
                    state.site.organization?.idCompanyEntity ??
                    ''
            ]
    );
    const isCompanyEntity = useSelector(
        (state: RootState) =>
            state.site.organization?.idCompanyEntity === entity._id
    );

    if (!entity) {
        return <div></div>;
    }

    const { data: integrations } = IntegrationsApi.useLoadIntegrationsQuery({
        idProject,
        idEntity: entity._id,
        type: IntegrationTypes.CONDITION,
    });

    const hasProperties = useMemo(() => {
        if (!context.entityValue?.public)
            return (
                Object.values(entity.steps).filter((step) =>
                    PrivatePropertyConditionTypes.includes(step.type)
                ).length > 0
            );
        return (
            Object.values(entity.steps).filter((step) =>
                PublicPropertyConditionTypes.includes(step.type)
            ).length > 0
        );
    }, [context, entity]);

    const hasIntegrations = useMemo(() => {
        return integrations && integrations?.length > 0;
    }, [integrations]);

    const renderValue = (): JSX.Element => {
        switch (condition.operator) {
            case ConditionOperators.EQUAL:
            case ConditionOperators.NOTEQUAL:
                if (isCompanyEntity)
                    return (
                        <div className={styles.valueContainer}>
                            <div className={styles.multiSelectContainer}>
                                <RoundedCompanyPicker
                                    value={(condition.values as any) ?? []}
                                    label={'Empresas'}
                                    multiple={true}
                                    height="40px"
                                    error={hoverDelete}
                                    handleUpdate={(companies): void => {
                                        handleUpdate({
                                            ...condition,
                                            values: companies as any,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    );
                else {
                    return (
                        <div className={styles.valueContainer}>
                            <div className={styles.multiSelectContainer}>
                                <RoundedEntityValuePicker
                                    value={(condition.values as any) ?? []}
                                    multiple={true}
                                    height="40px"
                                    error={hoverDelete}
                                    idEntity={entity._id}
                                    handleUpdate={(entityValues): void => {
                                        handleUpdate({
                                            ...condition,
                                            values: entityValues as any,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    );
                }
            case ConditionOperators.INCLUDES:
            case ConditionOperators.NOTINCLUDES:
                return (
                    <React.Fragment>
                        <div className={entityStyles.propertySelectorContainer}>
                            <RoundedEntityPropertyPicker
                                idEntity={entity._id}
                                publicProperties={context.entityValue?.public}
                                value={condition.idProperty}
                                label="Propiedad"
                                propertyTypes={
                                    context.entityValue?.public
                                        ? PublicPropertyConditionTypes
                                        : PrivatePropertyConditionTypes
                                }
                                integrationTypes={[IntegrationTypes.DRAFT]}
                                error={hoverDelete}
                                handleUpdate={(property): void => {
                                    if (isIntegration(property)) {
                                        handleUpdate({
                                            ...condition,
                                            idProperty: property?._id,
                                            propertyType:
                                                EntityProperties.DRAFT_INTEGRATION as any,
                                        });
                                    } else {
                                        handleUpdate({
                                            ...condition,
                                            idProperty: property?.id,
                                            propertyType: property?.type as any,
                                        });
                                    }
                                }}
                            />
                        </div>
                        <EntityPropertyConditionComponent
                            entity={entity}
                            context={context}
                            condition={condition}
                            hoverDelete={hoverDelete}
                            handleUpdate={handleUpdate as any}
                        />
                    </React.Fragment>
                );
            case ConditionOperators.INTEGRATION_MET:
            case ConditionOperators.NOTINTEGRATION_MET:
                return (
                    <React.Fragment>
                        <div className={entityStyles.propertySelectorContainer}>
                            <RoundedEntityPropertyPicker
                                idEntity={entity._id}
                                fullWidth
                                value={condition.idIntegration}
                                label="Integración"
                                propertyTypes={[]}
                                integrationTypes={[IntegrationTypes.CONDITION]}
                                error={hoverDelete}
                                handleUpdate={(property): void => {
                                    if (!isIntegration(property)) return;
                                    handleUpdate({
                                        ...condition,
                                        idIntegration: property?._id,
                                    });
                                }}
                            />
                        </div>
                        <IntegrationCondition
                            context={context}
                            condition={condition}
                            hoverDelete={hoverDelete}
                            handleUpdate={handleUpdate as any}
                            idIntegration={condition.idIntegration}
                        />
                    </React.Fragment>
                );
            default:
                return <div></div>;
        }
    };
    return (
        <React.Fragment>
            <div className={styles.operatorContainer}>
                <div className={styles.selectorContainer}>
                    <RoundedSelect
                        value={condition.operator ?? ''}
                        label="Operador"
                        containerMargin="0px"
                        error={hoverDelete}
                        handleUpdate={(event): void => {
                            handleUpdate(
                                produce(condition, (condition) => {
                                    const operator = event.target.value;
                                    if (
                                        ConditionEqualsOperators.includes(
                                            condition.operator
                                        ) &&
                                        !ConditionEqualsOperators.includes(
                                            operator
                                        )
                                    ) {
                                        delete (condition as any).values;
                                    }
                                    condition.operator = operator;
                                })
                            );
                        }}
                    >
                        <MenuItem value={ConditionOperators.EQUAL}>
                            Es igual a
                        </MenuItem>
                        <MenuItem value={ConditionOperators.NOTEQUAL}>
                            No es igual a
                        </MenuItem>
                        {hasProperties && (
                            <MenuItem value={ConditionOperators.INCLUDES}>
                                Contiene
                            </MenuItem>
                        )}
                        {hasProperties && (
                            <MenuItem value={ConditionOperators.NOTINCLUDES}>
                                No contiene
                            </MenuItem>
                        )}
                        {(isCompanyEntity ||
                            context.entityValue?.idEntity !==
                                condition.idEntity) && (
                            <MenuItem value={ConditionOperators.EXISTS}>
                                Existe
                            </MenuItem>
                        )}
                        {(isCompanyEntity ||
                            context.entityValue?.idEntity !==
                                condition.idEntity) && (
                            <MenuItem value={ConditionOperators.NOTEXISTS}>
                                No existe
                            </MenuItem>
                        )}
                        {(hasIntegrations ||
                            condition.operator ===
                                ConditionOperators.INTEGRATION_MET) && (
                            <MenuItem
                                value={ConditionOperators.INTEGRATION_MET}
                            >
                                Cumple
                            </MenuItem>
                        )}
                        {(hasIntegrations ||
                            condition.operator ===
                                ConditionOperators.NOTINTEGRATION_MET) && (
                            <MenuItem
                                value={ConditionOperators.NOTINTEGRATION_MET}
                            >
                                No cumple
                            </MenuItem>
                        )}
                    </RoundedSelect>
                </div>
            </div>
            {renderValue()}
        </React.Fragment>
    );
}
export default EntityConditionComponent;
