import { useState, useRef, useEffect } from 'react';
import styles from './ContentCard.module.css';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import { EditorState, convertFromRaw } from 'draft-js';
import { getRawText } from '../../../../../utils/draftFunctions';
import { Payload } from '../../../../../@Types/Payload';
import DraftRenderer from '../../../../../shared/DraftRenderer/DraftRenderer';
import './DraftStyles.css';
import { useMediaQuery } from '@material-ui/core';
import SentimentTypes from '../../../../../constants/SentimentTypes';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
interface ContentCardProps {
    content?: Payload;
    /** If the layout is mobile */
    layoutIsMobile?: boolean;
}

function ContentCard({
    content,
    layoutIsMobile,
}: ContentCardProps): JSX.Element {
    const editorRef = useRef<any>(null);
    const listRef = useRef<HTMLDivElement>(null);
    const [showMore, setShowMore] = useState(false);
    const [showLineClamp, setShowLineClamp] = useState(true);
    const [canShowMore, setCanShowMore] = useState(false);
    const mobile = useMediaQuery('(max-height:899px)', {
        noSsr: true,
    });
    let maxHeightClose = !layoutIsMobile && mobile ? 75 : 120;
    /** Calcs if the contentcard has more to show */
    const handleResize = (): void => {
        setCanShowMore(
            editorRef.current &&
                (editorRef?.current?.scrollHeight ??
                    editorRef?.current?.editor?.scrollHeight) > maxHeightClose
        );
    };

    useEffect(() => {
        /** On load add a resize listener to check if the content fits in the contentcard */
        window.addEventListener('resize', handleResize);
        handleResize();
        setTimeout(() => {
            handleResize();
        }, 500);
        return (): void => {
            window.removeEventListener('resize', handleResize);
        };
    }, [content, editorRef.current]);

    useEffect(() => {
        if (!showLineClamp) {
            handleResize();
        }
    }, [showLineClamp]);

    const editorState = EditorState.createWithContent(
        convertFromRaw(getRawText(content?.draft, content?.text))
    );

    return (
        <div className={styles.container}>
            {content?.sentiment && (
                <div
                    className={
                        layoutIsMobile
                            ? styles.mobileSentimentContainer
                            : styles.sentimentContainer
                    }
                >
                    <ContentSentiment sentiment={content.sentiment} />
                </div>
            )}
            <div
                className={layoutIsMobile ? styles.mobileCard : styles.card}
                style={{
                    transition: 'max-height 0.5s',
                    maxHeight: showMore
                        ? layoutIsMobile
                            ? '100%'
                            : 270
                        : maxHeightClose,
                }}
            >
                <div
                    className={
                        layoutIsMobile
                            ? styles.mobileCardContent + ' ticketContentCard'
                            : styles.cardContent + ' ticketContentCard'
                    }
                    ref={listRef}
                >
                    <div
                        className={
                            showLineClamp
                                ? layoutIsMobile
                                    ? styles.mobileTextParagraph
                                    : styles.clampedTextParagraph
                                : styles.textParagraph
                        }
                    >
                        <DraftRenderer
                            editorRef={editorRef}
                            editorState={editorState}
                            images={content?.images ?? {}}
                        />
                    </div>
                </div>
            </div>
            {canShowMore && !showMore && (
                <div className={styles.footer}>
                    <div
                        className={styles.showMore}
                        onClick={(): void => {
                            setShowMore(true);
                            setShowLineClamp(false);
                        }}
                    >
                        <div className={styles.arrowIcon}>
                            <ExpandMoreRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                </div>
            )}
            {canShowMore && showMore && (
                <div className={styles.footer}>
                    <div
                        className={styles.showMore}
                        onClick={(): void => {
                            if (listRef.current) listRef.current.scrollTop = 0;
                            setShowMore(false);

                            /** For the close animation to work */
                            if (layoutIsMobile) {
                                setShowLineClamp(true);
                            } else {
                                setTimeout(() => {
                                    setShowLineClamp(true);
                                }, 500);
                            }
                        }}
                    >
                        <div className={styles.arrowIcon}>
                            <ExpandLessRoundedIcon fontSize="inherit" />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
export default ContentCard;

interface ContentSentimentProps {
    sentiment: SentimentTypes;
}

function ContentSentiment({ sentiment }: ContentSentimentProps): JSX.Element {
    switch (sentiment) {
        case SentimentTypes.NEGATIVE:
            return (
                <div
                    className={styles.negativeSentiment}
                    title="Tono identificado: Negativo"
                >
                    <SentimentVeryDissatisfiedIcon fontSize="inherit" />
                </div>
            );
        case SentimentTypes.NEUTRAL:
            return (
                <div
                    className={styles.neutralSentiment}
                    title="Tono identificado: Neutral"
                >
                    <SentimentSatisfiedIcon fontSize="inherit" />
                </div>
            );
        case SentimentTypes.POSITIVE:
            return (
                <div
                    className={styles.positiveSentiment}
                    title="Tono identificado: Positivo"
                >
                    <SentimentSatisfiedAltIcon fontSize="inherit" />
                </div>
            );

        default:
            return <div></div>;
    }
}
