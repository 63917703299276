import { Integration } from '../../@Types/Integration';
import axiosInstance from '../../AxiosAPI';
import IntegrationTypes from '../../constants/IntegrationTypes';
import { RootApi } from '../../utils/_api';

const IntegrationsWithTags = RootApi.enhanceEndpoints({
    addTagTypes: ['Integrations'],
});

interface IntegrationsQuery {
    idProject?: string;
    idEntity?: string;
    type?: IntegrationTypes;
}

export const IntegrationsApi = IntegrationsWithTags.injectEndpoints({
    endpoints: (build) => ({
        loadIntegration: build.query<Integration, string>({
            query: (idIntegration) => {
                return {
                    url: `/integrations/${idIntegration}`,
                    method: 'get',
                };
            },
            providesTags: ['Integrations'],
        }),

        loadIntegrations: build.query<Integration[], IntegrationsQuery>({
            queryFn: async (query, { dispatch }) => {
                const integrations = await loadIntegrations(query);
                await Promise.all(
                    integrations.map(async (integration) => {
                        await dispatch(
                            IntegrationsApi.util.upsertQueryData(
                                'loadIntegration',
                                integration._id,
                                integration
                            )
                        );
                    })
                );

                return { data: integrations };
            },
            providesTags: ['Integrations'],
        }),
    }),
    overrideExisting: false,
});

/**
 * Function that loads a list of integrations
 * @param query - The query object
 */
const loadIntegrations = async (
    query: IntegrationsQuery
): Promise<Integration[]> => {
    const urlQuery = new URLSearchParams();

    for (const [key, value] of Object.entries(query)) {
        if (value !== undefined) {
            urlQuery.append(key, value);
        }
    }

    const { data } = await axiosInstance.get<Integration[]>(
        `/integrations?${urlQuery.toString()}`
    );
    return data;
};

export const { useLoadIntegrationsQuery } = IntegrationsApi;
