import { Entry } from '../../../@Types/@Types';
import React from 'react';
import styles from './EntryDetail.module.css';
import EditContainer from '../../../shared/EditContainer/EditContainer';
import RoundedButton from '../../../shared/RoundedButton/RoundedButton';
import EntryTypes from '../../../constants/EntryTypes';
import EntryEmail from './EntryEmail';
import EntryForm from './EntryForm';
import { match, RouteComponentProps, withRouter } from 'react-router-dom';
import EntryConversation from './EntryConversation';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { checkAdmin } from '../../../utils/PermissionsFunctions';

interface EntryDetailProps {
    /** The selected Entry */
    selectedElement?: Entry;
    /** Function called when an agent's value needs updating */
    handleUpdate: Function;
    match: match<{ idProject: string }>;
    /** Function that handles deleting the current classifier */
    handleClickDelete: Function;
}
function EntryDetail({
    handleClickDelete,
    selectedElement,
    handleUpdate,
    history,
    match,
}: EntryDetailProps & RouteComponentProps): JSX.Element {
    const user = useSelector((state: RootState) => state.site.user);

    if (!selectedElement) {
        return <div></div>;
    }

    return (
        <div
            className={styles.containerDetail}
            data-testid={'EntryDetailContainer'}
        >
            <div className={styles.btnsContainer}>
                {checkAdmin(user) && (
                    <div
                        className={styles.delBtn + ' grey-btn'}
                        onClick={(): void => {
                            handleClickDelete?.(selectedElement);
                        }}
                    >
                        <DeleteRoundedIcon
                            style={{ fill: 'black' }}
                            fontSize="inherit"
                        />
                    </div>
                )}
            </div>
            <div
                className={styles.titleContainer}
                data-testid={'titleContainer'}
            >
                <div className={styles.title}>
                    <EditContainer
                        object={selectedElement}
                        field={'entryName'}
                        handleConfirm={handleUpdate}
                    >
                        <div className={styles.titlelbl}>
                            {selectedElement.entryName}
                        </div>
                        <input
                            className={styles.nameInput + ' edit-input'}
                            defaultValue={selectedElement.entryName}
                            type="text"
                            size={10}
                        />
                    </EditContainer>
                </div>
            </div>
            <div className={styles.descriptionLabel}>Descripción</div>
            <div
                className={styles.descriptionContainer}
                data-testid={'descriptionContainer'}
            >
                <EditContainer
                    object={selectedElement}
                    field={'description'}
                    handleConfirm={handleUpdate}
                >
                    <div className={styles.description}>
                        {selectedElement.description}
                    </div>
                    <textarea
                        className={styles.descriptionInput + ' edit-input'}
                        defaultValue={selectedElement.description}
                    />
                </EditContainer>
            </div>
            {selectedElement.type === EntryTypes.EMAIL && (
                <EntryEmail
                    entry={selectedElement}
                    handleUpdate={handleUpdate}
                />
            )}
            {selectedElement.type === EntryTypes.FORM && (
                <EntryForm entry={selectedElement} />
            )}
            {selectedElement.type === EntryTypes.WHATSAPP && (
                <EntryConversation entry={selectedElement} />
            )}
            <div className={styles.flujoLabel}>Flujo de Entrada</div>
            <div className={styles.flujo}>
                <div>
                    <img
                        alt={'Flow'}
                        className={styles.flowFoto}
                        src={'/media/flow.png'}
                    ></img>
                    <div className={styles.flowBtn}>
                        <RoundedButton
                            text="Ver Flujo"
                            backgroundColor="var(--secondary)"
                            color="white"
                            onClick={(): void => {
                                history.push(
                                    `/${match.params.idProject}/flows/${selectedElement.idFlow}`
                                );
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default withRouter(EntryDetail);
