import { InmuebleInfo360 } from '../../../types';
import { Saldos } from './Saldos';
import styles from './Cartera.module.css';
import { CierreFinanciero } from './CierreFinanciero';
import { Entidades } from './Entidades/Entidades';
import { Pagos } from './Pagos';

interface CarteraProps {
    cartera: InmuebleInfo360['cartera'];
}

export function Cartera({ cartera }: CarteraProps): JSX.Element {
    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <CierreFinanciero conceptosMap={cartera.conceptosMap} />
            </div>
            <div className={styles.section}>
                <Entidades
                    entidades={cartera.entidades}
                    conceptosMap={cartera.conceptosMap}
                />
            </div>
            <div className={styles.section}>
                <Saldos planDePagos={cartera.planDePagos} />
            </div>
            <div className={styles.section}>
                <Pagos pagos={cartera.pagos} />
            </div>
        </div>
    );
}
