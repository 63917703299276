import { StepComponentProps } from '../../Step';
import styles from './InfoStickerStep.module.css';
import React from 'react';
import StepContainer from '../../StepContainer/StepContainer';
import { InfoStickerStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../utils/_store';

interface InfoStickerStepProps extends StepComponentProps {
    step: InfoStickerStep;
}

function InfoStickerStepComponent({
    step,
    path,
}: InfoStickerStepProps): JSX.Element {
    const maxWidth = useSelector(
        (state: RootState) => state.conversationEditor.imgMaxWidth
    );
    const isPlaceholder =
        step.file.url === 'https://content.capta.co/Capta/Files/CaptaSqr.png';
    const { width, ratio } = step;
    const calcHeight = (): number => {
        if (!isNaN(maxWidth) && maxWidth > 0 && maxWidth < width) {
            return maxWidth / ratio + 7;
        } else {
            const newHeight = width / ratio + 7;
            return !isNaN(newHeight) ? newHeight : 0;
        }
    };
    return (
        <StepContainer step={step} path={path}>
            <div
                className={styles.imageContainer}
                style={{ minHeight: calcHeight() }}
            >
                <img
                    className={
                        isPlaceholder ? styles.imagePlaceholder : styles.image
                    }
                    src={step.file.url}
                    style={{
                        height: 'auto',
                        width,
                        maxWidth: '100%',
                    }}
                />
                <div className={styles.stickerIconContainer}>
                    <img
                        src="/media/icons/stickerIcon.svg"
                        className={styles.stickerIcon}
                    />
                </div>
            </div>
        </StepContainer>
    );
}

export default InfoStickerStepComponent;
