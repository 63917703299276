import { IconProps } from './@IconTypes';
import { JSX } from 'react';

function GroupedEmailIcon({ className, style, fill }: IconProps): JSX.Element {
    return (
        <svg
            fill={fill}
            style={style}
            className={className}
            version="1.1"
            viewBox="0 0 18 15"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M16.7425 4.91983H15.6079V3.78524C15.6079 3.09185 15.0438 2.52773 14.3504 2.52773H13.2158V1.39324C13.2158 0.697573 12.6515 0.135742 11.9584 0.135742H1.2575C0.565839 0.135777 0 0.695815 0 1.39324V8.82287C0 9.51626 0.564116 10.0804 1.2575 10.0804H2.39203V11.215C2.39203 11.9084 2.95614 12.4725 3.64953 12.4725H4.78405V13.607C4.78405 14.3004 5.34817 14.8645 6.04155 14.8645H16.7425C17.4359 14.8645 18 14.3004 18 13.607V6.1773C18 5.48395 17.4358 4.91983 16.7425 4.91983ZM11.0507 1.39149L6.59999 5.40009L2.1653 1.39159L11.0507 1.39149ZM1.25581 8.82287L1.25606 2.25882L5.99999 6.30009C6.24415 6.53939 6.9559 6.53932 7.19999 6.30009L11.9599 2.25879L11.9585 8.82456L1.25581 8.82287ZM3.64784 11.2149V10.0804H11.9585C12.6519 10.0804 13.2159 9.51629 13.2159 8.82287V3.78376L14.3521 3.78411C14.3521 3.78411 14.3522 3.7845 14.3522 3.78527L14.3505 11.2166L3.64784 11.2149ZM16.7425 13.6086L6.03987 13.6069V12.4724H14.3505C15.0439 12.4724 15.608 11.9083 15.608 11.2149V6.17582L16.7442 6.17614C16.7442 6.17614 16.7442 6.17652 16.7442 6.1773L16.7425 13.6086Z"
            />
        </svg>
    );
}

export default GroupedEmailIcon;
