import { useMemo } from 'react';
import { DraftEntityData } from '../../../@Types/Draft/DraftEntityData';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { EntityDataMapper } from '../EntityDataMapper';
import styles from './Decorator.module.css';
import FilterIcon from '../../../Icons/FilterIcon';

export interface DecoratorProps {
    data: DraftEntityData;
    onEdit?: () => void;
}

function Decorator({ data, onEdit }: DecoratorProps): JSX.Element {
    const { Icon, Label } = useMemo(() => EntityDataMapper(data), [data]);

    return (
        <div
            className={styles.container}
            style={{
                cursor: onEdit ? 'pointer' : 'default',
            }}
            onClick={onEdit}
            title={onEdit ? 'Editar' : undefined}
        >
            <div className={styles.icon}>
                <Icon
                    fill="var(--primary1)"
                    contrast="var(--secondary2)"
                    style={{
                        width: '16px',
                        height: '16px',
                    }}
                />
            </div>
            <div className={styles.label}>
                {typeof Label === 'string' ? Label : <Label />}
            </div>
            {data.condition && (
                <span style={{ paddingRight: 3 }}>
                    {'('}
                    <FilterIcon
                        fill={'var(--primary1)'}
                        style={{
                            marginTop: -2,
                            paddingLeft: 1,
                            paddingRight: 1,
                            width: 10,
                            height: 10,
                        }}
                    />
                    {')'}
                </span>
            )}
            {onEdit && (
                <div className={styles.editIcon}>
                    <EditRoundedIcon fontSize="inherit" />
                </div>
            )}
        </div>
    );
}

export default Decorator;
