import { Company } from '../../@Types/@Types';
import { CompanyClient } from '../../@Types/Company';
import axiosInstance from '../../AxiosAPI';

export const loadCompaniesByQuery = async (
    queryString: string,
    idCompany?: string
): Promise<{
    elements: Company[];
    page: number;
    selectedElement?: number;
    filters: {};
    orderBy: string;
}> => {
    const query = new URLSearchParams(queryString);
    const resp = await axiosInstance.get(
        `/companies/load${idCompany ? '/' + idCompany : ''}` + '?' + query
    );
    return resp.data;
};

/**
 * Function that loads the companies' list
 * @param page currently viewing
 * @param pageSize to request
 * @param filters currently active
 * @param orderBy method ordered by, undefined if search is active
 * @param search search query, undefined if no search is active
 * @returns array of Companies that match the inputs
 */
export const loadCompanies = async (
    page: number,
    pageSize: number,
    filters: any[], //TODO definir filtros
    orderBy: string | undefined,
    search: string | undefined
): Promise<Company[]> => {
    let url = `/companies?page=${page}&pageSize=${pageSize}`;
    if (filters.length !== 0) {
        url += `&filters=${filters}`;
    }
    if (search !== undefined) {
        url += `&search=${encodeURIComponent(search)}`;
    } else {
        url += `&orderBy=${orderBy}`;
    }
    const resp = await axiosInstance.get(url);
    return resp.data;
};

/**
 * Function called to handle the conexion with the server to create an company
 * @param company Record of values to create the company with
 */
export const createCompany = async (
    company: Record<string, unknown>
): Promise<void> => {
    await axiosInstance.post('/companies', company);
};

/**
 * Function that handles the async update of an company's value
 * @param id of the company to update
 * @param company Record of values to update
 */
export const updateCompany = async (
    id: string,
    company: Partial<Company>
): Promise<void> => {
    await axiosInstance.patch(`/companies/${id}`, company);
};

/**
 * Function that fetches a single company given its id
 * @param idCompany the id of the company to fetch
 * @returns ticket
 */
export const loadCompanyById = async (idCompany: string): Promise<Company> => {
    const response = await axiosInstance.get(`/companies/${idCompany}`);
    return response.data;
};

/**
 * Function that loads the companies by a given list of ids
 * @param companies the list of ids
 */
export const loadCompaniesById = async (
    companies: string[]
): Promise<Company[]> => {
    if (companies.length === 0) return [];
    const response = await axiosInstance.get(
        `/companies?id=${companies.join(',')}`
    );
    return response.data;
};

/**
 * Function called to handle the conexion with the server to delete a company
 * @param idCompany the id of the company to delete
 */
export const deleteCompany = async (idCompany: string): Promise<void> => {
    await axiosInstance.delete(`/companies/${idCompany}`);
};

/**
 * Function called to handle the conexion with the server to fetch the clients of a company
 * @param idCompany the id of the company to fetch
 */
export const loadCompanyClients = async (
    idCompany: string
): Promise<CompanyClient[]> => {
    const response = await axiosInstance.get(`/companies/${idCompany}/clients`);
    return response.data;
};

/**
 * Function called to handle the conexion with the server to update a clients company permission
 * @param idCompany the id of the company to update
 * @param idClient the id of the client to update
 * @param permissions the new permissions of the client
 */
export const updateCompanyClientPermissions = async (
    idCompany: string,
    idClient: string,
    permissions: Record<string, boolean>
): Promise<void> => {
    await axiosInstance.patch(
        `/companies/${idCompany}/clients/${idClient}`,
        permissions
    );
};

/**
 * Function called to handle the conexion with the server to remove a client from a company
 * @param idCompany the id of the company to update
 * @param idClient the id of the client to remove
 */
export const removeCompanyClient = async (
    idCompany: string,
    idClient: string
): Promise<void> => {
    await axiosInstance.delete(`/companies/${idCompany}/clients/${idClient}`);
};

/**
 * Function called to handle the conexion with the server to add a client from a company
 * @param idCompany the id of the company to update
 * @param idClient the id of the client to add
 */
export const addCompanyClient = async (
    idCompany: string,
    idClient: string
): Promise<void> => {
    await axiosInstance.post(`/companies/${idCompany}/clients/${idClient}`);
};

export default {
    loadCompanies,
    createCompany,
    updateCompany,
    loadCompanyById,
    loadCompaniesById,
    deleteCompany,
    loadCompanyClients,
    addCompanyClient,
    removeCompanyClient,
    updateCompanyClientPermissions,
};
