import styles from './EmailTicketEntry.module.css';
import { Ticket } from '../../../../../@Types/TicketTypes/Ticket';
import FormRenderer from '../../../../../shared/FormRenderer/FormRenderer';
import { Form } from '../../../../../@Types/@Types';
import FormStepTypes from '../../../../../constants/FormStepTypes';

interface EmailTicketEntryProps {
    ticket: Ticket;
}
/**
 * Component rendered inside the postview dialog.
 */
function EmailTicketEntryComponent({
    ticket,
}: EmailTicketEntryProps): JSX.Element {
    return (
        <div className={styles.container}>
            <div className={styles.containerLbl}>{ticket.entry.name}</div>
            <div
                className={styles.widgetContainer}
                style={{
                    width:
                        (emailForm.size.blockSize +
                            emailForm.size.spacingSize) *
                            emailForm.size.blockNum +
                        40,
                }}
            >
                <FormRenderer
                    postview={true}
                    form={emailForm}
                    values={{
                        subject: ticket.subject,
                        content: {
                            draft: ticket.original.draft,
                            value: ticket.original.text,
                        },
                        adjuntos: ticket.content.files,
                    }}
                    rootSteps={['subject', 'content', 'adjuntos']}
                />
            </div>
        </div>
    );
}

export default EmailTicketEntryComponent;

const emailForm: { steps: Form['steps']; size: Form['size'] } = {
    steps: {
        subject: {
            id: 'subject',
            type: FormStepTypes.TEXTINPUT,
            label: 'Asunto',
            description: '',
            required: true,
            size: 4,
            isSubject: true,
        },
        content: {
            id: 'content',
            type: FormStepTypes.TEXTAREA,
            label: 'Cuerpo',
            description: '',
            required: true,
            hasTextEditor: true,
            isPrimary: true,
        },
        adjuntos: {
            id: 'adjuntos',
            type: FormStepTypes.FILEUPLOAD,
            label: 'Adjuntos',
            description: '',
            required: false,
        },
    },
    size: {
        blockNum: 4,
        blockSize: 210,
        spacingSize: 20,
    },
};
