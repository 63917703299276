import { useRef } from 'react';
import styles from './ImageButton.module.css';
import CropOriginalRoundedIcon from '@material-ui/icons/CropOriginalRounded';
import React from 'react';
import {
    PayloadEditorActions,
    usePayloadEditorDispatch,
} from '../../../controllers/PayloadEditorController/PayloadEditorSlice';

/**
 * Generic attach file button for PayloadEditor
 */
function ImageButton(): JSX.Element {
    const inputRef = useRef<HTMLInputElement>(null);
    const btnRef = useRef<HTMLButtonElement>(null);

    const dispatch = usePayloadEditorDispatch();

    const onFilesSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const newFiles = e.target.files;
        if (newFiles) {
            const filesArray = Array.from(newFiles);
            if (filesArray.length === 1) {
                dispatch(PayloadEditorActions.uploadImage(filesArray[0]));
            }
            if (inputRef.current) {
                inputRef.current.value = '';
            }
        }
    };

    return (
        <div
            onClick={(): void => {
                inputRef?.current?.click?.();
            }}
            className={styles.btnContainer}
            data-testid="upload_image_btn"
        >
            <button className={styles.imageBtn} ref={btnRef}>
                <CropOriginalRoundedIcon fontSize="inherit" />
            </button>
            <input
                type="file"
                ref={inputRef}
                style={{ display: 'none' }}
                onChange={onFilesSelected}
                accept="image/*"
            />
        </div>
    );
}
export default ImageButton;
