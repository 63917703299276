import LogRocket from 'logrocket';
import createSagaMiddleware from 'redux-saga';
import 'regenerator-runtime/runtime';
import reducer from './_reducer';
import rootSaga from './_saga';
import { ThunkDispatch, configureStore } from '@reduxjs/toolkit';
import { RootApi } from './_api';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
        }),
        RootApi.middleware,
        sagaMiddleware,
        LogRocket.reduxMiddleware(),
    ],
});

sagaMiddleware.run(rootSaga);
export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
type AppAction = ReturnType<typeof store.dispatch>;
export type AppDispatch = ThunkDispatch<RootState, any, AppAction>;
export type AppStore = typeof store;
