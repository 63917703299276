import { StepComponentProps } from '../../Step';
import styles from './InfoImageStep.module.css';
import React from 'react';
import StepContainer from '../../StepContainer/StepContainer';
import { InfoImageStep } from '../../../../../../@Types/ConversationTypes/ConversationStep';
import DraftRenderer from '../../../../../../shared/DraftRenderer/DraftRenderer';
import { EditorState, convertFromRaw } from 'draft-js';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../utils/_store';

interface InfoImageStepProps extends StepComponentProps {
    step: InfoImageStep;
}

function InfoImageStepComponent({
    step,
    path,
}: InfoImageStepProps): JSX.Element {
    const content = convertFromRaw(step.caption);
    const maxWidth = useSelector(
        (state: RootState) => state.conversationEditor.imgMaxWidth
    );
    const isPlaceholder =
        step.file.url === 'https://content.capta.co/Capta/Files/CaptaSqr.png';
    const { width, ratio } = step;
    const calcHeight = (): number => {
        if (!isNaN(maxWidth) && maxWidth > 0 && maxWidth < width) {
            return maxWidth / ratio + 7;
        } else {
            const newHeight = width / ratio + 7;
            return !isNaN(newHeight) ? newHeight : 0;
        }
    };
    return (
        <StepContainer step={step} path={path}>
            <div
                className={styles.imageContainer}
                style={{ minHeight: calcHeight() }}
            >
                <img
                    className={
                        isPlaceholder ? styles.imagePlaceholder : styles.image
                    }
                    src={step.file.url}
                    style={{
                        height: 'auto',
                        width,
                        maxWidth: '100%',
                    }}
                />
            </div>
            {content.hasText() && (
                <div
                    className={styles.messageContainer}
                    style={{
                        width:
                            !isNaN(maxWidth) && width > maxWidth
                                ? maxWidth
                                : width,
                    }}
                >
                    <DraftRenderer
                        editorState={EditorState.createWithContent(content)}
                    />
                </div>
            )}
        </StepContainer>
    );
}

export default InfoImageStepComponent;
