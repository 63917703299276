import TrendingFlatRoundedIcon from '@material-ui/icons/TrendingFlatRounded';
import styles from './AutomaticContent.module.css';
import { AutomaticTransformation } from '../../../../../../@Types/FlowTypes/NodeTypes/TransformationNode';
import { RootState } from '../../../../../../utils/_store';
import { useSelector } from 'react-redux';
import { TicketPropertyTypes } from '../../../../../../constants/TicketPropertyTypes';
import { ChannelTypes } from '../../../../../../constants/ChannelTypes';

interface AutomaticContentProps {
    /** Automatic Transformation Node data */
    data: AutomaticTransformation;
}
function AutomaticContent({ data }: AutomaticContentProps): JSX.Element {
    const siteInfo = useSelector((state: RootState) => state.site);
    if (
        data.property === TicketPropertyTypes.CLASSIFIER &&
        data.idRoot &&
        data.idValue
    ) {
        const root = siteInfo.classifiers[data.idRoot];
        const value = siteInfo.classifiers[data.idValue];
        if (!root || !value) {
            return <div></div>;
        }
        return (
            <div className={styles.container}>
                <label className={styles.lbl}>{root.name}</label>
                <div className={styles.arrowContainer}>
                    <TrendingFlatRoundedIcon fontSize="inherit" />
                </div>
                <label className={styles.lbl}>{value.name}</label>
            </div>
        );
    } else if (data.property === TicketPropertyTypes.RESOLUTION_DATE) {
        return (
            <div className={styles.dateContainer}>
                <label className={styles.dateLbl}>Fecha</label>
                <label className={styles.dateLbl}>{` + ${
                    data.numDays ?? 0
                } días`}</label>
                {data.numHours > 0 && (
                    <label className={styles.dateLbl}>
                        {`+ ${data.numHours} horas`}
                    </label>
                )}
            </div>
        );
    } else if (data.property === TicketPropertyTypes.STATE && data.idState) {
        const state =
            siteInfo.projects[siteInfo.idProject!].states[data.idState];
        return (
            <div className={styles.container}>
                <label className={styles.dateLbl}>Estado: </label>
                <label className={styles.lbl}>{state.label}</label>
            </div>
        );
    } else if (
        data.property === TicketPropertyTypes.CHANNEL &&
        data.channel !== undefined
    ) {
        return (
            <div className={styles.container}>
                <label className={styles.dateLbl}>Canal: </label>
                <label className={styles.lbl}>
                    {calcChannelType(data.channel?.type ?? null)}
                </label>
            </div>
        );
    } else if (data.property === TicketPropertyTypes.SUBJECT) {
        return (
            <div className={styles.container}>
                <label className={styles.dateLbl}>Asunto: </label>
                <label className={styles.lbl}>{data.subject}</label>
            </div>
        );
    } else if (data.property === TicketPropertyTypes.SURVEY) {
        return (
            <div className={styles.container}>
                <label className={styles.dateLbl}>Encuesta:</label>
                <label className={styles.lbl}>
                    {data.survey?.name ?? 'Sin Encuesta'}
                </label>
            </div>
        );
    } else if (
        data.property === TicketPropertyTypes.PROCESS &&
        data.idProcess
    ) {
        return (
            <div className={styles.container}>
                <label className={styles.dateLbl}>Proceso:</label>
                <label className={styles.lbl}>
                    {data.process?.name ?? 'Sin Proceso'}
                </label>
            </div>
        );
    } else {
        return <div></div>;
    }
}
export default AutomaticContent;

function calcChannelType(type: ChannelTypes | null): string {
    switch (type) {
        case ChannelTypes.EMAIL:
            return 'Correo';
        case ChannelTypes.WHATSAPP:
            return 'Whatsapp';
        default:
            return 'Sin Canal';
    }
}
