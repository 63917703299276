import { useEffect, useState } from 'react';
import { Agent } from '../../@Types/Agent';
import styles from './Avatar.module.css';
import AgentsService from '../../controllers/AgentsController/AgentsService';
import Avatar from './Avatar';
interface SmartAgentProps {
    /** Size of the avatar */
    size?: number;
    /** If shows text on hover */
    toolTip?: boolean;
    /** The size of the font to display */
    fontSize?: number;
    /** The id of the agent to display */
    idAgent: string;
    /** Suffix to add after name */
    suffix?: string;
}
/**
 * Component for custom avatar
 */
function SmartAgent({ idAgent, suffix }: SmartAgentProps): JSX.Element {
    const [agent, setAgent] = useState<Agent | null>(null);

    const loadAgent = async (): Promise<void> => {
        try {
            const agent = await AgentsService.loadAgent(idAgent);
            setAgent(agent);
        } catch (e) {
            //
        }
    };

    useEffect(() => {
        loadAgent();
    }, [idAgent]);

    return (
        <div className={styles.agentContainer}>
            <Avatar lbl={agent?.name} img={agent?.img} />
            <label className={styles.nameLbl}>
                {agent?.name}
                {suffix ? suffix : ''}
            </label>
        </div>
    );
}

export default SmartAgent;
