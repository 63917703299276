enum LocationTypes {
    CLASSIFIER_SELECTOR = 'CLASSIFIER_SELECTOR',
    ENTITY_VALUE = 'ENTITY_VALUE',
    API_SELECTOR = 'API_SELECTOR',
    SELECTOR = 'SELECTOR',
    SECTION = 'SECTION',
    NESTED = 'NESTED',
    RATING = 'RATING',
    MAPPER = 'MAPPER',
    SUBSTEP = 'SUBSTEP',
    CHECKBOX = 'CHECKBOX',
}
export default LocationTypes;
