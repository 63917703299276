import { AnyAction } from '@reduxjs/toolkit';
import SOCKET_MESSAGE_TYPES from '../../constants/Sockets/SocketMessageTypes';
import * as Actions from './SocketsActions';
import SocketTicketActionEventType from '../../constants/Sockets/SocketTicketActionEventType';

/**
 * Interface that represent the distinct types of incomming messages from the sockets
 */
export type SocketMessage = SocketTicketActionMessage;

/**
 * Atributes that all incomming messages must have
 */
interface GenericSocketMessage {
    /** The type of the incomming message */
    type: SOCKET_MESSAGE_TYPES;
}

/**
 * Interface for a message of type TICKET_ACTION
 */
interface SocketTicketActionMessage extends GenericSocketMessage {
    /** The type of the incomming message */
    type: SOCKET_MESSAGE_TYPES.TICKET_ACTION;
    /** The payload with the action */
    idTicket: string;
    eventType: SocketTicketActionEventType;
}

export interface SocketsState {
    /** All the messages received by the socket */
    messages: SocketMessage[];
}

const initialState = {
    messages: [],
};
/**
 * Redux Reducer that handles SocketActions Triggers
 * @param state The current state
 * @param action the action that was triggered
 * @returns the new state
 */
const SocketsReducer = (
    state: SocketsState = initialState,
    action: AnyAction
): SocketsState => {
    if (Actions.receiveMessage.match(action)) {
        return {
            ...state,
            messages: [...state.messages, action.payload],
        };
    }
    return state;
};
export default SocketsReducer;
