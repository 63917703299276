import Dialog from '../../../shared/Dialog/Dialog';
import styles from './SaveDialog.module.css';
import RoundedButton from '../../../shared/RoundedButton/RoundedButton';
import { useEffect, useState } from 'react';
import { Flow, FlowError } from '../../../@Types/@Types';
import * as FlowService from '../../../controllers/FlowsController/FlowsService';
import WarningIcon from '@material-ui/icons/Warning';
import { FlowDecision } from '../../../@Types/FlowTypes/NodeTypes/DecisionNode';
import FlowNodeTypes from '../../../constants/Flows/FlowNodeTypes';
import { Edge, Node } from 'react-flow-renderer';
import ActivityPersonaTypes from '../../../constants/ActivityTypes/ActivityPersonaTypes';
import { mapCondition } from '../../../controllers/ConditionEditorController/ConditionFunctions';
interface SaveProps {
    /** Function that handles the closing event */
    onClose: (event?: MouseEvent) => void;
    /** Current Flow */
    flow: Flow;
    /** React Flow instance to generate elements */
    reactFlowInstance: any;
    /** Function called to focus on a node of the flow */
    handleFocusNode: Function;
    /**  Function to update the current elements*/
    setElements: React.Dispatch<
        React.SetStateAction<(Node<any> | Edge<any>)[]>
    >;
}

class LocalFlowError extends Error {
    response: {
        data: {
            errors: FlowError[];
        };
    };
    constructor(error: FlowError) {
        super(error.message);
        this.response = {
            data: {
                errors: [error],
            },
        };
    }
}

/**
 * Component that validates the current flow, confirms the save and calls the services to save.
 */
function Save(props: SaveProps): JSX.Element {
    return (
        <Dialog
            open={props.reactFlowInstance !== undefined}
            onClose={props.onClose}
            showLoader={false}
        >
            <InnerSaveDialog {...props}></InnerSaveDialog>
        </Dialog>
    );
}
export default Save;

interface InnerDialogProps extends SaveProps {
    /** Function that toggles the loader in the modal, inherited from the modal. */
    setLoading?: Function;
    /** if loader should be active */
    loading?: boolean;
}

function InnerSaveDialog({
    flow,
    onClose,
    setLoading,
    setElements,
    handleFocusNode,
    reactFlowInstance,
}: InnerDialogProps): JSX.Element {
    const [validating, setValidating] = useState(true);
    const [tempFlowId, setTempFlowId] = useState('');
    const [savedCorrectly, setSavedCorrectly] = useState(false);
    const [errors, setErrors] = useState<FlowError[] | null>([]);
    //TODO agregar un ícono de un check en el modal de confirmaciónd e guardado
    const onSave = async (): Promise<void> => {
        if (setLoading && reactFlowInstance !== undefined) {
            setLoading(true);
            try {
                await FlowService.saveElements(
                    flow.idProject,
                    flow._id,
                    tempFlowId
                );
                setSavedCorrectly(true);
            } catch (error) {
                //TODO handle errors
                console.error(error);
            }
            setLoading(false);
        } else {
            onClose();
        }
    };

    useEffect(() => {
        validate();
    }, []);

    const mapElements = (elmts: any[]): any[] => {
        return elmts.map((element) => {
            if (element?.data?.error) {
                delete element.data.error;
            }
            ////Migracion de flowtypes a mayusculas
            if (element.type !== 'default' && element.type !== 'error')
                element.type = element.type.toUpperCase();
            switch (element.type) {
                case FlowNodeTypes.AGENT: {
                    return {
                        ...element,
                        data: { _id: element.data._id },
                    };
                }
                case FlowNodeTypes.AREA: {
                    return {
                        ...element,
                        data: { _id: element.data._id },
                    };
                }
                case FlowNodeTypes.TRANSFORMATION: {
                    const copy = {
                        ...element,
                        data: { ...element.data },
                    };
                    if (copy.data.property === 'SURVEY' && copy.data.survey) {
                        delete copy.data.survey;
                    }
                    if (
                        copy.data.property === 'CHANNEL' &&
                        copy.data.channel.apiKey
                    ) {
                        copy.data.channel.apiKey =
                            copy.data.channel.apiKey.apiKey ??
                            copy.data.channel.apiKey;
                    } else if (
                        copy.data.property === 'PROCESS' &&
                        copy.data.process
                    ) {
                        delete copy.data.process;
                        if (copy.data.roles) {
                            for (const idRole of Object.keys(copy.data.roles)) {
                                const role = copy.data.roles[idRole];
                                if (role.type === ActivityPersonaTypes.AREA) {
                                    role.idArea =
                                        role.idArea?._id ?? role.idArea;
                                } else if (
                                    role.type === ActivityPersonaTypes.AGENT
                                ) {
                                    role.agents = role.agents?.map(
                                        (agent: any) => agent?._id ?? agent
                                    );
                                } else if (
                                    role.type ===
                                    ActivityPersonaTypes.ENTITYVALUE
                                ) {
                                    role.idEntity =
                                        role.idEntity?._id ?? role.idEntity;
                                    role.fallback = role.fallback?.map(
                                        (agent: any) => agent?._id ?? agent
                                    );
                                }
                            }
                        }
                    } else if (
                        copy.data.property === 'SUBTICKET' &&
                        copy.data.idFlow
                    ) {
                        if (typeof copy.data.idFlow !== 'string') {
                            copy.data.idFlow = copy.data.idFlow.idFlow;
                        }
                    }
                    return copy;
                }
                case FlowNodeTypes.DECISION: {
                    element.data.decisions = element.data.decisions?.map(
                        (decision: FlowDecision) => {
                            const condition = mapCondition(decision.condition);
                            if (condition) decision.condition = condition;
                            else {
                                throw new LocalFlowError({
                                    nodeId: element.id,
                                    message: 'La condición no es válida',
                                });
                            }
                            return decision;
                        }
                    );
                    return element;
                }
                default:
                    return element;
            }
        });
    };

    const clearErrors = (): void => {
        setErrors([]);
        setElements((elements) =>
            elements.map((element) =>
                element?.data?.error
                    ? {
                          ...element,
                          data: { ...element.data, error: undefined },
                      }
                    : element
            )
        );
    };

    const validate = async (): Promise<void> => {
        if (setLoading && reactFlowInstance !== undefined) {
            setValidating(true);
            setLoading(true);
            try {
                const elements = await mapElements(
                    JSON.parse(
                        JSON.stringify(reactFlowInstance.toObject().elements)
                    )
                    //JSON use for deep cloning
                );
                const idTemp = await FlowService.validateElements(
                    flow.idProject,
                    flow._id,
                    elements
                );
                setTempFlowId(idTemp);
                clearErrors();
            } catch (error: any) {
                clearErrors();
                const errors: FlowError[] | null =
                    error?.response?.data?.errors ?? null;
                setErrors(errors);
                if (errors) {
                    setElements((elements) =>
                        elements.map((element) =>
                            errors.find(
                                (error) => error.nodeId === element.id
                            ) && element.data
                                ? {
                                      ...element,
                                      data: { ...element.data, error: true },
                                  }
                                : element
                        )
                    );
                }
            }
            setValidating(false);
            setLoading(false);
        } else {
            onClose();
        }
    };
    if (validating) {
        return (
            <div className={styles.container + ' standard-dialog'}>
                <div className={styles.validatingMsg}>
                    Validando
                    <span className="loader__dot">.</span>
                    <span className="loader__dot">.</span>
                    <span className="loader__dot">.</span>
                </div>
            </div>
        );
    }
    if (errors && errors?.length > 0) {
        return (
            <div className={styles.containerErrors + ' standard-dialog'}>
                <div className="center-anything">
                    <label className={styles.titleError}>
                        Errores Encontrados
                        <div className={styles.errorsIcon}>
                            <WarningIcon fontSize="inherit" />
                        </div>
                    </label>
                </div>
                <div className={styles.messageErrors}>
                    Se encontraron los siguientes errores:
                </div>

                {errors.map(
                    (error, index): JSX.Element => (
                        <div className={styles.errorMsgContainer} key={index}>
                            <div className={styles.errorsBullet}></div>
                            <span className={styles.errorMessageSpan}>
                                {error.message}
                            </span>
                            <div
                                className={styles.viewErrorBtn}
                                onClick={(): void => {
                                    handleFocusNode(error.nodeId);
                                    onClose();
                                }}
                            >
                                Ver Error
                            </div>
                        </div>
                    )
                )}

                <div className={styles.messageErrorsEnd + ' center-anything'}>
                    Por favor revisarlos antes de guardar los cambios.
                </div>
            </div>
        );
    }
    if (!errors) {
        return (
            <div className={styles.containerUnknownError + ' standard-dialog'}>
                <div className="center-anything">
                    <label className={styles.titleError}>
                        Error Desconocido
                        <div className={styles.errorsIcon}>
                            <WarningIcon fontSize="inherit" />
                        </div>
                    </label>
                </div>
            </div>
        );
    }
    if (savedCorrectly) {
        return (
            <div className={styles.container + ' standard-dialog'}>
                <div className="center-anything">
                    <label className={styles.title + ' center-anything'}>
                        Se ha guardado correctamente!
                    </label>
                </div>
                <div className={styles.cerrarBtn}>
                    <RoundedButton
                        text={'Cerrar'}
                        color="white"
                        fontSize={18}
                        onClick={(): void => onClose()}
                    />
                </div>
            </div>
        );
    }
    return (
        <div className={styles.container + ' standard-dialog'}>
            <div className="center-anything">
                <label className={styles.title + ' center-anything'}>
                    Este flujo es válido!
                </label>
                <div className={styles.message}>
                    <label>¿Guardar los cambios realizados?</label>
                </div>
                <div className="button">
                    <RoundedButton
                        text="Cancelar"
                        color="var(--accent)"
                        backgroundColor="var(--light-grey)"
                        onClick={(): void => onClose()}
                        fontSize={18}
                    />
                </div>
                <div className="button">
                    <RoundedButton
                        text={'Guardar'}
                        color="white"
                        fontSize={18}
                        onClick={onSave}
                    />
                </div>
            </div>
        </div>
    );
}
