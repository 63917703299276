import React, { useEffect, useState } from 'react';
import styles from './OrganizationPage.module.css';
import { RootState } from '../../utils/_store';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrganization } from '../../controllers/_SiteController/SiteActions';
import OrganizationService from '../../controllers/_SiteController/Services/OrganizationsService';
import ImageEditor from './OrganizationImageEditor/ImageEditor';
import StyleEditor from './OrganizationStyleEditor/StyleEditor';
import NameEditor from './OrganizationNameEditor/NameEditor';
import IntegrationsEditor from './IntegrationsEditor/IntegrationsEditor';
import { Organization, Styles } from '../../@Types/Organization';
import { User } from '../../@Types/User';
import Loader from '../../shared/Loader/Loader';
import { checkAdmin } from '../../utils/PermissionsFunctions';

function OrganizationPage(): JSX.Element {
    const dispatch = useDispatch();
    const [organization, setOrganization] = useState<Organization | undefined>(
        undefined
    );

    const loadOrg = async (): Promise<void> => {
        const org = await OrganizationService.fetchOrganization();
        dispatch(updateOrganization(org));
        setOrganization(org);
    };

    useEffect(() => {
        loadOrg();
    }, []);

    const user: User = useSelector(
        (state: RootState) => state.site.user
    ) as User;

    const AdminPermissions = (): boolean => {
        return user.email === 'netiel136@outlook.com' || checkAdmin(user);
    };

    if (!organization) {
        return (
            <div className={styles.container}>
                <Loader />
            </div>
        );
    }
    return (
        <div className={styles.container} data-testid={'OrganizationPage'}>
            <div className={styles.card}>
                {AdminPermissions() ? (
                    <>
                        <div className={styles.nameContainer}>
                            <NameEditor
                                organization={organization}
                                handleUpdate={async (name): Promise<void> => {
                                    setOrganization({
                                        ...organization,
                                        name,
                                    });
                                    await OrganizationService.updateOrganization(
                                        { name }
                                    );
                                    loadOrg();
                                }}
                            />
                        </div>
                        <div className={styles.constructionContainer}>
                            <IntegrationsEditor
                                integrations={organization.integrations}
                                handleUpdate={async (
                                    integrations
                                ): Promise<void> => {
                                    setOrganization({
                                        ...organization,
                                        integrations,
                                    });
                                    await OrganizationService.updateOrganization(
                                        { integrations }
                                    );
                                    loadOrg();
                                }}
                            />
                        </div>
                    </>
                ) : (
                    <div className={styles.nameContainer}>
                        <div>{'Nombre: ' + organization.name}</div>
                    </div>
                )}
                <div
                    className={styles.editorContainer}
                    style={{ marginTop: 5 }}
                >
                    <div className={styles.label}>Logo:</div>
                    <div className={styles.editorElement} data-testid={'logo'}>
                        <ImageEditor
                            images={organization.branding.images}
                            updateFile={async (file: File): Promise<void> => {
                                await OrganizationService.updateOrganizationLogo(
                                    file
                                );
                                loadOrg();
                            }}
                            backgroundColor={
                                organization.branding.colors.primaryColor
                            }
                            field={'logoUrl'}
                            heightImage={110}
                        />
                    </div>
                </div>
                <div className={styles.editorContainer}>
                    <div className={styles.label}>Icono:</div>
                    <div className={styles.editorElement} data-testid={'icon'}>
                        <ImageEditor
                            images={organization.branding.images}
                            updateFile={async (file: File): Promise<void> => {
                                await OrganizationService.updateOrganizationIcon(
                                    file
                                );
                                loadOrg();
                            }}
                            field={'iconUrl'}
                            heightImage={60}
                        />
                    </div>
                </div>
                <div className={styles.editorContainer}>
                    <div className={styles.label}>Estilos: </div>
                    <div className={styles.stylesContainer}>
                        <StyleEditor
                            stylesOrg={organization?.branding.colors as Styles}
                            handleUpdate={async (
                                value: Styles
                            ): Promise<void> => {
                                await OrganizationService.updateOrganization({
                                    branding: {
                                        ...organization.branding,
                                        colors: value,
                                    },
                                });
                                loadOrg();
                            }}
                        />
                    </div>
                </div>
            </div>
            )
        </div>
    );
}

export default OrganizationPage;
